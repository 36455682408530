import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { logger } from 'Adapters/Logger'
import { Grouping } from 'Components/GroupingControl/types'
import { useTriggerToast } from 'Components/Toast'
import { setInitialDescription } from 'Features/UpdateAction'
import { UpdateActionForm } from 'Features/UpdateAction/useForm'
import { useUpdateAction } from 'Features/UpdateAction/useUpdateAction'
import { FieldType } from '__generated__'
import { useEffect } from 'react'
import { match } from 'ts-pattern'
import { DestinationSchema, SourceSchema } from './types'

export const useDropToUpdate = (grouping: Grouping) => {
    const {
        triggerToast,
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
    } = useTriggerToast()

    const { updateAction } = useUpdateAction()

    useEffect(
        () =>
            monitorForElements({
                onDrop({ source, location }) {
                    const destination = location.current.dropTargets[0]

                    if (!destination) return // if dropped outside of any drop targets

                    const sourceData = SourceSchema.safeParse(source.data)

                    const destinationData = DestinationSchema.safeParse(
                        destination.data
                    )

                    if (!sourceData.success || !destinationData.success) {
                        logger.error(
                            'Failed to parse drag and drop source or destination data with zod'
                        )
                        triggerToast()
                        return
                    }

                    const { action } = sourceData.data

                    const { groupId: value, fieldType } = destinationData.data

                    const update = {
                        ...action,
                        description: setInitialDescription(action.description),
                        assignedToId:
                            fieldType === 'assignedTo'
                                ? match(value)
                                      .with('unassigned', () => undefined)
                                      .otherwise(() => value)
                                : action.assignedToId,
                        extendedBy: action.extendedBy
                            ? action.extendedBy.fields.map(field => ({
                                  value:
                                      fieldType === field.type
                                          ? match(fieldType)
                                                .with(FieldType.Select, () =>
                                                    value === 'unassigned'
                                                        ? []
                                                        : [value]
                                                )
                                                .with(FieldType.Boolean, () =>
                                                    value === 'true'
                                                        ? true
                                                        : false
                                                )
                                                // No other fields implemented yet
                                                .otherwise(() => undefined)
                                          : field.value,
                                  initialValue: field.value,
                                  field: { id: field.id, type: field.type },
                              }))
                            : [],
                    } satisfies UpdateActionForm

                    updateAction({
                        id: action.id,
                        extendedByDefinitionId: action.extendedBy?.definitionId,
                        input: update,
                    })
                },
            }),
        [grouping.selection, triggerToast, updateAction]
    )

    return { errorToastOpen, setErrorToastOpen }
}
