import { match } from 'ts-pattern'
import { FieldType, Operator } from '../../__generated__'

export const operatorsByFieldType = (fieldType: FieldType) =>
    match(fieldType)
        .with(FieldType.Boolean, () => [Operator.Equals])
        .with(FieldType.Select, () => [
            Operator.Equals,
            Operator.IsDefined,
            Operator.Includes,
        ])
        .with(FieldType.Number, () => [
            Operator.Equals,
            Operator.GreaterThan,
            Operator.LessThan,
            Operator.IsDefined,
        ])
        .with(FieldType.Date, () => [
            Operator.Equals,
            Operator.IsDefined,
            Operator.GreaterThan,
            Operator.LessThan,
        ])
        .with(FieldType.User, () => [Operator.Equals, Operator.IsDefined])
        .with(FieldType.Relation, () => [Operator.Equals, Operator.IsDefined])
        .with(FieldType.Currency, () => [
            Operator.Equals,
            Operator.IsDefined,
            Operator.GreaterThan,
            Operator.LessThan,
        ])
        .with(FieldType.Text, () => [Operator.Equals, Operator.IsDefined])
        .with(FieldType.Email, () => [Operator.Equals, Operator.IsDefined])
        .with(FieldType.Url, () => [Operator.Equals, Operator.IsDefined])
        .otherwise(() => [])
