import { TextInput } from 'Components/Input'
import { BizObjDef_Url, BOField } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useURLConstraints } from './useURLConstraints'

type Props = BOField<'BusinessObjectUrlField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const URLField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    urlValue,
    onComplete,
}) => {
    const { urlConstraints, ...common } = fieldDefinition as BizObjDef_Url

    const { constraints, constraintsTooltip } = useURLConstraints({
        urlConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(urlValue ?? '')
    const [value, setValue] = useState(initialValue.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <TextInput
                name={common.type + '||' + common.id}
                value={value}
                id={common.id}
                hasError={!!errors.length}
                onChange={e => {
                    setValue(e.target.value)
                }}
                required={constraints.required}
            />

            <EditFieldButton
                disabled={initialValue.current === value || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current
                                    ? initialValue.current
                                    : undefined,
                                value: value.length ? value : null,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = value
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}
