import { dayjs } from 'Adapters/DayJS'
import { useMemo } from 'react'
import {
    CreateBusinessObjectDefinitionDateFieldPrecision,
    DateFieldConstraints,
} from '__generated__'
import { DateConstraintsMap, defaultConstraints } from './types'

type Params = {
    dateConstraints: DateFieldConstraints[]
    datePrecision: CreateBusinessObjectDefinitionDateFieldPrecision
}

export const useDateConstraints = ({
    dateConstraints,
    datePrecision,
}: Params) => {
    const constraints = useMemo(() => {
        return dateConstraints.reduce<DateConstraintsMap>((out, constraint) => {
            if (constraint.__typename === 'RequiredConstraint') {
                return { ...out, required: true }
            }

            if (constraint.__typename === 'DateRangeConstraint') {
                return {
                    ...out,
                    endBefore: constraint.end
                        ? dayjs(constraint.end)
                              .endOf(datePrecision)
                              .toISOString()
                        : undefined,
                    startAfter: constraint.start
                        ? dayjs(constraint.start)
                              .startOf(datePrecision)
                              .toISOString()
                        : undefined,
                }
            }

            return out
        }, defaultConstraints)
    }, [dateConstraints, datePrecision])

    const constraintsTooltip = useMemo(() => {
        const strings = []

        if (constraints.required) strings.push('Required.')
        if (constraints.startAfter && !constraints.endBefore)
            strings.push(
                `Must be after ${dayjs(constraints.startAfter).format(
                    'Do MMM YYYY'
                )}.`
            )
        if (constraints.endBefore && !constraints.startAfter)
            strings.push(
                `Must be before ${dayjs(constraints.endBefore).format(
                    'Do MMM YYYY'
                )}.`
            )
        if (constraints.endBefore && constraints.startAfter)
            strings.push(
                `Must be between ${dayjs(constraints.startAfter).format(
                    'Do MMM YYYY'
                )} and ${dayjs(constraints.endBefore).format('Do MMM YYYY')}.`
            )

        return strings.join(' ')
    }, [constraints])

    return { constraints, constraintsTooltip }
}
