import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { ContactUsToast } from 'Components/Toast'
import { FC } from 'react'
import { useToggleSubscription } from '../useToggleSubscription'

type Props = {
    id: string
}

export const ToggleSubscription: FC<Props> = ({ id }) => {
    const { subscribed, loading, toggleSubscription, toastOpen, setToastOpen } =
        useToggleSubscription(id)

    return (
        <>
            <MenuNavButton
                title="Subscribe"
                description="Receive notifications for this object."
                icon="Notifications"
                disabled={loading}
                onClick={toggleSubscription}
                secondIcon={subscribed ? 'ToggleChecked' : 'ToggleUnchecked'}
            />
            <ContactUsToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'We were unable to toggle the subscription. Contact us if the problem persists.'
                }
            />
        </>
    )
}
