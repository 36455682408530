import { ApolloError, LazyQueryExecFunction } from '@apollo/client'
import { flex, styled } from 'Adapters/Freestyled'
import { AIPowered } from 'Components/AIPowered'
import { TextButton } from 'Components/Button'
import { TextAreaInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { BusinessObjectDefinitionSuggestionQuery } from 'Features/CreateBusinessObjectDef/__generated__/query'
import { fromEvent } from 'Utils'
import { NoTypename, stripTypenameFields } from 'Utils/stripTypenames'
import {
    BusinessObjectDefinitionTemplate,
    Exact,
    QueryBusinessObjectDefinitionSuggestionInput,
} from '__generated__'
import { pipe } from 'lodash/fp'
import { FC, FormEvent, useCallback, useState } from 'react'

type Props = {
    onSuggestionReceived: (template: Template | undefined) => void
    getSuggestion: LazyQueryExecFunction<
        BusinessObjectDefinitionSuggestionQuery,
        Exact<{
            input: QueryBusinessObjectDefinitionSuggestionInput
        }>
    >
    disabled: boolean
    suggestionLoading: boolean
    suggestionError: ApolloError | undefined
}

export type Template = NoTypename<BusinessObjectDefinitionTemplate>

export const CreateBusinessObjectDefSuggestion: FC<Props> = ({
    onSuggestionReceived,
    getSuggestion,
    disabled,
    suggestionLoading,
    suggestionError,
}) => {
    const [prompt, setPrompt] = useState('')

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault()
            getSuggestion({ variables: { input: { prompt } } }).then(
                ({ data, error }) => {
                    return (
                        !error &&
                        onSuggestionReceived(
                            stripTypenameFields(
                                data?.businessObjectDefinitionSuggestion
                            )
                        )
                    )
                }
            )
        },
        [prompt, getSuggestion, onSuggestionReceived]
    )

    return (
        <StyledSuggestion $disabled={disabled}>
            <StyledTitle>
                <Text as="h2" variant="bold-4" htmlFor="description">
                    Generate with AI:
                </Text>

                <AIPowered />
            </StyledTitle>

            <Text as="p" variant="regular-4">
                Use AI to generate a structure for your dataset based on a
                description you provide.
            </Text>

            <TextAreaInput
                onChange={pipe(fromEvent, setPrompt)}
                value={prompt}
                minRows={3}
                placeholder="Describe yourdataset here..."
            />

            <TextButton
                text="Generate"
                type="button"
                onClick={handleSubmit}
                variant="primary"
                size="small"
                disabled={disabled || !prompt}
            />

            {suggestionLoading && (
                <Text as="span" variant="regular-5" className="loading-message">
                    Generating a model from your input, this may take up to 30
                    seconds...
                </Text>
            )}

            {suggestionError && !suggestionLoading && (
                <Text as="span" variant="regular-5" className="error-message">
                    We were unable to generate a model based on your input. Try
                    building the workflow manually.
                </Text>
            )}
        </StyledSuggestion>
    )
}

const StyledSuggestion = styled.div<{ $disabled: boolean }>`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    padding: 1.5rem 2rem;
    border: 1px dashed ${({ theme }) => theme.palette.ai.primary};
    width: 100%;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    background-color: ${({ theme }) => `${theme.palette.ai.primary}1A`};

    a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }

    .textarea-input {
        border: 1px solid ${({ theme }) => theme.palette.ui['05'].normal};
    }

    button {
        width: 100%;
    }

    .loading-message {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .error-message {
        color: ${({ theme }) => theme.palette.support['01'].normal};
    }
`

const StyledTitle = styled.div`
    ${flex('row', 'flex-start', 'center')}
    gap: 1rem;
    width: 100%;
`
