import * as Collapsible from '@radix-ui/react-collapsible'
import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { AddFieldContent } from 'Features/BusinessObjectDefinitionEditor/AddFieldContent'
import { BusinessObjectDefinitionField } from 'Features/BusinessObjectDefinitionField'
import { BusinessObjectDefinitionFieldEditor } from 'Features/BusinessObjectDefinitionFieldEditor'
import {
    FieldType,
    Field as TField,
    ValidationStatus,
    createNewField,
    createPendingFieldValidation,
    validateField,
    validateLabel,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { TextInput } from '../../../Components/Input'
import { Tooltip } from '../../../Components/Tooltip'
import { useModalControls, useResponder } from '../../../Hooks'
import { useProcessCreator } from '../useProcessCreator'
import { FieldCreation } from '../useProcessCreator/types'

type Props = {
    fields: FieldCreation[]
}

export const InitFields: FC<Props> = ({ fields }) => {
    const {
        dispatch,
        state: { label, relationOptions, userOptions, currencyOptions },
    } = useProcessCreator()

    const labelValidation = validateLabel(label.value)

    const addFieldRef = useRef<HTMLButtonElement>(null)
    const addFieldModal = useModalControls()
    const [addingFieldType, setAddingFieldType] = useState<
        FieldType | undefined
    >(undefined)

    const [editingField, setEditingField] = useState<
        { field: TField; index: number } | undefined
    >(undefined)

    const [open, setOpen] = useState(true)

    const { isLarge } = useResponder()

    return (
        <StyledInitFields open={open} onOpenChange={setOpen} $open={open}>
            <Collapsible.Content>
                <StyledRow>
                    <div>
                        <header>
                            <Text as="h3" variant="bold-4">
                                Creation
                            </Text>
                            <Text as="p" variant="regular-5">
                                This data will be collected as new records enter
                                the workflow.
                            </Text>
                        </header>

                        <StyledCards>
                            <ul>
                                {fields.map(({ field }, i, { length }) => (
                                    <BusinessObjectDefinitionField
                                        key={i}
                                        field={field}
                                        index={i}
                                        last={i === length - 1}
                                        validation={validateField(field)}
                                        onEdit={() => {
                                            setEditingField({ field, index: i })
                                        }}
                                        onMoveUp={() =>
                                            dispatch({
                                                type: 'fieldMoved',
                                                payload: {
                                                    index: i,
                                                    direction: 'up',
                                                },
                                            })
                                        }
                                        onMoveDown={() =>
                                            dispatch({
                                                type: 'fieldMoved',
                                                payload: {
                                                    index: i,
                                                    direction: 'down',
                                                },
                                            })
                                        }
                                        onRemove={() =>
                                            dispatch({
                                                type: 'fieldRemoved',
                                                payload: {
                                                    requiredBy: { init: true },
                                                    field,
                                                    index: i,
                                                },
                                            })
                                        }
                                        mode="create"
                                        hideBullet={!isLarge}
                                    />
                                ))}
                            </ul>

                            <IconTextButton
                                icon="Plus"
                                text="Add field"
                                variant="secondary"
                                ref={addFieldRef}
                                onClick={addFieldModal.openModal}
                                className="add-field-button"
                            />
                        </StyledCards>
                    </div>

                    <div>
                        <header>
                            <Text as="h3" variant="bold-4">
                                Label
                            </Text>
                            <Text as="p" variant="regular-5">
                                This is how a record will be identified in the
                                workflow.
                            </Text>
                        </header>

                        <StyledLabelInputContainer>
                            <StyledTextInput
                                value={label.value}
                                placeholder={
                                    label.isCustom
                                        ? `{{{ it.field_name }}}`
                                        : 'Add named fields to generate a label'
                                }
                                disabled={!label.isCustom}
                                onChange={({ currentTarget: { value } }) =>
                                    dispatch({
                                        type: 'labelEdited',
                                        payload: { value },
                                    })
                                }
                                onBlur={({ currentTarget: { value } }) =>
                                    dispatch({
                                        type: 'labelEdited',
                                        payload: { value },
                                    })
                                }
                                hasError={
                                    labelValidation.status ===
                                    ValidationStatus.Invalid
                                }
                            />
                            <Tooltip
                                content={
                                    label.isCustom
                                        ? 'Generate label'
                                        : 'Customise label'
                                }
                            >
                                <IconButton
                                    iconName={label.isCustom ? 'Sync' : 'Edit'}
                                    variant="primary"
                                    onClick={() => {
                                        dispatch({
                                            type: 'customLabelToggled',
                                        })
                                    }}
                                />
                            </Tooltip>
                        </StyledLabelInputContainer>
                        {labelValidation.status ===
                            ValidationStatus.Invalid && (
                            <p className="error">{labelValidation.message}</p>
                        )}
                    </div>
                </StyledRow>

                <ControlledModal
                    title="Collect data"
                    description="What type of data would you like to collect?"
                    {...addFieldModal}
                    onOpenChange={addFieldModal.setOpen}
                    renderContent={() => (
                        <AddFieldContent onFieldAdded={setAddingFieldType} />
                    )}
                    returnFocus={() => addFieldRef.current?.focus()}
                />

                <BusinessObjectDefinitionFieldEditor
                    onOpenChange={open => {
                        if (!open) {
                            setAddingFieldType(undefined)
                        }
                    }}
                    onSubmit={({ field }) => {
                        dispatch({
                            type: 'fieldAdded',
                            payload: {
                                requiredBy: { init: true },
                                field,
                            },
                        })
                        setAddingFieldType(undefined)
                        addFieldModal.closeModal()
                    }}
                    relationOptions={relationOptions}
                    userOptions={userOptions}
                    currencyOptions={currencyOptions}
                    {...(addingFieldType !== undefined
                        ? {
                              field: createNewField(addingFieldType),
                              validation:
                                  createPendingFieldValidation(addingFieldType),
                          }
                        : {
                              field: undefined,
                              validation: undefined,
                          })}
                />

                <BusinessObjectDefinitionFieldEditor
                    onOpenChange={open => {
                        if (!open) {
                            setEditingField(undefined)
                        }
                    }}
                    onSubmit={({ field }) => {
                        dispatch({
                            type: 'fieldEdited',
                            payload: {
                                requiredBy: { init: true },
                                field,
                                index: editingField?.index ?? 0,
                            },
                        })
                        setEditingField(undefined)
                    }}
                    relationOptions={relationOptions}
                    userOptions={userOptions}
                    currencyOptions={currencyOptions}
                    {...(editingField !== undefined
                        ? {
                              field: editingField.field,
                              validation: validateField(editingField.field),
                          }
                        : {
                              field: undefined,
                              validation: undefined,
                          })}
                />
            </Collapsible.Content>

            <Collapsible.Trigger asChild>
                <StyledCollapseButton
                    icon={open ? 'AngleUp' : 'AngleDown'}
                    text={open ? 'Hide Initial Fields' : 'Show Initial Fields'}
                    variant="secondary"
                    size="xSmall"
                />
            </Collapsible.Trigger>
        </StyledInitFields>
    )
}

const StyledInitFields = styled(Collapsible.Root)<{ $open: boolean }>`
    ${flex('column', 'space-between', 'flex-start')}
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    margin-bottom: 1rem;
    height: max-content;
    overflow-y: auto;

    @media (max-width: ${breakpoints.large}px) {
        ${({ $open }) => $open && 'flex: 1'};
    }

    @media (min-width: ${breakpoints.large}px) {
        margin-left: 5.24rem;
        display: block;
    }

    &[data-state='open'] {
        padding: 1rem;
    }

    &[data-state='closed'] {
        padding: 0.5rem;
    }

    > button {
        left: 50%;
        transform: translateX(-50%);
    }
`

const StyledCards = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media (min-width: ${breakpoints.large}px) {
        padding: 1rem;
    }

    > ul {
        ${flex('column', 'flex-start', 'flex-start')}
        width: 100%;
        gap: 0.5rem;

        > li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            width: 100%;
        }

        @media (max-width: ${breakpoints.large}px) {
            .field-card {
                overflow-x: scroll;
                gap: 0.25rem;

                > div {
                    flex: 1;
                    gap: 0.5rem;
                }

                > menu {
                    gap: 0.25rem;
                }
            }
        }
    }
`

const StyledRow = styled.div`
    ${flex('column', 'flex-start', 'flex-start')}
    gap: 1rem;

    > div {
        width: 100%;
    }

    .add-field-button {
        width: 100%;
    }

    @media (min-width: ${breakpoints.large}px) {
        ${flex('row', 'space-around', 'flex-start')}

        > div {
            width: 50%;
        }

        .add-field-button {
            width: inherit;
        }
    }
`

const StyledTextInput = styled(TextInput)`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    &[data-disabled='true'] {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    }
    &[data-disabled='true'] definition {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const StyledLabelInputContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
    margin-top: 0.55rem;
`

const StyledCollapseButton = styled(IconTextButton)`
    width: 15rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
`
