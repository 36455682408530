import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    from,
} from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { gqlUri } from 'Adapters/Apollo/config'
import { useSandbox } from 'Hooks/useSandbox'
import { useSetIdentity } from 'Hooks/useSetIdentity'
import { FC, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ConvertSandboxUser: FC = () => {
    const [params] = useSearchParams()
    const { getIdTokenClaims } = useAuth0()
    const navigate = useNavigate()
    const sandbox = useSandbox()

    const token = params.get('token')
    const returnTo = params.get('pathname') ?? '/'

    const { setIdentity } = useSetIdentity({
        client: new ApolloClient({
            link: from([
                new ApolloLink((operation, forward) => {
                    operation.setContext(() => ({
                        headers: {
                            Authorization: `Bearer ${token}`,
                            clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
                        },
                    }))
                    return forward(operation)
                }),
                new HttpLink({ uri: gqlUri }),
            ]),
            cache: new InMemoryCache(),
        }),
    })

    // will run only once on mount, after which the triggered navigation will unmount this component
    useEffect(() => {
        async function convertSandboxUser() {
            if (!token) {
                // user cannot rectify, let this bubble up to the error boundary
                throw new Error('No token found in URL')
            }
            const auth0Token = await getIdTokenClaims()

            if (!auth0Token?.sub) {
                // user cannot rectify, let this bubble up to the error boundary
                throw new Error('No sub property on auth0 token')
            }

            if (!auth0Token?.email) {
                // user cannot rectify, let this bubble up to the error boundary
                throw new Error('No email property on auth0 token')
            }

            // update user identity on the BE
            await setIdentity({
                identityId: auth0Token.sub,
                email: auth0Token.email,
            })

            // disable sandbox, so banners/restrictions are no longer visible
            if (sandbox.state === 'active') {
                sandbox.disable()
            }

            // clear local storage to remove sandbox user ID and token
            localStorage.clear()

            // navigate to the previous location
            navigate(returnTo, { replace: true })
        }

        convertSandboxUser()
    }, [getIdTokenClaims, navigate, returnTo, sandbox, setIdentity, token])

    return null
}
