import dayjs from 'dayjs'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { ProcessSingle_CardQuery } from '../__generated__/q'

const useCriteriaCounts = (obj: ProcessSingle_CardQuery['businessObject']) => {
    const { delegateProcesses, fieldCriteria } = useMemo(() => {
        const init = {
            delegateProcesses: { total: 0, incomplete: 0 },
            fieldCriteria: { total: 0, incomplete: 0 },
        }
        return (
            obj.onProcess?.onPhase.phase.criteria.reduce(
                (acc: typeof init, criterion) =>
                    match(criterion)
                        .with(
                            { __typename: 'ProcessDelegatePhaseCriterion' },
                            criterion => ({
                                ...acc,
                                delegateProcesses: {
                                    total:
                                        acc.delegateProcesses.total +
                                        (criterion.businessObjectOnCriterion
                                            ?.isApplicable
                                            ? 1
                                            : 0),
                                    incomplete:
                                        acc.delegateProcesses.incomplete +
                                        (criterion.businessObjectOnCriterion
                                            ?.isApplicable &&
                                        !criterion.businessObjectOnCriterion
                                            ?.isCompleted
                                            ? 1
                                            : 0),
                                },
                            })
                        )
                        .with(
                            { __typename: 'FieldConditionPhaseCriterion' },
                            criterion => {
                                return {
                                    ...acc,
                                    fieldCriteria: {
                                        total:
                                            acc.fieldCriteria.total +
                                            (criterion.businessObjectOnCriterion
                                                ?.isApplicable
                                                ? 1
                                                : 0),
                                        incomplete:
                                            acc.fieldCriteria.incomplete +
                                            (criterion.businessObjectOnCriterion
                                                ?.isApplicable &&
                                            !criterion.businessObjectOnCriterion
                                                ?.isCompleted
                                                ? 1
                                                : 0),
                                    },
                                }
                            }
                        )
                        .otherwise(() => init),
                init
            ) ?? init
        )
    }, [obj])

    const actions = useMemo(() => {
        const init = {
            total: 0,
            incomplete: 0,
            overdue: 0,
            incompleteActionIds: [] as string[],
        }
        return (
            obj.onProcess?.onPhase.actions.reduce<{
                total: number
                incomplete: number
                overdue: number
                incompleteActionIds: string[]
            }>(
                (acc, action) =>
                    match(action)
                        .with(
                            { __typename: 'IncompleteAction' },
                            ({ dueDate }) => ({
                                total: acc.total + 1,
                                incomplete: acc.incomplete + 1,
                                overdue: dayjs(dueDate).isBefore(dayjs(), 'day')
                                    ? acc.overdue + 1
                                    : acc.overdue,
                                incompleteActionIds: [
                                    ...acc.incompleteActionIds,
                                    action.id,
                                ],
                            })
                        )
                        .otherwise(() => ({
                            ...acc,
                            total: acc.total + 1,
                            incompleteActionIds: [...acc.incompleteActionIds],
                        })),
                init
            ) ?? init
        )
    }, [obj])

    const canMoveForward = !(
        obj.onProcess?.onPhase.phase.isTerminalPhase ||
        actions.incomplete +
            fieldCriteria.incomplete +
            delegateProcesses.incomplete
    )

    return { delegateProcesses, fieldCriteria, actions, canMoveForward }
}

export { useCriteriaCounts }
