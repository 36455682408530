import { styled } from 'Adapters/Freestyled'
import { Variant } from 'Components/Button/IconButton/types'
import { ClickLinkList } from 'Components/ClickLinkList/ClickLinkList'
import { Markdown } from 'Components/Markdown'
import { Select } from 'Components/Select'
import { bold1, regular4 } from 'Components/Text'
import { TutorialModalDesktop, TutorialModalMobile } from 'Features/Tutorials'
import { useResponder } from 'Hooks'
import { FC, useState } from 'react'

type Props = {
    markdown: Record<
        string,
        { markdown: string; attributes: Record<string, string> }
    >
    buttonVariant?: Variant
}

export const Tutorial: FC<Props> = ({
    markdown,
    buttonVariant = 'default',
}) => {
    const slides = Object.values(markdown).map(({ attributes, markdown }) => ({
        title: attributes.title,
        content: markdown,
    }))

    const [selectedSlide, setSelectedSlide] = useState(0)

    const { isLarge } = useResponder()

    if (isLarge) {
        return (
            <TutorialModalDesktop
                title={'Workflows'}
                content={
                    <StyledProcessesTutorialContent>
                        <StyledClickLinkList
                            items={slides.map((slide, index) => ({
                                key: index.toString(),
                                heading: slide.title,
                                name: slide.title,
                                onClick: () => setSelectedSlide(index),
                                isActive: selectedSlide === index,
                            }))}
                        />

                        <StyledSlide>
                            <Markdown>{slides[selectedSlide].content}</Markdown>
                        </StyledSlide>
                    </StyledProcessesTutorialContent>
                }
                buttonVariant={buttonVariant}
            />
        )
    }

    return (
        <TutorialModalMobile
            content={
                <StyledSlide>
                    <div className="select-wrapper">
                        <Select
                            id={'slide'}
                            onValueChange={val => {
                                setSelectedSlide(Number(val))
                            }}
                            name={'slide'}
                            options={slides.map((slide, index) => ({
                                text: slide.title,
                                value: index.toString(),
                            }))}
                            value={selectedSlide.toString()}
                        />
                    </div>

                    <Markdown>{slides[selectedSlide].content}</Markdown>
                </StyledSlide>
            }
        />
    )
}

const StyledProcessesTutorialContent = styled.section`
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100vw;
    max-width: 65rem;
    height: 55rem;
    max-height: 90vh;
    overflow: none;
`

const StyledSlide = styled.div`
    padding: 0 1rem;
    overflow: auto;
    max-height: 90vh;
    padding-bottom: 2rem;

    h1 {
        ${bold1};
        padding-bottom: 0.75rem;
    }

    p {
        ${regular4};
        padding-bottom: 0.5rem;
    }

    img {
        transform: translateX(-50%);
        margin-left: 50%;
        max-height: 25rem;
    }

    .select-wrapper {
        padding: 0.5rem 0 1rem 0;
    }

    ul {
        padding-left: 1rem;
        padding-bottom: 1rem;
        list-style-type: initial;

        > li {
            list-style-type: initial;
        }
    }

    code {
        background-color: black;
        color: white;
        padding: 0.1rem;
        padding-bottom: 0.2rem;
        border-radius: 0.4rem;
    }
`

const StyledClickLinkList = styled(ClickLinkList)`
    overflow: auto;
`
