import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { ActionCard } from 'Features/ActionView/ActionCard'
import { FC } from 'react'
import { Group } from './types'

type Props = {
    col: number
    row: number
    group: Group
}

const ActionGroup: FC<Props> = ({ col, row, group }) => (
    <>
        {col === 1 && group.label !== 'all' && (
            <StyledHeader
                $color={group.color}
                style={{ gridRow: `${row} / ${row + 1}` }}
            >
                <Text as="h6" variant="bold-5">
                    {group.label}
                </Text>
            </StyledHeader>
        )}
        <Styled
            $color={group.color}
            style={{
                gridColumn: `${col} / ${col + 1}`,
                gridRow: `${row + 1} / ${row + 2}`,
            }}
        >
            <ul>
                {group.actions.map(({ id }, i) => (
                    <li key={`${id}-${i}`}>
                        <ActionCard id={id} />
                    </li>
                ))}
            </ul>
        </Styled>
    </>
)

const Styled = styled.section<{ $color: string }>`
    overflow-x: scroll;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-left: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ $color }) => `${$color}11`};

    > ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

const StyledHeader = styled.header<{ $color: string }>`
    grid-column: 1 / -1;
    border-left: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ $color }) => `${$color}11`};
    /* color: ${({ $color }) => $color}; */

    padding: 0.5rem 1rem;
`

export { ActionGroup }
