import { hasShortCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { BizObjectDef_CompleteFragmentDoc } from 'Fragments/__generated__/BusinessObjectDefinition'
import { removeAt, replaceEmptyString } from 'Utils'
import {
    CreateBusinessObjectDefinitionInput,
    Extendable,
    FieldType,
} from '__generated__'
import { compact, omit, uniq } from 'lodash'
import { FormEvent, ReactEventHandler, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import {
    CreateBusinessObjectDefinitionMutation,
    useCreateBusinessObjectDefinitionExtensionMutation,
    useCreateBusinessObjectDefinitionMutation,
} from './__generated__/mutation'
import {
    blankField,
    documentsFieldMutationVar,
    updatesFieldMutationVar,
} from './helpers'
import { Fields, State } from './types'

/**
 * Traverse the template and remove all __typename properties, because otherwise GQL gets very
 * annoyed when we send them back
 */
const fieldStateToMutationVars = (
    field: Fields
): CreateBusinessObjectDefinitionInput['fields'][number] => {
    if (field.type === 'list') {
        if (!field.listOf) {
            throw new Error('Must select a type for list field')
        }
        const {
            type: listOfType,
            name: listOfName,
            description: listOfDescription,
            ...listOf
        } = field.listOf
        return {
            name: field.name,
            description: replaceEmptyString(field.description),
            [field.type]: {
                listOf: {
                    name: listOfName,
                    [listOfType]: {
                        ...listOf,
                    },
                },
            },
        }
    }
    return {
        name: field.name,
        description: replaceEmptyString(field.description ?? ''),
        [field.type]: omit(field, 'name', 'description', 'type'),
    }
}

const stateToMutationVars = (
    state: State,
    {
        updatesEnabled,
        documentsEnabled,
    }: { updatesEnabled: boolean; documentsEnabled: boolean }
): CreateBusinessObjectDefinitionInput => ({
    name: state.name,
    label: state.label,
    description: replaceEmptyString(state.description),
    fields: compact([
        ...state.fields.map(fieldStateToMutationVars),
        updatesEnabled ? updatesFieldMutationVar : null,
        documentsEnabled ? documentsFieldMutationVar : null,
    ]),
})

const blankState = {
    name: '',
    description: '',
    label: '',
    fields: [],
}

export const useCreateBusinessObjectDefinition = (
    extensionOf?: 'action',
    onCompleted?: (data: CreateBusinessObjectDefinitionMutation) => void
) => {
    const [state, setState] = useState<State>(blankState)
    const [updatesEnabled, setUpdatesEnabled] = useState(false)
    const [documentsEnabled, setDocumentsEnabled] = useState(false)

    const setStateValue =
        <
            T extends Exclude<
                keyof CreateBusinessObjectDefinitionInput,
                'fields'
            >
        >(
            key: T
        ) =>
        (value: State[T]) => {
            setState(prev => ({
                ...prev,
                [key]: value,
            }))
        }

    const removeField =
        (index: number): ReactEventHandler =>
        () => {
            setState(s => ({
                ...s,
                fields: removeAt(s.fields, index),
            }))
        }

    const addField = (type: FieldType) => () => {
        setState(s => ({
            ...s,
            fields: [...s.fields, blankField(type)],
        }))
    }

    const resetState = () => setState(blankState)

    const navigate = useNavigate()
    const errorToast = useTriggerToast()
    const [errors, setErrors] = useState<string[]>([])

    const [createBodMutation] = useCreateBusinessObjectDefinitionMutation({
        onCompleted:
            onCompleted ||
            (data => {
                navigate(
                    slugify(data.createBusinessObjectDefinition?.name ?? '', {
                        lower: true,
                    })
                )
            }),
        onError: e => {
            e.graphQLErrors.forEach(error => {
                if (!hasShortCustomErrorExtenstions(error.extensions)) {
                    setErrors(prev => uniq([...prev, error.message]))
                    return
                }

                const message = error.extensions.errors.message

                if (typeof message === 'string') {
                    setErrors(prev => uniq([...prev, message]))
                    return
                }

                if (Array.isArray(message)) {
                    message.forEach(errorMessage => {
                        setErrors(prev => uniq([...prev, errorMessage]))
                    })
                    return
                }
            })
            logger.error('Could not create business object definition error', e)
            errorToast.triggerToast()
        },
        update(cache, { data }) {
            try {
                cache.modify({
                    fields: {
                        businessObjectDefinitions(existing = []) {
                            const newBizObjRef = cache.writeFragment({
                                data: data?.createBusinessObjectDefinition,
                                fragment: BizObjectDef_CompleteFragmentDoc,
                                fragmentName: 'BizObjectDef_Complete',
                            })
                            return [...existing, newBizObjRef]
                        },
                    },
                })
            } catch (err) {
                logger.error(
                    'Could not update cache after creating definition',
                    err as Error
                )
                throw err
            }
        },
    })

    const [createBodExtensionMutation] =
        useCreateBusinessObjectDefinitionExtensionMutation({
            onError: () => {
                errorToast.triggerToast()
            },
            refetchQueries: ['ActionExtensionObjects'],
        })

    const createBusinessObjectDefinition = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            setErrors([])
            if (extensionOf) {
                createBodExtensionMutation({
                    variables: {
                        input: {
                            ...omit(
                                stateToMutationVars(state, {
                                    updatesEnabled,
                                    documentsEnabled,
                                }),
                                ['name', 'label']
                            ),
                            extensionOf: Extendable.Action,
                        },
                    },
                    refetchQueries: [
                        'OnboardingProgress',
                        'ActionExtensionObjects',
                    ],
                })
            } else {
                createBodMutation({
                    variables: {
                        input: stateToMutationVars(state, {
                            updatesEnabled,
                            documentsEnabled,
                        }),
                    },
                    refetchQueries: [
                        'OnboardingProgress',
                        'ActionExtensionObjects',
                    ],
                })
            }
        },
        [
            createBodMutation,
            createBodExtensionMutation,
            state,
            updatesEnabled,
            documentsEnabled,
            extensionOf,
        ]
    )

    return {
        createBusinessObjectDefinition,
        errorToast,
        state,
        setState,
        setStateValue,
        resetState,
        addField,
        removeField,
        updatesEnabled,
        setUpdatesEnabled,
        errors,
        documentsEnabled,
        setDocumentsEnabled,
    }
}
