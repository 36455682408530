import { Select } from 'Components/Select'
import { useOrganisationUsers } from 'Hooks'
import { FieldCommonWrapper } from './Common'
import { RequiredConstraint } from './Constraints/Required'
import { Field } from './Field'
import { FieldProps } from './types'

export const UserFieldDefinitionInput: React.FC<FieldProps<'user'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    const { users } = useOrganisationUsers()

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-${index}-default`} name="Default value">
                <Select
                    id={`bod-field-${index}-default`}
                    value={field.defaultValue ?? ''}
                    onValueChange={value =>
                        onChange('defaultValue')(() =>
                            value ? value : undefined
                        )
                    }
                    name="Default user"
                    options={[
                        {
                            text: '-- no default user --',
                            value: '',
                        },
                        ...users.map(user => ({
                            text: user.isCurrentUser ? 'Me' : user.name,
                            value: user.id,
                        })),
                    ]}
                    disabled={!users.length}
                />
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
        </FieldCommonWrapper>
    )
}
