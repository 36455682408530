import { hasShortCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { BizObjectDef_CompleteFragmentDoc } from 'Fragments/__generated__/BusinessObjectDefinition'
import { waitForDelay } from 'Utils'
import { sanitiseTemplateSourceData } from 'Utils/BusinessObjectDefinition'
import { noop, uniq } from 'lodash'
import { useCallback, useReducer, useState } from 'react'
import { P, match } from 'ts-pattern'
import {
    BusinessObjectDefinitionsCreatorQuery,
    useBusinessObjectDefinitionsCreatorSuggestionLazyQuery,
    useCreateBusinessObjectDefinitionMutation,
} from '../__generated__/q'
import {
    handleAddField,
    handleChangePrompt,
    handleChoosePath,
    handleCloseAddField,
    handleCloseCreator,
    handleCloseFieldEditor,
    handleMoveField,
    handleOpenAddField,
    handleOpenCreator,
    handleOpenFieldEditor,
    handleRemoveField,
    handleRestart,
    handleSelectExisting,
    handleSelectTemplate,
    handleSetDescription,
    handleSetField,
    handleSetLabel,
    handleSetName,
    handleSetStatus,
    handleStartFromScratch,
    handleTakeSuggestion,
    handleToggleCustomLabel,
    handleToggleEnableDocuments,
    handleToggleEnableUpdates,
    handleToggleLoadingSuggestion,
} from './handle'
import { makeInit } from './makeInit'
import { makeMutationVariables } from './makeMutationVariables'
import { CreatorAction, State, Status } from './types'

const reducer =
    (data: BusinessObjectDefinitionsCreatorQuery) =>
    (state: State, action: CreatorAction): State => {
        const next = match(action)
            .with({ type: 'openCreator' }, handleOpenCreator(data)(state))
            .with({ type: 'closeCreator' }, handleCloseCreator(data)(state))
            .with({ type: 'restart' }, handleRestart(data)(state))
            .with({ type: 'selectTemplate' }, handleSelectTemplate(state))
            .with({ type: 'selectExisting' }, handleSelectExisting(state))
            .with({ type: 'changePrompt' }, handleChangePrompt(state))
            .with(
                { type: 'toggleLoadingSuggestion' },
                handleToggleLoadingSuggestion(state)
            )
            .with({ type: 'takeSuggestion' }, handleTakeSuggestion(state))
            .with({ type: 'startFromScratch' }, handleStartFromScratch(state))
            .with({ type: 'toggleCustomLabel' }, handleToggleCustomLabel(state))
            .with({ type: 'setName' }, handleSetName(state))
            .with({ type: 'setDescription' }, handleSetDescription(state))
            .with({ type: 'setLabel' }, handleSetLabel(state))
            .with({ type: 'openFieldEditor' }, handleOpenFieldEditor(state))
            .with({ type: 'closeFieldEditor' }, handleCloseFieldEditor(state))
            .with({ type: 'openAddField' }, handleOpenAddField(state))
            .with({ type: 'closeAddField' }, handleCloseAddField(state))
            .with({ type: 'addField' }, handleAddField(state))
            .with({ type: 'removeField' }, handleRemoveField(state))
            .with({ type: 'moveField' }, handleMoveField(state))
            .with({ type: 'setField' }, handleSetField(state))
            .with(
                { type: 'toggleEnableUpdates' },
                handleToggleEnableUpdates(state)
            )
            .with(
                { type: 'toggleEnableDocuments' },
                handleToggleEnableDocuments(state)
            )
            .with({ type: 'setStatus' }, handleSetStatus(state))
            .with({ type: 'choosePath' }, handleChoosePath(state))
            .exhaustive()
        return next
    }

const useCreator = ({
    data,
    onComplete,
}: {
    data: BusinessObjectDefinitionsCreatorQuery
    onComplete?: (id: string) => void
}) => {
    const { triggerToast: triggerSuggestionToast, ...suggestionToastProps } =
        useTriggerToast()
    const { triggerToast: triggerCreateToast, ...createToastProps } =
        useTriggerToast()
    const [state, dispatch] = useReducer(reducer(data), makeInit(data))

    const [query] = useBusinessObjectDefinitionsCreatorSuggestionLazyQuery()
    const [create] = useCreateBusinessObjectDefinitionMutation()

    const getSuggestion = useCallback(async () => {
        dispatch({ type: 'toggleLoadingSuggestion', payload: undefined })
        const { data } = await query({
            variables: { input: { prompt: state.suggestionPrompt } },
        })

        match(data)
            .with(P.nullish, () => {
                triggerSuggestionToast()
                dispatch({
                    type: 'toggleLoadingSuggestion',
                    payload: undefined,
                })
            })
            .otherwise(({ businessObjectDefinitionSuggestion: suggestion }) => {
                dispatch({
                    type: 'takeSuggestion',
                    payload: {
                        suggestion: sanitiseTemplateSourceData(suggestion),
                    },
                })
            })
    }, [query, state, triggerSuggestionToast])

    const [errorMessages, setErrorMessages] = useState<string[]>([])

    const saveDefinition = useCallback(async () => {
        setErrorMessages([])
        dispatch({ type: 'setStatus', payload: { status: Status.Saving } })
        await create({
            variables: makeMutationVariables(state),
            onCompleted: async data => {
                dispatch({
                    type: 'setStatus',
                    payload: { status: Status.Success },
                })
                dispatch({ type: 'closeCreator', payload: undefined })
                match(data.createBusinessObjectDefinition?.id)
                    .with(P.string, id => {
                        onComplete?.(id)
                    })
                    .otherwise(noop)
            },
            onError: async e => {
                e.graphQLErrors.forEach(error => {
                    if (!hasShortCustomErrorExtenstions(error.extensions)) {
                        setErrorMessages(prev => uniq([...prev, error.message]))
                        return
                    }

                    const message = error.extensions.errors.message

                    if (typeof message === 'string') {
                        setErrorMessages(prev =>
                            uniq([
                                ...prev,
                                message.includes('already exists')
                                    ? 'A dataset with that name already exists'
                                    : message,
                            ])
                        )
                        return
                    }

                    if (Array.isArray(message)) {
                        message.forEach(errorMessage => {
                            setErrorMessages(prev =>
                                uniq([
                                    ...prev,
                                    errorMessage.includes('already exists')
                                        ? 'A dataset with that name already exists'
                                        : errorMessage,
                                ])
                            )
                        })
                        return
                    }
                })

                dispatch({
                    type: 'setStatus',
                    payload: { status: Status.Error },
                })
                triggerCreateToast()
                await waitForDelay(2000)
                dispatch({
                    type: 'setStatus',
                    payload: { status: Status.Idle },
                })
            },
            update(cache, { data }) {
                try {
                    cache.modify({
                        fields: {
                            businessObjectDefinitions(existing = []) {
                                const newBizObjRef = cache.writeFragment({
                                    data: data?.createBusinessObjectDefinition,
                                    fragment: BizObjectDef_CompleteFragmentDoc,
                                    fragmentName: 'BizObjectDef_Complete',
                                })
                                return [...existing, newBizObjRef]
                            },
                        },
                    })
                } catch (err) {
                    logger.error(
                        'Could not update cache after creating definition',
                        err as Error
                    )
                    throw err
                }
            },
            refetchQueries: ['OnboardingProgress'],
        })
    }, [state, triggerCreateToast, create, onComplete])

    return {
        state,
        dispatch,
        getSuggestion,
        suggestionToastProps,
        createToastProps,
        saveDefinition,
        errorMessages,
    }
}

export { useCreator }
