import { useMemo } from 'react'
import { TelephoneFieldConstraints } from '__generated__'
import { defaultConstraints, TelephoneConstraintsMap } from './types'

type Params = {
    telephoneConstraints: TelephoneFieldConstraints[]
}

export const useTelephoneConstraints = ({ telephoneConstraints }: Params) => {
    const constraints = useMemo(() => {
        return telephoneConstraints.reduce<TelephoneConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'RequiredConstraint') {
                    return { ...out, required: true }
                }

                return out
            },
            defaultConstraints
        )
    }, [telephoneConstraints])

    const constraintsTooltip = useMemo(() => {
        if (constraints.required) return 'Required'
        return ''
    }, [constraints])

    return { constraints, constraintsTooltip }
}
