import { flex, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { AnimatePresence } from 'framer-motion'
import { FC, ReactEventHandler, SetStateAction } from 'react'
import { setAt } from 'Utils'
import { FieldType } from '__generated__'
import { Fields, State } from '../types'
import { AddFieldButtons } from './AddFieldButtons'
import { CreateBusinessObjectDefFields } from './CreateBusinessObjectDefFields'
import { EditorErrors } from './EditorErrors'

type Props = {
    fields: Fields[]
    addField: (type: FieldType) => () => void
    removeField: (index: number) => ReactEventHandler
    extensionOf?: 'action' | undefined
    errors: string[]
    setState: (value: SetStateAction<State>) => void
}

export const DataFields: FC<Props> = ({
    fields,
    addField,
    removeField,
    extensionOf,
    errors,
    setState,
}) => {
    return (
        <>
            <StyledHeader>
                <Text as="h2" variant="bold-1">
                    Data fields
                </Text>
            </StyledHeader>

            <AnimatePresence initial={false}>
                {fields.map((field, index) => (
                    <CreateBusinessObjectDefFields
                        key={`${field.type}-${index}`}
                        field={field}
                        index={index}
                        disableRequiredConstraint={!!extensionOf}
                        onFieldChange={index => prop => setter =>
                            setState(s => ({
                                ...s,
                                fields: setAt(s.fields, index, prop, setter),
                            }))}
                        onFieldRemoved={removeField(index)}
                    />
                ))}
            </AnimatePresence>

            <AddFieldButtons onClick={addField} />

            <EditorErrors errors={errors} />
        </>
    )
}

const StyledHeader = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.25rem;
    padding-bottom: 0.5rem;
`
