import { styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { ComponentProps, FC, useRef, useState } from 'react'

type Props = Pick<
    ComponentProps<typeof ControlledModal>,
    'title' | 'description' | 'hideDescription' | 'fullScreen'
> & {
    renderContent: (triggerConfirmation: () => void) => JSX.Element
    confirmationDescription: string
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    trigger: Partial<
        Pick<
            ComponentProps<typeof IconTextButton>,
            'icon' | 'text' | 'variant' | 'size' | 'disabled'
        >
    >
    navStyleButton?: true
}

const CreatorModal: FC<Props> = ({
    trigger,
    open,
    setOpen,
    renderContent,
    title,
    description,
    hideDescription,
    confirmationDescription,
    fullScreen,
    navStyleButton,
}) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [confirmationOpen, setConfirmationOpen] = useState(false)

    return (
        <>
            {navStyleButton ? (
                <>
                    <StyledDivider />
                    <StyledNavStyleTrigger
                        ref={ref}
                        onClick={() => setOpen(true)}
                    >
                        <Icon name="Plus" />

                        <div className="text">
                            <Text as="p" variant="bold-4">
                                Create new dataset
                            </Text>
                        </div>

                        <Icon name="CarrotRight" />
                    </StyledNavStyleTrigger>
                </>
            ) : (
                <IconTextButton
                    ref={ref}
                    text="Create"
                    icon="Plus"
                    {...trigger}
                    variant={trigger.variant ?? 'primary'}
                    size={trigger.size ?? 'xSmall'}
                    onClick={() => setOpen(true)}
                />
            )}
            <ControlledModal
                open={open}
                onOpenChange={open =>
                    open ? setOpen(true) : setConfirmationOpen(true)
                }
                title={title}
                description={description}
                hideDescription={hideDescription}
                renderContent={() =>
                    renderContent(() => setConfirmationOpen(true))
                }
                returnFocus={() => ref.current?.focus()}
                fullScreen={fullScreen}
            />
            <ControlledModal
                open={confirmationOpen}
                onOpenChange={setConfirmationOpen}
                title="Are you sure?"
                description={confirmationDescription}
                renderContent={() => (
                    <StyledConfirmation>
                        <TextButton
                            text="Cancel"
                            variant="secondary"
                            onClick={() => setConfirmationOpen(false)}
                            size="small"
                        />
                        <TextButton
                            text="Confirm"
                            onClick={() => {
                                setOpen(false)
                                setConfirmationOpen(false)
                            }}
                            size="small"
                        />
                    </StyledConfirmation>
                )}
                returnFocus={() => {
                    if (!open) ref.current?.focus()
                }}
            />
        </>
    )
}

const StyledConfirmation = styled.footer`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-top: 1rem;

    button {
        flex: 1;
    }
`

const StyledDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    margin: 0.5rem 0;
`

const StyledNavStyleTrigger = styled.button`
    display: grid;
    grid-template-columns: 1.5rem auto 1.5rem;
    grid-template-rows: 1fr;
    align-items: center;
    grid-gap: 1rem;
    padding-right: 0.5rem;
    padding-left: 1rem;
    width: 100%;
    height: 4.5rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    transition: background-color ease
        ${({ theme }) => theme.animation.buttonTransitionDuration};
    min-height: 0;
    min-width: 0;

    .text {
        overflow: hidden;
        min-width: 0;

        p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
            transition: color ease
                ${({ theme }) => theme.animation.buttonTransitionDuration};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            text-align: left;
        }
    }

    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
        transition: fill ease
            ${({ theme }) => theme.animation.buttonTransitionDuration};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }
`

export { CreatorModal }
