import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import {
    CurrencyFieldValidation,
    CurrencyField as Field,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: CurrencyFieldValidation
    onSubmit: OnSubmit<Field, CurrencyFieldValidation>
    onClose: () => void
    currencyOptions: {
        value: string
        text: string
    }[]

    hideRequired: boolean
}

const CurrencyField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,
    currencyOptions,

    hideRequired = false,
}) => {
    const [state, setState] = useState<State<Field, CurrencyFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <CurrencySubFields
                defaultValue={{
                    code: {
                        value: state.field.defaultValue?.currencyCode ?? '',
                        onChange: currencyCode => {
                            setState(prev => ({
                                ...prev,
                                field: {
                                    ...prev.field,
                                    defaultValue: {
                                        ...prev.field.defaultValue,
                                        currencyCode,
                                    },
                                },
                            }))
                        },
                    },
                    amount: {
                        value: state.field.defaultValue?.amount ?? '',
                        onChange: amount => {
                            setState(prev => ({
                                ...prev,
                                field: {
                                    ...prev.field,
                                    defaultValue: {
                                        ...prev.field.defaultValue,
                                        amount: amount ?? undefined,
                                    },
                                },
                            }))
                        },
                    },
                }}
                currencyOptions={currencyOptions}
            />

            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type CurrencySubFieldsProps = {
    defaultValue: {
        code: {
            value: string
            onChange: (value: string) => void
        }
        amount: {
            value: number | string
            onChange: (value: number | undefined) => void
        }
    }
    currencyOptions: {
        value: string
        text: string
    }[]
}

const CurrencySubFields: FC<CurrencySubFieldsProps> = ({
    defaultValue,
    currencyOptions,
}) => {
    const { current: codeFieldId } = useRef(v4())
    const { current: amountFieldId } = useRef(v4())

    return (
        <>
            <InputWrapper
                label="Default value"
                fieldset
                description="If set this will be the default value when a new record is created."
            >
                <StyledDefaultWrapper>
                    <VisuallyHidden>
                        <label htmlFor={codeFieldId}>
                            Default currency code
                        </label>
                    </VisuallyHidden>
                    <Select
                        name={codeFieldId}
                        id={codeFieldId}
                        options={[
                            {
                                text: '--',
                                value: '',
                            },
                            ...currencyOptions,
                        ]}
                        value={defaultValue.code.value}
                        onValueChange={defaultValue.code.onChange}
                    />
                    <VisuallyHidden>
                        <label htmlFor={amountFieldId}>Default amount</label>
                    </VisuallyHidden>
                    <NumberInput
                        id={amountFieldId}
                        value={defaultValue.amount.value}
                        placeholder="Enter a default amount"
                        step={0.01}
                        onChange={({ currentTarget: { value } }) => {
                            defaultValue.amount.onChange(
                                Number(value) ?? undefined
                            )
                        }}
                    />
                </StyledDefaultWrapper>
            </InputWrapper>
        </>
    )
}

const StyledDefaultWrapper = styled.div`
    display: flex;
    gap: 1rem;

    > button {
        width: 8rem;
    }

    div:last-child {
        flex: 1;
    }
`

export { CurrencyField, CurrencySubFields }
