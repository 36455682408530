import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { AddDocumentButtons } from './AddDocumentButtons'
import { DocumentDisplay } from './DocumentDisplay'
import { useGetDocumentsFromBusinessObject } from './useGetDocumentsFromBusinessObject'

type Props = {
    businessObjectId: string
}

const Documents: FC<Props> = ({ businessObjectId }) => {
    const result = useGetDocumentsFromBusinessObject({ businessObjectId })

    return (
        <SmallScreenWarning
            element={match(result)
                .with({ hasInitiallyLoaded: false }, () => <CenteredSpinner />)
                .with({ documents: [], fields: P._ }, ({ fields }) => (
                    <StyledDocumentsTab>
                        <AddDocumentButtons
                            fields={fields}
                            businessObjectId={businessObjectId}
                        />
                        <p>
                            <em>
                                This record doesn't have any uploaded documents.
                            </em>
                        </p>
                    </StyledDocumentsTab>
                ))
                .with({ documents: P._ }, ({ documents, fields }) => (
                    <StyledDocumentsTab>
                        <AddDocumentButtons
                            fields={fields}
                            businessObjectId={businessObjectId}
                        />
                        <StyledDocumentsList>
                            {documents.map((document, i) => (
                                <DocumentDisplay document={document} key={i} />
                            ))}
                        </StyledDocumentsList>
                    </StyledDocumentsTab>
                ))
                .exhaustive()}
        />
    )
}

const StyledDocumentsTab = styled.div`
    padding: 2rem 3rem 0;
    flex: 1;
    overflow: auto;

    > p {
        text-align: center;
    }
`

const StyledDocumentsList = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1.5rem;
    width: 100%;
    flex-wrap: wrap;
`

export { Documents }
