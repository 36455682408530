import { match } from 'ts-pattern'
import { setAt } from 'Utils'
import { Operator, ValueSelectorType } from '__generated__'
import {
    mapComparatorsToConditions,
    mapComparatorToInputComparator,
    mapConditionsToInputConditions,
} from './helpers'
import {
    AddActionCriterion,
    AddFieldConditionCriterion,
    AddProcessDelegateCriterion,
    AddProcessFanoutCriterion,
    RenameCriterion,
    SetDescription,
    SetName,
    State,
    UpdateCriterionConditions,
    UpdateCriterionDueTime,
} from './types'

export const handleShowDetailsStep = (prev: State) => (): State => ({
    ...prev,
    step: 'details',
})

export const handleShowPhasesStep = (prev: State) => (): State => ({
    ...prev,
    step: 'phases',
})

export const handleSetName =
    (prev: State) =>
    ({ payload: { name } }: SetName): State => ({
        ...prev,
        process: {
            ...prev.process,
            name,
        },
        operations: [
            ...prev.operations.filter(o => !o.updateName),
            {
                updateName: {
                    nextValue: name,
                    previousValue: prev.initial.name,
                },
            },
        ],
    })

export const handleSetDescription =
    (prev: State) =>
    ({ payload: { description } }: SetDescription): State => ({
        ...prev,
        process: {
            ...prev.process,
            description,
        },
        operations: [
            ...prev.operations.filter(o => !o.updateDescription),
            {
                updateDescription: {
                    nextValue: description,
                    previousValue: prev.initial.description,
                },
            },
        ],
    })

export const handleAddActionCriterion =
    (prev: State) =>
    ({
        payload: { phaseIndex, description, defaultDueSeconds, conditions },
    }: AddActionCriterion): State => {
        return {
            ...prev,
            process: {
                ...prev.process,
                phases: prev.process.phases.map((p, i) =>
                    i === phaseIndex
                        ? {
                              ...p,
                              criteria: [
                                  ...p.criteria,
                                  {
                                      __typename: 'ActionPhaseCriterion',
                                      description,
                                      defaultDueSeconds,
                                      conditions:
                                          mapComparatorsToConditions(
                                              conditions
                                          ),
                                  },
                              ],
                          }
                        : p
                ),
            },
            operations: [
                ...prev.operations,
                {
                    addPhaseCriterion: {
                        nextValue: {
                            action: {
                                description,
                                defaultDueSeconds,
                                conditions: conditions.map(
                                    mapComparatorToInputComparator
                                ),
                            },
                        },
                        phaseIndex,
                        previousCriteria:
                            prev.process.phases[phaseIndex].criteria.length,
                    },
                },
            ],
        }
    }

export const handleAddFieldConditionCriterion =
    (prev: State) =>
    ({
        payload: { phaseIndex, fieldId, conditions },
    }: AddFieldConditionCriterion): State => {
        const selectorIds = fieldId.split('___')

        const valueSelectorOperation =
            selectorIds.length === 1
                ? {
                      fieldValue: {
                          fieldId,
                      },
                  }
                : {
                      deep: {
                          selectors: selectorIds.map(fieldId => ({
                              fieldValue: {
                                  fieldId,
                              },
                          })),
                      },
                  }

        const valueSelectorState =
            selectorIds.length === 1
                ? {
                      __typename: 'FieldValueSelector' as const,
                      type: ValueSelectorType.FieldValue,
                      fieldId,
                  }
                : {
                      __typename: 'DeepSelector' as const,
                      type: ValueSelectorType.Deep,
                      selectors: selectorIds.map(fieldId => ({
                          __typename: 'FieldValueSelector' as const,
                          type: ValueSelectorType.FieldValue,
                          fieldId,
                      })),
                  }

        return {
            ...prev,
            process: {
                ...prev.process,
                phases: prev.process.phases.map((p, i) =>
                    i === phaseIndex
                        ? {
                              ...p,
                              criteria: [
                                  ...p.criteria,
                                  {
                                      __typename:
                                          'FieldConditionPhaseCriterion',
                                      comparator: {
                                          __typename:
                                              'BusinessObjectComparator',
                                          valueSelector: valueSelectorState,
                                          operator: Operator.IsDefined,
                                          with: '{}',
                                          negate: false,
                                      },
                                      conditions:
                                          mapComparatorsToConditions(
                                              conditions
                                          ),
                                  },
                              ],
                          }
                        : p
                ),
            },
            operations: [
                ...prev.operations,
                {
                    addPhaseCriterion: {
                        nextValue: {
                            fieldCondition: {
                                comparator: {
                                    valueSelector: valueSelectorOperation,
                                    operator: Operator.IsDefined,
                                    with: '{}',
                                    negate: false,
                                },
                                conditions: conditions.map(
                                    mapComparatorToInputComparator
                                ),
                            },
                        },
                        phaseIndex,
                        previousCriteria:
                            prev.process.phases[phaseIndex].criteria.length,
                    },
                },
            ],
        }
    }

export const handleAddProcessFanoutCriterion =
    (prev: State) =>
    ({
        payload: { phaseIndex, process, conditions },
    }: AddProcessFanoutCriterion): State => ({
        ...prev,
        process: {
            ...prev.process,
            phases: prev.process.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              {
                                  __typename: 'ProcessFanoutPhaseCriterion',
                                  process,
                                  ...(process.transform && {
                                      transform: {
                                          __typename:
                                              'RelationFieldDelegationTransformer',
                                          field: process.transform.field,
                                      },
                                  }),
                                  conditions:
                                      mapComparatorsToConditions(conditions),
                              },
                          ],
                      }
                    : p
            ),
        },
        operations: [
            ...prev.operations,
            {
                addPhaseCriterion: {
                    nextValue: {
                        processFanout: {
                            processId: process.id,
                            ...(process.transform && {
                                transform: {
                                    [process.transform.type]: {
                                        fieldId: process.transform.field.id,
                                    },
                                },
                            }),
                            conditions: conditions.map(
                                mapComparatorToInputComparator
                            ),
                        },
                    },
                    phaseIndex,
                    previousCriteria:
                        prev.process.phases[phaseIndex].criteria.length,
                },
            },
        ],
    })

export const handleAddProcessDelegateCriterion =
    (prev: State) =>
    ({
        payload: { phaseIndex, process, conditions },
    }: AddProcessDelegateCriterion): State => ({
        ...prev,
        process: {
            ...prev.process,
            phases: prev.process.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              {
                                  __typename: 'ProcessDelegatePhaseCriterion',
                                  process,
                                  ...(process.transform && {
                                      transform: {
                                          __typename:
                                              'RelationFieldDelegationTransformer',
                                          field: process.transform.field,
                                      },
                                  }),
                                  conditions:
                                      mapComparatorsToConditions(conditions),
                              },
                          ],
                      }
                    : p
            ),
        },
        operations: [
            ...prev.operations,
            {
                addPhaseCriterion: {
                    nextValue: {
                        processDelegate: {
                            processId: process.id,
                            ...(process.transform && {
                                transform: {
                                    [process.transform.type]: {
                                        fieldId: process.transform.field.id,
                                    },
                                },
                            }),
                            conditions: conditions.map(
                                mapComparatorToInputComparator
                            ),
                        },
                    },
                    phaseIndex,
                    previousCriteria:
                        prev.process.phases[phaseIndex].criteria.length,
                },
            },
        ],
    })

export const handleRenameCriterion =
    (prev: State) =>
    ({
        payload: { phaseIndex, criterionIndex, newValue },
    }: RenameCriterion): State => ({
        ...prev,
        process: {
            ...prev.process,
            phases: setAt(
                prev.process.phases,
                phaseIndex,
                'criteria',
                setAt(
                    prev.process.phases[phaseIndex].criteria,
                    criterionIndex,
                    'description',
                    newValue
                )
            ),
        },
        operations: [
            ...prev.operations,
            {
                renamePhaseCriterion: {
                    nextValue: newValue,
                    previousValue: match(
                        prev.process.phases[phaseIndex].criteria[criterionIndex]
                    )
                        .with(
                            { __typename: 'ActionPhaseCriterion' },
                            c => c.description
                        )
                        .otherwise(() => ''),
                    phaseIndex,
                    criterionIndex,
                },
            },
        ],
    })

export const handleUpdateCriterionDueTime =
    (prev: State) =>
    ({
        payload: { phaseIndex, criterionIndex, newValue },
    }: UpdateCriterionDueTime): State => ({
        ...prev,
        process: {
            ...prev.process,
            phases: setAt(
                prev.process.phases,
                phaseIndex,
                'criteria',
                setAt(
                    prev.process.phases[phaseIndex].criteria,
                    criterionIndex,
                    'defaultDueSeconds',
                    newValue
                )
            ),
        },
        operations: [
            ...prev.operations,
            {
                updateDefaultDueSeconds: {
                    nextValue: newValue,
                    previousValue: match(
                        prev.process.phases[phaseIndex].criteria[criterionIndex]
                    )
                        .with(
                            { __typename: 'ActionPhaseCriterion' },
                            c => c.defaultDueSeconds
                        )
                        .otherwise(() => undefined),
                    phaseIndex,
                    criterionIndex,
                },
            },
        ],
    })

export const handleUpdateCriterionConditions =
    (prev: State) =>
    ({
        payload: { phaseIndex, criterionIndex, newValue },
    }: UpdateCriterionConditions): State => {
        const prevConditions =
            prev.process.phases[phaseIndex].criteria[criterionIndex]
                .conditions ?? []

        const out = {
            ...prev,
            process: {
                ...prev.process,
                phases: setAt(
                    prev.process.phases,
                    phaseIndex,
                    'criteria',
                    setAt(
                        prev.process.phases[phaseIndex].criteria,
                        criterionIndex,
                        'conditions',
                        newValue
                    )
                ),
            },
            operations: [
                ...prev.operations,
                {
                    changeConditions: {
                        nextValue: newValue.map(mapComparatorToInputComparator),
                        previousValue:
                            mapConditionsToInputConditions(prevConditions),
                        phaseIndex,
                        criterionIndex,
                    },
                },
            ],
        }

        return out
    }
