import * as set from 'Utils/set'
import { uniqBy } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Available_TemplatesQuery } from '../__generated__/query'

type Props = {
    templateCategory: Available_TemplatesQuery['workspaceTemplates'][number]
}

export const useTemplateState = ({ templateCategory }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedTemplates = new Set(
        searchParams.get('templates')?.split(',') ?? []
    )
    const setSelectedTemplates = (templates: Set<string>) =>
        setSearchParams(
            templates.size > 0
                ? {
                      templates: [...templates].join(','),
                  }
                : {}
        )
    const toggleSelection = (id: string) => () =>
        setSelectedTemplates(
            selectedTemplates.has(id)
                ? set.remove(selectedTemplates)(id)
                : set.add(selectedTemplates)(id)
        )

    const toDeploy = [...selectedTemplates].map(templateId => {
        const template = templateCategory.templates.find(
            t => t.id === templateId
        )

        if (!template) {
            return {
                datasets: [],
                processes: [],
            }
        }

        return {
            datasets: templateCategory.definitions.filter(d =>
                template.definitions.some(f => f.name === d.name)
            ),
            fields: template.fields,
            processes: template.processes,
        }
    })

    const datasets = uniqBy(
        toDeploy.flatMap(t => t.datasets),
        d => d.name
    ).map(dataset => ({
        ...dataset,
        fields: [
            ...dataset.fields,
            ...toDeploy.flatMap(
                t =>
                    t.fields
                        ?.filter(f => f.name === dataset.name)
                        .flatMap(f => f.fields) ?? []
            ),
        ],
    }))

    const processes = uniqBy(
        toDeploy.flatMap(t => t.processes),
        p => p.name
    )

    return {
        selectedTemplates,
        toggleSelection,
        datasets,
        processes,
    }
}
