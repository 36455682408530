import { styled } from 'Adapters/Freestyled'
import { TextAreaInput, TextInput } from 'Components/Input'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { useProcessCreator } from '../../useProcessCreator'
import { Field } from './Field'

type Props = { index: number }

const PhaseDetails: FC<Props> = ({ index }) => {
    const {
        state: {
            input: { phases },
        },
        dispatch,
    } = useProcessCreator()
    const { current: nameId } = useRef(v4())
    const { current: descriptionId } = useRef(v4())

    return (
        <Styled>
            <Field
                label="Name"
                htmlFor={nameId}
                input={
                    <TextInput
                        id={nameId}
                        value={phases[index].name}
                        placeholder="Name"
                        onChange={({ currentTarget: { value: name } }) => {
                            dispatch({
                                type: 'setPhaseName',
                                payload: { index, name },
                            })
                        }}
                    />
                }
            />
            <Field
                label="Description"
                htmlFor={descriptionId}
                input={
                    <TextAreaInput
                        id={descriptionId}
                        value={phases[index].description || ''}
                        placeholder="Description"
                        onChange={({
                            currentTarget: { value: description },
                        }) => {
                            dispatch({
                                type: 'setPhaseDescription',
                                payload: { index, description },
                            })
                        }}
                    />
                }
            />
        </Styled>
    )
}

const Styled = styled.section`
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    margin: 0 1rem;
    flex-direction: column;
`

export { PhaseDetails }
