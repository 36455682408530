import { styled } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { Text } from 'Components/Text'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC } from 'react'
import { Card } from '../../Card'
import { useGroupBusinessObjects } from '../../Desktop/BusinessObject/CardDisplay/useGroupBusinessObjects'

type Props = {
    businessObjects: BusinessObjectTableItemFragment[]
    grouping: Grouping
    openDrawer: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

export const GroupedCardList: FC<Props> = ({
    businessObjects,
    grouping,
    openDrawer,
}) => {
    const groups = useGroupBusinessObjects(businessObjects, grouping)

    return (
        <StyledSection>
            <VerticalScrollArea height={'100%'} width={'100%'}>
                {Object.keys(groups).length ? (
                    <>
                        <Text variant="bold-4" as="h2" className="grouping-by">
                            {
                                grouping.fields.find(
                                    field => field.id === grouping.selection
                                )?.label
                            }
                        </Text>

                        <div className="groups">
                            {Object.entries(groups).map(([id, group]) => (
                                <StyledGroup key={id} $color={group.color}>
                                    <Text as={'h3'} variant={'bold-5'}>
                                        {group.value}
                                    </Text>

                                    <div className="cards">
                                        {group.items.map(businessObject => (
                                            <Card
                                                key={businessObject.id}
                                                businessObject={businessObject}
                                                openDrawer={id => {
                                                    openDrawer({
                                                        id,
                                                        tab: 'details',
                                                    })
                                                }}
                                            />
                                        ))}
                                    </div>
                                </StyledGroup>
                            ))}
                        </div>
                    </>
                ) : (
                    <Text variant="regular-4" as="p" className="no-records">
                        <em>
                            No <strong>records</strong> to show for this{' '}
                            <strong>dataset</strong>
                        </em>
                    </Text>
                )}
            </VerticalScrollArea>
        </StyledSection>
    )
}

const StyledSection = styled.section`
    padding: 1rem 0.5rem;

    .grouping-by {
        padding-bottom: 1.5rem;
        padding-left: 0.5rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .no-records {
        width: 100%;
        text-align: center;
    }

    .groups {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 1rem;
    }
`

const StyledGroup = styled.div<{ $color: string }>`
    width: 100%;
    min-height: 5rem;
    padding: 1rem;
    background-color: ${({ $color }) => `${$color}11`};
    border-radius: 0.25rem;

    > h3 {
        padding-bottom: 0.75rem;
        color: ${({ $color }) => $color};
        filter: brightness(50%);
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-auto-rows: auto;
        gap: 1rem;
    }
`
