import { Panel } from '@uploadcare/react-widget'
import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { compact } from 'lodash'
import { FC } from 'react'
import { useModalControls } from '../../../../Hooks'
import { TextButton } from '../../../Button'
import { DocumentPicker } from '../../../DocumentPicker'
import { DocumentMetadata, FileMetadata } from '../../types'

const UPLOADCARE_TABS = 'file camera url gdrive dropbox onedrive'
const { VITE_UPLOADCARE_PUBLIC_KEY } = import.meta.env

type BaseProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
    secureSignature: string
    secureExpire: number
    onUploadsComplete: (fileMetadata: FileMetadata[]) => void
    returnFocus: () => void
}

export type PropsWithAllowExistingSelection = {
    allowExistingSelection: true
    onExistingDocumentSelected: (documents: DocumentMetadata[]) => void
    existingSelections: string[]
    selectMode: 'single' | 'multiple'
}

export type PropsWithDisallowExistingSelection = {
    allowExistingSelection: false
    onExistingDocumentSelected?: never
    existingSelections?: never
    selectMode?: never
}

type Props = BaseProps &
    (PropsWithAllowExistingSelection | PropsWithDisallowExistingSelection)

export const UploadModal: FC<Props> = ({
    open,
    onOpenChange,
    secureSignature,
    secureExpire,
    onUploadsComplete,
    returnFocus,
    allowExistingSelection,
    onExistingDocumentSelected,
    existingSelections,
    selectMode,
}) => {
    const selectionModal = useModalControls()

    if (!VITE_UPLOADCARE_PUBLIC_KEY) return null

    return (
        <ControlledModal
            renderContent={() => {
                return (
                    <StyledModalContent>
                        <Panel
                            publicKey={VITE_UPLOADCARE_PUBLIC_KEY}
                            tabs={UPLOADCARE_TABS}
                            secureSignature={secureSignature}
                            secureExpire={secureExpire}
                            multiple={false}
                            onProgress={uploads => {
                                if (
                                    uploads.every(
                                        ({ state }) => state === 'ready'
                                    )
                                ) {
                                    onUploadsComplete(
                                        compact(
                                            uploads.map(upload => {
                                                const { uuid, name, mimeType } =
                                                    upload.incompleteFileInfo
                                                if (uuid && name && mimeType) {
                                                    return {
                                                        id: uuid,
                                                        name,
                                                        type: mimeType,
                                                    }
                                                }
                                                return undefined
                                            })
                                        )
                                    )
                                }
                            }}
                        />

                        {allowExistingSelection ? (
                            <>
                                <TextButton
                                    text="Attach existing document"
                                    variant="secondary"
                                    ref={selectionModal.buttonRef}
                                    onClick={selectionModal.openModal}
                                />

                                <DocumentPicker
                                    onConfirm={selections => {
                                        onExistingDocumentSelected(selections)

                                        selectionModal.closeModal()
                                        onOpenChange(false)
                                    }}
                                    selectMode={selectMode}
                                    isOpen={selectionModal.open}
                                    onOpenChange={selectionModal.setOpen}
                                    returnFocus={returnFocus}
                                    initialSelections={existingSelections}
                                />
                            </>
                        ) : null}
                    </StyledModalContent>
                )
            }}
            title={'Upload File'}
            description={'Use the provided ui to upload files'}
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}

const StyledModalContent = styled.div`
    width: calc(100vw - 3rem); //  accounts for modal padding on mobile
    max-width: 60rem;
    height: 100dvh;
    max-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // Custom CSS For Panel ->
    > div {
        height: 100% !important; // using !important because height is inlined by the component for whatever reason

        .uploadcare--panel {
            height: 100%;
            border: none;

            button,
            div[role='button'] {
                cursor: pointer;
            }

            .uploadcare--footer,
            #preview__title {
                display: none;
            }

            .uploadcare--button_primary {
                color: ${({ theme }) => theme.palette.text['04'].normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui.primary.normal};
                border: 1px solid transparent;

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};
                }

                &:hover {
                    color: ${({ theme }) => theme.palette.text['04'].hover};
                    background-color: ${({ theme }) =>
                        theme.palette.ui.primary.hover};
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text['04'].active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui.primary.active};
                }

                &:focus-visible {
                    box-shadow: inset 0rem 0rem 0rem 1px
                        ${({ theme }) => theme.palette.ui['01'].normal};
                }
            }

            .uploadcare--button_icon.uploadcare--file-source.uploadcare--file-source_all.uploadcare--file-sources__item {
                svg {
                    fill: ${({ theme }) => theme.palette.ui.primary.normal};
                }

                border-color: ${({ theme }) => theme.palette.ui.primary.normal};
            }
        }
    }
`
