import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { MenuButton } from '../Button'

type Props = {
    unseenCount: number | undefined
}

const Bell: FC<Props> = ({ unseenCount }) => (
    <Styled>
        <MenuButton icon="Notifications" tooltip="Notifications" />
        {!!unseenCount && <StyledBadge />}
    </Styled>
)

const Styled = styled.div`
    position: relative;
`

const StyledBadge = styled.div`
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    background-color: ${({ theme }) => theme.palette.support['01'].normal};
    border-radius: 50%;
    height: 0.325rem;
    width: 0.325rem;
`

export { Bell }
