import { createContext, useContext } from 'react'
import { ColorVariant } from './types'

const colorVariantContext = createContext<ColorVariant>('light')

export const { Provider } = colorVariantContext

export const useColorVariant = () => ({
    variant: useContext(colorVariantContext),
})
