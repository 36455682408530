import { useMemo } from 'react'
import { UrlFieldConstraints } from '__generated__'
import { defaultConstraints, URLConstraintsMap } from './types'

type Params = {
    urlConstraints: UrlFieldConstraints[]
}

export const useURLConstraints = ({ urlConstraints }: Params) => {
    const constraints = useMemo(() => {
        return urlConstraints.reduce<URLConstraintsMap>((out, constraint) => {
            if (constraint.__typename === 'RequiredConstraint') {
                return { ...out, required: true }
            }

            return out
        }, defaultConstraints)
    }, [urlConstraints])

    const constraintsTooltip = useMemo(() => {
        if (constraints.required) return 'Required.'
        return ''
    }, [constraints])

    return { constraints, constraintsTooltip }
}
