import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { EditBusinessObject } from '../../EditBusinessObject'
import { DocumentField, ListOfDocumentField } from './types'

type Props = {
    businessObjectId: string
    documentField: DocumentField | ListOfDocumentField
}

export const AddDocument: FC<Props> = ({ documentField, businessObjectId }) => {
    const { buttonRef, open, openModal, returnFocus, setOpen } =
        useModalControls()

    return (
        <>
            <IconTextButton
                icon={'Document'}
                text={
                    <>
                        Add document to{' '}
                        <em>{documentField.fieldDefinition.name}</em>
                    </>
                }
                variant="secondary"
                size="small"
                ref={buttonRef}
                onClick={openModal}
            />

            <ControlledModal
                renderContent={() => (
                    <StyledModalContent>
                        <EditBusinessObject
                            businessObjectId={businessObjectId}
                            fieldsFilter={fields =>
                                fields.filter(
                                    field =>
                                        field.fieldDefinition.id ===
                                        documentField.fieldDefinition.id
                                )
                            }
                            hideViewDocumentButton={true}
                        />
                    </StyledModalContent>
                )}
                title={'Add document to field'}
                description={`Add document to field ${documentField.fieldDefinition.name}`}
                hideDescription
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledModalContent = styled.div`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    border: 0;

    > div {
        padding: 0;
    }
`
