import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { useBusinessObjectOverview_RemoveFromProccessMutation } from './__generated__/q'

type Props = {
    businessObjectId: string
    processId: string
    onRemoveFromProcess: () => void
    isTerminalPhase: boolean
    percentageComplete: number
}

export const OverviewHeader: FC<Props> = ({
    processId,
    businessObjectId,
    onRemoveFromProcess,
    isTerminalPhase,
    percentageComplete,
}) => {
    const isComplete = isTerminalPhase && percentageComplete === 100

    const [remove, { loading }] =
        useBusinessObjectOverview_RemoveFromProccessMutation()

    const { open, setOpen, triggerToast } = useTriggerToast()

    return (
        <StyledHeader>
            <Tooltip
                content={
                    'This record cannot be removed as it is already complete'
                }
                disabled={!isComplete}
                side="left"
                offset={10}
            >
                <IconTextButton
                    icon="CircleMinus"
                    text="Remove from workflow"
                    size="xSmall"
                    variant="secondary"
                    onClick={() => {
                        remove({
                            variables: {
                                input: {
                                    processId,
                                    businessObjectId,
                                },
                            },
                            onError: () => {
                                triggerToast()
                            },
                            onCompleted: () => {
                                onRemoveFromProcess()
                            },
                        })
                    }}
                    disabled={loading || isComplete}
                />
            </Tooltip>

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to remove the record"
            />
        </StyledHeader>
    )
}

const StyledHeader = styled.header`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
`
