import { Text } from 'Components/Text'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type ListUpdateProps = {
    userName: string
    timestamp: string
    fieldName: string
    updateKind: 'added' | 'removed' | 'changed'
}

export const ListFieldUpdatedHistoryEntry: FC<ListUpdateProps> = ({
    userName,
    timestamp,
    fieldName,
    updateKind,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                {match(updateKind)
                    .with('added', () => (
                        <>
                            <strong>{userName}</strong> added a value to the{' '}
                            <strong>{fieldName}</strong> list field.
                        </>
                    ))
                    .with('removed', () => (
                        <>
                            <strong>{userName}</strong> removed a value from the{' '}
                            <strong>{fieldName}</strong> list field.
                        </>
                    ))
                    .with('changed', () => (
                        <>
                            <strong>{userName}</strong> updated the values
                            within the <strong>{fieldName}</strong> list field.
                        </>
                    ))
                    .exhaustive()}
            </Text>
        </BaseHistoryEntry>
    )
}
