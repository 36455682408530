import { Portal, Root } from '@radix-ui/react-dialog'
import { useResponder } from 'Hooks'
import { getPortalsContainer } from 'Utils'
import { FC } from 'react'
import { CloseButton, ModalDescription, ModalTitle } from './Components'
import { StyledContent, StyledOverlay } from './styles'
import { ControlledModalProps } from './types'

export const ControlledModal: FC<ControlledModalProps> = ({
    renderContent,
    title,
    description,
    hideDescription = false,
    open,
    onOpenChange,
    returnFocus,
    fullScreen,
    showCloseButton = true,
}) => {
    const { isLarge } = useResponder()

    return (
        <Root modal open={open} onOpenChange={onOpenChange}>
            <Portal container={getPortalsContainer()}>
                <StyledOverlay onClick={e => e.stopPropagation()} />

                <StyledContent
                    // Because this modal is controlled, we need to provide a function that returns focus to the button that opened the modal
                    onCloseAutoFocus={e => {
                        e.preventDefault()
                        returnFocus()
                    }}
                    $location={isLarge ? 'center' : 'bottom'}
                    $fullScreen={fullScreen}
                >
                    <ModalTitle title={title} />
                    {showCloseButton ? <CloseButton /> : null}

                    <ModalDescription
                        description={description}
                        hideDescription={hideDescription}
                    />
                    <div>{renderContent()}</div>
                </StyledContent>
            </Portal>
        </Root>
    )
}
