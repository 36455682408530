import { FileIcon } from 'Components/Documents'
import { FC } from 'react'
import { OverflowMenu } from './OverflowMenu'
import { StyledCard, StyledIconContainer } from './styles'
import { Document } from './types'

type Props = {
    document: Document
}

export const IconCard: FC<Props> = ({ document }) => {
    return (
        <StyledCard>
            <StyledIconContainer>
                <FileIcon name={document.fileType} />
            </StyledIconContainer>

            <p>{document.name}</p>

            <OverflowMenu document={document} />
        </StyledCard>
    )
}
