import { Select } from 'Components/Select'
import { useOrganisationUsers } from 'Hooks'
import { FC } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useUserConstraints } from './useUserConstraints'

export const UserField: FC<FieldInputProps<'UserFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useUserConstraints({
        userConstraints: field.userConstraints,
    })

    const { users } = useOrganisationUsers()

    return (
        <Field
            id={field.id}
            name={field.name}
            description={field.description ?? ''}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <Select
                id="user"
                value={value}
                onValueChange={onChange}
                name="user"
                options={[
                    {
                        disabled: true,
                        text: '-- select a user --',
                        value: '',
                    },
                    ...users.map(user => ({
                        text: user.isCurrentUser ? 'Me' : user.name,
                        value: user.id,
                        disabled: false,
                    })),
                ]}
                disabled={!users.length}
            />
        </Field>
    )
}
