export const strings = {
    subHeading: `Next step:`,
    heading: `Let's create your Modlify workspace`,
    nameLabel: 'Your full name',
    companyNameLabel: 'Your workspace name (i.e. your company)',
    btnTextNormal: 'Create workspace',
    btnTextWaiting: 'Creating workspace',
    btnTextSuccess: 'Workspace created',
    btnTextError: 'Error creating workspace',
    errorMessage: 'We were unable to create your workspace. Please try again.',
}
