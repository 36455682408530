import { lightTheme } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { FieldConstraintType, FieldType } from '__generated__'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { Action, Extension } from '../types'

export const useGroupActions = (
    actions: Action[],
    grouping: Grouping,
    extension?: Extension
) => {
    return useMemo(() => {
        if (!actions.length) return {}

        const fieldId = grouping.selection

        const field = grouping.fields.find(field => field.id === fieldId)

        const groups = field?.groups

        const fieldType = field?.type

        if (!groups || !fieldType) return {}

        const fieldDefinition =
            extension && extension.fields.find(field => field.id === fieldId)

        const showUnassigned =
            fieldDefinition?.__typename === 'SelectFieldDefinition' &&
            fieldDefinition.selectConstraints.some(
                c => c.type === FieldConstraintType.NumberOfSelections && !c.min
            )

        const groupMap = groups.reduce<GroupedActions>(
            (out, group) => ({
                ...out,
                [group.id]: {
                    fieldType,
                    value: group.value,
                    color: group.color,
                    items: [],
                },
            }),
            {
                ...(showUnassigned && {
                    unassigned: {
                        fieldType,
                        value: 'Unset',
                        color: lightTheme.palette.ui['05'].normal,
                        items: [],
                    },
                }),
            }
        )

        actions.forEach(action => {
            if (fieldId === 'assignedTo') {
                const assignedToPrincipalId = match(action.assignedTo)
                    .with({ __typename: 'UserPrincipal' }, p => p.id)
                    .with({ __typename: 'UserGroupPrincipal' }, p => p.id)
                    .otherwise(() => null)

                if (assignedToPrincipalId) {
                    groupMap[assignedToPrincipalId]?.items.push(action)
                } else {
                    groupMap.unassigned.items.push(action)
                }

                return
            }

            if (!action.extendedBy) return

            const field = action.extendedBy.fields.find(
                ({ fieldDefinition: { id } }) => id === fieldId
            )

            if (!field) return

            match(field)
                .with({ __typename: 'BusinessObjectSelectField' }, field => {
                    const value = field.selectValue

                    // Unset can be [] or ['']
                    if (value.filter(v => v.length).length) {
                        groupMap[value[0]]?.items.push(action)
                    } else {
                        groupMap.unassigned.items.push(action)
                    }
                })
                .with({ __typename: 'BusinessObjectBooleanField' }, field => {
                    const value = field.booleanValue

                    groupMap[value ? 'true' : 'false']?.items.push(action)
                })
                .otherwise(noop)
        })

        return groupMap
    }, [actions, grouping.selection, grouping.fields, extension])
}

export type GroupedActions = Record<
    string,
    {
        fieldType: FieldType
        value: string
        color: string
        items: Action[]
    }
>
