import { Select } from 'Components/Select'
import { BizObjDef_User, BOField, useOrganisationUsers } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useUserConstraints } from './useUserConstraints'

type Props = BOField<'BusinessObjectUserField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const UserField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    userValue,
    onComplete,
}) => {
    const { userDefaultValue, userConstraints, ...common } =
        fieldDefinition as BizObjDef_User

    const { constraints, constraintsTooltip } = useUserConstraints({
        userConstraints,
    })

    const { users } = useOrganisationUsers()

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(userValue?.id ?? null)

    const [value, setValue] = useState(initialValue.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <Select
                id="user"
                value={value ?? undefined}
                onValueChange={setValue}
                name="user"
                options={[
                    {
                        disabled: false,
                        text: '-- Unallocated --',
                        value: '',
                    },
                    ...users.map(user => ({
                        text: user.isCurrentUser ? 'Me' : user.name,
                        value: user.id,
                        disabled: false,
                    })),
                ]}
                disabled={!users.length}
            />

            <EditFieldButton
                disabled={initialValue.current === value || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current || null, // intentionally removing empty string
                                value: value || null,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = value
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}
