import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Actions } from './Actions'
import { BusinessObjectDefinitions } from './BusinessObjectDefinitions'
import { ModelManagerNav } from './ModelManagerNav'
import { Processes } from './Processes'
import { VisualiseBusinessObjectDefinitions } from './VisualiseBusinessObjectDefinitions'
import { VisualiseProcesses } from './VisualiseProcesses'

const ModelManager: FC = () => (
    <Routes>
        <Route index element={<ModelManagerNav />} />
        <Route
            path="processes/*"
            element={<SmallScreenWarning element={<Processes />} />}
        />
        <Route
            path="datasets/*"
            element={
                <SmallScreenWarning element={<BusinessObjectDefinitions />} />
            }
        />
        <Route
            path="actions/*"
            element={<SmallScreenWarning element={<Actions />} />}
        />
        <Route
            path="visualise-processes/*"
            element={<SmallScreenWarning element={<VisualiseProcesses />} />}
        />
        <Route
            path="visualise-datasets/*"
            element={
                <SmallScreenWarning
                    element={<VisualiseBusinessObjectDefinitions />}
                />
            }
        />
    </Routes>
)

export { ModelManager }
