import { Value } from '@udecode/plate-common'
import { dayjs } from 'Adapters/DayJS'
import { styled } from 'Adapters/Freestyled'
import { Attachment, Attachments } from 'Components/Attachments'
import { Avatar } from 'Components/Avatar'
import { RichText } from 'Components/RichText'
import * as principal from 'Features/Principal'
import { BizObjFragment } from 'Fragments/__generated__/BusinessObject'
import { byTypename } from 'Utils'
import { compact, isUndefined, orderBy, takeRight, uniqBy } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import slugify from 'slugify'
import { useUpdateBusinessObjectField } from '../EditBusinessObject/Fields/useUpdateBusinessObjectField'
import { DeleteUpdate } from './DeleteUpdateModal'
import {
    AuthorAvatar,
    AuthorText,
    UpdateContent,
    UpdateDisplay,
    UpdateInfo,
    UpdateText,
} from './styles'

type Props = {
    businessObject: BizObjFragment
    limit?: number
}

export const UpdatesDisplay: FC<Props> = ({ businessObject, limit }) => {
    const updates = useMemo(() => {
        const listField = businessObject.fields
            .filter(byTypename('BusinessObjectListField' as const))
            .find(
                field =>
                    byTypename('ListFieldDefinition' as const)(
                        field.fieldDefinition
                    ) &&
                    byTypename('UpdatesFieldDefinition')(
                        field.fieldDefinition.listOf
                    )
            )

        if (!listField) return listField

        const { listValue } = listField

        const values = compact(
            listValue.map((value, i) => {
                if (value.__typename !== 'BusinessObjectUpdatesField')
                    return undefined
                if (!value.updatesValue) return undefined
                try {
                    const descendants = JSON.parse(
                        value.updatesValue.richText
                    ) as Value
                    return {
                        descendants,
                        attachments: value.updatesValue.attachments,
                        createdBy: value.updatesValue.createdBy,
                        createdAt: value.updatesValue.createdAt,
                        deleteIndex: i,
                    }
                } catch {
                    return undefined
                }
            })
        )

        return {
            values: !isUndefined(limit) ? takeRight(values, 3) : values,
            listField,
        }
    }, [businessObject, limit])

    const [attachments, setAttachments] = useState<Attachment[]>([])

    const { updateBusinessObjectField, loading } =
        useUpdateBusinessObjectField()

    const previousValue = useMemo(
        () =>
            updates?.values.map(value => ({
                updates: {
                    richText: JSON.stringify(value.descendants),
                    attachments: value.attachments.map(attachment => ({
                        [attachment.type]: attachment.file.id,
                    })),
                    createdBy: value.createdBy.id,
                    createdAt: value.createdAt,
                },
            })),
        [updates]
    )

    const handleSubmit = (newUpdateValue: Value) => {
        if (!updates || !previousValue) return

        updateBusinessObjectField({
            field: {
                list: {
                    fieldDefinitionId: updates.listField.fieldDefinition.id,
                    previousValue,
                    value: [
                        ...previousValue,
                        {
                            updates: {
                                richText: JSON.stringify(newUpdateValue),
                                attachments: attachments.map(attachment => ({
                                    [attachment.type]: attachment.file.id,
                                })),
                            },
                        },
                    ],
                },
            },
            id: businessObject.id,
            onSuccess: () => {
                setAttachments([])
            },
        })
    }

    if (!updates || !previousValue) {
        return (
            <StyledEnableUpdates>
                <p>
                    Updates are not enabled within this dataset. Enable them{' '}
                    <NavLink
                        to={`/manage-workspace/datasets/${slugify(
                            businessObject.definition.name
                        )}`}
                    >
                        on the dataset settings page.
                    </NavLink>
                </p>
            </StyledEnableUpdates>
        )
    }

    return (
        <>
            <RichText
                id={`update-editor`}
                attachments={attachments}
                onAttachmentAdded={newAttachments => {
                    // Dedupe new total attachments
                    setAttachments(prevAttachments =>
                        uniqBy(
                            [...prevAttachments, ...newAttachments],
                            attachment => attachment.file.id
                        )
                    )
                }}
                handleSubmit={handleSubmit}
                disabled={loading}
            />

            <StyledScrollArea>
                {orderBy(updates.values, v => v.createdAt, 'desc').map(
                    (value, i) => {
                        return (
                            <UpdateDisplay key={i}>
                                <AuthorAvatar>
                                    <Avatar
                                        name={principal.helpers.name(
                                            value.createdBy
                                        )}
                                        noOfInitials={2}
                                    />
                                </AuthorAvatar>

                                <UpdateContent>
                                    <AuthorText>
                                        <principal.PrincipalName
                                            principal={value.createdBy}
                                        />{' '}
                                        {dayjs(value.createdAt).format(
                                            'DD MMM YYYY HH:mm'
                                        )}
                                    </AuthorText>

                                    <UpdateText>
                                        <RichText
                                            id={`update-${i}`}
                                            readonly={true}
                                            initialValue={value.descendants}
                                        />
                                    </UpdateText>

                                    <UpdateInfo>
                                        <Attachments
                                            attachments={value.attachments}
                                        />
                                    </UpdateInfo>
                                </UpdateContent>

                                <DeleteUpdate
                                    previousValue={previousValue}
                                    fieldDefinitionId={
                                        updates.listField.fieldDefinition.id
                                    }
                                    businessObjectId={businessObject.id}
                                    updateAuthor={value.createdBy}
                                    deleteIndex={value.deleteIndex}
                                />
                            </UpdateDisplay>
                        )
                    }
                )}
            </StyledScrollArea>
        </>
    )
}

const StyledScrollArea = styled.div`
    overflow-y: auto;

    > p {
        text-align: center;
        font-style: italic;
    }
`
const StyledEnableUpdates = styled.div`
    > p > a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }
`
