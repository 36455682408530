import { flex, styled } from 'Adapters/Freestyled'
import { canFilterFieldType } from 'Components/ComparatorBuilderV2/useFilterableFields'
import { SearchBar } from 'Components/SearchBar'
import { regular4, regular6 } from 'Components/Text'
import { useSearchTerms } from 'Features/FilterBusinessObject'
import { fromEvent } from 'Utils'
import { FC, ReactNode, useMemo } from 'react'
import { FieldDefinitions } from '../types'
import { ApplyView } from './ApplyView'
import { Filters } from './Filters'
import { SaveView } from './SaveView'

type Props = {
    businessObjectDefinitionId: string
    fieldDefinitions: FieldDefinitions
    leftSlot?: ReactNode
}

export const FilterBarLarge: FC<Props> = ({
    businessObjectDefinitionId,
    fieldDefinitions,
    leftSlot,
}) => {
    const canFilter = useMemo(
        () =>
            fieldDefinitions.filter(field => canFilterFieldType(field.type))
                .length
                ? true
                : false,
        [fieldDefinitions]
    )

    const { searchTerms, setSearchTerms } = useSearchTerms()

    return (
        <StyledFilterBar>
            <menu>
                <div>
                    {leftSlot ?? null}

                    <StyledSearchBar
                        searchTerms={searchTerms}
                        placeholderText={'Search records'}
                        updateSearchTerms={e => setSearchTerms(fromEvent(e))}
                        clearSearchTerms={() => setSearchTerms('')}
                    />

                    {canFilter ? (
                        <Filters
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                        />
                    ) : null}
                </div>

                <div>
                    <SaveView
                        businessObjectDefinitionId={businessObjectDefinitionId}
                    />
                    <ApplyView
                        businessObjectDefinitionId={businessObjectDefinitionId}
                        fieldDefinitions={fieldDefinitions}
                    />
                </div>
            </menu>
        </StyledFilterBar>
    )
}

const StyledFilterBar = styled.div`
    padding: 0 3rem;
    overflow: auto;

    > menu {
        ${flex('row', 'space-between', 'stretch')}
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: ${({ theme }) => theme.borderRadius.small};

        > div {
            ${flex('row', 'flex-start', 'center')}
            padding: 0.25rem 0.5rem;
            gap: 0.75rem;
            background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        }

        > div:first-of-type {
            border-radius: ${({
                theme: {
                    borderRadius: { small },
                },
            }) => ` ${small} 0 0 ${small}`};
        }

        > div:last-of-type {
            border-radius: ${({
                theme: {
                    borderRadius: { small },
                },
            }) => `0 ${small} ${small} 0`};
        }
    }
`

const StyledSearchBar = styled(SearchBar)`
    width: 20rem;
    height: 2rem;
    border: none;

    input {
        ${regular6};
    }

    .icon svg {
        min-height: 1em;
        min-width: 1em;
        max-height: 1em;
        max-width: 1em;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        input {
            ${regular4}
        }
    }
`
