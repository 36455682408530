import { Text } from 'Components/Text'
import { FC } from 'react'
import { NumberOfSelectionsConstraint } from '__generated__'

export const NumberOfSelections: FC<NumberOfSelectionsConstraint> = ({
    min,
    max,
}) => (
    <>
        <Text as="p" variant="bold-4">
            Minimum number of selections
        </Text>
        {min ? (
            <Text as="p" variant="regular-4">
                {min}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No minimum set
            </Text>
        )}
        <Text as="p" variant="bold-4">
            Maximum number of selections
        </Text>
        {max ? (
            <Text as="p" variant="regular-4">
                {max}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No maximum set
            </Text>
        )}
    </>
)
