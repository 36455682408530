import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsFreshTenantQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsFreshTenantQuery = { __typename?: 'Query', actionCount: number, businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string }>, processes: Array<{ __typename?: 'Process', id: string }> };


export const IsFreshTenantDocument = gql`
    query IsFreshTenant {
  actionCount
  businessObjectDefinitions {
    id
  }
  processes {
    id
  }
}
    `;

/**
 * __useIsFreshTenantQuery__
 *
 * To run a query within a React component, call `useIsFreshTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFreshTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFreshTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsFreshTenantQuery(baseOptions?: Apollo.QueryHookOptions<IsFreshTenantQuery, IsFreshTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsFreshTenantQuery, IsFreshTenantQueryVariables>(IsFreshTenantDocument, options);
      }
export function useIsFreshTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsFreshTenantQuery, IsFreshTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsFreshTenantQuery, IsFreshTenantQueryVariables>(IsFreshTenantDocument, options);
        }
export type IsFreshTenantQueryHookResult = ReturnType<typeof useIsFreshTenantQuery>;
export type IsFreshTenantLazyQueryHookResult = ReturnType<typeof useIsFreshTenantLazyQuery>;
export type IsFreshTenantQueryResult = Apollo.QueryResult<IsFreshTenantQuery, IsFreshTenantQueryVariables>;