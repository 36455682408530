import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { regular5 } from 'Components/Text'
import dayjs from 'dayjs'
import { BizObjDef_Date, BOField, useModalControls } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useDateConstraints } from './useDateConstraints'

type Props = BOField<'BusinessObjectDateField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const DateField: FC<Props> = ({
    businessObjectId,
    dateValue,
    fieldDefinition,
    onComplete,
}) => {
    const { dateConstraints, ...common } = fieldDefinition as BizObjDef_Date

    const { constraints, constraintsTooltip } = useDateConstraints({
        dateConstraints,
    })

    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef<Date | undefined>(
        dateValue ? new Date(dateValue) : undefined
    )

    const [date, setDate] = useState<Date | undefined>(initialValue.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledOpenDatePickerButton
                icon="Calendar"
                text={!date ? 'Add a date' : dayjs(date).format('DD MMM YYYY')}
                onClick={e => {
                    e.preventDefault() // prevent from firing onSubmit
                    openModal()
                }}
                ref={buttonRef}
            />
            <DatePicker
                open={open}
                onOpenChange={setOpen}
                title={`Choose a date`}
                description={`Choose a date for ${common.name}`}
                onConfirm={({ date }) => {
                    setDate(date)
                    closeModal()
                }}
                returnFocus={returnFocus}
                limit={{
                    from: constraints.startAfter
                        ? new Date(constraints.startAfter)
                        : undefined,
                    to: constraints.endBefore
                        ? new Date(constraints.endBefore)
                        : undefined,
                }}
            />

            {date !== undefined ? (
                <IconButton
                    iconName={'Close'}
                    onClick={() => {
                        setDate(undefined)
                    }}
                />
            ) : null}

            <EditFieldButton
                disabled={
                    initialValue.current?.getTime() === date?.getTime() ||
                    loading
                }
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue:
                                    initialValue.current?.toISOString() ??
                                    undefined,
                                value: date?.toISOString() ?? undefined,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = date
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}

const StyledOpenDatePickerButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
