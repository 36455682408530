import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { Updates } from 'Features/Updates'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { useSingleBusinessObjectQuery } from '../../__generated__/SingleBusinessObject'

type Props = {
    businessObjectId: string
}

export const RecentUpdates: FC<Props> = ({ businessObjectId }) => {
    const result = useSingleBusinessObjectQuery({
        variables: { input: { id: businessObjectId } },
        fetchPolicy: 'cache-and-network',
    })

    return (
        <StyledRecentUpdates>
            <header>
                <Text as="h2" variant="bold-4">
                    Recent Updates
                </Text>
            </header>

            {match(result)
                .with({ data: P.not(P.nullish) }, ({ data }) => (
                    <StyledUpdates
                        businessObjectId={data.businessObject.id}
                        limit={5}
                    />
                ))
                .with({ data: P.nullish, loading: true }, () => (
                    <CenteredSpinner />
                ))
                .with({ data: P.nullish, loading: false }, () => (
                    <Text as="p" variant="regular-4">
                        We failed to retreive any updates. Contact us if the
                        problem persists.
                    </Text>
                ))
                .exhaustive()}
        </StyledRecentUpdates>
    )
}

const StyledRecentUpdates = styled.section`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 1.5rem;
    position: relative;

    h2 {
        padding-bottom: 0.25rem;
    }

    > .updates-container {
        align-self: center;
        width: 50rem;
        max-width: 100%;
        padding-top: 0.75rem;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        border: 0;
        border-radius: 0;
    }
`

const StyledUpdates = styled(Updates)`
    padding-top: 1rem;
    width: 100%;
`
