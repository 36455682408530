import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { regular5 } from 'Components/Text'
import dayjs from 'dayjs'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { useModalControls } from 'Hooks'
import { FC, useEffect, useState } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useDateConstraints } from './useDateConstraints'

export const DateField: FC<BODField<'DateFieldDefinition'>> = ({
    dateConstraints,
    datePrecision,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useDateConstraints({
        dateConstraints,
        datePrecision,
    })

    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    const [date, setDate] = useState<Date | undefined>(() => {
        const initialValue = state[common.id].value

        if (typeof initialValue === 'string' && dayjs(initialValue).isValid())
            return new Date(initialValue)

        return undefined
    })

    useEffect(() => {
        dispatch({
            type: 'updateDateField',
            payload: {
                id: common.id,
                value: date?.toISOString(),
            },
        })
    }, [date, dispatch, common.id])

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <StyledOpenDatePickerButton
                icon="Calendar"
                text={!date ? 'Add a date' : dayjs(date).format('DD MMM YYYY')}
                onClick={e => {
                    e.preventDefault() // prevent from firing onSubmit
                    openModal()
                }}
                ref={buttonRef}
            />

            {date !== undefined ? (
                <IconButton
                    iconName={'Close'}
                    onClick={() => {
                        setDate(undefined)
                    }}
                />
            ) : null}

            <DatePicker
                open={open}
                onOpenChange={setOpen}
                title={`Choose a date`}
                description={`Choose a date for ${common.name}`}
                onConfirm={({ date }) => {
                    setDate(date)
                    closeModal()
                }}
                returnFocus={returnFocus}
                limit={{
                    from: constraints.startAfter
                        ? dayjs(constraints.startAfter)
                              .startOf(datePrecision)
                              .toDate()
                        : undefined,
                    to: constraints.endBefore
                        ? dayjs(constraints.endBefore)
                              .endOf(datePrecision)
                              .toDate()
                        : undefined,
                }}
            />
        </Field>
    )
}

const StyledOpenDatePickerButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
