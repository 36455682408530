import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Styled } from './styles'

type Item = {
    key: string
    heading: string
    onClick: () => void
    isActive: boolean
}

export const ClickLinkList: FC<{
    items: Item[]
    className?: string
}> = ({ items, className }) => (
    <Styled className={className}>
        {items.map(({ key, heading, onClick, isActive }) => (
            <button
                key={key}
                className={isActive ? 'current' : undefined}
                onClick={onClick}
            >
                <div className="text">
                    <Text as="p" variant="bold-4">
                        {heading}
                    </Text>
                </div>

                <Icon name="CarrotRight" className="chevron-icon" />
            </button>
        ))}
    </Styled>
)
