import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'

type Props = {
    label: string
    htmlFor: string
    input: ReactNode
}

const Field: FC<Props> = ({ label, htmlFor, input }) => (
    <StyledField>
        <Text as="label" variant="bold-5" htmlFor={htmlFor}>
            {label}
        </Text>
        {input}
    </StyledField>
)

const StyledField = styled.div`
    display: flex;
    flex-direction: column;

    > label {
        padding-bottom: 0.25em;
    }
`

export { Field }
