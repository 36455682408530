import { Label } from '@radix-ui/react-select'
import { flex, styled } from '../../../Adapters/Freestyled'
import { regular5 } from '../../Text'

export const SelectLabel = styled(Label)`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};

    color: ${({ theme }) => theme.palette.text['03'].normal};
    padding: 0.5rem 0.75rem;
    height: fit-content;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    position: relative;
    user-select: none;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    svg {
        path {
            fill: ${({ theme }) => theme.palette.icon['01'].normal};
        }
    }
`
