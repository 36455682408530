import * as Accordion from '@radix-ui/react-accordion'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Select } from 'Components/Select'
import { SelectOption } from 'Components/Select/types'
import { Text } from 'Components/Text'
import { FC, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StyledInputGroup, StyledItem, StyledTrigger } from './styles'

type Props = {
    options: SelectOption[]
}

export const ProcessFilterControls: FC<Props> = ({ options }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const processId = searchParams.get('processId')

    const updateProcessFilter = useCallback(
        (value: string) => {
            if (!value) {
                searchParams.delete('processId')
            } else {
                searchParams.set('processId', value)
            }

            setSearchParams(searchParams)
        },
        [setSearchParams, searchParams]
    )

    return (
        <StyledItem value={'process'} key={'process'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Workflow :
                </Text>

                {processId ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledInputGroup>
                    <Text as="label" variant="bold-6" htmlFor="process-select">
                        Workflow name:
                    </Text>

                    <Select
                        id="process-select"
                        onValueChange={updateProcessFilter}
                        value={processId ?? ''}
                        name="process-select"
                        options={[{ text: 'All', value: '' }, ...options]}
                    />
                </StyledInputGroup>
            </Accordion.Content>
        </StyledItem>
    )
}
