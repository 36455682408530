import { gt } from 'Utils'
import {
    DateRangeConstraint,
    LengthConstraint,
    NumberOfSelectionsConstraint,
    RangeConstraint,
    RelationTypeConstraint,
    RequiredConstraint,
} from '__generated__'
import { partition } from 'lodash'
import { useState } from 'react'
import { P, match } from 'ts-pattern'
import { BizObjectDef_CompleteFragment } from '../Fragments/__generated__/BusinessObjectDefinition'
import { FieldConstraintType } from '../__generated__'

type Field = BizObjectDef_CompleteFragment['fields'][number]

type Constraint =
    | (RequiredConstraint & { type: FieldConstraintType.Required })
    | (LengthConstraint & { type: FieldConstraintType.Length })
    | (DateRangeConstraint & { type: FieldConstraintType.DateRange })
    | (RangeConstraint & { type: FieldConstraintType.Range })
    | (NumberOfSelectionsConstraint & {
          type: FieldConstraintType.NumberOfSelections
      })
    | (RelationTypeConstraint & { type: FieldConstraintType.RelationType })

const getConstraints = (field: Field): Constraint[] => {
    return (
        match(field)
            .with(
                { __typename: 'TextFieldDefinition' },
                props => props.textConstraints
            )
            .with(
                { __typename: 'URLFieldDefinition' },
                props => props.urlConstraints
            )
            .with(
                { __typename: 'NumberFieldDefinition' },
                props => props.numConstraints
            )
            .with(
                { __typename: 'EmailFieldDefinition' },
                props => props.emailConstraints
            )
            .with({ __typename: 'BooleanFieldDefinition' }, () => [])

            // Complex
            .with(
                { __typename: 'SelectFieldDefinition' },
                props => props.selectConstraints
            )
            .with(
                { __typename: 'DateFieldDefinition' },
                props => props.dateConstraints
            )
            .with(
                { __typename: 'TelephoneFieldDefinition' },
                props => props.telephoneConstraints
            )

            // Built In Objects
            .with(
                { __typename: 'DocumentFieldDefinition' },
                props => props.docConstraints
            )
            .with(
                { __typename: 'UserFieldDefinition' },
                props => props.userConstraints
            )

            // Relationships
            .with(
                { __typename: 'RelationFieldDefinition' },
                props => props.relationConstraints
            )
            .with({ __typename: 'ListFieldDefinition' }, () => [])
            .with({ __typename: 'UpdatesFieldDefinition' }, () => [])
            .with(
                { __typename: 'CurrencyFieldDefinition' },
                props => props.currencyConstraints
            )
            .exhaustive() as Constraint[]
    )
}

export const useOptionalFields = ({
    initiallyShowOptionalFields,
    fields,
}: {
    initiallyShowOptionalFields: boolean
    fields: Field[]
}) => {
    const [showOptionalFieldsState, setShowOptionalFields] = useState(
        initiallyShowOptionalFields
    )

    const [requiredFields, optionalFields] = partition(fields, field => {
        const constraints = getConstraints(field)

        return (
            constraints.some(c => c.type === FieldConstraintType.Required) ||
            constraints.some(
                c =>
                    c.type === FieldConstraintType.NumberOfSelections &&
                    c.min &&
                    c.min > 0
            )
        )
    })

    /**
     * Optional fields toggle, and conditional hiding is only needed if there are both optional AND required fields
     */
    const [showToggle, showOptionalFields] = match([
        requiredFields.length,
        optionalFields.length,
    ])
        .with([P.when(gt(0)), P.when(gt(0))], () => [
            true,
            showOptionalFieldsState,
        ])
        .otherwise(() => [false, true])

    return {
        showToggle,
        showOptionalFields,
        setShowOptionalFields,
        requiredFields,
        optionalFields,
    }
}
