import { compact } from 'lodash'
import { Action, CreateFormState } from './types'

export const reducer = (state: CreateFormState, action: Action) => {
    switch (action.type) {
        case 'updateTextField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateURLField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateEmailField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateNumberField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateBooleanField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateTelephoneField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateUserField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateDocumentField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateDateField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateSelectField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: compact(action.payload.value),
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateRelationField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateListField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'updateCurrencyField': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    value: action.payload.value,
                    errors: undefined, // Clear errors when start typing again
                },
            }
        }
        case 'setErrors': {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    errors: action.payload.errors,
                },
            }
        }
        default:
            return state
    }
}
