import { FC, ReactNode } from 'react'
import {
    StyledConstraintsTooltip,
    StyledErrorMessage,
    StyledInputGroup,
} from '../../../styles'

// A wrapper around a field that provides a label, error message, list of items etc.

type Props = {
    children: ReactNode
    id: string
    name: string
    required?: boolean
    constraintsTooltip?: string
    errors?: string[]
    list: ReactNode
}

export const ListFieldWrapper: FC<Props> = ({
    children,
    id,
    errors,
    constraintsTooltip,
    name,
    required,
    list,
}) => {
    return (
        <StyledInputGroup>
            <label htmlFor={id}>
                {name} {required ? <strong>*</strong> : null}
            </label>

            {constraintsTooltip ? (
                <StyledConstraintsTooltip>
                    {constraintsTooltip}
                </StyledConstraintsTooltip>
            ) : null}

            <div>{children}</div>

            <StyledErrorMessage>
                {errors?.map(message => message)}
            </StyledErrorMessage>

            <ul>{list}</ul>
        </StyledInputGroup>
    )
}
