import { styled } from 'Adapters/Freestyled'
import {
    BusinessObjectInProcessOverviewQuery,
    BusinessObjectPhaseProgressQuery,
} from 'Features/SingleBusinessObjectTabs/Overview/__generated__/query'
import { FC } from 'react'
import { CurrentPhaseBox } from './PhaseBoxes/CurrentPhaseBox'
import { PhaseBox } from './PhaseBoxes/PhaseBox'

type Props = {
    process: BusinessObjectInProcessOverviewQuery['process']
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    businessObjectPhaseIndex: number
    onProgressChange: () => void
    percentageComplete: number
    percentageCompleteLoading: boolean
    actions: BusinessObjectPhaseProgressQuery['actions']
}

export const ProgressBar: FC<Props> = ({
    process,
    businessObjectPhaseIndex,
    businessObject,
    onProgressChange,
    percentageComplete,
    percentageCompleteLoading,
    actions,
}) => {
    return (
        <StyledProgressBar>
            {process.phases.map(phase =>
                phase.phaseIndex === businessObjectPhaseIndex ? (
                    <CurrentPhaseBox
                        key={phase.phaseIndex}
                        phaseName={phase.name}
                        phaseIndex={phase.phaseIndex}
                        businessObject={businessObject}
                        process={process}
                        onProgressChange={onProgressChange}
                        percentageComplete={percentageComplete}
                        percentageCompleteLoading={percentageCompleteLoading}
                        actions={actions}
                    />
                ) : (
                    <PhaseBox
                        key={phase.phaseIndex}
                        phaseName={phase.name}
                        phaseIndex={phase.phaseIndex}
                        businessObject={businessObject}
                        process={process}
                        isComplete={phase.phaseIndex < businessObjectPhaseIndex}
                    />
                )
            )}
        </StyledProgressBar>
    )
}

const StyledProgressBar = styled.div`
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    gap: 0.3rem;
    width: 100%;
    padding: 0 0 1rem;

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        padding: 0 4vw 0.5rem;
    }
`
