import { FC, ReactNode } from 'react'
import { LoadingDisplay } from '../../../Components/LoadingDisplay'
import { SetupUser } from './SetupUser'
import { ErrorScreen } from './SetupUser/ErrorScreen'
import { TemplateContext, useUserSetup } from './useUserSetup'

type Props = {
    children: ReactNode
    isFirstVisit?: boolean
}

export const UserSetup: FC<Props> = ({
    children,
    isFirstVisit: isFirstVisitInitial,
}) => {
    const { status, invites, onSuccess, isFirstVisit, setIsFirstVisit } =
        useUserSetup(isFirstVisitInitial)

    const Component = () =>
        ({
            loading: <LoadingDisplay />,
            userIsSetup: <>{children}</>,
            userIsNotSetup: (
                <SetupUser invites={invites} onSuccess={onSuccess} />
            ),
            error: <ErrorScreen />,
        }[status])

    return (
        <TemplateContext.Provider
            value={{
                isOpen: isFirstVisit,
                dismiss: () => setIsFirstVisit(false),
                open: () => setIsFirstVisit(true),
            }}
        >
            <Component />
        </TemplateContext.Provider>
    )
}
