import { styled } from 'Adapters/Freestyled'
import { NavCard } from 'Components/NavCard'
import { Text } from 'Components/Text'
import { FeatureName } from 'Features/FeatureName'
import { useResponder } from 'Hooks'
import { FC } from 'react'

export const ConnectNav: FC = () => {
    const { isLarge } = useResponder()
    return (
        <Styled>
            {!isLarge && (
                <header>
                    <Text as="h2" variant="bold-2">
                        Model Manager
                    </Text>
                </header>
            )}
            <ul>
                <li>
                    <NavCard
                        to="integrations"
                        heading="Integrations"
                        icon="Integrations"
                        background="process-card.jpg"
                        description={
                            <>
                                <FeatureName>Integrations</FeatureName> let you
                                take specific actions in external tools as
                                records move through a workflow.
                            </>
                        }
                    />
                </li>
                <li>
                    <NavCard
                        to="webhooks"
                        heading="Webhooks"
                        icon="Pipeline"
                        background="dataset-card.jpg"
                        description={
                            <>
                                <FeatureName>Webhooks</FeatureName> allow
                                third-party systems to take actions within your
                                Modlify workspace.
                            </>
                        }
                    />
                </li>
                <li>
                    <NavCard
                        to="outgoing-webhooks"
                        heading="Outgoing Webhooks"
                        icon="External"
                        background="actions-card.jpg"
                        description={
                            <>
                                Use <FeatureName>Outgoing Webhooks</FeatureName>{' '}
                                to respond to events that happen in your Modlify
                                workspace.
                            </>
                        }
                    />
                </li>
            </ul>
        </Styled>
    )
}

const Styled = styled.section`
    padding: 2rem;
    height: 100%;
    overflow: auto;

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        padding: 1rem 4vw;
    }

    [data-screen-size='small'] & ul,
    [data-screen-size='medium'] & ul {
        gap: 1rem;
    }

    > header {
        padding-bottom: 1rem;
    }
`
