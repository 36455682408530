import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { logger } from 'Adapters/Logger'
import { Grouping } from 'Components/GroupingControl/types'
import { useTriggerToast } from 'Components/Toast'
import { useUpdateBusinessObjectField } from 'Features/EditBusinessObject/Fields/useUpdateBusinessObjectField'
import { FieldType } from '__generated__'
import { useEffect } from 'react'
import { match } from 'ts-pattern'
import { DestinationSchema, SourceSchema } from './types'

export const useDropToUpdate = (grouping: Grouping) => {
    const {
        triggerToast,
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
    } = useTriggerToast()

    const { updateBusinessObjectField } =
        useUpdateBusinessObjectField(triggerToast)

    useEffect(
        () =>
            monitorForElements({
                onDrop({ source, location }) {
                    const destination = location.current.dropTargets[0]

                    if (!destination) return // if dropped outside of any drop targets

                    const sourceData = SourceSchema.safeParse(source.data)
                    const destinationData = DestinationSchema.safeParse(
                        destination.data
                    )

                    if (!sourceData.success || !destinationData.success) {
                        logger.error(
                            'Failed to parse drag and drop source or destination data with zod'
                        )
                        triggerToast()
                        return
                    }

                    const fieldDefinitionId = grouping.selection
                    const { businessObjectId, groupId: previousValue } =
                        sourceData.data
                    const { groupId: value, fieldType } = destinationData.data

                    const update = match(fieldType)
                        .with(FieldType.Select, () => ({
                            select: {
                                previousValue:
                                    previousValue === 'unassigned'
                                        ? []
                                        : [previousValue],
                                value: value === 'unassigned' ? [] : [value],
                                fieldDefinitionId,
                            },
                        }))
                        .with(FieldType.Boolean, () => ({
                            boolean: {
                                previousValue:
                                    previousValue === 'true' ? true : false,
                                value: value === 'true' ? true : false,
                                fieldDefinitionId,
                            },
                        }))
                        .otherwise(() => ({}))

                    updateBusinessObjectField({
                        field: {
                            ...update,
                        },
                        id: businessObjectId,
                    })
                },
            }),
        [grouping.selection, updateBusinessObjectField, triggerToast]
    )

    return { errorToastOpen, setErrorToastOpen }
}
