import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { FC, useMemo, useState } from 'react'
import { Extension } from '../../../types'
import { Filters } from '../Filters'
import { CardSettings } from './CardSettings'
import { Grouping } from './Grouping'
import { Sorting } from './Sorting'

type Props = {
    extension: Extension | undefined
    allAssignablePrincipals: { id: string; name: string }[]
}

export const EditViewMenu: FC<Props> = ({
    extension,
    allAssignablePrincipals,
}) => {
    const [open, setOpen] = useState(false)

    const fieldsNoUpdates = useMemo(
        () =>
            extension?.fields.filter(
                field =>
                    !(
                        field.__typename === 'ListFieldDefinition' &&
                        field.listOf.__typename === 'UpdatesFieldDefinition'
                    )
            ) ?? [],
        [extension]
    )

    return (
        <>
            <IconTextButton
                icon="Filter"
                text="Edit view"
                onClick={() => setOpen(true)}
                size="xSmall"
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                <VerticalScrollArea height="100%" width="100%">
                    <StyledOptions>
                        <Filters extension={extension} />

                        <Sorting
                            extensionFields={
                                extension
                                    ? extension.fields.map(field => ({
                                          id: field.id,
                                          label: field.name,
                                          type: field.type,
                                      }))
                                    : []
                            }
                        />

                        <Grouping
                            fields={extension?.fields ?? []}
                            allAssignablePrincipals={allAssignablePrincipals}
                        />

                        {fieldsNoUpdates.length ? (
                            <CardSettings fields={fieldsNoUpdates} />
                        ) : null}
                    </StyledOptions>
                </VerticalScrollArea>
            </MinimalModal>
        </>
    )
}

const StyledOptions = styled.div`
    ${flex('column', 'flex-start', 'flex-start')}
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;

    button {
        width: 100%;
    }
`
