import processes from 'Assets/images/process-screenshot.png'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { FeatureName } from 'Features/FeatureName'
import { useSandbox } from 'Hooks/useSandbox'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    category: string
    isLarge: boolean
}

export const AlreadyDeployed: FC<Props> = ({ category, isLarge }) => {
    const navigate = useNavigate()
    const { isSandbox } = useSandbox()

    const message = isSandbox
        ? `If you want to try a different combination of ${category.toLocaleLowerCase()} templates, then register for an account and create a new workspace.`
        : `If you want to try a different combination of ${category.toLocaleLowerCase()} templates, then create a new workspace.`

    const handler = useCallback(() => {
        if (isLarge) {
            navigate(`/manage-workspace${isLarge ? '' : '/model'}/processes`)
        } else {
            navigate('/processes')
        }
    }, [navigate, isLarge])

    return (
        <EmptyStateCard
            title="Your workspace is ready!"
            paragraphs={[
                `You've already deployed some of our fantastic ${category.toLocaleLowerCase()} templates to your workspace.`,
                <>
                    You can use the main menu{' '}
                    {isLarge ? 'on the left' : 'below'} to start exploring, but
                    we'd suggest{' '}
                    {isLarge ? (
                        <>
                            starting by taking a look at your new{' '}
                            <FeatureName>workflows</FeatureName> and making sure
                            they fit your needs perfectly.
                        </>
                    ) : (
                        <>
                            having a go at running the{' '}
                            <FeatureName>workflows</FeatureName> you created
                            from a template.
                        </>
                    )}
                </>,

                `If you'd like to try a different category, go right ahead.`,
                message,
            ]}
            imageUrl={processes}
            button={[
                {
                    text: isLarge ? 'View my workflows' : 'Run a workflow',
                    handler,
                    icon: 'ArrowRight',
                },
            ]}
        />
    )
}
