import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { FC, useState } from 'react'
import { ProcessDisplayFragment } from '../../__generated__/q'
import { DispatchAction, ViewConfig } from '../../types'
import { SaveView, SavedViews } from '../ViewPersistor/Small'
import { CardSettings } from './CardSettings'
import { CompletedToggle } from './CompletedToggle'
import { Filters } from './Filters'
import { Grouping } from './Grouping'

type Props = {
    process: ProcessDisplayFragment
    config: ViewConfig
    dispatch: DispatchAction
}

const ViewBarSmall: FC<Props> = ({ config, dispatch, process }) => {
    const [open, setOpen] = useState(false)
    return (
        <StyledSmall>
            <IconTextButton
                icon="Filter"
                text="Edit view"
                size="xSmall"
                onClick={() => setOpen(true)}
            />
            <SaveView process={process} />
            <SavedViews processId={process.id} />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <StyledEdit>
                    <Grouping config={config} dispatch={dispatch} />
                    <Filters
                        process={process}
                        config={config}
                        dispatch={dispatch}
                    />
                    <CardSettings config={config} dispatch={dispatch} />
                    <CompletedToggle
                        process={process}
                        config={config}
                        dispatch={dispatch}
                    />
                    <footer>
                        <IconTextButton
                            icon="Check"
                            text="Done"
                            size="xSmall"
                            variant="primary"
                            onClick={() => setOpen(false)}
                        />
                    </footer>
                </StyledEdit>
            </MinimalModal>
        </StyledSmall>
    )
}

const StyledSmall = styled.div`
    padding: 0.25rem 4vw;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
`

const StyledEdit = styled.div`
    padding: 0.5rem 4vw 1rem;

    > footer {
        padding-top: 1rem;
    }

    > footer button {
        width: 100%;
    }
`

export { ViewBarSmall }
