import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { AddRecordToProcess } from './ChecklistItems/AddRecordToProcess'
import { CreateProcess } from './ChecklistItems/CreateProcess'
import { CreateRecord } from './ChecklistItems/CreateRecord'
import { SetName } from './ChecklistItems/SetName'
import { SetOrgName } from './ChecklistItems/SetOrgName'
import { UpgradeFromSandbox } from './ChecklistItems/UpgradeFromSandbox'
import { ProgressBar } from './ProgressBar'

type Props = {
    done: number
    size: number
    closeModal: () => Promise<void>
}

export const ChecklistContent: FC<Props> = ({ done, size, closeModal }) => (
    <StyledChecklist>
        <ProgressBar done={done} size={size} />

        <div className="items">
            <UpgradeFromSandbox />
            <SetName />
            <SetOrgName />
            <CreateProcess closeModal={closeModal} />
            <CreateRecord closeModal={closeModal} />
            <AddRecordToProcess closeModal={closeModal} />
        </div>
    </StyledChecklist>
)

const StyledChecklist = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    width: 100%;
    height: 100%;

    .items {
        ${flex('column', 'flex-start', 'flex-start')};
        flex: 1;
        gap: 1rem;
        overflow-y: auto;
    }

    @media screen and (min-width: ${breakpoints.medium}) {
        height: inherit;
        width: inherit;
        max-width: 80vw;
        max-height: 80vh;
    }
`
