import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { compact } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { ActionTimelineCardQuery } from '../ActionCard/__generated__/query'
import { Source } from './types'

export const useDragActionCard = (
    action: ActionTimelineCardQuery['action']
) => {
    const [dragging, setDragging] = useState<boolean>(false)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const el = ref.current

        if (!el) return

        return draggable({
            element: el,
            onDragStart: () => setDragging(true),
            onDrop: () => setDragging(false),
            getInitialData: () =>
                ({
                    action: {
                        id: action.id,
                        name: action.name,
                        assignedToId: match(action.assignedTo)
                            .with(
                                { __typename: 'UserPrincipal' },
                                ({ user: { id } }) => id
                            )
                            .otherwise(() => undefined),
                        description: action.description ?? undefined,
                        dueDate: action.dueDate ?? undefined,
                        extendedBy: action.extendedBy
                            ? {
                                  definitionId: action.extendedBy.definition.id,
                                  id: action.extendedBy.id,
                                  fields: compact(
                                      action.extendedBy.fields.map(field =>
                                          match(field)
                                              .with(
                                                  {
                                                      __typename:
                                                          'BusinessObjectBooleanField',
                                                  },
                                                  ({
                                                      booleanValue,
                                                      type,
                                                      fieldDefinition: { id },
                                                  }) => ({
                                                      id,
                                                      type,
                                                      value: booleanValue,
                                                  })
                                              )
                                              .with(
                                                  {
                                                      __typename:
                                                          'BusinessObjectSelectField',
                                                  },
                                                  ({
                                                      selectValue,
                                                      type,
                                                      fieldDefinition: { id },
                                                  }) => ({
                                                      id,
                                                      type,
                                                      value: selectValue,
                                                  })
                                              )
                                              .otherwise(() => undefined)
                                      )
                                  ),
                              }
                            : undefined,
                    },
                } satisfies Source),
        })
    }, [action])

    return { ref, dragging }
}
