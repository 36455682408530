import { usePossibleDelegateProcesses } from 'Hooks/usePossibleDelegateProcesses/usePossibleDelegateProcesses'
import { useMemo } from 'react'

export const useHasViableOutletProcess = (operatesUpon: string[]) => {
    const { processes } = usePossibleDelegateProcesses(operatesUpon)

    const filteredPossibleDelegateProcesses = useMemo(() => {
        return (processes ?? []).filter(
            process => process.untransformed || process.fields.length > 0
        )
    }, [processes])

    return {
        hasViableOutletProcess: filteredPossibleDelegateProcesses.length > 0,
    }
}
