import * as Accordion from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Text } from 'Components/Text'
import {
    SetBusinessObject,
    useBusinessObjectDetails,
} from 'Features/SetBusinessObject'
import { FC, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StyledItem, StyledTrigger } from './styles'

export const BusinessObjectFilterControls: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const businessObjectId = searchParams.get('businessObjectId')

    const updateBusinessObjectFilter = useCallback(
        (value: string) => {
            if (!value) {
                searchParams.delete('businessObjectId')
            } else {
                searchParams.set('businessObjectId', value)
            }

            setSearchParams(searchParams)
        },
        [searchParams, setSearchParams]
    )

    const { businessObjectLabel, businessObjectDefinitionId } =
        useBusinessObjectDetails(businessObjectId ?? undefined)

    return (
        <StyledItem value={'businessObject'} key={'businessObject'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Record:
                </Text>

                {businessObjectId ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledInputGroup>
                    <SetBusinessObject
                        onBusinessObjectSelect={({ businessObjectId }) => {
                            updateBusinessObjectFilter(businessObjectId)
                        }}
                        selection={{
                            businessObjectId: businessObjectId ?? undefined,
                            businessObjectLabel,
                            businessObjectDefinitionId,
                        }}
                        title="Filter by record"
                        buttonText="Filter by record"
                    />

                    <StyledClearButton
                        iconName={'Close'}
                        onClick={() => {
                            updateBusinessObjectFilter('')
                        }}
                        variant="secondary"
                    />
                </StyledInputGroup>
            </Accordion.Content>
        </StyledItem>
    )
}

const StyledInputGroup = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;
    padding-bottom: 1rem;
    padding-right: 1rem;

    button:first-of-type {
        width: 100%;
    }
`

const StyledClearButton = styled(IconButton)`
    padding: 0.6728rem;
`
