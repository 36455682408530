import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

type Props = {
    icon: IconName
    tooltip: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}
const MenuButton: FC<Props> = ({ icon, tooltip, onClick }) => (
    <Tooltip content={tooltip}>
        <Styled onClick={onClick}>
            <Icon name={icon} />
        </Styled>
    </Tooltip>
)

const Styled = styled.button`
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.brand['01'].normal};
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.brand['01'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.brand['01'].active};
    }

    svg {
        --icon-size: 1.25rem;
        width: var(--icon-size);
        height: var(--icon-size);
        min-width: var(--icon-size);
        min-height: var(--icon-size);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.ui['06'].normal};
    }
`

export { MenuButton }
