import {
    CreateBusinessObjectDefinitionField,
    PatchBusinessObjectDefinitionFieldDefaultInput,
} from '__generated__'
import { BusinessDomain_PatchBusinessObjectDefinitionMutationVariables } from './__generated__/Patch'
import { BusinessDomain_BusinessObjectDefinitionQuery } from './__generated__/query'

export type BusinessObjectDefinition = Exclude<
    BusinessDomain_BusinessObjectDefinitionQuery['businessObjectDefinition'],
    undefined | null
>

export type Fields = NonNullable<BusinessObjectDefinition>['fields']

type FieldTypes = Fields[number]['__typename']

export type Common = Pick<
    Fields[number],
    'type' | 'id' | 'name' | 'description'
>

export type Field<T extends FieldTypes> = Extract<
    Fields[number],
    { __typename: T }
>

type PatchOperationVariable =
    BusinessDomain_PatchBusinessObjectDefinitionMutationVariables['input']['operations'][number]

export type _PatchOperation = Exclude<
    {
        [K in keyof PatchOperationVariable]: {
            operation: K
        } & PatchOperationVariable[K]
    }[keyof PatchOperationVariable],
    null | undefined
>

// object needed here to make it spreadable
export type CreateFieldParams = object &
    Exclude<
        {
            [K in keyof CreateBusinessObjectDefinitionField]: {
                type: K
            } & CreateBusinessObjectDefinitionField[K] &
                (K extends 'list'
                    ? {
                          listOf: {
                              type: keyof CreateBusinessObjectDefinitionField
                          }
                      }
                    : {})
        }[keyof CreateBusinessObjectDefinitionField] & {
            name: string
            description?: string
        },
        undefined | null
    >

export type PatchOperation =
    | Exclude<_PatchOperation, { operation: 'addField' }>
    | {
          operation: 'addField'
          nextValue: CreateFieldParams
      }

export const isOperation =
    <O extends PatchOperation['operation']>(operation: O) =>
    (
        patch: PatchOperation
    ): patch is Extract<PatchOperation, { operation: O }> => {
        return patch.operation === operation
    }

export type PatchDefaultFieldType =
    keyof PatchBusinessObjectDefinitionFieldDefaultInput
