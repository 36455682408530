import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { CreateRelationModal } from 'Features/CreateRelationModal'
import { useFetchBusinessObjectsById, useModalControls } from 'Hooks'
import { FC, useMemo } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useRelationConstraints } from './useRelationConstraints'

// For the sake of simplicity, I am assuming that we will only relate exactly one type of
// business object to make data fetching and UI easier, even though the BE supports
// multiple BO types.

// When displaying UI for BO's to select, if the first field is a text field we use that, otherwise we just use the id.
// This should be improved when we come to dogfood it

export const RelationField: FC<FieldInputProps<'RelationFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useRelationConstraints(
        field.relationConstraints
    )

    const data = useFetchBusinessObjectsById(constraints.relationId)

    const modalControls = useModalControls()

    const options = useMemo(() => {
        return (
            data?.businessObjects.map(bo => {
                return {
                    id: bo.id,
                    label: bo.label,
                }
            }) ?? []
        )
    }, [data])

    return (
        <>
            <Field
                id={field.id}
                name={field.name}
                required={!!constraints.required}
                description={field.description ?? ''}
                constraintsTooltip={constraintsTooltip}
                errors={errors}
            >
                <Dropdown
                    renderContent={() => (
                        <>
                            <DropdownButton
                                text={'-- No Selection --'}
                                onSelect={() => onChange(undefined)}
                            />
                            {options.map(option => (
                                <DropdownButton
                                    key={option.id}
                                    text={option.label}
                                    onSelect={() => onChange(option.id)}
                                />
                            ))}
                        </>
                    )}
                    renderOpenDropdownButton={() => (
                        <StyledDropdownButton
                            icon="Dashboard"
                            text={
                                value
                                    ? options.find(
                                          option => option.id === value
                                      )?.label ?? ''
                                    : `Select: ${field.name}`
                            }
                            maxLength={50}
                        />
                    )}
                    variant="light"
                />

                <IconButton
                    iconName="AddSmall"
                    title={`Create new ${
                        data?.businessObjects[0]?.definition.name ?? 'Relation'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        modalControls.openModal()
                    }}
                />
            </Field>
            <CreateRelationModal
                businessObjectDefinitionId={constraints.relationId}
                onBusinessObjectCreated={businessObject => {
                    onChange(businessObject.id)
                }}
                {...modalControls}
            />
        </>
    )
}

const StyledDropdownButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    flex: 1;
`
