import { IconTextButton } from 'Components/Button'
import { ComponentProps, FC, forwardRef } from 'react'

type Props = ComponentProps<typeof IconTextButton>

export const IconTextButtonCell: FC<Props> = forwardRef<
    HTMLButtonElement,
    Props
>(({ text, icon, className = '', onClick }, ref) => (
    <td className={className}>
        <IconTextButton ref={ref} icon={icon} text={text} onClick={onClick} />
    </td>
))
