import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    updating: boolean
    options: { id: string; name: string }[]
    onSelect: (id: string) => void
}

const SelectBackstop: FC<Props> = ({ updating, options, onSelect }) => {
    return (
        <Styled>
            <Text as="h4" variant="bold-4">
                Select a backstop workflow
            </Text>
            <ul>
                {options.map(op => (
                    <li key={op.id}>
                        <IconTextButton
                            disabled={updating}
                            icon="Process"
                            text={op.name}
                            onClick={() => onSelect(op.id)}
                        />
                    </li>
                ))}
            </ul>
        </Styled>
    )
}

const Styled = styled.section`
    > ul {
        padding-top: 1rem;
    }

    > ul > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};

        button {
            width: 100%;
            justify-content: flex-start;
        }
    }

    > ul > li:first-child {
        border-top: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    }
`

export { SelectBackstop }
