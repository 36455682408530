export const defaultTheme = {
    borderRadius: {
        small: '4px',
        large: '8px',
    },
    animation: {
        buttonTransitionDuration: '0.1s', //Doherty Threshold
        mid: '0.3s',
    },
    spacing: {
        '01': '.125rem', // 2px
        '02': '.25rem', // 4px
        '03': '.5rem', // 8px
        '03.5': '.625rem', // 10px
        '04': '.75rem', // 12px
        '05': '1rem', // 16px
        '06': '1.5rem', // 24px
        '07': '2rem', // 32px
        '08': '2.5rem', // 40px
        '09': '3rem', // 48px
        '10': '4rem', // 64px
        '10.25': '4.5rem', // 72px
        '11': '6rem', // 96px
        '12': '8rem', // 126px
        '13': '10rem', // 160px
    },
    structure: {
        navColumnWidth: '22rem',
        safeWidth: `calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))`,
        safeHeight: `calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
    },
    typography: {
        fontFamily: `'Lexend', sans-serif;`,
        weight: {
            regular: '400',
            bold: '600',
        },
        lineHeight: {
            regular: '125%',
        },
        scale: {
            '00': '0.625rem', // 10px
            '01': '0.75rem', // 12px
            '02': '0.875rem', // 14px
            '03': '1rem', // 16px
            '04': '1.125rem', // 18px
            '05': '1.25rem', // 20px
            '06': '1.5rem', // 24px
            '07': '1.75rem', // 28px
            '08': '2rem', // 32px
            '09': '2.5rem', // 40px
            '10': '3rem', // 48px
            '11': '3.5rem',
            '12': '4rem', // 64px
            '13': '4.5rem',
            '14': '5rem', // 80px
            '15': '5.5rem',
            '16': '6rem', // 96px
        },
    },
} as const
