import { Toggle } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const BoolField: FC<
    Field<'BooleanFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: NonNullable<
                    PatchBusinessObjectDefinitionFieldDefaultInput['boolean']
                >
            ) => void
        }
> = ({ handleDefaultChanged, booleanDefaultValue, ...common }) => (
    <AbstractField
        {...common}
        defaultDisplay={
            common.isEditing ? (
                <div>
                    <Toggle
                        name={common.type + '||' + common.id}
                        id={common.id}
                        checked={booleanDefaultValue}
                        onCheckedChange={bool => {
                            handleDefaultChanged({
                                fieldId: common.id,
                                previousValue: booleanDefaultValue,
                                nextValue: bool,
                            })
                        }}
                    />
                </div>
            ) : (
                <Text as="p" variant="bold-4">{`${booleanDefaultValue}`}</Text>
            )
        }
    />
)
