import { Select } from 'Components/Select'
import { first } from 'lodash'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { FieldInputProps } from '../../types'

export const Selects: FC<FieldInputProps<'SelectFieldDefinition'>> = ({
    field,
    value,
    onChange,
}) => {
    const selectedOption = first(value)

    return (
        <Select
            id={field.id}
            value={selectedOption}
            onValueChange={pipe(v => [v], onChange)}
            name={field.name}
            options={[
                {
                    disabled: false,
                    text: '-- select one or none --',
                    value: '',
                },
                ...field.selectOptions.map(option => ({
                    text: option.value,
                    value: option.id,
                    disabled: false,
                })),
            ]}
        />
    )
}
