import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CenteredSpinner } from 'Components/Spinner'
import { BusinessObjectDefinitionDisplay } from 'Features/BusinessObjectDefinitionDisplay'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { Breadcrumb } from '../../Breadcrumb/Breadcrumb'
import { Delete } from './Delete'
import { Edit } from './Edit'
import { useModelManager_SingleBusinessObjectDefinitionQuery } from './__generated__/q'

type Props = {
    id: string
    navOptions: {
        selected: number
        options: {
            text: string
            path: string
        }[]
    }
}

const SingleDefinition: FC<Props> = ({ id, navOptions }) => {
    const navigate = useNavigate()
    const { data, loading } =
        useModelManager_SingleBusinessObjectDefinitionQuery({
            variables: { input: { id } },
        })
    return match({ data, loading })
        .with(
            {
                data: { businessObjectDefinition: P.not(P.nullish) },
                loading: false,
            },
            ({ data }) => (
                <Styled>
                    <header>
                        <Breadcrumb area="datasets" single={navOptions} />
                        <div />
                        <menu>
                            <Delete
                                definition={data.businessObjectDefinition}
                            />
                            <Edit data={data} />
                            <IconTextButton
                                icon="Cube"
                                size="xSmall"
                                text="View records"
                                variant="primary"
                                onClick={() => navigate(`/datasets/${id}`)}
                            />
                        </menu>
                    </header>
                    <BusinessObjectDefinitionDisplay id={id} />
                </Styled>
            )
        )
        .otherwise(() => <CenteredSpinner />)
}

const Styled = styled.section`
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }

    > article {
        height: 100%;
        overflow: auto;
    }
`

export { SingleDefinition }
