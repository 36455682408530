import { flex, styled } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { countryCodeOptions } from 'Utils'
import { fromEvent } from '../../../../Utils'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useTelephoneConstraints } from './useTelephoneConstraints'

export const TelephoneField: FC<
    FieldInputProps<'TelephoneFieldDefinition'>
> = ({ field, value, onChange, errors }) => {
    const { constraints, constraintsTooltip } = useTelephoneConstraints({
        telephoneConstraints: field.telephoneConstraints,
    })

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledTelInput>
                <Select
                    id={field.id + 'countrycode'}
                    value={value?.countryCode ?? undefined}
                    onValueChange={countryCode =>
                        onChange({
                            countryCode,
                            number: value?.number,
                        })
                    }
                    name="Country Code"
                    options={countryCodeOptions}
                />
                <TextInput
                    value={value?.number ?? undefined}
                    id={field.id}
                    onChange={pipe(fromEvent, number =>
                        onChange({
                            number,
                            countryCode: value?.countryCode,
                        })
                    )}
                    required={constraints.required}
                    className="tel-number-input"
                />
            </StyledTelInput>
        </Field>
    )
}

const StyledTelInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .tel-number-input {
        flex: 1;
        height: 2.75rem;
    }
`
