import { DocumentMetadata } from 'Components/Documents'
import { countBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

/*
 *   Currently only filtering on file types as that has the most utility.
 *   Could also be worth doing : addedBy, addedOn, size etc.
 */

export const useFilter = (documents: DocumentMetadata[]) => {
    const [fileTypes, setFileTypes] = useState<Set<string>>(() => new Set())

    const toggleFileTypeFilter = useCallback((fileType: string) => {
        setFileTypes(fileTypes => {
            const next = new Set(fileTypes)

            if (next.has(fileType)) {
                next.delete(fileType)
            } else {
                next.add(fileType)
            }

            return next
        })
    }, [])

    const availableFileTypes = useMemo(
        () => Object.keys(countBy(documents, 'fileType')),
        [documents]
    )

    const filteredDocuments = useMemo(() => {
        if (fileTypes.size === 0) {
            return documents
        }

        return documents.filter(document => {
            return fileTypes.has(document.fileType)
        })
    }, [fileTypes, documents])

    return {
        filteredDocuments,
        availableFileTypes,
        fileTypeFilter: fileTypes,
        toggleFileTypeFilter,
    }
}
