import { TextAreaInput, TextInput } from 'Components/Input'
import { FC } from 'react'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from '../../../../CreateBusinessObjectModal/Hooks/useCreateFormState'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useTextConstraints } from './useTextConstraints'

export const TextField: FC<BODField<'TextFieldDefinition'>> = ({
    textConstraints,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useTextConstraints({
        textConstraints,
    })

    return (
        <Field
            id={common.id}
            name={common.name}
            description={common.description ?? ''}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            {constraints.max && constraints.max <= 70 ? (
                <TextInput
                    name={common.type + '||' + common.id}
                    value={state[common.id].value as string}
                    id={common.id}
                    hasError={!!state[common.id].errors}
                    onChange={e => {
                        dispatch({
                            type: 'updateTextField',
                            payload: { id: common.id, value: e.target.value },
                        })
                    }}
                    required={constraints.required}
                />
            ) : (
                <TextAreaInput
                    name={common.type + '||' + common.id}
                    value={state[common.id].value as string}
                    id={common.id}
                    hasError={!!state[common.id].errors}
                    onChange={e => {
                        dispatch({
                            type: 'updateTextField',
                            payload: { id: common.id, value: e.target.value },
                        })
                    }}
                    required={constraints.required}
                />
            )}
        </Field>
    )
}
