import { CollapsableLeftNav } from 'Components/CollapsableLeftNav'
import { NavLinkList } from 'Components/NavLinkList'
import { BusinessObjectDefinitionCreator } from 'Features/BusinessObjectDefinitionCreator'
import { FC } from 'react'
import { Route } from '../../../types'

type Props = {
    routes: Route[]
}

const BusinessObjectsNav: FC<Props> = ({ routes }) => {
    return (
        <CollapsableLeftNav
            title="Datasets"
            feature={'businessObject'}
            content={
                <>
                    <NavLinkList
                        items={routes.map(({ name, description, slug }) => ({
                            icon: 'Cube',
                            heading: name,
                            description,
                            to: slug,
                        }))}
                    />
                    <BusinessObjectDefinitionCreator navStyleButton />
                </>
            }
        />
    )
}

export { BusinessObjectsNav }
