import { styled } from 'Adapters/Freestyled'
import { Drawer } from 'Components/Drawer'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { CenteredSpinner } from 'Components/Spinner'
import { Tabs } from 'Components/Tabs'
import {
    Actions,
    Documents,
    History,
    Overview,
    Processes,
} from 'Features/SingleBusinessObjectTabs'
import { Updates } from 'Features/Updates'
import { noop } from 'lodash'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { NotInSandbox } from '../NotInSandbox'
import { Details } from './Details'
import { BOHeader } from './Header'
import { tabs } from './config'

type Props = {
    isOpen: boolean
    close: () => void
    id: string | undefined
    tab: keyof typeof tabs
    onSelect: (tab: keyof typeof tabs) => void
    businessObjectDefinitionId?: string
    processId?: string
}

const Large: FC<Props> = ({
    isOpen,
    close,
    id,
    tab,
    onSelect,
    processId,
    businessObjectDefinitionId,
}) => (
    <Drawer
        open={isOpen}
        onOpenChange={o => !o && close()}
        returnFocus={noop}
        content={
            id ? (
                <Styled>
                    <BOHeader
                        id={id}
                        onDelete={close}
                        showDelete={!processId}
                    />
                    <Tabs
                        tabs={{
                            overview: match(processId)
                                .with(P.nullish, () => ({
                                    label: '',
                                    content: <></>,
                                    hidden: true,
                                }))
                                .otherwise(pid => ({
                                    label: tabs.overview.label,
                                    content: (
                                        <Overview
                                            businessObjectId={id}
                                            processId={pid}
                                            onRemoveFromProcess={close}
                                        />
                                    ),
                                })),
                            details: {
                                label: tabs.details.label,
                                content: <Details businessObjectId={id} />,
                            },
                            actions: {
                                label: tabs.actions.label,
                                content: (
                                    <Actions
                                        businessObjectId={id}
                                        actionsQueryInput={{
                                            relatesTo: {
                                                businessObjectId: id,
                                            },
                                        }}
                                    />
                                ),
                            },
                            ...(!processId &&
                                businessObjectDefinitionId && {
                                    processes: {
                                        label: tabs.processes.label,
                                        content: (
                                            <StyledProcesses
                                                businessObjectId={id}
                                                businessObjectDefinitionId={
                                                    businessObjectDefinitionId
                                                }
                                            />
                                        ),
                                    },
                                }),
                            documents: {
                                label: tabs.documents.label,
                                content: (
                                    <VerticalScrollArea
                                        height="100%"
                                        width="100%"
                                    >
                                        <NotInSandbox>
                                            <Documents businessObjectId={id} />
                                        </NotInSandbox>
                                    </VerticalScrollArea>
                                ),
                            },
                            updates: {
                                label: tabs.updates.label,
                                content: (
                                    <StyledUpdates businessObjectId={id} />
                                ),
                            },
                            history: {
                                label: tabs.history.label,
                                content: (
                                    <StyledHistory businessObjectId={id} />
                                ),
                            },
                        }}
                        current={tab}
                        onSelect={onSelect}
                    />
                </Styled>
            ) : (
                <CenteredSpinner />
            )
        }
    />
)

const Styled = styled.div`
    display: flex;
    flex-direction: column;

    > header {
        display: flex;
    }

    > nav {
        padding: 0 1rem;
        background: ${({ theme }) => theme.palette.ui['02'].normal};
    }
`

const StyledUpdates = styled(Updates)`
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem 0;
    flex: 1;
`

const StyledHistory = styled(History)`
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem 0;
    flex: 1;
    overflow: auto;
`

const StyledProcesses = styled(Processes)`
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem 0;
    flex: 1;
    overflow: auto;
`

export { Large }
