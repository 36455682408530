import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { Field } from '../Field'
import { useProcessCreator } from '../useProcessCreator'
import { saveAIAnswers } from '../useProcessCreator/persistAIAnswers'
import { industries } from './options/industries'
import { sizes } from './options/sizes'
import { teams } from './options/teams'

const AIStep1: FC = () => {
    const {
        state: { ai },
        dispatch,
    } = useProcessCreator()

    const { current: nameInputId } = useRef(v4())
    const { current: sizeInputId } = useRef(v4())
    const { current: teamInputId } = useRef(v4())

    const isValid = ai.industry && ai.size && ai.team

    return (
        <Styled>
            <header>
                <Text as="h3" variant="bold-2">
                    Tell us about yourself
                </Text>
            </header>
            <fieldset>
                <Field
                    label="What industry are you in?"
                    htmlFor={nameInputId}
                    input={
                        <Select
                            id={nameInputId}
                            name="industry"
                            value={ai.industry}
                            placeholder="Industry"
                            onValueChange={value =>
                                dispatch({
                                    type: 'aiInputChanged',
                                    payload: {
                                        key: 'industry',
                                        value,
                                    },
                                })
                            }
                            options={industries.map(industry => ({
                                value: industry,
                                text: industry,
                            }))}
                        />
                    }
                />
                <Field
                    label="How many people work in your business?"
                    htmlFor={sizeInputId}
                    input={
                        <Select
                            id={sizeInputId}
                            name="size"
                            value={ai.size}
                            placeholder="Business Size"
                            onValueChange={value =>
                                dispatch({
                                    type: 'aiInputChanged',
                                    payload: {
                                        key: 'size',
                                        value,
                                    },
                                })
                            }
                            options={sizes.map(size => ({
                                value: size,
                                text: size,
                            }))}
                        />
                    }
                />
                <Field
                    label="What does your team do?"
                    htmlFor={teamInputId}
                    input={
                        <Select
                            id={teamInputId}
                            name="team"
                            value={ai.team}
                            placeholder="Your team"
                            onValueChange={value =>
                                dispatch({
                                    type: 'aiInputChanged',
                                    payload: {
                                        key: 'team',
                                        value,
                                    },
                                })
                            }
                            options={teams.map(team => ({
                                value: team,
                                text: team,
                            }))}
                        />
                    }
                />
            </fieldset>
            <footer>
                <IconTextButton
                    icon="AngleRight"
                    iconRight
                    text="Next step"
                    onClick={() => {
                        saveAIAnswers({
                            industry: ai.industry,
                            size: ai.size,
                            team: ai.team,
                        })
                        dispatch({
                            type: 'stepChanged',
                            payload: { step: 'ai-2' },
                        })
                    }}
                    variant="primary"
                    disabled={!isValid}
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    width: 30rem;
    max-width: 100%;

    fieldset {
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
    }

    footer {
        padding-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
`

export { AIStep1 }
