import * as Types from '../../../../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleProcess_UpdateBackstopMutationVariables = Types.Exact<{
  input: Types.PatchProcessInput;
}>;


export type SingleProcess_UpdateBackstopMutation = { __typename?: 'Mutation', patchProcess: { __typename?: 'Process', id: string, backstop?: { __typename?: 'Process', id: string } | null } };

export type SingleProcess_BackstopProcessesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SingleProcess_BackstopProcessesQuery = { __typename?: 'Query', processes: Array<{ __typename?: 'Process', id: string, name: string, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string }> }> };


export const SingleProcess_UpdateBackstopDocument = gql`
    mutation SingleProcess_UpdateBackstop($input: PatchProcessInput!) {
  patchProcess(input: $input) {
    id
    backstop {
      id
    }
  }
}
    `;
export type SingleProcess_UpdateBackstopMutationFn = Apollo.MutationFunction<SingleProcess_UpdateBackstopMutation, SingleProcess_UpdateBackstopMutationVariables>;

/**
 * __useSingleProcess_UpdateBackstopMutation__
 *
 * To run a mutation, you first call `useSingleProcess_UpdateBackstopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleProcess_UpdateBackstopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleProcessUpdateBackstopMutation, { data, loading, error }] = useSingleProcess_UpdateBackstopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSingleProcess_UpdateBackstopMutation(baseOptions?: Apollo.MutationHookOptions<SingleProcess_UpdateBackstopMutation, SingleProcess_UpdateBackstopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingleProcess_UpdateBackstopMutation, SingleProcess_UpdateBackstopMutationVariables>(SingleProcess_UpdateBackstopDocument, options);
      }
export type SingleProcess_UpdateBackstopMutationHookResult = ReturnType<typeof useSingleProcess_UpdateBackstopMutation>;
export type SingleProcess_UpdateBackstopMutationResult = Apollo.MutationResult<SingleProcess_UpdateBackstopMutation>;
export type SingleProcess_UpdateBackstopMutationOptions = Apollo.BaseMutationOptions<SingleProcess_UpdateBackstopMutation, SingleProcess_UpdateBackstopMutationVariables>;
export const SingleProcess_BackstopProcessesDocument = gql`
    query SingleProcess_BackstopProcesses {
  processes {
    id
    name
    operatesUpon {
      id
    }
  }
}
    `;

/**
 * __useSingleProcess_BackstopProcessesQuery__
 *
 * To run a query within a React component, call `useSingleProcess_BackstopProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleProcess_BackstopProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleProcess_BackstopProcessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSingleProcess_BackstopProcessesQuery(baseOptions?: Apollo.QueryHookOptions<SingleProcess_BackstopProcessesQuery, SingleProcess_BackstopProcessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleProcess_BackstopProcessesQuery, SingleProcess_BackstopProcessesQueryVariables>(SingleProcess_BackstopProcessesDocument, options);
      }
export function useSingleProcess_BackstopProcessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleProcess_BackstopProcessesQuery, SingleProcess_BackstopProcessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleProcess_BackstopProcessesQuery, SingleProcess_BackstopProcessesQueryVariables>(SingleProcess_BackstopProcessesDocument, options);
        }
export type SingleProcess_BackstopProcessesQueryHookResult = ReturnType<typeof useSingleProcess_BackstopProcessesQuery>;
export type SingleProcess_BackstopProcessesLazyQueryHookResult = ReturnType<typeof useSingleProcess_BackstopProcessesLazyQuery>;
export type SingleProcess_BackstopProcessesQueryResult = Apollo.QueryResult<SingleProcess_BackstopProcessesQuery, SingleProcess_BackstopProcessesQueryVariables>;