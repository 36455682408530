import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { ActionsList } from 'Features/ActionsList'
import { CreateActionModal } from 'Features/CreateActionModal'
import { QueryActionsInput } from '__generated__'
import { FC, useRef, useState } from 'react'
import { SingleBusinessObjectActionsQuery } from './__generated__/SingleBusinessObjectActions'

type Props = {
    actions: SingleBusinessObjectActionsQuery['actions']
    actionsQueryInput: QueryActionsInput
}

const Small: FC<Props> = ({ actions, actionsQueryInput }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    return (
        <Styled>
            <div>
                {!!actions.length ? (
                    <ActionsList actions={actions} />
                ) : (
                    <StyledNoActions>
                        <Text as="h3" variant="bold-4">
                            No Actions
                        </Text>
                        <Text as="p" variant="regular-5">
                            This record has no actions associated with it.
                            Actions will be automatically created for you as it
                            progresses through workflows. You can also create a
                            custom action by clicking the button below.
                        </Text>
                    </StyledNoActions>
                )}
            </div>
            <footer>
                <IconTextButton
                    icon="Plus"
                    text="Create action"
                    onClick={() => setOpen(true)}
                    ref={ref}
                    variant="primary"
                />

                <CreateActionModal
                    open={open}
                    closeModal={() => setOpen(false)}
                    onOpenChange={setOpen}
                    returnFocus={() => ref.current?.focus()}
                    relatesTo={actionsQueryInput.relatesTo ?? undefined}
                />
            </footer>
        </Styled>
    )
}
const Styled = styled.div`
    padding: 1rem 4vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > div {
        flex: 1;
        overflow: auto;
    }

    > footer {
        padding-top: 1rem;
    }

    > footer button {
        width: 100%;
    }
`
const StyledNoActions = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        padding-bottom: 0.5rem;
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { Small }
