import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { PrincipalName } from '../../../../../../../../../Features/Principal/Name'
import { CriterionConditions } from '../../CriterionConditions/CriterionConditions'
import { generateDueTimeString } from '../../helpers'
import { FieldDefDictionary } from '../../types'
import { StyledCriterion } from '../styles'
import { ActionCriterionProps } from '../types'

export const ActionCriterion: FC<{
    criterion: ActionCriterionProps
    fieldDefById: FieldDefDictionary
}> = ({ criterion, fieldDefById }) => {
    const { description, defaultDueSeconds, defaultAssignee } = criterion

    return (
        <StyledCriterion>
            <div className="action">
                <Icon name="CheckboxTicked" />
                <Text as="p" variant="bold-6">
                    Action
                </Text>
            </div>

            <Text as="p" variant="regular-4">
                {description}
            </Text>

            {defaultDueSeconds ? (
                <Text as="p" variant="regular-6" className="default-due-time">
                    {generateDueTimeString(defaultDueSeconds)}
                </Text>
            ) : null}

            {defaultAssignee ? (
                <Text as="p" variant="regular-6">
                    Assigned to <PrincipalName principal={defaultAssignee} />
                </Text>
            ) : null}

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </StyledCriterion>
    )
}
