import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC, useRef, useState } from 'react'
import { ControlledModal } from '../../../../Components/Modal'
import { useModalControls } from '../../../../Hooks'
import { has } from '../../../../Utils'
import {
    FieldType,
    createNewField,
    createPendingFieldValidation,
} from '../../../../Utils/BusinessObjectDefinition'
import { AddFieldContent } from '../../../BusinessObjectDefinitionEditor/AddFieldContent'
import { BusinessObjectDefinitionFieldEditor } from '../../../BusinessObjectDefinitionFieldEditor'
import { useProcessCreator } from '../../useProcessCreator'
import { Criterion } from './Criterion'
import { DataCollectionCriterion } from './DataCollectionCriterion'
import { useHasViableOutletProcess } from './useHasViableOutletProcess'

type Props = {
    phaseIndex: number
}

const Criteria: FC<Props> = ({ phaseIndex }) => {
    const {
        state: { input, fields, relationOptions, userOptions, currencyOptions },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const { hasViableOutletProcess } = useHasViableOutletProcess(
        input.operatesUpon
    )

    const addFieldRef = useRef<HTMLButtonElement>(null)
    const addFieldModal = useModalControls()
    const [editingFieldType, setEditingFieldType] = useState<
        FieldType | undefined
    >(undefined)

    return (
        <Styled>
            <header>
                <Text as="h4" variant="bold-5">
                    Criteria
                </Text>
            </header>
            <ul>
                {fields
                    .filter(
                        field =>
                            has(field.requiredBy, 'phase') &&
                            field.requiredBy.phase === phaseIndex
                    )
                    .map((field, i) => (
                        <DataCollectionCriterion
                            key={i}
                            phaseIndex={phaseIndex}
                            criterionIndex={i}
                            field={field.field}
                        />
                    ))}
                {input.phases[phaseIndex].criteria.map((_, i) => (
                    <Criterion
                        key={i}
                        phaseIndex={phaseIndex}
                        criterionIndex={i}
                    />
                ))}
            </ul>
            <footer>
                <StyledButton
                    icon="Plus"
                    text="Action"
                    size="xSmall"
                    onClick={() => {
                        dispatch({
                            type: 'addActionCriterion',
                            payload: { phaseIndex },
                        })
                    }}
                />
                {isProcessFirst ? (
                    <>
                        <StyledButton
                            icon="Plus"
                            text="Data"
                            size="xSmall"
                            onClick={addFieldModal.openModal}
                            ref={addFieldRef}
                        />
                        <ControlledModal
                            title="Collect data"
                            description="What type of data would you like to collect?"
                            {...addFieldModal}
                            onOpenChange={addFieldModal.setOpen}
                            renderContent={() => (
                                <AddFieldContent
                                    onFieldAdded={setEditingFieldType}
                                />
                            )}
                            returnFocus={() => addFieldRef.current?.focus()}
                        />
                        <BusinessObjectDefinitionFieldEditor
                            onOpenChange={open => {
                                if (!open) {
                                    setEditingFieldType(undefined)
                                }
                            }}
                            onSubmit={({ field }) => {
                                dispatch({
                                    type: 'fieldAdded',
                                    payload: {
                                        requiredBy: { phase: phaseIndex },
                                        field,
                                    },
                                })
                                setEditingFieldType(undefined)
                                addFieldModal.closeModal()
                            }}
                            hideRequired
                            relationOptions={relationOptions}
                            userOptions={userOptions}
                            currencyOptions={currencyOptions}
                            {...(editingFieldType !== undefined
                                ? {
                                      field: createNewField(editingFieldType),
                                      validation:
                                          createPendingFieldValidation(
                                              editingFieldType
                                          ),
                                  }
                                : {
                                      field: undefined,
                                      validation: undefined,
                                  })}
                        />
                    </>
                ) : (
                    <StyledButton
                        icon="Plus"
                        text="Required field"
                        size="xSmall"
                        onClick={() => {
                            dispatch({
                                type: 'addFieldConditionCriterion',
                                payload: { phaseIndex },
                            })
                        }}
                    />
                )}

                <StyledButton
                    icon="Plus"
                    text="Sub-workflow"
                    size="xSmall"
                    onClick={() => {
                        dispatch({
                            type: 'addProcessDelegateCriterion',
                            payload: { phaseIndex },
                        })
                    }}
                    disabled={!hasViableOutletProcess}
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    flex: 1;
    overflow: auto;
    margin: 0 1rem;

    > ul {
        padding: 0.25rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > footer {
        padding-top: 0.25rem;
    }

    > footer button {
        width: 100%;
    }
`

const StyledButton = styled(IconTextButton)`
    width: 100%;
    justify-content: flex-start;
`

export { Criteria }
