import { styled } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { countryCodeOptions } from 'Utils'
import {
    TelephoneField as Field,
    TelephoneFieldValidation,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: TelephoneFieldValidation
    onSubmit: OnSubmit<Field, TelephoneFieldValidation>
    onClose: () => void

    hideRequired: boolean
}

const TelephoneField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, TelephoneFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <TelephoneSubFields
                defaultValue={{
                    code: {
                        value:
                            state.field.defaultValue?.countryCode ?? undefined,
                        onChange: code => {
                            setState(prev => ({
                                ...prev,
                                field: {
                                    ...prev.field,
                                    defaultValue: {
                                        ...prev.field.defaultValue,
                                        countryCode: code,
                                    },
                                },
                            }))
                        },
                    },
                    number: {
                        value: state.field.defaultValue?.number ?? '',
                        onChange: value => {
                            setState(prev => ({
                                ...prev,
                                field: {
                                    ...prev.field,
                                    defaultValue: {
                                        ...prev.field.defaultValue,
                                        number: value,
                                    },
                                },
                            }))
                        },
                    },
                }}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type SubFieldsProps = {
    defaultValue: {
        code: {
            value: string | undefined
            onChange: (code: string) => void
        }
        number: {
            value: string
            onChange: (value: string) => void
        }
    }
}

const TelephoneSubFields: FC<SubFieldsProps> = ({ defaultValue }) => {
    const { current: defaultCodeFieldId } = useRef(v4())
    const { current: defaultNumberFieldId } = useRef(v4())

    return (
        <InputWrapper
            label="Default value"
            description="If set this will be the default phone number when a new record is created."
            fieldset
        >
            <StyledTelInput>
                <Select
                    id={defaultCodeFieldId}
                    value={defaultValue.code.value}
                    onValueChange={defaultValue.code.onChange}
                    name={'Country Code'}
                    options={countryCodeOptions}
                />
                <TextInput
                    value={defaultValue.number.value}
                    id={defaultNumberFieldId}
                    onChange={({ currentTarget: { value } }) => {
                        defaultValue.number.onChange(value)
                    }}
                />
            </StyledTelInput>
        </InputWrapper>
    )
}

const StyledTelInput = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    input {
        flex: 1;
        height: 2.75rem;
    }
`

export { TelephoneField, TelephoneSubFields }
