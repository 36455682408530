import { FC, useMemo } from 'react'
import { useFetchBusinessObjectsById } from '../../Hooks'
import { Select } from '../Select'

type Props = {
    index: number
    businessObjectDefinitionId: string
    relationValue: string | undefined
    onRelationSelected: (relationId: string) => void
}

export const RelationSelector: FC<Props> = ({
    businessObjectDefinitionId,
    index,
    relationValue,
    onRelationSelected,
}) => {
    const { businessObjects } = useFetchBusinessObjectsById(
        businessObjectDefinitionId
    )

    const options = useMemo(() => {
        return (
            businessObjects.map(bo => {
                return {
                    value: bo.id,
                    text: bo.label,
                }
            }) ?? []
        )
    }, [businessObjects])

    return (
        <Select
            id={`select-value-${index}`}
            value={relationValue}
            onValueChange={onRelationSelected}
            name="operator"
            options={options}
        />
    )
}
