import { keyBy } from 'lodash'
import { useMemo } from 'react'
import { Operator } from '../../__generated__'
import { operatorsByFieldType } from './operators'
import {
    stateComparatorToValidated,
    ValidatedComparator,
} from './parse-comparator'
import { ComparatorState } from './types'
import { DeepFilterableField, FilterableField } from './useFilterableFields'

type Props = {
    comparators: Partial<ComparatorState>[]
    availableFields: FilterableField[]
    availableDeepFields: DeepFilterableField[]
}

export type DisplayComparator = {
    comparator: Partial<ComparatorState>
    validOperators: Operator[]
    valueSelectorField: FilterableField | undefined
    validation: ValidatedComparator
}

type ComparatorDisplay = {
    displayComparators: DisplayComparator[]
}

export const useComparatorDisplay = ({
    comparators,
    availableFields,
    availableDeepFields,
}: Props): ComparatorDisplay => {
    const parsedComparators = useMemo(
        () => comparators.map(stateComparatorToValidated),
        [comparators]
    )

    const allAvailableFieldsById = useMemo(
        () =>
            keyBy(
                [
                    ...(availableFields ?? []),
                    ...(availableDeepFields?.flatMap(({ fields }) => fields) ??
                        []),
                ],
                f => f.id
            ),
        [availableFields, availableDeepFields]
    )

    const displayComparators = useMemo(
        () =>
            comparators.map((comparator, index) => {
                const valueSelectorField =
                    comparator.valueSelector &&
                    allAvailableFieldsById[comparator.valueSelector.fieldId]
                const validOperators =
                    (valueSelectorField &&
                        operatorsByFieldType(valueSelectorField.type)) ??
                    []
                return {
                    comparator,
                    validOperators,
                    valueSelectorField,
                    validation: parsedComparators[index]!, // mapped from same source array, guaranteed to be same length
                }
            }),
        [comparators, parsedComparators, allAvailableFieldsById]
    )

    return { displayComparators }
}
