import { Column } from 'Components/GridTable'
import {
    sortBos,
    sortableDynamicColumns,
} from 'Features/SortBusinessObject/sortBos'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { useMemo, useState } from 'react'
import { match } from 'ts-pattern'

type Cols = {
    [index: string]: Column
}

type Sort = {
    by: string
    asc: boolean
}

const enabledDynamicColumns: BusinessObjectTableItemFragment['fields'][number]['__typename'][] =
    [
        'BusinessObjectSelectField',
        'BusinessObjectBooleanField',
        'BusinessObjectDocumentField',
        'BusinessObjectEmailField',
        'BusinessObjectNumberField',
        'BusinessObjectTelephoneField',
        'BusinessObjectTextField',
        'BusinessObjectUrlField',
        'BusinessObjectCurrencyField',
        'BusinessObjectUserField',
        'BusinessObjectRelationField',
        'BusinessObjectDateField',
        'BusinessObjectListField',
    ]

const useCols = (objs: BusinessObjectTableItemFragment[]) => {
    const [sort, setSort] = useState<Sort>({ by: 'label', asc: false })

    const sorted = useMemo(() => sortBos(sort)(objs), [objs, sort])

    const columns: Cols = {
        label: {
            header: match(sort)
                .with({ by: 'label', asc: true }, () => ({
                    label: 'Label',
                    icon: 'AngleDown',
                    onClick: () => setSort({ by: 'label', asc: false }),
                }))
                .with({ by: 'label', asc: false }, () => ({
                    label: 'Label',
                    icon: 'AngleUp',
                    onClick: () => setSort({ by: 'label', asc: true }),
                }))
                .otherwise(() => ({
                    label: 'Label',
                    onClick: () => setSort({ by: 'label', asc: false }),
                })),
        },
        ...objs[0]?.fields?.reduce(
            (acc, field) =>
                enabledDynamicColumns.includes(field.__typename)
                    ? {
                          ...acc,
                          [field.fieldDefinition.name]: {
                              header: {
                                  label: field.fieldDefinition.name,
                                  ...match(field)
                                      .when(
                                          ({ __typename }) =>
                                              sortableDynamicColumns.includes(
                                                  __typename
                                              ),
                                          f =>
                                              match(sort)
                                                  .with(
                                                      {
                                                          by: f.fieldDefinition
                                                              .name,
                                                          asc: true,
                                                      },
                                                      () => ({
                                                          icon: 'AngleDown',
                                                          onClick: () => {
                                                              setSort({
                                                                  by: f
                                                                      .fieldDefinition
                                                                      .name,
                                                                  asc: false,
                                                              })
                                                          },
                                                      })
                                                  )
                                                  .with(
                                                      {
                                                          by: f.fieldDefinition
                                                              .name,
                                                          asc: false,
                                                      },
                                                      () => ({
                                                          icon: 'AngleUp',
                                                          onClick: () =>
                                                              setSort({
                                                                  by: f
                                                                      .fieldDefinition
                                                                      .name,
                                                                  asc: true,
                                                              }),
                                                      })
                                                  )
                                                  .otherwise(() => ({
                                                      icon: 'AngleUp',
                                                      inactive: true,
                                                      onClick: () =>
                                                          setSort({
                                                              by: f
                                                                  .fieldDefinition
                                                                  .name,
                                                              asc: false,
                                                          }),
                                                  }))
                                      )
                                      .otherwise(() => ({})),
                              },
                          },
                      }
                    : acc,
            {}
        ),
    }

    return {
        columns,
        sorted,
    }
}

export { useCols }
