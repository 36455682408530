import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'

export const Complete = () => {
    return (
        <StyledComplete>
            <div>
                <Text as="h2" variant="bold-4">
                    Complete!
                </Text>
                <Icon name="CircleCheck" />
            </div>

            <Text as="p" variant="regular-5">
                This record has reached the end of the workflow.
            </Text>
        </StyledComplete>
    )
}

const StyledComplete = styled.section`
    ${flex('column', 'center', 'stretch')};
    padding: 0.6rem;
    gap: 0.3rem;

    > div {
        ${flex('row', 'space-between', 'center')};
    }

    h2 {
        padding-bottom: 0.25rem;
    }

    span.icon > svg {
        width: 2.85rem;
        height: 2.85rem;

        path {
            fill: ${({ theme }) => theme.palette.icon['01'].normal};
        }
    }
`
