import { Value } from '@udecode/plate-common'
import { richTextEmptyValue } from 'Components/RichText'
import { setAt } from 'Utils'
import { FieldType, RecurringScheduleFrequency } from '__generated__'
import { useCallback, useState } from 'react'
import { dayjs } from '../../Adapters/DayJS'
import { FieldChangeHandler } from '../BusinessObjectFieldset/types'

export type CreateFormState = {
    name: string
    assignedToId?: string
    description: string
    dueDate?: string
    extendedBy: {
        field: { id: string; type: FieldType }
        value: unknown
    }[]
    schedule?:
        | { type: 'single'; scheduledAt: string }
        | {
              type: 'recurring'
              from: string
              frequency: RecurringScheduleFrequency
              interval: number
          }
}

export const useForm = (currentUserId: string) => {
    const [form, setForm] = useState<CreateFormState>({
        description: JSON.stringify(richTextEmptyValue),
        name: '',
        assignedToId: currentUserId,
        dueDate: '',
        extendedBy: [],
        schedule: undefined,
    })

    const handleDescriptionUpdate = useCallback((description: Value) => {
        setForm(prev => ({
            ...prev,
            description: JSON.stringify(description),
        }))
    }, [])

    const handleTextInputUpdate = useCallback(
        (key: keyof CreateFormState) =>
            (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                setForm(prev => ({
                    ...prev,
                    [key]: e.target.value,
                }))
            },
        []
    )

    const handleSelectChange = useCallback((assignedToId: string) => {
        setForm(prev => ({
            ...prev,
            assignedToId,
        }))
    }, [])

    const handleDateChange = useCallback((date: Date) => {
        setForm(prev => ({ ...prev, dueDate: date.toISOString() }))
    }, [])

    const handleExtensionFieldChanged: FieldChangeHandler = field => value => {
        setForm(({ extendedBy, ...action }) => {
            const fieldIndex = extendedBy.findIndex(
                ({ field: { id } }) => field.id === id
            )

            if (fieldIndex < 0) {
                return {
                    ...action,
                    extendedBy: [
                        ...extendedBy,
                        {
                            field: {
                                id: field.id,
                                type: field.type as FieldType,
                            },
                            value,
                        },
                    ],
                }
            }

            return {
                ...action,
                extendedBy: setAt(extendedBy, fieldIndex, 'value', value),
            }
        })
    }

    const handleScheduleTypeChanged = useCallback(
        (scheduleType: 'single' | 'recurring' | undefined) =>
            setForm(prev => {
                switch (scheduleType) {
                    case 'single':
                        return {
                            ...prev,
                            schedule: {
                                type: 'single',
                                scheduledAt: '',
                            },
                        }
                    case 'recurring':
                        return {
                            ...prev,
                            schedule: {
                                type: 'recurring',
                                from:
                                    prev.schedule?.type === 'single'
                                        ? prev.schedule.scheduledAt
                                        : '',
                                frequency: RecurringScheduleFrequency.Weekly,
                                interval: 1,
                            },
                        }
                    case undefined: {
                        return {
                            ...prev,
                            schedule: undefined,
                        }
                    }
                }
            }),
        []
    )

    // Set all times to 4AM for now - potentially need better i18n solution at some point
    const handleScheduledAtChanged = useCallback((date: Date) => {
        setForm(prev => {
            if (!prev.schedule) return prev

            return {
                ...prev,
                schedule: {
                    ...prev.schedule!,
                    ...(prev.schedule.type === 'single' && {
                        scheduledAt: dayjs(date).set('hour', 4).toISOString(),
                    }),
                    ...(prev.schedule.type === 'recurring' && {
                        from: dayjs(date).set('hour', 4).toISOString(),
                    }),
                },
            }
        })
    }, [])

    const handleRecurringFrequencyChanged = useCallback(
        (frequency: RecurringScheduleFrequency) => {
            setForm(prev => {
                if (prev.schedule?.type !== 'recurring') return prev

                return {
                    ...prev,
                    schedule: {
                        ...prev.schedule,
                        frequency,
                    },
                }
            })
        },
        []
    )

    const handleRecurringIntervalChanged = useCallback((interval: number) => {
        setForm(prev => {
            if (prev.schedule?.type !== 'recurring') return prev

            return {
                ...prev,
                schedule: {
                    ...prev.schedule,
                    interval,
                },
            }
        })
    }, [])

    return {
        form,
        handleTextInputUpdate,
        handleSelectChange,
        handleDateChange,
        handleExtensionFieldChanged,
        handleScheduledAtChanged,
        handleScheduleTypeChanged,
        handleRecurringFrequencyChanged,
        handleRecurringIntervalChanged,
        handleDescriptionUpdate,
    }
}
