import * as Accordion from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'

export const StyledItem = styled(Accordion.Item)`
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
`

export const StyledTrigger = styled(Accordion.Trigger)`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;
    cursor: pointer;
    padding: 0;

    .chevron-icon {
        margin-left: auto;
    }

    > span {
        ${flex('row', 'flex-start', 'center')};
        display: block;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 1.5rem 0;
        text-transform: capitalize;
    }

    > .icon {
        padding-right: 1rem;

        svg {
            transition: transform
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
        }
    }

    &[data-state='open'] > .icon svg {
        transform: rotateZ(180deg);
    }
`

export const StyledInputGroup = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;
    padding-bottom: 1rem;

    label {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.2rem;
    }
`
