import { Indicator, Root } from '@radix-ui/react-checkbox'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'

type BaseProps = {
    htmlFor: string
    label: string
    name: string
    disabled?: boolean
    required?: boolean
    onCheckedChange?: (bool: boolean) => void
    className?: string
}

type ControlledProps = BaseProps & {
    defaultChecked?: never
    checked: boolean
}

type UncontrolledProps = BaseProps & {
    defaultChecked: boolean
    checked?: never
}

type Props = ControlledProps | UncontrolledProps

export const Checkbox: FC<Props> = props => {
    const {
        htmlFor,
        label,
        onCheckedChange,
        disabled,
        required,
        name,
        className,
        checked,
    } = props

    return (
        <StyledCheckbox className={className ?? ''}>
            <Root
                className="checkbox-root"
                id={htmlFor}
                onCheckedChange={onCheckedChange}
                disabled={disabled}
                required={required}
                name={name}
                checked={checked}
            >
                <Indicator className="checkbox-indicator">
                    <Icon name="Check" />
                </Indicator>
            </Root>

            <label className="label" htmlFor={htmlFor}>
                {label}
            </label>
        </StyledCheckbox>
    )
}

const StyledCheckbox = styled.div`
    ${flex('row', 'flex-start', 'center')};
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    border-radius: 0.25rem;
    padding: 0 0.5rem;

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    .checkbox-root {
        ${flex('row', 'center', 'center')};
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        width: 1.25rem;
        height: 1.25rem;
        border-radius: ${({ theme }) => theme.borderRadius.small};
        border: 0.138rem solid ${({ theme }) => theme.palette.icon['02'].normal};

        .checkbox-indicator {
            svg {
                max-width: none;
                height: 1.25rem;
                width: 1.25rem;

                path {
                    fill: ${({ theme }) => theme.palette.icon['02'].normal};
                }
            }
        }
    }

    > label {
        ${regular5};
        cursor: pointer;
        padding: 0.5rem;
        display: block;
        flex: 1;
    }
`
