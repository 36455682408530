import {
    BusinessObjectComparatorInput,
    BusinessObjectValueSelectorInput,
    ValueSelectorType,
} from '__generated__'
import {
    Comparator,
    ConstantSelector,
    FieldValueSelector,
    ValueSelector,
} from 'Components/ComparatorBuilderV2'
import { compact } from 'lodash'
import { match, P } from 'ts-pattern'

const mapValueSelectorToInputSelector = (
    valueSelector: Comparator['valueSelector']
): BusinessObjectValueSelectorInput => {
    return match(valueSelector)
        .with({ fieldId: P.string }, ({ fieldId }) => ({
            fieldValue: {
                fieldId,
            },
        }))
        .with({ value: P.string }, ({ value }) => ({
            constant: {
                value,
            },
        }))
        .with({ selectors: P.any }, ({ selectors }) => ({
            deep: {
                selectors: selectors.map(mapValueSelectorToInputSelector),
            },
        }))
        .exhaustive()
}

const mapInputSelectorToSelector = (
    valueSelector: BusinessObjectComparatorInput['valueSelector']
): ValueSelector => {
    return match(valueSelector)
        .with({ deep: P.not(P.nullish) }, ({ deep }) => ({
            type: ValueSelectorType.Deep as const,
            selectors: deep.selectors.map(mapInputSelectorToSelector) as Array<
                FieldValueSelector | ConstantSelector
            >,
        }))
        .with({ fieldValue: P.not(P.nullish) }, ({ fieldValue }) => ({
            type: ValueSelectorType.FieldValue as const,
            fieldId: fieldValue.fieldId,
        }))
        .with({ constant: P.not(P.nullish) }, ({ constant }) => ({
            type: ValueSelectorType.Constant as const,
            value: constant.value,
        }))
        .otherwise(selector => {
            throw new Error(
                `Invalid selector input: ${JSON.stringify(selector)}}`
            )
        })
}

export const mapInputComparatorToComparator = (
    inputComparator: BusinessObjectComparatorInput
): Comparator | undefined => {
    return {
        negate: inputComparator.negate,
        operator: inputComparator.operator,
        valueSelector: mapInputSelectorToSelector(
            inputComparator.valueSelector
        ),
        with: inputComparator.with,
    }
}

export const mapComparatorToInputComparator = (
    comparator: Comparator
): BusinessObjectComparatorInput => {
    return {
        negate: comparator.negate,
        operator: comparator.operator,
        valueSelector: mapValueSelectorToInputSelector(
            comparator.valueSelector
        ),
        with: comparator.with,
    }
}

export const determineInitialComparators = (
    inputComparators: BusinessObjectComparatorInput[]
): Comparator[] => compact(inputComparators.map(mapInputComparatorToComparator))
