import * as Popover from '@radix-ui/react-popover'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { FC, ReactNode, useState } from 'react'
import { keyframes } from 'styled-components'
import { fromEvent, getPortalsContainer } from 'Utils'

type Props = {
    trigger: ReactNode
    handleConfirm: (value: number, unit: TimeUnit) => void
    align?: 'center' | 'end' | 'start' | undefined
}

type TimeUnit = 'hours' | 'days' | 'months' | 'years'

const unitOptions = [
    { text: 'hour(s)', value: 'hours' as const },
    { text: 'day(s)', value: 'days' as const },
    { text: 'month(s)', value: 'months' as const },
    { text: 'year(s)', value: 'years' as const },
] satisfies { text: string; value: TimeUnit }[]

const numberInputExclusions = ['e', 'E', '+', '-', '.']

const defaultNumber = 0
const defaultUnit = 'days' as const

export const DurationSelector: FC<Props> = ({
    trigger,
    handleConfirm,
    align = 'start',
}) => {
    const [numberValue, setNumberValue] = useState<number>(defaultNumber)
    const [unitValue, setUnitValue] = useState<TimeUnit>(defaultUnit)
    const [open, setOpen] = useState(false)

    const reset = () => {
        setOpen(false)
        setNumberValue(defaultNumber)
        setUnitValue(defaultUnit)
    }

    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger asChild>{trigger}</Popover.Trigger>

            <Popover.Portal container={getPortalsContainer()}>
                <StyledDurationSelector
                    side="bottom"
                    align={align}
                    sideOffset={10}
                >
                    <StyledContents>
                        <Text variant="regular-4" as="p">
                            in
                        </Text>

                        <div className="number">
                            <VisuallyHidden>
                                <Text
                                    variant="regular-5"
                                    as="label"
                                    htmlFor="number"
                                >
                                    Duration:{' '}
                                </Text>
                            </VisuallyHidden>

                            <NumberInput
                                id="number"
                                value={numberValue}
                                onKeyDown={e => {
                                    if (numberInputExclusions.includes(e.key)) {
                                        e.preventDefault()
                                    }
                                }}
                                onChange={e => {
                                    const newValue = fromEvent(e)
                                    setNumberValue(Number(newValue))
                                }}
                            />
                        </div>

                        <div className="unit">
                            <VisuallyHidden>
                                <Text
                                    variant="regular-5"
                                    as="label"
                                    htmlFor="unit"
                                >
                                    Unit:{' '}
                                </Text>
                            </VisuallyHidden>

                            <Select
                                value={unitValue}
                                id={'unit'}
                                onValueChange={unit => {
                                    setUnitValue(unit)
                                }}
                                name={'timeUnit'}
                                options={unitOptions}
                            />
                        </div>

                        <TextButton
                            text={'Confirm'}
                            onClick={e => {
                                e.preventDefault()
                                handleConfirm(numberValue, unitValue)
                                reset()
                            }}
                        />
                    </StyledContents>
                </StyledDurationSelector>
            </Popover.Portal>
        </Popover.Root>
    )
}

const DURATION = 400
const TIMING = 'cubic-bezier(0.16, 1, 0.3, 1)'

const slideOutFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideBackToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-1rem);
  }
`

const StyledDurationSelector = styled(Popover.Content)`
    pointer-events: all;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    animation-duration: ${DURATION}ms;
    animation-timing-function: ${TIMING};
    will-change: opacity, transform;

    &[data-state='open'][data-side='right'] {
        animation-name: ${slideOutFromLeft};
    }

    &[data-state='closed'][data-side='right'] {
        animation-name: ${slideBackToLeft};
    }
`

const StyledContents = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.75rem;

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-left: 0.25rem;
    }

    .number > div {
        height: 3rem;
        width: 5rem;

        > input {
            width: 100%;
        }
    }

    .unit > button {
        width: 7.5rem;
    }

    > button {
        align-self: stretch;
    }
`
