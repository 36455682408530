import { GraphQLErrorExtensions } from 'graphql'

// NB : The nesting of errors seems to change between features

export type CustomErrorExtensions = GraphQLErrorExtensions & {
    errors: {
        message: string | string[]
        errors: Record<string, string[]>
    }
}

export const hasCustomErrorExtenstions = (
    extensions: GraphQLErrorExtensions
): extensions is CustomErrorExtensions => {
    if (!extensions.hasOwnProperty('errors')) return false
    return true
}

export type ShortCustomErrorExtensions = GraphQLErrorExtensions & {
    errors: {
        message: string | string[]
    }
}

export const hasShortCustomErrorExtenstions = (
    extensions: GraphQLErrorExtensions
): extensions is ShortCustomErrorExtensions => {
    if (!extensions.hasOwnProperty('errors')) return false
    return true
}
