import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { dayjs } from 'Adapters/DayJS'
import { css, flex, styled } from 'Adapters/Freestyled'
import { DocumentMetadata, FileIcon } from 'Components/Documents'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { formatBytes } from 'Utils'

type Props = {
    document: DocumentMetadata
    selected: boolean
    toggleSelection: (document: DocumentMetadata) => void
}

export const ListItem: FC<Props> = ({
    document,
    selected,
    toggleSelection,
}) => {
    return (
        <StyledListItem $selected={selected}>
            <label>
                <VisuallyHidden>
                    <input
                        type="checkbox"
                        name="document"
                        value={document.id}
                        checked={selected}
                        onChange={() => toggleSelection(document)}
                    />
                </VisuallyHidden>

                <FileIcon name={document.fileType} />

                <div className="label">
                    <Text as="h2" variant="bold-5">
                        {document.name}
                    </Text>
                    <Text as="p" variant="regular-6">
                        {formatBytes(document.sizeInBytes)},{' '}
                    </Text>
                    <Text as="p" variant="regular-6">
                        {dayjs(document.addedOn).format('DD MMM YYYY')}
                    </Text>
                </div>
            </label>
        </StyledListItem>
    )
}

const StyledListItem = styled.li<{ $selected: boolean }>`
    width: 100%;

    label {
        ${flex('row', 'flex-start', 'center')};
        width: 100%;
        padding: 1.125rem 0.5rem;
        border-radius: ${({ theme }) => theme.borderRadius.small};
        cursor: pointer;

        &:has(:focus-visible) {
            box-shadow: inset 0 0 0 0.125rem
                ${({ theme }) => theme.palette.brand['01'].normal};
        }

        .label {
            padding-left: ${({ theme }) => theme.spacing['04']};

            p {
                color: ${({ theme }) => theme.palette.text['02'].normal};
            }
        }

        ${({ $selected }) => $selected && selectedStyles};
    }
`

const selectedStyles = css`
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
`
