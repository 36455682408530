import { useEditorRef } from '@udecode/plate-core'
import {
    useFloatingLinkUrlInput,
    useFloatingLinkUrlInputState,
    validateUrl,
} from '@udecode/plate-link'
import { styled } from 'Adapters/Freestyled'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'

type Props = {
    isOpen: boolean
}

export const LinkInput: FC<Props> = ({ isOpen }) => {
    const editorRef = useEditorRef()

    const state = useFloatingLinkUrlInputState()
    const { props, ref } = useFloatingLinkUrlInput(state)

    const [hasError, setHasError] = useState(false)

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e)

            const value = e.target.value
            const isValidURL = validateUrl(editorRef, value)

            match({ value, isValidURL })
                .with({ value: '' }, () => {
                    setHasError(false)
                })
                .with({ isValidURL: false }, () => {
                    setHasError(true)
                })
                .with({ isValidURL: true }, () => {
                    setHasError(false)
                })
                .exhaustive()
        },
        [props, editorRef]
    )

    // Clears the link input value on close of the floating link menu
    const isOpenRef = useRef(false)
    useEffect(() => {
        if (isOpen !== isOpenRef.current) {
            isOpenRef.current = isOpen

            if (isOpen === false && ref.current?.value) {
                ref.current.value = ''
            }
        }
    }, [isOpen, ref])

    return (
        <StyledLinkInput
            ref={ref}
            onChange={handleChange}
            defaultValue={props.defaultValue}
            $hasError={hasError}
            placeholder="Valid URL ( including https:// )"
        />
    )
}

const StyledLinkInput = styled.input<{ $hasError: boolean }>`
    &:focus-visible {
        box-shadow: inset 0 0 0 0.125rem
            ${({ theme, $hasError }) =>
                $hasError
                    ? theme.palette.support['01'].normal
                    : theme.palette.brand['01'].normal};
    }

    ${({ $hasError, theme }) =>
        $hasError &&
        `box-shadow: inset 0 0 0 0.125rem ${theme.palette.support['01'].normal}`}
`
