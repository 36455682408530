import { Group } from '@radix-ui/react-dropdown-menu'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownLabel,
} from 'Components/Dropdown'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import {
    checkBusinessObjectDefinitionUpdated,
    encodeGroupState,
} from 'Features/ActionView'
import { encodeFilterState } from 'Features/FilterBusinessObject'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    GetActionViewsQuery,
    useGetActionViewsQuery,
} from '../../../__generated__/query'
import { Extension, ViewSchema } from '../../../types'
import { ActionView } from '../types'
import { ManageViews } from './ManageViews'
import { presetViewsFactory } from './templates'

type Props = {
    currentUserId: string
    extension: Extension | undefined
}

export const ApplyView: FC<Props> = ({ currentUserId, extension }) => {
    const [open, setOpen] = useState(false)

    const [, setSearchParams] = useSearchParams()

    const presetViews = useMemo(
        () => presetViewsFactory(currentUserId),
        [currentUserId]
    )

    const handleSelectPresetView = useCallback(
        (view: ActionView) => {
            setSearchParams({
                ...view.actionFilter,
                ...(Object.keys(view.actionExtensionFilter).length && {
                    filterState: encodeFilterState(view.actionExtensionFilter),
                }),
                display: view.display,
            })
            setOpen(false)
        },
        [setSearchParams]
    )

    const { data } = useGetActionViewsQuery({
        variables: { input: { type: ViewOfType.Actions } },
    })

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const {
        open: bodefChangedToastOpen,
        setOpen: setBodefChangedToastOpen,
        triggerToast: triggerBodefChangedToast,
    } = useTriggerToast()

    const handleSelectUserDefinedView = useCallback(
        (view: GetActionViewsQuery['views'][number]) => {
            const decodedView = JSON.parse(
                Buffer.from(view.configuration, 'base64').toString()
            )

            const isValidSchema = ViewSchema.safeParse(decodedView)

            if (!isValidSchema.success) {
                logger.error('Invalid view schema', isValidSchema.error)
                triggerErrorToast()
                return
            }

            if (extension) {
                const bodefHasChangedSinceLastEncoded = !decodedView.grouping
                    ? false
                    : checkBusinessObjectDefinitionUpdated(
                          decodedView.grouping,
                          // Ignore updates
                          extension.fields.filter(
                              field =>
                                  !(
                                      field.__typename ===
                                          'ListFieldDefinition' &&
                                      field.listOf.__typename ===
                                          'UpdatesFieldDefinition'
                                  )
                          )
                      )

                if (bodefHasChangedSinceLastEncoded) {
                    triggerBodefChangedToast()
                    return
                }
            }

            setSearchParams({
                ...decodedView,
                filterState: encodeFilterState(decodedView.filterState),
                ...(decodedView.grouping && {
                    grouping: encodeGroupState(decodedView.grouping),
                }),
            })

            setOpen(false)
        },
        [
            extension,
            setSearchParams,
            triggerBodefChangedToast,
            triggerErrorToast,
        ]
    )

    return (
        <>
            <Dropdown
                variant="light"
                open={open}
                onOpenChange={setOpen}
                renderOpenDropdownButton={() => (
                    <IconTextButton
                        icon="View"
                        text="Saved views"
                        size="xSmall"
                    />
                )}
                renderContent={() => (
                    <Group>
                        <DropdownLabel text="Preset Views" />

                        {Object.values(presetViews).map(view => (
                            <DropdownButton
                                key={view.id}
                                text={view.name}
                                icon="View"
                                onSelect={() => {
                                    handleSelectPresetView(view)
                                }}
                            />
                        ))}

                        {data?.views.length ? (
                            <>
                                <DropdownLabel text="Saved Views" />

                                {data.views.map(view => (
                                    <DropdownButton
                                        key={view.id}
                                        text={view.name}
                                        icon="View"
                                        onSelect={() => {
                                            handleSelectUserDefinedView(view)
                                        }}
                                    />
                                ))}
                            </>
                        ) : null}

                        {data?.views.length ? (
                            <>
                                <DropdownDivider />

                                <ManageViews views={data.views} />
                            </>
                        ) : null}
                    </Group>
                )}
            />
            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'Unable to apply the view. Contact us if the problem persists.'
                }
            />

            <SimpleToast
                open={bodefChangedToastOpen}
                onOpenChange={setBodefChangedToastOpen}
                description={
                    'Action extensions have been updated since this view was saved. We reccomend you delete it and save a new one.'
                }
            />
        </>
    )
}
