import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { useProcessEditor } from '../../useProcessEditor'

type Props = {
    index: number
}

const PhaseHeader: FC<Props> = ({ index }) => {
    const {
        state: { process },
    } = useProcessEditor()

    return (
        <Styled>
            <Text as="h4" variant="bold-3">
                {process.phases[index].name}
            </Text>
            {process.phases[index].description && (
                <Text as="p" variant="regular-6">
                    {process.phases[index].description}
                </Text>
            )}
        </Styled>
    )
}

const Styled = styled.header`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { PhaseHeader }
