import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { ContactUsToast } from 'Components/Toast'
import { noop } from 'lodash'
import { Dispatch, FC, SetStateAction } from 'react'

type Props = {
    deleteState: {
        documentId: string
        documentName: string
        canDelete: boolean
    }
    closeModal: () => void
    deleteDocument: () => void
    deleteLoading: boolean
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    toastOpen: boolean
    setToastOpen: Dispatch<SetStateAction<boolean>>
}

export const ConfirmDeleteModal: FC<Props> = ({
    deleteState,
    closeModal,
    deleteDocument,
    deleteLoading,
    open,
    setOpen,
    toastOpen,
    setToastOpen,
}) => {
    return (
        <>
            <ControlledModal
                renderContent={() => (
                    <StyledConfirmDelete>
                        {deleteState.canDelete ? (
                            <Text as={'p'} variant={'regular-4'}>
                                Deleting this document is permanent and cannot
                                be undone. Please confirm that you would like to
                                delete this document.
                            </Text>
                        ) : (
                            <>
                                <Text as={'p'} variant={'regular-4'}>
                                    This document is not available for deletion.
                                    This is because it is referenced by one or
                                    more records in a document or document list
                                    field.
                                </Text>

                                <Text as={'p'} variant={'regular-4'}>
                                    Remove any references if you would like to
                                    delete this file.
                                </Text>
                            </>
                        )}

                        <div>
                            <TextButton
                                onClick={e => {
                                    e.preventDefault()
                                    closeModal()
                                }}
                                text="Cancel"
                                variant="secondary"
                                size="small"
                            />

                            <TextButton
                                onClick={e => {
                                    e.preventDefault()
                                    deleteDocument()
                                }}
                                text={!deleteLoading ? 'Confirm' : 'Loading...'}
                                variant="primary"
                                size="small"
                                disabled={
                                    deleteLoading || !deleteState.canDelete
                                }
                            />
                        </div>
                    </StyledConfirmDelete>
                )}
                title={'Delete Document'}
                description={deleteState.documentName}
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
            />

            <ContactUsToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'We were unable to delete your document. Contact us if the problem persists.'
                }
            />
        </>
    )
}

const StyledConfirmDelete = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 30rem;

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        ${flex('row', 'center', 'center')};
        gap: 1rem;
        width: 100%;

        > button {
            flex: 1;
        }
    }
`
