import { useCallback, useRef, useState } from 'react'
import { waitForDelay } from 'Utils'

export const useModalControls = <T extends HTMLElement = HTMLButtonElement>(
    initiallyOpen = false
) => {
    const [open, setOpen] = useState(initiallyOpen)
    const buttonRef = useRef<T>(null)

    const openModal = useCallback(() => {
        setOpen(true)
    }, [])

    const closeModal = useCallback(async () => {
        setOpen(false)
        return await waitForDelay(300)
    }, [])

    const returnFocus = useCallback(() => {
        buttonRef.current?.focus()
    }, [])

    return {
        open,
        buttonRef,
        setOpen,
        openModal,
        closeModal,
        returnFocus,
    }
}
