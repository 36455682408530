import { GridTable } from 'Components/GridTable'
import { FC, useState } from 'react'
import { JSONTree } from 'react-json-tree'
import { dayjs } from '../../../../../../Adapters/DayJS'
import { styled } from '../../../../../../Adapters/Freestyled'
import { ControlledModal } from '../../../../../../Components/Modal'
import { useModalControls } from '../../../../../../Hooks'
import { useWebhookExecutionsQuery } from './__generated__/q'

type Props = {
    webhookId: string
}

export const ExecutionsModal: FC<Props> = ({ webhookId }) => {
    const webhook = useWebhookExecutionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: { id: webhookId },
    })

    const [selectedResult, setSelectedResult] = useState<string>()

    const executions = webhook.data?.webhook.executions ?? []

    const resultModal = useModalControls()

    return (
        <>
            <Styled>
                <GridTable
                    columns={{
                        createdAt: { header: { label: 'Execution Time' } },
                        result: { header: { label: 'Result' } },
                    }}
                    groups={[
                        {
                            label: '',
                            colorConfig: { color: 'blue' },
                            rows: executions.map(execution => ({
                                cells: {
                                    createdAt: {
                                        element: dayjs(
                                            execution.createdAt
                                        ).format('DD/MM/YYYY HH:mm:ss'),
                                    },
                                    result: {
                                        element: 'Click to view',
                                    },
                                },
                                onClick: () => {
                                    setSelectedResult(execution.result)
                                    resultModal.openModal()
                                },
                            })),
                        },
                    ]}
                ></GridTable>
            </Styled>

            <ControlledModal
                {...resultModal}
                onOpenChange={resultModal.setOpen}
                title="Execution Result"
                description=""
                hideDescription
                renderContent={() => (
                    <StyledResult>
                        {selectedResult && (
                            <JSONTree data={JSON.parse(selectedResult)} />
                        )}
                    </StyledResult>
                )}
            />
        </>
    )
}

const Styled = styled.div`
max-height: 80vh;
max-width: 80vw;
padding-top: 1rem;
}
`

const StyledResult = styled.div`
    height: 80vh;
    width: 80vw;
    padding-top: 1rem;

    > ul {
        height: 100%;
        width: 100%;
        overflow: scroll;
    }
`
