import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { noop } from 'lodash'
import { FC, useState } from 'react'
import { useEditDocument } from './useEditDocument'

type Props = {
    documentId: string
    initialDocumentName: string
    open: boolean
    onOpenChange: (bool: boolean) => void
}

export const RenameDocumentModal: FC<Props> = ({
    documentId,
    initialDocumentName,
    open,
    onOpenChange,
}) => {
    const { editDocument, editLoading } = useEditDocument()

    const [value, setValue] = useState(initialDocumentName)

    return (
        <ControlledModal
            renderContent={() => (
                <StyledForm
                    onSubmit={e => {
                        e.preventDefault()
                        editDocument({
                            variables: {
                                input: { id: documentId, name: value },
                            },
                            onCompleted() {
                                onOpenChange(false)
                            },
                            onError(err) {
                                logger.error(
                                    'Failed to change document name',
                                    err
                                )
                            },
                        })
                    }}
                >
                    <TextInput
                        value={value}
                        onChange={e => {
                            setValue(e.target.value)
                        }}
                    />

                    <TextButton
                        text={editLoading ? 'Saving' : 'Save Changes'}
                        disabled={editLoading}
                        className={'submit-button'}
                        size={'small'}
                    />
                </StyledForm>
            )}
            title={'Rename document'}
            description={''}
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={noop}
        />
    )
}

const StyledForm = styled.form`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;

    .submit-button {
        margin-top: 1.5rem;
        width: 100%;
    }
`
