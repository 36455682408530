import { isImageFileType } from 'Components/Documents'
import { FC } from 'react'
import { IconCard } from './IconCard'
import { ImageCard } from './ImageCard'
import { Document } from './types'

type Props = {
    document: Document
}

export const DocumentDisplay: FC<Props> = ({ document }) => {
    return isImageFileType(document.fileType) ? (
        <ImageCard document={document} />
    ) : (
        <IconCard document={document} />
    )
}
