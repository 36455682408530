import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import { useDeleteBusinessObjectsViewMutation } from '../../../__generated__/mutation'
import { GetBusinessObjectViewsQuery } from '../../../__generated__/query'

type Props = {
    view: NonNullable<GetBusinessObjectViewsQuery['views']>[number]
}

export const ManageSingleView: FC<Props> = ({ view }) => {
    const [deleteView] = useDeleteBusinessObjectsViewMutation({
        variables: {
            input: {
                id: view.id,
            },
        },
        optimisticResponse: {
            deleteView: view.id,
        },
        update(cache) {
            cache.evict({
                id: cache.identify({ id: view.id, __typename: 'View' }),
            })
            cache.gc()
        },
    })

    return (
        <Styled>
            <Icon name="View" />

            <span>{view.name}</span>

            <IconButton
                iconName={'Delete'}
                className="delete-button"
                variant="secondary"
                onClick={() => {
                    deleteView()
                }}
            />
        </Styled>
    )
}
const Styled = styled.li`
    display: grid;
    grid-template: auto / auto 1fr auto auto;
    align-items: center;
    justify-content: center;
    text-align: left;
    grid-column-gap: 1rem;
    grid-row-gap: 0.125rem;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};

    .icon:first-child {
        grid-column: 1 / 2;
    }

    .title {
        grid-column: 2 / 3;
    }

    .icon:last-child {
        grid-column: 3 / 4;
    }

    .icon svg {
        --icon-width: 1rem;
        width: var(--icon-width);
        height: var(--icon-width);
        min-width: var(--icon-width);
        min-height: var(--icon-width);
    }

    .icon svg path,
    .icon svg circle {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }
`
