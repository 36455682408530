import { useTriggerToast } from 'Components/Toast'
import { useCallback } from 'react'
import { useProcessSingle_DeleteViewMutation } from './__generated__/q'

type UseDeleteView = (id: string) => {
    deleteView: () => Promise<void>
    showSuccess: boolean
    setShowSuccess: (open: boolean) => void
    showError: boolean
    setShowError: (open: boolean) => void
}

export const useDeleteView: UseDeleteView = id => {
    const {
        open: showSuccess,
        setOpen: setShowSuccess,
        triggerToast: triggerSuccessToast,
    } = useTriggerToast()
    const {
        open: showError,
        setOpen: setShowError,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()
    const [runDelete] = useProcessSingle_DeleteViewMutation()

    const deleteView = useCallback(async () => {
        await runDelete({
            variables: {
                input: {
                    id,
                },
            },
            onCompleted: () => {
                triggerSuccessToast()
            },
            onError: () => {
                triggerErrorToast()
            },
            onQueryUpdated: q => q.refetch(),
            update: (cache, result) => {
                cache.evict({
                    id: cache.identify({
                        __typename: 'View',
                        id,
                    }),
                })
            },
        })
        return
    }, [id, runDelete, triggerSuccessToast, triggerErrorToast])

    return {
        deleteView,
        showSuccess,
        setShowSuccess,
        showError,
        setShowError,
    }
}
