import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableCurrenciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AvailableCurrenciesQuery = { __typename?: 'Query', currencies: Array<{ __typename?: 'Currency', code: string, nativeSymbol: string }> };


export const AvailableCurrenciesDocument = gql`
    query AvailableCurrencies {
  currencies {
    code
    nativeSymbol
  }
}
    `;

/**
 * __useAvailableCurrenciesQuery__
 *
 * To run a query within a React component, call `useAvailableCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableCurrenciesQuery, AvailableCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableCurrenciesQuery, AvailableCurrenciesQueryVariables>(AvailableCurrenciesDocument, options);
      }
export function useAvailableCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableCurrenciesQuery, AvailableCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableCurrenciesQuery, AvailableCurrenciesQueryVariables>(AvailableCurrenciesDocument, options);
        }
export type AvailableCurrenciesQueryHookResult = ReturnType<typeof useAvailableCurrenciesQuery>;
export type AvailableCurrenciesLazyQueryHookResult = ReturnType<typeof useAvailableCurrenciesLazyQuery>;
export type AvailableCurrenciesQueryResult = Apollo.QueryResult<AvailableCurrenciesQuery, AvailableCurrenciesQueryVariables>;