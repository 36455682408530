import { fieldLabels } from 'Utils'
import {
    DateFieldPrecision,
    FieldType,
    ListOf,
} from 'Utils/BusinessObjectDefinition'
import { match } from 'ts-pattern'

type CreateSubField = (_: {
    fieldType: Exclude<FieldType, FieldType.List>
}) => ListOf
export const createSubField: CreateSubField = ({ fieldType }) =>
    match(fieldType)
        .with(FieldType.Text, type => ({
            type,

            constraints: {
                required: false,
                length: {
                    max: undefined,
                    min: undefined,
                },
            },
            defaultValue: undefined,
        }))
        .with(FieldType.Number, type => ({
            type,

            constraints: {
                required: false,
                range: {
                    max: undefined,
                    min: undefined,
                },
            },
            defaultValue: undefined,
        }))
        .with(FieldType.Telephone, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: {
                countryCode: undefined,
                number: undefined,
            },
        }))
        .with(FieldType.Email, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: '',
        }))
        .with(FieldType.Document, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: '',
        }))
        .with(FieldType.Currency, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: {
                amount: undefined,
                currencyCode: undefined,
            },
        }))
        .with(FieldType.Relation, type => ({
            type,

            constraints: {
                required: false,
                relationType: {
                    id: '',
                    name: '',
                },
            },
        }))
        .with(FieldType.User, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: undefined,
        }))
        .with(FieldType.Url, type => ({
            type,

            constraints: {
                required: false,
            },
            defaultValue: undefined,
        }))
        .with(FieldType.Date, type => ({
            type,
            constraints: {
                required: false,
                dateRange: {
                    start: undefined,
                    end: undefined,
                },
            },
            precision: DateFieldPrecision.Day,
            defaultValue: undefined,
        }))
        .with(FieldType.Boolean, type => ({
            type,
            defaultValue: false,
        }))
        .with(FieldType.Select, type => ({
            type,
            options: [],
            constraints: { numberOfSelections: { min: 1, max: 1 } },
            defaultValue: [],
        }))
        .exhaustive()

export const listOfOptions: {
    text: string
    value: Exclude<FieldType, FieldType.List>
}[] = [
    {
        text: fieldLabels.text,
        value: FieldType.Text,
    },
    {
        text: fieldLabels.number,
        value: FieldType.Number,
    },
    {
        text: fieldLabels.telephone,
        value: FieldType.Telephone,
    },
    {
        text: fieldLabels.email,
        value: FieldType.Email,
    },
    {
        text: fieldLabels.document,
        value: FieldType.Document,
    },
    {
        text: fieldLabels.currency,
        value: FieldType.Currency,
    },
    {
        text: fieldLabels.relation,
        value: FieldType.Relation,
    },
    {
        text: fieldLabels.user,
        value: FieldType.User,
    },
    {
        text: fieldLabels.url,
        value: FieldType.Url,
    },
    {
        text: fieldLabels.date,
        value: FieldType.Date,
    },
    {
        text: fieldLabels.boolean,
        value: FieldType.Boolean,
    },
    {
        text: fieldLabels.select,
        value: FieldType.Select,
    },
]
