import { NumberInput } from 'Components/Input'
import { FC, useMemo } from 'react'
import { P, match } from 'ts-pattern'
import { FieldCommonWrapper } from './Common'
import { RangeConstraint } from './Constraints/Range'
import { RequiredConstraint } from './Constraints/Required'
import { Field } from './Field'
import { FieldProps } from './types'

export const NumberFieldDefinitionInput: FC<FieldProps<'number'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    const defaultValidationErrors = useMemo(
        () => validateNumberDefault(field),
        [field]
    )

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field
                htmlFor={`bod-field-${index}-default`}
                name="Default value"
                errors={defaultValidationErrors}
            >
                <NumberInput
                    name={'default'}
                    value={field.defaultValue ?? ''}
                    id={`bod-field-${index}-default`}
                    onChange={e =>
                        onChange('defaultValue')(() => Number(e.target.value))
                    }
                    type="number"
                    min={
                        field.constraints.find(constraint => constraint.range)
                            ?.range?.min ?? undefined
                    }
                    max={
                        field.constraints.find(constraint => constraint.range)
                            ?.range?.max ?? undefined
                    }
                    hasError={!!defaultValidationErrors.length}
                />
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}

            <RangeConstraint
                index={index}
                value={field.constraints.find(constraint => constraint.range)}
                onChange={constraint =>
                    onChange('constraints')(prev =>
                        constraint
                            ? [...prev.filter(c => !c.range), constraint]
                            : prev.filter(c => !c.range)
                    )
                }
            />
        </FieldCommonWrapper>
    )
}

type NumberField = FieldProps<'number'>['field']

const validateNumberDefault = (field: NumberField): string[] => {
    if (!field.defaultValue) return []

    return field.constraints.reduce<string[]>((errors, constraint) => {
        return match(constraint)
            .with({ range: P.not(P.nullish) }, ({ range }) => [
                ...errors,
                ...validateAgainstLengthConstraint(field.defaultValue!, range),
            ])
            .with({ required: P.not(P.nullish) }, () => errors)
            .otherwise(() => [])
    }, [])
}

const validateAgainstLengthConstraint = (
    defaultValue: number,
    rangeConstraint: NumberField['constraints'][number]['range']
): string[] => {
    if (!rangeConstraint) return []

    const min = rangeConstraint.min
    const max = rangeConstraint.max

    if (min && defaultValue < min)
        return ['The default value is lower than the minimum']

    if (max && defaultValue > max)
        return ['The default value is higher than the maximum']

    return []
}
