import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import {
    FieldValidation,
    isBusinessObjectDefinitionFieldValid,
    Field as TField,
} from 'Utils/BusinessObjectDefinition'
import { FieldCard } from './FieldCard'
import { Mode } from './types'

type Props = {
    field: TField
    index: number
    last: boolean
    validation: FieldValidation
    mode: Mode
    onEdit: () => void
    onMoveUp: () => void
    onMoveDown: () => void
    onRemove: () => void
    hideBullet?: boolean
}

const BusinessObjectDefinitionField: FC<Props> = ({
    field,
    index,
    last,
    validation,
    mode,
    hideBullet = false,
    ...fieldActions
}) => {
    const isValid = isBusinessObjectDefinitionFieldValid(validation)
    return (
        <Styled>
            {!hideBullet ? (
                <StyledValidationIcon data-valid={isValid}>
                    <Icon name={isValid ? 'Bullet' : 'ErrorCircle'} />
                </StyledValidationIcon>
            ) : null}
            <FieldCard
                field={field}
                index={index}
                last={last}
                isValid={isValid}
                mode={mode}
                {...fieldActions}
            />
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const StyledValidationIcon = styled.div`
    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    &[data-valid='false'] .icon svg path {
        fill: ${({ theme }) => theme.palette.support['01'].normal};
    }
`

export { BusinessObjectDefinitionField }
