import { ArrowHandler } from 'Components/DatePicker/types'
import { waitForDelay } from 'Utils'

export const arrowHandler: ArrowHandler =
    focusTargetDate => (date, setDisplay) => (direction, isVisible) =>
        ({
            up: async () => {
                if (isVisible) return focusTargetDate(date.subtract(3, 'month'))
                setDisplay(prev => prev.subtract(1, 'year'))
                await waitForDelay(1)
                focusTargetDate(date.subtract(3, 'month'))
            },
            down: async () => {
                if (isVisible) return focusTargetDate(date.add(3, 'month'))
                setDisplay(prev => prev.add(1, 'year'))
                await waitForDelay(1)
                focusTargetDate(date.add(3, 'month'))
            },
            left: async () => {
                if (isVisible) return focusTargetDate(date.subtract(1, 'month'))
                setDisplay(prev => prev.subtract(1, 'year'))
                await waitForDelay(1)
                focusTargetDate(date.subtract(1, 'month'))
            },
            right: async () => {
                if (isVisible) return focusTargetDate(date.add(1, 'month'))
                setDisplay(prev => prev.add(1, 'year'))
                await waitForDelay(1)
                focusTargetDate(date.add(1, 'month'))
            },
        }[direction])
