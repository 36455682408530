import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { SelectorName, useSelectorFieldMap } from 'Components/SelectorName'
import { Text } from 'Components/Text'
import { BusinessObjectInProcessOverviewQuery } from 'Features/SingleBusinessObjectTabs/Overview/__generated__/query'
import { always } from 'lodash/fp'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { StyledCriteria, StyledPhaseBox } from './styles'

type Props = {
    phaseIndex: number
    phaseName: string
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    process: BusinessObjectInProcessOverviewQuery['process']
    isComplete: boolean
}

export const PhaseBox: FC<Props> = ({
    phaseIndex,
    businessObject,
    process,
    phaseName,
    isComplete,
}) => {
    const fieldMap = useSelectorFieldMap(businessObject.definition)
    const phase = process.phases[phaseIndex]

    return (
        <StyledPhaseBox $complete={isComplete} $current={false}>
            <div>
                <header>
                    <Text as="h3" variant="regular-3">
                        {phaseName}
                    </Text>

                    <div className="complete">
                        <Icon
                            name={
                                isComplete
                                    ? 'CheckboxTicked'
                                    : 'CheckboxUnticked'
                            }
                            className={isComplete ? '' : 'down-icon'}
                        />

                        <span>{isComplete ? ' complete' : ' incomplete'}</span>
                    </div>
                </header>

                <StyledCriteria>
                    <StyledPhaseCriterionList>
                        {phase?.criteria.map((criterion, i) =>
                            match(criterion)
                                .with(
                                    {
                                        __typename: 'ActionPhaseCriterion',
                                    },
                                    criterion => (
                                        <li key={i}>
                                            <Icon name="CircleCheck" />
                                            <Text
                                                as="span"
                                                variant="regular-5"
                                                key={i}
                                            >
                                                {criterion.description}
                                            </Text>
                                        </li>
                                    )
                                )
                                .otherwise(always(null))
                        )}

                        {phase?.criteria.map((criterion, i) =>
                            match(criterion)
                                .with(
                                    {
                                        __typename:
                                            'FieldConditionPhaseCriterion',
                                    },
                                    criterion => (
                                        <li key={i}>
                                            <Icon name="Edit" />
                                            <Text
                                                as="span"
                                                variant="regular-5"
                                                key={i}
                                            >
                                                <SelectorName
                                                    selector={
                                                        criterion.comparator
                                                            .valueSelector
                                                    }
                                                    fieldMap={fieldMap}
                                                />
                                            </Text>
                                        </li>
                                    )
                                )
                                .with(
                                    {
                                        __typename:
                                            'ProcessDelegatePhaseCriterion',
                                    },
                                    criterion => (
                                        <li key={i}>
                                            <Icon name="Process" />
                                            <Text
                                                as="span"
                                                variant="regular-5"
                                                key={i}
                                            >
                                                {criterion.process.name}
                                            </Text>
                                        </li>
                                    )
                                )
                                .otherwise(always(null))
                        )}
                    </StyledPhaseCriterionList>
                </StyledCriteria>
            </div>
        </StyledPhaseBox>
    )
}

const StyledPhaseCriterionList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 18rem;
    gap: 0.25rem;
    padding: 1rem;

    > li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.4rem;

        > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 12rem;
        }
    }
`
