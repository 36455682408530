import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { SelectOption } from 'Components/Select/types'
import { BizObjDef_Currency, BOField } from 'Hooks'
import { FC, useMemo, useRef, useState } from 'react'
import { useAvailableCurrencies } from '../../../../Hooks/useAvailableCurrencies/useAvailableCurrencies'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useCurrencyConstraints } from './useCurrencyConstraints'

type Props = BOField<'BusinessObjectCurrencyField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const CurrencyField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    currencyValue,
    onComplete,
}) => {
    const { currencyConstraints, ...common } =
        fieldDefinition as BizObjDef_Currency

    const { constraints, constraintsTooltip } = useCurrencyConstraints({
        currencyConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialCode = useRef(currencyValue?.currencyDetails.code ?? '')
    const initialAmount = useRef(currencyValue?.amount.toString() ?? '')

    const [currencyCode, setCurrencyCode] = useState(initialCode.current)
    const [amount, setAmount] = useState(initialAmount.current)

    const currencyOptions = useAvailableCurrencies()

    const options = useMemo(() => {
        return [
            {
                text: `-`,
                value: '',
            },
            ...currencyOptions.map<SelectOption>(option => ({
                text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
                value: option.code,
            })),
        ]
    }, [currencyOptions])

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledCurrencyInput>
                <Select
                    id={common.id + 'currencyCode'}
                    value={currencyCode}
                    onValueChange={setCurrencyCode}
                    name={'Currency Code'}
                    options={options}
                />
                <NumberInput
                    value={amount}
                    id={common.id}
                    hasError={!!errors.length}
                    onChange={e => setAmount(e.target.value)}
                    required={constraints.required}
                    className="currency-value-input"
                />
            </StyledCurrencyInput>

            <EditFieldButton
                disabled={
                    (initialAmount.current === amount &&
                        initialCode.current === currencyCode) ||
                    loading
                }
                onClick={() => {
                    // If both previous values are empty, send undefined rather than empty object
                    const previousValue =
                        initialCode.current || initialAmount.current
                            ? {
                                  currencyCode: initialCode.current,
                                  amount: Number(initialAmount.current),
                              }
                            : undefined

                    const newValue =
                        !amount && !currencyCode
                            ? undefined
                            : {
                                  currencyCode,
                                  // Empty strings should be converted to undefined, not 0
                                  amount:
                                      amount.length === 0
                                          ? undefined
                                          : Number(amount),
                              }

                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue,
                                value: newValue,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialCode.current = currencyCode
                            initialAmount.current = amount
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}

const StyledCurrencyInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .currency-value-input {
        flex: 1;
        height: 2.75rem;
    }
`
