import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables = Types.Exact<{
  input: Types.QueryUserInput;
}>;


export type BusinessObjectDefinitionDisplay_UserFieldContentQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, name: string } };


export const BusinessObjectDefinitionDisplay_UserFieldContentDocument = gql`
    query BusinessObjectDefinitionDisplay_UserFieldContent($input: QueryUserInput!) {
  user(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useBusinessObjectDefinitionDisplay_UserFieldContentQuery__
 *
 * To run a query within a React component, call `useBusinessObjectDefinitionDisplay_UserFieldContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectDefinitionDisplay_UserFieldContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectDefinitionDisplay_UserFieldContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessObjectDefinitionDisplay_UserFieldContentQuery(baseOptions: Apollo.QueryHookOptions<BusinessObjectDefinitionDisplay_UserFieldContentQuery, BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessObjectDefinitionDisplay_UserFieldContentQuery, BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables>(BusinessObjectDefinitionDisplay_UserFieldContentDocument, options);
      }
export function useBusinessObjectDefinitionDisplay_UserFieldContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessObjectDefinitionDisplay_UserFieldContentQuery, BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessObjectDefinitionDisplay_UserFieldContentQuery, BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables>(BusinessObjectDefinitionDisplay_UserFieldContentDocument, options);
        }
export type BusinessObjectDefinitionDisplay_UserFieldContentQueryHookResult = ReturnType<typeof useBusinessObjectDefinitionDisplay_UserFieldContentQuery>;
export type BusinessObjectDefinitionDisplay_UserFieldContentLazyQueryHookResult = ReturnType<typeof useBusinessObjectDefinitionDisplay_UserFieldContentLazyQuery>;
export type BusinessObjectDefinitionDisplay_UserFieldContentQueryResult = Apollo.QueryResult<BusinessObjectDefinitionDisplay_UserFieldContentQuery, BusinessObjectDefinitionDisplay_UserFieldContentQueryVariables>;