import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DocumentPicker } from 'Components/DocumentPicker'
import { useFetchAllDocuments } from 'Components/Documents'
import { regular5 } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useUserConstraints } from './useUserConstraints'

export const DocumentField: FC<FieldInputProps<'DocumentFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useUserConstraints({
        docConstraints: field.docConstraints,
    })

    const { documents } = useFetchAllDocuments()

    const { open, setOpen, closeModal, openModal, buttonRef, returnFocus } =
        useModalControls()

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledAddDocumentButton
                icon="Document"
                text={
                    value
                        ? documents.find(doc => doc.id === value)?.name ?? ''
                        : 'Add a document'
                }
                ref={buttonRef}
                onClick={e => {
                    e.preventDefault()
                    openModal()
                }}
            />

            {!!value ? (
                <IconTextButton
                    icon={'Close'}
                    text={'Clear'}
                    onClick={() => {
                        onChange(undefined)
                    }}
                    variant="secondary"
                />
            ) : null}

            <DocumentPicker
                onConfirm={selections => {
                    if (!selections.length) {
                        onChange(undefined)
                    } else {
                        onChange(selections[0].id)
                    }

                    closeModal()
                }}
                selectMode={'single'}
                isOpen={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
                initialSelections={value ? [value] : []}
            />
        </Field>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
