import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { UpdateAction } from 'Features/UpdateAction'
import { FC } from 'react'

type Props = {
    open: boolean
    closeModal: () => void
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    actionId: string
}

export const UpdateActionModal: FC<Props> = ({
    onOpenChange,
    open,
    returnFocus,
    closeModal,
    actionId,
}) => {
    return (
        <ControlledModal
            title="Update Action"
            description={'Update the details of an existing action'}
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
            renderContent={() => (
                <Styled>
                    <UpdateAction
                        actionId={actionId}
                        onCompleted={closeModal}
                        onDeleted={closeModal}
                    />
                </Styled>
            )}
        />
    )
}

const Styled = styled.div`
    width: 30rem;
    max-width: 100vw;
    height: 45rem;
`
