import { FC, useEffect, useRef, useState } from 'react'
import { useDragActionCard } from '../dnd'
import { ActionToggle } from './ActionToggle'
import { CardContent } from './Content'
import { CardHeader } from './Header'
import { ActionTimelineCardQuery } from './__generated__/query'
import { StyledCard } from './styles'

type BaseProps = {
    action: ActionTimelineCardQuery['action']
    hideMenu: boolean
    isMostRecent?: boolean
}

type StandardProps = {
    draggable?: never
} & BaseProps

type DraggableProps = {
    draggable: true
} & BaseProps

type Props = StandardProps | DraggableProps

export const Loaded: FC<Props> = ({
    action,
    draggable,
    hideMenu,
    isMostRecent,
}) => {
    const { ref: dragRef, dragging } = useDragActionCard(action)

    const nonDragRef = useRef<HTMLDivElement>(null)

    const [showMostRecent, setShowMostRecent] = useState(
        isMostRecent ? true : false
    )

    const ref = draggable ? dragRef : nonDragRef

    useEffect(() => {
        if (!isMostRecent) return
        ref.current?.scrollIntoView({ behavior: 'instant' })
        const timeout = setTimeout(() => {
            setShowMostRecent(false)
        }, 5000)
        return () => clearTimeout(timeout)
    })

    return (
        <StyledCard
            data-completed={action.__typename === 'CompletedAction'}
            ref={ref}
            $dragging={dragging}
            $draggable={draggable}
            $mostRecent={showMostRecent}
        >
            <CardHeader action={action} hideMenu={hideMenu} />
            <CardContent action={action} />
            <footer>
                <ActionToggle action={action} />
            </footer>
        </StyledCard>
    )
}
