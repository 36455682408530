import { FC } from 'react'
import { match } from 'ts-pattern'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { Checkboxes } from './Checkboxes'
import { Radios } from './Radios'
import { Selects } from './Selects'
import { useSelectConstraints } from './useSelectConstraints'

export const SelectField: FC<FieldInputProps<'SelectFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { UIToRender, constraintsTooltip, constraints } =
        useSelectConstraints({
            selectConstraints: field.selectConstraints,
        })

    return (
        <Field
            id={field.id}
            name={field.name}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
            required={constraints.min >= 1}
        >
            {match(UIToRender)
                .with('checkbox', () => (
                    <Checkboxes
                        field={field}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                    />
                ))
                .with('radio', () => (
                    <Radios
                        field={field}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                    />
                ))
                .with('select', () => (
                    <Selects
                        field={field}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                    />
                ))
                .exhaustive()}
        </Field>
    )
}
