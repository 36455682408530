import { useMemo } from 'react'
import { TextFieldConstraints } from '__generated__'
import { defaultConstraints, TextConstraintsMap } from './types'

type Params = {
    textConstraints: TextFieldConstraints[]
}

export const useTextConstraints = ({ textConstraints }: Params) => {
    const constraints = useMemo(() => {
        return textConstraints.reduce<TextConstraintsMap>((out, constraint) => {
            if (constraint.__typename === 'RequiredConstraint') {
                return { ...out, required: true }
            }

            if (constraint.__typename === 'LengthConstraint') {
                return {
                    ...out,
                    max: constraint.max ?? undefined,
                    min: constraint.min ?? undefined,
                }
            }
            return out
        }, defaultConstraints)
    }, [textConstraints])

    const constraintsTooltip = useMemo(() => {
        const strings = []

        if (constraints.required) strings.push('Required.')

        if (constraints.min && !constraints.max)
            strings.push(`Minimum of ${constraints.min} characters.`)

        if (constraints.max && !constraints.min)
            strings.push(`Maximum of ${constraints.max} characters.`)

        if (constraints.max && constraints.min) {
            if (constraints.max !== constraints.min) {
                strings.push(
                    `Must be between ${constraints.min} and ${constraints.max} characters.`
                )
            }

            if (constraints.max === constraints.min) {
                strings.push(`Must be exactly ${constraints.min} characters.`)
            }
        }

        return strings.join(' ')
    }, [constraints])

    return { constraints, constraintsTooltip }
}
