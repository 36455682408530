import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { SingleProcessHeader } from './Header'
import { Loading } from './Loading'
import { Phase } from './Phase'
import { useProcessDef_SingleProcessQuery } from './__generated__/query'

type Props = {
    id: string
    onProcessDeleted?: () => void
    navOptions?: {
        selected: number
        options: {
            text: string
            path: string
        }[]
    }
}

const SingleProcess: FC<Props> = ({ id, onProcessDeleted, navOptions }) => {
    const { data, loading, refetch } = useProcessDef_SingleProcessQuery({
        variables: { input: { id } },
    })

    return match((!loading || undefined) && data)
        .with(undefined, () => <Loading />)
        .otherwise(({ process }) => (
            <Styled>
                <SingleProcessHeader
                    onProcessDeleted={onProcessDeleted}
                    process={process}
                    canDelete={process.canDelete}
                    refetch={refetch}
                    navOptions={navOptions}
                />
                <div>
                    <ul>
                        {process.phases.map(phase => (
                            <Phase
                                key={phase.phaseIndex}
                                processId={process.id}
                                phase={phase}
                                operatesUpon={process.operatesUpon[0]}
                                refetch={refetch}
                            />
                        ))}
                    </ul>
                </div>
            </Styled>
        ))
}

const Styled = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    > div {
        flex: 1;
        overflow: auto;
        width: 100%;
    }

    > div > ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-template-rows: auto 1fr;
        grid-column-gap: 2rem;
        height: 100%;
        padding: 0 0 2rem 2rem;
    }

    > div > ul::after {
        content: '';
        width: 1px;
        grid-row: 1 / 3;
    }
`

export { SingleProcess }
