import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { EditBusinessObject } from 'Features/EditBusinessObject'
import { useInPhaseFieldCriteria } from 'Hooks/useInPhaseCriteria/useInPhaseCriteria'
import { ComponentProps, FC, useCallback } from 'react'
import { BusinessObjectFieldCompletionDetailsFragment } from './__generated__/q'

type FilterFields = NonNullable<
    ComponentProps<typeof EditBusinessObject>['fieldsFilter']
>

type Props = {
    businessObject: BusinessObjectFieldCompletionDetailsFragment
    onClose: () => void
    onFieldUpdated: (fieldId: string) => void
    includeFields?: string[]
}

const Content: FC<Props> = ({
    businessObject,
    onClose,
    onFieldUpdated,
    includeFields,
}) => {
    const fieldCriteria = useInPhaseFieldCriteria(businessObject)

    const filterFields = useCallback<FilterFields>(
        fields => {
            const incompleteFieldIds = fieldCriteria.incomplete.map(
                ({ id }) => id
            )
            return fields.filter(
                field =>
                    incompleteFieldIds.includes(field.fieldDefinition.id) ||
                    (includeFields ?? []).includes(field.fieldDefinition.id)
            )
        },
        [fieldCriteria, includeFields]
    )

    return (
        <EditBusinessObject
            businessObjectId={businessObject.id}
            fieldsFilter={filterFields}
            onComplete={onFieldUpdated}
            incompleteRelations={fieldCriteria.incompleteRelations}
            noFieldsDisplay={
                <StyledCompleted>
                    <Text as="p" variant="regular-4">
                        All fields have been completed.
                    </Text>
                    <footer>
                        <IconTextButton
                            icon="Check"
                            text="OK"
                            variant="primary"
                            onClick={onClose}
                        />
                    </footer>
                </StyledCompleted>
            }
        />
    )
}

const StyledCompleted = styled.div`
    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        text-align: center;
    }

    footer {
        padding-top: 1.5rem;
        display: flex;
    }

    footer button {
        flex: 1;
    }
`

export { Content }
