import { css, flex, styled } from 'Adapters/Freestyled'
import { bold4, bold5, bold6, bold7 } from 'Components/Text'
import { AvatarColorVariant, AvatarSize } from './types'

export const StyledAvatar = styled.div<{
    $variant: AvatarColorVariant
    $size: AvatarSize
}>`
    ${flex('column', 'center', 'center')};
    ${({ $size }) => applySizeStyles($size)};

    background-color: ${({ theme, $variant }) =>
        $variant === 'grey'
            ? theme.palette.ui['04'].normal
            : theme.palette.brand['01'].normal};

    border-radius: 50%;
    border: 0.125rem solid ${({ theme }) => theme.palette.ui.tint['03'].normal};

    > span {
        width: 100%;
        text-align: center;
    }
`

const applySizeStyles = (size: AvatarSize) => {
    switch (size) {
        case 'xSmall': {
            return css`
                ${bold7};
                line-height: 1.5rem;
                min-height: 1.5rem;
                min-width: 1.5rem;
                height: 1.5rem;
                width: 1.5rem;
            `
        }
        case 'small': {
            return css`
                ${bold6};
                line-height: 2rem;
                min-height: 2rem;
                min-width: 2rem;
                height: 2rem;
                width: 2rem;
            `
        }
        case 'medium': {
            return css`
                ${bold5};
                line-height: 2.5rem;
                min-height: 2.5rem;
                min-width: 2.5rem;
                height: 2.5rem;
                width: 2.5rem;
            `
        }
        // large
        default: {
            return css`
                ${bold4};
                line-height: 3rem;
                min-height: 3rem;
                min-width: 3rem;
                height: 3rem;
                width: 3rem;
            `
        }
    }
}
