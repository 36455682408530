import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { Definition } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'

type Props = {
    definition: Definition
    updatesEnabled: boolean
    documentsEnabled: boolean
}

const DefinitionHeader: FC<Props> = ({
    definition,
    updatesEnabled,
    documentsEnabled,
}) => {
    return (
        <Styled>
            <Headline>
                <Text as="h2" variant="bold-3">
                    {definition.name}
                </Text>
                {definition.description && (
                    <Text as="p" variant="regular-4">
                        {definition.description}
                    </Text>
                )}
                <Text as="p" variant="regular-4">
                    <strong>Label: </strong>
                    {definition.label}
                </Text>
            </Headline>
            <StyledTags>
                <li>
                    <Tag data-enabled={updatesEnabled}>
                        <Icon name="Comment" />
                        <Text as="span" variant="bold-6">
                            {`Updates ${
                                updatesEnabled ? 'Enabled' : 'Disabled'
                            }`}
                        </Text>
                    </Tag>
                </li>
                <li>
                    <Tag data-enabled={documentsEnabled}>
                        <Icon name="Document" />
                        <Text as="span" variant="bold-6">
                            {`Documents ${
                                documentsEnabled ? 'Enabled' : 'Disabled'
                            }`}
                        </Text>
                    </Tag>
                </li>
            </StyledTags>
        </Styled>
    )
}

const Styled = styled.header`
    display: flex;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
`

const Headline = styled.div`
    flex: 1;

    h2 {
        padding-bottom: 0.75rem;
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.75rem;
        max-width: 70ch;
    }

    strong {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }
`

const StyledTags = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
    li {
        flex; 1;
    }
`

const Tag = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    padding: 0.125rem 0.5rem 0.125rem 0.25rem;
    border-radius: 0.25rem;

    svg {
        height: 0.75rem;
        width: 0.75rem;
        min-height: 0;
        min-width: 0;
    }

    &:disabled {
        opacity: 0.5;
    }

    &[data-enabled='true'] {
        color: ${({ theme }) => theme.palette.text.support02.normal};
        border: 1px solid ${({ theme }) => theme.palette.support['02'].normal};
        background-color: ${({ theme }) => theme.palette.support.bg02.normal};
        svg path {
            fill: ${({ theme }) => theme.palette.text.support02.normal};
        }
    }
    &[data-enabled='false'] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        svg path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }
`

export { DefinitionHeader }
