import { Group } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Dropdown, DropdownCheckbox } from 'Components/Dropdown'
import { FileType, iconMap } from 'Components/FileIcon'
import { Tooltip } from 'Components/Tooltip'
import { FC, useState } from 'react'

type Props = {
    availableFileTypes: string[]
    fileTypeFilter: Set<string>
    toggleFileTypeFilter: (fileType: string) => void
}

export const Filter: FC<Props> = ({
    availableFileTypes,
    fileTypeFilter,
    toggleFileTypeFilter,
}) => {
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

    const hasFiltersApplied = fileTypeFilter.size > 0

    return (
        <Dropdown
            variant="light"
            side="left"
            align="start"
            open={dropdownMenuOpen}
            onOpenChange={setDropdownMenuOpen}
            renderInSpan // needed for tooltip
            renderOpenDropdownButton={() => (
                <Tooltip content="Filter">
                    <StyledTrigger
                        iconName={'Filter'}
                        variant="secondary"
                        $hasFiltersApplied={hasFiltersApplied}
                    />
                </Tooltip>
            )}
            renderContent={() => (
                <Group>
                    {availableFileTypes.map(fileType => (
                        <DropdownCheckbox
                            key={fileType}
                            text={
                                iconMap[fileType as FileType]?.name ?? fileType
                            }
                            onCheckedChange={() => {
                                toggleFileTypeFilter(fileType)
                            }}
                            checked={fileTypeFilter.has(fileType)}
                            closeOnSelect={false}
                        />
                    ))}
                </Group>
            )}
        />
    )
}

const pipStyles = css`
    position: relative;

    &:after {
        content: '';
        height: 0.5rem;
        width: 0.5rem;
        background-color: ${({ theme }) => theme.palette.support['01'].normal};
        border-radius: 50%;
        top: 0.1rem;
        right: 0.1rem;
        position: absolute;
    }
`

const StyledTrigger = styled(IconButton)<{ $hasFiltersApplied: boolean }>`
    ${({ $hasFiltersApplied }) => $hasFiltersApplied && pipStyles};
`
