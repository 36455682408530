import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

const NoFilteredResults: FC = () => (
    <Styled>
        <p>
            <em>No actions match the filter</em>
        </p>
    </Styled>
)

const Styled = styled.div`
    padding: 2rem 3rem 0;

    p {
        text-align: center;
    }
`

export { NoFilteredResults }
