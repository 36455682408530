import { resetCache } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks/useMutationStatus'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetActiveTenantMutation } from './__generated__/SetActiveTenant'

export const useSetActiveTenant = (
    navigateToOnComplete: string | undefined = '/'
) => {
    const navigate = useNavigate()

    const { triggerToast, open, setOpen } = useTriggerToast()

    const [updateActiveTenant, mutationData] = useSetActiveTenantMutation({
        onCompleted: async () => {
            await resetCache()
            localStorage.clear()
            navigate(navigateToOnComplete, { replace: true })
        },
        onError: e => {
            logger.error('Failed to set active tenant', e)
            triggerToast()
        },
        onQueryUpdated: query => query.refetch(),
    })

    const { status } = useMutationStatus(mutationData)

    const setActiveTenant = useCallback(
        (tenantId: string) => {
            updateActiveTenant({
                variables: {
                    input: { tenantId },
                },
            })
        },
        [updateActiveTenant]
    )

    return {
        setActiveTenant,
        status,
        errorToast: { open, onOpenChange: setOpen },
    }
}
