import { CreateProcessInput } from '__generated__'
import { CreateIntegrationState } from '../IntegrationEditor'
import { State } from './useProcessCreator/types'

type SharedProps = {
    onCompleted: (process: { id: string; name: string }) => void
    initialStep?: State['step']
}

type CreateModeProps = {
    preselectedBO?: {
        id: string
        name: string
    }
    initialProcess?: never
    initialIntegrations?: never
    processId?: never
} & SharedProps

type EditModeProps = {
    preselectedBO?: never
    initialProcess: CreateProcessInput
    initialIntegrations: (CreateIntegrationState & { id: string })[]
    processId: string
} & SharedProps

export const isEditMode = (props: Props): props is EditModeProps =>
    props.initialProcess !== undefined

export type Props = EditModeProps | CreateModeProps

export type Mode = 'edit' | 'create'

export const determineMode = (props: Props): Mode =>
    isEditMode(props) ? 'edit' : 'create'
