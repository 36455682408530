/* eslint-disable no-console */
import { datadogLogs, LogsEvent } from '@datadog/browser-logs'

/* TODO
 * - use addContext when user session is setup to add user / tenant / organisation to all logs i.e.
 *       logger.addContext('modlify', {
 *           user,
 *           organisation,
 *       })
 */

const {
    VITE_DATADOG_CLIENT_TOKEN,
    VITE_DATADOG_ENV,
    VITE_DATADOG_SITE,
    VITE_COMMIT_REF,
    DEV,
    PROD,
} = import.meta.env

export const initDatadog = () => {
    if (
        PROD &&
        (!VITE_DATADOG_CLIENT_TOKEN ||
            !VITE_DATADOG_SITE ||
            !VITE_DATADOG_ENV ||
            !VITE_COMMIT_REF)
    ) {
        console.error('Datadog environment variables not set correctly')
        return
    }

    try {
        datadogLogs.init({
            clientToken: VITE_DATADOG_CLIENT_TOKEN,
            site: VITE_DATADOG_SITE,
            env: VITE_DATADOG_ENV,
            forwardErrorsToLogs: true,
            version: VITE_COMMIT_REF,
            sampleRate: 100,
            service: 'web',
            beforeSend: ({ status, error }: LogsEvent) => {
                if (
                    status === 'error' &&
                    !!error?.stack?.includes('AbortError')
                )
                    return false
            },
        })
    } catch (error) {
        console.error(error, `Error setting up datadog`)
    }
}

export const logger = {
    info: (message: string, context?: object) => {
        try {
            if (DEV) {
                console.info({
                    message,
                    context,
                })
            }
            datadogLogs.logger.info(message, context)
        } catch (error) {
            console.error(error, `Error setting up datadog`)
        }
    },
    debug: (message: string, context?: object) => {
        try {
            if (DEV)
                console.info({
                    message,
                    context,
                })

            datadogLogs.logger.debug(message, context)
        } catch (error) {
            console.error(error, `Error setting up datadog`)
        }
    },
    error: (message: string, error?: Error, context?: object) => {
        try {
            if (DEV) {
                console.error(error)
                console.error({ message, context })
            }

            datadogLogs.logger.error(message, {
                ...context,
                error: error,
            })
        } catch (error) {
            console.error(error, `Error setting up datadog`)
        }
    },
    addContext: (key: string, value: any) => {
        try {
            datadogLogs.logger.addContext(key, value)
        } catch (error) {
            console.error(error, `Error calling addContext on datadog logger`)
        }
    },
}
