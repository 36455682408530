import { useFlow } from '@frigade/react'
import * as Popover from '@radix-ui/react-popover'
import { styled } from 'Adapters/Freestyled'
import { FrigadeHint } from 'Features/Frigade/FrigadeHint'
import { getPortalsContainer } from 'Utils'
import { FC, ReactNode } from 'react'
import { keyframes } from 'styled-components'
import { TutorialTooltipContent } from './TutorialTooltipContent'
import { IDENTIFIER, STEP_IDENTIFIER } from './constants'
import { StyledTutorialTrigger } from './styles'

export type Props = {
    content: ReactNode
    container?: HTMLElement | undefined | null
}

export const TutorialTooltip: FC<Props> = ({
    content,
    container = getPortalsContainer(),
}) => {
    const { flow } = useFlow(IDENTIFIER)
    const hint = flow?.steps.get(STEP_IDENTIFIER)

    if (!hint) return null

    return (
        <Popover.Root>
            <FrigadeHint flowId={IDENTIFIER} stepId={STEP_IDENTIFIER}>
                <Popover.Trigger asChild>
                    <StyledTutorialTrigger
                        iconName={'Question'}
                        onClick={() => {
                            if (!hint.$state.completed) {
                                hint.complete()
                            }
                        }}
                    />
                </Popover.Trigger>
            </FrigadeHint>

            <Popover.Portal container={container}>
                <StyledContent side="right" align="start" sideOffset={10}>
                    <TutorialTooltipContent content={content} />
                </StyledContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const DURATION = 400
const TIMING = 'cubic-bezier(0.16, 1, 0.3, 1)'

const slideOutFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideBackToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-1rem);
  }
`

const StyledContent = styled(Popover.Content)`
    max-height: 75vh;
    max-width: 25rem;
    padding: 1rem;
    background-color: white;
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};

    // Animation
    animation-duration: ${DURATION}ms;
    animation-timing-function: ${TIMING};
    will-change: opacity, transform;

    &[data-state='open'][data-side='right'] {
        animation-name: ${slideOutFromLeft};
    }

    &[data-state='closed'][data-side='right'] {
        animation-name: ${slideBackToLeft};
    }
`
