import { has, isNull, omit } from 'lodash'
import {
    FieldDefinitionTemplate,
    FieldType,
    ListFieldDefinitionTemplate,
} from '__generated__'
import { documentToggleFieldListOfName } from '../../Utils'
import { Fields, FieldShared } from './types'

const commonFieldProps: FieldShared = {
    name: '',
    description: '',
}

const blankFields = {
    date: () => ({ constraints: [], precision: 'day' }),
    boolean: () => ({ defaultValue: false }),
    document: () => ({ constraints: [] }),
    email: () => ({ constraints: [] }),
    list: () => ({}),
    number: () => ({ constraints: [] }),
    relation: () => ({ constraints: [] }),
    select: () => ({ constraints: [], options: [] }),
    telephone: () => ({
        constraints: [],
        defaultValue: { number: null, countryCode: null },
    }),
    text: () => ({ constraints: [] }),
    url: () => ({ constraints: [] }),
    user: () => ({ constraints: [] }),
    updates: () => ({}),
    currency: () => ({ constraints: [] }),
}

export const blankField = <T extends Fields['type']>(type: T) =>
    ({
        type,
        ...commonFieldProps,
        ...blankFields[type](),
    } as Fields)

export const updatesFieldMutationVar = {
    name: 'Updates',
    description: 'Updates about this object',
    [FieldType.List]: {
        listOf: {
            name: 'Update',
            [FieldType.Updates]: {},
        },
    },
}

export const documentsFieldMutationVar = {
    name: 'Documents',
    description: 'Documents relating to this object',
    [FieldType.List]: {
        listOf: {
            name: documentToggleFieldListOfName,
            [FieldType.Document]: {
                constraints: [],
            },
        },
    },
}

export const noNullKeys = <T extends object>(obj: T) =>
    Object.fromEntries(Object.entries(obj).filter(([_, val]) => !isNull(val)))

export const hasConstraints = <T>(
    field: T
): field is Extract<T, { constraints: any }> => has(field, 'constraints')

export const stataliseField = (field: FieldDefinitionTemplate): Fields => ({
    ...omit(field, 'listOf'),
    ...(has(field, 'listOf') && {
        listOf: stataliseField((field as ListFieldDefinitionTemplate).listOf),
    }),
    ...(hasConstraints(field) && {
        constraints: (field as any).constraints.map(
            // excuse the anys - GQL codegen makes this very difficult to do accurately
            ({ type, ...constraint }: any) => ({
                [type]: noNullKeys(constraint),
            })
        ),
    }),
    type: field.type as any,
})
