import { ProcessDisplayFragment } from '../__generated__/q'
import { ProcessDisplay, ViewConfig } from '../types'
import { flattenAndFilterBusinessObjects } from './filters'
import { buildGroups } from './grouping'

export const applyViewConfig =
    (config: ViewConfig) =>
    (process: ProcessDisplayFragment): ProcessDisplay => {
        const { buildPhaseGroups } = buildGroups(config)
        return {
            phases: process.phases.map(phase => {
                const filteredPhase = {
                    ...phase,
                    businessObjects: {
                        nodes: flattenAndFilterBusinessObjects(
                            Object.values(config.filters),
                            config.showCompleted
                                ? phase.businessObjects.nodes
                                : phase.businessObjects.nodes.filter(
                                      bo => !bo.onProcess?.isCompleted
                                  )
                        ),
                    },
                }

                return {
                    name: phase.name,
                    phaseIndex: phase.phaseIndex,
                    count: filteredPhase.businessObjects.nodes.length,
                    groups: buildPhaseGroups(filteredPhase),
                }
            }),
        }
    }
