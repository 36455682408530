import { useCreateDocument, useUploadSignature } from 'Components/Documents'
import { CreateDocumentMutation } from 'Components/Documents/hooks/__generated__/mutations'
import { useModalControls } from 'Hooks'

type Params = {
    onComplete: (data: CreateDocumentMutation) => void
}

export const useUploadDocument = ({ onComplete }: Params) => {
    const { data } = useUploadSignature()

    const { open, setOpen, returnFocus, buttonRef } = useModalControls()

    const { createDocument, errorToastOpen, setErrorToastOpen } =
        useCreateDocument({
            onComplete(data) {
                if (data) onComplete(data)
                setOpen(false)
            },
        })

    return {
        expire: data?.uploadSignature.expire,
        signature: data?.uploadSignature.signature,
        uploadModalOpen: open,
        setUploadModalOpen: setOpen,
        returnFocus,
        buttonRef,
        createDocument,
        errorToastOpen,
        setErrorToastOpen,
    }
}
