import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { fromEvent } from 'Utils'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import type { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const UrlField: FC<
    Field<'URLFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: NonNullable<
                    PatchBusinessObjectDefinitionFieldDefaultInput['url']
                >
            ) => void
        }
> = ({ urlDefaultValue, urlConstraints, handleDefaultChanged, ...common }) => (
    <AbstractField
        {...common}
        defaultDisplay={
            common.isEditing ? (
                <TextInput
                    name={common.type + '||' + common.id}
                    id={common.id}
                    value={urlDefaultValue ?? ''}
                    onChange={e => {
                        const value = fromEvent(e)

                        handleDefaultChanged({
                            fieldId: common.id,
                            previousValue: urlDefaultValue,
                            nextValue: value ? value : null,
                        })
                    }}
                />
            ) : (
                <Text
                    as="p"
                    variant="regular-4"
                    className={!urlDefaultValue ? 'none-set' : ''}
                >
                    {urlDefaultValue ?? 'No default value set'}
                </Text>
            )
        }
        constraints={urlConstraints}
    />
)
