import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { BusinessObjectDefinitionCard } from 'Features/BusinessObjectDefinitionCard'
import { BusinessObjectDefinitionCreator } from 'Features/BusinessObjectDefinitionCreator'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { Breadcrumb } from '../../Breadcrumb/Breadcrumb'
import { ModelManagerBusinessObjectDefinitionsQuery } from '../__generated__/q'
import { NoDefinitions } from './NoDefinitions'

type Props = {
    definitions: ModelManagerBusinessObjectDefinitionsQuery['businessObjectDefinitions']
}

const Directory: FC<Props> = ({ definitions }) => {
    const navigate = useNavigate()

    return (
        <Styled>
            <header>
                <Breadcrumb area="datasets" />
                <menu>
                    <IconTextButton
                        icon="Hub"
                        size="xSmall"
                        text="Visualise datasets"
                        variant="secondary"
                        onClick={() => navigate('./visualise')}
                    />
                    <BusinessObjectDefinitionCreator />
                </menu>
            </header>
            {definitions.length ? (
                <StyledCards>
                    <ul>
                        {definitions.map(def => (
                            <li key={def.id}>
                                <BusinessObjectDefinitionCard
                                    def={def}
                                    onClick={() =>
                                        navigate(
                                            slugify(def.name, {
                                                lower: true,
                                            })
                                        )
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </StyledCards>
            ) : (
                <NoDefinitions />
            )}
        </Styled>
    )
}

const Styled = styled.section`
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }

    > article {
        height: 100%;
        overflow: auto;
    }
`

const StyledCards = styled.div`
    > ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;
        padding: 2rem;
    }
`

export { Directory }
