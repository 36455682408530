import * as Types from '../../__generated__/index';

import { gql } from '@apollo/client';
export type ComparatorFragment = { __typename: 'BusinessObjectComparator', negate: boolean, operator: Types.Operator, with: string, valueSelector: { __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'DeepSelector', type: Types.ValueSelectorType, selectors: Array<{ __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType }> } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType } };

export const ComparatorFragmentDoc = gql`
    fragment Comparator on BusinessObjectComparator {
  __typename
  negate
  operator
  with
  valueSelector {
    __typename
    type
    ... on FieldValueSelector {
      default
      fieldId
      type
    }
    ... on ConstantSelector {
      type
      value
    }
    ... on DeepSelector {
      selectors {
        __typename
        type
        ... on FieldValueSelector {
          default
          fieldId
          type
        }
        ... on ConstantSelector {
          type
          value
        }
      }
    }
  }
}
    `;