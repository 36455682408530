import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SmallWorkspaceControlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SmallWorkspaceControlQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } } | null, tenancies: Array<{ __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } }> }, processes: Array<{ __typename?: 'Process', id: string }>, businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string }>, users: Array<{ __typename?: 'User', id: string }> };


export const SmallWorkspaceControlDocument = gql`
    query SmallWorkspaceControl {
  currentUser {
    id
    activeTenant {
      tenant {
        id
        name
      }
    }
    tenancies {
      tenant {
        id
        name
      }
    }
  }
  processes {
    id
  }
  businessObjectDefinitions {
    id
  }
  users {
    id
  }
}
    `;

/**
 * __useSmallWorkspaceControlQuery__
 *
 * To run a query within a React component, call `useSmallWorkspaceControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmallWorkspaceControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmallWorkspaceControlQuery({
 *   variables: {
 *   },
 * });
 */
export function useSmallWorkspaceControlQuery(baseOptions?: Apollo.QueryHookOptions<SmallWorkspaceControlQuery, SmallWorkspaceControlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmallWorkspaceControlQuery, SmallWorkspaceControlQueryVariables>(SmallWorkspaceControlDocument, options);
      }
export function useSmallWorkspaceControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmallWorkspaceControlQuery, SmallWorkspaceControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmallWorkspaceControlQuery, SmallWorkspaceControlQueryVariables>(SmallWorkspaceControlDocument, options);
        }
export type SmallWorkspaceControlQueryHookResult = ReturnType<typeof useSmallWorkspaceControlQuery>;
export type SmallWorkspaceControlLazyQueryHookResult = ReturnType<typeof useSmallWorkspaceControlLazyQuery>;
export type SmallWorkspaceControlQueryResult = Apollo.QueryResult<SmallWorkspaceControlQuery, SmallWorkspaceControlQueryVariables>;