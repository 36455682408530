import { useLogout } from 'Adapters/Authentication/useLogout'
import { LoadingDisplay } from 'Components/LoadingDisplay'
import { FC, useEffect } from 'react'

export const Logout: FC = () => {
    const { logout } = useLogout()

    useEffect(() => {
        logout()
    }, [logout])

    return <LoadingDisplay />
}
