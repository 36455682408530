import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { fieldLabels } from 'Utils'
import { FieldType } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'

type Props = {
    onFieldAdded: (fieldType: FieldType) => void
}

export const AddFieldContent: FC<Props> = ({ onFieldAdded }) => {
    return (
        <StyledAddFieldContent>
            <ul>
                {Object.values(FieldType).map(fieldType => (
                    <li key={fieldType}>
                        <button
                            onClick={() => {
                                onFieldAdded(fieldType)
                            }}
                        >
                            <Text as="span" variant="bold-4">
                                {fieldLabels[fieldType]}
                            </Text>
                            <Icon name="AngleRight" />
                        </button>
                    </li>
                ))}
            </ul>
        </StyledAddFieldContent>
    )
}

const StyledAddFieldContent = styled.div`
    overflow: auto;
    max-height: 70vh;
    margin-top: 1rem;

    > ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    li button {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: 0.25rem;
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

        @media (hover: hover) {
            &:hover {
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].hover};
            }
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.ui['02'].active};
        }
    }
`
