import { TextInput } from 'Components/Input'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { fromEvent } from '../../../../Utils'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useEmailConstraints } from './useEmailConstraints'

export const EmailField: FC<FieldInputProps<'EmailFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useEmailConstraints({
        emailConstraints: field.emailConstraints,
    })

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <TextInput
                name={field.type + '||' + field.id}
                id={field.id}
                value={value ?? undefined}
                onChange={pipe(fromEvent, onChange)}
                type="email"
                required={constraints.required}
            />
        </Field>
    )
}
