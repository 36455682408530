import { omit } from 'lodash'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { styled } from '../../../Adapters/Freestyled'
import { TextButton } from '../../../Components/Button'
import { Text } from '../../../Components/Text'
import { documentToggleFieldListOfName, oneOfPair } from '../../../Utils'
import { FieldType } from '../../../__generated__'
import { Fields, PatchOperation } from '../types'

export type Props = {
    operations: PatchOperation[]
    fields: Fields
    onUndoOperation: (opIndex: number) => () => void
}

export const PatchDisplay: FC<Props> = ({
    operations,
    fields,
    onUndoOperation,
}) =>
    operations.length ? (
        <>
            <Text as="h3" variant="regular-3">
                Your changes
            </Text>
            <StyledList>
                {operations.map((operation, index) => (
                    <DisplayOperationListItem
                        operation={operation}
                        key={index}
                        fields={fields}
                        onUndoOperation={onUndoOperation(index)}
                    />
                ))}
            </StyledList>
        </>
    ) : null

const getFieldName = (fields: Fields, id: string) => {
    return fields.find(field => field.id === id)?.name ?? id
}

const DisplayOperationListItem: FC<{
    operation: PatchOperation
    fields: Fields
    onUndoOperation: () => void
}> = ({ operation, fields, onUndoOperation }) => {
    return match(operation)
        .with(
            {
                operation: 'addField',
                nextValue: {
                    type: FieldType.List,
                    listOf: { type: FieldType.Updates },
                },
            },
            () => (
                <Text as="li" variant="regular-4">
                    <TextButton
                        variant="ghost"
                        text="Undo"
                        onClick={onUndoOperation}
                    />
                    <span>Enabled updates</span>
                </Text>
            )
        )
        .with(
            {
                operation: 'addField',
                nextValue: {
                    type: FieldType.List,
                    listOf: {
                        type: FieldType.Document,
                        name: documentToggleFieldListOfName,
                    },
                },
            },
            () => (
                <Text as="li" variant="regular-4">
                    <TextButton
                        variant="ghost"
                        text="Undo"
                        onClick={onUndoOperation}
                    />
                    <span>Enabled documents</span>
                </Text>
            )
        )
        .with({ operation: 'addField' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Added new <DynamicValue>{op.nextValue.type}</DynamicValue>{' '}
                    field with name{' '}
                    <DynamicValue>{op.nextValue.name}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateName' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Changed definition name from{' '}
                    <DynamicValue>{op.previousValue}</DynamicValue> to{' '}
                    <DynamicValue>{op.nextValue}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateDescription' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Changed definition description from{' '}
                    <DynamicValue>{op.previousValue}</DynamicValue> to{' '}
                    <DynamicValue>{op.nextValue}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateLabel' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Changed definition label from{' '}
                    <DynamicValue>{op.previousValue}</DynamicValue> to{' '}
                    <DynamicValue>{op.nextValue}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'removeFieldConstraint' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Removed <DynamicValue>{op.constraintType}</DynamicValue>{' '}
                    constraint from field{' '}
                    <DynamicValue>
                        {getFieldName(fields, op.fieldId)}
                    </DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateFieldDescription' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Changed the description of field{' '}
                    <DynamicValue>
                        {getFieldName(fields, op.fieldId)}
                    </DynamicValue>{' '}
                    from <DynamicValue>{op.previousValue}</DynamicValue> to{' '}
                    <DynamicValue>{op.nextValue}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateFieldName' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Changed name of field{' '}
                    <DynamicValue>{op.previousValue}</DynamicValue> to{' '}
                    <DynamicValue>{op.nextValue}</DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'addSelectFieldOption' }, op => (
            <Text as="li" variant="regular-4">
                <TextButton
                    variant="ghost"
                    text="Undo"
                    onClick={onUndoOperation}
                />
                <span>
                    Added option <DynamicValue>{op.nextValue}</DynamicValue> to
                    select field{' '}
                    <DynamicValue>
                        {getFieldName(fields, op.fieldId)}
                    </DynamicValue>
                </span>
            </Text>
        ))
        .with({ operation: 'updateFieldDefault' }, op => {
            const [, { fieldId }] = oneOfPair(omit(op, 'operation'))

            return (
                <Text as="li" variant="regular-4">
                    <TextButton
                        variant="ghost"
                        text="Undo"
                        onClick={onUndoOperation}
                    />
                    <span>
                        Changed the default value of field{' '}
                        <DynamicValue>
                            {getFieldName(fields, fieldId)}
                        </DynamicValue>
                    </span>
                </Text>
            )
        })
        .with({ operation: 'removeField' }, op => {
            return (
                <Text as="li" variant="regular-4">
                    <TextButton
                        variant="ghost"
                        text="Undo"
                        onClick={onUndoOperation}
                    />
                    <span>
                        Removed field{' '}
                        <DynamicValue>
                            {getFieldName(fields, op.fieldId)}
                        </DynamicValue>
                    </span>
                </Text>
            )
        })
        .exhaustive()
}

const StyledList = styled.ul`
    > li {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        padding-bottom: 1rem;
    }
`

const DynamicValue = styled.span`
    font-weight: bold;
`
