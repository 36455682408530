import { styled } from 'Adapters/Freestyled'
import { IntegrationProviders } from 'Features/IntegrationProviders'
import { FC } from 'react'

export const Integrations: FC = () => (
    <Styled>
        <IntegrationProviders />
    </Styled>
)

const Styled = styled.section`
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    height: 100%;
`
