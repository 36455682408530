export enum FieldType {
    Boolean = 'boolean',
    Currency = 'currency',
    Date = 'date',
    Document = 'document',
    Email = 'email',
    List = 'list',
    Number = 'number',
    Relation = 'relation',
    Select = 'select',
    Telephone = 'telephone',
    Text = 'text',
    Url = 'url',
    User = 'user',
}

export type FieldHead = {
    id: string
    name: string
    description: string
    constraintsLocked: boolean
}

export type BooleanFieldUnique = {
    type: FieldType.Boolean
    defaultValue: boolean
}
export type BooleanField = FieldHead & BooleanFieldUnique

export type CurrencyFieldUnique = {
    type: FieldType.Currency
    constraints: { required: boolean }
    defaultValue: {
        amount: number | undefined
        currencyCode: string | undefined
    }
}
export type CurrencyField = FieldHead & CurrencyFieldUnique

export enum DateFieldPrecision {
    Day = 'day',
    Month = 'month',
    Year = 'year',
}

export type DateFieldUnique = {
    type: FieldType.Date
    constraints: {
        required: boolean
        dateRange: {
            end: string | undefined
            start: string | undefined
        }
    }
    defaultValue:
        | {
              absolute: string
          }
        | {
              relative: string
          }
        | undefined
    precision: DateFieldPrecision | undefined
}
export type DateField = FieldHead & DateFieldUnique

export type DocumentFieldUnique = {
    type: FieldType.Document
    constraints: { required: boolean }
}
export type DocumentField = FieldHead & DocumentFieldUnique

export type EmailFieldUnique = {
    type: FieldType.Email
    defaultValue: string
    constraints: { required: boolean }
}
export type EmailField = FieldHead & EmailFieldUnique

export type NumberFieldUnique = {
    type: FieldType.Number
    constraints: {
        required: boolean
        range: {
            max: number | undefined
            min: number | undefined
        }
    }
    defaultValue: number | undefined
}
export type NumberField = FieldHead & NumberFieldUnique

export type RelationFieldUnique = {
    type: FieldType.Relation
    constraints: {
        required: boolean
        relationType: {
            id: string
            name: string
        }
    }
}
export type RelationField = FieldHead & RelationFieldUnique

export type SelectFieldUnique = {
    type: FieldType.Select
    constraints: {
        numberOfSelections: {
            max: number | undefined
            min: number | undefined
        }
    }

    defaultValue: string[]
    options: {
        id: string
        value: string
        locked: boolean
    }[]
}
export type SelectField = FieldHead & SelectFieldUnique

export type TelephoneFieldUnique = {
    type: FieldType.Telephone
    constraints: { required: boolean }
    defaultValue: {
        countryCode: string | undefined
        number: string | undefined
    }
}
export type TelephoneField = FieldHead & TelephoneFieldUnique

export type TextFieldUnique = {
    type: FieldType.Text
    constraints: {
        required: boolean
        length: {
            max: number | undefined
            min: number | undefined
        }
    }
    defaultValue: string | undefined
}
export type TextField = FieldHead & TextFieldUnique

export type UrlFieldUnique = {
    type: FieldType.Url
    constraints: { required: boolean }
    defaultValue: string | undefined
}
export type UrlField = FieldHead & UrlFieldUnique

export type UserFieldUnique = {
    type: FieldType.User
    constraints: { required: boolean }
    defaultValue: string | undefined
}
export type UserField = FieldHead & UserFieldUnique

export type ListOf =
    | BooleanFieldUnique
    | CurrencyFieldUnique
    | DateFieldUnique
    | DocumentFieldUnique
    | EmailFieldUnique
    | NumberFieldUnique
    | RelationFieldUnique
    | SelectFieldUnique
    | TelephoneFieldUnique
    | TextFieldUnique
    | UrlFieldUnique
    | UserFieldUnique

export type ListField = FieldHead & {
    type: FieldType.List
    listOf: ListOf
}

export type Field =
    | BooleanField
    | CurrencyField
    | DateField
    | DocumentField
    | EmailField
    | ListField
    | NumberField
    | RelationField
    | SelectField
    | TelephoneField
    | TextField
    | UrlField
    | UserField

export type Definition = {
    id: string
    description: string | undefined
    fields: Field[]
    label: string
    name: string
}
