import { FieldType } from '__generated__'
import { z } from 'zod'

export const SourceSchema = z.object({
    businessObjectId: z.string(),
    groupId: z.string(),
})

export type Source = z.infer<typeof SourceSchema>

export const DestinationSchema = z.object({
    groupId: z.string(),
    fieldType: z.nativeEnum(FieldType),
})

export type Destination = z.infer<typeof DestinationSchema>
