import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChecklistItem } from './ChecklistItem'
import { StyledChecklistItemActions } from './styles'

type Props = {
    closeModal: () => Promise<void>
}

export const CreateProcess: FC<Props> = ({ closeModal }) => {
    const navigate = useNavigate()

    return (
        <ChecklistItem stepId="setup-workspace">
            <StyledChecklistItemActions>
                <p>
                    <button
                        onClick={() => {
                            navigate('/welcome')
                            closeModal()
                        }}
                    >
                        Set up your workspace with a workflow
                    </button>
                </p>
            </StyledChecklistItemActions>
        </ChecklistItem>
    )
}
