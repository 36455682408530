import { IconTextButton } from 'Components/Button'
import { PrincipalName } from 'Features/Principal/Name'
import { noop } from 'lodash'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { PrincipalType } from '../../../__generated__'
import { ExtensionDisplayFields } from './ExtensionDisplayFields'
import { ActionTimelineCardQuery } from './__generated__/query'
import { boLink, processLink } from './utils'

type Props = {
    action: ActionTimelineCardQuery['action']
}

const CardContent: FC<Props> = ({ action }) => {
    const navigate = useNavigate()
    return (
        <div>
            {match(action)
                .with(
                    { relatesTo: { process: P.not(P.nullish) } },
                    ({ relatesTo }) => (
                        <>
                            <IconTextButton
                                size="xSmall"
                                variant="ghost"
                                icon="Cube"
                                text={relatesTo.businessObject.label}
                                onClick={() =>
                                    navigate(boLink(relatesTo.businessObject))
                                }
                            />
                            <IconTextButton
                                size="xSmall"
                                variant="ghost"
                                icon="Process"
                                text={relatesTo.process.name}
                                onClick={() =>
                                    navigate(processLink(relatesTo.process))
                                }
                            />
                        </>
                    )
                )
                .with({ relatesTo: P.not(P.nullish) }, ({ relatesTo }) => (
                    <IconTextButton
                        size="xSmall"
                        variant="ghost"
                        icon="Cube"
                        text={relatesTo.businessObject.label}
                        onClick={() =>
                            navigate(boLink(relatesTo.businessObject))
                        }
                    />
                ))
                .otherwise(() => null)}
            <IconTextButton
                size="xSmall"
                variant="ghost"
                icon={match(action.assignedTo)
                    .with({ type: PrincipalType.Group }, () => 'Team' as const)
                    .otherwise(() => 'User')}
                text={
                    action.assignedTo ? (
                        <PrincipalName principal={action.assignedTo} />
                    ) : (
                        'Unassigned'
                    )
                }
                onClick={noop}
                disabled
            />

            {action.extendedBy ? (
                <ExtensionDisplayFields obj={action.extendedBy} />
            ) : null}
        </div>
    )
}

export { CardContent }
