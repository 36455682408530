import { styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { FC, ReactNode } from 'react'
import { P, match } from 'ts-pattern'
import { PrefilledBusinessObject } from '../CreateBusinessObjectModal/types'
import {
    BooleanField,
    CurrencyField,
    DateField,
    DocumentField,
    EmailField,
    ListField,
    NumberField,
    RelationField,
    SelectField,
    TelephoneField,
    TextField,
    URLField,
    UserField,
} from './Fields'
import {
    EditBusinessObject_BusinessObjectQuery,
    useEditBusinessObject_BusinessObjectQuery,
} from './__generated__/q'

type Fields = EditBusinessObject_BusinessObjectQuery['businessObject']['fields']

type Props = {
    businessObjectId: string
    prefilledBusinessObject?: PrefilledBusinessObject
    fieldsFilter?: (fields: Fields) => Fields
    hideViewDocumentButton?: boolean
    className?: string
    noFieldsDisplay?: ReactNode
    onComplete?: (fieldId: string) => void
    incompleteRelations?: Record<
        string,
        {
            fieldId: string
            relationId: string | undefined
            relationFieldId: string
        }[]
    >
}

const EditBusinessObject: FC<Props> = ({
    businessObjectId,
    fieldsFilter = fields => fields,
    hideViewDocumentButton = false,
    className = '',
    noFieldsDisplay = null,
    onComplete,
    incompleteRelations,
}) => {
    const res = useEditBusinessObject_BusinessObjectQuery({
        variables: { input: { id: businessObjectId } },
        fetchPolicy: 'cache-and-network',
    })

    const handleCompleted = (fieldId: string) => () => {
        onComplete?.(fieldId)
    }

    return match(res)
        .with({ loading: true }, () => <CenteredSpinner />)
        .with({ loading: false, data: undefined }, () => (
            <div>No BO with the ID {businessObjectId} was found</div>
        ))
        .with(
            { loading: false, data: P.not(P.nullish) },
            ({
                data: {
                    businessObject: { fields },
                },
            }) =>
                match(fieldsFilter(fields))
                    .with([], () => <>{noFieldsDisplay}</>)
                    .otherwise(fs => (
                        <StyledEditBusinessObject className={className}>
                            {fs.map((field, index) =>
                                match(field)
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectTextField',
                                        },
                                        props => (
                                            <TextField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectBooleanField',
                                        },
                                        props => (
                                            <BooleanField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectDateField',
                                        },
                                        props => (
                                            <DateField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectDocumentField',
                                        },
                                        props => (
                                            <DocumentField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                hideViewDocumentButton={
                                                    hideViewDocumentButton
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectEmailField',
                                        },
                                        props => (
                                            <EmailField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectNumberField',
                                        },
                                        props => (
                                            <NumberField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectRelationField',
                                        },
                                        props => (
                                            <RelationField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                                incompleteRelations={
                                                    incompleteRelations?.[
                                                        field.fieldDefinition.id
                                                    ]
                                                }
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectSelectField',
                                        },
                                        props => (
                                            <SelectField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectTelephoneField',
                                        },
                                        props => (
                                            <TelephoneField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUrlField',
                                        },
                                        props => (
                                            <URLField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUserField',
                                        },
                                        props => (
                                            <UserField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectListField',
                                        },
                                        props => (
                                            <ListField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )

                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUpdatesField',
                                        },
                                        () => null // Updates only ever exist on a list
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectCurrencyField',
                                        },
                                        props => (
                                            <CurrencyField
                                                key={field.fieldDefinition.id}
                                                businessObjectId={
                                                    businessObjectId
                                                }
                                                {...props}
                                                onComplete={handleCompleted(
                                                    field.fieldDefinition.id
                                                )}
                                            />
                                        )
                                    )
                                    .exhaustive()
                            )}
                        </StyledEditBusinessObject>
                    ))
        )
        .exhaustive()
}

const StyledEditBusinessObject = styled.div`
    width: 100%;
    max-width: 62.5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export { EditBusinessObject }
