import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import {
    checkBusinessObjectDefinitionUpdated,
    encodeGroupState,
} from 'Features/BusinessObjectView/Grouping'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { encodeFilterState } from '../../../helpers'
import {
    GetBusinessObjectViewsQuery,
    useGetBusinessObjectViewsQuery,
} from '../../__generated__/query'
import { FieldDefinitions, ViewSchema } from '../../types'
import { ManageViews } from './ManageViews'
import { MenuButton } from './MenuButton'

type Props = {
    fieldDefinitions: FieldDefinitions
    businessObjectDefinitionId: string
}

const ApplyView: FC<Props> = ({
    fieldDefinitions,
    businessObjectDefinitionId,
}) => {
    const { data } = useGetBusinessObjectViewsQuery({
        variables: {
            input: {
                businessObjectDefinitionId,
                type: ViewOfType.BusinessObjects,
            },
        },
        fetchPolicy: 'cache-and-network',
    })

    const [open, setOpen] = useState(false)

    const [, setSearchParams] = useSearchParams()

    const {
        open: toastOpen,
        setOpen: setToastOpen,
        triggerToast,
    } = useTriggerToast()

    const handleSelectView = useCallback(
        (view: GetBusinessObjectViewsQuery['views'][number]) => {
            const decodedView = JSON.parse(
                Buffer.from(view.configuration, 'base64').toString()
            )

            const isValidSchema = ViewSchema.safeParse(decodedView)

            if (!isValidSchema.success) {
                logger.error('Invalid view schema', isValidSchema.error)
                triggerToast()
                return
            }

            const bodefHasChangedSinceLastEncoded = !decodedView.grouping
                ? false
                : checkBusinessObjectDefinitionUpdated(
                      decodedView.grouping,
                      fieldDefinitions
                  )

            if (bodefHasChangedSinceLastEncoded) {
                triggerToast()
                return
            }

            setSearchParams({
                filterState: encodeFilterState(decodedView.filterState),
                display: decodedView.display,
                selectedFields: decodedView.selectedFields,
                sort: decodedView.sort,
                ...(decodedView.grouping && {
                    grouping: encodeGroupState(decodedView.grouping),
                }),
            })

            setOpen(false)
        },
        [setSearchParams, setOpen, fieldDefinitions, triggerToast]
    )

    return (
        <>
            <StyledTrigger
                icon="View"
                text="Saved views"
                size="xSmall"
                disabled={data?.views?.length === 0}
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled>
                    {data?.views.map(view => (
                        <MenuButton
                            key={view.id}
                            title={view.name}
                            icon="View"
                            onClick={() => {
                                handleSelectView(view)
                            }}
                        />
                    ))}
                    <footer>
                        {data?.views ? (
                            <ManageViews views={data.views} />
                        ) : null}

                        <IconTextButton
                            icon="Check"
                            text="Done"
                            size="xSmall"
                            variant="primary"
                            onClick={() => setOpen(false)}
                        />
                    </footer>
                </Styled>
            </MinimalModal>

            <SimpleToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'The dataset has been updated since this view was saved. We reccomend you delete it and save a new one.'
                }
            />
        </>
    )
}

const StyledTrigger = styled(IconTextButton)`
    &:disabled {
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const Styled = styled.div`
    padding: 0.5rem 4vw 1rem;

    > footer {
        padding-top: 1rem;
        display: flex;
        gap: 1rem;
    }

    > footer button {
        flex: 1;
    }
`

export { ApplyView }
