import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { PrincipalFragmentDoc } from '../../../../../../Features/Principal/__generated__/Principal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ScheduledEventsQuery = { __typename?: 'Query', scheduledEvents: Array<{ __typename?: 'ScheduledEvent', id: string, createdBy: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } }, payload: { __typename: 'CreateActionPayload', type: Types.ScheduledPayloadType, data?: { __typename?: 'CreateActionPayloadData', name: string, description?: string | null, assignedTo?: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } } | null } | null }, activity: Array<{ __typename?: 'ScheduledEventActivity', executionId: string, timestamp: string, activity: Types.ScheduledEventActivityType }>, schedule: { __typename: 'ScheduledEventScheduleRecurring', frequency: Types.RecurringScheduleFrequency, interval: number, from: string, to?: string | null, nextExecution?: string | null, type: Types.ScheduledEventScheduleType } | { __typename: 'ScheduledEventScheduleSingle', scheduledAt?: string | null, type: Types.ScheduledEventScheduleType } }> };


export const ScheduledEventsDocument = gql`
    query ScheduledEvents {
  scheduledEvents {
    id
    createdBy {
      ...Principal
    }
    payload {
      __typename
      type
      ... on CreateActionPayload {
        data {
          name
          description
          assignedTo {
            ...Principal
          }
        }
      }
    }
    activity {
      executionId
      timestamp
      activity
    }
    schedule {
      __typename
      type
      ... on ScheduledEventScheduleSingle {
        scheduledAt
      }
      ... on ScheduledEventScheduleRecurring {
        frequency
        interval
        from
        to
        nextExecution
      }
    }
  }
}
    ${PrincipalFragmentDoc}`;

/**
 * __useScheduledEventsQuery__
 *
 * To run a query within a React component, call `useScheduledEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduledEventsQuery(baseOptions?: Apollo.QueryHookOptions<ScheduledEventsQuery, ScheduledEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledEventsQuery, ScheduledEventsQueryVariables>(ScheduledEventsDocument, options);
      }
export function useScheduledEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledEventsQuery, ScheduledEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledEventsQuery, ScheduledEventsQueryVariables>(ScheduledEventsDocument, options);
        }
export type ScheduledEventsQueryHookResult = ReturnType<typeof useScheduledEventsQuery>;
export type ScheduledEventsLazyQueryHookResult = ReturnType<typeof useScheduledEventsLazyQuery>;
export type ScheduledEventsQueryResult = Apollo.QueryResult<ScheduledEventsQuery, ScheduledEventsQueryVariables>;