import { styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { DateField, DateFieldPrecision } from 'Utils/BusinessObjectDefinition'
import dayjs from 'dayjs'
import { FC, useMemo, useRef } from 'react'
import { v4 } from 'uuid'
import { DateRangeConstraint } from '../Constraint/DateRange'
import { InputWrapper } from '../InputWrapper'
import { DefaultValue } from './DefaultValue'

type SubFieldsProps = {
    precision: {
        value: DateFieldPrecision | undefined
        onChange: (precision: DateFieldPrecision) => void
    }
    dateRange: {
        constraint: DateField['constraints']['dateRange'] | undefined
        onSetStart: (date: Date) => void
        onSetEnd: (date: Date) => void
        onClear: () => void
    }
    defaultValue: {
        value: DateField['defaultValue'] | undefined
        onClear: () => void
        onSetSpecifc: (date: Date) => void
        onSetRelative: (
            value: number,
            unit: 'hours' | 'days' | 'months' | 'years'
        ) => void
    }
    constraintsLocked: boolean
}
const DateSubFields: FC<SubFieldsProps> = ({
    precision,
    dateRange,
    defaultValue,
    constraintsLocked,
}) => {
    const { current: precisionFieldId } = useRef(v4())

    const range = useMemo(() => {
        const start = dateRange.constraint?.start
            ? dayjs(dateRange.constraint.start).toDate()
            : undefined
        const end = dateRange.constraint?.end
            ? dayjs(dateRange.constraint.end).toDate()
            : undefined
        return { start, end }
    }, [dateRange.constraint])

    return (
        <>
            <InputWrapper
                label="Precision"
                fieldId={precisionFieldId}
                description="This will determine the precision to which the selected date will be recorded."
                displayOnly={constraintsLocked}
            >
                {constraintsLocked ? (
                    <StyledDisplayOnly>
                        <Text as="p" variant="bold-4">
                            {precision.value || 'None'}
                        </Text>
                    </StyledDisplayOnly>
                ) : (
                    <Select
                        id={precisionFieldId}
                        name={precisionFieldId}
                        value={precision.value}
                        onValueChange={precision.onChange}
                        options={Object.entries(DateFieldPrecision)
                            .filter(([, v]) =>
                                [
                                    DateFieldPrecision.Day,
                                    DateFieldPrecision.Month,
                                    DateFieldPrecision.Year,
                                ].includes(v)
                            )
                            .map(([text, value]) => ({ text, value }))}
                    />
                )}
            </InputWrapper>
            <DateRangeConstraint
                start={range.start}
                end={range.end}
                onSetStart={dateRange.onSetStart}
                onSetEnd={dateRange.onSetEnd}
                onClear={dateRange.onClear}
                displayOnly={constraintsLocked}
            />
            <DefaultValue
                defaultValue={defaultValue.value}
                onSetSpecifc={defaultValue.onSetSpecifc}
                onSetRelative={defaultValue.onSetRelative}
                onClear={defaultValue.onClear}
                range={range}
            />
        </>
    )
}

const StyledDisplayOnly = styled.div`
    color: ${({ theme }) => theme.palette.text['support03'].normal};
`

export { DateSubFields }
