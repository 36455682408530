import { flex, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC, useState } from 'react'
import { TextButton } from '../../Components/Button'
import { CopyExisting } from './Components/CopyExisting'
import { Editor } from './Components/Editor'
import { FromScratch } from './Components/FromScratch'
import { RestartButton } from './Components/RestartButton'
import { CreateBusinessObjectDefSuggestion } from './Components/Suggestions/CreateBusinessObjectDefSuggestion'
import { CreateBusinessObjectDefTemplate } from './Components/Templates'
import { useCreateBusinessObjectDefinition } from './useCreateBusinessObjectDefinition'
import { useExistingDefinition } from './useExisting'
import { useSuggestion } from './useSuggestion'
import { useTemplate } from './useTemplate'
import { CreateBusinessObjectDefinitionMutation } from './__generated__/mutation'
import { useBusinessObjectDefinitionSuggestionLazyQuery } from './__generated__/query'

type Props = {
    extensionOf?: 'action'
    onCompleted?: (data: CreateBusinessObjectDefinitionMutation) => void
}

export const CreateBusinessObjectDef: FC<Props> = ({
    extensionOf,
    onCompleted,
}) => {
    const {
        createBusinessObjectDefinition,
        errorToast,
        state,
        setState,
        resetState,
        setStateValue,
        addField,
        removeField,
        updatesEnabled,
        setUpdatesEnabled,
        errors,
        documentsEnabled,
        setDocumentsEnabled,
    } = useCreateBusinessObjectDefinition(extensionOf, onCompleted)

    const [hasStarted, setHasStarted] = useState(!!extensionOf) // skip templating step and go straight to fields for extensions

    const { template, setTemplate, handleTemplateSelected } = useTemplate({
        setState,
        resetState,
        setHasStarted,
    })

    const { handleSuggestionSelected } = useSuggestion({
        setState,
        resetState,
        setHasStarted,
    })

    const [
        getSuggestion,
        { loading: suggestionLoading, error: suggestionError },
    ] = useBusinessObjectDefinitionSuggestionLazyQuery({
        fetchPolicy: 'no-cache',
    })

    const { handleExistingSelected, setExisting, selectedExisting } =
        useExistingDefinition({
            setState,
            resetState,
            setHasStarted,
        })

    return (
        <StyledCreateBusinessObjectDefinitionForm
            $isExtension={!!extensionOf}
            $hasStarted={hasStarted}
            onSubmit={createBusinessObjectDefinition}
        >
            {!extensionOf ? (
                <div className="left">
                    <div className="starters">
                        <CreateBusinessObjectDefTemplate
                            onTemplateSelected={handleTemplateSelected}
                            template={template}
                            disabled={suggestionLoading || hasStarted}
                        />

                        <FromScratch
                            handleStartFromScratch={() => {
                                handleTemplateSelected(undefined)
                                handleSuggestionSelected(undefined)
                            }}
                            disabled={suggestionLoading || hasStarted}
                        />

                        <CopyExisting
                            onExistingSelected={handleExistingSelected}
                            selectedExisting={selectedExisting}
                            disabled={suggestionLoading || hasStarted}
                        />

                        <CreateBusinessObjectDefSuggestion
                            onSuggestionReceived={handleSuggestionSelected}
                            suggestionError={suggestionError}
                            getSuggestion={getSuggestion}
                            disabled={suggestionLoading || hasStarted}
                            suggestionLoading={suggestionLoading}
                        />
                    </div>

                    <div className="button-wrapper">
                        <RestartButton
                            handleRestart={() => {
                                setHasStarted(!!extensionOf)
                                setTemplate(undefined)
                                setExisting(undefined)
                                resetState()
                            }}
                        />
                        <TextButton
                            type="submit"
                            variant="primary"
                            size="small"
                            text="Save"
                            disabled={!state.name || !state.label}
                        />
                    </div>
                </div>
            ) : null}

            <div className="right">
                {hasStarted ? (
                    <Editor
                        extensionOf={extensionOf}
                        state={state}
                        setState={setState}
                        setStateValue={setStateValue}
                        addField={addField}
                        removeField={removeField}
                        updatesEnabled={updatesEnabled}
                        setUpdatesEnabled={setUpdatesEnabled}
                        errors={errors}
                        documentsEnabled={documentsEnabled}
                        setDocumentsEnabled={setDocumentsEnabled}
                        errorToast={errorToast}
                    />
                ) : (
                    <Text as="p" variant="regular-4">
                        <em>
                            Use the controls on the left to choose how you will
                            start.
                        </em>
                    </Text>
                )}
            </div>
        </StyledCreateBusinessObjectDefinitionForm>
    )
}

const StyledCreateBusinessObjectDefinitionForm = styled.form<{
    $isExtension: boolean
    $hasStarted: boolean
}>`
    width: ${({ $isExtension }) =>
        $isExtension ? '100%' : 'calc(100vw - 6rem)'};
    height: ${({ $isExtension }) =>
        $isExtension ? '100%' : 'calc(100vh - 8rem)'};
    display: grid;
    grid-template-columns: ${({ $isExtension }) =>
        $isExtension ? '1fr' : '1fr 3fr'};
    grid-template-rows: 1fr;
    overflow: hidden;

    .left {
        ${flex('column', 'space-between', 'flex-start')};
        border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        height: 100%;
        padding-right: 1.5rem;
        overflow: auto;

        .starters {
            ${flex('column', 'flex-start', 'flex-start')};
            gap: 2rem;
        }

        .button-wrapper {
            ${flex('row', 'center', 'center')};
            gap: 0.75rem;
            width: 100%;
            background-color: ${({ theme }) => theme.palette.ui['01'].normal};
            position: sticky;
            bottom: -1px;
            left: 0;
            padding-top: 1.25rem;

            > button {
                flex: 1;
            }
        }
    }

    .right {
        ${({ $hasStarted }) =>
            flex('column', $hasStarted ? 'flex-start' : 'center', 'center')};
        overflow: auto;
        padding: 1rem 2rem;
    }
`
