import { styled } from 'Adapters/Freestyled'
import { Spinner } from 'Components/Spinner'
import { useBusinessObjectDisplay } from 'Features/BusinessObjectDisplay'
import { ProcessDisplay } from 'Features/ProcessDisplay'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { HeaderLarge } from './HeaderLarge'
import { HeaderSmall } from './HeaderSmall'
import { useProcessSingleQuery } from './__generated__/query'

const Single: FC = () => {
    const { screenSize } = useResponder()
    const { processId = '' } = useParams()
    const { data, loading } = useProcessSingleQuery({
        variables: { processId },
    })
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'process-display-object-display',
        processId: processId,
    })

    return match((!loading || undefined) && data)
        .with(undefined, () => (
            <StyledLoading>
                <Spinner />
            </StyledLoading>
        ))
        .otherwise(({ process }) =>
            match(screenSize)
                .with('large', () => (
                    <StyledLarge>
                        <HeaderLarge
                            {...process}
                            onBusinessObjectAdded={boDisplayProps.open}
                        />
                        <ProcessDisplay
                            process={process}
                            latestViewKey="SingleProcess"
                            boDisplayProps={boDisplayProps}
                        />
                    </StyledLarge>
                ))
                .otherwise(() => (
                    <StyledSmall>
                        <HeaderSmall
                            {...process}
                            onBusinessObjectAdded={boDisplayProps.open}
                        />
                        <ProcessDisplay
                            process={process}
                            latestViewKey="SingleProcess"
                            boDisplayProps={boDisplayProps}
                        />
                    </StyledSmall>
                ))
        )
}

const StyledLarge = styled.div`
    display: grid;
    grid-template: auto auto minmax(0, 1fr) / minmax(0, 1fr);
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    > header {
        grid-row: 1 / 2;
    }

    > menu {
        grid-row: 2 / 3;
    }

    > section {
        overflow: auto;
        grid-row: 3 / 4;
    }
`

const StyledSmall = styled.div`
    display: grid;
    grid-template: auto auto minmax(0, 1fr) / minmax(0, 1fr);
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    > header {
        grid-row: 1 / 2;
    }

    > menu {
        grid-row: 3 / 4;
    }

    > section {
        overflow: auto;
        grid-row: 2 / 4;
    }
`

const StyledLoading = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

export { Single }
