import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { applyComparator, applySelector } from 'Utils/comparator'
import { first, orderBy } from 'lodash'
import { pipe } from 'lodash/fp'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useActionsQuery } from './__generated__/query'
import { defaultShowByParamsFactory } from './constants'
import { getShowBy } from './helpers'
import { showByToPhase } from './types'

export const useFilterActions = (currentUserId: string) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const showBy = getShowBy(searchParams)
    const businessObjectId = searchParams.get('businessObjectId')
    const processId = searchParams.get('processId')
    const actionId = searchParams.get('actionId')
    const assignedTo = searchParams.get('assignedTo')
    const createdBy = searchParams.get('createdBy')
    const dueBefore = searchParams.get('dueBefore')
    const noDueDate = searchParams.get('noDueDate')

    const hasFilter = !!(
        businessObjectId ||
        processId ||
        actionId ||
        assignedTo ||
        createdBy ||
        dueBefore
    )

    /**
     * If we land on this page with zero filters defined, this sets our default view into the URL params
     */
    useEffect(() => {
        const setDefault = showBy.length === 0 && !hasFilter

        if (!setDefault) {
            return
        }

        setSearchParams(defaultShowByParamsFactory(currentUserId))

        // Disabled because this should only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data } = useActionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                ...(showBy.length === 1 && { phase: showByToPhase[showBy[0]] }), // if zero/two phases selected, we show them all so no filter here
                ...((processId || businessObjectId) && {
                    relatesTo: {
                        ...(businessObjectId && { businessObjectId }),
                        ...(processId && { processId }),
                    },
                }),
                ...(assignedTo &&
                    assignedTo !== 'unassigned' && { assignedTo }),
                ...(createdBy && { createdBy }),
                ...(dueBefore && { dueBefore }),
                ...(actionId && { actionId }),
                ...(assignedTo === 'unassigned' && { noAssigneeOnly: true }),
                ...(noDueDate && { noDueDateOnly: true }),
            },
        },
    })

    const extension = useMemo(() => {
        if (!data) {
            return
        }

        return first(data.businessObjectDefinitionExtensions)
    }, [data])

    const serverFilteredActions = useMemo(() => data?.actions ?? [], [data])

    const { filterState } = useBusinessObjectFilterState()

    const filteredByExtension = useMemo(() => {
        if (!extension) return serverFilteredActions

        const comparators = Object.values(filterState).flatMap(
            comparator => comparator
        )

        return comparators.reduce(
            (out, comparator) =>
                out.filter(
                    pipe(
                        action =>
                            action.extendedBy
                                ? applySelector(comparator.valueSelector)(
                                      action.extendedBy
                                  )
                                : undefined,
                        applyComparator(comparator)
                    )
                ),
            serverFilteredActions
        )
    }, [serverFilteredActions, filterState, extension])

    const mostRecentAction = useMemo(() => {
        if (!data?.actions.length) return undefined
        return orderBy(data?.actions, 'createdAt', 'desc')[0].id
    }, [data])

    return {
        actions: filteredByExtension,
        allAssignablePrincipals: [
            ...(data?.users.map(u => ({ id: u.principal.id, name: u.name })) ??
                []),
            ...(data?.userGroups.map(g => ({
                id: g.principal.id,
                name: g.name,
            })) ?? []),
        ],
        hasInitiallyLoaded: data !== undefined,
        extension,
        mostRecentAction,
    }
}
