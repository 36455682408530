import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { TextAreaInput, TextInput } from 'Components/Input'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { useProcessEditor } from '../useProcessEditor'
import { validateDetails } from '../validation'
import { Field } from './Field'
import { RestrictedMode } from './RestrictedMode'

const DetailsStep: FC = () => {
    const {
        state: { process },
        dispatch,
    } = useProcessEditor()
    const { current: nameId } = useRef(v4())
    const { current: descriptionId } = useRef(v4())

    return (
        <Styled>
            {process.usage.businessObjects > 0 ? (
                <RestrictedMode usage={process.usage.businessObjects} />
            ) : null}

            <fieldset>
                <Field
                    label="Name"
                    htmlFor={nameId}
                    input={
                        <TextInput
                            id={nameId}
                            value={process.name}
                            onChange={({ currentTarget: { value: name } }) => {
                                dispatch({ type: 'setName', payload: { name } })
                            }}
                        />
                    }
                />
                <Field
                    label="Description (optional)"
                    htmlFor={descriptionId}
                    input={
                        <TextAreaInput
                            id={descriptionId}
                            value={process.description || ''}
                            onChange={({
                                currentTarget: { value: description },
                            }) => {
                                dispatch({
                                    type: 'setDescription',
                                    payload: { description },
                                })
                            }}
                        />
                    }
                />
            </fieldset>
            <footer>
                <IconTextButton
                    icon="AngleRight"
                    iconRight
                    text="Edit phases"
                    onClick={() => {
                        dispatch({ type: 'showPhasesStep' })
                    }}
                    variant="primary"
                    disabled={!validateDetails(process)}
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    width: 30rem;

    fieldset {
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
    }

    footer {
        padding-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
`

export { DetailsStep }
