import { flex, styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton, TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { useModalControls, useMutationStatus, useResponder } from 'Hooks'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteProcessMutation } from '../../__generated__/mutation'

type Props = {
    usage: number
    processId: string
    onProcessDeleted?: () => void
    canDelete: boolean
}

export const DeleteProcessDefinition: FC<Props> = ({
    onProcessDeleted,
    processId,
    usage,
    canDelete,
}) => {
    const [deleteProcess, mutation] = useDeleteProcessMutation({
        variables: { input: { processId } },
        onCompleted: onProcessDeleted,
        update: cache => {
            cache.evict({
                id: cache.identify({
                    __typename: 'Process',
                    id: processId,
                }),
            })
        },
        onError(e) {
            logger.error('Failed to delete process definition', e, {
                processId: processId,
            })
        },
    })

    const { status } = useMutationStatus(mutation)

    const { buttonRef, open, setOpen, returnFocus, closeModal, openModal } =
        useModalControls()

    const navigate = useNavigate()

    const { isLarge } = useResponder()

    return (
        <>
            <IconTextButton
                icon="Delete"
                text="Delete workflow"
                size="xSmall"
                variant="secondary"
                onClick={openModal}
                ref={buttonRef}
                disabled={!canDelete}
            />

            <ControlledModal
                renderContent={() => (
                    <StyledDeleteProcessDefinition>
                        <Text as={'p'} variant={'regular-4'}>
                            Deleting this workflow is permanant and cannot be
                            undone.
                        </Text>

                        <Text as={'p'} variant={'regular-4'}>
                            You will have to recreate it from scratch if you
                            want to use it again.
                        </Text>

                        {usage > 0 ? (
                            <Text
                                as={'p'}
                                variant={'regular-4'}
                                className="warning"
                            >
                                This workflow is currently in use by {usage}{' '}
                                record(s). Any progress will be lost if you
                                delete it.
                            </Text>
                        ) : null}

                        <div>
                            <TextButton
                                onClick={() => {
                                    closeModal()
                                    mutation.reset()
                                }}
                                text="Cancel"
                                variant="secondary"
                                size="small"
                                disabled={
                                    status === 'loading' || status === 'success'
                                }
                            />

                            <TextButton
                                onClick={async e => {
                                    e.stopPropagation()
                                    await deleteProcess()
                                    navigate(
                                        `/manage-workspace${
                                            isLarge ? '' : '/model'
                                        }/processes`
                                    )
                                }}
                                text={
                                    {
                                        ready: 'Delete workflow',
                                        loading: 'Deleting workflow',
                                        error: 'Error deleting workflow',
                                        success: 'Deleted workflow',
                                    }[status]
                                }
                                variant="primary"
                                size="small"
                                disabled={
                                    status === 'loading' || status === 'success'
                                }
                            />
                        </div>
                    </StyledDeleteProcessDefinition>
                )}
                title={'Delete workflow definition'}
                description={''}
                open={open}
                onOpenChange={open => {
                    setOpen(open)
                    mutation.reset()
                }}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledDeleteProcessDefinition = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 30rem;

    .warning {
        color: ${({ theme }) => theme.palette.support['01'].normal};
    }

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        ${flex('row', 'center', 'center')};
        gap: 1rem;
        width: 100%;

        > button {
            flex: 1;
        }
    }
`
