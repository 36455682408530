import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import {
    DocumentMetadata,
    FileIcon,
    isImageFileType,
} from 'Components/Documents'
import { regular4, regular5 } from 'Components/Text'
import { FC } from 'react'
import { formatBytes } from 'Utils'

type Props = {
    document: DocumentMetadata
    selected: boolean
    toggleSelection: (document: DocumentMetadata) => void
}

export const Thumbnail: FC<Props> = ({
    document,
    selected,
    toggleSelection,
}) => {
    return (
        <StyledThumbnail $selected={selected}>
            <label>
                <VisuallyHidden>
                    <input
                        type="checkbox"
                        name="document"
                        value={document.id}
                        checked={selected}
                        onChange={() => toggleSelection(document)}
                    />
                </VisuallyHidden>

                {isImageFileType(document.fileType) ? (
                    <div className="image">
                        <img src={document.url} alt={document.name} />
                    </div>
                ) : (
                    <div className="icon">
                        <FileIcon name={document.fileType} />
                    </div>
                )}

                <p>{document.name}</p>
                <p>
                    <span>{formatBytes(document.sizeInBytes)}</span>
                    <span>{dayjs(document.addedOn).format('DD MMM YYYY')}</span>
                </p>
            </label>
        </StyledThumbnail>
    )
}

// Retains a 3:2 aspect ratio
const cardWidth = '28.125rem'
const childWidth = '18.75rem'

export const StyledThumbnail = styled.li<{ $selected: boolean }>`
    min-width: ${cardWidth};

    label {
        ${flex('column', 'flex-start', 'flex-start')};
        width: 100%;
        position: relative;
        cursor: pointer;
        padding: 1.5rem;
        background-color: ${({ theme, $selected }) =>
            $selected ? theme.palette.ui['02'].normal : 'inherit'};
        border-radius: ${({ theme }) => theme.borderRadius.small};

        &:has(:focus-visible) {
            box-shadow: inset 0 0 0 0.125rem
                ${({ theme }) => theme.palette.brand['01'].normal};
        }

        > div {
            margin-bottom: 0.5rem;
            width: ${cardWidth};
            height: ${childWidth};
            box-shadow: 0 0.05rem 0.3rem 0
                ${({ theme }) => theme.palette.ui.shadow};
            border-radius: ${({ theme }) => theme.borderRadius.small};
        }

        .icon {
            ${flex('column', 'center', 'center')};
        }

        .image {
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        p:first-of-type {
            ${regular4};
            color: ${({ theme }) => theme.palette.text['02'].normal};
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p:last-of-type {
            ${flex('row', 'space-between', 'center')};
            width: 100%;
            ${regular5};
            color: ${({ theme }) => theme.palette.text['03'].hover};
        }
    }
`
