import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

export const Pip: FC = () => {
    return (
        <StyledPip height={16} width={16} viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="4" />
        </StyledPip>
    )
}

const StyledPip = styled.svg`
    > circle {
        fill: ${({ theme }) => theme.palette.support['02'].normal};
    }
`
