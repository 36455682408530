import { SelectIcon, Trigger, Value } from '@radix-ui/react-select'
import { css, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { applyButtonVariantStyles } from 'Components/Button/TextButton/styles'
import { ButtonProps, Variant } from 'Components/Button/TextButton/types'
import { Icon } from 'Components/Icon'
import { Select } from 'Components/Select'
import { first } from 'lodash'
import { useMemo, useState } from 'react'

type Option<T> = {
    key: T
    selectText: string
    buttonText: string
}

export type SelectButtonProps<T extends string> = {
    options: Option<T>[]
    onClick?: (
        e: React.MouseEvent<HTMLButtonElement>,
        option: T | undefined
    ) => void
    onSelectOptionChanged?: (key: T) => void
    buttonProps?: Omit<ButtonProps, 'text' | 'onClick'>
}

export const SelectButton = <T extends string>({
    options,
    onClick,
    onSelectOptionChanged,
    buttonProps,
}: SelectButtonProps<T>) => {
    const [selectedOptionKey, setSelectedOptionKey] = useState(
        first(options)?.key
    )

    const CustomTrigger = useMemo(
        () => VariantTrigger({ variant: buttonProps?.variant }),
        [buttonProps?.variant]
    )

    const selectedOption = useMemo(
        () => options.find(({ key }) => key === selectedOptionKey),
        [options, selectedOptionKey]
    )

    const handleSelectOptionChanged = (key: string) => {
        setSelectedOptionKey(key as T)
        onSelectOptionChanged?.(key as T)
    }

    return (
        <SelectButtonContainer>
            <StyledTextButton
                text={selectedOption?.buttonText ?? ''}
                onClick={e => onClick?.(e, selectedOptionKey)}
                {...buttonProps}
            />
            <Select
                id="select-button"
                name="select-button"
                onValueChange={handleSelectOptionChanged}
                options={options.map(({ key, selectText }) => ({
                    text: selectText,
                    value: key,
                }))}
                Trigger={CustomTrigger}
                contentPosition="item-aligned"
            />
        </SelectButtonContainer>
    )
}

// The space child to Value is important (for radix-ui reasons...)
const VariantTrigger =
    ({ variant = 'primary' }: { variant?: Variant }) =>
    () =>
        (
            <StyledTrigger $variant={variant}>
                <Value> </Value>
                <SelectIcon>
                    <StyledIcon $variant={variant} name={'AngleDown'} />
                </SelectIcon>
            </StyledTrigger>
        )

const StyledTextButton = styled(TextButton)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`

const SelectButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    > button:first-child {
        flex-grow: 1;
    }

    > button {
        width: initial;
    }
`
const StyledTrigger = styled(Trigger)<{ $variant: Variant }>`
    ${({ $variant }) => applyButtonVariantStyles($variant)}
    border-top-right-radius: ${({ theme }) => theme.borderRadius.small};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.small};
`

const StyledIcon = styled(Icon)<{ $variant: Variant }>`
    ${({ $variant }) => applyIconVariantStyles($variant)}
`

export const applyIconVariantStyles = (variant: Variant) => {
    switch (variant) {
        case 'primary': {
            return css`
                path {
                    fill: ${({ theme }) => theme.palette.text['04'].normal};
                }
            `
        }
        case 'secondary': {
            return css`
                path {
                    fill: ${({ theme }) => theme.palette.text['02'].normal};
                }
            `
        }
        case 'ghost': {
            return css`
                path {
                    fill: ${({ theme }) => theme.palette.text.primary.normal};
                }
            `
        }
        default: {
            throw Error('Button variant not supported')
        }
    }
}
