import { flex, styled } from 'Adapters/Freestyled'
import screenPoint from 'Assets/images/john-schnobrich-FlPc9_VocJ4-unsplash.jpg'
import { IconTextButton } from 'Components/Button'
import { IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { FC, ReactNode } from 'react'
import { match } from 'ts-pattern'

type ButtonSetup = {
    handler: () => void
    text: string
    icon?: IconName
}

type Props = {
    title: string
    paragraphs: ReactNode[]
    button?: ButtonSetup | ButtonSetup[]
    imageUrl?: string
}

export const EmptyStateCard: FC<Props> = ({
    title,
    paragraphs,
    button,
    imageUrl = screenPoint,
}) => {
    const { screenSize } = useResponder()
    return match(screenSize)
        .with('large', () => (
            <StyledLarge className="empty-state-card" $imageUrl={imageUrl}>
                <div>
                    <Text as="h2" variant="bold-0">
                        {title}
                    </Text>

                    {paragraphs.map((paragraph, index) => (
                        <Text as="p" variant="regular-2-spaced" key={index}>
                            {paragraph}
                        </Text>
                    ))}

                    {button ? (
                        <footer>
                            {Array.isArray(button) ? (
                                button.map((button, i) => (
                                    <IconTextButton
                                        key={i}
                                        icon={button.icon ?? 'Plus'}
                                        text={button.text}
                                        size="small"
                                        variant="secondary"
                                        onClick={button.handler}
                                    />
                                ))
                            ) : (
                                <IconTextButton
                                    icon={button.icon ?? 'Plus'}
                                    text={button.text}
                                    size="small"
                                    variant="secondary"
                                    onClick={button.handler}
                                />
                            )}
                        </footer>
                    ) : null}
                </div>
            </StyledLarge>
        ))
        .otherwise(() => (
            <StyledSmall className="empty-state-card" $imageUrl={imageUrl}>
                <div>
                    <Text as="h2" variant="bold-1">
                        {title}
                    </Text>

                    {paragraphs.map((paragraph, index) => (
                        <Text as="p" variant="regular-4" key={index}>
                            {paragraph}
                        </Text>
                    ))}

                    {button ? (
                        <footer>
                            {Array.isArray(button) ? (
                                button.map((button, i) => (
                                    <IconTextButton
                                        key={i}
                                        icon={button.icon ?? 'Plus'}
                                        text={button.text}
                                        size="small"
                                        variant="primary"
                                        onClick={button.handler}
                                    />
                                ))
                            ) : (
                                <IconTextButton
                                    icon={button.icon ?? 'Plus'}
                                    text={button.text}
                                    size="small"
                                    variant="primary"
                                    onClick={button.handler}
                                />
                            )}
                        </footer>
                    ) : null}
                </div>
            </StyledSmall>
        ))
}

const StyledLarge = styled.section<{ $imageUrl: string }>`
    padding: 1.5rem 4vw;
    background-size: cover;
    background-position: center;
    color: ${({ theme }) => theme.palette.text['04'].normal};
    background-color: ${({ theme }) => theme.palette.ui['07'].normal};
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 1rem;
    padding: 4rem;
    background-image: linear-gradient(
            95deg,
            ${({ theme }) => theme.palette.ui['07'].normal} 10%,
            ${({ theme }) => theme.palette.ui['07'].normal}DD 40%,
            ${({ theme }) => theme.palette.ui['07'].normal}00
        ),
        url(${({ $imageUrl }) => $imageUrl});

    > div {
        max-width: 70ch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1.5rem;
    }

    > div > footer {
        ${flex('row', 'flex-start', 'center')};
        gap: 1.5rem;
        padding-top: 0.75rem;
    }

    p a {
        text-decoration: underline;
    }
`

const StyledSmall = styled.section<{ $imageUrl: string }>`
    padding: 1.5rem 4vw;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(
            95deg,
            ${({ theme }) => theme.palette.ui['07'].normal} 10%,
            ${({ theme }) => theme.palette.ui['07'].normal}DD 40%,
            ${({ theme }) => theme.palette.ui['07'].normal}00
        ),
        url(${({ $imageUrl }) => $imageUrl});
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    > div {
        padding: 1.5rem 4vw;
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        border-radius: 0.25rem;
        filter: drop-shadow(0px 8px 8px rgba(16, 37, 97, 0.04))
            drop-shadow(0px 16px 16px rgba(16, 37, 97, 0.08));
        overflow: auto;
    }

    h2,
    p {
        padding-bottom: 1rem;
    }

    > div > footer {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.5rem;
        padding-top: 0.75rem;
    }

    p a {
        text-decoration: underline;
    }
`
