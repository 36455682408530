import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { ActionCard } from 'Features/ActionView/ActionCard'
import { useDropOnGroup } from 'Features/ActionView/dnd/useDropOnGroup'
import { FC } from 'react'
import { useGroupActions } from '../../Hooks/useGroupActions'

type Props = {
    fieldId: string
    entityId: string
    group: ReturnType<typeof useGroupActions> extends Record<string, infer T>
        ? T
        : never
}

export const Group: FC<Props> = ({ entityId, group, fieldId }) => {
    const { isDraggedOver, ref } = useDropOnGroup(
        entityId,
        fieldId === 'assignedTo' ? 'assignedTo' : group.fieldType
    )

    return (
        <StyledGroup
            $color={group.color}
            $isDraggedOver={isDraggedOver}
            ref={ref}
        >
            <Text as={'h3'} variant={'bold-5'}>
                {group.value}
            </Text>

            <div className="cards">
                {group.items.map(action => (
                    <ActionCard id={action.id} key={action.id} draggable />
                ))}
            </div>
        </StyledGroup>
    )
}

const StyledGroup = styled.div<{ $color: string; $isDraggedOver: boolean }>`
    width: 100%;
    min-height: 5rem;
    padding: 1rem;
    background-color: ${({ $color, $isDraggedOver }) =>
        `${$color}${$isDraggedOver ? '33' : '11'}`};
    border-radius: 0.25rem;

    > h3 {
        padding-bottom: 0.75rem;
        color: ${({ $color }) => $color};
        filter: brightness(50%);
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-auto-rows: auto;
        gap: 1rem;
    }
`
