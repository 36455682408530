import { InMemoryCache } from '@apollo/client'

export const cache = new InMemoryCache({
    possibleTypes: {
        ActionRelatesTo: [
            'PhaseCriterionActionRelatesTo',
            'BusinessObjectActionRelatesTo',
        ],
        Principal: ['UserPrincipal', 'WebhookPrincipal', 'UserGroupPrincipal'],
    },
    typePolicies: {
        Action: {
            fields: {
                relatesTo: {
                    merge: true,
                },
            },
        },
        Query: {
            fields: {
                businessObjects: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
                actions: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
                views: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
                processes: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
            },
        },
        BusinessObject: {
            fields: {
                onProcess: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
            },
        },
        BusinessObjectOnProcess: {
            fields: {
                onPhase: {
                    merge(_, incoming) {
                        return incoming
                    },
                },
            },
        },
    },
})

export const resetCache = () => cache.reset()
