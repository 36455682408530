import { flex, styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Spinner } from './Spinner'

type Props = {
    className?: string
}

const CenteredSpinner: FC<Props> = ({ className = '' }) => (
    <StyledSpinnerContainer className={className}>
        <Spinner />
    </StyledSpinnerContainer>
)

const StyledSpinnerContainer = styled.div`
    ${flex('column', 'center', 'center')};
`

export { CenteredSpinner }
