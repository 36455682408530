import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessObjectViewsQueryVariables = Types.Exact<{
  input: Types.ViewsInput;
}>;


export type GetBusinessObjectViewsQuery = { __typename?: 'Query', views: Array<{ __typename?: 'View', id: string, name: string, configuration: string, viewOf: { __typename?: 'ViewOfActions' } | { __typename?: 'ViewOfBusinessObjects', businessObjectDefinition: { __typename?: 'BusinessObjectDefinition', id: string, name: string } } | { __typename?: 'ViewOfProcess' } }> };


export const GetBusinessObjectViewsDocument = gql`
    query GetBusinessObjectViews($input: ViewsInput!) {
  views(input: $input) {
    id
    name
    viewOf {
      ... on ViewOfBusinessObjects {
        businessObjectDefinition {
          id
          name
        }
      }
    }
    configuration
  }
}
    `;

/**
 * __useGetBusinessObjectViewsQuery__
 *
 * To run a query within a React component, call `useGetBusinessObjectViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessObjectViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessObjectViewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBusinessObjectViewsQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessObjectViewsQuery, GetBusinessObjectViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessObjectViewsQuery, GetBusinessObjectViewsQueryVariables>(GetBusinessObjectViewsDocument, options);
      }
export function useGetBusinessObjectViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessObjectViewsQuery, GetBusinessObjectViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessObjectViewsQuery, GetBusinessObjectViewsQueryVariables>(GetBusinessObjectViewsDocument, options);
        }
export type GetBusinessObjectViewsQueryHookResult = ReturnType<typeof useGetBusinessObjectViewsQuery>;
export type GetBusinessObjectViewsLazyQueryHookResult = ReturnType<typeof useGetBusinessObjectViewsLazyQuery>;
export type GetBusinessObjectViewsQueryResult = Apollo.QueryResult<GetBusinessObjectViewsQuery, GetBusinessObjectViewsQueryVariables>;