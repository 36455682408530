import { Root } from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { StatusButton, TextButton } from 'Components/Button'
import { Toggle } from 'Components/Input'
import { LoadingDisplay } from 'Components/LoadingDisplay'
import { MaybeEdit } from 'Components/MaybeEdit'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { documentToggleFieldListOfName } from 'Utils'
import { CreateBusinessObjectDefinitionField } from '__generated__'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { AddFieldButtons } from '../CreateBusinessObjectDef/Components/AddFieldButtons'
import { FieldType } from '../CreateBusinessObjectDef/types'
import { CreateFieldModal } from './Components/CreateFieldModal'
import { PatchDisplay } from './Components/PatchDisplay'
import { RestrictedMode } from './Components/RestrictedMode'
import { BoolField } from './Fields/BoolField'
import { CurrencyField } from './Fields/CurrencyField'
import { DateField } from './Fields/DateField'
import { DocumentField } from './Fields/DocumentField'
import { EmailField } from './Fields/EmailField'
import { ListField } from './Fields/ListField'
import { NumberField } from './Fields/NumberField'
import { RelationField } from './Fields/RelationField'
import { SelectField } from './Fields/SelectField'
import { TelephoneField } from './Fields/TelephoneField'
import { TextField } from './Fields/TextField'
import { UrlField } from './Fields/UrlField'
import { UserField } from './Fields/UserField'
import { newFieldId } from './Hooks/patch-def'
import { useOperationsState } from './Hooks/useOperationsState'
import { usePatchBusinessObjectDefinition } from './Hooks/usePatchBusinessObjectDefinition'
import { useDeleteBusinessObjectDefinitionMutation } from './__generated__/delete'
import { isOperation } from './types'

type Props = {
    id: string
    extensionOf?: 'action'
    displayOnly?: boolean
    onDefinitionDeleted?: () => void
}

// This is a semi-retired component that is still in use
// on the Actions definition screen, but is otherwise
// superceded by the BusinessObjectDefinitionDisplay component.
// You should probably use that instead of this.

export const BusinessObjectDef: FC<Props> = ({
    id,
    extensionOf,
    onDefinitionDeleted,
    displayOnly = true,
}) => {
    const navigate = useNavigate()

    const { open, setOpen, triggerToast } = useTriggerToast()

    const [deleteDefinition, mutationData] =
        useDeleteBusinessObjectDefinitionMutation({
            variables: { input: { id } },
            onCompleted: onDefinitionDeleted,
            update: cache => {
                cache.evict({
                    id: cache.identify({
                        __typename: 'BusinessObjectDefinition',
                        id,
                    }),
                })
            },
            onError: e => {
                logger.error('Failed to delete business object definition', e)
                triggerToast()
            },
        })

    const { status } = useMutationStatus(mutationData)

    // Go back to read mode when ID changed
    useEffect(() => setEditing(false), [id])

    const [isEditing, setEditing] = useState(false)
    const [addingFieldType, setAddingFieldType] = useState<FieldType | null>(
        null
    )
    const createFieldModalReturnRef = useRef<HTMLElement | null>(null)

    const [errors, setErrors] = useState<string[]>([])

    const {
        operations,
        setOperations,
        businessObjectDefinition,
        unpatchedDefinition,
        loading,
        handleConstraintRemoved,
        handleFieldChanged,
        handleFieldCreated,
        handleTopLevelPropertyChanged,
        handleSelectOptionAdded,
        handleUndoOperation,
        updatesEnabledInitially,
        updatesEnabledNow,
        handleUpdatesEnabledChanged,
        documentsEnabledInitially,
        documentsEnabledNow,
        handleDocumentsEnabledChanged,
        handleDefaultChanged,
        handleFieldRemoved,
    } = useOperationsState(id)

    const handleReset = useCallback(() => {
        setOperations([])
        setErrors([])
        setEditing(false)
    }, [setOperations, setErrors, setEditing])

    useEffect(() => {
        handleReset()
    }, [id, handleReset])

    const onFieldCreated = (field: CreateBusinessObjectDefinitionField) => {
        handleFieldCreated(field)
        setAddingFieldType(null)
    }

    const [patchBusinessObjectDefinition, { loading: isMutating }] =
        usePatchBusinessObjectDefinition({
            setErrors,
            onCompleted: () => {
                setEditing(false)
                setOperations([])

                /**
                 * Because URL is based on the name, if it has changed, we need to navigate to the new URL
                 */
                const updateNameOperation = operations.find(
                    isOperation('updateName')
                )
                if (updateNameOperation) {
                    navigate(
                        `/manage-workspace/datasets/${slugify(
                            updateNameOperation.nextValue,
                            { lower: true }
                        )}`
                    )
                }
            },
        })

    const handleSave = () => patchBusinessObjectDefinition({ id, operations })

    const isRestrictedMode = useMemo(() => {
        if (!businessObjectDefinition || extensionOf) return true

        const { processes, instanceCount, relations } =
            businessObjectDefinition.usage

        return [processes.length, instanceCount, relations.length].some(
            count => count > 0
        )
    }, [businessObjectDefinition, extensionOf])

    const isNotDocumentsToggleField = (
        field: Exclude<
            typeof businessObjectDefinition,
            null | undefined
        >['fields'][number]
    ) => {
        return !(
            field.__typename === 'ListFieldDefinition' &&
            field.listOf.__typename === 'DocumentFieldDefinition' &&
            field.listOf.name === documentToggleFieldListOfName
        )
    }

    if (loading) {
        return <LoadingDisplay />
    }

    if (!businessObjectDefinition) {
        return null
    }

    return (
        <Styled>
            {addingFieldType ? (
                <CreateFieldModal
                    onFieldCreated={onFieldCreated}
                    onModalClosed={() => setAddingFieldType(null)}
                    type={addingFieldType}
                    returnFocus={() =>
                        createFieldModalReturnRef.current?.focus()
                    }
                />
            ) : null}
            <header>
                {extensionOf ? null : (
                    <>
                        {isRestrictedMode ? (
                            <RestrictedMode
                                usage={businessObjectDefinition.usage}
                            />
                        ) : null}
                        <MaybeEdit
                            isEditing={isEditing}
                            label={<strong>Name:</strong>}
                            onChange={handleTopLevelPropertyChanged(
                                'updateName',
                                'name'
                            )}
                            as="h2"
                            variant="bold-2"
                        >
                            {businessObjectDefinition.name}
                        </MaybeEdit>
                        <MaybeEdit
                            isEditing={isEditing}
                            label={<strong>Description:</strong>}
                            onChange={handleTopLevelPropertyChanged(
                                'updateDescription',
                                'description'
                            )}
                            as="h2"
                            variant={
                                businessObjectDefinition.description
                                    ? 'bold-2'
                                    : 'regular-2'
                            }
                            className={
                                businessObjectDefinition.description
                                    ? undefined
                                    : 'empty-description'
                            }
                            placeholder="No description set"
                        >
                            {businessObjectDefinition.description}
                        </MaybeEdit>
                        <MaybeEdit
                            isEditing={isEditing}
                            label={<strong>Label:</strong>}
                            onChange={handleTopLevelPropertyChanged(
                                'updateLabel',
                                'label'
                            )}
                            as="h2"
                            variant="bold-2"
                        >
                            {businessObjectDefinition.label}
                        </MaybeEdit>
                    </>
                )}
                {isEditing ? (
                    <>
                        <div>
                            <label htmlFor="updates-enabled">
                                <Text as="h4" variant="bold-4">
                                    Updates:
                                </Text>
                            </label>
                            <Toggle
                                name="updates-enabled"
                                indicatorText={[
                                    'Enabled' +
                                        (updatesEnabledInitially
                                            ? ' (locked)'
                                            : ''),
                                    'Not enabled',
                                ]}
                                checked={updatesEnabledNow}
                                onCheckedChange={handleUpdatesEnabledChanged}
                            />
                        </div>
                        <div>
                            <label htmlFor="documents-enabled">
                                <Text as="h4" variant="bold-4">
                                    Documents:
                                </Text>
                            </label>
                            <Toggle
                                name="documents-enabled"
                                indicatorText={[
                                    'Enabled' +
                                        (documentsEnabledInitially
                                            ? ' (locked)'
                                            : ''),
                                    'Not enabled',
                                ]}
                                checked={documentsEnabledNow}
                                onCheckedChange={handleDocumentsEnabledChanged}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <strong>Updates: </strong>
                            {updatesEnabledInitially ? 'Enabled' : 'Disabled'}
                        </div>
                        <div>
                            <strong>Documents: </strong>
                            {documentsEnabledInitially ? 'Enabled' : 'Disabled'}
                        </div>
                    </>
                )}
            </header>
            <Root type="single" collapsible>
                {businessObjectDefinition.fields
                    .filter(isNotDocumentsToggleField)
                    .map(field =>
                        match(field)
                            .with(
                                { __typename: 'TextFieldDefinition' },
                                props => (
                                    <TextField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        onConstraintRemoved={handleConstraintRemoved(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'text'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'BooleanFieldDefinition' },
                                props => (
                                    <BoolField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'boolean'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'DateFieldDefinition' },
                                props => (
                                    <DateField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'date'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'DocumentFieldDefinition' },
                                props => (
                                    <DocumentField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'EmailFieldDefinition' },
                                props => (
                                    <EmailField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'email'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'SelectFieldDefinition' },
                                props => (
                                    <SelectField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        onSelectOptionAdded={handleSelectOptionAdded(
                                            field.id
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'ListFieldDefinition' },
                                props => (
                                    <ListField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'NumberFieldDefinition' },
                                props => (
                                    <NumberField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'number'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'RelationFieldDefinition' },
                                props => (
                                    <RelationField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'TelephoneFieldDefinition' },
                                props => (
                                    <TelephoneField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'telephone'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'UserFieldDefinition' },
                                props => (
                                    <UserField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'user'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'URLFieldDefinition' },
                                props => (
                                    <UrlField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'url'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .with(
                                { __typename: 'UpdatesFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'CurrencyFieldDefinition' },
                                props => (
                                    <CurrencyField
                                        key={field.id}
                                        isEditing={
                                            isEditing &&
                                            !props.id.startsWith(newFieldId)
                                        }
                                        onPropertyChanged={handleFieldChanged(
                                            props.id
                                        )}
                                        handleDefaultChanged={handleDefaultChanged(
                                            'currency'
                                        )}
                                        isRestrictedMode={isRestrictedMode}
                                        onFieldRemoved={handleFieldRemoved(
                                            props.id
                                        )}
                                        {...props}
                                    />
                                )
                            )
                            .exhaustive()
                    )}
            </Root>

            {match(displayOnly)
                .with(true, () => null)
                .otherwise(() =>
                    isEditing ? (
                        <>
                            <AddFieldButtons
                                onClick={type => () => setAddingFieldType(type)}
                            />
                            <PatchDisplay
                                operations={operations}
                                fields={unpatchedDefinition?.fields ?? []}
                                onUndoOperation={handleUndoOperation}
                            />

                            {errors.length > 0 ? (
                                <StyledErrorMessage>
                                    {errors.map((errorMessage, i) => (
                                        <span key={i}>
                                            {errorMessage}
                                            {errors.length > 1 &&
                                            i < errors.length - 1
                                                ? ', '
                                                : ''}
                                        </span>
                                    ))}
                                </StyledErrorMessage>
                            ) : null}

                            <ButtonRow>
                                <TextButton
                                    disabled={isMutating}
                                    onClick={handleReset}
                                    type="button"
                                    text="Stop Editing (lose your changes)"
                                    variant="secondary"
                                    size="small"
                                />
                                <TextButton
                                    disabled={isMutating}
                                    onClick={handleSave}
                                    type="button"
                                    text="Save changes"
                                    variant="primary"
                                    size="small"
                                />
                            </ButtonRow>
                        </>
                    ) : (
                        <ButtonRow>
                            <TextButton
                                text="Edit this definition"
                                type="button"
                                onClick={() => setEditing(true)}
                                size="small"
                            />
                            <StatusButton
                                status={status}
                                text={{
                                    ready: 'Delete this definition',
                                    loading: 'Deleting',
                                    error: 'Could not delete definition',
                                    success: 'Deleted definition',
                                }}
                                type="button"
                                disabled={isRestrictedMode}
                                onClick={() => deleteDefinition()}
                                size="small"
                            />
                            <ContactUsToast
                                open={open}
                                onOpenChange={setOpen}
                                description={
                                    'We were unable to delete your dataset. Contact us if the problem persists'
                                }
                            />
                        </ButtonRow>
                    )
                )}
        </Styled>
    )
}

const Styled = styled.article`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 65rem;
    width: 100%;
    margin: 0 auto;

    > header h2 {
        margin-bottom: 1.5rem;
    }

    > header > div {
        margin-bottom: 0.46rem;
    }

    > header p {
        padding-bottom: 1rem;
    }

    > header p strong {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .empty-description {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 0.2rem;

    > button {
        flex-grow: 1;
        width: 100%;
    }
`

const StyledErrorMessage = styled.p`
    color: ${({ theme }) => theme.palette.support['01'].normal};
`
