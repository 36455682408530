import { FC, useState } from 'react'
import { flex, styled } from '../../../../../Adapters/Freestyled'
import { Avatar } from '../../../../../Components/Avatar'
import { IconTextButton, TextButton } from '../../../../../Components/Button'
import { Icon } from '../../../../../Components/Icon'
import { ControlledModal } from '../../../../../Components/Modal'
import { Text } from '../../../../../Components/Text'
import { useModalControls, useOrganisationUsers } from '../../../../../Hooks'
import { useUpdateUserGroupMembersMutation } from './__generated__/q'

type Props = {
    userGroupId: string
    currentMembers: string[]
}

export const UserGroupAddMember: FC<Props> = props => {
    const modal = useModalControls()

    return (
        <>
            <TextButton
                variant="secondary"
                text="Select Group Members"
                onClick={modal.openModal}
            />

            <ControlledModal
                {...modal}
                onOpenChange={modal.setOpen}
                title="Select Group Members"
                description=""
                hideDescription
                renderContent={() => (
                    <Content {...props} onCompleted={modal.closeModal} />
                )}
            />
        </>
    )
}

const Content: FC<Props & { onCompleted: () => void }> = ({
    currentMembers,
    userGroupId,
    onCompleted,
}) => {
    const { users } = useOrganisationUsers()

    const [members, setMembers] = useState(new Set(currentMembers))

    const [mutate, { loading }] = useUpdateUserGroupMembersMutation({
        variables: {
            input: {
                userGroupId,
                principals: Array.from(members),
            },
        },
        onCompleted,
    })

    return (
        <Styled>
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        <button
                            onClick={() =>
                                setMembers(curr => {
                                    const next = new Set([...curr])
                                    if (curr.has(user.principalId)) {
                                        next.delete(user.principalId)
                                        return next
                                    }
                                    next.add(user.principalId)
                                    return next
                                })
                            }
                        >
                            <Avatar name={user.name} />
                            <Text as="p" variant="regular-6">
                                {user.name}
                            </Text>
                            <Icon
                                name={
                                    members.has(user.principalId)
                                        ? 'CheckboxTicked'
                                        : 'CheckboxUnticked'
                                }
                            />
                        </button>
                    </li>
                ))}
            </ul>
            <div>
                <IconTextButton
                    disabled={loading}
                    variant="primary"
                    text="Save"
                    icon="Save"
                    onClick={() => mutate()}
                />
            </div>
        </Styled>
    )
}

const Styled = styled.div`
    ${flex('column', 'center', 'center')};
    width: 100vw;
    max-width: 40rem;

    > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    > ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 0.5rem;

        > li {
            padding: 0.4rem;
            border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

            > button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.6rem;

                width: 8rem;
                height: 8rem;

                overflow: hidden;

                p {
                    max-width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
`
