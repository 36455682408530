import { CreateBusinessObjectModal } from 'Features/CreateBusinessObjectModal'
import { CreatedBusinessObject } from 'Features/CreateBusinessObjectModal/Hooks/useCreateBusinessObject'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { useBusinessDomain_BusinessObjectDefinitionQuery } from '../BusinessObjectDef/__generated__/query'
import { PrefilledBusinessObject } from '../CreateBusinessObjectModal/types'

export type Props = ReturnType<typeof useModalControls> & {
    businessObjectDefinitionId: string
    onBusinessObjectCreated?: (businessObject: CreatedBusinessObject) => void
    prefilledBusinessObject?: PrefilledBusinessObject
}

export const CreateRelationModal: FC<Props> = ({
    businessObjectDefinitionId,
    onBusinessObjectCreated,
    prefilledBusinessObject,
    setOpen,
    ...modalControls
}) => {
    const { data, loading } = useBusinessDomain_BusinessObjectDefinitionQuery({
        variables: { input: { id: businessObjectDefinitionId } },
    })

    if (loading || !data) return null

    const { businessObjectDefinition } = data

    if (!businessObjectDefinition) return null

    return (
        <CreateBusinessObjectModal
            businessObjectDefinitionId={businessObjectDefinitionId}
            fields={businessObjectDefinition.fields}
            businessObjectDefinitionName={businessObjectDefinition.name}
            onBusinessObjectCreated={onBusinessObjectCreated}
            {...modalControls}
            onOpenChange={setOpen}
            prefilledBusinessObject={prefilledBusinessObject}
        />
    )
}
