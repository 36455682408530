import * as amplitude from '@amplitude/analytics-browser'
import { useFlow } from '@frigade/react'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { MinimalModal } from 'Components/Modal/Minimal'
import { regular6 } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { ChecklistContent } from './ChecklistContent'
import { CHECKLIST_INTERACTED_WITH, FLOW_IDENTIFIER } from './constants'
import { useMarkProgress } from './useMarkProgress'

export const OnboardingChecklistMobile = () => {
    const { flow } = useFlow(FLOW_IDENTIFIER)

    const stepsCompleted = flow?.getNumberOfCompletedSteps() ?? 0
    const totalSteps = flow?.getNumberOfAvailableSteps() ?? 0
    const percentComplete = Math.round((stepsCompleted / totalSteps) * 100)

    useMarkProgress()

    const { open, openModal, setOpen, closeModal } = useModalControls()

    if (!flow || percentComplete === 100) return null

    return (
        <>
            <StyledButton
                onClick={() => {
                    amplitude.track(CHECKLIST_INTERACTED_WITH)
                    openModal()
                }}
            >
                <Icon name="Rocket" />
                <span className="percent">{percentComplete}%</span>
            </StyledButton>

            <MinimalModal
                children={
                    <ChecklistContent
                        done={stepsCompleted}
                        size={totalSteps}
                        closeModal={closeModal}
                    />
                }
                open={open}
                onOpenChange={setOpen}
                fullScreen
            />
        </>
    )
}

const StyledButton = styled.button`
    ${flex('row', 'center', 'center')};
    flex: 1;
    height: 3rem;
    padding: 0;
    position: relative;

    .percent {
        ${regular6};
        color: ${({ theme }) => theme.palette.text['02'].normal};
        position: absolute;
        right: -0.2rem;
        bottom: 0.5rem;
    }

    svg {
        height: 1rem;
        width: 1rem;

        path,
        circle {
            fill: ${({ theme }) => theme.palette.ui['05'].normal};
        }
    }
`
