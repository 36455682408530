import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { fieldLabels } from 'Utils'
import { FieldType } from 'Utils/BusinessObjectDefinition'
import { FC, useRef } from 'react'
import { DispatchAction, State } from './types'

type Props = Pick<State, 'addFieldOpen'> & {
    dispatch: DispatchAction
    onAdd: () => void
}
const AddField: FC<Props> = ({ addFieldOpen, dispatch, onAdd }) => {
    const addFieldRef = useRef<HTMLButtonElement>(null)
    return (
        <Styled>
            <IconTextButton
                icon="Plus"
                text="Add field"
                variant="primary"
                ref={addFieldRef}
                onClick={() => {
                    dispatch({
                        type: 'openAddField',
                        payload: undefined,
                    })
                }}
            />
            <ControlledModal
                title="Add field"
                description="What type of field would you like to add?"
                open={addFieldOpen}
                onOpenChange={open => {
                    if (!open)
                        dispatch({ type: 'closeAddField', payload: undefined })
                }}
                renderContent={() => (
                    <StyledAddFieldContent>
                        <ul>
                            {Object.values(FieldType).map(fieldType => (
                                <li key={fieldType}>
                                    <button
                                        onClick={() => {
                                            dispatch({
                                                type: 'addField',
                                                payload: {
                                                    fieldType,
                                                },
                                            })
                                            onAdd()
                                        }}
                                    >
                                        <Text as="span" variant="bold-4">
                                            {fieldLabels[fieldType]}
                                        </Text>
                                        <Icon name="AngleRight" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </StyledAddFieldContent>
                )}
                returnFocus={() => addFieldRef.current?.focus()}
            />
        </Styled>
    )
}

const Styled = styled.div`
    position: sticky;
    bottom: 0;
    padding: 0.5rem;
    display: flex;
    justify-content: center;

    > button {
        opacity: 0.98;
        filter: drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['07'].normal}20
        );
    }
`

const StyledAddFieldContent = styled.div`
    overflow: auto;
    max-height: 70vh;
    margin-top: 1rem;

    > ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    li button {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: 0.25rem;
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

        @media (hover: hover) {
            &:hover {
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].hover};
            }
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.ui['02'].active};
        }
    }
`

export { AddField }
