import { Separator } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { FC } from 'react'

export const DropdownDivider: FC = () => {
    const { variant } = useColorVariant()

    return <StyledSeparator $variant={variant} />
}

export const StyledSeparator = styled(Separator)<{ $variant: ColorVariant }>`
    display: block;
    padding: 0.25rem 0;
    pointer-events: none;

    &:after {
        content: '';
        display: block;
        height: 0.125rem;
        background-color: ${({ theme, $variant }) =>
            $variant === 'light'
                ? theme.palette.ui['02'].normal
                : theme.palette.ui['06'].normal};
    }
`
