import { resetCache } from 'Adapters/Apollo'
import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { StatusButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { useCreateTenantMutation } from './__generated__/CreateTenant'

type Props = {
    onSuccess?: () => void | Promise<void>
}

const CreateTenantForm: FC<Props> = ({ onSuccess }) => {
    const { triggerToast, open, setOpen } = useTriggerToast()
    const [create, res] = useCreateTenantMutation()
    const { status } = useMutationStatus(res)
    const [companyName, setCompanyName] = useState('')
    const inputId = useRef(v4())

    return (
        <Styled
            onSubmit={e => {
                e.preventDefault()
                create({
                    variables: { input: { companyName } },
                    onCompleted: resetCache,
                    onError(e) {
                        logger.error('Failed to create new tenant', e)
                        triggerToast()
                    },
                    onQueryUpdated: query => query.refetch(),
                }).then(onSuccess)
            }}
        >
            <div>
                <Text as="label" variant="bold-4" htmlFor={inputId.current}>
                    Workspace name
                </Text>
                <TextInput
                    id={inputId.current}
                    hasError={status === 'error'}
                    placeholder="i.e. your company name"
                    onChange={({ currentTarget: { value } }) =>
                        setCompanyName(value)
                    }
                />
            </div>
            <StatusButton
                type="submit"
                status={status}
                text={{
                    error: 'Create workspace',
                    loading: 'Creating workspace',
                    ready: 'Create workspace',
                    success: 'Workspace created',
                }}
                disabled={
                    !companyName || ['loading', 'success'].includes(status)
                }
            />
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description={'Could not create workspace'}
            />
        </Styled>
    )
}

const Styled = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 1rem;

    label {
        display: block;
        padding-bottom: 0.5rem;
    }
`

export { CreateTenantForm }
