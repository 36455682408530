import { flex, styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { noop } from 'lodash'
import { FC, useMemo } from 'react'
import { NoTypename, stripTypenameFields } from 'Utils/stripTypenames'
import { BusinessObjectDefinitionTemplate } from '__generated__'
import { useTemplateBusinessObjectDefinitionsQuery } from './__generated__/Templates'

type Props = {
    onTemplateSelected: (template: Template | undefined) => void
    template: Template | undefined
    disabled: boolean
}

export type Template = NoTypename<BusinessObjectDefinitionTemplate>

export const CreateBusinessObjectDefTemplate: FC<Props> = ({
    onTemplateSelected,
    template,
    disabled,
}) => {
    const { data, loading } = useTemplateBusinessObjectDefinitionsQuery()

    const templates = useMemo(
        () => data?.businessObjectDefinitionTemplates ?? [],
        [data]
    )

    const options = useMemo(
        () => [
            { text: 'Select a template', value: '' },
            ...templates.map(template => ({
                text: template.name,
                value: template.name,
            })),
        ],
        [templates]
    )

    return (
        <StyledTemplate $isLoading={loading} $disabled={disabled}>
            {loading ? (
                <CenteredSpinner />
            ) : (
                <>
                    <Text as="h2" variant="bold-4">
                        Start with a template:
                    </Text>

                    <Text as="p" variant="regular-4">
                        Use one of our templates to get you started. We are
                        adding to these over time.
                    </Text>

                    <Text as="p" variant="regular-4">
                        You will be able to edit the template however you see
                        fit.
                    </Text>

                    <Select
                        id={`bod-template`}
                        name={`bod-template`}
                        options={options}
                        value={template?.name ?? ''}
                        onValueChange={value =>
                            value
                                ? onTemplateSelected(
                                      stripTypenameFields(
                                          templates.find(
                                              ({ name }) => name === value
                                          )
                                      )
                                  )
                                : noop()
                        }
                        disabled={disabled}
                    />
                </>
            )}
        </StyledTemplate>
    )
}

const StyledTemplate = styled.div<{ $isLoading: boolean; $disabled: boolean }>`
    ${({ $isLoading }) =>
        flex('column', 'flex-start', $isLoading ? 'center' : 'flex-start')};
    gap: 1rem;
    padding: 1.5rem 2rem;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    width: 100%;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

    a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }
`
