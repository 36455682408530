import { styled } from 'Adapters/Freestyled'
import { FC, useMemo } from 'react'
import { createPortal } from 'react-dom'

/*
 * Credit to @Danjo for the idea
 * Overlays the screen with a grid of 5 rectangles. 4 of these have an opaque backdrop,
 * while the middle one is transparent. This creates a spotlight effect.
 * User interaction is only allowed on the transparent rectangle.
 */

type Props = {
    domRect: DOMRect | null | undefined
    active: boolean
}

const SPOTLIGHT_PADDING = 8

export const Spotlight: FC<Props> = ({ domRect, active }) => {
    const gridTemplate = useMemo(() => {
        if (!domRect) return null

        const { top, left, bottom, right } = domRect

        const topSpace = top
        const bottomSpace = window.innerHeight - bottom
        const leftSpace = left
        const rightSpace = window.innerWidth - right

        return [
            // Rows
            `${topSpace - SPOTLIGHT_PADDING}px`,
            `1fr`,
            `${bottomSpace - SPOTLIGHT_PADDING}px`,
            `/`,
            // Columns
            `${leftSpace - SPOTLIGHT_PADDING}px`,
            `1fr`,
            `${rightSpace - SPOTLIGHT_PADDING}px`,
        ].join(' ')
    }, [domRect])

    if (!active || !gridTemplate) return null

    return createPortal(
        <Styled
            style={{
                gridTemplate,
            }}
        >
            <div />
            <div />
            <div />
            <div />
            <div />
        </Styled>,
        document.body
    )
}

const Styled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 1;
    display: grid;
    pointer-events: none;

    > div {
        background-color: ${({ theme }) => theme.palette.ui['07'].normal};
        opacity: 0.9;
        pointer-events: all;
    }

    > div:nth-child(1) {
        grid-row: 1 / 4;
        grid-column: 1 / 2;
    }

    > div:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    }

    > div:nth-child(3) {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        background: transparent;
        pointer-events: none;
        box-shadow: inset 0 0 0.25rem 0.25rem
            ${({ theme }) => theme.palette.ui['07'].normal};
    }

    > div:nth-child(4) {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
    }

    > div:nth-child(5) {
        grid-row: 1 / 4;
        grid-column: 3 / 4;
    }
`
