import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Updates } from 'Features/Updates'
import { useResponder } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { match } from 'ts-pattern'

type Props = {
    updateCount: number
    businessObjectId?: string
}

const UpdatesCell: FC<Props> = ({ updateCount, businessObjectId }) => {
    const { screenSize } = useResponder()
    const ref = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)

    return businessObjectId ? (
        <>
            <IconTextButton
                icon="Send"
                text={`${updateCount} updates`}
                variant="ghost"
                onClick={e => {
                    e.stopPropagation()
                    setOpen(true)
                }}
            />
            {match(screenSize)
                .with('large', () => (
                    <ControlledModal
                        open={open}
                        onOpenChange={setOpen}
                        title="Updates"
                        description="Action updates"
                        hideDescription
                        renderContent={() => (
                            <StyledLarge businessObjectId={businessObjectId} />
                        )}
                        returnFocus={() => ref.current?.focus()}
                    />
                ))
                .otherwise(() => (
                    <MinimalModal open={open} onOpenChange={setOpen}>
                        <StyledSmall businessObjectId={businessObjectId} />
                    </MinimalModal>
                ))}
        </>
    ) : null
}

const StyledLarge = styled(Updates)`
    max-height: 80vh;
    width: calc(100vw - 6rem);
    max-width: 37rem;
    padding-top: 1rem;
`

const StyledSmall = styled(Updates)``

export { UpdatesCell }
