import { lightTheme, styled } from 'Adapters/Freestyled'
import { bold5 } from 'Components/Text'
import DromoUploader from 'dromo-uploader-react'
import { FC, useMemo } from 'react'
import { BODFields } from '../../../../Hooks/types'
import { FixValidationErrorsWizard } from './FixValidationErrorsWizard'
import {
    canImportBusinessObject,
    convertResponseIntoCreateBusinessObjectInputs,
    createFieldDictionary,
} from './helpers'
import { useBatchCreateBusinessObject } from './useBatchCreateBusinessObject'
import { useGenerateCSVFields } from './useGenerateCSVFields'
import { useUserMetadata } from './useUserMetadata'

const { VITE_DROMO_KEY } = import.meta.env

type Props = {
    businessObjectDefinitionFields: BODFields
    businessObjectDefinitionId: string
    businessObjectDefinitionName: string
    disabled?: boolean
}

export const ConvertFromCSV: FC<Props> = ({
    businessObjectDefinitionFields,
    businessObjectDefinitionId,
    businessObjectDefinitionName,
    disabled = false,
}) => {
    const user = useUserMetadata()

    const canImport = useMemo(
        () => canImportBusinessObject(businessObjectDefinitionFields),
        [businessObjectDefinitionFields]
    )

    const fieldDictionary = useMemo(
        () => createFieldDictionary(businessObjectDefinitionFields),
        [businessObjectDefinitionFields]
    )

    const { csvFields } = useGenerateCSVFields(businessObjectDefinitionFields)

    const {
        handleBatchCreateBusinessObjects,
        erroringInputs,
        setWizardOpen,
        wizardIsOpen,
    } = useBatchCreateBusinessObject()

    if (!user || !canImport || disabled) return null

    return (
        <>
            <StyledDromoUploader
                licenseKey={VITE_DROMO_KEY ?? ''}
                fields={csvFields}
                settings={{
                    importIdentifier: businessObjectDefinitionName,
                    developmentMode: true,

                    styleOverrides: {
                        primaryButton: {
                            backgroundColor:
                                lightTheme.palette.ui.primary.normal,
                            border: '1px solid transparent',
                            borderRadius: lightTheme.borderRadius.small,
                            hoverBackgroundColor:
                                lightTheme.palette.ui.primary.hover,
                            hoverBorder: '1px solid transparent',
                            hoverTextColor: lightTheme.palette.text['04'].hover,
                            textColor: lightTheme.palette.text['04'].normal,
                        },
                        secondaryButton: {
                            backgroundColor: lightTheme.palette.ui['02'].normal,
                            border: '1px solid transparent',
                            borderRadius: lightTheme.borderRadius.small,
                            hoverBackgroundColor:
                                lightTheme.palette.ui['02'].hover,
                            hoverBorder: '1px solid transparent',
                            hoverTextColor: lightTheme.palette.text['02'].hover,
                            textColor: lightTheme.palette.text['02'].normal,
                        },
                    },
                }}
                user={user}
                onResults={async response => {
                    const convertedResponse =
                        convertResponseIntoCreateBusinessObjectInputs({
                            businessObjectDefinitionId,
                            fieldDictionary,
                            response,
                        })

                    await handleBatchCreateBusinessObjects(convertedResponse) // Awaiting shows the spinner
                }}
            >
                Upload from CSV
            </StyledDromoUploader>

            <FixValidationErrorsWizard
                open={wizardIsOpen}
                setOpen={setWizardOpen}
                erroringInputs={erroringInputs}
                businessObjectDefinitionFields={businessObjectDefinitionFields}
            />
        </>
    )
}

const StyledDromoUploader = styled(DromoUploader)`
    ${bold5}
    padding: 0.75rem 1rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    width: max-content;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.text['02'].hover};
            background-color: ${({ theme }) => theme.palette.ui['02'].hover};
        }
    }

    &:active {
        color: ${({ theme }) => theme.palette.text['02'].active};
        background-color: ${({ theme }) => theme.palette.ui['02'].active};
    }

    &:focus-visible {
        box-shadow: 0 0 0 0.125rem
            ${({ theme }) => theme.palette.brand['01'].normal};
    }

    &:disabled {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        border: 1px solid transparent;
    }
`
