import { Root } from '@radix-ui/react-toast'
import { flex, styled } from 'Adapters/Freestyled'
import { enterAnimation, exitAnimation } from './animation'

export const StyledToast = styled(Root)`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.75rem;
    padding: 0.5rem 0.5rem 0.5rem 1.25rem;
    filter: drop-shadow(0px 8px 8px rgba(16, 37, 97, 0.04))
        drop-shadow(0px 16px 16px rgba(16, 37, 97, 0.08));
    background-color: ${({ theme }) => theme.palette.ui['07'].normal};
    border-radius: ${({ theme }) => theme.spacing['02']};
    color: ${({ theme }) => theme.palette.text['04'].normal};
    font-size: ${({ theme }) => theme.typography.scale['02']};
    line-height: ${({ theme }) => theme.typography.lineHeight.regular};

    &[data-state='open'] {
        ${enterAnimation};
    }

    &[data-state='closed'] {
        ${exitAnimation};
    }
`
