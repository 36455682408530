import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    name: string
    description: string | undefined | null
    fieldCount: number
    updates: boolean
    documents: boolean
    onClick: () => void
}

const GettingStartedCard: FC<Props> = ({
    name,
    description,
    fieldCount,
    updates,
    documents,
    onClick,
}) => (
    <Styled onClick={onClick}>
        <div>
            <Text as="span" variant="bold-4">
                {name}
            </Text>
            <Text as="span" variant="regular-5">
                {description ?? ''}
            </Text>
        </div>
        <footer>
            <div>
                <Icon name="Bullet" />
                <Text as="span" variant="regular-6">
                    {`${fieldCount} fields`}
                </Text>
            </div>
            {updates && (
                <div>
                    <Icon name="Comment" />
                    <Text as="span" variant="regular-6">
                        Updates
                    </Text>
                </div>
            )}
            {documents && (
                <div>
                    <Icon name="Document" />
                    <Text as="span" variant="regular-6">
                        Documents
                    </Text>
                </div>
            )}
        </footer>
    </Styled>
)

const Styled = styled.button`
    width: 100%;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    text-align: left;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['04'].normal}20
    );
    border-radius: 0.25rem;

    > div {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.25rem;
        text-align: left;
    }

    > div > span:nth-child(2) {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        padding-top: 0.75rem;
        border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    footer div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.25rem;
    }

    footer div .icon svg {
        --icon-width: 0.75rem;
        width: var(--icon-width);
        height: var(--icon-width);
        min-width: var(--icon-width);
        min-height: var(--icon-width);
    }
`

export { GettingStartedCard }
