import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, principal: { __typename?: 'UserPrincipal', id: string } }>, currentUser: { __typename?: 'User', id: string } };


export const OrganisationUsersDocument = gql`
    query OrganisationUsers {
  users {
    id
    name
    principal {
      id
    }
  }
  currentUser {
    id
  }
}
    `;

/**
 * __useOrganisationUsersQuery__
 *
 * To run a query within a React component, call `useOrganisationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationUsersQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationUsersQuery, OrganisationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationUsersQuery, OrganisationUsersQueryVariables>(OrganisationUsersDocument, options);
      }
export function useOrganisationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationUsersQuery, OrganisationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationUsersQuery, OrganisationUsersQueryVariables>(OrganisationUsersDocument, options);
        }
export type OrganisationUsersQueryHookResult = ReturnType<typeof useOrganisationUsersQuery>;
export type OrganisationUsersLazyQueryHookResult = ReturnType<typeof useOrganisationUsersLazyQuery>;
export type OrganisationUsersQueryResult = Apollo.QueryResult<OrganisationUsersQuery, OrganisationUsersQueryVariables>;