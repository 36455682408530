import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { CreateRelationModal } from 'Features/CreateRelationModal'
import { useFetchBusinessObjectsById, useModalControls } from 'Hooks'
import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { compact, isEqual, uniqBy } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../../../EditFieldButton'
import { useUpdateBusinessObjectField } from '../../../useUpdateBusinessObjectField'
import { ListValue } from '../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { RelationListFieldItem } from './RelationListFieldItem'
import { useRelationConstraints } from './useRelationConstraints'

type Props = BODField<'RelationFieldDefinition'> & {
    businessObjectId: string
    listName: string
    listId: string
    listValue: ListValue
    onComplete: () => void
}

type SelectedItems = { id: string; label: string }

const generateInitialValues = (
    listValue: ListValue
): { id: string; label: string }[] =>
    compact(
        listValue.map(field =>
            match(field)
                .with(
                    { __typename: 'BusinessObjectRelationField' },
                    ({ relationValue }) => {
                        return {
                            id: relationValue?.id ?? '',
                            label: relationValue?.label ?? '',
                        }
                    }
                )
                .otherwise(() => null)
        )
    )

export const RelationListField: FC<Props> = ({
    businessObjectId,
    relationConstraints,
    listName,
    listId,
    listValue,
    onComplete,
}) => {
    const modalControls = useModalControls()
    const { constraints, constraintsTooltip } = useRelationConstraints({
        relationConstraints,
    })

    const data = useFetchBusinessObjectsById(constraints.relationId)

    const [initialValues, setInitialValues] = useState<SelectedItems[]>(
        generateInitialValues(listValue)
    )

    const [values, setValues] = useState(generateInitialValues(listValue))

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const hasChanged = useMemo(() => {
        if (!isEqual(initialValues, values)) return true
        return false
    }, [values, initialValues])

    const handleSelection = (item: SelectedItems) => {
        setValues(prev => uniqBy([...prev, item], 'id'))
    }

    return (
        <>
            <ListFieldWrapper
                id={listId}
                name={listName}
                required={!!constraints.required}
                constraintsTooltip={constraintsTooltip}
                errors={errors}
                list={values.map(({ id }) => {
                    const relatedBusinessObject = data.businessObjects.find(
                        bo => bo.id === id
                    )

                    return relatedBusinessObject ? (
                        <RelationListFieldItem
                            key={relatedBusinessObject.id}
                            relatedBusinessObject={relatedBusinessObject}
                            setValues={setValues}
                        />
                    ) : null
                })}
            >
                <Dropdown
                    renderContent={() => (
                        <>
                            {data.businessObjects.map(businessObject => {
                                return (
                                    <DropdownButton
                                        key={businessObject.id}
                                        text={businessObject.label}
                                        onSelect={() => {
                                            setValues(prev =>
                                                uniqBy(
                                                    [
                                                        ...prev,
                                                        {
                                                            id: businessObject.id,
                                                            label: businessObject.label,
                                                        },
                                                    ],
                                                    'id'
                                                )
                                            )
                                        }}
                                    />
                                )
                            })}
                        </>
                    )}
                    renderOpenDropdownButton={() => (
                        <StyledAddDocumentButton
                            icon="Cube"
                            text={'Add a relation'}
                        />
                    )}
                    variant="light"
                />

                <IconButton
                    iconName="AddSmall"
                    title={`Create new ${
                        data?.businessObjects[0]?.definition.name ?? 'Relation'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        modalControls.openModal()
                    }}
                />

                <EditFieldButton
                    disabled={!hasChanged || loading}
                    onClick={() => {
                        updateBusinessObjectField({
                            field: {
                                list: {
                                    fieldDefinitionId: listId,
                                    previousValue: initialValues.map(val => ({
                                        relation: val.id,
                                    })),
                                    value: values.map(val => ({
                                        relation: val.id,
                                    })),
                                },
                            },
                            id: businessObjectId,
                            onSuccess: () => {
                                setInitialValues(values)
                                onComplete()
                            },
                        })
                    }}
                />
            </ListFieldWrapper>
            <CreateRelationModal
                businessObjectDefinitionId={constraints.relationId}
                onBusinessObjectCreated={businessObject => {
                    handleSelection({
                        id: businessObject.id,
                        label: businessObject.label,
                    })
                }}
                {...modalControls}
            />
        </>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
