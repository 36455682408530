import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { FC } from 'react'

type Props = {
    noOfSelections: number
    handleConfirm: () => void
    clearSelections: () => void
}

export const Footer: FC<Props> = ({
    handleConfirm,
    clearSelections,
    noOfSelections,
}) => {
    return (
        <StyledFooter>
            <TextButton
                text={'Clear selections'}
                onClick={clearSelections}
                size="small"
                variant="secondary"
            />

            <TextButton
                text={`Confirm selections (${noOfSelections})`}
                onClick={handleConfirm}
                size="small"
                variant="primary"
            />
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    ${flex('row', 'flex-end', 'center')}
    gap: 1rem;
    padding-top: 1.25rem;
    margin-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
`
