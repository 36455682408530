import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { CenteredSpinner } from 'Components/Spinner'
import { useResponder } from 'Hooks'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { Large } from './Large'
import { Small } from './Small'
import { useBusinessObjectInProcessOverviewQuery } from './__generated__/query'

type Props = {
    businessObjectId: string
    processId: string
    onRemoveFromProcess: () => void
}

const Overview: FC<Props> = ({
    businessObjectId,
    processId,
    onRemoveFromProcess,
}) => {
    const { screenSize } = useResponder()
    const { data, refetch } = useBusinessObjectInProcessOverviewQuery({
        variables: {
            processId,
            businessObjectId,
        },
        fetchPolicy: 'cache-and-network',
        onError(error) {
            logger.error('Failed to fetch process data', error, {
                processId,
            })
        },
    })

    const businessObject = useMemo(() => data?.businessObject, [data])

    const businessProcessPhaseIndex = useMemo(
        () => businessObject?.onProcess?.onPhase.phase.phaseIndex ?? undefined,
        [businessObject]
    )

    return !data ||
        !businessObject ||
        businessProcessPhaseIndex === undefined ? (
        <StyledLoading />
    ) : (
        match(screenSize)
            .with('large', () => (
                <Large
                    businessObject={businessObject}
                    process={data.process}
                    phaseIndex={businessProcessPhaseIndex}
                    refetch={refetch}
                    onRemoveFromProcess={onRemoveFromProcess}
                />
            ))
            .otherwise(() => (
                <Small
                    businessObject={businessObject}
                    process={data.process}
                    phaseIndex={businessProcessPhaseIndex}
                    refetch={refetch}
                    onRemoveFromProcess={onRemoveFromProcess}
                />
            ))
    )
}

const StyledLoading = styled(CenteredSpinner)`
    height: 100%;
`

export { Overview }
