import { omit } from 'lodash'
import { has, oneOfPair, upperFirst, UpperFirst } from 'Utils'
import { FieldType } from '__generated__'
import {
    BusinessObjectDefinition,
    CreateFieldParams,
    Fields,
    PatchDefaultFieldType,
    PatchOperation,
} from '../types'

export const newFieldId = '_NEW_FIELD_ID_'
export const newSelectOptionId = '_NEW_SELECT_OPTION_ID_'

const getFieldTypename = <T extends CreateFieldParams['type']>(
    type: T
): T extends 'url'
    ? 'URLFieldDefinition'
    : `${UpperFirst<T>}FieldDefinition` => {
    if (type === 'url') {
        return `URLFieldDefinition` as T extends 'url'
            ? 'URLFieldDefinition'
            : `${UpperFirst<T>}FieldDefinition`
    }
    return `${upperFirst(type)}FieldDefinition` as T extends 'url'
        ? 'URLFieldDefinition'
        : `${UpperFirst<T>}FieldDefinition`
}

const getConstraintTypename = (type: string) => {
    return `${upperFirst(type)}Constraint`
}

const getConstraintProp = (type: CreateFieldParams['type']) => {
    return `${type}Constraints` as const
}

const getDefaultProp = (type: PatchDefaultFieldType) => {
    if (type === 'number') return `numDefaultValue` as const
    return `${type}DefaultValue` as const
}

const mapFieldToDef = (
    fakeId: string,
    nextValue: CreateFieldParams
): BusinessObjectDefinition['fields'][number] => {
    return {
        __typename: getFieldTypename(nextValue.type),
        id: fakeId,
        ...nextValue,
        ...(nextValue.type === 'select'
            ? {
                  selectOptions: nextValue.options.map((option, i) => ({
                      ...option,
                      id: i,
                  })),
              }
            : {}),
        ...(nextValue.type === 'list'
            ? {
                  listOf: mapFieldToDef(
                      `${fakeId}-list`,
                      nextValue.listOf as CreateFieldParams
                  ),
              }
            : {}),
        ...(has(nextValue, 'constraints')
            ? {
                  [getConstraintProp(nextValue.type)]:
                      nextValue.constraints.flatMap(constraint =>
                          Object.entries(constraint).map(([type, value]) => ({
                              __typename: getConstraintTypename(type),
                              type,
                              ...value,
                          }))
                      ),
              }
            : {}),
    } as BusinessObjectDefinition['fields'][number] //fix this with a per-field map here, above works for now
}

export const patchDefinition = (
    businessObjectDefinition: BusinessObjectDefinition,
    op: PatchOperation
): BusinessObjectDefinition => {
    switch (op.operation) {
        case 'addField':
            return {
                ...businessObjectDefinition,
                fields: [
                    ...businessObjectDefinition.fields,
                    mapFieldToDef(
                        `${newFieldId}-${businessObjectDefinition.fields.length}`,
                        op.nextValue
                    ),
                ] as Fields,
            }
        case 'removeFieldConstraint':
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.map(field => {
                    const constraintProp = getConstraintProp(field.type)
                    if (field.id === op.fieldId) {
                        return {
                            ...field,
                            [constraintProp]: (has(field, 'constraintProp')
                                ? field[constraintProp]
                                : []
                            ).filter(({ type }) => type !== op.constraintType),
                        }
                    }
                    return field
                }),
            }
        case 'updateFieldDescription':
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.map(field => {
                    if (field.id === op.fieldId) {
                        return {
                            ...field,
                            description: op.nextValue,
                        }
                    }
                    return field
                }),
            }
        case 'updateFieldName':
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.map(field => {
                    if (field.id === op.fieldId) {
                        return {
                            ...field,
                            name: op.nextValue,
                        }
                    }
                    return field
                }),
            }
        case 'updateDescription':
            return {
                ...businessObjectDefinition,
                description: op.nextValue,
            }
        case 'updateLabel':
            return {
                ...businessObjectDefinition,
                label: op.nextValue,
            }
        case 'updateName':
            return {
                ...businessObjectDefinition,
                name: op.nextValue,
            }
        case 'addSelectFieldOption':
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.map(field => {
                    if (
                        field.__typename === 'SelectFieldDefinition' &&
                        field.id === op.fieldId
                    ) {
                        return {
                            ...field,
                            selectOptions: [
                                ...field.selectOptions,
                                {
                                    id: `${newSelectOptionId}-${field.selectOptions.length}`,
                                    value: op.nextValue,
                                },
                            ],
                        }
                    }
                    return field
                }),
            }
        case 'updateFieldDefault': {
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.map(field => {
                    const [fieldType, { fieldId, nextValue }] = oneOfPair(
                        omit(op, 'operation')
                    )

                    if (field.id === fieldId) {
                        if (field.type === FieldType.User) {
                            if (
                                typeof nextValue === 'string' ||
                                typeof nextValue === 'undefined'
                            ) {
                                return {
                                    ...field,
                                    userDefaultValue: nextValue
                                        ? {
                                              id: nextValue,
                                              name: '',
                                          }
                                        : null,
                                }
                            }

                            return field
                        }

                        return {
                            ...field,
                            [getDefaultProp(fieldType)]: nextValue,
                        }
                    }

                    return field
                }),
            }
        }
        case 'removeField':
            return {
                ...businessObjectDefinition,
                fields: businessObjectDefinition.fields.filter(
                    field => field.id !== op.fieldId
                ),
            }
    }
}
