export const slides = [
    'welcome',
    'processes',
    'businessObjects',
    'actions',
    'nextSteps',
] as const

export type Slide =
    | 'welcome'
    | 'processes'
    | 'businessObjects'
    | 'actions'
    | 'nextSteps'
