import { styled } from 'Adapters/Freestyled'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { FC } from 'react'
import { ActionCard } from '../../../../../../Features/ActionView/ActionCard'
import { useSortActions } from '../../Hooks'
import { Action } from '../../types'
import { DayHeader } from './DayHeader'
import { ActionGroup } from './Group'
import { MonthHeader } from './MonthHeader'
import { NoDueDateNotice } from './NoDueDateNotice'
import { useActionsTimeline } from './useActionsTimeline'

type Props = {
    actions: Action[]
    mostRecentAction: string | undefined
}

export const ActionsTimeline: FC<Props> = ({ actions, mostRecentAction }) => {
    const sortedActions = useSortActions(actions)
    const { days, actionsWithNoDueDate } = useActionsTimeline(sortedActions)

    return (
        <VerticalScrollArea width="100%" height="100%">
            <StyledGrid
                $cols={days.map(d => !!d.count)}
                $rows={Object.values(days[0].groups).length * 2}
            >
                <ul>
                    {days.map((day, i) => (
                        <li key={day.d.format('YYYY/MM/DD')}>
                            <MonthHeader dayIndex={i} day={day} />
                            <DayHeader day={day} />
                            {day.groups.map((group, j) => (
                                <ActionGroup
                                    key={`${group.label}-${j}`}
                                    group={group}
                                    row={2 * j + 3}
                                    col={i + 1}
                                />
                            ))}
                        </li>
                    ))}
                </ul>
            </StyledGrid>

            <NoDueDateNotice />

            <StyledNoDueDateActions>
                {actionsWithNoDueDate.map(({ id }) => (
                    <ActionCard
                        id={id}
                        key={id}
                        isMostRecent={id === mostRecentAction}
                    />
                ))}
            </StyledNoDueDateActions>
        </VerticalScrollArea>
    )
}

const StyledGrid = styled.div<{ $cols: boolean[]; $rows: number }>`
    display: grid;
    margin-left: 3rem;
    grid-template: ${({ $rows, $cols }) =>
        `repeat(${$rows + 2}, auto) / ${$cols
            .map(notEmpty =>
                notEmpty ? 'minmax(20rem, 1fr)' : 'minmax(2.25rem, 1fr)'
            )
            .join(' ')} 3rem`};
    overflow-x: scroll;
    width: 100%;

    &:after {
        content: '';
        grid-column: ${({ $cols }) =>
            `${$cols.length + 1} / ${$cols.length + 2}`};
        grid-row: 1 / ${({ $rows }) => `${$rows + 2}`};
        width: 3rem;
    }

    > ul,
    > ul > li {
        display: contents;
    }

    > ul > li > section[data-today='true'] {
        background: ${({ theme }) => theme.palette.brand['01'].normal};
    }
`

const StyledNoDueDateActions = styled.div`
    display: grid;
    padding: 1rem 3rem 2rem 3rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: auto;
    gap: 1rem;
    width: 100%;
`
