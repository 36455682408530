import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { UpdateBusinessObjectField } from '__generated__'
import { uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { useUpdateBusinessObjectFieldMutation } from './__generated__/UpdateBusinessObjectField'

type UpdateBOParams = {
    id: string
    field: UpdateBusinessObjectField
    onSuccess?: () => void
}

export const useUpdateBusinessObjectField = (onError?: () => void) => {
    const [errors, setErrors] = useState<string[]>([])

    const [update, { loading }] = useUpdateBusinessObjectFieldMutation({
        onError(error) {
            logger.error('Failed to update business object field', error)

            error.graphQLErrors.forEach(error => {
                if (!hasCustomErrorExtenstions(error.extensions)) {
                    setErrors(prev => uniq([...prev, error.message]))
                    return
                }

                Object.values(error.extensions.errors.errors).forEach(value => {
                    setErrors(prev => uniq([...prev, ...value]))
                })
            })
        },
    })

    const updateBusinessObjectField = useCallback(
        ({ field, id, onSuccess }: UpdateBOParams) => {
            setErrors([])

            update({
                variables: {
                    input: {
                        id,
                        field,
                    },
                },
                onCompleted: onSuccess,
                onError,
            })
        },
        [update, onError]
    )

    return { updateBusinessObjectField, errors, loading }
}
