import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { AreaSelect } from './AreaSelect'
import { SingleSelect } from './SingleSelect'
import { areas } from './config'

type Props = {
    area: keyof typeof areas
    single?: {
        selected: number
        options: {
            text: string
            path: string
        }[]
    }
}

const Breadcrumb: FC<Props> = ({ area, single }) => {
    const navigate = useNavigate()
    return (
        <Styled>
            <IconButton iconName="AngleLeft" onClick={() => navigate('../')} />
            <AreaSelect area={area} />
            <SingleSelect area={area} single={single} />
        </Styled>
    )
}

const Styled = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: max-content;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    h3 {
        padding: 0 0.5rem;
    }
`

export { Breadcrumb }
