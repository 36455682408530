import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { ComponentProps, ReactElement } from 'react'
import { P, match } from 'ts-pattern'
import { ShortText } from './ShortText'
import { Unset } from './Unset'

type HeaderCell = {
    label: string
    icon?: ComponentProps<typeof Icon>['name']
    inactive?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export type Column = {
    justify?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
    header: HeaderCell
}

export type Columns = Record<string, Column>

export type Group<T extends Columns> = {
    label: string
    colorConfig: ColorConfig
    rows: {
        cells: {
            [key in keyof T]: {
                element: ReactElement | string | null | undefined
                stopPropagation?: boolean
            }
        }
        onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
    }[]
}

type ColorConfig =
    | {
          color: string
      }
    | {
          headerBackground: string
          label: string
          background: string
          backgroundHover: string
          backgroundActive: string
          borderTop: string
          borderRight: string
      }

type Props<T extends Columns> = {
    groups: Group<T>[]
    columns: T
    className?: string
}

const GridTable = <T extends Columns>({
    groups,
    columns,
    className = '',
}: Props<T>): ReactElement => (
    <Styled $cols={Object.keys(columns).length} className={className}>
        <header>
            <ul>
                {Object.values(columns).map(
                    ({ header: { label, icon, onClick, inactive } }) => (
                        <StyledHeaderCell key={label}>
                            {onClick ? (
                                <button
                                    onClick={onClick}
                                    data-inactive={!!inactive}
                                >
                                    <Text as="span" variant="bold-4">
                                        {label}
                                    </Text>
                                    {icon && <Icon name={icon} />}
                                </button>
                            ) : (
                                <div>
                                    <Text as="p" variant="bold-4">
                                        {label}
                                    </Text>
                                    {icon && <Icon name={icon} />}
                                </div>
                            )}
                        </StyledHeaderCell>
                    )
                )}
            </ul>
        </header>
        {groups.map(({ label, colorConfig, rows }, i) => (
            <StyledGroup
                key={`${label || 'ungroup'}-${i}`}
                $colorConfig={colorConfig}
            >
                {label && (
                    <header>
                        <Text as="h6" variant="bold-6">
                            {label}
                        </Text>
                    </header>
                )}
                <ul>
                    {rows.map((row, i) => (
                        <StyledRow
                            key={`row-${i}`}
                            data-clickable={!!row.onClick}
                            $colorConfig={colorConfig}
                        >
                            <ul>
                                {Object.keys(columns).map(key => {
                                    const cell = row.cells[key]
                                    return (
                                        <StyledCell
                                            key={key}
                                            $justify={
                                                columns[key].justify ||
                                                'flex-start'
                                            }
                                            onClick={row.onClick}
                                        >
                                            <div
                                                onClick={e =>
                                                    cell.stopPropagation &&
                                                    e.stopPropagation()
                                                }
                                            >
                                                {match(cell?.element)
                                                    .with(P.nullish, () => (
                                                        <Unset />
                                                    ))
                                                    .otherwise(el =>
                                                        typeof el ===
                                                        'string' ? (
                                                            <ShortText
                                                                text={el}
                                                            />
                                                        ) : (
                                                            el
                                                        )
                                                    )}
                                            </div>
                                        </StyledCell>
                                    )
                                })}
                            </ul>
                        </StyledRow>
                    ))}
                </ul>
            </StyledGroup>
        ))}
    </Styled>
)

const Styled = styled.section<{ $cols: number }>`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${({ $cols }) => $cols}, minmax(auto, 27rem));
    min-width: fit-content;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    > header,
    > header > ul,
    > section,
    > section > ul,
    > section > ul > li,
    > section > ul > li > ul {
        display: contents;
    }

    > section > header,
    > section > ul > li > ul > li {
        padding: 0.5rem 1.5rem;
    }

    > section > header {
        grid-column: 1/-1;
    }
`

const StyledHeaderCell = styled.li`
    border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    > div,
    > button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
        padding: 0.5rem 1.5rem;
        white-space: nowrap;
        height: 100%;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        text-transform: capitalize;
    }

    > div svg path,
    > button svg path {
        fill: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > button[data-inactive='true'] svg {
        opacity: 0.3;
    }

    > button[data-inactive='true'] svg path {
        fill: ${({ theme }) => theme.palette.text['03'].normal};
    }

    > button {
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration};
    }

    > button:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration};
    }

    > button:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration};
    }
`

const StyledGroup = styled.section<{ $colorConfig: ColorConfig }>`
    > header {
        background-color: ${({ $colorConfig }) =>
            match($colorConfig)
                .with({ color: P.string }, ({ color }) => `${color}33`)
                .otherwise(({ headerBackground }) => headerBackground)};
    }

    > header h6 {
        color: ${({ $colorConfig }) =>
            match($colorConfig)
                .with({ color: P.string }, ({ color }) => color)
                .otherwise(({ label }) => label)};
    }
`

const StyledRow = styled.li<{ $colorConfig: ColorConfig }>`
    > ul > li {
        background-color: ${({ $colorConfig }) =>
            match($colorConfig)
                .with({ color: P.string }, ({ color }) => `${color}11`)
                .otherwise(({ background }) => background)};
        border-right: 1px dotted
            ${({ $colorConfig }) =>
                match($colorConfig)
                    .with({ color: P.string }, ({ color }) => `${color}33`)
                    .otherwise(({ borderRight }) => borderRight)};
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration};
    }

    &:not(:first-child) > ul > li {
        border-top: 1px solid
            ${({ $colorConfig }) =>
                match($colorConfig)
                    .with({ color: P.string }, ({ color }) => `${color}33`)
                    .otherwise(({ borderTop }) => borderTop)};
    }

    &[data-clickable='true']:hover > ul > li {
        cursor: pointer;
        background-color: ${({ $colorConfig }) =>
            match($colorConfig)
                .with({ color: P.string }, ({ color }) => `${color}22`)
                .otherwise(({ backgroundHover }) => backgroundHover)};
    }

    &[data-clickable='true']:active > ul > li {
        cursor: pointer;
        background-color: ${({ $colorConfig }) =>
            match($colorConfig)
                .with({ color: P.string }, ({ color }) => `${color}33`)
                .otherwise(({ backgroundActive }) => backgroundActive)};
    }
`

const StyledCell = styled.li<{
    $justify: 'flex-start' | 'center' | 'flex-end' | 'stretch'
}>`
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: ${({ $justify }) => $justify};

    > div {
        width: 100%;
    }
`

export { GridTable }
