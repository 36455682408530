import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button/IconButton'
import { TextButton } from 'Components/Button/TextButton'
import { FC } from 'react'
import { strings } from './strings'

export type Props = {
    displayPrevious: () => void
    displayNext: () => void
    displayText: string
    toggleMonthSelector: () => void
}

export const DatePickerNav: FC<Props> = ({
    displayPrevious,
    displayNext,
    displayText,
    toggleMonthSelector,
}) => (
    <StyledDatePickerNav>
        <IconButton
            aria-label={strings.ariaLabelPrev}
            iconName="CarrotLeft"
            onClick={displayPrevious}
        />
        <TextButton
            aria-label={strings.ariaLabelSelectMonth}
            variant="ghost"
            text={displayText}
            onClick={toggleMonthSelector}
        />
        <IconButton
            aria-label={strings.ariaLabelNext}
            iconName="CarrotRight"
            onClick={displayNext}
        />
    </StyledDatePickerNav>
)

const StyledDatePickerNav = styled.nav`
    ${flex('row', 'space-between', 'center')};
    width: 100%;
    padding-bottom: 1rem;
`
