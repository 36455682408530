import { dayjs } from 'Adapters/DayJS'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { ActionPhase } from '__generated__'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { ActionsTableItemFragment } from '../__generated__/q'
import { useActionsTableToggleActionMutation } from './__generated__/ToggleAction'

type Props = {
    action: ActionsTableItemFragment
}

const CompletionCell: FC<Props> = ({ action }) => {
    const [update, { loading }] = useActionsTableToggleActionMutation()
    const { open, setOpen, triggerToast } = useTriggerToast()

    return (
        <>
            <IconTextButton
                disabled={loading}
                icon={
                    action.__typename === 'CompletedAction'
                        ? 'CheckboxTicked'
                        : 'CheckboxUnticked'
                }
                text={
                    action.__typename === 'CompletedAction'
                        ? dayjs(action.completedAt).format('DD MMM YYYY')
                        : 'Incomplete'
                }
                onClick={() => {
                    update({
                        variables: {
                            input: {
                                id: action.id,
                                phase:
                                    action.__typename === 'CompletedAction'
                                        ? ActionPhase.Open
                                        : ActionPhase.Closed,
                            },
                        },
                        onError: e => {
                            triggerToast()
                            logger.error('Failed to toggle action', e)
                        },
                        update: cache => {
                            cache.modify({
                                id: cache.identify({
                                    __typename: action.__typename,
                                    id: action.id,
                                }),
                                fields: {
                                    __typename: () =>
                                        match(action)
                                            .with(
                                                {
                                                    __typename:
                                                        'CompletedAction',
                                                },
                                                () =>
                                                    'IncompleteAction' as const
                                            )
                                            .with(
                                                {
                                                    __typename:
                                                        'IncompleteAction',
                                                },
                                                () => 'CompletedAction' as const
                                            )
                                            .exhaustive(),
                                },
                            })
                        },
                        onQueryUpdated: query => query.refetch(),
                    })
                }}
                size="small"
            />
            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to toggle the action's completed state. Contact us if the problem persists."
            />
        </>
    )
}

export { CompletionCell }
