import { ApolloLink, from, HttpLink, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { logger } from 'Adapters/Logger'
import { gqlUri } from './config'

const { VITE_AUTH0_CLIENT_ID } = import.meta.env

type GetLinks = (getToken: () => Promise<string | undefined>) => ApolloLink
export const getLinks: GetLinks = getToken => {
    const withToken = setContext(async () => ({ token: await getToken() }))

    const authenticationMiddleware = new ApolloLink((operation, forward) => {
        const { token } = operation.getContext()
        const noTokenErr = new Error(
            'Unable to set request headers as no token present'
        )
        if (!token) logger.error(noTokenErr.message, noTokenErr)
        else {
            operation.setContext(() => ({
                headers: {
                    Authorization: `Bearer ${token}`,
                    clientId: VITE_AUTH0_CLIENT_ID,
                },
            }))
        }

        return forward(operation)
    })

    const errorMiddleware = onError(
        ({ graphQLErrors, networkError, response, operation, forward }) => {
            graphQLErrors?.forEach(err =>
                logger.error('GraphQL Error', err, {
                    response,
                    operation,
                })
            )
            if (networkError)
                logger.error('Network Error', networkError, {
                    response,
                    operation,
                })
            return forward(operation)
        }
    )

    const http = new HttpLink({ uri: gqlUri })
    const batch = new BatchHttpLink({
        uri: gqlUri,
        batchInterval: 20, // Wait no more than 20ms after first batched operation
        batchMax: 5,
    })

    return from([
        withToken,
        authenticationMiddleware,
        errorMiddleware,
        split(op => op.getContext().useBatching, batch, http),
    ])
}
