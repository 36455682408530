import { flex, styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { verticalAlignWithinCell } from '../cssHelpers'

/*
 * A text cell with an icon preceding the text
 */

type Props = {
    text: string
    iconName: IconName
    className?: string
}

export const IconTextCell: FC<Props> = ({ text, iconName, className = '' }) => {
    return (
        <StyledIconTextCell className={className}>
            <div>
                <Icon name={iconName} />
                <span className="text">{text}</span>
            </div>
        </StyledIconTextCell>
    )
}

const StyledIconTextCell = styled.td`
    padding: ${({ theme }) => theme.spacing['04']} 0;
    padding-left: 1.25rem;
    vertical-align: middle;

    > div {
        ${verticalAlignWithinCell};
        ${flex('row', 'center', 'center')};
        gap: 0.5rem;

        .icon {
            min-height: 1rem;
            min-width: 1rem;
        }

        svg path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }

        .text {
            ${regular5};
            text-align: left;
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }
    }
`
