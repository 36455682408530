import { flex, styled } from 'Adapters/Freestyled'

export const StyledCriterion = styled.li`
    ${flex('column', 'flex-start', 'flex-start')};
    padding: 1rem 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    }

    .action {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.25rem;
        padding-bottom: 0.5rem;

        > p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }

        .icon svg {
            height: 0.75rem;
            width: 0.75rem;
        }

        .icon path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    .default-due-time {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`
