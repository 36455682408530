import { Auth0Provider } from '@auth0/auth0-react'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth0Domain, authClientId } from './config'

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate()
    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={authClientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: import.meta.env.VITE_GQL_AUDIENCE,
            }}
            onRedirectCallback={appState => {
                navigate(appState?.returnTo || '/app')
            }}
        >
            {children}
        </Auth0Provider>
    )
}
