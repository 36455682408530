import { IUser } from 'dromo-uploader-react'
import { useMemo } from 'react'
import { useUserMetadataQuery } from './__generated__/query'

export const useUserMetadata = () => {
    const { data } = useUserMetadataQuery()

    const userMetadata = useMemo(() => {
        if (!data) return undefined

        return {
            id: data.currentUser.id,
            name: data.currentUser.name,
            email: data.currentUser.email,
            companyId: data.currentUser.activeTenant?.tenant.id ?? '',
            companyName: data.currentUser.activeTenant?.tenant.name ?? '',
        } satisfies IUser
    }, [data])

    return userMetadata
}
