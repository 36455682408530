import { NumberInput } from 'Components/Input'
import { BizObjDef_Num, BOField } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useNumberConstraints } from './useNumberConstraints'

// NB. I am assuming the user knows that numbers should be incrementable
// and number fields are not to be used to denote credit card numbers, phone numbers etc
// that are 'numeric' rather than numbers

type Props = BOField<'BusinessObjectNumberField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const NumberField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    numValue,
    onComplete,
}) => {
    const { numConstraints, ...common } = fieldDefinition as BizObjDef_Num

    const { constraints, constraintsTooltip } = useNumberConstraints({
        numConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(numValue?.toString() ?? '')

    const [value, setValue] = useState(initialValue.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <NumberInput
                name={common.type + '||' + common.id}
                defaultValue={numValue ?? ''}
                id={common.id}
                hasError={!!errors.length}
                onChange={e => {
                    setValue(e.target.value)
                }}
                type="number"
                min={constraints.min}
                max={constraints.max}
                required={constraints.required}
            />

            <EditFieldButton
                disabled={initialValue.current === value || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current
                                    ? Number(initialValue.current)
                                    : undefined,
                                value:
                                    // Empty strings should be converted to undefined, not 0
                                    value.length === 0
                                        ? undefined
                                        : Number(value),
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = value
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}
