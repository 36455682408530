import { Title } from '@radix-ui/react-dialog'
import { styled } from 'Adapters/Freestyled'
import { bold2 } from 'Components/Text'
import { FC } from 'react'

type Props = {
    title: React.ReactElement | string
}

export const ModalTitle: FC<Props> = ({ title }) => {
    return <StyledTitle>{title}</StyledTitle>
}

export const StyledTitle = styled(Title)`
    ${bold2};
    color: ${({ theme }) => theme.palette.text['01'].normal};
    padding-bottom: 0.5rem;
`
