import { styled } from 'Adapters/Freestyled'
import { bold1, regular5 } from 'Components/Text'
import { keyframes } from 'styled-components'

const enter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const Styled = styled.div<{ $isLarge: boolean }>`
    width: 100vw;
    height: 100dvh;
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr) ${({ $isLarge }) =>
            $isLarge ? '35%' : ''};
    align-items: center;
    justify-content: center;

    > header {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        padding: 2rem 3rem;
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: ${({ $isLarge }) =>
            $isLarge ? 'flex-start' : 'center'};

        svg {
            display: block;
        }
    }

    > div:first-of-type {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        height: 100%;
        position: relative;
        z-index: 1;
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > .background {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        height: 100%;
        position: relative;
        background-color: ${({ theme }) => theme.palette.brand['01'].normal};

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            // Placholder BG
            background-color: #0093e9;
            background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);

            animation-name: ${enter};
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
    }

    form {
        width: 26rem;
        display: block;
        padding: 2rem;
        overflow: auto;
        max-height: 100%;
    }

    form > header {
        padding-bottom: 1.5rem;
    }

    form > header p {
        ${regular5}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.5rem;
    }

    form > header h2 {
        ${bold1}
    }

    form > footer {
        padding-top: 0.5rem;
    }
`
