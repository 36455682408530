import { Item, ItemIndicator, ItemText } from '@radix-ui/react-select'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { SelectOption } from '../types'

type Props = {
    option: SelectOption
}

export const SelectItem: FC<Props> = ({ option }) => {
    return (
        <StyledItem
            value={option.value}
            textValue={option.text}
            key={option.value}
            disabled={option.disabled}
        >
            <ItemText>{option.text}</ItemText>
            <StyledItemIndicator>
                <Icon name="Check" />
            </StyledItemIndicator>
        </StyledItem>
    )
}

const StyledItem = styled(Item)`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};
    padding: 0.5rem 0.75rem;
    height: fit-content;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    position: relative;
    user-select: none;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    svg {
        path {
            fill: ${({ theme }) => theme.palette.icon['01'].normal};
        }
    }

    &[data-disabled] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        pointer-events: none;

        svg {
            path {
                fill: ${({ theme }) => theme.palette.icon['03'].normal};
            }
        }
    }

    &[data-highlighted] {
        outline: none;
        box-shadow: none;
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }
`

const StyledItemIndicator = styled(ItemIndicator)`
    margin-left: auto;
`
