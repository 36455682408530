import { TextInput } from 'Components/Input'
import {
    TextField as Field,
    TextFieldUnique,
    TextFieldValidation,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { LengthConstraint } from './Constraint/Length'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: TextFieldValidation
    onSubmit: OnSubmit<Field, TextFieldValidation>
    onClose: () => void

    hideRequired: boolean
}

const TextField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, TextFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <TextSubFields<Field>
                defaultValue={{
                    value: state.field.defaultValue ?? '',
                    onChange: value =>
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                defaultValue: value,
                            },
                        })),
                }}
                minMax={{
                    length: state.field.constraints.length ?? undefined,
                    update: fn =>
                        setState(prev => ({
                            ...prev,
                            field: fn(prev.field),
                        })),
                }}
                constraintsLocked={field.constraintsLocked}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type SubFieldsProps<T extends TextFieldUnique> = {
    defaultValue: {
        value: string
        onChange: (value: string) => void
    }
    minMax: {
        length: T['constraints']['length']
        update: (fn: (prev: T) => T) => void
    }
    constraintsLocked: boolean
}

const TextSubFields = <T extends TextFieldUnique>({
    defaultValue,
    minMax,
    constraintsLocked,
}: SubFieldsProps<T>): ReturnType<FC<SubFieldsProps<T>>> => {
    const { current: defaultFieldId } = useRef(v4())

    return (
        <>
            <InputWrapper
                label="Default value"
                fieldId={defaultFieldId}
                description="If set this will be the default value for the text field when a new record is created."
            >
                <TextInput
                    id={defaultFieldId}
                    value={defaultValue.value}
                    placeholder="Enter a default value"
                    onChange={({ currentTarget: { value } }) =>
                        defaultValue.onChange(value)
                    }
                    onBlur={({ currentTarget: { value } }) =>
                        defaultValue.onChange(value.trim())
                    }
                />
            </InputWrapper>
            <InputWrapper
                fieldset
                label="Min/max characters"
                description="This will limit the number of characters that can be entered into the text field."
                displayOnly={constraintsLocked}
            >
                <LengthConstraint
                    length={minMax.length}
                    update={fn => {
                        minMax.update(prev => ({
                            ...prev,
                            constraints: {
                                ...prev.constraints,
                                length: fn(prev.constraints.length),
                            },
                        }))
                    }}
                    displayOnly={constraintsLocked}
                />
            </InputWrapper>
        </>
    )
}

export { TextField, TextSubFields }
