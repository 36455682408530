import { flex, styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { AddDocument } from './AddDocument'
import { Field } from './types'

type Props = {
    businessObjectId: string
    fields: Field[]
}

export const AddDocumentButtons: FC<Props> = ({ fields, businessObjectId }) => {
    return (
        <StyledAddDocumentButtonsContainer>
            {fields.map(field => {
                return match(field)
                    .with(
                        { __typename: 'BusinessObjectDocumentField' },
                        documentField => (
                            <AddDocument
                                key={documentField.fieldDefinition.id}
                                documentField={documentField}
                                businessObjectId={businessObjectId}
                            />
                        )
                    )
                    .with(
                        {
                            __typename: 'BusinessObjectListField',
                            fieldDefinition: {
                                listOf: {
                                    __typename: 'DocumentFieldDefinition',
                                },
                            },
                        },
                        documentListField => (
                            <AddDocument
                                key={documentListField.fieldDefinition.id}
                                documentField={documentListField}
                                businessObjectId={businessObjectId}
                            />
                        )
                    )
                    .otherwise(() => null)
            })}
        </StyledAddDocumentButtonsContainer>
    )
}

const StyledAddDocumentButtonsContainer = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    width: 75%;
    overflow-x: auto;

    > button {
        white-space: nowrap;
    }
`
