import { styled } from 'Adapters/Freestyled'
import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route } from '../../types'

type Props = {
    routes: Route[]
}

const BusinessObjectDirectory: FC<Props> = ({ routes }) => {
    const navigate = useNavigate()
    return (
        <Styled>
            <header>
                <Text as="h1" variant="bold-2">
                    Datasets
                </Text>
            </header>
            <section>
                <ul>
                    {routes.map(route => (
                        <li key={route.id}>
                            <MenuNavButton
                                icon="Cube"
                                title={route.name}
                                description={route.description}
                                onClick={() => navigate(`./${route.slug}`)}
                            />
                        </li>
                    ))}
                </ul>
            </section>
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr);
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    > header {
        padding: 1rem 4vw;
        grid-row: 1 / 2;
    }

    > section {
        overflow: auto;
        grid-row: 2 / 3;
    }

    > section > ul {
        padding: 0 0 1rem 4vw;
        display: flex;
        flex-direction: column;
    }
`

export { BusinessObjectDirectory }
