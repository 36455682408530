import { DATE_FORMAT, WEEKDAYS } from 'Components/DatePicker/constants'
import { makeDay } from 'Components/DatePicker/utils'
import { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'
import { blankArray } from 'Utils'
import { Day } from '../../Button/Day/Day'
import { FocusTargetDate } from '../../types'
import { Limit } from '../types'
import { strings } from './strings'
import { Styled, StyledWeekdayLabel } from './styles'
import { arrowHandler } from './utils'

export type Props = {
    selection: Dayjs | undefined
    setSelection: React.Dispatch<React.SetStateAction<Dayjs | undefined>>
    display: Dayjs
    setDisplay: React.Dispatch<React.SetStateAction<Dayjs>>
    focusTargetDate: FocusTargetDate
    limit?: Limit
}

export const Calendar: FC<Props> = ({
    display,
    setDisplay,
    selection,
    setSelection,
    focusTargetDate,
    limit,
}) => {
    const daysOfMonth = blankArray(display.daysInMonth())

    // To conform to WAI-ARIA specifications, only one day in the grid should be tabbable (other navigation should happen by arrow key)
    // This order of preference is as follows: selected date > today's date > first of the month
    const tabbableDate = useMemo<number>(() => {
        const { selected, today, first } = daysOfMonth.reduce<{
            selected: number | undefined
            today: number | undefined
            first: number
        }>(
            (out, _, i) => {
                const day = display.add(i, 'day')
                const dateString = day.format(DATE_FORMAT)
                const isSelected = selection?.format(DATE_FORMAT) === dateString
                const isToday = day.isSame(makeDay(), 'day')
                if (isSelected) return { ...out, selected: i + 1 }
                if (isToday) return { ...out, today: i + 1 }
                return out
            },
            {
                selected: undefined,
                today: undefined,
                first: 1,
            }
        )

        if (selected) return selected
        if (today) return today
        return first
    }, [daysOfMonth, display, selection])

    return (
        <Styled
            aria-label={`${strings.ariaLabel} ${display.format('MMM YYYY')}`}
        >
            {WEEKDAYS.map((day, i) => (
                <li key={i} data-day={day.tag}>
                    <StyledWeekdayLabel>{day.label}</StyledWeekdayLabel>
                </li>
            ))}

            {daysOfMonth.map((_, i, arr) => {
                const day = display.add(i, 'day')
                const dateString = day.format(DATE_FORMAT)
                const handleArrow = arrowHandler(focusTargetDate)(
                    day,
                    setDisplay
                )
                const isBeforeLimit =
                    limit?.from &&
                    day.isBefore(makeDay({ input: limit.from }), 'day')
                const isAfterLimit =
                    limit?.to &&
                    day.isAfter(makeDay({ input: limit.to }), 'day')

                return (
                    <li key={dateString} data-day={WEEKDAYS[day.weekday()].tag}>
                        <Day
                            disabled={isBeforeLimit || isAfterLimit}
                            dayDisplay={i + 1}
                            isToday={day.isSame(makeDay(), 'day')}
                            selected={
                                selection?.format(DATE_FORMAT) === dateString
                            }
                            dateString={dateString}
                            onClick={() => setSelection(day)}
                            onUpArrow={handleArrow('up', i > 6)}
                            onDownArrow={handleArrow(
                                'down',
                                arr.length - i > 7
                            )}
                            onLeftArrow={handleArrow('left', i > 0)}
                            onRightArrow={handleArrow(
                                'right',
                                i + 1 < arr.length
                            )}
                            isTabbable={tabbableDate === i + 1}
                        />
                    </li>
                )
            })}
        </Styled>
    )
}
