import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { oneOfPair, replaceEmptyString } from 'Utils'
import { PatchBusinessObjectDefinitionInput } from '__generated__'
import { isNull, omit, uniq } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { FieldState } from '../../CreateBusinessObjectDef/types'
import { useBusinessDomain_PatchBusinessObjectDefinitionMutation } from '../__generated__/Patch'
import { CreateFieldParams, PatchOperation } from '../types'

const fieldStateToMutationVars = ({
    type,
    name,
    description,
    ...field
}: CreateFieldParams) => {
    if (type === 'list') {
        const {
            type: listOfType,
            name: listOfName,
            description: listOfDescription,
            ...listOf
        } = (field as FieldState<'list'>).listOf
        return {
            name,
            description: replaceEmptyString(description),
            [type]: {
                listOf: {
                    name: listOfName,
                    [listOfType]: {
                        ...listOf,
                    },
                },
            },
        }
    }
    return {
        name,
        description: replaceEmptyString(description ?? ''),
        [type]: field,
    }
}

const operationToMutationVars = (
    operation: PatchOperation
): PatchBusinessObjectDefinitionInput['operations'][number] => {
    if (operation.operation === 'addField') {
        return {
            [operation.operation]: {
                ...omit(operation, ['operation', 'nextValue']),
                nextValue: fieldStateToMutationVars(operation.nextValue),
            },
        }
    }

    if (operation.operation === 'updateFieldDefault') {
        const [fieldType, { fieldId, nextValue, previousValue }] = oneOfPair(
            omit(operation, 'operation')
        )

        const nextValueUpdate =
            typeof nextValue === 'object'
                ? omit(nextValue, '__typename')
                : nextValue ?? null

        const previousValueUpdate =
            typeof previousValue === 'object'
                ? omit(previousValue, '__typename')
                : previousValue ?? null

        return {
            updateFieldDefault: {
                [fieldType]: {
                    fieldId,
                    nextValue:
                        typeof nextValueUpdate === 'object' &&
                        !isNull(nextValueUpdate) &&
                        !Object.keys(nextValueUpdate!).length
                            ? null
                            : nextValueUpdate,
                    previousValue:
                        typeof previousValueUpdate === 'object' &&
                        !isNull(previousValueUpdate) &&
                        !Object.keys(previousValueUpdate!).length
                            ? null
                            : previousValueUpdate,
                },
            },
        }
    }

    return {
        [operation.operation]: omit(operation, 'operation'),
    }
}

export const usePatchBusinessObjectDefinition = ({
    onCompleted,
    setErrors,
}: Pick<
    Exclude<
        Parameters<
            typeof useBusinessDomain_PatchBusinessObjectDefinitionMutation
        >[0],
        undefined
    >,
    'onCompleted'
> & { setErrors: Dispatch<SetStateAction<string[]>> }) => {
    const [mutate, mutateMeta] =
        useBusinessDomain_PatchBusinessObjectDefinitionMutation({
            onQueryUpdated: query => query.refetch(),
            onCompleted,
            onError(e) {
                logger.error('Failed to patch business object definition', e)

                e.graphQLErrors.forEach(error => {
                    if (hasCustomErrorExtenstions(error.extensions)) {
                        const message = error.extensions.errors.message

                        setErrors(prev =>
                            uniq([
                                ...prev,
                                ...(Array.isArray(message)
                                    ? message
                                    : [message]),
                            ])
                        )

                        const deepErrors = Object.values(
                            error.extensions.errors.errors
                        ).flat()

                        setErrors(prev => uniq([...prev, ...deepErrors]))
                    }
                })
            },
        })

    const handleSave = ({
        id,
        operations,
    }: {
        id: string
        operations: PatchOperation[]
    }) => {
        setErrors([])
        mutate({
            variables: {
                input: {
                    id,
                    operations: operations.map(operationToMutationVars),
                },
            },
        })
    }

    return [handleSave, mutateMeta] as const
}
