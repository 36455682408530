import * as Accordion from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { canFilterFieldType } from 'Components/ComparatorBuilderV2/useFilterableFields'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { useModalControls } from 'Hooks'
import { ValueSelectorType } from '__generated__'
import { FC, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Extension } from '../../types'
import { pipStyles } from './EditViewMenu/styles'
import {
    AssignedToFilterControls,
    CreatedByFilterControls,
    ProcessFilterControls,
    ShowByFilterControls,
} from './FilterControls'
import { BusinessObjectFilterControls } from './FilterControls/BusinessObjectFilterControls'
import { DueBeforeFilterControls } from './FilterControls/DueBeforeFilterControls'
import { useProcesses_ActionsFilterQuery } from './__generated__/query'

type Props = {
    extension: Extension | undefined
}

const Filters: FC<Props> = ({ extension }) => {
    const [open, setOpen] = useState(false)
    const [openAccordionItem, setOpenAccordionItem] = useState<string[]>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const { filterState, setFilters, noOfFilters } =
        useBusinessObjectFilterState()

    const noOfFiltersApplied = useMemo(() => {
        const noOfActionFilters = Object.keys(
            convertSearchParamsToObject(searchParams)
        ).filter(
            key =>
                ![
                    'filterState',
                    'display',
                    'selectedFields',
                    'sort',
                    'grouping',
                ].includes(key)
        ).length

        return {
            noOfActionFilters,
            noOfBusinessObjectFilters: noOfFilters,
            totalFilters: noOfActionFilters + noOfFilters,
        }
    }, [searchParams, noOfFilters])

    const { data } = useProcesses_ActionsFilterQuery()

    const {
        buttonRef,
        open: extraFiltersOpen,
        setOpen: setExtraFiltersOpen,
        closeModal,
        returnFocus,
        openModal,
    } = useModalControls()

    return (
        <>
            <StyledFilterMenuButton
                $hasFilter={noOfFiltersApplied.totalFilters > 0}
                icon="Filter"
                text={
                    noOfFiltersApplied.totalFilters
                        ? `Filters (${noOfFiltersApplied.totalFilters})`
                        : 'Filters'
                }
                size="xSmall"
                onClick={() => setOpen(true)}
                variant="secondary"
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                fullScreen
                closeOnOverlayClick={false}
            >
                <Styled>
                    <header>
                        <Text as="h3" variant="bold-4">
                            Filter actions
                        </Text>
                    </header>
                    <div>
                        <Accordion.Root
                            type="multiple"
                            value={openAccordionItem}
                            onValueChange={setOpenAccordionItem}
                        >
                            {/* Base Filters */}
                            <ShowByFilterControls />
                            <ProcessFilterControls
                                options={
                                    data?.processes.map(process => ({
                                        text: process.name,
                                        value: process.id,
                                    })) ?? []
                                }
                            />
                            <BusinessObjectFilterControls />
                            <AssignedToFilterControls />
                            <CreatedByFilterControls />
                            <DueBeforeFilterControls />

                            {/* ExtensionFilters */}
                            {extension?.fields.filter(field =>
                                canFilterFieldType(field.type)
                            ).length ? (
                                <>
                                    <StyledExtraFiltersButton
                                        onClick={openModal}
                                        ref={buttonRef}
                                        text={
                                            noOfFiltersApplied.noOfBusinessObjectFilters
                                                ? `${noOfFiltersApplied.noOfBusinessObjectFilters} extra filters applied`
                                                : 'More filters'
                                        }
                                        icon="Filter"
                                        variant="secondary"
                                    />
                                    <ComparatorBuilderV2
                                        businessObjectDefinitionId={
                                            extension.id
                                        }
                                        onConfirm={setFilters}
                                        title={'More filters'}
                                        open={extraFiltersOpen}
                                        onOpenChange={setExtraFiltersOpen}
                                        returnFocus={returnFocus}
                                        onClearAll={() => {
                                            closeModal()
                                        }}
                                        initialComparators={Object.values(
                                            filterState
                                        ).flat()}
                                        allowedFilterTypes={[
                                            ValueSelectorType.FieldValue,
                                        ]}
                                    />
                                </>
                            ) : null}
                        </Accordion.Root>
                    </div>
                    <footer>
                        <IconTextButton
                            icon={'Close'}
                            text={'Clear all filters'}
                            variant="secondary"
                            size="xSmall"
                            onClick={() => {
                                setOpenAccordionItem([])
                                setSearchParams({})
                            }}
                            disabled={noOfFiltersApplied.totalFilters < 1}
                        />
                        <IconTextButton
                            icon="Check"
                            text="Done"
                            variant="primary"
                            size="xSmall"
                            onClick={() => setOpen(false)}
                        />
                    </footer>
                </Styled>
            </MinimalModal>
        </>
    )
}

const StyledFilterMenuButton = styled(IconTextButton)<{ $hasFilter: boolean }>`
    ${({ $hasFilter }) => $hasFilter && pipStyles};
`

const Styled = styled.div`
    padding: 0.5rem 4vw 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    > header {
        padding-bottom: 0.5rem;
    }

    > div {
        flex: 1;
        overflow: auto;
        width: 100%;
    }

    > footer {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding-top: 0.5rem;
    }

    > footer button {
        flex: 1;
    }
`

const StyledExtraFiltersButton = styled(IconTextButton)`
    margin-top: 1rem;
    width: 100%;
`

export { Filters }
