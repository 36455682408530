import { displays } from 'Features/ActionView/useActionsView'
import { FieldType } from '__generated__'
import { z } from 'zod'
import { ActionsQuery } from './Hooks/__generated__/query'

export type Action = ActionsQuery['actions'][number]

export type ActionType = 'CompletedAction' | 'IncompleteAction'

export type Extension =
    ActionsQuery['businessObjectDefinitionExtensions'][number]

export const ViewSchema = z.object({
    filterState: z.record(z.string(), z.any()).optional(),
    display: z.enum(displays).optional(),
    processId: z.string().optional(),
    businessObjectId: z.string().optional(),
    assignedTo: z.string().optional(),
    createdBy: z.string().optional(),
    dueBefore: z.string().datetime().optional(),
    'showBy[]': z
        .array(z.enum(['CompletedAction', 'IncompleteAction']))
        .optional(),
    noDueDate: z.string().optional(),
    grouping: z
        .object({
            fields: z.array(
                z.object({
                    id: z.string(),
                    label: z.string(),
                    type: z.nativeEnum(FieldType),
                    groups: z.array(
                        z.object({
                            id: z.string(),
                            value: z.string(),
                            color: z.string(),
                        })
                    ),
                })
            ),
            selection: z.string(),
        })
        .optional(),
})
