import * as Accordion from '@radix-ui/react-accordion'
import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Checkbox } from 'Components/Checkbox'
import { DatePicker } from 'Components/DatePicker'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Text } from 'Components/Text'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useCallback, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StyledItem, StyledTrigger } from './styles'

export const DueBeforeFilterControls = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const dueBefore = searchParams.get('dueBefore')
    const noDueDate = searchParams.get('noDueDate')

    const updateDueBeforeFilter = useCallback(
        (value: string) => {
            setSearchParams(prev => {
                const prevAsObject = convertSearchParamsToObject(prev)

                if (!value) {
                    const { dueBefore: _, ...rest } = prevAsObject
                    return rest
                }

                return { ...prevAsObject, dueBefore: value }
            })
        },
        [setSearchParams]
    )

    const [open, setOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <StyledItem value={'dueBefore'} key={'dueBefore'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Due Date:
                </Text>

                {dueBefore ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledCheckbox
                    checked={noDueDate === 'true' ? true : false}
                    htmlFor="noDueDate"
                    onCheckedChange={checked => {
                        if (checked) {
                            searchParams.set('noDueDate', 'true')
                            searchParams.delete('dueBefore')
                        } else {
                            searchParams.delete('noDueDate')
                        }
                        setSearchParams(searchParams)
                    }}
                    label="Only show no due date?"
                    name="noDueDate"
                />

                <StyledDateWrapper>
                    <StyledDateButton
                        icon={'Calendar'}
                        text={
                            !dueBefore
                                ? 'Due before'
                                : `Due before ${dayjs(dueBefore).format(
                                      'DD MMM YYYY'
                                  )}`
                        }
                        ref={buttonRef}
                        onClick={() => {
                            setOpen(true)
                        }}
                        variant="secondary"
                        disabled={noDueDate === 'true' ? true : false}
                    />

                    <DatePicker
                        open={open}
                        onOpenChange={setOpen}
                        title={'Due before'}
                        description={'Select a due before date'}
                        onConfirm={({ date }) => {
                            updateDueBeforeFilter(date.toISOString())
                            setOpen(false)
                        }}
                        returnFocus={() => {
                            buttonRef.current?.focus()
                        }}
                        selectedDate={
                            dueBefore ? new Date(dueBefore) : undefined
                        }
                    />

                    <StyledClearButton
                        iconName={'Close'}
                        title="clear"
                        onClick={() => {
                            searchParams.delete('noDueDate')
                            searchParams.delete('dueBefore')
                            setSearchParams(searchParams)
                        }}
                        variant="secondary"
                    />
                </StyledDateWrapper>
            </Accordion.Content>
        </StyledItem>
    )
}

const StyledDateWrapper = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;
    padding-bottom: 1rem;
    padding-right: 1rem;
`

const StyledDateButton = styled(IconTextButton)`
    width: 100%;
    font-weight: normal;
`

const StyledClearButton = styled(IconButton)`
    padding: 0.6728rem;
`

const StyledCheckbox = styled(Checkbox)`
    &:hover,
    &:active {
        background-color: inherit;
    }

    margin-bottom: 1rem;
`
