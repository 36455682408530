import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CardSettingsControl } from 'Components/CardSettingsControl'
import { Dropdown } from 'Components/Dropdown'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { FieldType } from '__generated__'
import { FC, useState } from 'react'

type Props = {
    fields: { id: string; label: string; type: FieldType }[]
}

export const CardSettings: FC<Props> = ({ fields }) => {
    const [open, setOpen] = useState(false)

    const { selectedFields, toggleSelectedField } = useBusinessObjectsView()

    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <IconTextButton
                    icon="Cards"
                    text={`Card settings ${
                        selectedFields.length
                            ? `(${selectedFields.length})`
                            : ''
                    }`}
                    size="xSmall"
                />
            )}
            renderContent={() => (
                <Styled
                    fields={fields}
                    selected={selectedFields}
                    onSelect={toggleSelectedField}
                    onDone={() => setOpen(false)}
                />
            )}
            align="start"
        />
    )
}

const Styled = styled(CardSettingsControl)`
    min-width: 20rem;
`
