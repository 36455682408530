import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
    fieldName: string
    addedValues: string[]
    removedValues: string[]
}

export const SelectFieldUpdatedHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    fieldName,
    addedValues,
    removedValues,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> updated the{' '}
                <strong>{fieldName}</strong> select field.
                {addedValues.length ? (
                    <>
                        {' '}
                        <strong>
                            {addedValues.map((value, i) => {
                                const maybeComma =
                                    i < addedValues.length - 1 ? ', ' : ' '
                                return value + maybeComma
                            })}
                        </strong>{' '}
                        added.
                    </>
                ) : null}
                {removedValues.length ? (
                    <>
                        {' '}
                        <strong>
                            {removedValues.map((value, i) => {
                                const maybeComma =
                                    i < removedValues.length - 1 ? ', ' : ' '
                                return value + maybeComma
                            })}
                        </strong>{' '}
                        removed.
                    </>
                ) : null}
            </Text>
        </BaseHistoryEntry>
    )
}
