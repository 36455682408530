import { ActionCompletionModal } from 'Features/ActionCompletionModal'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { FieldCompletionModal } from 'Features/FieldCompletionModal'
import { useResponder } from 'Hooks'
import { FC, useState } from 'react'
import { match } from 'ts-pattern'
import { Phases } from './Phases'
import { ViewBarLarge, ViewBarSmall } from './ViewBar'
import { ProcessDisplayFragment } from './__generated__/q'
import { useView } from './useView'

const ProcessDisplay: FC<{
    process: ProcessDisplayFragment
    latestViewKey: string
    boDisplayProps: ReturnType<typeof useBusinessObjectDisplay>
}> = ({ process, latestViewKey, boDisplayProps }) => {
    const { screenSize } = useResponder()
    const { config, display, dispatch } = useView({ process, latestViewKey })
    const [fieldCompletionBoId, setFieldCompletionBoId] = useState<string>()
    const [actionIds, setActionIds] = useState<string[]>()

    return (
        <>
            {match(screenSize)
                .with('large', () => (
                    <ViewBarLarge
                        process={process}
                        config={config}
                        dispatch={dispatch}
                    />
                ))
                .otherwise(() => (
                    <ViewBarSmall
                        process={process}
                        config={config}
                        dispatch={dispatch}
                    />
                ))}

            <Phases
                cardSettings={config.cardSettings}
                processId={process.id}
                display={display}
                cardInteractions={{
                    contentClick:
                        ({ id }) =>
                        () =>
                            boDisplayProps.open({ id, tab: 'overview' }),
                    requiredFieldsClick: obj => () =>
                        setFieldCompletionBoId(obj.id),
                    actionsClick: actionIds => setActionIds(actionIds),
                    subprocessesClick:
                        ({ id }) =>
                        () =>
                            boDisplayProps.open({ id, tab: 'overview' }),
                }}
            />

            <BusinessObjectDisplay
                {...boDisplayProps}
                businessObjectDefinitionId={process.operatesUpon[0].id}
            />

            <FieldCompletionModal
                processId={process.id}
                boId={fieldCompletionBoId}
                onClose={() => setFieldCompletionBoId(undefined)}
            />

            <ActionCompletionModal
                actionIds={actionIds}
                onClose={() => setActionIds(undefined)}
            />
        </>
    )
}

export { ProcessDisplay }
