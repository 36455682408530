import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    userName: string
    timestamp: string
    children: React.ReactNode
}

export const BaseHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    children,
}) => {
    return (
        <StyledHistoryEntry>
            <Avatar name={userName} variant="blue" noOfInitials={2} />
            <div className="content">
                {children}
                <Text as="p" variant="regular-6">
                    {generateTimeString(timestamp)}
                </Text>
            </div>
        </StyledHistoryEntry>
    )
}

function generateTimeString(timestamp: string) {
    return dayjs(timestamp).format('D MMM YYYY [at] h:mma')
}

const StyledHistoryEntry = styled.div`
    ${flex('row', 'flex-start', 'flex-start')};
    gap: 0.75rem;
    width: 100%;
    z-index: 2;

    .content {
        > p:first-of-type {
            padding-bottom: 0.25rem;
        }

        > p:last-of-type {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }
    }
`
