import { useState } from 'react'
import { useDeployTemplateMutation } from '../__generated__/mutation'

export type Props = {
    onError: () => void
    onDeploy: () => void
}

export const useDeployTemplate = ({ onDeploy, onError }: Props) => {
    const [isDeployed, setIsDeployed] = useState(false)

    const [mutate, { loading }] = useDeployTemplateMutation({
        onError,
        onCompleted: () => {
            setIsDeployed(true)
            onDeploy()
        },
        update: (cache, result) => {
            cache.modify({
                fields: {
                    processes(existing = []) {
                        return [
                            ...existing,
                            ...(result.data?.deployTemplate.processes ?? []),
                        ]
                    },
                    businessObjectDefinitions(existing = []) {
                        return [
                            ...existing,
                            ...(result.data?.deployTemplate
                                .businessObjectDefinitions ?? []),
                        ]
                    },
                },
            })
        },
        refetchQueries: ['OnboardingProgress'],
    })

    const deployTemplate = ({
        templateCategory,
        templates,
    }: {
        templateCategory: string
        templates: string[]
    }) => {
        return mutate({
            variables: {
                input: {
                    templateCategory,
                    templates,
                },
            },
        })
    }

    return {
        isDeployed,
        deployTemplate,
        loading,
    }
}
