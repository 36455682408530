import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { FC, useState } from 'react'
import { MenuButton } from '../../Small/MenuButton'
import { useSavedViews } from '../useSavedViews'
import { ManageViews } from './ManageViews'

type Props = {
    processId: string
}

const SavedViews: FC<Props> = ({ processId }) => {
    const [open, setOpen] = useState(false)
    const { savedViews, selectSavedView } = useSavedViews(processId)

    return (
        <>
            <StyledTrigger
                icon="View"
                text="Saved views"
                size="xSmall"
                disabled={!savedViews.length}
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled>
                    {savedViews.map(view => (
                        <MenuButton
                            key={view.id}
                            title={view.label}
                            icon="View"
                            onClick={() => {
                                selectSavedView(view)
                            }}
                        />
                    ))}
                    <footer>
                        <ManageViews views={savedViews} />
                        <IconTextButton
                            icon="Check"
                            text="Done"
                            size="xSmall"
                            variant="primary"
                            onClick={() => setOpen(false)}
                        />
                    </footer>
                </Styled>
            </MinimalModal>
        </>
    )
}

const StyledTrigger = styled(IconTextButton)`
    &:disabled {
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const Styled = styled.div`
    padding: 0.5rem 4vw 1rem;

    > footer {
        padding-top: 1rem;
        display: flex;
        gap: 1rem;
    }

    > footer button {
        flex: 1;
    }
`

export { SavedViews }
