import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { bold3 } from 'Components/Text'
import { FC, useState } from 'react'
import { BusinessObjectHeaderQuery } from '../__generated__/q'
import { DeleteBusinessObject } from './DeleteBusinessObject'
import { ToggleSubscription } from './ToggleSubscription'

type Props = {
    businessObject: BusinessObjectHeaderQuery['businessObject'] | undefined
    onDelete: () => void
    showDelete: boolean
}
const Small: FC<Props> = ({ businessObject, onDelete, showDelete }) => {
    const [open, setOpen] = useState(false)
    return (
        <Styled data-loading={!businessObject}>
            <h1>{businessObject?.label || '...'}</h1>
            <IconButton
                iconName="More"
                onClick={() => setOpen(true)}
                disabled={!businessObject}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                {businessObject ? (
                    <StyledMenu>
                        {showDelete ? (
                            <DeleteBusinessObject
                                id={businessObject.id}
                                canDelete={businessObject.canDelete}
                                onDelete={onDelete}
                            />
                        ) : null}
                        <ToggleSubscription id={businessObject.id} />
                    </StyledMenu>
                ) : null}
            </MinimalModal>
        </Styled>
    )
}

const Styled = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 4vw 1rem;
    gap: 1rem;

    h1 {
        ${bold3}
    }

    [data-loading='true'] h1 {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }
`

const StyledMenu = styled.menu`
    > button {
        width: 100%;
        justify-content: flex-start;
    }
`

export { Small }
