import Fuse from 'fuse.js'
import { useMemo } from 'react'

import { FilterableBusinessObject } from './types'
import { useSearchTerms } from './useSearchTerms'

/*
 * Leaving the following fields for now since they do not resolve to a (useful) string:
 * Number, Select, Relation, List
 */

export const useBusinessObjectFuzzySearch = <
    T extends FilterableBusinessObject
>(
    businessObjects: T[]
): { searchedBusinessObjects: T[] } => {
    const { searchTerms } = useSearchTerms()

    const searchedBusinessObjects = useMemo(() => {
        if (!searchTerms) return businessObjects

        const options = {
            includeMatches: true,
            keys: [
                {
                    name: 'label',
                    getFn: (businessObject: FilterableBusinessObject) =>
                        businessObject.label,
                },
                'fields.textValue',
                'fields.telephoneValue.number',
                'fields.telephoneValue.countryCode',
                'fields.urlValue',
                'fields.emailValue',
                'fields.userValue.name',
                'fields.dateValue',
                'fields.docValue.name',
            ],
            threshold: 0.3,
        }

        const fuse = new Fuse(businessObjects, options)

        const results = fuse.search(searchTerms)

        return results.map(result => result.item)
    }, [businessObjects, searchTerms])

    return { searchedBusinessObjects }
}
