import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOrgNameQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SetOrgNameQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } } | null } };


export const SetOrgNameDocument = gql`
    query SetOrgName {
  currentUser {
    id
    activeTenant {
      tenant {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSetOrgNameQuery__
 *
 * To run a query within a React component, call `useSetOrgNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetOrgNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetOrgNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetOrgNameQuery(baseOptions?: Apollo.QueryHookOptions<SetOrgNameQuery, SetOrgNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetOrgNameQuery, SetOrgNameQueryVariables>(SetOrgNameDocument, options);
      }
export function useSetOrgNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetOrgNameQuery, SetOrgNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetOrgNameQuery, SetOrgNameQueryVariables>(SetOrgNameDocument, options);
        }
export type SetOrgNameQueryHookResult = ReturnType<typeof useSetOrgNameQuery>;
export type SetOrgNameLazyQueryHookResult = ReturnType<typeof useSetOrgNameLazyQuery>;
export type SetOrgNameQueryResult = Apollo.QueryResult<SetOrgNameQuery, SetOrgNameQueryVariables>;