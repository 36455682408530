import { ControlledModal } from 'Components/Modal'
import React, { FC } from 'react'
import { DatePickerSingle } from './DatePickerSingle'
import { Limit } from './DatePickerSingle/types'

type Props = {
    open: boolean
    onOpenChange: (bool: boolean) => void
    title: string
    description: string
    hideDescription?: boolean
    selectedDate?: Date | undefined
    onConfirm: (props: {
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        date: Date
    }) => void
    returnFocus: () => void
    limit?: Limit
}

export const DatePicker: FC<Props> = ({
    open,
    onOpenChange,
    title,
    description,
    selectedDate,
    onConfirm,
    hideDescription = false,
    returnFocus,
    limit,
}) => {
    return (
        <ControlledModal
            renderContent={() => {
                return (
                    <DatePickerSingle
                        selected={selectedDate}
                        onConfirm={onConfirm}
                        limit={limit}
                    />
                )
            }}
            title={title}
            description={description}
            hideDescription={hideDescription}
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}
