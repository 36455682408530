import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject'
import { ValueSelectorType } from '__generated__'
import { noop } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { pipStyles } from './styles'

type Props = {
    businessObjectDefinitionId: string
}

export const Filters: FC<Props> = ({ businessObjectDefinitionId }) => {
    const { setFilters, filterState } = useBusinessObjectFilterState()

    const [open, setOpen] = useState(false)

    const hasFilters = useMemo(
        () => Object.values(filterState).flat().length > 0,
        [filterState]
    )

    return (
        <>
            <StyledFilterMenuButton
                $hasFilter={hasFilters}
                icon="Filter"
                text="Filter"
                onClick={() => setOpen(true)}
                size="xSmall"
                variant="secondary"
            />

            <ComparatorBuilderV2
                businessObjectDefinitionId={businessObjectDefinitionId}
                onConfirm={setFilters}
                title={'Filters'}
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
                onClearAll={() => {
                    setOpen(false)
                }}
                initialComparators={Object.values(filterState).flat()}
                allowedFilterTypes={[ValueSelectorType.FieldValue]}
            />
        </>
    )
}

const StyledFilterMenuButton = styled(IconTextButton)<{ $hasFilter: boolean }>`
    ${({ $hasFilter }) => $hasFilter && pipStyles};
`
