import { Value } from '@udecode/plate-common'
import { Attachment } from 'Components/Attachments'
import { useCallback, useState } from 'react'
import { setAt } from '../../Utils'
import { FieldChangeHandler } from '../BusinessObjectFieldset/types'
import { FieldType } from '../CreateBusinessObjectDef/types'

export type UpdateActionForm = {
    name: string
    assignedToId?: string
    description: string
    descriptionAttachments?: Attachment[]
    dueDate?: string
    extendedBy: {
        field: { id: string; type: FieldType }
        value: unknown
        initialValue: unknown
    }[]
}

type Params = {
    initialValues: UpdateActionForm
}

export const useForm = ({ initialValues }: Params) => {
    const [form, setForm] = useState<UpdateActionForm>(initialValues)

    const handleTextInputUpdate = useCallback(
        (key: keyof UpdateActionForm) =>
            (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                setForm(prev => ({
                    ...prev,
                    [key]: e.target.value,
                }))
            },
        []
    )

    const handleDescriptionUpdate = useCallback((description: Value) => {
        setForm(prev => ({
            ...prev,
            description: JSON.stringify(description),
        }))
    }, [])

    const handleSelectChange = useCallback((assignedTo: string) => {
        setForm(prev => ({
            ...prev,
            assignedToId: assignedTo,
        }))
    }, [])

    const handleDateChange = useCallback((date: Date) => {
        setForm(prev => ({ ...prev, dueDate: date.toISOString() }))
    }, [])

    const clearDateInput = useCallback(() => {
        setForm(prev => ({ ...prev, dueDate: '' }))
    }, [])

    const handleExtensionFieldChanged: FieldChangeHandler = field => value => {
        setForm(({ extendedBy, ...action }) => {
            const fieldIndex = extendedBy.findIndex(
                ({ field: { id } }) => field.id === id
            )

            if (fieldIndex < 0) {
                return { ...action, extendedBy }
            }

            return {
                ...action,
                extendedBy: setAt(extendedBy, fieldIndex, 'value', value),
            }
        })
    }

    const handleAttachmentsChanged = useCallback(
        (attachments: Attachment[]) => {
            setForm(prev => ({ ...prev, descriptionAttachments: attachments }))
        },
        []
    )

    return {
        form,
        handleDescriptionUpdate,
        handleTextInputUpdate,
        handleSelectChange,
        handleDateChange,
        clearDateInput,
        handleExtensionFieldChanged,
        handleAttachmentsChanged,
    }
}
