import { strings } from './strings'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const WEEKDAYS = [
    { label: strings.mondayLabel, tag: 'monday' },
    { label: strings.tuesdayLabel, tag: 'tuesday' },
    { label: strings.wednesdayLabel, tag: 'wednesday' },
    { label: strings.thursdayLabel, tag: 'thursday' },
    { label: strings.fridayLabel, tag: 'friday' },
    { label: strings.saturdayLabel, tag: 'saturday' },
    { label: strings.sundayLabel, tag: 'sunday' },
] as const

export const MONTHS = [
    { label: strings.janLabel, tag: 'jan' },
    { label: strings.febLabel, tag: 'feb' },
    { label: strings.marLabel, tag: 'mar' },
    { label: strings.aprLabel, tag: 'apr' },
    { label: strings.mayLabel, tag: 'may' },
    { label: strings.junLabel, tag: 'jun' },
    { label: strings.julLabel, tag: 'jul' },
    { label: strings.augLabel, tag: 'aug' },
    { label: strings.sepLabel, tag: 'sep' },
    { label: strings.octLabel, tag: 'oct' },
    { label: strings.novLabel, tag: 'nov' },
    { label: strings.decLabel, tag: 'dec' },
] as const
