import * as Types from '../../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionExtensionObjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActionExtensionObjectsQuery = { __typename?: 'Query', businessObjectDefinitionExtensions: Array<{ __typename?: 'BusinessObjectDefinitionExtension', id: string }> };


export const ActionExtensionObjectsDocument = gql`
    query ActionExtensionObjects {
  businessObjectDefinitionExtensions(input: {extension: action}) {
    id
  }
}
    `;

/**
 * __useActionExtensionObjectsQuery__
 *
 * To run a query within a React component, call `useActionExtensionObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionExtensionObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionExtensionObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionExtensionObjectsQuery(baseOptions?: Apollo.QueryHookOptions<ActionExtensionObjectsQuery, ActionExtensionObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionExtensionObjectsQuery, ActionExtensionObjectsQueryVariables>(ActionExtensionObjectsDocument, options);
      }
export function useActionExtensionObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionExtensionObjectsQuery, ActionExtensionObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionExtensionObjectsQuery, ActionExtensionObjectsQueryVariables>(ActionExtensionObjectsDocument, options);
        }
export type ActionExtensionObjectsQueryHookResult = ReturnType<typeof useActionExtensionObjectsQuery>;
export type ActionExtensionObjectsLazyQueryHookResult = ReturnType<typeof useActionExtensionObjectsLazyQuery>;
export type ActionExtensionObjectsQueryResult = Apollo.QueryResult<ActionExtensionObjectsQuery, ActionExtensionObjectsQueryVariables>;