import { PlateElement, PlateElementProps } from '@udecode/plate-common'
import { styled } from 'Adapters/Freestyled'

export function Link({ className, children, ...props }: PlateElementProps) {
    return (
        <PlateElement asChild className={className} {...props}>
            <StyledLink target="_blank" rel="noopener noreferrer external">
                {children}
            </StyledLink>
        </PlateElement>
    )
}

const StyledLink = styled.a`
    color: ${({ theme }) => theme.palette.text.support03.normal};

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
        text-decoration: underline;
    }

    &:active,
    &:focus-visible {
        color: ${({ theme }) => theme.palette.text.support03.active};
        text-decoration: underline;
    }
`
