import { richTextEmptyValue } from 'Components/RichText'

export const setInitialDescription = (
    description: string | undefined | null
) => {
    const empty = JSON.stringify(richTextEmptyValue)

    if (!description) {
        return empty
    }

    try {
        const parsed = JSON.parse(description)

        if (Array.isArray(parsed) && !parsed.length) {
            return empty
        }
    } catch (e) {
        return empty
    }

    return description
}
