import { useCallback } from 'react'

type Params = {
    url: string
    onSuccess?: () => void
}

export const useTriggerDownload = ({ onSuccess, url }: Params) => {
    const triggerDownload = useCallback(() => {
        window.open(url, '_blank')
        onSuccess?.()
    }, [onSuccess, url])

    return { triggerDownload }
}
