import { FetchBusinessObjectDefinitionByIdQuery } from 'Root/App/Main/BusinessObjects/Hooks/__generated__/queries'
import { z } from 'zod'

export type FieldDefinitions = NonNullable<
    FetchBusinessObjectDefinitionByIdQuery['businessObjectDefinition']
>['fields']

export type FieldDefinition = FieldDefinitions[number]

export type FieldDefinitionType = FieldDefinition['__typename']

export type SpecificFieldDefinition<T extends FieldDefinitionType> = Extract<
    FieldDefinition,
    { __typename: T }
>

export type BooleanFieldDefinition =
    SpecificFieldDefinition<'BooleanFieldDefinition'>

export type SelectFieldDefinition =
    SpecificFieldDefinition<'SelectFieldDefinition'>

export type NumberFieldDefinition =
    SpecificFieldDefinition<'NumberFieldDefinition'>

export type DateFieldDefinition = SpecificFieldDefinition<'DateFieldDefinition'>

export type UserFieldDefinition = SpecificFieldDefinition<'UserFieldDefinition'>

export type RelationFieldDefinition =
    SpecificFieldDefinition<'RelationFieldDefinition'>

export type CurrencyFieldDefinition =
    SpecificFieldDefinition<'CurrencyFieldDefinition'>

export const ViewSchema = z.object({
    filterState: z.record(z.string(), z.any()),
    display: z.enum(['table', 'card']),
    selectedFields: z.array(z.string()),
    sort: z.tuple([z.string(), z.enum(['asc', 'desc'])]),
    grouping: z.optional(
        z.object({ selection: z.string(), fields: z.array(z.any()) })
    ),
})

export type View = z.infer<typeof ViewSchema>
