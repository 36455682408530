import { Content, Trigger } from '@radix-ui/react-accordion'
import { breakpoints, css, flex, styled } from 'Adapters/Freestyled'
import { keyframes } from 'styled-components'

export const StyledWhatWeWillBuild = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;

    .columns {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 2rem;

        .column {
            ${flex('column', 'flex-start', 'flex-start')};
            gap: 1rem;

            .no-build {
                color: ${({ theme }) => theme.palette.text['02'].normal};
            }
        }
    }

    @media screen and (min-width: ${breakpoints.medium}px) {
        padding-top: 2rem;

        .columns {
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const cardsLargeStyles = css`
    ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;

        li {
            max-width: none;
            width: 100%;
        }
    }
`

const cardsSmallStyles = css`
    padding-bottom: 1rem;

    ul {
        ${flex('column', 'flex-start', 'center')};
        gap: 1rem;
        padding: 0;

        li {
            max-width: none;
            width: 100%;
        }
    }
`

export const StyledCards = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) => ($isLarge ? cardsLargeStyles : cardsSmallStyles)};
    width: 100%;
`

export const StyledTrigger = styled(Trigger)`
    ${flex('row', 'center', 'center')};
    width: 100%;
    cursor: pointer;
    gap: 1rem;
    padding: 0;

    > span {
        ${flex('row', 'flex-start', 'center')};
        display: block;
    }

    > .icon {
        padding-right: 1rem;

        svg {
            transition: transform
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
        }
    }

    &[data-state='open'] > .icon svg {
        transform: rotateZ(180deg);
    }

    @media screen and (${breakpoints.medium}px) {
        ${flex('row', 'flex-start', 'center')};
    }
`

const slideDown = keyframes`
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
`

const slideUp = keyframes`
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
`

export const StyledContent = styled(Content)`
    overflow: hidden;
    padding-top: 1rem;

    &[data-state='open'] {
        animation: ${slideDown} ${({ theme }) => theme.animation.mid} ease;
    }

    &[data-state='closed'] {
        animation: ${slideUp} ${({ theme }) => theme.animation.mid} ease;
    }
`
