import { useEffect, useState } from 'react'
import { calculateResponse } from './utils'

export const useResponder = () => {
    const [response, setResponse] = useState(calculateResponse())

    useEffect(() => {
        const updateResponse = () => {
            setResponse(calculateResponse())
        }

        window.addEventListener('resize-x-end', updateResponse)

        return () => {
            window.removeEventListener('resize-x-end', updateResponse)
        }
    }, [])

    return response
}
