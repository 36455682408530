import { useTriggerToast } from 'Components/Toast'
import { ActionPhase } from '__generated__'
import { useCallback } from 'react'
import { match } from 'ts-pattern'
import { ActionTimelineCardQuery } from '../__generated__/query'
import { useToggleActionMutation } from './__generated__/query'

type UseToggleAction = (_: { action: ActionTimelineCardQuery['action'] }) => {
    loading: boolean
    toggleAction: () => void
    toast: {
        open: boolean
        onOpenChange: (open: boolean) => void
    }
}
export const useToggleAction: UseToggleAction = ({ action }) => {
    const { open, setOpen, triggerToast } = useTriggerToast()
    const [update, { loading }] = useToggleActionMutation()

    const toggleAction = useCallback(() => {
        const { phase, before, after } = match(action)
            .with({ __typename: 'CompletedAction' }, () => ({
                phase: ActionPhase.Open,
                before: 'CompletedAction',
                after: 'IncompleteAction',
            }))
            .otherwise(() => ({
                phase: ActionPhase.Closed,
                before: 'IncompleteAction',
                after: 'CompletedAction',
            }))
        update({
            variables: {
                input: { id: action.id, phase },
            },
            onError: () => triggerToast(),
            update: cache => {
                cache.modify({
                    id: cache.identify({
                        __typename: before,
                        id: action.id,
                    }),
                    fields: {
                        __typename: () => after,
                    },
                })
            },
            onQueryUpdated: q => q.refetch(),
        })
    }, [triggerToast, update, action])

    return { toggleAction, loading, toast: { open, onOpenChange: setOpen } }
}
