import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Comparator, ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { DeepFieldSelector } from 'Components/DeepFieldSelector'
import { ControlledModal } from 'Components/Modal'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { useRequireableFields } from 'Hooks/useRequireableFields'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'
import { useProcessEditor } from '../../useProcessEditor'
import { StyledComparatorBuilderButton } from './styles'

type Props = {
    onSave: (fieldId: string, conditions: Comparator[]) => void
    open: boolean
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    businessObjectDefinitionId: string
    phaseIndex: number
}

export const AddFieldConditionCriterionModal: FC<Props> = ({
    onSave,
    open,
    onOpenChange,
    returnFocus,
    businessObjectDefinitionId,
    phaseIndex,
}) => {
    const {
        state: { process },
    } = useProcessEditor()

    const [fieldId, setFieldId] = useState('')

    const [conditions, setConditions] = useState<Comparator[]>([])

    const { current: inputId } = useRef(v4())

    const handleSubmit = useCallback(() => {
        onSave(fieldId, conditions)
        onOpenChange(false)
        setFieldId('')
    }, [fieldId, onOpenChange, onSave, conditions])

    const { availableFields, availableDeepFields, loading } =
        useRequireableFields({
            businessObjectDefinitionId,
        })

    // Remove fields already used in this phase for field condition criteria
    const availableUnusedFields = useMemo(
        () =>
            availableFields?.filter(
                field =>
                    !process.phases[phaseIndex].criteria.some(
                        criteria =>
                            criteria.__typename ===
                                'FieldConditionPhaseCriterion' &&
                            criteria.comparator.valueSelector.__typename ===
                                'FieldValueSelector' &&
                            criteria.comparator.valueSelector.fieldId ===
                                field.id
                    )
            ),
        [availableFields, phaseIndex, process]
    )

    const isValid = fieldId.length > 0

    const {
        buttonRef,
        open: comparatorBuilderIsOpen,
        openModal,
        setOpen,
        returnFocus: returnFocusToComparatorBuilderButton,
    } = useModalControls()

    return (
        <ControlledModal
            renderContent={() => (
                <StyledModalContent>
                    <InputGroup>
                        {match({
                            loading,
                            availableUnusedFields,
                            availableDeepFields,
                        })
                            .with(
                                {
                                    availableUnusedFields: P.not(P.nullish),
                                    availableDeepFields: P.not(P.nullish),
                                },
                                ({
                                    availableUnusedFields,
                                    availableDeepFields,
                                }) => (
                                    <>
                                        <label htmlFor={inputId}>
                                            Required field
                                        </label>
                                        <DeepFieldSelector
                                            id={inputId}
                                            onFieldChanged={setFieldId}
                                            fields={availableUnusedFields}
                                            deepFields={availableDeepFields}
                                            fieldId={fieldId}
                                        />
                                    </>
                                )
                            )
                            .with({ loading: true }, () => <CenteredSpinner />)
                            .otherwise(() => (
                                <Text as="p" variant="regular-4">
                                    An error occurred fetching available fields
                                </Text>
                            ))}
                    </InputGroup>

                    <StyledComparatorBuilderButton
                        icon={'Plus'}
                        text={
                            !conditions.length
                                ? 'Set conditions'
                                : `${conditions.length} condition(s) set`
                        }
                        size="small"
                        variant="secondary"
                        onClick={openModal}
                        ref={buttonRef}
                    />

                    <ComparatorBuilderV2
                        initialComparators={conditions}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                        onConfirm={comparators => setConditions(comparators)}
                        title={'Add conditions'}
                        open={comparatorBuilderIsOpen}
                        onOpenChange={setOpen}
                        returnFocus={returnFocusToComparatorBuilderButton}
                    />

                    <Buttons>
                        <TextButton
                            text={'Cancel'}
                            onClick={() => onOpenChange(false)}
                            size="small"
                            variant="secondary"
                        />

                        <TextButton
                            text="Create"
                            disabled={!isValid}
                            onClick={handleSubmit}
                            size="small"
                        />
                    </Buttons>
                </StyledModalContent>
            )}
            title="Add required field criterion"
            description="Add criterion that a field must be defined"
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;
`

const InputGroup = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;

    label {
        padding-bottom: 0.25rem;
    }
`

const Buttons = styled.div`
    ${flex('row', 'flex-start', 'center')};
    width: 100%;
    gap: 1rem;

    > button {
        flex: 1;
    }
`
