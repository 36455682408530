import { Select } from 'Components/Select'
import { useState } from 'react'
import { fieldLabels } from 'Utils'
import { SelectOption } from '../../../../Components/Select/types'
import { blankField } from '../../helpers'
import { Fields, ListableFields, ListableFieldType } from '../../types'
import { CreateBusinessObjectDefFields } from '../CreateBusinessObjectDefFields'
import { FieldCommonWrapper } from './Common'
import { Field } from './Field'
import { FieldProps } from './types'

const { list, updates, ...listableFieldLabels } = fieldLabels

export const ListFieldDefinitionInput: React.FC<FieldProps<'list'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
}) => {
    const [listFieldType, setListFieldType] = useState(
        field.listOf?.type as ListableFieldType | undefined
    )

    const fieldTypeOptions: SelectOption<ListableFieldType>[] = Object.entries(
        listableFieldLabels
    ).map(([value, text]) => ({ text, value: value as ListableFieldType }))

    const handleFieldTypeSelected = (type: ListableFieldType) => {
        onChange('listOf')(() => blankField(type) as ListableFields)
        setListFieldType(type)
    }

    const listField = listFieldType && (field.listOf as Fields)

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-list-${index}`} name="List type">
                <Select
                    id={`bod-field-list-${index}`}
                    name={`bod-field-list-${index}`}
                    options={fieldTypeOptions}
                    value={listFieldType}
                    onValueChange={handleFieldTypeSelected}
                />
            </Field>

            {listField && (
                <CreateBusinessObjectDefFields
                    field={listField}
                    index={-1}
                    disableRequiredConstraint={true}
                    onFieldChange={index => prop => setter => {
                        const prev = (field.listOf as any)[prop]

                        const newValue =
                            typeof setter === 'function' ? setter(prev) : setter

                        onChange('listOf')(state => ({
                            ...state,
                            [prop]: newValue,
                        }))
                    }}
                />
            )}
        </FieldCommonWrapper>
    )
}
