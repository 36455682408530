import { styled } from 'Adapters/Freestyled'
import { Toggle } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { InputWrapper } from '../InputWrapper'

type Props = {
    checked: boolean
    onCheckedChange: (checked: boolean) => void
    displayOnly: boolean
    hidden: boolean
}

const RequiredField: FC<Props> = ({
    checked,
    onCheckedChange,
    displayOnly,
    hidden,
}) => {
    const { current: fieldId } = useRef(v4())
    return hidden ? null : (
        <InputWrapper
            label="Required field"
            fieldId={fieldId}
            description="If set this field will be required when creating a new record."
            displayOnly={displayOnly}
        >
            <div>
                {displayOnly ? (
                    <StyledDisplayOnly>
                        <Text as="p" variant="bold-4">
                            {checked ? 'Yes' : 'No'}
                        </Text>
                    </StyledDisplayOnly>
                ) : (
                    <Toggle
                        id={fieldId}
                        checked={checked}
                        onCheckedChange={onCheckedChange}
                        indicatorText={['Yes', 'No']}
                    />
                )}
            </div>
        </InputWrapper>
    )
}

const StyledDisplayOnly = styled.div`
    color: ${({ theme }) => theme.palette.text['support03'].normal};
`

export { RequiredField }
