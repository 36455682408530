import { useCallback, useState } from 'react'
import { ViewMode } from '../types'

export const useViewMode = () => {
    const [viewMode, setViewMode] = useState<ViewMode>('grid')

    const changeViewMode = useCallback((viewMode: ViewMode) => {
        setViewMode(viewMode)
    }, [])

    return { viewMode, changeViewMode }
}
