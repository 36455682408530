// In this file we take data from the BusinessObjectDefinitionsCreator queries,
// which collect existing definitions and templates, and we sanitise them into a
// format that is coinsistent with the input type of the createBusinessObjectDefinition mutation.
// By doing this upfront, we simplify the state management in the rest of the features's lifecycle.

// It would probably be a good idea to create a dedicated query for the source data of this feature
// that returns data in a consistent way, and to shift all of this complexity to the back end.
// Not a job for this delivery though...

// Some field specfic notes:
//   - Some template fields have ids but most do not. I have omited them to simplify type definition wrangling,
//     as they are not used in the mutation input, but it is possible all fields should have them to ensure the
//     cached data is managed correctly.
//   - Relation fields have been omitted from templates
//   - Updates fields have been omitted entirely as the the inclusion of an update field will be managed by a boolean in state
//     and the mutation input will be augmented during validation if true
//   - Default values have been omitted from User fields on templates
//   - Default values have been omitted from Date fields on templates as they do not share the same format as the input

import { BizObjectDef_CompleteFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { Template_BizObjDefFragment } from 'Fragments/__generated__/BusinessObjectDefinitionTemplate'
import { FieldType as GeneratedFieldType } from '__generated__'
import { compact } from 'lodash'
import { match } from 'ts-pattern'
import { v4 } from 'uuid'
import { Definition, Field, FieldType, ListField } from '../types'
import {
    sanitiseBooleanField,
    sanitiseCurrencyField,
    sanitiseDateField,
    sanitiseDocumentField,
    sanitiseEmailField,
    sanitiseNumberField,
    sanitiseRelationField,
    sanitiseSelectField,
    sanitiseTelephoneField,
    sanitiseTextField,
    sanitiseURLField,
    sanitiseUserField,
} from './fields'

const filterFields = <
    T extends (
        | Template_BizObjDefFragment
        | BizObjectDef_CompleteFragment
    )['fields']
>(
    fields: T
): {
    filteredFields: T
    enableUpdates: boolean
    enableDocuments: boolean
} => {
    const updatesFiltered = fields.filter(f =>
        match(f)
            .with(
                {
                    type: GeneratedFieldType.List,
                    listOf: { type: GeneratedFieldType.Updates },
                },
                () => false
            )
            .otherwise(() => true)
    )
    const filteredFields = updatesFiltered.filter(f =>
        match(f)
            .with(
                {
                    type: FieldType.List,
                    listOf: { type: FieldType.Document },
                    name: 'Documents',
                },
                () => false
            )
            .otherwise(() => true)
    )

    return {
        filteredFields: filteredFields as T,
        enableUpdates: updatesFiltered.length !== fields.length,
        enableDocuments: filteredFields.length !== updatesFiltered.length,
    }
}

type SanitiseTemplateSourceData = (_: Template_BizObjDefFragment) => {
    definition: Definition
    enableUpdates: boolean
    enableDocuments: boolean
}
const sanitiseTemplateSourceData: SanitiseTemplateSourceData = ({
    name,
    label,
    description,
    fields,
}) => {
    const { filteredFields, enableUpdates, enableDocuments } =
        filterFields(fields)
    return {
        definition: {
            id: v4(),
            name,
            label,
            description: description ?? '',
            fields: compact(filteredFields.map(sanatiseTemplateField)),
        },
        enableUpdates,
        enableDocuments,
    }
}

export const sanatiseTemplateField = (
    sourceField: Template_BizObjDefFragment['fields'][number]
): Field | undefined => {
    const field: Field | undefined = match({ id: v4(), ...sourceField })
        .with(
            { __typename: 'TextFieldDefinitionTemplate' },
            sanitiseTextField(false)
        )
        .with(
            { __typename: 'BooleanFieldDefinitionTemplate' },
            sanitiseBooleanField(false)
        )
        .with(
            { __typename: 'CurrencyFieldDefinitionTemplate' },
            sanitiseCurrencyField(false)
        )
        .with(
            { __typename: 'DateFieldDefinitionTemplate' },
            sanitiseDateField(false)
        )
        .with(
            { __typename: 'DocumentFieldDefinitionTemplate' },
            sanitiseDocumentField(false)
        )
        .with(
            { __typename: 'EmailFieldDefinitionTemplate' },
            sanitiseEmailField(false)
        )
        .with(
            { __typename: 'NumberFieldDefinitionTemplate' },
            sanitiseNumberField(false)
        )
        .with({ __typename: 'SelectFieldDefinitionTemplate' }, select =>
            sanitiseSelectField(false)({
                ...select,
                options: select.options.map(o => ({
                    id: v4(),
                    locked: false,
                    ...o,
                })),
            })
        )
        .with(
            { __typename: 'TelephoneFieldDefinitionTemplate' },
            sanitiseTelephoneField(false)
        )
        .with(
            { __typename: 'URLFieldDefinitionTemplate' },
            sanitiseURLField(false)
        )
        .with({ __typename: 'UserFieldDefinitionTemplate' }, user =>
            sanitiseUserField(false)({
                ...user,
                defaultValue: undefined,
            })
        )
        .with(
            { __typename: 'ListFieldDefinitionTemplate' },
            ({ name, description, listOf: sourceListOf }) => {
                const listOf = match({ ...sourceListOf, id: v4() })
                    .with(
                        { __typename: 'TextFieldDefinitionTemplate' },
                        sanitiseTextField(false)
                    )
                    .with(
                        { __typename: 'BooleanFieldDefinitionTemplate' },
                        bool =>
                            sanitiseBooleanField(false)({
                                ...bool,
                                defaultValue: false,
                            })
                    )
                    .with(
                        { __typename: 'CurrencyFieldDefinitionTemplate' },
                        curr =>
                            sanitiseCurrencyField(false)({
                                ...curr,
                                constraints: [],
                            }) // Why are we losing constraints here?
                    )
                    .with(
                        { __typename: 'DateFieldDefinitionTemplate' },
                        sanitiseDateField(false)
                    )
                    .with(
                        { __typename: 'DocumentFieldDefinitionTemplate' },
                        sanitiseDocumentField(false)
                    )
                    .with(
                        { __typename: 'EmailFieldDefinitionTemplate' },
                        sanitiseEmailField(false)
                    )
                    .with(
                        { __typename: 'NumberFieldDefinitionTemplate' },
                        sanitiseNumberField(false)
                    )
                    .with(
                        { __typename: 'SelectFieldDefinitionTemplate' },
                        select =>
                            sanitiseSelectField(false)({
                                ...select,
                                options: select.options.map(o => ({
                                    id: v4(),
                                    locked: false,
                                    ...o,
                                })),
                            })
                    )
                    .with(
                        { __typename: 'TelephoneFieldDefinitionTemplate' },
                        sanitiseTelephoneField(false)
                    )
                    .with(
                        { __typename: 'URLFieldDefinitionTemplate' },
                        sanitiseURLField(false)
                    )
                    .with({ __typename: 'UserFieldDefinitionTemplate' }, user =>
                        sanitiseUserField(false)({
                            ...user,
                            defaultValue: undefined,
                        })
                    )
                    .otherwise(() => undefined)

                const list: ListField | undefined = listOf && {
                    type: FieldType.List,
                    id: v4(),
                    name,
                    description: description ?? '',
                    listOf,
                    constraintsLocked: false,
                }

                return list
            }
        )
        .otherwise(() => undefined)

    return field
}

type SanitiseBizObjectDef_CompleteFragmentSourceData = (lock?: {
    fieldConstraints: boolean
    selectOptions: boolean
    enabledUpdates: boolean
    enabledDocuments: boolean
}) => (_: BizObjectDef_CompleteFragment) => {
    definition: Definition
    enableUpdates: boolean
    updatesLocked: boolean
    enableDocuments: boolean
    documentsLocked: boolean
}

const defaultLock = {
    fieldConstraints: true,
    selectOptions: true,
    enabledUpdates: true,
    enabledDocuments: true,
}
const sanitiseExistingDefinitionSourceData: SanitiseBizObjectDef_CompleteFragmentSourceData =

        (lock = defaultLock) =>
        ({ id, name, label, description, fields }) => {
            const { filteredFields, enableUpdates, enableDocuments } =
                filterFields(fields)

            return {
                definition: {
                    id,
                    name,
                    label,
                    description: description ?? '',
                    fields: compact(
                        filteredFields.map(sanitiseExistingField(lock))
                    ),
                },
                enableUpdates,
                updatesLocked: lock.enabledUpdates && enableUpdates,
                enableDocuments,
                documentsLocked: lock.enabledDocuments && enableDocuments,
            }
        }

const sanitiseExistingField =
    (lock: { fieldConstraints: boolean; selectOptions: boolean }) =>
    (
        sourceField: BizObjectDef_CompleteFragment['fields'][number]
    ): Field | undefined => {
        const field = match(sourceField)
            .with(
                { __typename: 'TextFieldDefinition' },
                ({
                    textConstraints: constraints,
                    textDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseTextField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'BooleanFieldDefinition' },
                ({ booleanDefaultValue: defaultValue, ...rest }) =>
                    sanitiseBooleanField(lock.fieldConstraints)({
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'CurrencyFieldDefinition' },
                ({
                    currencyDefaultValue: defaultValue,
                    currencyConstraints: constraints,
                    ...rest
                }) =>
                    sanitiseCurrencyField(lock.fieldConstraints)({
                        defaultValue,
                        constraints,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'DateFieldDefinition' },
                ({
                    dateConstraints: constraints,
                    datePrecision: precision,
                    dateDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseDateField(lock.fieldConstraints)({
                        constraints,
                        precision,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'DocumentFieldDefinition' },
                ({ docConstraints: constraints, ...rest }) =>
                    sanitiseDocumentField(lock.fieldConstraints)({
                        constraints,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'EmailFieldDefinition' },
                ({
                    emailConstraints: constraints,
                    emailDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseEmailField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'NumberFieldDefinition' },
                ({
                    numConstraints: constraints,
                    numDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseNumberField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'SelectFieldDefinition' },
                ({
                    selectConstraints: constraints,
                    selectDefaultValue: defaultValue,
                    selectOptions,
                    ...rest
                }) =>
                    sanitiseSelectField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        options: selectOptions.map(o => ({
                            locked: lock.selectOptions,
                            ...o,
                        })),
                        ...rest,
                    })
            )
            .with(
                { __typename: 'TelephoneFieldDefinition' },
                ({
                    telephoneConstraints: constraints,
                    telephoneDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseTelephoneField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'URLFieldDefinition' },
                ({
                    urlConstraints: constraints,
                    urlDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseURLField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'UserFieldDefinition' },
                ({
                    userConstraints: constraints,
                    userDefaultValue: defaultValue,
                    ...rest
                }) =>
                    sanitiseUserField(lock.fieldConstraints)({
                        constraints,
                        defaultValue,
                        ...rest,
                    })
            )
            .with(
                { __typename: 'ListFieldDefinition' },
                ({ id, name, description, listOf: sourceListOf }) => {
                    const listOf = match(sourceListOf)
                        .with(
                            { __typename: 'TextFieldDefinition' },
                            ({ textConstraints: constraints, ...rest }) =>
                                sanitiseTextField(lock.fieldConstraints)({
                                    constraints,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'BooleanFieldDefinition' },
                            ({ booleanDefaultValue: defaultValue, ...rest }) =>
                                sanitiseBooleanField(lock.fieldConstraints)({
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'CurrencyFieldDefinition' },
                            ({
                                currencyDefaultValue: defaultValue,
                                currencyConstraints: constraints,
                                ...rest
                            }) =>
                                sanitiseCurrencyField(lock.fieldConstraints)({
                                    defaultValue,
                                    constraints,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'DocumentFieldDefinition' },
                            ({ docConstraints: constraints, ...rest }) =>
                                sanitiseDocumentField(lock.fieldConstraints)({
                                    constraints,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'DateFieldDefinition' },
                            ({
                                dateConstraints: constraints,
                                datePrecision: precision,
                                ...rest
                            }) =>
                                sanitiseDateField(lock.fieldConstraints)({
                                    constraints,
                                    precision,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'EmailFieldDefinition' },
                            ({
                                emailConstraints: constraints,
                                emailDefaultValue: defaultValue,
                                ...rest
                            }) =>
                                sanitiseEmailField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'NumberFieldDefinition' },
                            ({
                                numConstraints: constraints,
                                numDefaultValue: defaultValue,
                                ...rest
                            }) =>
                                sanitiseNumberField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'SelectFieldDefinition' },
                            ({
                                selectConstraints: constraints,
                                selectDefaultValue: defaultValue,
                                selectOptions,
                                ...rest
                            }) =>
                                sanitiseSelectField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    options: selectOptions.map(o => ({
                                        locked: lock.selectOptions,
                                        ...o,
                                    })),
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'TelephoneFieldDefinition' },
                            ({
                                telephoneConstraints: constraints,
                                telephoneDefaultValue: defaultValue,
                                ...rest
                            }) =>
                                sanitiseTelephoneField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'URLFieldDefinition' },
                            ({
                                urlConstraints: constraints,
                                urlDefaultValue: defaultValue,
                                ...rest
                            }) =>
                                sanitiseURLField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .with(
                            { __typename: 'UserFieldDefinition' },
                            ({
                                userConstraints: constraints,
                                userDefaultValue: defaultValue,
                                ...rest
                            }) =>
                                sanitiseUserField(lock.fieldConstraints)({
                                    constraints,
                                    defaultValue,
                                    ...rest,
                                })
                        )
                        .otherwise(() => undefined)

                    const list: ListField | undefined = listOf && {
                        type: FieldType.List,
                        id,
                        name,
                        description: description ?? '',
                        listOf,
                        constraintsLocked: lock.fieldConstraints,
                    }

                    return list
                }
            )
            .with(
                { __typename: 'RelationFieldDefinition' },
                ({ relationConstraints: constraints, ...rest }) =>
                    sanitiseRelationField(lock.fieldConstraints)({
                        constraints,
                        ...rest,
                    })
            )
            .otherwise(() => undefined)
        return field
    }

export { sanitiseExistingDefinitionSourceData, sanitiseTemplateSourceData }
