import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { BusinessObjectDefinitionCreator } from 'Features/BusinessObjectDefinitionCreator'
import { FC } from 'react'

const NoDefinitions: FC = () => (
    <Styled>
        <div>
            <Text as="h2" variant="bold-0">
                Datasets
            </Text>
            <Text as="p" variant="regular-2-spaced">
                When your team share a clear understanding of the way your
                business operates, communication and collaboration are simple.
            </Text>
            <Text as="p" variant="regular-2-spaced">
                Here you can create definitions for your datasets that are
                tailored perfectly to your use case.
            </Text>
            <footer>
                <BusinessObjectDefinitionCreator
                    buttonSize="small"
                    buttonVariant="secondary"
                />
            </footer>
        </div>
    </Styled>
)

const Styled = styled.section`
    position: relative;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 1rem;
    padding: 4rem;
    color: ${({ theme }) => theme.palette.text['04'].normal};
    background-color: ${({ theme }) => theme.palette.ui['07'].normal};

    &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 60%;
        background-size: cover;
        background-position: center;
        background-image: linear-gradient(
                90deg,
                ${({ theme }) => theme.palette.ui['07'].normal},
                ${({ theme }) => theme.palette.ui['07'].normal}00
            ),
            url('/images/dataset-card.jpg');
    }

    > div {
        position: relative;
        z-index: 1;
        max-width: 70ch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1.5rem;
    }
    > div > footer {
        padding-top: 0.75rem;
    }
`

export { NoDefinitions }
