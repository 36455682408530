import { ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { ProcessDisplayFragment } from 'Features/ProcessDisplay/__generated__/q'
import { useModalControls } from 'Hooks'
import { ValueSelectorType } from '__generated__'
import { noop } from 'lodash'
import { FC, useMemo } from 'react'
import { P, match } from 'ts-pattern'
import { DispatchAction, ViewConfig } from '../../../types'
import { MenuButton } from '../MenuButton'

type Props = {
    process: ProcessDisplayFragment
    config: ViewConfig
    dispatch: DispatchAction
}

export const Filters: FC<Props> = ({ dispatch, config, process }) => {
    const { open, setOpen, openModal, closeModal } = useModalControls()

    const filterCount = useMemo(
        () =>
            Object.values(config.filters).reduce(
                (out, comparator) =>
                    match(comparator)
                        .with(P.array(P._), c => out + c.length)
                        .with(P.not(P.nullish), () => out + 1)
                        .exhaustive(),
                0
            ),
        [config.filters]
    )

    return (
        <>
            <MenuButton
                icon="Filter"
                title={filterCount ? `Filters (${filterCount})` : 'Filters'}
                onClick={openModal}
            />

            <ComparatorBuilderV2
                businessObjectDefinitionId={process.operatesUpon[0].id}
                onConfirm={comparators => {
                    dispatch({
                        type: 'SetFilters',
                        payload: { comparators },
                    })
                }}
                title={'Filter records'}
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
                onClearAll={() => {
                    dispatch({ type: 'ClearAllFilters', payload: undefined })
                    closeModal()
                }}
                initialComparators={Object.values(config.filters).flat()}
                allowedFilterTypes={[ValueSelectorType.FieldValue]}
            />
        </>
    )
}
