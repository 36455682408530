import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'

export const CanEditSnackbar = () => {
    return (
        <StyledCanEditSnackbar>
            <Icon name="ExclamationTriangle" />{' '}
            <p>
                Templates are not fixed. You will be able to customise them to
                your liking once deployed.
            </p>
        </StyledCanEditSnackbar>
    )
}

const StyledCanEditSnackbar = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    align-items: center;
    padding: 0.25rem 0.75rem;
    box-shadow: 0 0 0.5rem -0.25rem ${({ theme }) => theme.palette.ui['07'].normal};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.support.bg03.normal};
    border: 1px solid ${({ theme }) => theme.palette.support['03'].normal};
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    box-shadow: none;

    > .icon svg {
        width: 1rem;
        height: 1rem;
        min-width: 1rem;
        min-height: 1rem;
    }

    > .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }

    > p {
        flex: 1;
    }
`
