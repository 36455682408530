import { styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { CenteredSpinner } from 'Components/Spinner'
import { compact } from 'lodash'
import { pipe } from 'lodash/fp'
import { FC, useCallback, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { TextButton } from '../../../../Components/Button'
import { preventDefault } from '../../../../Utils'
import { useProcessCreator } from '../../useProcessCreator'
import { useCopyable_ProcessesQuery } from './__generated__/query'

type Props = {
    onCopied: () => void
    businessObjectDefinitionId: string
}
export const CopyExistingModal: FC<Props> = ({
    onCopied,
    businessObjectDefinitionId,
}) => {
    const { data, loading } = useCopyable_ProcessesQuery({
        variables: { operatesUpon: businessObjectDefinitionId },
    })

    const [selectedProcess, setSelectedProcess] = useState<string | null>(null)

    const options = useMemo(() => {
        return [
            // Because the main use case is probably a controlled form, we can pass a 'placeholder' like so
            {
                disabled: true,
                text: '-- Select an existing workflow --',
                value: '',
            },
            // The regular options
            ...(data?.processes ?? []).map(process => ({
                text: process.name,
                value: process.id,
                disabled: false,
            })),
        ]
    }, [data])

    const {
        dispatch,
        state: { input },
    } = useProcessCreator()

    const handleProcessCopied = useCallback(() => {
        const process = data?.processes.find(p => p.id === selectedProcess)

        if (!process) return

        dispatch({
            type: 'suggestionApplied',
            payload: {
                name: input.name,
                phases: process.phases.map(phase => ({
                    name: phase.name,
                    description: phase.description,
                    criteria: compact(
                        phase.criteria.map(criterion =>
                            match(criterion)
                                .with(
                                    { __typename: 'ActionPhaseCriterion' },
                                    criterion => ({
                                        action: {
                                            description: criterion.description,
                                            ...(criterion.defaultDueSeconds && {
                                                defaultDueSeconds:
                                                    criterion.defaultDueSeconds,
                                            }),
                                            ...(criterion.purpose && {
                                                purpose: criterion.purpose,
                                            }),
                                        },
                                    })
                                )
                                .with(
                                    {
                                        __typename:
                                            'FieldConditionPhaseCriterion',
                                        comparator: {
                                            valueSelector: {
                                                __typename:
                                                    'FieldValueSelector',
                                            },
                                        },
                                    },
                                    criterion => ({
                                        fieldCondition: {
                                            comparator: {
                                                operator:
                                                    criterion.comparator
                                                        .operator,
                                                negate: criterion.comparator
                                                    .negate,
                                                with: criterion.comparator.with,
                                                valueSelector: {
                                                    fieldValue: {
                                                        fieldId:
                                                            criterion.comparator
                                                                .valueSelector
                                                                .fieldId,
                                                    },
                                                },
                                            },
                                        },
                                    })
                                )
                                .otherwise(() => null)
                        )
                    ),
                })),
            },
        })
        onCopied()
    }, [data?.processes, dispatch, input.name, onCopied, selectedProcess])

    if (loading) {
        return <CenteredSpinner />
    }

    return (
        <StyledForm onSubmit={pipe(preventDefault, handleProcessCopied)}>
            <Select
                id="select-existing-process"
                name="Select existing process"
                value={selectedProcess ?? ''}
                onValueChange={setSelectedProcess}
                options={options}
            />

            <TextButton text="Copy workflow" disabled={!selectedProcess} />
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 40rem;
`
