import { css, styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { verticalAlignWithinCell } from './cssHelpers'

export const StyledTableWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`

const hideExtraBorder = css`
    &:nth-last-of-type(2) {
        border-right: 1px solid transparent;
    }
`

export const StyledTable = styled.table<{ $includeMenuCell: boolean }>`
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 100%;

    thead {
        position: sticky;
        top: 0;
        z-index: 2;

        tr {
            th {
                ${regular5};
                text-align: left;
                padding: 0.688rem 1.25rem;
                background-color: white;
                white-space: nowrap;
                border-right: 1px dashed
                    ${({ theme }) => theme.palette.ui['03'].normal};

                &:nth-last-of-type(1) {
                    border-right: 1px solid transparent;
                }

                ${props => (props.$includeMenuCell ? hideExtraBorder : '')}

                // Simulates border-bottom
                    &::after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            th:has(button) {
                padding: 0 0.5rem 0.125rem;
            }
        }
    }

    tbody {
        z-index: 1;

        tr td {
            ${regular5};
            padding: 1rem 1.25rem;
            position: relative;
        }

        tr {
            td {
                border-right: 1px dashed
                    ${({ theme }) => theme.palette.ui['03'].normal};

                &:nth-last-of-type(1) {
                    border-right: 1px solid transparent;
                }

                ${props => (props.$includeMenuCell ? hideExtraBorder : '')}
            }
        }

        tr:first-child td {
            padding-top: 1.5rem;
        }
    }
`

export const StyledMenuCell = styled.th`
    width: 4.5rem;
    padding-right: ${({ theme }) => theme.spacing['05']};
    padding-left: ${({ theme }) => theme.spacing['03']};

    > button {
        ${verticalAlignWithinCell};
        tr:first-child td {
            padding-top: 1.5rem;
        }
    }
`
