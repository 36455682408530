import { Group } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { useTriggerDownload } from 'Components/Documents'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { RenameDocumentModal } from 'Features/RenameDocumentModal'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { Document } from './types'

type Props = {
    document: Document
}

export const OverflowMenu: FC<Props> = ({ document: { id, name, url } }) => {
    const {
        open: dropdownOpen,
        setOpen: setDropdownOpen,
        closeModal: closeDropdown,
    } = useModalControls()

    const {
        open: editOpen,
        setOpen: setEditOpen,
        openModal: openEditModal,
    } = useModalControls()

    const { triggerDownload } = useTriggerDownload({
        url,
        onSuccess: closeDropdown,
    })

    return (
        <Dropdown
            variant="dark"
            open={dropdownOpen}
            onOpenChange={setDropdownOpen}
            align="end"
            side="bottom"
            renderOpenDropdownButton={() => (
                <StyledIconButton iconName={'More'} variant="default" />
            )}
            renderContent={() => (
                <Group>
                    <DropdownButton
                        text={'Download document'}
                        icon={'ArrowDown'}
                        onSelect={triggerDownload}
                    />

                    <DropdownButton
                        text={'Rename document'}
                        onSelect={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            openEditModal()
                        }}
                        icon="Edit"
                    />

                    <RenameDocumentModal
                        documentId={id}
                        initialDocumentName={name}
                        open={editOpen}
                        onOpenChange={setEditOpen}
                    />
                </Group>
            )}
        />
    )
}

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
`
