import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2/ComparatorBuilder'
import { Text } from 'Components/Text'
import { useProcessCreator } from 'Features/ProcessCreator/useProcessCreator'
import { useModalControls } from 'Hooks'
import { FC, useMemo } from 'react'
import {
    determineInitialComparators,
    mapComparatorToInputComparator,
} from './helpers'

type Props = {
    phaseIndex: number
    criterionIndex: number
}

export const Conditions: FC<Props> = ({ phaseIndex, criterionIndex }) => {
    const {
        dispatch,
        state: { input },
    } = useProcessCreator()

    const { buttonRef, open, openModal, setOpen, returnFocus } =
        useModalControls()

    const conditions = useMemo(() => {
        const criterion = input.phases[phaseIndex].criteria[criterionIndex]

        if (criterion.action) {
            return determineInitialComparators(
                criterion.action.conditions ?? []
            )
        }

        if (criterion.fieldCondition) {
            return determineInitialComparators(
                criterion.fieldCondition.conditions ?? []
            )
        }

        if (criterion.processFanout) {
            return determineInitialComparators(
                criterion.processFanout.conditions ?? []
            )
        }

        if (criterion.processDelegate) {
            return determineInitialComparators(
                criterion.processDelegate.conditions ?? []
            )
        }

        return []
    }, [input, criterionIndex, phaseIndex])

    return (
        <StyledDefaultDueTime>
            <Text as="p" variant="regular-6">
                Phase criteria conditions:
            </Text>

            <IconTextButton
                icon={
                    conditions.length
                        ? 'CheckboxTickedInverted'
                        : 'CheckboxUnticked'
                }
                text={
                    conditions.length
                        ? `${conditions.length} conditions set`
                        : 'Set conditions'
                }
                variant="secondary"
                ref={buttonRef}
                onClick={openModal}
            />

            <ComparatorBuilderV2
                businessObjectDefinitionId={input.operatesUpon[0]}
                initialComparators={conditions}
                onConfirm={comparators => {
                    dispatch({
                        type: 'applyCriterionConditions',
                        payload: {
                            phaseIndex,
                            criterionIndex,
                            conditions: comparators.map(
                                mapComparatorToInputComparator
                            ),
                        },
                    })
                }}
                title={'Set conditions'}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </StyledDefaultDueTime>
    )
}

const StyledDefaultDueTime = styled.div`
    ${flex('column', 'flex-start', 'flex-start')}
    border: none;
    padding: 0 0.5rem 0.5rem;

    p {
        padding-bottom: 0.25rem;
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    > button {
        width: 100%;
    }
`
