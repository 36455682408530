import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Route as BusinessObjectRoute } from '../../types'
import { BusinessObject } from './BusinessObject'
import { BusinessObjectsNav } from './BusinessObjectsNav'

type Props = {
    routes: BusinessObjectRoute[]
}

const Desktop: FC<Props> = ({ routes }) => (
    <StyledBusinessObjectsDesktop>
        <BusinessObjectsNav routes={routes} />
        <Routes>
            {routes.map(route => (
                <Route
                    key={route.id}
                    path={`/${route.slug}/:id?/*`}
                    element={
                        <BusinessObject
                            name={route.name}
                            businessObjectDefinitionId={route.id}
                            description={route.description}
                        />
                    }
                />
            ))}
            <Route path="/" element={<Navigate to={routes[0].slug} />} />

            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    </StyledBusinessObjectsDesktop>
)

const StyledBusinessObjectsDesktop = styled.div`
    display: grid;
    grid-template: minmax(0, 1fr) / auto minmax(0, 1fr);
`

export { Desktop }
