import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Comparator, ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { NumberInput, TextInput } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { fromEvent } from 'Utils'
import { TimeUnit, convertToSeconds } from 'Utils/timeConversions'
import { FC, useCallback, useState } from 'react'
import { StyledComparatorBuilderButton } from './styles'

type Props = {
    onSave: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[]
    ) => void
    open: boolean
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    businessObjectDefinitionId: string
}

export const AddActionCriterionModal: FC<Props> = ({
    onSave,
    open,
    onOpenChange,
    returnFocus,
    businessObjectDefinitionId,
}) => {
    const [description, setDescription] = useState('')

    const [dueTime, setDueTime] = useState<{
        number: string
        unit: TimeUnit
    }>({ number: '', unit: 'day' })

    const [conditions, setConditions] = useState<Comparator[]>([])

    const handleSubmit = useCallback(() => {
        const newDefaultDueTime = dueTime.number
            ? convertToSeconds(Number(dueTime.number), dueTime.unit)
            : undefined

        onSave(description, newDefaultDueTime, conditions)
        onOpenChange(false)
    }, [description, dueTime, onOpenChange, onSave, conditions])

    const isValid = description.length > 0

    const {
        buttonRef,
        open: comparatorBuilderIsOpen,
        openModal,
        setOpen,
        returnFocus: returnFocusToComparatorBuilderButton,
    } = useModalControls()

    return (
        <ControlledModal
            renderContent={() => (
                <StyledModalContent>
                    <div className="input-group">
                        <label htmlFor="criterion-description">
                            Description:
                        </label>

                        <TextInput
                            id="criterion-description"
                            value={description}
                            onChange={e => setDescription(fromEvent(e))}
                        />
                    </div>

                    <StyledDefaultDueTime>
                        <Text as="legend" variant="regular-4">
                            Default due time:
                        </Text>

                        <NumberInput
                            className="number-input"
                            value={dueTime.number}
                            onChange={e =>
                                setDueTime(prev => ({
                                    ...prev,
                                    number: fromEvent(e),
                                }))
                            }
                        />

                        <Select
                            id={'timeUnit'}
                            onValueChange={unit =>
                                setDueTime(prev => ({ ...prev, unit }))
                            }
                            name={'timeUnit'}
                            options={[
                                { text: 'hour(s)', value: 'hour' },
                                { text: 'day(s)', value: 'day' },
                                { text: 'week(s)', value: 'week' },
                            ]}
                            value={dueTime.unit}
                        />
                    </StyledDefaultDueTime>

                    <StyledComparatorBuilderButton
                        icon={'Plus'}
                        text={
                            !conditions.length
                                ? 'Set conditions'
                                : `${conditions.length} condition(s) set`
                        }
                        size="small"
                        variant="secondary"
                        onClick={openModal}
                        ref={buttonRef}
                    />

                    <ComparatorBuilderV2
                        initialComparators={conditions}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                        onConfirm={comparators => setConditions(comparators)}
                        title={'Add conditions'}
                        open={comparatorBuilderIsOpen}
                        onOpenChange={setOpen}
                        returnFocus={returnFocusToComparatorBuilderButton}
                    />

                    <div className="buttons">
                        <TextButton
                            text={'Cancel'}
                            onClick={() => onOpenChange(false)}
                            size="small"
                            variant="secondary"
                        />

                        <TextButton
                            text="Create"
                            disabled={!isValid}
                            onClick={handleSubmit}
                            size="small"
                        />
                    </div>
                </StyledModalContent>
            )}
            title="Add action criterion"
            description="Add criterion description and/or due time"
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;

    .message {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 1rem;
    }

    .input-group {
        ${flex('column', 'flex-start', 'flex-start')};
        width: 100%;

        label {
            padding-bottom: 0.25rem;
        }
    }

    .buttons {
        ${flex('row', 'flex-start', 'center')};
        width: 100%;
        gap: 1rem;

        > button {
            flex: 1;
        }
    }
`

const StyledDefaultDueTime = styled.fieldset`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
    border: none;
    padding: 0;
    width: 100%;

    legend {
        padding-bottom: 0.25rem;
    }

    .number-input {
        min-height: 38px;
        width: 50%;
    }

    .select-trigger {
        max-height: 42px;
    }
`
