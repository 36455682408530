import { RadioGroup } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Dropdown, DropdownRadio } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'

type Props = {
    options: { id: string; value: string }[]
    selections: string[]
    setSelections: Dispatch<SetStateAction<string[]>>
}

export const Radios: FC<Props> = ({ options, selections, setSelections }) => {
    const selectedOption = useMemo(() => {
        return options.find(option => selections[0] === option.id)?.value ?? ''
    }, [options, selections])

    return (
        <Dropdown
            renderContent={() => (
                <RadioGroup>
                    {options.map(option => (
                        <DropdownRadio
                            key={option.id}
                            text={option.value}
                            value={selections[0]}
                            isSelected={selections[0] === option.id}
                            onSelect={() => {
                                setSelections([option.id])
                            }}
                            closeOnSelect={true}
                        />
                    ))}
                </RadioGroup>
            )}
            renderOpenDropdownButton={() =>
                selectedOption ? (
                    <StyledOpenDropdownButtonIcon
                        icon={'CircleCheck'}
                        text={selectedOption}
                    />
                ) : (
                    <StyledOpenDropdownButton
                        text={'Select an option (required)'}
                        variant="secondary"
                    />
                )
            }
            variant="light"
        />
    )
}

const buttonStyles = css`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledOpenDropdownButton = styled(TextButton)`
    ${buttonStyles}
`

const StyledOpenDropdownButtonIcon = styled(IconTextButton)`
    ${buttonStyles}
`
