import { BizObjectDef_CompleteFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { documentToggleFieldListOfName, has } from 'Utils'
import { FieldConstraint, FieldType } from '__generated__'
import { isNull, isUndefined, omit } from 'lodash'
import { useCallback, useState } from 'react'
import { match } from 'ts-pattern'
import { FieldState, Fields, State } from './types'

type Params = {
    setHasStarted: React.Dispatch<React.SetStateAction<boolean>>
    setState: React.Dispatch<React.SetStateAction<State>>
    resetState: () => void
}

export const useExistingDefinition = ({
    setHasStarted,
    setState,
    resetState,
}: Params) => {
    const [selectedExisting, setExisting] = useState<{ id: string }>()

    const handleExistingSelected = useCallback(
        (selectedExisting: BizObjectDef_CompleteFragment | undefined) => {
            if (!selectedExisting) {
                setExisting(undefined)
                return resetState()
            }

            setHasStarted(true)

            setExisting({ id: selectedExisting.id })

            setState({
                name: selectedExisting.name,
                description: selectedExisting.description,
                label: selectedExisting.label,
                fields: selectedExisting.fields
                    .filter(field => {
                        if (field.__typename !== 'ListFieldDefinition')
                            return true

                        /*
                         * Toggleable fields filtered out so user can
                         * choose to apply them or not to new definition
                         */
                        if (
                            field.listOf.__typename ===
                                'DocumentFieldDefinition' &&
                            field.listOf.name === documentToggleFieldListOfName
                        )
                            return false

                        if (
                            field.listOf.__typename === 'UpdatesFieldDefinition'
                        )
                            return false

                        return true
                    })
                    .map(stataliseField),
            })
        },
        [resetState, setHasStarted, setState, setExisting]
    )

    return { selectedExisting, setExisting, handleExistingSelected }
}

const stataliseField = (
    field:
        | BizObjectDef_CompleteFragment['fields'][number]
        | Extract<
              BizObjectDef_CompleteFragment['fields'][number],
              { __typename: 'ListFieldDefinition' }
          >['listOf']
): Fields => {
    return {
        name: field.name,
        description: field.description,
        ...match(field)
            .with({ __typename: 'ListFieldDefinition' }, field => ({
                type: FieldType.List as const,
                listOf: has(field, 'listOf')
                    ? stataliseField(field.listOf)
                    : undefined,
            }))
            .with({ __typename: 'BooleanFieldDefinition' }, () => ({
                type: FieldType.Boolean as const,
            }))
            .with({ __typename: 'DateFieldDefinition' }, field => ({
                type: FieldType.Date as const,
                constraints: field.dateConstraints.map(statalizeConstraint),
                precision: field.datePrecision,
                defaultValue: field.dateDefaultValue
                    ? omit(field.dateDefaultValue, '__typename')
                    : undefined,
            }))
            .with({ __typename: 'EmailFieldDefinition' }, field => ({
                type: FieldType.Email as const,
                constraints: field.emailConstraints.map(statalizeConstraint),
                defaultValue: field.emailDefaultValue,
            }))
            .with({ __typename: 'CurrencyFieldDefinition' }, field => ({
                type: FieldType.Currency as const,
                constraints: field.currencyConstraints.map(statalizeConstraint),
                defaultValue:
                    isSet(field.currencyDefaultValue) &&
                    isSet(field.currencyDefaultValue?.amount) &&
                    isSet(field.currencyDefaultValue?.currencyCode)
                        ? {
                              amount: field.currencyDefaultValue.amount,
                              currencyCode:
                                  field.currencyDefaultValue.currencyCode,
                          }
                        : undefined,
            }))
            .with({ __typename: 'DocumentFieldDefinition' }, () => ({
                type: FieldType.List as const,
            }))
            .with({ __typename: 'NumberFieldDefinition' }, field => ({
                type: FieldType.Document as const,
                constraints: field.numConstraints.map(statalizeConstraint),
                defaultValue: field.numDefaultValue,
            }))
            .with({ __typename: 'RelationFieldDefinition' }, field => {
                const constraints = field.relationConstraints.map<
                    FieldState<'relation'>['constraints'][number]
                >(constraint => {
                    return match(constraint)
                        .with({ __typename: 'RelationTypeConstraint' }, c => ({
                            relationType: {
                                types: [c.types[0].id],
                            },
                        }))
                        .with({ __typename: 'RequiredConstraint' }, c => ({
                            require: omit(c, ['__typename', 'type']),
                        }))
                        .with({ __typename: undefined }, () => ({}))
                        .exhaustive()
                })

                return {
                    type: FieldType.Relation as const,
                    constraints,
                }
            })
            .with({ __typename: 'SelectFieldDefinition' }, field => ({
                type: FieldType.Select as const,
                options: field.selectOptions.map(option => ({
                    value: option.value,
                })),
                defaultValue: undefined,
                constraints: field.selectConstraints.map(statalizeConstraint),
            }))
            .with({ __typename: 'TelephoneFieldDefinition' }, field => ({
                type: FieldType.Telephone as const,
                constraints:
                    field.telephoneConstraints.map(statalizeConstraint),
                defaultValue: omit(field.telephoneDefaultValue, '__typename'),
            }))
            .with({ __typename: 'TextFieldDefinition' }, field => ({
                type: FieldType.Text as const,
                constraints: field.textConstraints.map(statalizeConstraint),
                defaultValue: field.textDefaultValue,
            }))
            .with({ __typename: 'URLFieldDefinition' }, field => ({
                type: FieldType.Url as const,
                constraints: field.urlConstraints.map(statalizeConstraint),
                defaultValue: field.urlDefaultValue,
            }))

            .with({ __typename: 'UpdatesFieldDefinition' }, () => ({
                type: FieldType.Updates as const,
            }))
            .with({ __typename: 'UserFieldDefinition' }, field => ({
                type: FieldType.User as const,
                constraints: field.userConstraints.map(statalizeConstraint),
                defaultValue: omit(field.userDefaultValue, '__typename'),
            }))
            .exhaustive(),
    } as Fields
}

const statalizeConstraint = (constraint: FieldConstraint) => ({
    [constraint.type]: omit(constraint, ['__typename', 'type']),
})

const isSet = <T>(val: T | null | undefined): val is T =>
    !(isUndefined(val) || isNull(val))
