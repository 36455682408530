import { useAuthenticate } from 'Adapters/Authentication/useAuthenticate'
import { styled } from 'Adapters/Freestyled'
import { useSandbox } from 'Hooks/useSandbox'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { ChecklistItem } from './ChecklistItem'

export const UpgradeFromSandbox: FC = () => {
    const { signup } = useAuthenticate()

    const { pathname } = useLocation()

    const sandbox = useSandbox()

    return (
        <ChecklistItem stepId="upgrade-from-sandbox">
            {sandbox.state === 'active' ? (
                <StyledLinkLikeButton
                    onClick={() => {
                        signup({
                            returnTo: `/sandbox-signup?id=${sandbox.user.id}&token=${sandbox.user.token}&pathname=${pathname}`,
                        })
                    }}
                >
                    Register to save your work
                </StyledLinkLikeButton>
            ) : null}
        </ChecklistItem>
    )
}

const StyledLinkLikeButton = styled.button`
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    text-decoration: underline;
    padding: 0;
`
