import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { BusinessObjectDefinitionCard } from 'Features/BusinessObjectDefinitionCard'
import { ProcessCard } from 'Features/ProcessCard/ProcessCard'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { TextButton } from '../../Components/Button'
import { BusinessObjectDefinitionDisplayQuery } from './__generated__/q'

type Props = {
    definition: NonNullable<
        BusinessObjectDefinitionDisplayQuery['businessObjectDefinition']
    >
}

const Usage: FC<Props> = ({ definition }) => {
    const navigate = useNavigate()
    return (
        <Styled>
            <header>
                <Text as="h3" variant="bold-4">
                    Usage
                </Text>
                <Text as="p" variant="regular-5">
                    {definition.usage.instanceCount} records
                </Text>
            </header>
            <section>
                <header>
                    <Text as="h4" variant="bold-4">
                        Workflows
                    </Text>
                    <Text as="p" variant="regular-5">
                        This dataset is used in the following workflows:
                    </Text>
                </header>
                <StyledCards>
                    {definition.usage.processes.map(process => (
                        <li key={process.id}>
                            <StyledProcessCard
                                process={process}
                                onClick={() => {
                                    navigate(
                                        `/manage-workspace/processes/${slugify(
                                            process.name,
                                            {
                                                lower: true,
                                            }
                                        )}`
                                    )
                                }}
                            />
                        </li>
                    ))}
                </StyledCards>
                <TextButton
                    variant="ghost"
                    text="Create workflow with this dataset"
                    onClick={() => {
                        navigate(
                            `/manage-workspace/processes?creator=open&step=phases&preselectedBO=${definition.id}`
                        )
                    }}
                />
            </section>
            <section>
                <header>
                    <Text as="h3" variant="bold-4">
                        Relations
                    </Text>
                    <Text as="p" variant="regular-5">
                        This dataset has been related to the following datasets:
                    </Text>
                </header>
                <StyledCards>
                    {definition.usage.relations.map(d =>
                        match(d)
                            .with(
                                { __typename: 'BusinessObjectDefinition' },
                                def => (
                                    <li key={def.id}>
                                        <StyledDefinitionCard
                                            def={def}
                                            onClick={() => {
                                                navigate(
                                                    `/manage-workspace/datasets/${slugify(
                                                        def.name,
                                                        {
                                                            lower: true,
                                                        }
                                                    )}`
                                                )
                                            }}
                                        />
                                    </li>
                                )
                            )
                            .otherwise(() => null)
                    )}
                </StyledCards>
            </section>
        </Styled>
    )
}

const Styled = styled.div`
    padding: 2rem 2rem 0;
    overflow: auto;
    height: 100%;
    max-width: 27rem;
    background-image: linear-gradient(
        175deg,
        ${({ theme }) => theme.palette.ui['02'].normal},
        ${({ theme }) => theme.palette.ui['03'].normal}
    );

    > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

    > section {
        padding: 1.75rem 0;
        border-top: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    }

    > header p,
    > section > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > section > header {
        padding-bottom: 0.75rem;
    }
`

const StyledCards = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledProcessCard = styled(ProcessCard)`
    border-color: ${({ theme }) => theme.palette.ui['04'].normal};
`

const StyledDefinitionCard = styled(BusinessObjectDefinitionCard)`
    border-color: ${({ theme }) => theme.palette.ui['04'].normal};
`

export { Usage }
