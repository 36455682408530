import { Select } from 'Components/Select'
import { Field } from '../../Field'
import { usePossibleRelationTypesQuery } from './__generated__/query'

type RelationTypeConstraintValue = {
    relationType?: { types: string[] } | null
}

type Props = {
    index: number
    onChange: (constraint: RelationTypeConstraintValue | undefined) => void
    value: RelationTypeConstraintValue | undefined
}

const noneSelected = {
    value: 'NOT_SELECTED',
    text: '--- Select a dataset ---',
    disabled: true,
}

export const RelationTypeConstraint: React.FC<Props> = ({
    index,
    onChange,
    value,
}) => {
    const { data, loading } = usePossibleRelationTypesQuery()

    const options = [
        noneSelected,
        ...(data?.businessObjectDefinitions.map(({ id, name }) => ({
            value: id,
            text: name,
        })) ?? []),
    ]

    const selectedValue = value?.relationType?.types?.[0] ?? options[0]?.value

    return (
        <Field htmlFor={`bod-field-relationtype-${index}`} name="Relation">
            {data && !loading && (
                <Select
                    id={`bod-field-relationtype-${index}`}
                    name={`bod-field-relationtype-${index}`}
                    options={options}
                    value={selectedValue}
                    onValueChange={value =>
                        onChange({ relationType: { types: [value] } })
                    }
                />
            )}
        </Field>
    )
}
