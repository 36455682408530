export type NumberConstraintsMap = {
    required: boolean
    min: undefined | number
    max: undefined | number
}

export const defaultConstraints = {
    required: false,
    min: undefined,
    max: undefined,
} satisfies NumberConstraintsMap
