import { Text } from 'Components/Text'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC } from 'react'
import { DisplayFields } from './DisplayFields'
import { StyledCard, StyledContent } from './styles'

type Props = {
    businessObject: BusinessObjectTableItemFragment
    openDrawer: (id: string) => void
}

export const Card: FC<Props> = ({ businessObject, openDrawer }) => {
    return (
        <StyledCard>
            <StyledContent onClick={() => openDrawer(businessObject.id)}>
                <header>
                    <Text as="h4" variant="bold-4">
                        {businessObject.label}
                    </Text>
                </header>
                <DisplayFields obj={businessObject} />
            </StyledContent>
        </StyledCard>
    )
}
