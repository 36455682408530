const getInitial = (name: string): string => {
    return name.match(/[a-z]/i)?.[0]?.toUpperCase() ?? ''
}

export const generateInitials = (
    name: string,
    noOfInitials: number
): string => {
    return name
        .trim()
        .split(' ')
        .slice(0, noOfInitials)
        .map(getInitial)
        .join('')
}
