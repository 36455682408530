import { logger } from 'Adapters/Logger'
import { TextButton } from 'Components/Button'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { FC } from 'react'
import { useRemoveUserFromTenantMutation } from './__generated__/q'

type Props = { userId: string }

export const RemoveUserButton: FC<Props> = ({ userId }) => {
    const { triggerToast, open, setOpen } = useTriggerToast()
    const [mutate, data] = useRemoveUserFromTenantMutation({
        variables: { input: { userId } },
        update: cache => {
            cache.evict({
                id: cache.identify({
                    __typename: 'User',
                    id: userId,
                }),
            })
        },
        onError(e) {
            logger.error('Failed to remove user from tenant', e)
            triggerToast()
        },
    })

    const { status } = useMutationStatus(data)

    const text = {
        ready: 'Remove from tenant',
        loading: 'Removing...',
        error: 'Removing failed',
        success: 'Removed',
    }[status]

    return (
        <>
            <TextButton
                variant="secondary"
                size="xSmall"
                text={text}
                disabled={data.loading}
                onClick={() => mutate()}
            />

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description={
                    'We were unable to remove the user. Contact us if the problem persists'
                }
            />
        </>
    )
}
