import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CardSettingsControl } from 'Components/CardSettingsControl'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { BizObjectDefFieldsFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { pipStyles } from './styles'

type Props = {
    fields: BizObjectDefFieldsFragment['fields']
}

export const CardSettings: FC<Props> = ({ fields }) => {
    const [open, setOpen] = useState(false)

    const { selectedFields, toggleSelectedField } = useBusinessObjectsView()

    return (
        <>
            <StyledCardSettingsMenuButton
                $hasSelections={selectedFields.length > 0}
                icon="Cards"
                text={`Card settings ${
                    selectedFields.length ? `(${selectedFields.length})` : ''
                }`}
                size="xSmall"
                variant="secondary"
                onClick={() => setOpen(true)}
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                <VerticalScrollArea height="100%" width="100%">
                    <StyledCardSettings>
                        <CardSettingsControl
                            fields={fields.map(({ id, name, type }) => ({
                                id,
                                label: name,
                                type,
                            }))}
                            selected={selectedFields}
                            onSelect={toggleSelectedField}
                            onDone={() => setOpen(false)}
                        />
                    </StyledCardSettings>
                </VerticalScrollArea>
            </MinimalModal>
        </>
    )
}

const StyledCardSettings = styled.div`
    width: 100%;
    padding: 1rem;
`

const StyledCardSettingsMenuButton = styled(IconTextButton)<{
    $hasSelections: boolean
}>`
    ${({ $hasSelections }) => $hasSelections && pipStyles};
`
