export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Duration: string;
  Json: string;
};

export type Action = {
  assignedTo?: Maybe<Principal>;
  canDelete: CanDeleteAction;
  createdAt: Scalars['Date'];
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  descriptionAttachments?: Maybe<Array<File>>;
  dueDate?: Maybe<Scalars['Date']>;
  extendedBy?: Maybe<BusinessObjectExtension>;
  id: Scalars['ID'];
  name: Scalars['String'];
  relatesTo?: Maybe<ActionRelatesTo>;
  subscribed: Scalars['Boolean'];
};

export type ActionExecution = {
  __typename?: 'ActionExecution';
  result: Scalars['Json'];
};

export type ActionExecutionInput = {
  name: Scalars['String'];
  params: Scalars['Json'];
  provider: Scalars['String'];
};

export enum ActionPhase {
  Closed = 'closed',
  Open = 'open'
}

export type ActionPhaseCriterion = PhaseCriterion & {
  __typename?: 'ActionPhaseCriterion';
  businessObjectOnCriterion?: Maybe<BusinessObjectOnPhaseCriterion>;
  conditions?: Maybe<Array<BusinessObjectComparator>>;
  defaultAssignee?: Maybe<Principal>;
  defaultDueSeconds?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  purpose?: Maybe<Scalars['String']>;
  type: PhaseCriterionType;
};


export type ActionPhaseCriterionBusinessObjectOnCriterionArgs = {
  input: BusinessObjectOnPhaseCriterionInput;
};

export type ActionRelatesTo = {
  type: ActionRelation;
};

export enum ActionRelation {
  BusinessObject = 'businessObject',
  PhaseCriterion = 'phaseCriterion'
}

export type AddBusinessObjectToProcessInput = {
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type AddBusinessObjectToProcessWebhook = WebhookAction & {
  __typename?: 'AddBusinessObjectToProcessWebhook';
  payload: AddBusinessObjectToProcessWebhookPayload;
  type: WebhookActionType;
};

export type AddBusinessObjectToProcessWebhookPayload = {
  __typename?: 'AddBusinessObjectToProcessWebhookPayload';
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type AddBusinessObjectsToProcessInput = {
  businessObjectIds: Array<Scalars['ID']>;
  processId: Scalars['ID'];
};

export type AddableToProcessInput = {
  includeRemoved: Scalars['Boolean'];
  processId: Scalars['ID'];
};

export type AddedToProcessPayload = {
  __typename?: 'AddedToProcessPayload';
  process: Process;
};

export enum AttachmentType {
  File = 'file'
}

export type AvailableTemplate = {
  __typename?: 'AvailableTemplate';
  definitions: Array<TemplateDefinitionDefinition>;
  description: Scalars['String'];
  fields: Array<FieldAdditions>;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  processes: Array<TemplateProcess>;
};

export type AvailableTemplateCategory = {
  __typename?: 'AvailableTemplateCategory';
  definitions: Array<TemplateDefinition>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  templates: Array<AvailableTemplate>;
};

export type Board = {
  __typename?: 'Board';
  businessObjectDefinition: BusinessObjectDefinition;
  businessObjects: Array<BusinessObject>;
  cardFormatter: BoardFormatter;
  columns: Array<BoardColumn>;
  filter: BusinessObjectComparator;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BoardColumn = {
  __typename?: 'BoardColumn';
  label: Scalars['String'];
  order: BusinessObjectSorter;
  selector: BusinessObjectComparator;
};

export type BoardFormatter = {
  __typename?: 'BoardFormatter';
  lines: Array<CardFormatter>;
  title: CardFormatter;
};

export type BoardFormatterInput = {
  lines: Array<CardFormatterInput>;
  title: CardFormatterInput;
};

export type BooleanFieldDefinition = FieldDefinition & {
  __typename?: 'BooleanFieldDefinition';
  defaultValue: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type BooleanFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'BooleanFieldDefinitionTemplate';
  defaultValue: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type BusinessObject = {
  __typename?: 'BusinessObject';
  canDelete: Scalars['Boolean'];
  definition: BusinessObjectDefinition;
  fields: Array<BusinessObjectField>;
  id: Scalars['ID'];
  /** @deprecated superceded by BusinessObject.onProcess.isCompleted */
  isCompletedOnProcess: Scalars['Boolean'];
  label: Scalars['String'];
  onProcess?: Maybe<BusinessObjectOnProcess>;
  subscribed: Scalars['Boolean'];
};


export type BusinessObjectIsCompletedOnProcessArgs = {
  input: IsCompletedOnProcessInput;
};


export type BusinessObjectOnProcessArgs = {
  input: BusinessObjectOnProcessInput;
};

export type BusinessObjectActionRelatesTo = ActionRelatesTo & {
  __typename?: 'BusinessObjectActionRelatesTo';
  businessObject: BusinessObject;
  type: ActionRelation;
};

export type BusinessObjectAddedToProcessHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'BusinessObjectAddedToProcessHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
  payload: AddedToProcessPayload;
};

export type BusinessObjectBooleanField = BusinessObjectField & {
  __typename?: 'BusinessObjectBooleanField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['Boolean'];
};

export type BusinessObjectBooleanFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectBooleanFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue: Scalars['Boolean'];
  previousValue: Scalars['Boolean'];
  type: FieldType;
};

export type BusinessObjectComparator = {
  __typename?: 'BusinessObjectComparator';
  negate: Scalars['Boolean'];
  operator: Operator;
  valueSelector: BusinessObjectValueSelector;
  with: Scalars['Json'];
};

export type BusinessObjectComparatorInput = {
  negate: Scalars['Boolean'];
  operator: Operator;
  valueSelector: BusinessObjectValueSelectorInput;
  with: Scalars['Json'];
};

export type BusinessObjectCreatedHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'BusinessObjectCreatedHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
};

export type BusinessObjectCurrencyField = BusinessObjectField & {
  __typename?: 'BusinessObjectCurrencyField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<BusinessObjectCurrencyFieldValue>;
};

export type BusinessObjectCurrencyFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectCurrencyFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<BusinessObjectCurrencyFieldValue>;
  previousValue?: Maybe<BusinessObjectCurrencyFieldValue>;
  type: FieldType;
};

export type BusinessObjectCurrencyFieldValue = {
  __typename?: 'BusinessObjectCurrencyFieldValue';
  amount: Scalars['Float'];
  currencyDetails: CurrencyDetails;
};

export type BusinessObjectDateField = BusinessObjectField & {
  __typename?: 'BusinessObjectDateField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<Scalars['Date']>;
};

export type BusinessObjectDateFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectDateFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<Scalars['Date']>;
  previousValue?: Maybe<Scalars['Date']>;
  type: FieldType;
};

export type BusinessObjectDefinition = BusinessObjectDefinitionCommon & {
  __typename?: 'BusinessObjectDefinition';
  consumedBy: Array<BusinessObjectDefinition>;
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  fields: Array<FieldDefinition>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  usage: BusinessObjectDefinitionUsage;
};

export type BusinessObjectDefinitionCommon = {
  consumedBy: Array<BusinessObjectDefinition>;
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  fields: Array<FieldDefinition>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type BusinessObjectDefinitionExtension = BusinessObjectDefinitionCommon & {
  __typename?: 'BusinessObjectDefinitionExtension';
  consumedBy: Array<BusinessObjectDefinition>;
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  extensionOf: Extendable;
  fields: Array<FieldDefinition>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type BusinessObjectDefinitionTemplate = {
  __typename?: 'BusinessObjectDefinitionTemplate';
  description?: Maybe<Scalars['String']>;
  fields: Array<FieldDefinitionTemplate>;
  label: Scalars['String'];
  name: Scalars['String'];
};

export type BusinessObjectDefinitionUsage = {
  __typename?: 'BusinessObjectDefinitionUsage';
  boardCount: Scalars['Int'];
  boards: Array<Board>;
  instanceCount: Scalars['Int'];
  instances: Array<BusinessObject>;
  processCount: Scalars['Int'];
  processes: Array<Process>;
  relationCount: Scalars['Int'];
  relations: Array<BusinessObjectDefinitionCommon>;
};

export type BusinessObjectDocumentField = BusinessObjectField & {
  __typename?: 'BusinessObjectDocumentField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<File>;
};

export type BusinessObjectDocumentFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectDocumentFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<File>;
  previousValue?: Maybe<File>;
  type: FieldType;
};

export type BusinessObjectEmailField = BusinessObjectField & {
  __typename?: 'BusinessObjectEmailField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<Scalars['String']>;
};

export type BusinessObjectEmailFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectEmailFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<Scalars['String']>;
  previousValue?: Maybe<Scalars['String']>;
  type: FieldType;
};

export type BusinessObjectEnteredPhaseHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'BusinessObjectEnteredPhaseHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
  payload: EnteredPhasePayload;
};

export type BusinessObjectExtension = {
  __typename?: 'BusinessObjectExtension';
  canDelete: Scalars['Boolean'];
  definition: BusinessObjectDefinitionExtension;
  fields: Array<BusinessObjectField>;
  id: Scalars['ID'];
};

export type BusinessObjectField = {
  fieldDefinition: FieldDefinition;
  type: FieldType;
};

export type BusinessObjectFieldUpdatedHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'BusinessObjectFieldUpdatedHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
  payload: BusinessObjectFieldUpdatedPayload;
};

export type BusinessObjectFieldUpdatedPayload = {
  fieldDefinition: FieldDefinition;
  type: FieldType;
};

export type BusinessObjectHistoryEntry = {
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
};

export enum BusinessObjectHistoryEventType {
  AddedToProcess = 'addedToProcess',
  Created = 'created',
  EnteredPhase = 'enteredPhase',
  FieldUpdated = 'fieldUpdated',
  PhaseCriterionToggled = 'phaseCriterionToggled',
  RemovedFromProcess = 'removedFromProcess'
}

export type BusinessObjectHistoryInput = {
  businessObjectId: Scalars['String'];
};

export type BusinessObjectInProcessCountInput = {
  processId?: InputMaybe<Scalars['ID']>;
};

export type BusinessObjectListField = BusinessObjectField & {
  __typename?: 'BusinessObjectListField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Array<BusinessObjectField>;
};

export type BusinessObjectListFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectListFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue: Scalars['Int'];
  previousValue: Scalars['Int'];
  type: FieldType;
};

export type BusinessObjectNumberField = BusinessObjectField & {
  __typename?: 'BusinessObjectNumberField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<Scalars['Float']>;
};

export type BusinessObjectNumberFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectNumberFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<Scalars['Float']>;
  previousValue?: Maybe<Scalars['Float']>;
  type: FieldType;
};

export type BusinessObjectOnPhase = {
  __typename?: 'BusinessObjectOnPhase';
  actions: Array<Action>;
  phase: Phase;
};

export type BusinessObjectOnPhaseCriterion = {
  __typename?: 'BusinessObjectOnPhaseCriterion';
  isApplicable: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
};

export type BusinessObjectOnPhaseCriterionInput = {
  businessObjectId: Scalars['ID'];
};

export type BusinessObjectOnProcess = {
  __typename?: 'BusinessObjectOnProcess';
  isCompleted: Scalars['Boolean'];
  onPhase: BusinessObjectOnPhase;
  /** @deprecated superceded by BusinessObjectOnProcess.onPhase.phase */
  phase: Phase;
  process: Process;
};

export type BusinessObjectOnProcessInput = {
  processId: Scalars['ID'];
};

export type BusinessObjectRelationField = BusinessObjectField & {
  __typename?: 'BusinessObjectRelationField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<BusinessObject>;
};

export type BusinessObjectRelationFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectRelationFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<BusinessObject>;
  previousValue?: Maybe<BusinessObject>;
  type: FieldType;
};

export type BusinessObjectRemovedFromProcessHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'BusinessObjectRemovedFromProcessHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
  payload: RemovedFromProcessPayload;
};

export type BusinessObjectSelectField = BusinessObjectField & {
  __typename?: 'BusinessObjectSelectField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Array<Scalars['String']>;
};

export type BusinessObjectSelectFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectSelectFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue: Array<Scalars['String']>;
  previousValue: Array<Scalars['String']>;
  type: FieldType;
};

export type BusinessObjectSorter = {
  __typename?: 'BusinessObjectSorter';
  direction?: Maybe<SortDirection>;
  selector: BusinessObjectValueSelector;
};

export type BusinessObjectSorterInput = {
  direction: SortDirection;
  selector: BusinessObjectValueSelectorInput;
};

export type BusinessObjectTelephoneField = BusinessObjectField & {
  __typename?: 'BusinessObjectTelephoneField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<BusinessObjectTelephoneFieldValue>;
};

export type BusinessObjectTelephoneFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectTelephoneFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<BusinessObjectTelephoneFieldValue>;
  previousValue?: Maybe<BusinessObjectTelephoneFieldValue>;
  type: FieldType;
};

export type BusinessObjectTelephoneFieldValue = {
  __typename?: 'BusinessObjectTelephoneFieldValue';
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type BusinessObjectTextField = BusinessObjectField & {
  __typename?: 'BusinessObjectTextField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<Scalars['String']>;
};

export type BusinessObjectTextFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectTextFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<Scalars['String']>;
  previousValue?: Maybe<Scalars['String']>;
  type: FieldType;
};

export type BusinessObjectUpdatesField = BusinessObjectField & {
  __typename?: 'BusinessObjectUpdatesField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<BusinessObjectUpdatesFieldValue>;
};

export type BusinessObjectUpdatesFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectUpdatesFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<BusinessObjectUpdatesFieldValue>;
  previousValue?: Maybe<BusinessObjectUpdatesFieldValue>;
  type: FieldType;
};

export type BusinessObjectUpdatesFieldValue = {
  __typename?: 'BusinessObjectUpdatesFieldValue';
  attachments: Array<UpdateAttachmentBase>;
  createdAt: Scalars['Date'];
  createdBy: Principal;
  richText: Scalars['Json'];
};

export type BusinessObjectUrlField = BusinessObjectField & {
  __typename?: 'BusinessObjectUrlField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<Scalars['String']>;
};

export type BusinessObjectUrlFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectUrlFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<Scalars['String']>;
  previousValue?: Maybe<Scalars['String']>;
  type: FieldType;
};

export type BusinessObjectUserField = BusinessObjectField & {
  __typename?: 'BusinessObjectUserField';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value?: Maybe<User>;
};

export type BusinessObjectUserFieldUpdate = BusinessObjectFieldUpdatedPayload & {
  __typename?: 'BusinessObjectUserFieldUpdate';
  fieldDefinition: FieldDefinition;
  newValue?: Maybe<User>;
  previousValue?: Maybe<User>;
  type: FieldType;
};

export type BusinessObjectValueSelector = {
  type: ValueSelectorType;
};

export type BusinessObjectValueSelectorInput = {
  constant?: InputMaybe<ConstantSelectorInput>;
  deep?: InputMaybe<DeepSelectorInput>;
  fieldValue?: InputMaybe<FieldValueSelectorInput>;
};

export type BusinessObjectsInPhaseInput = {
  excludeCompleted?: InputMaybe<Scalars['Boolean']>;
};

export type CanDeleteAction = {
  canDelete: Scalars['Boolean'];
};

export type CanDeleteActionNo = CanDeleteAction & {
  __typename?: 'CanDeleteActionNo';
  canDelete: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type CanDeleteActionYes = CanDeleteAction & {
  __typename?: 'CanDeleteActionYes';
  canDelete: Scalars['Boolean'];
};

export type CardFormatter = {
  type: CardFormatterType;
};

export type CardFormatterInput = {
  constant?: InputMaybe<ConstantCardFormatterInput>;
  fieldValue?: InputMaybe<FieldValueCardFormatterInput>;
  label?: InputMaybe<LabelCardFormatterInput>;
};

export enum CardFormatterType {
  Constant = 'constant',
  FieldValue = 'fieldValue',
  Label = 'label'
}

export type ChangeActionSubscriptionInput = {
  action: ChangeSubscriptionAction;
  actionId: Scalars['ID'];
};

export type ChangeConditionsInput = {
  criterionIndex: Scalars['Int'];
  nextValue: Array<BusinessObjectComparatorInput>;
  phaseIndex: Scalars['Int'];
  previousValue?: InputMaybe<Array<BusinessObjectComparatorInput>>;
};

export enum ChangeSubscriptionAction {
  Add = 'add',
  Remove = 'remove'
}

export type ChangeSubscriptionInput = {
  action: ChangeSubscriptionAction;
  businessObjectId: Scalars['ID'];
};

export type CompletedAction = Action & {
  __typename?: 'CompletedAction';
  assignedTo?: Maybe<Principal>;
  canDelete: CanDeleteAction;
  completedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  descriptionAttachments?: Maybe<Array<File>>;
  dueDate?: Maybe<Scalars['Date']>;
  extendedBy?: Maybe<BusinessObjectExtension>;
  id: Scalars['ID'];
  name: Scalars['String'];
  relatesTo?: Maybe<ActionRelatesTo>;
  subscribed: Scalars['Boolean'];
};

export type Connection = {
  __typename?: 'Connection';
  id: Scalars['String'];
};

export type ConstantCardFormatter = CardFormatter & {
  __typename?: 'ConstantCardFormatter';
  type: CardFormatterType;
  value: Scalars['String'];
};

export type ConstantCardFormatterInput = {
  value: Scalars['String'];
};

export type ConstantSelector = BusinessObjectValueSelector & DeepSelectorSelector & {
  __typename?: 'ConstantSelector';
  type: ValueSelectorType;
  value: Scalars['Json'];
};

export type ConstantSelectorInput = {
  value: Scalars['Json'];
};

export type CreateActionCriterionInput = {
  conditions?: InputMaybe<Array<BusinessObjectComparatorInput>>;
  defaultAssignee?: InputMaybe<PrincipalInput>;
  defaultDueSeconds?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  purpose?: InputMaybe<Scalars['String']>;
};

export type CreateActionDescriptionAttachmentInput = {
  attachment: Scalars['String'];
  type: AttachmentType;
};

export type CreateActionInput = {
  assignedTo?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionAttachments?: InputMaybe<Array<CreateActionDescriptionAttachmentInput>>;
  dueDate?: InputMaybe<Scalars['Date']>;
  extension?: InputMaybe<CreateBusinessObjectInput>;
  name: Scalars['String'];
  relatesTo?: InputMaybe<CreateActionRelatesToInput>;
};

export type CreateActionPayload = ScheduledPayload & {
  __typename?: 'CreateActionPayload';
  data?: Maybe<CreateActionPayloadData>;
  type: ScheduledPayloadType;
};

export type CreateActionPayloadData = {
  __typename?: 'CreateActionPayloadData';
  assignedTo?: Maybe<Principal>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
};

export type CreateActionRelatesToBusinessObjectInput = {
  id: Scalars['ID'];
};

export type CreateActionRelatesToInput = {
  businessObject?: InputMaybe<CreateActionRelatesToBusinessObjectInput>;
};

export type CreateActionWebhook = WebhookAction & {
  __typename?: 'CreateActionWebhook';
  payload: CreateActionWebhookPayload;
  type: WebhookActionType;
};

export type CreateActionWebhookPayload = {
  __typename?: 'CreateActionWebhookPayload';
  assignedTo?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateAttachment = {
  file?: InputMaybe<Scalars['ID']>;
};

export type CreateBoardInput = {
  businessObjectDefinitionId: Scalars['String'];
  columns: Array<PatchBoardAddColumnInput>;
  filter?: InputMaybe<BusinessObjectComparatorInput>;
  name: Scalars['String'];
};

export type CreateBusinessObjectBooleanField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type CreateBusinessObjectBooleanFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectBooleanFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['Boolean'];
};

export type CreateBusinessObjectCurrencyField = {
  fieldDefinitionId: Scalars['ID'];
  value: CreateBusinessObjectCurrencyFieldValue;
};

export type CreateBusinessObjectCurrencyFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectCurrencyFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: CreateBusinessObjectCurrencyFieldValueOutput;
};

export type CreateBusinessObjectCurrencyFieldValue = {
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type CreateBusinessObjectCurrencyFieldValueOutput = {
  __typename?: 'CreateBusinessObjectCurrencyFieldValueOutput';
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type CreateBusinessObjectDateField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['Date'];
};

export type CreateBusinessObjectDateFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectDateFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['String'];
};

export type CreateBusinessObjectDefinitionBooleanField = {
  defaultValue?: InputMaybe<Scalars['Boolean']>;
};

export type CreateBusinessObjectDefinitionCurrencyField = {
  constraints: Array<CreateBusinessObjectDefinitionCurrencyFieldConstraint>;
  defaultValue?: InputMaybe<CreateBusinessObjectDefinitionCurrencyFieldDefaultValue>;
};

export type CreateBusinessObjectDefinitionCurrencyFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionCurrencyFieldDefaultValue = {
  amount?: InputMaybe<Scalars['Float']>;
  currencyCode?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionDateField = {
  constraints: Array<CreateBusinessObjectDefinitionDateFieldConstraint>;
  defaultValue?: InputMaybe<DefaultDateInput>;
  precision?: InputMaybe<CreateBusinessObjectDefinitionDateFieldPrecision>;
};

export type CreateBusinessObjectDefinitionDateFieldConstraint = {
  dateRange?: InputMaybe<CreateBusinessObjectDefinitionDateRangeConstraint>;
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export enum CreateBusinessObjectDefinitionDateFieldPrecision {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Month = 'month',
  Year = 'year'
}

export type CreateBusinessObjectDefinitionDateRangeConstraint = {
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};

export type CreateBusinessObjectDefinitionDocumentField = {
  constraints: Array<CreateBusinessObjectDefinitionDocumentFieldConstraint>;
};

export type CreateBusinessObjectDefinitionDocumentFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionEmailField = {
  constraints: Array<CreateBusinessObjectDefinitionEmailFieldConstraint>;
  defaultValue?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionEmailFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionExtensionInput = {
  extensionOf: Extendable;
  fields: Array<CreateBusinessObjectDefinitionField>;
};

export type CreateBusinessObjectDefinitionField = {
  boolean?: InputMaybe<CreateBusinessObjectDefinitionBooleanField>;
  currency?: InputMaybe<CreateBusinessObjectDefinitionCurrencyField>;
  date?: InputMaybe<CreateBusinessObjectDefinitionDateField>;
  description?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<CreateBusinessObjectDefinitionDocumentField>;
  email?: InputMaybe<CreateBusinessObjectDefinitionEmailField>;
  list?: InputMaybe<CreateBusinessObjectDefinitionListField>;
  name: Scalars['String'];
  number?: InputMaybe<CreateBusinessObjectDefinitionNumberField>;
  relation?: InputMaybe<CreateBusinessObjectDefinitionRelationField>;
  select?: InputMaybe<CreateBusinessObjectDefinitionSelectField>;
  telephone?: InputMaybe<CreateBusinessObjectDefinitionTelephoneField>;
  text?: InputMaybe<CreateBusinessObjectDefinitionTextField>;
  updates?: InputMaybe<CreateBusinessObjectDefinitionUpdatesField>;
  url?: InputMaybe<CreateBusinessObjectDefinitionUrlField>;
  user?: InputMaybe<CreateBusinessObjectDefinitionUserField>;
};

export type CreateBusinessObjectDefinitionInput = {
  description?: InputMaybe<Scalars['String']>;
  fields: Array<CreateBusinessObjectDefinitionField>;
  label: Scalars['String'];
  name: Scalars['String'];
};

export type CreateBusinessObjectDefinitionLengthConstraint = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type CreateBusinessObjectDefinitionListField = {
  listOf: CreateBusinessObjectDefinitionListFieldListOf;
};

export type CreateBusinessObjectDefinitionListFieldListOf = {
  boolean?: InputMaybe<CreateBusinessObjectDefinitionBooleanField>;
  currency?: InputMaybe<CreateBusinessObjectDefinitionCurrencyField>;
  date?: InputMaybe<CreateBusinessObjectDefinitionDateField>;
  document?: InputMaybe<CreateBusinessObjectDefinitionDocumentField>;
  email?: InputMaybe<CreateBusinessObjectDefinitionEmailField>;
  name: Scalars['String'];
  number?: InputMaybe<CreateBusinessObjectDefinitionNumberField>;
  relation?: InputMaybe<CreateBusinessObjectDefinitionRelationField>;
  select?: InputMaybe<CreateBusinessObjectDefinitionSelectField>;
  telephone?: InputMaybe<CreateBusinessObjectDefinitionTelephoneField>;
  text?: InputMaybe<CreateBusinessObjectDefinitionTextField>;
  updates?: InputMaybe<CreateBusinessObjectDefinitionUpdatesField>;
  url?: InputMaybe<CreateBusinessObjectDefinitionUrlField>;
  user?: InputMaybe<CreateBusinessObjectDefinitionUserField>;
};

export type CreateBusinessObjectDefinitionNumberField = {
  constraints: Array<CreateBusinessObjectDefinitionNumberFieldConstraint>;
  defaultValue?: InputMaybe<Scalars['Float']>;
};

export type CreateBusinessObjectDefinitionNumberFieldConstraint = {
  range?: InputMaybe<CreateBusinessObjectDefinitionRangeConstraint>;
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionNumberOfSelectionsConstraint = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type CreateBusinessObjectDefinitionRangeConstraint = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type CreateBusinessObjectDefinitionRelationField = {
  constraints: Array<CreateBusinessObjectDefinitionRelationFieldConstraint>;
};

export type CreateBusinessObjectDefinitionRelationFieldConstraint = {
  relationType?: InputMaybe<CreateBusinessObjectDefinitionRelationTypeConstraint>;
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionRelationTypeConstraint = {
  types: Array<Scalars['ID']>;
};

export type CreateBusinessObjectDefinitionRequiredConstraint = {
  ignoreMe?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionSelectField = {
  constraints: Array<CreateBusinessObjectDefinitionSelectFieldConstraint>;
  defaultValue?: InputMaybe<CreateBusinessObjectDefinitionSelectFieldDefaultValue>;
  options: Array<CreateBusinessObjectDefinitionSelectFieldOption>;
};

export type CreateBusinessObjectDefinitionSelectFieldConstraint = {
  numberOfSelections?: InputMaybe<CreateBusinessObjectDefinitionNumberOfSelectionsConstraint>;
};

export type CreateBusinessObjectDefinitionSelectFieldDefaultValue = {
  selectedIds: Array<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionSelectFieldOption = {
  value: Scalars['String'];
};

export type CreateBusinessObjectDefinitionTelephoneField = {
  constraints: Array<CreateBusinessObjectDefinitionTelephoneFieldConstraint>;
  defaultValue?: InputMaybe<CreateBusinessObjectDefinitionTelephoneFieldDefaultValue>;
};

export type CreateBusinessObjectDefinitionTelephoneFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionTelephoneFieldDefaultValue = {
  countryCode?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionTextField = {
  constraints: Array<CreateBusinessObjectDefinitionTextFieldConstraint>;
  defaultValue?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionTextFieldConstraint = {
  length?: InputMaybe<CreateBusinessObjectDefinitionLengthConstraint>;
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionUrlField = {
  constraints: Array<CreateBusinessObjectDefinitionUrlFieldConstraint>;
  defaultValue?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionUrlFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDefinitionUpdatesField = {
  unused?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectDefinitionUserField = {
  constraints: Array<CreateBusinessObjectDefinitionUserFieldConstraint>;
  defaultValue?: InputMaybe<Scalars['ID']>;
};

export type CreateBusinessObjectDefinitionUserFieldConstraint = {
  required?: InputMaybe<CreateBusinessObjectDefinitionRequiredConstraint>;
};

export type CreateBusinessObjectDocumentField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['ID'];
};

export type CreateBusinessObjectDocumentFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectDocumentFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['ID'];
};

export type CreateBusinessObjectEmailField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateBusinessObjectEmailFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectEmailFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['String'];
};

export type CreateBusinessObjectField = {
  boolean?: InputMaybe<CreateBusinessObjectBooleanField>;
  currency?: InputMaybe<CreateBusinessObjectCurrencyField>;
  date?: InputMaybe<CreateBusinessObjectDateField>;
  document?: InputMaybe<CreateBusinessObjectDocumentField>;
  email?: InputMaybe<CreateBusinessObjectEmailField>;
  list?: InputMaybe<CreateBusinessObjectListField>;
  number?: InputMaybe<CreateBusinessObjectNumberField>;
  relation?: InputMaybe<CreateBusinessObjectRelationField>;
  select?: InputMaybe<CreateBusinessObjectSelectField>;
  telephone?: InputMaybe<CreateBusinessObjectTelephoneField>;
  text?: InputMaybe<CreateBusinessObjectTextField>;
  updates?: InputMaybe<CreateBusinessObjectUpdatesField>;
  url?: InputMaybe<CreateBusinessObjectUrlField>;
  user?: InputMaybe<CreateBusinessObjectUserField>;
};

export type CreateBusinessObjectFieldOutput = {
  fieldDefinition: FieldDefinition;
  type: FieldType;
};

export type CreateBusinessObjectInput = {
  businessObjectDefinitionId: Scalars['String'];
  fields: Array<CreateBusinessObjectField>;
};

export type CreateBusinessObjectInputOutput = {
  __typename?: 'CreateBusinessObjectInputOutput';
  businessObjectDefinition: BusinessObjectDefinition;
  fields: Array<CreateBusinessObjectFieldOutput>;
};

export type CreateBusinessObjectListField = {
  fieldDefinitionId: Scalars['ID'];
  value: Array<CreateBusinessObjectListFieldValue>;
};

export type CreateBusinessObjectListFieldValue = {
  boolean?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<CreateBusinessObjectCurrencyFieldValue>;
  date?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Float']>;
  relation?: InputMaybe<Scalars['ID']>;
  select?: InputMaybe<Array<Scalars['String']>>;
  telephone?: InputMaybe<CreateBusinessObjectTelephoneFieldValue>;
  text?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type CreateBusinessObjectNumberField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['Float'];
};

export type CreateBusinessObjectNumberFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectNumberFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['Float'];
};

export type CreateBusinessObjectRelationField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['ID'];
};

export type CreateBusinessObjectRelationFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectRelationFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['ID'];
};

export type CreateBusinessObjectSelectField = {
  fieldDefinitionId: Scalars['ID'];
  value: Array<Scalars['String']>;
};

export type CreateBusinessObjectSelectFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectSelectFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Array<Scalars['String']>;
};

export type CreateBusinessObjectTelephoneField = {
  fieldDefinitionId: Scalars['ID'];
  value: CreateBusinessObjectTelephoneFieldValue;
};

export type CreateBusinessObjectTelephoneFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectTelephoneFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: CreateBusinessObjectTelephoneFieldValueOutput;
};

export type CreateBusinessObjectTelephoneFieldValue = {
  countryCode?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessObjectTelephoneFieldValueOutput = {
  __typename?: 'CreateBusinessObjectTelephoneFieldValueOutput';
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type CreateBusinessObjectTextField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateBusinessObjectTextFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectTextFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['String'];
};

export type CreateBusinessObjectUpdatesField = {
  fieldDefinitionId: Scalars['ID'];
  value: CreateBusinessObjectUpdatesFieldValue;
};

export type CreateBusinessObjectUpdatesFieldValue = {
  attachments?: InputMaybe<Array<CreateAttachment>>;
  richText: Scalars['Json'];
};

export type CreateBusinessObjectUrlField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateBusinessObjectUrlFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectUrlFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['String'];
};

export type CreateBusinessObjectUserField = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['ID'];
};

export type CreateBusinessObjectUserFieldOutput = CreateBusinessObjectFieldOutput & {
  __typename?: 'CreateBusinessObjectUserFieldOutput';
  fieldDefinition: FieldDefinition;
  type: FieldType;
  value: Scalars['ID'];
};

export type CreateBusinessObjectWebhook = WebhookAction & {
  __typename?: 'CreateBusinessObjectWebhook';
  payload: CreateBusinessObjectInputOutput;
  type: WebhookActionType;
};

export type CreateCriterionInput = {
  action?: InputMaybe<CreateActionCriterionInput>;
  fieldCondition?: InputMaybe<CreateFieldConditionCriterionInput>;
  processDelegate?: InputMaybe<CreateProcessDelegateCriterionInput>;
  processFanout?: InputMaybe<CreateProcessFanoutCriterionInput>;
};

export type CreateDelegateTransformerInput = {
  listField?: InputMaybe<CreateListFieldDelegateTransformerInput>;
  relationField?: InputMaybe<CreateRelationFieldDelegateTransformerInput>;
};

export type CreateFieldConditionCriterionInput = {
  comparator: BusinessObjectComparatorInput;
  conditions?: InputMaybe<Array<BusinessObjectComparatorInput>>;
};

export type CreateFileInput = {
  name: Scalars['String'];
  storageProvider: StorageProvider;
};

export type CreateIntegrationActionInput = {
  name: Scalars['String'];
  params: Scalars['Json'];
  provider: Scalars['String'];
};

export type CreateIntegrationFilterInput = {
  params: Scalars['Json'];
};

export type CreateIntegrationInstanceInput = {
  action: CreateIntegrationActionInput;
  trigger: CreateIntegrationTriggerInput;
};

export type CreateIntegrationTriggerInput = {
  event: TriggerEvent;
  filter: CreateIntegrationFilterInput;
};

export type CreateInvitedUserInput = {
  inviteId: Scalars['String'];
  name: Scalars['String'];
  termsAccepted: Scalars['Date'];
};

export type CreateListFieldDelegateTransformerInput = {
  fieldId: Scalars['ID'];
};

export type CreatePhaseInput = {
  criteria: Array<CreateCriterionInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateProcessDelegateCriterionInput = {
  conditions?: InputMaybe<Array<BusinessObjectComparatorInput>>;
  processId: Scalars['ID'];
  transform?: InputMaybe<CreateDelegateTransformerInput>;
};

export type CreateProcessFanoutCriterionInput = {
  conditions?: InputMaybe<Array<BusinessObjectComparatorInput>>;
  processId: Scalars['ID'];
  transform?: InputMaybe<CreateDelegateTransformerInput>;
};

export type CreateProcessInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  operatesUpon: Array<Scalars['String']>;
  phases: Array<CreatePhaseInput>;
};

export type CreateRelationFieldDelegateTransformerInput = {
  fieldId: Scalars['ID'];
};

export type CreateTenantInput = {
  companyName: Scalars['String'];
};

export type CreateUserAndTenantInput = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  identityUserId: Scalars['String'];
  name: Scalars['String'];
  termsAccepted: Scalars['Date'];
};

export type CreateUserGroupInput = {
  name: Scalars['String'];
};

export type CreateWebhookActionAddBusinessObjectToProcessInput = {
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type CreateWebhookActionCreateActionInput = {
  assignedTo?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateWebhookActionFilterInput = {
  operator: Scalars['String'];
  selector: Scalars['String'];
  value: Scalars['String'];
};

export type CreateWebhookActionInput = {
  addBusinessObjectToProcess?: InputMaybe<CreateWebhookActionAddBusinessObjectToProcessInput>;
  createAction?: InputMaybe<CreateWebhookActionCreateActionInput>;
  createBusinessObject?: InputMaybe<CreateBusinessObjectInput>;
  filter?: InputMaybe<CreateWebhookActionFilterInput>;
};

export type CreateWebhookInput = {
  actions: Array<CreateWebhookActionInput>;
  name: Scalars['String'];
  signingSecret?: InputMaybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  decimalDigits: Scalars['Float'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  nativeSymbol: Scalars['String'];
  rounding: Scalars['Float'];
  symbol: Scalars['String'];
};

export type CurrencyDetails = {
  __typename?: 'CurrencyDetails';
  code: Scalars['String'];
  decimalDigits: Scalars['Float'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  nativeSymbol: Scalars['String'];
  rounding: Scalars['Float'];
  symbol: Scalars['String'];
};

export type CurrencyFieldConstraints = RequiredConstraint;

export type CurrencyFieldDefinition = FieldDefinition & {
  __typename?: 'CurrencyFieldDefinition';
  constraints: Array<CurrencyFieldConstraints>;
  defaultValue?: Maybe<CurrencyFieldValue>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<Currency>;
  type: FieldType;
};

export type CurrencyFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'CurrencyFieldDefinitionTemplate';
  constraints: Array<CurrencyFieldConstraints>;
  defaultValue?: Maybe<CurrencyFieldValue>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type CurrencyFieldValue = {
  __typename?: 'CurrencyFieldValue';
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type DateFieldConstraints = DateRangeConstraint | RequiredConstraint;

export type DateFieldDefinition = FieldDefinition & {
  __typename?: 'DateFieldDefinition';
  constraints: Array<DateFieldConstraints>;
  defaultValue?: Maybe<DefaultDate>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  precision: CreateBusinessObjectDefinitionDateFieldPrecision;
  type: FieldType;
};

export type DateFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'DateFieldDefinitionTemplate';
  constraints: Array<DateFieldConstraints>;
  defaultValue?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  precision: CreateBusinessObjectDefinitionDateFieldPrecision;
  type: FieldType;
};

export type DateRangeConstraint = FieldConstraint & {
  __typename?: 'DateRangeConstraint';
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  type: FieldConstraintType;
};

export type DeclineInviteInput = {
  inviteId: Scalars['ID'];
};

export type DeepSelector = BusinessObjectValueSelector & {
  __typename?: 'DeepSelector';
  selectors: Array<DeepSelectorSelector>;
  type: ValueSelectorType;
};

export type DeepSelectorInput = {
  selectors: Array<DeepSelectorSelectorInput>;
};

export type DeepSelectorSelector = {
  type: ValueSelectorType;
};

export type DeepSelectorSelectorInput = {
  constant?: InputMaybe<ConstantSelectorInput>;
  fieldValue?: InputMaybe<FieldValueSelectorInput>;
};

export type DefaultDate = {
  __typename?: 'DefaultDate';
  absolute?: Maybe<Scalars['Date']>;
  relative?: Maybe<Scalars['Duration']>;
};

export type DefaultDateInput = {
  absolute?: InputMaybe<Scalars['Date']>;
  relative?: InputMaybe<Scalars['Duration']>;
};

export type DelegationTransformer = {
  field: FieldDefinition;
  type: DelegationTransformerType;
};

export enum DelegationTransformerType {
  ListField = 'listField',
  RelationField = 'relationField'
}

export type DeleteActionInput = {
  actionId: Scalars['ID'];
};

export type DeleteBusinessObjectDefinitionInput = {
  id: Scalars['ID'];
};

export type DeleteBusinessObjectInput = {
  id: Scalars['ID'];
};

export type DeleteFileInput = {
  id: Scalars['String'];
};

export type DeleteIntegrationInstanceInput = {
  id: Scalars['ID'];
};

export type DeleteProcessInput = {
  processId: Scalars['ID'];
};

export type DeleteScheduledEventInput = {
  id: Scalars['ID'];
};

export type DeleteViewInput = {
  id: Scalars['ID'];
};

export type DeleteWebhookInput = {
  id: Scalars['ID'];
};

export type DeployTemplateInput = {
  templateCategory: Scalars['String'];
  templates: Array<Scalars['String']>;
};

export type DeployedTemplate = {
  __typename?: 'DeployedTemplate';
  businessObjectDefinitions: Array<BusinessObjectDefinition>;
  processes: Array<Process>;
};

export type DisconnectIntegrationInput = {
  name: Scalars['String'];
};

export type DocumentFieldConstraints = RequiredConstraint;

export type DocumentFieldDefinition = FieldDefinition & {
  __typename?: 'DocumentFieldDefinition';
  constraints: Array<DocumentFieldConstraints>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type DocumentFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'DocumentFieldDefinitionTemplate';
  constraints: Array<DocumentFieldConstraints>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type EditFileInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EmailFieldConstraints = RequiredConstraint;

export type EmailFieldDefinition = FieldDefinition & {
  __typename?: 'EmailFieldDefinition';
  constraints: Array<EmailFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type EmailFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'EmailFieldDefinitionTemplate';
  constraints: Array<EmailFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type EnteredPhasePayload = {
  __typename?: 'EnteredPhasePayload';
  phaseIndex: Scalars['Int'];
  process: Process;
};

export enum Extendable {
  Action = 'action'
}

export type FieldAddition = {
  __typename?: 'FieldAddition';
  name: Scalars['String'];
  type: FieldType;
};

export type FieldAdditions = {
  __typename?: 'FieldAdditions';
  fields: Array<FieldAddition>;
  name: Scalars['String'];
};

export type FieldConditionPhaseCriterion = PhaseCriterion & {
  __typename?: 'FieldConditionPhaseCriterion';
  businessObjectOnCriterion?: Maybe<BusinessObjectOnPhaseCriterion>;
  comparator: BusinessObjectComparator;
  conditions?: Maybe<Array<BusinessObjectComparator>>;
  type: PhaseCriterionType;
};


export type FieldConditionPhaseCriterionBusinessObjectOnCriterionArgs = {
  input: BusinessObjectOnPhaseCriterionInput;
};

export type FieldConstraint = {
  type: FieldConstraintType;
};

export enum FieldConstraintType {
  DateRange = 'dateRange',
  Length = 'length',
  NumberOfSelections = 'numberOfSelections',
  Range = 'range',
  RelationType = 'relationType',
  Required = 'required'
}

export type FieldDefinition = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type FieldDefinitionTemplate = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export enum FieldType {
  Boolean = 'boolean',
  Currency = 'currency',
  Date = 'date',
  Document = 'document',
  Email = 'email',
  List = 'list',
  Number = 'number',
  Relation = 'relation',
  Select = 'select',
  Telephone = 'telephone',
  Text = 'text',
  Updates = 'updates',
  Url = 'url',
  User = 'user'
}

export type FieldValueCardFormatter = CardFormatter & {
  __typename?: 'FieldValueCardFormatter';
  fieldId: Scalars['ID'];
  type: CardFormatterType;
};

export type FieldValueCardFormatterInput = {
  fieldId: Scalars['ID'];
};

export type FieldValueSelector = BusinessObjectValueSelector & DeepSelectorSelector & {
  __typename?: 'FieldValueSelector';
  default?: Maybe<Scalars['Json']>;
  fieldId: Scalars['ID'];
  type: ValueSelectorType;
};

export type FieldValueSelectorInput = {
  default?: InputMaybe<Scalars['Json']>;
  fieldId: Scalars['ID'];
};

export type File = {
  __typename?: 'File';
  canDelete: Scalars['Boolean'];
  fileType: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Permissions;
  sizeInBytes: Scalars['Int'];
  uploadedAt: Scalars['String'];
  uploadedBy: Principal;
  url: Scalars['String'];
};

export type FilterWebhook = WebhookAction & {
  __typename?: 'FilterWebhook';
  payload: FilterWebhookPayload;
  type: WebhookActionType;
};

export type FilterWebhookPayload = {
  __typename?: 'FilterWebhookPayload';
  operator: Scalars['String'];
  selector: Scalars['String'];
  value: Scalars['String'];
};

export type FindIntegrationInstancesActionInput = {
  provider?: InputMaybe<Scalars['String']>;
};

export type FindIntegrationInstancesInput = {
  action?: InputMaybe<FindIntegrationInstancesActionInput>;
  trigger?: InputMaybe<FindIntegrationInstancesTriggerInput>;
};

export type FindIntegrationInstancesTriggerInput = {
  event?: InputMaybe<TriggerEvent>;
  params?: InputMaybe<Scalars['Json']>;
};

export type GetBoardInput = {
  id: Scalars['ID'];
};

export type GetBusinessObjectDefinitionExtensionsInput = {
  extension: Extendable;
};

export type GetBusinessObjectInput = {
  id: Scalars['ID'];
};

export type GetBusinessObjectsInput = {
  addableToProcess?: InputMaybe<AddableToProcessInput>;
  businessObjectDefinitionId?: InputMaybe<Scalars['ID']>;
  includeRemoved?: InputMaybe<AddableToProcessInput>;
};

export type GetFileInput = {
  id: Scalars['String'];
};

export type GetFilesInput = {
  createdAfter?: InputMaybe<Scalars['Date']>;
};

export type GetProcessByIdInput = {
  id: Scalars['ID'];
};

export type HasBusinessObjectCompletedInput = {
  businessObjectId: Scalars['String'];
};

export type IncompleteAction = Action & {
  __typename?: 'IncompleteAction';
  assignedTo?: Maybe<Principal>;
  canDelete: CanDeleteAction;
  createdAt: Scalars['Date'];
  createdBy: Principal;
  description?: Maybe<Scalars['String']>;
  descriptionAttachments?: Maybe<Array<File>>;
  dueDate?: Maybe<Scalars['Date']>;
  extendedBy?: Maybe<BusinessObjectExtension>;
  id: Scalars['ID'];
  name: Scalars['String'];
  relatesTo?: Maybe<ActionRelatesTo>;
  subscribed: Scalars['Boolean'];
};

export type Integration = {
  __typename?: 'Integration';
  actions: Array<IntegrationAction>;
  connection?: Maybe<Connection>;
  displayName: Scalars['String'];
  instances: Array<IntegrationInstance>;
  name: Scalars['String'];
};

export type IntegrationAction = {
  __typename?: 'IntegrationAction';
  displayName: Scalars['String'];
  hidden?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  params: Array<IntegrationActionParam>;
};

export type IntegrationActionParam = {
  __typename?: 'IntegrationActionParam';
  displayName: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  resolver: IntegrationActionParamResolver;
  type: IntegrationActionParamType;
};

export type IntegrationActionParamResolver = {
  type: IntegrationActionParamResolverType;
};

export type IntegrationActionParamResolverDataLookup = IntegrationActionParamResolver & {
  __typename?: 'IntegrationActionParamResolverDataLookup';
  action: Scalars['String'];
  displayKey: Scalars['String'];
  idKey: Scalars['String'];
  itemsKey: Scalars['String'];
  provider: Scalars['String'];
  type: IntegrationActionParamResolverType;
};

export type IntegrationActionParamResolverInput = IntegrationActionParamResolver & {
  __typename?: 'IntegrationActionParamResolverInput';
  type: IntegrationActionParamResolverType;
};

export enum IntegrationActionParamResolverType {
  DataLookup = 'dataLookup',
  Input = 'input'
}

export enum IntegrationActionParamType {
  Number = 'number',
  String = 'string'
}

export type IntegrationInstance = {
  __typename?: 'IntegrationInstance';
  action: IntegrationInstanceAction;
  id: Scalars['ID'];
  trigger: IntegrationInstanceTrigger;
};

export type IntegrationInstanceAction = {
  __typename?: 'IntegrationInstanceAction';
  action: IntegrationAction;
  integration: Integration;
  name: Scalars['String'];
  params: Scalars['Json'];
  provider: Scalars['String'];
};

export type IntegrationInstanceTrigger = {
  __typename?: 'IntegrationInstanceTrigger';
  event: TriggerEvent;
  filter: IntegrationInstanceTriggerFilter;
};

export type IntegrationInstanceTriggerFilter = {
  __typename?: 'IntegrationInstanceTriggerFilter';
  params: Scalars['Json'];
};

export type IntegrationToken = {
  __typename?: 'IntegrationToken';
  token: Scalars['String'];
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['ID'];
  inviteeEmail: Scalars['String'];
  inviter: InvitingUser;
  tenant: InvitingTenant;
};

export type InvitingTenant = {
  __typename?: 'InvitingTenant';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InvitingUser = {
  __typename?: 'InvitingUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IsCompletedOnProcessInput = {
  processId: Scalars['String'];
};

export type LabelCardFormatter = CardFormatter & {
  __typename?: 'LabelCardFormatter';
  template?: Maybe<Scalars['String']>;
  type: CardFormatterType;
};

export type LabelCardFormatterInput = {
  template?: InputMaybe<Scalars['String']>;
};

export type LengthConstraint = FieldConstraint & {
  __typename?: 'LengthConstraint';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  type: FieldConstraintType;
};

export type ListFieldDefinition = FieldDefinition & {
  __typename?: 'ListFieldDefinition';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOf: FieldDefinition;
  name: Scalars['String'];
  type: FieldType;
};

export type ListFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'ListFieldDefinitionTemplate';
  description?: Maybe<Scalars['String']>;
  listOf: FieldDefinitionTemplate;
  name: Scalars['String'];
  type: FieldType;
};

export type ListFieldDelegationTransformer = DelegationTransformer & {
  __typename?: 'ListFieldDelegationTransformer';
  field: FieldDefinition;
  type: DelegationTransformerType;
};

export type Meta = {
  __typename?: 'Meta';
  totalCount: Scalars['Int'];
};

export type MoveBusinessObjectToNextPhase = {
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type MoveBusinessObjectToPrevPhase = {
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBusinessObjectToProcess: Process;
  addBusinessObjectsToProcess: Process;
  changeActionSubscription: Scalars['Boolean'];
  changeBusinessObjectSubscription: Scalars['Boolean'];
  createAction: Action;
  createBoard: Board;
  createBusinessObject: BusinessObject;
  createBusinessObjectDefinition?: Maybe<BusinessObjectDefinition>;
  createBusinessObjectDefinitionExtension?: Maybe<BusinessObjectDefinitionExtension>;
  createFile: File;
  createIntegrationInstance: IntegrationInstance;
  createInvitedUser: User;
  createProcess: Process;
  createSandboxUser: SandboxUser;
  createTenant: User;
  createUserAndTenant: User;
  createUserGroup: UserGroup;
  createWebhook: Webhook;
  declineInvite: Invite;
  deleteAction: Scalars['Boolean'];
  deleteBusinessObject: Scalars['ID'];
  deleteBusinessObjectDefinition?: Maybe<Scalars['Boolean']>;
  deleteFile: Scalars['Boolean'];
  deleteIntegrationInstance: Scalars['Boolean'];
  deleteProcess: Scalars['Boolean'];
  deleteScheduledEvent: Scalars['Boolean'];
  deleteView: Scalars['ID'];
  deleteWebhook: Scalars['Boolean'];
  deployTemplate: DeployedTemplate;
  disconnectIntegration: Scalars['Boolean'];
  editFile: File;
  moveBusinessObjectToNextPhase: Process;
  moveBusinessObjectToPrevPhase: Process;
  patchBoard: Board;
  patchBusinessObjectDefinition: BusinessObjectDefinition;
  patchProcess: Process;
  putProcess: Process;
  removeBusinessObjectFromProcess: Process;
  removeUserFromTenant: Scalars['Boolean'];
  saveView: View;
  scheduleAction: ScheduledEvent;
  sendInvite: Invite;
  setActiveTenant: User;
  setIdentity: User;
  updateAction: Action;
  updateActionPhase: Action;
  updateBusinessObjectField: BusinessObject;
  updateTenant: Tenant;
  updateUser: User;
  updateUserGroupMembers: UserGroup;
  updateWebhook: Webhook;
  upsertActionExtensionObject: Action;
};


export type MutationAddBusinessObjectToProcessArgs = {
  input: AddBusinessObjectToProcessInput;
};


export type MutationAddBusinessObjectsToProcessArgs = {
  input: AddBusinessObjectsToProcessInput;
};


export type MutationChangeActionSubscriptionArgs = {
  input: ChangeActionSubscriptionInput;
};


export type MutationChangeBusinessObjectSubscriptionArgs = {
  input: ChangeSubscriptionInput;
};


export type MutationCreateActionArgs = {
  input: CreateActionInput;
};


export type MutationCreateBoardArgs = {
  input: CreateBoardInput;
};


export type MutationCreateBusinessObjectArgs = {
  input: CreateBusinessObjectInput;
};


export type MutationCreateBusinessObjectDefinitionArgs = {
  input: CreateBusinessObjectDefinitionInput;
};


export type MutationCreateBusinessObjectDefinitionExtensionArgs = {
  input: CreateBusinessObjectDefinitionExtensionInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateIntegrationInstanceArgs = {
  input: CreateIntegrationInstanceInput;
};


export type MutationCreateInvitedUserArgs = {
  input: CreateInvitedUserInput;
};


export type MutationCreateProcessArgs = {
  input: CreateProcessInput;
};


export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


export type MutationCreateUserAndTenantArgs = {
  input: CreateUserAndTenantInput;
};


export type MutationCreateUserGroupArgs = {
  input: CreateUserGroupInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeclineInviteArgs = {
  input: DeclineInviteInput;
};


export type MutationDeleteActionArgs = {
  input: DeleteActionInput;
};


export type MutationDeleteBusinessObjectArgs = {
  input: DeleteBusinessObjectInput;
};


export type MutationDeleteBusinessObjectDefinitionArgs = {
  input: DeleteBusinessObjectDefinitionInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteIntegrationInstanceArgs = {
  input: DeleteIntegrationInstanceInput;
};


export type MutationDeleteProcessArgs = {
  input: DeleteProcessInput;
};


export type MutationDeleteScheduledEventArgs = {
  input: DeleteScheduledEventInput;
};


export type MutationDeleteViewArgs = {
  input: DeleteViewInput;
};


export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationDeployTemplateArgs = {
  input: DeployTemplateInput;
};


export type MutationDisconnectIntegrationArgs = {
  input: DisconnectIntegrationInput;
};


export type MutationEditFileArgs = {
  input: EditFileInput;
};


export type MutationMoveBusinessObjectToNextPhaseArgs = {
  input: MoveBusinessObjectToNextPhase;
};


export type MutationMoveBusinessObjectToPrevPhaseArgs = {
  input: MoveBusinessObjectToPrevPhase;
};


export type MutationPatchBoardArgs = {
  input: PatchBoardInput;
};


export type MutationPatchBusinessObjectDefinitionArgs = {
  input: PatchBusinessObjectDefinitionInput;
};


export type MutationPatchProcessArgs = {
  input: PatchProcessInput;
};


export type MutationPutProcessArgs = {
  input: PutProcessInput;
};


export type MutationRemoveBusinessObjectFromProcessArgs = {
  input: RemoveBusinessObjectFromProcessInput;
};


export type MutationRemoveUserFromTenantArgs = {
  input: RemoveUserFromTenantInput;
};


export type MutationSaveViewArgs = {
  input: SaveViewInput;
};


export type MutationScheduleActionArgs = {
  input: CreateActionInput;
  schedule: ScheduleInput;
};


export type MutationSendInviteArgs = {
  input: SendInviteInput;
};


export type MutationSetActiveTenantArgs = {
  input: SetActiveTenantInput;
};


export type MutationSetIdentityArgs = {
  input: SetIdentityInput;
};


export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};


export type MutationUpdateActionPhaseArgs = {
  input: UpdateActionPhaseInput;
};


export type MutationUpdateBusinessObjectFieldArgs = {
  input: UpdateBusinessObjectFieldInput;
};


export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserGroupMembersArgs = {
  input: UpdateUserGroupMembersInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationUpsertActionExtensionObjectArgs = {
  input: UpsertActionExtensionObjectInput;
};

export type NumberFieldConstraints = RangeConstraint | RequiredConstraint;

export type NumberFieldDefinition = FieldDefinition & {
  __typename?: 'NumberFieldDefinition';
  constraints: Array<NumberFieldConstraints>;
  defaultValue?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type NumberFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'NumberFieldDefinitionTemplate';
  constraints: Array<NumberFieldConstraints>;
  defaultValue?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type NumberOfSelectionsConstraint = FieldConstraint & {
  __typename?: 'NumberOfSelectionsConstraint';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  type: FieldConstraintType;
};

export enum Operator {
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  Includes = 'includes',
  IsDefined = 'isDefined',
  LessThan = 'lessThan'
}

export type OutgoingWebhookPortal = {
  __typename?: 'OutgoingWebhookPortal';
  token: Scalars['String'];
  url: Scalars['String'];
};

export type PatchBoardAddColumnInput = {
  label: Scalars['String'];
  order: BusinessObjectSorterInput;
  selector: BusinessObjectComparatorInput;
};

export type PatchBoardInput = {
  id: Scalars['ID'];
  operations: Array<PatchBoardOperationInput>;
};

export type PatchBoardOperationInput = {
  addColumn?: InputMaybe<PatchBoardAddColumnInput>;
  updateCardFormatter?: InputMaybe<PatchBoardUpdateCardFormatterInput>;
};

export type PatchBoardUpdateCardFormatterInput = {
  cardFormatter: BoardFormatterInput;
};

export type PatchBusinessObjectAddFieldInput = {
  nextValue: CreateBusinessObjectDefinitionField;
};

export type PatchBusinessObjectAddSelectFieldOption = {
  fieldId: Scalars['ID'];
  nextValue: Scalars['String'];
  previousValue: Array<Scalars['ID']>;
};

export type PatchBusinessObjectDefinitionBooleanFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['Boolean']>;
  previousValue?: InputMaybe<Scalars['Boolean']>;
};

export type PatchBusinessObjectDefinitionCurrencyFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<CreateBusinessObjectDefinitionCurrencyFieldDefaultValue>;
  previousValue?: InputMaybe<CreateBusinessObjectDefinitionCurrencyFieldDefaultValue>;
};

export type PatchBusinessObjectDefinitionDateFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<DefaultDateInput>;
  previousValue?: InputMaybe<DefaultDateInput>;
};

export type PatchBusinessObjectDefinitionEmailFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['String']>;
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchBusinessObjectDefinitionFieldDefaultInput = {
  boolean?: InputMaybe<PatchBusinessObjectDefinitionBooleanFieldDefault>;
  currency?: InputMaybe<PatchBusinessObjectDefinitionCurrencyFieldDefault>;
  date?: InputMaybe<PatchBusinessObjectDefinitionDateFieldDefault>;
  email?: InputMaybe<PatchBusinessObjectDefinitionEmailFieldDefault>;
  number?: InputMaybe<PatchBusinessObjectDefinitionNumberFieldDefault>;
  telephone?: InputMaybe<PatchBusinessObjectDefinitionTelephoneFieldDefault>;
  text?: InputMaybe<PatchBusinessObjectDefinitionTextFieldDefault>;
  url?: InputMaybe<PatchBusinessObjectDefinitionUrlFieldDefault>;
  user?: InputMaybe<PatchBusinessObjectDefinitionUserFieldDefault>;
};

export type PatchBusinessObjectDefinitionInput = {
  id: Scalars['ID'];
  operations: Array<PatchBusinessObjectDefinitionOperationInput>;
};

export type PatchBusinessObjectDefinitionNumberFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['Float']>;
  previousValue?: InputMaybe<Scalars['Float']>;
};

export type PatchBusinessObjectDefinitionOperationInput = {
  addField?: InputMaybe<PatchBusinessObjectAddFieldInput>;
  addSelectFieldOption?: InputMaybe<PatchBusinessObjectAddSelectFieldOption>;
  removeField?: InputMaybe<PatchBusinessObjectDefinitionRemoveFieldInput>;
  removeFieldConstraint?: InputMaybe<PatchBusinessObjectRemoveFieldConstraintInput>;
  updateDescription?: InputMaybe<PatchBusinessObjectUpdateDescriptionInput>;
  updateFieldDefault?: InputMaybe<PatchBusinessObjectDefinitionFieldDefaultInput>;
  updateFieldDescription?: InputMaybe<PatchBusinessObjectUpdateFieldDescriptionInput>;
  updateFieldName?: InputMaybe<PatchBusinessObjectUpdateFieldNameInput>;
  updateLabel?: InputMaybe<PatchBusinessObjectUpdateLabelInput>;
  updateName?: InputMaybe<PatchBusinessObjectUpdateNameInput>;
};

export type PatchBusinessObjectDefinitionRemoveFieldInput = {
  fieldId: Scalars['ID'];
};

export type PatchBusinessObjectDefinitionTelephoneFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<CreateBusinessObjectDefinitionTelephoneFieldDefaultValue>;
  previousValue?: InputMaybe<CreateBusinessObjectDefinitionTelephoneFieldDefaultValue>;
};

export type PatchBusinessObjectDefinitionTextFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['String']>;
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchBusinessObjectDefinitionUrlFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['String']>;
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchBusinessObjectDefinitionUserFieldDefault = {
  fieldId: Scalars['ID'];
  nextValue?: InputMaybe<Scalars['ID']>;
  previousValue?: InputMaybe<Scalars['ID']>;
};

export type PatchBusinessObjectRemoveFieldConstraintInput = {
  constraintType: FieldConstraintType;
  fieldId: Scalars['ID'];
};

export type PatchBusinessObjectUpdateDescriptionInput = {
  nextValue: Scalars['String'];
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchBusinessObjectUpdateFieldDescriptionInput = {
  fieldId: Scalars['ID'];
  nextValue: Scalars['String'];
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchBusinessObjectUpdateFieldNameInput = {
  fieldId: Scalars['ID'];
  nextValue: Scalars['String'];
  previousValue: Scalars['String'];
};

export type PatchBusinessObjectUpdateLabelInput = {
  nextValue: Scalars['String'];
  previousValue: Scalars['String'];
};

export type PatchBusinessObjectUpdateNameInput = {
  nextValue: Scalars['String'];
  previousValue: Scalars['String'];
};

export type PatchProcessAddPhaseCriterionInput = {
  nextValue: CreateCriterionInput;
  phaseIndex: Scalars['Int'];
  previousCriteria: Scalars['Int'];
};

export type PatchProcessInput = {
  id: Scalars['ID'];
  operations: Array<PatchProcessOperationInput>;
};

export type PatchProcessOperationInput = {
  addPhaseCriterion?: InputMaybe<PatchProcessAddPhaseCriterionInput>;
  changeConditions?: InputMaybe<ChangeConditionsInput>;
  renamePhaseCriterion?: InputMaybe<RenamePhaseCriterionInput>;
  updateBackstop?: InputMaybe<PatchProcessUpdateBackstopInput>;
  updateDefaultDueSeconds?: InputMaybe<UpdateDefaultDueSecondsInput>;
  updateDescription?: InputMaybe<PatchProcessUpdateDescriptionInput>;
  updateName?: InputMaybe<PatchProcessUpdateNameInput>;
};

export type PatchProcessUpdateBackstopInput = {
  nextValue?: InputMaybe<Scalars['String']>;
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchProcessUpdateDescriptionInput = {
  nextValue: Scalars['String'];
  previousValue?: InputMaybe<Scalars['String']>;
};

export type PatchProcessUpdateNameInput = {
  nextValue: Scalars['String'];
  previousValue: Scalars['String'];
};

export type Permissions = {
  __typename?: 'Permissions';
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type Phase = {
  __typename?: 'Phase';
  businessObjects: PhaseBusinessObjects;
  criteria: Array<PhaseCriterion>;
  description?: Maybe<Scalars['String']>;
  integrations: Array<IntegrationInstance>;
  isTerminalPhase: Scalars['Boolean'];
  name: Scalars['String'];
  phaseIndex: Scalars['Int'];
};


export type PhaseBusinessObjectsArgs = {
  input?: InputMaybe<BusinessObjectsInPhaseInput>;
};

export type PhaseBusinessObjects = {
  __typename?: 'PhaseBusinessObjects';
  meta: Meta;
  nodes: Array<BusinessObject>;
};

export type PhaseCriterion = {
  businessObjectOnCriterion?: Maybe<BusinessObjectOnPhaseCriterion>;
  type: PhaseCriterionType;
};


export type PhaseCriterionBusinessObjectOnCriterionArgs = {
  input: BusinessObjectOnPhaseCriterionInput;
};

export type PhaseCriterionActionRelatesTo = ActionRelatesTo & {
  __typename?: 'PhaseCriterionActionRelatesTo';
  businessObject: BusinessObject;
  criterion: PhaseCriterion;
  phase: Phase;
  process: Process;
  type: ActionRelation;
};

export type PhaseCriterionToggledHistoryEntry = BusinessObjectHistoryEntry & {
  __typename?: 'PhaseCriterionToggledHistoryEntry';
  businessObjectId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: Principal;
  eventType: BusinessObjectHistoryEventType;
  id: Scalars['ID'];
  payload: PhaseCriterionToggledPayload;
};

export type PhaseCriterionToggledPayload = {
  __typename?: 'PhaseCriterionToggledPayload';
  action: Action;
  process: Process;
  toPhase: ActionPhase;
};

export enum PhaseCriterionType {
  Action = 'action',
  FieldCondition = 'fieldCondition',
  ProcessDelegate = 'processDelegate',
  ProcessFanout = 'processFanout'
}

export type Principal = {
  id: Scalars['ID'];
  tenant: Tenant;
  type: PrincipalType;
};

export type PrincipalInput = {
  user?: InputMaybe<UserPrincipalInput>;
};

export enum PrincipalType {
  Group = 'group',
  User = 'user',
  Webhook = 'webhook'
}

export type Process = {
  __typename?: 'Process';
  backstop?: Maybe<Process>;
  canDelete: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  hasBusinessObjectCompleted: Scalars['Boolean'];
  id: Scalars['ID'];
  integrations: Array<IntegrationInstance>;
  name: Scalars['String'];
  operatesUpon: Array<BusinessObjectDefinition>;
  phases: Array<Phase>;
  usage: ProcessUsage;
};


export type ProcessHasBusinessObjectCompletedArgs = {
  input: HasBusinessObjectCompletedInput;
};

export type ProcessDelegatePhaseCriterion = PhaseCriterion & {
  __typename?: 'ProcessDelegatePhaseCriterion';
  businessObjectOnCriterion?: Maybe<BusinessObjectOnPhaseCriterion>;
  conditions?: Maybe<Array<BusinessObjectComparator>>;
  process: Process;
  transform?: Maybe<DelegationTransformer>;
  type: PhaseCriterionType;
};


export type ProcessDelegatePhaseCriterionBusinessObjectOnCriterionArgs = {
  input: BusinessObjectOnPhaseCriterionInput;
};

export type ProcessFanoutPhaseCriterion = PhaseCriterion & {
  __typename?: 'ProcessFanoutPhaseCriterion';
  businessObjectOnCriterion?: Maybe<BusinessObjectOnPhaseCriterion>;
  conditions?: Maybe<Array<BusinessObjectComparator>>;
  process: Process;
  transform?: Maybe<DelegationTransformer>;
  type: PhaseCriterionType;
};


export type ProcessFanoutPhaseCriterionBusinessObjectOnCriterionArgs = {
  input: BusinessObjectOnPhaseCriterionInput;
};

export type ProcessUsage = {
  __typename?: 'ProcessUsage';
  businessObjects: Scalars['Int'];
};

export type PutProcessInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  operatesUpon: Scalars['String'];
  phases: Array<CreatePhaseInput>;
};

export type Query = {
  __typename?: 'Query';
  action: Action;
  actionCount: Scalars['Int'];
  actionExecution: ActionExecution;
  actions: Array<Action>;
  availableIntegrations: Array<Integration>;
  board: Board;
  boards: Array<Board>;
  businessObject: BusinessObject;
  businessObjectCount: Scalars['Int'];
  businessObjectDefinition?: Maybe<BusinessObjectDefinition>;
  businessObjectDefinitionCount: Scalars['Int'];
  businessObjectDefinitionExtensions: Array<BusinessObjectDefinitionExtension>;
  businessObjectDefinitionSuggestion: BusinessObjectDefinitionTemplate;
  businessObjectDefinitionTemplates: Array<BusinessObjectDefinitionTemplate>;
  businessObjectDefinitions: Array<BusinessObjectDefinition>;
  businessObjectHistory: Array<BusinessObjectHistoryEntry>;
  businessObjectInProcessCount: Scalars['Int'];
  businessObjects: Array<BusinessObject>;
  connectedIntegrations: Array<Integration>;
  currencies: Array<Currency>;
  currentUser: User;
  file: File;
  files: Array<File>;
  integrationInstances: Array<IntegrationInstance>;
  integrationToken: IntegrationToken;
  invites: Array<Invite>;
  isUserSetup: Scalars['Boolean'];
  process: Process;
  processCount: Scalars['Int'];
  processes: Array<Process>;
  scheduledEvents: Array<ScheduledEvent>;
  suggestedPhases: SuggestedProcess;
  suggestedProcessAndDefinition: SuggestedProcessAndDefinition;
  uploadSignature: UploadSignature;
  user: User;
  userGroups: Array<UserGroup>;
  users: Array<User>;
  views: Array<View>;
  webhook: Webhook;
  webhookPortal: OutgoingWebhookPortal;
  webhooks: Array<Webhook>;
  workspaceTemplates: Array<AvailableTemplateCategory>;
};


export type QueryActionArgs = {
  input: QueryActionInput;
};


export type QueryActionExecutionArgs = {
  input: ActionExecutionInput;
};


export type QueryActionsArgs = {
  input?: InputMaybe<QueryActionsInput>;
};


export type QueryBoardArgs = {
  input: GetBoardInput;
};


export type QueryBusinessObjectArgs = {
  input: GetBusinessObjectInput;
};


export type QueryBusinessObjectCountArgs = {
  input: GetBusinessObjectsInput;
};


export type QueryBusinessObjectDefinitionArgs = {
  input: QueryBusinessObjectDefinitionInput;
};


export type QueryBusinessObjectDefinitionExtensionsArgs = {
  input: GetBusinessObjectDefinitionExtensionsInput;
};


export type QueryBusinessObjectDefinitionSuggestionArgs = {
  input: QueryBusinessObjectDefinitionSuggestionInput;
};


export type QueryBusinessObjectHistoryArgs = {
  input: BusinessObjectHistoryInput;
};


export type QueryBusinessObjectInProcessCountArgs = {
  input?: InputMaybe<BusinessObjectInProcessCountInput>;
};


export type QueryBusinessObjectsArgs = {
  input: GetBusinessObjectsInput;
};


export type QueryFileArgs = {
  input: GetFileInput;
};


export type QueryFilesArgs = {
  input?: InputMaybe<GetFilesInput>;
};


export type QueryIntegrationInstancesArgs = {
  input: FindIntegrationInstancesInput;
};


export type QueryProcessArgs = {
  input: GetProcessByIdInput;
};


export type QueryProcessCountArgs = {
  input?: InputMaybe<QueryProcessesInput>;
};


export type QueryProcessesArgs = {
  input?: InputMaybe<QueryProcessesInput>;
};


export type QuerySuggestedPhasesArgs = {
  input: SuggestedPhasesInput;
};


export type QuerySuggestedProcessAndDefinitionArgs = {
  input: SuggestedProcessAndDefinitionInput;
};


export type QueryUserArgs = {
  input: QueryUserInput;
};


export type QueryUsersArgs = {
  input?: InputMaybe<QueryUsersInput>;
};


export type QueryViewsArgs = {
  input: ViewsInput;
};


export type QueryWebhookArgs = {
  input: QueryWebhookByIdInput;
};


export type QueryWebhooksArgs = {
  input: QueryWebhooksInput;
};

export type QueryActionInput = {
  id: Scalars['ID'];
};

export type QueryActionsInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  dueBefore?: InputMaybe<Scalars['Date']>;
  noAssigneeOnly?: InputMaybe<Scalars['Boolean']>;
  noDueDateOnly?: InputMaybe<Scalars['Boolean']>;
  phase?: InputMaybe<ActionPhase>;
  relatesTo?: InputMaybe<QueryActionsRelatesTo>;
};

export type QueryActionsRelatesTo = {
  businessObjectId?: InputMaybe<Scalars['ID']>;
  criterionIndex?: InputMaybe<Scalars['Int']>;
  phaseIndex?: InputMaybe<Scalars['Int']>;
  processId?: InputMaybe<Scalars['ID']>;
};

export type QueryBusinessObjectDefinitionInput = {
  id: Scalars['String'];
};

export type QueryBusinessObjectDefinitionSuggestionInput = {
  prompt: Scalars['String'];
};

export type QueryProcessesInput = {
  businessObjectDefinitionId?: InputMaybe<Scalars['ID']>;
};

export type QueryUserInput = {
  id: Scalars['String'];
};

export type QueryUsersInput = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebhookByIdInput = {
  id: Scalars['ID'];
};

export type QueryWebhooksInput = {
  unused?: InputMaybe<Scalars['String']>;
};

export type RangeConstraint = FieldConstraint & {
  __typename?: 'RangeConstraint';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  type: FieldConstraintType;
};

export enum RecurringScheduleFrequency {
  Annually = 'annually',
  Daily = 'daily',
  Hourly = 'hourly',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type RecurringScheduleInput = {
  frequency: RecurringScheduleFrequency;
  from: Scalars['Date'];
  interval: Scalars['Int'];
  to?: InputMaybe<Scalars['Date']>;
};

export type RelationFieldConstraints = RelationTypeConstraint | RequiredConstraint;

export type RelationFieldDefinition = FieldDefinition & {
  __typename?: 'RelationFieldDefinition';
  constraints: Array<RelationFieldConstraints>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type RelationFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'RelationFieldDefinitionTemplate';
  constraints: Array<RelationFieldConstraints>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type RelationFieldDelegationTransformer = DelegationTransformer & {
  __typename?: 'RelationFieldDelegationTransformer';
  field: FieldDefinition;
  type: DelegationTransformerType;
};

export type RelationTypeConstraint = FieldConstraint & {
  __typename?: 'RelationTypeConstraint';
  type: FieldConstraintType;
  types: Array<BusinessObjectDefinition>;
};

export type RemoveBusinessObjectFromProcessInput = {
  businessObjectId: Scalars['ID'];
  processId: Scalars['ID'];
};

export type RemoveUserFromTenantInput = {
  userId: Scalars['ID'];
};

export type RemovedFromProcessPayload = {
  __typename?: 'RemovedFromProcessPayload';
  process: Process;
};

export type RenamePhaseCriterionInput = {
  criterionIndex: Scalars['Int'];
  nextValue: Scalars['String'];
  phaseIndex: Scalars['Int'];
  previousValue: Scalars['String'];
};

export type RequiredConstraint = FieldConstraint & {
  __typename?: 'RequiredConstraint';
  type: FieldConstraintType;
};

export type SandboxUser = {
  __typename?: 'SandboxUser';
  token: Scalars['String'];
  user: User;
};

export type SaveViewInput = {
  businessObjectDefinitionId?: InputMaybe<Scalars['String']>;
  configuration: Scalars['String'];
  name: Scalars['String'];
  processId?: InputMaybe<Scalars['String']>;
  type: ViewOfType;
};

export type ScheduleInput = {
  recurring?: InputMaybe<RecurringScheduleInput>;
  single?: InputMaybe<SingleScheduleInput>;
};

export type ScheduledEvent = {
  __typename?: 'ScheduledEvent';
  activity: Array<ScheduledEventActivity>;
  createdBy: Principal;
  id: Scalars['ID'];
  payload: ScheduledPayload;
  schedule: ScheduledEventSchedule;
};

export type ScheduledEventActivity = {
  __typename?: 'ScheduledEventActivity';
  activity: ScheduledEventActivityType;
  executionId: Scalars['ID'];
  timestamp: Scalars['Date'];
};

export enum ScheduledEventActivityType {
  Completed = 'completed',
  Failed = 'failed',
  Started = 'started'
}

export type ScheduledEventSchedule = {
  type: ScheduledEventScheduleType;
};

export type ScheduledEventScheduleRecurring = ScheduledEventSchedule & {
  __typename?: 'ScheduledEventScheduleRecurring';
  frequency: RecurringScheduleFrequency;
  from: Scalars['Date'];
  interval: Scalars['Int'];
  nextExecution?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  type: ScheduledEventScheduleType;
};

export type ScheduledEventScheduleSingle = ScheduledEventSchedule & {
  __typename?: 'ScheduledEventScheduleSingle';
  scheduledAt?: Maybe<Scalars['Date']>;
  type: ScheduledEventScheduleType;
};

export enum ScheduledEventScheduleType {
  Recurring = 'recurring',
  Single = 'single'
}

export type ScheduledPayload = {
  type: ScheduledPayloadType;
};

export enum ScheduledPayloadType {
  CreateAction = 'createAction'
}

export type SelectFieldConstraints = NumberOfSelectionsConstraint;

export type SelectFieldDefinition = FieldDefinition & {
  __typename?: 'SelectFieldDefinition';
  constraints: Array<SelectFieldConstraints>;
  defaultValue?: Maybe<SelectFieldValue>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<SelectFieldOption>;
  type: FieldType;
};

export type SelectFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'SelectFieldDefinitionTemplate';
  constraints: Array<SelectFieldConstraints>;
  defaultValue?: Maybe<SelectFieldValue>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  options: Array<SelectFieldOption>;
  type: FieldType;
};

export type SelectFieldOption = {
  __typename?: 'SelectFieldOption';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type SelectFieldValue = {
  __typename?: 'SelectFieldValue';
  selectedIds: Array<Scalars['String']>;
};

export type SendInviteInput = {
  email: Scalars['String'];
};

export type SetActiveTenantInput = {
  tenantId: Scalars['ID'];
};

export type SetIdentityInput = {
  email: Scalars['String'];
  identityId: Scalars['String'];
};

export type SingleScheduleInput = {
  scheduledAt: Scalars['Date'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type StorageProvider = {
  id: Scalars['String'];
  service: StorageService;
};

export enum StorageService {
  Uploadcare = 'uploadcare'
}

export type SuggestedFieldRequiredBy = {
  unused?: Maybe<Scalars['Boolean']>;
};

export type SuggestedFieldRequiredByInit = SuggestedFieldRequiredBy & {
  __typename?: 'SuggestedFieldRequiredByInit';
  init?: Maybe<Scalars['Boolean']>;
  unused?: Maybe<Scalars['Boolean']>;
};

export type SuggestedFieldRequiredByPhase = SuggestedFieldRequiredBy & {
  __typename?: 'SuggestedFieldRequiredByPhase';
  phase: Scalars['Int'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type SuggestedPhase = {
  __typename?: 'SuggestedPhase';
  criteria: Array<PhaseCriterion>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phaseIndex: Scalars['Int'];
};

export type SuggestedPhasesInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  operatesUpon: Scalars['ID'];
};

export type SuggestedProcess = {
  __typename?: 'SuggestedProcess';
  phases: Array<SuggestedPhase>;
};

export type SuggestedProcessAndDefinition = {
  __typename?: 'SuggestedProcessAndDefinition';
  fields: Array<SuggestedProcessField>;
  label: Scalars['String'];
  name: Scalars['String'];
  phases: Array<SuggestedPhase>;
};

export type SuggestedProcessAndDefinitionInput = {
  industry: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  purpose: Scalars['String'];
  size: Scalars['String'];
  team?: InputMaybe<Scalars['String']>;
};

export type SuggestedProcessField = {
  __typename?: 'SuggestedProcessField';
  field: FieldDefinitionTemplate;
  requiredBy: SuggestedFieldRequiredBy;
};

export type TelephoneFieldConstraints = RequiredConstraint;

export type TelephoneFieldDefinition = FieldDefinition & {
  __typename?: 'TelephoneFieldDefinition';
  constraints: Array<TelephoneFieldConstraints>;
  defaultValue?: Maybe<TelephoneFieldValue>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type TelephoneFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'TelephoneFieldDefinitionTemplate';
  constraints: Array<TelephoneFieldConstraints>;
  defaultValue?: Maybe<TelephoneFieldValue>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type TelephoneFieldValue = {
  __typename?: 'TelephoneFieldValue';
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type TemplateDefinition = {
  __typename?: 'TemplateDefinition';
  description?: Maybe<Scalars['String']>;
  fields: Array<FieldAddition>;
  name: Scalars['String'];
};

export type TemplateDefinitionDefinition = {
  __typename?: 'TemplateDefinitionDefinition';
  name: Scalars['String'];
};

export type TemplateProcess = {
  __typename?: 'TemplateProcess';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  openInvites: Array<Invite>;
};

export type TextFieldConstraints = LengthConstraint | RequiredConstraint;

export type TextFieldDefinition = FieldDefinition & {
  __typename?: 'TextFieldDefinition';
  constraints: Array<TextFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type TextFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'TextFieldDefinitionTemplate';
  constraints: Array<TextFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export enum TriggerEvent {
  ActionAssigned = 'actionAssigned',
  ActionCreated = 'actionCreated',
  ActionPhaseCriterionPhaseUpdated = 'actionPhaseCriterionPhaseUpdated',
  ActionPhaseUpdated = 'actionPhaseUpdated',
  ActionUsersMentionedOnDescription = 'actionUsersMentionedOnDescription',
  BusinessObjectCreated = 'businessObjectCreated',
  BusinessObjectDefinitionUpdated = 'businessObjectDefinitionUpdated',
  BusinessObjectFieldUpdated = 'businessObjectFieldUpdated',
  BusinessObjectUsersMentionedOnUpdate = 'businessObjectUsersMentionedOnUpdate',
  ProcessBoEnteredPhase = 'processBoEnteredPhase',
  ProcessBoEnteredProcess = 'processBoEnteredProcess',
  ProcessBoRemovedFromProcess = 'processBoRemovedFromProcess',
  TenantCreated = 'tenantCreated',
  UserCreated = 'userCreated',
  UserInvited = 'userInvited'
}

export type UrlFieldConstraints = RequiredConstraint;

export type UrlFieldDefinition = FieldDefinition & {
  __typename?: 'URLFieldDefinition';
  constraints: Array<UrlFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type UrlFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'URLFieldDefinitionTemplate';
  constraints: Array<UrlFieldConstraints>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type UpdateActionInput = {
  assignedTo?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionAttachments?: InputMaybe<Array<CreateActionDescriptionAttachmentInput>>;
  dueDate?: InputMaybe<Scalars['Date']>;
  extension?: InputMaybe<UpdateBusinessObjectInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateActionPhaseInput = {
  id: Scalars['ID'];
  phase: ActionPhase;
};

export type UpdateAttachment = {
  file?: InputMaybe<Scalars['ID']>;
};

export type UpdateAttachmentBase = {
  type: UpdateAttachmentType;
};

export type UpdateAttachmentFile = UpdateAttachmentBase & {
  __typename?: 'UpdateAttachmentFile';
  file: File;
  type: UpdateAttachmentType;
};

export enum UpdateAttachmentType {
  File = 'file'
}

export type UpdateBusinessObjectBooleanField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBusinessObjectCurrencyField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<UpdateBusinessObjectCurrencyFieldValue>;
  value?: InputMaybe<UpdateBusinessObjectCurrencyFieldValue>;
};

export type UpdateBusinessObjectCurrencyFieldValue = {
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type UpdateBusinessObjectDateField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['Date']>;
  value?: InputMaybe<Scalars['Date']>;
};

export type UpdateBusinessObjectDocumentField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['ID']>;
};

export type UpdateBusinessObjectEmailField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessObjectField = {
  boolean?: InputMaybe<UpdateBusinessObjectBooleanField>;
  currency?: InputMaybe<UpdateBusinessObjectCurrencyField>;
  date?: InputMaybe<UpdateBusinessObjectDateField>;
  document?: InputMaybe<UpdateBusinessObjectDocumentField>;
  email?: InputMaybe<UpdateBusinessObjectEmailField>;
  list?: InputMaybe<UpdateBusinessObjectListField>;
  number?: InputMaybe<UpdateBusinessObjectNumberField>;
  relation?: InputMaybe<UpdateBusinessObjectRelationField>;
  select?: InputMaybe<UpdateBusinessObjectSelectField>;
  telephone?: InputMaybe<UpdateBusinessObjectTelephoneField>;
  text?: InputMaybe<UpdateBusinessObjectTextField>;
  updates?: InputMaybe<UpdateBusinessObjectUpdatesField>;
  url?: InputMaybe<UpdateBusinessObjectUrlField>;
  user?: InputMaybe<UpdateBusinessObjectUserField>;
};

export type UpdateBusinessObjectFieldInput = {
  field: UpdateBusinessObjectField;
  id: Scalars['ID'];
};

export type UpdateBusinessObjectInput = {
  fields: Array<UpdateBusinessObjectField>;
  id: Scalars['ID'];
};

export type UpdateBusinessObjectListField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Array<UpdateBusinessObjectListFieldValue>>;
  value: Array<UpdateBusinessObjectListFieldValue>;
};

export type UpdateBusinessObjectListFieldValue = {
  boolean?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<UpdateBusinessObjectCurrencyFieldValue>;
  date?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Float']>;
  relation?: InputMaybe<Scalars['ID']>;
  select?: InputMaybe<Array<Scalars['String']>>;
  telephone?: InputMaybe<UpdateBusinessObjectTelephoneFieldValue>;
  text?: InputMaybe<Scalars['String']>;
  updates?: InputMaybe<UpdateBusinessObjectUpdatesFieldValue>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type UpdateBusinessObjectNumberField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateBusinessObjectRelationField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['ID']>;
};

export type UpdateBusinessObjectSelectField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Array<Scalars['String']>>;
  value: Array<Scalars['String']>;
};

export type UpdateBusinessObjectTelephoneField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<UpdateBusinessObjectTelephoneFieldValue>;
  value?: InputMaybe<UpdateBusinessObjectTelephoneFieldValue>;
};

export type UpdateBusinessObjectTelephoneFieldValue = {
  countryCode?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessObjectTextField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessObjectUpdatesField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<UpdateBusinessObjectUpdatesFieldValue>;
  value: UpdateBusinessObjectUpdatesFieldValue;
};

export type UpdateBusinessObjectUpdatesFieldValue = {
  attachments?: InputMaybe<Array<UpdateAttachment>>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  richText: Scalars['Json'];
};

export type UpdateBusinessObjectUrlField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessObjectUserField = {
  fieldDefinitionId: Scalars['ID'];
  previousValue?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['ID']>;
};

export type UpdateDefaultDueSecondsInput = {
  criterionIndex: Scalars['Int'];
  nextValue: Scalars['Int'];
  phaseIndex: Scalars['Int'];
  previousValue?: InputMaybe<Scalars['Int']>;
};

export type UpdateTenantInput = {
  name: Scalars['String'];
};

export type UpdateUserGroupMembersInput = {
  principals: Array<Scalars['ID']>;
  userGroupId: Scalars['ID'];
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateWebhookInput = {
  actions?: InputMaybe<Array<CreateWebhookActionInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  signingSecret?: InputMaybe<Scalars['String']>;
};

export type UpdatesFieldDefinition = FieldDefinition & {
  __typename?: 'UpdatesFieldDefinition';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type UpdatesFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'UpdatesFieldDefinitionTemplate';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type UploadSignature = {
  __typename?: 'UploadSignature';
  expire: Scalars['String'];
  signature: Scalars['String'];
};

export type UpsertActionExtensionObjectInput = {
  businessObjectDefinitionId: Scalars['String'];
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  activeTenant?: Maybe<UserTenancy>;
  email: Scalars['String'];
  id: Scalars['ID'];
  invites: Array<Invite>;
  name: Scalars['String'];
  principal: UserPrincipal;
  tenancies: Array<UserTenancy>;
};

export type UserFieldConstraints = RequiredConstraint;

export type UserFieldDefinition = FieldDefinition & {
  __typename?: 'UserFieldDefinition';
  constraints: Array<UserFieldConstraints>;
  defaultValue?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type UserFieldDefinitionTemplate = FieldDefinitionTemplate & {
  __typename?: 'UserFieldDefinitionTemplate';
  constraints: Array<UserFieldConstraints>;
  defaultValue?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: FieldType;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  principal: UserGroupPrincipal;
  users: Array<UserGroupUser>;
};

export type UserGroupPrincipal = Principal & {
  __typename?: 'UserGroupPrincipal';
  id: Scalars['ID'];
  tenant: Tenant;
  type: PrincipalType;
  userGroup: UserGroup;
};

export type UserGroupUser = {
  __typename?: 'UserGroupUser';
  id: Scalars['ID'];
  name: Scalars['String'];
  principal: UserGroupUserPrincipal;
};

export type UserGroupUserPrincipal = {
  __typename?: 'UserGroupUserPrincipal';
  id: Scalars['ID'];
};

export type UserPrincipal = Principal & {
  __typename?: 'UserPrincipal';
  id: Scalars['ID'];
  tenant: Tenant;
  type: PrincipalType;
  user: User;
};

export type UserPrincipalInput = {
  userId: Scalars['ID'];
};

export type UserTenancy = {
  __typename?: 'UserTenancy';
  tenant: Tenant;
};

export enum ValueSelectorType {
  Constant = 'constant',
  Deep = 'deep',
  FieldValue = 'fieldValue'
}

export type View = {
  __typename?: 'View';
  configuration: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  viewOf: ViewOf;
};

export type ViewOf = ViewOfActions | ViewOfBusinessObjects | ViewOfProcess;

export type ViewOfActions = {
  __typename?: 'ViewOfActions';
  isViewOfActions: Scalars['Boolean'];
};

export type ViewOfBusinessObjects = {
  __typename?: 'ViewOfBusinessObjects';
  businessObjectDefinition: BusinessObjectDefinition;
};

export type ViewOfProcess = {
  __typename?: 'ViewOfProcess';
  process: Process;
};

export enum ViewOfType {
  Actions = 'actions',
  BusinessObjects = 'businessObjects',
  Process = 'process'
}

export type ViewsInput = {
  businessObjectDefinitionId?: InputMaybe<Scalars['String']>;
  processId?: InputMaybe<Scalars['String']>;
  type: ViewOfType;
};

export type Webhook = {
  __typename?: 'Webhook';
  actions: Array<WebhookAction>;
  executions: Array<WebhookExecution>;
  hasSigningSecret: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type WebhookAction = {
  type: WebhookActionType;
};

export enum WebhookActionType {
  AddBusinessObjectToProcess = 'addBusinessObjectToProcess',
  CreateAction = 'createAction',
  CreateBusinessObject = 'createBusinessObject',
  Filter = 'filter'
}

export type WebhookExecution = {
  __typename?: 'WebhookExecution';
  createdAt: Scalars['Date'];
  result: Scalars['Json'];
};

export type WebhookPrincipal = Principal & {
  __typename?: 'WebhookPrincipal';
  id: Scalars['ID'];
  tenant: Tenant;
  type: PrincipalType;
  webhook: Webhook;
};
