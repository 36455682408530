import { FC } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'

type Props = {
    isFreshTenant: boolean
}

export const Init: FC<Props> = ({ isFreshTenant }) => {
    const [params] = useSearchParams()

    const category = params.get('category')
    const templates = params.get('templates')

    return match({ isFreshTenant, category, templates })
        .with(
            { isFreshTenant: true, category: P.string, templates: P.string },
            ({ category, templates }) => (
                <Navigate to={`welcome/${category}?templates=${templates}`} />
            )
        )
        .with({ isFreshTenant: true, category: P.string }, ({ category }) => (
            <Navigate to={`welcome/${category}`} />
        ))
        .with({ isFreshTenant: true }, () => <Navigate to="welcome" />)
        .with({ isFreshTenant: false }, () => <Navigate to="processes" />)
        .exhaustive()
}
