import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { regular5 } from 'Components/Text'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { BizObjectDefFieldsFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { pipStyles } from './styles'

type Props = {
    fields: BizObjectDefFieldsFragment['fields']
}

export const Sorting: FC<Props> = ({ fields }) => {
    const [open, setOpen] = useState(false)

    const {
        toggleSort,
        sort: [by, order],
        hasSort,
        clearSort,
    } = useBusinessObjectsView()

    return (
        <>
            <StyledSortMenuButton
                $hasSort={hasSort}
                icon="Sort"
                text="Sort"
                size="xSmall"
                onClick={() => setOpen(true)}
                variant="secondary"
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                <VerticalScrollArea height="100%" width="100%">
                    <StyledSortingControl>
                        <StyledSortButton
                            onClick={() => {
                                toggleSort('label')
                            }}
                        >
                            <span>Label</span>

                            {by === 'label' ? (
                                <Icon
                                    name={
                                        order === 'asc'
                                            ? 'ArrowUp'
                                            : 'ArrowDown'
                                    }
                                />
                            ) : null}
                        </StyledSortButton>

                        {fields.map(field => (
                            <StyledSortButton
                                onClick={() => {
                                    toggleSort(field.name)
                                }}
                            >
                                <span>{field.name}</span>

                                {by === field.name ? (
                                    <Icon
                                        name={
                                            order === 'asc'
                                                ? 'ArrowUp'
                                                : 'ArrowDown'
                                        }
                                    />
                                ) : null}
                            </StyledSortButton>
                        ))}

                        <StyledFooter>
                            <TextButton
                                text="Clear"
                                onClick={() => {
                                    setOpen(false)
                                    clearSort()
                                }}
                                size="xSmall"
                                variant="secondary"
                            />

                            <TextButton
                                text="Done"
                                onClick={() => setOpen(false)}
                                size="xSmall"
                                variant="primary"
                            />
                        </StyledFooter>
                    </StyledSortingControl>
                </VerticalScrollArea>
            </MinimalModal>
        </>
    )
}

const StyledSortMenuButton = styled(IconTextButton)<{ $hasSort: boolean }>`
    ${({ $hasSort }) => $hasSort && pipStyles};
`

const StyledSortingControl = styled.div`
    width: 100%;
    padding: 1rem;
`

const StyledSortButton = styled.button`
    ${flex('row', 'space-between', 'center')};
    gap: 0.5rem;
    ${regular5};
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    height: fit-content;
    width: 100%;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    border-radius: ${({ theme }) => theme.borderRadius.small};

    &[data-disabled] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    .icon {
        > svg {
            height: 1rem;
            width: 1rem;
            min-width: 0;
            min-height: 0;
        }
    }
`

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    padding-top: 0.75rem;
    gap: 1rem;

    button {
        flex: 1;
    }
`
