import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { fromEvent } from 'Utils'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useActionsView } from '../../../../../../../Features/ActionView/useActionsView'
import { useSaveActionViewMutation } from '../../../__generated__/mutation'

export const SaveView = () => {
    const [searchParams] = useSearchParams()

    const { filterState } = useBusinessObjectFilterState()

    const { grouping } = useActionsView()

    const [viewName, setViewName] = useState('')

    const [open, setOpen] = useState(false)

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const {
        open: successToastOpen,
        setOpen: setSuccessToastOpen,
        triggerToast: triggerSuccessToast,
    } = useTriggerToast()

    const [saveActionView] = useSaveActionViewMutation({
        onCompleted: () => {
            setOpen(false)
            triggerSuccessToast()
            setViewName('')
        },
        onError: () => {
            triggerErrorToast()
        },
        refetchQueries: ['GetActionViews'],
    })

    return (
        <>
            <IconTextButton
                icon="Save"
                text="Save view"
                onClick={() => setOpen(true)}
                size="xSmall"
            />

            <MinimalModal open={open} onOpenChange={setOpen}>
                <StyledModalContent>
                    <div>
                        <Text as="label" variant="bold-5">
                            View name
                        </Text>
                        <TextInput
                            value={viewName}
                            onChange={e => setViewName(fromEvent(e))}
                        />
                    </div>
                    <TextButton
                        text="Save view"
                        onClick={() => {
                            saveActionView({
                                variables: {
                                    input: {
                                        type: ViewOfType.Actions,
                                        configuration: Buffer.from(
                                            JSON.stringify({
                                                ...convertSearchParamsToObject(
                                                    searchParams
                                                ),
                                                filterState,
                                                ...(grouping && {
                                                    grouping,
                                                }),
                                            })
                                        ).toString('base64'),
                                        name: viewName,
                                    },
                                },
                            })
                        }}
                        size="small"
                        disabled={!viewName}
                    />
                </StyledModalContent>
            </MinimalModal>

            <SimpleToast
                open={successToastOpen}
                onOpenChange={setSuccessToastOpen}
                description={'Successfully saved view'}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'Unable to save the view. Contact us if the problem persists.'
                }
            />
        </>
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    overflow: auto;
    border: 0;
    padding: 1rem 4vw;

    > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.25rem;
        align-items: stretch;
    }

    button {
        margin-top: 0.5rem;
        width: 100%;
    }
`
