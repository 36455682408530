import { Text } from 'Components/Text'
import { FC } from 'react'
import { customModelledFields } from '../../../Utils'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const ListField: FC<
    Field<'ListFieldDefinition'> & AbstractFieldChangeProps
> = ({ listOf, ...common }) =>
    customModelledFields.has(listOf.type) ? null : (
        <AbstractField
            {...common}
            customRows={[
                {
                    label: 'Field name',
                    element: (
                        <Text as="p" variant="regular-4">
                            {listOf.name}
                        </Text>
                    ),
                },
                {
                    label: 'Field type',
                    element: (
                        <Text as="p" variant="regular-4">
                            {listOf.type}
                        </Text>
                    ),
                },
            ]}
        />
    )
