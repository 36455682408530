import { FC } from 'react'
import { generateInitials } from './helpers'
import { StyledAvatar } from './styles'
import { AvatarColorVariant, AvatarSize } from './types'

type Props = {
    name: string
    size?: AvatarSize
    noOfInitials?: 1 | 2
    variant?: AvatarColorVariant
    className?: string
}

export const Avatar: FC<Props> = ({
    name,
    noOfInitials = 1,
    className = '',
    variant = 'grey',
    size = 'medium',
}) => {
    const initials = generateInitials(name, noOfInitials)

    return (
        <StyledAvatar
            title={name}
            className={className}
            $variant={variant}
            $size={size}
        >
            <span>{initials}</span>
        </StyledAvatar>
    )
}
