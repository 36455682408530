import { styled } from 'Adapters/Freestyled'
import { sanitiseExistingDefinitionSourceData } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { BusinessObjectDefinitionDisplayQuery } from '../__generated__/q'
import { DefinitionFields } from './DefinitionFields'
import { DefinitionHeader } from './Header'

type Props = {
    definition: NonNullable<
        BusinessObjectDefinitionDisplayQuery['businessObjectDefinition']
    >
}
const Definition: FC<Props> = ({ definition: d }) => {
    const { definition, enableUpdates, enableDocuments } =
        sanitiseExistingDefinitionSourceData()(d)
    return (
        <Styled>
            <DefinitionHeader
                definition={definition}
                updatesEnabled={enableUpdates}
                documentsEnabled={enableDocuments}
            />
            <DefinitionFields definition={definition} />
        </Styled>
    )
}

const Styled = styled.div`
    padding: 2rem 2rem 0;
    background-image: linear-gradient(
        175deg,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );
    border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    overflow: auto;
`

export { Definition }
