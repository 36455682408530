import { dayjs } from 'Adapters/DayJS'
import { omit } from 'lodash'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'
import { oneOfPair } from 'Utils'

type DateDefaultValue =
    | {
          __typename?: 'DefaultDate' | undefined
          absolute?: string | null | undefined
          relative?: string | null | undefined
      }
    | null
    | undefined

type DefaultTuple =
    | [undefined, undefined]
    | ['absolute', string]
    | ['relative', string]

export const useDefaultDate = (dateDefaultValue: DateDefaultValue) => {
    const defaultValue = useMemo(() => {
        if (!dateDefaultValue) return [undefined, undefined]
        return oneOfPair(omit(dateDefaultValue, '__typename'))
    }, [dateDefaultValue]) satisfies DefaultTuple

    const defaultValueText = useMemo(
        () =>
            match(defaultValue)
                .with([undefined, undefined], () => 'No default value set')
                .with(['absolute', P.string], defaultValue =>
                    dayjs(defaultValue[1]).format('DD MMM YYYY')
                )
                .with(['relative', P.string], defaultValue =>
                    dayjs.duration(defaultValue[1]).humanize(true)
                )
                .exhaustive(),
        [defaultValue]
    )

    return {
        defaultValue,
        defaultValueText,
    }
}
