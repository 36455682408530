import * as Accordion from '@radix-ui/react-accordion'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StyledInputGroup, StyledItem, StyledTrigger } from './styles'
import { useUserOptions } from './useUserOptions'

export const CreatedByFilterControls = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const createdBy = searchParams.get('createdBy')

    const updateCreatedByFilter = useCallback(
        (value: string) => {
            setSearchParams(prev => {
                const prevAsObject = convertSearchParamsToObject(prev)

                if (!value) {
                    const { createdBy: _, ...rest } = prevAsObject
                    return rest
                }

                return { ...prevAsObject, createdBy: value }
            })
        },
        [setSearchParams]
    )

    const userOptions = useUserOptions()

    return (
        <StyledItem value={'createdBy'} key={'createdBy'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Created by :
                </Text>

                {createdBy ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledInputGroup>
                    <Text
                        as="label"
                        variant="bold-6"
                        htmlFor="created-by-select"
                    >
                        User :
                    </Text>

                    <Select
                        id="created-by-select"
                        onValueChange={updateCreatedByFilter}
                        value={createdBy ?? ''}
                        name="created-by-select"
                        options={userOptions}
                    />
                </StyledInputGroup>
            </Accordion.Content>
        </StyledItem>
    )
}
