import { useFetchBusinessObjectDefinitionByIdQuery } from './__generated__/queries'

type Params = {
    id: string
}

export const useFetchBusinessObjectDefinitionFieldsById = ({ id }: Params) => {
    const { data } = useFetchBusinessObjectDefinitionByIdQuery({
        variables: { input: { id } },
    })

    return {
        businessObjectDefnitionId: data?.businessObjectDefinition?.id ?? '',
        fields: data?.businessObjectDefinition?.fields ?? [],
        hasInitiallyLoaded: data !== undefined,
    }
}
