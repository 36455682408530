import { flex } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { countryCodeOptions, fromEvent } from 'Utils'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { FC } from 'react'
import styled from 'styled-components'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

const TelephoneField: FC<
    Field<'TelephoneFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: Pick<
                    NonNullable<
                        PatchBusinessObjectDefinitionFieldDefaultInput['telephone']
                    >,
                    'fieldId' | 'nextValue'
                >
            ) => void
        }
> = ({
    telephoneConstraints,
    telephoneDefaultValue,
    handleDefaultChanged,
    ...common
}) => {
    return (
        <AbstractField
            {...common}
            defaultDisplay={
                common.isEditing ? (
                    <StyledTelInput>
                        <Select
                            id={common.id + 'countrycode'}
                            value={telephoneDefaultValue?.countryCode ?? ''}
                            onValueChange={code => {
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    nextValue: {
                                        countryCode: code || null,
                                        number:
                                            telephoneDefaultValue?.number ||
                                            null,
                                    },
                                })
                            }}
                            name={'Country Code'}
                            options={countryCodeOptions}
                        />
                        <TextInput
                            value={telephoneDefaultValue?.number ?? ''}
                            id={common.id}
                            onChange={e => {
                                const value = fromEvent(e)

                                handleDefaultChanged({
                                    fieldId: common.id,
                                    nextValue: {
                                        countryCode:
                                            telephoneDefaultValue?.countryCode ||
                                            null,
                                        number: value || null,
                                    },
                                })
                            }}
                            className="tel-number-input"
                        />
                    </StyledTelInput>
                ) : (
                    <DefaultDisplay
                        telephoneDefaultValue={telephoneDefaultValue}
                    />
                )
            }
            constraints={telephoneConstraints}
        />
    )
}

const DefaultDisplay: FC<{
    telephoneDefaultValue: Field<'TelephoneFieldDefinition'>['telephoneDefaultValue']
}> = ({ telephoneDefaultValue }) => {
    const hasDefault =
        telephoneDefaultValue?.countryCode || telephoneDefaultValue?.number

    return hasDefault ? (
        <StyledDefault>
            <Text as="span" variant="regular-4">
                {telephoneDefaultValue.countryCode ?? ''}
            </Text>
            <Text as="span" variant="regular-4">
                {telephoneDefaultValue.number ?? ''}
            </Text>
        </StyledDefault>
    ) : (
        <Text as="p" variant="regular-4" className="none-set">
            No default value set
        </Text>
    )
}

const StyledDefault = styled.p`
    display: flex;
    gap: 0.5rem;

    span {
        display: inline-block;
    }
`

const StyledTelInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .tel-number-input {
        flex: 1;
        height: 2.75rem;
    }
`

export { TelephoneField }
