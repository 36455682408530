import { styled } from 'Adapters/Freestyled'
import { DropDownTextButton } from 'Components/Button/DropDownTextButton'
import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { MinimalModal } from 'Components/Modal/Minimal'
import { CenteredSpinner } from 'Components/Spinner'
import {
    Actions,
    Documents,
    History,
    Overview,
    Processes,
} from 'Features/SingleBusinessObjectTabs'
import { Updates } from 'Features/Updates'
import { FC, useState } from 'react'
import { match } from 'ts-pattern'
import { NotInSandbox } from '../NotInSandbox'
import { Details } from './Details'
import { BOHeader } from './Header'
import { tabs } from './config'

type Props = {
    isOpen: boolean
    close: () => void
    id: string | undefined
    tab: keyof typeof tabs
    onSelect: (tab: keyof typeof tabs) => void
    businessObjectDefinitionId?: string
    processId?: string
}

const Small: FC<Props> = ({
    tab,
    isOpen,
    processId,
    id,
    close,
    onSelect,
    businessObjectDefinitionId,
}) => {
    const [navOpen, setNavOpen] = useState(false)
    return (
        <MinimalModal
            open={isOpen}
            onOpenChange={o => !o && close()}
            fullScreen
        >
            {id ? (
                <Styled>
                    <BOHeader
                        id={id}
                        onDelete={close}
                        showDelete={!processId}
                    />
                    <StyledSmallNav>
                        <DropDownTextButton
                            text={tabs[tab].label}
                            onClick={() => setNavOpen(true)}
                        />
                        <MinimalModal open={navOpen} onOpenChange={setNavOpen}>
                            <nav>
                                {Object.entries(tabs).map(
                                    ([key, { label, description, icon }]) =>
                                        key === 'overview' &&
                                        !processId ? null : (
                                            <MenuNavButton
                                                key={key}
                                                title={label}
                                                description={description}
                                                onClick={() => {
                                                    onSelect(
                                                        key as keyof typeof tabs
                                                    )
                                                    setNavOpen(false)
                                                }}
                                                current={key === tab}
                                                icon={icon}
                                                secondIcon={
                                                    key === tab
                                                        ? 'Bullet'
                                                        : undefined
                                                }
                                            />
                                        )
                                )}
                            </nav>
                        </MinimalModal>
                    </StyledSmallNav>
                    <StyledContent>
                        {match(tab)
                            .with('overview', () =>
                                processId ? (
                                    <Overview
                                        businessObjectId={id}
                                        processId={processId}
                                        onRemoveFromProcess={close}
                                    />
                                ) : null
                            )
                            .with('details', () => (
                                <Details businessObjectId={id} />
                            ))
                            .with('actions', () => (
                                <Actions
                                    businessObjectId={id}
                                    actionsQueryInput={{
                                        relatesTo: {
                                            businessObjectId: id,
                                        },
                                    }}
                                />
                            ))
                            .with('documents', () => (
                                <NotInSandbox>
                                    <Documents businessObjectId={id} />
                                </NotInSandbox>
                            ))
                            .with('updates', () => (
                                <StyledUpdates businessObjectId={id} />
                            ))
                            .with('history', () => (
                                <StyledHistory businessObjectId={id} />
                            ))
                            .with('processes', () =>
                                businessObjectDefinitionId ? (
                                    <StyledProcesses
                                        businessObjectId={id}
                                        businessObjectDefinitionId={
                                            businessObjectDefinitionId
                                        }
                                    />
                                ) : null
                            )
                            .exhaustive()}
                    </StyledContent>
                </Styled>
            ) : (
                <CenteredSpinner />
            )}
        </MinimalModal>
    )
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const StyledContent = styled.div`
    flex: 1;
    overflow: auto;
`

const StyledSmallNav = styled.div`
    padding: 0 4vw 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
`

const StyledUpdates = styled(Updates)`
    padding: 1rem 4vw 0;
    height: 100%;
`

const StyledHistory = styled(History)`
    padding: 1rem 4vw 0;
    height: 100%;
`

const StyledProcesses = styled(Processes)`
    padding: 1rem 4vw 0;
    height: 100%;
`

export { Small }
