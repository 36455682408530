import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { FC } from 'react'
import { Complete } from '../Complete'
import { useSingleBoNextPhaseMutation } from './__generated__/SingleBoNextPhase'

type Props = {
    businessObjectId: string
    processId: string
    isTerminalPhase: boolean
    onPhaseUpdated: () => void
}

export const MoveToNextPhase: FC<Props> = ({
    businessObjectId,
    processId,
    isTerminalPhase,
    onPhaseUpdated,
}) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const [move, { loading }] = useSingleBoNextPhaseMutation({
        variables: {
            input: { businessObjectId, processId },
            onProcessInput: { processId },
        },
        onError(error) {
            logger.error('Failed to move BO to next phase', error, {
                businessObjectId,
                processId,
            })
            triggerToast()
        },
        onCompleted: onPhaseUpdated,
        onQueryUpdated: q => q.refetch(),
    })

    if (isTerminalPhase) return <Complete />

    return (
        <>
            <StyledMoveToNextPhaseButton
                text={'Go to next phase'}
                onClick={() => {
                    move()
                }}
                disabled={loading}
                size="small"
                icon="ArrowRight"
                variant="primary"
                type="button"
            />
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to to move to next phase"
            />
        </>
    )
}

const StyledMoveToNextPhaseButton = styled(IconTextButton)`
    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal} !important;
    }
`
