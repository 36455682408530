import { useTriggerToast } from 'Components/Toast'
import { ProcessDisplayFragment } from 'Features/ProcessDisplay/__generated__/q'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ViewConfig } from '../../types'
import { useBuildConfig } from '../../utils'
import {
    ProcessSingle_ViewFragmentDoc,
    useProcessSingle_SaveViewMutation,
} from './__generated__/q'

const formatConfigForSave = (config: ViewConfig) =>
    Buffer.from(JSON.stringify(config)).toString('base64')

type UseSaveView = (process: ProcessDisplayFragment) => {
    setLabel: React.Dispatch<React.SetStateAction<string>>
    canSave: boolean
    saveView: () => void
    showSuccess: boolean
    setShowSuccess: (open: boolean) => void
    showError: boolean
    setShowError: (open: boolean) => void
}

export const useSaveView: UseSaveView = process => {
    const {
        open: showSuccess,
        setOpen: setShowSuccess,
        triggerToast: triggerSuccessToast,
    } = useTriggerToast()
    const {
        open: showError,
        setOpen: setShowError,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()
    const [urlSearchParams] = useSearchParams()
    const [save] = useProcessSingle_SaveViewMutation()
    const [label, setLabel] = useState('')

    const buildConfig = useBuildConfig(process)

    const saveView = useCallback<ReturnType<UseSaveView>['saveView']>(() => {
        save({
            variables: {
                input: {
                    type: ViewOfType.Process,
                    processId: process.id,
                    configuration: formatConfigForSave(
                        buildConfig(urlSearchParams)
                    ),
                    name: label,
                },
            },
            onCompleted: () => {
                triggerSuccessToast()
            },
            onError: () => {
                triggerErrorToast()
            },
            onQueryUpdated: q => q.refetch(),
            update: (cache, result) => {
                cache.modify({
                    fields: {
                        views(existing = []) {
                            const newView = cache.writeFragment({
                                data: result.data?.saveView,
                                fragment: ProcessSingle_ViewFragmentDoc,
                                fragmentName: 'ProcessSingle_View',
                            })
                            return [...existing, newView]
                        },
                    },
                })
            },
        })
    }, [
        save,
        process.id,
        buildConfig,
        urlSearchParams,
        triggerSuccessToast,
        triggerErrorToast,
        label,
    ])

    return {
        setLabel,
        canSave: !!label,
        saveView,
        showSuccess,
        setShowSuccess,
        showError,
        setShowError,
    }
}
