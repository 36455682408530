import * as Popover from '@radix-ui/react-popover'
import { flex, styled } from 'Adapters/Freestyled'
import { keyframes } from 'styled-components'

const DURATION = 400
const TIMING = 'cubic-bezier(0.16, 1, 0.3, 1)'

const slideOutFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideBackToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-1rem);
  }
`

export const StyledContent = styled(Popover.Content)`
    max-height: 75vh;
    width: 30rem;
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: white;
    padding: 1rem 1rem 2rem 2rem;
    position: relative;
    animation-duration: ${DURATION}ms;
    animation-timing-function: ${TIMING};
    will-change: opacity, transform;

    header {
        ${flex('row', 'space-between', 'center')};
        padding-bottom: 0.75rem;

        .buttons {
            ${flex('row', 'flex-start', 'center')};
            gap: 0.25rem;
        }
    }

    &[data-state='open'][data-side='right'] {
        animation-name: ${slideOutFromLeft};
    }

    &[data-state='closed'][data-side='right'] {
        animation-name: ${slideBackToLeft};
    }
`
