import { first } from 'lodash'
import { pipe } from 'lodash/fp'
import { match, P } from 'ts-pattern'
import { Operator } from '../../__generated__'
import { styled } from '../../Adapters/Freestyled'
import { byTypename, fromEvent } from '../../Utils'
import { NumberInput, TextInput } from '../Input'
import { Select } from '../Select'
import { DateSelector } from './DateSelector'
import { RelationSelector } from './RelationSelector'
import { FilterableField } from './useFilterableFields'
import { UserSelector } from './UserSelector'

type Props = {
    id: string
    index: number
    field: FilterableField | undefined
    onValueChanged: (value: unknown) => void
    value: unknown | undefined
    operator: Operator | undefined
}

export const ValueSelector: React.FC<Props> = ({
    id,
    index,
    field,
    operator,
    value,
    onValueChanged,
}) => {
    return (
        <StyledFieldWrapper>
            {match({ field, operator, value })
                .with({ operator: Operator.IsDefined }, () => null)
                .with(
                    {
                        field: {
                            __typename: P.union(
                                'TextFieldDefinition',
                                'EmailFieldDefinition',
                                'URLFieldDefinition'
                            ),
                        },
                        value: P.union(P.string, undefined),
                    },
                    ({ value }) => (
                        <TextInput
                            id={id}
                            value={(value as string) ?? ''}
                            onChange={pipe(fromEvent, onValueChanged)}
                        />
                    )
                )
                .with(
                    {
                        field: {
                            __typename: P.union(
                                'CurrencyFieldDefinition',
                                'NumberFieldDefinition'
                            ),
                        },
                        value: P.union(P.string, P.number, undefined),
                    },
                    ({ value }) => (
                        <NumberInput
                            id={id}
                            value={value ?? ''}
                            onChange={pipe(
                                fromEvent,
                                s => Number(s),
                                onValueChanged
                            )}
                        />
                    )
                )
                .with(
                    {
                        field: {
                            __typename: 'SelectFieldDefinition',
                        },
                        value: P.union(P.array(P.string), undefined),
                    },
                    ({ field: { options }, value }) => (
                        <Select
                            id={id}
                            value={value?.[0] ?? undefined}
                            onValueChange={value => onValueChanged([value])}
                            name="operator"
                            options={options.map(({ id, value }) => ({
                                text: value,
                                value: id,
                            }))}
                        />
                    )
                )
                .with(
                    {
                        field: {
                            __typename: 'BooleanFieldDefinition',
                        },
                        value: P.union(P.string, P.boolean, undefined),
                    },
                    ({ value }) => (
                        <Select
                            id={id}
                            value={
                                typeof value === 'boolean'
                                    ? value.toString()
                                    : value
                            }
                            onValueChange={val => {
                                onValueChanged(val === 'true' ? true : false)
                            }}
                            name="operator"
                            options={[
                                {
                                    text: 'True',
                                    value: 'true',
                                },
                                {
                                    text: 'False',
                                    value: 'false',
                                },
                            ]}
                        />
                    )
                )
                .with(
                    {
                        field: {
                            __typename: 'DateFieldDefinition',
                        },
                        value: P.union(P.string, undefined),
                    },
                    ({ value }) => (
                        <DateSelector
                            date={value}
                            onDateChanged={onValueChanged}
                        />
                    )
                )
                .with(
                    {
                        field: {
                            __typename: 'UserFieldDefinition',
                        },
                        value: P.union(P.string, undefined),
                    },
                    ({ value }) => (
                        <UserSelector
                            index={index}
                            userId={value}
                            onUserSelected={onValueChanged}
                        />
                    )
                )
                .with(
                    {
                        field: { __typename: 'RelationFieldDefinition' },
                        value: P.union(P.string, undefined),
                    },
                    ({ field, value }) => {
                        const typeConstraint = field.constraints.find(
                            byTypename('RelationTypeConstraint' as const)
                        )
                        const businessObjectDefinitionId = first(
                            typeConstraint?.types
                        )?.id

                        if (!businessObjectDefinitionId) {
                            return null
                        }

                        return (
                            <RelationSelector
                                index={index}
                                businessObjectDefinitionId={
                                    businessObjectDefinitionId
                                }
                                relationValue={value}
                                onRelationSelected={onValueChanged}
                            />
                        )
                    }
                )
                // TODO - Use the document picker component (disable create new)
                .with(
                    { field: { __typename: 'DocumentFieldDefinition' } },
                    () => <span>Not implemented</span>
                )
                // Ignore for now : Difficult to filter on Telephone
                .with(
                    { field: { __typename: 'TelephoneFieldDefinition' } },
                    () => null
                )
                // Ignore : Cannot filter on updates or list
                .with(
                    { field: { __typename: 'UpdatesFieldDefinition' } },
                    () => null
                )
                .with(
                    { field: { __typename: 'ListFieldDefinition' } },
                    () => null
                )
                .otherwise(() => null)}
        </StyledFieldWrapper>
    )
}

const StyledFieldWrapper = styled.div`
    min-width: 208px;

    > div,
    > button {
        height: 100%;
    }
`
