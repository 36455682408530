import { logger } from 'Adapters/Logger'
import { StatusButton, TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import dayjs from 'dayjs'
import { FC, MouseEventHandler, useCallback, useState } from 'react'
import { useMutationStatus } from '../../../../../Hooks'
import { strings } from './strings'
import { Styled } from './styles'
import { useCreateInvitedUserMutation } from './__generated__/RespondToInvites'

type Props = {
    onSuccess: () => void
    invites: {
        id: string
        tenant: {
            name: string
        }
    }[]
    skip: () => void
}

export const RespondToInvites: FC<Props> = ({
    skip,
    onSuccess,
    invites: [invite],
}) => {
    const { open, triggerToast, setOpen } = useTriggerToast()
    const [userName, setUserName] = useState('')
    const [createInvitedUser, result] = useCreateInvitedUserMutation({
        onCompleted() {
            onSuccess()
        },
        onError(e) {
            logger.error('Error creating invited user', e)
            triggerToast()
        },
    })

    const { status } = useMutationStatus(result)

    const onSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
        e => {
            e.preventDefault()
            createInvitedUser({
                variables: {
                    input: {
                        inviteId: invite.id,
                        name: userName,
                        termsAccepted: dayjs().toISOString(),
                    },
                },
            })
        },
        [createInvitedUser, invite, userName]
    )

    return (
        <Styled>
            <header>
                <p>{strings.supHeading}</p>
                <h2>{strings.heading.replace('%', invite.tenant.name)}</h2>
            </header>
            <TextInput
                placeholder={strings.nameLabel}
                aria-label={strings.nameLabel}
                onChange={e => setUserName(e.currentTarget.value)}
                value={userName}
            />
            <footer>
                <StatusButton
                    onClick={onSubmit}
                    status={status}
                    text={{
                        ready: strings.btnTextNormal,
                        loading: strings.btnTextWaiting,
                        success: strings.btnTextSuccess,
                        error: strings.btnTextNormal,
                    }}
                    disabled={
                        status === 'loading' ||
                        status === 'success' ||
                        !userName
                    }
                />
                <TextButton
                    variant="ghost"
                    text={strings.skipText}
                    onClick={skip}
                />
            </footer>
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description={strings.errorMessage}
            />
        </Styled>
    )
}
