import { Avatar } from 'Components/Avatar'
import { Icon } from 'Components/Icon'
import { TextInput } from 'Components/Input'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { Invite } from './Invite'
import { RemoveUserButton } from './RemoveUserButton'
import { UserGroups } from './UserGroups'
import {
    ManageWorkspaceMembersQuery,
    useManageWorkspaceMembersQuery,
} from './__generated__/q'
import {
    Styled,
    StyledInviteAvatar,
    StyledInviteListItem,
    StyledMemberDetails,
    StyledMemberListItem,
} from './styles'
import { useMemberSearch } from './useMembersSearch'

export const Members: FC = () => {
    const { data, refetch } = useManageWorkspaceMembersQuery()

    return match(data)
        .with(
            { currentUser: { activeTenant: P.not(P.nullish) } },
            ({ users, currentUser }) => (
                <MembersContent
                    users={users}
                    currentUser={currentUser}
                    refetch={refetch}
                />
            )
        )
        .otherwise(() => (
            <section>
                <CenteredSpinner />
            </section>
        ))
}

type Props = Pick<ManageWorkspaceMembersQuery, 'currentUser' | 'users'> & {
    refetch: () => void
}
const MembersContent: FC<Props> = ({ users, currentUser, refetch }) => {
    const { searchTerm, setSearchTerm, filteredUsers, filteredOpenInvites } =
        useMemberSearch({
            users,
            openInvites: currentUser.activeTenant?.tenant.openInvites ?? [],
        })

    return (
        <Styled>
            <header>
                <div>
                    <TextInput
                        id="tenant-members-search-input"
                        name="tenant-members-search-input"
                        value={searchTerm}
                        onChange={({ currentTarget: { value } }) => {
                            refetch()
                            setSearchTerm(value)
                        }}
                        placeholder="Search members"
                    />
                </div>
                <div>
                    <Invite
                        refetch={refetch}
                        users={users}
                        currentUser={currentUser}
                    />
                </div>
            </header>
            <div>
                <h2>Members</h2>
                <ul>
                    {filteredUsers.map(({ id, name, email }) => (
                        <StyledMemberListItem
                            key={id}
                            data-current={id === currentUser.id}
                        >
                            <Avatar variant="blue" name={name} />
                            <StyledMemberDetails>
                                <Text as="h5" variant="bold-4">
                                    {id === currentUser.id
                                        ? `${name} (you)`
                                        : name}
                                </Text>
                                <Text as="p" variant="regular-6">
                                    {email}
                                </Text>
                            </StyledMemberDetails>
                            {id === currentUser.id ? null : (
                                <RemoveUserButton userId={id} />
                            )}
                        </StyledMemberListItem>
                    ))}
                </ul>
            </div>
            {filteredOpenInvites.length > 0 ? (
                <div>
                    <ul>
                        <h2>Invites</h2>
                        {filteredOpenInvites.map(({ id, inviteeEmail }) => (
                            <StyledInviteListItem key={id}>
                                <StyledInviteAvatar>
                                    <Icon name="Mail" />
                                </StyledInviteAvatar>
                                <div>
                                    <Text as="h5" variant="bold-4">
                                        {inviteeEmail}
                                    </Text>
                                    <Text as="p" variant="regular-6">
                                        Invite pending
                                    </Text>
                                </div>
                            </StyledInviteListItem>
                        ))}
                    </ul>
                </div>
            ) : null}
            <UserGroups />
        </Styled>
    )
}
