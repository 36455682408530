import { useContext } from 'react'
import { DispatchContext, StateContext } from './context'

export const useCreateFormState = () => {
    const state = useContext(StateContext)

    if (state === undefined) {
        throw new Error('useFormState must be used within a FormStateProvider')
    }

    return state
}

export const useCreateFormStateDispatch = () => {
    const dispatch = useContext(DispatchContext)

    if (dispatch === undefined) {
        throw new Error(
            'useFormStateDispatch must be used within a FormStateProvider'
        )
    }

    return dispatch
}
