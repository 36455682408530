import {
    AddField,
    CloseAddField,
    CloseFieldEditor,
    MoveField,
    OpenAddField,
    OpenFieldEditor,
    RemoveField,
    SetDescription,
    SetField,
    SetLabel,
    SetName,
    ToggleCustomLabel,
    ToggleEnableDocuments,
    ToggleEnableUpdates,
} from 'Features/BusinessObjectDefinitionEditor'
import {
    ValidationStatus,
    createDefaultLabel,
    createNewField,
    createPendingFieldValidation,
    validateDefinition,
    validateField,
    validateLabel,
    validateName,
} from 'Utils/BusinessObjectDefinition'
import { v4 } from 'uuid'
import { BusinessObjectDefinitionsCreatorQuery } from '../__generated__/q'
import { makeInit } from './makeInit'
import {
    ChangePrompt,
    ChoosePath,
    CloseCreator,
    Handle,
    OpenCreator,
    Restart,
    SelectExisting,
    SelectTemplate,
    SetStatus,
    StartFromScratch,
    State,
    Step,
    TakeSuggestion,
    ToggleLoadingSuggestion,
} from './types'

// This prevents futher actions being handled
// whilst a suggestion is being loaded
// which is neccessary as the suggestion is
// loaded asyncronously
const guardLoadingSuggestion = (prev: State) => (next: State) =>
    prev.loadingSuggestion ? prev : next

export const handleOpenCreator =
    (data: BusinessObjectDefinitionsCreatorQuery): Handle<OpenCreator> =>
    prev =>
    () =>
        guardLoadingSuggestion(prev)({
            ...makeInit(data),
            open: true,
        })

export const handleCloseCreator =
    (data: BusinessObjectDefinitionsCreatorQuery): Handle<CloseCreator> =>
    prev =>
    () =>
        guardLoadingSuggestion(prev)(makeInit(data))

export const handleRestart =
    (data: BusinessObjectDefinitionsCreatorQuery): Handle<Restart> =>
    () =>
    () => ({
        ...makeInit(data),
        open: true,
    })

export const handleSelectTemplate: Handle<SelectTemplate> =
    prev =>
    ({ payload: { template } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: validateDefinition(template.definition),
            ...template,
        })

export const handleSelectExisting: Handle<SelectExisting> =
    prev =>
    ({ payload: { definition } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: validateDefinition(definition.definition),
            ...definition,
            definition: {
                ...definition.definition,
                name: definition.definition.name + ' (copy)',
            },
        })

export const handleChangePrompt: Handle<ChangePrompt> =
    prev =>
    ({ payload: { suggestionPrompt } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            suggestionPrompt,
            fieldEditorOpen: false,
            addFieldOpen: false,
        })

export const handleToggleLoadingSuggestion: Handle<ToggleLoadingSuggestion> =
    prev => () => ({
        ...prev,
        loadingSuggestion: !prev.loadingSuggestion,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleTakeSuggestion: Handle<TakeSuggestion> =
    prev =>
    ({ payload: { suggestion } }) => ({
        ...prev,
        suggestionPrompt: '',
        loadingSuggestion: false,
        step: Step.Details,
        fieldEditorOpen: false,
        addFieldOpen: false,
        customLabel: !!suggestion.definition.label,
        validation: validateDefinition(suggestion.definition),
        ...suggestion,
    })

export const handleStartFromScratch: Handle<StartFromScratch> = prev => () =>
    guardLoadingSuggestion(prev)({
        ...prev,
        definition: {
            id: v4(),
            name: '',
            label: '',
            description: undefined,
            fields: [],
        },
        step: Step.Details,
        fieldEditorOpen: false,
        addFieldOpen: false,
        validation: {
            name: {
                status: ValidationStatus.Pending,
                message: '',
            },
            label: {
                status: ValidationStatus.Pending,
                message: '',
            },
            fields: [],
        },
    })

export const handleToggleCustomLabel: Handle<ToggleCustomLabel> =
    prev => () => {
        const defaultLabel = createDefaultLabel(prev.definition)
        return guardLoadingSuggestion(prev)({
            ...prev,
            definition: {
                ...prev.definition,
                label: prev.customLabel ? defaultLabel : '',
            },
            customLabel: !prev.customLabel,
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: {
                ...prev.validation,
                label: prev.customLabel
                    ? {
                          status: ValidationStatus.Pending,
                          message: '',
                      }
                    : validateLabel(defaultLabel),
            },
        })
    }

export const handleSetName: Handle<SetName> =
    prev =>
    ({ payload: { value } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            definition: {
                ...prev.definition,
                name: value,
            },
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: {
                ...prev.validation,
                name: validateName(value),
            },
        })

export const handleSetDescription: Handle<SetDescription> =
    prev =>
    ({ payload: { value } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            definition: {
                ...prev.definition,
                description: value || undefined,
            },
            fieldEditorOpen: false,
            addFieldOpen: false,
        })

export const handleSetLabel: Handle<SetLabel> =
    prev =>
    ({ payload: { value } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            definition: {
                ...prev.definition,
                label: value,
            },
            customLabel: true,
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: {
                ...prev.validation,
                label: validateLabel(value),
            },
        })

export const handleOpenFieldEditor: Handle<OpenFieldEditor> =
    prev =>
    ({ payload: { index } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: index,
        })

export const handleCloseFieldEditor: Handle<CloseFieldEditor> = prev => () =>
    guardLoadingSuggestion(prev)({
        ...prev,
        step: Step.Details,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleOpenAddField: Handle<OpenAddField> = prev => () =>
    guardLoadingSuggestion(prev)({
        ...prev,
        step: Step.Details,
        fieldEditorOpen: false,
        addFieldOpen: true,
    })

export const handleCloseAddField: Handle<CloseAddField> = prev => () =>
    guardLoadingSuggestion(prev)({
        ...prev,
        step: Step.Details,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleAddField: Handle<AddField> =
    prev =>
    ({ payload: { fieldType } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: prev.definition.fields.length,
            addFieldOpen: false,
            definition: {
                ...prev.definition,
                fields: [...prev.definition.fields, createNewField(fieldType)],
            },
            validation: {
                ...prev.validation,
                fields: [
                    ...prev.validation.fields,
                    createPendingFieldValidation(fieldType),
                ],
            },
        })

export const handleRemoveField: Handle<RemoveField> =
    prev =>
    ({ payload: { index } }) => {
        const nextInput = {
            ...prev.definition,
            fields: prev.definition.fields.filter((_, i) => i !== index),
        }
        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        const nextFieldsValidation = prev.validation.fields.filter(
            (_, i) => i !== index
        )
        return guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: false,
            addFieldOpen: false,
            definition: {
                ...nextInput,
                label,
            },
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: nextFieldsValidation,
            },
        })
    }

export const handleMoveField: Handle<MoveField> =
    prev =>
    ({ payload: { index, targetIndex } }) => {
        const fields = prev.definition.fields.filter((_, i) => i !== index)
        fields.splice(targetIndex, 0, prev.definition.fields[index])
        const validationFields = prev.validation.fields.filter(
            (_, i) => i !== index
        )
        validationFields.splice(targetIndex, 0, prev.validation.fields[index])
        const nextInput = {
            ...prev.definition,
            fields,
        }
        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        return guardLoadingSuggestion(prev)({
            ...prev,
            step: Step.Details,
            fieldEditorOpen: false,
            addFieldOpen: false,
            definition: {
                ...nextInput,
                label,
            },
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: validationFields,
            },
        })
    }

export const handleSetField: Handle<SetField> =
    prev =>
    ({ payload: { index, value } }) => {
        const nextInput = {
            ...prev.definition,
            fields: prev.definition.fields.map((f, i) =>
                i === index ? value : f
            ),
        }
        const validationFields = prev.validation.fields.map((v, i) =>
            i === index ? validateField(value) : v
        )

        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        return guardLoadingSuggestion(prev)({
            ...prev,
            definition: {
                ...nextInput,
                label,
            },
            fieldEditorOpen: false,
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: validationFields,
            },
        })
    }

export const handleToggleEnableUpdates: Handle<ToggleEnableUpdates> =
    prev => () =>
        guardLoadingSuggestion(prev)({
            ...prev,
            enableUpdates: !prev.enableUpdates,
            fieldEditorOpen: false,
            addFieldOpen: false,
        })

export const handleToggleEnableDocuments: Handle<ToggleEnableDocuments> =
    prev => () =>
        guardLoadingSuggestion(prev)({
            ...prev,
            enableDocuments: !prev.enableDocuments,
            fieldEditorOpen: false,
            addFieldOpen: false,
        })

export const handleSetStatus: Handle<SetStatus> =
    prev =>
    ({ payload: { status } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            status,
            fieldEditorOpen: false,
            addFieldOpen: false,
        })

export const handleChoosePath: Handle<ChoosePath> =
    prev =>
    ({ payload: { step } }) =>
        guardLoadingSuggestion(prev)({
            ...prev,
            step,
            fieldEditorOpen: false,
            addFieldOpen: false,
        })
