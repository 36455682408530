import { styled } from 'Adapters/Freestyled'
import { Spinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { Card } from './Card'
import { Empty } from './Empty'
import { useProcessCatalogueQuery } from './__generated__/query'

const Catalogue: FC = () => {
    const { isLarge } = useResponder()
    const { data, loading } = useProcessCatalogueQuery()

    return match((!loading || undefined) && data)
        .with(undefined, () => (
            <StyledLoading>
                <Spinner />
            </StyledLoading>
        ))
        .with({ processes: [] }, () => <Empty />)
        .otherwise(({ processes }) => (
            <Styled>
                <header>
                    <Text as="h1" variant={isLarge ? 'bold-1' : 'bold-2'}>
                        Workflows
                    </Text>
                </header>
                <section>
                    <ul>
                        {processes.map(process => (
                            <li key={process.id}>
                                <Card process={process} />
                            </li>
                        ))}
                    </ul>
                </section>
            </Styled>
        ))
}

const StyledLoading = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Styled = styled.div`
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr);
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    > header {
        padding: 1rem 4vw;
        grid-row: 1 / 2;
    }

    > section {
        overflow: auto;
        grid-row: 2 / 3;
    }

    > section > ul {
        padding: 0 4vw 1rem;
        display: flex;
        flex-direction: column;
        gap: 4vw;
    }

    [data-screen-size='large'] & {
        > header {
            padding: 2rem 3rem 1rem;
        }

        > section > ul {
            padding: 1rem 3rem 3rem;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
            gap: 2rem;
        }
    }
`

export { Catalogue }
