import { NumberInput } from 'Components/Input'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useNumberConstraints } from './useNumberConstraints'

// NB. I am assuming the user knows that numbers should be incrementable
// and number fields are not to be used to denote credit card numbers, phone numbers etc
// that are 'numeric' rather than numbers

export const NumberField: FC<BODField<'NumberFieldDefinition'>> = ({
    numConstraints,
    ...common
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useNumberConstraints({
        numConstraints,
    })

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <NumberInput
                name={common.type + '||' + common.id}
                value={state[common.id].value as number}
                id={common.id}
                hasError={!!state[common.id].errors}
                onChange={e =>
                    dispatch({
                        type: 'updateNumberField',
                        payload: {
                            id: common.id,
                            value: Number(e.target.value),
                        },
                    })
                }
                type="number"
                min={constraints.min}
                max={constraints.max}
                required={constraints.required}
            />
        </Field>
    )
}
