import { noop } from 'lodash'
import { createContext, useCallback, useState } from 'react'
import { P, match } from 'ts-pattern'
import { useIsUserSetupQuery } from './__generated__/query'

export const TemplateContext = createContext({
    isOpen: false,
    open: noop,
    dismiss: noop,
})

export const useUserSetup = (isFirstVisitInitial?: boolean) => {
    const { data, error, refetch, loading } = useIsUserSetupQuery()

    const [isFirstVisit, setIsFirstVisit] = useState(
        isFirstVisitInitial ?? false
    )

    const onSuccess = useCallback(() => {
        refetch()
        setIsFirstVisit(true)
    }, [refetch])

    const status = match([loading, error, data])
        .with([true, P._, P._], () => 'loading' as const)
        .with(
            [false, P.nullish, { isUserSetup: true }],
            () => 'userIsSetup' as const
        )
        .with(
            [false, P.nullish, { isUserSetup: false }],
            () => 'userIsNotSetup' as const
        )
        .with([false, P.any, P._], () => 'error' as const)
        .exhaustive()

    return {
        status,
        invites: data?.invites ?? [],
        onSuccess,
        isFirstVisit,
        setIsFirstVisit,
    }
}
