import { breakpoints, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { useModalControls, useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'

type Props = {
    imageURL: string
    title: string
}

export const ViewImageModal: FC<Props> = ({ imageURL, title }) => {
    const { isLarge } = useResponder()

    const { buttonRef, open, setOpen, openModal, returnFocus } =
        useModalControls()

    return (
        <>
            <StyledIconButton
                iconName={'View'}
                onClick={e => {
                    e.stopPropagation()
                    openModal()
                }}
                ref={buttonRef}
            />

            {match(isLarge)
                .with(true, () => (
                    <ControlledModal
                        renderContent={() => (
                            <StyledModalContents>
                                <img src={imageURL} alt={title} />
                            </StyledModalContents>
                        )}
                        title={title}
                        description={''}
                        hideDescription
                        open={open}
                        onOpenChange={setOpen}
                        returnFocus={returnFocus}
                    />
                ))
                .with(false, () => (
                    <MinimalModal open={open} onOpenChange={setOpen}>
                        <StyledModalContents>
                            <img src={imageURL} alt={title} />
                        </StyledModalContents>
                    </MinimalModal>
                ))
                .exhaustive()}
        </>
    )
}

const StyledIconButton = styled(IconButton)`
    background-color: transparent;
    border: 1px solid transparent;
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.text.primary.hover};
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['03'].hover};
        }
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.primary.active};
        background-color: ${({ theme }) => theme.palette.ui.tint['03'].active};
    }

    &:focus-visible {
        box-shadow: 0 0 0 0.125rem
            ${({ theme }) => theme.palette.brand['01'].normal};
        background-color: ${({ theme }) => theme.palette.ui.tint['03'].active};
    }
`

const StyledModalContents = styled.div`
    img {
        width: 100%;
        height: auto;
        border-radius: ${({ theme }) => theme.borderRadius.small};

        @media screen and (min-width: ${breakpoints.medium}px) {
            max-height: 80vh;
            max-width: 80vw;
        }
    }
`
