import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Day } from './types'

const DayHeader: FC<{ day: Day }> = ({ day }) => {
    return (
        <Styled data-today={day.isToday}>
            <Text as="h2" variant="bold-6">
                {day.d.format('DD')}
            </Text>
        </Styled>
    )
}

const Styled = styled.header`
    position: sticky;
    top: 4rem;
    z-index: 1;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    grid-row: 2 / 3;
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 0.5rem 0;

    &[data-today='true'] {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        background-color: ${({ theme }) => theme.palette.support.bg03.normal};
    }
`

export { DayHeader }
