import { NetworkStatus } from '@apollo/client'
import { FC, useMemo } from 'react'
import { styled } from '../../Adapters/Freestyled'
import { IconButton } from '../../Components/Button'
import { Select } from '../../Components/Select'
import { IntegrationActionParamResolverDataLookup } from '../../__generated__'
import { useAction_Execution_ResultQuery } from './__generated__/query'

type Props = {
    resolver: IntegrationActionParamResolverDataLookup
    params: Record<string, unknown>
    value: string | undefined
    onValueChanged: (value: string) => void
}

export const ResolveAndSelect: FC<Props> = ({
    resolver,
    params,
    value,
    onValueChanged,
}) => {
    const { data, refetch, loading, networkStatus } =
        useAction_Execution_ResultQuery({
            variables: {
                input: {
                    provider: resolver.provider,
                    name: resolver.action,
                    params: JSON.stringify(params),
                },
            },
            notifyOnNetworkStatusChange: true,
        })

    const records: Array<Record<string, string>> = useMemo(() => {
        return data?.actionExecution.result
            ? JSON.parse(data.actionExecution.result)[resolver.itemsKey]
            : []
    }, [data, resolver.itemsKey])

    return (
        <Styled>
            <Select
                id={`select-param`}
                name={`select-param`}
                options={records.map(item => ({
                    value: item[resolver.idKey],
                    text: item[resolver.displayKey],
                }))}
                value={value}
                onValueChange={onValueChanged}
                disabled={loading || networkStatus === NetworkStatus.refetch}
            />
            <IconButton
                iconName="Sync"
                disabled={loading || networkStatus === NetworkStatus.refetch}
                onClick={e => {
                    e.preventDefault()
                    refetch()
                }}
            />
        </Styled>
    )
}

const Styled = styled.div`
    display: flex;
    flex-direction: row;
`
