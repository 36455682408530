import { styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { FC, useRef, useState } from 'react'
import { ProcessDef_SingleProcessQuery } from '../../__generated__/query'
import { Content } from './Contents'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
}

const Editor: FC<Props> = ({ process }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [editorOpen, setEditorOpen] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)

    return (
        <>
            <IconTextButton
                ref={ref}
                icon="Edit"
                text="Edit workflow"
                variant="secondary"
                size="xSmall"
                onClick={() => setEditorOpen(true)}
            />
            <ControlledModal
                open={editorOpen}
                onOpenChange={open =>
                    open ? setEditorOpen(true) : setConfirmationOpen(true)
                }
                title="Edit workflow"
                description=""
                hideDescription
                renderContent={() => (
                    <Content
                        process={process}
                        onCompleted={() => {
                            setEditorOpen(false)
                        }}
                    />
                )}
                returnFocus={() => ref.current?.focus()}
            />
            <ControlledModal
                open={confirmationOpen}
                onOpenChange={setConfirmationOpen}
                title="Are you sure?"
                description="If you close the editor now, you will lose all your changes."
                renderContent={() => (
                    <StyledConfirmation>
                        <TextButton
                            text="Cancel"
                            variant="secondary"
                            onClick={() => setConfirmationOpen(false)}
                            size="small"
                        />
                        <TextButton
                            text="Confirm"
                            onClick={() => {
                                setEditorOpen(false)
                                setConfirmationOpen(false)
                            }}
                            size="small"
                        />
                    </StyledConfirmation>
                )}
                returnFocus={() => {
                    if (!editorOpen) ref.current?.focus()
                }}
            />
        </>
    )
}

const StyledConfirmation = styled.footer`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-top: 1rem;

    button {
        flex: 1;
    }
`

export { Editor }
