import { datadogRum } from '@datadog/browser-rum'
import { gqlUri } from '../Apollo/config'
import { logger } from '../Logger'
import {
    APP_VERSION,
    DATADOG_ENV,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_SITE,
} from './config'

const initRum = () => {
    if (!DATADOG_RUM_APPLICATION_ID || !DATADOG_RUM_CLIENT_TOKEN) {
        logger.info('RUM config unavailable, so not enabled')
        return
    }

    datadogRum.init({
        applicationId: DATADOG_RUM_APPLICATION_ID,
        clientToken: DATADOG_RUM_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE,
        env: DATADOG_ENV,
        version: APP_VERSION,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [gqlUri],
    })

    datadogRum.startSessionReplayRecording()
}

initRum()
