import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { useModalControls } from 'Hooks'
import { fromEvent } from 'Utils'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useActionsView } from '../../../../../../../Features/ActionView/useActionsView'
import { useSaveActionViewMutation } from '../../../__generated__/mutation'

export const SaveView: FC = () => {
    const [searchParams] = useSearchParams()

    const { filterState } = useBusinessObjectFilterState()

    const { grouping } = useActionsView()

    const [viewName, setViewName] = useState('')

    const {
        buttonRef,
        open: modalOpen,
        openModal,
        closeModal,
        returnFocus,
        setOpen: setModalOpen,
    } = useModalControls()

    const {
        open: successToastOpen,
        setOpen: setSuccessToastOpen,
        triggerToast: triggerSuccessToast,
    } = useTriggerToast()

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const [saveActionView] = useSaveActionViewMutation({
        onCompleted: () => {
            closeModal()
            triggerSuccessToast()
            setViewName('')
        },
        onError: () => {
            triggerErrorToast()
        },
        refetchQueries: ['GetActionViews'],
    })

    return (
        <>
            <IconTextButton
                icon="Save"
                text="Save view"
                onClick={openModal}
                ref={buttonRef}
                size="xSmall"
            />

            <ControlledModal
                renderContent={() => (
                    <StyledModalContent>
                        <TextInput
                            value={viewName}
                            onChange={e => setViewName(fromEvent(e))}
                        />
                        <TextButton
                            text="Save view"
                            onClick={() => {
                                saveActionView({
                                    variables: {
                                        input: {
                                            type: ViewOfType.Actions,
                                            configuration: Buffer.from(
                                                JSON.stringify({
                                                    ...convertSearchParamsToObject(
                                                        searchParams
                                                    ),
                                                    filterState,
                                                    ...(grouping && {
                                                        grouping,
                                                    }),
                                                })
                                            ).toString('base64'),
                                            name: viewName,
                                        },
                                    },
                                })
                            }}
                            size="small"
                            disabled={!viewName}
                        />
                    </StyledModalContent>
                )}
                title={'Save view'}
                description={'Give the view a name'}
                open={modalOpen}
                onOpenChange={setModalOpen}
                returnFocus={returnFocus}
            />

            <SimpleToast
                open={successToastOpen}
                onOpenChange={setSuccessToastOpen}
                description={'Successfully saved view'}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'Unable to save the view. Contact us if the problem persists.'
                }
            />
        </>
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;

    button {
        margin-top: 0.5rem;
        width: 100%;
    }
`
