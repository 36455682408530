import { Comparator } from 'Components/ComparatorBuilderV2'
import { pipe } from 'lodash/fp'
import {
    applyComparator,
    applySelector,
    FilterableBusinessObject,
} from 'Utils/comparator'

const filterBusinessObjects = <T extends FilterableBusinessObject>(
    comparators: Comparator[],
    businessObjects: T[]
): T[] =>
    comparators.reduce(
        (out, comparator) =>
            out.filter(
                pipe(
                    applySelector(comparator.valueSelector),
                    applyComparator(comparator)
                )
            ),
        businessObjects
    )

export const flattenAndFilterBusinessObjects = <
    T extends FilterableBusinessObject
>(
    comparators: (Comparator | Comparator[])[],
    businessObjects: T[]
): T[] => filterBusinessObjects(comparators.flat(), businessObjects)
