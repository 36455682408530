import { FC, ReactNode } from 'react'
import {
    StyledConstraintsTooltip,
    StyledErrorMessage,
    StyledInputGroup,
} from '../styles'

// A wrapper around a field that provides a label, error message etc.

type Props = {
    children: ReactNode
    id: string
    name: string
    required?: boolean
    description: string
    constraintsTooltip?: string
    errors?: string[]
}

export const Field: FC<Props> = ({
    children,
    id,
    errors,
    constraintsTooltip,
    name,
    required,
    description,
}) => {
    return (
        <StyledInputGroup>
            <label htmlFor={id}>
                {name} {required ? <strong>*</strong> : null}
            </label>

            {description ? (
                <StyledConstraintsTooltip>
                    {description}
                </StyledConstraintsTooltip>
            ) : null}

            {constraintsTooltip ? (
                <StyledConstraintsTooltip>
                    {constraintsTooltip}
                </StyledConstraintsTooltip>
            ) : null}

            <div>{children}</div>

            <StyledErrorMessage>
                {errors?.map(message => message)}
            </StyledErrorMessage>
        </StyledInputGroup>
    )
}
