import { DocumentMetadata } from 'Components/Documents'
import { useCallback, useMemo, useState } from 'react'
import { SelectMode } from '../types'

type Params = {
    selectMode: SelectMode
    documents: DocumentMetadata[]
    initialSelections: string[]
}

export const useSelections = ({
    selectMode,
    documents,
    initialSelections,
}: Params) => {
    const initialDocuments = useMemo<DocumentMetadata[]>(
        () => documents.filter(doc => initialSelections.includes(doc.id)),
        [documents, initialSelections]
    )

    const [selections, setSelections] = useState<Map<string, DocumentMetadata>>(
        () => {
            const selections = new Map<string, DocumentMetadata>()

            initialDocuments.forEach(document => {
                selections.set(document.id, document)
            })

            return selections
        }
    )

    const toggleSelection = useCallback(
        (document: DocumentMetadata) => {
            setSelections(selections => {
                const next = new Map(selections)

                if (next.has(document.id)) {
                    next.delete(document.id)
                } else {
                    if (selectMode === 'multiple' || next.size === 0) {
                        next.set(document.id, document)
                    } else {
                        next.clear()
                        next.set(document.id, document)
                    }
                }

                return next
            })
        },
        [selectMode]
    )

    const clearSelections = useCallback(() => {
        setSelections(new Map())
    }, [])

    return { selections, toggleSelection, clearSelections }
}
