import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteScheduledEventMutationVariables = Types.Exact<{
  input: Types.DeleteScheduledEventInput;
}>;


export type DeleteScheduledEventMutation = { __typename?: 'Mutation', deleteScheduledEvent: boolean };


export const DeleteScheduledEventDocument = gql`
    mutation DeleteScheduledEvent($input: DeleteScheduledEventInput!) {
  deleteScheduledEvent(input: $input)
}
    `;
export type DeleteScheduledEventMutationFn = Apollo.MutationFunction<DeleteScheduledEventMutation, DeleteScheduledEventMutationVariables>;

/**
 * __useDeleteScheduledEventMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledEventMutation, { data, loading, error }] = useDeleteScheduledEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScheduledEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledEventMutation, DeleteScheduledEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledEventMutation, DeleteScheduledEventMutationVariables>(DeleteScheduledEventDocument, options);
      }
export type DeleteScheduledEventMutationHookResult = ReturnType<typeof useDeleteScheduledEventMutation>;
export type DeleteScheduledEventMutationResult = Apollo.MutationResult<DeleteScheduledEventMutation>;
export type DeleteScheduledEventMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledEventMutation, DeleteScheduledEventMutationVariables>;