import * as Accordion from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'
import illustration from 'Assets/IllustrationError.svg'
import { TextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import * as support from 'Features/Support'
import { FC } from 'react'

type Props = {
    errorMessage: string
}

export const Fallback: FC<Props> = ({ errorMessage }) => {
    return (
        <StyledFallback>
            <img src={illustration} alt="error" />

            <Text as="h1" variant="bold-0">
                Oops...
            </Text>

            <Text as="p" variant="regular-3">
                It looks like we encountered an error on our side. This has been
                logged and we will investigate ASAP.
            </Text>

            <Text as="p" variant="regular-3">
                In the meantime, you can try refreshing the page, navigating to
                other parts of the app, or contact us if it is urgent.
            </Text>

            <StyledAccordion type="single" collapsible>
                <Accordion.Item className="AccordionItem" value="item-1">
                    <StyledTrigger>
                        <Text as="span" variant="regular-3">
                            Show details
                        </Text>

                        <Icon name="AngleDown" />
                    </StyledTrigger>

                    <Accordion.Content>
                        <Text as="span" variant="regular-3">
                            {errorMessage}
                        </Text>
                    </Accordion.Content>
                </Accordion.Item>
            </StyledAccordion>

            <div className="buttons">
                <TextButton
                    text={'Contact us'}
                    onClick={support.togglePopup}
                    size="small"
                />

                <TextButton
                    text={'Refresh page'}
                    onClick={() => {
                        window.location.reload()
                    }}
                    size="small"
                />
            </div>
        </StyledFallback>
    )
}

const StyledFallback = styled.section`
    ${flex('column', 'center', 'center')};
    gap: 1rem;
    height: 100%;
    width: 100%;

    > h1,
    p,
    img,
    .buttons {
        width: 100%;
        max-width: 38rem;
    }

    .buttons {
        ${flex('row', 'flex-start', 'center')};
        gap: 1rem;

        button {
            flex: 1;
        }
    }
`

const StyledAccordion = styled(Accordion.Root)`
    width: 100%;
    max-width: 38rem;
`

const StyledTrigger = styled(Accordion.Trigger)`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    padding: 0;

    > span {
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }

    svg path {
        fill: blue;
    }
`
