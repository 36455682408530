import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { FC } from 'react'
import { useOptionalFields } from '../../../Hooks/useOptionalFields'
import {
    CreatedBusinessObject,
    useCreateBusinessObject,
} from '../Hooks/useCreateBusinessObject'
import { BODFields, PrefilledBusinessObject } from '../types'
import { CreateBusinessObjectFormContents } from './CreateBusinessObjectFormContents'

type Props = {
    businessObjectDefinitionId: string
    fields: BODFields
    onBusinessObjectCreated?: (businessObject: CreatedBusinessObject) => void
    prefilledBusinessObject?: PrefilledBusinessObject
    initiallyShowOptionalFields?: boolean
}

export const CreateBusinessObjectForm: FC<Props> = ({
    fields,
    businessObjectDefinitionId,
    onBusinessObjectCreated,
    prefilledBusinessObject,
    initiallyShowOptionalFields = false,
}) => {
    const { handleSubmit, loading } = useCreateBusinessObject({
        businessObjectDefinitionId,
        onCompleted: onBusinessObjectCreated,
    })

    const {
        requiredFields,
        optionalFields,
        showOptionalFields,
        setShowOptionalFields,
        showToggle,
    } = useOptionalFields({ fields, initiallyShowOptionalFields })

    return (
        <StyledCreateBusinessObjectForm>
            <CreateBusinessObjectFormContents
                requiredFields={requiredFields}
                optionalFields={optionalFields}
                setShowOptionalFields={setShowOptionalFields}
                showOptionalFields={showOptionalFields}
                showOptionalFieldsToggle={showToggle}
                prefilledBusinessObject={prefilledBusinessObject}
            >
                <TextButton
                    text="Submit"
                    disabled={loading}
                    className="submit-button"
                    size="small"
                    onClick={e => {
                        // Because the form is sometimes a child of another form,
                        // we need to prevent clicking from submitting the form
                        // so it doesn't auto submit the parent
                        e.preventDefault()
                        handleSubmit()
                    }}
                />
            </CreateBusinessObjectFormContents>
        </StyledCreateBusinessObjectForm>
    )
}

const StyledCreateBusinessObjectForm = styled.form`
    max-height: 75vh;
    overflow: auto;

    [data-screen-size='large'] & {
        width: 30rem;
    }

    .submit-button {
        margin-top: 0.5rem;
        width: 100%;
    }
`
