import { styled } from 'Adapters/Freestyled'
import { BusinessObjectDefinitionField } from 'Features/BusinessObjectDefinitionField'
import { BusinessObjectDefinitionFieldEditor } from 'Features/BusinessObjectDefinitionFieldEditor'
import { FC, useRef } from 'react'
import { AddField } from './AddField'
import { DetailsSection } from './Section'
import { DispatchAction, State } from './types'

type Props = Pick<
    State,
    | 'definition'
    | 'fieldEditorOpen'
    | 'addFieldOpen'
    | 'relationOptions'
    | 'userOptions'
    | 'currencyOptions'
    | 'validation'
    | 'mode'
> & {
    dispatch: DispatchAction
}

const Fields: FC<Props> = ({
    definition,
    fieldEditorOpen,
    addFieldOpen,
    relationOptions,
    userOptions,
    currencyOptions,
    validation,
    dispatch,
    mode,
}) => {
    const anchor = useRef<HTMLDivElement>(null)

    return (
        <Styled
            heading="Fields"
            description="Fields are used to capture information on a record. They can be of different types, such as text, number, date, and more. Each field can be configured with constraints and default values according to its type."
        >
            <StyledCards>
                <ul>
                    {definition.fields.map((field, index, { length }) => (
                        <BusinessObjectDefinitionField
                            key={index}
                            field={field}
                            mode={mode}
                            index={index}
                            last={index === length - 1}
                            validation={validation.fields[index]}
                            onEdit={() =>
                                dispatch({
                                    type: 'openFieldEditor',
                                    payload: { index },
                                })
                            }
                            onMoveUp={() =>
                                dispatch({
                                    type: 'moveField',
                                    payload: { index, targetIndex: index - 1 },
                                })
                            }
                            onMoveDown={() =>
                                dispatch({
                                    type: 'moveField',
                                    payload: { index, targetIndex: index + 1 },
                                })
                            }
                            onRemove={() =>
                                dispatch({
                                    type: 'removeField',
                                    payload: { index },
                                })
                            }
                        />
                    ))}
                </ul>
                <AddField
                    addFieldOpen={addFieldOpen}
                    dispatch={dispatch}
                    onAdd={() => {
                        anchor.current?.scrollIntoView({ behavior: 'smooth' })
                    }}
                />
                <StyledAnchor ref={anchor} />
            </StyledCards>
            <BusinessObjectDefinitionFieldEditor
                onOpenChange={async open => {
                    if (!open)
                        dispatch({
                            type: 'closeFieldEditor',
                            payload: undefined,
                        })
                }}
                onSubmit={({ field }) => {
                    if (fieldEditorOpen !== false)
                        dispatch({
                            type: 'setField',
                            payload: { index: fieldEditorOpen, value: field },
                        })
                }}
                relationOptions={relationOptions}
                userOptions={userOptions}
                currencyOptions={currencyOptions}
                {...(fieldEditorOpen !== false
                    ? {
                          field: definition.fields[fieldEditorOpen],
                          validation: validation.fields[fieldEditorOpen],
                      }
                    : {
                          field: undefined,
                          validation: undefined,
                      })}
            />
        </Styled>
    )
}

const Styled = styled(DetailsSection)`
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    background-image: linear-gradient(
        175deg,
        ${({ theme }) => theme.palette.ui['03'].normal},
        ${({ theme }) => theme.palette.ui['04'].normal}
    );
    border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: inset 0 0 1rem -0.5rem ${({ theme }) => theme.palette.ui['04'].normal};
    display: grid;
    grid-template: auto 1fr / 1fr;
`

const StyledCards = styled.div`
    position: relative;
    overflow: auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;

    > ul {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        padding: 1rem 0;
    }

    > div {
        align-self: center;
    }
`

const StyledAnchor = styled.div`
    margin-top: 4rem;
`

export { Fields }
