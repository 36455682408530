import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardingProgressQueryVariables = Types.Exact<{
  businessObjectInput: Types.GetBusinessObjectsInput;
  processInput?: Types.InputMaybe<Types.QueryProcessesInput>;
  businessObjectInProcessInput?: Types.InputMaybe<Types.BusinessObjectInProcessCountInput>;
}>;


export type OnboardingProgressQuery = { __typename?: 'Query', businessObjectCount: number, processCount: number, businessObjectDefinitionCount: number, businessObjectInProcessCount: number, currentUser: { __typename?: 'User', id: string, name: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } } | null } };


export const OnboardingProgressDocument = gql`
    query OnboardingProgress($businessObjectInput: GetBusinessObjectsInput!, $processInput: QueryProcessesInput, $businessObjectInProcessInput: BusinessObjectInProcessCountInput) {
  businessObjectCount(input: $businessObjectInput)
  processCount(input: $processInput)
  businessObjectDefinitionCount
  businessObjectInProcessCount(input: $businessObjectInProcessInput)
  currentUser {
    id
    name
    activeTenant {
      tenant {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useOnboardingProgressQuery__
 *
 * To run a query within a React component, call `useOnboardingProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingProgressQuery({
 *   variables: {
 *      businessObjectInput: // value for 'businessObjectInput'
 *      processInput: // value for 'processInput'
 *      businessObjectInProcessInput: // value for 'businessObjectInProcessInput'
 *   },
 * });
 */
export function useOnboardingProgressQuery(baseOptions: Apollo.QueryHookOptions<OnboardingProgressQuery, OnboardingProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingProgressQuery, OnboardingProgressQueryVariables>(OnboardingProgressDocument, options);
      }
export function useOnboardingProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingProgressQuery, OnboardingProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingProgressQuery, OnboardingProgressQueryVariables>(OnboardingProgressDocument, options);
        }
export type OnboardingProgressQueryHookResult = ReturnType<typeof useOnboardingProgressQuery>;
export type OnboardingProgressLazyQueryHookResult = ReturnType<typeof useOnboardingProgressLazyQuery>;
export type OnboardingProgressQueryResult = Apollo.QueryResult<OnboardingProgressQuery, OnboardingProgressQueryVariables>;