import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Spinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import dayjs from 'dayjs'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { ActionTimelineCardQuery } from '../__generated__/query'
import { useToggleAction } from './useToggleAction'

type Props = {
    action: ActionTimelineCardQuery['action']
}

const ActionToggle: FC<Props> = ({ action }) => {
    const { toggleAction, loading, toast } = useToggleAction({ action })

    return (
        <>
            {match(action)
                .with({ __typename: 'CompletedAction' }, ({ completedAt }) => (
                    <Styled
                        data-completed
                        disabled={!!loading}
                        onClick={toggleAction}
                    >
                        <Text as="p" variant="regular-6">
                            {`Completed on ${dayjs(completedAt).format(
                                'DD MMM YYYY @ HH:mm'
                            )}`}
                        </Text>
                        {loading ? <Spinner /> : <Icon name="CheckboxTicked" />}
                    </Styled>
                ))
                .when(
                    ({ dueDate }) => dayjs(dueDate).isSame(dayjs(), 'day'),
                    () => (
                        <Styled
                            disabled={!!loading}
                            onClick={toggleAction}
                            data-due
                        >
                            <Text as="p" variant="regular-6">
                                Due today
                            </Text>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Icon name="CheckboxUnticked" />
                            )}
                        </Styled>
                    )
                )
                .when(
                    ({ dueDate }) => dayjs(dueDate).isBefore(dayjs(), 'day'),
                    ({ dueDate }) => {
                        const diff = dayjs().diff(dayjs(dueDate), 'day')
                        return (
                            <Styled
                                disabled={!!loading}
                                onClick={toggleAction}
                                data-overdue
                            >
                                <Text as="p" variant="regular-6">
                                    {`${diff} ${
                                        diff === 1 ? 'day' : 'days'
                                    } overdue`}
                                </Text>
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <Icon name="CheckboxUnticked" />
                                )}
                            </Styled>
                        )
                    }
                )
                .with({ dueDate: P.nullish }, () => (
                    <Styled disabled={!!loading} onClick={toggleAction}>
                        <Text as="p" variant="regular-6">
                            No due date
                        </Text>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Icon name="CheckboxUnticked" />
                        )}
                    </Styled>
                ))
                .with({ dueDate: P.string }, ({ dueDate }) => (
                    <Styled disabled={!!loading} onClick={toggleAction}>
                        <Text as="p" variant="regular-6">
                            Due {dayjs(dueDate).format('DD MMMM YYYY')}
                        </Text>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Icon name="CheckboxUnticked" />
                        )}
                    </Styled>
                ))
                .otherwise(() => (
                    <Styled disabled={!!loading} onClick={toggleAction}>
                        <Text as="p" variant="regular-6">
                            Incomplete
                        </Text>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Icon name="CheckboxUnticked" />
                        )}
                    </Styled>
                ))}
            <SimpleToast
                {...toast}
                description="Unable to toggle the action's completed state. Pease try again."
            />
        </>
    )
}

const Styled = styled.button`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.235rem;
    transition: filter
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
        opacity ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    &[data-completed='true'] {
        background-color: ${({ theme }) => theme.palette.support.bg02.normal};
        color: ${({ theme }) => theme.palette.text.support02.normal};
    }

    &[data-completed='true'] svg path {
        fill: ${({ theme }) => theme.palette.text.support02.normal};
    }

    &[data-due='true'] {
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }

    &[data-overdue='true'] {
        color: ${({ theme }) => theme.palette.text.support05.normal};
    }

    &:hover {
        filter: brightness(95%);
    }

    &:active {
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.5;
    }

    .spinner {
        padding: 0.25rem;
        height: 1.5rem;
        width: 1.5rem;
    }
`

export { ActionToggle }
