import { forwardRef } from 'react'
import { StyledTextInput } from './styles'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean
    className?: string
}

export const TextInput = forwardRef<HTMLInputElement, Props>(
    ({ hasError = false, className, disabled = false, ...props }, ref) => (
        <StyledTextInput
            $hasError={hasError}
            className={className}
            data-disabled={disabled}
        >
            <input type="text" disabled={disabled} {...props} ref={ref} />
        </StyledTextInput>
    )
)
