import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { togglePopup } from 'Features/Support'
import { useResponder } from 'Hooks'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

type State = {
    open: boolean
    shouldDismiss: boolean
}

const isDismissedForSession = () => {
    try {
        return !!localStorage.getItem('dismissedForSession')
    } catch (err) {
        logger.error('Error reading from localStorage', err as Error)
        return false
    }
}

const dismissForSession = () => {
    try {
        localStorage.setItem('dismissedForSession', 'true')
    } catch (err) {
        logger.error('Error writing to localStorage', err as Error)
    }
}

const SmallScreenWarning: FC<{ element: ReactNode }> = ({ element }) => {
    const { screenSize } = useResponder()
    const [state, setState] = useState<State>({
        open: !isDismissedForSession() && screenSize !== 'large',
        shouldDismiss: false,
    })

    useEffect(() => {
        if (screenSize === 'large') {
            setState(prev => ({ ...prev, open: false }))
        }
    }, [screenSize])

    const close = useCallback(() => {
        setState(prev => {
            if (prev.shouldDismiss) {
                dismissForSession()
            }
            return { ...prev, open: false }
        })
    }, [])

    return (
        <>
            {element}
            <MinimalModal
                open={state.open}
                onOpenChange={open => {
                    if (!open) close()
                }}
            >
                <Styled>
                    <header>
                        <Icon name="ExclamationTriangle" />
                        <Text as="h2" variant="bold-4">
                            Not optimised for small screens
                        </Text>
                    </header>
                    <section>
                        <blockquote>
                            <Text as="p" variant="regular-4">
                                We are in the process of optimising this feature
                                for small screens. For now, your experience will
                                be substantially better on a larger screen
                                device.
                            </Text>
                            <Text as="p" variant="regular-4">
                                If you have any feedback or want to stay in
                                touch with upcoming updates, please get in
                                touch.
                            </Text>
                        </blockquote>
                        <Text as="cite" variant="regular-5">
                            - The Modlify team
                        </Text>
                    </section>
                    <footer>
                        <IconTextButton
                            icon={
                                state.shouldDismiss
                                    ? 'CheckboxTicked'
                                    : 'CheckboxUnticked'
                            }
                            text="Do not show this message again"
                            onClick={() => {
                                setState(prev => ({
                                    ...prev,
                                    shouldDismiss: !prev.shouldDismiss,
                                }))
                            }}
                            size="xSmall"
                        />
                        <div>
                            <IconTextButton
                                icon="Comment"
                                text="Get in touch"
                                onClick={togglePopup}
                                variant="secondary"
                            />
                            <IconTextButton
                                icon="Check"
                                text="Close"
                                onClick={close}
                                variant="primary"
                            />
                        </div>
                    </footer>
                </Styled>
            </MinimalModal>
        </>
    )
}

const Styled = styled.div`
    padding: 1rem 4vw;

    header {
        padding: 1rem 4vw;
        background-color: ${({ theme }) => theme.palette.support.bg04.normal};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        border-radius: 0.5rem;
    }

    header svg path {
        fill: ${({ theme }) => theme.palette.support['04'].normal};
    }
    header h2 {
        color: ${({ theme }) => theme.palette.text.support04.normal};
    }

    section {
        padding: 1.5rem 4vw 1rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    blockquote p {
        padding-bottom: 1em;
    }

    cite {
        display: block;
        text-align: right;
    }

    footer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    footer button {
        width: 100%;
    }

    footer > div {
        display: flex;
        gap: 0.5rem;
    }
`

export { SmallScreenWarning }
