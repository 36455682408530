import { TextInput } from 'Components/Input'
import { FC } from 'react'

import { pipe } from 'lodash/fp'
import { fromEvent } from 'Utils'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useURLConstraints } from './useURLConstraints'

export const URLField: FC<FieldInputProps<'URLFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useURLConstraints({
        urlConstraints: field.urlConstraints,
    })

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <TextInput
                name={field.type + '||' + field.id}
                id={field.id}
                value={value ?? undefined}
                onChange={pipe(fromEvent, onChange)}
                required={constraints.required}
            />
        </Field>
    )
}
