import processes from 'Assets/images/process-screenshot.png'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { FeatureName } from 'Features/FeatureName'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const Empty: FC = () => {
    const navigate = useNavigate()
    const { isLarge } = useResponder()

    return (
        <EmptyStateCard
            title={'Workflows'}
            paragraphs={[
                <>
                    <FeatureName>Workflows</FeatureName> are what your business
                    does. They represent the way in which you want to work.
                </>,
                'They define the steps needed to fulfil business objectives, and the criteria that must be met to deliver successful outcomes.',
                'They provide structure to ensure relevant business data is captured at the right time.',
            ]}
            button={{
                handler: () => {
                    navigate(
                        isLarge
                            ? '/manage-workspace/processes'
                            : '/manage-workspace/model/processes'
                    )
                },
                text: 'Set up a workflow',
            }}
            imageUrl={isLarge ? processes : '/images/process-card.jpg'}
        />
    )
}

export { Empty }
