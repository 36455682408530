import { Root, Scrollbar, Thumb, Viewport } from '@radix-ui/react-scroll-area'
import { styled } from 'Adapters/Freestyled'
import { FC, ReactNode } from 'react'

type Props = {
    children: ReactNode
    height: string
    width: string
}

export const VerticalScrollArea: FC<Props> = ({ children, height, width }) => {
    return (
        <StyledRoot $height={height} $width={width}>
            <StyledViewport>{children}</StyledViewport>

            <StyledScrollbar orientation="vertical">
                <StyledThumb />
            </StyledScrollbar>
        </StyledRoot>
    )
}

const StyledRoot = styled(Root)<{ $height: string; $width: string }>`
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    overflow: hidden;
`

const StyledViewport = styled(Viewport)`
    width: 100%;
    height: 100%;

    > div {
        //  issue with horizontal overflow in radix scroll area
        // see https://github.com/radix-ui/primitives/issues/926#issuecomment-1015279283
        display: block !important;
    }
`

const StyledScrollbar = styled(Scrollbar)`
    display: flex;
    user-select: none;
    touch-action: none;
    background: transparent;
    z-index: 3;

    &[data-orientation='vertical'] {
        width: 0.5rem;
    }
`

const StyledThumb = styled(Thumb)`
    flex: 1;
    background: ${({ theme }) => theme.palette.ui['05'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    position: relative;
    opacity: 24%;

    /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44px;
        min-height: 44px;
    }
`
