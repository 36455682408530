import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Comparator, ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { Toggle } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { Select } from 'Components/Select'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { usePossibleDelegateProcesses } from 'Hooks/usePossibleDelegateProcesses/usePossibleDelegateProcesses'
import { byTypename } from 'Utils'
import { DelegationTransformerType } from '__generated__'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'
import { useProcessEditor } from '../../useProcessEditor'
import { StyledComparatorBuilderButton } from './styles'

type FanoutProcess = { id: string; name: string; transform?: Transform }
type Transform = {
    type: DelegationTransformerType
    field: { id: string; name: string }
}

type Props = {
    onSave: (
        process: FanoutProcess,
        conditions: Comparator[],
        type: 'Delegate' | 'Fanout'
    ) => void
    open: boolean
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    phaseIndex: number
    businessObjectDefinitionId: string
}

export const AddProcessFanoutCriterionModal: FC<Props> = ({
    onSave,
    open,
    onOpenChange,
    returnFocus,
    phaseIndex,
    businessObjectDefinitionId,
}) => {
    const {
        state: { process },
    } = useProcessEditor()

    const { processes, loading } = usePossibleDelegateProcesses(
        process.operatesUpon.map(o => o.id)
    )

    const [selectedTransform, setSelectedTransform] = useState<
        Transform | undefined
    >()
    const [fanoutProcessId, setFanoutProcessId] = useState('')
    const selectedFanout = processes?.find(
        p => p.process.id === fanoutProcessId
    )

    const { current: inputId } = useRef(v4())

    const [conditions, setConditions] = useState<Comparator[]>([])
    const [type, setType] = useState<'Fanout' | 'Delegate'>('Fanout')

    const handleSubmit = useCallback(() => {
        if (!selectedFanout) return

        onSave(
            {
                ...selectedFanout.process,
                ...(selectedTransform && { transform: selectedTransform }),
            },
            conditions,
            type
        )
        onOpenChange(false)
        setFanoutProcessId('')
        setSelectedTransform(undefined)
    }, [
        onOpenChange,
        onSave,
        selectedFanout,
        selectedTransform,
        conditions,
        type,
    ])

    const availableProcesses = useMemo(() => {
        const inUseDelegateProcessesForThisPhase = new Set(
            process.phases[phaseIndex].criteria
                .filter(byTypename('ProcessDelegatePhaseCriterion' as const))
                .map(c => c.process.id)
        )
        return processes?.filter(
            ({ process }) => !inUseDelegateProcessesForThisPhase.has(process.id)
        )
    }, [phaseIndex, process.phases, processes])

    const isValid = fanoutProcessId !== ''

    const {
        buttonRef,
        open: comparatorBuilderIsOpen,
        openModal,
        setOpen,
        returnFocus: returnFocusToComparatorBuilderButton,
    } = useModalControls()

    return (
        <ControlledModal
            renderContent={() => (
                <StyledModalContent>
                    <InputGroup>
                        {match({ loading, availableProcesses })
                            .with(
                                { availableProcesses: P.not(P.nullish) },
                                ({ availableProcesses }) =>
                                    availableProcesses.length > 0 ? (
                                        <>
                                            <label htmlFor={inputId}>
                                                Process
                                            </label>
                                            <Select
                                                id={inputId}
                                                name="processId"
                                                onValueChange={processId => {
                                                    setFanoutProcessId(
                                                        processId
                                                    )
                                                    const process =
                                                        availableProcesses.find(
                                                            p =>
                                                                p.process.id ===
                                                                processId
                                                        )

                                                    if (
                                                        !process?.untransformed
                                                    ) {
                                                        setSelectedTransform({
                                                            type: DelegationTransformerType.RelationField,
                                                            field: {
                                                                id: '',
                                                                name: '',
                                                            },
                                                        })
                                                    }
                                                }}
                                                options={availableProcesses.map(
                                                    process => ({
                                                        value: process.process
                                                            .id,
                                                        text: process.process
                                                            .name,
                                                    })
                                                )}
                                                value={fanoutProcessId}
                                            />
                                            {selectedFanout &&
                                                !selectedFanout.untransformed && (
                                                    <>
                                                        <label
                                                            htmlFor={inputId}
                                                        >
                                                            Field
                                                        </label>
                                                        <Select
                                                            id={inputId}
                                                            name="fieldId"
                                                            onValueChange={fieldId => {
                                                                const field =
                                                                    selectedFanout.fields.find(
                                                                        f =>
                                                                            f.fieldId ===
                                                                            fieldId
                                                                    )
                                                                if (!field)
                                                                    return

                                                                setSelectedTransform(
                                                                    {
                                                                        type: DelegationTransformerType.RelationField,
                                                                        field: {
                                                                            id: field.fieldId,
                                                                            name: field.fieldName,
                                                                        },
                                                                    }
                                                                )
                                                            }}
                                                            options={
                                                                selectedFanout
                                                                    .fields
                                                                    .length > 0
                                                                    ? selectedFanout.fields.map(
                                                                          p => ({
                                                                              value: p.fieldId,
                                                                              text: p.fieldName,
                                                                          })
                                                                      )
                                                                    : [
                                                                          {
                                                                              value: '',
                                                                              text: 'No fields available',
                                                                          },
                                                                      ]
                                                            }
                                                            value={
                                                                selectedTransform
                                                                    ?.field.id
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </>
                                    ) : (
                                        <Text as="p" variant="regular-4">
                                            No available fields
                                        </Text>
                                    )
                            )
                            .with({ loading: true }, () => <CenteredSpinner />)
                            .otherwise(() => (
                                <Text as="p" variant="regular-4">
                                    An error occurred fetching available
                                    workflows
                                </Text>
                            ))}
                    </InputGroup>

                    <Text
                        as="label"
                        htmlFor="fanout-or-delegate"
                        variant="regular-4"
                    >
                        Completion required?
                        <Toggle
                            name="fanout-or-delegate"
                            id="fanout-or-delegate"
                            indicatorText={['Yes', 'No']}
                            checked={type === 'Delegate'}
                            onCheckedChange={checked =>
                                setType(checked ? 'Delegate' : 'Fanout')
                            }
                        />
                    </Text>

                    <StyledComparatorBuilderButton
                        icon={'Plus'}
                        text={
                            !conditions.length
                                ? 'Set conditions'
                                : `${conditions.length} condition(s) set`
                        }
                        size="small"
                        variant="secondary"
                        onClick={openModal}
                        ref={buttonRef}
                    />

                    <ComparatorBuilderV2
                        initialComparators={conditions}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                        onConfirm={comparators => setConditions(comparators)}
                        title={'Add conditions'}
                        open={comparatorBuilderIsOpen}
                        onOpenChange={setOpen}
                        returnFocus={returnFocusToComparatorBuilderButton}
                    />

                    <Buttons>
                        <TextButton
                            text={'Cancel'}
                            onClick={() => onOpenChange(false)}
                            size="small"
                            variant="secondary"
                        />

                        <TextButton
                            text="Create"
                            disabled={!isValid}
                            onClick={handleSubmit}
                            size="small"
                        />
                    </Buttons>
                </StyledModalContent>
            )}
            title="Dispatch to another workflow"
            description="A workflow to which we can dispatch this object"
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;

    > label {
        ${flex('row', 'space-between', 'center')};
        width: 100%;
    }
`

const InputGroup = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;

    label {
        padding-bottom: 0.25rem;
    }
`

const Buttons = styled.div`
    ${flex('row', 'flex-start', 'center')};
    width: 100%;
    gap: 1rem;

    > button {
        flex: 1;
    }
`
