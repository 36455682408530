import { logger } from 'Adapters/Logger'
import { FC, useState } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { Fallback } from './Fallback'

type Props = {
    children: React.ReactNode
    page: string
}

export const ErrorBoundary: FC<Props> = ({ children, page }) => {
    const [errorMessage, setErrorMessage] = useState('')

    return (
        <ReactErrorBoundary
            fallback={<Fallback errorMessage={errorMessage} />}
            onError={e => {
                setErrorMessage(e.message)
                logger.error('Error caught in boundary', e, { page })
            }}
        >
            {children}
        </ReactErrorBoundary>
    )
}
