import { ValueSelectorType } from '__generated__'
import { Comparator } from 'Components/ComparatorBuilderV2'
import { match, P } from 'ts-pattern'
import {
    Action,
    ClearAllFilters,
    MoveGroupDown,
    MoveGroupUp,
    SetFilters,
    SetGroupColor,
    SetGroupingSelection,
    ToggleFieldDisplay,
    ToggleShowCompleted,
    ViewConfig,
} from '../types'

const handle =
    <A extends Action>(
        handler: (_: { config: ViewConfig; action: A }) => ViewConfig
    ) =>
    (config: ViewConfig) =>
    (action: A): ViewConfig =>
        handler({ config, action })

export const handleSetGroupingSelection = handle<SetGroupingSelection>(
    ({ config, action }) => ({
        ...config,
        grouping: {
            ...config.grouping,
            ...action.payload,
        },
    })
)

export const handleSetGroupColor = handle<SetGroupColor>(
    ({
        config,
        action: {
            payload: { fieldId, groupId, color },
        },
    }) => ({
        ...config,
        grouping: {
            ...config.grouping,
            fields: config.grouping.fields.map(field =>
                field.id === fieldId
                    ? {
                          ...field,
                          groups: field.groups.map(group =>
                              group.id === groupId
                                  ? {
                                        ...group,
                                        color,
                                    }
                                  : group
                          ),
                      }
                    : field
            ),
        },
    })
)

export const handleMoveGroup = handle<MoveGroupUp | MoveGroupDown>(
    ({
        config,
        action: {
            type,
            payload: { fieldId, groupId },
        },
    }) => {
        const field = config.grouping.fields.find(({ id }) => id === fieldId)
        if (!field) return config

        const groupIndex = field.groups.findIndex(({ id }) => id === groupId)
        const filteredGroups = field.groups.filter(({ id }) => id !== groupId)
        const shiftedIndex =
            type === 'MoveGroupDown' ? groupIndex + 1 : groupIndex - 1

        return {
            ...config,
            grouping: {
                ...config.grouping,
                fields: config.grouping.fields.map(field =>
                    field.id === fieldId
                        ? {
                              ...field,
                              groups: [
                                  ...filteredGroups.slice(0, shiftedIndex),
                                  field.groups[groupIndex],
                                  ...filteredGroups.slice(shiftedIndex),
                              ],
                          }
                        : field
                ),
            },
        }
    }
)

export const handleSetFilters = handle<SetFilters>(
    ({ config, action: { payload } }) => {
        return {
            ...config,
            filters: payload.comparators.reduce((acc, comparator) => {
                if (
                    comparator.valueSelector.type !==
                    ValueSelectorType.FieldValue
                ) {
                    throw new Error('Only FieldValue selectors are supported')
                }

                const fieldId = comparator.valueSelector.fieldId

                return match(acc[fieldId])
                    .with(P.nullish, () => ({
                        ...acc,
                        [fieldId]: comparator,
                    }))
                    .with(P.array(P._), () => ({
                        ...acc,
                        [fieldId]: (acc[fieldId] as Comparator[]).concat(
                            comparator
                        ),
                    }))
                    .with(P.not(P.nullish), () => ({
                        ...acc,
                        [fieldId]: [acc[fieldId] as Comparator, comparator],
                    }))
                    .exhaustive()
            }, {} as Record<string, Comparator | Comparator[]>),
        }
    }
)

export const handleClearAllFilters = handle<ClearAllFilters>(({ config }) => ({
    ...config,
    filters: {},
}))

export const handleToggleFieldDisplay = handle<ToggleFieldDisplay>(
    ({
        config,
        action: {
            payload: { fieldId },
        },
    }) => ({
        ...config,
        cardSettings: {
            ...config.cardSettings,
            selected: config.cardSettings.selected.includes(fieldId)
                ? config.cardSettings.selected.filter(id => id !== fieldId)
                : [...config.cardSettings.selected, fieldId],
        },
    })
)

export const handleToggleShowCompleted = handle<ToggleShowCompleted>(
    ({ config }) => ({
        ...config,
        showCompleted: !config.showCompleted,
    })
)
