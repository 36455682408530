import { flex, styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'

export const StyledSearchBar = styled.div`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
    padding: 0.625rem 0.625rem 0.625rem 0.5rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background: ${({ theme }) => theme.palette.ui['01'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    .icon {
        path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    .clear-icon-button {
        padding: 0;
    }

    input {
        ${regular5};
        flex: 1;
        color: ${({ theme }) => theme.palette.text['01'].normal};
        border: none;

        &:focus-visible {
            box-shadow: none; // Handled by parent
        }

        &::placeholder {
            color: ${({ theme }) => theme.palette.text['03'].normal};
            opacity: 1; /* Firefox uses lower opacity by default */
        }
    }

    &:hover {
        background: ${({ theme }) => theme.palette.ui['03'].hover};

        .clear-icon-button {
            background: ${({ theme }) => theme.palette.ui['03'].hover};
        }
    }

    &:focus-within {
        background: ${({ theme }) => theme.palette.ui['01'].normal};
        border-color: transparent;
        box-shadow: inset 0 0 0 2px
            ${({ theme }) => theme.palette.brand['01'].normal};

        .clear-icon-button {
            background: ${({ theme }) => theme.palette.ui['01'].normal};
        }
    }
`
