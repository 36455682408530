import {
    flattenAndFilterBusinessObjects,
    useBusinessObjectFilterState,
} from 'Features/FilterBusinessObject'
import { useFetchBusinessObjectsById } from 'Hooks'
import { useMemo } from 'react'

export const useFilterBusinessObjects = (
    businessObjectDefinitionId: string
) => {
    const { filterState } = useBusinessObjectFilterState()

    const { businessObjects, hasInitiallyLoaded } = useFetchBusinessObjectsById(
        businessObjectDefinitionId
    )

    const filteredBusinessObjects = useMemo(() => {
        return flattenAndFilterBusinessObjects(
            Object.values(filterState),
            businessObjects
        )
    }, [businessObjects, filterState])

    return { filteredBusinessObjects, hasInitiallyLoaded }
}
