import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { TextAreaInput } from 'Components/Input'
import { LoadingBar } from 'Components/LoadingBar'
import { Text } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { Field } from '../Field'
import { useProcessCreator } from '../useProcessCreator'
import { useSuggestedPhases } from './useSuggestedPhases'

const AIStep2: FC = () => {
    const {
        state: { ai },
        dispatch,
    } = useProcessCreator()

    const { getSuggestion, loading, loadingText, errorToast, percentComplete } =
        useSuggestedPhases(ai)

    const { current: purposeInputId } = useRef(v4())

    const infoText = loading
        ? loadingText
        : 'Generating your workflow can take up to 2 minutes.'

    return (
        <Styled>
            <header>
                <Text as="h3" variant="bold-2">
                    Tell us about your workflow
                </Text>
            </header>
            <fieldset>
                <Field
                    label="What is this workflow designed to achieve?"
                    htmlFor={purposeInputId}
                    input={
                        <TextAreaInput
                            id={purposeInputId}
                            name="purpose"
                            value={ai.purpose}
                            placeholder="Onboarding new employees, including setting up their accounts and introducing them to the team."
                            onChange={event =>
                                dispatch({
                                    type: 'aiInputChanged',
                                    payload: {
                                        key: 'purpose',
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    }
                />
            </fieldset>
            <footer>
                <div>
                    {loading && (
                        <LoadingBar
                            progress={percentComplete}
                            loadingTimeSeconds={9}
                        />
                    )}
                    <IconTextButton
                        icon="AngleRight"
                        iconRight
                        text="Generate my workflow"
                        onClick={getSuggestion}
                        variant="primary"
                        disabled={!ai.purpose || loading}
                    />
                </div>
                <Text as="p" variant="regular-5">
                    {infoText}
                </Text>
            </footer>

            <SimpleToast
                open={errorToast.open}
                onOpenChange={errorToast.setOpen}
                description="Error generating workflow. Please try again."
            />
        </Styled>
    )
}

const Styled = styled.section`
    width: 30rem;
    max-width: 100%;

    fieldset {
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
    }

    footer {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        > div {
            ${flex('row', 'flex-end', 'center')}
            gap: 1rem;
            width: 100%;
        }
    }
`

export { AIStep2 }
