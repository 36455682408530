import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { createContext, FC, useContext } from 'react'
import { strings } from './strings'

/*
 * We are using a context provider for delete toasts so when deleting a row
 * the toast won't get prematurely removed from the DOM
 */

type Props = { children: React.ReactNode }

type Value = {
    triggerDeleteSuccessToast: () => void
}

const Context = createContext<Value | undefined>(undefined)

export const DeleteSuccessToastProvider: FC<Props> = ({ children }) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    return (
        <Context.Provider value={{ triggerDeleteSuccessToast: triggerToast }}>
            {/* Delete Success */}
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description={strings.deleteSuccess}
            />
            {children}
        </Context.Provider>
    )
}

export const useTriggerDeleteToast = () => {
    const context = useContext(Context)

    if (context === undefined) {
        throw new Error(
            'useTriggerDeleteToast must be used within a DeleteSuccessToastProvider'
        )
    }

    return context
}
