import { styled } from 'Adapters/Freestyled'
import { bold4 } from 'Components/Text'
import { identity } from 'lodash'
import { ReactElement } from 'react'
import { match } from 'ts-pattern'

const Tabs = <
    const T extends Record<
        string,
        {
            label: string
            content: ReactElement
            hidden?: boolean
        }
    >
>({
    tabs,
    current,
    onSelect,
}: {
    tabs: T
    current: keyof T
    onSelect: (selection: keyof T) => void
}): ReactElement => {
    const visibleTabs = Object.entries(tabs).filter(([, v]) => !v.hidden)
    const tabKey = match(current)
        .when(c => !tabs[c].hidden, identity)
        .otherwise(() => visibleTabs[0]?.[0])
    return (
        <>
            <Styled>
                {visibleTabs.map(([key, { label }]) => (
                    <button 
                        key={key}
                        onClick={() => onSelect(key)}
                        data-current={key === tabKey}
                        role="tab"
                    >
                        {label}
                    </button>
                ))}
            </Styled>
            {tabKey && tabs[tabKey].content}
        </>
    )
}

const Styled = styled.nav`
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};

    button {
        ${bold4}
        text-decoration: none;
        display: inline-block;
        padding: 0.75rem 1rem 0.5rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        border-bottom: 0.25rem solid transparent;
        border-radius: 0.25rem 0.25rem 0 0;
        transition: background-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
            color ${({ theme }) => theme.animation.buttonTransitionDuration}
                ease,
            border-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    button:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    button:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    button[data-current='true'] {
        color: ${({ theme }) => theme.palette.text['01'].normal};
        border-bottom-color: ${({ theme }) => theme.palette.ui['07'].normal};
    }
`

export { Tabs }
