import { styled } from 'Adapters/Freestyled'
import { OverflowModalButton } from 'Components/Button'
import { DocumentMetadata, useTriggerDownload } from 'Components/Documents'
import { Icon } from 'Components/Icon'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { ContactUsToast } from 'Components/Toast'
import { FC, useRef, useState } from 'react'
import { formatBytes } from 'Utils'
import { FileIcon } from '../../../../FileIcon'
import { StyledDocumentCard, StyledEllipsisButton } from './styles'
import { useDelete } from './useDelete'

type Props = {
    document: DocumentMetadata
}

export const DocumentCard: FC<Props> = ({ document }) => {
    const [open, setOpen] = useState(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    const returnFocus = () => {
        buttonRef.current?.focus()
    }

    const { handleDelete, deleteLoading, errorToastOpen, setErrorToastOpen } =
        useDelete({
            document,
            closeModal: () => {
                setOpen(false)
            },
        })

    const { triggerDownload } = useTriggerDownload({
        url: document.url,
        onSuccess: () => setOpen(false),
    })

    return (
        <StyledDocumentCard>
            <FileIcon name={document.fileType} />

            <div className="label">
                <Text as="h2" variant="bold-5">
                    {document.name}
                </Text>
                <Text as="p" variant="regular-6">
                    {formatBytes(document.sizeInBytes)}
                </Text>
            </div>

            <StyledEllipsisButton
                ref={buttonRef}
                onClick={() => {
                    setOpen(true)
                }}
            >
                <Icon name="More" />
            </StyledEllipsisButton>

            <ControlledModal
                open={open}
                onOpenChange={setOpen}
                renderContent={() => {
                    return (
                        <StyledModalMenu>
                            <OverflowModalButton
                                text={'Download document'}
                                icon="ArrowDown"
                                onClick={triggerDownload}
                            />
                            <OverflowModalButton
                                text={
                                    !deleteLoading
                                        ? 'Delete document'
                                        : 'Deleting document'
                                }
                                icon="Delete"
                                onClick={handleDelete}
                                disabled={deleteLoading}
                            />
                        </StyledModalMenu>
                    )
                }}
                title={document.name}
                description={'Perform actions on the chosen file'}
                hideDescription
                returnFocus={returnFocus}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'We were unable to delete your document. Contact us if the problem persists.'
                }
            />
        </StyledDocumentCard>
    )
}

const StyledModalMenu = styled.div`
    width: calc(100vw - 3rem); // Account for padding on both sides
`
