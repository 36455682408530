import { styled } from 'Adapters/Freestyled'
import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { MinimalModal } from 'Components/Modal/Minimal'
import { CenteredSpinner } from 'Components/Spinner'
import { SimpleToast } from 'Components/Toast'
import { CreateTenantModal } from 'Features/CreateTenantModal'
import { useModalControls } from 'Hooks'
import { useSandbox } from 'Hooks/useSandbox'
import { useSetActiveTenant } from 'Hooks/useSetActiveTenant/useSetActiveTenant'
import { FC, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSmallWorkspaceControlQuery } from './__generated__/q'
import { strings } from './strings'

type Props = {
    onNavigate: () => void
}

const WorkspaceControl: FC<Props> = ({ onNavigate }) => {
    const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = useState(false)
    const navigate = useNavigate()
    const { data } = useSmallWorkspaceControlQuery()
    const { setActiveTenant, errorToast } = useSetActiveTenant()
    const {
        open: createTenantOpen,
        setOpen: setCreateTenantOpen,
        closeModal,
    } = useModalControls()
    const createTenantBtnRef = useRef<HTMLDivElement>(null)
    const { isSandbox } = useSandbox()

    return data ? (
        <menu>
            <MenuNavButton
                onClick={() => {
                    navigate('/manage-workspace')
                    onNavigate()
                }}
                title="Manage workspace"
                description={data.currentUser.activeTenant?.tenant.name || ''}
                icon="Settings"
            />
            {data.currentUser.tenancies.length > 1 && (
                <MenuNavButton
                    onClick={() => setSwitchWorkspaceOpen(true)}
                    title="Switch workspace"
                    description={`${data.currentUser.tenancies.length} workspaces`}
                    icon="Sync"
                />
            )}
            <MenuNavButton
                disabled={isSandbox}
                onClick={() => setCreateTenantOpen(true)}
                title="Create new workspace"
                description={
                    isSandbox
                        ? `Not available in sandbox`
                        : `With it's own models and members`
                }
                icon="Plus"
            />
            <MinimalModal
                open={switchWorkspaceOpen}
                onOpenChange={setSwitchWorkspaceOpen}
            >
                <ul>
                    {data.currentUser.tenancies.map(
                        ({ tenant: { id, name } }) => {
                            const memberCount =
                                data.users.length === 1
                                    ? '1 member'
                                    : `${data.users.length} members`
                            const processCount =
                                data.processes.length === 1
                                    ? '1 workflow'
                                    : `${data.processes.length} workflows`
                            const datasetCount =
                                data.businessObjectDefinitions.length === 1
                                    ? '1 dataset'
                                    : `${data.businessObjectDefinitions.length} datasets`
                            return (
                                <li key={id}>
                                    <MenuNavButton
                                        disabled={isSandbox}
                                        onClick={() => setActiveTenant(id)}
                                        title={name}
                                        description={
                                            <>
                                                {memberCount}
                                                <span className="spacer">
                                                    •
                                                </span>
                                                {processCount}
                                                <span className="spacer">
                                                    •
                                                </span>
                                                {datasetCount}
                                            </>
                                        }
                                        icon={
                                            id ===
                                            data.currentUser.activeTenant
                                                ?.tenant.id
                                                ? 'CircleCheck'
                                                : 'Circle'
                                        }
                                    />
                                </li>
                            )
                        }
                    )}
                </ul>
            </MinimalModal>
            <SimpleToast {...errorToast} description={strings.errorMessage} />
            <CreateTenantModal
                open={createTenantOpen}
                close={closeModal}
                onOpenChange={setCreateTenantOpen}
                returnFocus={() => createTenantBtnRef.current?.focus()}
            />
        </menu>
    ) : (
        <StyledLoading>
            <CenteredSpinner />
        </StyledLoading>
    )
}

const StyledLoading = styled.div`
    padding: 1rem;
`

export { WorkspaceControl }
