import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { CriterionConditions } from '../../CriterionConditions/CriterionConditions'
import { FieldDefDictionary } from '../../types'
import { StyledCriterion } from '../styles'
import { CriterionProps } from '../types'

export const ProcessFanoutPhaseCriterion: FC<{
    criterion: Extract<
        CriterionProps['criterion'],
        { __typename: 'ProcessFanoutPhaseCriterion' }
    >
    fieldDefById: FieldDefDictionary
}> = ({ criterion, fieldDefById }) => {
    return (
        <StyledCriterion>
            <div className="action">
                <Icon name="Process" />
                <Text as="p" variant="bold-6">
                    Sub-workflow (optional)
                </Text>
            </div>

            <Text as="p" variant="regular-4">
                {criterion.process.name}
            </Text>
            {criterion.transform && (
                <Text as="p" variant="regular-6">
                    {criterion.transform.field.name}
                </Text>
            )}

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </StyledCriterion>
    )
}
