import { styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { Definition } from './Definition/Definition'
import { Usage } from './Usage'
import { useBusinessObjectDefinitionDisplayQuery } from './__generated__/q'

type Props = {
    id: string
}

const BusinessObjectDefinitionDisplay: FC<Props> = ({ id }) => {
    const { data } = useBusinessObjectDefinitionDisplayQuery({
        variables: { input: { id } },
    })
    return match(data)
        .with(
            { businessObjectDefinition: P.not(P.nullish) },
            ({ businessObjectDefinition }) => (
                <Styled>
                    <Definition definition={businessObjectDefinition} />
                    <Usage definition={businessObjectDefinition} />
                </Styled>
            )
        )
        .otherwise(() => <Loading />)
}

const Loading = styled(CenteredSpinner)`
    height: 100%;
`

const Styled = styled.div`
    display: grid;
    grid-template: 1fr / 1fr auto;
    height: 100%;
    overflow: hidden;
`

export { BusinessObjectDefinitionDisplay }
