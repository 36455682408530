import { flex, styled } from 'Adapters/Freestyled'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { Attachment } from '../../types'
import { Separator } from '../Separator'
import { Attach } from './Attach'
import { Link } from './Link'
import { Mark } from './Mark'
import { MentionHelp } from './MentionHelp'
import { Send } from './Send'

type Props = {
    attachments?: Attachment[]
    onAttachment?: (attachments: Attachment[]) => void
    onSubmit?: () => void
    sendDisabled: boolean
}

export const Toolbar: FC<Props> = ({
    attachments,
    onAttachment,
    onSubmit,
    sendDisabled,
}) => {
    const { screenSize } = useResponder()
    return match(screenSize)
        .with('large', () => (
            <StyledLarge>
                <Mark mark="bold" />
                <Mark mark="italic" />
                <Mark mark="underline" />
                <Mark mark="strikethrough" />
                <Separator />

                <Link />

                <MentionHelp />

                {(onAttachment || onSubmit) && <Separator />}

                {onAttachment && attachments && (
                    <Attach
                        onAttachment={onAttachment}
                        attachments={attachments}
                    />
                )}
                {onSubmit && (
                    <Send
                        onSubmit={onSubmit}
                        disabled={sendDisabled}
                        variant="primary"
                    />
                )}
            </StyledLarge>
        ))
        .otherwise(() => (
            <StyledSmall>
                <div>
                    <div>
                        <Mark mark="bold" />
                        <Mark mark="italic" />
                        <Mark mark="underline" />
                        <Mark mark="strikethrough" />
                    </div>
                    <div>
                        <Link />
                        <MentionHelp />
                        {onAttachment && attachments && (
                            <Attach
                                onAttachment={onAttachment}
                                attachments={attachments}
                            />
                        )}
                    </div>
                </div>
                <div>
                    {onSubmit && (
                        <Send
                            onSubmit={onSubmit}
                            disabled={sendDisabled}
                            variant="primary"
                        />
                    )}
                </div>
            </StyledSmall>
        ))
}

const StyledLarge = styled.menu`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.25rem;
    padding: 0.25rem;
    height: 2.75rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    button:enabled .icon path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    button:not(:enabled) .icon path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    button:not(:enabled) {
        opacity: 0.5;
    }
`

const StyledSmall = styled.menu`
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    > div:first-child > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.25rem;
        gap: 0.25rem;

        button:enabled .icon path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }

        button:not(:enabled) .icon path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    > div:last-child {
        padding: 0.5rem 0.25rem 0.25rem;
    }
    > div:last-child button {
        width: 100%;
    }
`
