import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
export type BusinessObjectDefinitionCardFragment = { __typename?: 'BusinessObjectDefinition', id: string, name: string, description?: string | null, fields: Array<{ __typename?: 'BooleanFieldDefinition', id: string } | { __typename?: 'CurrencyFieldDefinition', id: string } | { __typename?: 'DateFieldDefinition', id: string } | { __typename?: 'DocumentFieldDefinition', id: string } | { __typename?: 'EmailFieldDefinition', id: string } | { __typename?: 'ListFieldDefinition', id: string } | { __typename?: 'NumberFieldDefinition', id: string } | { __typename?: 'RelationFieldDefinition', id: string } | { __typename?: 'SelectFieldDefinition', id: string } | { __typename?: 'TelephoneFieldDefinition', id: string } | { __typename?: 'TextFieldDefinition', id: string } | { __typename?: 'URLFieldDefinition', id: string } | { __typename?: 'UpdatesFieldDefinition', id: string } | { __typename?: 'UserFieldDefinition', id: string }>, usage: { __typename?: 'BusinessObjectDefinitionUsage', instanceCount: number, processes: Array<{ __typename?: 'Process', id: string }> } };

export const BusinessObjectDefinitionCardFragmentDoc = gql`
    fragment BusinessObjectDefinitionCard on BusinessObjectDefinition {
  id
  name
  description
  fields {
    id
  }
  usage {
    processes {
      id
    }
    instanceCount
  }
}
    `;