import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { CreateActionModal } from 'Features/CreateActionModal'
import { useModalControls } from 'Hooks'
import { FC } from 'react'

export const ActionsHeader: FC = () => {
    const { open, buttonRef, setOpen, openModal, closeModal, returnFocus } =
        useModalControls()

    return (
        <StyledHeader>
            <Text as="h1" variant="bold-1">
                Actions
            </Text>

            <div className="controls">
                <IconTextButton
                    icon="Plus"
                    text="Create Action"
                    onClick={openModal}
                    ref={buttonRef}
                    variant="primary"
                    size="xSmall"
                />

                <CreateActionModal
                    open={open}
                    onOpenChange={setOpen}
                    returnFocus={returnFocus}
                    closeModal={closeModal}
                />
            </div>
        </StyledHeader>
    )
}

const StyledHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem 1rem;

    [data-screen-size='small'] & {
        padding: 1rem 4vw;
    }
`
