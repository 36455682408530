import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { SetBusinessObjectContent } from './SetBusinessObjectContent'

type Props = {
    onBusinessObjectSelect: (params: {
        businessObjectDefinitionId: string
        businessObjectId: string
        businessObjectLabel: string
    }) => void
    selection: {
        businessObjectId?: string
        businessObjectDefinitionId?: string
        businessObjectLabel?: string
    }
    buttonText?: string
    title?: string
    description?: string
    disabled?: boolean
}

export const SetBusinessObject: FC<Props> = ({
    onBusinessObjectSelect,
    selection,
    buttonText = 'Set record',
    title = 'Set record',
    description = '',
    disabled,
}) => {
    const { open, setOpen, buttonRef, returnFocus, openModal, closeModal } =
        useModalControls()

    return (
        <>
            <IconTextButton
                icon={'Cube'}
                text={selection.businessObjectLabel ?? buttonText}
                variant="secondary"
                onClick={e => {
                    e.preventDefault()
                    openModal()
                }}
                ref={buttonRef}
                disabled={disabled}
                className="set-business-object-button"
            />

            <ControlledModal
                renderContent={() => (
                    <SetBusinessObjectContent
                        onBusinessObjectSelect={businessObjectData => {
                            onBusinessObjectSelect(businessObjectData)
                            closeModal()
                        }}
                        selection={selection}
                    />
                )}
                title={title}
                description={description}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}
