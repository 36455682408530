import { useLogout } from 'Adapters/Authentication/useLogout'
import { logger } from 'Adapters/Logger'
import { StatusButton, TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { FC } from 'react'
import { strings } from './strings'
import { Styled } from './styles'
import { useUserDetails } from './useUserDetails'
import { useCreateUserAndTenantMutation } from './__generated__/CreateUserTenant'

export type Props = {
    onSuccess: () => void
}

export const CreateUserTenant: FC<Props> = ({ onSuccess }) => {
    const { open, triggerToast, setOpen } = useTriggerToast()
    const { userDetails, formIncomplete, setName, setCompanyName } =
        useUserDetails()

    const [update, result] = useCreateUserAndTenantMutation({
        variables: {
            input: userDetails,
        },
        onCompleted() {
            onSuccess()
        },
        onError(e) {
            logger.error('Failed to create user and tenant', e)
            triggerToast()
        },
    })

    const { status } = useMutationStatus(result)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        update()
    }

    const { logout } = useLogout()

    return (
        <Styled onSubmit={handleSubmit}>
            <header>
                <p>{strings.subHeading}</p>
                <h2>{strings.heading}</h2>
            </header>

            <div className="inputs">
                <TextInput
                    value={userDetails.name}
                    onChange={e => setName(e.target.value)}
                    placeholder={strings.nameLabel}
                    aria-label={strings.nameLabel}
                />

                <TextInput
                    value={userDetails.companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    placeholder={strings.companyNameLabel}
                    aria-label={strings.companyNameLabel}
                />
            </div>

            <footer>
                <StatusButton
                    status={status}
                    text={{
                        ready: strings.btnTextNormal,
                        loading: strings.btnTextWaiting,
                        error: strings.btnTextError,
                        success: strings.btnTextSuccess,
                    }}
                    aria-disabled={status === 'loading' || status === 'success'}
                    disabled={formIncomplete}
                />

                <TextButton
                    text="Logout"
                    onClick={logout}
                    variant="ghost"
                    size="small"
                />
            </footer>

            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description={strings.errorMessage}
            />
        </Styled>
    )
}
