import { logger } from 'Adapters/Logger'
import { useCallback } from 'react'
import { useProcessCreator } from '../useProcessCreator'
import { useSaveIntegrations } from './saveIntegrations'
import { useCreator_UpdateProcessMutation } from './__generated__/mutation'

export const useUpdateProcess = (
    setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const {
        state: { input, id, integrations, initialIntegrations },
        onCompleted,
    } = useProcessCreator()

    const [save, { loading }] = useCreator_UpdateProcessMutation()
    const { saveIntegrations } = useSaveIntegrations({
        onError: () => setShowErrorToast(true),
    })

    const handleUpdate = useCallback(async () => {
        const result = await save({
            variables: {
                input: {
                    ...input,
                    operatesUpon: input.operatesUpon[0],
                    id: id ?? '',
                },
            },
            update: (cache, { data }) => {
                data &&
                    cache.modify({
                        fields: {
                            processes: (existing: { id: string }[]) =>
                                existing.find(p => p.id === data.putProcess.id)
                                    ? existing
                                    : [...existing, data.putProcess],
                        },
                    })
            },
            onError: e => {
                logger.error('Failed to save process', e, {
                    input,
                })
                setShowErrorToast(true)
            },
            onQueryUpdated: q => q.refetch(),
        })

        const process = result.data?.putProcess

        if (!process) {
            logger.error(
                'Could not save integrations as no process ID returned'
            )
            return
        }

        await saveIntegrations(integrations, initialIntegrations, process.id)

        onCompleted(process)
    }, [
        save,
        input,
        id,
        saveIntegrations,
        integrations,
        initialIntegrations,
        onCompleted,
        setShowErrorToast,
    ])

    return {
        handleUpdate,
        updateLoading: loading,
    }
}
