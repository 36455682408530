import { logger } from 'Adapters/Logger'
import { IconButton } from 'Components/Button'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import styled from 'styled-components'
import { useMoveBoToPrevPhaseMutation } from './__generated__/MoveBOToPrevPhase'

type Props = {
    businessObjectId: string
    processId: string
    disabled: boolean
    tootip: string
}

const PrevPhase: FC<Props> = ({
    businessObjectId,
    processId,
    disabled,
    tootip,
}) => {
    const [move] = useMoveBoToPrevPhaseMutation()
    const { open, setOpen, triggerToast } = useTriggerToast()
    return (
        <>
            <Tooltip content={tootip} side="bottom">
                <Styled
                    variant="secondary"
                    iconName="AngleLeft"
                    disabled={disabled}
                    onClick={() => {
                        move({
                            variables: {
                                input: {
                                    businessObjectId,
                                    processId,
                                },
                                onProcessInput: {
                                    processId,
                                },
                                hasBusinessObjectCompletedInput: {
                                    businessObjectId,
                                },
                            },
                            onError: e => {
                                logger.error(
                                    'Failed to move business object to previous phase',
                                    e,
                                    { businessObjectId, processId }
                                )
                                triggerToast()
                            },
                        })
                    }}
                    hideTitle
                />
            </Tooltip>
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to move the record"
            />
        </>
    )
}

const Styled = styled(IconButton)`
    border-radius: 0;
`

export { PrevPhase }
