import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { noop } from 'lodash'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { useDeleteScheduledEventMutation } from './__generated__/mutation'

type Props = {
    id: string
    onDeleted?: () => void
}

export const DeleteScheduledEvent: FC<Props> = ({ id, onDeleted }) => {
    const modalControls = useModalControls()

    const [deleteEvent, { loading }] = useDeleteScheduledEventMutation({
        variables: { input: { id } },
        onCompleted: pipe(
            () => modalControls.setOpen(false),
            onDeleted ?? noop
        ),
    })

    return (
        <>
            <IconTextButton
                icon="Delete"
                text="Delete"
                onClick={() => modalControls.setOpen(true)}
            />
            <ControlledModal
                {...modalControls}
                onOpenChange={modalControls.setOpen}
                title="Are you sure?"
                description="Deletion is permanent"
                renderContent={() => (
                    <>
                        <IconTextButton
                            onClick={() => deleteEvent()}
                            disabled={loading}
                            text="Delete"
                            icon="Delete"
                        />
                    </>
                )}
            />
        </>
    )
}
