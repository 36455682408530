import { useFlow } from '@frigade/react'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { FrigadeHint } from 'Features/Frigade/FrigadeHint'
import { useModalControls } from 'Hooks'
import { FC, ReactNode } from 'react'
import { IDENTIFIER, STEP_IDENTIFIER } from './constants'
import { colorStyles } from './styles'

type Props = {
    content: ReactNode
}

export const TutorialModalMobile: FC<Props> = ({ content }) => {
    const { open, setOpen, openModal } = useModalControls()

    const { flow } = useFlow(IDENTIFIER)
    const hint = flow?.steps.get(STEP_IDENTIFIER)

    if (!hint) return null

    return (
        <>
            <FrigadeHint flowId={IDENTIFIER} stepId={STEP_IDENTIFIER}>
                <StyledTutorialTriggerMobile
                    iconName={'Question'}
                    onClick={() => {
                        openModal?.()

                        if (!hint.$state.completed) {
                            hint.complete()
                        }
                    }}
                />
            </FrigadeHint>

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                {content}
            </MinimalModal>
        </>
    )
}

const StyledTutorialTriggerMobile = styled(IconButton)`
    ${colorStyles};
`
