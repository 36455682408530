import { styled } from 'Adapters/Freestyled'
import { OnboardingChecklistDesktop } from 'Features/OnboardingChecklistFrigade'
import { FC } from 'react'
import { NavItem } from './NavItem'
import { WorkspaceControl } from './WorkspaceControl'

type Props = {
    workspaceControlOpen: boolean
    setWorkspaceControlOpen: (open: boolean) => void
}

const LargeWorkspaceMenu: FC<Props> = ({
    workspaceControlOpen,
    setWorkspaceControlOpen,
}) => {
    return (
        <Styled>
            <header>
                <WorkspaceControl
                    open={workspaceControlOpen}
                    setOpen={setWorkspaceControlOpen}
                />
            </header>
            <nav>
                <NavItem
                    name="Workflows"
                    icon="Process"
                    to="processes"
                    expanded
                />
                <NavItem name="Datasets" icon="Cube" to="datasets" expanded />
                <NavItem
                    name="Actions"
                    icon="CircleCheck"
                    to="actions"
                    end
                    expanded
                />
                <OnboardingChecklistDesktop />
            </nav>
            <footer>
                <NavItem
                    name="Manage workspace"
                    icon="Settings"
                    to="/manage-workspace"
                    expanded
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.menu`
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    width: 15rem;
    display: flex;
    flex-direction: column;

    > nav {
        flex: 1;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > footer {
        padding: 0.5rem;
        border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

export { LargeWorkspaceMenu }
