import { flex, styled } from 'Adapters/Freestyled'

export const StyledDocumentCard = styled.li`
    ${flex('row', 'flex-start', 'center')};
    width: 100%;
    padding: 1.125rem 0.5rem;

    .label {
        padding-left: ${({ theme }) => theme.spacing['04']};

        p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }
    }

    button {
        margin-left: auto;
    }
`

export const StyledEllipsisButton = styled.button`
    height: 2.5rem;
    width: 2.5rem;
    left: ${({ theme }) => theme.spacing['03']};
    border-radius: ${({ theme }) => theme.spacing['02']};
    transition: 0.1s;

    > .icon {
        ${flex('column', 'center', 'center')};

        svg {
            height: 1.5rem;
            width: 1.5rem;

            path {
                fill: ${({ theme }) => theme.palette.icon['01'].normal};
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['01'].hover};
        }

        &:active {
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['01'].active};
        }
    }
`
