import { Group } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import {
    Dropdown,
    DropdownLabel,
    DropdownSortButton,
} from 'Components/Dropdown'
import { Tooltip } from 'Components/Tooltip'
import { FieldType } from '__generated__'
import { FC, useState } from 'react'
import { useActionsView } from '../../../../../../Features/ActionView/useActionsView'

type Props = {
    extensionFields: { id: string; label: string; type: FieldType }[]
}

export const Sort: FC<Props> = ({ extensionFields }) => {
    const [open, setOpen] = useState(false)

    const {
        toggleSort,
        sort: [by, order],
        hasSort,
    } = useActionsView()

    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <Tooltip
                    content={`Sort by : ${by} : ${order}`}
                    disabled={!hasSort}
                >
                    <StyledSortMenuButton
                        $hasSort={hasSort}
                        icon="Sort"
                        text="Sort"
                        size="xSmall"
                    />
                </Tooltip>
            )}
            renderInSpan
            renderContent={() => (
                <>
                    <DropdownLabel text="Sort by" />
                    <StyledGroup>
                        <StyledSortButton
                            text="Name"
                            onSelect={() => {
                                toggleSort('name')
                            }}
                            closeOnSelect={false}
                            order={by === 'name' ? order : undefined}
                        />

                        <StyledSortButton
                            text="Assigned to"
                            onSelect={() => {
                                toggleSort('assignedTo')
                            }}
                            closeOnSelect={false}
                            order={by === 'assignedTo' ? order : undefined}
                        />

                        <StyledSortButton
                            text="Due date"
                            onSelect={() => {
                                toggleSort('dueDate')
                            }}
                            closeOnSelect={false}
                            order={by === 'dueDate' ? order : undefined}
                        />

                        {extensionFields.map(field => (
                            <StyledSortButton
                                text={field.label}
                                onSelect={() => {
                                    toggleSort(field.label)
                                }}
                                closeOnSelect={false}
                                order={by === field.label ? order : undefined}
                                key={field.id}
                            />
                        ))}
                    </StyledGroup>
                </>
            )}
            align="start"
        />
    )
}

const pipStyles = css`
    position: relative;

    &:after {
        content: '';
        height: 5px;
        width: 5px;
        background-color: ${({ theme }) => theme.palette.support['01'].normal};
        border-radius: 50%;
        top: 0.2rem;
        right: 0.2rem;
        position: absolute;
    }
`

const StyledSortMenuButton = styled(IconTextButton)<{ $hasSort: boolean }>`
    ${({ $hasSort }) => $hasSort && pipStyles};
`

const StyledGroup = styled(Group)`
    width: 15rem;
`

const StyledSortButton = styled(DropdownSortButton)`
    width: 100%;
`
