import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { bold5 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

type Props = {
    value: string
}
const LockedOption: FC<Props> = ({ value }) => (
    <StyledOption>
        <Icon name="Bullet" />
        <Tooltip content="Existing options cannot be modified once a dataset has been created">
            <div>
                <p>{value}</p>
                <Icon name="Lock" />
            </div>
        </Tooltip>
    </StyledOption>
)

const StyledOption = styled.li`
    display: flex;
    align-items: center;
    gap: 1rem;

    > .icon svg {
        --width: 1rem;
        width: var(--width);
        height: var(--width);
        min-width: var(--width);
        min-height: var(--width);
    }

    > .icon svg path {
        fill: ${({ theme }) => theme.palette.ui['05'].normal};
    }

    > div {
        border: 1px dotted ${({ theme }) => theme.palette.support['03'].normal};
        padding: 0.75rem;
        flex: 1;
        border-radius: 0.25rem;
        display: flex;
    }

    p {
        ${bold5}
        flex: 1;
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }

    > div .icon svg {
        --width: 1rem;
        width: var(--width);
        height: var(--width);
        min-width: var(--width);
        min-height: var(--width);
    }

    > div .icon svg path {
        fill: ${({ theme }) => theme.palette.ui['05'].normal};
    }
`

export { LockedOption }
