import { omit } from 'lodash'
import { set } from 'lodash/fp'
import { useCallback, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import {
    documentToggleFieldListOfName,
    fromEvent,
    guardAnd,
    isDocumentToggleField,
    isUpdateListField,
    matchesProp,
    removeAt,
    setAt,
} from 'Utils'
import {
    CreateBusinessObjectDefinitionField,
    FieldConstraintType,
    FieldType,
    PatchBusinessObjectDefinitionFieldDefaultInput,
} from '__generated__'
import {
    Fields,
    isOperation,
    PatchDefaultFieldType,
    PatchOperation,
} from '../types'
import { useBusinessDomain_BusinessObjectDefinitionQuery } from '../__generated__/query'
import { patchDefinition } from './patch-def'

const fieldOperationsByProperty = {
    name: 'updateFieldName',
    description: 'updateFieldDescription',
} as const

const updatesFieldPatch = {
    name: 'Updates',
    description: 'Updates about this object',
    type: FieldType.List as const,
    listOf: {
        name: 'Update',
        type: FieldType.Updates as const,
    },
}

const documentsFieldPatch = {
    name: 'Documents',
    description: 'Documents relating to this object',
    type: FieldType.List as const,
    listOf: {
        name: documentToggleFieldListOfName,
        type: FieldType.Document,
        constraints: [],
    },
}

export const useOperationsState = (id: string) => {
    const { data, loading } = useBusinessDomain_BusinessObjectDefinitionQuery({
        variables: { input: { id } },
        fetchPolicy: 'network-only',
    })

    const def = useMemo(() => data?.businessObjectDefinition, [data])
    const [operations, setOperations] = useState<PatchOperation[]>([])

    const businessObjectDefinition = useMemo(
        () => def && operations.reduce(patchDefinition, def),
        [operations, def]
    )

    const updatesEnabledInitially =
        def?.fields.some(fieldDefinition =>
            isUpdateListField({ fieldDefinition })
        ) ?? true

    const updatesEnabledNow =
        businessObjectDefinition?.fields.some(fieldDefinition =>
            isUpdateListField({ fieldDefinition })
        ) ?? true

    const handleUpdatesEnabledChanged = (isEnabled: boolean) => {
        if (updatesEnabledInitially) return // cannot remove fields - breaking definition change

        if (isEnabled) {
            setOperations(ops => [
                ...ops,
                { operation: 'addField', nextValue: updatesFieldPatch },
            ])
        } else {
            setOperations(ops => {
                const updatesFieldIndex = ops.findIndex(
                    op =>
                        op.operation === 'addField' &&
                        op.nextValue.type === FieldType.List &&
                        op.nextValue.listOf.type === FieldType.Updates
                )

                if (updatesFieldIndex >= 0) {
                    return removeAt(ops, updatesFieldIndex)
                }
                return ops
            })
        }
    }

    const documentsEnabledInitially =
        def?.fields.some(fieldDefinition =>
            isDocumentToggleField({ fieldDefinition })
        ) ?? true

    const documentsEnabledNow =
        businessObjectDefinition?.fields.some(fieldDefinition =>
            isDocumentToggleField({ fieldDefinition })
        ) ?? true

    const handleDocumentsEnabledChanged = (isEnabled: boolean) => {
        if (documentsEnabledInitially) return // cannot remove fields - breaking definition change

        if (isEnabled) {
            setOperations(ops => [
                ...ops,
                { operation: 'addField', nextValue: documentsFieldPatch },
            ])
        } else {
            setOperations(ops => {
                const documentsFieldIndex = ops.findIndex(
                    op =>
                        op.operation === 'addField' &&
                        op.nextValue.type === FieldType.List &&
                        op.nextValue.listOf.type === FieldType.Document &&
                        op.nextValue.listOf.name ===
                            documentToggleFieldListOfName
                )

                if (documentsFieldIndex >= 0) {
                    return removeAt(ops, documentsFieldIndex)
                }
                return ops
            })
        }
    }

    const handleTopLevelPropertyChanged =
        (
            operation: 'updateName' | 'updateDescription' | 'updateLabel',
            property: 'name' | 'label' | 'description'
        ) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            const nextValue = fromEvent(e)
            setOperations(ops => {
                const existing = ops.find(isOperation(operation))
                if (existing) {
                    // If modified back to where we started, just remove the patch entirely
                    if (existing.previousValue === nextValue) {
                        return removeAt(ops, ops.indexOf(existing))
                    }
                    return setAt(
                        ops,
                        ops.indexOf(existing),
                        'nextValue',
                        nextValue
                    )
                }
                return [
                    ...ops,
                    {
                        operation,
                        previousValue: (businessObjectDefinition?.[property] ??
                            null) as string,
                        nextValue,
                    },
                ]
            })
        }

    const handleFieldChanged =
        (fieldId: string) => (property: 'name' | 'description') => {
            const operation = fieldOperationsByProperty[property]
            return (e: React.ChangeEvent<HTMLInputElement>) => {
                e.preventDefault()
                const nextValue = fromEvent(e)
                setOperations(ops => {
                    const existing = ops.find(
                        guardAnd(
                            isOperation(operation),
                            matchesProp('fieldId')(fieldId)
                        )
                    )

                    if (existing) {
                        // If modified back to where we started, just remove the patch entirely
                        if (existing.previousValue === nextValue) {
                            return removeAt(ops, ops.indexOf(existing))
                        }
                        return setAt(
                            ops,
                            ops.indexOf(existing),
                            'nextValue',
                            nextValue
                        )
                    }
                    return [
                        ...ops,
                        {
                            operation,
                            fieldId,
                            previousValue:
                                (businessObjectDefinition?.fields.find(
                                    f => f.id === fieldId
                                )?.[property] ?? null) as string,
                            nextValue,
                        },
                    ]
                })
            }
        }

    const handleConstraintRemoved =
        (fieldId: string) => (constraintType: FieldConstraintType) => () => {
            setOperations(ops => {
                const existingEdit = ops.find(
                    guardAnd(
                        guardAnd(
                            isOperation('removeFieldConstraint'),
                            matchesProp('fieldId')(fieldId)
                        ),
                        matchesProp('constraintType')(constraintType)
                    )
                )
                if (existingEdit) {
                    return ops
                }
                return [
                    ...ops,
                    {
                        operation: 'removeFieldConstraint',
                        fieldId,
                        constraintType,
                    },
                ]
            })
        }

    const handleFieldCreated = (field: CreateBusinessObjectDefinitionField) => {
        setOperations(
            ops =>
                [
                    ...ops,
                    {
                        operation: 'addField',
                        nextValue: field,
                    },
                ] as PatchOperation[]
        )
    }

    const handleUndoOperation = (index: number) => () => {
        setOperations(ops => removeAt(ops, index))
    }

    const handleSelectOptionAdded =
        (fieldId: string) => (nextValue: string) => () =>
            setOperations(ops => {
                const field = businessObjectDefinition?.fields.find(
                    f => f.id === fieldId
                )

                if (!field || field.__typename !== 'SelectFieldDefinition') {
                    return ops
                }

                return [
                    ...ops,
                    {
                        operation: 'addSelectFieldOption',
                        fieldId,
                        previousValue: field.selectOptions.map(({ id }) => id),
                        nextValue,
                    },
                ]
            })

    const handleDefaultChanged = useCallback(
        <T extends PatchDefaultFieldType>(fieldType: T) =>
            ({
                fieldId,
                nextValue,
            }: Pick<
                NonNullable<PatchBusinessObjectDefinitionFieldDefaultInput[T]>,
                'fieldId' | 'nextValue'
            >) => {
                setOperations(operations => {
                    const existing = getExisitingDefaultOperation(
                        operations,
                        fieldType,
                        fieldId
                    )

                    if (!existing) {
                        const field = businessObjectDefinition?.fields.find(
                            f => f.id === fieldId
                        )!

                        const previousValue = getPreviousDefaultValue(field)

                        return [
                            ...operations,
                            {
                                operation: 'updateFieldDefault',
                                [fieldType]: {
                                    fieldId,
                                    nextValue,
                                    previousValue,
                                },
                            },
                        ]
                    }

                    return setAt(
                        operations,
                        operations.indexOf(existing),
                        fieldType,
                        set('nextValue', nextValue)
                    )
                })
            },
        [businessObjectDefinition]
    )

    const handleFieldRemoved = (fieldId: string) => () => {
        setOperations(ops => [
            ...ops,
            {
                operation: 'removeField',
                fieldId,
            },
        ])
    }

    return {
        loading,
        businessObjectDefinition,
        operations,
        setOperations,
        handleTopLevelPropertyChanged,
        handleConstraintRemoved,
        handleFieldChanged,
        handleFieldCreated,
        handleSelectOptionAdded,
        handleUndoOperation,
        updatesEnabledInitially,
        updatesEnabledNow,
        handleUpdatesEnabledChanged,
        documentsEnabledInitially,
        documentsEnabledNow,
        handleDocumentsEnabledChanged,
        handleDefaultChanged,
        handleFieldRemoved,
        unpatchedDefinition: def,
    }
}

const getExisitingDefaultOperation = (
    operations: PatchOperation[],
    fieldType: PatchDefaultFieldType,
    fieldId: string
) =>
    operations.find(op =>
        !isOperation('updateFieldDefault')(op) ||
        op[fieldType]?.fieldId !== fieldId
            ? false
            : true
    )

const getPreviousDefaultValue = (field: Fields[number]) => {
    return match(field)
        .with(
            { __typename: 'BooleanFieldDefinition' },
            ({ booleanDefaultValue }) => booleanDefaultValue
        )
        .with(
            { __typename: 'TextFieldDefinition' },
            ({ textDefaultValue }) => textDefaultValue
        )
        .with(
            { __typename: 'CurrencyFieldDefinition' },
            ({ currencyDefaultValue }) =>
                omit(currencyDefaultValue, '__typename')
        )
        .with(
            { __typename: 'DateFieldDefinition' },
            ({ dateDefaultValue }) => dateDefaultValue
        )
        .with(
            { __typename: 'EmailFieldDefinition' },
            ({ emailDefaultValue }) => emailDefaultValue
        )
        .with(
            { __typename: 'NumberFieldDefinition' },
            ({ numDefaultValue }) => numDefaultValue
        )
        .with(
            { __typename: 'TelephoneFieldDefinition' },
            ({ telephoneDefaultValue }) =>
                omit(telephoneDefaultValue, '__typename')
        )
        .with(
            { __typename: 'URLFieldDefinition' },
            ({ urlDefaultValue }) => urlDefaultValue
        )
        .with(
            { __typename: 'UserFieldDefinition' },
            ({ userDefaultValue }) => userDefaultValue?.id ?? undefined
        )
        .otherwise(() => undefined)
}
