import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CenteredSpinner } from 'Components/Spinner'
import { ProcessCard } from 'Features/ProcessCard/ProcessCard'
import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { FC } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { VisualiseProcesses } from '../VisualiseProcesses'
import { Creator } from './Creator'
import { NoDefinitions } from './NoDefinitions'
import { SingleProcess } from './SingleProcess'
import { useModelManagerProcessesQuery } from './__generated__/q'

const Processes: FC = () => {
    const navigate = useNavigate()
    const { data, loading, refetch } = useModelManagerProcessesQuery()

    const [params] = useSearchParams()

    const openCreator = params.get('creator') === 'open'
    const step =
        (params.get('step') as
            | 'start'
            | 'details'
            | 'phases'
            | 'ai-1'
            | 'ai-2') ?? undefined
    const preselectedBO = params.get('preselectedBO') ?? undefined

    return match((!loading || undefined) && data)
        .with(undefined, () => (
            <section>
                <CenteredSpinner />
            </section>
        ))
        .otherwise(({ processes }) => (
            <Routes>
                <Route
                    index
                    element={
                        <Styled>
                            <header>
                                <Breadcrumb area="processes" />
                                <menu>
                                    <IconTextButton
                                        icon="Hub"
                                        size="xSmall"
                                        text="Visualise workflows"
                                        variant="secondary"
                                        onClick={() => navigate('./visualise')}
                                    />
                                    <Creator
                                        initiallyOpen={openCreator}
                                        buttonSize="xSmall"
                                        initialStep={step}
                                        preselectedBOId={preselectedBO}
                                    />
                                </menu>
                            </header>
                            {processes.length ? (
                                <StyledCards>
                                    <ul>
                                        {processes.map(process => (
                                            <li key={process.id}>
                                                <ProcessCard
                                                    process={process}
                                                    onClick={() =>
                                                        navigate(
                                                            slugify(
                                                                process.name,
                                                                {
                                                                    lower: true,
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </StyledCards>
                            ) : (
                                <NoDefinitions />
                            )}
                        </Styled>
                    }
                />
                <Route
                    path="visualise"
                    element={
                        <SmallScreenWarning element={<VisualiseProcesses />} />
                    }
                />
                {processes.map(({ id, name }, i) => (
                    <Route
                        key={id}
                        path={slugify(name, { lower: true })}
                        element={
                            <SingleProcess
                                onProcessDeleted={refetch}
                                id={id}
                                navOptions={{
                                    selected: i,
                                    options: processes.map(p => ({
                                        path: slugify(p.name, { lower: true }),
                                        text: p.name,
                                    })),
                                }}
                            />
                        }
                    />
                ))}
            </Routes>
        ))
}

const Styled = styled.section`
    height: 100%;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }
`

const StyledCards = styled.div`
    > ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;
        padding: 2rem;
    }
`

export { Processes }
