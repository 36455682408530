import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { InviteMember } from 'Features/InviteMember'
import { useResponder } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { useAccountSendInviteQuery } from './__generated__/query'

const SendInvite: FC = () => {
    const { data, refetch } = useAccountSendInviteQuery()
    const { screenSize } = useResponder()
    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)
    return match(screenSize)
        .with('large', () => (
            <>
                <IconTextButton
                    icon="Invite"
                    text="Invite a member to this workspace"
                    onClick={() => data && setOpen(true)}
                    ref={ref}
                />
                <ControlledModal
                    title="Invite a member"
                    description="Enter an email and we'll send an invitation to their inbox."
                    renderContent={() =>
                        data && (
                            <InviteMember
                                onCompleted={() => {
                                    refetch()
                                    setOpen(false)
                                }}
                                users={data.users}
                                currentUser={data.currentUser}
                            />
                        )
                    }
                    open={open}
                    onOpenChange={setOpen}
                    returnFocus={() => ref.current?.focus()}
                />
            </>
        ))
        .otherwise(() => (
            <>
                <IconTextButton
                    icon="Invite"
                    text="Invite a member to this workspace"
                    onClick={() => data && setOpen(true)}
                    ref={ref}
                />
                <MinimalModal open={open} onOpenChange={setOpen}>
                    <StyledSmallContent>
                        {data && (
                            <InviteMember
                                onCompleted={() => {
                                    refetch()
                                    setOpen(false)
                                }}
                                users={data.users}
                                currentUser={data.currentUser}
                            />
                        )}
                        <footer>
                            <IconTextButton
                                icon="Close"
                                text="Cancel"
                                onClick={() => setOpen(false)}
                                variant="secondary"
                            />
                        </footer>
                    </StyledSmallContent>
                </MinimalModal>
            </>
        ))
}

const StyledSmallContent = styled.div`
    padding: 0.5rem 4vw 1rem;

    > footer {
        padding-top: 1rem;
    }

    > footer button {
        width: 100%;
    }
`

export { SendInvite }
