import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { first } from 'lodash'
import { FC } from 'react'
import { CreateActionModalContent } from './CreateActionModalContent'
import { useActionExtensionObjects_CompleteQuery } from './__generated__/query'
import { RelatesTo } from './types'

type Props = {
    closeModal: () => void
    relatesTo?: RelatesTo
}

export const CreateActionModalData: FC<Props> = ({ closeModal, relatesTo }) => {
    const { data, loading } = useActionExtensionObjects_CompleteQuery()

    const bodExtension = data
        ? first(data.businessObjectDefinitionExtensions)
        : undefined

    if (loading || !data) {
        return (
            <StyledCreateActionData>
                <CenteredSpinner />
            </StyledCreateActionData>
        )
    }

    return (
        <CreateActionModalContent
            closeModal={closeModal}
            relatesTo={relatesTo}
            bodExtension={bodExtension}
            currentUserId={data?.currentUser.id}
            userGroups={data?.userGroups}
        />
    )
}

const StyledCreateActionData = styled.div`
    ${flex('column', 'center', 'center')};
    width: 100vw;
    max-width: 40rem;
`
