import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Spinner } from 'Components/Spinner'
import { ContactUsToast, SimpleToast } from 'Components/Toast'
import { FC, useCallback, useState } from 'react'
import { useDeleteView } from '../useDeleteView'

type Props = {
    id: string
}

const DeleteView: FC<Props> = ({ id }) => {
    const { deleteView, showSuccess, showError, setShowError, setShowSuccess } =
        useDeleteView(id)
    const [deleting, setDeleting] = useState(false)

    const handleDelete = useCallback(async () => {
        setDeleting(true)
        await deleteView()
        setDeleting(false)
    }, [deleteView])

    return (
        <>
            {deleting ? (
                <StyledSpinner>
                    <Spinner />
                </StyledSpinner>
            ) : (
                <IconButton
                    iconName="Delete"
                    onClick={handleDelete}
                    variant="secondary"
                />
            )}
            <SimpleToast
                open={showSuccess}
                onOpenChange={setShowSuccess}
                description="View deleted"
            />
            <ContactUsToast
                open={showError}
                onOpenChange={setShowError}
                description="Unable to delete the view. Contact us if the problem persists."
            />
        </>
    )
}

const StyledSpinner = styled.div`
    padding: 0.5rem;
    .spinner {
        display: block;
        height: 1rem;
        width: 1rem;
    }
`

export { DeleteView }
