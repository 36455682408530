import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

type Props = {
    val: string
    type: 'link' | 'email' | 'tel'
}

const ExternalLink: FC<Props> = ({ val, type }) => (
    <Styled
        target="_blank"
        rel="noreferrer"
        href={`${{ link: '', email: 'mailto:', tel: 'tel:' }[type]}${val}`}
        onClick={e => e.stopPropagation()}
    >
        {val}
    </Styled>
)

const Styled = styled.a`
    transition: color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease,
        background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    background-color: ${({ theme }) => theme.palette.ui['02'].hover}00;
    border-radius: 0.125rem;

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
        background-color: ${({ theme }) => theme.palette.ui['02'].hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support03.active};
        background-color: ${({ theme }) => theme.palette.ui['02'].active};
    }
`

export { ExternalLink }
