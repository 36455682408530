import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { FC } from 'react'
import { useAddBusinessObjectToProcessFromOverviewMutation } from './__generated__/AddBusinessObjectToProcessFromOverview'

type Props = {
    businessObjectId: string
    processId: string
}

export const AddBusinessObjectToProcess: FC<Props> = ({
    businessObjectId,
    processId,
}) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const [addBusinessObjectToProcess, { loading }] =
        useAddBusinessObjectToProcessFromOverviewMutation({
            variables: {
                input: { businessObjectIds: [businessObjectId], processId },
            },
            onError(error) {
                logger.error(
                    'Failed to add business object to process from process overview tab',
                    error,
                    { businessObjectId, processId }
                )
                triggerToast()
            },
            refetchQueries: ['OnboardingProgress'],
        })

    return (
        <StyledAddBusinessObjectToProcess>
            <Text as="h2" variant="bold-4">
                Record not in workflow
            </Text>

            <Text as="p" variant="regular-5">
                This record has not yet been added to the workflow. Click the
                button below to move it to the first phase.
            </Text>

            <IconTextButton
                text={'Add record to workflow'}
                onClick={() => {
                    addBusinessObjectToProcess()
                }}
                disabled={loading}
                size="small"
                icon="ArrowRight"
                variant="primary"
            />

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description={
                    'We were not able to add this record to the workflow. Contact us if the problem persists.'
                }
            />
        </StyledAddBusinessObjectToProcess>
    )
}

const StyledAddBusinessObjectToProcess = styled.section`
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 1.5rem;
    position: relative;

    h2 {
        padding-bottom: 0.25rem;
    }

    button {
        margin-top: 1.5rem;
        width: 100%;
    }
`
