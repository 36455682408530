import * as Popover from '@radix-ui/react-popover'
import {
    comboboxActions,
    ComboboxContentItemProps,
    ComboboxContentProps,
    ComboboxProps,
    Data,
    NoData,
    TComboboxItem,
    useActiveComboboxStore,
    useComboboxContent,
    useComboboxContentState,
    useComboboxControls,
    useComboboxItem,
    useComboboxSelectors,
} from '@udecode/plate-combobox'
import { useEditorState, useEventEditorSelectors } from '@udecode/plate-common'
import { createVirtualRef } from '@udecode/plate-floating'
import { flex, styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { useEffect } from 'react'
import { getPortalsContainer } from 'Utils'

export function ComboboxItem<TData extends Data = NoData>({
    combobox,
    index,
    item,
    onRenderItem,
}: ComboboxContentItemProps<TData>) {
    const { props } = useComboboxItem({ item, index, combobox, onRenderItem })

    const editor = useEditorState()

    const activeComboboxStore = useActiveComboboxStore()!

    return (
        <StyledComboboxItem
            {...props}
            onMouseDown={() => {
                activeComboboxStore.store
                    .getState()
                    .onSelectItem?.(editor as any, item)
            }}
        />
    )
}

const StyledComboboxItem = styled.button`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};
    position: relative;
    padding: 0.5rem 0.375rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    outline-style: none;
    transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    user-select: none;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    width: 100%;

    &[data-highlighted='true'] {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }
`

export function ComboboxContent<TData extends Data = NoData>(
    props: ComboboxContentProps<TData>
) {
    const { component: Component, items, combobox, onRenderItem } = props

    const editor = useEditorState()

    const filteredItems =
        useComboboxSelectors.filteredItems() as TComboboxItem<TData>[]
    const activeComboboxStore = useActiveComboboxStore()!

    const state = useComboboxContentState({ items, combobox })
    const { menuProps, targetRange } = useComboboxContent(state)

    return (
        <Popover.Root open>
            <Popover.PopoverAnchor
                virtualRef={createVirtualRef(editor, targetRange ?? undefined)}
            />

            <Popover.Portal container={getPortalsContainer()}>
                <StyledPopoverContent
                    {...menuProps}
                    sideOffset={5}
                    side="bottom"
                    align="start"
                    onOpenAutoFocus={event => event.preventDefault()}
                >
                    {Component
                        ? Component({ store: activeComboboxStore })
                        : null}

                    {filteredItems.map((item, index) => (
                        <ComboboxItem
                            key={item.key}
                            item={item}
                            combobox={combobox}
                            index={index}
                            onRenderItem={onRenderItem}
                        />
                    ))}
                </StyledPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const StyledPopoverContent = styled(Popover.Content)`
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: 0.25rem;
    width: 17rem;
    max-height: 18rem;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    overflow: auto;
`

/**
 * Register the combobox id, trigger, onSelectItem
 * Renders the combobox if active.
 */
export function Combobox<TData extends Data = NoData>({
    id,
    trigger,
    searchPattern,
    onSelectItem,
    controlled,
    maxSuggestions,
    filter,
    sort,
    disabled: _disabled,
    ...props
}: ComboboxProps<TData>) {
    const storeItems = useComboboxSelectors.items()
    const disabled =
        _disabled ?? (storeItems.length === 0 && !props.items?.length)

    const focusedEditorId = useEventEditorSelectors.focus?.()
    const combobox = useComboboxControls()
    const activeId = useComboboxSelectors.activeId()
    const editor = useEditorState()

    useEffect(() => {
        comboboxActions.setComboboxById({
            id,
            trigger,
            searchPattern,
            controlled,
            onSelectItem,
            maxSuggestions,
            filter,
            sort,
        })
    }, [
        id,
        trigger,
        searchPattern,
        controlled,
        onSelectItem,
        maxSuggestions,
        filter,
        sort,
    ])

    if (
        !combobox ||
        !editor.selection ||
        focusedEditorId !== editor.id ||
        activeId !== id ||
        disabled
    ) {
        return null
    }

    return <ComboboxContent combobox={combobox} {...props} />
}
