import { FieldType, ValueSelectorType } from '__generated__'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { byTypename } from '../../Utils'
import { useFilterableFieldsQuery } from './__generated__/query'

const yes = () => true
const no = () => false

export const canFilterFieldType = (fieldType: FieldType) =>
    match(fieldType)
        .with(FieldType.Boolean, yes)
        .with(FieldType.Select, yes)
        .with(FieldType.Number, yes)
        .with(FieldType.Date, yes)
        .with(FieldType.User, yes)
        .with(FieldType.Relation, yes)
        .with(FieldType.Currency, yes)
        .with(FieldType.Text, yes)
        .with(FieldType.Email, yes)
        .with(FieldType.Url, yes)
        .otherwise(no)

export type FilterableField = Exclude<
    Exclude<
        ReturnType<typeof useFilterableFieldsQuery>['data'],
        undefined
    >['businessObjectDefinition'],
    null | undefined
>['fields'][number]

export type DeepFilterableField = {
    parentField: FilterableField
    fields: FilterableField[]
}

type FilterableFields = {
    loading: boolean
    availableFields: FilterableField[] | undefined
    availableDeepFields: DeepFilterableField[] | undefined
}

export const useFilterableFields = ({
    businessObjectDefinitionId,
    onError,
    allowedFilterTypes,
}: {
    businessObjectDefinitionId: string
    onError?: () => void
    allowedFilterTypes: ValueSelectorType[]
}): FilterableFields => {
    const { data, loading } = useFilterableFieldsQuery({
        variables: { input: { id: businessObjectDefinitionId } },
        onError,
    })

    const availableFields = useMemo(
        () =>
            allowedFilterTypes.includes(ValueSelectorType.FieldValue)
                ? data?.businessObjectDefinition?.fields.filter(field =>
                      canFilterFieldType(field.type)
                  )
                : [],
        [data, allowedFilterTypes]
    )

    const availableDeepFields = useMemo(
        () =>
            allowedFilterTypes.includes(ValueSelectorType.Deep)
                ? (data?.businessObjectDefinition?.fields
                      .filter(byTypename('RelationFieldDefinition' as const))
                      .map(field => ({
                          parentField: field,
                          fields: field.constraints
                              .filter(
                                  byTypename('RelationTypeConstraint' as const)
                              )
                              .flatMap(constraint =>
                                  constraint.types
                                      .flatMap(type => type.fields)
                                      .filter(subfield =>
                                          canFilterFieldType(subfield.type)
                                      )
                                      .map(subfield => ({
                                          ...subfield,
                                          id: `${field.id}___${subfield.id}`,
                                      }))
                              ),
                      }))
                      .filter(
                          ({ fields }) => fields.length > 0
                      ) as DeepFilterableField[])
                : [],
        [data, allowedFilterTypes]
    )

    return {
        loading,
        availableFields,
        availableDeepFields,
    }
}
