import { TextInput } from 'Components/Input'
import { fromEvent } from 'Utils'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { Select } from '../../../../../../../Components/Select'
import { useAllProcessesQuery } from '../__generated__/q'
import { TemplateableField } from '../components/TemplateableField'
import { AddBusinessObjectToProcessPayload } from '../types'
import { StepOutline } from './StepOutline'
import { StepProps } from './types'

export const AddBusinessObjectToProcess: FC<
    StepProps<AddBusinessObjectToProcessPayload>
> = ({
    onFieldChanged,
    payload: { businessObjectId, processId },
    ...outlineProps
}) => {
    const { data } = useAllProcessesQuery()

    const processes = data?.processes ?? []

    return (
        <StepOutline {...outlineProps} title="Add Record to Workflow">
            <label>
                Record ID
                <TextInput
                    type="text"
                    value={businessObjectId}
                    onChange={pipe(
                        fromEvent,
                        onFieldChanged('businessObjectId')
                    )}
                />
            </label>
            <label>
                Workflow
                <TemplateableField
                    value={processId}
                    onChange={onFieldChanged('processId')}
                    native={() => (
                        <Select
                            id={`${outlineProps.step}-processId`}
                            name={`${outlineProps.step}-processId`}
                            onValueChange={onFieldChanged('processId')}
                            value={processId}
                            options={processes.map(({ id, name }) => ({
                                value: id,
                                text: name,
                            }))}
                        />
                    )}
                />
            </label>
        </StepOutline>
    )
}
