import { css, flex, styled } from 'Adapters/Freestyled'
import { bold4, bold5, bold6 } from 'Components/Text'
import { match } from 'ts-pattern'
import { Size, Variant } from './types'

export const StyledIconTextButton = styled.button<{
    $selected: boolean
    $variant: Variant
    $size: Size
    $iconRight: boolean
}>`
    ${({ $selected, $variant }) =>
        applyButtonVariantStyles($variant, $selected)};
    ${({ $size }) => applyButtonSizeStyles($size)};
    position: relative;
    gap: 0.386rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: none;
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};
    ${({ $iconRight }) =>
        $iconRight
            ? flex('row-reverse', 'center', 'center')
            : flex('row', 'center', 'center')}

    > span {
        display: block;
        white-space: nowrap;
    }

    .icon {
        > svg {
            min-height: 1rem;
            min-width: 1rem;
            max-height: 1rem;
            max-width: 1rem;
        }
    }
`

const applyButtonSizeStyles = (size: Size) => {
    return match(size)
        .with(
            'xSmall',
            () => css`
                ${bold6};
                padding: 0.5rem;
            `
        )
        .with(
            'small',
            () => css`
                ${bold5};
                padding: 0.75rem 1rem;
            `
        )
        .with(
            'medium',
            () => css`
                ${bold4}
                padding: 1rem 1.5rem;
            `
        )
        .exhaustive()
}

const applyButtonVariantStyles = (variant: Variant, $selected: boolean) => {
    return match(variant)
        .with(
            'ghost',
            () => css`
                color: ${({ theme }) => theme.palette.text['01'].normal};
                background-color: ${({ theme }) =>
                    $selected
                        ? theme.palette.ui.tint['03'].active
                        : 'transparent'};

                .icon svg path {
                    fill: ${({ theme }) => theme.palette.icon['02'].normal};
                }

                /* HOVER */
                @media (hover: hover) {
                    &:hover {
                        background-color: ${({ theme }) =>
                            $selected
                                ? theme.palette.ui.tint['04'].hover
                                : theme.palette.ui.tint['03'].hover};
                    }
                }

                /* ACTIVE */
                &:active {
                    background-color: ${({ theme }) =>
                        $selected
                            ? theme.palette.ui.tint['04'].hover
                            : theme.palette.ui.tint['03'].active};
                }

                /* FOCUS */
                &:focus-visible {
                    background-color: ${({ theme }) =>
                        $selected
                            ? theme.palette.ui.tint['04'].active
                            : theme.palette.ui.tint['03'].active};
                }

                /* DISABLED */
                &:disabled {
                    background-color: ${({ theme }) =>
                        $selected
                            ? theme.palette.ui.tint['04'].active
                            : theme.palette.ui['01'].normal};
                    color: ${({ theme }) =>
                        $selected
                            ? theme.palette.text['03'].active
                            : theme.palette.text['03'].normal};

                    .icon path {
                        fill: ${({ theme }) =>
                            $selected
                                ? theme.palette.text['03'].active
                                : theme.palette.text['03'].normal};
                    }
                }
            `
        )
        .with(
            'primary',
            () => css`
                color: ${({ theme }) => theme.palette.text['04'].normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui['07'].normal};

                .icon path {
                    fill: ${({ theme }) =>
                        $selected
                            ? theme.palette.icon['04'].active
                            : theme.palette.icon['04'].normal};
                }

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) => theme.palette.text['04'].hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui['07'].hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text['04'].active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['07'].active};
                }

                &:focus-visible {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['07'].hover};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};

                    .icon path {
                        fill: ${({ theme }) =>
                            $selected
                                ? theme.palette.icon['03'].active
                                : theme.palette.icon['03'].normal};
                    }
                }
            `
        )
        .with(
            'secondary',
            () => css`
                color: ${({ theme }) => theme.palette.text['02'].normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].normal};

                .icon path {
                    fill: ${({ theme }) =>
                        $selected
                            ? theme.palette.icon['02'].active
                            : theme.palette.icon['02'].normal};
                }

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) => theme.palette.text['02'].hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui['02'].hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text['02'].active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].active};
                }

                &:focus-visible {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].hover};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].normal};

                    .icon path {
                        fill: ${({ theme }) =>
                            $selected
                                ? theme.palette.icon['03'].active
                                : theme.palette.icon['03'].normal};
                    }
                }
            `
        )
        .exhaustive()
}
