import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { fromEvent } from 'Utils'
import {
    TimeUnit,
    convertToSeconds,
    convertToTimeUnit,
} from 'Utils/timeConversions'
import { pipe } from 'lodash/fp'
import { FC, useMemo, useState } from 'react'
import { useProcessCreator } from '../../useProcessCreator'

type Props = {
    phaseIndex: number
    criterionIndex: number
}

export const DefaultDueTime: FC<Props> = ({ phaseIndex, criterionIndex }) => {
    /**
     * The current value in seconds is stored in workflow creator state, and converted for display in the current unit.
     * Unit is stored in local state, as it is not relevant outside of this component.
     */

    const {
        dispatch,
        state: {
            input: { phases },
        },
    } = useProcessCreator()

    const [unit, setUnit] = useState('hour' as TimeUnit)

    const numberInCurrentUnit = useMemo(() => {
        const seconds =
            phases[phaseIndex]?.criteria[criterionIndex].action
                ?.defaultDueSeconds ?? 0

        return convertToTimeUnit(seconds, unit)
    }, [phases, phaseIndex, criterionIndex, unit])

    const handleNumberInputChanged = (newNumber: number) => {
        dispatch({
            type: 'setCriterionDueTime',
            payload: {
                defaultDueSeconds: convertToSeconds(newNumber, unit),
                criterionIndex,
                phaseIndex,
            },
        })
    }

    const handleUnitChanged = (newUnit: TimeUnit) => {
        setUnit(newUnit)
        dispatch({
            type: 'setCriterionDueTime',
            payload: {
                defaultDueSeconds: convertToSeconds(
                    numberInCurrentUnit,
                    newUnit
                ),
                criterionIndex,
                phaseIndex,
            },
        })
    }

    return (
        <StyledDefaultDueTime>
            <Text as="legend" variant="regular-6">
                Default due time:
            </Text>

            <NumberInput
                className="number-input"
                value={numberInCurrentUnit}
                onChange={pipe(fromEvent, Number, handleNumberInputChanged)}
            />

            <Select
                id={'timeUnit'}
                onValueChange={handleUnitChanged}
                name={'timeUnit'}
                options={[
                    { text: 'hour(s)', value: 'hour' },
                    { text: 'day(s)', value: 'day' },
                    { text: 'week(s)', value: 'week' },
                ]}
                value={unit}
            />
        </StyledDefaultDueTime>
    )
}

const StyledDefaultDueTime = styled.fieldset`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
    border: none;
    padding: 0 0.5rem 0.5rem;

    legend {
        padding-bottom: 0.25rem;
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    .number-input {
        min-height: 38px;
        width: 50%;
    }

    .select-trigger {
        max-height: 42px;
    }
`
