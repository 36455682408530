import * as amplitude from '@amplitude/analytics-browser'
import { useFlow } from '@frigade/react'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { ChecklistContent } from './ChecklistContent'
import { CHECKLIST_INTERACTED_WITH, FLOW_IDENTIFIER } from './constants'
import { useMarkProgress } from './useMarkProgress'

export const OnboardingChecklistDesktop = () => {
    const { flow } = useFlow(FLOW_IDENTIFIER)

    const stepsCompleted = flow?.getNumberOfCompletedSteps() ?? 0
    const totalSteps = flow?.getNumberOfAvailableSteps() ?? 0
    const percentComplete = Math.round((stepsCompleted / totalSteps) * 100)

    useMarkProgress()

    const { buttonRef, open, openModal, returnFocus, setOpen, closeModal } =
        useModalControls()

    if (!flow || percentComplete === 100) return null

    return (
        <>
            <StyledButton
                ref={buttonRef}
                onClick={() => {
                    amplitude.track(CHECKLIST_INTERACTED_WITH)
                    openModal()
                }}
                className={open ? 'active' : undefined}
            >
                <Icon name={'Rocket'} title={'Onboarding'} />
                <Text as="p" variant="regular-5">
                    Onboarding
                </Text>
                <Text as="p" variant="regular-5" className="percent-complete">
                    {percentComplete}%
                </Text>
            </StyledButton>

            <ControlledModal
                renderContent={() => (
                    <ChecklistContent
                        done={stepsCompleted}
                        size={totalSteps}
                        closeModal={closeModal}
                    />
                )}
                title={flow.title ?? 'Onboarding'}
                description={
                    flow.subtitle ??
                    'Complete the steps below to get up and running in Modlify quickly'
                }
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledButton = styled.button`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['02'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['02'].active};
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        transition: color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &.active > p {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    svg {
        height: 1rem;
        width: 1rem;

        path,
        circle {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
            transition: fill
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
        }
    }

    &.active svg path {
        fill: ${({ theme }) => theme.palette.icon['01'].normal};
    }

    .percent-complete {
        margin-left: auto;
    }
`
