import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActionCountQuery = { __typename?: 'Query', actionCount: number, currentUser: { __typename?: 'User', id: string } };

export type GetActionViewsQueryVariables = Types.Exact<{
  input: Types.ViewsInput;
}>;


export type GetActionViewsQuery = { __typename?: 'Query', views: Array<{ __typename?: 'View', id: string, name: string, configuration: string }> };


export const ActionCountDocument = gql`
    query ActionCount {
  actionCount
  currentUser {
    id
  }
}
    `;

/**
 * __useActionCountQuery__
 *
 * To run a query within a React component, call `useActionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionCountQuery(baseOptions?: Apollo.QueryHookOptions<ActionCountQuery, ActionCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionCountQuery, ActionCountQueryVariables>(ActionCountDocument, options);
      }
export function useActionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionCountQuery, ActionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionCountQuery, ActionCountQueryVariables>(ActionCountDocument, options);
        }
export type ActionCountQueryHookResult = ReturnType<typeof useActionCountQuery>;
export type ActionCountLazyQueryHookResult = ReturnType<typeof useActionCountLazyQuery>;
export type ActionCountQueryResult = Apollo.QueryResult<ActionCountQuery, ActionCountQueryVariables>;
export const GetActionViewsDocument = gql`
    query GetActionViews($input: ViewsInput!) {
  views(input: $input) {
    id
    name
    configuration
  }
}
    `;

/**
 * __useGetActionViewsQuery__
 *
 * To run a query within a React component, call `useGetActionViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionViewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActionViewsQuery(baseOptions: Apollo.QueryHookOptions<GetActionViewsQuery, GetActionViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionViewsQuery, GetActionViewsQueryVariables>(GetActionViewsDocument, options);
      }
export function useGetActionViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionViewsQuery, GetActionViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionViewsQuery, GetActionViewsQueryVariables>(GetActionViewsDocument, options);
        }
export type GetActionViewsQueryHookResult = ReturnType<typeof useGetActionViewsQuery>;
export type GetActionViewsLazyQueryHookResult = ReturnType<typeof useGetActionViewsLazyQuery>;
export type GetActionViewsQueryResult = Apollo.QueryResult<GetActionViewsQuery, GetActionViewsQueryVariables>;