import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Toggle } from 'Components/Input'
import { Text } from 'Components/Text'
import { SelectFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { v4 } from 'uuid'
import { NumberOfSelectionsConstraint } from '../Constraint/NumberOfSelections'
import { InputWrapper } from '../InputWrapper'
import { LockedOption } from './LockedOption'
import { Option } from './Option'

type Props<T extends SelectFieldUnique> = {
    constraints: T['constraints']
    options: T['options']
    update: (fn: (prev: T) => T) => void
    constraintsLocked: boolean
}
type Acc<T extends SelectFieldUnique> = {
    locked: T['options']
    unlocked: T['options']
}
const SelectSubFields = <T extends SelectFieldUnique>({
    constraints,
    options,
    update,
    constraintsLocked,
}: Props<T>): ReturnType<FC<Props<T>>> => {
    const [multi, setMulti] = useState(false)
    const { current: multiToggleFieldId } = useRef(v4())
    const { locked, unlocked } = useMemo(
        () =>
            options.reduce(
                (acc: Acc<T>, o) =>
                    o.locked
                        ? { ...acc, locked: [...acc.locked, o] }
                        : { ...acc, unlocked: [...acc.unlocked, o] },
                { locked: [], unlocked: [] }
            ),
        [options]
    )

    useEffect(() => {
        const c = constraints.numberOfSelections
        setMulti(c.min !== 1 || c.max !== 1)
    }, [constraints])

    return (
        <>
            <InputWrapper
                fieldset
                label="Options"
                description="Enter the options to be selected from"
            >
                <StyledOptions>
                    <ul>
                        {locked.map(({ value }) => (
                            <LockedOption key={value} value={value} />
                        ))}
                        {unlocked.map(({ value }, i, { length }) => (
                            <Option
                                key={i}
                                value={value}
                                index={i + locked.length}
                                isFirst={i === 0}
                                isLast={i === length - 1}
                                update={update}
                            />
                        ))}
                    </ul>
                    <footer>
                        <IconTextButton
                            icon="Plus"
                            text="Add option"
                            variant="secondary"
                            size="xSmall"
                            onClick={() => {
                                update(prev => ({
                                    ...prev,
                                    options: [...prev.options, { value: '' }],
                                }))
                            }}
                        />
                    </footer>
                </StyledOptions>
            </InputWrapper>
            <InputWrapper
                fieldId={multiToggleFieldId}
                label="Multi select"
                description="If true, multiple options can be selected. Multi select fields cannot be used for field grouping when configuring workflow views."
                displayOnly={constraintsLocked}
            >
                <div>
                    {constraintsLocked ? (
                        <StyledDisplayOnlyMutliSelect>
                            <Text as="p" variant="bold-4">
                                {multi ? 'Yes' : 'No'}
                            </Text>
                        </StyledDisplayOnlyMutliSelect>
                    ) : (
                        <Toggle
                            checked={multi}
                            onCheckedChange={checked =>
                                match(checked)
                                    .with(true, () => {
                                        setMulti(true)
                                    })
                                    .otherwise(() => {
                                        update(prev => ({
                                            ...prev,
                                            constraints: {
                                                numberOfSelections: {
                                                    min: 1,
                                                    max: 1,
                                                },
                                            },
                                        }))
                                        setMulti(false)
                                    })
                            }
                            indicatorText={['Yes', 'No']}
                        />
                    )}
                </div>
            </InputWrapper>
            {multi && (
                <InputWrapper
                    fieldset
                    label="Number of selections"
                    description="This will limit the number of characters that can be entered into the text field."
                    displayOnly={constraintsLocked}
                >
                    <NumberOfSelectionsConstraint
                        numberOfSelections={
                            constraints.numberOfSelections || {
                                min: 0,
                                max: null,
                            }
                        }
                        update={fn => {
                            update(prev => ({
                                ...prev,
                                constraints: {
                                    numberOfSelections: fn(
                                        prev.constraints.numberOfSelections ?? {
                                            min: 0,
                                            max: null,
                                        }
                                    ),
                                },
                            }))
                        }}
                        displayOnly={constraintsLocked}
                    />
                </InputWrapper>
            )}
        </>
    )
}

const StyledOptions = styled.div`
    > ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > footer {
        display: flex;
        justify-content: center;
        padding-top: 0.75rem;
    }
`

const StyledDisplayOnlyMutliSelect = styled.div`
    color: ${({ theme }) => theme.palette.text['support03'].normal};
`

export { SelectSubFields }
