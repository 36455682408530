import { Text } from 'Components/Text'
import { FC } from 'react'
import { RelationTypeConstraint } from '__generated__'

export const RelationType: FC<
    Omit<RelationTypeConstraint, 'types'> & {
        types: Array<
            Pick<RelationTypeConstraint['types'][number], 'id' | 'name'>
        >
    }
> = ({ types }) => (
    <>
        <Text as="p" variant="bold-4">
            Dataset
        </Text>
        <ul>
            {types.map(({ id, name }) => (
                <li key={id}>
                    <Text as="p" variant="regular-4">
                        {name}
                    </Text>
                </li>
            ))}
        </ul>
    </>
)
