import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Available_TemplatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Available_TemplatesQuery = { __typename?: 'Query', workspaceTemplates: Array<{ __typename?: 'AvailableTemplateCategory', id: string, name: string, description: string, definitions: Array<{ __typename?: 'TemplateDefinition', name: string, description?: string | null, fields: Array<{ __typename?: 'FieldAddition', name: string, type: Types.FieldType }> }>, templates: Array<{ __typename?: 'AvailableTemplate', id: string, name: string, description: string, imageURL?: string | null, processes: Array<{ __typename?: 'TemplateProcess', name: string, description?: string | null }>, fields: Array<{ __typename?: 'FieldAdditions', name: string, fields: Array<{ __typename?: 'FieldAddition', name: string, type: Types.FieldType }> }>, definitions: Array<{ __typename?: 'TemplateDefinitionDefinition', name: string }> }> }>, businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, processes: Array<{ __typename?: 'Process', id: string, name: string }> };


export const Available_TemplatesDocument = gql`
    query Available_Templates {
  workspaceTemplates {
    id
    name
    description
    definitions {
      name
      description
      fields {
        name
        type
      }
    }
    templates {
      id
      name
      description
      imageURL
      processes {
        name
        description
      }
      fields {
        name
        fields {
          name
          type
        }
      }
      definitions {
        name
      }
    }
  }
  businessObjectDefinitions {
    id
    name
  }
  processes {
    id
    name
  }
}
    `;

/**
 * __useAvailable_TemplatesQuery__
 *
 * To run a query within a React component, call `useAvailable_TemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailable_TemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailable_TemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailable_TemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Available_TemplatesQuery, Available_TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Available_TemplatesQuery, Available_TemplatesQueryVariables>(Available_TemplatesDocument, options);
      }
export function useAvailable_TemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Available_TemplatesQuery, Available_TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Available_TemplatesQuery, Available_TemplatesQueryVariables>(Available_TemplatesDocument, options);
        }
export type Available_TemplatesQueryHookResult = ReturnType<typeof useAvailable_TemplatesQuery>;
export type Available_TemplatesLazyQueryHookResult = ReturnType<typeof useAvailable_TemplatesLazyQuery>;
export type Available_TemplatesQueryResult = Apollo.QueryResult<Available_TemplatesQuery, Available_TemplatesQueryVariables>;