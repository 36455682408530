import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { match, P } from 'ts-pattern'
import { ScheduledEventsQuery } from '../__generated__/query'
import { CreateActionPayloadDetail } from './CreateActionPayloadDetail'

export type Props = {
    payload: ScheduledEventsQuery['scheduledEvents'][0]['payload']
}

export const DetailsModal: FC<Props> = ({ payload }) => {
    const modalControls = useModalControls()

    return (
        <>
            <IconTextButton
                icon="Detail"
                text="Details"
                onClick={() => modalControls.setOpen(true)}
            />
            <ControlledModal
                {...modalControls}
                onOpenChange={modalControls.setOpen}
                title="Event details"
                hideDescription
                description=""
                renderContent={() => (
                    <StyledModal>
                        {match(payload)
                            .with(
                                {
                                    __typename: 'CreateActionPayload',
                                    data: P.not(P.nullish),
                                },
                                payload => (
                                    <CreateActionPayloadDetail
                                        payload={payload.data}
                                    />
                                )
                            )
                            .otherwise(() => (
                                <div>
                                    Could not render details for this event
                                </div>
                            ))}
                    </StyledModal>
                )}
            />
        </>
    )
}

const StyledModal = styled.div`
    width: 100vw;
    max-width: 40rem;
    overflow: auto;
    border: 0;
`
