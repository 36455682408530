import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { RelationTypeConstraint } from '__generated__'
import { useMemo } from 'react'

type Params = {
    relationConstraints: BODField<'RelationFieldDefinition'>['relationConstraints']
}

export const useRelationConstraints = ({ relationConstraints }: Params) => {
    const constraints = useMemo(() => {
        const relation = relationConstraints.find(
            contraint => contraint.__typename === 'RelationTypeConstraint'
        ) as RelationTypeConstraint

        return {
            required: !!relationConstraints.find(
                contraint => contraint.__typename === 'RequiredConstraint'
            ),
            relationId: relation.types[0].id,
        }
    }, [relationConstraints])

    const constraintsTooltip = useMemo(() => {
        if (!constraints.required) return ''
        return 'Required.'
    }, [constraints])

    return {
        constraints,
        constraintsTooltip,
    }
}
