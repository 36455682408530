import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { UrlFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'

type Props = {
    field: UrlFieldUnique
}

const UrlFieldContent: FC<Props> = ({ field: { defaultValue } }) => (
    <Styled>
        <Text as="p" variant="bold-5">
            Default Value:
        </Text>
        <Text as="p" variant="regular-5">
            {defaultValue || 'none'}
        </Text>
    </Styled>
)

const Styled = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    p:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { UrlFieldContent }
