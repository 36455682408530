import { FC, ReactNode } from 'react'

type Props = {
    children: ReactNode
}

export const FeatureName: FC<Props> = ({ children }) => {
    return (
        <strong>
            <em>{children}</em>
        </strong>
    )
}
