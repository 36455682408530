import { logger } from 'Adapters/Logger'
import { CenteredSpinner } from 'Components/Spinner'
import { useResponder } from 'Hooks'
import { Route, Routes } from 'react-router'
import { P, match } from 'ts-pattern'
import { Large } from './Large/'
import { NoActions } from './Large/NoActions'
import { Small } from './Small'
import { useActionCountQuery } from './__generated__/query'

export const Actions = () => {
    const { isLarge } = useResponder()
    const { data } = useActionCountQuery({
        fetchPolicy: 'network-only',
        onError: e => {
            logger.error('Failed to load action count', e)
        },
    })

    return (
        <Routes>
            <Route
                path=":id?/*"
                element={
                    <>
                        {match({ data, isLarge })
                            .with({ data: P.nullish }, () => (
                                <CenteredSpinner />
                            ))
                            .with({ data: { actionCount: 0 } }, () => (
                                <NoActions />
                            ))
                            .with(
                                { data: P.not(P.nullish), isLarge: false },
                                ({ data: { currentUser } }) => (
                                    <Small currentUserId={currentUser.id} />
                                )
                            )
                            .with(
                                { data: P.not(P.nullish), isLarge: true },
                                ({ data: { currentUser } }) => (
                                    <Large currentUserId={currentUser.id} />
                                )
                            )
                            .exhaustive()}
                    </>
                }
            />
        </Routes>
    )
}
