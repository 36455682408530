import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUser_FrigadeProviderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUser_FrigadeProviderQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string } };


export const CurrentUser_FrigadeProviderDocument = gql`
    query CurrentUser_FrigadeProvider {
  currentUser {
    id
  }
}
    `;

/**
 * __useCurrentUser_FrigadeProviderQuery__
 *
 * To run a query within a React component, call `useCurrentUser_FrigadeProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUser_FrigadeProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUser_FrigadeProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUser_FrigadeProviderQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUser_FrigadeProviderQuery, CurrentUser_FrigadeProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUser_FrigadeProviderQuery, CurrentUser_FrigadeProviderQueryVariables>(CurrentUser_FrigadeProviderDocument, options);
      }
export function useCurrentUser_FrigadeProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUser_FrigadeProviderQuery, CurrentUser_FrigadeProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUser_FrigadeProviderQuery, CurrentUser_FrigadeProviderQueryVariables>(CurrentUser_FrigadeProviderDocument, options);
        }
export type CurrentUser_FrigadeProviderQueryHookResult = ReturnType<typeof useCurrentUser_FrigadeProviderQuery>;
export type CurrentUser_FrigadeProviderLazyQueryHookResult = ReturnType<typeof useCurrentUser_FrigadeProviderLazyQuery>;
export type CurrentUser_FrigadeProviderQueryResult = Apollo.QueryResult<CurrentUser_FrigadeProviderQuery, CurrentUser_FrigadeProviderQueryVariables>;