import { useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { Large } from './Large'
import { Small } from './Small'
import { tabs } from './config'

type Props = {
    isOpen: boolean
    close: () => void
    id: string | undefined
    tab: keyof typeof tabs
    onSelect: (tab: keyof typeof tabs) => void
    processId?: string
    businessObjectDefinitionId?: string
}

const BusinessObjectDisplay: FC<Props> = props => {
    const { screenSize } = useResponder()

    return match(screenSize)
        .with('large', () => <Large {...props} />)
        .otherwise(() => <Small {...props} />)
}

export { BusinessObjectDisplay }
