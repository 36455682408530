import ReactMarkdown, { Components, Options } from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const Markdown = (props: Omit<Options, 'components'>) => {
    const components: Components = {
        code(props) {
            const { children, className, node, ref, ...rest } = props
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
                <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    children={String(children).replace(/\n$/, '')}
                    language={match[1]}
                    style={dark}
                />
            ) : (
                <code {...rest} className={className}>
                    {children}
                </code>
            )
        },
    }

    return (
        <ReactMarkdown {...props} components={components}>
            {props.children}
        </ReactMarkdown>
    )
}
