import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { SortState } from '../types'

type Params<TItem> = {
    items: TItem[]
}
export const useSortItems = <TItem>({ items }: Params<TItem>) => {
    const [sortState, setSortState] = useState<SortState<TItem>>({
        primary: undefined,
        secondary: undefined,
        order: 'asc',
    })

    const sortedItems = useMemo(() => {
        if (!sortState.primary) return items
        return orderBy(
            items,
            [sortState.primary, sortState.secondary],
            [sortState.order]
        )
    }, [items, sortState]) as TItem[]

    return { sortState, setSortState, sortedItems }
}
