import * as Types from '../../../../../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcessEditor_BusinessObjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProcessEditor_BusinessObjectsQuery = { __typename?: 'Query', businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }> };


export const ProcessEditor_BusinessObjectsDocument = gql`
    query ProcessEditor_BusinessObjects {
  businessObjectDefinitions {
    id
    name
  }
}
    `;

/**
 * __useProcessEditor_BusinessObjectsQuery__
 *
 * To run a query within a React component, call `useProcessEditor_BusinessObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessEditor_BusinessObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessEditor_BusinessObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessEditor_BusinessObjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessEditor_BusinessObjectsQuery, ProcessEditor_BusinessObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessEditor_BusinessObjectsQuery, ProcessEditor_BusinessObjectsQueryVariables>(ProcessEditor_BusinessObjectsDocument, options);
      }
export function useProcessEditor_BusinessObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessEditor_BusinessObjectsQuery, ProcessEditor_BusinessObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessEditor_BusinessObjectsQuery, ProcessEditor_BusinessObjectsQueryVariables>(ProcessEditor_BusinessObjectsDocument, options);
        }
export type ProcessEditor_BusinessObjectsQueryHookResult = ReturnType<typeof useProcessEditor_BusinessObjectsQuery>;
export type ProcessEditor_BusinessObjectsLazyQueryHookResult = ReturnType<typeof useProcessEditor_BusinessObjectsLazyQuery>;
export type ProcessEditor_BusinessObjectsQueryResult = Apollo.QueryResult<ProcessEditor_BusinessObjectsQuery, ProcessEditor_BusinessObjectsQueryVariables>;