import { createContext, FC, ReactNode, useContext, useReducer } from 'react'
import { match } from 'ts-pattern'
import { ProcessDef_SingleProcessQuery } from '../../../../__generated__/query'
import {
    handleAddActionCriterion,
    handleAddFieldConditionCriterion,
    handleAddProcessDelegateCriterion,
    handleAddProcessFanoutCriterion,
    handleRenameCriterion,
    handleSetDescription,
    handleSetName,
    handleShowDetailsStep,
    handleShowPhasesStep,
    handleUpdateCriterionConditions,
    handleUpdateCriterionDueTime,
} from './handle'
import { Action, State } from './types'

const Ctx = createContext<
    | {
          state: State
          dispatch: React.Dispatch<Action>
          onCompleted: () => void
      }
    | undefined
>(undefined)

const reducer = (state: State, action: Action): State => {
    const next = match(action)
        .with({ type: 'showDetailsStep' }, handleShowDetailsStep(state))
        .with({ type: 'showPhasesStep' }, handleShowPhasesStep(state))
        .with({ type: 'setName' }, handleSetName(state))
        .with({ type: 'setDescription' }, handleSetDescription(state))
        .with({ type: 'addActionCriterion' }, handleAddActionCriterion(state))
        .with({ type: 'renameCriterion' }, handleRenameCriterion(state))
        .with(
            { type: 'updateCriterionDueTime' },
            handleUpdateCriterionDueTime(state)
        )
        .with(
            { type: 'addFieldConditionCriterion' },
            handleAddFieldConditionCriterion(state)
        )
        .with(
            { type: 'addProcessFanoutCriterion' },
            handleAddProcessFanoutCriterion(state)
        )
        .with(
            { type: 'addProcessDelegateCriterion' },
            handleAddProcessDelegateCriterion(state)
        )
        .with(
            { type: 'updateCriterionConditions' },
            handleUpdateCriterionConditions(state)
        )
        .exhaustive()

    return next
}

const ProcessEditorProvider: FC<{
    process: ProcessDef_SingleProcessQuery['process']
    render: (step: State['step']) => ReactNode
    onCompleted: () => void
}> = ({ render, onCompleted, process }) => {
    const [state, dispatch] = useReducer(reducer, {
        initial: process,
        process,
        step: 'details',
        operations: [],
    })

    return (
        <Ctx.Provider value={{ state, dispatch, onCompleted }}>
            {render(state.step)}
        </Ctx.Provider>
    )
}

const useProcessEditor = () => {
    const c = useContext(Ctx)
    if (!c)
        throw new Error(
            'useProcessEditor was called outside of ProcessEditorProvider'
        )
    return c
}

export { ProcessEditorProvider, useProcessEditor }
