import { Select } from 'Components/Select'
import { useOrganisationUsers } from 'Hooks'
import { FC } from 'react'

type Props = {
    name: string
    value: string | undefined
    onChange: (value: string | undefined) => void
}

export const UserField: FC<Props> = ({ name, value, onChange }) => {
    const { users } = useOrganisationUsers()
    return (
        <Select
            id={name}
            value={value}
            onValueChange={onChange}
            name={name}
            options={[
                {
                    text: '-- select a user --',
                    value: '',
                },
                ...users.map(user => ({
                    text: user.isCurrentUser ? 'Me' : user.name,
                    value: user.id,
                    disabled: false,
                })),
            ]}
        />
    )
}
