import { waitForDelay } from 'Utils'
import { useCallback, useState } from 'react'

type UseTriggerToast = (delay?: number) => {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    triggerToast: () => Promise<void>
}

export const useTriggerToast: UseTriggerToast = (delay = 5000) => {
    const [open, setOpen] = useState(false)
    const triggerToast = useCallback(async () => {
        setOpen(true)
        await waitForDelay(delay)
        setOpen(false)
    }, [delay])

    return { open, setOpen, triggerToast }
}
