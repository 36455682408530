import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextAreaInput, TextInput } from 'Components/Input'
import { CenteredSpinner } from 'Components/Spinner'
import { regular4 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { useSingleWebhookQuery } from './__generated__/q'

type Props = {
    webhookId: string
    signingSecret?: string
}

export const PostCreationModal: FC<Props> = ({ webhookId, signingSecret }) => {
    const { data } = useSingleWebhookQuery({
        fetchPolicy: 'cache-and-network',
        variables: { id: webhookId },
    })

    if (!data?.webhook) return <CenteredSpinner />

    const { webhook } = data

    const curl = `curl --request POST \\
    --url ${webhook.url} \\
    --header 'Content-Type: application/json' \\
    --data '{
        "name": "John Smith" 
    }'`

    return (
        <Styled>
            <label>
                Webhook Name
                <TextInput
                    type="text"
                    disabled
                    value={webhook.name ?? 'Unnamed'}
                />
            </label>
            {signingSecret ? (
                <label>
                    Signing Secret
                    <div>
                        <TextInput type="text" value={signingSecret} disabled />
                        <Tooltip content="Copy to clipboard">
                            <IconButton
                                iconName="Copy"
                                type="button"
                                onClick={() => {
                                    signingSecret &&
                                        navigator.clipboard.writeText(
                                            signingSecret
                                        )
                                }}
                            />
                        </Tooltip>
                    </div>
                </label>
            ) : null}
            <label>
                URL
                <div>
                    <TextInput type="text" value={webhook.url} disabled />
                    <Tooltip content="Copy to clipboard">
                        <IconButton
                            iconName="Copy"
                            type="button"
                            onClick={() => {
                                navigator.clipboard.writeText(webhook.url)
                            }}
                        />
                    </Tooltip>
                </div>
            </label>
            <label>
                cURL Command
                <div>
                    <TextAreaInput disabled value={curl} />
                    <Tooltip content="Copy to clipboard">
                        <IconButton
                            iconName="Copy"
                            type="button"
                            onClick={() => {
                                navigator.clipboard.writeText(curl)
                            }}
                        />
                    </Tooltip>
                </div>
                Run this from the command-line to test your webhook. It can also
                be imported into Postman or Insomnia clients.
            </label>
        </Styled>
    )
}

const Styled = styled.div`
    max-height: 30vh;
    max-width: 80vw;
    min-width: 40rem;
    min-height: 36rem;
    padding-top: 1rem;

    > label {
        ${regular4};
        display: block;
        padding-bottom: 1.5rem;
        width: 100%;

        &:last-of-type {
            padding-bottom: 1rem;
        }

        > div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: space-between;
        }
    }
`
