import { styled } from 'Adapters/Freestyled'
import { FC, useRef } from 'react'
import { AddCriterion } from './AddCriterion'
import { Criteria } from './Criteria'
import { PhaseHeader } from './Header'

type Props = { index: number }

const Phase: FC<Props> = ({ index }) => {
    const critContainer = useRef<HTMLDivElement>(null)
    return (
        <Styled>
            <PhaseHeader index={index} />
            <div ref={critContainer}>
                <Criteria phaseIndex={index} />
                <AddCriterion
                    phaseIndex={index}
                    onCriterionAdded={() => {
                        requestAnimationFrame(() => {
                            if (critContainer.current)
                                critContainer.current.scrollBy({
                                    behavior: 'smooth',
                                    top: critContainer.current.scrollHeight,
                                })
                        })
                    }}
                />
            </div>
        </Styled>
    )
}

const Styled = styled.li`
    border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    display: flex;
    flex-direction: column;
    width: 20rem;
    flex-shrink: 0;

    > div {
        flex: 1;
        overflow: auto;
    }
`

export { Phase }
