import { useRef } from 'react'
import { v4 } from 'uuid'
import { DATE_FORMAT } from './constants'
import { FocusTargetDate } from './types'

type UseSetupDatePicker = () => {
    datePickerId: string
    focusTargetDate: FocusTargetDate
}

export const useSetupDatePicker: UseSetupDatePicker = () => {
    const { current } = useRef(v4())

    return {
        datePickerId: current,
        focusTargetDate: day => {
            const target: HTMLButtonElement | null = document.querySelector(
                `[data-date-picker-id="${current}"] [data-date="${day.format(
                    DATE_FORMAT
                )}"]`
            )
            target?.focus()
        },
    }
}
