import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSandboxUserMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateSandboxUserMutation = { __typename?: 'Mutation', createSandboxUser: { __typename?: 'SandboxUser', token: string, user: { __typename?: 'User', id: string } } };


export const CreateSandboxUserDocument = gql`
    mutation CreateSandboxUser {
  createSandboxUser {
    user {
      id
    }
    token
  }
}
    `;
export type CreateSandboxUserMutationFn = Apollo.MutationFunction<CreateSandboxUserMutation, CreateSandboxUserMutationVariables>;

/**
 * __useCreateSandboxUserMutation__
 *
 * To run a mutation, you first call `useCreateSandboxUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSandboxUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSandboxUserMutation, { data, loading, error }] = useCreateSandboxUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSandboxUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSandboxUserMutation, CreateSandboxUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSandboxUserMutation, CreateSandboxUserMutationVariables>(CreateSandboxUserDocument, options);
      }
export type CreateSandboxUserMutationHookResult = ReturnType<typeof useCreateSandboxUserMutation>;
export type CreateSandboxUserMutationResult = Apollo.MutationResult<CreateSandboxUserMutation>;
export type CreateSandboxUserMutationOptions = Apollo.BaseMutationOptions<CreateSandboxUserMutation, CreateSandboxUserMutationVariables>;