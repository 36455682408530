import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { GroupingControl } from 'Components/GroupingControl'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { useGroupingMenuState } from 'Features/ActionView/Grouping/useGroupingMenuState'
import { FC, useState } from 'react'
import { Extension } from '../../../types'
import { pipStyles } from './styles'

type Props = {
    fields: Extension['fields']
    allAssignablePrincipals: { id: string; name: string }[]
}

export const Grouping: FC<Props> = ({ fields, allAssignablePrincipals }) => {
    const [open, setOpen] = useState(false)

    const {
        groupState,
        moveGroupDown,
        moveGroupUp,
        setGroupColor,
        setSelection,
        setToSearchParams,
        groupingBy,
    } = useGroupingMenuState(fields, allAssignablePrincipals)

    return (
        <>
            <StyledGroupingMenuButton
                $hasGrouping={!!groupingBy}
                icon="DotCircle"
                text="Group"
                size="xSmall"
                variant="secondary"
                onClick={() => setOpen(true)}
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                <VerticalScrollArea height="100%" width="100%">
                    <StyledGroupingControl
                        grouping={groupState}
                        onFieldSelect={setSelection}
                        onMoveGroupUp={moveGroupUp}
                        onMoveGroupDown={moveGroupDown}
                        onSelectGroupColor={setGroupColor}
                        onClose={() => {
                            setOpen(false)
                            setToSearchParams()
                        }}
                    />
                </VerticalScrollArea>
            </MinimalModal>
        </>
    )
}

const StyledGroupingControl = styled(GroupingControl)`
    width: 100%;
    padding: 1rem;
`

const StyledGroupingMenuButton = styled(IconTextButton)<{
    $hasGrouping: boolean
}>`
    ${({ $hasGrouping }) => $hasGrouping && pipStyles};
`
