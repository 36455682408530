import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { CreateBusinessObjectModal } from 'Features/CreateBusinessObjectModal'
import {
    FilterBarSmall,
    useBusinessObjectFuzzySearch,
} from 'Features/FilterBusinessObject'
import { sortBos } from 'Features/SortBusinessObject'
import { FC, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { P, match } from 'ts-pattern'
import { useFetchBusinessObjectDefinitionFieldsById } from '../../Hooks'
import { useFilterBusinessObjects } from '../../Hooks/useFilterBusinessObjects'
import { CardList } from './CardList/CardList'
import { GroupedCardList } from './GroupedCardList'

type Props = {
    businessObjectDefinitionId: string
    name: string
    description: string
}

const Definition: FC<Props> = ({ businessObjectDefinitionId, name }) => {
    const navigate = useNavigate()

    const { filteredBusinessObjects, hasInitiallyLoaded: objsLoaded } =
        useFilterBusinessObjects(businessObjectDefinitionId)

    const {
        sort: [by, asc],
        grouping,
    } = useBusinessObjectsView()

    const sortedBusinessObjects = useMemo(
        () => sortBos({ by, asc: asc === 'asc' })(filteredBusinessObjects),
        [asc, by, filteredBusinessObjects]
    )

    const { searchedBusinessObjects } = useBusinessObjectFuzzySearch(
        sortedBusinessObjects
    )

    const { fields, hasInitiallyLoaded: fieldsLoaded } =
        useFetchBusinessObjectDefinitionFieldsById({
            id: businessObjectDefinitionId,
        })

    const loaded = objsLoaded && fieldsLoaded

    const [createOpen, setCreateOpen] = useState(false)

    const createTrigger = useRef<HTMLButtonElement>(null)

    const boDisplayProps = useBusinessObjectDisplay({ key: 'business-object' })

    return (
        <Styled>
            <header>
                <Text as="h1" variant="bold-2">
                    {name}
                </Text>
                <menu>
                    <IconButton
                        iconName="Domain"
                        variant="secondary"
                        onClick={() => {
                            navigate(
                                `/manage-workspace/model/datasets/${slugify(
                                    name,
                                    {
                                        lower: true,
                                    }
                                )}`
                            )
                        }}
                    />
                    <IconButton
                        iconName="Plus"
                        variant="primary"
                        ref={createTrigger}
                        onClick={() => setCreateOpen(true)}
                        disabled={!loaded}
                    />
                </menu>
            </header>
            {match(loaded)
                .with(true, () => (
                    <>
                        {match(grouping)
                            .with(P.nullish, () => (
                                <CardList
                                    businessObjects={searchedBusinessObjects}
                                    openDrawer={boDisplayProps.open}
                                />
                            ))
                            .with(P.not(P.nullish), grouping => (
                                <GroupedCardList
                                    businessObjects={searchedBusinessObjects}
                                    grouping={grouping}
                                    openDrawer={boDisplayProps.open}
                                />
                            ))
                            .exhaustive()}

                        <footer>
                            <FilterBarSmall
                                businessObjectDefinitionId={
                                    businessObjectDefinitionId
                                }
                                fieldDefinitions={fields}
                            />
                        </footer>

                        <CreateBusinessObjectModal
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                            businessObjectDefinitionName={name}
                            fields={fields}
                            open={createOpen}
                            onOpenChange={setCreateOpen}
                            closeModal={() => setCreateOpen(false)}
                            returnFocus={() => createTrigger.current?.focus()}
                        />

                        <BusinessObjectDisplay
                            {...boDisplayProps}
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                        />
                    </>
                ))
                .with(false, () => <CenteredSpinner />)
                .exhaustive()}
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr);
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    > header {
        padding: 0.75rem 4vw 0.5rem;
        grid-row: 1 / 2;
        display: flex;
        gap: 4vw;
        justify-content: space-between;
        align-items: flex-start;
    }

    > header h1 {
        padding-top: 0.25rem;
    }

    > header menu {
        display: flex;
        gap: 0.5rem;
    }
`

export { Definition }
