import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { has } from 'Utils'
import { sanatiseTemplateField } from 'Utils/BusinessObjectDefinition'
import { compact } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { useProcessCreator } from '../useProcessCreator'
import { State } from '../useProcessCreator/types'
import { useSuggestProcessAndDefinitionLazyQuery } from './__generated__/query'

const loadingTextSequence = [
    'Validating your request...',
    'Constructing workflow phases...',
    'Adding data requirements...',
    'Generating actions to be taken...',
    'Putting it all together...',
    'Your new workflow is almost ready...',
]

export const useSuggestedPhases = ({
    industry,
    size,
    purpose,
    team,
}: State['ai']) => {
    const {
        state: { input },
        dispatch,
    } = useProcessCreator()

    const errorToast = useTriggerToast()

    const [loadingText, setLoadingText] = useState<string | undefined>()

    const percentComplete = useMemo(() => {
        if (!loadingText) return 0
        const index = loadingTextSequence.indexOf(loadingText)
        const percentComplete = ((index + 1) / loadingTextSequence.length) * 100
        return percentComplete === 100 ? 95 : percentComplete // don't actually reach 100% until the query completes
    }, [loadingText])

    const [timers, setTimers] = useState<ReturnType<typeof setTimeout>[]>([])

    // clear all timers on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => timers.forEach(clearTimeout), [])

    const [query, { loading }] = useSuggestProcessAndDefinitionLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                purpose: purpose!,
                size: size!,
                industry: industry!,
                team: team!,
            },
        },
        onError: error => {
            errorToast.triggerToast()
            logger.error('Failed to suggest workflow', error)
        },
        onCompleted: data => {
            dispatch({
                type: 'suggestionApplied',
                payload: {
                    name: data.suggestedProcessAndDefinition.name,
                    label: data.suggestedProcessAndDefinition.label,
                    fields: data.suggestedProcessAndDefinition.fields.map(
                        ({ field, requiredBy }) => ({
                            field: sanatiseTemplateField(field)!,
                            requiredBy: has(requiredBy, 'phase')
                                ? { phase: requiredBy.phase }
                                : { init: true },
                        })
                    ),
                    phases: data.suggestedProcessAndDefinition.phases.map(
                        phase => ({
                            name: phase.name,
                            description: phase.description,
                            criteria: compact(
                                phase.criteria.map(criterion =>
                                    match(criterion)
                                        .with(
                                            {
                                                __typename:
                                                    'ActionPhaseCriterion',
                                            },
                                            criterion => ({
                                                action: {
                                                    description:
                                                        criterion.description,
                                                    ...(criterion.defaultDueSeconds && {
                                                        defaultDueSeconds:
                                                            criterion.defaultDueSeconds,
                                                    }),
                                                    ...(criterion.purpose && {
                                                        purpose:
                                                            criterion.purpose,
                                                    }),
                                                },
                                            })
                                        )
                                        .with(
                                            {
                                                __typename:
                                                    'FieldConditionPhaseCriterion',
                                                comparator: {
                                                    valueSelector: {
                                                        __typename:
                                                            'FieldValueSelector',
                                                    },
                                                },
                                            },
                                            criterion => ({
                                                fieldCondition: {
                                                    comparator: {
                                                        operator:
                                                            criterion.comparator
                                                                .operator,
                                                        negate: criterion
                                                            .comparator.negate,
                                                        with: criterion
                                                            .comparator.with,
                                                        valueSelector: {
                                                            fieldValue: {
                                                                fieldId:
                                                                    criterion
                                                                        .comparator
                                                                        .valueSelector
                                                                        .fieldId,
                                                            },
                                                        },
                                                    },
                                                },
                                            })
                                        )
                                        .otherwise(() => null)
                                )
                            ),
                        })
                    ),
                },
            })
        },
    })

    const getSuggestion = () => {
        input.phases.forEach((_, index) => {
            dispatch({
                type: 'removePhase',
                payload: { index: input.phases.length - 1 - index }, // reverse otherwise indices change
            })
        })

        setTimers(
            loadingTextSequence.map((text, i) =>
                setTimeout(() => {
                    setLoadingText(text)
                }, i * 9000)
            )
        )

        return query()
    }

    return {
        getSuggestion,
        percentComplete,
        loading,
        loadingText,
        errorToast,
    }
}
