export type SelectConstraintsMap = {
    min: number
    max: undefined | number
}

export const defaultConstraints = {
    min: 0,
    max: undefined,
} satisfies SelectConstraintsMap

export type SelectUI = 'select' | 'radio' | 'checkbox'
