import { styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { FeatureName } from 'Features/FeatureName'
import { DetailsForm, useUserDetailsQuery } from 'Features/UserDetails'
import { P, match } from 'ts-pattern'

const YourDetails = () => {
    const { data, loading } = useUserDetailsQuery()

    return match({ data, loading })
        .with({ loading: true, data: P.nullish }, () => <CenteredSpinner />)
        .with({ loading: false, data: P.nullish }, () => (
            <Text variant="regular-6" as={'p'}>
                Unable to load your details
            </Text>
        ))
        .with(
            { data: P.not(P.nullish) },
            ({
                data: {
                    currentUser: { name, id },
                },
            }) => (
                <Styled>
                    <header>
                        <Text as="h2" variant="bold-3">
                            Profile
                        </Text>
                        <Text as="p" variant="regular-4">
                            These details control the way you appear in the app,
                            for example when you are selected in a user field on
                            a <FeatureName>record</FeatureName>, or when you
                            leave updates.
                        </Text>
                    </header>
                    <DetailsForm name={name} id={id} />
                </Styled>
            )
        )
        .exhaustive()
}

const Styled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > header h2 {
        padding-bottom: 1rem;
    }
    > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { YourDetails }
