import { CenteredSpinner } from 'Components/Spinner'
import { FC, useCallback } from 'react'
import { Content } from './Content'
import { useFieldCompletionModalQuery } from './__generated__/q'

type Props = {
    processId: string
    id: string
    onClose: () => void
    includeFields?: string[]
    onFieldUpdated?: (fieldId: string) => void
}

const Container: FC<Props> = ({
    processId,
    id,
    onClose,
    includeFields,
    onFieldUpdated,
}) => {
    const { data, loading, refetch } = useFieldCompletionModalQuery({
        variables: {
            businessObjectId: id,
            onProcessInput: { processId },
        },
    })

    const handleUpdate = useCallback(
        (fieldId: string) => {
            refetch()
            onFieldUpdated?.(fieldId)
        },
        [onFieldUpdated, refetch]
    )

    return data && !loading ? (
        <Content
            businessObject={data.businessObject}
            onClose={onClose}
            onFieldUpdated={handleUpdate}
            includeFields={includeFields}
        />
    ) : (
        <CenteredSpinner />
    )
}

export { Container }
