import { Group } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Dropdown, DropdownCheckbox } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { includes } from 'lodash'
import { Dispatch, FC, SetStateAction } from 'react'

type Props = {
    options: { id: string; value: string }[]
    selections: string[]
    setSelections: Dispatch<SetStateAction<string[]>>
}

export const Checkboxes: FC<Props> = ({
    options,
    selections,
    setSelections,
}) => {
    return (
        <Dropdown
            renderContent={() => (
                <Group>
                    {options.map(option => (
                        <DropdownCheckbox
                            key={option.id}
                            text={option.value}
                            checked={includes(selections, option.id)}
                            onCheckedChange={bool => {
                                setSelections(prev => {
                                    if (bool === true)
                                        return [...prev, option.id]
                                    return prev.filter(id => id !== option.id)
                                })
                            }}
                            closeOnSelect={false}
                        />
                    ))}
                </Group>
            )}
            renderOpenDropdownButton={() => (
                <StyledOpenDropdownButton
                    icon={
                        selections.length
                            ? 'CheckboxTicked'
                            : 'CheckboxUnticked'
                    }
                    text={
                        selections.length
                            ? `${selections.length} selected`
                            : 'Select Items'
                    }
                />
            )}
            variant="light"
        />
    )
}

const StyledOpenDropdownButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
