import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { Text } from 'Components/Text'
import dayjs from 'dayjs'
import { FC, useRef, useState } from 'react'
import { InputWrapper } from '../InputWrapper'

type Props = {
    start: Date | undefined
    end: Date | undefined
    onSetStart: (date: Date) => void
    onSetEnd: (date: Date) => void
    onClear: () => void
    displayOnly: boolean
}

const DateRangeConstraint: FC<Props> = ({
    start,
    end,
    onSetStart,
    onSetEnd,
    onClear,
    displayOnly,
}) => {
    const [startOpen, setStartOpen] = useState(false)
    const [endOpen, setEndOpen] = useState(false)
    const startTrigger = useRef<HTMLButtonElement>(null)
    const endTrigger = useRef<HTMLButtonElement>(null)

    return (
        <InputWrapper
            label="Date range"
            fieldset
            description="This will limit the date range for the field. If a specifc default date has been set outside of this range it will be removed."
            displayOnly={displayOnly}
        >
            {displayOnly ? (
                <StyledDisplayOnly>
                    <div>
                        <Text as="p" variant="bold-5">
                            Start:
                        </Text>
                        <Text as="p" variant="bold-5">
                            {start
                                ? dayjs(start).format('DD MMM YYYY')
                                : 'None'}
                        </Text>
                    </div>
                    <div>
                        <Text as="p" variant="bold-5">
                            End:
                        </Text>
                        <Text as="p" variant="bold-5">
                            {end ? dayjs(end).format('DD MMM YYYY') : 'None'}
                        </Text>
                    </div>
                </StyledDisplayOnly>
            ) : (
                <Styled>
                    <StyledDateButton
                        icon="Calendar"
                        text={
                            start ? dayjs(start).format('DD MMM YYYY') : 'Start'
                        }
                        onClick={() => {
                            setStartOpen(true)
                        }}
                        variant="secondary"
                        ref={startTrigger}
                    />
                    <DatePicker
                        title="Set start"
                        description=""
                        hideDescription
                        open={startOpen}
                        onOpenChange={setStartOpen}
                        onConfirm={({ date }) => {
                            onSetStart(date)
                            setStartOpen(false)
                        }}
                        selectedDate={start}
                        returnFocus={() => startTrigger.current?.focus()}
                    />
                    <StyledDateButton
                        icon="Calendar"
                        text={end ? dayjs(end).format('DD MMM YYYY') : 'End'}
                        onClick={() => {
                            setEndOpen(true)
                        }}
                        variant="secondary"
                        ref={endTrigger}
                    />
                    <DatePicker
                        title="Set end"
                        description=""
                        hideDescription
                        open={endOpen}
                        onOpenChange={setEndOpen}
                        onConfirm={({ date }) => {
                            onSetEnd(date)
                            setEndOpen(false)
                        }}
                        selectedDate={end}
                        returnFocus={() => endTrigger.current?.focus()}
                    />
                    <IconTextButton
                        icon="Close"
                        text="Clear"
                        onClick={() => onClear()}
                        variant="ghost"
                        ref={startTrigger}
                        disabled={!start && !end}
                    />
                </Styled>
            )}
        </InputWrapper>
    )
}

const Styled = styled.div`
    display: flex;
    gap: 1rem;
`

const StyledDateButton = styled(IconTextButton)`
    flex: 1;
`

const StyledDisplayOnly = styled.div`
    display: flex;
    gap: 1rem;

    > div {
        display: flex;
        gap: 0.5rem;
    }

    > div p:last-child {
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }
`

export { DateRangeConstraint }
