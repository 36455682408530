import { useFlow } from '@frigade/react'
import * as Popover from '@radix-ui/react-popover'
import { boxShadow, flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Text, regular5 } from 'Components/Text'
import { useResponder } from 'Hooks'
import { getPortalsContainer } from 'Utils'
import { noop } from 'lodash'
import { FC, ReactNode } from 'react'
import { Spotlight } from './Spotlight'
import { DURATION, enter, exit } from './styles'
import { useSpotlight } from './useSpotlight'

type Props = {
    children: ReactNode
    flowId: string
    stepId: string
}

export const FrigadeHint: FC<Props> = ({ children, flowId, stepId }) => {
    const { flow } = useFlow(flowId)

    const { isLarge } = useResponder()

    const hint = flow?.steps.get(stepId)

    const showHint =
        flow && hint && !flow.isCompleted && !hint.$state.completed && isLarge

    const { targetRect, targetRef } = useSpotlight(showHint)

    if (!showHint || hint.$state.completed) return <>{children}</>

    return (
        <>
            <Spotlight domRect={targetRect} active={showHint} />

            <Popover.Root open={showHint} onOpenChange={noop}>
                <Popover.Trigger asChild ref={targetRef}>
                    {children}
                </Popover.Trigger>

                <Popover.Portal container={getPortalsContainer()}>
                    <StyledContent side="bottom" align="center" sideOffset={10}>
                        <Popover.PopoverArrow className="arrow" />

                        <Text as={'h1'} variant={'bold-3'}>
                            {hint.title ?? ''}
                        </Text>

                        {hint.subtitle && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: hint.subtitle,
                                }}
                            />
                        )}

                        <StyledFooter>
                            <TextButton
                                onClick={() => {
                                    hint.complete()
                                    flow.complete()
                                }}
                                text={'Understood'}
                                size="xSmall"
                                variant="primary"
                                autoFocus
                            />
                        </StyledFooter>
                    </StyledContent>
                </Popover.Portal>
            </Popover.Root>
        </>
    )
}

const StyledContent = styled(Popover.Content)`
    max-height: 75vh;
    max-width: 25rem;
    width: 100vw;
    padding: 1rem;
    background-color: white;
    overflow: auto;
    box-shadow: ${boxShadow()};
    border-radius: ${({ theme }) => theme.borderRadius.small};

    .arrow {
        fill: ${({ theme }) => theme.palette.ui['01'].normal};
    }

    p {
        ${regular5};
    }

    p:not(:first-of-type) {
        padding-top: 0.5rem;
    }

    h1 {
        padding-bottom: 1rem;
    }

    &[data-state='open'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-out;
        animation-name: ${enter};
    }

    &[data-state='closed'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-in;
        animation-name: ${exit};
    }
`

const StyledFooter = styled.footer`
    ${flex('row', 'flex-end', 'center')}
    width: 100%;
    gap: 1rem;
    padding-top: 1rem;

    p {
        padding: 0;
    }

    button {
        min-width: 10rem;
    }
`
