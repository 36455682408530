import { css, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { MinimalModal } from 'Components/Modal/Minimal'
import { OnboardingChecklistMobile } from 'Features/OnboardingChecklistFrigade'
import { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { WorkspaceControl } from './WorkspaceControl'

const SmallWorkspaceMenu: FC = () => {
    const [workspaceControlOpen, setWorkspaceControlOpen] = useState(false)

    return (
        <Styled>
            <StyledNavItem to="processes">
                <Icon name="Process" />
            </StyledNavItem>
            <StyledNavItem to="datasets">
                <Icon name="Cube" />
            </StyledNavItem>
            <StyledNavItem to="actions">
                <Icon name="CircleCheck" />
            </StyledNavItem>
            <OnboardingChecklistMobile />
            <StyledWorkspaceControl
                onClick={() => setWorkspaceControlOpen(true)}
            >
                <Icon name="Settings" />
            </StyledWorkspaceControl>
            <MinimalModal
                open={workspaceControlOpen}
                onOpenChange={setWorkspaceControlOpen}
            >
                <WorkspaceControl
                    onNavigate={() => setWorkspaceControlOpen(false)}
                />
            </MinimalModal>
        </Styled>
    )
}

const Styled = styled.menu`
    display: flex;
    padding: 0 4vw;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-top: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
`

const navItemStyles = css`
    flex: 1;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: center;
    padding: 0;

    > .icon svg path {
        fill: ${({ theme }) => theme.palette.ui['05'].normal};
        transition: fill
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &[aria-current='page'] > .icon svg path {
        fill: ${({ theme }) => theme.palette.ui['06'].normal};
    }
`

const StyledNavItem = styled(NavLink)`
    ${navItemStyles}
`

const StyledWorkspaceControl = styled.button`
    ${navItemStyles}
    padding: 0;
`

export { SmallWorkspaceMenu }
