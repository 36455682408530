import { Icon } from 'Components/Icon'
import { StyledSortButton } from './styles'
import { SortButtonProps } from './types'

export const SortButton = <TItem,>({
    heading,
    primarySort,
    secondarySort,
    sortState,
    setSortState,
}: SortButtonProps<TItem>) => {
    const updateSort = () => {
        setSortState(prev => {
            const isSameSortField = primarySort === prev.primary

            const orderMap = {
                asc: 'desc',
                desc: 'asc',
            } as const

            return {
                primary: primarySort,
                secondary: secondarySort,
                order: !isSameSortField ? 'asc' : orderMap[prev.order],
            }
        })
    }

    return (
        <StyledSortButton onClick={updateSort}>
            {heading}

            {sortState.primary === primarySort ? (
                <Icon
                    name={sortState.order === 'desc' ? 'ArrowDown' : 'ArrowUp'}
                />
            ) : (
                // Invisible icon to stop the width of the column changing
                <Icon name="ArrowUp" className="invisible-icon" />
            )}
        </StyledSortButton>
    )
}
