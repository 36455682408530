import { styled } from 'Adapters/Freestyled'
import { bold1, regular5, regular6 } from 'Components/Text'

export const Styled = styled.form`
    width: 26rem;
    display: block;
    padding: 2rem;
    overflow: auto;
    max-height: 100%;

    > header {
        padding-bottom: 1.5rem;
    }

    > header p {
        ${regular5}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.5rem;
    }

    > header h2 {
        ${bold1}
    }

    > footer {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

export const StyledToast = styled.div`
    padding: 0.5rem 0.75rem;
    background-color: ${({ theme }) => theme.palette.support.bg01.normal};
    color: ${({ theme }) => theme.palette.support['01'].normal};
    border-radius: 0.25rem;
    ${regular6}
`
