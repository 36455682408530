import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { FieldType } from '__generated__'
import { useEffect, useRef, useState } from 'react'
import { Destination } from './types'

export const useDropOnGroup = (
    id: string,
    fieldType: FieldType | 'assignedTo'
) => {
    const ref = useRef(null)

    const [isDraggedOver, setIsDraggedOver] = useState(false)

    useEffect(() => {
        const el = ref.current

        if (!el) return

        return dropTargetForElements({
            element: el,
            onDragEnter: () => setIsDraggedOver(true),
            onDragLeave: () => setIsDraggedOver(false),
            onDrop: () => setIsDraggedOver(false),
            canDrop: ({ source }) => source.data.groupId !== id,
            getData: () => ({ groupId: id, fieldType } satisfies Destination),
        })
    }, [id, fieldType])

    return { ref, isDraggedOver }
}
