import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useMutationStatus } from 'Hooks'
import { AttachmentType } from '__generated__'
import { compact, uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { useUpdateActionMutation } from './__generated__/mutation'
import { UpdateActionForm } from './useForm'

export const useUpdateAction = () => {
    const [update, result] = useUpdateActionMutation()

    const [mutationErrors, setMutationErrors] = useState<
        Record<string, string[]>
    >({})

    const updateAction = useCallback(
        ({
            input: {
                name,
                assignedToId,
                description,
                dueDate,
                extendedBy,
                descriptionAttachments,
            },
            id,
            extendedByDefinitionId,
            onCompleted,
        }: {
            input: UpdateActionForm
            id: string
            extendedByDefinitionId?: string
            onCompleted?: () => void
        }) => {
            setMutationErrors({})

            const payload = {
                id,
                name,
                assignedTo: assignedToId ? assignedToId : null,
                description: description ? description : null,
                ...(descriptionAttachments && {
                    descriptionAttachments: descriptionAttachments.map(
                        attachment => ({
                            type: AttachmentType.File,
                            attachment: attachment.file.id,
                        })
                    ),
                }),
                dueDate: dueDate ? dueDate : null,
                ...(extendedByDefinitionId && {
                    extension: {
                        id: extendedByDefinitionId,
                        fields: compact(
                            extendedBy.map(
                                ({
                                    field: { id, type },
                                    value,
                                    initialValue,
                                }) => {
                                    // Lists are currently not implemented for editing, and 'updates'
                                    // which are also a list are updated elsewhere
                                    if (type === 'list') return undefined

                                    return {
                                        [type]: {
                                            fieldDefinitionId: id,
                                            value,
                                            previousValue: initialValue,
                                        },
                                    }
                                }
                            )
                        ),
                    },
                }),
            }

            update({
                variables: {
                    input: payload,
                },
                onCompleted,
                onQueryUpdated: query => query.refetch(),
                onError(error) {
                    logger.error('Failed to update action', error, {
                        id,
                        extendedByDefinitionId,
                    })

                    error.graphQLErrors.forEach(error => {
                        if (!hasCustomErrorExtenstions(error.extensions)) return

                        const errors: Record<string, string[]> = {}

                        Object.entries(error.extensions.errors.errors).forEach(
                            ([key, value]) => {
                                errors[key] = uniq(value)
                            }
                        )

                        setMutationErrors(errors)
                    })
                },
            })
        },
        [update]
    )

    const { status } = useMutationStatus(result)

    return {
        updateAction,
        mutationErrors,
        status,
    }
}
