import { css, flex, styled } from 'Adapters/Freestyled'

const cardsLargeStyles = css`
    > ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;
        padding: 2rem;
    }
`

const cardsSmallStyles = css`
    padding-bottom: 1rem;

    > ul {
        ${flex('column', 'flex-start', 'center')};
        gap: 1rem;
        padding: 0;

        li {
            max-width: none;
            width: 100%;
        }
    }
`

export const StyledCards = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) => ($isLarge ? cardsLargeStyles : cardsSmallStyles)};
    width: 100%;
`
