import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveActionViewMutationVariables = Types.Exact<{
  input: Types.SaveViewInput;
}>;


export type SaveActionViewMutation = { __typename?: 'Mutation', saveView: { __typename?: 'View', id: string, name: string, configuration: string } };

export type DeleteActionViewMutationVariables = Types.Exact<{
  input: Types.DeleteViewInput;
}>;


export type DeleteActionViewMutation = { __typename?: 'Mutation', deleteView: string };


export const SaveActionViewDocument = gql`
    mutation SaveActionView($input: SaveViewInput!) {
  saveView(input: $input) {
    id
    name
    configuration
  }
}
    `;
export type SaveActionViewMutationFn = Apollo.MutationFunction<SaveActionViewMutation, SaveActionViewMutationVariables>;

/**
 * __useSaveActionViewMutation__
 *
 * To run a mutation, you first call `useSaveActionViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActionViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActionViewMutation, { data, loading, error }] = useSaveActionViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveActionViewMutation(baseOptions?: Apollo.MutationHookOptions<SaveActionViewMutation, SaveActionViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveActionViewMutation, SaveActionViewMutationVariables>(SaveActionViewDocument, options);
      }
export type SaveActionViewMutationHookResult = ReturnType<typeof useSaveActionViewMutation>;
export type SaveActionViewMutationResult = Apollo.MutationResult<SaveActionViewMutation>;
export type SaveActionViewMutationOptions = Apollo.BaseMutationOptions<SaveActionViewMutation, SaveActionViewMutationVariables>;
export const DeleteActionViewDocument = gql`
    mutation DeleteActionView($input: DeleteViewInput!) {
  deleteView(input: $input)
}
    `;
export type DeleteActionViewMutationFn = Apollo.MutationFunction<DeleteActionViewMutation, DeleteActionViewMutationVariables>;

/**
 * __useDeleteActionViewMutation__
 *
 * To run a mutation, you first call `useDeleteActionViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionViewMutation, { data, loading, error }] = useDeleteActionViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActionViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionViewMutation, DeleteActionViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionViewMutation, DeleteActionViewMutationVariables>(DeleteActionViewDocument, options);
      }
export type DeleteActionViewMutationHookResult = ReturnType<typeof useDeleteActionViewMutation>;
export type DeleteActionViewMutationResult = Apollo.MutationResult<DeleteActionViewMutation>;
export type DeleteActionViewMutationOptions = Apollo.BaseMutationOptions<DeleteActionViewMutation, DeleteActionViewMutationVariables>;