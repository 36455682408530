export const teams = [
    'Human Resources',
    'Marketing',
    'Sales',
    'Research and development',
    'Finance',
    'IT',
    'Operations management',
    'Accounting',
    'Business administration',
    'Customer service',
    'Production',
    'Purchasing',
    'Accounts',
    'Design',
    'Management',
    'Procurement',
    'Quality',
    'Distribution',
    'Security',
]
