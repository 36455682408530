import { flex, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    done: number
    size: number
}

export const ProgressBar: FC<Props> = ({ done, size }) => {
    const progress = Math.min(Math.max(done / size, 0), 1) * 100

    return (
        <StyledContainer>
            <StyledProgressBarContainer>
                <StyledProgress $width={progress} />
            </StyledProgressBarContainer>

            <Text variant="regular-5" as={'span'}>
                {done} of {size}
            </Text>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;
    padding-bottom: 1rem;
`

const StyledProgressBarContainer = styled.div`
    flex: 1;
    height: 0.5rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    overflow: hidden;
    position: relative;
`

const StyledProgress = styled.div<{ $width: number }>`
    height: 100%;
    width: ${props => props.$width}%;
    background-color: ${({ theme }) => theme.palette.brand['01'].active};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.small};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.small};
    transition: width 0.2s ease-in-out;
`
