import { styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { FC } from 'react'

type Props = {
    options: { id: string; value: string }[]
    selectedValues: string[]
}

export const SelectedValues: FC<Props> = ({ selectedValues, options }) => {
    if (selectedValues.length)
        return (
            <StyledSelectedValues>
                {selectedValues.map((value, i) => (
                    <em key={value}>
                        <strong>
                            {options.find(option => option.id === value)
                                ?.value ?? ''}
                            {i < selectedValues.length - 1 ? ', ' : ''}
                        </strong>
                    </em>
                ))}{' '}
                selected.
            </StyledSelectedValues>
        )

    return null
}

const StyledSelectedValues = styled.p`
    ${regular5};
    margin-top: -1rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    text-align: center;
`
