import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CardSettingsControl } from 'Components/CardSettingsControl'
import { Dropdown } from 'Components/Dropdown'
import { FC, useState } from 'react'
import { DispatchAction, ViewConfig } from '../../types'

type Props = {
    config: ViewConfig
    dispatch: DispatchAction
}

const CardSettings: FC<Props> = ({ config: { cardSettings }, dispatch }) => {
    const [open, setOpen] = useState(false)
    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <IconTextButton
                    icon="Cards"
                    text="Card settings"
                    size="xSmall"
                />
            )}
            renderContent={() => (
                <Styled
                    fields={cardSettings.fields}
                    selected={cardSettings.selected}
                    onSelect={fieldId =>
                        dispatch({
                            type: 'ToggleFieldDisplay',
                            payload: { fieldId },
                        })
                    }
                    onDone={() => setOpen(false)}
                />
            )}
            align="start"
        />
    )
}

const Styled = styled(CardSettingsControl)`
    min-width: 20rem;
`

export { CardSettings }
