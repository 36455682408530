import { styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { FC } from 'react'

/*
 * A generic text cell
 */

type Props = {
    text: string
}

export const TextCell: FC<Props> = ({ text }) => {
    return <StyledTextCell>{text}</StyledTextCell>
}

const StyledTextCell = styled.td`
    ${regular5};
    padding: ${({ theme }) => theme.spacing['04']} 0;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    padding-left: 1.25rem;
    text-align: left;
    vertical-align: middle;
`
