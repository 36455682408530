import { Text } from 'Components/Text'
import { FC } from 'react'
import { LengthConstraint } from '__generated__'

export const Length: FC<LengthConstraint> = ({ min, max }) => (
    <>
        <Text as="p" variant="bold-4">
            Length
        </Text>
        <div>
            <Text as="p" variant="bold-4">
                Minimum
            </Text>
            {min ? (
                <Text as="p" variant="regular-4">
                    {min} characters
                </Text>
            ) : (
                <Text as="p" variant="regular-4" className="none-set">
                    No minimum set
                </Text>
            )}
            <Text as="p" variant="bold-4">
                Maximum
            </Text>
            {max ? (
                <Text as="p" variant="regular-4">
                    {max} characters
                </Text>
            ) : (
                <Text as="p" variant="regular-4" className="none-set">
                    No maximum set
                </Text>
            )}
        </div>
    </>
)
