import { styled } from 'Adapters/Freestyled'
import { Toggle } from 'Components/Input'
import { BizObjDef_Bool, BOField } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'

type Props = BOField<'BusinessObjectBooleanField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const BooleanField: FC<Props> = ({
    businessObjectId,
    booleanValue,
    fieldDefinition,
    onComplete,
}) => {
    const { ...common } = fieldDefinition as BizObjDef_Bool
    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(booleanValue)

    const [value, setValue] = useState(booleanValue)

    return (
        <Field id={common.id} name={common.name} errors={errors}>
            <Toggle
                name={common.type + '||' + common.id}
                id={common.id}
                checked={value}
                onCheckedChange={bool => {
                    setValue(bool)
                }}
            />

            <StyledEditBooleanFieldButton
                disabled={initialValue.current === value || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current,
                                value,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = value
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}

const StyledEditBooleanFieldButton = styled(EditFieldButton)`
    margin-left: auto;
`
