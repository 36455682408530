import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC, useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { Checkboxes } from './Checkboxes'
import { Radios } from './Radios'
import { SelectedValues } from './SelectedValues'
import { Selects } from './Selects'
import { useSelectConstraints } from './useSelectConstraints'

export const SelectField: FC<BODField<'SelectFieldDefinition'>> = ({
    selectConstraints,
    selectDefaultValue,
    selectOptions,
    ...common
}) => {
    const state = useCreateFormState()

    const { UIToRender, constraintsTooltip } = useSelectConstraints({
        selectConstraints,
    })

    const dispatch = useCreateFormStateDispatch()
    const [selections, setSelections] = useState<string[]>(
        selectDefaultValue ? selectDefaultValue.selectedIds : []
    )

    useEffect(() => {
        dispatch({
            type: 'updateSelectField',
            payload: { id: common.id, value: selections },
        })
    }, [selections, dispatch, common.id])

    return (
        <>
            <Field
                id={common.id}
                name={common.name}
                description={common.description ?? ''}
                constraintsTooltip={constraintsTooltip}
                errors={state[common.id].errors}
                required={selectConstraints.some(c => c.min && c.min > 0)}
            >
                {match(UIToRender)
                    .with('checkbox', () => (
                        <Checkboxes
                            options={selectOptions}
                            selections={selections}
                            setSelections={setSelections}
                        />
                    ))
                    .with('radio', () => (
                        <Radios
                            options={selectOptions}
                            selections={selections}
                            setSelections={setSelections}
                        />
                    ))
                    .with('select', () => (
                        <Selects
                            fieldId={common.id}
                            fieldName={common.name}
                            options={selectOptions}
                            selections={selections}
                            setSelections={setSelections}
                        />
                    ))
                    .exhaustive()}
            </Field>
            <SelectedValues
                options={selectOptions}
                selectedValues={selections}
            />
        </>
    )
}
