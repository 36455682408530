import { CenteredSpinner } from 'Components/Spinner'
import { BusinessObjectTable } from 'Features/BusinessObjectTable'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { useBusinessObjectFuzzySearch } from 'Features/FilterBusinessObject'
import { sortBos } from 'Features/SortBusinessObject/sortBos'
import { FC, useMemo } from 'react'
import { P, match } from 'ts-pattern'
import { useFilterBusinessObjects } from '../../../Hooks/useFilterBusinessObjects'
import { CardDisplay } from './CardDisplay'
import { CardGroupDisplay } from './CardDisplay/CardGroupDisplay'

type Props = {
    businessObjectDefinitionId: string
}

export const Display: FC<Props> = ({ businessObjectDefinitionId }) => {
    const {
        display,
        sort: [by, asc],
        grouping,
    } = useBusinessObjectsView()

    const { filteredBusinessObjects, hasInitiallyLoaded } =
        useFilterBusinessObjects(businessObjectDefinitionId)

    const sortedBusinessObjects = useMemo(
        () =>
            display === 'table'
                ? filteredBusinessObjects // Sorting handled within table
                : sortBos({ by, asc: asc === 'asc' })(filteredBusinessObjects),
        [asc, by, display, filteredBusinessObjects]
    )

    const { searchedBusinessObjects } = useBusinessObjectFuzzySearch(
        sortedBusinessObjects
    )

    return (
        <div>
            {match({ display, hasInitiallyLoaded, grouping })
                .with({ hasInitiallyLoaded: P.nullish }, () => (
                    <CenteredSpinner />
                ))
                .with({ display: 'table' }, () => (
                    <BusinessObjectTable
                        objs={searchedBusinessObjects}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                    />
                ))
                .with(
                    { display: 'card', grouping: P.not(P.nullish) },
                    ({ grouping }) => (
                        <CardGroupDisplay
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                            businessObjects={searchedBusinessObjects}
                            grouping={grouping}
                        />
                    )
                )
                .with({ display: 'card' }, () => (
                    <CardDisplay
                        businessObjects={searchedBusinessObjects}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                    />
                ))
                .exhaustive()}
        </div>
    )
}
