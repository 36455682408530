import { flex, styled } from 'Adapters/Freestyled'
import { FC, ReactNode } from 'react'

type Props = {
    content: ReactNode
}

export const TutorialTooltipContent: FC<Props> = ({ content }) => (
    <StyledTooltipContent>{content}</StyledTooltipContent>
)

const StyledTooltipContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;

    span a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }
`
