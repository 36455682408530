import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { Select } from 'Components/Select'
import { regular5 } from 'Components/Text'
import { DurationSelector } from 'Features/DurationSelector'
import { useModalControls } from 'Hooks'
import { FC, useMemo } from 'react'
import { FieldCommonWrapper } from '../Common'
import { DateRangeConstraint } from '../Constraints/DateRange'
import { RequiredConstraint } from '../Constraints/Required'
import { Field } from '../Field'
import { FieldProps } from '../types'
import { precisionOptions, validateDateDefault } from './helpers'
import { useDateDefault } from './useDateDefault'

export const DateFieldDefinitionInput: FC<FieldProps<'date'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    const defaultValidationErrors = useMemo(
        () => validateDateDefault(field),
        [field]
    )

    const [type, defaultValue] = useDateDefault(field.defaultValue)

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-precision-${index}`} name="Precision">
                <Select
                    id={`bod-field-precision-${index}`}
                    name={`bod-field-precision-${index}`}
                    options={precisionOptions}
                    value={field.precision ?? 'day'}
                    onValueChange={onChange('precision')}
                />
            </Field>

            <Field
                htmlFor={`bod-field-default-${index}`}
                name="Default value"
                errors={defaultValidationErrors}
            >
                <StyledDefault>
                    <IconTextButton
                        icon="Calendar"
                        text={
                            !!defaultValue && type === 'absolute'
                                ? dayjs(defaultValue).format('DD MMM YYYY')
                                : 'Add a specific date'
                        }
                        type="button"
                        onClick={openModal}
                        ref={buttonRef}
                        variant="secondary"
                        disabled={!!defaultValue && type === 'relative'}
                    />

                    <DurationSelector
                        trigger={
                            <IconTextButton
                                icon="Calendar"
                                text={
                                    !!defaultValue && type === 'relative'
                                        ? dayjs
                                              .duration(defaultValue)
                                              .humanize(true)
                                        : 'Add a relative default'
                                }
                                type="button"
                                ref={buttonRef}
                                variant="secondary"
                                disabled={!!defaultValue && type === 'absolute'}
                            />
                        }
                        handleConfirm={(value, unit) => {
                            if (!value || !unit) return
                            onChange('defaultValue')(() => ({
                                relative: dayjs
                                    .duration(value, unit)
                                    .toISOString(),
                            }))
                        }}
                    />

                    <IconTextButton
                        icon={'Close'}
                        title="Clear"
                        onClick={() => {
                            onChange('defaultValue')(() => undefined)
                        }}
                        text="Clear"
                        variant="ghost"
                        disabled={!field.defaultValue}
                    />

                    <DatePicker
                        open={open}
                        onOpenChange={setOpen}
                        title={`Choose a date`}
                        description={`Choose a date for ${field.name}`}
                        onConfirm={({ date }) => {
                            onChange('defaultValue')(() => ({
                                absolute: dayjs(date).toISOString(),
                            }))
                            closeModal()
                        }}
                        returnFocus={returnFocus}
                    />
                </StyledDefault>
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={
                        field.constraints.find(
                            constraint => constraint.required
                        ) ?? {}
                    }
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
            <DateRangeConstraint
                index={index}
                value={
                    field.constraints.find(
                        constraint => constraint.dateRange
                    ) ?? {}
                }
                onChange={dateRange =>
                    onChange('constraints')(() =>
                        dateRange ? [dateRange] : []
                    )
                }
            />
        </FieldCommonWrapper>
    )
}

const StyledDefault = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;

    > button {
        ${regular5};
        height: 2.75rem;
        padding: 0.625rem 0.875rem;
    }
`
