import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton, TextButton } from 'Components/Button'
import { Comparator, ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { NumberInput, TextInput } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { fromEvent, round } from 'Utils'
import { TimeUnit, convertToSeconds } from 'Utils/timeConversions'
import { FC, useState } from 'react'
import { secondsToUsefulUnit } from '../../../../../helpers'

type Props = {
    onSave: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    initialDescription: string | undefined
    initialDefaultDueSeconds: number | null | undefined
    initialConditions: Comparator[]
    businessObjectDefinitionId: string
    criteriaType:
        | 'action'
        | 'fieldCondition'
        | 'processFanout'
        | 'processDelegate'
}

export const EditCriterion: FC<Props> = ({
    initialDescription,
    initialDefaultDueSeconds,
    initialConditions,
    onSave,
    businessObjectDefinitionId,
    criteriaType,
}) => {
    const { open, setOpen, openModal, closeModal, buttonRef, returnFocus } =
        useModalControls()

    const [description, setDescription] = useState(() => initialDescription)

    const [dueTime, setDueTime] = useState<{
        number: string
        unit: TimeUnit
    }>(() => {
        if (!initialDefaultDueSeconds) return { number: '', unit: 'day' }

        const { number, unit } = secondsToUsefulUnit(initialDefaultDueSeconds)

        return { number: round(0.5)(number).toString(), unit }
    })

    const [comparators, setComparators] =
        useState<Comparator[]>(initialConditions)

    const {
        open: conditionsOpen,
        setOpen: setConditionsOpen,
        returnFocus: conditionsReturnFocus,
        buttonRef: conditionsButtonRef,
        openModal: openConditionsModal,
    } = useModalControls()

    return (
        <>
            <StyledEditButton
                iconName={'Edit'}
                ref={buttonRef}
                onClick={openModal}
            />

            <ControlledModal
                renderContent={() => (
                    <StyledModalContent>
                        {criteriaType === 'action' ? (
                            <div className="message">
                                <Text as="p" variant="regular-4">
                                    Updating the description on an action
                                    criterion is a breaking change.
                                </Text>

                                <Text as="p" variant="regular-4">
                                    It will not affect any actions that
                                    currently exist for this criterion, but all
                                    future actions will be generated with the
                                    new description.
                                </Text>
                            </div>
                        ) : null}

                        {criteriaType === 'action' ? (
                            <div className="input-group">
                                <label htmlFor="criterion-description">
                                    New description:
                                </label>

                                <TextInput
                                    id="criterion-description"
                                    value={description}
                                    onChange={e => setDescription(fromEvent(e))}
                                />
                            </div>
                        ) : null}

                        {criteriaType === 'action' ? (
                            <StyledDefaultDueTime>
                                <Text as="legend" variant="regular-4">
                                    New default due time:
                                </Text>

                                <NumberInput
                                    className="number-input"
                                    value={dueTime.number}
                                    onChange={e =>
                                        setDueTime(prev => ({
                                            ...prev,
                                            number: fromEvent(e),
                                        }))
                                    }
                                />

                                <Select
                                    id={'timeUnit'}
                                    onValueChange={unit =>
                                        setDueTime(prev => ({ ...prev, unit }))
                                    }
                                    name={'timeUnit'}
                                    options={[
                                        { text: 'hour(s)', value: 'hour' },
                                        { text: 'day(s)', value: 'day' },
                                        { text: 'week(s)', value: 'week' },
                                    ]}
                                    value={dueTime.unit}
                                />
                            </StyledDefaultDueTime>
                        ) : null}

                        <StyledComparatorBuilderButton
                            icon={'Plus'}
                            text={
                                !comparators.length
                                    ? 'Set conditions'
                                    : `${comparators.length} condition(s) set`
                            }
                            size="small"
                            variant="secondary"
                            onClick={openConditionsModal}
                            ref={conditionsButtonRef}
                        />

                        <ComparatorBuilderV2
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                            onConfirm={comparators => {
                                setComparators(comparators)
                            }}
                            title={'Change criterion conditions'}
                            open={conditionsOpen}
                            onOpenChange={setConditionsOpen}
                            returnFocus={conditionsReturnFocus}
                            initialComparators={comparators}
                        />

                        <div className="buttons">
                            <TextButton
                                text={'Cancel'}
                                onClick={closeModal}
                                size="small"
                                variant="secondary"
                            />

                            <TextButton
                                text={'Update'}
                                onClick={() => {
                                    const newDescription =
                                        description &&
                                        description !== initialDescription
                                            ? description
                                            : undefined

                                    const newDefaultDueTime = dueTime.number
                                        ? convertToSeconds(
                                              Number(dueTime.number),
                                              dueTime.unit
                                          )
                                        : undefined

                                    onSave(
                                        newDescription,
                                        newDefaultDueTime,
                                        comparators
                                    )

                                    closeModal()
                                }}
                                size="small"
                            />
                        </div>
                    </StyledModalContent>
                )}
                title={'Edit criterion'}
                description={'Edit criterion description and/or due time'}
                hideDescription
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledEditButton = styled(IconButton)`
    padding: 0.25rem;

    .icon svg {
        min-height: 1rem;
        min-width: 1rem;
        max-height: 1rem;
        max-width: 1rem;
    }
`

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;

    .message {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 1rem;
    }

    .input-group {
        ${flex('column', 'flex-start', 'flex-start')};
        width: 100%;

        label {
            padding-bottom: 0.25rem;
        }
    }

    .buttons {
        ${flex('row', 'flex-start', 'center')};
        width: 100%;
        gap: 1rem;

        > button {
            flex: 1;
        }
    }
`

const StyledDefaultDueTime = styled.fieldset`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
    border: none;
    padding: 0;
    width: 100%;

    legend {
        padding-bottom: 0.25rem;
    }

    .number-input {
        min-height: 38px;
        width: 50%;
    }

    .select-trigger {
        max-height: 42px;
    }
`

const StyledComparatorBuilderButton = styled(IconTextButton)`
    width: 100%;
`
