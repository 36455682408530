import { styled } from 'Adapters/Freestyled'

export const Styled = styled.span`
    display: block;

    svg {
        min-width: 1.5rem;
        min-height: 1.5rem;
        display: block;
    }

    path {
        fill: ${({ theme }) => theme.palette.icon.primary.normal};
    }
`
