import { styled } from 'Adapters/Freestyled'
import { Comparator } from 'Components/ComparatorBuilderV2'
import { FC, useCallback } from 'react'
import { useProcessEditor } from '../../useProcessEditor'
import { Criterion } from './Criterion'

type Props = {
    phaseIndex: number
}

const Criteria: FC<Props> = ({ phaseIndex }) => {
    const {
        state: { process },
        dispatch,
    } = useProcessEditor()

    const updateCriterion = useCallback(
        (criterionIndex: number) =>
            (
                description: string | undefined,
                defaultDueSeconds: number | undefined,
                conditions: Comparator[] | undefined
            ) => {
                if (description !== undefined) {
                    dispatch({
                        type: 'renameCriterion',
                        payload: {
                            phaseIndex,
                            criterionIndex,
                            newValue: description,
                        },
                    })
                }

                if (defaultDueSeconds !== undefined) {
                    dispatch({
                        type: 'updateCriterionDueTime',
                        payload: {
                            phaseIndex,
                            criterionIndex,
                            newValue: defaultDueSeconds,
                        },
                    })
                }

                if (conditions !== undefined) {
                    dispatch({
                        type: 'updateCriterionConditions',
                        payload: {
                            phaseIndex,
                            criterionIndex,
                            newValue: conditions,
                        },
                    })
                }
            },
        [dispatch, phaseIndex]
    )

    return (
        <Styled>
            {process.phases[phaseIndex].criteria.map((criterion, i) => (
                <Criterion
                    key={i}
                    criterion={criterion}
                    updateCriterion={updateCriterion(i)}
                    operatesUpon={process.operatesUpon[0]}
                />
            ))}
        </Styled>
    )
}

const Styled = styled.ul`
    padding: 0.25rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export { Criteria }
