import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

export type AuthParams = {
    returnTo?: string
    loginHint?: string
}

const defaultReturnTo = '/app'

export const useAuthenticate = () => {
    const { loginWithRedirect } = useAuth0()

    const login = useCallback(
        (options?: AuthParams) =>
            loginWithRedirect({
                appState: { returnTo: options?.returnTo ?? defaultReturnTo },
            }),
        [loginWithRedirect]
    )

    const signup = useCallback(
        (options?: AuthParams) =>
            loginWithRedirect({
                appState: { returnTo: options?.returnTo ?? defaultReturnTo },
                authorizationParams: {
                    screen_hint: 'signup',
                    login_hint: options?.loginHint,
                },
            }),
        [loginWithRedirect]
    )

    return { login, signup }
}
