import AudioIcon from './icons/file-audio.svg?react'
import ExcelIcon from './icons/file-excel.svg?react'
import GenericIcon from './icons/file-generic.svg?react'
import GoogleDocIcon from './icons/file-google-doc.svg?react'
import GoogleSheetIcon from './icons/file-google-sheet.svg?react'
import GoogleSlidesIcon from './icons/file-google-slides.svg?react'
import ImageIcon from './icons/file-image.svg?react'
import PDF from './icons/file-pdf.svg?react'
import PowerpointIcon from './icons/file-powerpoint.svg?react'
import VideoIcon from './icons/file-video.svg?react'
import WordIcon from './icons/file-word.svg?react'
import ZipIcon from './icons/file-zip.svg?react'

/*
 * A non-exhaustive list of file types and asscociated icons
 * Can be extended if necessary, but a generic file icon
 * covers exceptions.
 *
 * The uploadcare api returns a mime type
 */
const audioFileTypes = {
    'audio/mp3': {
        icon: AudioIcon,
        name: 'mp3',
    },
    'audio/aac': {
        icon: AudioIcon,
        name: 'aac',
    },
    'audio/ogg': {
        icon: AudioIcon,
        name: 'ogg',
    },
    'audio/flac': {
        icon: AudioIcon,
        name: 'flac',
    },
    'audio/alac': {
        icon: AudioIcon,
        name: 'alac',
    },
    'audio/wav:': {
        icon: AudioIcon,
        name: 'wav',
    },
    'audio/aiff': {
        icon: AudioIcon,
        name: 'aiff',
    },
    'audio/dsd:': {
        icon: AudioIcon,
        name: 'dsd',
    },
    'audio/pcm:': {
        icon: AudioIcon,
        name: 'pcm',
    },
} as const

const videoFileTypes = {
    'video/mp4': {
        icon: VideoIcon,
        name: 'mp4',
    },
    'video/mov': {
        icon: VideoIcon,
        name: 'mov',
    },
    'video/wmv': {
        icon: VideoIcon,
        name: 'wmv',
    },
    'video/avi': {
        icon: VideoIcon,
        name: 'avi',
    },
    'video/mkv': {
        icon: VideoIcon,
        name: 'mkv',
    },
    'video/webm': {
        icon: VideoIcon,
        name: 'webm',
    },
    'video/mpeg': {
        icon: VideoIcon,
        name: 'mpeg',
    },
    'video/quicktime': {
        icon: VideoIcon,
        name: 'quicktime',
    },
} as const

const sheetFileTypes = {
    'text/csv': {
        icon: ExcelIcon,
        name: 'csv',
    },
    'application/vnd.ms-excel': {
        icon: ExcelIcon,
        name: 'xls',
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        icon: ExcelIcon,
        name: 'xlsx',
    },
    'application/vnd.google-apps.spreadsheet': {
        icon: GoogleSheetIcon,
        name: 'gsheet',
    },
} as const

const slideshowFileTypes = {
    'application/vnd.ms-powerpoint': {
        icon: PowerpointIcon,
        name: 'ppt',
    },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        {
            icon: PowerpointIcon,
            name: 'pptx',
        },
    'application/vnd.google-apps.presentation': {
        icon: GoogleSlidesIcon,
        name: 'gslide',
    },
} as const

const documentFiletypes = {
    'text/plain': {
        icon: GenericIcon,
        name: 'txt',
    },
    'application/pdf': {
        icon: PDF,
        name: 'pdf',
    },
    'application/msword': {
        icon: WordIcon,
        name: 'doc',
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        icon: WordIcon,
        name: 'docx',
    },
    'application/vnd.google-apps.document': {
        icon: GoogleDocIcon,
        name: 'gdoc',
    },
} as const

const zipFileTypes = {
    'application/zip': {
        icon: ZipIcon,
        name: 'zip',
    },
    'application/x-7z-compressed': {
        icon: ZipIcon,
        name: '7z',
    },
    'application/vnd.rar': {
        icon: ZipIcon,
        name: 'rar',
    },
} as const

const imageFileTypes = {
    'image/png': {
        icon: ImageIcon,
        name: 'png',
    },
    'image/avif': {
        icon: ImageIcon,
        name: 'avif',
    },
    'image/bmp': {
        icon: ImageIcon,
        name: 'bmp',
    },
    'image/gif': {
        icon: ImageIcon,
        name: 'gif',
    },
    'vnd.microsoft.icon': {
        icon: ImageIcon,
        name: 'ico',
    },
    'image/jpeg': {
        icon: ImageIcon,
        name: 'jpeg',
    },
    'image/svg+xml': {
        icon: ImageIcon,
        name: 'svg',
    },
    'image/tiff': {
        icon: ImageIcon,
        name: 'tiff',
    },
    'image/webp': {
        icon: ImageIcon,
        name: 'webp',
    },
} as const

export const iconMap = {
    ...audioFileTypes,
    ...videoFileTypes,
    ...sheetFileTypes,
    ...slideshowFileTypes,
    ...documentFiletypes,
    ...zipFileTypes,
    ...imageFileTypes,
} as const

export type FileType = keyof typeof iconMap

export const isKnownFileType = (string: string): string is FileType =>
    !!Object.keys(iconMap).find(icon => string === icon)
