import * as Types from '../../../../../../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { Process_CompleteFragmentDoc } from '../../../../../../../../../../../../Fragments/__generated__/Process';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Editor_SaveProcessMutationVariables = Types.Exact<{
  input: Types.PatchProcessInput;
}>;


export type Editor_SaveProcessMutation = { __typename?: 'Mutation', patchProcess: { __typename?: 'Process', id: string, description?: string | null, name: string, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', name: string, description?: string | null, phaseIndex: number, criteria: Array<{ __typename?: 'ActionPhaseCriterion', description: string } | { __typename?: 'FieldConditionPhaseCriterion', comparator: { __typename: 'BusinessObjectComparator', negate: boolean, operator: Types.Operator, with: string, valueSelector: { __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'DeepSelector', type: Types.ValueSelectorType, selectors: Array<{ __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType }> } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType } } } | { __typename?: 'ProcessDelegatePhaseCriterion' } | { __typename?: 'ProcessFanoutPhaseCriterion' }> }> } };


export const Editor_SaveProcessDocument = gql`
    mutation Editor_SaveProcess($input: PatchProcessInput!) {
  patchProcess(input: $input) {
    ...Process_Complete
  }
}
    ${Process_CompleteFragmentDoc}`;
export type Editor_SaveProcessMutationFn = Apollo.MutationFunction<Editor_SaveProcessMutation, Editor_SaveProcessMutationVariables>;

/**
 * __useEditor_SaveProcessMutation__
 *
 * To run a mutation, you first call `useEditor_SaveProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditor_SaveProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editorSaveProcessMutation, { data, loading, error }] = useEditor_SaveProcessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditor_SaveProcessMutation(baseOptions?: Apollo.MutationHookOptions<Editor_SaveProcessMutation, Editor_SaveProcessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Editor_SaveProcessMutation, Editor_SaveProcessMutationVariables>(Editor_SaveProcessDocument, options);
      }
export type Editor_SaveProcessMutationHookResult = ReturnType<typeof useEditor_SaveProcessMutation>;
export type Editor_SaveProcessMutationResult = Apollo.MutationResult<Editor_SaveProcessMutation>;
export type Editor_SaveProcessMutationOptions = Apollo.BaseMutationOptions<Editor_SaveProcessMutation, Editor_SaveProcessMutationVariables>;