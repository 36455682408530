import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { Text } from 'Components/Text'
import { DurationSelector } from 'Features/DurationSelector'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { Field } from '../../types'
import { AbstractField } from '../AbstractField'
import { AbstractFieldChangeProps } from '../AbstractField/AbstractField'
import { useDefaultDate } from './useDefaultDate'

export const DateField: FC<
    Field<'DateFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: NonNullable<
                    PatchBusinessObjectDefinitionFieldDefaultInput['date']
                >
            ) => void
        }
> = ({
    dateConstraints,
    dateDefaultValue,
    handleDefaultChanged,
    ...common
}) => {
    const { openModal, buttonRef, open, setOpen, returnFocus, closeModal } =
        useModalControls()

    const {
        defaultValue: [type, defaultDate],
        defaultValueText,
    } = useDefaultDate(dateDefaultValue)

    return (
        <AbstractField
            {...common}
            defaultDisplay={
                common.isEditing ? (
                    <StyledDateControls>
                        <IconTextButton
                            icon="Calendar"
                            text={
                                type === 'absolute' && defaultDate
                                    ? dayjs(defaultDate).format('DD MMM YYYY')
                                    : 'Add a specific date'
                            }
                            type="button"
                            onClick={openModal}
                            ref={buttonRef}
                            variant="secondary"
                            disabled={!!defaultDate && type === 'relative'}
                        />

                        <DurationSelector
                            trigger={
                                <IconTextButton
                                    icon="Calendar"
                                    text={
                                        type === 'relative' && defaultDate
                                            ? dayjs
                                                  .duration(defaultDate)
                                                  .humanize(true)
                                            : 'Add a relative date'
                                    }
                                    type="button"
                                    ref={buttonRef}
                                    variant="secondary"
                                    disabled={
                                        !!defaultDate && type === 'absolute'
                                    }
                                />
                            }
                            handleConfirm={(value, unit) => {
                                if (!value || !unit) return
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    previousValue: defaultDate
                                        ? { relative: defaultDate }
                                        : null,
                                    nextValue: {
                                        relative: dayjs
                                            .duration(value, unit)
                                            .toISOString(),
                                    },
                                })
                            }}
                        />

                        <IconTextButton
                            icon={'Close'}
                            title="Clear"
                            onClick={() => {
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    previousValue: defaultDate
                                        ? { absolute: defaultDate }
                                        : null,
                                    nextValue: null,
                                })
                            }}
                            text="Clear"
                            variant="ghost"
                            disabled={!defaultDate}
                        />

                        <DatePicker
                            open={open}
                            onOpenChange={setOpen}
                            title={`Choose a date`}
                            description={`Choose a date for ${common.name}`}
                            onConfirm={({ date }) => {
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    previousValue: defaultDate
                                        ? { absolute: defaultDate }
                                        : null,
                                    nextValue: date
                                        ? { absolute: date.toISOString() }
                                        : null,
                                })
                                closeModal()
                            }}
                            returnFocus={returnFocus}
                        />
                    </StyledDateControls>
                ) : (
                    <Text
                        as="p"
                        variant="regular-4"
                        className={!dateDefaultValue ? 'none-set' : ''}
                    >
                        {defaultValueText}
                    </Text>
                )
            }
            constraints={dateConstraints}
        />
    )
}

const StyledDateControls = styled.div`
    ${flex('row', 'flex-start', 'center')};
    width: 100%;
    gap: 1rem;
`
