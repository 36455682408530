import { forwardRef } from 'react'
import { StyledTextButton } from './styles'
import { ButtonProps } from './types'

export const TextButton = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ text, variant = 'primary', size = 'xSmall', ...props }, ref) => {
        return (
            <StyledTextButton
                {...props}
                ref={ref}
                $variant={variant}
                $size={size}
            >
                <span>{text}</span>
            </StyledTextButton>
        )
    }
)
