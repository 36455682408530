import { Item, Root } from '@radix-ui/react-accordion'

import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FeatureName } from 'Features/FeatureName'
import { FieldType } from '__generated__'
import { noop } from 'lodash'
import { FC } from 'react'
import { Card } from '../Card'
import {
    StyledCards,
    StyledContent,
    StyledTrigger,
    StyledWhatWeWillBuild,
} from './styles'

type Props = {
    processes: {
        __typename?: 'TemplateProcess' | undefined
        name: string
        description?: string | null | undefined
    }[]
    datasets: {
        fields: {
            __typename?: 'FieldAddition' | undefined
            name: string
            type: FieldType
        }[]
        __typename?: 'TemplateDefinition' | undefined
        name: string
        description?: string | null | undefined
    }[]
    isLarge: boolean
}

export const WhatWeWillBuild: FC<Props> = ({
    processes,
    datasets,
    isLarge,
}) => {
    if (!processes.length && !datasets.length) return null

    return (
        <StyledWhatWeWillBuild>
            <Root type="single" collapsible>
                <Item value="1">
                    <StyledTrigger>
                        <Text as="span" variant="bold-2">
                            See what we'll build for you
                        </Text>

                        <Icon name="AngleDown" />
                    </StyledTrigger>

                    <StyledContent>
                        <div className="columns">
                            <Processes
                                processes={processes}
                                isLarge={isLarge}
                            />
                            <Datasets datasets={datasets} isLarge={isLarge} />
                        </div>
                    </StyledContent>
                </Item>
            </Root>
        </StyledWhatWeWillBuild>
    )
}

const Processes = ({ processes, isLarge }: Omit<Props, 'datasets'>) => {
    return (
        <div className="column">
            <Text as="h3" variant="bold-2">
                Workflows
            </Text>
            {processes.length ? (
                <>
                    <Text variant="regular-4" as="p">
                        <FeatureName>Workflows</FeatureName> get work done in a
                        repeatable and structured way.
                    </Text>

                    <StyledCards $isLarge={isLarge}>
                        <ul>
                            {processes.length
                                ? processes.map(process => (
                                      <Card
                                          key={process.name}
                                          title={process.name}
                                          onClick={noop}
                                          interactive={false}
                                      >
                                          <p>{process.description}</p>
                                      </Card>
                                  ))
                                : null}
                        </ul>
                    </StyledCards>
                </>
            ) : (
                <Text variant="regular-4" as="p" className="no-build">
                    <em>
                        No <FeatureName>processes</FeatureName> will be built
                        given the current selection.
                    </em>
                </Text>
            )}
        </div>
    )
}

const Datasets = ({ datasets, isLarge }: Omit<Props, 'processes'>) => {
    return (
        <div className="column">
            <Text as="h3" variant="bold-2">
                Datasets
            </Text>
            {datasets.length ? (
                <>
                    <Text variant="regular-4" as="p">
                        This is the data that powers your workspace.
                    </Text>

                    <StyledCards $isLarge={isLarge}>
                        <ul>
                            {datasets.length
                                ? datasets.map(dataset => (
                                      <Card
                                          key={dataset.name}
                                          title={dataset.name}
                                          onClick={noop}
                                          interactive={false}
                                      >
                                          <div>
                                              {dataset.fields.map(
                                                  ({ name, type }) => (
                                                      <Text
                                                          key={name}
                                                          variant="regular-5"
                                                          as="p"
                                                      >
                                                          {name} ({type})
                                                      </Text>
                                                  )
                                              )}
                                          </div>
                                      </Card>
                                  ))
                                : null}
                        </ul>
                    </StyledCards>
                </>
            ) : (
                <Text variant="regular-4" as="p" className="no-build">
                    No <FeatureName>datasets</FeatureName> will be built given
                    the current selection.
                </Text>
            )}
        </div>
    )
}
