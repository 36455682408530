import { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './GlobalStyles'
import { lightTheme } from './themes'

type Props = {
    children: React.ReactNode
}

export const StyleProvider: FC<Props> = ({ children }) => {
    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    )
}
