import { Select } from 'Components/Select'
import { Dispatch, FC, SetStateAction } from 'react'

type Props = {
    fieldId: string
    fieldName: string
    options: { id: string; value: string }[]
    selections: string[]
    setSelections: Dispatch<SetStateAction<string[]>>
}

export const Selects: FC<Props> = ({
    options,
    fieldName,
    selections,
    setSelections,
    fieldId,
}) => {
    return (
        <Select
            id={fieldId}
            value={selections[0]}
            onValueChange={val => {
                setSelections([val])
            }}
            name={fieldName}
            options={[
                {
                    disabled: false,
                    text: '-- select one or none --',
                    value: '',
                },
                ...options.map(option => ({
                    text: option.value,
                    value: option.id,
                    disabled: false,
                })),
            ]}
        />
    )
}
