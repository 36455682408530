import { styled } from 'Adapters/Freestyled'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { Spinner } from 'Components/Spinner'
import { BusinessObjectDef } from 'Features/BusinessObjectDef'
import { CreateBusinessObjectDef } from 'Features/CreateBusinessObjectDef/CreateBusinessObjectDef'
import { first } from 'lodash'
import { FC, useMemo } from 'react'
import { useActionExtensionObjectsQuery } from './__generated__/query'

const ActionFields: FC = () => {
    const { data, loading } = useActionExtensionObjectsQuery()
    const defs = useMemo(
        () => data?.businessObjectDefinitionExtensions || [],
        [data]
    )

    const extension = first(defs)

    return loading ? (
        <StyledLoading>
            <Spinner />
        </StyledLoading>
    ) : (
        <Styled>
            <VerticalScrollArea height="100%" width="100%">
                {extension ? (
                    <BusinessObjectDef
                        id={extension.id}
                        extensionOf="action"
                        displayOnly={false}
                    />
                ) : (
                    <CreateBusinessObjectDef extensionOf="action" />
                )}
            </VerticalScrollArea>
        </Styled>
    )
}

const Styled = styled.section`
    padding: 2rem;
    padding-top: 0;
`

const StyledLoading = styled.section`
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export { ActionFields }
