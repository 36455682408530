import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import { PrincipalFragmentDoc } from '../../Principal/__generated__/Principal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditDocumentMutationVariables = Types.Exact<{
  input: Types.EditFileInput;
}>;


export type EditDocumentMutation = { __typename?: 'Mutation', editFile: { __typename?: 'File', id: string, name: string, uploadedAt: string, sizeInBytes: number, fileType: string, uploadedBy: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } }, permissions: { __typename?: 'Permissions', delete: boolean } } };


export const EditDocumentDocument = gql`
    mutation EditDocument($input: EditFileInput!) {
  editFile(input: $input) {
    id
    name
    uploadedAt
    uploadedBy {
      ...Principal
    }
    sizeInBytes
    fileType
    permissions {
      delete
    }
  }
}
    ${PrincipalFragmentDoc}`;
export type EditDocumentMutationFn = Apollo.MutationFunction<EditDocumentMutation, EditDocumentMutationVariables>;

/**
 * __useEditDocumentMutation__
 *
 * To run a mutation, you first call `useEditDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDocumentMutation, { data, loading, error }] = useEditDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDocumentMutation(baseOptions?: Apollo.MutationHookOptions<EditDocumentMutation, EditDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDocumentMutation, EditDocumentMutationVariables>(EditDocumentDocument, options);
      }
export type EditDocumentMutationHookResult = ReturnType<typeof useEditDocumentMutation>;
export type EditDocumentMutationResult = Apollo.MutationResult<EditDocumentMutation>;
export type EditDocumentMutationOptions = Apollo.BaseMutationOptions<EditDocumentMutation, EditDocumentMutationVariables>;