import { dayjs } from 'Adapters/DayJS'
import { Column } from 'Components/GridTable'
import * as principal from 'Features/Principal'
import _, { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { ActionsTableItemFragment } from './__generated__/q'

type Cols = {
    [K in keyof Required<
        Pick<
            ActionsTableItemFragment,
            'name' | 'assignedTo' | 'dueDate' | 'description' | 'createdBy'
        >
    >]: Column
} & {
    completed: Column
    businessObject: Column
    process: Column
}

type Sort = {
    by: keyof Pick<Cols, 'name' | 'assignedTo' | 'dueDate'>
    asc: boolean
}
type SortActions = (
    sort: Sort
) => (actions: ActionsTableItemFragment[]) => ActionsTableItemFragment[]

const sortActions: SortActions = sort => actions =>
    orderBy(
        actions,
        [
            a =>
                match(sort.by)
                    .with('name', () => a.name)
                    .with(
                        'assignedTo',
                        () =>
                            a.assignedTo && principal.helpers.name(a.assignedTo)
                    )
                    .with('dueDate', () =>
                        a.dueDate ? dayjs(a.dueDate).unix() : 0
                    )
                    .exhaustive(),
        ],
        [sort.asc ? 'asc' : 'desc']
    )

const enabledExtensionColumns: NonNullable<
    ActionsTableItemFragment['extendedBy']
>['fields'][number]['__typename'][] = [
    'BusinessObjectSelectField',
    'BusinessObjectBooleanField',
    'BusinessObjectDocumentField',
    'BusinessObjectEmailField',
    'BusinessObjectNumberField',
    'BusinessObjectTelephoneField',
    'BusinessObjectTextField',
    'BusinessObjectUrlField',
]

const isColumnEnabled = (
    field: Exclude<
        ActionsTableItemFragment['extendedBy'],
        undefined | null
    >['fields'][number]
) => {
    if (enabledExtensionColumns.includes(field.__typename)) {
        return true
    }

    if (
        field.__typename === 'BusinessObjectListField' &&
        field.fieldDefinition.__typename === 'ListFieldDefinition' &&
        field.fieldDefinition.listOf.__typename === 'UpdatesFieldDefinition'
    ) {
        return true
    }

    return false
}

const extensionCols = (actions: ActionsTableItemFragment[]) => {
    return _(actions)
        .filter(action => !!action.extendedBy)
        .flatMap(action => action.extendedBy?.fields ?? [])
        .filter(isColumnEnabled)
        .uniqBy(field => field.fieldDefinition.id)
        .map(field => ({ header: { label: field.fieldDefinition.name } }))
        .keyBy(column => column.header.label)
        .valueOf()
}

const addExtensionColumns =
    (columns: Cols) =>
    (
        actions: ActionsTableItemFragment[]
    ): Cols & { [index: string]: Column } => ({
        ...columns,
        ...extensionCols(actions),
    })

const useCols = (actions: ActionsTableItemFragment[]) => {
    const [sort, setSort] = useState<Sort>({ by: 'name', asc: false })

    const sortedActions = useMemo(
        () => sortActions(sort)(actions),
        [actions, sort]
    )

    const sortableHeaderCell = ({
        by,
        label,
    }: {
        by: Sort['by']
        label: string
    }): Column['header'] =>
        match(sort)
            .with({ by, asc: true }, () => ({
                label,
                icon: 'AngleDown' as const,
                onClick: () => setSort({ by, asc: false }),
            }))
            .with({ by, asc: false }, () => ({
                label,
                icon: 'AngleUp' as const,
                onClick: () => setSort({ by, asc: true }),
            }))
            .otherwise(() => ({
                label,
                icon: 'AngleUp' as const,
                inactive: true,
                onClick: () => setSort({ by, asc: false }),
            }))

    const staticCols: Cols = {
        completed: {
            header: {
                label: 'Complete',
            },
        },
        name: {
            header: sortableHeaderCell({ by: 'name', label: 'Name' }),
        },
        assignedTo: {
            header: sortableHeaderCell({
                by: 'assignedTo',
                label: 'Assigned To',
            }),
        },
        dueDate: {
            header: sortableHeaderCell({ by: 'dueDate', label: 'Due Date' }),
        },
        description: {
            header: {
                label: 'Description',
            },
        },
        businessObject: {
            header: {
                label: 'Record',
            },
        },
        process: {
            header: {
                label: 'Workflow',
            },
        },
        createdBy: {
            header: {
                label: 'Created By',
            },
        },
    }

    return {
        columns: addExtensionColumns(staticCols)(sortedActions),
        sortedActions,
    }
}

export { useCols }
