import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Disconnect_ProviderMutationVariables = Types.Exact<{
  input: Types.DisconnectIntegrationInput;
}>;


export type Disconnect_ProviderMutation = { __typename?: 'Mutation', disconnectIntegration: boolean };


export const Disconnect_ProviderDocument = gql`
    mutation Disconnect_Provider($input: DisconnectIntegrationInput!) {
  disconnectIntegration(input: $input)
}
    `;
export type Disconnect_ProviderMutationFn = Apollo.MutationFunction<Disconnect_ProviderMutation, Disconnect_ProviderMutationVariables>;

/**
 * __useDisconnect_ProviderMutation__
 *
 * To run a mutation, you first call `useDisconnect_ProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnect_ProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectProviderMutation, { data, loading, error }] = useDisconnect_ProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnect_ProviderMutation(baseOptions?: Apollo.MutationHookOptions<Disconnect_ProviderMutation, Disconnect_ProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Disconnect_ProviderMutation, Disconnect_ProviderMutationVariables>(Disconnect_ProviderDocument, options);
      }
export type Disconnect_ProviderMutationHookResult = ReturnType<typeof useDisconnect_ProviderMutation>;
export type Disconnect_ProviderMutationResult = Apollo.MutationResult<Disconnect_ProviderMutation>;
export type Disconnect_ProviderMutationOptions = Apollo.BaseMutationOptions<Disconnect_ProviderMutation, Disconnect_ProviderMutationVariables>;