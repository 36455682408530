import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { useResponder } from 'Hooks'
import { noop } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { WelcomeSlideshow } from './WelcomeSlideshow'

export const WelcomeModal = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const isOpen = useMemo(
        () => searchParams.get('showWelcome') === 'true',
        [searchParams]
    )

    const handleClose = useCallback(() => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev)
            newParams.delete('showWelcome')
            newParams.delete('page')
            return newParams
        })
    }, [setSearchParams])

    const { isLarge } = useResponder()

    if (isLarge)
        return (
            <ControlledModal
                renderContent={() => (
                    <StyledSlideshowWrapperDesktop>
                        <WelcomeSlideshow
                            slides={[
                                'welcome',
                                'processes',
                                'businessObjects',
                                'actions',
                                'nextSteps',
                            ]}
                            clearWelcomeModal={handleClose}
                        />
                    </StyledSlideshowWrapperDesktop>
                )}
                title={'Welcome to Modlify'}
                hideDescription
                description={''}
                open={isOpen}
                onOpenChange={handleClose}
                returnFocus={noop}
            />
        )

    return (
        <MinimalModal open={isOpen} onOpenChange={handleClose}>
            <StyledSlideshowWrapperMobile>
                <WelcomeSlideshow
                    slides={[
                        'welcome',
                        'processes',
                        'businessObjects',
                        'actions',
                        'nextSteps',
                    ]}
                    clearWelcomeModal={handleClose}
                />
            </StyledSlideshowWrapperMobile>
        </MinimalModal>
    )
}

const StyledSlideshowWrapperDesktop = styled.div`
    height: 45rem;
    width: 80rem;
    max-width: 80vh;
    max-height: 80vh;
`

const StyledSlideshowWrapperMobile = styled.div`
    height: 80vh;
    width: 100%;
`
