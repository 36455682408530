import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { useOrganisationUsers } from 'Hooks'
import { FC } from 'react'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const UserField: FC<
    Field<'UserFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: Pick<
                    NonNullable<
                        PatchBusinessObjectDefinitionFieldDefaultInput['user']
                    >,
                    'fieldId' | 'nextValue'
                >
            ) => void
        }
> = ({
    userDefaultValue,
    userConstraints,
    handleDefaultChanged,
    ...common
}) => {
    const { users } = useOrganisationUsers()

    return (
        <AbstractField
            {...common}
            defaultDisplay={
                common.isEditing ? (
                    <Select
                        id={common.id}
                        value={userDefaultValue?.id ?? ''}
                        onValueChange={value =>
                            handleDefaultChanged({
                                fieldId: common.id,
                                nextValue: value ? value : undefined,
                            })
                        }
                        name="Default user"
                        options={[
                            {
                                text: '-- no default user --',
                                value: '',
                            },
                            ...users.map(user => ({
                                text: user.isCurrentUser ? 'Me' : user.name,
                                value: user.id,
                            })),
                        ]}
                        disabled={!users.length}
                    />
                ) : (
                    <Text
                        as="p"
                        variant="regular-4"
                        className={userDefaultValue ? '' : 'none-set'}
                    >
                        {userDefaultValue
                            ? userDefaultValue.name
                            : 'No default value set'}
                    </Text>
                )
            }
            constraints={userConstraints}
        />
    )
}
