import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessObjectHeaderQueryVariables = Types.Exact<{
  input: Types.GetBusinessObjectInput;
}>;


export type BusinessObjectHeaderQuery = { __typename?: 'Query', businessObject: { __typename?: 'BusinessObject', id: string, label: string, canDelete: boolean, definition: { __typename?: 'BusinessObjectDefinition', id: string, label: string } } };

export type UserIsSubscribedToBusinessObjectQueryVariables = Types.Exact<{
  input: Types.GetBusinessObjectInput;
}>;


export type UserIsSubscribedToBusinessObjectQuery = { __typename?: 'Query', businessObject: { __typename?: 'BusinessObject', id: string, subscribed: boolean } };

export type ToggleBusinessObjectSubscriptionMutationVariables = Types.Exact<{
  input: Types.ChangeSubscriptionInput;
}>;


export type ToggleBusinessObjectSubscriptionMutation = { __typename?: 'Mutation', changeBusinessObjectSubscription: boolean };

export type DeleteBusinessObjectMutationVariables = Types.Exact<{
  input: Types.DeleteBusinessObjectInput;
}>;


export type DeleteBusinessObjectMutation = { __typename?: 'Mutation', deleteBusinessObject: string };


export const BusinessObjectHeaderDocument = gql`
    query BusinessObjectHeader($input: GetBusinessObjectInput!) {
  businessObject(input: $input) {
    id
    label
    definition {
      id
      label
    }
    canDelete
  }
}
    `;

/**
 * __useBusinessObjectHeaderQuery__
 *
 * To run a query within a React component, call `useBusinessObjectHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessObjectHeaderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessObjectHeaderQuery(baseOptions: Apollo.QueryHookOptions<BusinessObjectHeaderQuery, BusinessObjectHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessObjectHeaderQuery, BusinessObjectHeaderQueryVariables>(BusinessObjectHeaderDocument, options);
      }
export function useBusinessObjectHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessObjectHeaderQuery, BusinessObjectHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessObjectHeaderQuery, BusinessObjectHeaderQueryVariables>(BusinessObjectHeaderDocument, options);
        }
export type BusinessObjectHeaderQueryHookResult = ReturnType<typeof useBusinessObjectHeaderQuery>;
export type BusinessObjectHeaderLazyQueryHookResult = ReturnType<typeof useBusinessObjectHeaderLazyQuery>;
export type BusinessObjectHeaderQueryResult = Apollo.QueryResult<BusinessObjectHeaderQuery, BusinessObjectHeaderQueryVariables>;
export const UserIsSubscribedToBusinessObjectDocument = gql`
    query UserIsSubscribedToBusinessObject($input: GetBusinessObjectInput!) {
  businessObject(input: $input) {
    id
    subscribed
  }
}
    `;

/**
 * __useUserIsSubscribedToBusinessObjectQuery__
 *
 * To run a query within a React component, call `useUserIsSubscribedToBusinessObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsSubscribedToBusinessObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsSubscribedToBusinessObjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserIsSubscribedToBusinessObjectQuery(baseOptions: Apollo.QueryHookOptions<UserIsSubscribedToBusinessObjectQuery, UserIsSubscribedToBusinessObjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIsSubscribedToBusinessObjectQuery, UserIsSubscribedToBusinessObjectQueryVariables>(UserIsSubscribedToBusinessObjectDocument, options);
      }
export function useUserIsSubscribedToBusinessObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIsSubscribedToBusinessObjectQuery, UserIsSubscribedToBusinessObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIsSubscribedToBusinessObjectQuery, UserIsSubscribedToBusinessObjectQueryVariables>(UserIsSubscribedToBusinessObjectDocument, options);
        }
export type UserIsSubscribedToBusinessObjectQueryHookResult = ReturnType<typeof useUserIsSubscribedToBusinessObjectQuery>;
export type UserIsSubscribedToBusinessObjectLazyQueryHookResult = ReturnType<typeof useUserIsSubscribedToBusinessObjectLazyQuery>;
export type UserIsSubscribedToBusinessObjectQueryResult = Apollo.QueryResult<UserIsSubscribedToBusinessObjectQuery, UserIsSubscribedToBusinessObjectQueryVariables>;
export const ToggleBusinessObjectSubscriptionDocument = gql`
    mutation ToggleBusinessObjectSubscription($input: ChangeSubscriptionInput!) {
  changeBusinessObjectSubscription(input: $input)
}
    `;
export type ToggleBusinessObjectSubscriptionMutationFn = Apollo.MutationFunction<ToggleBusinessObjectSubscriptionMutation, ToggleBusinessObjectSubscriptionMutationVariables>;

/**
 * __useToggleBusinessObjectSubscriptionMutation__
 *
 * To run a mutation, you first call `useToggleBusinessObjectSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBusinessObjectSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBusinessObjectSubscriptionMutation, { data, loading, error }] = useToggleBusinessObjectSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleBusinessObjectSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBusinessObjectSubscriptionMutation, ToggleBusinessObjectSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBusinessObjectSubscriptionMutation, ToggleBusinessObjectSubscriptionMutationVariables>(ToggleBusinessObjectSubscriptionDocument, options);
      }
export type ToggleBusinessObjectSubscriptionMutationHookResult = ReturnType<typeof useToggleBusinessObjectSubscriptionMutation>;
export type ToggleBusinessObjectSubscriptionMutationResult = Apollo.MutationResult<ToggleBusinessObjectSubscriptionMutation>;
export type ToggleBusinessObjectSubscriptionMutationOptions = Apollo.BaseMutationOptions<ToggleBusinessObjectSubscriptionMutation, ToggleBusinessObjectSubscriptionMutationVariables>;
export const DeleteBusinessObjectDocument = gql`
    mutation DeleteBusinessObject($input: DeleteBusinessObjectInput!) {
  deleteBusinessObject(input: $input)
}
    `;
export type DeleteBusinessObjectMutationFn = Apollo.MutationFunction<DeleteBusinessObjectMutation, DeleteBusinessObjectMutationVariables>;

/**
 * __useDeleteBusinessObjectMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessObjectMutation, { data, loading, error }] = useDeleteBusinessObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBusinessObjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessObjectMutation, DeleteBusinessObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessObjectMutation, DeleteBusinessObjectMutationVariables>(DeleteBusinessObjectDocument, options);
      }
export type DeleteBusinessObjectMutationHookResult = ReturnType<typeof useDeleteBusinessObjectMutation>;
export type DeleteBusinessObjectMutationResult = Apollo.MutationResult<DeleteBusinessObjectMutation>;
export type DeleteBusinessObjectMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessObjectMutation, DeleteBusinessObjectMutationVariables>;