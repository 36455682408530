import { RadioItem } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import { baseButtonStyles } from '../styles'

type Props = {
    text: string
    value: string
    isSelected: boolean
    onSelect?: (event: Event) => void
    disabled?: boolean
    closeOnSelect?: boolean
}

export const DropdownRadio: FC<Props> = ({
    text,
    value,
    onSelect,
    isSelected,
    disabled = false,
    closeOnSelect = true,
}) => {
    const { variant } = useColorVariant()

    return (
        <StyledDropdownRadio
            value={value}
            onSelect={e => {
                if (!closeOnSelect) e.preventDefault()
                onSelect?.(e)
            }}
            disabled={disabled}
            $variant={variant}
            $isSelected={isSelected}
        >
            <Icon name={isSelected ? 'CircleCheck' : 'Circle'} />

            <span>{text}</span>
        </StyledDropdownRadio>
    )
}

export const StyledDropdownRadio = styled(RadioItem)<{
    $variant: ColorVariant
    $isSelected: boolean
}>`
    ${baseButtonStyles};
    ${({ $variant, $isSelected }) => applyColorStyles($variant, $isSelected)};
    pointer-events: ${({ $isSelected }) => ($isSelected ? 'none' : 'all')};
`

const applyColorStyles = (variant: ColorVariant, isSelected: boolean) =>
    variant === 'light'
        ? css`
              background-color: ${({ theme }) => theme.palette.ui['01'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['01'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['01'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['01'].normal};
                  }
              }
          `
        : css`
              background-color: ${({ theme }) => theme.palette.ui['07'].normal};
              color: white;

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['07'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['07'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) =>
                          isSelected
                              ? theme.palette.icon['04'].normal
                              : theme.palette.icon['02'].normal};
                  }
              }
          `
