import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { regular5 } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { compact, isEqual } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../../../EditFieldButton'
import { useUpdateBusinessObjectField } from '../../../useUpdateBusinessObjectField'
import { ListValue } from '../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useDateConstraints } from './useDateConstraints'

type Props = BODField<'DateFieldDefinition'> & {
    businessObjectId: string
    listName: string
    listId: string
    listValue: ListValue
    onComplete: () => void
}

const generateInitialValues = (listValue: ListValue): string[] =>
    compact(
        listValue.map(field =>
            match(field)
                .with(
                    { __typename: 'BusinessObjectDateField' },
                    ({ dateValue }) => dateValue ?? ''
                )
                .otherwise(() => null)
        )
    )

export const DateListField: FC<Props> = ({
    businessObjectId,
    dateConstraints,
    listName,
    listId,
    listValue,
    onComplete,
}) => {
    const { constraints, constraintsTooltip } = useDateConstraints({
        dateConstraints,
    })

    const [initialValues, setInitialValues] = useState(
        generateInitialValues(listValue)
    )
    const [values, setValues] = useState(initialValues)

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const hasChanged = useMemo(() => {
        if (!isEqual(initialValues, values)) return true
        return false
    }, [values, initialValues])

    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
            list={values.map((date, i) => (
                <StyledListItem key={date + i}>
                    <span>{dayjs(date).format('DD MM YYYY')}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev =>
                                prev.filter(dateValue => dateValue !== date)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <StyledOpenDatePickerButton
                icon="Calendar"
                text={'Add a date'}
                onClick={e => {
                    e.preventDefault() // prevent from firing onSubmit
                    openModal()
                }}
                ref={buttonRef}
            />

            <DatePicker
                open={open}
                onOpenChange={setOpen}
                title={`Choose a date`}
                description={`Choose a date to add to the list`}
                onConfirm={({ date }) => {
                    setValues(prev => [...prev, date.toISOString()])
                    closeModal()
                }}
                returnFocus={returnFocus}
                limit={{
                    from: constraints.startAfter
                        ? new Date(constraints.startAfter)
                        : undefined,
                    to: constraints.endBefore
                        ? new Date(constraints.endBefore)
                        : undefined,
                }}
            />

            <EditFieldButton
                disabled={!hasChanged || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            list: {
                                fieldDefinitionId: listId,
                                previousValue: initialValues.map(value => ({
                                    text: value,
                                })),
                                value: values.map(value => ({
                                    text: value,
                                })),
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            setInitialValues(values)
                            onComplete()
                        },
                    })
                }}
            />
        </ListFieldWrapper>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > span {
        overflow-wrap: break-word;
        word-break: break-all;
    }

    > button {
        margin-left: auto;
    }
`

const StyledOpenDatePickerButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
