import { flex } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { fromEvent } from 'Utils'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { isNil } from 'lodash'
import { FC } from 'react'
import styled from 'styled-components'
import { useAvailableCurrencies } from '../../../Hooks/useAvailableCurrencies/useAvailableCurrencies'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const CurrencyField: FC<
    Field<'CurrencyFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: Pick<
                    NonNullable<
                        PatchBusinessObjectDefinitionFieldDefaultInput['currency']
                    >,
                    'fieldId' | 'nextValue'
                >
            ) => void
        }
> = ({
    currencyConstraints,
    currencyDefaultValue,
    handleDefaultChanged,
    ...common
}) => {
    const currencyOptions = useAvailableCurrencies()

    return (
        <AbstractField
            {...common}
            defaultDisplay={
                common.isEditing ? (
                    <StyledCurrencyInput>
                        <Select
                            id={common.id + 'currencyCode'}
                            value={currencyDefaultValue?.currencyCode ?? ''}
                            onValueChange={code => {
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    nextValue: {
                                        amount:
                                            currencyDefaultValue?.amount ??
                                            null,
                                        currencyCode: code ? code : null,
                                    },
                                })
                            }}
                            name={'Currency Code'}
                            options={[
                                {
                                    text: `-`,
                                    value: '',
                                },
                                ...currencyOptions.map(option => ({
                                    text: `${
                                        option.nativeSymbol
                                    } (${option.code.toUpperCase()})`,
                                    value: option.code,
                                })),
                            ]}
                        />

                        <NumberInput
                            value={currencyDefaultValue?.amount ?? ''}
                            id={common.id}
                            onChange={e => {
                                const value = fromEvent(e)
                                handleDefaultChanged({
                                    fieldId: common.id,
                                    nextValue: {
                                        amount: value ? Number(value) : null,
                                        currencyCode:
                                            currencyDefaultValue?.currencyCode ??
                                            null,
                                    },
                                })
                            }}
                            className="currency-value-input"
                        />
                    </StyledCurrencyInput>
                ) : (
                    <DefaultDisplay
                        currencyDefaultValue={currencyDefaultValue}
                    />
                )
            }
            constraints={currencyConstraints}
        />
    )
}

const DefaultDisplay: FC<{
    currencyDefaultValue: Field<'CurrencyFieldDefinition'>['currencyDefaultValue']
}> = ({ currencyDefaultValue }) => {
    return !isNil(currencyDefaultValue?.currencyCode) ||
        !isNil(currencyDefaultValue?.amount) ? (
        <StyledDefault>
            <Text as="span" variant="regular-4">
                {currencyDefaultValue.currencyCode}
            </Text>
            <Text as="span" variant="regular-4">
                {currencyDefaultValue.amount}
            </Text>
        </StyledDefault>
    ) : (
        <Text as="p" variant="regular-4" className="none-set">
            No default value set
        </Text>
    )
}

const StyledDefault = styled.p`
    display: flex;
    gap: 0.5rem;

    span {
        display: inline-block;
    }
`

const StyledCurrencyInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 12rem;
    }

    .currency-value-input {
        flex: 1;
        height: 2.75rem;
    }
`
