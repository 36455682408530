import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { fieldLabels } from 'Utils'
import { Field } from 'Utils/BusinessObjectDefinition'
import { FieldType } from '__generated__'
import { FC } from 'react'

type Props = { field: Field }

export const FieldTypeTag: FC<Props> = ({ field }) => {
    const label =
        field.type === FieldType.List
            ? `list: ${fieldLabels[field.type] ?? '[unset]'}`
            : fieldLabels[field.type]
    return (
        <StyledTypeTag>
            <Text as="p" variant="bold-6">
                {label}
            </Text>
        </StyledTypeTag>
    )
}

const StyledTypeTag = styled.div`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
`
