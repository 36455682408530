import { flex, styled } from 'Adapters/Freestyled'
import { AIPowered } from 'Components/AIPowered'
import { IconTextButton, TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { ControlledModal } from '../../../Components/Modal'
import { NameCriteriaTooltip } from '../../../Components/NameCriteriaTooltip'
import { useModalControls } from '../../../Hooks'
import { has } from '../../../Utils'
import { SaveProcess } from '../SaveProcess'
import { useProcessCreator_BusinessObjectsQuery } from '../__generated__/query'
import { useProcessCreator } from '../useProcessCreator'
import { BasicInformation } from './BasicInformation'
import { CopyExistingModal } from './CopyExistingModal'
import { InitFields } from './InitFields'
import { Phases } from './Phases'
import { SuggestionModal } from './SuggestionModal'

type Props = {
    mode: 'create' | 'edit'
}

const PhasesStep: FC<Props> = ({ mode }) => {
    const {
        state: { input, ...state },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const { data } = useProcessCreator_BusinessObjectsQuery({
        skip: isProcessFirst,
    })

    const suggestionModalProps = useModalControls()
    const copyExistingModalProps = useModalControls()

    return (
        <Styled>
            <header>
                <Text as="h3" variant="bold-2">
                    Workflow phases
                </Text>

                <NameCriteriaTooltip />

                {isProcessFirst ? null : (
                    <>
                        <TextButton
                            onClick={() => copyExistingModalProps.setOpen(true)}
                            text="Copy phases from existing workflow"
                        />

                        <div>
                            <TextButton
                                onClick={() =>
                                    suggestionModalProps.setOpen(true)
                                }
                                text="Suggest phases for me"
                            />

                            <AIPowered tooltip="Use AI to suggest a structure for your workflow based on the provided description" />
                        </div>
                    </>
                )}
            </header>

            <div>
                <BasicInformation data={data} />

                {isProcessFirst ? (
                    <InitFields
                        fields={state.fields.filter(
                            f =>
                                has(f.requiredBy, 'init') &&
                                f.requiredBy.init === true
                        )}
                    />
                ) : null}

                <Phases />
            </div>

            <footer>
                <IconTextButton
                    icon="AngleLeft"
                    text="Change details"
                    variant="secondary"
                    onClick={() => {
                        dispatch({ type: 'showDetailsStep' })
                    }}
                />
                <SaveProcess mode={mode} />
            </footer>

            <ControlledModal
                {...suggestionModalProps}
                onOpenChange={suggestionModalProps.setOpen}
                title="Suggest phases with AI"
                description=""
                hideDescription
                renderContent={() => (
                    <SuggestionModal
                        description={input.description ?? ''}
                        onSuggested={suggestionModalProps.closeModal}
                    />
                )}
            />

            <ControlledModal
                {...copyExistingModalProps}
                onOpenChange={copyExistingModalProps.setOpen}
                title="Copy phases from existing workflow"
                description=""
                hideDescription
                renderContent={() => (
                    <CopyExistingModal
                        onCopied={copyExistingModalProps.closeModal}
                        businessObjectDefinitionId={input.operatesUpon[0]}
                    />
                )}
            />
        </Styled>
    )
}

const Styled = styled.section`
    width: calc(100vw - 6rem);
    height: calc(100vh - 8rem);
    gap: 1.5rem;
    overflow: hidden;
    display: grid;
    grid-template: auto minmax(0, 1fr) auto / minmax(0, 1fr);

    > header {
        grid-row: 1 / 2;
        ${flex('row', 'flex-start', 'center')};
        gap: 1rem;

        > div {
            ${flex('row', 'flex-start', 'center')};
            gap: 1rem;
        }
    }

    > div {
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    > footer {
        grid-row: 3 / 4;
        display: flex;
        justify-content: space-between;
    }
`

export { PhasesStep }
