import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextAreaInput, TextInput } from 'Components/Input'
import { regular5 } from 'Components/Text'
import { uniq } from 'lodash'
import { FC, useEffect, useState } from 'react'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from '../../../../../Hooks/useCreateFormState'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useTextConstraints } from './useTextConstraints'

type Props = BODField<'TextFieldDefinition'> & {
    listName: string
    listId: string
}

export const TextListField: FC<Props> = ({
    textConstraints,
    textDefaultValue,
    type,
    listName,
    listId,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useTextConstraints({
        textConstraints,
    })

    const [currentValue, setCurrentValue] = useState(textDefaultValue ?? '')

    const [values, setValues] = useState(
        textDefaultValue ? [textDefaultValue] : []
    )

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: values.map(url => ({ url })),
            },
        })
    }, [values, dispatch, listId])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[listId].errors ?? []}
            list={values.map((url, i) => (
                <StyledListItem key={url + i}>
                    <span>{url}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev => prev.filter(link => link !== url))
                        }}
                    />
                </StyledListItem>
            ))}
        >
            {constraints.max && constraints.max <= 70 ? (
                <TextInput
                    name={type + '||' + listId}
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    id={listId}
                    placeholder="Type some text and press enter"
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            setValues(prev => uniq([...prev, currentValue]))
                            setCurrentValue('')
                        }
                    }}
                />
            ) : (
                <TextAreaInput
                    name={type + '||' + listId}
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    id={listId}
                    placeholder="Type some text and press enter"
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            setValues(prev => uniq([...prev, currentValue]))
                            setCurrentValue('')
                        }
                    }}
                />
            )}
        </ListFieldWrapper>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > span {
        overflow-wrap: break-word;
        word-break: break-all;
    }

    > button {
        margin-left: auto;
    }
`
