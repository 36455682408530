import { styled } from 'Adapters/Freestyled'
import { StatusButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { FC, useMemo, useRef, useState } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'
import {
    WorkspaceMembersFragment,
    useSendInviteMutation,
} from './__generated__/q'

const init = {
    email: '',
    valid: false,
    message: '',
}

type Props = WorkspaceMembersFragment & {
    onCompleted: () => void
}
const InviteMember: FC<Props> = ({ onCompleted, users, currentUser }) => {
    const { current: inputId } = useRef(v4())
    const [state, setState] = useState(init)
    const [send, res] = useSendInviteMutation()
    const { status, resetMutation } = useMutationStatus(res)
    const { open, setOpen, triggerToast } = useTriggerToast()

    const openInvites = useMemo(
        () =>
            currentUser.activeTenant?.tenant.openInvites.map(
                i => i.inviteeEmail
            ) ?? [],
        [currentUser]
    )

    return (
        <Styled
            onSubmit={e => {
                e.preventDefault()
                send({
                    variables: { input: { email: state.email } },
                    onCompleted,
                    onError: () => {
                        triggerToast()
                        resetMutation()
                    },
                })
            }}
        >
            <div>
                <Text as="label" variant="bold-5" htmlFor={inputId}>
                    Email
                </Text>
                <TextInput
                    id="inputId"
                    name="invite member input"
                    value={state.email}
                    disabled={['loading', 'success'].includes(status)}
                    onChange={({ currentTarget: { value } }) => {
                        match(value)
                            .with(P.nullish, () => setState(init))
                            .when(
                                v => !v.includes('@'),
                                email =>
                                    setState({
                                        email,
                                        valid: false,
                                        message: '',
                                    })
                            )
                            .when(
                                v =>
                                    openInvites.map(email => email).includes(v),
                                email =>
                                    setState({
                                        email,
                                        valid: false,
                                        message:
                                            'There is already an invite open for this email',
                                    })
                            )
                            .when(
                                v =>
                                    users.map(({ email }) => email).includes(v),
                                email =>
                                    setState({
                                        email,
                                        valid: false,
                                        message:
                                            'There is already a user registered with this email',
                                    })
                            )
                            .otherwise(email =>
                                setState({
                                    email,
                                    valid: true,
                                    message: '',
                                })
                            )
                    }}
                    placeholder="example@domain.com"
                />
            </div>
            {state.message && (
                <StyledMessage>
                    <Text as="p" variant="regular-6">
                        {state.message}
                    </Text>
                </StyledMessage>
            )}
            <footer>
                <StatusButton
                    type="submit"
                    disabled={
                        !state.valid || ['loading', 'success'].includes(status)
                    }
                    status={status}
                    text={{
                        ready: 'Send invite',
                        loading: 'Sending invite',
                        success: 'Invite sent',
                        error: 'Send invite',
                    }}
                />
            </footer>
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to send invite"
            />
        </Styled>
    )
}

const Styled = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 0.5rem;
`

const StyledMessage = styled.div`
    p {
        padding: 0.75rem 1rem;
        background-color: ${({ theme }) => theme.palette.support.bg04.normal};
        color: ${({ theme }) => theme.palette.text.support04.normal};
        border-radius: 0.25rem;
    }
`

export { InviteMember }
