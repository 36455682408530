import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { css, flex, styled } from 'Adapters/Freestyled'
import { ViewMode } from 'Components/DocumentPicker/types'
import { DocumentMetadata } from 'Components/Documents'
import { FC } from 'react'
import { DisplayDocument } from './DisplayDocument'

type Props = {
    viewMode: ViewMode
    documents: DocumentMetadata[]
    selections: Map<string, DocumentMetadata>
    toggleSelection: (document: DocumentMetadata) => void
}

export const Documents: FC<Props> = ({
    viewMode,
    documents,
    selections,
    toggleSelection,
}) => {
    return (
        <StyledDocuments $viewMode={viewMode}>
            <VisuallyHidden>
                <legend>Select documents from the list</legend>
            </VisuallyHidden>

            <ul>
                {documents.map(document => (
                    <DisplayDocument
                        key={document.id}
                        document={document}
                        viewMode={viewMode}
                        selected={selections.has(document.id)}
                        toggleSelection={toggleSelection}
                    />
                ))}
            </ul>
        </StyledDocuments>
    )
}

const StyledDocuments = styled.fieldset<{ $viewMode: ViewMode }>`
    overflow-y: auto;
    border: none;
    padding: 0;

    > ul {
        ${({ $viewMode }) => ($viewMode === 'list' ? listStyles : gridStyles)};
    }
`

const listStyles = css`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;
`

const gridStyles = css`
    ${flex('row', 'flex-start', 'center')};
    flex-wrap: wrap;
    gap: 0.75rem;
`
