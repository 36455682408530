import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { useFetchAllDocuments } from 'Components/Documents'
import { regular5 } from 'Components/Text'
import { AnchorProps, Upload } from 'Components/Upload'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useUserConstraints } from './useUserConstraints'

export const DocumentField: FC<BODField<'DocumentFieldDefinition'>> = ({
    docConstraints,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useUserConstraints({
        docConstraints,
    })

    const { documents } = useFetchAllDocuments()

    const value = state[common.id].value as string | undefined

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <Upload
                anchor={AddDocumentButton}
                anchorProps={{
                    text: value
                        ? documents.find(doc => doc.id === value)?.name ?? ''
                        : 'Add a document',
                }}
                onUploadCompleted={document => {
                    dispatch({
                        type: 'updateDocumentField',
                        payload: { id: common.id, value: document.id },
                    })
                }}
                existingSelections={{
                    selectMode: 'single',
                    allowExistingSelection: true,
                    existingSelections: value ? [value] : [],
                    onExistingDocumentSelected: documents => {
                        dispatch({
                            type: 'updateDocumentField',
                            payload: { id: common.id, value: documents[0].id },
                        })
                    },
                }}
            />

            {!!value ? (
                <IconTextButton
                    icon={'Close'}
                    text={'Clear'}
                    onClick={() => {
                        dispatch({
                            type: 'updateDocumentField',
                            payload: { id: common.id, value: undefined },
                        })
                    }}
                    variant="secondary"
                />
            ) : null}
        </Field>
    )
}

const AddDocumentButton: FC<AnchorProps<{ text: string }>> = ({
    buttonRef,
    onClick,
    text,
}) => (
    <StyledAddDocumentButton
        icon="Document"
        text={text}
        ref={buttonRef}
        onClick={onClick}
    />
)

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
