import { TextInput } from 'Components/Input'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC } from 'react'

import { BODField } from '../../../types'
import { Field } from '../Field'
import { useURLConstraints } from './useURLConstraints'

export const URLField: FC<BODField<'URLFieldDefinition'>> = ({
    urlConstraints,
    ...common
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useURLConstraints({
        urlConstraints,
    })

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <TextInput
                name={common.type + '||' + common.id}
                value={state[common.id].value as string}
                id={common.id}
                hasError={!!state[common.id].errors}
                onChange={e =>
                    dispatch({
                        type: 'updateURLField',
                        payload: { id: common.id, value: e.target.value },
                    })
                }
                required={constraints.required}
            />
        </Field>
    )
}
