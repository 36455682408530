import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useTriggerCreateModal = (openModal: () => void) => {
    const [searchParams] = useSearchParams()
    const hasOpened = useRef(false)

    useEffect(() => {
        const create = searchParams.get('create')
        if (!create || hasOpened.current) return
        openModal()
        hasOpened.current = true
    }, [searchParams, openModal])
}
