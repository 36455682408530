import { Arrow, Portal, Root, Trigger } from '@radix-ui/react-tooltip'
import { FC, ReactNode } from 'react'
import { StyledTooltipContent } from './styles'

type Props = {
    children: ReactNode
    content: ReactNode
    align?: 'center' | 'end' | 'start'
    side?: 'top' | 'right' | 'bottom' | 'left'
    offset?: number
    disabled?: boolean
}

export const Tooltip: FC<Props> = ({
    children,
    content,
    align = 'center',
    side = 'top',
    offset = 0,
    disabled = false,
}) => {
    if (disabled) return <>{children}</>

    return (
        <Root>
            <Trigger asChild>{children}</Trigger>

            <Portal>
                <StyledTooltipContent
                    side={side}
                    align={align}
                    sideOffset={offset}
                >
                    {content}
                    <Arrow width={10} height={5} />
                </StyledTooltipContent>
            </Portal>
        </Root>
    )
}
