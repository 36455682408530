import { Item } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { Icon, IconName } from 'Components/Icon'
import { FC } from 'react'
import { baseButtonStyles } from '../styles'

type Props = {
    text: string
    onSelect: (e: Event) => void
    disabled?: boolean
    closeOnSelect?: boolean
    icon?: IconName
    title?: string
    className?: string
    selected?: boolean
}

export const DropdownButton: FC<Props> = ({
    text,
    onSelect,
    closeOnSelect = true,
    disabled = false,
    icon,
    title,
    className = '',
    selected = false,
}) => {
    const { variant } = useColorVariant()

    return (
        <StyledItem
            className={className}
            $variant={variant}
            onSelect={e => {
                if (!closeOnSelect) e.preventDefault()
                onSelect(e)
            }}
            disabled={disabled}
            title={title}
            data-selected={selected}
        >
            {icon ? <Icon name={icon} /> : null}
            <span>{text}</span>
        </StyledItem>
    )
}

export const StyledItem = styled(Item)<{ $variant: ColorVariant }>`
    ${({ $variant }) => applyColorStyles($variant)};
    ${baseButtonStyles};
`

const applyColorStyles = (variant: ColorVariant) =>
    variant === 'light'
        ? css`
              background-color: ${({ theme }) => theme.palette.ui['01'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['01'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['01'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['03'].normal};
                  }
              }
          `
        : css`
              background-color: ${({ theme }) => theme.palette.ui['07'].normal};
              color: white;

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['07'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['07'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['02'].normal};
                  }
              }
          `
