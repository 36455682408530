import { IconTextButton } from 'Components/Button'
import { ContactUsToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { noop } from 'lodash'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { useDelete } from '../useDelete'

type Props = {
    id: string
    canDelete: boolean
    onDelete: () => void
}

const DeleteBusinessObject: FC<Props> = ({ id, canDelete, onDelete }) => {
    const { deleteBusinessObject, toastOpen, setToastOpen } = useDelete({
        id,
        onDelete,
    })

    return match(canDelete)
        .with(true, () => (
            <>
                <IconTextButton
                    text="Delete"
                    icon="Delete"
                    variant="secondary"
                    onClick={deleteBusinessObject}
                />
                <ContactUsToast
                    open={toastOpen}
                    onOpenChange={setToastOpen}
                    description={
                        'We were unable to delete the record. Contact us if the problem persists.'
                    }
                />
            </>
        ))
        .otherwise(() => (
            <Tooltip content="This object cannot be deleted.">
                <IconTextButton
                    text="Delete"
                    icon="Delete"
                    variant="secondary"
                    onClick={noop}
                    disabled
                />
            </Tooltip>
        ))
}

export { DeleteBusinessObject }
