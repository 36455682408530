import { Toggle } from 'Components/Input'
import { FieldCommonWrapper } from './Common'
import { Field } from './Field'
import { FieldProps } from './types'

export const BooleanFieldDefinitionInput: React.FC<FieldProps<'boolean'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
}) => {
    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-${index}-default`} name="Default value">
                <Toggle
                    name={'Default value'}
                    id={`bod-field-${index}-default`}
                    checked={field.defaultValue ?? false}
                    onCheckedChange={bool =>
                        onChange('defaultValue')(() => bool)
                    }
                />
            </Field>
        </FieldCommonWrapper>
    )
}
