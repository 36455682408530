import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { FC, useState } from 'react'
import { FieldDefinitions } from '../../types'
import { CardSettings } from './CardSettings'
import { Filters } from './Filters'
import { Grouping } from './Grouping'
import { Sorting } from './Sorting'

type Props = {
    businessObjectDefinitionId: string
    canFilter: boolean
    fieldDefinitions: FieldDefinitions
}

export const EditViewMenu: FC<Props> = ({
    businessObjectDefinitionId,
    canFilter,
    fieldDefinitions,
}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <IconTextButton
                icon="Filter"
                text="Edit view"
                onClick={() => setOpen(true)}
                size="xSmall"
            />

            <MinimalModal
                open={open}
                onOpenChange={setOpen}
                closeOnOverlayClick={false}
            >
                <VerticalScrollArea height="100%" width="100%">
                    <StyledOptions>
                        {canFilter ? (
                            <Filters
                                businessObjectDefinitionId={
                                    businessObjectDefinitionId
                                }
                            />
                        ) : null}

                        <Sorting fields={fieldDefinitions} />

                        <Grouping fields={fieldDefinitions} />

                        <CardSettings fields={fieldDefinitions} />
                    </StyledOptions>
                </VerticalScrollArea>
            </MinimalModal>
        </>
    )
}

const StyledOptions = styled.div`
    ${flex('column', 'flex-start', 'flex-start')}
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;

    button {
        width: 100%;
    }
`
