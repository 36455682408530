import { css, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { compact } from 'lodash'
import { FC, Fragment, useCallback, useState } from 'react'
import { P, match } from 'ts-pattern'
import { ValueSelectorType } from '../../__generated__'
import { CenteredSpinner } from '../Spinner'
import { ContactUsToast, useTriggerToast } from '../Toast'
import { BuildComparator } from './BuildComparator'
import { Comparator } from './types'
import { useBuildComparatorState } from './useBuildComparatorState'
import { useComparatorDisplay } from './useComparatorDisplay'
import { useFilterableFields } from './useFilterableFields'

type Props = {
    businessObjectDefinitionId: string
    initialComparators: Comparator[]
    onConfirm: (comparators: Comparator[]) => void
    onCancel: () => void
    isSmall?: boolean
    onClearAll?: () => void
    allowedFilterTypes: ValueSelectorType[]
}

export const Content: FC<Props> = ({
    businessObjectDefinitionId,
    initialComparators,
    onConfirm,
    isSmall = false,
    onClearAll,
    allowedFilterTypes,
}) => {
    const toast = useTriggerToast()

    const { loading, availableFields, availableDeepFields } =
        useFilterableFields({
            businessObjectDefinitionId,
            onError: toast.triggerToast,
            allowedFilterTypes,
        })

    const [showErrors, setShowErrors] = useState(false)

    const {
        comparators,
        handleAddBlankComparator,
        handleComparatorFieldIdChanged,
        handleComparatorRemoved,
        handleComparatorWithChanged,
        handleOperatorChanged,
    } = useBuildComparatorState({ initialComparators })

    const { displayComparators } = useComparatorDisplay({
        comparators,
        availableFields: availableFields ?? [],
        availableDeepFields: availableDeepFields ?? [],
    })

    const handleConfirm = useCallback(() => {
        const errors = displayComparators.filter(c => !c.validation.valid)

        if (errors.length) {
            setShowErrors(true)
            return
        }

        onConfirm(
            compact(
                displayComparators.map(c =>
                    c.validation.valid ? c.validation.comparator : undefined
                )
            )
        )
    }, [onConfirm, displayComparators])

    return (
        <StyledComparatorBuilder $isSmall={isSmall}>
            {match({ loading, availableFields, availableDeepFields })
                .with(
                    {
                        availableFields: P.not(P.nullish),
                        availableDeepFields: P.not(P.nullish),
                    },
                    ({ availableFields, availableDeepFields }) => (
                        <StyledComparatorList>
                            {displayComparators.map((comparator, index) => {
                                return (
                                    <Fragment key={index}>
                                        <BuildComparator
                                            index={index}
                                            availableFields={availableFields}
                                            availableDeepFields={
                                                availableDeepFields
                                            }
                                            comparator={comparator}
                                            handleComparatorFieldIdChanged={
                                                handleComparatorFieldIdChanged
                                            }
                                            handleComparatorRemoved={
                                                handleComparatorRemoved
                                            }
                                            handleComparatorWithChanged={
                                                handleComparatorWithChanged
                                            }
                                            handleOperatorChanged={
                                                handleOperatorChanged
                                            }
                                            showErrors={showErrors}
                                            isSmall={isSmall}
                                        />
                                        {displayComparators.length - 1 >
                                            index && (
                                            <StyledDivider>
                                                <div />
                                                <Text
                                                    as="p"
                                                    variant="regular-5"
                                                >
                                                    and
                                                </Text>
                                                <div />
                                            </StyledDivider>
                                        )}
                                    </Fragment>
                                )
                            })}
                        </StyledComparatorList>
                    )
                )
                .with({ loading: true }, () => <CenteredSpinner />)
                .otherwise(() => null)}

            <IconTextButton
                icon="Plus"
                text="Add new condition"
                size="small"
                onClick={handleAddBlankComparator}
            />

            {onClearAll ? (
                <TextButton
                    text="Clear all"
                    onClick={() => {
                        onConfirm([])
                        onClearAll()
                    }}
                    size="small"
                    variant="secondary"
                />
            ) : null}

            <TextButton text="Confirm" onClick={handleConfirm} size="small" />

            <ContactUsToast
                open={toast.open}
                onOpenChange={toast.setOpen}
                description="An error occurred fetching available fields"
            />
        </StyledComparatorBuilder>
    )
}

const StyledDivider = styled.div`
    ${flex('row', 'center', 'center')};
    gap: 0.5rem;

    div {
        flex: 1;
        height: 1px;
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        text-align: center;
        font-style: italic;
    }
`

const StyledComparatorList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`

const smallStyles = css`
    max-width: 100%;
    padding: 0.5rem 1rem;
`

const largeStyles = css`
    width: 100vw;
    max-width: 40rem;
`

const StyledComparatorBuilder = styled.div<{ $isSmall: boolean }>`
    ${({ $isSmall }) => ($isSmall ? smallStyles : largeStyles)};
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;
    overflow: auto;
    border: 0;

    > div {
        width: 100%;
    }

    > button {
        width: 100%;
    }
`
