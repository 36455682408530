import { styled } from 'Adapters/Freestyled'

export const Styled = styled.ul`
    display: inline-grid;
    grid-template: repeat(7, auto) / repeat(7, auto);
    height: calc(2.25rem * 8);

    [data-day='monday'] {
        grid-column: 1 / 2;
    }
    [data-day='tuesday'] {
        grid-column: 2 / 3;
    }
    [data-day='wednesday'] {
        grid-column: 3 / 4;
    }
    [data-day='thursday'] {
        grid-column: 4 / 5;
    }
    [data-day='friday'] {
        grid-column: 5 / 6;
    }
    [data-day='saturday'] {
        grid-column: 6 / 7;
    }
    [data-day='sunday'] {
        grid-column: 7 / 8;
    }
`

export const StyledWeekdayLabel = styled.span`
    display: block;
    font-size: ${({ theme }) => theme.typography.scale['02']};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    line-height: 1.25em;
    padding-bottom: 0.5rem;
`
