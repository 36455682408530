import * as Accordion from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import { match } from 'ts-pattern'

type Props = {
    usage: number
}

export const RestrictedMode: FC<Props> = ({ usage }) => {
    return (
        <Accordion.Root type="single" collapsible>
            <StyledItem value="1">
                <section>
                    <Text as="h3" variant="regular-3">
                        Restricted mode
                    </Text>

                    <Accordion.Trigger asChild>
                        <StyledTriggerButton iconName="AngleDown" />
                    </Accordion.Trigger>
                </section>

                <StyledContent>
                    <Text as="p" variant="regular-5">
                        Editing this workflow is restricted due to its usage
                        within your tenant.
                    </Text>

                    <Text as="p" variant="regular-5">
                        {usage} {pluralise(usage)} currently using this
                        workflow.
                    </Text>

                    <Text as="p" variant="regular-5">
                        The kind of edits you can make are limited to
                        non-breaking changes.
                    </Text>
                </StyledContent>
            </StyledItem>
        </Accordion.Root>
    )
}

const pluralise = (count: number): string =>
    match(count)
        .with(1, () => 'record is')
        .otherwise(() => 'records are')

const StyledItem = styled(Accordion.Item)`
    padding: 0.5rem;
    border-radius: 0.2rem;
    background-color: ${({ theme }) => theme.palette.ui['04'].normal};

    > section {
        ${flex('row', 'space-between', 'center')};
    }
`

const slideDown = keyframes`
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
`

const slideUp = keyframes`
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
`

const StyledContent = styled(Accordion.Content)`
    overflow: hidden;

    p:first-of-type {
        padding-top: 0.5rem;
    }

    p {
        padding-bottom: 0.75rem;
    }

    &[data-state='open'] {
        animation: ${slideDown} ${({ theme }) => theme.animation.mid} ease;
    }

    &[data-state='closed'] {
        animation: ${slideUp} ${({ theme }) => theme.animation.mid} ease;
    }
`

const StyledTriggerButton = styled(IconButton)`
    height: 1.5rem;
    width: 1.5rem;

    span {
        transition: transform
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &[data-state='open'] span {
        transform: rotateZ(180deg);
    }
`
