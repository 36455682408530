import { PopoverClose } from '@radix-ui/react-popover'
import { css, flex, styled } from 'Adapters/Freestyled'
import { bold5 } from 'Components/Text'
import React, { FC } from 'react'
import { Popover } from '../Popover/Popover'

type PopoverMenuOption = {
    children: React.ReactNode
    onClick?: () => void
    disabled?: boolean
}

type Props = {
    trigger: React.ReactNode
    options: PopoverMenuOption[]
}

export const PopoverMenu: FC<Props> = ({ trigger, options }) => {
    return (
        <Popover trigger={<StyledTrigger>{trigger}</StyledTrigger>}>
            {options.map(({ children, onClick }, index) => (
                <StyledPopoverButton key={index} onClick={onClick}>
                    {children}
                </StyledPopoverButton>
            ))}
        </Popover>
    )
}

const buttonCss = css`
    ${flex('row', 'flex-start', 'center')};
    ${bold5};
    padding: 0.75rem 1rem;

    width: 100%;
    position: relative;
    gap: 0.386rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui.tint['03'].hover};

    &:last-of-type {
        border-bottom: none;
    }

    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    > span {
        display: block;
        white-space: nowrap;
    }

    .icon {
        > svg {
            min-height: 1rem;
            min-width: 1rem;
            max-height: 1rem;
            max-width: 1rem;
        }
    }

    color: ${({ theme }) => theme.palette.text['01'].normal};
    background-color: 'transparent';

    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    /* HOVER */
    @media (hover: hover) {
        &:hover {
            border-radius: ${({ theme }) => theme.borderRadius.small};
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['03'].hover};
        }
    }

    /* ACTIVE */
    &:active {
        background-color: ${({ theme }) => theme.palette.ui.tint['03'].active};
    }

    /* FOCUS */
    &:focus-visible {
        background-color: ${({ theme }) => theme.palette.ui.tint['03'].active};
    }

    /* DISABLED */
    &:disabled {
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        color: ${({ theme }) => theme.palette.text['03'].normal};

        .icon path {
            fill: ${({ theme }) => theme.palette.text['03'].normal};
        }
    }
`

const StyledPopoverButton = styled(PopoverClose)`
    ${buttonCss};
`

const StyledTrigger = styled.div`
    ${flex('row', 'flex-start', 'center')};
    ${bold5};
    padding: 0.5rem;

    width: 100%;
    position: relative;
    gap: 0.386rem;
    border-radius: 0;
    border: none;
`
