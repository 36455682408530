import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import { State } from 'Features/CreateBusinessObjectDef/types'
import { FeatureName } from 'Features/FeatureName'
import { FieldType } from '__generated__'
import { FC } from 'react'
import { CreateBusinessObjectDefBasics } from '../CreateBusinessObjectDefBasics'
import { DataFields } from '../DataFields'

type Props = {
    extensionOf: 'action' | undefined
    state: State
    setState: React.Dispatch<React.SetStateAction<State>>
    setStateValue: <T extends 'description' | 'label' | 'name'>(
        key: T
    ) => (value: State[T]) => void
    addField: (type: FieldType) => () => void
    removeField: (index: number) => React.ReactEventHandler
    updatesEnabled: boolean
    setUpdatesEnabled: React.Dispatch<React.SetStateAction<boolean>>
    errors: string[]
    documentsEnabled: boolean
    setDocumentsEnabled: React.Dispatch<React.SetStateAction<boolean>>
    errorToast: {
        open: boolean
        setOpen: React.Dispatch<React.SetStateAction<boolean>>
        triggerToast: () => void
    }
}

export const Editor: FC<Props> = ({
    state,
    setState,
    errorToast,
    addField,
    documentsEnabled,
    errors,
    removeField,
    setDocumentsEnabled,
    setStateValue,
    setUpdatesEnabled,
    updatesEnabled,
    extensionOf,
}) => {
    return (
        <StyledEditior>
            {extensionOf ? (
                <div className="intro">
                    <Text as="p" variant="regular-4">
                        <FeatureName>Actions</FeatureName> have a predefined set
                        of fields by default. However these can be extended to
                        contain more data points to suit your needs.
                    </Text>
                </div>
            ) : null}

            <CreateBusinessObjectDefBasics
                setState={setStateValue}
                state={state}
                updatesEnabled={updatesEnabled}
                setUpdatesEnabled={setUpdatesEnabled}
                documentsEnabled={documentsEnabled}
                setDocumentsEnabled={setDocumentsEnabled}
                extensionOf={extensionOf}
            />

            <DataFields
                fields={state.fields}
                addField={addField}
                removeField={removeField}
                errors={errors}
                setState={setState}
            />

            {extensionOf ? (
                <StyledSaveButton
                    type="submit"
                    variant="primary"
                    size="small"
                    text="Save"
                />
            ) : null}

            <SimpleToast
                open={errorToast.open}
                onOpenChange={errorToast.setOpen}
                description="Could not save your record"
            />
        </StyledEditior>
    )
}

export const StyledEditior = styled.section`
    .intro {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.25rem;
        padding: 1rem 0;

        a {
            color: ${({ theme }) => theme.palette.text.support03.normal};
            text-decoration: underline;
        }
    }

    > button {
        width: 100%;

        &:first-of-type {
            margin-bottom: 2rem;
        }
    }
`

const StyledSaveButton = styled(TextButton)`
    margin-top: 3rem;
    max-width: 17rem;
    margin-left: auto;
`
