import { css, flex, styled } from 'Adapters/Freestyled'

const draggingStyles = css`
    opacity: 0.5;
`

const draggablePointer = css`
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
`

const mostRecentStyles = css`
    outline: 2px solid ${({ theme }) => theme.palette.brand['01'].active};
`

export const StyledCard = styled.div<{
    $dragging?: boolean
    $draggable?: boolean
    $mostRecent?: boolean
}>`
    ${flex('column', 'flex-start', 'flex-start')}
    background: ${({ theme }) => theme.palette.ui['01'].normal};
    border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    overflow: hidden;
    transition: opacity
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    ${({ $dragging }) => $dragging && draggingStyles}
    ${({ $draggable }) => $draggable && draggablePointer}
    ${({ $mostRecent }) => $mostRecent && mostRecentStyles}

    &[data-loading='true'] {
        opacity: 0.5;
    }

    &[data-completed='true'] {
        border-color: ${({ theme }) => theme.palette.support['02'].normal};
    }

    > header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    > header h4 {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    }

    > div {
        width: 100%;
        padding: 0 0.5rem 0.5rem;
    }

    > div > button {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 0.25rem;
    }

    footer {
        width: 100%;
        margin-top: auto;
    }
`
