import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { fieldLabels } from 'Utils'
import { FieldType, ListField } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { BooleanFieldContent } from './BooleanFieldContent'
import { CurrencyFieldContent } from './CurrencyFieldContent'
import { DateFieldContent } from './DateFieldContent'
import { EmailFieldContent } from './EmailFieldContent'
import { NumberFieldContent } from './NumberFieldContent'
import { RelationFieldContent } from './RelationFieldContent'
import { SelectFieldContent } from './SelectFieldContent'
import { TelephoneFieldContent } from './TelephoneFieldContent'
import { TextFieldContent } from './TextFieldContent'
import { UrlFieldContent } from './UrlFieldContent'
import { UserFieldContent } from './UserFieldContent'

type Props = {
    field: ListField
}

const ListFieldContent: FC<Props> = ({ field: { listOf } }) => (
    <Styled>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    List of:
                </Text>
                <Text as="p" variant="regular-5">
                    {fieldLabels[listOf.type]}
                </Text>
            </div>
        </StyledConstraint>
        {match(listOf)
            .with({ type: FieldType.Boolean }, f => (
                <BooleanFieldContent field={f} />
            ))
            .with({ type: FieldType.Currency }, f => (
                <CurrencyFieldContent field={f} />
            ))
            .with({ type: FieldType.Date }, f => <DateFieldContent field={f} />)
            .with({ type: FieldType.Document }, () => null)
            .with({ type: FieldType.Email }, f => (
                <EmailFieldContent field={f} />
            ))
            .with({ type: FieldType.Number }, f => (
                <NumberFieldContent field={f} />
            ))
            .with({ type: FieldType.Relation }, f => (
                <RelationFieldContent field={f} />
            ))
            .with({ type: FieldType.Select }, f => (
                <SelectFieldContent field={f} />
            ))
            .with({ type: FieldType.Telephone }, f => (
                <TelephoneFieldContent field={f} />
            ))
            .with({ type: FieldType.Text }, f => <TextFieldContent field={f} />)
            .with({ type: FieldType.Url }, f => <UrlFieldContent field={f} />)
            .with({ type: FieldType.User }, f => <UserFieldContent field={f} />)
            .exhaustive()}
    </Styled>
)

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledConstraint = styled.div`
    > p {
        padding-bottom: 0.5rem;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    div p:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { ListFieldContent }
