import { flex, styled } from 'Adapters/Freestyled'

export const StyledSortButton = styled.button`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.spacing['04']} 0;
    padding-left: ${({ theme }) => theme.spacing['04']};
    text-align: left;
    transition: 0.1s;
    border-radius: ${({ theme }) => theme.spacing['02']};

    svg {
        transform: scale(0.66);

        path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    .invisible-icon {
        opacity: 0;
    }

    /* Hover  */
    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    /* Active */
    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
        color: ${({ theme }) => theme.palette.text['02'].active};
    }

    /* Focus */
    &:focus-visible {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        color: ${({ theme }) => theme.palette.text['02'].hover};
        box-shadow: inset 0 0 0 ${({ theme }) => theme.spacing['01']}
            ${({ theme }) => theme.palette.brand['01'].normal};
    }
`
