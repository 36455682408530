import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { CenteredSpinner } from 'Components/Spinner'
import { ActionCard } from 'Features/ActionView'
import { noop } from 'lodash'
import { FC } from 'react'

type Props = {
    actionIds: string[] | undefined
    onClose: () => void
}

export const ActionCompletionModal: FC<Props> = ({ actionIds, onClose }) => (
    <ControlledModal
        open={!!actionIds}
        onOpenChange={open => !open && onClose()}
        title={'Incomplete actions'}
        description="The following actions need completion in order to progress"
        renderContent={() => (
            <Styled>
                {actionIds ? (
                    actionIds.map(id => (
                        <ActionCard id={id} key={id} hideMenu />
                    ))
                ) : (
                    <CenteredSpinner />
                )}
            </Styled>
        )}
        returnFocus={noop}
    />
)

const Styled = styled.div`
    padding-top: 1rem;
    width: 30rem;
    max-width: 90vw;
    max-height: 80vh;
    overflow: auto;

    > div {
        margin-bottom: 1rem;
    }
`
