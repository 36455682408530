import { keyframes } from 'styled-components'

export const DURATION = '300ms'

export const enter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const exit = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`
