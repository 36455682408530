import Logo from 'Assets/Logo.svg?react'
import { FC, ReactNode } from 'react'
import { Styled } from './styles'

type Props = {
    children: ReactNode
}

export const Layout: FC<Props> = ({ children }) => {
    return (
        <Styled $isLarge={true}>
            <header>
                <Logo />
            </header>

            <div>{children}</div>

            <div className="background" />
        </Styled>
    )
}
