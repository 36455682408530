import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { CenteredSpinner } from 'Components/Spinner'
import { noop } from 'lodash'
import { FC } from 'react'
import { Container } from './Container'

type Props = {
    processId: string
    boId: string | undefined
    onClose: () => void
    includeFields?: string[]
    onFieldUpdated?: (fieldId: string) => void
}

const FieldCompletionModal: FC<Props> = ({
    processId,
    boId,
    onClose,
    includeFields,
    onFieldUpdated,
}) => (
    <ControlledModal
        open={!!boId}
        onOpenChange={open => !open && onClose()}
        title={'Incomplete fields'}
        description="The following fields are required in order to progress"
        renderContent={() => (
            <Styled>
                {boId ? (
                    <Container
                        processId={processId}
                        id={boId}
                        onClose={onClose}
                        includeFields={includeFields}
                        onFieldUpdated={onFieldUpdated}
                    />
                ) : (
                    <CenteredSpinner />
                )}
            </Styled>
        )}
        returnFocus={noop}
    />
)

const Styled = styled.div`
    padding-top: 1rem;
    min-width: 30rem;
    max-width: 90vw;
`

export { FieldCompletionModal }
