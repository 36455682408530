import { flex, styled } from 'Adapters/Freestyled'
import { AIPowered } from 'Components/AIPowered'
import { IconTextButton } from 'Components/Button'
import { TextAreaInput } from 'Components/Input'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { GettingStartedSection } from './Section'

type Props = {
    prompt: string
    onPromptChange: (prompt: string) => void
    onPromptSubmit: () => void
    loadingSuggestion: boolean
    restart: () => void
}

// This must be generated should this component ever be made reusable
const fieldId = 'bo-definition-creator-suggestion-prompt'

const Generate: FC<Props> = ({
    prompt,
    onPromptChange,
    onPromptSubmit,
    loadingSuggestion,
    restart,
}) => (
    <Styled
        heading="Generate from AI"
        description="Use AI to generate a dataset based on the description you provide."
    >
        <StyledAITag />
        <div>
            <Text as="label" variant="bold-5" htmlFor={fieldId}>
                Prompt
            </Text>
            <StyledTextArea
                id={fieldId}
                value={prompt}
                onChange={e => onPromptChange(e.currentTarget.value)}
                placeholder="This data set will contain..."
                disabled={loadingSuggestion}
            />
        </div>
        <footer>
            <IconTextButton
                icon="ArrowLeft"
                text="Go back"
                onClick={restart}
                variant="secondary"
            />
            {loadingSuggestion ? (
                <CenteredSpinner />
            ) : (
                <IconTextButton
                    icon="Cube"
                    text="Generate"
                    onClick={onPromptSubmit}
                    variant="primary"
                    disabled={!prompt}
                />
            )}
        </footer>
    </Styled>
)

const Styled = styled(GettingStartedSection)`
    > div {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > footer {
        ${flex('row', 'flex-end', 'center')};
        padding: 0 2rem 2rem 2rem;
        width: 100%;
        gap: 1rem;
    }
`

const StyledAITag = styled(AIPowered)`
    position: absolute;
    top: 0rem;
    right: 0rem;
    border-radius: 0 0.5rem 0 0.25rem;
`

const StyledTextArea = styled(TextAreaInput)`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
`

export { Generate }
