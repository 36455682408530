import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'

type Props = {
    heading: string
    description: string
    children: ReactNode
    className?: string
}

const DetailsSection: FC<Props> = ({
    heading,
    description,
    children,
    className = '',
}) => (
    <Styled className={className}>
        <header>
            <Text as="h3" variant="bold-2">
                {heading}
            </Text>
            <Text as="p" variant="regular-5">
                {description}
            </Text>
        </header>
        {children}
    </Styled>
)

const Styled = styled.section`
    overflow: hidden;

    > header {
        padding: 2rem;
    }

    > header h3 {
        padding-bottom: 0.75rem;
    }

    > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        max-width: 35rem;
    }
`

export { DetailsSection }
