import { styled } from 'Adapters/Freestyled'
import { useWebhookPortalQuery } from './__generated__/q'

export const OutgoingWebhooks = () => {
    const { data } = useWebhookPortalQuery()

    if (!data?.webhookPortal.url) {
        return null
    }

    return (
        <StyledSection>
            <StyledIFrame
                src={data.webhookPortal.url}
                allow="clipboard-write"
                loading="lazy"
                title="Outgoing Webhooks Portal"
            ></StyledIFrame>
        </StyledSection>
    )
}

const StyledSection = styled.section`
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const StyledIFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`
