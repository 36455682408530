import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserTrackingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserTrackingQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, email: string } };


export const UserTrackingDocument = gql`
    query UserTracking {
  currentUser {
    id
    email
  }
}
    `;

/**
 * __useUserTrackingQuery__
 *
 * To run a query within a React component, call `useUserTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTrackingQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTrackingQuery(baseOptions?: Apollo.QueryHookOptions<UserTrackingQuery, UserTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTrackingQuery, UserTrackingQueryVariables>(UserTrackingDocument, options);
      }
export function useUserTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTrackingQuery, UserTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTrackingQuery, UserTrackingQueryVariables>(UserTrackingDocument, options);
        }
export type UserTrackingQueryHookResult = ReturnType<typeof useUserTrackingQuery>;
export type UserTrackingLazyQueryHookResult = ReturnType<typeof useUserTrackingLazyQuery>;
export type UserTrackingQueryResult = Apollo.QueryResult<UserTrackingQuery, UserTrackingQueryVariables>;