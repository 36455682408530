import { styled } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { Text } from 'Components/Text'
import { useGroupActions } from 'Root/App/Main/Actions/Hooks/useGroupActions'
import { FC } from 'react'
import { Action } from '../types'
import { Group } from './Group'

type Props = {
    actions: Action[]
    grouping: Grouping
}

export const GroupedActionsList: FC<Props> = ({ actions, grouping }) => {
    const groups = useGroupActions(actions, grouping)

    return (
        <StyledActionsList>
            {Object.keys(groups).length ? (
                <>
                    <Text variant="bold-3" as="h2">
                        {
                            grouping.fields.find(
                                field => field.id === grouping.selection
                            )?.label
                        }
                    </Text>

                    <div className="groups">
                        {Object.entries(groups).map(([entityId, group]) => (
                            <Group
                                fieldId={grouping.selection}
                                group={group}
                                entityId={entityId}
                                key={entityId}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Text variant="regular-4" as="p">
                    <em>
                        No <strong>actions</strong> to show.
                    </em>
                </Text>
            )}
        </StyledActionsList>
    )
}

const StyledActionsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
`
