import { IconButton } from 'Components/Button/IconButton'
import { Icon } from 'Components/Icon'
import { FC, useRef } from 'react'
import { StyledSearchBar } from './styles'
import { Props } from './types'

export const SearchBar: FC<Props> = ({
    searchTerms,
    updateSearchTerms,
    clearSearchTerms,
    placeholderText,
    className = '',
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleFocusInput = () => {
        inputRef.current?.focus()
    }

    return (
        <StyledSearchBar onClick={handleFocusInput} className={className}>
            <Icon name="Search" className="search-icon" />

            <input
                {...props}
                type="text"
                placeholder={placeholderText}
                value={searchTerms}
                onChange={updateSearchTerms}
                ref={inputRef}
                name="search-bar"
            />

            {searchTerms ? (
                <IconButton
                    iconName={'Close'}
                    className="clear-icon-button"
                    onClick={clearSearchTerms}
                    aria-label="Clear search terms"
                />
            ) : null}
        </StyledSearchBar>
    )
}
