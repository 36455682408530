import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { ActionFields } from './ActionFields'

const Actions: FC = () => (
    <Styled>
        <header>
            <menu>
                <Breadcrumb area="actions" />
                <div />
            </menu>
        </header>
        <ActionFields />
    </Styled>
)

const Styled = styled.section`
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }
`

export { Actions }
