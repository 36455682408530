import { NetworkStatus } from '@apollo/client'
import { logger } from 'Adapters/Logger'
import { byDiscriminant, byTypename } from 'Utils'
import { countBy } from 'lodash'
import { useMemo } from 'react'
import {
    BusinessObjectInProcessOverviewQuery,
    useBusinessObjectPhaseProgressQuery,
} from './__generated__/query'

type Params = {
    process: BusinessObjectInProcessOverviewQuery['process']
    phaseIndex: number
    businessObjectId: string
}

export const useCurrentPhaseProgress = ({
    process,
    phaseIndex,
    businessObjectId,
}: Params) => {
    const { data, networkStatus } = useBusinessObjectPhaseProgressQuery({
        context: {
            useBatching: true,
        },
        variables: {
            businessObjectId,
            processId: process.id,
            phaseIndex,
        },
        onError(error) {
            logger.error('Failed to fetch card actions', error, {
                businessObjectId,
                processId: process.id,
                phaseIndex,
            })
        },
        notifyOnNetworkStatusChange: true,
    })

    const phase = process.phases[phaseIndex]

    const percentageComplete = useMemo<number>(() => {
        if (!data) return 0

        const totalActions = data.actions.length

        const applicableCriteria = phase.criteria.filter(
            criterion => criterion.businessObjectOnCriterion?.isApplicable
        )

        const fieldCriteria = applicableCriteria.filter(
            byTypename('FieldConditionPhaseCriterion' as const)
        )

        const processDelegateCriteria = applicableCriteria.filter(
            byTypename('ProcessDelegatePhaseCriterion' as const)
        )

        const totalCriteria =
            totalActions + fieldCriteria.length + processDelegateCriteria.length

        if (totalCriteria === 0) return 100 // assume that 0 / 0 is 100% 'complete'

        const completedActions =
            countBy(
                data.actions,
                byDiscriminant('__typename', 'CompletedAction')
            ).true ?? 0

        const completedFieldCriteria = fieldCriteria.filter(
            criterion => criterion.businessObjectOnCriterion?.isCompleted
        )

        const completedProcessDelegateCriteria = processDelegateCriteria.filter(
            criterion => criterion.businessObjectOnCriterion?.isCompleted
        )

        return (
            ((completedActions +
                completedFieldCriteria.length +
                completedProcessDelegateCriteria.length) /
                totalCriteria) *
            100
        )
    }, [data, phase])

    return {
        percentageComplete,
        actions: data?.actions ?? [],
        loading: [NetworkStatus.loading, NetworkStatus.refetch].includes(
            networkStatus
        ),
    }
}
