import { dayjs } from 'Adapters/DayJS'
import { ActionViews } from '../types'

export const presetViewsFactory = (currentUserId: string): ActionViews => ({
    '0': {
        id: '0',
        name: 'Unassigned',
        actionFilter: {
            assignedTo: 'unassigned',
            'showBy[]': 'IncompleteAction',
        },
        actionExtensionFilter: {},
        display: 'table',
    },
    '1': {
        id: '1',
        name: 'No due date',
        actionFilter: {
            noDueDate: 'true',
            'showBy[]': 'IncompleteAction',
        },
        actionExtensionFilter: {},
        display: 'table',
    },
    '2': {
        id: '2',
        name: 'Due by end of week',
        actionFilter: {
            dueBefore: dayjs().endOf('week').endOf('day').toISOString(),
            'showBy[]': 'IncompleteAction',
        },
        actionExtensionFilter: {},
        display: 'timeline',
    },
    '3': {
        id: '3',
        name: 'My Todo List',
        actionFilter: {
            'showBy[]': 'IncompleteAction',
            assignedTo: currentUserId,
        },
        actionExtensionFilter: {},
        display: 'table',
    },
})
