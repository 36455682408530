import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { TextInput, Toggle } from 'Components/Input'
import { PopoverMenu } from 'Components/PopoverMenu'
import { Text, regular4 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { fromEvent } from 'Utils'
import { pipe } from 'lodash/fp'
import { FC, useCallback, useState } from 'react'
import { match } from 'ts-pattern'
import { WebhookFragment } from './__generated__/q'
import { AddBusinessObjectToProcess } from './steps/AddBusinessObjectToProcess'
import { CreateAction } from './steps/CreateAction'
import { CreateBusinessObject } from './steps/CreateBusinessObject'
import { Filter } from './steps/Filter'
import { useMutateWebhook } from './useMutateWebhook'

type Props = {
    initialWebhook?: WebhookFragment
    onCompleted: (args: {
        webhook: WebhookFragment
        signingSecret: string | undefined
    }) => void
}

export const CreateWebhookModal: FC<Props> = ({
    initialWebhook,
    onCompleted,
}) => {
    const {
        mutate,
        setName,
        setSigningSecret,
        generateSigningSecret,
        removeAction,
        updateActionField,
        addBlankStep,
        moveActionDown,
        moveActionUp,
        state,
        errors,
    } = useMutateWebhook({ initialWebhook, onCompleted })

    const canMutate = errors.length === 0

    const [signingEnabled, setSigningEnabled] = useState(false)

    const toggleSigning = useCallback(
        (enabled: boolean) => {
            if (!enabled) {
                setSigningSecret('')
            }
            setSigningEnabled(enabled)
        },
        [setSigningSecret, setSigningEnabled]
    )

    return (
        <Styled>
            <div>
                <label>
                    Webhook Name
                    <TextInput
                        type="text"
                        required
                        value={state.name}
                        onChange={pipe(fromEvent, setName)}
                    />
                    <Text as="p" variant="regular-5">
                        This will appear throughout the UI wherever this webhook
                        performs an action
                    </Text>
                </label>
                <label>
                    Signed Headers
                    <Toggle
                        onCheckedChange={toggleSigning}
                        checked={signingEnabled}
                        indicatorText={['Enabled', 'Disabled']}
                    />
                    {signingEnabled ? (
                        <>
                            <div>
                                <TextInput
                                    type="text"
                                    value={state.signingSecret}
                                    onChange={pipe(fromEvent, setSigningSecret)}
                                    placeholder={
                                        initialWebhook?.hasSigningSecret
                                            ? 'Secret not visible for security reasons'
                                            : 'Enter the secret with which headers will be signed'
                                    }
                                />
                                <Tooltip content="Copy to clipboard">
                                    <IconButton
                                        iconName="Copy"
                                        type="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                state.signingSecret
                                            )
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip content="Generate new secret">
                                    <IconButton
                                        type="button"
                                        iconName="MiniDice"
                                        onClick={generateSigningSecret}
                                    />
                                </Tooltip>
                            </div>
                            <Text as="p" variant="regular-5">
                                If supported by the external service, we can
                                verify the source of incoming webhooks.
                            </Text>
                            {initialWebhook?.hasSigningSecret ? (
                                <Text as="p" variant="regular-5">
                                    Editing this field will update the stored
                                    secret, which may require updating external
                                    systems.
                                </Text>
                            ) : (
                                <Text as="p" variant="regular-5">
                                    This will not be shown again after the
                                    webhook is created.
                                </Text>
                            )}
                        </>
                    ) : null}
                </label>

                {state.actions.map((action, index) => {
                    const props = {
                        key: index,
                        step: index + 1,
                        onDelete: removeAction(index),
                        onFieldChanged: updateActionField(index),
                        moveUp: index === 0 ? undefined : moveActionUp(index),
                        moveDown:
                            index === state.actions.length - 1
                                ? undefined
                                : moveActionDown(index),
                    }

                    return match(action)
                        .with({ type: 'createAction' }, ({ payload }) => (
                            <CreateAction
                                {...props}
                                payload={payload}
                                onFieldChanged={updateActionField(index)}
                            />
                        ))
                        .with(
                            { type: 'createBusinessObject' },
                            ({ payload }) => (
                                <CreateBusinessObject
                                    {...props}
                                    payload={payload}
                                    onFieldChanged={updateActionField(index)}
                                />
                            )
                        )
                        .with({ type: 'filter' }, ({ payload }) => (
                            <Filter
                                {...props}
                                payload={payload}
                                onFieldChanged={updateActionField(index)}
                            />
                        ))
                        .with(
                            { type: 'addBusinessObjectToProcess' },
                            ({ payload }) => (
                                <AddBusinessObjectToProcess
                                    {...props}
                                    payload={payload}
                                    onFieldChanged={updateActionField(index)}
                                />
                            )
                        )
                        .exhaustive()
                })}
                <PopoverMenu
                    trigger={
                        <>
                            <Icon name="Plus" />
                            Add Step
                        </>
                    }
                    options={[
                        {
                            children: 'Create Action',
                            disabled: !canMutate,
                            onClick: addBlankStep('createAction'),
                        },
                        {
                            children: 'Create Record',
                            disabled: !canMutate,
                            onClick: addBlankStep('createBusinessObject'),
                        },
                        {
                            children: 'Add Record To Workflow',
                            disabled: !canMutate,
                            onClick: addBlankStep('addBusinessObjectToProcess'),
                        },
                        {
                            children: 'Filter',
                            disabled: !canMutate,
                            onClick: addBlankStep('filter'),
                        },
                    ]}
                />
            </div>
            <Tooltip
                content={errors.map(error => (
                    <div>{error}</div>
                ))}
                disabled={errors.length === 0}
            >
                <StyledSubmitButton
                    disabled={!canMutate}
                    onClick={mutate}
                    icon="Save"
                    variant="primary"
                    text="Save Webhook"
                    type="button"
                />
            </Tooltip>
        </Styled>
    )
}

const StyledSubmitButton = styled(IconTextButton)`
    align-self: flex-end;
`

const Styled = styled.form`
    ${flex('column', 'space-between', 'stretch')};
    gap: 1rem;
    width: 100vw;
    max-width: 40rem;
    height: 80vh;
    border: 0;

    .error-message {
        min-height: 1em;
        color: ${({ theme }) => theme.palette.text.support05.normal};
    }

    > div {
        overflow: auto;
    }

    label {
        ${regular4};
        display: block;
        padding-bottom: 1.5rem;
        width: 100%;

        > :not(last-child) {
            margin-top: 0.4rem;
        }

        &:last-of-type {
            padding-bottom: 1rem;
        }

        > div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: space-between;
        }
    }

    > button {
        margin-top: 0.5rem;
    }
`
