import { TextInput } from 'Components/Input'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'
import { fromEvent } from 'Utils'
import { FieldCommonWrapper } from './Common'
import { LengthConstraint } from './Constraints/Length'
import { RequiredConstraint } from './Constraints/Required'
import { Field } from './Field'
import { FieldProps } from './types'

export const TextFieldDefinitionInput: React.FC<FieldProps<'text'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    const defaultValidationErrors = useMemo(
        () => validateTextDefault(field),
        [field]
    )

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field
                htmlFor={`bod-field-${index}-default`}
                name="Default value"
                errors={defaultValidationErrors}
            >
                <TextInput
                    id={`bod-field-${index}-default`}
                    value={field.defaultValue ?? ''}
                    onChange={e => onChange('defaultValue')(() => fromEvent(e))}
                    hasError={!!defaultValidationErrors.length}
                />
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
            <LengthConstraint
                index={index}
                value={field.constraints.find(constraint => constraint.length)}
                onChange={constraint =>
                    onChange('constraints')(prev =>
                        constraint
                            ? [...prev.filter(c => !c.length), constraint]
                            : prev.filter(c => !c.length)
                    )
                }
            />
        </FieldCommonWrapper>
    )
}

type TextField = FieldProps<'text'>['field']

const validateTextDefault = (field: TextField): string[] => {
    if (!field.defaultValue) return []

    return field.constraints.reduce<string[]>((errors, constraint) => {
        return match(constraint)
            .with({ length: P.not(P.nullish) }, ({ length }) => [
                ...errors,
                ...validateAgainstLengthConstraint(field.defaultValue!, length),
            ])
            .with({ required: P.not(P.nullish) }, () => errors)
            .otherwise(() => [])
    }, [])
}

const validateAgainstLengthConstraint = (
    defaultValue: string,
    lengthConstraint: TextField['constraints'][number]['length']
): string[] => {
    if (!lengthConstraint) return []

    const min = lengthConstraint.min
    const max = lengthConstraint.max

    if (min && defaultValue.length < min)
        return ['The default value is too short']

    if (max && defaultValue.length > max)
        return ['The default value is too long']

    return []
}
