import { IconTextButton } from 'Components/Button'
import { Drawer } from 'Components/Drawer'
import { BusinessObjectDefinitionDisplay } from 'Features/BusinessObjectDefinitionDisplay'
import { FC, useRef, useState } from 'react'

type Props = {
    definitionId: string
}

export const ViewDefinition: FC<Props> = ({ definitionId }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [defOpen, setDefOpen] = useState(false)
    return (
        <>
            <IconTextButton
                ref={ref}
                text="Dataset Definition"
                icon="Domain"
                onClick={() => setDefOpen(true)}
                variant="secondary"
            />
            <Drawer
                open={defOpen}
                onOpenChange={open => setDefOpen(open)}
                content={<BusinessObjectDefinitionDisplay id={definitionId} />}
                returnFocus={() => ref.current?.focus()}
            />
        </>
    )
}
