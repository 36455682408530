import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessObjectOverview_RemoveFromProccessMutationVariables = Types.Exact<{
  input: Types.RemoveBusinessObjectFromProcessInput;
}>;


export type BusinessObjectOverview_RemoveFromProccessMutation = { __typename?: 'Mutation', removeBusinessObjectFromProcess: { __typename?: 'Process', id: string, phases: Array<{ __typename?: 'Phase', businessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }> } };


export const BusinessObjectOverview_RemoveFromProccessDocument = gql`
    mutation BusinessObjectOverview_RemoveFromProccess($input: RemoveBusinessObjectFromProcessInput!) {
  removeBusinessObjectFromProcess(input: $input) {
    id
    phases {
      businessObjects(input: {excludeCompleted: true}) {
        meta {
          totalCount
        }
      }
    }
  }
}
    `;
export type BusinessObjectOverview_RemoveFromProccessMutationFn = Apollo.MutationFunction<BusinessObjectOverview_RemoveFromProccessMutation, BusinessObjectOverview_RemoveFromProccessMutationVariables>;

/**
 * __useBusinessObjectOverview_RemoveFromProccessMutation__
 *
 * To run a mutation, you first call `useBusinessObjectOverview_RemoveFromProccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessObjectOverview_RemoveFromProccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessObjectOverviewRemoveFromProccessMutation, { data, loading, error }] = useBusinessObjectOverview_RemoveFromProccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessObjectOverview_RemoveFromProccessMutation(baseOptions?: Apollo.MutationHookOptions<BusinessObjectOverview_RemoveFromProccessMutation, BusinessObjectOverview_RemoveFromProccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BusinessObjectOverview_RemoveFromProccessMutation, BusinessObjectOverview_RemoveFromProccessMutationVariables>(BusinessObjectOverview_RemoveFromProccessDocument, options);
      }
export type BusinessObjectOverview_RemoveFromProccessMutationHookResult = ReturnType<typeof useBusinessObjectOverview_RemoveFromProccessMutation>;
export type BusinessObjectOverview_RemoveFromProccessMutationResult = Apollo.MutationResult<BusinessObjectOverview_RemoveFromProccessMutation>;
export type BusinessObjectOverview_RemoveFromProccessMutationOptions = Apollo.BaseMutationOptions<BusinessObjectOverview_RemoveFromProccessMutation, BusinessObjectOverview_RemoveFromProccessMutationVariables>;