import { VerticalScrollArea } from 'Components/ScrollableArea'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { useActionsView } from '../../../../../../Features/ActionView/useActionsView'
import { useSortActions } from '../../Hooks'
import { Action, Extension } from '../../types'
import { CardDisplay } from './CardDisplay'
import { GroupDisplay } from './GroupDisplay'

type Props = {
    actions: Action[]
    extension?: Extension
    mostRecentAction: string | undefined
}

export const CardView: FC<Props> = ({
    actions,
    extension,
    mostRecentAction,
}) => {
    const sortedActions = useSortActions(actions)

    const { grouping } = useActionsView()

    return (
        <VerticalScrollArea height="100%" width="100%">
            {match(grouping)
                .with(null, () => (
                    <CardDisplay
                        actions={sortedActions}
                        mostRecentAction={mostRecentAction}
                    />
                ))
                .with(P.not(P.nullish), grouping => (
                    <GroupDisplay
                        actions={sortedActions}
                        extension={extension}
                        grouping={grouping}
                    />
                ))
                .exhaustive()}
        </VerticalScrollArea>
    )
}
