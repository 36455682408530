import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
    to: string
    heading: string
    icon: IconName
    description: ReactNode
    background: string
}

const NavCard: FC<Props> = ({ to, heading, icon, description, background }) => (
    <Styled to={to} style={{ backgroundImage: `url('/images/${background}')` }}>
        <div>
            <header>
                <Icon name={icon} />
                <Text as="h3" variant="bold-2">
                    {heading}
                </Text>
            </header>
            <div>
                <p>{description}</p>
            </div>
        </div>
    </Styled>
)

const Styled = styled(NavLink)`
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: brightness(100%)
        drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
        );
    border-radius: 0.25rem;
    height: 100%;
    background-size: cover;
    background-position: center bottom;
    align-items: flex-end;
    padding-top: 8rem;
    transition: filter
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    @media (hover: hover) {
        &:hover {
            filter: brightness(110%)
                drop-shadow(
                    0 0.25rem 0.25rem
                        ${({ theme }) => theme.palette.ui['03'].normal}
                );
        }
    }

    &:active {
        filter: brightness(95%)
            drop-shadow(
                0 0.25rem 0.25rem
                    ${({ theme }) => theme.palette.ui['03'].normal}
            );
    }

    > div {
        background-color: ${({ theme }) => theme.palette.ui['01'].normal}EE;
        padding: 1rem;
        height: 100%;
        width: 100%;
    }

    header {
        display: flex;
        padding-bottom: 0.75rem;
        gap: 0.5rem;
    }

    h3 {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['01'].normal};
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        font-size: 0.8rem;
        line-height: 150%;
    }
`

export { NavCard }
