import * as Accordion from '@radix-ui/react-accordion'
import * as Popover from '@radix-ui/react-popover'
import { IconButton, IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { getPortalsContainer } from 'Utils'
import { FC, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    AssignedToFilterControls,
    CreatedByFilterControls,
    ProcessFilterControls,
    ShowByFilterControls,
} from './FilterControls'
import { BusinessObjectFilterControls } from './FilterControls/BusinessObjectFilterControls'
import { DueBeforeFilterControls } from './FilterControls/DueBeforeFilterControls'

import { styled } from 'Adapters/Freestyled'
import { ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { canFilterFieldType } from 'Components/ComparatorBuilderV2/useFilterableFields'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { useModalControls } from 'Hooks'
import { ValueSelectorType } from '__generated__'
import { Extension } from '../../types'
import { useProcesses_ActionsFilterQuery } from './__generated__/query'
import { StyledContent } from './styles'

type Props = {
    extension: Extension | undefined
}

export const Filters: FC<Props> = ({ extension }) => {
    const [openAccordionItem, setOpenAccordionItem] = useState<string[]>([])

    const [searchParams, setSearchParams] = useSearchParams()

    const { filterState, setFilters, noOfFilters } =
        useBusinessObjectFilterState()

    const noOfFiltersApplied = useMemo(() => {
        const noOfActionFilters = Object.keys(
            convertSearchParamsToObject(searchParams)
        ).filter(
            key =>
                ![
                    'filterState',
                    'display',
                    'selectedFields',
                    'sort',
                    'grouping',
                ].includes(key)
        ).length

        return {
            noOfActionFilters,
            noOfBusinessObjectFilters: noOfFilters,
            totalFilters: noOfActionFilters + noOfFilters,
        }
    }, [searchParams, noOfFilters])

    const { data } = useProcesses_ActionsFilterQuery()

    const { open, setOpen, buttonRef, closeModal, openModal, returnFocus } =
        useModalControls()

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <IconTextButton
                    icon="Filter"
                    text={
                        !noOfFiltersApplied.totalFilters
                            ? 'Filter actions'
                            : `${noOfFiltersApplied.totalFilters} filter(s) applied`
                    }
                    size="xSmall"
                />
            </Popover.Trigger>

            <Popover.Portal container={getPortalsContainer()}>
                <StyledContent side="right" align="start" sideOffset={10}>
                    <header>
                        <Text as="h3" variant="bold-4">
                            Filter actions
                        </Text>

                        <div className="buttons">
                            <IconTextButton
                                icon={'Close'}
                                text={'Clear all filters'}
                                variant="ghost"
                                size="xSmall"
                                onClick={() => {
                                    setOpenAccordionItem([])
                                    setSearchParams({})
                                }}
                            />

                            <Popover.Close aria-label="Close" asChild>
                                <IconButton iconName="Close" />
                            </Popover.Close>
                        </div>
                    </header>

                    <Accordion.Root
                        type="multiple"
                        value={openAccordionItem}
                        onValueChange={setOpenAccordionItem}
                    >
                        {/* Base Filters */}
                        <ShowByFilterControls />
                        <ProcessFilterControls
                            options={
                                data?.processes.map(process => ({
                                    text: process.name,
                                    value: process.id,
                                })) ?? []
                            }
                        />
                        <BusinessObjectFilterControls />
                        <AssignedToFilterControls />
                        <CreatedByFilterControls />
                        <DueBeforeFilterControls />

                        {/* ExtensionFilters */}
                        {extension?.fields.filter(field =>
                            canFilterFieldType(field.type)
                        ).length ? (
                            <>
                                <StyledExtraFiltersButton
                                    onClick={openModal}
                                    ref={buttonRef}
                                    text={
                                        noOfFiltersApplied.noOfBusinessObjectFilters
                                            ? `${noOfFiltersApplied.noOfBusinessObjectFilters} extra filters applied`
                                            : 'More filters'
                                    }
                                    icon="Filter"
                                    variant="secondary"
                                />
                                <ComparatorBuilderV2
                                    businessObjectDefinitionId={extension.id}
                                    onConfirm={setFilters}
                                    title={'More filters'}
                                    open={open}
                                    onOpenChange={setOpen}
                                    returnFocus={returnFocus}
                                    onClearAll={() => {
                                        closeModal()
                                    }}
                                    initialComparators={Object.values(
                                        filterState
                                    ).flat()}
                                    allowedFilterTypes={[
                                        ValueSelectorType.FieldValue,
                                    ]}
                                />
                            </>
                        ) : null}
                    </Accordion.Root>
                </StyledContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const StyledExtraFiltersButton = styled(IconTextButton)`
    margin-top: 1rem;
    width: 100%;
`
