import { FieldCommonWrapper } from './Common'
import { RequiredConstraint } from './Constraints/Required'
import { FieldProps } from './types'

export const DocumentFieldDefinitionInput: React.FC<FieldProps<'document'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => (
    <FieldCommonWrapper
        label={name}
        field={field}
        index={index}
        onChange={onChange}
        onRemoved={onRemoved}
    >
        {disableRequiredConstraint ? null : (
            <RequiredConstraint
                index={index}
                value={
                    field.constraints.find(constraint => constraint.required) ??
                    {}
                }
                onChange={constraint =>
                    onChange('constraints')(prev =>
                        constraint?.required
                            ? [...prev, constraint]
                            : prev.filter(c => !c.required)
                    )
                }
            />
        )}
    </FieldCommonWrapper>
)
