import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleActionMutationVariables = Types.Exact<{
  input: Types.UpdateActionPhaseInput;
}>;


export type ToggleActionMutation = { __typename?: 'Mutation', updateActionPhase: { __typename?: 'CompletedAction', completedAt: string, id: string } | { __typename?: 'IncompleteAction', id: string } };


export const ToggleActionDocument = gql`
    mutation ToggleAction($input: UpdateActionPhaseInput!) {
  updateActionPhase(input: $input) {
    id
    ... on CompletedAction {
      completedAt
    }
  }
}
    `;
export type ToggleActionMutationFn = Apollo.MutationFunction<ToggleActionMutation, ToggleActionMutationVariables>;

/**
 * __useToggleActionMutation__
 *
 * To run a mutation, you first call `useToggleActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActionMutation, { data, loading, error }] = useToggleActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleActionMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActionMutation, ToggleActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActionMutation, ToggleActionMutationVariables>(ToggleActionDocument, options);
      }
export type ToggleActionMutationHookResult = ReturnType<typeof useToggleActionMutation>;
export type ToggleActionMutationResult = Apollo.MutationResult<ToggleActionMutation>;
export type ToggleActionMutationOptions = Apollo.BaseMutationOptions<ToggleActionMutation, ToggleActionMutationVariables>;