import { dayjs } from 'Adapters/DayJS'
import { styled } from 'Adapters/Freestyled'
import { Table } from 'Components/Table'
import {
    RecurringScheduleFrequency,
    ScheduledEventActivityType,
    ScheduledPayloadType,
} from '__generated__'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { DeleteScheduledEvent } from './Delete'
import { DetailsModal } from './DetailsModal'
import { useScheduledEventsQuery } from './__generated__/query'

const frequencyToString = (frequency: RecurringScheduleFrequency) => {
    switch (frequency) {
        case RecurringScheduleFrequency.Hourly:
            return 'hours'
        case RecurringScheduleFrequency.Daily:
            return 'days'
        case RecurringScheduleFrequency.Weekly:
            return 'weeks'
        case RecurringScheduleFrequency.Monthly:
            return 'months'
        case RecurringScheduleFrequency.Annually:
            return 'years'
    }
}

export const Automation: FC = () => {
    const { data, refetch } = useScheduledEventsQuery({
        fetchPolicy: 'cache-and-network',
    })

    const items = useMemo(
        () =>
            data
                ? data.scheduledEvents
                      .map(event => ({
                          ...event,
                          ...match(event.schedule)
                              .with(
                                  {
                                      __typename:
                                          'ScheduledEventScheduleSingle',
                                  },
                                  s => ({
                                      nextExecution: s.scheduledAt,
                                      recurrence: 'One off',
                                  })
                              )
                              .with(
                                  {
                                      __typename:
                                          'ScheduledEventScheduleRecurring',
                                  },
                                  s => ({
                                      nextExecution: s.nextExecution,
                                      recurrence: `Every ${
                                          s.interval
                                      } ${frequencyToString(s.frequency)}`,
                                  })
                              )
                              .exhaustive(),
                      }))
                      .sort((a, b) =>
                          dayjs(a.nextExecution).diff(dayjs(b.nextExecution))
                      )
                : [],
        [data]
    )

    return (
        <Styled>
            <Table
                items={items}
                columnSetup={[
                    {
                        heading: 'Type',
                    },
                    {
                        heading: 'Next execution',
                    },
                    {
                        heading: 'Recurrence',
                    },
                    {
                        heading: 'Successful executions',
                    },
                    {
                        heading: 'Failed executions',
                    },
                    { heading: '' },
                ]}
                renderItem={event => (
                    <tr key={event.id}>
                        <td>
                            {match(event.payload.type)
                                .with(
                                    ScheduledPayloadType.CreateAction,
                                    () => 'Create action'
                                )
                                .exhaustive()}
                        </td>
                        <td>
                            {event.nextExecution
                                ? dayjs(event.nextExecution).format(
                                      'DD/MM/YYYY'
                                  )
                                : 'No further executions scheduled'}
                        </td>
                        <td>{event.recurrence}</td>
                        <td>
                            {
                                event.activity.filter(
                                    ac =>
                                        ac.activity ===
                                        ScheduledEventActivityType.Completed
                                ).length
                            }
                        </td>
                        <td>
                            {
                                event.activity.filter(
                                    ac =>
                                        ac.activity ===
                                        ScheduledEventActivityType.Failed
                                ).length
                            }
                        </td>
                        <FlexCell>
                            <DetailsModal payload={event.payload} />
                            <DeleteScheduledEvent
                                id={event.id}
                                onDeleted={refetch}
                            />
                        </FlexCell>
                    </tr>
                )}
            />
        </Styled>
    )
}

const Styled = styled.section`
    padding: 2rem 3rem;
    height: 100%;
`

const FlexCell = styled.td`
    display: flex;
    flex-direction: row;
`
