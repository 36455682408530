import { styled } from 'Adapters/Freestyled'
import { ComponentProps, FC, forwardRef } from 'react'
import { IconTextButton } from '../IconTextButton'

type Props = Omit<
    ComponentProps<typeof IconTextButton>,
    'icon' | 'iconRight' | 'variant' | 'maxLength'
>

const DropDownTextButton: FC<Props> = forwardRef((props, ref) => (
    <Styled
        {...props}
        variant="secondary"
        icon="DropDown"
        iconRight
        ref={ref}
    />
))

const Styled = styled(IconTextButton)`
    width: 100%;

    > span:last-child {
        flex: 1;
        text-align: left;
    }
`

export { DropDownTextButton }
