import {
    CreateBusinessObjectField,
    CreateBusinessObjectInput,
    FieldType,
} from '__generated__'
import { compact } from 'lodash'
import { match } from 'ts-pattern'
import { BODFields } from '../../../../Hooks/types'

/*
    Does not allow import if :

    1) includes a list field
    2) has any fields with a 'length', 'range', 'noOfSelections' other than minMax 1, 'dateRange' constraint
    3) is 'id' (relation, document, user) field and has required constraint
*/

export const canImportBusinessObject = (
    businessObjectDefinitionFields: BODFields
): boolean => {
    const canImport = businessObjectDefinitionFields.map<boolean>(bodField => {
        return (
            match(bodField)
                .with({ __typename: 'BooleanFieldDefinition' }, () => true)
                .with({ __typename: 'DateFieldDefinition' }, () => true)
                .with({ __typename: 'EmailFieldDefinition' }, () => true)
                .with({ __typename: 'NumberFieldDefinition' }, () => true)
                .with(
                    { __typename: 'SelectFieldDefinition' },
                    ({ selectConstraints }) => {
                        if (!selectConstraints.length) return true

                        const noOfSelections = selectConstraints.find(
                            constraint =>
                                constraint.__typename ===
                                'NumberOfSelectionsConstraint'
                        )

                        if (!noOfSelections?.min) return true

                        if (noOfSelections.min <= 1) return true

                        return false
                    }
                )
                .with({ __typename: 'TelephoneFieldDefinition' }, () => true)
                .with({ __typename: 'TextFieldDefinition' }, () => true)
                .with({ __typename: 'URLFieldDefinition' }, () => true)
                .with({ __typename: 'ListFieldDefinition' }, () => true)
                // We only block import for the 'id' fields when they are required
                .with(
                    { __typename: 'RelationFieldDefinition' },
                    ({ relationConstraints }) => {
                        if (
                            relationConstraints.find(
                                constraint =>
                                    constraint.__typename ===
                                    'RequiredConstraint'
                            )
                        )
                            return false
                        return true
                    }
                )
                .with(
                    { __typename: 'DocumentFieldDefinition' },
                    ({ docConstraints }) => {
                        if (docConstraints.length) return false
                        return true
                    }
                )
                .with(
                    { __typename: 'UserFieldDefinition' },
                    ({ userConstraints }) => {
                        if (userConstraints.length) return false
                        return true
                    }
                )
                .with({ __typename: 'UpdatesFieldDefinition' }, () => false)
                .with(
                    { __typename: 'CurrencyFieldDefinition' },
                    ({ currencyConstraints }) => {
                        if (currencyConstraints.length) return false
                        return true
                    }
                )
                .exhaustive()
        )
    })

    const hasFalse = canImport.findIndex(val => val === false)

    return hasFalse === -1 ? true : false
}

type FieldDictionary = Record<string, { fieldId: string; fieldType: FieldType }>

export const createFieldDictionary = (
    businessObjectDefinitionFields: BODFields
): FieldDictionary =>
    businessObjectDefinitionFields.reduce<FieldDictionary>((out, field) => {
        return {
            ...out,
            [field.name]: {
                fieldId: field.id,
                fieldType: field.type,
            },
        }
    }, {})

export type ResponseItem = Record<string, string | boolean | number>

type ConvertResponseIntoCreateBusinessObjectInputParams = {
    businessObjectDefinitionId: string
    response: ResponseItem[]
    fieldDictionary: FieldDictionary
}

type ConvertResponseIntoCreateBusinessObjectInput = (
    params: ConvertResponseIntoCreateBusinessObjectInputParams
) => CreateBusinessObjectInput[]

export const convertResponseIntoCreateBusinessObjectInputs: ConvertResponseIntoCreateBusinessObjectInput =
    ({ businessObjectDefinitionId, fieldDictionary, response }) => {
        const inputs: CreateBusinessObjectInput[] = response.map(
            responseItem => {
                const fieldsFromResponse =
                    convertResponseItemIntoCreateBusinessObjectInputFields({
                        fieldDictionary,
                        responseItem,
                    })

                /* Lists are ignored as part of the CSV conversion process.
                 * However initialising a business object with a list field requires an empty array.
                 */
                const fieldsWithList = includeEmptyListField({
                    fieldDictionary,
                    fields: fieldsFromResponse,
                })

                return {
                    businessObjectDefinitionId,
                    fields: fieldsWithList,
                }
            }
        )
        return inputs
    }

type ConvertResponseItemIntoCreateBusinessObjectInputParams = {
    responseItem: ResponseItem
    fieldDictionary: FieldDictionary
}

type ConvertResponseItemIntoCreateBusinessObjectInput = (
    params: ConvertResponseItemIntoCreateBusinessObjectInputParams
) => CreateBusinessObjectInput['fields']

const convertResponseItemIntoCreateBusinessObjectInputFields: ConvertResponseItemIntoCreateBusinessObjectInput =
    ({ responseItem, fieldDictionary }) => {
        return compact(
            Object.entries(responseItem).map(([key, value]) => {
                const { fieldId, fieldType } = fieldDictionary[key]

                return (
                    match(fieldType)
                        .with(FieldType.Boolean, () => {
                            if (typeof value !== 'boolean') return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['boolean'],
                            }
                        })
                        .with(FieldType.Date, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['date'],
                            }
                        })
                        .with(FieldType.Email, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['email'],
                            }
                        })
                        .with(FieldType.Number, () => {
                            if (typeof value !== 'number') return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['number'],
                            }
                        })
                        .with(FieldType.Telephone, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value: {
                                        number: value,
                                        countryCode: undefined, // Not handling country code for now
                                    },
                                } satisfies CreateBusinessObjectField['telephone'],
                            }
                        })
                        .with(FieldType.Select, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value: [value],
                                } satisfies CreateBusinessObjectField['select'],
                            }
                        })
                        .with(FieldType.Text, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['text'],
                            }
                        })
                        .with(FieldType.Url, () => {
                            if (!value || typeof value !== 'string')
                                return undefined

                            return {
                                [fieldType]: {
                                    fieldDefinitionId: fieldId,
                                    value,
                                } satisfies CreateBusinessObjectField['url'],
                            }
                        })
                        // The following paths are never currently met (these fields ignored by the CSV import widget)
                        .with(FieldType.List, () => {
                            return {
                                [fieldType]: [],
                            }
                        })
                        .with(FieldType.Document, () => {
                            return {
                                [fieldType]: undefined,
                            }
                        })
                        .with(FieldType.Relation, () => {
                            return {
                                [fieldType]: undefined,
                            }
                        })
                        .with(FieldType.User, () => {
                            return {
                                [fieldType]: undefined,
                            }
                        })
                        .with(FieldType.Updates, () => {
                            return {
                                [fieldType]: undefined,
                            }
                        })
                        .with(FieldType.Currency, () => {
                            return {
                                [fieldType]: undefined,
                            }
                        })
                        .exhaustive()
                )
            })
        )
    }

type IncludeEmptyListField = (params: {
    fieldDictionary: FieldDictionary
    fields: CreateBusinessObjectInput['fields']
}) => CreateBusinessObjectInput['fields']
export const includeEmptyListField: IncludeEmptyListField = ({
    fieldDictionary,
    fields,
}) => {
    const listField = Object.values(fieldDictionary).find(
        value => value.fieldType === FieldType.List
    )

    if (!listField) return fields

    return [
        ...fields,
        { list: { fieldDefinitionId: listField.fieldId, value: [] } },
    ]
}
