import { styled } from 'Adapters/Freestyled'
import { IconButton, TextButton } from 'Components/Button'
import { Popover } from 'Components/Popover'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Icon } from '../../../../../../Components/Icon'
import { ControlledModal } from '../../../../../../Components/Modal'
import { Tooltip } from '../../../../../../Components/Tooltip'
import { useModalControls } from '../../../../../../Hooks'
import { ExecutionsModal } from './ExecutionsModal'
import { WebhookFragment, useDeleteWebhookMutation } from './__generated__/q'

type Props = {
    editWebhook: () => void
    webhook: WebhookFragment
}

export const Card: FC<Props> = ({ webhook, editWebhook }) => {
    const [deleteWebhook, { loading }] = useDeleteWebhookMutation({
        variables: { input: { id: webhook.id } },
        update: cache => {
            cache.evict({
                id: cache.identify({
                    __typename: 'Webhook',
                    id: webhook.id,
                }),
            })
        },
    })

    const executionsModal = useModalControls()

    return (
        <>
            <Styled>
                <header>
                    <StyledCardIcons>
                        {webhook.hasSigningSecret ? (
                            <Tooltip content="Protected by secure signature verification">
                                <Icon name="SubscriptionPlan" />
                            </Tooltip>
                        ) : null}
                        <Text as="h4" variant="bold-3">
                            {webhook.name || 'Webhook'}
                        </Text>
                    </StyledCardIcons>
                    <StyledCardIcons>
                        <Popover
                            trigger={
                                <Tooltip content="Delete Webhook">
                                    <Icon name="Delete" />
                                </Tooltip>
                            }
                        >
                            <StyledDeleteConfirm>
                                <Text as="p" variant="regular-5">
                                    Are you sure you want to delete this
                                    webhook?
                                </Text>
                                <TextButton
                                    disabled={loading}
                                    text={`I'm Sure`}
                                    type="button"
                                    onClick={() => deleteWebhook()}
                                />
                            </StyledDeleteConfirm>
                        </Popover>
                        <Tooltip content="View Webhook Executions">
                            <IconButton
                                iconName="Analytics"
                                type="button"
                                disabled={loading}
                                onClick={executionsModal.openModal}
                            />
                        </Tooltip>
                        <Tooltip content="Edit">
                            <IconButton
                                iconName="Edit"
                                type="button"
                                disabled={loading}
                                onClick={editWebhook}
                            />
                        </Tooltip>
                    </StyledCardIcons>
                </header>
                <div></div>
                <footer>
                    <input disabled value={webhook.url} />
                    <IconButton
                        iconName="Copy"
                        type="button"
                        onClick={() => {
                            navigator.clipboard.writeText(webhook.url)
                        }}
                    />
                </footer>
            </Styled>

            <ControlledModal
                {...executionsModal}
                onOpenChange={executionsModal.setOpen}
                title="Webhook Executions"
                description=""
                hideDescription
                renderContent={() => <ExecutionsModal webhookId={webhook.id} />}
            />
        </>
    )
}

const StyledCardIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

const Styled = styled.li`
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
    border-radius: 0.25rem;
    height: 100%;
    transition: filter
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
        border-color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease;

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.25rem;
        padding: 1rem;
        background: ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 1rem 0.5rem 1rem;
        gap: 0.5rem;
    }

    > div p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > footer {
        padding: 0.75rem 1rem;
        display: flex;
        gap: 1rem;

        > input {
            width: 100%;
            font-size: 0.85rem;
        }
    }
`

const StyledDeleteConfirm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
