import * as Types from '../../__generated__/index';

import { gql } from '@apollo/client';
export type BizObjUpdate_Bool_UniqueFragment = { __typename: 'BusinessObjectBooleanFieldUpdate', booleanUpdateValue: boolean, booleanUpdatePreviousValue: boolean };

export type BizObjUpdate_Currency_UniqueFragment = { __typename: 'BusinessObjectCurrencyFieldUpdate', currencyUpdatedPreviousValue?: { __typename?: 'BusinessObjectCurrencyFieldValue', amount: number, currencyDetails: { __typename?: 'CurrencyDetails', symbol: string } } | null, currencyUpdateValue?: { __typename?: 'BusinessObjectCurrencyFieldValue', amount: number, currencyDetails: { __typename?: 'CurrencyDetails', symbol: string } } | null };

export type BizObjUpdate_Date_UniqueFragment = { __typename: 'BusinessObjectDateFieldUpdate', dateUpdatePreviousValue?: string | null, dateUpdateValue?: string | null };

export type BizObjUpdate_Number_UniqueFragment = { __typename: 'BusinessObjectNumberFieldUpdate', numberUpdatePreviousValue?: number | null, numberUpdateValue?: number | null };

export type BizObjUpdate_Email_UniqueFragment = { __typename: 'BusinessObjectEmailFieldUpdate', emailUpdatePreviousValue?: string | null, emailUpdateValue?: string | null };

export type BizObjUpdate_Telephone_UniqueFragment = { __typename: 'BusinessObjectTelephoneFieldUpdate', telephoneUpdatePreviousValue?: { __typename?: 'BusinessObjectTelephoneFieldValue', countryCode?: string | null, number?: string | null } | null, telephoneUpdateValue?: { __typename?: 'BusinessObjectTelephoneFieldValue', countryCode?: string | null, number?: string | null } | null };

export type BizObjUpdate_Text_UniqueFragment = { __typename: 'BusinessObjectTextFieldUpdate', textUpdateValue?: string | null, textUpdatePreviousValue?: string | null };

export type BizObjUpdate_Url_UniqueFragment = { __typename: 'BusinessObjectUrlFieldUpdate', urlUpdateValue?: string | null, urlUpdatePreviousValue?: string | null };

export type BizObjUpdate_User_UniqueFragment = { __typename: 'BusinessObjectUserFieldUpdate', userUpdateValue?: { __typename?: 'User', name: string } | null, userUpdatePreviousValue?: { __typename?: 'User', name: string } | null };

export type BizObjUpdate_Document_UniqueFragment = { __typename: 'BusinessObjectDocumentFieldUpdate', documentUpdateValue?: { __typename?: 'File', name: string, id: string } | null, documentUpdatePreviousValue?: { __typename?: 'File', name: string, id: string } | null };

export type BizObjUpdate_Select_UniqueFragment = { __typename: 'BusinessObjectSelectFieldUpdate', selectUpdatePreviousValue: Array<string>, selectUpdateValue: Array<string>, fieldDefinition: { __typename?: 'BooleanFieldDefinition' } | { __typename?: 'CurrencyFieldDefinition' } | { __typename?: 'DateFieldDefinition' } | { __typename?: 'DocumentFieldDefinition' } | { __typename?: 'EmailFieldDefinition' } | { __typename?: 'ListFieldDefinition' } | { __typename?: 'NumberFieldDefinition' } | { __typename?: 'RelationFieldDefinition' } | { __typename?: 'SelectFieldDefinition', options: Array<{ __typename?: 'SelectFieldOption', id: string, value: string }> } | { __typename?: 'TelephoneFieldDefinition' } | { __typename?: 'TextFieldDefinition' } | { __typename?: 'URLFieldDefinition' } | { __typename?: 'UpdatesFieldDefinition' } | { __typename?: 'UserFieldDefinition' } };

export type BizObjUpdate_Relation_UniqueFragment = { __typename: 'BusinessObjectRelationFieldUpdate', relationUpdateValue?: { __typename?: 'BusinessObject', id: string, label: string, definition: { __typename?: 'BusinessObjectDefinition', id: string, name: string, label: string } } | null, relationUpdatePreviousValue?: { __typename?: 'BusinessObject', id: string, label: string, definition: { __typename?: 'BusinessObjectDefinition', id: string, name: string, label: string } } | null };

export type BizObjUpdate_List_UniqueFragment = { __typename: 'BusinessObjectListFieldUpdate', listUpdateValue: number, listUpdatePreviousValue: number };

export const BizObjUpdate_Bool_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Bool_Unique on BusinessObjectBooleanFieldUpdate {
  __typename
  booleanUpdateValue: newValue
  booleanUpdatePreviousValue: previousValue
}
    `;
export const BizObjUpdate_Currency_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Currency_Unique on BusinessObjectCurrencyFieldUpdate {
  __typename
  currencyUpdatedPreviousValue: previousValue {
    amount
    currencyDetails {
      symbol
    }
  }
  currencyUpdateValue: newValue {
    amount
    currencyDetails {
      symbol
    }
  }
}
    `;
export const BizObjUpdate_Date_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Date_Unique on BusinessObjectDateFieldUpdate {
  __typename
  dateUpdatePreviousValue: previousValue
  dateUpdateValue: newValue
}
    `;
export const BizObjUpdate_Number_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Number_Unique on BusinessObjectNumberFieldUpdate {
  __typename
  numberUpdatePreviousValue: previousValue
  numberUpdateValue: newValue
}
    `;
export const BizObjUpdate_Email_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Email_Unique on BusinessObjectEmailFieldUpdate {
  __typename
  emailUpdatePreviousValue: previousValue
  emailUpdateValue: newValue
}
    `;
export const BizObjUpdate_Telephone_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Telephone_Unique on BusinessObjectTelephoneFieldUpdate {
  __typename
  telephoneUpdatePreviousValue: previousValue {
    countryCode
    number
  }
  telephoneUpdateValue: newValue {
    countryCode
    number
  }
}
    `;
export const BizObjUpdate_Text_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Text_Unique on BusinessObjectTextFieldUpdate {
  __typename
  textUpdateValue: newValue
  textUpdatePreviousValue: previousValue
}
    `;
export const BizObjUpdate_Url_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_URL_Unique on BusinessObjectUrlFieldUpdate {
  __typename
  urlUpdateValue: newValue
  urlUpdatePreviousValue: previousValue
}
    `;
export const BizObjUpdate_User_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_User_Unique on BusinessObjectUserFieldUpdate {
  __typename
  userUpdateValue: newValue {
    name
  }
  userUpdatePreviousValue: previousValue {
    name
  }
}
    `;
export const BizObjUpdate_Document_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Document_Unique on BusinessObjectDocumentFieldUpdate {
  __typename
  documentUpdateValue: newValue {
    name
    id
  }
  documentUpdatePreviousValue: previousValue {
    name
    id
  }
}
    `;
export const BizObjUpdate_Select_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Select_Unique on BusinessObjectSelectFieldUpdate {
  __typename
  fieldDefinition {
    ... on SelectFieldDefinition {
      options {
        id
        value
      }
    }
  }
  selectUpdatePreviousValue: previousValue
  selectUpdateValue: newValue
}
    `;
export const BizObjUpdate_Relation_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_Relation_Unique on BusinessObjectRelationFieldUpdate {
  __typename
  relationUpdateValue: newValue {
    id
    definition {
      id
      name
      label
    }
    label
  }
  relationUpdatePreviousValue: previousValue {
    id
    definition {
      id
      name
      label
    }
    label
  }
}
    `;
export const BizObjUpdate_List_UniqueFragmentDoc = gql`
    fragment BizObjUpdate_List_Unique on BusinessObjectListFieldUpdate {
  __typename
  listUpdateValue: newValue
  listUpdatePreviousValue: previousValue
}
    `;