import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { TextAreaInput, TextInput } from 'Components/Input'
import {
    Field,
    FieldValidation,
    ValidationStatus,
    validateName,
} from 'Utils/BusinessObjectDefinition'
import { ReactNode } from 'react'
import { OnSubmit, SetState, State } from '../types'
import { InputWrapper } from './InputWrapper'

const Content = <F extends Field, Validation extends FieldValidation>({
    state,
    setState,
    onSubmit,
    onClose,
    children,
}: {
    state: State<F, Validation>
    setState: SetState<F, Validation>
    onSubmit: OnSubmit<F, Validation>
    onClose: () => void
    children: ReactNode
}) => (
    <Styled>
        <div>
            <InputWrapper
                label="Name"
                description="Giving your field a unique and
                                        meaningful name will help others to
                                        understand it's purpose, and what
                                        information it should capture."
                fieldId="bo-definition-field-name"
                errorMessage={state.validation.name.message}
            >
                <TextInput
                    id="bo-definition-field-name"
                    value={state.field.name}
                    placeholder="Field name"
                    onChange={({ currentTarget: { value } }) =>
                        setState(prev => ({
                            field: {
                                ...prev.field,
                                name: value,
                            },
                            validation: {
                                ...prev.validation,
                                name: validateName(value),
                            },
                        }))
                    }
                    onBlur={({ currentTarget: { value } }) => {
                        const next = value.trim()
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                name: next,
                            },
                            validation: {
                                ...prev.validation,
                                name: validateName(next),
                            },
                        }))
                    }}
                    hasError={
                        state.validation.name.status ===
                        ValidationStatus.Invalid
                    }
                />
            </InputWrapper>
            <InputWrapper
                label="Description (optional)"
                description="By giving your field a meaningful
                                    description you can help others to
                                    understand it's purpose, and what
                                    information it should capture."
                fieldId="bo-definition-field-description"
            >
                <TextAreaInput
                    id="bo-definition-field-description"
                    value={state.field.description ?? ''}
                    placeholder="Field description"
                    onChange={({ currentTarget: { value } }) =>
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                description: value,
                            },
                        }))
                    }
                    onBlur={({ currentTarget: { value } }) =>
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                description: value.trim(),
                            },
                        }))
                    }
                />
            </InputWrapper>
            {children}
        </div>
        <footer>
            <IconTextButton
                icon="Check"
                iconRight
                text="Cancel"
                variant="secondary"
                onClick={onClose}
            />
            <IconTextButton
                icon="AngleRight"
                iconRight
                text="Done"
                variant="primary"
                disabled={!state}
                onClick={() => state && onSubmit(state)}
            />
        </footer>
    </Styled>
)

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    width: 100%;
    max-width: 32rem;

    > div {
        flex: 1;
        overflow: auto;
    }

    > footer {
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
    }
`

export { Content }
