import { logger } from '../../Adapters/Logger'

declare const window: {
    tidioChatApi:
        | undefined
        | {
              open: () => void
              hide: () => void
              close: () => void
              show: () => void
              on: (event: string, callback: () => void) => void
              setVisitorData: (data: {
                  distinct_id: string
                  email?: string
                  name?: string
              }) => void
          }
}

let isOpen = false
let initialised = false

export const init = () => {
    try {
        if (!window.tidioChatApi) {
            logger.error('Tidio Chat API not found')
            setTimeout(init, 5000)
            return
        }

        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', () => window.tidioChatApi?.hide())
        initialised = true
    } catch (err) {
        logger.error(
            'Failed to initialise Tidio Chat window.tidioChatApi',
            err as Error
        )
        setTimeout(init, 5000)
    }
}

export const togglePopup = () => {
    if (!initialised) {
        return
    }

    if (isOpen) {
        window.tidioChatApi?.close()
        isOpen = false
        return
    }

    isOpen = true
    window.tidioChatApi?.show()
    window.tidioChatApi?.open()
}

export const setVisitorData = (data: any) => {
    window.tidioChatApi?.setVisitorData(data)
}
