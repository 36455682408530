import { IconName } from 'Components/Icon'

type Tab = {
    label: string
    description: string
    icon: IconName
}

const tabs: Record<
    | 'overview'
    | 'details'
    | 'actions'
    | 'documents'
    | 'updates'
    | 'history'
    | 'processes',
    Tab
> = {
    overview: {
        label: 'Progress',
        description: 'See how this record is progressing through the workflow',
        icon: 'Process',
    },
    details: {
        label: 'Details',
        description: 'View and edit the details of this record',
        icon: 'List',
    },
    processes: {
        label: 'Workflows',
        description: `View this record's usage in workflows`,
        icon: 'Process',
    },
    actions: {
        label: 'Actions',
        description: 'Manage the actions associated with this record',
        icon: 'CircleCheck',
    },
    documents: {
        label: 'Documents',
        description: 'Manage the documents associated with this record',
        icon: 'Document',
    },
    updates: {
        label: 'Updates',
        description: 'Review the updates made to this record',
        icon: 'Comment',
    },
    history: {
        label: 'History',
        description: 'Review the history of this record',
        icon: 'TimeTravel',
    },
}

export { tabs }
