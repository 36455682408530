import { documentToggleFieldListOfName } from 'Utils'
import { createDefinitionFieldInput } from 'Utils/BusinessObjectDefinition'
import {
    CreateBusinessObjectDefinitionInput,
    FieldType as MutFieldType,
} from '__generated__'
import { State } from './types'

const updatesField = {
    name: 'Updates',
    description: 'Updates about this object',
    [MutFieldType.List]: {
        listOf: {
            name: 'Update',
            [MutFieldType.Updates]: {},
        },
    },
}

const documentsField = {
    name: 'Documents',
    description: 'Documents relating to this object',
    [MutFieldType.List]: {
        listOf: {
            name: documentToggleFieldListOfName,
            [MutFieldType.Document]: {
                constraints: [],
            },
        },
    },
}

type MakeMutationVariables = (
    state: Pick<State, 'definition' | 'enableDocuments' | 'enableUpdates'>
) => {
    input: CreateBusinessObjectDefinitionInput
}
const makeMutationVariables: MakeMutationVariables = ({
    definition,
    enableDocuments,
    enableUpdates,
}) => {
    const fields = definition.fields.map(createDefinitionFieldInput)

    if (enableUpdates) fields.push(updatesField)
    if (enableDocuments) fields.push(documentsField)
    return {
        input: {
            name: definition.name,
            label: definition.label,
            description: definition.description || undefined,
            fields,
        },
    }
}

export { makeMutationVariables }
