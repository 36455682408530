import { getDataColors } from 'Adapters/Freestyled'
import { decodeURIComponentObj } from 'Utils'
import { FieldConstraintType, FieldType } from '__generated__'
import { compact, uniqBy } from 'lodash'
import { pipe } from 'lodash/fp'
import { P, match } from 'ts-pattern'
import { useSandbox } from '../../Hooks/useSandbox'
import { ProcessDisplayFragment } from './__generated__/q'
import { ViewConfig } from './types'

export const getConfigSearchParam = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).find(([key]) => key === 'config')?.[1]

const allGroup = {
    id: 'all',
    label: 'No grouping',
    type: FieldType.Boolean, // Unused but required for the type
    groups: [],
}

export const useClearedConfig = (
    process: ProcessDisplayFragment
): ViewConfig => {
    const colors = getDataColors()
    const sandbox = useSandbox()

    const businessObjects = process.phases.flatMap(p => p.businessObjects.nodes)
    return {
        showCompleted: sandbox.isSandbox, // in sandbox, show completed by default
        filters: {},
        search: { term: '' },
        cardSettings: {
            fields: process.operatesUpon[0].fields.map(
                ({ id, name: label, type }) => ({
                    id,
                    label,
                    type,
                })
            ),
            selected: [],
        },
        grouping: {
            fields: process.operatesUpon[0].fields.reduce(
                (acc: ViewConfig['grouping']['fields'], field) =>
                    match(field)
                        .with(
                            { __typename: 'SelectFieldDefinition' },
                            ({ selectOptions, selectConstraints }) =>
                                selectConstraints.some(c =>
                                    match(c)
                                        .with(
                                            {
                                                type: FieldConstraintType.NumberOfSelections,
                                                max: 1,
                                            },
                                            () => true
                                        )
                                        .otherwise(() => false)
                                )
                                    ? [
                                          ...acc,
                                          {
                                              id: field.id,
                                              label: `Group by ${field.name}`,
                                              type: field.type,
                                              groups: selectOptions.map(
                                                  (o, i) => ({
                                                      id: o.id,
                                                      value: o.value,
                                                      color: colors[
                                                          i % colors.length
                                                      ],
                                                  })
                                              ),
                                          },
                                      ]
                                    : acc
                        )
                        .with(
                            { __typename: 'RelationFieldDefinition' },
                            field => [
                                ...acc,
                                {
                                    id: field.id,
                                    label: `Group by ${field.name}`,
                                    type: field.type,
                                    groups: uniqBy(
                                        compact(
                                            businessObjects
                                                .flatMap(bo =>
                                                    bo.fields.filter(
                                                        f =>
                                                            f.fieldDefinition
                                                                .id === field.id
                                                    )
                                                )
                                                .map((boField, i) =>
                                                    match(boField)
                                                        .with(
                                                            {
                                                                __typename:
                                                                    'BusinessObjectRelationField',
                                                                fieldDefinition:
                                                                    {
                                                                        id: field.id,
                                                                    },
                                                                relationValue:
                                                                    P.not(
                                                                        P.nullish
                                                                    ),
                                                            },
                                                            field => ({
                                                                id: field
                                                                    .relationValue
                                                                    .id,
                                                                value: field
                                                                    .relationValue
                                                                    ?.label,
                                                                color: colors[
                                                                    i %
                                                                        colors.length
                                                                ],
                                                            })
                                                        )
                                                        .otherwise(
                                                            () => undefined
                                                        )
                                                )
                                        ),
                                        bo => bo.id
                                    ),
                                },
                            ]
                        )
                        .otherwise(() => acc),
                [allGroup]
            ),
            selection: 'all',
        },
        ordering: {},
    }
}

const configFromSearchParams = (
    urlSearchParams: URLSearchParams
): ViewConfig | undefined =>
    match(getConfigSearchParam(urlSearchParams))
        .with(P.nullish, () => undefined)
        .otherwise(decodeURIComponentObj<ViewConfig>)

const mergeGroups =
    (secondary: ViewConfig) =>
    (priority: ViewConfig): ViewConfig => ({
        ...priority,
        grouping: {
            ...priority.grouping,
            fields: secondary.grouping.fields.reduce(
                (acc, field) =>
                    compact([
                        ...acc,
                        !acc.find(({ id }) => id === field.id) && field,
                    ]),
                priority.grouping.fields
            ),
        },
    })

export const useBuildConfig = (process: ProcessDisplayFragment) => {
    const clearedConfig = useClearedConfig(process)

    return (urlSearchParams: URLSearchParams): ViewConfig =>
        match(configFromSearchParams(urlSearchParams))
            .with(P.nullish, () => clearedConfig)
            .otherwise(paramConfig =>
                pipe(mergeGroups(clearedConfig))(paramConfig)
            )
}
