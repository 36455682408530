import { Provider, Viewport } from '@radix-ui/react-toast'
import { flex, styled } from 'Adapters/Freestyled'
import { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'

const ToastPortal: FC = () =>
    createPortal(
        <StyledViewport />,
        document.getElementById('toasts') || document.body
    )

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Provider duration={6000}>
            {children}

            {/* Generated toasts get injected here */}
            <ToastPortal />
        </Provider>
    )
}

const StyledViewport = styled(Viewport)`
    ${flex('column', 'flex-start', 'center')};
    gap: 1rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.5rem;
    list-style: none;
    z-index: 2147483647;
    outline: none;

    [data-screen-size='large'] & {
        bottom: 0;
    }
    [data-screen-size='medium'] &,
    [data-screen-size='small'] & {
        top: 0;
    }
`
