import { useAuth0 } from '@auth0/auth0-react'
import { resetCache } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useSandbox } from 'Hooks/useSandbox'
import { useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { authLogoutUrl } from './config'

export const useLogout = () => {
    const { logout: auth0Logout } = useAuth0()
    const removeCookie = useCookies(['cookieAccepted'])[2]
    const sandbox = useSandbox()

    const logout = useCallback(async () => {
        try {
            await resetCache()
            localStorage.clear()
            removeCookie('cookieAccepted')
            if (sandbox.state === 'active') sandbox.disable()
            auth0Logout({
                logoutParams: { returnTo: authLogoutUrl },
            })
        } catch (e) {
            const error = e as Error
            logger.error('Failed to', error)
        }
    }, [sandbox, removeCookie, auth0Logout])

    return { logout }
}
