import { FC } from 'react'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const RelationField: FC<
    Field<'RelationFieldDefinition'> & AbstractFieldChangeProps
> = ({ relationConstraints, ...common }) => (
    <AbstractField {...common} constraints={relationConstraints} />
)
