import { Text } from 'Components/Text'
import { FC } from 'react'

export const Required: FC = () => (
    <>
        <Text as="p" variant="bold-4">
            Required field
        </Text>
        <div />
    </>
)
