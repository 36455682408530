import { dayjs } from 'Adapters/DayJS'
import {
    BizObjUpdate_Bool_UniqueFragment,
    BizObjUpdate_Currency_UniqueFragment,
    BizObjUpdate_Date_UniqueFragment,
    BizObjUpdate_Document_UniqueFragment,
    BizObjUpdate_Email_UniqueFragment,
    BizObjUpdate_List_UniqueFragment,
    BizObjUpdate_Number_UniqueFragment,
    BizObjUpdate_Relation_UniqueFragment,
    BizObjUpdate_Select_UniqueFragment,
    BizObjUpdate_Telephone_UniqueFragment,
    BizObjUpdate_Text_UniqueFragment,
    BizObjUpdate_Url_UniqueFragment,
    BizObjUpdate_User_UniqueFragment,
} from 'Fragments/__generated__/BusinessObjectHistory'
import { difference, keyBy } from 'lodash'
import { FC } from 'react'
import { match } from 'ts-pattern'

import {
    FieldUpdatedHistoryEntry,
    ListFieldUpdatedHistoryEntry,
    SelectFieldUpdatedHistoryEntry,
} from './HistoryEntry'

type FieldUpdatedHistoryEntryPayload =
    | BizObjUpdate_Bool_UniqueFragment
    | BizObjUpdate_Currency_UniqueFragment
    | BizObjUpdate_Date_UniqueFragment
    | BizObjUpdate_Document_UniqueFragment
    | BizObjUpdate_Email_UniqueFragment
    | BizObjUpdate_List_UniqueFragment
    | BizObjUpdate_Number_UniqueFragment
    | BizObjUpdate_Relation_UniqueFragment
    | BizObjUpdate_Select_UniqueFragment
    | BizObjUpdate_Telephone_UniqueFragment
    | BizObjUpdate_Text_UniqueFragment
    | BizObjUpdate_Url_UniqueFragment
    | BizObjUpdate_User_UniqueFragment

type Props = {
    createdAt: string
    createdByName: string
    id: string
    fieldDefinitionName: string
    payload: FieldUpdatedHistoryEntryPayload
}

export const FieldUpdatedEntries: FC<Props> = ({
    createdAt,
    createdByName,
    fieldDefinitionName,
    id,
    payload,
}) => {
    return match(payload)
        .with(
            {
                __typename: 'BusinessObjectBooleanFieldUpdate',
            },
            ({ booleanUpdateValue, booleanUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={`${booleanUpdateValue}`}
                    previousValueString={`${booleanUpdatePreviousValue}`}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectCurrencyFieldUpdate',
            },
            ({ currencyUpdateValue, currencyUpdatedPreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={
                        currencyUpdateValue
                            ? `${currencyUpdateValue.currencyDetails.symbol}${currencyUpdateValue.amount}`
                            : undefined
                    }
                    previousValueString={
                        currencyUpdatedPreviousValue
                            ? `${currencyUpdatedPreviousValue.currencyDetails.symbol}${currencyUpdatedPreviousValue.amount}`
                            : undefined
                    }
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectDateFieldUpdate',
            },
            ({ dateUpdateValue, dateUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={
                        dateUpdateValue
                            ? dayjs(dateUpdateValue).format('D MMM YYYY')
                            : undefined
                    }
                    previousValueString={
                        dateUpdatePreviousValue
                            ? dayjs(dateUpdatePreviousValue).format(
                                  'D MMM YYYY'
                              )
                            : undefined
                    }
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectDocumentFieldUpdate',
            },
            ({ documentUpdateValue, documentUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={documentUpdateValue?.name}
                    previousValueString={documentUpdatePreviousValue?.name}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectEmailFieldUpdate',
            },
            ({ emailUpdateValue, emailUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={emailUpdateValue ?? undefined}
                    previousValueString={emailUpdatePreviousValue ?? undefined}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectNumberFieldUpdate',
            },
            ({ numberUpdateValue, numberUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={
                        numberUpdateValue === null
                            ? undefined
                            : numberUpdateValue?.toString()
                    }
                    previousValueString={
                        numberUpdatePreviousValue === null
                            ? undefined
                            : numberUpdatePreviousValue?.toString()
                    }
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectRelationFieldUpdate',
            },
            ({ relationUpdateValue, relationUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={relationUpdateValue?.label}
                    previousValueString={relationUpdatePreviousValue?.label}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectTelephoneFieldUpdate',
            },
            ({ telephoneUpdateValue, telephoneUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={
                        !telephoneUpdateValue?.countryCode &&
                        !telephoneUpdateValue?.number
                            ? undefined
                            : `[${telephoneUpdateValue.countryCode ?? ''}] ${
                                  telephoneUpdateValue.number ?? ''
                              }`
                    }
                    previousValueString={
                        !telephoneUpdatePreviousValue?.countryCode &&
                        !telephoneUpdatePreviousValue?.number
                            ? undefined
                            : `[${
                                  telephoneUpdatePreviousValue.countryCode ?? ''
                              }] ${telephoneUpdatePreviousValue.number ?? ''}`
                    }
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectTextFieldUpdate',
            },
            ({ textUpdateValue, textUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={textUpdateValue ?? undefined}
                    previousValueString={textUpdatePreviousValue ?? undefined}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectUrlFieldUpdate',
            },
            ({ urlUpdateValue, urlUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={urlUpdateValue ?? undefined}
                    previousValueString={urlUpdatePreviousValue ?? undefined}
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectUserFieldUpdate',
            },
            ({ userUpdateValue, userUpdatePreviousValue }) => (
                <FieldUpdatedHistoryEntry
                    key={id}
                    newValueString={
                        userUpdateValue ? userUpdateValue.name : undefined
                    }
                    previousValueString={
                        userUpdatePreviousValue
                            ? userUpdatePreviousValue.name
                            : undefined
                    }
                    userName={createdByName}
                    fieldName={fieldDefinitionName}
                    timestamp={createdAt}
                />
            )
        )
        .with(
            {
                __typename: 'BusinessObjectSelectFieldUpdate',
            },
            ({
                fieldDefinition,
                selectUpdateValue,
                selectUpdatePreviousValue,
            }) => {
                const optionsMap = keyBy(
                    fieldDefinition.__typename === 'SelectFieldDefinition'
                        ? fieldDefinition.options
                        : [],
                    'id'
                )

                const addedValues = difference(
                    selectUpdateValue,
                    selectUpdatePreviousValue
                ).map(id => optionsMap[id].value)

                const removedValues = difference(
                    selectUpdatePreviousValue,
                    selectUpdateValue
                ).map(id => optionsMap[id].value)

                return (
                    <SelectFieldUpdatedHistoryEntry
                        key={id}
                        userName={createdByName}
                        timestamp={createdAt}
                        fieldName={fieldDefinitionName}
                        addedValues={addedValues}
                        removedValues={removedValues}
                    />
                )
            }
        )
        .with(
            {
                __typename: 'BusinessObjectListFieldUpdate',
            },
            ({ listUpdateValue, listUpdatePreviousValue }) => (
                <ListFieldUpdatedHistoryEntry
                    key={id}
                    updateKind={
                        listUpdateValue === listUpdatePreviousValue
                            ? 'changed'
                            : listUpdateValue > listUpdatePreviousValue
                            ? 'added'
                            : 'removed'
                    }
                    userName={createdByName}
                    timestamp={createdAt}
                    fieldName={fieldDefinitionName}
                />
            )
        )
        .exhaustive()
}
