import { compact } from 'lodash'
import { always } from 'lodash/fp'
import { useCallback, useState } from 'react'
import { match } from 'ts-pattern'
import { v4 } from 'uuid'
import { removeAt, setAt } from '../../../../../../Utils'
import { WebhookActionType } from '../../../../../../__generated__'
import {
    WebhookFragment,
    useCreateWebhookMutation,
    useUpdateWebhookMutation,
} from './__generated__/q'
import { State, StepPayload, Steps } from './types'
import { getFieldValue } from './util'

type Props = {
    initialWebhook?: WebhookFragment
    onCompleted: (data: { webhook: any; signingSecret: string }) => void
}

const transformExistingWebhookActions = (
    actions: WebhookFragment['actions']
): State['actions'] => {
    return actions.map(action =>
        match(action)
            .with(
                {
                    payload: {
                        __typename: 'AddBusinessObjectToProcessWebhookPayload',
                    },
                },
                ({ payload: { businessObjectId, processId } }) => ({
                    type: WebhookActionType.AddBusinessObjectToProcess as const,
                    payload: {
                        businessObjectId,
                        processId,
                    },
                })
            )
            .with(
                {
                    payload: {
                        __typename: 'CreateActionWebhookPayload',
                    },
                },
                ({ payload: { name, description, dueDate, assignedTo } }) => ({
                    type: WebhookActionType.CreateAction as const,
                    payload: {
                        name,
                        description: description ?? undefined,
                        dueDate: dueDate ?? undefined,
                        assignedTo: assignedTo?.id ?? undefined,
                    },
                })
            )
            .with(
                {
                    payload: {
                        __typename: 'FilterWebhookPayload',
                    },
                },
                ({ payload: { selector, operator, value } }) => ({
                    type: WebhookActionType.Filter as const,
                    payload: {
                        selector,
                        operator,
                        value,
                    },
                })
            )
            .with(
                {
                    payload: {
                        __typename: 'CreateBusinessObjectInputOutput',
                    },
                },
                ({ payload: { businessObjectDefinition, fields } }) => ({
                    type: WebhookActionType.CreateBusinessObject as const,
                    payload: {
                        businessObjectDefinitionId: businessObjectDefinition.id,
                        fields: fields.map(field => ({
                            id: field.fieldDefinition.id,
                            type: field.type,
                            value: getFieldValue(field),
                        })),
                    },
                })
            )
            .otherwise(() => {
                throw new Error('Unknown action type')
            })
    )
}

export const useMutateWebhook = ({ initialWebhook, onCompleted }: Props) => {
    const [name, setName] = useState(initialWebhook?.name ?? '')
    const [signingSecret, setSigningSecret] = useState('')
    const [actions, setActions] = useState<State['actions']>(
        initialWebhook
            ? transformExistingWebhookActions(initialWebhook.actions)
            : []
    )

    const [createWebhook, { loading: creating }] = useCreateWebhookMutation({
        onCompleted: data =>
            onCompleted({ webhook: data.createWebhook, signingSecret }),
    })

    const [updateWebhook, { loading: updating }] = useUpdateWebhookMutation({
        onCompleted: data =>
            onCompleted({ webhook: data.updateWebhook, signingSecret }),
    })

    const handleCreate = useCallback(() => {
        createWebhook({
            variables: {
                input: {
                    name,
                    ...(signingSecret && { signingSecret }),
                    actions: compact(
                        actions.map(action =>
                            match(action)
                                .with(
                                    { type: 'createAction' },
                                    ({ payload }) => ({
                                        createAction: payload,
                                    })
                                )
                                .with(
                                    { type: 'createBusinessObject' },
                                    ({ payload }) => ({
                                        createBusinessObject: {
                                            businessObjectDefinitionId:
                                                payload.businessObjectDefinitionId,
                                            fields: payload.fields.map(
                                                ({ type, id, value }) => ({
                                                    [type]: {
                                                        fieldDefinitionId: id,
                                                        value,
                                                    },
                                                })
                                            ),
                                        },
                                    })
                                )
                                .with({ type: 'filter' }, ({ payload }) => ({
                                    filter: payload,
                                }))
                                .with(
                                    { type: 'addBusinessObjectToProcess' },
                                    ({ payload }) => ({
                                        addBusinessObjectToProcess: payload,
                                    })
                                )
                                .exhaustive()
                        )
                    ),
                },
            },
        })
    }, [actions, createWebhook, name, signingSecret])

    const handleUpdate = useCallback(() => {
        if (!initialWebhook) return

        updateWebhook({
            variables: {
                input: {
                    id: initialWebhook?.id,
                    name,
                    ...(signingSecret && { signingSecret }),
                    actions: compact(
                        actions.map(action =>
                            match(action)
                                .with(
                                    { type: 'createAction' },
                                    ({ payload }) => ({
                                        createAction: payload,
                                    })
                                )
                                .with(
                                    { type: 'createBusinessObject' },
                                    ({ payload }) => ({
                                        createBusinessObject: {
                                            businessObjectDefinitionId:
                                                payload.businessObjectDefinitionId,
                                            fields: payload.fields.map(
                                                ({ type, id, value }) => ({
                                                    [type]: {
                                                        fieldDefinitionId: id,
                                                        value,
                                                    },
                                                })
                                            ),
                                        },
                                    })
                                )
                                .with({ type: 'filter' }, ({ payload }) => ({
                                    filter: payload,
                                }))
                                .with(
                                    { type: 'addBusinessObjectToProcess' },
                                    ({ payload }) => ({
                                        addBusinessObjectToProcess: payload,
                                    })
                                )
                                .exhaustive()
                        )
                    ),
                },
            },
        })
    }, [actions, initialWebhook, name, signingSecret, updateWebhook])

    const mutate = initialWebhook ? handleUpdate : handleCreate

    const generateSigningSecret = useCallback(() => setSigningSecret(v4()), [])

    const addBlankStep = useCallback(
        (type: Steps) => () => {
            setActions([
                ...actions,
                match(type)
                    .with(
                        'createAction',
                        always({
                            type: 'createAction' as const,
                            payload: { name: '', description: '' },
                        })
                    )
                    .with(
                        'createBusinessObject',
                        always({
                            type: 'createBusinessObject' as const,
                            payload: {
                                businessObjectDefinitionId: '',
                                fields: [],
                            },
                        })
                    )
                    .with(
                        'filter',
                        always({
                            type: 'filter' as const,
                            payload: { selector: '', operator: '', value: '' },
                        })
                    )
                    .with(
                        'addBusinessObjectToProcess',
                        always({
                            type: 'addBusinessObjectToProcess' as const,
                            payload: { businessObjectId: '', processId: '' },
                        })
                    )
                    .exhaustive(),
            ])
        },
        [actions]
    )

    const removeAction = useCallback(
        (index: number) => () =>
            setActions(actions => removeAt(actions, index)),
        []
    )

    const updateActionField = useCallback(
        <
                Step extends Steps,
                Payload extends StepPayload<Step> = StepPayload<Step>
            >(
                index: number
            ) =>
            <K extends keyof Payload>(field: K) =>
            (value: Payload[K]) =>
                setActions(actions =>
                    setAt(actions, index, 'payload', (payload: {}) => ({
                        ...payload,
                        [field]: value,
                    }))
                ),
        []
    )

    const moveActionUp = useCallback(
        (index: number) => () => {
            setActions(actions => {
                if (index === 0) return actions
                return [
                    ...actions.slice(0, index - 1),
                    actions[index],
                    actions[index - 1],
                    ...actions.slice(index + 1),
                ]
            })
        },
        []
    )

    const moveActionDown = useCallback(
        (index: number) => () => {
            setActions(actions => {
                if (index === actions.length - 1) return actions
                return [
                    ...actions.slice(0, index),
                    actions[index + 1],
                    actions[index],
                    ...actions.slice(index + 2),
                ]
            })
        },
        []
    )

    const errors = compact([
        creating && 'Creating webhook...',
        updating && 'Updating webhook...',
        name === '' && 'Name is required',
        actions.length === 0 && 'At least one action is required',
    ])

    return {
        mutate,
        setName,
        setSigningSecret,
        setActions,
        removeAction,
        updateActionField,
        generateSigningSecret,
        addBlankStep,
        moveActionDown,
        moveActionUp,
        errors,
        state: {
            name,
            signingSecret,
            actions,
        },
    }
}
