import { Group } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { UpdateActionModal } from 'Features/UpdateActionModal'
import { Updates } from 'Features/Updates'
import { useResponder } from 'Hooks'
import { noop } from 'lodash'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { ActionDisplay } from './ActionDisplay'
import { ActionTimelineCardQuery } from './__generated__/query'
import { boLink, processLink } from './utils'

type Props = {
    action: ActionTimelineCardQuery['action']
    hideMenu: boolean
}

export const CardHeader: FC<Props> = ({ action, hideMenu }) => {
    const navigate = useNavigate()

    const [editOpen, setEditOpen] = useState(false)

    const [updatesOpen, setUpdatesOpen] = useState(false)

    const [detailsOpen, setDetailsOpen] = useState(false)

    const { isLarge } = useResponder()

    const hasUpdatesEnabled =
        !!action.extendedBy &&
        action.extendedBy.fields.find(field =>
            match(field.fieldDefinition)
                .with(
                    { __typename: 'ListFieldDefinition' },
                    field =>
                        field.listOf.__typename === 'UpdatesFieldDefinition'
                )
                .otherwise(() => false)
        )

    return (
        <Styled>
            <Text as="h4" variant="bold-4">
                {action.name}
            </Text>

            {!hideMenu ? (
                <>
                    {isLarge ? (
                        <>
                            {hasUpdatesEnabled && (
                                <IconButton
                                    iconName="Comment"
                                    onClick={() => setUpdatesOpen(true)}
                                />
                            )}
                            <Dropdown
                                renderOpenDropdownButton={() => (
                                    <IconButton
                                        iconName="More"
                                        disabled={!action}
                                    />
                                )}
                                renderContent={() => (
                                    <Group>
                                        <DropdownButton
                                            icon="Edit"
                                            text="Edit action"
                                            onSelect={() => setEditOpen(true)}
                                        />
                                        {match(action)
                                            .with(
                                                {
                                                    relatesTo: {
                                                        process: P.not(
                                                            P.nullish
                                                        ),
                                                    },
                                                },
                                                ({ relatesTo }) => (
                                                    <>
                                                        <DropdownButton
                                                            icon="Cube"
                                                            text="View Record"
                                                            onSelect={() =>
                                                                navigate(
                                                                    boLink(
                                                                        relatesTo.businessObject
                                                                    )
                                                                )
                                                            }
                                                        />
                                                        <DropdownButton
                                                            icon="Process"
                                                            text="View Workflow"
                                                            onSelect={() =>
                                                                navigate(
                                                                    processLink(
                                                                        relatesTo.process
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )
                                            )
                                            .with(
                                                { relatesTo: P.not(P.nullish) },
                                                ({ relatesTo }) => (
                                                    <DropdownButton
                                                        icon="Cube"
                                                        text="View Record"
                                                        onSelect={() =>
                                                            navigate(
                                                                boLink(
                                                                    relatesTo.businessObject
                                                                )
                                                            )
                                                        }
                                                    />
                                                )
                                            )
                                            .otherwise(() => null)}
                                    </Group>
                                )}
                            />
                        </>
                    ) : (
                        <IconButton
                            iconName="More"
                            disabled={!action}
                            onClick={() => {
                                setDetailsOpen(true)
                            }}
                        />
                    )}
                    {isLarge ? (
                        <>
                            <UpdateActionModal
                                open={editOpen}
                                closeModal={() => setEditOpen(false)}
                                onOpenChange={setEditOpen}
                                returnFocus={noop}
                                actionId={action.id}
                            />
                            <ControlledModal
                                title="Updates"
                                description="Action updates"
                                hideDescription
                                open={updatesOpen}
                                onOpenChange={setUpdatesOpen}
                                renderContent={() =>
                                    action.extendedBy ? (
                                        <StyledUpdates
                                            businessObjectId={
                                                action.extendedBy.id
                                            }
                                        />
                                    ) : null
                                }
                                returnFocus={noop}
                            />
                        </>
                    ) : (
                        <MinimalModal
                            open={detailsOpen}
                            onOpenChange={setDetailsOpen}
                            closeOnOverlayClick={false}
                            fullScreen
                        >
                            <ActionDisplay
                                action={action}
                                onUpdated={() => setDetailsOpen(false)}
                                onDeleted={() => setDetailsOpen(false)}
                            />
                        </MinimalModal>
                    )}
                </>
            ) : null}
        </Styled>
    )
}

const Styled = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    h4 {
        padding: 0.5rem 0.5rem 0.25rem 0.75rem;
        margin-right: auto;
    }

    .icon svg path {
        fill: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const StyledUpdates = styled(Updates)`
    max-height: 80vh;
    width: calc(100vw - 6rem);
    max-width: 37rem;
    padding-top: 1rem;
`
