import { CenteredSpinner } from 'Components/Spinner'
import { first } from 'lodash'
import { FC } from 'react'
import { UpdateActionContent } from './UpdateActionContent'
import { useAction_And_ExtensionQuery } from './__generated__/query'

type Props = {
    actionId: string
    onCompleted: () => void
    onDeleted: () => void
}

export const UpdateAction: FC<Props> = ({
    actionId,
    onCompleted,
    onDeleted,
}) => {
    const { data, loading, refetch } = useAction_And_ExtensionQuery({
        variables: { input: { id: actionId } },
    })

    const bodExtension = data
        ? first(data.businessObjectDefinitionExtensions)
        : undefined

    const action = data?.action
    const users = data?.users.map(user => ({
        ...user,
        isCurrentUser: user.id === data.currentUser.id,
    }))

    return action ? (
        <UpdateActionContent
            onCompleted={() => {
                refetch()
                onCompleted()
            }}
            onDeleted={onDeleted}
            bodExtension={bodExtension}
            action={action}
            users={users ?? []}
            userGroups={data.userGroups}
        />
    ) : loading ? (
        <CenteredSpinner />
    ) : (
        <div>Failed to load action</div>
    )
}
