import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import ELK from 'elkjs'
import { compact } from 'lodash'
import { FC, useEffect } from 'react'
import ReactFlow, {
    Background,
    BackgroundVariant,
    Controls,
    Edge,
    useEdgesState,
    useNodesState,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { useBusinessDomain_BusinessObjectDefinition_AllQuery } from './__generated__/query'
import { Field } from './types'

const isRelationField = (
    field: Field
): field is Field<'RelationFieldDefinition'> => field.type === 'relation'

const isListField = (field: Field): field is Field<'ListFieldDefinition'> =>
    field.type === 'list'

type ListOf = Field<'ListFieldDefinition'>['listOf']

const isRelationList = (
    listOf: ListOf
): listOf is Field<'RelationFieldDefinition'> => {
    return listOf.type === 'relation'
}

const getRelations = (field: Field<'RelationFieldDefinition'>) => {
    const typeConstraints = field.relationConstraints.filter(
        f => f.type === 'relationType'
    ) as unknown as { types: { id: string }[] }[]

    return typeConstraints.flatMap(c => c.types)
}

const nodeWidth = 169
const nodeHeight = 45

const getLayoutedElements = async <T,>(
    nodes: { id: string; data: T }[],
    edges: Edge[]
) => {
    const elk = new ELK()

    const graph = await elk.layout(
        {
            id: 'root',
            children: nodes.map(node => ({
                id: node.id,
                width: nodeHeight,
                height: nodeWidth,
            })),
            edges: edges.map(edge => ({
                id: edge.id,
                sources: [edge.source],
                targets: [edge.target],
            })),
        },
        {
            layoutOptions: { 'elk.algorithm': 'layered' },
        }
    )

    const posNodes = nodes.map(node => {
        const gnode = graph.children?.find(v => v.id === node.id)
        return {
            ...node,
            position: { x: gnode?.y!, y: gnode?.x! },
        }
    })

    return { nodes: posNodes, edges }
}

const VisualiseBusinessObjectDefinitions: FC<{}> = () => {
    const { data, loading } =
        useBusinessDomain_BusinessObjectDefinition_AllQuery()

    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges] = useEdgesState([])

    useEffect(() => {
        if (!data) return

        getLayoutedElements(
            data.businessObjectDefinitions.map((bod, i) => ({
                id: bod.id,
                data: { label: bod.name },
            })) ?? [],
            compact([
                ...(data?.businessObjectDefinitions.flatMap(bod =>
                    bod.fields.filter(isRelationField).flatMap(field =>
                        (field.type === 'relation'
                            ? getRelations(field)
                            : []
                        ).map(rel => ({
                            id: `${field.id}-${bod.id}-${rel.id}`,
                            source: bod.id,
                            target: rel.id,
                        }))
                    )
                ) ?? []),
                ...(data?.businessObjectDefinitions.flatMap(bod =>
                    bod.fields.filter(isListField).flatMap(field =>
                        isRelationList(field.listOf)
                            ? getRelations(field.listOf).map(rel => ({
                                  id: `${field.id}-${bod.id}-${rel.id}`,
                                  source: bod.id,
                                  target: rel.id,
                              }))
                            : null
                    )
                ) ?? []),
            ])
        ).then(({ nodes, edges }) => {
            setNodes(nodes)
            setEdges(edges)
        })
    }, [data, setNodes, setEdges])

    return (
        <Styled>
            <header>
                <menu>
                    <Breadcrumb area="datasets" />
                </menu>
                <Text as="h2" variant="bold-4">
                    Visualise Datasets
                </Text>
                <div />
            </header>
            {!data || loading ? (
                <CenteredSpinner />
            ) : (
                <ReactFlow
                    fitView
                    nodesDraggable={true}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <Background
                        variant={BackgroundVariant.Dots}
                        gap={12}
                        size={1}
                    />
                    <Controls />
                </ReactFlow>
            )}
        </Styled>
    )
}

const Styled = styled.section`
    height: 100%;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }
`

export { VisualiseBusinessObjectDefinitions }
