import { UserTracking } from 'Adapters/Amplitude/UserTracking'
import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { FrigadeProvider } from 'Features/Frigade'
import { useIsFreshTenant, useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { MainRoutes } from './MainRoutes'
import { LargeWorkspaceMenu, SmallWorkspaceMenu } from './WorkspaceMenu'

type Props = {
    workspaceControlOpen: boolean
    setWorkspaceControlOpen: (open: boolean) => void
}

export const AppMain: FC<Props> = ({
    workspaceControlOpen,
    setWorkspaceControlOpen,
}) => {
    const isFreshTenant = useIsFreshTenant()
    const { screenSize } = useResponder()

    return isFreshTenant === undefined ? (
        <StyledFullPageSpinner>
            <CenteredSpinner />
        </StyledFullPageSpinner>
    ) : (
        <FrigadeProvider>
            {match(screenSize)
                .with('large', () => (
                    <StyledLarge>
                        <UserTracking />
                        <LargeWorkspaceMenu
                            workspaceControlOpen={workspaceControlOpen}
                            setWorkspaceControlOpen={setWorkspaceControlOpen}
                        />
                        <MainRoutes isFreshTenant={isFreshTenant} />
                    </StyledLarge>
                ))
                .otherwise(() => (
                    <StyledSmall>
                        <UserTracking />
                        <MainRoutes isFreshTenant={isFreshTenant} />
                        <SmallWorkspaceMenu />
                    </StyledSmall>
                ))}
        </FrigadeProvider>
    )
}

const StyledLarge = styled.main`
    display: flex;

    > div {
        flex: 1;
    }
`

const StyledSmall = styled.main`
    display: grid;
    grid-template: minmax(0, 1fr) auto / minmax(0, 1fr);

    > div {
        overflow: hidden;
        height: 100%;
    }
`

const StyledFullPageSpinner = styled.div`
    ${flex('column', 'center', 'center')}
    height: 100dvh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
`
