import {
    getHandler,
    PlateElement,
    PlateElementProps,
    Value,
} from '@udecode/plate-common'
import { TMentionElement } from '@udecode/plate-mention'
import { focusOutline, styled } from 'Adapters/Freestyled'
import { bold5 } from 'Components/Text'
import React, { forwardRef } from 'react'
import { useFocused, useSelected } from 'slate-react'

export interface MentionElementProps
    extends PlateElementProps<Value, TMentionElement> {
    prefix?: string
    onClick?: (mentionNode: any) => void
    renderLabel?: (mentionable: TMentionElement) => string
}

export const Mention = forwardRef<
    React.ElementRef<typeof PlateElement>,
    MentionElementProps
>(({ prefix = '@', renderLabel, className, onClick, ...props }, ref) => {
    const { children, element } = props

    const selected = useSelected()
    const focused = useFocused()

    return (
        <StyledMention
            $bold={element.children[0].bold === true}
            $italic={element.children[0].italic === true}
            $underline={element.children[0].underline === true}
            $selected={selected && focused}
            ref={ref}
            className={className}
            data-slate-value={element.value}
            contentEditable={false}
            onClick={getHandler(onClick, element)}
            as="span"
            {...props}
        >
            {prefix}
            {renderLabel ? renderLabel(element) : element.value}
            {children}
        </StyledMention>
    )
})

const StyledMention = styled(PlateElement)<{
    $selected: boolean
    $bold: boolean
    $italic: boolean
    $underline: boolean
}>`
    ${bold5};
    display: inline-block;
    padding: 0.125rem 0.375rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    vertical-align: baseline;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};

    ${({ $selected }) => $selected && focusOutline()};

    ${({ $bold }) => $bold && 'font-weight: 700'};

    ${({ $italic }) => $italic && 'font-style: italic'};

    ${({ $underline }) => $underline && 'text-decoration: underline'};
`
