import { dayjs } from 'Adapters/DayJS'
import { Text } from 'Components/Text'
import { last } from 'lodash'
import { ReactNode } from 'react'
import { match, P } from 'ts-pattern'
import { FieldType, Operator } from '__generated__'
import { Conditions, FieldDefDictionary } from '../types'

export const generateConditionStrings = (
    conditions: Conditions,
    fieldDefById: FieldDefDictionary,
    users: Record<string, string>
): ReactNode[] => {
    if (!conditions) return []

    return conditions.map((condition, i) => {
        return match(condition.valueSelector)
            .with({ fieldId: P.string }, ({ fieldId }) => {
                const field = fieldDefById[fieldId]
                const { name: fieldName, __typename: fieldType } = field

                const operatorString = generateOperatorString(
                    condition.operator,
                    condition.negate
                )

                const idToNameMap: Record<string, string> = { ...users }

                if (fieldType === 'SelectFieldDefinition') {
                    const options = field.options
                    options.forEach(option => {
                        idToNameMap[option.id] = option.value
                    })
                }

                return (
                    <Text as="li" variant={'regular-5'} key={i}>
                        if : <strong>{fieldName}</strong> {operatorString}{' '}
                        {generateDisplayStringFromWithJSON(
                            condition.with,
                            fieldDefById[fieldId].type,
                            idToNameMap
                        )}
                    </Text>
                )
            })
            .with({ value: P.string }, () => '')
            .with({ selectors: P.any }, ({ selectors }) => {
                const finalType = last(selectors)

                if (finalType?.__typename !== 'FieldValueSelector') return ''

                const finalField = fieldDefById[finalType.fieldId]

                if (!finalField) return ''

                const fieldPath = selectors.reduce((path, selector) => {
                    if (selector.__typename !== 'FieldValueSelector')
                        return path

                    const pathHere =
                        fieldDefById[selector.fieldId]?.name ?? 'Unknown'

                    return path ? `${path} → ${pathHere}` : pathHere
                }, '')

                const operatorString = generateOperatorString(
                    condition.operator,
                    condition.negate
                )

                const optionValueMap =
                    finalField.__typename === 'SelectFieldDefinition'
                        ? finalField.options.reduce(
                              (out, opts) => ({
                                  ...out,
                                  [opts.id]: opts.value,
                              }),
                              {}
                          )
                        : {}

                return (
                    <Text as="li" variant={'regular-5'} key={i}>
                        if : <strong>{fieldPath}</strong> {operatorString}{' '}
                        {generateDisplayStringFromWithJSON(
                            condition.with,
                            finalField.type,
                            {
                                ...users,
                                ...optionValueMap,
                            }
                        )}
                    </Text>
                )
            })
            .exhaustive()
    })
}

const generateOperatorString = (operator: Operator, negate: boolean) => {
    return match({ operator, negate })
        .with({ operator: Operator.Equals, negate: false }, () => 'equals')
        .with(
            { operator: Operator.Equals, negate: true },
            () => 'does not equal'
        )
        .with({ operator: Operator.Includes, negate: false }, () => 'includes')
        .with(
            { operator: Operator.Includes, negate: true },
            () => 'does not include'
        )
        .with(
            { operator: Operator.IsDefined, negate: false },
            () => 'is defined'
        )
        .with(
            { operator: Operator.IsDefined, negate: true },
            () => 'is not defined'
        )
        .with(
            { operator: Operator.GreaterThan, negate: false },
            () => 'is greater than'
        )
        .with(
            { operator: Operator.GreaterThan, negate: true },
            () => 'is not greater than'
        )
        .with(
            { operator: Operator.LessThan, negate: false },
            () => 'is less than'
        )
        .with(
            { operator: Operator.LessThan, negate: true },
            () => 'is not less than'
        )
        .exhaustive()
}

const generateDisplayStringFromWithJSON = (
    withJson: string,
    fieldType: FieldType,
    idToNameMap: Record<string, string>
) => {
    return match(fieldType)
        .with(FieldType.Boolean, () => withJson)
        .with(FieldType.Text, () => JSON.parse(withJson))
        .with(FieldType.Currency, () => JSON.parse(withJson))
        .with(FieldType.Number, () => JSON.parse(withJson))
        .with(FieldType.Date, () =>
            dayjs(JSON.parse(withJson)).format('DD MMM YYYY')
        )
        .with(FieldType.Select, () => {
            const result = JSON.parse(withJson)

            if (typeof result === 'string') {
                return `'${idToNameMap[result]}'` ?? 'Not found'
            }

            if (Array.isArray(result)) {
                return result
                    .map(id => `'${idToNameMap[id]}'` ?? 'Not found')
                    .join(', ')
            }

            return 'Not found'
        })
        .with(FieldType.User, () => {
            const id = JSON.parse(withJson) as string
            return idToNameMap[id] ? `'${idToNameMap[id]}'` : 'Not found'
        })
        .with(FieldType.Relation, () => {
            const id = JSON.parse(withJson) as string
            return idToNameMap[id] ? `'${idToNameMap[id]}'` : 'Not found'
        })
        .otherwise(() => withJson)
}
