import { flex, styled } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { useOrganisationUsers } from 'Hooks'
import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { compact, isEqual, uniqBy } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../../../EditFieldButton'
import { useUpdateBusinessObjectField } from '../../../useUpdateBusinessObjectField'
import { ListValue } from '../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useUserConstraints } from './useUserConstraints'

type Props = BODField<'UserFieldDefinition'> & {
    businessObjectId: string
    listName: string
    listId: string
    listValue: ListValue
    onComplete: () => void
}

const generateInitialValues = (
    listValue: ListValue
): { id: string; name: string }[] =>
    compact(
        listValue.map(field =>
            match(field)
                .with(
                    { __typename: 'BusinessObjectUserField' },
                    ({ userValue }) => ({
                        id: userValue?.id ?? '',
                        name: userValue?.name ?? '',
                    })
                )
                .otherwise(() => null)
        )
    )

export const UserListField: FC<Props> = ({
    businessObjectId,
    userConstraints,
    listName,
    listId,
    listValue,
    onComplete,
}) => {
    const { constraints, constraintsTooltip } = useUserConstraints({
        userConstraints,
    })

    const { users } = useOrganisationUsers()

    const [initialValues, setInitialValues] = useState(
        generateInitialValues(listValue)
    )
    const [usersList, setUsersList] = useState(generateInitialValues(listValue))

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const hasChanged = useMemo(() => {
        if (!isEqual(initialValues, usersList)) return true
        return false
    }, [usersList, initialValues])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
            list={usersList.map(({ name, id }) => (
                <StyledListItem key={id}>
                    <Avatar name={name} variant="blue" size="small" />
                    <span>{name}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setUsersList(prev =>
                                prev.filter(doc => doc.id !== id)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <Dropdown
                renderContent={() => (
                    <>
                        {users.map(user => (
                            <DropdownButton
                                key={user.id}
                                text={user.name}
                                onSelect={() => {
                                    setUsersList(prev =>
                                        uniqBy(
                                            [
                                                ...prev,
                                                {
                                                    id: user.id,
                                                    name: user.name,
                                                },
                                            ],
                                            'id'
                                        )
                                    )
                                }}
                            />
                        ))}
                    </>
                )}
                renderOpenDropdownButton={() => (
                    <StyledAddDocumentButton icon="User" text={'Add a User'} />
                )}
                variant="light"
            />

            <EditFieldButton
                disabled={!hasChanged || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            list: {
                                fieldDefinitionId: listId,
                                previousValue: initialValues.map(val => ({
                                    user: val.id,
                                })),
                                value: usersList.map(val => ({
                                    user: val.id,
                                })),
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            setInitialValues(usersList)
                            onComplete()
                        },
                    })
                }}
            />
        </ListFieldWrapper>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button {
        margin-left: auto;
    }
`
