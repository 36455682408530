import { Provider } from '@frigade/react'
import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { FC, ReactNode } from 'react'
import { P, match } from 'ts-pattern'
import { useCurrentUser_FrigadeProviderQuery } from './__generated__/query'
import { frigadeApiKey } from './config'

type Props = {
    children: ReactNode
}

export const FrigadeProvider: FC<Props> = ({ children }) => {
    const res = useCurrentUser_FrigadeProviderQuery()

    return match(res)
        .with({ loading: true }, () => (
            <StyledFullPageSpinner>
                <CenteredSpinner />
            </StyledFullPageSpinner>
        ))
        .with({ loading: false, data: P.nullish }, () => <>{children}</>)
        .with(
            { loading: false, data: P.not(P.nullish) },
            ({
                data: {
                    currentUser: { id },
                },
            }) => (
                <Provider userId={id} apiKey={frigadeApiKey}>
                    {children}
                </Provider>
            )
        )
        .exhaustive()
}

const StyledFullPageSpinner = styled.div`
    ${flex('column', 'center', 'center')}
    height: 100dvh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
`
