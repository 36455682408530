import { FC, useCallback } from 'react'
import { styled } from '../../Adapters/Freestyled'
import { logger } from '../../Adapters/Logger'
import { SimpleToast, useTriggerToast } from '../../Components/Toast'
import { CreateIntegrationState, IntegrationEditor } from '../IntegrationEditor'
import {
    useCreate_Integration_InstanceMutation,
    useDelete_Integration_InstanceMutation,
} from './__generated__/mutation'
import { Update_Integration_InstanceFragment } from './__generated__/UpdateIntegrationInstance'

type Props = {
    integration: Update_Integration_InstanceFragment
    onUpdated: () => void
}

export const UpdateIntegration: FC<Props> = ({ integration, onUpdated }) => {
    const [deleteIntegration, { loading: deleting }] =
        useDelete_Integration_InstanceMutation({
            variables: { input: { id: integration.id } },
        })
    const [createIntegration, { loading: creating }] =
        useCreate_Integration_InstanceMutation()

    const toast = useTriggerToast()

    const handleIntegrationUpdated = useCallback(
        async (updatedIntegration: CreateIntegrationState) => {
            try {
                if (deleting || creating) return

                await deleteIntegration()
                await createIntegration({
                    variables: {
                        input: {
                            ...updatedIntegration,
                            trigger: {
                                ...updatedIntegration.trigger,
                                filter: {
                                    ...updatedIntegration.trigger.filter,
                                    params: JSON.stringify(
                                        updatedIntegration.trigger.filter.params
                                    ),
                                },
                            },
                            action: {
                                ...updatedIntegration.action,
                                params: JSON.stringify(
                                    updatedIntegration.action.params
                                ),
                            },
                        },
                    },
                })
                onUpdated()
            } catch (err) {
                logger.error('Updating integration failed', err as Error, {
                    updatedIntegration,
                })
                toast.triggerToast()
            }
        },
        [
            createIntegration,
            creating,
            deleteIntegration,
            deleting,
            onUpdated,
            toast,
        ]
    )

    const handleIntegrationDeleted = useCallback(async () => {
        try {
            if (deleting || creating) return

            await deleteIntegration()
            onUpdated()
        } catch (err) {
            logger.error('Deleting integration failed', err as Error, {
                integration,
            })
            toast.triggerToast()
        }
    }, [creating, deleteIntegration, deleting, integration, onUpdated, toast])

    return (
        <>
            <Styled>
                <IntegrationEditor
                    lockTrigger
                    initialValues={{
                        action: {
                            provider: integration.action.provider,
                            name: integration.action.name,
                            params: JSON.parse(integration.action.params),
                        },
                        trigger: {
                            event: integration.trigger.event,
                            filter: {
                                params: JSON.parse(
                                    integration.trigger.filter.params
                                ),
                            },
                        },
                    }}
                    onSubmit={handleIntegrationUpdated}
                    additionalActions={[
                        { onClick: handleIntegrationDeleted, text: 'Delete' },
                    ]}
                />
            </Styled>
            <SimpleToast
                {...toast}
                onOpenChange={toast.setOpen}
                description="Updating integration failed"
            />
        </>
    )
}

const Styled = styled.div`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`
