import { Description } from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { styled } from 'Adapters/Freestyled'
import { regular4 } from 'Components/Text'
import { FC } from 'react'

type Props = {
    description: string
    hideDescription: boolean
}

export const ModalDescription: FC<Props> = ({
    description,
    hideDescription,
}) => {
    if (!hideDescription) {
        return <StyledDescription>{description}</StyledDescription>
    } else {
        return (
            <VisuallyHidden asChild>
                <Description>{description}</Description>
            </VisuallyHidden>
        )
    }
}

export const StyledDescription = styled(Description)`
    ${regular4};
    color: ${({ theme }) => theme.palette.text['01'].normal};
    padding-bottom: 0.5rem;
    max-width: 45rem;
`
