import { flex, styled } from 'Adapters/Freestyled'
import { bold6 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

type Props = {
    tooltip?: string
    className?: string
}

export const AIPowered: FC<Props> = ({ tooltip, className }) => {
    if (tooltip)
        return (
            <Tooltip
                children={<StyledAIPowered>✨ Powered by AI</StyledAIPowered>}
                content={tooltip}
                offset={10}
            />
        )
    else
        return (
            <StyledAIPowered className={className}>
                ✨ Powered by AI
            </StyledAIPowered>
        )
}

const StyledAIPowered = styled.span`
    ${bold6};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.75rem;
    color: white;
    background-color: ${({ theme }) => theme.palette.ai.primary};
    padding: 0.35rem 0.75rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    margin: 0;
`
