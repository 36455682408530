import { PlateElement, PlateElementProps } from '@udecode/plate-common'

export function Paragraph({
    className,
    children,
    ...props
}: PlateElementProps) {
    return (
        <PlateElement asChild className={className} {...props}>
            <p>{children}</p>
        </PlateElement>
    )
}
