import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'

type Props = {
    label: string
    description?: string
    htmlFor?: string
    input: ReactNode
    errorMessage?: string
}

const Field: FC<Props> = ({
    label,
    description,
    htmlFor,
    input,
    errorMessage,
}) => (
    <StyledField $hasDescription={!!description}>
        <Text as="label" variant="bold-4" htmlFor={htmlFor}>
            {label}
        </Text>

        {description && (
            <Text as="p" variant="regular-5">
                {description}
            </Text>
        )}

        {input}

        {errorMessage && (
            <Text as="span" variant="regular-6" className="error-message">
                {errorMessage}
            </Text>
        )}
    </StyledField>
)

const StyledField = styled.div<{ $hasDescription: boolean }>`
    display: flex;
    flex-direction: column;

    > label {
        padding-bottom: ${({ $hasDescription }) =>
            $hasDescription ? 0 : '0.5rem'};
    }

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: ${({ $hasDescription }) =>
            $hasDescription ? '0.5rem' : 0};
    }

    .error-message {
        color: ${({ theme }) => theme.palette.support['01'].normal};
        padding-top: 0.25rem;
    }
`

export { Field }
