import { Indicator, Item, Root } from '@radix-ui/react-radio-group'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    value: string
    options: { id: string; label: string }[]
    onValueChange: (value: string) => void
    name: string
    disabled?: boolean
    className?: string
}

export const RadioGroup: FC<Props> = ({
    name,
    onValueChange,
    options,
    value,
    disabled,
    className,
}) => (
    <StyledRadioGroup
        name={name}
        onValueChange={onValueChange}
        value={value}
        disabled={disabled}
        className={className}
    >
        {options.map(option => (
            <StyledRadioItemGroup key={option.id}>
                <StyledItem value={option.id} id={option.id}>
                    <StyledIndicator>
                        <Icon name="Check" />
                    </StyledIndicator>
                </StyledItem>
                <Text variant="regular-5" as="label" htmlFor={option.id}>
                    {option.label}
                </Text>
            </StyledRadioItemGroup>
        ))}
    </StyledRadioGroup>
)

const StyledRadioGroup = styled(Root)`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;
`

const StyledRadioItemGroup = styled.div`
    ${flex('row', 'flex-start', 'center')};
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    width: 100%;

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    > label {
        cursor: pointer;
        padding: 0.5rem;
        display: block;
        flex: 1;
    }
`

const StyledItem = styled(Item)`
    ${flex('row', 'center', 'center')};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 0.138rem solid ${({ theme }) => theme.palette.icon['02'].normal};
`

const StyledIndicator = styled(Indicator)`
    svg {
        max-width: none;
        height: 1.25rem;
        width: 1.25rem;

        path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }
    }
`
