import { styled } from 'Adapters/Freestyled'
import { NavCard } from 'Components/NavCard'
import { Text } from 'Components/Text'
import { FeatureName } from 'Features/FeatureName'
import { useResponder } from 'Hooks'
import { FC } from 'react'

const ModelManagerNav: FC = () => {
    const { isLarge } = useResponder()
    return (
        <Styled>
            {!isLarge && (
                <header>
                    <Text as="h2" variant="bold-2">
                        Model Manager
                    </Text>
                </header>
            )}
            <ul>
                <li>
                    <NavCard
                        to="processes"
                        heading="Workflows"
                        icon="Process"
                        background="process-card.jpg"
                        description={
                            <>
                                <FeatureName>Workflows</FeatureName> represent
                                what your business does. They define the steps
                                needed to fulfil business objectives, and the
                                criteria that must be met to deliver successful
                                outcomes.
                            </>
                        }
                    />
                </li>
                <li>
                    <NavCard
                        to="datasets"
                        heading="Datasets"
                        icon="Cube"
                        background="dataset-card.jpg"
                        description={
                            <>
                                <FeatureName>Datasets</FeatureName> represent
                                what your business has. They define the entities
                                that your business interacts with.
                            </>
                        }
                    />
                </li>
                <li>
                    <NavCard
                        to="actions"
                        heading="Actions"
                        icon="CircleCheck"
                        background="actions-card.jpg"
                        description={
                            <>
                                Augment your <FeatureName>actions</FeatureName>{' '}
                                with custom fields to capture additional
                                information.
                            </>
                        }
                    />
                </li>
                <li>
                    <NavCard
                        to="/welcome/templates"
                        heading="Templates"
                        icon="Cards"
                        background="template-card.jpg"
                        description="View and deploy our range of templates."
                    />
                </li>
            </ul>
        </Styled>
    )
}

const Styled = styled.section`
    padding: 2rem;
    height: 100%;
    overflow: auto;

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: 2rem;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        padding: 1rem 4vw;
    }

    [data-screen-size='small'] & ul,
    [data-screen-size='medium'] & ul {
        gap: 1rem;
    }

    > header {
        padding-bottom: 1rem;
    }
`

export { ModelManagerNav }
