import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { FC, useCallback } from 'react'
import { DeepPartial } from 'Utils/types'
import { CreateIntegrationState, IntegrationEditor } from '../IntegrationEditor'
import { useCreate_Integration_InstanceMutation } from './__generated__/mutation'

type Props = {
    initialValues: DeepPartial<CreateIntegrationState>
    onCreated: () => void
}

export const CreateIntegration: FC<Props> = ({ onCreated, initialValues }) => {
    const [createIntegration, { loading: creating }] =
        useCreate_Integration_InstanceMutation()

    const toast = useTriggerToast()

    const handleIntegrationUpdated = useCallback(
        async (integration: CreateIntegrationState) => {
            try {
                if (creating) return
                await createIntegration({
                    variables: {
                        input: {
                            ...integration,
                            trigger: {
                                ...integration.trigger,
                                filter: {
                                    ...integration.trigger.filter,
                                    params: JSON.stringify(
                                        integration.trigger.filter.params
                                    ),
                                },
                            },
                            action: {
                                ...integration.action,
                                params: JSON.stringify(
                                    integration.action.params
                                ),
                            },
                        },
                    },
                })
                onCreated()
            } catch (err) {
                logger.error('Creating integration failed', err as Error, {
                    updatedIntegration: integration,
                })
                toast.triggerToast()
            }
        },
        [createIntegration, creating, onCreated, toast]
    )

    return (
        <>
            <Styled>
                <IntegrationEditor
                    lockTrigger
                    initialValues={initialValues}
                    onSubmit={handleIntegrationUpdated}
                />
            </Styled>
            <SimpleToast
                {...toast}
                onOpenChange={toast.setOpen}
                description="Updating integration failed"
            />
        </>
    )
}

const Styled = styled.div`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`
