import * as Types from '../../__generated__/index';

import { gql } from '@apollo/client';
import { ComparatorFragmentDoc } from './Comparator';
export type Process_CompleteFragment = { __typename?: 'Process', id: string, description?: string | null, name: string, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', name: string, description?: string | null, phaseIndex: number, criteria: Array<{ __typename?: 'ActionPhaseCriterion', description: string } | { __typename?: 'FieldConditionPhaseCriterion', comparator: { __typename: 'BusinessObjectComparator', negate: boolean, operator: Types.Operator, with: string, valueSelector: { __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'DeepSelector', type: Types.ValueSelectorType, selectors: Array<{ __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType }> } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType } } } | { __typename?: 'ProcessDelegatePhaseCriterion' } | { __typename?: 'ProcessFanoutPhaseCriterion' }> }> };

export type Process_CatalogueFragment = { __typename?: 'Process', id: string, name: string, description?: string | null, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', activeBusinessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }>, usage: { __typename?: 'ProcessUsage', businessObjects: number } };

export const Process_CompleteFragmentDoc = gql`
    fragment Process_Complete on Process {
  id
  description
  name
  operatesUpon {
    id
    name
  }
  phases {
    name
    description
    phaseIndex
    criteria {
      ... on ActionPhaseCriterion {
        description
      }
      ... on FieldConditionPhaseCriterion {
        comparator {
          ...Comparator
        }
      }
    }
  }
}
    ${ComparatorFragmentDoc}`;
export const Process_CatalogueFragmentDoc = gql`
    fragment Process_Catalogue on Process {
  id
  name
  description
  operatesUpon {
    id
    name
  }
  phases {
    activeBusinessObjects: businessObjects(input: {excludeCompleted: true}) {
      meta {
        totalCount
      }
    }
  }
  usage {
    businessObjects
  }
}
    `;