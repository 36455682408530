import { LoadingDisplay } from 'Components/LoadingDisplay'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { DocDisplay } from './DocDisplay'
import { useSingleDocumentQuery } from './__generated__/SingleDocument'

type Props = {
    id: string
}

const SingleDocument: FC<Props> = ({ id }) => {
    const { data, loading } = useSingleDocumentQuery({
        variables: { input: { id } },
    })
    return match(loading)
        .with(true, () => <LoadingDisplay />)
        .otherwise(() =>
            match(data)
                .with(undefined, () => <div>Unable to retrieve document</div>)
                .otherwise(({ file }) => <DocDisplay file={file} />)
        )
}

export { SingleDocument }
