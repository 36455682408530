import { ViewMode } from 'Components/DocumentPicker/types'
import { DocumentMetadata } from 'Components/Documents'
import { FC } from 'react'
import { ListItem } from './ListItem'
import { Thumbnail } from './Thumbnail'

type Props = {
    document: DocumentMetadata
    viewMode: ViewMode
    selected: boolean
    toggleSelection: (document: DocumentMetadata) => void
}

export const DisplayDocument: FC<Props> = ({
    document,
    viewMode,
    selected,
    toggleSelection,
}) => {
    return viewMode === 'grid' ? (
        <Thumbnail
            document={document}
            selected={selected}
            toggleSelection={toggleSelection}
        />
    ) : (
        <ListItem
            document={document}
            selected={selected}
            toggleSelection={toggleSelection}
        />
    )
}
