import { TextInput } from 'Components/Input'
import { Field } from './Field'

type Props = {
    name: string
    label: string
    required?: boolean
    value: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    labelExtras?: React.ReactNode
    maxLength?: number
}

export const TextField: React.FC<Props> = ({
    name,
    label,
    required,
    value,
    onChange,
    labelExtras,
    maxLength,
}) => (
    <Field
        htmlFor={`bod-input-${name}`}
        name={label}
        required={required}
        labelExtras={labelExtras}
    >
        <TextInput
            id={`bod-input-${name}`}
            required={required}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
        />
    </Field>
)
