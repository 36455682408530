import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { fromEvent } from 'Utils'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

const TextField: FC<
    Field<'TextFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: NonNullable<
                    PatchBusinessObjectDefinitionFieldDefaultInput['text']
                >
            ) => void
        }
> = ({
    textDefaultValue,
    textConstraints,
    handleDefaultChanged,
    ...common
}) => {
    return (
        <AbstractField
            {...common}
            defaultDisplay={
                common.isEditing ? (
                    <TextInput
                        value={textDefaultValue ?? ''}
                        onChange={e => {
                            const value = fromEvent(e)

                            handleDefaultChanged({
                                fieldId: common.id,
                                previousValue: textDefaultValue,
                                nextValue: value ? value : null,
                            })
                        }}
                    />
                ) : (
                    <Text
                        as="p"
                        variant="regular-4"
                        className={!textDefaultValue ? 'none-set' : ''}
                    >
                        {textDefaultValue ?? 'No default value set'}
                    </Text>
                )
            }
            constraints={textConstraints}
        />
    )
}

export { TextField }
