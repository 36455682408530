import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { CreateIntegration } from 'Features/CreateIntegration'
import { UpdateIntegration } from 'Features/UpdateIntegration'
import { useModalControls } from 'Hooks'
import { TriggerEvent } from '__generated__'
import { first } from 'lodash'
import { FC, useCallback } from 'react'
import { ProcessDef_SingleProcessQuery } from '../__generated__/query'
import { Criterion } from './Criterion'

type Props = {
    processId: string
    phase: ProcessDef_SingleProcessQuery['process']['phases'][number]
    operatesUpon: ProcessDef_SingleProcessQuery['process']['operatesUpon'][number]
    refetch: () => Promise<unknown>
}

export const Phase: FC<Props> = ({
    processId,
    phase,
    operatesUpon,
    refetch,
}) => {
    const integrationModal = useModalControls()

    const handleIntegrationChanged = useCallback(async () => {
        await refetch()
        integrationModal.closeModal()
    }, [integrationModal, refetch])

    const phaseIntegration = first(phase.integrations)

    return (
        <>
            <ControlledModal
                {...integrationModal}
                onOpenChange={integrationModal.setOpen}
                title="Phase Integration"
                description=""
                hideDescription
                renderContent={() =>
                    phaseIntegration ? (
                        <UpdateIntegration
                            integration={phase.integrations[0]}
                            onUpdated={handleIntegrationChanged}
                        />
                    ) : (
                        <CreateIntegration
                            onCreated={handleIntegrationChanged}
                            initialValues={{
                                trigger: {
                                    event: TriggerEvent.ProcessBoEnteredPhase,
                                    filter: {
                                        params: {
                                            processId,
                                            phaseIndex: phase.phaseIndex,
                                        },
                                    },
                                },
                            }}
                        />
                    )
                }
            />
            <Styled>
                <header>
                    <div>
                        <Text as="h4" variant="bold-3">
                            {phase.name}
                        </Text>
                        {phase.description && (
                            <Text as="p" variant="regular-6">
                                {phase.description}
                            </Text>
                        )}
                    </div>
                    <IconButton
                        iconName="Integrations"
                        onClick={() => integrationModal.setOpen(true)}
                        ref={integrationModal.buttonRef}
                    />
                </header>
                <div>
                    <ul>
                        {phase.criteria.map((criterion, i) => (
                            <Criterion
                                key={i}
                                criterion={criterion}
                                operatesUpon={operatesUpon}
                            />
                        ))}
                    </ul>
                </div>
            </Styled>
        </>
    )
}

const Styled = styled.li`
    display: contents;

    > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 20rem;
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
        border-bottom-width: 0;
        padding: 1rem;
        grid-row: 1 / 2;
    }

    > header > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
        border-top-width: 0;
        width: 20rem;
        padding: 0 1rem 1rem;
        grid-row: 2 / 3;
        height: 100%;
        overflow: auto;
    }

    > div > ul {
        display: flex;
        flex-direction: column;
    }
`
