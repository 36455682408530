import { useAuth0 } from '@auth0/auth0-react'
import { LoadingDisplay } from 'Components/LoadingDisplay'
import { ErrorBoundary } from 'Features/ErrorBoundary'
import { useSandboxContext } from 'Hooks/useSandbox'
import { FC } from 'react'
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { App } from './App'
import { Landing } from './Landing'
import { Login } from './Landing/Login'
import { ConvertSandboxUser } from './Landing/Sandbox/ConvertSandboxUser'
import { EnterSandbox } from './Landing/Sandbox/EnterSandbox'
import { Logout } from './Logout'

export const Root: FC = () => {
    const { pathname } = useLocation()
    const [params] = useSearchParams()
    const { isLoading, isAuthenticated } = useAuth0()
    const { Provider, context } = useSandboxContext()

    return (
        <Provider value={context}>
            {isLoading ? (
                <LoadingDisplay />
            ) : (
                <Routes>
                    <Route
                        path="sandbox-signup"
                        element={
                            <ErrorBoundary page={'sandbox-signup'}>
                                <ConvertSandboxUser />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="login"
                        element={<Login returnTo={pathname} />}
                    />
                    <Route path="enter-sandbox" element={<EnterSandbox />} />
                    <Route
                        path="logout"
                        element={
                            <ErrorBoundary page={'logout'}>
                                <Logout />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/*"
                        element={
                            isAuthenticated ? (
                                <App />
                            ) : (
                                <ErrorBoundary page={'landing'}>
                                    <Landing
                                        auth={{
                                            returnTo: pathname,
                                            loginHint:
                                                params.get('email') ??
                                                undefined,
                                        }}
                                    />
                                </ErrorBoundary>
                            )
                        }
                    />
                </Routes>
            )}
        </Provider>
    )
}
