import { Select } from 'Components/Select'
import { FC } from 'react'
import { useAllBusinessObjectDefinitionsQuery } from '../../__generated__/q'

type Props = {
    name: string
    value: string | undefined
    onChange: (value: string) => void
}

export const DatasetSelector: FC<Props> = ({ name, value, onChange }) => {
    const { data } = useAllBusinessObjectDefinitionsQuery()

    const defs = data?.businessObjectDefinitions ?? []

    return (
        <Select
            id={name}
            value={value}
            onValueChange={onChange}
            name={name}
            options={[
                {
                    disabled: true,
                    text: '-- select a dataset --',
                    value: 'disabled',
                },
                ...defs.map(def => ({
                    text: def.name,
                    value: def.id,
                })),
            ]}
        />
    )
}
