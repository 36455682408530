import { SelectUI } from './types'

export const determineSelectUIType = (
    min: number | undefined,
    max: number | undefined
): SelectUI => {
    if (min === 0 && max === 1) return 'select'
    if (min === 1 && max === 1) return 'radio'
    return 'checkbox'
}
