import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Tooltip } from 'Components/Tooltip'
import { noop } from 'lodash'
import { ComponentProps, forwardRef } from 'react'
import { match } from 'ts-pattern'

type Props = {
    tooltipLabel: string
    icon: ComponentProps<typeof IconTextButton>['icon']
    total: number
    incomplete: number
    overdue?: number
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
    preventAction?: boolean // Prevent actions from being taken whilst a hint is open
}

const CompletionButton = forwardRef<HTMLButtonElement, Props>(
    (
        {
            tooltipLabel,
            icon,
            total,
            overdue,
            incomplete,
            onClick,
            preventAction = false,
        },
        ref
    ) => {
        const complete = total - incomplete

        return total ? (
            <Tooltip
                content={`${complete} of ${total} ${tooltipLabel}`}
                side="bottom"
            >
                {match({ total, complete, overdue })
                    .when(
                        ({ total, complete }) =>
                            total > 0 && total === complete,
                        () => (
                            <StyledCompleted
                                icon={icon}
                                variant="secondary"
                                text={`${complete}/${total}`}
                                size="xSmall"
                                disabled={!total}
                                onClick={preventAction ? noop : onClick}
                                hideTitle
                                ref={ref}
                            />
                        )
                    )
                    .when(
                        ({ overdue }) => (overdue ?? 0) > 0,
                        () => (
                            <StyledOverdue
                                icon={icon}
                                variant="secondary"
                                text={`${complete}/${total}`}
                                size="xSmall"
                                disabled={!total}
                                onClick={preventAction ? noop : onClick}
                                hideTitle
                                ref={ref}
                            />
                        )
                    )
                    .otherwise(() => (
                        <StyledIncomplete
                            icon={icon}
                            variant="secondary"
                            text={`${complete}/${total}`}
                            size="xSmall"
                            disabled={!total}
                            onClick={preventAction ? noop : onClick}
                            hideTitle
                            ref={ref}
                        />
                    ))}
            </Tooltip>
        ) : (
            <StyledDisabled
                icon={icon}
                variant="secondary"
                text={''}
                size="xSmall"
                disabled={true}
                onClick={noop}
                hideTitle
                ref={ref}
            />
        )
    }
)

const StyledCompleted = styled(IconTextButton)`
    color: ${({ theme }) => theme.palette.text.support02.normal};
    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support02.normal};
    }

    &:hover {
        color: ${({ theme }) => theme.palette.text.support02.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support02.active};
    }
`

const StyledIncomplete = styled(IconTextButton)`
    color: ${({ theme }) => theme.palette.text.support03.normal};
    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support03.active};
    }
`

const StyledOverdue = styled(IconTextButton)`
    color: ${({ theme }) => theme.palette.text.support01.normal};
    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support01.normal};
    }

    &:hover {
        color: ${({ theme }) => theme.palette.text.support01.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support01.active};
    }
`

const StyledDisabled = styled(IconTextButton)`
    pointer-events: none;
`

export { CompletionButton }
