import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import {
    checkBusinessObjectDefinitionUpdated,
    encodeGroupState,
} from 'Features/ActionView'
import { encodeFilterState } from 'Features/FilterBusinessObject'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    GetActionViewsQuery,
    useGetActionViewsQuery,
} from '../../../__generated__/query'
import { Extension, ViewSchema } from '../../../types'
import { ActionView } from '../types'
import { ManageViews } from './ManageViews'
import { presetViewsFactory } from './templates'

type Props = {
    currentUserId: string
    extension: Extension | undefined
}

const ApplyView: FC<Props> = ({ extension, currentUserId }) => {
    const [open, setOpen] = useState(false)

    const [, setSearchParams] = useSearchParams()

    const presetViews = useMemo(
        () => presetViewsFactory(currentUserId),
        [currentUserId]
    )

    const handleSelectPresetView = useCallback(
        (view: ActionView) => {
            setSearchParams({
                ...view.actionFilter,
                ...(Object.keys(view.actionExtensionFilter).length && {
                    filterState: encodeFilterState(view.actionExtensionFilter),
                }),
                display: view.display,
            })
            setOpen(false)
        },
        [setSearchParams]
    )

    const { data } = useGetActionViewsQuery({
        variables: { input: { type: ViewOfType.Actions } },
    })

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const {
        open: bodefChangedToastOpen,
        setOpen: setBodefChangedToastOpen,
        triggerToast: triggerBodefChangedToast,
    } = useTriggerToast()

    const handleSelectUserDefinedView = useCallback(
        (view: GetActionViewsQuery['views'][number]) => {
            const decodedView = JSON.parse(
                Buffer.from(view.configuration, 'base64').toString()
            )

            const isValidSchema = ViewSchema.safeParse(decodedView)

            if (!isValidSchema.success) {
                logger.error('Invalid view schema', isValidSchema.error)
                triggerErrorToast()
                return
            }

            if (extension) {
                const bodefHasChangedSinceLastEncoded = !decodedView.grouping
                    ? false
                    : checkBusinessObjectDefinitionUpdated(
                          decodedView.grouping,
                          extension.fields.filter(
                              field =>
                                  // Ignore updates
                                  !(
                                      field.__typename ===
                                          'ListFieldDefinition' &&
                                      field.listOf.__typename ===
                                          'UpdatesFieldDefinition'
                                  )
                          )
                      )

                if (bodefHasChangedSinceLastEncoded) {
                    triggerBodefChangedToast()
                    return
                }
            }

            setSearchParams({
                ...decodedView,
                filterState: encodeFilterState(decodedView.filterState),
                ...(decodedView.grouping && {
                    grouping: encodeGroupState(decodedView.grouping),
                }),
            })

            setOpen(false)
        },
        [
            extension,
            setSearchParams,
            triggerBodefChangedToast,
            triggerErrorToast,
        ]
    )

    return (
        <>
            <IconTextButton
                icon="View"
                text="Saved views"
                size="xSmall"
                onClick={() => setOpen(true)}
            />

            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled>
                    <section>
                        <Text as="p" variant="bold-6">
                            Preset Views
                        </Text>
                        {Object.values(presetViews).map(view => (
                            <IconTextButton
                                key={view.id}
                                text={view.name}
                                icon="View"
                                onClick={() => {
                                    handleSelectPresetView(view)
                                }}
                            />
                        ))}
                    </section>

                    {data?.views.length ? (
                        <section>
                            <Text as="p" variant="bold-6">
                                Saved Views
                            </Text>
                            {data.views.map(view => (
                                <IconTextButton
                                    key={view.id}
                                    text={view.name}
                                    icon="View"
                                    onClick={() => {
                                        handleSelectUserDefinedView(view)
                                    }}
                                />
                            ))}
                        </section>
                    ) : null}

                    <footer>
                        {data?.views.length ? (
                            <ManageViews views={data.views ?? []} />
                        ) : null}

                        <IconTextButton
                            text={'Done'}
                            onClick={() => setOpen(false)}
                            icon="Close"
                            variant="primary"
                            size="xSmall"
                        />
                    </footer>
                </Styled>
            </MinimalModal>

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'Unable to apply the view. Contact us if the problem persists.'
                }
            />

            <SimpleToast
                open={bodefChangedToastOpen}
                onOpenChange={setBodefChangedToastOpen}
                description={
                    'Action extensions have been updated since this view was saved. We reccomend you delete it and save a new one.'
                }
            />
        </>
    )
}

const Styled = styled.div`
    padding: 0 4vw 1rem;

    > section {
        padding-top: 1rem;
    }
    > section p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.25rem;
    }
    > section > button {
        width: 100%;
        justify-content: flex-start;
    }

    > footer {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        button {
            width: 100%;
        }
    }
`

export { ApplyView }
