import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon, IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    pageTitle: string
    children: React.ReactNode
    icon: IconName
}

export const ConnectSection: FC<Props> = ({ pageTitle, icon, children }) => {
    const navigate = useNavigate()
    return (
        <Styled>
            <header>
                <nav>
                    <IconButton
                        iconName="AngleLeft"
                        onClick={() => navigate('..')}
                        variant="secondary"
                    />
                    <div>
                        <Icon name={icon} />
                        <Text as="h2" variant="bold-2">
                            {pageTitle}
                        </Text>
                    </div>
                </nav>
                <div />
            </header>
            {children}
        </Styled>
    )
}

const Styled = styled.section`
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.75rem 2rem 0.75rem 2rem;
    }

    > header > nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    > header > nav > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }
`
