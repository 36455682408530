import { styled } from 'Adapters/Freestyled'
import modlifyLogo from 'Assets/modlify-full-logo.svg'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

export const HomeLink: FC = () => {
    const navigate = useNavigate()
    return (
        <Styled onClick={() => navigate('/')}>
            <img src={modlifyLogo} width="300" height="100" alt="Modlify" />
        </Styled>
    )
}

const Styled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1rem;
    height: 3rem;
    background-color: ${({ theme }) => theme.palette.brand['01'].normal};
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    img {
        height: 100%;
        width: auto;
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.brand['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.brand['01'].active};
    }
`
