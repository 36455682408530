import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

type Props = {
    progress: number
    loadingTimeSeconds?: number
}

export const LoadingBar: FC<Props> = ({
    progress,
    loadingTimeSeconds = 0.2,
}) => {
    return (
        <StyledWrapper>
            <StyledBarInner
                $progress={progress}
                $loadingTimeSeconds={loadingTimeSeconds}
            />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    width: 100%;
    height: 5px;
    background-color: transparent;
    overflow: hidden;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.ui['01'].hover};
`

const StyledBarInner = styled.div<{
    $progress: number
    $loadingTimeSeconds: number
}>`
    width: ${props => props.$progress}%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.brand['01'].active};
    transition: width ${({ $loadingTimeSeconds }) => `${$loadingTimeSeconds}s`};
`
