import actions from 'Assets/images/actions-screenshot.png'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { CreateActionModal } from 'Features/CreateActionModal'
import { FeatureName } from 'Features/FeatureName'
import { useModalControls } from 'Hooks'
import { noop } from 'lodash'
import { FC } from 'react'
import { useTriggerCreateModal } from './useTriggerCreateModal'

export const NoActions: FC = () => {
    const { open, setOpen, closeModal, openModal } = useModalControls()

    useTriggerCreateModal(openModal) // Triggers when navigating from welcome screen

    return (
        <>
            <EmptyStateCard
                title={'Actions'}
                paragraphs={[
                    <>
                        <FeatureName>Actions</FeatureName> represent atomic
                        items of work that need to be completed either as part
                        of a <FeatureName>workflow</FeatureName> or
                        independently. Think of them as tasks or todos.
                    </>,
                    'They can be assigned to team members, given a due date and have any number of other data fields attached to them.',
                    <>
                        <FeatureName>Actions</FeatureName> are generated
                        automatically when moving a{' '}
                        <FeatureName>record</FeatureName> through a{' '}
                        <FeatureName>workflow</FeatureName>, based on the phase
                        criteria. They can also be created at any time from the{' '}
                        <FeatureName>actions</FeatureName> page.
                    </>,
                ]}
                button={{
                    handler: openModal,
                    text: 'Create a new action',
                }}
                imageUrl={actions}
            />

            <CreateActionModal
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
                closeModal={closeModal}
            />
        </>
    )
}
