import { styled } from 'Adapters/Freestyled'
import { Drawer } from 'Components/Drawer'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useBusinessObjectDisplay } from 'Features/BusinessObjectDisplay'
import { ProcessDisplay } from 'Features/ProcessDisplay'
import { useQueryNav } from 'Hooks/useQueryNav'
import { noop } from 'lodash'
import { FC } from 'react'
import { useActionsTableProcessDisplayQuery } from './__generated__/q'

type Props = {
    isOpen: boolean
    open: (id: string) => void
    close: () => void
    id: string | undefined
}

const useProcessDisplay = (): Props => {
    const { values, nav, remove } = useQueryNav<
        ['id'],
        {
            id: string
        }
    >({ key: 'actions-table-process-display', path: ['id'] })

    return {
        open: id => nav({ id }),
        close: remove,
        isOpen: !!values,
        id: values?.id,
    }
}

const ActionsProcessDisplay: FC<Omit<Props, 'open'>> = ({
    isOpen,
    close,
    id,
}) => {
    const { data } = useActionsTableProcessDisplayQuery({
        variables: { processId: id ?? '' },
        skip: !id,
    })
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'actions-table-business-object-display',
        processId: id,
    })
    return (
        <Drawer
            open={isOpen}
            onOpenChange={o => !o && close()}
            returnFocus={noop}
            content={
                data ? (
                    <Styled>
                        <header>
                            <Text as="h2" variant="bold-1">
                                {data.process.name}
                            </Text>
                        </header>
                        <ProcessDisplay
                            process={data.process}
                            latestViewKey="ActionsTable"
                            boDisplayProps={boDisplayProps}
                        />
                    </Styled>
                ) : (
                    <CenteredSpinner />
                )
            }
        />
    )
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;

    > header {
        padding: 2rem 3rem;
    }

    > section {
        flex: 1;
    }
`

export { ActionsProcessDisplay, useProcessDisplay }
