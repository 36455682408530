import { match } from 'ts-pattern'
import { WebhookFragment } from './__generated__/q'

type Field = Extract<
    WebhookFragment['actions'][number],
    { __typename: 'CreateBusinessObjectWebhook' }
>['payload']['fields'][number]

export const getFieldValue = (field: Field) => {
    return match(field)
        .with(
            {
                __typename: 'CreateBusinessObjectTextFieldOutput',
            },
            f => f.textValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectBooleanFieldOutput',
            },
            f => f.booleanValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectDateFieldOutput',
            },
            f => f.dateValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectDocumentFieldOutput',
            },
            f => f.documentValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectEmailFieldOutput',
            },
            f => f.emailValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectNumberFieldOutput',
            },
            f => f.numberValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectRelationFieldOutput',
            },
            f => f.relationValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectSelectFieldOutput',
            },
            f => f.selectValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectTelephoneFieldOutput',
            },
            f =>
                f.telephoneValue
                    ? {
                          countryCode: f.telephoneValue.countryCode,
                          number: f.telephoneValue.number,
                      }
                    : undefined
        )
        .with(
            {
                __typename: 'CreateBusinessObjectUrlFieldOutput',
            },
            f => f.urlValue
        )
        .with(
            {
                __typename: 'CreateBusinessObjectUserFieldOutput',
            },
            f => f.userValue
        )
        .with({ __typename: 'CreateBusinessObjectCurrencyFieldOutput' }, f =>
            f.currencyValue
                ? {
                      amount: f.currencyValue.amount,
                      currencyCode: f.currencyValue.currencyCode,
                  }
                : undefined
        )
        .exhaustive()
}
