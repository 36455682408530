import { forwardRef } from 'react'
import { StyledTextInput } from './styles'

// NB. Currently no different to TextInput (other than input type)
// although this may change in the future

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean
}

export const NumberInput = forwardRef<HTMLInputElement, Props>(
    ({ hasError = false, ...props }, ref) => {
        return (
            <StyledTextInput $hasError={hasError}>
                <input type="number" {...props} ref={ref} />
            </StyledTextInput>
        )
    }
)
