import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { DocumentMetadata } from '../../types'
import { DocumentCard } from './DocumentCard'

type Props = {
    documents: DocumentMetadata[]
}

export const DocumentsList: FC<Props> = ({ documents }) => (
    <StyledDocumentsList>
        {documents.map(document => {
            return <DocumentCard document={document} key={document.id} />
        })}
    </StyledDocumentsList>
)

const StyledDocumentsList = styled.ul`
    width: 100%;
`
