import slugify from 'slugify'

export const boLink = (businessObject: {
    id: string
    definition: { name: string }
}) =>
    `/datasets/${slugify(businessObject.definition.name, {
        lower: true,
    })}/${businessObject.id}`

export const processLink = (process: { id: string }) =>
    `/processes/${slugify(process.id, {
        lower: true,
    })}`
