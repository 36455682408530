import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { RelationTypeConstraint } from '__generated__/'
import { useMemo } from 'react'

export const useRelationConstraints = (
    relationContraints: BODField<'RelationFieldDefinition'>['relationConstraints']
) => {
    const constraints = useMemo(() => {
        const relation = relationContraints.find(
            contraint => contraint.__typename === 'RelationTypeConstraint'
        ) as RelationTypeConstraint

        return {
            required: !!relationContraints.find(
                contraint => contraint.__typename === 'RequiredConstraint'
            ),
            relationId: relation.types[0].id,
        }
    }, [relationContraints])

    const constraintsTooltip = useMemo(() => {
        if (!constraints.required) return ''
        return 'Required.'
    }, [constraints])

    return {
        constraints,
        constraintsTooltip,
    }
}
