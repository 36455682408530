import {
    useMarkToolbarButton,
    useMarkToolbarButtonState,
} from '@udecode/plate-common'
import { IconName } from 'Components/Icon'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { StyledPressableIconButton } from '../styles'

const modKey = navigator.userAgent.includes('Mac') ? 'CMD' : 'CTRL'

type MarkType = 'bold' | 'italic' | 'underline' | 'strikethrough'

type Props = {
    mark: MarkType
}

const markMap: Record<
    MarkType,
    { icon: IconName; displayName: string; keyboardShortcut: string }
> = {
    bold: {
        icon: 'Bold',
        displayName: 'Bold',
        keyboardShortcut: `${modKey} + B`,
    },
    italic: {
        icon: 'Italic',
        displayName: 'Italic',
        keyboardShortcut: `${modKey} + I`,
    },
    underline: {
        icon: 'Minus',
        displayName: 'Underline',
        keyboardShortcut: `${modKey} + U`,
    },
    strikethrough: {
        icon: 'Strikethrough',
        displayName: 'Strikethrough',
        keyboardShortcut: `${modKey} + SHIFT + X`,
    },
}

export const Mark: FC<Props> = ({ mark }) => {
    const buttonState = useMarkToolbarButtonState({ nodeType: mark })
    const { props } = useMarkToolbarButton(buttonState)

    return (
        <Tooltip
            content={
                <span>
                    {markMap[mark].displayName} :{' '}
                    <kbd>{markMap[mark].keyboardShortcut}</kbd>
                </span>
            }
        >
            <StyledPressableIconButton
                iconName={markMap[mark].icon}
                $pressed={props.pressed}
                variant="secondary"
                onClick={props.onClick}
                onMouseDown={props.onMouseDown}
                type="button"
            />
        </Tooltip>
    )
}
