import { Group } from '@radix-ui/react-dropdown-menu'
import { IconButton } from 'Components/Button'
import { Dropdown, DropdownSortButton } from 'Components/Dropdown'
import { Tooltip } from 'Components/Tooltip'
import { FC, useState } from 'react'
import { Field, SortOptions } from '../../types'

type Props = {
    sort: SortOptions
    updateSort: (field: Field) => void
}

export const Sort: FC<Props> = ({ sort, updateSort }) => {
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

    return (
        <Dropdown
            variant="light"
            side="left"
            align="start"
            open={dropdownMenuOpen}
            onOpenChange={setDropdownMenuOpen}
            renderInSpan // needed for tooltip
            renderOpenDropdownButton={() => (
                <Tooltip content="Sort">
                    <IconButton iconName={'Sort'} variant="secondary" />
                </Tooltip>
            )}
            renderContent={() => (
                <Group>
                    <DropdownSortButton
                        text={'Name'}
                        onSelect={() => updateSort('name')}
                        order={sort.field === 'name' ? sort.order : undefined}
                        closeOnSelect={false}
                    />

                    <DropdownSortButton
                        text={'Added on'}
                        onSelect={() => updateSort('addedOn')}
                        order={
                            sort.field === 'addedOn' ? sort.order : undefined
                        }
                        closeOnSelect={false}
                    />

                    <DropdownSortButton
                        text={'File size'}
                        onSelect={() => updateSort('sizeInBytes')}
                        order={
                            sort.field === 'sizeInBytes'
                                ? sort.order
                                : undefined
                        }
                        closeOnSelect={false}
                    />

                    <DropdownSortButton
                        text={'File type'}
                        onSelect={() => updateSort('fileType')}
                        order={
                            sort.field === 'fileType' ? sort.order : undefined
                        }
                        closeOnSelect={false}
                    />
                </Group>
            )}
        />
    )
}
