import { NavTabs } from 'Components/NavTabs'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { WorkspaceNameForm } from 'Features/WorkspaceName'
import { useResponder } from 'Hooks'
import { compact } from 'lodash'
import { FC } from 'react'
import styled from 'styled-components'
import { match, P } from 'ts-pattern'
import { useManageWorkspaceQuery } from './__generated__/q'
import { Automation } from './Automation'
import { Connect } from './Connect/Connect'
import { ManageWorkspaceHeader } from './Header'
import { Members } from './Members'
import { ModelManager } from './ModelManager'

const commonTabs = () =>
    compact([
        {
            label: 'Members',
            description: 'Manage members & teams',
            icon: 'User' as const,
            link: {
                to: 'members',
            },
            route: {
                path: 'members/*',
                element: <SmallScreenWarning element={<Members />} />,
            },
        },
        {
            label: 'Automation',
            description: 'Manage automated tasks',
            icon: 'TimeTravel' as const,
            link: {
                to: 'automation',
            },
            route: {
                path: 'automation/*',
                element: <SmallScreenWarning element={<Automation />} />,
            },
        },
        {
            label: 'Connect',
            description: 'Integrate with external systems',
            icon: 'TimeTravel' as const,
            link: {
                to: 'connect',
            },
            route: {
                path: 'connect/*',
                element: <SmallScreenWarning element={<Connect />} />,
            },
        },
    ])

const ManageWorkspace: FC = () => {
    const { data } = useManageWorkspaceQuery()
    const { screenSize } = useResponder()

    return match(data)
        .with(
            { currentUser: { activeTenant: P.not(P.nullish) } },
            ({ currentUser }) => {
                return match(screenSize)
                    .with('large', () => (
                        <StyledLarge>
                            <ManageWorkspaceHeader
                                tenant={currentUser.activeTenant.tenant}
                            />
                            <NavTabs
                                tabs={[
                                    {
                                        label: 'Model',
                                        description: 'Workspace definitions',
                                        icon: 'Cards' as const,
                                        link: {
                                            to: '.',
                                            end: true,
                                        },
                                        route: {
                                            path: '/*',
                                            element: <ModelManager />,
                                        },
                                    },
                                    ...commonTabs(),
                                ]}
                                redirections={['model/*']}
                            />
                        </StyledLarge>
                    ))
                    .otherwise(() => (
                        <StyledSmall>
                            <NavTabs
                                tabs={[
                                    {
                                        label: 'Details',
                                        description: 'Workspace details',
                                        icon: 'List' as const,
                                        link: {
                                            to: '.',
                                            end: true,
                                        },
                                        route: {
                                            path: '/',
                                            element: (
                                                <StyledDetails>
                                                    <header>
                                                        <Text
                                                            as="h2"
                                                            variant="bold-2"
                                                        >
                                                            Workspace details
                                                        </Text>
                                                    </header>
                                                    <WorkspaceNameForm
                                                        tenant={
                                                            currentUser
                                                                .activeTenant
                                                                ?.tenant
                                                        }
                                                    />
                                                </StyledDetails>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Model',
                                        description: 'Workspace definitions',
                                        icon: 'Cards' as const,
                                        link: {
                                            to: 'model',
                                        },
                                        route: {
                                            path: 'model/*',
                                            element: <ModelManager />,
                                        },
                                    },
                                    ...commonTabs(),
                                ]}
                            />
                        </StyledSmall>
                    ))
            }
        )
        .otherwise(() => <CenteredSpinner />)
}

const StyledLarge = styled.div`
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr) auto;
    align-items: center;

    > header {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    > nav {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        padding: 1rem 2rem 0;
        border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > section {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
    }
`
const StyledSmall = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    align-items: center;

    > nav {
        grid-row: 3 / 4;
    }

    > section {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        overflow: auto;
    }
`

const StyledDetails = styled.div`
    padding: 1rem 4vw;
    height: 100%;
    overflow: auto;

    > header {
        padding-bottom: 1rem;
    }
`

export { ManageWorkspace }
