import { Plate, Value } from '@udecode/plate-common'
import { FC } from 'react'
import { Editor } from './Editor'
import { plugins } from './config'
import { Attachment } from './types'

type CommonProps = {
    id: string // We need to provide an ID when using multiple instances of this component
    className?: string
}

type ControlledProps = {
    onChange?: (value: Value) => void
    value?: Value
    readonly?: never
    attachments: Attachment[]
    onAttachmentAdded?: (attachments: Attachment[]) => void
    handleSubmit?: never
    disabled?: never
    initialValue?: never
} & CommonProps

type EditProps = {
    onChange?: never
    value?: never
    attachments: Attachment[]
    onAttachmentAdded?: (attachments: Attachment[]) => void
    handleSubmit: (newUpdateValue: Value) => void
    disabled?: boolean
    readonly?: never
    initialValue?: never
} & CommonProps

type ReadonlyProps = {
    onChange?: never
    value?: never
    readonly: true
    attachments?: never
    onAttachmentAdded?: never
    handleSubmit?: never
    disabled?: never
    initialValue?: Value
} & CommonProps

type Props = EditProps | ReadonlyProps | ControlledProps

export const RichText: FC<Props> = ({
    attachments,
    onAttachmentAdded,
    handleSubmit,
    readonly,
    disabled,
    id,
    onChange,
    value,
    className,
    initialValue,
}) => {
    return (
        <Plate
            id={id}
            plugins={plugins}
            readOnly={readonly}
            onChange={onChange}
            value={value}
        >
            <Editor
                readonly={!!readonly}
                attachments={attachments ?? []}
                handleSubmit={handleSubmit}
                onAttachmentAdded={onAttachmentAdded}
                disabled={!!disabled}
                className={className}
                initialValue={initialValue}
            />
        </Plate>
    )
}
