export const sizes = [
    '0-5 people',
    '6-10 people',
    '11-20 people',
    '21-50 people',
    '51-100 people',
    '101-500 people',
    '501-1000 people',
    'More than 1000 people',
]
