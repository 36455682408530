import { useFlow } from '@frigade/react'
import { useSandbox } from 'Hooks/useSandbox'
import { useEffect, useMemo } from 'react'
import { useOnboardingProgressQuery } from './__generated__/query'
import { FLOW_IDENTIFIER } from './constants'

export const useMarkProgress = () => {
    const { data } = useOnboardingProgressQuery({
        fetchPolicy: 'cache-and-network',
        variables: { businessObjectInput: {} },
    })

    const sandbox = useSandbox()

    const onboardingProgress = useMemo(() => {
        return {
            hasUpgraded: !sandbox.isSandbox,
            hasSetOrgName:
                data?.currentUser.activeTenant &&
                data.currentUser.activeTenant.tenant.name !== 'Sandbox',
            hasSetName:
                data?.currentUser.name &&
                data.currentUser.name !== 'Sandbox User',
            hasCreatedProcess: (data?.processCount ?? 0) > 0,
            hasCreatedRecord: (data?.businessObjectCount ?? 0) > 0,
            hasAddedRecordToProcess:
                (data?.businessObjectInProcessCount ?? 0) > 0,
        }
    }, [data, sandbox])

    const { flow } = useFlow(FLOW_IDENTIFIER)

    const upgradeFromSandbox = flow?.steps.get('upgrade-from-sandbox')

    const setName = flow?.steps.get('set-name')

    const setOrgName = flow?.steps.get('name-workspace')

    const createProcess = flow?.steps.get('setup-workspace')

    const createRecord = flow?.steps.get('add-data')

    const addRecordToProcess = flow?.steps.get('use-process')

    useEffect(() => {
        if (
            onboardingProgress.hasUpgraded &&
            upgradeFromSandbox &&
            !upgradeFromSandbox.$state.completed
        )
            upgradeFromSandbox?.complete()

        if (
            onboardingProgress.hasSetName &&
            setName &&
            !setName.$state.completed
        )
            setName.complete()

        if (
            onboardingProgress.hasSetOrgName &&
            setOrgName &&
            !setOrgName.$state.completed
        ) {
            setOrgName.complete()
        }

        if (
            onboardingProgress.hasCreatedProcess &&
            createProcess &&
            !createProcess.$state.completed
        )
            createProcess.complete()

        if (
            onboardingProgress.hasCreatedRecord &&
            createRecord &&
            !createRecord.$state.completed
        )
            createRecord.complete()

        if (
            onboardingProgress.hasAddedRecordToProcess &&
            addRecordToProcess &&
            !addRecordToProcess.$state.completed
        )
            addRecordToProcess.complete()
    }, [
        addRecordToProcess,
        createProcess,
        createRecord,
        onboardingProgress.hasAddedRecordToProcess,
        onboardingProgress.hasCreatedProcess,
        onboardingProgress.hasCreatedRecord,
        onboardingProgress.hasSetName,
        onboardingProgress.hasSetOrgName,
        onboardingProgress.hasUpgraded,
        setName,
        setOrgName,
        upgradeFromSandbox,
    ])
}
