import { FieldCommonWrapper } from './Common'
import { FieldProps } from './types'

export const UpdatesFieldInput: React.FC<FieldProps<'updates'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
}) => (
    <FieldCommonWrapper
        field={field}
        index={index}
        onChange={onChange}
        onRemoved={onRemoved}
        label={name}
    />
)
