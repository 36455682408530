import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DropDownTextButton } from 'Components/Button/DropDownTextButton'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { CreateBusinessObjectDef } from 'Features/CreateBusinessObjectDef/CreateBusinessObjectDef'
import { CreateProcessInput } from '__generated__'
import { FC, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { Field } from '../Field'
import { useProcessCreator_BusinessObjectsQuery } from '../__generated__/query'

type Props = {
    value: CreateProcessInput['operatesUpon'][number] | undefined
    onSelect: (id: string) => void
}

const BODefSelect: FC<Props> = ({ value, onSelect }) => {
    const createBORef = useRef<HTMLButtonElement>(null)
    const { data } = useProcessCreator_BusinessObjectsQuery()
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [createBOOpen, setCreateBOOpen] = useState(false)

    return (
        <>
            <Field
                label="Dataset"
                description="Select from your existing datasets or create a new one."
                input={match(data)
                    .with(undefined, () => (
                        <Styled>
                            <IconTextButton
                                text="Loading"
                                icon="AngleDown"
                                iconRight
                                variant="secondary"
                                disabled
                            />
                        </Styled>
                    ))
                    .with({ businessObjectDefinitions: [] }, () => null)
                    .otherwise(({ businessObjectDefinitions: defs }) => (
                        <Styled>
                            <div>
                                <Dropdown
                                    open={optionsOpen}
                                    onOpenChange={setOptionsOpen}
                                    renderOpenDropdownButton={() => (
                                        <DropDownTextButton
                                            text={
                                                (value &&
                                                    defs.find(
                                                        d => d.id === value
                                                    )?.name) ||
                                                'Choose a dataset'
                                            }
                                        />
                                    )}
                                    renderContent={() => (
                                        <StyledOptions>
                                            {defs.map(def => (
                                                <li key={def.id}>
                                                    <DropdownButton
                                                        text={def.name}
                                                        onSelect={() =>
                                                            onSelect(def.id)
                                                        }
                                                    />
                                                </li>
                                            ))}
                                        </StyledOptions>
                                    )}
                                />
                            </div>
                            <Text as="p" variant="regular-4">
                                -- or --
                            </Text>
                        </Styled>
                    ))}
            />
            <ControlledModal
                title="Create a dataset"
                description=""
                open={createBOOpen}
                onOpenChange={setCreateBOOpen}
                renderContent={() => (
                    <StyledModalContent>
                        <CreateBusinessObjectDef
                            onCompleted={({
                                createBusinessObjectDefinition: def,
                            }) => {
                                if (def) onSelect(def.id)
                                setCreateBOOpen(false)
                            }}
                        />
                    </StyledModalContent>
                )}
                returnFocus={() => createBORef.current?.focus()}
            />
        </>
    )
}

const Styled = styled.div`
    > div {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    > div button:first-child {
        flex: 1;
    }

    > div button:first-child span:last-child {
        flex: 1;
        text-align: left;
    }

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        text-align: center;
        padding: 1rem;
    }
`
const StyledOptions = styled.ul`
    max-height: 12rem;
    overflow: auto;
`
const StyledModalContent = styled.div`
    overflow: hidden;
`

export { BODefSelect }
