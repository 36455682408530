import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { BusinessObjectView } from 'Features/BusinessObjectView'
import { CreateBusinessObjectModal } from 'Features/CreateBusinessObjectModal'
import { FilterBarLarge } from 'Features/FilterBusinessObject'
import { Tutorial } from 'Features/Tutorials'
import { useModalControls } from 'Hooks'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { BODFields } from '../../../Hooks/types'
import { slides } from '../../tutorial/slides'
import { ConvertFromCSV } from './ConvertFromCSV'
import { Display } from './Display'
import { StyledListView } from './styles'

type Props = {
    name: string
    description: string
    id: string
    fields: BODFields
}

export const ListView: FC<Props> = ({ name, description, id, fields }) => {
    const { openModal, buttonRef, open, closeModal, setOpen, returnFocus } =
        useModalControls()

    const navigate = useNavigate()

    const createRecordDisabled = useMemo<
        { disabled: false } | { disabled: true; reason: string }
    >(() => {
        if (!fields.length)
            return {
                disabled: true,
                reason: 'No fields defined on this dataset',
            }
        return { disabled: false }
    }, [fields.length])

    return (
        <StyledListView>
            <header>
                <div>
                    <Text as="h1" variant="bold-1">
                        {name}
                    </Text>

                    <div className="button-wrapper">
                        <IconTextButton
                            onClick={() => {
                                navigate(
                                    `/manage-workspace/datasets/${slugify(
                                        name,
                                        {
                                            lower: true,
                                        }
                                    )}`
                                )
                            }}
                            icon="Domain"
                            size="small"
                            variant="secondary"
                            text="View definition"
                        />

                        <ConvertFromCSV
                            businessObjectDefinitionFields={fields}
                            businessObjectDefinitionId={id}
                            businessObjectDefinitionName={name}
                            disabled={createRecordDisabled.disabled}
                        />

                        <Tooltip
                            content={
                                createRecordDisabled.disabled
                                    ? createRecordDisabled.reason
                                    : ''
                            }
                            disabled={!createRecordDisabled.disabled}
                            side="bottom"
                            offset={5}
                        >
                            <IconTextButton
                                text={`Create ${name}`}
                                onClick={openModal}
                                ref={buttonRef}
                                disabled={createRecordDisabled.disabled}
                                icon={'Plus'}
                                variant="primary"
                            />
                        </Tooltip>

                        <CreateBusinessObjectModal
                            businessObjectDefinitionName={name}
                            businessObjectDefinitionId={id}
                            fields={fields}
                            open={open}
                            closeModal={closeModal}
                            onOpenChange={setOpen}
                            returnFocus={returnFocus}
                        />
                    </div>
                </div>

                <Text as="p" variant="regular-5">
                    {description}
                </Text>
            </header>

            <FilterBarLarge
                businessObjectDefinitionId={id}
                fieldDefinitions={fields}
                leftSlot={
                    <>
                        <Tutorial markdown={slides} buttonVariant="ghost" />
                        <BusinessObjectView fields={fields} />
                    </>
                }
            />

            <Display businessObjectDefinitionId={id} />
        </StyledListView>
    )
}
