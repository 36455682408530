import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { SelectOption } from 'Components/Select/types'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC, useEffect, useMemo, useState } from 'react'
import { CreateBusinessObjectCurrencyFieldValue } from '__generated__'
import { useAvailableCurrencies } from '../../../../../Hooks/useAvailableCurrencies/useAvailableCurrencies'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useCurrencyConstraints } from './useCurrencyConstraints'

export const CurrencyField: FC<BODField<'CurrencyFieldDefinition'>> = ({
    currencyConstraints,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useCurrencyConstraints({
        currencyConstraints,
    })

    const [currencyCode, setCurrencyCode] = useState<string>(() => {
        const value = state[common.id].value as
            | CreateBusinessObjectCurrencyFieldValue
            | undefined

        return value?.currencyCode ?? ''
    })

    const [currencyAmount, setCurrencyAmount] = useState<number | ''>(() => {
        const value = state[common.id].value as
            | CreateBusinessObjectCurrencyFieldValue
            | undefined

        return value?.amount ?? ''
    })

    useEffect(() => {
        dispatch({
            type: 'updateCurrencyField',
            payload: {
                id: common.id,
                value: {
                    currencyCode,
                    amount:
                        typeof currencyAmount === 'number'
                            ? currencyAmount
                            : undefined,
                },
            },
        })
    }, [common.id, dispatch, currencyCode, currencyAmount])

    const currencyOptions = useAvailableCurrencies()

    const options = useMemo(() => {
        return [
            {
                text: `-`,
                value: '',
            },
            ...currencyOptions.map<SelectOption>(option => ({
                text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
                value: option.code,
            })),
        ]
    }, [currencyOptions])

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <StyledCurrencyInput>
                <Select
                    id={common.id + 'currencyCode'}
                    value={currencyCode}
                    onValueChange={setCurrencyCode}
                    name={'Currency Code'}
                    options={options}
                />
                <NumberInput
                    value={currencyAmount}
                    id={common.id}
                    hasError={!!state[common.id].errors}
                    onChange={e => setCurrencyAmount(Number(e.target.value))}
                    required={constraints.required}
                    className="currency-value-input"
                />
            </StyledCurrencyInput>
        </Field>
    )
}

const StyledCurrencyInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 12rem;
    }

    .currency-value-input {
        flex: 1;
        height: 2.75rem;
    }
`
