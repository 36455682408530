import * as amplitude from '@amplitude/analytics-browser'
import { FC, useEffect } from 'react'
import { useSandbox } from '../../Hooks/useSandbox'
import { useUserTrackingQuery } from './__generated__/query'
import { amplitudeEnabled } from './init'

export const UserTracking: FC = () => {
    const { data } = useUserTrackingQuery({ skip: !amplitudeEnabled })
    const sandbox = useSandbox()

    useEffect(() => {
        if (data) {
            const identifyObj = new amplitude.Identify()
            identifyObj.set('Email', data.currentUser.email)
            identifyObj.set('Sandbox', sandbox.isSandbox)
            amplitude.identify(identifyObj)
            amplitude.setUserId(data.currentUser.id)
        }
    }, [data, sandbox])

    return null
}
