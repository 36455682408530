import { FC } from 'react'
import { IconButton } from '../../Components/Button'
import { removeAt } from '../../Utils'
import { UpdateBusinessObjectListFieldValue } from '../../__generated__'
import { useUpdateBusinessObjectField } from '../EditBusinessObject/Fields/useUpdateBusinessObjectField'
import { PrincipalFragment } from '../Principal/__generated__/Principal'
import { isUserPrincipal } from '../Principal/helpers'
import { useCurrentUserIdQuery } from './__generated__/queries'

type Props = {
    deleteIndex: number
    businessObjectId: string
    fieldDefinitionId: string
    updateAuthor: PrincipalFragment
    previousValue: UpdateBusinessObjectListFieldValue[]
}

export const DeleteUpdate: FC<Props> = ({
    deleteIndex,
    updateAuthor,
    fieldDefinitionId,
    previousValue,
    businessObjectId,
}) => {
    const { updateBusinessObjectField, loading } =
        useUpdateBusinessObjectField()

    const { data } = useCurrentUserIdQuery()

    const currentUserId = data?.currentUser.id

    const handleDelete = () => {
        updateBusinessObjectField({
            field: {
                list: {
                    fieldDefinitionId,
                    previousValue,
                    value: removeAt(previousValue, deleteIndex),
                },
            },
            id: businessObjectId,
        })
    }

    if (!currentUserId) return null

    if (
        !isUserPrincipal(updateAuthor) ||
        currentUserId !== updateAuthor.user.id
    )
        return null

    return (
        <>
            <IconButton
                iconName="Delete"
                onClick={handleDelete}
                disabled={loading}
            />
        </>
    )
}
