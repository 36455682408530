import { FC } from 'react'
import { BusinessObjectFields } from '../components/fields/BusinessObjectFields'
import { DatasetSelector } from '../components/fields/Dataset'
import { CreateBusinessObjectPayload } from '../types'
import { StepOutline } from './StepOutline'
import { StepProps } from './types'

export const CreateBusinessObject: FC<
    StepProps<CreateBusinessObjectPayload>
> = ({
    payload: { businessObjectDefinitionId, fields },
    onFieldChanged,
    ...outlineProps
}) => {
    return (
        <StepOutline {...outlineProps} title="Create Record">
            <label>
                Dataset
                <DatasetSelector
                    name="dataset"
                    value={businessObjectDefinitionId}
                    onChange={onFieldChanged('businessObjectDefinitionId')}
                />
            </label>

            {businessObjectDefinitionId && (
                <BusinessObjectFields
                    businessObjectDefinitionId={businessObjectDefinitionId}
                    fieldValues={fields}
                    onFieldsChanged={onFieldChanged('fields')}
                />
            )}
        </StepOutline>
    )
}
