import { flex, styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { verticalAlignWithinCell } from '../cssHelpers'

/*
 * A text cell with an icon preceding the text
 * that links somewhere
 */

type Props = {
    text: string
    iconName: IconName
    to: string
    className?: string
}

export const IconTextLinkCell: FC<Props> = ({
    text,
    iconName,
    to,
    className = '',
}) => {
    return (
        <StyledIconTextCell className={className}>
            <Link to={to} className="link">
                <Icon name={iconName} />
                <span className="text">{text}</span>
            </Link>
        </StyledIconTextCell>
    )
}

const StyledIconTextCell = styled.td`
    padding: ${({ theme }) => theme.spacing['04']} 0;
    padding-left: 1.25rem;
    vertical-align: middle;

    > .link {
        ${verticalAlignWithinCell};
        ${flex('row', 'center', 'center')};
        gap: 0.5rem;

        &,
        &:visited {
            color: inherit;
            text-decoration: none;
        }

        .icon {
            min-height: 1rem;
            min-width: 1rem;
        }

        svg path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }

        .text {
            ${regular5};
            text-align: left;
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }
    }
`
