import pMap from 'p-map'
import { useCallback, useEffect, useState } from 'react'

import { FieldType } from '__generated__'
import { Conditions, FieldDefDictionary } from '../types'
import { useBusinessObjectById_PhaseCriteriaConditionsLazyQuery } from '../__generated__/query'

type Params = {
    conditions: Conditions
    fieldDefById: FieldDefDictionary
}

export const useRelationIdToLabelMap = ({
    conditions,
    fieldDefById,
}: Params) => {
    const [fetchBusinessObject] =
        useBusinessObjectById_PhaseCriteriaConditionsLazyQuery()

    const [relationIdToLabelMap, setRelationIdToLabelMap] = useState<
        Record<string, string>
    >({})

    const generateRelationIdToLabelMap = useCallback(async () => {
        if (!conditions) return {}

        const relationFieldDefIdMap = Object.values(fieldDefById).reduce<
            Record<string, string>
        >((acc, fieldDef) => {
            if (fieldDef.type === FieldType.Relation) {
                acc[fieldDef.id] = fieldDef.id
            }
            return acc
        }, {})

        if (!Object.keys(relationFieldDefIdMap).length) return {}

        const relations = conditions.reduce<string[]>((acc, condition) => {
            if (
                condition.valueSelector.__typename === 'FieldValueSelector' &&
                relationFieldDefIdMap[condition.valueSelector.fieldId]
            ) {
                return [...acc, JSON.parse(condition.with) as string]
            }

            return acc
        }, [])

        const results = await pMap(
            relations,
            async id => {
                return await fetchBusinessObject({
                    variables: { input: { id } },
                })
            },
            { concurrency: 5, stopOnError: false }
        )

        const relationIdToLabelMap = results.reduce<Record<string, string>>(
            (acc, { data }) => {
                if (!data) return acc
                const { businessObject } = data
                return { ...acc, [businessObject.id]: businessObject.label }
            },
            {}
        )

        setRelationIdToLabelMap(relationIdToLabelMap)
    }, [fieldDefById, conditions, fetchBusinessObject])

    useEffect(() => {
        generateRelationIdToLabelMap()
    }, [generateRelationIdToLabelMap])

    return {
        relationIdToLabelMap,
    }
}
