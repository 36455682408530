import { IconTextButton } from 'Components/Button'
import { Comparator, ComparatorBuilderV2 } from 'Components/ComparatorBuilderV2'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject'
import { useModalControls } from 'Hooks'
import { ValueSelectorType } from '__generated__'
import { FC } from 'react'

type Props = {
    businessObjectDefinitionId: string
}

export const Filters: FC<Props> = ({ businessObjectDefinitionId }) => {
    const { filterState, setFilters, noOfFilters } =
        useBusinessObjectFilterState()

    const { open, setOpen, buttonRef, returnFocus, openModal } =
        useModalControls()

    return (
        <>
            <IconTextButton
                icon="Filter"
                text={
                    noOfFilters > 0
                        ? `${noOfFilters} filter(s) selected`
                        : 'Filter records'
                }
                size="xSmall"
                ref={buttonRef}
                onClick={openModal}
            />

            <ComparatorBuilderV2
                businessObjectDefinitionId={businessObjectDefinitionId}
                onConfirm={(comparators: Comparator[]) => {
                    setFilters(comparators)
                    setOpen(false)
                }}
                title={'Filter records'}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
                initialComparators={Object.values(filterState).flat()}
                onClearAll={() => {
                    setOpen(false)
                }}
                allowedFilterTypes={[ValueSelectorType.FieldValue]}
            />
        </>
    )
}
