import { keyframes } from 'styled-components'

export const ANIMATION_DURATION = 500

export const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const slideUp = keyframes`
    from {
        transform: translateY(0.1rem);
    }
    to {
        transform: translateY(0);
    }
`

export const slideDown = keyframes`
    from {
        transform: translateY(-0.1rem);
    }
    to {
        transform: translateY(0);
    }
`

export const slideRight = keyframes`
    from {
        transform: translateX(-0.1rem);
    }
    to {
        transform: translateX(0);
    }
`

export const slideLeft = keyframes`
    from {
      transform: translateX(0.1rem);
    }
    to {
      transform: translateX(0);
    }
`
