import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModelManagerProcessesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModelManagerProcessesQuery = { __typename?: 'Query', processes: Array<{ __typename?: 'Process', id: string, name: string, description?: string | null, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', activeBusinessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }>, usage: { __typename?: 'ProcessUsage', businessObjects: number } }> };

export type Preselected_BoQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type Preselected_BoQuery = { __typename?: 'Query', businessObjectDefinition?: { __typename?: 'BusinessObjectDefinition', id: string, name: string } | null };


export const ModelManagerProcessesDocument = gql`
    query ModelManagerProcesses {
  processes {
    id
    name
    description
    operatesUpon {
      id
      name
    }
    phases {
      activeBusinessObjects: businessObjects(input: {excludeCompleted: true}) {
        meta {
          totalCount
        }
      }
    }
    usage {
      businessObjects
    }
  }
}
    `;

/**
 * __useModelManagerProcessesQuery__
 *
 * To run a query within a React component, call `useModelManagerProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelManagerProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelManagerProcessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelManagerProcessesQuery(baseOptions?: Apollo.QueryHookOptions<ModelManagerProcessesQuery, ModelManagerProcessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelManagerProcessesQuery, ModelManagerProcessesQueryVariables>(ModelManagerProcessesDocument, options);
      }
export function useModelManagerProcessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelManagerProcessesQuery, ModelManagerProcessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelManagerProcessesQuery, ModelManagerProcessesQueryVariables>(ModelManagerProcessesDocument, options);
        }
export type ModelManagerProcessesQueryHookResult = ReturnType<typeof useModelManagerProcessesQuery>;
export type ModelManagerProcessesLazyQueryHookResult = ReturnType<typeof useModelManagerProcessesLazyQuery>;
export type ModelManagerProcessesQueryResult = Apollo.QueryResult<ModelManagerProcessesQuery, ModelManagerProcessesQueryVariables>;
export const Preselected_BoDocument = gql`
    query Preselected_BO($id: String!) {
  businessObjectDefinition(input: {id: $id}) {
    id
    name
  }
}
    `;

/**
 * __usePreselected_BoQuery__
 *
 * To run a query within a React component, call `usePreselected_BoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreselected_BoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreselected_BoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreselected_BoQuery(baseOptions: Apollo.QueryHookOptions<Preselected_BoQuery, Preselected_BoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Preselected_BoQuery, Preselected_BoQueryVariables>(Preselected_BoDocument, options);
      }
export function usePreselected_BoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Preselected_BoQuery, Preselected_BoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Preselected_BoQuery, Preselected_BoQueryVariables>(Preselected_BoDocument, options);
        }
export type Preselected_BoQueryHookResult = ReturnType<typeof usePreselected_BoQuery>;
export type Preselected_BoLazyQueryHookResult = ReturnType<typeof usePreselected_BoLazyQuery>;
export type Preselected_BoQueryResult = Apollo.QueryResult<Preselected_BoQuery, Preselected_BoQueryVariables>;