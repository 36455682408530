import { css, flex, styled } from 'Adapters/Freestyled'

export const verticalAlignWithinCell = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`

// For consistent ellipsis button styles in tables
export const StyledEllipsisButton = styled.button`
    height: 2rem;
    width: 2rem;
    left: ${({ theme }) => theme.spacing['03']};
    border-radius: ${({ theme }) => theme.spacing['02']};
    transition: 0.1s;

    > .icon {
        ${flex('column', 'center', 'center')};

        svg {
            height: 1.5rem;
            width: 1.5rem;

            path {
                fill: ${({ theme }) => theme.palette.icon['01'].normal};
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['01'].hover};
        }

        &:active {
            background-color: ${({ theme }) =>
                theme.palette.ui.tint['01'].active};
        }
    }

    &:focus-visible {
        box-shadow: inset 0 0 0 ${({ theme }) => theme.spacing['01']}
            ${({ theme }) => theme.palette.brand['01'].normal};
    }
`
