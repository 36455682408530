import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { ContactUsToast, SimpleToast } from 'Components/Toast'
import { ProcessDisplayFragment } from 'Features/ProcessDisplay/__generated__/q'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { useSaveView } from '../useSaveView'

type Props = {
    process: ProcessDisplayFragment
}

const SaveView: FC<Props> = ({ process }) => {
    const [open, setOpen] = useState(false)
    const { current: inputId } = useRef(v4())
    const {
        showSuccess,
        setShowSuccess,
        showError,
        setShowError,
        saveView,
        setLabel,
        canSave,
    } = useSaveView(process)

    return (
        <>
            <IconTextButton
                icon="Save"
                text="Save view"
                size="xSmall"
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled
                    onSubmit={async e => {
                        e.preventDefault()
                        saveView()
                        setLabel('')
                        setOpen(false)
                    }}
                >
                    <Text as="label" variant="bold-5" htmlFor={inputId}>
                        Label
                    </Text>
                    <Text as="p" variant="regular-6">
                        Give your view a memorable name
                    </Text>
                    <TextInput
                        id={inputId}
                        placeholder="My view"
                        onChange={({ currentTarget: { value = '' } }) =>
                            setLabel(value)
                        }
                    />
                    <footer>
                        <IconTextButton
                            icon="Close"
                            type="button"
                            text="Cancel"
                            size="xSmall"
                            variant="secondary"
                            onClick={() => setOpen(false)}
                        />
                        <IconTextButton
                            icon="Save"
                            type="submit"
                            text="Save"
                            disabled={!canSave}
                            size="xSmall"
                            variant="primary"
                        />
                    </footer>
                </Styled>
            </MinimalModal>
            <SimpleToast
                open={showSuccess}
                onOpenChange={setShowSuccess}
                description="View saved"
            />
            <ContactUsToast
                open={showError}
                onOpenChange={setShowError}
                description="Unable to save the view. Contact us if the problem persists."
            />
        </>
    )
}

const Styled = styled.form`
    padding: 0.5rem 4vw 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    footer {
        display: flex;
        padding-top: 0.75rem;
        gap: 1rem;
    }

    footer button {
        flex: 1;
    }
`

export { SaveView }
