import { TextInput } from 'Components/Input'
import { fromEvent } from 'Utils'
import { pipe } from 'lodash/fp'
import { FC, useEffect } from 'react'
import { FilterPayload } from '../types'
import { StepOutline } from './StepOutline'
import { StepProps } from './types'

export const Filter: FC<StepProps<FilterPayload>> = ({
    onFieldChanged,
    payload: { selector, value },
    ...outlineProps
}) => {
    // auto set to equals until other operators supported
    useEffect(() => {
        onFieldChanged('operator')('equals')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StepOutline {...outlineProps} title="Filter">
            <label>
                Selector
                <TextInput
                    type="text"
                    value={selector}
                    onChange={pipe(fromEvent, onFieldChanged('selector'))}
                />
            </label>
            <label>
                Value
                <TextInput
                    type="text"
                    value={value}
                    onChange={pipe(fromEvent, onFieldChanged('value'))}
                />
            </label>
        </StepOutline>
    )
}
