import * as Accordion from '@radix-ui/react-accordion'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StyledInputGroup, StyledItem, StyledTrigger } from './styles'
import { useUserOptions } from './useUserOptions'

export const AssignedToFilterControls = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const assignedTo = searchParams.get('assignedTo')

    const updateAssignedToFilter = useCallback(
        (value: string) => {
            setSearchParams(prev => {
                const prevAsObject = convertSearchParamsToObject(prev)

                if (!value) {
                    const { assignedTo: _, ...rest } = prevAsObject
                    return rest
                }

                return { ...prevAsObject, assignedTo: value }
            })
        },
        [setSearchParams]
    )

    const userOptions = useUserOptions({ withUnassigned: true })

    return (
        <StyledItem value={'assignedTo'} key={'assignedTo'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Assigned to :
                </Text>

                {assignedTo ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledInputGroup>
                    <Text
                        as="label"
                        variant="bold-6"
                        htmlFor="assigned-to-select"
                    >
                        User :
                    </Text>

                    <Select
                        id="assigned-to-select"
                        onValueChange={updateAssignedToFilter}
                        value={assignedTo ?? ''}
                        name="assigned-to-select"
                        options={userOptions}
                    />
                </StyledInputGroup>
            </Accordion.Content>
        </StyledItem>
    )
}
