import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Dropdown } from 'Components/Dropdown'
import { FC, useState } from 'react'

type Props = {
    colors: string[]
    selected: string
    onSelect: (color: string) => void
}

const ColorPicker: FC<Props> = ({ colors, selected, onSelect }) => {
    const [open, setOpen] = useState(false)

    return (
        <Dropdown
            align="end"
            open={open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <StyledColorDropDownButton
                    $color={selected}
                    iconName={open ? 'AngleUp' : 'AngleDown'}
                    onClick={() => setOpen(p => !p)}
                />
            )}
            renderContent={() => (
                <Styled>
                    {colors.map(color => (
                        <li key={color}>
                            <StyledColorDropDownButton
                                $color={color}
                                $hideIcon={color !== selected}
                                iconName="Check"
                                onClick={() => {
                                    onSelect(color)
                                    setOpen(false)
                                }}
                            />
                        </li>
                    ))}
                </Styled>
            )}
        />
    )
}

const StyledColorDropDownButton = styled(IconButton)<{
    $color: string
    $hideIcon?: boolean
}>`
    background-color: ${({ $color }) => $color}22;

    .icon svg {
        opacity: ${({ $hideIcon }) => ($hideIcon ? 0 : 1)};
        filter: brightness(50%);
    }

    .icon svg path {
        fill: ${({ $color }) => $color};
    }

    &:hover {
        background-color: ${({ $color }) => $color}66;
    }
    &:active {
        background-color: ${({ $color }) => $color}99;
    }
`

const Styled = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 0.5rem;
`

export { ColorPicker }
