import { flex, styled } from 'Adapters/Freestyled'
import { DropdownButton } from 'Components/Dropdown'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { noop } from 'lodash'
import { FC } from 'react'
import { SavedConfig } from '../../../types'
import { ManageSingleView } from './ManageSingleView'

type Props = {
    views: SavedConfig[]
}

export const ManageViews: FC<Props> = ({ views }) => {
    const { open, openModal, setOpen } = useModalControls()

    return (
        <>
            <DropdownButton
                text={'Manage views'}
                onSelect={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    openModal()
                }}
                icon="Settings"
                disabled={!views.length}
            />

            <ControlledModal
                renderContent={() => (
                    <StyledContent>
                        {views.map(view => (
                            <ManageSingleView key={view.id} view={view} />
                        ))}
                    </StyledContent>
                )}
                title={'Manage views'}
                description={
                    views.length
                        ? `Remove any views you don't need here`
                        : 'You have no saved views'
                }
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
            />
        </>
    )
}

const StyledContent = styled.ul`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100vw;
    max-width: 35rem;
    gap: 0.5rem;

    li:first-child {
        margin-top: 1rem;
    }
`
