import { dayjs } from 'Adapters/DayJS'
import { styled } from 'Adapters/Freestyled'
import { DATE_FORMAT, MONTHS } from 'Components/DatePicker/constants'
import { makeDay } from 'Components/DatePicker/utils'
import { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'
import { Month } from '../../Button/Month/Month'
import { FocusTargetDate } from '../../types'
import { Limit } from '../types'
import { arrowHandler } from './utils'

type Props = {
    selection: Dayjs | undefined
    setSelection: React.Dispatch<React.SetStateAction<Dayjs | undefined>>
    display: Dayjs
    setDisplay: React.Dispatch<React.SetStateAction<Dayjs>>
    setShowMonthSelector: React.Dispatch<React.SetStateAction<boolean>>
    focusTargetDate: FocusTargetDate
    limit?: Limit
}

export const MonthSelector: FC<Props> = ({
    display,
    setDisplay,
    selection,
    setSelection,
    setShowMonthSelector,
    focusTargetDate,
    limit,
}) => {
    const yearStart = display.startOf('year')

    // To conform to WAI-ARIA specifications, only one month in the grid should be tabbable (other navigation should happen by arrow key)
    // This order of preference is as follows: selected month > this month
    const tabbableMonth = useMemo(() => {
        if (selection) return selection.format('MMM')
        return dayjs().format('MMM')
    }, [selection])

    return (
        <StyledMonthSelector>
            {MONTHS.map((month, i) => {
                const date = yearStart.add(i, 'month')
                const dateString = date.format(DATE_FORMAT)
                const selected = !!selection?.isSame(date, 'month')
                const isToday = makeDay().isSame(date, 'month')
                const handleArrow = arrowHandler(focusTargetDate)(
                    date,
                    setDisplay
                )
                const isBeforeLimit =
                    limit?.from &&
                    date
                        .add(1, 'month')
                        .isBefore(makeDay({ input: limit.from }), 'day')
                const isAfterLimit =
                    limit?.to &&
                    date.isAfter(makeDay({ input: limit.to }), 'day')

                return (
                    <li key={dateString} data-month={month.tag}>
                        <Month
                            disabled={isBeforeLimit || isAfterLimit}
                            monthDisplay={month.label}
                            selected={selected}
                            isToday={isToday}
                            dateString={dateString}
                            onClick={() => {
                                setDisplay(date)
                                if (!selected) setSelection(undefined)
                                setShowMonthSelector(false)
                            }}
                            onUpArrow={handleArrow('up', i > 2)}
                            onDownArrow={handleArrow('down', i < 9)}
                            onLeftArrow={handleArrow('left', i > 0)}
                            onRightArrow={handleArrow('right', i < 11)}
                            isTabbable={tabbableMonth === date.format('MMM')}
                        />
                    </li>
                )
            })}
        </StyledMonthSelector>
    )
}

const StyledMonthSelector = styled.ul`
    display: inline-grid;
    grid-template: repeat(4, auto) / repeat(3, auto);
`
