import { styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Dispatch, FC, SetStateAction } from 'react'

type Props = {
    close: () => void
    step: 'fieldSelection' | 'groupConfig'
    setStep: Dispatch<SetStateAction<'fieldSelection' | 'groupConfig'>>
    selection: string
}

const GroupingFooter: FC<Props> = ({ close, step, setStep, selection }) => {
    const isFieldSelection = step === 'fieldSelection'
    return (
        <Styled>
            <IconTextButton
                size="xSmall"
                variant="secondary"
                icon={isFieldSelection ? 'AngleRight' : 'AngleLeft'}
                iconRight={isFieldSelection}
                text={
                    isFieldSelection
                        ? 'Configure groups'
                        : 'Select grouping field'
                }
                onClick={() =>
                    setStep(isFieldSelection ? 'groupConfig' : 'fieldSelection')
                }
                disabled={selection === 'all'}
            />
            <TextButton
                text="Done"
                onClick={close}
                size="xSmall"
                variant="primary"
            />
        </Styled>
    )
}

const Styled = styled.footer`
    display: flex;
    justify-content: space-between;
    padding-top: 0.75rem;
    gap: 1rem;

    button {
        flex: 1;
    }
`

export { GroupingFooter }
