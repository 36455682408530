import { logger } from 'Adapters/Logger'
import { IconButton } from 'Components/Button'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { noop } from 'lodash'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { useMoveBoToNextPhaseMutation } from './__generated__/MoveBOToPrevPhase'

type Props = {
    businessObjectId: string
    processId: string
    disabled: boolean
    tooltip: string
    preventAction?: boolean // Prevent actions from being taken whilst a hint is open
}

const NextPhase = forwardRef<HTMLButtonElement, Props>(
    (
        {
            businessObjectId,
            processId,
            disabled,
            tooltip,
            preventAction = false,
        },
        ref
    ) => {
        const [move] = useMoveBoToNextPhaseMutation()
        const { open, setOpen, triggerToast } = useTriggerToast()

        return (
            <>
                <Tooltip content={tooltip} side="bottom">
                    <Styled
                        variant="secondary"
                        iconName="AngleRight"
                        disabled={disabled}
                        onClick={() => {
                            preventAction
                                ? noop()
                                : move({
                                      variables: {
                                          input: {
                                              businessObjectId,
                                              processId,
                                          },
                                          onProcessInput: {
                                              processId,
                                          },
                                          hasBusinessObjectCompletedInput: {
                                              businessObjectId,
                                          },
                                      },
                                      onError: e => {
                                          logger.error(
                                              'Failed to move business object to next phase',
                                              e,
                                              { businessObjectId, processId }
                                          )
                                          triggerToast()
                                      },
                                  })
                        }}
                        hideTitle
                        ref={ref}
                    />
                </Tooltip>
                <SimpleToast
                    open={open}
                    onOpenChange={setOpen}
                    description="Unable to move the record"
                />
            </>
        )
    }
)

const Styled = styled(IconButton)`
    border-radius: 0;
`

export { NextPhase }
