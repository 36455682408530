import { Comparator } from 'Components/ComparatorBuilderV2'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { ValueSelectorType } from '../../__generated__'
import {
    FILTER_STATE_KEY,
    convertSearchParamsToObject,
    decodeFilterState,
    encodeFilterState,
} from './helpers'

export const useBusinessObjectFilterState = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const filterState = searchParams.get(FILTER_STATE_KEY)

    const setFilters = useCallback(
        (comparators: Comparator[]) => {
            const queryParamsAsObject =
                convertSearchParamsToObject(searchParams)
            setSearchParams({
                ...queryParamsAsObject,
                [FILTER_STATE_KEY]: encodeFilterState(
                    comparators.reduce((acc, comparator) => {
                        if (
                            comparator.valueSelector.type !==
                            ValueSelectorType.FieldValue
                        ) {
                            throw new Error(
                                'Only FieldValue selectors are supported'
                            )
                        }

                        const fieldId = comparator.valueSelector.fieldId

                        return match(acc[fieldId])
                            .with(P.nullish, () => ({
                                ...acc,
                                [fieldId]: comparator,
                            }))
                            .with(P.array(P._), () => ({
                                ...acc,
                                [fieldId]: (
                                    acc[fieldId] as Comparator[]
                                ).concat(comparator),
                            }))
                            .with(P.not(P.nullish), () => ({
                                ...acc,
                                [fieldId]: [
                                    acc[fieldId] as Comparator,
                                    comparator,
                                ],
                            }))
                            .exhaustive()
                    }, {} as Record<string, Comparator | Comparator[]>)
                ),
            })
        },
        [searchParams, setSearchParams]
    )

    const noOfFilters = useMemo(() => {
        const filterStateAsObject = decodeFilterState(filterState)

        return Object.keys(filterStateAsObject).reduce((out, key) => {
            if (Array.isArray(filterStateAsObject[key])) {
                return out + (filterStateAsObject[key] as Comparator[]).length
            } else {
                return out + 1
            }
        }, 0)
    }, [filterState])

    return {
        filterState: decodeFilterState(filterState),
        setFilters,
        noOfFilters,
    }
}
