// From uploadcare
export type FileMetadata = {
    id: string
    name: string
    type: string
}

// A flattened version of the GQL response for ease of use in components
export type DocumentMetadata = {
    id: string
    name: string
    sizeInBytes: number
    addedBy: string
    addedOn: string
    fileType: string
    canDelete: boolean
    url: string
}

// File Types
export const imageFileTypes = {
    'image/png': '',
    'image/avif': '',
    'image/bmp': '',
    'image/gif': '',
    'vnd.microsoft.icon': '',
    'image/jpeg': '',
    'image/svg+xml': '',
    'image/tiff': '',
    'image/webp': '',
} as const

export type ImageFileType = keyof typeof imageFileTypes

export const isImageFileType = (string: string): string is ImageFileType => {
    if (imageFileTypes.hasOwnProperty(string)) return true
    return false
}
