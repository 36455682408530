import { styled } from 'Adapters/Freestyled'
import { ActionsTable } from 'Features/ActionsTable'
import { QueryActionsInput } from '__generated__'
import { FC } from 'react'
import { SingleBusinessObjectActionsQuery } from './__generated__/SingleBusinessObjectActions'

type Props = {
    actions: SingleBusinessObjectActionsQuery['actions']
    actionsQueryInput: QueryActionsInput
}

const Large: FC<Props> = ({ actions, actionsQueryInput }) => (
    <Styled>
        <ActionsTable
            actions={actions}
            className="table-container"
            tableClassName="table"
            relatesTo={actionsQueryInput.relatesTo}
            includeMenu
        />
    </Styled>
)

const Styled = styled.div`
    flex: 1;
    overflow: auto;

    .table-container {
        height: calc(100% - 2rem);

        .table {
            padding: 0 2rem;
        }
    }
`

export { Large }
