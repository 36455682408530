import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
    actionName: string
    toPhase: 'open' | 'closed'
    processName: string
}

export const CriterionToggledHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    processName,
    actionName,
    toPhase,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> marked <strong>{actionName}</strong>{' '}
                in workflow <strong>{processName}</strong> as{' '}
                {toPhase === 'closed' ? 'complete ✅' : 'incomplete ❌'}.
            </Text>
        </BaseHistoryEntry>
    )
}
