import { css, styled } from 'Adapters/Freestyled'
import { StatusButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Text, regular6 } from 'Components/Text'
import { ContactUsToast } from 'Components/Toast'
import { FC } from 'react'
import { useForm } from './useForm'

type Direction = 'column' | 'row'

type Props = {
    id: string
    name: string
    direction?: Direction
}

export const DetailsForm: FC<Props> = ({ id, name, direction = 'column' }) => {
    const {
        handleNameChange,
        handleSubmit,
        saveEnabled,
        details,
        status,
        showToast,
        setShowToast,
    } = useForm({ id, name })

    return (
        <StyledForm onSubmit={handleSubmit} $direction={direction}>
            <div>
                <StyledField>
                    <Text as="label" variant="bold-5" htmlFor="name-input">
                        Your name
                    </Text>
                    <TextInput
                        id="name-input"
                        name="name-input"
                        value={details.name}
                        onChange={handleNameChange}
                        placeholder="Enter your full name"
                    />
                </StyledField>
            </div>
            <footer>
                <StatusButton
                    status={status}
                    text={{
                        error: 'Save',
                        loading: 'Saving',
                        ready: 'Save',
                        success: 'Saved',
                    }}
                    disabled={!saveEnabled}
                />
            </footer>

            <ContactUsToast
                open={showToast}
                onOpenChange={setShowToast}
                description={
                    'We were unable to change your name. Contact us if the problem persists.'
                }
            />
        </StyledForm>
    )
}

const columnStyles = css`
    flex-direction: column;

    > footer {
        padding: 2rem 0;
    }
`

const rowStyles = css`
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;

    > footer {
        > button {
            max-height: none;
            height: 2.813rem;
        }
    }
`

const StyledForm = styled.form<{ $direction: Direction }>`
    flex: 1;
    display: flex;

    [data-screen-size='large'] & {
        ${({ $direction }) =>
            $direction === 'column' ? columnStyles : rowStyles}
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        flex-direction: column;
        gap: 0.75rem;
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
`

const StyledField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
        ${regular6}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        line-height: 150%;
    }
`
