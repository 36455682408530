import { byTypename } from 'Utils'
import { chain, compact, first } from 'lodash'
import { useMemo } from 'react'
import { P, match } from 'ts-pattern'
import { BusinessObjectFieldCompletionDetailsFragment } from '../../Features/FieldCompletionModal/__generated__/q'
import { InPhaseCriteriaQuery } from './__generated__/InPhaseCriteria'

export const useInPhaseFieldCriteria = (
    businessObject:
        | ((BusinessObjectFieldCompletionDetailsFragment & {
              onProcess?: InPhaseCriteriaQuery['businessObject']['onProcess']
          }) & {
              definition: InPhaseCriteriaQuery['businessObject']['definition']
          })
        | undefined
) =>
    useMemo(
        () =>
            match(businessObject)
                .with(P.nullish, () => ({
                    total: [],
                    incomplete: [],
                    incompleteRelations: {},
                }))
                .otherwise(bo => {
                    const total = (
                        bo.onProcess?.onPhase.phase.criteria.filter(
                            byTypename('FieldConditionPhaseCriterion' as const)
                        ) ?? []
                    ).filter(
                        criterion =>
                            criterion.businessObjectOnCriterion?.isApplicable
                    )

                    const incomplete = compact(
                        total
                            .filter(
                                criterion =>
                                    !criterion.businessObjectOnCriterion
                                        ?.isCompleted
                            )
                            .map(
                                criterion => criterion.comparator.valueSelector
                            )
                            .filter(byTypename('FieldValueSelector' as const))
                            .map(selector =>
                                bo.definition.fields.find(
                                    field => field.id === selector.fieldId
                                )
                            )
                    )

                    const incompleteDeep = chain(total)
                        .filter(
                            criterion =>
                                !criterion.businessObjectOnCriterion
                                    ?.isCompleted
                        )
                        .map(criterion => criterion.comparator.valueSelector)
                        .filter(byTypename('DeepSelector'))
                        .map(selector => {
                            const firstSelector = first(selector.selectors)
                            if (!firstSelector) return undefined
                            if (
                                firstSelector.__typename !==
                                'FieldValueSelector'
                            )
                                return undefined

                            const field = bo.definition.fields.find(
                                field => field.id === firstSelector.fieldId
                            )

                            if (!field) return undefined

                            const relationField = selector.selectors[1]

                            const relationFieldId =
                                relationField?.__typename ===
                                'FieldValueSelector'
                                    ? relationField.fieldId
                                    : undefined

                            if (!relationFieldId) return undefined

                            return {
                                ...field,
                                relationFieldId,
                            }
                        })
                        .compact()

                    const incompleteRelations = incompleteDeep
                        .map(({ relationFieldId, ...field }) => {
                            const parentField = bo.fields.find(
                                parentField =>
                                    parentField.fieldDefinition.id === field.id
                            )

                            return parentField
                                ? {
                                      fieldId: parentField?.fieldDefinition.id,
                                      relationId:
                                          (parentField.__typename ===
                                          'BusinessObjectRelationField'
                                              ? parentField.relationValue?.id
                                              : undefined) ?? undefined,
                                      relationFieldId,
                                  }
                                : undefined
                        })
                        .compact()
                        .groupBy(({ fieldId }) => fieldId)
                        .value()

                    return {
                        total,
                        incomplete: [...incomplete, ...incompleteDeep.value()],
                        incompleteRelations,
                    }
                }),
        [businessObject]
    )
