import { compact } from 'lodash'
import { useCallback, useState } from 'react'
import { removeAt, setAt } from '../../Utils'
import { Operator } from '../../__generated__'
import { initialComparatorToState } from './parse-comparator'
import { Comparator, ComparatorState } from './types'

type Props = {
    initialComparators: Comparator[]
}

export const useBuildComparatorState = ({ initialComparators }: Props) => {
    const [comparators, setComparators] = useState<Partial<ComparatorState>[]>(
        initialComparators.length
            ? compact(initialComparators.map(initialComparatorToState))
            : [{}]
    )

    const handleAddBlankComparator = useCallback(() => {
        setComparators(comparators => [...comparators, {}])
    }, [setComparators])

    const handleComparatorFieldIdChanged = useCallback(
        (comparatorIndex: number) => (fieldId: string) =>
            setComparators(comparators =>
                comparators.map((comparator, index) =>
                    index === comparatorIndex
                        ? {
                              ...comparator,
                              valueSelector: {
                                  fieldId,
                              },
                              operator: undefined,
                              with: undefined,
                              negate: undefined,
                          }
                        : comparator
                )
            ),
        [setComparators]
    )

    const handleOperatorChanged = useCallback(
        (comparatorIndex: number) => (operator: Operator, negate?: boolean) =>
            setComparators(comparators => {
                const operatorSet = setAt(
                    comparators,
                    comparatorIndex,
                    'operator',
                    operator
                )

                if (negate !== undefined) {
                    return setAt(operatorSet, comparatorIndex, 'negate', negate)
                } else return operatorSet
            }),
        [setComparators]
    )

    const handleComparatorWithChanged = useCallback(
        (comparatorIndex: number) => (withValue: unknown) =>
            setComparators(comparators =>
                setAt(comparators, comparatorIndex, 'with', withValue)
            ),
        [setComparators]
    )

    const handleComparatorRemoved = useCallback(
        (comparatorIndex: number) => () =>
            setComparators(comparators =>
                removeAt(comparators, comparatorIndex)
            ),
        [setComparators]
    )

    return {
        comparators,
        handleAddBlankComparator,
        handleComparatorFieldIdChanged,
        handleOperatorChanged,
        handleComparatorWithChanged,
        handleComparatorRemoved,
    }
}
