import { styled } from 'Adapters/Freestyled'
import { Dayjs } from 'dayjs'
import { FC, useState } from 'react'
import { DatePickerNav } from '../DatePickerNav/DatePickerNav'
import { useSetupDatePicker } from '../useSetupDatePicker'
import { makeDay } from '../utils'
import { Calendar } from './Calendar/Calendar'
import { DatePickerFooter } from './DatePickerFooter/DatePickerFooter'
import { MonthSelector } from './MonthSelector/MonthSelector'
import { Limit } from './types'

type Props = {
    selected: Date | undefined
    onConfirm: (props: {
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        date: Date
    }) => void
    limit?: Limit
}

export const DatePickerSingle: FC<Props> = ({ selected, onConfirm, limit }) => {
    const { datePickerId, focusTargetDate } = useSetupDatePicker()
    const [display, setDisplay] = useState<Dayjs>(
        makeDay({ input: selected }).startOf('month')
    )
    const [selection, setSelection] = useState<Dayjs | undefined>(
        selected && makeDay({ input: selected })
    )
    const [showMonthSelector, setShowMonthSelector] = useState<boolean>(false)

    return (
        <StyledDatePickerSingle data-date-picker-id={datePickerId}>
            <DatePickerNav
                displayText={display.format(
                    showMonthSelector ? 'YYYY' : 'MMM YYYY'
                )}
                displayPrevious={() =>
                    setDisplay(prev =>
                        prev.subtract(1, showMonthSelector ? 'year' : 'month')
                    )
                }
                displayNext={() =>
                    setDisplay(prev =>
                        prev.add(1, showMonthSelector ? 'year' : 'month')
                    )
                }
                toggleMonthSelector={() => setShowMonthSelector(prev => !prev)}
            />

            {showMonthSelector ? (
                <MonthSelector
                    selection={selection}
                    setSelection={setSelection}
                    display={display}
                    setDisplay={setDisplay}
                    setShowMonthSelector={setShowMonthSelector}
                    focusTargetDate={focusTargetDate}
                    limit={limit}
                />
            ) : (
                <Calendar
                    selection={selection}
                    setSelection={setSelection}
                    display={display}
                    setDisplay={setDisplay}
                    focusTargetDate={focusTargetDate}
                    limit={limit}
                />
            )}

            <DatePickerFooter selection={selection} onConfirm={onConfirm} />
        </StyledDatePickerSingle>
    )
}

export const StyledDatePickerSingle = styled.div`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    position: relative;
    width: 100%;
    padding-top: 1.5rem;
    text-align: center;

    footer {
        padding-top: 1rem;

        button {
            width: 100%;
        }
    }
`
