import { DocumentMetadata } from 'Components/Documents'
import Fuse from 'fuse.js'
import { useCallback, useMemo, useState } from 'react'

export const useFuzzySearch = (documents: DocumentMetadata[]) => {
    const [searchTerms, setSearchTerms] = useState('')

    const updateSearchTerms = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerms(event.target.value)
        },
        []
    )

    const clearSearchTerms = useCallback(() => {
        setSearchTerms('')
    }, [])

    const documentSearchResults = useMemo(() => {
        if (!searchTerms) return documents

        return new Fuse(documents, {
            includeMatches: true,
            keys: ['name', 'fileType'],
            threshold: 0.3,
        })
            .search(searchTerms)
            .map(result => result.item)
    }, [searchTerms, documents])

    return {
        searchTerms,
        updateSearchTerms,
        clearSearchTerms,
        documentSearchResults,
    }
}
