import { BizObjDef_Relation_UniqueFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import {
    BooleanFieldDefinition,
    CreateBusinessObjectDefinitionDateFieldPrecision,
    CurrencyFieldDefinition,
    DateFieldDefinition,
    DateRangeConstraint,
    DocumentFieldDefinition,
    EmailFieldDefinition,
    LengthConstraint,
    NumberFieldDefinition,
    NumberOfSelectionsConstraint,
    RangeConstraint,
    RelationTypeConstraint,
    SelectFieldDefinition,
    SelectFieldOption,
    TelephoneFieldDefinition,
    TextFieldDefinition,
    UrlFieldDefinition,
    UserFieldDefinition,
} from '__generated__'
import { P, match } from 'ts-pattern'
import {
    BooleanField,
    CurrencyField,
    DateField,
    DateFieldPrecision,
    DocumentField,
    EmailField,
    FieldType,
    NumberField,
    RelationField,
    SelectField,
    TelephoneField,
    TextField,
    UrlField,
    UserField,
} from '../types'

export const sanitiseTextField =
    (constraintsLocked: boolean) =>
    ({
        constraints,
        id,
        name,
        description,
        defaultValue,
    }: Pick<
        TextFieldDefinition,
        'id' | 'name' | 'constraints' | 'description' | 'defaultValue'
    >): TextField => ({
        type: FieldType.Text,
        id,
        name,
        description: description ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
            length: match(
                constraints.find(c => c.__typename === 'LengthConstraint') as
                    | LengthConstraint
                    | undefined
            )
                .with(P.nullish, () => ({ min: undefined, max: undefined }))
                .otherwise(({ min, max }) => ({
                    min: min ?? undefined,
                    max: max ?? undefined,
                })),
        },
        defaultValue: defaultValue ?? '',
        constraintsLocked,
    })

export const sanitiseBooleanField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        defaultValue,
    }: Pick<
        BooleanFieldDefinition,
        'id' | 'name' | 'description' | 'defaultValue'
    >): BooleanField => ({
        type: FieldType.Boolean,
        id,
        name,
        description: description ?? '',
        defaultValue: defaultValue ?? false,
        constraintsLocked,
    })

export const sanitiseCurrencyField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        CurrencyFieldDefinition,
        'id' | 'name' | 'description' | 'defaultValue' | 'constraints'
    >): CurrencyField => ({
        type: FieldType.Currency,
        id,
        name,
        description: description ?? '',
        defaultValue: {
            amount: defaultValue?.amount ?? undefined,
            currencyCode: defaultValue?.currencyCode ?? undefined,
        },
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        constraintsLocked,
    })

export const sanitiseDateField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        defaultValue,
        constraints,
        precision,
    }: Pick<
        DateFieldDefinition,
        | 'id'
        | 'name'
        | 'description'
        | 'defaultValue'
        | 'constraints'
        | 'precision'
    >): DateField => ({
        type: FieldType.Date,
        id,
        name,
        description: description ?? '',
        defaultValue: match(defaultValue)
            .with({ absolute: P.string }, ({ absolute }) => ({ absolute }))
            .with({ relative: P.string }, ({ relative }) => ({ relative }))
            .otherwise(() => undefined),
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
            dateRange: match(
                constraints.find(
                    c => c.__typename === 'DateRangeConstraint'
                ) as DateRangeConstraint | undefined
            )
                .with(P.nullish, () => ({
                    start: undefined,
                    end: undefined,
                }))
                .otherwise(({ start, end }) => ({
                    start: start ?? undefined,
                    end: end ?? undefined,
                })),
        },
        constraintsLocked,
        // CreateBusinessObjectDefinitionDateFieldPrecision is not unique to the create
        // business object mutation input, but is also the type of the precision contraint
        // for date fields in the Template_BizObjDefFragment and BizObjectDef_CompleteFragment
        // which are the only types that are accepted as input for santisation by the editor.
        // I assue this is a result of the schema definition being shared.
        precision: match(precision)
            .with(
                CreateBusinessObjectDefinitionDateFieldPrecision.Year,
                () => DateFieldPrecision.Year
            )
            .with(
                CreateBusinessObjectDefinitionDateFieldPrecision.Month,
                () => DateFieldPrecision.Month
            )
            .otherwise(() => DateFieldPrecision.Day),
    })

export const sanitiseDocumentField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
    }: Pick<
        DocumentFieldDefinition,
        'id' | 'name' | 'description' | 'constraints'
    >): DocumentField => ({
        type: FieldType.Document,
        id,
        name,
        description: description ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        constraintsLocked,
    })

export const sanitiseEmailField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        EmailFieldDefinition,
        'id' | 'name' | 'description' | 'constraints' | 'defaultValue'
    >): EmailField => ({
        type: FieldType.Email,
        id,
        name,
        description: description ?? '',
        defaultValue: defaultValue ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        constraintsLocked,
    })

export const sanitiseNumberField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        NumberFieldDefinition,
        'id' | 'name' | 'description' | 'constraints' | 'defaultValue'
    >): NumberField => ({
        type: FieldType.Number,
        id,
        name,
        description: description ?? '',
        defaultValue: defaultValue ?? undefined,
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
            range: match(
                constraints.find(c => c.__typename === 'RangeConstraint') as
                    | RangeConstraint
                    | undefined
            )
                .with(P.nullish, () => ({ min: undefined, max: undefined }))
                .otherwise(({ min, max }) => ({
                    min: min ?? undefined,
                    max: max ?? undefined,
                })),
        },
        constraintsLocked,
    })

export const sanitiseSelectField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
        options,
    }: Pick<
        SelectFieldDefinition,
        'id' | 'name' | 'description' | 'constraints' | 'defaultValue'
    > & {
        options: Array<SelectFieldOption & { locked: boolean }>
    }): SelectField => ({
        type: FieldType.Select,
        id,
        name,
        description: description ?? '',
        constraints: {
            numberOfSelections: match(
                constraints.find(
                    c => c.__typename === 'NumberOfSelectionsConstraint'
                ) as NumberOfSelectionsConstraint | undefined
            )
                .with(P.nullish, () => ({ min: undefined, max: undefined }))
                .otherwise(({ min, max }) => ({
                    min: min ?? undefined,
                    max: max ?? undefined,
                })),
        },
        defaultValue: defaultValue?.selectedIds ?? [],
        options,
        constraintsLocked,
    })

export const sanitiseTelephoneField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        TelephoneFieldDefinition,
        'id' | 'name' | 'description' | 'defaultValue' | 'constraints'
    >): TelephoneField => ({
        type: FieldType.Telephone,
        id,
        name,
        description: description ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        defaultValue: match(defaultValue)
            .with(P.nullish, () => ({
                countryCode: undefined,
                number: undefined,
            }))
            .otherwise(({ countryCode, number }) => ({
                countryCode: countryCode ?? undefined,
                number: number ?? undefined,
            })),
        constraintsLocked,
    })

export const sanitiseURLField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        UrlFieldDefinition,
        'id' | 'name' | 'description' | 'constraints' | 'defaultValue'
    >): UrlField => ({
        type: FieldType.Url,
        id,
        name,
        description: description ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        defaultValue: defaultValue ?? '',
        constraintsLocked,
    })

export const sanitiseUserField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
        defaultValue,
    }: Pick<
        UserFieldDefinition,
        'id' | 'name' | 'description' | 'constraints'
    > & {
        defaultValue: { id: string } | null | undefined
    }): UserField => ({
        type: FieldType.User,
        id,
        name,
        description: description ?? '',
        constraints: {
            required: constraints.some(
                c => c.__typename === 'RequiredConstraint'
            ),
        },
        defaultValue: defaultValue?.id ?? undefined,
        constraintsLocked,
    })

export const sanitiseRelationField =
    (constraintsLocked: boolean) =>
    ({
        id,
        name,
        description,
        constraints,
    }: {
        id: string
        name: string
        description?: string | null | undefined
        constraints: BizObjDef_Relation_UniqueFragment['relationConstraints']
    }): RelationField | undefined => {
        // All relation fields should have a relation type constraint
        // if one is not present we ignore this field
        const relationConstraint = constraints.find(
            c => c.__typename === 'RelationTypeConstraint'
        ) as RelationTypeConstraint | undefined
        const relationType = relationConstraint?.types[0]
        return (
            relationType && {
                type: FieldType.Relation,
                id,
                name,
                description: description ?? '',
                constraints: {
                    required: constraints.some(
                        c => c.__typename === 'RequiredConstraint'
                    ),
                    relationType: {
                        id: relationType.id,
                        name: relationType.name,
                    },
                },
                constraintsLocked,
            }
        )
    }
