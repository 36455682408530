import { styled } from 'Adapters/Freestyled'
import { FC, useState } from 'react'
import { AppHeader } from './Header'
import { AppMain } from './Main'

export const AppContent: FC = () => {
    const [workspaceControlOpen, setWorkspaceControlOpen] = useState(false)
    return (
        <Styled>
            <AppHeader setWorkspaceControlOpen={setWorkspaceControlOpen} />
            <AppMain
                workspaceControlOpen={workspaceControlOpen}
                setWorkspaceControlOpen={setWorkspaceControlOpen}
            />
        </Styled>
    )
}

const Styled = styled.div`
    height: 100dvh;
    width: 100vw;
    display: grid;
    grid-template: auto minmax(0, 1fr) / minmax(0, 1fr);

    > header {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    > main {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }
`
