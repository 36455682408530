import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog'
import { styled } from 'Adapters/Freestyled'
import { getPortalsContainer } from 'Utils'
import { noop } from 'lodash'
import { FC, ReactNode } from 'react'
import { keyframes } from 'styled-components'

type Props = {
    open: boolean
    onOpenChange: (open: boolean) => void
    children: ReactNode
    fullScreen?: boolean
    closeOnOverlayClick?: boolean
}

const MinimalModal: FC<Props> = ({
    open,
    onOpenChange,
    children,
    fullScreen = false,
    closeOnOverlayClick = true,
}) => (
    <Root open={open} onOpenChange={onOpenChange}>
        <Portal container={getPortalsContainer()}>
            <StyledOverlay
                onClick={e => {
                    e.stopPropagation()
                    closeOnOverlayClick ? onOpenChange(false) : noop()
                }}
            />
            <StyledContent data-fullscreen={fullScreen}>
                {/* When time permits we should add swipe detection */}
                <button
                    onClick={e => {
                        e.stopPropagation()
                        onOpenChange(false)
                    }}
                >
                    <span />
                </button>
                <div>{children}</div>
            </StyledContent>
        </Portal>
    </Root>
)

const DURATION = '200ms'

const enter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const exit = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

const slideUp = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`

const slideDown = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
`

const StyledOverlay = styled(Overlay)`
    position: fixed;
    top: 0;
    left: 0;
    height: var(--safe-height);
    width: var(--safe-width);
    background-color: ${({ theme }) => theme.palette.overlay['01'].normal};
    cursor: pointer;

    &[data-state='open'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease;
        animation-name: ${enter};
    }

    &[data-state='closed'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease;
        animation-name: ${exit};
    }
`

const StyledContent = styled(Content)`
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 90dvh;
    width: var(--safe-width);
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: 1.25rem 1.25rem 0 0;
    display: grid;
    grid-template: auto 1fr/ 1fr;

    &[data-fullscreen='true'] {
        height: calc(var(--safe-height) - 2rem);
        max-height: calc(var(--safe-height) - 2rem);
    }

    > button {
        padding: 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    > button span {
        display: block;
        width: 2.5rem;
        height: 0.125rem;
        background-color: ${({ theme }) => theme.palette.ui['04'].normal};
    }

    > div {
        overflow: hidden;
    }

    &[data-state='open'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-out;
        animation-name: ${slideUp};
    }

    &[data-state='closed'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-in;
        animation-name: ${slideDown};
    }
`

export { MinimalModal }
