import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { regular5 } from 'Components/Text'
import { AnchorProps, Upload } from 'Components/Upload'
import { BOField, BizObjDef_Doc } from 'Hooks'
import { isEqual } from 'lodash'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { DocumentDisplay } from './DocumentDisplay'
import { useDocumentConstraints } from './useDocumentConstraints'

type Props = BOField<'BusinessObjectDocumentField'> & {
    businessObjectId: string
    hideViewDocumentButton: boolean
    onComplete: () => void
}

export const DocumentField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    docValue,
    hideViewDocumentButton,
    onComplete,
}) => {
    const { docConstraints, ...common } = fieldDefinition as BizObjDef_Doc

    const { constraints, constraintsTooltip } = useDocumentConstraints({
        docConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(
        docValue
            ? {
                  name: docValue.name,
                  id: docValue.id,
              }
            : undefined
    )

    const [document, setDocument] = useState(initialValue.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <Upload
                anchor={AddDocumentButton}
                anchorProps={{
                    text: document ? document.name : 'Add a document',
                }}
                onUploadCompleted={setDocument}
                existingSelections={{
                    selectMode: 'single',
                    allowExistingSelection: true,
                    existingSelections: docValue ? [docValue.id] : [],
                    onExistingDocumentSelected: documents => {
                        setDocument(documents[0])
                    },
                }}
            />

            {!!document ? (
                <IconTextButton
                    icon={'Close'}
                    text={'Clear'}
                    onClick={() => {
                        setDocument(undefined)
                    }}
                    variant="secondary"
                />
            ) : null}

            {!!document && !hideViewDocumentButton ? (
                <DocumentDisplay id={document.id} />
            ) : null}

            <EditFieldButton
                disabled={isEqual(initialValue.current, document) || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current?.id,
                                value: document?.id,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = document
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}

const AddDocumentButton: FC<AnchorProps<{ text: string }>> = ({
    buttonRef,
    onClick,
    text,
}) => (
    <StyledAddDocumentButton
        icon="Document"
        text={text}
        ref={buttonRef}
        onClick={onClick}
    />
)

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
