import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Breadcrumb } from '../../../Breadcrumb/Breadcrumb'
import { ProcessDef_SingleProcessQuery } from '../__generated__/query'
import { Backstop } from './Backstop'
import { DeleteProcessDefinition } from './DeleteProcessDefinition'
import { Editor } from './Editor'
import { FreeEditor } from './FreeEditor'
import { ObjectDefTag } from './ObjectDefTag'
import { UseProcessButton } from './UseProcessButton'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
    onProcessDeleted?: () => void
    canDelete: boolean
    refetch: () => void
    navOptions?: {
        selected: number
        options: {
            text: string
            path: string
        }[]
    }
}

const SingleProcessHeader: FC<Props> = ({
    process,
    onProcessDeleted,
    canDelete,
    refetch,
    navOptions,
}) => {
    const isRestricted = process.usage.businessObjects > 0

    return (
        <Styled>
            <div>
                <div>
                    {navOptions && (
                        <Breadcrumb area="processes" single={navOptions} />
                    )}
                </div>
                <menu>
                    <DeleteProcessDefinition
                        usage={process.usage.businessObjects}
                        processId={process.id}
                        onProcessDeleted={onProcessDeleted}
                        canDelete={canDelete}
                    />

                    {isRestricted ? (
                        <Editor process={process} />
                    ) : (
                        <FreeEditor process={process} refetch={refetch} />
                    )}

                    <UseProcessButton processId={process.id} />
                </menu>
            </div>

            <div>
                <Headline>
                    <Text as="h2" variant="bold-3">
                        {process.name}
                    </Text>
                    {process.description && (
                        <Text as="p" variant="regular-5">
                            {process.description}
                        </Text>
                    )}
                </Headline>
                <Tags>
                    <ObjectDefTag obj={process.operatesUpon[0]} />
                    <Backstop process={process} />
                </Tags>
            </div>
        </Styled>
    )
}

const Styled = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    > div:first-of-type {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;
        width: 100%;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }

        > menu {
            ${flex('row-reverse', 'flex-start', 'center')};
            gap: 0.5rem;

            @media (min-width: ${breakpoints.large}px) {
                flex-direction: row;
                gap: 1rem;
            }
        }
    }

    > div:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 2rem;
    }
`

const Headline = styled.div`
    p,
    h2 {
        display: inline-block;
        vertical-align: baseline;
    }

    h2 {
        padding-right: 1rem;
    }

    p {
        padding-top: 0.25rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

export { SingleProcessHeader }
