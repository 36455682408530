import { flex, styled } from 'Adapters/Freestyled'

export const Styled = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;
    width: 100%;
    flex: 2;

    > button {
        display: grid;
        grid-template-columns: auto 1.5rem;
        grid-template-rows: 1fr;
        align-items: center;
        grid-gap: 1rem;
        padding-right: 0.5rem;
        padding-left: 1rem;
        width: 100%;
        height: 4.5rem;
        border-radius: ${({ theme }) => theme.borderRadius.small};
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        transition: background-color ease
            ${({ theme }) => theme.animation.buttonTransitionDuration};
        min-height: 0;
        min-width: 0;

        .type-icon path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
            transition: fill ease
                ${({ theme }) => theme.animation.buttonTransitionDuration};
        }

        .text {
            overflow: hidden;
            min-width: 0;

            p {
                color: ${({ theme }) => theme.palette.text['02'].normal};
                transition: color ease
                    ${({ theme }) => theme.animation.buttonTransitionDuration};
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }
        }

        .chevron-icon {
            opacity: 0;
            transition: opacity ease
                ${({ theme }) => theme.animation.buttonTransitionDuration};
        }

        @media (hover: hover) {
            &:hover {
                background-color: ${({ theme }) =>
                    theme.palette.ui['01'].hover};
            }
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.ui['01'].active};
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &.current {
            .type-icon path {
                fill: ${({ theme }) => theme.palette.icon['01'].normal};
            }

            p {
                color: ${({ theme }) => theme.palette.text['01'].normal};
            }

            .chevron-icon {
                opacity: 1;
            }
        }
    }
`
