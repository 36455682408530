import { keyBy } from 'lodash'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { byTypename } from 'Utils'
import { ActionCriterion } from './Criteria/ActionCriterion'
import { FieldCriterion } from './Criteria/FieldCriterion'
import { ProcessDelegatePhaseCriterion } from './Criteria/ProcessDelegateCriterion'
import { ProcessFanoutPhaseCriterion } from './Criteria/ProcessFanoutCriterion'
import { CriterionProps } from './types'

export const Criterion: FC<CriterionProps> = ({ criterion, operatesUpon }) => {
    const fieldDefById = useMemo(() => {
        const relationFields = operatesUpon.fields
            .filter(byTypename('RelationFieldDefinition' as const))
            .map(field =>
                field.constraints
                    .filter(byTypename('RelationTypeConstraint' as const))
                    .flatMap(constraint =>
                        constraint.types.flatMap(type => type.fields)
                    )
            )
            .flat()

        return keyBy([...operatesUpon.fields, ...relationFields], f => f.id)
    }, [operatesUpon])

    return match(criterion)
        .with({ __typename: 'ActionPhaseCriterion' }, criterion => (
            <ActionCriterion
                criterion={criterion}
                fieldDefById={fieldDefById}
            />
        ))
        .with({ __typename: 'FieldConditionPhaseCriterion' }, criterion => (
            <FieldCriterion
                criterion={criterion}
                operatesUpon={operatesUpon}
                fieldDefById={fieldDefById}
            />
        ))
        .with({ __typename: 'ProcessFanoutPhaseCriterion' }, criterion => (
            <ProcessFanoutPhaseCriterion
                criterion={criterion}
                fieldDefById={fieldDefById}
            />
        ))
        .with({ __typename: 'ProcessDelegatePhaseCriterion' }, criterion => (
            <ProcessDelegatePhaseCriterion
                criterion={criterion}
                fieldDefById={fieldDefById}
            />
        ))
        .exhaustive()
}
