import { css, flex, focusOutline, styled } from 'Adapters/Freestyled'
import { regular4 } from 'Components/Text'

// Retains a 3:2 aspect ratio
const cardWidth = '28.125rem'
const childWidth = '18.75rem'

export const StyledCard = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: ${cardWidth};
    position: relative;

    p {
        ${regular4};
        color: ${({ theme }) => theme.palette.text['02'].normal};
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const thumbnailStyles = css`
    width: ${cardWidth};
    height: ${childWidth};
    box-shadow: 0 0.05rem 0.3rem 0 ${({ theme }) => theme.palette.ui.shadow};
    border-radius: ${({ theme }) => theme.borderRadius.small};
`

export const StyledImageThumbnail = styled.div`
    ${thumbnailStyles};

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &:focus-visible {
        ${focusOutline};
    }
`

export const StyledIconContainer = styled.div`
    ${flex('column', 'center', 'center')};
    ${thumbnailStyles};
`
