import { useFlow } from '@frigade/react'
import { IconTextButton } from 'Components/Button'
import { FrigadeHint } from 'Features/Frigade/FrigadeHint'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const IDENTIFIER = 'flow_j4kI4pvt'
const STEP_IDENTIFIER = 'use-process-hint'

type Props = {
    processId: string
}

export const UseProcessButton: FC<Props> = ({ processId }) => {
    const navigate = useNavigate()

    const { flow } = useFlow(IDENTIFIER)

    const hint = flow?.steps.get(STEP_IDENTIFIER)

    if (!hint) return null

    return (
        <>
            <FrigadeHint flowId={IDENTIFIER} stepId={STEP_IDENTIFIER}>
                <IconTextButton
                    icon="Process"
                    text="Use workflow"
                    size="xSmall"
                    variant="primary"
                    onClick={() => {
                        if (!hint.$state.completed) {
                            hint.complete()
                        }

                        navigate(`/processes/${processId}`)
                    }}
                />
            </FrigadeHint>
        </>
    )
}
