import { ApolloError } from '@apollo/client'
import { logger } from 'Adapters/Logger'
import { useCallback } from 'react'
import { CreateIntegrationState } from '../../IntegrationEditor'
import {
    useCreate_Integration_InstanceMutation,
    useDelete_Integration_InstanceMutation,
} from './__generated__/mutation'

export const useSaveIntegrations = ({
    onError,
}: {
    onError?: (error: ApolloError) => void
}) => {
    const [saveIntegration, { loading: saving }] =
        useCreate_Integration_InstanceMutation()
    const [deleteIntegration, { loading: deleting }] =
        useDelete_Integration_InstanceMutation()

    const saveIntegrations = useCallback(
        async (
            integrations: CreateIntegrationState[],
            initialIntegrations: Array<{ id: string }>,
            processId: string
        ) => {
            await Promise.all(
                initialIntegrations.map(async integration => {
                    await deleteIntegration({
                        variables: { input: { id: integration.id } },
                        onError: err => {
                            logger.error(
                                'Failed to delete process integration',
                                err,
                                {
                                    integration,
                                }
                            )
                            onError?.(err)
                        },
                    })
                })
            )
            await Promise.all(
                integrations.map(integration =>
                    saveIntegration({
                        variables: {
                            input: {
                                ...integration,
                                trigger: {
                                    ...integration.trigger,
                                    filter: {
                                        ...integration.trigger.filter,
                                        params: JSON.stringify({
                                            ...integration.trigger.filter
                                                .params,
                                            processId,
                                        }),
                                    },
                                },
                                action: {
                                    ...integration.action,
                                    params: JSON.stringify(
                                        integration.action.params
                                    ),
                                },
                            },
                        },
                        onError: err => {
                            logger.error(
                                'Failed to save process integration',
                                err,
                                {
                                    integration,
                                }
                            )
                            onError?.(err)
                        },
                    })
                )
            )
        },
        [deleteIntegration, onError, saveIntegration]
    )

    return { saveIntegrations, mutating: saving || deleting }
}
