import {
    EditorAction,
    State as EditorState,
} from 'Features/BusinessObjectDefinitionEditor'
import { ModelManager_SingleBusinessObjectDefinitionQuery } from '../__generated__/q'

export enum Status {
    Idle = 'Idle',
    Saving = 'Saving',
    Success = 'Success',
    Error = 'Error',
}

export type Data = Pick<
    ModelManager_SingleBusinessObjectDefinitionQuery,
    'businessObjectDefinitions' | 'currencies' | 'users'
> & {
    businessObjectDefinition: NonNullable<
        ModelManager_SingleBusinessObjectDefinitionQuery['businessObjectDefinition']
    >
}

export type State = Omit<EditorState, 'definition'> & {
    definition: EditorState['definition'] & { id: string }
    open: boolean
    status: Status
}

export type Open = {
    type: 'open'
    payload: undefined
}

export type Close = {
    type: 'close'
    payload: undefined
}

export type SetStatus = {
    type: 'setStatus'
    payload: { status: Status }
}

export type Action = Open | Close | EditorAction | SetStatus

export type Handle<T extends Action> = (prev: State) => (action: T) => State
