import { styled } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { IconTextButton } from 'Components/Button'
import { GridTable } from 'Components/GridTable'
import { Icon } from 'Components/Icon'
import { Tooltip } from 'Components/Tooltip'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import dayjs from 'dayjs'
import { compact } from 'lodash'
import { ComponentProps } from 'react'
import { P, match } from 'ts-pattern'

type GetDynamicCell = (
    field: BusinessObjectTableItemFragment['fields'][number],
    actions: {
        onRelationClick: (id: string) => void
    }
) => ComponentProps<
    typeof GridTable
>['groups'][number]['rows'][number]['cells'][string]

const getDynamicCell: GetDynamicCell = (field, actions) =>
    match(field)
        .with(
            {
                __typename: 'BusinessObjectSelectField',
                fieldDefinition: { __typename: 'SelectFieldDefinition' },
            },
            ({ fieldDefinition, selectValue }) => ({
                element: match(
                    fieldDefinition.selectOptions.filter(o =>
                        selectValue.includes(o.id)
                    )
                )
                    .with([], () => (
                        <StyledUnselected>
                            <Icon name="CheckboxTickedInverted" />
                            <span>0 selected</span>
                        </StyledUnselected>
                    ))
                    .with([P.not(P.nullish)], ([{ value }]) => (
                        <StyledIconTextCell>
                            <Icon name="CheckboxTickedInverted" />
                            <span>{value}</span>
                        </StyledIconTextCell>
                    ))
                    .otherwise(options => (
                        <Tooltip
                            content={
                                <StyledTooltipList>
                                    {options.map(o => (
                                        <li key={o.id}>{o.value}</li>
                                    ))}
                                </StyledTooltipList>
                            }
                        >
                            <StyledIconTextCell>
                                <Icon name="CheckboxTickedInverted" />
                                <span>{`${options.length} selected`}</span>
                            </StyledIconTextCell>
                        </Tooltip>
                    )),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectListField',
                fieldDefinition: { __typename: 'ListFieldDefinition' },
            },
            ({ fieldDefinition, listValue }) => ({
                element: match(listValue)
                    .with([], () => (
                        <StyledUnselected>
                            <Icon name="Bullet" />
                            <span>0 items</span>
                        </StyledUnselected>
                    ))
                    .with([P.not(P.nullish)], () => (
                        <StyledIconTextCell>
                            <Icon name="Bullet" />
                            <span>{`1 item`}</span>
                        </StyledIconTextCell>
                    ))
                    .otherwise(items => (
                        <StyledIconTextCell>
                            <Icon name="Bullet" />
                            <span>{`${items.length} items`}</span>
                        </StyledIconTextCell>
                    )),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectBooleanField',
                fieldDefinition: { __typename: 'BooleanFieldDefinition' },
            },
            ({ booleanValue }) => ({
                element: `${booleanValue}`,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectDocumentField',
                fieldDefinition: { __typename: 'DocumentFieldDefinition' },
            },
            ({ docValue }) => ({
                element: docValue?.name,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectEmailField',
                fieldDefinition: { __typename: 'EmailFieldDefinition' },
            },
            ({ emailValue }) => ({
                element: emailValue,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectNumberField',
                fieldDefinition: { __typename: 'NumberFieldDefinition' },
            },
            ({ numValue }) => ({
                element: match(numValue)
                    .with(P.number, val => `${val}`)
                    .otherwise(() => undefined),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectTextField',
                fieldDefinition: { __typename: 'TextFieldDefinition' },
            },
            ({ textValue }) => ({ element: textValue })
        )
        .with(
            {
                __typename: 'BusinessObjectTelephoneField',
                fieldDefinition: { __typename: 'TelephoneFieldDefinition' },
            },
            ({ telephoneValue }) => ({
                element: match(telephoneValue)
                    .with(P.nullish, () => undefined)
                    .otherwise(({ countryCode, number }) =>
                        compact([countryCode, number]).join(' ')
                    ),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectUrlField',
                fieldDefinition: { __typename: 'URLFieldDefinition' },
            },
            ({ urlValue }) => ({
                element: urlValue && (
                    <StyledAnchor
                        target="_blank"
                        rel="noreferrer"
                        href={urlValue}
                    >
                        {urlValue}
                    </StyledAnchor>
                ),
                stopPropagation: !!urlValue,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectCurrencyField',
                fieldDefinition: { __typename: 'CurrencyFieldDefinition' },
            },
            ({ currencyValue }) => ({
                element:
                    currencyValue &&
                    `${currencyValue.currencyDetails.symbol}${currencyValue.amount} (${currencyValue.currencyDetails.code})`,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectUserField',
                fieldDefinition: { __typename: 'UserFieldDefinition' },
            },
            ({ userValue }) => ({
                element: userValue && (
                    <StyledUserCell>
                        <Avatar name={userValue.name} size="xSmall" />
                        <span>{userValue.name}</span>
                    </StyledUserCell>
                ),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectRelationField',
                fieldDefinition: { __typename: 'RelationFieldDefinition' },
            },
            ({ relationValue }) => ({
                element: relationValue && (
                    <IconTextButton
                        icon="Cube"
                        text={relationValue.label}
                        onClick={() =>
                            actions.onRelationClick(relationValue.id)
                        }
                    />
                ),
                stopPropagation: !!relationValue,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectDateField',
                fieldDefinition: { __typename: 'DateFieldDefinition' },
            },
            ({ dateValue }) => ({
                element: dateValue && (
                    <StyledIconTextCell>
                        <Icon name="Calendar" />
                        <span>{dayjs(dateValue).format('DD MMM YYYY')}</span>
                    </StyledIconTextCell>
                ),
            })
        )
        .otherwise(() => ({
            // Not yet implemented
            element: <p>Not implemented</p>,
        }))

const StyledIconTextCell = styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    svg {
        --icon-size: 1rem;
        height: var(--icon-size);
        width: var(--icon-size);
        min-height: var(--icon-size);
        min-width: var(--icon-size);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    > span:last-child {
        white-space: nowrap;
    }
`

const StyledUserCell = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

const StyledUnselected = styled(StyledIconTextCell)`
    color: ${({ theme }) => theme.palette.text['03'].normal};
    opacity: 0.5;
`

const StyledTooltipList = styled.ul`
    text-align: left;
    list-style: disc;
    padding-left: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const StyledAnchor = styled.a`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    transition: color ${({ theme }) => theme.animation.buttonTransitionDuration}
        ease;

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support03.active};
    }
`

export { getDynamicCell }
