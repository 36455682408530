import {
    FieldType,
    createDefinitionFieldInput,
} from 'Utils/BusinessObjectDefinition'
import {
    FieldType as GeneratedFieldType,
    PatchBusinessObjectAddFieldInput,
    PatchBusinessObjectAddSelectFieldOption,
    PatchBusinessObjectDefinitionFieldDefaultInput,
    PatchBusinessObjectDefinitionOperationInput,
    PatchBusinessObjectDefinitionRemoveFieldInput,
    PatchBusinessObjectUpdateFieldDescriptionInput,
    PatchBusinessObjectUpdateFieldNameInput,
} from '__generated__'
import { compact } from 'lodash'
import { P, match } from 'ts-pattern'
import { Data } from '../types'
import { FieldsFromState } from './types'

// This UX does not currently facilitat the "removeFieldConstraint" operation.
// Constraints are not stored here as collections but as well structured objects.
// Field constraints are defined in forms with no simple way of giving the user
// a 'remove constraint' action.
// This functionality will need to be dewlivered seperately, but should arguably
// be superceded by a general approach to constraint modification, which should
// form part of a broader change management strategy.

type CreateRemoveFieldOperation = (_: { fieldId: string }) => {
    removeField: PatchBusinessObjectDefinitionRemoveFieldInput
}

const createRemoveFieldOperation: CreateRemoveFieldOperation = ({
    fieldId,
}) => ({
    removeField: {
        fieldId,
    },
})

type CreateAddFieldOperation = (_: { field: FieldsFromState[number] }) => {
    addField: PatchBusinessObjectAddFieldInput
}

const createAddFieldOperation: CreateAddFieldOperation = ({ field }) => {
    const nextValue = match(field)
        .with({ type: GeneratedFieldType.Updates }, () => ({
            name: 'Updates',
            description: 'Updates about this object',
            [GeneratedFieldType.List]: {
                listOf: {
                    name: 'Update',
                    [GeneratedFieldType.Updates]: {},
                },
            },
        }))
        .otherwise(createDefinitionFieldInput)
    return { addField: { nextValue } }
}

type CreateUpdateFieldName = (_: {
    field: FieldsFromState[number]
    extant: Data['businessObjectDefinition']['fields'][number]
}) =>
    | {
          updateFieldName: PatchBusinessObjectUpdateFieldNameInput
      }
    | undefined
const createUpdateFieldName: CreateUpdateFieldName = ({ field, extant }) =>
    match(field)
        .with({ name: extant.name }, () => undefined)
        .otherwise(({ name: nextValue }) => ({
            updateFieldName: {
                fieldId: field.id,
                previousValue: extant.name,
                nextValue,
            },
        }))

type CreateUpdateFieldDescription = (_: {
    field: FieldsFromState[number]
    extant: Data['businessObjectDefinition']['fields'][number]
}) =>
    | {
          updateFieldDescription: PatchBusinessObjectUpdateFieldDescriptionInput
      }
    | undefined
const createUpdateFieldDescription: CreateUpdateFieldDescription = ({
    field,
    extant,
}) =>
    match(field)
        .with({ description: extant.description ?? '' }, () => undefined)
        .otherwise(({ description }) => ({
            updateFieldDescription: {
                fieldId: field.id,
                previousValue: extant.description,
                nextValue: description ?? '',
            },
        }))

type CreateUpdateFieldDefault = (_: {
    field: FieldsFromState[number]
    extant: Data['businessObjectDefinition']['fields'][number]
}) =>
    | {
          updateFieldDefault: PatchBusinessObjectDefinitionFieldDefaultInput
      }
    | undefined
const createUpdateFieldDefault: CreateUpdateFieldDefault = ({
    field,
    extant,
}) =>
    match({
        field,
        extant,
    })
        .with(
            {
                field: { type: FieldType.Boolean },
                extant: { __typename: 'BooleanFieldDefinition' },
            },
            ({
                field: { id, defaultValue },
                extant: { booleanDefaultValue },
            }) =>
                booleanDefaultValue !== defaultValue
                    ? {
                          updateFieldDefault: {
                              boolean: {
                                  fieldId: id,
                                  nextValue: !!defaultValue,
                                  previousValue: booleanDefaultValue,
                              },
                          },
                      }
                    : undefined
        )
        .with(
            {
                field: { type: FieldType.Number },
                extant: { __typename: 'NumberFieldDefinition' },
            },
            ({ field: { id, defaultValue }, extant: { numDefaultValue } }) =>
                (numDefaultValue ?? undefined) !== defaultValue
                    ? {
                          updateFieldDefault: {
                              number: {
                                  fieldId: id,
                                  nextValue: defaultValue,
                                  previousValue: numDefaultValue,
                              },
                          },
                      }
                    : undefined
        )
        .with(
            {
                field: { type: FieldType.Text },
                extant: { __typename: 'TextFieldDefinition' },
            },
            ({ field: { id, defaultValue }, extant: { textDefaultValue } }) => {
                return (textDefaultValue || undefined) !==
                    (defaultValue || undefined)
                    ? {
                          updateFieldDefault: {
                              text: {
                                  fieldId: id,
                                  nextValue: defaultValue || null,
                                  previousValue: textDefaultValue,
                              },
                          },
                      }
                    : undefined
            }
        )
        .with(
            {
                field: { type: FieldType.Email },
                extant: { __typename: 'EmailFieldDefinition' },
            },
            ({
                field: { id, defaultValue },
                extant: { emailDefaultValue },
            }) => {
                const prev = emailDefaultValue ?? undefined
                return prev !== defaultValue
                    ? {
                          updateFieldDefault: {
                              email: {
                                  fieldId: id,
                                  nextValue: defaultValue || null,
                                  previousValue: emailDefaultValue,
                              },
                          },
                      }
                    : undefined
            }
        )
        .with(
            {
                field: { type: FieldType.Url },
                extant: { __typename: 'URLFieldDefinition' },
            },
            ({ field: { id, defaultValue }, extant: { urlDefaultValue } }) => {
                const prev = urlDefaultValue ?? undefined
                return prev !== defaultValue
                    ? {
                          updateFieldDefault: {
                              url: {
                                  fieldId: id,
                                  nextValue: defaultValue || null,
                                  previousValue: urlDefaultValue,
                              },
                          },
                      }
                    : undefined
            }
        )
        .with(
            {
                field: { type: FieldType.Date },
                extant: { __typename: 'DateFieldDefinition' },
            },
            ({ field: { id, defaultValue }, extant: { dateDefaultValue } }) => {
                const ab =
                    defaultValue && 'absolute' in defaultValue
                        ? defaultValue.absolute
                        : undefined
                const rel =
                    defaultValue && 'relative' in defaultValue
                        ? defaultValue.relative
                        : undefined

                return (dateDefaultValue?.absolute ?? undefined !== ab) ||
                    (dateDefaultValue?.relative ?? undefined !== rel)
                    ? {
                          updateFieldDefault: {
                              date: {
                                  fieldId: id,
                                  nextValue: defaultValue,
                                  previousValue: dateDefaultValue && {
                                      absolute: dateDefaultValue.absolute,
                                      relative: dateDefaultValue.relative,
                                  },
                              },
                          },
                      }
                    : undefined
            }
        )
        .with(
            {
                field: { type: FieldType.Currency },
                extant: { __typename: 'CurrencyFieldDefinition' },
            },
            ({
                field: { id, defaultValue },
                extant: { currencyDefaultValue },
            }) => {
                const prevAmount = currencyDefaultValue?.amount ?? undefined
                const prevCode = currencyDefaultValue?.currencyCode ?? undefined

                return prevAmount === defaultValue.amount &&
                    prevCode === defaultValue.currencyCode
                    ? undefined
                    : {
                          updateFieldDefault: {
                              currency: {
                                  fieldId: id,
                                  nextValue: {
                                      currencyCode:
                                          defaultValue.currencyCode ?? null,
                                      amount: defaultValue.amount ?? null,
                                  },
                                  previousValue: {
                                      currencyCode:
                                          currencyDefaultValue?.currencyCode ??
                                          null,
                                      amount:
                                          currencyDefaultValue?.amount ?? null,
                                  },
                              },
                          },
                      }
            }
        )
        .with(
            {
                field: { type: FieldType.User },
                extant: { __typename: 'UserFieldDefinition' },
            },
            ({ field: { id, defaultValue }, extant: { userDefaultValue } }) =>
                userDefaultValue?.id ?? undefined !== defaultValue
                    ? {
                          updateFieldDefault: {
                              user: {
                                  fieldId: id,
                                  nextValue: defaultValue || null,
                                  previousValue: userDefaultValue?.id ?? null,
                              },
                          },
                      }
                    : undefined
        )
        .with(
            {
                field: { type: FieldType.Telephone },
                extant: { __typename: 'TelephoneFieldDefinition' },
            },
            ({
                field: { id, defaultValue },
                extant: { telephoneDefaultValue },
            }) => {
                const prevCode = telephoneDefaultValue?.countryCode ?? undefined
                const prevNumber = telephoneDefaultValue?.number ?? undefined
                const codeIsEqual = prevCode === defaultValue.countryCode
                const numberIsEqual = prevNumber === defaultValue.number
                return codeIsEqual && numberIsEqual
                    ? undefined
                    : {
                          updateFieldDefault: {
                              telephone: {
                                  fieldId: id,
                                  nextValue: defaultValue,
                                  previousValue: {
                                      countryCode: prevCode,
                                      number: prevNumber,
                                  },
                              },
                          },
                      }
            }
        )
        .otherwise(() => undefined)

type CreateSelectFieldOptionsToAdd = (_: {
    field: FieldsFromState[number]
    extant: Data['businessObjectDefinition']['fields'][number]
}) => {
    addSelectFieldOption: PatchBusinessObjectAddSelectFieldOption
}[]
const createSelectFieldOptionsToAdd: CreateSelectFieldOptionsToAdd = ({
    field,
    extant,
}) =>
    match({
        field,
        extant,
    })
        .with(
            {
                field: { type: FieldType.Select },
                extant: { __typename: 'SelectFieldDefinition' },
            },
            ({ field: { options }, extant: { selectOptions } }) => {
                const prevIds = selectOptions.map(({ id }) => id)
                const newOptions = options
                    .slice(prevIds.length)
                    .map(({ value: nextValue }, i) => ({
                        addSelectFieldOption: {
                            fieldId: field.id,
                            nextValue,
                            previousValue: [
                                ...prevIds,
                                ...new Array(i)
                                    .fill(undefined)
                                    .map(
                                        (_, j) =>
                                            `_NEW_SELECT_OPTION_ID_-${
                                                prevIds.length + j - 1
                                            }`
                                    ),
                            ],
                        },
                    }))
                return newOptions
            }
        )
        .otherwise(() => [])

type CreateFieldModificationOperations = (_: {
    field: FieldsFromState[number]
    definition: Data['businessObjectDefinition']
}) => PatchBusinessObjectDefinitionOperationInput[]

const createFieldModificationOperations: CreateFieldModificationOperations = ({
    definition,
    field,
}) =>
    match(definition.fields.find(({ id }) => id === field.id))
        .with(P.nullish, () => [])
        .otherwise(extant =>
            compact([
                createUpdateFieldName({ field, extant }),
                createUpdateFieldDescription({ field, extant }),
                createUpdateFieldDefault({ field, extant }),
                ...createSelectFieldOptionsToAdd({ field, extant }),
            ])
        )

type CreateFieldOperations = (_: {
    definition: Data['businessObjectDefinition']
    fields: FieldsFromState
}) => PatchBusinessObjectDefinitionOperationInput[]

const createFieldOperations: CreateFieldOperations = ({
    definition,
    fields,
}) => {
    const extantFieldIds = new Set(definition.fields.map(field => field.id))
    const definitionFieldIds = new Set(fields.map(field => field.id))

    const operations = [
        ...new Set([...extantFieldIds, ...definitionFieldIds]),
    ].flatMap(id =>
        match(id)
            .when(
                fieldId => !extantFieldIds.has(fieldId),
                fieldId => {
                    const field = fields.find(field => field.id === fieldId)
                    return field
                        ? [
                              createAddFieldOperation({
                                  field,
                              }),
                          ]
                        : []
                }
            )
            .when(
                fieldId => !definitionFieldIds.has(fieldId),
                fieldId => [createRemoveFieldOperation({ fieldId })]
            )
            .otherwise(fieldId => {
                const field = fields.find(field => field.id === fieldId)
                return field
                    ? createFieldModificationOperations({
                          field,
                          definition,
                      })
                    : []
            })
    )
    return operations
}

export { createFieldOperations }
