import { Select } from 'Components/Select'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { useOrganisationUsers } from 'Hooks'
import { FC, useEffect, useState } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useUserConstraints } from './useUserConstraints'

export const UserField: FC<BODField<'UserFieldDefinition'>> = ({
    userDefaultValue,
    userConstraints,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useUserConstraints({
        userConstraints,
    })

    const { users } = useOrganisationUsers()

    const [value, setValue] = useState(() => {
        const initialValue = state[common.id].value as string | undefined
        return initialValue ?? ''
    })

    useEffect(() => {
        dispatch({
            type: 'updateUserField',
            payload: {
                id: common.id,
                value: value ?? undefined,
            },
        })
    }, [value, dispatch, common.id])

    return (
        <Field
            id={common.id}
            name={common.name}
            description={common.description ?? ''}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <Select
                id="user"
                value={value}
                onValueChange={setValue}
                name="user"
                options={[
                    {
                        disabled: true,
                        text: '-- select a user --',
                        value: '',
                    },
                    ...users.map(user => ({
                        text: user.isCurrentUser ? 'Me' : user.name,
                        value: user.id,
                        disabled: false,
                    })),
                ]}
                disabled={!users.length}
            />
        </Field>
    )
}
