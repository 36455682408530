import { styled } from 'Adapters/Freestyled'
import { ActionCard } from 'Features/ActionView/ActionCard'
import { FC } from 'react'
import { Action } from '../../types'

type Props = {
    actions: Action[]
    mostRecentAction: string | undefined
}

export const CardDisplay: FC<Props> = ({ actions, mostRecentAction }) => {
    return (
        <StyledCardDisplay>
            {actions.map(({ id }) => (
                <ActionCard
                    id={id}
                    key={id}
                    isMostRecent={id === mostRecentAction}
                />
            ))}
        </StyledCardDisplay>
    )
}

const StyledCardDisplay = styled.section`
    display: grid;
    padding: 2rem 3rem 2rem 3rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: auto;
    gap: 1rem;
    width: 100%;
`
