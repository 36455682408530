import { dayjs, isDurationIsoString } from 'Adapters/DayJS'
import { determineSelectUIType } from 'Features/CreateBusinessObjectModal/CreateBusinessObjectForm/Fields/SelectField/helpers'
import { find, omit } from 'lodash'
import { match } from 'ts-pattern'
import { oneOfPair } from 'Utils'
import {
    CreateBusinessObjectField,
    CreateBusinessObjectTelephoneFieldValue,
    CurrencyFieldValue,
} from '__generated__'
import { BODFields } from '../../types'
import { CreateFormState, ListFieldValue } from './types'

export const generateInitialState = (
    fields: BODFields,
    initialValues?: CreateBusinessObjectField[]
): CreateFormState => {
    return fields.reduce((out, field) => {
        const initialDefaultValue = initialValues
            ?.map(initialValueObj => Object.values(initialValueObj)[0])
            .find(
                initialValue => initialValue?.fieldDefinitionId === field.id
            )?.value

        return match(field)
            .with(
                { __typename: 'BooleanFieldDefinition' },
                ({ id, type, booleanDefaultValue }) => {
                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'boolean'
                                    ? initialDefaultValue
                                    : booleanDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'TextFieldDefinition' },
                ({ id, type, textDefaultValue }) => {
                    const definitionDefaultValue = textDefaultValue ?? ''

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'string'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'URLFieldDefinition' },
                ({ id, type, urlDefaultValue }) => {
                    const definitionDefaultValue = urlDefaultValue ?? ''

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'string'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'EmailFieldDefinition' },
                ({ id, type, emailDefaultValue }) => {
                    const definitionDefaultValue = emailDefaultValue ?? ''

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'string'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'NumberFieldDefinition' },
                ({ id, type, numDefaultValue }) => {
                    const definitionDefaultValue = numDefaultValue ?? undefined

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'number'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'TelephoneFieldDefinition' },
                ({ id, type, telephoneDefaultValue }) => {
                    const initDefaultValue = initialDefaultValue as
                        | CreateBusinessObjectTelephoneFieldValue
                        | undefined

                    return {
                        ...out,
                        [id]: {
                            type,
                            value: {
                                countryCode: initDefaultValue?.countryCode
                                    ? initDefaultValue.countryCode
                                    : telephoneDefaultValue?.countryCode ?? '',
                                number: initDefaultValue?.number
                                    ? initDefaultValue.number
                                    : telephoneDefaultValue?.number ??
                                      undefined,
                            },
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'UserFieldDefinition' },
                ({ id, type, userDefaultValue }) => {
                    const definitionDefaultValue =
                        userDefaultValue?.id ?? undefined

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'string'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with({ __typename: 'DocumentFieldDefinition' }, ({ id, type }) => {
                return {
                    ...out,
                    [id]: {
                        type,
                        value:
                            typeof initialDefaultValue === 'string'
                                ? initialDefaultValue
                                : undefined,
                        errors: undefined,
                    },
                }
            })
            .with(
                { __typename: 'DateFieldDefinition' },
                ({ id, type, dateDefaultValue }) => {
                    let definitionDefaultValue = !dateDefaultValue
                        ? ''
                        : oneOfPair(omit(dateDefaultValue, '__typename'))[1]

                    if (isDurationIsoString(definitionDefaultValue)) {
                        definitionDefaultValue = dayjs()
                            .add(dayjs.duration(definitionDefaultValue))
                            .toISOString()
                    }

                    return {
                        ...out,
                        [id]: {
                            type,
                            value:
                                typeof initialDefaultValue === 'string'
                                    ? initialDefaultValue
                                    : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'SelectFieldDefinition' },
                ({
                    id,
                    type,
                    selectDefaultValue,
                    selectConstraints,
                    selectOptions,
                }) => {
                    const noOfSelectionsConstraints = find(
                        selectConstraints,
                        constraint =>
                            constraint.__typename ===
                            'NumberOfSelectionsConstraint'
                    )

                    const UIType = determineSelectUIType(
                        noOfSelectionsConstraints?.min ?? undefined,
                        noOfSelectionsConstraints?.max ?? undefined
                    )

                    const defaultSelections = selectDefaultValue
                        ? selectDefaultValue.selectedIds
                        : []

                    const definitionDefaultValue =
                        UIType === 'radio' && !defaultSelections
                            ? [selectOptions[0].id]
                            : defaultSelections

                    const initDefaultValue = initialDefaultValue as
                        | string[]
                        | undefined

                    return {
                        ...out,
                        [id]: {
                            type,
                            value: Array.isArray(initDefaultValue)
                                ? initDefaultValue
                                : definitionDefaultValue,
                            errors: undefined,
                        },
                    }
                }
            )
            .with(
                { __typename: 'RelationFieldDefinition' },
                ({ id, type }) => ({
                    ...out,
                    [id]: {
                        type,
                        value:
                            typeof initialDefaultValue === 'string'
                                ? initialDefaultValue
                                : '',
                        errors: undefined,
                    },
                })
            )
            .with({ __typename: 'ListFieldDefinition' }, ({ id, type }) => {
                const initDefaultValue = initialDefaultValue as
                    | ListFieldValue
                    | undefined

                return {
                    ...out,
                    [id]: {
                        type,
                        value: Array.isArray(initDefaultValue)
                            ? initDefaultValue
                            : [],
                        errors: undefined,
                    },
                }
            })
            .with(
                { __typename: 'CurrencyFieldDefinition' },
                ({ id, type, currencyDefaultValue }) => {
                    const initDefaultValue = initialDefaultValue as
                        | CurrencyFieldValue
                        | undefined

                    return {
                        ...out,
                        [id]: {
                            type,
                            value: initDefaultValue
                                ? initDefaultValue
                                : {
                                      currencyCode:
                                          currencyDefaultValue?.currencyCode ??
                                          undefined,
                                      amount:
                                          currencyDefaultValue?.amount ??
                                          undefined,
                                  },
                            errors: undefined,
                        },
                    }
                }
            )
            .with({ __typename: 'UpdatesFieldDefinition' }, () => out) // not creating updates on BO create
            .exhaustive()
    }, {})
}
