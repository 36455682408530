import { TextInput } from 'Components/Input'
import { always, pipe } from 'lodash/fp'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { fromEvent } from 'Utils'
import { styled } from '../../Adapters/Freestyled'
import { Text } from '../../Components/Text'
import {
    IntegrationActionParamResolverDataLookup,
    IntegrationActionParamResolverInput,
    IntegrationActionParamType,
} from '../../__generated__'
import { ResolveAndSelect } from './ResolveAndSelect'
import { Available_Integration_ActionsQuery } from './__generated__/query'

type Props = {
    onParamChanged: (value: string) => void
    value: string | undefined
    params: Record<string, unknown>
    actions: Available_Integration_ActionsQuery['availableIntegrations'][number]['actions']
    param: {
        name: string
        displayName: string
        required: boolean
        type: IntegrationActionParamType
        resolver:
            | IntegrationActionParamResolverDataLookup
            | IntegrationActionParamResolverInput
    }
}

export const ParamResolver: FC<Props> = ({
    param,
    onParamChanged,
    value,
    params,
    actions,
}) => (
    <label htmlFor={`select-action-param-${param.name}`}>
        {param.displayName}
        {match(param)
            .with(
                {
                    type: IntegrationActionParamType.String,
                },
                param =>
                    match(param.resolver)
                        .with(
                            {
                                __typename:
                                    'IntegrationActionParamResolverInput',
                            },
                            () => (
                                <TextInput
                                    onChange={pipe(fromEvent, onParamChanged)}
                                    value={value ?? ''}
                                />
                            )
                        )
                        .with(
                            {
                                __typename:
                                    'IntegrationActionParamResolverDataLookup',
                            },
                            resolver => {
                                const resolverAction = actions.find(
                                    action => action.name === resolver.action
                                )

                                const missingParams =
                                    resolverAction?.params.filter(p => {
                                        const isUndefinedParam =
                                            typeof params[p.name] ===
                                            'undefined'
                                        return isUndefinedParam && p.required
                                    }) ?? []

                                if (missingParams.length > 0) {
                                    return (
                                        <p>
                                            <Text variant="regular-6" as="div">
                                                Provide these options first
                                                <ul>
                                                    {missingParams.map(p => (
                                                        <StyledParamListItem
                                                            key={p.name}
                                                        >
                                                            {p.displayName}
                                                        </StyledParamListItem>
                                                    ))}
                                                </ul>
                                            </Text>
                                        </p>
                                    )
                                }

                                const relevantParams =
                                    resolverAction?.params.reduce(
                                        (o, p) => ({
                                            ...o,
                                            [p.name]: params[p.name],
                                        }),
                                        {}
                                    ) ?? {}

                                return (
                                    <ResolveAndSelect
                                        resolver={resolver}
                                        params={relevantParams}
                                        value={value}
                                        onValueChanged={onParamChanged}
                                    />
                                )
                            }
                        )
                        .otherwise(always(null))
            )
            .otherwise(always(null))}
    </label>
)

const StyledParamListItem = styled.li`
    margin-left: 1rem;
`
