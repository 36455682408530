import { Icon } from 'Components/Icon'
import { SelectorName, useSelectorFieldMap } from 'Components/SelectorName'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { CriterionConditions } from '../../CriterionConditions/CriterionConditions'
import {
    ProcessDef_SingleProcessQuery,
    useBusinessObjectDefinitionFieldNamesQuery,
} from '../../__generated__/query'
import { FieldDefDictionary } from '../../types'
import { StyledCriterion } from '../styles'
import { CriterionProps } from '../types'

export const FieldCriterion: FC<{
    criterion: Extract<
        CriterionProps['criterion'],
        { __typename: 'FieldConditionPhaseCriterion' }
    >
    operatesUpon: ProcessDef_SingleProcessQuery['process']['operatesUpon'][number]
    fieldDefById: FieldDefDictionary
}> = ({ criterion, operatesUpon, fieldDefById }) => {
    const { data, loading } = useBusinessObjectDefinitionFieldNamesQuery({
        variables: { input: { id: operatesUpon.id } },
    })

    const fieldMap = useSelectorFieldMap(data?.businessObjectDefinition)

    return (
        <StyledCriterion>
            <div className="action">
                <Icon name="CheckboxTicked" />
                <Text as="p" variant="bold-6">
                    Required field
                </Text>
            </div>

            <Text as="p" variant="regular-4">
                {loading ? (
                    'Loading...'
                ) : (
                    <SelectorName
                        selector={criterion.comparator.valueSelector}
                        fieldMap={fieldMap}
                    />
                )}
            </Text>

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </StyledCriterion>
    )
}
