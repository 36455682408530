import { useCallback, useEffect, useState } from 'react'
import { logger } from '../Adapters/Logger'

// certainly not pinched from https://usehooks.com/useLocalStorage/

export function useLocalStorage<T>(
    key: string,
    initialValue: T,
    deps?: unknown[]
) {
    const getFromLS = useCallback(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            logger.error(
                'failed to parse JSON from localstorage',
                error as Error
            )
            return initialValue
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key])

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(getFromLS())

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value
                // Save state
                setStoredValue(valueToStore)
                // Save to local storage
                if (typeof window !== 'undefined') {
                    window.localStorage.setItem(
                        key,
                        JSON.stringify(valueToStore)
                    )
                }
            } catch (error) {
                logger.error(
                    'failed to save value to localstorage',
                    error as Error
                )
            }
        },
        [key, storedValue]
    )

    const unset = useCallback(() => {
        window.localStorage.removeItem(key)
        setStoredValue(initialValue)
    }, [initialValue, key])

    useEffect(() => {
        setStoredValue(getFromLS())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps ?? []), getFromLS])

    return [storedValue, setValue, unset] as const
}
