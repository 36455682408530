import { useLocalStorage } from 'Hooks/useLocalStorageState'
import { noop } from 'lodash'
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

type SandboxUser = { id: string; token: string }

type TSandboxContext = { isSandbox: boolean } & (
    | {
          state: 'disabled'
          activate: () => void
      }
    | {
          state: 'pending'
          user: undefined
          setUser: (user: SandboxUser) => void
          disable: () => void
      }
    | {
          state: 'active'
          user: SandboxUser
          disable: () => void
      }
)

const SandboxContext = createContext<TSandboxContext>({
    isSandbox: false,
    state: 'disabled',
    activate: noop,
})

export const useSandbox = () => useContext(SandboxContext)

export const useSandboxContext = () => {
    const [isSandboxActivated, setIsSandboxActivated] = useState(false)
    const [sandboxUser, setSandboxUser, unsetSandboxUser] = useLocalStorage<
        SandboxUser | undefined
    >('sandbox-user', undefined)

    useEffect(() => {
        if (sandboxUser) {
            setIsSandboxActivated(true)
        }
    }, [sandboxUser, setIsSandboxActivated])

    const disable = useCallback(() => {
        unsetSandboxUser()
        setIsSandboxActivated(false)
    }, [setIsSandboxActivated, unsetSandboxUser])

    const activate = useCallback(() => {
        setIsSandboxActivated(true)
    }, [setIsSandboxActivated])

    const context: TSandboxContext = useMemo(
        () =>
            isSandboxActivated
                ? sandboxUser
                    ? {
                          state: 'active',
                          isSandbox: true,
                          user: sandboxUser,
                          disable,
                      }
                    : {
                          state: 'pending',
                          isSandbox: true,
                          user: undefined,
                          setUser: setSandboxUser,
                          disable,
                      }
                : {
                      state: 'disabled',
                      isSandbox: false,
                      activate,
                  },
        [disable, activate, isSandboxActivated, sandboxUser, setSandboxUser]
    )

    return { context, Provider: SandboxContext.Provider }
}
