import { Comparator, ValueSelector } from 'Components/ComparatorBuilderV2'
import {
    BusinessObjectComparatorInput,
    BusinessObjectValueSelectorInput,
    ValueSelectorType,
} from '__generated__'
import { compact, omit } from 'lodash'
import { P, match } from 'ts-pattern'
import {
    ConditionConstantSelector,
    ConditionDeepSelector,
    ConditionFieldValueSelector,
    ConditionSelector,
    Conditions,
} from './types'

const mapValueSelectorToInputSelector = (
    valueSelector: ConditionSelector | ValueSelector
): BusinessObjectValueSelectorInput => {
    return match(valueSelector)
        .with({ fieldId: P.string }, ({ fieldId }) => ({
            fieldValue: {
                fieldId,
            },
        }))
        .with({ value: P.string }, ({ value }) => ({
            constant: {
                value,
            },
        }))
        .with({ selectors: P.any }, ({ selectors }) => ({
            deep: {
                selectors: selectors.map(mapValueSelectorToInputSelector),
            },
        }))
        .exhaustive()
}

export const mapConditionsToInputConditions = (
    conditions: Conditions
): BusinessObjectComparatorInput[] => {
    return (
        conditions?.map(condition => {
            const valueSelector = mapValueSelectorToInputSelector(
                condition.valueSelector
            )

            return {
                ...omit(condition, '__typename'),
                valueSelector,
            }
        }) ?? []
    )
}

export const mapConditionsToComparators = (
    conditions: Conditions
): Comparator[] => {
    if (!conditions) return []

    return compact(
        conditions.map(condition => {
            return match(condition.valueSelector)
                .with({ fieldId: P.string }, ({ fieldId }) => {
                    return {
                        negate: condition.negate,
                        operator: condition.operator,
                        valueSelector: {
                            type: ValueSelectorType.FieldValue,
                            fieldId,
                        },
                        with: condition.with,
                    } satisfies Comparator
                })
                .otherwise(() => undefined)
        })
    )
}

export const mapComparatorToInputComparator = (
    comparator: Comparator
): BusinessObjectComparatorInput => {
    return {
        negate: comparator.negate,
        operator: comparator.operator,
        valueSelector: mapValueSelectorToInputSelector(
            comparator.valueSelector
        ),
        with: comparator.with,
    }
}

export const mapComparatorsToConditions = (
    comparators: Comparator[]
): Conditions =>
    comparators.map(comparator => ({
        __typename: 'BusinessObjectComparator',
        ...comparator,
        valueSelector: mapValueSelectorToConditions(comparator.valueSelector),
    }))

const mapValueSelectorToConditions = (
    selector: Comparator['valueSelector']
): ConditionSelector => {
    return match(selector)
        .with(
            { type: ValueSelectorType.FieldValue },
            selector =>
                ({
                    __typename: 'FieldValueSelector' as const,
                    type: ValueSelectorType.FieldValue,
                    fieldId: selector.fieldId,
                } as ConditionFieldValueSelector)
        )
        .with(
            { type: ValueSelectorType.Deep },
            selector =>
                ({
                    __typename: 'DeepSelector' as const,
                    type: ValueSelectorType.Deep,
                    selectors: selector.selectors.map(
                        mapValueSelectorToConditions
                    ),
                } as ConditionDeepSelector)
        )
        .with(
            { type: ValueSelectorType.Constant },
            selector =>
                ({
                    __typename: 'ConstantSelector' as const,
                    type: ValueSelectorType.Constant,
                    value: selector.value,
                } as ConditionConstantSelector)
        )
        .exhaustive()
}
