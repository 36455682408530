import { flex, styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { regular4 } from 'Components/Text'
import { FC } from 'react'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    text: string
    icon?: IconName
}

export const OverflowModalButton: FC<Props> = ({ text, icon, ...props }) => {
    return (
        <StyledOverflowModalButton {...props}>
            {icon ? <Icon name={icon} /> : null}
            <span>{text}</span>
        </StyledOverflowModalButton>
    )
}

export const StyledOverflowModalButton = styled.button`
    ${regular4};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.75rem 0;
    height: fit-content;
    width: 100%;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    &[data-disabled] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    svg {
        height: 1em;
        width: 1em;
    }

    @media (hover: hover) {
        &:hover {
            box-shadow: none;
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    svg {
        path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }
`
