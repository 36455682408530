import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { SelectField } from 'Features/BusinessObjectFieldset/Fields'
import { BizObjectDef_CompleteFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { useOptionalFields } from 'Hooks/useOptionalFields'
import { fromEvent } from 'Utils'
import { FieldType } from '__generated__'
import { pipe } from 'lodash/fp'
import { FC, useMemo } from 'react'
import { P, match } from 'ts-pattern'
import { useSingleBusinessObjectDefinitionQuery } from '../../__generated__/q'
import { CreateBusinessObjectField } from '../../types'
import { TemplateableField } from '../TemplateableField'
import { UserField } from './User'

type Props = {
    businessObjectDefinitionId: string
    fieldValues: CreateBusinessObjectField[]
    onFieldsChanged: (fields: CreateBusinessObjectField[]) => void
}

type Field = BizObjectDef_CompleteFragment['fields'][number]

const renderField =
    (
        fieldValues: CreateBusinessObjectField[],
        onChange: (field: Field) => (value: unknown) => void
    ) =>
    (field: Field) =>
        match({
            field,
            fieldValue: fieldValues.find(f => f.id === field.id),
        })
            .with(
                P.union(
                    {
                        field: {
                            __typename: 'TextFieldDefinition',
                        },
                    },
                    {
                        field: {
                            __typename: 'EmailFieldDefinition',
                        },
                    },
                    { field: { __typename: 'URLFieldDefinition' } }
                ),
                ({ field, fieldValue }) => (
                    <label key={field.id}>
                        {field.name}
                        <TextInput
                            value={(fieldValue?.value as string) ?? ''}
                            onChange={pipe(fromEvent, onChange(field))}
                        />
                    </label>
                )
            )
            .with(
                { field: { __typename: 'UserFieldDefinition' } },
                ({ field, fieldValue }) => (
                    <label key={field.id}>
                        {field.name}
                        <TemplateableField<string | undefined>
                            value={fieldValue?.value as string}
                            onChange={onChange(field)}
                            native={({ value, onChange }) => (
                                <UserField
                                    name={field.id}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </label>
                )
            )
            .with(
                { field: { __typename: 'DateFieldDefinition' } },
                ({ field, fieldValue }) => (
                    <label key={field.id}>
                        {field.name}
                        <TemplateableField
                            value={fieldValue?.value as string}
                            onChange={onChange(field)}
                            native={({ value, onChange }) => (
                                <Select
                                    id={field.id}
                                    name={field.name}
                                    options={[
                                        {
                                            text: 'Webhook execution date',
                                            value: '{{ meta.now }}',
                                        },
                                    ]}
                                    value={value}
                                    onValueChange={onChange}
                                />
                            )}
                        />
                    </label>
                )
            )
            .with(
                { field: { __typename: 'SelectFieldDefinition' } },
                ({ field, fieldValue }) => (
                    <SelectField
                        key={field.id}
                        field={field}
                        value={fieldValue?.value as string[]}
                        onChange={onChange(field)}
                        errors={[]}
                    />
                )
            )
            .with(
                P.union(
                    {
                        field: {
                            __typename: 'ListFieldDefinition',
                            listOf: {
                                __typename: 'UpdatesFieldDefinition',
                            },
                        },
                    },
                    {
                        field: {
                            __typename: 'ListFieldDefinition',
                            listOf: {
                                __typename: 'DocumentFieldDefinition',
                            },
                        },
                    }
                ),
                () => null
            )
            .otherwise(({ field }) => (
                <label key={field.id}>
                    {field.name}
                    <TextInput
                        disabled
                        value={`No webhook support for ${field.type} fields yet.`}
                    />
                </label>
            ))

export const BusinessObjectFields: FC<Props> = ({
    businessObjectDefinitionId,
    fieldValues,
    onFieldsChanged,
}) => {
    const { data } = useSingleBusinessObjectDefinitionQuery({
        variables: { id: businessObjectDefinitionId },
    })

    const fields = useMemo(
        () => data?.businessObjectDefinition?.fields ?? [],
        [data]
    )

    const {
        requiredFields,
        optionalFields,
        showOptionalFields,
        setShowOptionalFields,
        showToggle,
    } = useOptionalFields({
        fields,
        initiallyShowOptionalFields: false,
    })

    const onChange =
        (field: { id: string; type: FieldType }) => (value: unknown) => {
            const oldIndex = fieldValues.find(f => f.id === field.id)

            const newFields = oldIndex
                ? fieldValues.map(fieldValue =>
                      fieldValue.id === field.id
                          ? { ...fieldValue, value }
                          : fieldValue
                  )
                : [...fieldValues, { id: field.id, value, type: field.type }]

            onFieldsChanged(newFields)
        }

    if (!data?.businessObjectDefinition) return null

    return (
        <StyledFieldset>
            {requiredFields.map(renderField(fieldValues, onChange))}

            {showToggle ? (
                <TextButton
                    variant="ghost"
                    text={
                        showOptionalFields
                            ? 'Hide optional fields'
                            : 'Show optional fields'
                    }
                    onClick={() => setShowOptionalFields(v => !v)}
                    type="button"
                />
            ) : null}

            {showOptionalFields
                ? optionalFields.map(renderField(fieldValues, onChange))
                : null}
        </StyledFieldset>
    )
}

const StyledFieldset = styled.fieldset`
    border: 0;
    padding: 0;
    padding-bottom: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
