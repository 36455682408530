import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { FC } from 'react'
import { useProcessCreator } from '../useProcessCreator'

type Props = {
    index: number
}

const InsertPhase: FC<Props> = ({ index }) => {
    const { dispatch } = useProcessCreator()

    return (
        <Styled>
            <IconButton
                iconName="Plus"
                variant="secondary"
                onClick={() =>
                    dispatch({ type: 'insertPhase', payload: { index } })
                }
            />
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    align-items: center;
`

export { InsertPhase }
