import { styled } from 'Adapters/Freestyled'

export const Styled = styled.section`
    height: 100%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2rem 0 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > header > div {
        flex: 1;
    }

    > header > div:last-child {
        display: flex;
        justify-content: flex-end;
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: auto;
        padding-top: 2rem;
    }

    > div > ul {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 1rem;
        padding-bottom: 2rem;
    }
`

export const StyledListItem = styled.li`
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-radius: 0.25rem;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export const StyledMemberListItem = styled(StyledListItem)`
    h5 {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    &[data-current='true'] {
        background: linear-gradient(
            to right,
            ${({ theme }) => theme.palette.support.bg03.normal},
            ${({ theme }) => theme.palette.support.bg03.normal}80
        );
    }
`

export const StyledInviteListItem = styled(StyledListItem)`
    h5 {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export const StyledInviteAvatar = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    svg {
        height: 1rem;
        width: 1rem;
    }

    path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }
`

export const StyledNoResults = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
`

export const StyledMemberDetails = styled.div`
    flex-grow: 1;
`
