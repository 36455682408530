import {
    EditorAction,
    State as EditorState,
} from 'Features/BusinessObjectDefinitionEditor'

export enum Step {
    GetStarted = 'GetStarted',
    Details = 'Details',
    AI = 'AI',
    Template = 'Template',
    Existing = 'Existing',
}

export enum Status {
    Idle = 'Idle',
    Saving = 'Saving',
    Success = 'Success',
    Error = 'Error',
}

// If I were being extremely rigerous I would model the state as a union
// representing only holistically valid states, but for now I'm just going to
// model the state as a single object and rely on the handle functions to
// enforce the invariants.

export type State = {
    open: boolean
    step: Step
    templates: Pick<
        EditorState,
        'definition' | 'enableUpdates' | 'enableDocuments'
    >[]
    definitions: Pick<
        EditorState,
        'definition' | 'enableUpdates' | 'enableDocuments'
    >[]
    suggestionPrompt: string
    loadingSuggestion: boolean
    status: Status
} & EditorState

export type OpenCreator = {
    type: 'openCreator'
    payload: undefined
}

export type CloseCreator = {
    type: 'closeCreator'
    payload: undefined
}

export type Restart = {
    type: 'restart'
    payload: undefined
}

export type SelectTemplate = {
    type: 'selectTemplate'
    payload: { template: State['templates'][number] }
}

export type SelectExisting = {
    type: 'selectExisting'
    payload: { definition: State['definitions'][number] }
}

export type ChangePrompt = {
    type: 'changePrompt'
    payload: { suggestionPrompt: string }
}

export type ToggleLoadingSuggestion = {
    type: 'toggleLoadingSuggestion'
    payload: undefined
}

export type TakeSuggestion = {
    type: 'takeSuggestion'
    payload: {
        suggestion: Pick<
            State,
            'definition' | 'enableUpdates' | 'enableDocuments'
        >
    }
}

export type StartFromScratch = {
    type: 'startFromScratch'
    payload: undefined
}

export type SetStatus = {
    type: 'setStatus'
    payload: { status: Status }
}

export type ChoosePath = {
    type: 'choosePath'
    payload: { step: Step.AI | Step.Template | Step.Existing }
}

export type CreatorAction =
    | EditorAction
    | OpenCreator
    | CloseCreator
    | Restart
    | SelectTemplate
    | SelectExisting
    | ChangePrompt
    | ToggleLoadingSuggestion
    | TakeSuggestion
    | StartFromScratch
    | SetStatus
    | ChoosePath

export type DispatchAction = React.Dispatch<CreatorAction>

export type Handle<T extends CreatorAction> = (
    prev: State
) => (action: T) => State
