import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import { PrincipalFragmentDoc } from '../../../../Features/Principal/__generated__/Principal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDocumentMutationVariables = Types.Exact<{
  input: Types.CreateFileInput;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createFile: { __typename?: 'File', id: string, fileType: string, url: string, name: string, uploadedAt: string, canDelete: boolean, sizeInBytes: number, uploadedBy: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } } } };

export type DeleteDocumentMutationVariables = Types.Exact<{
  input: Types.DeleteFileInput;
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteFile: boolean };


export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: CreateFileInput!) {
  createFile(input: $input) {
    id
    fileType
    url
    name
    uploadedAt
    uploadedBy {
      ...Principal
    }
    canDelete
    sizeInBytes
  }
}
    ${PrincipalFragmentDoc}`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($input: DeleteFileInput!) {
  deleteFile(input: $input)
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;