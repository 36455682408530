import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { styled } from 'Adapters/Freestyled'
import * as React from 'react'

export const Separator = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
    (
        { className, orientation = 'vertical', decorative = true, ...props },
        ref
    ) => (
        <StyledSeparator
            ref={ref}
            decorative={decorative}
            orientation={orientation}
            className={className}
            {...props}
        />
    )
)

const StyledSeparator = styled(SeparatorPrimitive.Root)`
    background-color: ${({ theme }) => theme.palette.ui['04'].normal};

    &[data-orientation='horizontal'] {
        height: 1px;
        width: 100%;
        margin: 0.25rem 0;
    }

    &[data-orientation='vertical'] {
        height: 100%;
        width: 1px;
        margin: 0 0.25rem;
    }
`
