import { logger } from 'Adapters/Logger'
import { Checkbox } from 'Components/Checkbox'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { ChangeSubscriptionAction } from '__generated__'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { useToggleActionSubscriptionMutation } from './__generated__/mutation'
import { Action_And_ExtensionQuery } from './__generated__/query'

type Props = {
    action: Action_And_ExtensionQuery['action']
}

export const ToggleActionSubscription: FC<Props> = ({ action }) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const [toggleSubscription] = useToggleActionSubscriptionMutation({
        onError: error => {
            logger.error(
                'Failed to toggle business object subscription',
                error,
                { actionId: action.id }
            )
            triggerToast()
        },
    })

    return (
        <>
            <Checkbox
                htmlFor="subscribed"
                label="Subscribe to notifications"
                name={'subscribed'}
                checked={action.subscribed}
                onCheckedChange={checked => {
                    toggleSubscription({
                        variables: {
                            input: {
                                actionId: action.id,
                                action: match(checked)
                                    .with(
                                        true,
                                        () => ChangeSubscriptionAction.Add
                                    )
                                    .with(
                                        false,
                                        () => ChangeSubscriptionAction.Remove
                                    )
                                    .exhaustive(),
                            },
                        },
                        update: cache => {
                            cache.modify({
                                id: cache.identify({
                                    __typename: action.__typename,
                                    id: action.id,
                                }),
                                fields: {
                                    subscribed: () => checked,
                                },
                            })
                        },
                        optimisticResponse: {
                            changeActionSubscription: checked,
                        },
                    })
                }}
            />

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description={
                    'We were unable to toggle the subscription. Contact us if the problem persists.'
                }
            />
        </>
    )
}
