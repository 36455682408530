import * as Accordion from '@radix-ui/react-accordion'
import { Icon } from 'Components/Icon'
import { Pip } from 'Components/Pip'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ShowBy } from '../../../Hooks'
import { showByQueryKey } from '../../../Hooks/constants'
import { getShowBy } from '../../../Hooks/helpers'
import { StyledInputGroup, StyledItem, StyledTrigger } from './styles'

export const ShowByFilterControls = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const showBy = getShowBy(searchParams)

    const updateShowBy = useCallback(
        (value: ShowBy | '') => {
            setSearchParams(prev => {
                const prevAsObject = convertSearchParamsToObject(prev)

                if (!value) {
                    const { [showByQueryKey]: _, ...rest } = prevAsObject
                    return rest
                }

                return { ...prevAsObject, [showByQueryKey]: value }
            })
        },
        [setSearchParams]
    )

    return (
        <StyledItem value={'showBy'} key={'showBy'}>
            <StyledTrigger>
                <Text as="span" variant="bold-5">
                    Complete / Incomplete
                </Text>

                {showBy.length ? <Pip /> : null}

                <Icon name="AngleDown" className="chevron-icon" />
            </StyledTrigger>

            <Accordion.Content>
                <StyledInputGroup>
                    <Text as="label" variant="bold-6" htmlFor="show-by-select">
                        Show by:
                    </Text>

                    <Select
                        id="show-by-select"
                        onValueChange={updateShowBy}
                        value={showBy[0] ?? ''}
                        name="show-by-select"
                        options={[
                            {
                                text: 'Complete',
                                value: 'CompletedAction' as const,
                                disabled: false,
                            },
                            {
                                text: 'Incomplete',
                                value: 'IncompleteAction' as const,
                                disabled: false,
                            },
                            {
                                text: 'All',
                                value: '' as const,
                                disabled: false,
                            },
                        ]}
                    />
                </StyledInputGroup>
            </Accordion.Content>
        </StyledItem>
    )
}
