import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebhookPortalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WebhookPortalQuery = { __typename?: 'Query', webhookPortal: { __typename?: 'OutgoingWebhookPortal', url: string } };


export const WebhookPortalDocument = gql`
    query WebhookPortal {
  webhookPortal {
    url
  }
}
    `;

/**
 * __useWebhookPortalQuery__
 *
 * To run a query within a React component, call `useWebhookPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookPortalQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebhookPortalQuery(baseOptions?: Apollo.QueryHookOptions<WebhookPortalQuery, WebhookPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebhookPortalQuery, WebhookPortalQueryVariables>(WebhookPortalDocument, options);
      }
export function useWebhookPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebhookPortalQuery, WebhookPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebhookPortalQuery, WebhookPortalQueryVariables>(WebhookPortalDocument, options);
        }
export type WebhookPortalQueryHookResult = ReturnType<typeof useWebhookPortalQuery>;
export type WebhookPortalLazyQueryHookResult = ReturnType<typeof useWebhookPortalLazyQuery>;
export type WebhookPortalQueryResult = Apollo.QueryResult<WebhookPortalQuery, WebhookPortalQueryVariables>;