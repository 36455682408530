import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { ContactUsToast } from 'Components/Toast'
import { FC } from 'react'
import { useDelete } from '../useDelete'

type Props = {
    id: string
    canDelete: boolean
    onDelete: () => void
}

const DeleteBusinessObject: FC<Props> = ({ id, canDelete, onDelete }) => {
    const { deleteBusinessObject, toastOpen, setToastOpen } = useDelete({
        id,
        onDelete,
    })

    return (
        <>
            <MenuNavButton
                title="Delete"
                description={
                    canDelete
                        ? 'This object will be permanently deleted'
                        : 'This object cannot be deleted.'
                }
                icon="Delete"
                onClick={deleteBusinessObject}
                disabled={!canDelete}
            />
            <ContactUsToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'We were unable to delete the record. Contact us if the problem persists.'
                }
            />
        </>
    )
}

export { DeleteBusinessObject }
