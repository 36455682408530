import {
    getHandler,
    PlateElement,
    PlateElementProps,
    Value,
} from '@udecode/plate-common'
import { TMentionElement } from '@udecode/plate-mention'
import { focusOutline, styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import React from 'react'
import { useFocused, useSelected } from 'slate-react'

export interface MentionInputElementProps
    extends PlateElementProps<Value, TMentionElement> {
    onClick?: (mentionNode: any) => void
}

export const MentionInput = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    MentionInputElementProps
>(({ className, onClick, ...props }, ref) => {
    const { children, element } = props

    const selected = useSelected()
    const focused = useFocused()

    return (
        <StyledMentionInput
            $selected={selected && focused}
            asChild
            ref={ref}
            data-slate-value={element.value}
            className={className}
            onClick={getHandler(onClick, element)}
            {...props}
        >
            <span>{children}</span>
        </StyledMentionInput>
    )
})

const StyledMentionInput = styled(PlateElement)<{ $selected: boolean }>`
    ${regular5};
    display: inline-block;
    vertical-align: baseline;
    padding: 0.125rem 0.375rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};

    ${({ $selected }) => $selected && focusOutline()};
`
