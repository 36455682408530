import { byTypename } from 'Utils'
import { keyBy } from 'lodash'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { BusinessObjectFieldNamesFragment } from './__generated__/BusinessObjectFieldNames'

type FieldValueSelector = {
    __typename: 'FieldValueSelector'
    fieldId: string
}

type ConstantSelector = {
    __typename: 'ConstantSelector'
    value: string
}

type DeepSelector = {
    __typename: 'DeepSelector'
    selectors: Array<FieldValueSelector | ConstantSelector>
}

type Props = {
    fieldMap: Record<string, { name: string }>
    selector: FieldValueSelector | DeepSelector | ConstantSelector
    suffix?: string
}

export const SelectorName: FC<Props> = ({ fieldMap, selector, suffix }) => {
    return (
        <>
            {match(selector)
                .with({ __typename: 'FieldValueSelector' }, selector => {
                    return (
                        (fieldMap[selector.fieldId]?.name ??
                            'Field name not found') + (suffix ?? '')
                    )
                })
                .with({ __typename: 'DeepSelector' }, selector => {
                    return selector.selectors.map((sel, i) => (
                        <SelectorName
                            key={i}
                            selector={sel}
                            fieldMap={fieldMap}
                            suffix={
                                i === selector.selectors.length - 1 ? '' : ' → '
                            }
                        />
                    ))
                })
                .otherwise(() => 'Selector not supported')}
        </>
    )
}

export const useSelectorFieldMap = (
    businessObjectDefinition:
        | BusinessObjectFieldNamesFragment
        | null
        | undefined
) => {
    return useMemo(
        () => ({
            ...keyBy(businessObjectDefinition?.fields, field => field.id),
            ...keyBy(
                businessObjectDefinition?.fields
                    .filter(byTypename('RelationFieldDefinition' as const))
                    .flatMap(field =>
                        field.constraints
                            .filter(
                                byTypename('RelationTypeConstraint' as const)
                            )
                            .flatMap(constraint =>
                                constraint.types.flatMap(type => type.fields)
                            )
                    ),
                field => field.id
            ),
        }),
        [businessObjectDefinition]
    )
}
