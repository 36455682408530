import { Toggle } from 'Components/Input'
import { FC } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'

export const BooleanField: FC<FieldInputProps<'BooleanFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    return (
        <Field
            id={field.id}
            name={field.name}
            description={field.description ?? ''}
            errors={errors}
        >
            <Toggle
                name={field.id}
                id={field.id}
                checked={value ?? field.booleanDefaultValue}
                onCheckedChange={onChange}
            />
        </Field>
    )
}
