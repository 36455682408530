export type DateConstraintsMap = {
    required: boolean
    startAfter: string | undefined
    endBefore: string | undefined
}

export const defaultConstraints = {
    required: false,
    startAfter: undefined,
    endBefore: undefined,
} satisfies DateConstraintsMap
