export const strings = {
    // Days
    mondayLabel: 'Mo',
    tuesdayLabel: 'Tu',
    wednesdayLabel: 'We',
    thursdayLabel: 'Th',
    fridayLabel: 'Fr',
    saturdayLabel: 'Sa',
    sundayLabel: 'Su',

    // Months
    janLabel: 'Jan',
    febLabel: 'Feb',
    marLabel: 'Mar',
    aprLabel: 'Apr',
    mayLabel: 'May',
    junLabel: 'Jun',
    julLabel: 'Jul',
    augLabel: 'Aug',
    sepLabel: 'Sep',
    octLabel: 'Oct',
    novLabel: 'Nov',
    decLabel: 'Dec',
}
