import { useBusinessObjectsByIdQuery } from './__generated__/query'

export const useFetchBusinessObjectsById = (
    businessObjectDefinitionId: string
) => {
    const { data } = useBusinessObjectsByIdQuery({
        variables: { input: { businessObjectDefinitionId } },
        fetchPolicy: 'cache-and-network',
    })

    return {
        businessObjects: data?.businessObjects ?? [],
        hasInitiallyLoaded: data !== undefined,
    }
}
