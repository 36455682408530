import { useTriggerToast } from 'Components/Toast'
import { waitForDelay } from 'Utils'
import { useCallback, useMemo, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { useModelManager_PatchBusinessObjectDefinitionMutation } from './__generated__/q'
import {
    handleAddField,
    handleClose,
    handleCloseAddField,
    handleCloseFieldEditor,
    handleMoveField,
    handleOpen,
    handleOpenAddField,
    handleOpenFieldEditor,
    handleRemoveField,
    handleSetDescription,
    handleSetField,
    handleSetLabel,
    handleSetName,
    handleSetStatus,
    handleToggleCustomLabel,
    handleToggleEnableDocuments,
    handleToggleEnableUpdates,
} from './handle'
import { makeMutationVariables } from './makeMutationVariables'
import { Action, Data, State, Status } from './types'
import { makeInit } from './utils'

const makeReducer =
    (data: Data) =>
    (state: State, action: Action): State => {
        const next = match(action)
            .with({ type: 'open' }, handleOpen(data)(state))
            .with({ type: 'close' }, handleClose(data)(state))
            .with({ type: 'toggleCustomLabel' }, handleToggleCustomLabel(state))
            .with({ type: 'setName' }, handleSetName(state))
            .with({ type: 'setDescription' }, handleSetDescription(state))
            .with({ type: 'setLabel' }, handleSetLabel(state))
            .with({ type: 'openFieldEditor' }, handleOpenFieldEditor(state))
            .with({ type: 'closeFieldEditor' }, handleCloseFieldEditor(state))
            .with({ type: 'openAddField' }, handleOpenAddField(state))
            .with({ type: 'closeAddField' }, handleCloseAddField(state))
            .with({ type: 'addField' }, handleAddField(state))
            .with({ type: 'removeField' }, handleRemoveField(state))
            .with({ type: 'moveField' }, handleMoveField(state))
            .with({ type: 'setField' }, handleSetField(state))
            .with(
                { type: 'toggleEnableUpdates' },
                handleToggleEnableUpdates(state)
            )
            .with(
                { type: 'toggleEnableDocuments' },
                handleToggleEnableDocuments(state)
            )
            .with({ type: 'setStatus' }, handleSetStatus(state))
            .exhaustive()
        return next
    }

const useEditor = (data: Data) => {
    const reducer = useMemo(() => makeReducer(data), [data])
    const init = useMemo(() => makeInit(data), [data])
    const [state, dispatch] = useReducer(reducer, init)
    const [patch] = useModelManager_PatchBusinessObjectDefinitionMutation()
    const {
        open: errorOpen,
        setOpen: setErrorOpen,
        triggerToast,
    } = useTriggerToast()
    const navigate = useNavigate()

    const updateDefinition = useCallback(async () => {
        dispatch({ type: 'setStatus', payload: { status: Status.Saving } })
        match(
            makeMutationVariables({
                definition: data.businessObjectDefinition,
                state,
            })
        )
            .with({ input: { operations: [] } }, () => {
                dispatch({ type: 'close', payload: undefined })
            })
            .otherwise(variables => {
                patch({
                    variables,
                    onCompleted: data => {
                        dispatch({
                            type: 'setStatus',
                            payload: { status: Status.Success },
                        })
                        dispatch({ type: 'close', payload: undefined })
                        navigate(
                            `/manage-workspace/datasets/${slugify(
                                data.patchBusinessObjectDefinition.name,
                                {
                                    lower: true,
                                }
                            )}`
                        )
                    },
                    onError: async () => {
                        dispatch({
                            type: 'setStatus',
                            payload: { status: Status.Error },
                        })
                        triggerToast()
                        await waitForDelay(2000)
                        dispatch({
                            type: 'setStatus',
                            payload: { status: Status.Idle },
                        })
                    },
                })
            })
    }, [state, triggerToast, patch, dispatch, data, navigate])

    return { state, dispatch, updateDefinition, errorOpen, setErrorOpen }
}

export { useEditor }
