import { css, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Dropdown } from 'Components/Dropdown'
import { GroupingControl } from 'Components/GroupingControl'
import { Tooltip } from 'Components/Tooltip'
import { BizObjectDefExtension_CompleteFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { useGroupingMenuState } from './useGroupingMenuState'

type Props = {
    fields: BizObjectDefExtension_CompleteFragment['fields']
    allAssignablePrincipals: { id: string; name: string }[]
}

export const Grouping: FC<Props> = ({ fields, allAssignablePrincipals }) => {
    const [open, setOpen] = useState(false)

    const {
        groupState,
        moveGroupDown,
        moveGroupUp,
        setGroupColor,
        setSelection,
        setToSearchParams,
        groupingBy,
    } = useGroupingMenuState(fields, allAssignablePrincipals)

    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            renderInSpan
            renderOpenDropdownButton={() => (
                <Tooltip content={groupingBy} disabled={!groupingBy}>
                    <StyledGroupingMenuButton
                        $hasGrouping={!!groupingBy}
                        icon="DotCircle"
                        text="Group"
                        size="xSmall"
                    />
                </Tooltip>
            )}
            renderContent={() => (
                <StyledGroupingControl
                    grouping={groupState}
                    onFieldSelect={setSelection}
                    onMoveGroupUp={moveGroupUp}
                    onMoveGroupDown={moveGroupDown}
                    onSelectGroupColor={setGroupColor}
                    onClose={() => {
                        setOpen(false)
                        setToSearchParams()
                    }}
                />
            )}
            align="start"
        />
    )
}

const StyledGroupingControl = styled(GroupingControl)`
    width: 27rem;
`

const pipStyles = css`
    position: relative;

    &:after {
        content: '';
        height: 5px;
        width: 5px;
        background-color: ${({ theme }) => theme.palette.support['01'].normal};
        border-radius: 50%;
        top: 0.2rem;
        right: 0.2rem;
        position: absolute;
    }
`

const StyledGroupingMenuButton = styled(IconTextButton)<{
    $hasGrouping: boolean
}>`
    ${({ $hasGrouping }) => $hasGrouping && pipStyles};
`
