import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CreateActionModal } from 'Features/CreateActionModal'
import { useModalControls } from 'Hooks'
import { QueryActionsRelatesTo } from '__generated__'
import { FC } from 'react'

type Props = {
    relatesTo?: Pick<QueryActionsRelatesTo, 'businessObjectId'>
}

export const CreateActionMenu: FC<Props> = ({ relatesTo }) => {
    const { open, openModal, closeModal, setOpen, buttonRef, returnFocus } =
        useModalControls()

    return (
        <StyledMenu>
            <IconTextButton
                icon="Plus"
                text="Create action"
                onClick={openModal}
                ref={buttonRef}
                variant="primary"
            />

            <CreateActionModal
                open={open}
                closeModal={closeModal}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
                relatesTo={relatesTo ?? undefined}
            />
        </StyledMenu>
    )
}

const StyledMenu = styled.menu`
    ${flex('row', 'flex-end', 'center')};
    width: 100%;
    padding: 1rem 2rem;
`
