import { Portal, Root, Trigger } from '@radix-ui/react-dialog'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { getPortalsContainer } from 'Utils'
import { CloseButton, ModalDescription, ModalTitle } from './Components'
import { StyledContent, StyledOverlay } from './styles'
import { UncontrolledModalProps } from './types'

export const UncontrolledModal: FC<UncontrolledModalProps> = ({
    renderOpenModalButton,
    renderContent,
    title,
    description,
    hideDescription = false,
}) => {
    const { isLarge } = useResponder()

    return (
        <Root modal>
            <Trigger asChild>{renderOpenModalButton()}</Trigger>

            <Portal container={getPortalsContainer()}>
                <StyledOverlay />

                <StyledContent $location={isLarge ? 'center' : 'bottom'}>
                    <ModalTitle title={title} />
                    <CloseButton />

                    <ModalDescription
                        description={description}
                        hideDescription={hideDescription}
                    />

                    {renderContent()}
                </StyledContent>
            </Portal>
        </Root>
    )
}
