import { logger } from '../Logger'

const dev = import.meta.env.DEV
const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN ?? ''
if (!auth0Domain)
    throw new Error(
        dev
            ? `VITE_AUTH0_DOMAIN not set. Make sure you are using 'netlify dev' to run the development environment'`
            : `auth0Domain not set`
    )

const authClientId = import.meta.env.VITE_AUTH0_CLIENT_ID ?? ''
if (!authClientId)
    throw new Error(
        dev
            ? `VITE_AUTH0_CLIENT_ID not set. Make sure you are using 'netlify dev' to run the development environment'`
            : `authClientId not set`
    )

const authLogoutUrl = import.meta.env.VITE_AUTH0_LOGOUT_URL
if (!authLogoutUrl) {
    logger.info('authLogoutUrl not set, logout will use auth0 default')
}

export { auth0Domain, authClientId, authLogoutUrl }
