export const strings = {
    supHeading: `Next step:`,
    heading: `You've been invited to %`,
    nameLabel: 'Your full name',
    btnTextNormal: 'Accept invite',
    btnTextWaiting: 'Accepting invite',
    btnTextSuccess: 'Invite acccepted',
    errorMessage: 'An error occurred. Please try again.',
    skipText: `I'd rather create my own workspace`,
}
