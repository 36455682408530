import { styled } from 'Adapters/Freestyled'
import { bold4 } from 'Components/Text'
import { CreateBusinessObjectForm } from 'Features/CreateBusinessObjectModal/CreateBusinessObjectForm'
import { CreateFormStateProvider } from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { BODFields } from 'Features/CreateBusinessObjectModal/types'
import { FC } from 'react'
import { ErroringInput } from '../types'

type SlideProps = {
    isOpen: boolean
    erroringInput: ErroringInput
    currentSlide: number
    incrementSlide: () => void
    isFinalSlide: boolean
    finalSlide: number
    businessObjectDefinitionFields: BODFields
    businessObjectDefinitionId: string
    closeModal: () => void
}

export const Slide: FC<SlideProps> = ({
    isOpen,
    erroringInput,
    currentSlide,
    incrementSlide,
    isFinalSlide,
    finalSlide,
    businessObjectDefinitionFields,
    businessObjectDefinitionId,
    closeModal,
}) => {
    if (!isOpen) return null

    return (
        <CreateFormStateProvider
            fields={businessObjectDefinitionFields}
            initialValues={erroringInput.erroringInput.fields}
        >
            <StyledSlideProgressIndicator>
                {currentSlide} of {finalSlide}
            </StyledSlideProgressIndicator>

            <CreateBusinessObjectForm
                fields={businessObjectDefinitionFields}
                businessObjectDefinitionId={businessObjectDefinitionId}
                onBusinessObjectCreated={
                    !isFinalSlide ? incrementSlide : closeModal
                }
                initiallyShowOptionalFields={true}
            />
        </CreateFormStateProvider>
    )
}

const StyledSlideProgressIndicator = styled.h3`
    width: 100%;
    text-align: center;
    ${bold4};
    padding-bottom: 0.5rem;
`
