import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { GetBusinessObjectViewsQuery } from 'Features/FilterBusinessObject/FilterBar/__generated__/query'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { ManageSingleView } from './ManageSingleView'

type Props = {
    views: NonNullable<GetBusinessObjectViewsQuery['views']>
}

const ManageViews: FC<Props> = ({ views }) => {
    const { open, openModal, setOpen } = useModalControls()

    return (
        <>
            <IconTextButton
                text={'Manage views'}
                onClick={openModal}
                icon="Settings"
                disabled={!views.length}
                size="xSmall"
                variant="secondary"
            />

            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled>
                    {views.length > 0 ? (
                        views.map(view => (
                            <ManageSingleView key={view.id} view={view} />
                        ))
                    ) : (
                        <Text as="p" variant="regular-5">
                            You have no saved views.
                        </Text>
                    )}
                    <footer>
                        <IconTextButton
                            icon="Check"
                            text="Done"
                            size="xSmall"
                            variant="primary"
                            onClick={() => setOpen(false)}
                        />
                    </footer>
                </Styled>
            </MinimalModal>
        </>
    )
}

const Styled = styled.div`
    padding: 0.5rem 4vw 1rem;

    > p {
        text-align: center;
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    > footer {
        padding-top: 1rem;
        display: flex;
        gap: 1rem;
    }

    > footer button {
        flex: 1;
    }
`

export { ManageViews }
