import { styled } from 'Adapters/Freestyled'
import { ProgressSummary } from 'Features/SingleBusinessObjectTabs/Widgets'
import { RecentUpdates } from 'Features/SingleBusinessObjectTabs/Widgets/RecentUpdates/RecentUpdates'
import { FC } from 'react'
import { BusinessObjectInProcessOverviewQuery } from '../__generated__/query'
import { useCurrentPhaseProgress } from '../useCurrentPhaseProgress'
import { OverviewFooter } from './Footer'

type Props = {
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    process: BusinessObjectInProcessOverviewQuery['process']
    phaseIndex: number
    refetch: () => void
    onRemoveFromProcess: () => void
}
const Small: FC<Props> = ({
    businessObject,
    onRemoveFromProcess,
    phaseIndex,
    process,
    refetch,
}) => {
    const { percentageComplete, actions, loading } = useCurrentPhaseProgress({
        businessObjectId: businessObject.id,
        process,
        phaseIndex,
    })

    return (
        <Styled>
            <ProgressSummary
                process={process}
                businessObjectPhaseIndex={phaseIndex}
                businessObject={businessObject}
                onProgressChange={refetch}
                percentageComplete={percentageComplete}
                percentageCompleteLoading={loading}
                actions={actions}
            />
            <RecentUpdates businessObjectId={businessObject.id} />
            <OverviewFooter
                businessObjectId={businessObject.id}
                processId={process.id}
                onRemoveFromProcess={onRemoveFromProcess}
                isTerminalPhase={phaseIndex === process.phases.length - 1}
                percentageComplete={percentageComplete}
            />
        </Styled>
    )
}

const Styled = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
`

export { Small }
