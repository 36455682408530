import { forwardRef } from 'react'
import TextareaAutosize, {
    TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { StyledTextAreaInput } from './styles'

type Props = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'className'> &
    TextareaAutosizeProps & {
        hasError?: boolean
    }

export const TextAreaInput = forwardRef<HTMLTextAreaElement, Props>(
    ({ hasError = false, style, className, ...props }, ref) => {
        return (
            <StyledTextAreaInput
                $hasError={hasError}
                className={`${'textarea-input'} ${className}`}
            >
                <TextareaAutosize {...props} ref={ref} />
            </StyledTextAreaInput>
        )
    }
)
