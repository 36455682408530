import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Catalogue } from './Catalogue'
import { Single } from './Single'

const Processes: FC = () => (
    <Routes>
        <Route index element={<Catalogue />} />
        <Route path=":processId/:id?/*" element={<Single />} />
    </Routes>
)

export { Processes }
