import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { customModelledFields, fieldLabels } from 'Utils'
import { FieldType } from '__generated__'
import { FC } from 'react'

export type Props = {
    onClick: (type: FieldType) => () => void
}

export const AddFieldButtons: FC<Props> = ({ onClick }) => {
    return (
        <div>
            <Text as="p" variant="regular-4">
                Use the buttons below to add fields to your dataset.
            </Text>
            <StyledAddFieldButtonGroup>
                {Object.entries(fieldLabels)
                    .filter(
                        ([type]) => !customModelledFields.has(type as FieldType)
                    )
                    .map(([type, label]) => (
                        <StyledAddFieldButton
                            key={`add-new-button-${type}`}
                            text={label}
                            variant="secondary"
                            type="button"
                            onClick={onClick(type as FieldType)}
                        />
                    ))}
            </StyledAddFieldButtonGroup>
        </div>
    )
}

const StyledAddFieldButton = styled(TextButton)`
    display: inline-block;
    margin: 0.1rem;
    max-height: 4rem;
`

const StyledAddFieldButtonGroup = styled.section`
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    padding-top: 0.3rem;
`
