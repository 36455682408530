import { useMemo } from 'react'
import { DocumentFieldConstraints } from '__generated__'
import { defaultConstraints, DocumentConstraintsMap } from './types'

type Params = {
    docConstraints: DocumentFieldConstraints[]
}

export const useDocumentConstraints = ({ docConstraints }: Params) => {
    const constraints = useMemo(() => {
        return docConstraints.reduce<DocumentConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'RequiredConstraint') {
                    return { ...out, required: true }
                }

                return out
            },
            defaultConstraints
        )
    }, [docConstraints])

    const constraintsTooltip = useMemo(() => {
        if (!constraints.required) return ''
        return 'Required.'
    }, [constraints])

    return { constraints, constraintsTooltip }
}
