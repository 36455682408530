import { DocumentMetadata } from 'Components/Documents'
import { orderBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Field, Order, SortOptions } from './types'

export const useSort = (documents: DocumentMetadata[]) => {
    const [sort, setSort] = useState<SortOptions>({
        field: 'addedOn',
        order: 'desc',
    })

    const updateSort = useCallback((field: Field) => {
        setSort(currentSort => {
            if (field === currentSort.field) {
                return { field, order: oppositeOrder[currentSort.order] }
            } else {
                return { field, order: 'asc' }
            }
        })
    }, [])

    const sortedDocuments = useMemo(
        () => orderBy(documents, sort.field, sort.order),
        [documents, sort]
    )

    return { sort, updateSort, sortedDocuments }
}

const oppositeOrder: Record<Order, Order> = {
    asc: 'desc',
    desc: 'asc',
}
