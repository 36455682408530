import { styled, useCurrentTheme } from 'Adapters/Freestyled'
import { GridTable } from 'Components/GridTable'
import { Text } from 'Components/Text'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC } from 'react'
import { getDynamicCell } from './getDynamicCell'
import { useCols } from './useCols'

type Props = {
    objs: BusinessObjectTableItemFragment[]
    businessObjectDefinitionId: string
}

const BusinessObjectTable: FC<Props> = ({
    objs,
    businessObjectDefinitionId,
}) => {
    const { theme } = useCurrentTheme()
    const { sorted, columns } = useCols(objs)
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'bo-table-object-display',
    })

    return (
        <Styled>
            {objs.length ? (
                <GridTable
                    columns={columns}
                    className="business-object-table"
                    groups={[
                        {
                            label: '',
                            colorConfig: {
                                headerBackground: 'transparent',
                                label: 'transparent',
                                background: theme.palette.ui['01'].normal,
                                backgroundHover: theme.palette.ui['01'].hover,
                                backgroundActive: theme.palette.ui['01'].active,
                                borderTop: theme.palette.ui['04'].normal,
                                borderRight: theme.palette.ui['03'].normal,
                            },
                            rows: sorted.map(({ id, label, fields }) => ({
                                cells: {
                                    label: {
                                        element: label,
                                    },
                                    ...fields?.reduce(
                                        (acc, field) => ({
                                            ...acc,
                                            [field.fieldDefinition.name]:
                                                getDynamicCell(field, {
                                                    onRelationClick: id =>
                                                        boDisplayProps.open({
                                                            id,
                                                            tab: 'details',
                                                        }),
                                                }),
                                        }),
                                        {}
                                    ),
                                },
                                onClick: () =>
                                    boDisplayProps.open({
                                        id,
                                        tab: 'details',
                                    }),
                            })),
                        },
                    ]}
                />
            ) : (
                <Text variant="regular-4" as="p">
                    <em>
                        No <strong>records</strong> to show for this{' '}
                        <strong>dataset</strong>
                    </em>
                </Text>
            )}
            <BusinessObjectDisplay
                {...boDisplayProps}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        </Styled>
    )
}

const Styled = styled.div`
    .business-object-table {
        padding-right: 3rem;
    }

    > p {
        text-align: center;
    }
`

export { BusinessObjectTable }
