import { styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { ProcessCreator } from 'Features/ProcessCreator'
import { useResponder } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { ProcessDef_SingleProcessQuery } from '../../__generated__/query'
import { processToProcessInput } from './helpers'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
    refetch: () => void
}

export const FreeEditor: FC<Props> = ({ process, refetch }) => {
    const navigate = useNavigate()
    const ref = useRef<HTMLButtonElement>(null)
    const [editorOpen, setEditorOpen] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const { isLarge } = useResponder()

    return (
        <>
            <IconTextButton
                ref={ref}
                icon="Edit"
                text="Edit workflow"
                variant="secondary"
                size="xSmall"
                onClick={() => setEditorOpen(true)}
            />

            <ControlledModal
                open={editorOpen}
                onOpenChange={open =>
                    open ? setEditorOpen(true) : setConfirmationOpen(true)
                }
                title="Edit workflow"
                description=""
                hideDescription
                renderContent={() => (
                    <ProcessCreator
                        onCompleted={({ name }) => {
                            setEditorOpen(false)

                            if (name !== process.name) {
                                navigate(
                                    `/manage-workspace${
                                        isLarge ? '' : '/model'
                                    }/processes/${slugify(name, {
                                        lower: true,
                                    })}`,
                                    { replace: true }
                                )
                            } else {
                                refetch()
                            }
                        }}
                        initialProcess={processToProcessInput(process)}
                        initialIntegrations={process.integrations.map(int => ({
                            ...int,
                            trigger: {
                                ...int.trigger,
                                filter: {
                                    ...int.trigger.filter,
                                    params: JSON.parse(
                                        int.trigger.filter.params
                                    ),
                                },
                            },
                            action: {
                                ...int.action,
                                params: JSON.parse(int.action.params),
                            },
                        }))}
                        processId={process.id}
                        initialStep="phases"
                    />
                )}
                returnFocus={() => ref.current?.focus()}
            />

            <ControlledModal
                open={confirmationOpen}
                onOpenChange={setConfirmationOpen}
                title="Are you sure?"
                description="If you close the editor now, you will lose all your changes."
                renderContent={() => (
                    <StyledConfirmation>
                        <TextButton
                            text="Cancel"
                            variant="secondary"
                            onClick={() => setConfirmationOpen(false)}
                            size="small"
                        />
                        <TextButton
                            text="Confirm"
                            onClick={() => {
                                setEditorOpen(false)
                                setConfirmationOpen(false)
                            }}
                            size="small"
                        />
                    </StyledConfirmation>
                )}
                returnFocus={() => {
                    if (!editorOpen) ref.current?.focus()
                }}
            />
        </>
    )
}

const StyledConfirmation = styled.footer`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-top: 1rem;

    button {
        flex: 1;
    }
`
