import { styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { BizObjDef_Select, BOField } from 'Hooks'
import { isEqual } from 'lodash'
import { FC, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { Checkboxes } from './Checkboxes'
import { Radios } from './Radios'
import { Selects } from './Selects'
import { useSelectConstraints } from './useSelectConstraints'

type Props = BOField<'BusinessObjectSelectField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const SelectField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    onComplete,
    selectValue,
}) => {
    const { selectConstraints, selectOptions, ...common } =
        fieldDefinition as BizObjDef_Select

    const { UIToRender, constraintsTooltip } = useSelectConstraints({
        selectConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValues = useRef(selectValue)

    const [selectedValues, setSelectedValues] = useState(initialValues.current)

    return (
        <>
            <Field
                id={common.id}
                name={common.name}
                constraintsTooltip={constraintsTooltip}
                errors={errors}
            >
                {match(UIToRender)
                    .with('checkbox', () => (
                        <Checkboxes
                            options={selectOptions}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                        />
                    ))
                    .with('radio', () => (
                        <Radios
                            options={selectOptions}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                        />
                    ))
                    .with('select', () => (
                        <Selects
                            fieldId={common.id}
                            fieldName={common.name}
                            options={selectOptions}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                        />
                    ))
                    .exhaustive()}

                <EditFieldButton
                    disabled={
                        isEqual(initialValues.current, selectedValues) ||
                        loading
                    }
                    onClick={() => {
                        updateBusinessObjectField({
                            field: {
                                [common.type]: {
                                    fieldDefinitionId: common.id,
                                    previousValue: initialValues.current,
                                    value: selectedValues,
                                },
                            },
                            id: businessObjectId,
                            onSuccess: () => {
                                initialValues.current = selectedValues
                                onComplete()
                            },
                        })
                    }}
                />
            </Field>

            {selectedValues.length ? (
                <StyledSelectedValues>
                    {selectedValues.map((value, i) => (
                        <em key={value}>
                            <strong>
                                {selectOptions.find(
                                    option => option.id === value
                                )?.value ?? ''}
                                {i < selectedValues.length - 1 ? ', ' : ''}
                            </strong>
                        </em>
                    ))}{' '}
                    selected.
                </StyledSelectedValues>
            ) : null}
        </>
    )
}

const StyledSelectedValues = styled.p`
    ${regular5};
    margin-top: -1rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    text-align: center;
`
