import { FC } from 'react'
import { match } from 'ts-pattern'
import { BODField } from '../../../types'
import {
    DateListField,
    DocumentListField,
    EmailListField,
    RelationListField,
    TextListField,
    UrlListField,
    UserListField,
} from './Fields'

export const ListField: FC<BODField<'ListFieldDefinition'>> = ({
    listOf,
    prefilledBusinessObject,
    ...common
}) => {
    return (
        <>
            {match(listOf)
                .with({ __typename: 'DocumentFieldDefinition' }, props => (
                    <DocumentListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))
                .with({ __typename: 'UserFieldDefinition' }, props => (
                    <UserListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))
                .with({ __typename: 'URLFieldDefinition' }, props => (
                    <UrlListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))
                .with({ __typename: 'TextFieldDefinition' }, props => (
                    <TextListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))
                .with({ __typename: 'RelationFieldDefinition' }, props => (
                    <RelationListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        prefilledBusinessObject={prefilledBusinessObject}
                        {...props}
                    />
                ))
                .with({ __typename: 'EmailFieldDefinition' }, props => (
                    <EmailListField
                        key={props.id}
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))
                .with({ __typename: 'DateFieldDefinition' }, props => (
                    <DateListField
                        listName={common.name}
                        listId={common.id}
                        {...props}
                    />
                ))

                // TODO : Implement lists of the following field types
                .with({ __typename: 'TelephoneFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'CurrencyFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'NumberFieldDefinition' }, () => (
                    <Unimplemented />
                ))

                // Maybe TODO : Are lists of these even useful?
                .with({ __typename: 'BooleanFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'SelectFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'ListFieldDefinition' }, () => (
                    <Unimplemented />
                )) // No lists of lists
                .with({ __typename: 'UpdatesFieldDefinition' }, () => null) // no updates on create
                .exhaustive()}
        </>
    )
}

const Unimplemented = () => (
    <p>
        <em>Unimplemented : Contact us if this is an issue</em>
    </p>
)
