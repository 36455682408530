import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { regular6 } from 'Components/Text'
import { AnimatePresence } from 'framer-motion'
import { uniqBy } from 'lodash'
import { ReactEventHandler, useState } from 'react'
import { FieldCommonWrapper } from './Common'
import { NumberOfSelectionsConstraint } from './Constraints/NumberOfSelections'
import { Field } from './Field'
import { StyledListItem } from './styles'
import { FieldProps } from './types'

export const SelectFieldDefinitionInput: React.FC<FieldProps<'select'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
}) => {
    const [pendingOption, setPendingOption] = useState('')
    const handleAddPending: ReactEventHandler = e => {
        e.preventDefault()

        if (pendingOption.trim().length === 0) return

        onChange('options')(prev =>
            uniqBy([...prev, { value: pendingOption }], 'value')
        )
        setPendingOption('')
    }

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-select-option-${index}`} name="Options">
                <TextInput
                    id={`bod-field-select-option-${index}`}
                    value={pendingOption}
                    onChange={e => setPendingOption(e.target.value)}
                    placeholder='Press "Enter" to add option'
                    onKeyDownCapture={e => {
                        if (e.key === 'Enter') {
                            handleAddPending(e)
                        }
                    }}
                />
                <StyledIconButton
                    iconName="ArrowDown"
                    onClick={handleAddPending}
                />
            </Field>
            {field.options.map(({ value }) => (
                <StyledListItem key={value}>
                    <span>{value}</span>
                    <IconButton
                        iconName="Close"
                        title="Remove"
                        onClick={e => {
                            e.preventDefault()
                            onChange('options')(prev =>
                                prev.filter(option => value !== option.value)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
            <AnimatePresence>
                {field.options.length ? (
                    <NumberOfSelectionsConstraint
                        index={index}
                        value={field.constraints.find(
                            c => c.numberOfSelections
                        )}
                        maxCeiling={field.options.length}
                        onChange={constraint =>
                            onChange('constraints')(prev =>
                                constraint
                                    ? [
                                          ...prev.filter(
                                              c => !c.numberOfSelections
                                          ),
                                          constraint,
                                      ]
                                    : prev.filter(c => !c.numberOfSelections)
                            )
                        }
                    />
                ) : (
                    <StyledOptionsMessage>
                        Add some options above and you will be able to set
                        constraints on the select field.
                    </StyledOptionsMessage>
                )}
            </AnimatePresence>
        </FieldCommonWrapper>
    )
}

const StyledIconButton = styled(IconButton)`
    min-height: 2.594rem;
    margin-left: 0.5rem;
`

const StyledOptionsMessage = styled.p`
    ${regular6};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    text-align: center;
    width: 100%;
`
