import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Dropdown } from 'Components/Dropdown'
import { GroupingControl } from 'Components/GroupingControl'
import { FC, useState } from 'react'
import { DispatchAction, ViewConfig } from '../../types'

type Props = {
    config: ViewConfig
    dispatch: DispatchAction
}

const Grouping: FC<Props> = ({ config, dispatch }) => {
    const [open, setOpen] = useState(false)
    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <IconTextButton icon="DotCircle" text="Group" size="xSmall" />
            )}
            renderContent={() => (
                <Styled
                    grouping={config.grouping}
                    onFieldSelect={selection => {
                        dispatch({
                            type: 'SetGroupingSelection',
                            payload: { selection },
                        })
                    }}
                    onMoveGroupUp={fieldId => groupId => {
                        dispatch({
                            type: 'MoveGroupUp',
                            payload: {
                                fieldId,
                                groupId,
                            },
                        })
                    }}
                    onMoveGroupDown={fieldId => groupId => {
                        dispatch({
                            type: 'MoveGroupDown',
                            payload: {
                                fieldId,
                                groupId,
                            },
                        })
                    }}
                    onSelectGroupColor={fieldId => groupId => color => {
                        dispatch({
                            type: 'SetGroupColor',
                            payload: {
                                fieldId,
                                groupId,
                                color,
                            },
                        })
                    }}
                    onClose={() => setOpen(false)}
                />
            )}
            align="start"
        />
    )
}

const Styled = styled(GroupingControl)`
    width: 27rem;
`

export { Grouping }
