import { styled } from 'Adapters/Freestyled'
import { CardSettingsControl } from 'Components/CardSettingsControl'
import { MinimalModal } from 'Components/Modal/Minimal'
import { FC, useState } from 'react'
import { DispatchAction, ViewConfig } from '../../types'
import { MenuButton } from './MenuButton'

type Props = {
    config: ViewConfig
    dispatch: DispatchAction
}

const CardSettings: FC<Props> = ({ config: { cardSettings }, dispatch }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <MenuButton
                icon="Cards"
                title={'Card settings'}
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled
                    fields={cardSettings.fields}
                    selected={cardSettings.selected}
                    onSelect={fieldId =>
                        dispatch({
                            type: 'ToggleFieldDisplay',
                            payload: { fieldId },
                        })
                    }
                    onDone={() => setOpen(false)}
                />
            </MinimalModal>
        </>
    )
}

const Styled = styled(CardSettingsControl)`
    padding: 0.25rem 4vw 1rem;
`

export { CardSettings }
