import { useMemo } from 'react'
import { FieldType } from '__generated__'
import { byTypename } from '../../Utils'
import { useRequireableFieldsQuery } from './__generated__/query'

const disallowedFieldTypes = new Set([FieldType.List])

export const useRequireableFields = ({
    businessObjectDefinitionId,
}: {
    businessObjectDefinitionId: string
}) => {
    const { data, loading } = useRequireableFieldsQuery({
        variables: { input: { id: businessObjectDefinitionId } },
    })

    const availableFields = useMemo(
        () =>
            data?.businessObjectDefinition?.fields.filter(
                field => !disallowedFieldTypes.has(field.type)
            ),
        [data]
    )

    const availableDeepFields = useMemo(
        () =>
            data?.businessObjectDefinition?.fields
                .filter(byTypename('RelationFieldDefinition' as const))
                .map(field => ({
                    parentField: field,
                    fields: field.constraints
                        .filter(byTypename('RelationTypeConstraint' as const))
                        .flatMap(constraint =>
                            constraint.types
                                .flatMap(type => type.fields)
                                .filter(
                                    subfield =>
                                        !disallowedFieldTypes.has(subfield.type)
                                )
                                .map(subfield => ({
                                    id: `${field.id}___${subfield.id}`,
                                    name: subfield.name,
                                }))
                        ),
                }))
                .filter(({ fields }) => fields.length > 0),
        [data]
    )

    return {
        loading,
        availableFields,
        availableDeepFields,
    }
}
