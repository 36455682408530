import { useLocalStorage } from 'Hooks/useLocalStorageState'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import { encodeURIComponentObj } from 'Utils'
import { ProcessDisplayFragment } from '../__generated__/q'
import { getConfigSearchParam, useClearedConfig } from '../utils'

type UseLatestView = (_: {
    uniqueKey: string
    urlSearchParams: URLSearchParams
    setUrlSearchParams: ReturnType<typeof useSearchParams>[1]
    process: ProcessDisplayFragment
}) => void
const useLatestView: UseLatestView = ({
    uniqueKey,
    urlSearchParams,
    setUrlSearchParams,
    process,
}) => {
    const [latestView, setLatestView] = useLocalStorage<string | undefined>(
        `processDisplayLatestView:${uniqueKey}:${process.id}`,
        undefined
    )

    const initialConfig = useClearedConfig(process)

    useEffect(() => {
        match(getConfigSearchParam(urlSearchParams))
            .with(P.string, config => {
                setLatestView(config)
            })
            .otherwise(() => {
                setUrlSearchParams(
                    prev => {
                        const next = new URLSearchParams(prev)
                        const config = match(latestView)
                            .with(P.string, x => x)
                            .otherwise(
                                () => encodeURIComponentObj(initialConfig) || ''
                            )
                        next.set('config', config)
                        return next
                    },
                    { replace: true }
                )
            })
    }, [
        urlSearchParams,
        process,
        setUrlSearchParams,
        latestView,
        setLatestView,
        initialConfig,
    ])
}

export { useLatestView }
