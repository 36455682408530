import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { pipe } from 'lodash/fp'
import { FC, useState } from 'react'
import { fromEvent, preventDefault } from 'Utils'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

type SelectFieldProps = {
    onSelectOptionAdded: (option: string) => () => void
}

const SelectField: FC<
    Field<'SelectFieldDefinition'> & AbstractFieldChangeProps & SelectFieldProps
> = ({
    selectConstraints,
    selectDefaultValue,
    selectOptions,
    onSelectOptionAdded,
    ...common
}) => {
    const [newOption, setNewOption] = useState('')
    const handleOptionChanged = pipe(fromEvent, setNewOption)
    const handleOptionAdded = pipe(
        preventDefault,
        () => setNewOption(''),
        onSelectOptionAdded(newOption)
    )

    return (
        <AbstractField
            {...common}
            constraints={selectConstraints}
            customRows={[
                {
                    label: 'Options',
                    element: (
                        <Options>
                            {selectOptions.map(option => {
                                const isDefault = (
                                    selectDefaultValue?.selectedIds ?? []
                                ).includes(option.id)
                                return (
                                    <li key={option.id}>
                                        <p>
                                            <Text as="span" variant="regular-4">
                                                {option.value}
                                            </Text>
                                            {isDefault && (
                                                <Text
                                                    as="span"
                                                    variant="bold-4"
                                                >
                                                    [default]
                                                </Text>
                                            )}
                                        </p>
                                    </li>
                                )
                            })}{' '}
                            {common.isEditing && (
                                <>
                                    <TextInput
                                        id="new-option-input"
                                        value={newOption}
                                        onChange={handleOptionChanged}
                                    />
                                    <TextButton
                                        onClick={handleOptionAdded}
                                        variant="secondary"
                                        text="Add option"
                                    />
                                </>
                            )}
                        </Options>
                    ),
                },
            ]}
        />
    )
}

const Options = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: disc;
    padding-left: 1.5rem;
    color: ${({ theme }) => theme.palette.text['03'].normal};

    span {
        display: inline-block;
        padding-right: 1ch;
    }
`

export { SelectField }
