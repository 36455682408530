import { ControlledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { useResponder } from 'Hooks'
import { FC, useCallback, useMemo } from 'react'
import { ValueSelectorType } from '../../__generated__'
import { Content } from './Content'
import { Comparator } from './types'

type Props = {
    businessObjectDefinitionId: string
    initialComparators?: Comparator[]
    onConfirm: (comparators: Comparator[]) => void
    title: string
    description?: string
    open: boolean
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    onClearAll?: () => void
    allowedFilterTypes?: ValueSelectorType[]
}

export const ComparatorBuilderV2: FC<Props> = ({
    businessObjectDefinitionId,
    initialComparators,
    onConfirm,
    title,
    description,
    open,
    onOpenChange,
    returnFocus,
    onClearAll,
    allowedFilterTypes = [
        ValueSelectorType.Constant,
        ValueSelectorType.FieldValue,
        ValueSelectorType.Deep,
    ],
}) => {
    const handleConfirm = useCallback(
        (comparators: Comparator[]) => {
            onOpenChange(false)
            onConfirm(comparators)
        },
        [onOpenChange, onConfirm]
    )

    const parsedInitialComparators = useMemo(
        () =>
            initialComparators?.map(comparator => ({
                ...comparator,
                with: JSON.parse(comparator.with),
            })) ?? [],
        [initialComparators]
    )

    const { isLarge } = useResponder()

    if (isLarge)
        return (
            <ControlledModal
                renderContent={() => (
                    <Content
                        onCancel={() => onOpenChange(false)}
                        onConfirm={handleConfirm}
                        initialComparators={parsedInitialComparators}
                        businessObjectDefinitionId={businessObjectDefinitionId}
                        onClearAll={onClearAll}
                        allowedFilterTypes={allowedFilterTypes}
                    />
                )}
                title={title}
                description={description ?? ''}
                open={open}
                onOpenChange={onOpenChange}
                returnFocus={returnFocus}
            />
        )

    return (
        <MinimalModal
            open={open}
            onOpenChange={onOpenChange}
            closeOnOverlayClick={false}
        >
            <VerticalScrollArea height="100%" width="100%">
                <Content
                    onCancel={() => onOpenChange(false)}
                    onConfirm={handleConfirm}
                    initialComparators={parsedInitialComparators}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                    isSmall
                    onClearAll={onClearAll}
                    allowedFilterTypes={allowedFilterTypes}
                />
            </VerticalScrollArea>
        </MinimalModal>
    )
}
