import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import durationPlugin from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(durationPlugin)
dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(weekday)
dayjs.updateLocale('en', {
    weekStart: 1,
})

const isDurationIsoString = (isoString: string): boolean => {
    const isStringMatch =
        isoString.match(
            /^P(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/
        ) !== null

    const isValid = dayjs.isDuration(dayjs.duration(isoString))

    return isStringMatch && isValid
}

const isIsoString = (isoString: string): boolean => dayjs(isoString).isValid()

export { dayjs, isDurationIsoString, isIsoString }
