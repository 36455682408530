import { useMemo } from 'react'
import { NumberFieldConstraints } from '__generated__'
import { defaultConstraints, NumberConstraintsMap } from './types'

type Params = {
    numConstraints: NumberFieldConstraints[]
}

export const useNumberConstraints = ({ numConstraints }: Params) => {
    const constraints = useMemo(() => {
        return numConstraints.reduce<NumberConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'RequiredConstraint') {
                    return { ...out, required: true }
                }

                if (constraint.__typename === 'RangeConstraint') {
                    return {
                        ...out,
                        max: constraint.max ?? undefined,
                        min: constraint.min ?? undefined,
                    }
                }
                return out
            },
            defaultConstraints
        )
    }, [numConstraints])

    const constraintsTooltip = useMemo(() => {
        const strings = []

        if (constraints.required) strings.push('Required.')
        if (constraints.min && !constraints.max)
            strings.push(`Minimum of ${constraints.min}.`)
        if (constraints.max && !constraints.min)
            strings.push(`Maximum of ${constraints.max}.`)
        if (constraints.max && constraints.min)
            strings.push(
                `Must be between ${constraints.min} and ${constraints.max}.`
            )

        return strings.join(' ')
    }, [constraints])

    return { constraints, constraintsTooltip }
}
