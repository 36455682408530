import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { match } from 'ts-pattern'
import { NoBusinessObjectDefinitions } from '../../NoBusinessObjectDefinitions'
import { Route as BusinessObjectRoute } from '../../types'
import { Definition } from './Definition'
import { BusinessObjectDirectory } from './Directory'

type Props = {
    routes: BusinessObjectRoute[]
}

export const Mobile: FC<Props> = ({ routes }) => {
    return (
        <Routes>
            <Route
                path="/"
                element={<BusinessObjectDirectory routes={routes} />}
            />

            {match(routes)
                .with([], () => (
                    <Route path="/" element={<NoBusinessObjectDefinitions />} />
                ))
                .otherwise(() => (
                    <>
                        {routes.map(route => (
                            <Route
                                key={route.id}
                                path={route.slug}
                                element={
                                    <Definition
                                        name={route.name}
                                        businessObjectDefinitionId={route.id}
                                        description={route.description}
                                    />
                                }
                            />
                        ))}
                    </>
                ))}

            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    )
}
