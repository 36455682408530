import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { ComponentProps, FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Styled } from './styles'

export const NavLinkList: FC<{
    items: {
        icon: ComponentProps<typeof Icon>['name']
        heading: string
        description: string
        to: string
        end?: boolean
    }[]
}> = ({ items }) => (
    <Styled>
        {items.map(({ icon, heading, description, to, end }) => (
            <Tooltip key={to} content={description} disabled={!description}>
                <NavLink end={end} to={to}>
                    <Icon name={icon} className="type-icon" />

                    <div className="text">
                        <Text as="p" variant="bold-4">
                            {heading}
                        </Text>
                        <Text as="p" variant="regular-5">
                            {description}
                        </Text>
                    </div>

                    <Icon name="CarrotRight" className="chevron-icon" />
                </NavLink>
            </Tooltip>
        ))}
    </Styled>
)
