import { CenteredSpinner } from 'Components/Spinner'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { BusinessObjectsDisplay } from './BusinessObjectsDisplay'
import { useFetchBusinessObjectRoutes } from './Hooks'

export const BusinessObjects: FC = () => {
    const fetchBusinessObjectRoutesResult = useFetchBusinessObjectRoutes()

    return match(fetchBusinessObjectRoutesResult)
        .with({ hasInitiallyLoaded: false }, () => <CenteredSpinner />)
        .with({ hasInitiallyLoaded: true }, ({ routes }) => (
            <BusinessObjectsDisplay routes={routes} />
        ))
        .exhaustive()
}
