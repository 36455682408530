import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton, DropdownDivider } from 'Components/Dropdown'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useRequireableFields } from 'Hooks/useRequireableFields'
import { FC, useRef } from 'react'
import { match, P } from 'ts-pattern'
import { v4 } from 'uuid'
import { CreateFieldConditionCriterionInput } from '__generated__'
import { DeepFieldSelector } from '../../../../Components/DeepFieldSelector'
import { useProcessCreator } from '../../useProcessCreator'
import { Conditions } from './Conditions'

type Props = {
    phaseIndex: number
    criterionIndex: number
    criterion: {
        fieldCondition: CreateFieldConditionCriterionInput
    }
}

export const FieldConditionCriterion: FC<Props> = ({
    phaseIndex,
    criterionIndex,
    criterion,
}) => {
    const {
        state: { input },
        dispatch,
    } = useProcessCreator()

    const { current: inputId } = useRef(v4())

    const { loading, availableFields, availableDeepFields } =
        useRequireableFields({
            businessObjectDefinitionId: input.operatesUpon[0],
        })

    return (
        <Styled>
            <header>
                <IconTextButton
                    icon="CheckboxTicked"
                    text="Required Field"
                    disabled
                    size="xSmall"
                    variant="ghost"
                />
                <Dropdown
                    renderOpenDropdownButton={() => (
                        <IconButton iconName="More" />
                    )}
                    renderContent={() => (
                        <>
                            <DropdownButton
                                icon="ArrowUp"
                                text="Move criterion up"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionUp',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={criterionIndex === 0}
                            />
                            <DropdownButton
                                icon="ArrowDown"
                                text="Move criterion down"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionDown',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={
                                    criterionIndex ===
                                    input.phases[phaseIndex].criteria.length - 1
                                }
                            />
                            <DropdownDivider />
                            <DropdownButton
                                icon="Delete"
                                text="Remove criterion"
                                onSelect={() => {
                                    dispatch({
                                        type: 'removeCriterion',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                            />
                        </>
                    )}
                />
            </header>

            <div>
                <VisuallyHidden>
                    <label htmlFor={inputId}>Required field</label>
                </VisuallyHidden>
                {match({ loading, availableFields, availableDeepFields })
                    .with(
                        {
                            availableFields: P.not(P.nullish),
                            availableDeepFields: P.not(P.nullish),
                        },
                        ({ availableFields, availableDeepFields }) => (
                            <DeepFieldSelector
                                id={inputId}
                                onFieldChanged={fieldId =>
                                    dispatch({
                                        type: 'setFieldConditionCriterionFieldId',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                            fieldId,
                                        },
                                    })
                                }
                                fields={availableFields}
                                deepFields={availableDeepFields}
                                fieldId={
                                    criterion.fieldCondition.comparator
                                        .valueSelector.fieldValue?.fieldId ??
                                    criterion.fieldCondition.comparator.valueSelector.deep?.selectors
                                        .map(s => s.fieldValue?.fieldId)
                                        .join('___')
                                }
                            />
                        )
                    )
                    .with({ loading: true }, () => <CenteredSpinner />)
                    .otherwise(() => (
                        <Text as="p" variant="regular-4">
                            An error occurred fetching available fields
                        </Text>
                    ))}
            </div>

            <Conditions
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.5rem 0.125rem 0.125rem;
    }

    > div {
        padding: 0 0.5rem 0.5rem;
    }
`
