import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { regular5 } from 'Components/Text'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { uniq } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useURLConstraints } from './useURLConstraints'

type Props = BODField<'URLFieldDefinition'> & {
    listName: string
    listId: string
}

export const UrlListField: FC<Props> = ({
    urlConstraints,
    urlDefaultValue,
    type,
    listName,
    listId,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useURLConstraints({
        urlConstraints,
    })

    const [currentValue, setCurrentValue] = useState(urlDefaultValue ?? '')

    const [values, setValues] = useState(
        urlDefaultValue ? [urlDefaultValue] : []
    )

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: values.map(url => ({ url })),
            },
        })
    }, [values, dispatch, listId])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[listId].errors ?? []}
            list={values.map((url, i) => (
                <StyledListItem key={url + i}>
                    <span>{url}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev => prev.filter(link => link !== url))
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <TextInput
                name={type + '||' + listId}
                value={currentValue}
                onChange={e => setCurrentValue(e.target.value)}
                id={listId}
                placeholder="Type a link and press enter"
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        setValues(prev => uniq([...prev, currentValue]))
                        setCurrentValue('')
                    }
                }}
            />
        </ListFieldWrapper>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button {
        margin-left: auto;
    }
`
