import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FieldType } from '__generated__'
import { FC } from 'react'
import { DraggableCard } from '../../../../Card'
import { useDropOnGroup } from '../../../../dnd'
import { GroupedBusinessObjects } from '../types'

type Props = {
    id: string
    fieldType: FieldType
    businessObjectGroup: GroupedBusinessObjects[string]
    openDrawer: (id: string) => void
}

export const Group: FC<Props> = ({
    id,
    openDrawer,
    businessObjectGroup: { color, items, value, fieldType },
}) => {
    const { isDraggedOver, ref } = useDropOnGroup(id, fieldType)

    return (
        <StyledGroup
            key={id}
            $color={color}
            $isDraggedOver={isDraggedOver}
            ref={ref}
        >
            <Text as={'h3'} variant={'bold-5'}>
                {value}
            </Text>

            <div className="cards">
                {items.map(businessObject => (
                    <DraggableCard
                        businessObject={businessObject}
                        key={businessObject.id}
                        openDrawer={openDrawer}
                        groupId={id}
                    />
                ))}
            </div>
        </StyledGroup>
    )
}

const StyledGroup = styled.div<{ $color: string; $isDraggedOver: boolean }>`
    width: 100%;
    min-height: 5rem;
    padding: 1rem;
    background-color: ${({ $color, $isDraggedOver }) =>
        `${$color}${$isDraggedOver ? '33' : '11'}`};
    border-radius: 0.25rem;

    > h3 {
        padding-bottom: 0.75rem;
        color: ${({ $color }) => $color};
        filter: brightness(50%);
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-auto-rows: auto;
        gap: 1rem;
    }
`
