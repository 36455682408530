import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Drawer } from 'Components/Drawer/Drawer'
import { SingleDocument } from 'Features/SingleDocument'
import { FC, useRef, useState } from 'react'

type Props = {
    id: string
    name: string
}

const DocumentDisplay: FC<Props> = ({ id, name }) => {
    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)
    return (
        <Styled>
            <IconTextButton
                ref={ref}
                icon="Document"
                text={name}
                onClick={() => setOpen(true)}
            />
            <Drawer
                open={open}
                onOpenChange={setOpen}
                content={<SingleDocument id={id} />}
                returnFocus={() => ref.current?.focus()}
            />
        </Styled>
    )
}

const Styled = styled.div`
    flex: 1;
    > button {
        width: 100%;
    }
`

export { DocumentDisplay }
