import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Dropdown, DropdownButton, DropdownDivider } from 'Components/Dropdown'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { ControlledModal } from '../../../../Components/Modal'
import { useModalControls } from '../../../../Hooks'
import { TriggerEvent } from '../../../../__generated__'
import {
    CreateIntegrationState,
    IntegrationEditor,
} from '../../../IntegrationEditor'
import { useProcessCreator } from '../../useProcessCreator'

type Props = {
    index: number
}

export const PhaseHeader: FC<Props> = ({ index }) => {
    const {
        state: { input, integrations },
        dispatch,
    } = useProcessCreator()

    const integrationModal = useModalControls()

    const existingIntegrationIndex = integrations.findIndex(
        integration =>
            integration.trigger.event === TriggerEvent.ProcessBoEnteredPhase &&
            integration.trigger.filter.params.phaseIndex === index
    )

    const existingIntegration = integrations[existingIntegrationIndex]

    const handleIntegrationAdded = (integration: CreateIntegrationState) => {
        if (existingIntegrationIndex < 0) {
            dispatch({
                type: 'integrationAdded',
                payload: { integration },
            })
        } else {
            dispatch({
                type: 'integrationUpdated',
                payload: {
                    integration,
                    integrationIndex: existingIntegrationIndex,
                },
            })
        }
        integrationModal.setOpen(false)
    }

    return (
        <>
            <Styled>
                <Text as="h4" variant="bold-6">{`Phase ${index + 1}`}</Text>
                <StyledPhaseOptions>
                    <IconButton
                        iconName="Integrations"
                        variant={existingIntegration ? 'primary' : 'default'}
                        onClick={integrationModal.openModal}
                    />
                    <Dropdown
                        renderOpenDropdownButton={() => (
                            <IconButton iconName="More" />
                        )}
                        renderContent={() => (
                            <>
                                <DropdownButton
                                    icon="ArrowLeft"
                                    text="Move phase left"
                                    onSelect={() => {
                                        dispatch({
                                            type: 'movePhaseLeft',
                                            payload: { index },
                                        })
                                    }}
                                    disabled={index === 0}
                                />
                                <DropdownButton
                                    icon="ArrowRight"
                                    text="Move phase right"
                                    onSelect={() => {
                                        dispatch({
                                            type: 'movePhaseRight',
                                            payload: { index },
                                        })
                                    }}
                                    disabled={index === input.phases.length - 1}
                                />
                                <DropdownDivider />
                                <DropdownButton
                                    icon="Delete"
                                    text="Remove phase"
                                    onSelect={() => {
                                        dispatch({
                                            type: 'removePhase',
                                            payload: { index },
                                        })
                                    }}
                                />
                            </>
                        )}
                    />
                </StyledPhaseOptions>
            </Styled>
            <ControlledModal
                {...integrationModal}
                onOpenChange={integrationModal.setOpen}
                title="Phase integration"
                description=""
                hideDescription
                renderContent={() => (
                    <IntegrationEditor
                        initialValues={
                            existingIntegration ?? {
                                trigger: {
                                    filter: { params: { phaseIndex: index } },
                                    event: TriggerEvent.ProcessBoEnteredPhase,
                                },
                            }
                        }
                        lockTrigger
                        onSubmit={handleIntegrationAdded}
                        additionalActions={
                            existingIntegration
                                ? [
                                      {
                                          onClick: () => {
                                              dispatch({
                                                  type: 'integrationRemoved',
                                                  payload: {
                                                      integrationIndex:
                                                          existingIntegrationIndex,
                                                  },
                                              })
                                              integrationModal.setOpen(false)
                                          },
                                          text: 'Remove integration',
                                      },
                                  ]
                                : []
                        }
                    />
                )}
            />
        </>
    )
}

const Styled = styled.header`
    border-bottom: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.25rem 0.25rem 1rem;

    h4 {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const StyledPhaseOptions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`
