import { isUndefined } from 'lodash'
import { useMemo } from 'react'
import { useSingleProcess_BusinessObjectsInProcessQuery } from './__generated__/q'

export const useAddableBusinessObjects = ({
    processId,
    businessObjectDefinitionId,
    includeRemoved,
}: {
    processId: string
    businessObjectDefinitionId: string
    includeRemoved: boolean
}) => {
    const { data, refetch } = useSingleProcess_BusinessObjectsInProcessQuery({
        variables: {
            processInput: { id: processId },
            businessObjectInput: {
                businessObjectDefinitionId,
                addableToProcess: {
                    processId,
                    includeRemoved,
                },
            },
        },
        fetchPolicy: 'cache-and-network',
    })

    const businessObjectsToDisplay = useMemo(() => {
        const businessObjects = data?.businessObjects ?? []
        const businessObjectsInProcess = new Set(
            data?.process.phases.flatMap(phase =>
                phase.businessObjects.nodes.map(bo => bo.id)
            ) ?? []
        )

        return businessObjects.filter(
            businessObject => !businessObjectsInProcess.has(businessObject.id)
        )
    }, [data])

    return {
        hasInitiallyLoaded: !isUndefined(data),
        refetch,
        businessObjectsToDisplay,
    }
}
