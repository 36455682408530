import { css, flex } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'

export const baseButtonStyles = css`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    ${regular5};
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    height: fit-content;
    width: 100%;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    border-radius: ${({ theme }) => theme.borderRadius.small};

    &[data-disabled] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    svg {
        height: 1em;
        width: 1em;
    }

    @media (hover: hover) {
        &:hover {
            box-shadow: none;
        }
    }
`
