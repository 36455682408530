import { ComponentProps, FC } from 'react'
import { P, match } from 'ts-pattern'
import { ViewConfig } from '../../types'
import { Loaded } from './Loaded'
import { Loading } from './Loading'
import { useProcessSingle_CardQuery } from './__generated__/q'

type Props = {
    id: string
    processId: string
    cardSettings: ViewConfig['cardSettings']
} & Pick<ComponentProps<typeof Loaded>, 'cardInteractions'>

const Card: FC<Props> = ({ id, processId, cardSettings, cardInteractions }) => {
    const { data } = useProcessSingle_CardQuery({
        context: { useBatching: true },
        variables: {
            boInput: { id },
            onProcessInput: { processId },
            hasBusinessObjectCompletedInput: { businessObjectId: id },
        },
    })

    return match(data)
        .with(P.nullish, () => <Loading />)
        .otherwise(({ businessObject }) => (
            <Loaded
                obj={businessObject}
                processId={processId}
                cardSettings={cardSettings}
                cardInteractions={cardInteractions}
            />
        ))
}

export { Card }
