import { ActionPhase } from '__generated__'

export const showByOptions = ['CompletedAction', 'IncompleteAction'] as const

export type ShowBy = (typeof showByOptions)[number]

export const isShowBy = (string: string): string is ShowBy =>
    string === 'CompletedAction' || string === 'IncompleteAction'

export const showByToPhase = {
    CompletedAction: ActionPhase.Closed,
    IncompleteAction: ActionPhase.Open,
} satisfies Record<ShowBy, ActionPhase>
