import { PlateContent, useEditorState, Value } from '@udecode/plate-common'
import { styled } from 'Adapters/Freestyled'
import { Attachments } from 'Components/Attachments'
import { regular4 } from 'Components/Text'
import { useOrganisationUsers } from 'Hooks'
import { FC, useCallback, useMemo } from 'react'
import { MentionCombobox } from './Components/MentionCombobox'
import { Toolbar } from './Components/Toolbar'
import { Attachment } from './types'

type Props = {
    readonly: boolean
    attachments: Attachment[]
    disabled: boolean
    onAttachmentAdded?: (attachments: Attachment[]) => void
    handleSubmit?: (newUpdateValue: Value) => void
    className?: string
    initialValue?: Value
}

export const Editor: FC<Props> = ({
    attachments,
    handleSubmit,
    onAttachmentAdded,
    readonly,
    disabled,
    className,
    initialValue,
}) => {
    const editor = useEditorState()

    const { users } = useOrganisationUsers()

    const onSubmit = useCallback(() => {
        handleSubmit?.(editor.children)
        editor.reset()
    }, [handleSubmit, editor])

    const sendDisabled = useMemo(() => {
        if (disabled) return true
        if (attachments.length) return false
        if (editor.children.length > 1) return false
        if (editor.children[0]?.children.length > 1) return false
        if (editor.children[0]?.children[0].text) return false
        return true
    }, [disabled, editor.children, attachments])

    // This is a hack to fix a state leakage issue in Plate
    // We should be able to provide an id to the provider to create unique instances
    // but for some reason it doesn't work as expected, so we set the initial value
    // here instead to avoid the state leakage
    if (readonly && initialValue) {
        editor.children = initialValue
    }

    return (
        <StyledEditor $readonly={!!readonly} className={className}>
            <PlateContent
                placeholder={readonly ? undefined : 'Leave a new update'}
            />

            <MentionCombobox
                items={users.map(user => ({
                    key: user.id,
                    userId: user.id,
                    text: user.name,
                }))}
            />

            {!readonly ? (
                <footer>
                    <Toolbar
                        attachments={attachments}
                        onAttachment={onAttachmentAdded}
                        onSubmit={!!handleSubmit ? onSubmit : undefined}
                        sendDisabled={sendDisabled}
                    />

                    {onAttachmentAdded && (
                        <Attachments attachments={attachments} />
                    )}
                </footer>
            ) : null}
        </StyledEditor>
    )
}

export const StyledEditor = styled.div<{ $readonly: boolean }>`
    width: 100%;
    border: ${({ theme, $readonly }) =>
        $readonly ? 'none' : `1px solid ${theme.palette.ui['03'].normal}`};

    > [role='textbox'] {
        ${regular4};
        padding: 1rem;

        [data-slate-placeholder='true'] {
            color: ${({ theme }) => theme.palette.text['02'].normal};
            top: initial !important; // overrides an inline style within slate CSS
        }
    }

    *::selection {
        background-color: ${({ theme }) => theme.palette.support.bg03.normal};
    }
`
