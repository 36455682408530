import { Content, Item, Root, Trigger } from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Spinner } from 'Components/Spinner'
import { Text, regular4 } from 'Components/Text'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProcessOverview } from './ProcessOverview'
import { useProcessesByBusinessObjectIdQuery } from './__generated__/ProcessesByBusinessObjectId'

type Props = {
    businessObjectId: string
    businessObjectDefinitionId: string
    className?: string
}

export const Processes: FC<Props> = ({
    businessObjectDefinitionId,
    businessObjectId,
    className,
}) => {
    const { data } = useProcessesByBusinessObjectIdQuery({
        variables: { input: { businessObjectDefinitionId } },
        onError(error) {
            logger.error(
                'Failed to fetch processes by business object id',
                error,
                { businessObjectDefinitionId }
            )
        },
        fetchPolicy: 'cache-and-network',
    })

    const navigate = useNavigate()

    if (!data) return <Spinner />

    if (!data.processes.length)
        return (
            <StyledNoProcessesMessage>
                This record is not currently in use by any workflows
            </StyledNoProcessesMessage>
        )

    return (
        <StyledProcesses className={className}>
            <Root
                type="single"
                collapsible
                defaultValue={data.processes[0]?.id ?? undefined}
            >
                {data.processes.map(process => {
                    return (
                        <StyledItem value={process.id} key={process.id}>
                            <StyledTrigger>
                                <Text as="span" variant="bold-5">
                                    {`${process.name}`}
                                </Text>

                                <Icon name="AngleDown" />
                            </StyledTrigger>

                            <StyledContent>
                                <IconTextButton
                                    icon="ArrowRight"
                                    text="View Workflow"
                                    onClick={() => {
                                        navigate(`/processes/${process.id}`, {
                                            replace: true,
                                        })
                                    }}
                                    size="xSmall"
                                    variant="secondary"
                                />

                                <ProcessOverview
                                    processId={process.id}
                                    businessObjectId={businessObjectId}
                                />
                            </StyledContent>
                        </StyledItem>
                    )
                })}
            </Root>
        </StyledProcesses>
    )
}

const StyledProcesses = styled.div`
    padding: 2rem 0;
    flex: 1;
    overflow: auto;
`

const StyledContent = styled(Content)`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    padding-bottom: 1rem;

    > div {
        width: 100%;
    }
`

const StyledItem = styled(Item)`
    border-top: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
    position: relative;

    &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const StyledTrigger = styled(Trigger)`
    ${flex('row', 'space-between', 'center')};
    width: 100%;
    cursor: pointer;

    > span {
        ${flex('row', 'flex-start', 'center')};
        display: block;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 1.5rem 0;
        text-transform: capitalize;
    }

    > .icon {
        padding-right: 1rem;

        svg {
            transition: transform
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
        }
    }

    &[data-state='open'] > .icon svg {
        transform: rotateZ(180deg);
    }
`

const StyledNoProcessesMessage = styled.p`
    ${regular4};
    width: 100%;
    text-align: center;
    font-style: italic;
    padding: 1rem;
`
