import { css, lightTheme, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { ComponentProps, FC } from 'react'
import { match } from 'ts-pattern'
import { ProcessDisplay, ViewConfig } from '../types'
import { Card } from './Card'

type Props = {
    col: number
    row: number
    processId: string
    group: ProcessDisplay['phases'][number]['groups'][number]
    cardSettings: ViewConfig['cardSettings']
} & Pick<ComponentProps<typeof Card>, 'cardInteractions'>

const PhaseGroupSection: FC<Props> = ({
    col,
    row,
    processId,
    group,
    cardSettings,
    cardInteractions,
}) => {
    return match(group)
        .with({ kind: 'PhaseGroup' }, ({ label, color, businessObjects }) => (
            <Styled
                $col={col}
                $row={row}
                $borderColor={color}
                $backgroundColor={color}
                $headingColor={color}
            >
                <header>
                    <Text as="h6" variant="bold-5">
                        {label}
                    </Text>
                </header>
                <ul>
                    {businessObjects.map(({ id }) => (
                        <li key={id}>
                            <Card
                                id={id}
                                processId={processId}
                                cardSettings={cardSettings}
                                cardInteractions={cardInteractions}
                            />
                        </li>
                    ))}
                </ul>
            </Styled>
        ))
        .with({ kind: 'OthersGroup' }, ({ businessObjects }) => (
            <Styled
                $col={col}
                $row={row}
                $borderColor={lightTheme.palette.ui['05'].normal}
                $backgroundColor={lightTheme.palette.ui['05'].normal}
                $headingColor={lightTheme.palette.ui['05'].normal}
            >
                <header>
                    <Text as="h6" variant="bold-5">
                        Others
                    </Text>
                </header>
                <ul>
                    {businessObjects.map(({ id }) => (
                        <li key={id}>
                            <Card
                                id={id}
                                processId={processId}
                                cardSettings={cardSettings}
                                cardInteractions={cardInteractions}
                            />
                        </li>
                    ))}
                </ul>
            </Styled>
        ))
        .with({ kind: 'AllGroup' }, ({ businessObjects }) => (
            <Styled
                $col={col}
                $row={row}
                $borderColor={lightTheme.palette.ui['04'].normal}
                $backgroundColor={'transparent'}
                $headingColor={'transparent'}
            >
                <ul>
                    {businessObjects.map(({ id }) => (
                        <li key={id}>
                            <Card
                                id={id}
                                processId={processId}
                                cardSettings={cardSettings}
                                cardInteractions={cardInteractions}
                            />
                        </li>
                    ))}
                </ul>
            </Styled>
        ))
        .exhaustive()
}

type StyledProps = {
    $col: number
    $row: number
    $borderColor: string
    $backgroundColor: string
    $headingColor: string
}
const Styled = styled.section<StyledProps>`
    grid-column: ${({ $col }) => `${$col} / ${$col + 1}`};
    grid-row: ${({ $row }) => `${$row} / ${$row + 1}`};
    padding: 0.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ $borderColor, $backgroundColor }) => css`
        border-bottom: 1px solid ${$borderColor}33;
        border-right: 1px dotted ${$borderColor};
        background-color: ${`${$backgroundColor}11`};
    `}

    > header > h6 {
        filter: brightness(50%);
        color: ${({ $headingColor }) => $headingColor};
        opacity: ${({ $col }) => ($col === 1 ? 1 : 0)};
    }

    > ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

export { PhaseGroupSection }
