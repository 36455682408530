import { flex, styled } from 'Adapters/Freestyled'
import { regular4, regular5 } from 'Components/Text'

export const StyledTextInput = styled.div<{ $hasError: boolean }>`
    ${flex('row', 'flex-start', 'center')};
    position: relative;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 0.125rem solid
        ${({ $hasError, theme }) =>
            $hasError ? theme.palette.support['01'].normal : 'transparent'};
    color: ${({ theme }) => theme.palette.text['01'].normal};
    width: 100%;
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    &:focus-within {
        border-color: ${({ theme }) => theme.palette.brand['01'].normal};
    }

    input {
        ${regular5};
        flex: 1;
        padding: 0.625rem 0.875rem;
    }

    input:disabled {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    input::placeholder {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        opacity: 1; /* Firefox uses lower opacity by default */
    }

    input:focus-visible {
        box-shadow: none; // Deferred to parent
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        input {
            ${regular4};
        }
    }
`
