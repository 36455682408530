import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { ProcessCatalogueQuery } from './__generated__/query'

type Props = {
    process: ProcessCatalogueQuery['processes'][number]
}

const Card: FC<Props> = ({ process }) => {
    const activeCount = process.phases.reduce(
        (total, { activeBusinessObjects }) =>
            total + activeBusinessObjects.meta.totalCount,
        0
    )
    const completedCount = process.usage.businessObjects - activeCount

    return (
        <Styled to={`./${process.id}`}>
            <header>
                <Icon name="Cube" />
                <Text as="p" variant="regular-5">
                    {process.operatesUpon[0].name}
                </Text>
            </header>
            <div>
                <Text as="h2" variant="bold-4">
                    {process.name}
                </Text>
                <Text as="p" variant="regular-5">
                    {process.description}
                </Text>
            </div>
            <footer>
                <Text as="p" variant="regular-5">
                    Active: {activeCount}
                </Text>
                <Text as="p" variant="regular-5">
                    Completed: {completedCount}
                </Text>
            </footer>
        </Styled>
    )
}

const Styled = styled(NavLink)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
    height: 100%;
    border-radius: 0.25rem;
    transition: filter
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
        border-color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease;

    &:hover {
        filter: drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}
        );
        border-color: ${({ theme }) => theme.palette.ui['03'].hover};
    }

    > header {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        padding: 0.5rem 0.5rem;
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        color: ${({ theme }) => theme.palette.text['02'].normal};
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &:hover > header {
        background-color: ${({ theme }) => theme.palette.ui['03'].hover};
    }

    > header svg {
        width: 1rem;
        height: 1rem;
    }

    > header path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0.75rem 1rem;
        gap: 0.25rem;
    }

    > div p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > footer {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 0.5rem 1rem 1rem;
        display: flex;
        gap: 1rem;
    }

    > footer > div {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }
`

export { Card }
