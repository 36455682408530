import { dayjs } from 'Adapters/DayJS'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { TextInput } from 'Components/Input'
import { useModalControls } from 'Hooks'
import { fromEvent, preventDefault } from 'Utils'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { TemplateableField } from '../components/TemplateableField'
import { UserField } from '../components/fields'
import { CreateActionPayload } from '../types'
import { StepOutline } from './StepOutline'
import { StepProps } from './types'

export const CreateAction: FC<StepProps<CreateActionPayload>> = ({
    payload: { name, description, dueDate, assignedTo },
    onFieldChanged,
    ...outlineProps
}) => {
    const dateModal = useModalControls()
    return (
        <StepOutline title="Create Action" {...outlineProps}>
            <label>
                Name
                <TextInput
                    type="text"
                    value={name}
                    onChange={pipe(fromEvent, onFieldChanged('name'))}
                />
            </label>
            <label>
                Description
                <TextInput
                    type="text"
                    value={description}
                    onChange={pipe(fromEvent, onFieldChanged('description'))}
                />
            </label>
            <label htmlFor="assignedTo">
                Assignee
                <UserField
                    name="assignedTo"
                    value={assignedTo}
                    onChange={onFieldChanged('assignedTo')}
                />
            </label>
            <label>
                Due Date
                <TemplateableField
                    value={dueDate ?? ''}
                    onChange={onFieldChanged('dueDate')}
                    native={({ onChange }) => (
                        <>
                            <IconTextButton
                                icon="Calendar"
                                text={
                                    !dueDate
                                        ? 'Add a date'
                                        : dayjs(dueDate).format('DD MMM YYYY')
                                }
                                onClick={pipe(
                                    preventDefault,
                                    dateModal.openModal
                                )}
                                ref={dateModal.buttonRef}
                            />
                            <DatePicker
                                {...dateModal}
                                onOpenChange={dateModal.setOpen}
                                title="Due Date"
                                description="Select the due date for this action."
                                hideDescription
                                onConfirm={({ date }) => {
                                    onChange(date.toISOString())
                                    dateModal.closeModal()
                                }}
                            />
                        </>
                    )}
                />
            </label>
        </StepOutline>
    )
}
