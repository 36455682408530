import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { SelectOption } from 'Components/Select/types'
import { FC, useMemo } from 'react'
import { useAvailableCurrencies } from '../../../../Hooks/useAvailableCurrencies/useAvailableCurrencies'
import { fromEvent } from '../../../../Utils'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useCurrencyConstraints } from './useCurrencyConstraints'

type Props = Omit<FieldInputProps<'CurrencyFieldDefinition'>, 'value'> & {
    // Value is incorrectly typed so overwriting -> a resolved currency field is not the same as a currency field input
    value?: {
        currencyCode: string
        amount: number
    }
}

export const CurrencyField: FC<Props> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const currencyOptions = useAvailableCurrencies()
    const options = useMemo(() => {
        return [
            {
                text: `-`,
                value: '',
            },
            ...currencyOptions.map<SelectOption>(option => ({
                text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
                value: option.code,
            })),
        ]
    }, [currencyOptions])

    const { constraints, constraintsTooltip } = useCurrencyConstraints(field)

    return (
        <Field
            id={field.id}
            name={field.name}
            description={field.description ?? ''}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledCurrencyInput>
                <Select
                    id={field.id + 'currencyCode'}
                    value={value?.currencyCode ?? ''}
                    onValueChange={currencyCode => {
                        if (!currencyCode && value?.amount === undefined)
                            return onChange(undefined)
                        onChange({ currencyCode, amount: value?.amount })
                    }}
                    name={'Currency Code'}
                    options={options}
                />
                <NumberInput
                    value={value?.amount ?? ''}
                    id={field.id}
                    hasError={!!errors?.length}
                    onChange={e => {
                        const amount = fromEvent(e)
                        if (amount === '' && !value?.currencyCode)
                            return onChange(undefined)
                        onChange({
                            amount: amount === '' ? undefined : Number(amount),
                            currencyCode: value?.currencyCode,
                        })
                    }}
                    required={constraints.required}
                    className="currency-value-input"
                />
            </StyledCurrencyInput>
        </Field>
    )
}

const StyledCurrencyInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    > div > button {
        margin: 0;
    }

    button {
        margin: 0;
    }

    .currency-value-input {
        flex: 1;
        height: 2.75rem;
    }
`
