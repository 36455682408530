import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    label: string
}

export const FieldLabel: FC<Props> = ({ label }) => (
    <Styled>
        <Text as="span" variant="bold-6">
            {label}
        </Text>
    </Styled>
)

const Styled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: ${({ theme }) => theme.palette.text['03'].normal};
`
