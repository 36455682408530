import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcessSingle_ViewFragment = { __typename?: 'View', id: string, name: string, configuration: string, viewOf: { __typename?: 'ViewOfActions' } | { __typename?: 'ViewOfBusinessObjects', businessObjectDefinition: { __typename?: 'BusinessObjectDefinition', id: string, name: string } } | { __typename?: 'ViewOfProcess', process: { __typename?: 'Process', id: string, name: string } } };

export type ProcessSingle_SaveViewMutationVariables = Types.Exact<{
  input: Types.SaveViewInput;
}>;


export type ProcessSingle_SaveViewMutation = { __typename?: 'Mutation', saveView: { __typename?: 'View', id: string, name: string, configuration: string, viewOf: { __typename?: 'ViewOfActions' } | { __typename?: 'ViewOfBusinessObjects', businessObjectDefinition: { __typename?: 'BusinessObjectDefinition', id: string, name: string } } | { __typename?: 'ViewOfProcess', process: { __typename?: 'Process', id: string, name: string } } } };

export type ProcessSingle_DeleteViewMutationVariables = Types.Exact<{
  input: Types.DeleteViewInput;
}>;


export type ProcessSingle_DeleteViewMutation = { __typename?: 'Mutation', deleteView: string };

export type ProcessSingle_ViewsQueryVariables = Types.Exact<{
  input: Types.ViewsInput;
}>;


export type ProcessSingle_ViewsQuery = { __typename?: 'Query', views: Array<{ __typename?: 'View', id: string, name: string, configuration: string, viewOf: { __typename?: 'ViewOfActions' } | { __typename?: 'ViewOfBusinessObjects', businessObjectDefinition: { __typename?: 'BusinessObjectDefinition', id: string, name: string } } | { __typename?: 'ViewOfProcess', process: { __typename?: 'Process', id: string, name: string } } }> };

export const ProcessSingle_ViewFragmentDoc = gql`
    fragment ProcessSingle_View on View {
  id
  name
  viewOf {
    ... on ViewOfBusinessObjects {
      businessObjectDefinition {
        id
        name
      }
    }
    ... on ViewOfProcess {
      process {
        id
        name
      }
    }
  }
  configuration
}
    `;
export const ProcessSingle_SaveViewDocument = gql`
    mutation ProcessSingle_SaveView($input: SaveViewInput!) {
  saveView(input: $input) {
    ...ProcessSingle_View
  }
}
    ${ProcessSingle_ViewFragmentDoc}`;
export type ProcessSingle_SaveViewMutationFn = Apollo.MutationFunction<ProcessSingle_SaveViewMutation, ProcessSingle_SaveViewMutationVariables>;

/**
 * __useProcessSingle_SaveViewMutation__
 *
 * To run a mutation, you first call `useProcessSingle_SaveViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSingle_SaveViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSingleSaveViewMutation, { data, loading, error }] = useProcessSingle_SaveViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessSingle_SaveViewMutation(baseOptions?: Apollo.MutationHookOptions<ProcessSingle_SaveViewMutation, ProcessSingle_SaveViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessSingle_SaveViewMutation, ProcessSingle_SaveViewMutationVariables>(ProcessSingle_SaveViewDocument, options);
      }
export type ProcessSingle_SaveViewMutationHookResult = ReturnType<typeof useProcessSingle_SaveViewMutation>;
export type ProcessSingle_SaveViewMutationResult = Apollo.MutationResult<ProcessSingle_SaveViewMutation>;
export type ProcessSingle_SaveViewMutationOptions = Apollo.BaseMutationOptions<ProcessSingle_SaveViewMutation, ProcessSingle_SaveViewMutationVariables>;
export const ProcessSingle_DeleteViewDocument = gql`
    mutation ProcessSingle_DeleteView($input: DeleteViewInput!) {
  deleteView(input: $input)
}
    `;
export type ProcessSingle_DeleteViewMutationFn = Apollo.MutationFunction<ProcessSingle_DeleteViewMutation, ProcessSingle_DeleteViewMutationVariables>;

/**
 * __useProcessSingle_DeleteViewMutation__
 *
 * To run a mutation, you first call `useProcessSingle_DeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSingle_DeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSingleDeleteViewMutation, { data, loading, error }] = useProcessSingle_DeleteViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessSingle_DeleteViewMutation(baseOptions?: Apollo.MutationHookOptions<ProcessSingle_DeleteViewMutation, ProcessSingle_DeleteViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessSingle_DeleteViewMutation, ProcessSingle_DeleteViewMutationVariables>(ProcessSingle_DeleteViewDocument, options);
      }
export type ProcessSingle_DeleteViewMutationHookResult = ReturnType<typeof useProcessSingle_DeleteViewMutation>;
export type ProcessSingle_DeleteViewMutationResult = Apollo.MutationResult<ProcessSingle_DeleteViewMutation>;
export type ProcessSingle_DeleteViewMutationOptions = Apollo.BaseMutationOptions<ProcessSingle_DeleteViewMutation, ProcessSingle_DeleteViewMutationVariables>;
export const ProcessSingle_ViewsDocument = gql`
    query ProcessSingle_Views($input: ViewsInput!) {
  views(input: $input) {
    ...ProcessSingle_View
  }
}
    ${ProcessSingle_ViewFragmentDoc}`;

/**
 * __useProcessSingle_ViewsQuery__
 *
 * To run a query within a React component, call `useProcessSingle_ViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessSingle_ViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessSingle_ViewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessSingle_ViewsQuery(baseOptions: Apollo.QueryHookOptions<ProcessSingle_ViewsQuery, ProcessSingle_ViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessSingle_ViewsQuery, ProcessSingle_ViewsQueryVariables>(ProcessSingle_ViewsDocument, options);
      }
export function useProcessSingle_ViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessSingle_ViewsQuery, ProcessSingle_ViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessSingle_ViewsQuery, ProcessSingle_ViewsQueryVariables>(ProcessSingle_ViewsDocument, options);
        }
export type ProcessSingle_ViewsQueryHookResult = ReturnType<typeof useProcessSingle_ViewsQuery>;
export type ProcessSingle_ViewsLazyQueryHookResult = ReturnType<typeof useProcessSingle_ViewsLazyQuery>;
export type ProcessSingle_ViewsQueryResult = Apollo.QueryResult<ProcessSingle_ViewsQuery, ProcessSingle_ViewsQueryVariables>;