import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { SelectOption } from 'Components/Select/types'
import { fromEvent } from 'Utils'
import { useMemo } from 'react'
import { FieldCommonWrapper } from '../Common'
import { RequiredConstraint } from '../Constraints/Required'
import { Field } from '../Field'
import { FieldProps } from '../types'
import { useCurrenciesQuery } from './__generated__/query'

export const CurrencyFieldDefinitionInput: React.FC<FieldProps<'currency'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    const { data } = useCurrenciesQuery()

    const currencyOptions = useMemo(
        () => [
            {
                text: `-`,
                value: '',
            },
            ...(data
                ? data.currencies.map<SelectOption>(option => ({
                      text: `${option.symbol} (${option.code})`,
                      value: option.code,
                  }))
                : []),
        ],
        [data]
    )

    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-${index}-default`} name="Default value">
                <StyledCurrencyInput>
                    <Select
                        id={`bod-field-${index}-default-currency-code`}
                        value={field.defaultValue?.currencyCode ?? ''}
                        onValueChange={newVal => {
                            onChange('defaultValue')(() => {
                                return {
                                    currencyCode: newVal ?? null,
                                    amount: field.defaultValue?.amount ?? null,
                                }
                            })
                        }}
                        name={'Currency Code'}
                        options={currencyOptions}
                    />
                    <NumberInput
                        value={field.defaultValue?.amount ?? ''}
                        id={`bod-field-${index}-default`}
                        onChange={e => {
                            const newAmount = fromEvent(e)
                            onChange('defaultValue')(() => {
                                return {
                                    amount: newAmount
                                        ? Number(newAmount)
                                        : null,
                                    currencyCode:
                                        field.defaultValue?.currencyCode ??
                                        null,
                                }
                            })
                        }}
                        className="currency-value-input"
                    />
                </StyledCurrencyInput>
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
        </FieldCommonWrapper>
    )
}

const StyledCurrencyInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .currency-value-input {
        flex: 1;
        height: 3rem;
    }
`
