import { DateFieldUnique } from 'Utils/BusinessObjectDefinition'
import dayjs from 'dayjs'

const updateDateRangeStart = <T extends DateFieldUnique>(
    date: Date,
    prev: T
): T => {
    const { dateRange } = prev.constraints
    const start = dayjs(date)
    const end = dateRange.end ? dayjs(dateRange.end) : undefined
    const abs =
        prev.defaultValue &&
        'absolute' in prev.defaultValue &&
        prev.defaultValue.absolute
    const defaultValue =
        abs && (dayjs(abs).isAfter(end) || dayjs(abs).isBefore(start))
            ? undefined
            : prev.defaultValue
    return {
        ...prev,
        constraints: {
            required: prev.constraints.required,
            dateRange: {
                start: start.toISOString(),
                end: end?.isAfter(start) ? end.toISOString() : undefined,
            },
        },
        defaultValue,
    }
}

const updateDateRangeEnd = <T extends DateFieldUnique>(
    date: Date,
    prev: T
): T => {
    const { dateRange } = prev.constraints
    const start = dateRange.start ? dayjs(dateRange.start) : undefined
    const end = dayjs(date)
    const abs =
        prev.defaultValue &&
        'absolute' in prev.defaultValue &&
        prev.defaultValue.absolute
    const defaultValue =
        abs && (dayjs(abs).isAfter(end) || dayjs(abs).isBefore(start))
            ? undefined
            : prev.defaultValue

    return {
        ...prev,
        constraints: {
            required: prev.constraints.required,
            dateRange: {
                end: end.toISOString(),
                start: start?.isBefore(end) ? start.toISOString() : undefined,
            },
        },
        defaultValue,
    }
}

const updateDefaultSpecificDate = <T extends DateFieldUnique>(
    date: Date,
    prev: T
): T => ({
    ...prev,
    defaultValue: {
        absolute: dayjs(date).toISOString(),
    },
})

const updateDefaultRelativeDate = <T extends DateFieldUnique>(
    value: number,
    unit: 'hours' | 'days' | 'months' | 'years',
    prev: T
): T => ({
    ...prev,
    defaultValue: {
        relative: dayjs.duration(value, unit).toISOString(),
    },
})

const clearDateRange = <T extends DateFieldUnique>(prev: T): T => ({
    ...prev,
    constraints: {
        required: prev.constraints.required,
        dateRange: {
            start: undefined,
            end: undefined,
        },
    },
})

export {
    clearDateRange,
    updateDateRangeEnd,
    updateDateRangeStart,
    updateDefaultRelativeDate,
    updateDefaultSpecificDate,
}
