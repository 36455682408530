import { styled } from 'Adapters/Freestyled'
import { IconButton, TextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { FC, useState } from 'react'
import { useSingleProcess_UpdateBackstopMutation } from './__generated__/q'

type Props = {
    backstop: {
        id: string
        name: string
    }
    processId: string
    onOpenDef: () => void
    onDone: () => void
}

const SelectedBackstop: FC<Props> = ({
    processId,
    backstop,
    onOpenDef,
    onDone,
}) => {
    const [remove] = useSingleProcess_UpdateBackstopMutation()
    const { open, setOpen, triggerToast } = useTriggerToast()
    const [removing, setRemoving] = useState(false)

    return (
        <Styled>
            <menu>
                <Icon name="ExitProcess" />
                <Text as="p" variant="regular-4">
                    {backstop.name}
                </Text>
                <IconButton
                    iconName="Domain"
                    onClick={onOpenDef}
                    variant="secondary"
                />
                <IconButton
                    iconName="CircleMinus"
                    onClick={() => {
                        setRemoving(true)
                        remove({
                            variables: {
                                input: {
                                    id: processId,
                                    operations: [
                                        {
                                            updateBackstop: {
                                                previousValue: backstop.id,
                                            },
                                        },
                                    ],
                                },
                            },
                            onError: () => {
                                setRemoving(false)
                                triggerToast()
                            },
                            onQueryUpdated: q => q.refetch(),
                        })
                    }}
                    variant="secondary"
                    disabled={removing}
                />
            </menu>
            <footer>
                <TextButton
                    variant="primary"
                    text="Done"
                    onClick={onDone}
                    size="small"
                />
            </footer>
            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="We were unable to remove this backstop workflow. Please contact us if the problem persists."
            />
        </Styled>
    )
}

const Styled = styled.section`
    menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
        padding: 0.5rem;
        border-top: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};

        > p {
            flex: 1;
        }
    }
    footer {
        padding-top: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`

export { SelectedBackstop }
