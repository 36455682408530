import { flex, styled } from 'Adapters/Freestyled'

export const StyledListView = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    > header {
        ${flex('column', 'flex-start', 'flex-start')};
        padding: 2rem 3rem 1.25rem 3rem;

        > div {
            ${flex('row', 'space-between', 'flex-start')};
            width: 100%;
            padding-bottom: 0.25rem;

            @media screen and (min-width: 1800px) {
                ${flex('row', 'space-between', 'center')};
                padding-bottom: 0;
            }
        }

        p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
            max-width: 35rem;
        }

        .button-wrapper {
            ${flex('row', 'flex-start', 'flex-start')};
            gap: 1rem;
        }
    }

    > div:last-of-type {
        flex: 1;
        overflow: auto;

        > div {
            padding: 2rem 3rem;
        }
    }
`
