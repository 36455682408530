import { Spinner } from 'Components/Spinner'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import {
    BusinessObjectDefinitionsCreatorQuery,
    useBusinessObjectDefinitionsCreatorQuery,
} from '../BusinessObjectDefinitionCreator/__generated__/q'
import { AIStep1 } from './AIStep/AIStep1'
import { AIStep2 } from './AIStep/AIStep2'
import { DetailsStep } from './DetailsStep'
import { PhasesStep } from './PhasesStep/PhasesStep'
import { PhasesStepMobile } from './PhasesStep/PhasesStepMobile'
import { StartOptionsStep } from './StartOptionsStep/StartOptionsStep'
import { Props, determineMode } from './types'
import { ProcessCreatorProvider } from './useProcessCreator'

export const ProcessCreator: FC<Props> = props =>
    match(useBusinessObjectDefinitionsCreatorQuery())
        .with({ data: P.not(P.nullish) }, ({ data }) => (
            <Content data={data} {...props} />
        ))
        .otherwise(() => <Spinner />)

const Content: FC<
    Props & { data: BusinessObjectDefinitionsCreatorQuery }
> = props => {
    const {
        onCompleted,
        preselectedBO,
        initialProcess,
        initialIntegrations,
        processId,
        data,
        initialStep,
    } = props

    const mode = determineMode(props)

    const { isLarge } = useResponder()

    return (
        <ProcessCreatorProvider
            preselectedBO={preselectedBO}
            onCompleted={onCompleted}
            render={step =>
                match({ step, isLarge })
                    .with({ step: 'start' }, () => <StartOptionsStep />)
                    .with({ step: 'details' }, () => (
                        <DetailsStep
                            preselectedBO={preselectedBO}
                            mode={mode}
                        />
                    ))
                    .with({ step: 'phases', isLarge: false }, () => (
                        <PhasesStepMobile mode={mode} />
                    ))
                    .with({ step: 'phases' }, () => <PhasesStep mode={mode} />)
                    .with({ step: 'ai-1' }, () => <AIStep1 />)
                    .with({ step: 'ai-2' }, () => <AIStep2 />)
                    .exhaustive()
            }
            initialProcess={initialProcess}
            initialIntegrations={initialIntegrations}
            processId={processId}
            definitionData={data}
            initialStep={initialStep}
        />
    )
}
