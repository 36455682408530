import { useOrganisationUsers } from 'Hooks'
import { useMemo } from 'react'

export const useUserOptions = (params?: { withUnassigned: boolean }) => {
    const { users } = useOrganisationUsers()

    const options = useMemo(() => {
        if (!users) return []

        const currentUser = users.find(user => user.isCurrentUser)

        const otherUsers = users.filter(user => !user.isCurrentUser)

        if (!currentUser) return [] // There will however always be a currentUser

        return [
            { text: `Me (${currentUser.name})`, value: currentUser.id },
            { text: 'Any', value: '' },
            ...(params?.withUnassigned
                ? [{ text: 'Unassigned', value: 'unassigned' }]
                : []),
            ...otherUsers.map(user => ({ text: user.name, value: user.id })),
        ]
    }, [users, params])

    return options
}
