import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { GetActionViewsQuery } from 'Root/App/Main/Actions/__generated__/query'
import { FC, useState } from 'react'
import { ManageSingleView } from './ManageSingleView'

type Props = {
    views: GetActionViewsQuery['views']
}

export const ManageViews: FC<Props> = ({ views }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <IconTextButton
                text={'Manage views'}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpen(true)
                }}
                icon="Settings"
                variant="secondary"
                size="xSmall"
            />

            <MinimalModal open={open} onOpenChange={setOpen}>
                <StyledContent>
                    {views.map(view => (
                        <ManageSingleView key={view.id} view={view} />
                    ))}

                    <footer>
                        <IconTextButton
                            text={'Done'}
                            onClick={() => setOpen(false)}
                            icon="Close"
                            variant="primary"
                            size="xSmall"
                        />
                    </footer>
                </StyledContent>
            </MinimalModal>
        </>
    )
}

const StyledContent = styled.div`
    padding: 0.5rem 4vw 1rem;
    ul {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.5rem;
    }
    > footer {
        padding-top: 1rem;

        button {
            width: 100%;
        }
    }
`
