import { ReactEventHandler } from 'react'
import { match } from 'ts-pattern'
import { fieldLabels } from 'Utils'
import { Setter } from 'Utils/types'
import { Fields } from '../types'
import { BooleanFieldDefinitionInput } from './Field/Boolean'
import { CurrencyFieldDefinitionInput } from './Field/Currency'
import { DateFieldDefinitionInput } from './Field/Date'
import { DocumentFieldDefinitionInput } from './Field/Document'
import { EmailFieldDefinitionInput } from './Field/Email'
import { ListFieldDefinitionInput } from './Field/List'
import { NumberFieldDefinitionInput } from './Field/Number'
import { RelationFieldDefinitionInput } from './Field/Relation'
import { SelectFieldDefinitionInput } from './Field/Select'
import { TelephoneFieldDefinitionInput } from './Field/Telephone'
import { TextFieldDefinitionInput } from './Field/Text'
import { UpdatesFieldInput } from './Field/Updates'
import { URLFieldDefinitionInput } from './Field/URL'
import { UserFieldDefinitionInput } from './Field/User'

type Props = {
    field: Fields
    index: number
    onFieldChange: (
        index: number
    ) => (prop: string) => (setter: Setter<any>) => void
    onFieldRemoved?: ReactEventHandler
    disableRequiredConstraint?: boolean
}

export const CreateBusinessObjectDefFields: React.FC<Props> = ({
    field,
    index: i,
    onFieldChange,
    onFieldRemoved,
    disableRequiredConstraint,
}) =>
    match(field)
        .with({ type: 'text' }, field => (
            <TextFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                key={i}
                index={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'boolean' }, field => (
            <BooleanFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'date' }, field => (
            <DateFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'document' }, field => (
            <DocumentFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'email' }, field => (
            <EmailFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'list' }, field => (
            <ListFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'number' }, field => (
            <NumberFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'relation' }, field => (
            <RelationFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'select' }, field => (
            <SelectFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'url' }, field => (
            <URLFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'user' }, field => (
            <UserFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'telephone' }, field => (
            <TelephoneFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'updates' }, field => (
            <UpdatesFieldInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .with({ type: 'currency' }, field => (
            <CurrencyFieldDefinitionInput
                name={fieldLabels[field.type]}
                field={field}
                index={i}
                key={i}
                onChange={onFieldChange(i)}
                onRemoved={onFieldRemoved}
                disableRequiredConstraint={disableRequiredConstraint}
            />
        ))
        .exhaustive()
