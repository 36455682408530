import { FC, useReducer } from 'react'
import { CreateBusinessObjectField } from '__generated__'
import { BODFields } from '../../types'
import { DispatchContext, StateContext } from './context'
import { generateInitialState } from './helpers'
import { reducer } from './reducer'

type CreateFormStateProviderProps = {
    children: React.ReactNode
    fields: BODFields
    initialValues?: CreateBusinessObjectField[]
}

export const CreateFormStateProvider: FC<CreateFormStateProviderProps> = ({
    children,
    fields,
    initialValues,
}) => {
    const [state, dispatch] = useReducer(
        reducer,
        generateInitialState(fields, initialValues)
    )

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
