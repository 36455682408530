import { flex, styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { FileIcon, iconMap, isKnownFileType } from '../../../../FileIcon'

type Props = {
    fileType: string
}

export const FileTypeCell: FC<Props> = ({ fileType }) => {
    const formattedFileType = isKnownFileType(fileType)
        ? iconMap[fileType].name
        : fileType

    return (
        <StyledFileTypeCell>
            <div>
                <FileIcon name={fileType} />
                {formattedFileType}
            </div>
        </StyledFileTypeCell>
    )
}

const StyledFileTypeCell = styled.td`
    vertical-align: middle;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${({ theme }) => theme.spacing['04']};
        width: 100%;
        height: 100%;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        ${regular5};
    }
`
