import { FieldType } from '__generated__'
import { z } from 'zod'

export const SourceSchema = z.object({
    action: z.object({
        id: z.string(),
        name: z.string(),
        assignedToId: z.string().optional(),
        description: z.string().optional(),
        dueDate: z.string().optional(),
        extendedBy: z
            .object({
                id: z.string(),
                definitionId: z.string(),
                fields: z.array(
                    z.object({
                        id: z.string(),
                        type: z.nativeEnum(FieldType),
                        value: z.unknown(),
                    })
                ),
            })
            .optional(),
    }),
})

export type Source = z.infer<typeof SourceSchema>

export const DestinationSchema = z.object({
    groupId: z.string(),
    fieldType: z.nativeEnum(FieldType).or(z.enum(['assignedTo'])),
})

export type Destination = z.infer<typeof DestinationSchema>
