import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetIdentityMutationVariables = Types.Exact<{
  input: Types.SetIdentityInput;
}>;


export type SetIdentityMutation = { __typename?: 'Mutation', setIdentity: { __typename?: 'User', id: string } };


export const SetIdentityDocument = gql`
    mutation SetIdentity($input: SetIdentityInput!) {
  setIdentity(input: $input) {
    id
  }
}
    `;
export type SetIdentityMutationFn = Apollo.MutationFunction<SetIdentityMutation, SetIdentityMutationVariables>;

/**
 * __useSetIdentityMutation__
 *
 * To run a mutation, you first call `useSetIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIdentityMutation, { data, loading, error }] = useSetIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIdentityMutation(baseOptions?: Apollo.MutationHookOptions<SetIdentityMutation, SetIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIdentityMutation, SetIdentityMutationVariables>(SetIdentityDocument, options);
      }
export type SetIdentityMutationHookResult = ReturnType<typeof useSetIdentityMutation>;
export type SetIdentityMutationResult = Apollo.MutationResult<SetIdentityMutation>;
export type SetIdentityMutationOptions = Apollo.BaseMutationOptions<SetIdentityMutation, SetIdentityMutationVariables>;