import { logger } from 'Adapters/Logger'
import { makeMutationVariables } from 'Features/BusinessObjectDefinitionCreator/useCreator/makeMutationVariables'
import { has } from 'Utils'
import { Operator } from '__generated__'
import { useCallback } from 'react'
import { useProcessCreator } from '../useProcessCreator'
import { State } from '../useProcessCreator/types'
import {
    useCreator_CreateBusinessObjectDefinitionMutation,
    useCreator_CreateProcessMutation,
} from './__generated__/mutation'
import { useSaveIntegrations } from './saveIntegrations'

export const useCreateProcess = (
    setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const {
        state: { input, integrations, initialIntegrations, label, fields },
        onCompleted,
    } = useProcessCreator()

    const [save, { loading }] = useCreator_CreateProcessMutation()

    const [saveBODef] = useCreator_CreateBusinessObjectDefinitionMutation()

    const { saveIntegrations, mutating } = useSaveIntegrations({
        onError: () => setShowErrorToast(true),
    })

    const saveProcess = useCallback(
        async (input: State['input']) => {
            return await save({
                variables: { input },
                update: (cache, { data }) => {
                    data &&
                        cache.modify({
                            fields: {
                                processes: (existing: { id: string }[]) =>
                                    existing.find(
                                        p => p.id === data.createProcess.id
                                    )
                                        ? existing
                                        : [...existing, data.createProcess],
                            },
                        })
                },
                onError: e => {
                    logger.error('Failed to save process', e, {
                        input,
                    })
                    setShowErrorToast(true)
                },
                onQueryUpdated: q => q.refetch(),
                refetchQueries: ['OnboardingProgress'],
            })
        },
        [save, setShowErrorToast]
    )

    const handleDatasetFirstCreate = useCallback(async () => {
        const result = await saveProcess(input)

        const process = result.data?.createProcess

        if (!process) {
            logger.error(
                'Could not save integrations as no process ID returned'
            )
            return
        }

        await saveIntegrations(integrations, initialIntegrations, process.id)

        onCompleted(process)
    }, [
        saveProcess,
        input,
        saveIntegrations,
        integrations,
        initialIntegrations,
        onCompleted,
    ])

    const handleProcessFirstCreate = useCallback(async () => {
        const definitionResult = await saveBODef({
            variables: makeMutationVariables({
                definition: {
                    id: '',
                    name: `${input.name} Item`,
                    description: `Generated dataset for ${input.name} workflow`,
                    label: label.value,
                    fields: fields.map(({ field }) => field),
                },
                enableDocuments: false,
                enableUpdates: true,
            }),
            onError: () => {
                setShowErrorToast(true)
            },
        })

        const definition = definitionResult.data?.createBusinessObjectDefinition

        if (!definition) {
            logger.error('Could not save process as no definition ID returned')
            setShowErrorToast(true)
            return
        }

        const result = await saveProcess({
            ...input,
            operatesUpon: [definition.id],
            phases: input.phases.map((phase, index) => {
                const dataCriteria = fields.filter(
                    ({ requiredBy }) =>
                        has(requiredBy, 'phase') && requiredBy.phase === index
                )

                return {
                    ...phase,
                    criteria: [
                        ...phase.criteria,
                        ...dataCriteria.map(({ field }) => {
                            const savedField = definition.fields.find(
                                f => f.name === field.name
                            )

                            if (!savedField) {
                                throw new Error(
                                    'No corresponding saved field for criterion'
                                )
                            }

                            return {
                                fieldCondition: {
                                    comparator: {
                                        operator: Operator.IsDefined,
                                        with: '{}',
                                        negate: false,
                                        valueSelector: {
                                            fieldValue: {
                                                fieldId: savedField.id,
                                            },
                                        },
                                    },
                                },
                            }
                        }),
                    ],
                }
            }),
        })

        const process = result.data?.createProcess

        if (!process) {
            logger.error(
                'Could not save integrations as no process ID returned'
            )
            setShowErrorToast(true)
            return
        }

        await saveIntegrations(integrations, initialIntegrations, process.id)

        onCompleted(process)
    }, [
        fields,
        initialIntegrations,
        input,
        integrations,
        label.value,
        onCompleted,
        saveBODef,
        saveIntegrations,
        saveProcess,
        setShowErrorToast,
    ])

    const handleCreate =
        input.operatesUpon.length > 0
            ? handleDatasetFirstCreate
            : handleProcessFirstCreate

    return {
        handleCreate,
        createLoading: loading || mutating,
    }
}
