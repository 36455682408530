import { FC } from 'react'
import { match, P } from 'ts-pattern'
import { CenteredSpinner } from '../../Components/Spinner'
import { useSingleBusinessObjectQuery } from '../SingleBusinessObjectTabs/__generated__/SingleBusinessObject'
import { UpdatesWrapper } from './styles'
import { UpdatesDisplay } from './UpdatesDisplay'

type Props = {
    businessObjectId: string
    limit?: number
    className?: string
}

export const Updates: FC<Props> = ({
    businessObjectId,
    limit,
    className = '',
}) => {
    const res = useSingleBusinessObjectQuery({
        variables: { input: { id: businessObjectId } },
        fetchPolicy: 'cache-and-network',
    })

    return (
        <UpdatesWrapper className={className}>
            {match(res)
                .with({ loading: true }, () => <CenteredSpinner />)
                .with({ loading: false, data: undefined }, () => (
                    <div>No record was found</div>
                ))
                .with(
                    { loading: false, data: P.not(P.nullish) },
                    ({ data: { businessObject } }) => {
                        return (
                            <UpdatesDisplay
                                businessObject={businessObject}
                                limit={limit}
                            />
                        )
                    }
                )
                .exhaustive()}
        </UpdatesWrapper>
    )
}
