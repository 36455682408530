import { flex, styled } from 'Adapters/Freestyled'
import { DropdownButton } from 'Components/Dropdown'
import { ControlledModal } from 'Components/Modal'
import { GetBusinessObjectViewsQuery } from 'Features/FilterBusinessObject/FilterBar/__generated__/query'
import { useModalControls } from 'Hooks'
import { noop } from 'lodash'
import { FC } from 'react'
import { ManageSingleView } from './ManageSingleView'

type Props = {
    views: NonNullable<GetBusinessObjectViewsQuery['views']>
}

export const ManageViews: FC<Props> = ({ views }) => {
    const { open, openModal, setOpen } = useModalControls()

    return (
        <>
            <DropdownButton
                text={'Manage views'}
                onSelect={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    openModal()
                }}
                icon="Settings"
            />

            <ControlledModal
                renderContent={() => (
                    <StyledContent>
                        {views.map(view => (
                            <ManageSingleView key={view.id} view={view} />
                        ))}
                    </StyledContent>
                )}
                title={'Manage views'}
                description={'Delete your saved views here'}
                open={open}
                onOpenChange={setOpen}
                returnFocus={noop}
            />
        </>
    )
}

const StyledContent = styled.ul`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100vw;
    max-width: 35rem;
    gap: 0.5rem;
    padding: 1rem 0;
`
