import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { useSaveBusinessObjectViewMutation } from '../__generated__/mutation'

type Props = {
    businessObjectDefinitionId: string
}

const SaveView: FC<Props> = ({ businessObjectDefinitionId }) => {
    const [open, setOpen] = useState(false)

    const { current: inputId } = useRef(v4())

    const { filterState } = useBusinessObjectFilterState()

    const { display, selectedFields, sort, grouping } = useBusinessObjectsView()

    const [viewName, setViewName] = useState('')

    const {
        open: successOpen,
        setOpen: setSuccessOpen,
        triggerToast: triggerSuccess,
    } = useTriggerToast()

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const [persist] = useSaveBusinessObjectViewMutation({
        variables: {
            input: {
                type: ViewOfType.BusinessObjects,
                businessObjectDefinitionId,
                configuration: Buffer.from(
                    JSON.stringify({
                        filterState,
                        display,
                        selectedFields,
                        sort,
                        ...(grouping && { grouping }),
                    })
                ).toString('base64'),
                name: viewName,
            },
        },
        onCompleted: () => {
            setOpen(false)
            triggerSuccess()
            setViewName('')
        },
        onError: () => {
            triggerErrorToast()
        },
        refetchQueries: ['GetBusinessObjectViews'],
    })

    return (
        <>
            <IconTextButton
                icon="Save"
                text="Save view"
                size="xSmall"
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled
                    onSubmit={e => {
                        e.preventDefault()
                        persist()
                    }}
                >
                    <Text as="label" variant="bold-5" htmlFor={inputId}>
                        Label
                    </Text>
                    <Text as="p" variant="regular-6">
                        Give your view a memorable name
                    </Text>
                    <TextInput
                        id={inputId}
                        placeholder="My view"
                        onChange={({ currentTarget: { value = '' } }) =>
                            setViewName(value)
                        }
                    />
                    <footer>
                        <IconTextButton
                            icon="Close"
                            type="button"
                            text="Cancel"
                            size="xSmall"
                            variant="secondary"
                            onClick={() => setOpen(false)}
                        />
                        <IconTextButton
                            icon="Save"
                            type="submit"
                            text="Save"
                            size="xSmall"
                            variant="primary"
                        />
                    </footer>
                </Styled>
            </MinimalModal>
            <SimpleToast
                open={successOpen}
                onOpenChange={setSuccessOpen}
                description="View saved"
            />
            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={'There was an error saving the view.'}
            />
        </>
    )
}

const Styled = styled.form`
    padding: 0.5rem 4vw 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    footer {
        display: flex;
        padding-top: 0.75rem;
        gap: 1rem;
    }

    footer button {
        flex: 1;
    }
`

export { SaveView }
