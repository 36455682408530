import { useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { NoBusinessObjectDefinitions } from '../NoBusinessObjectDefinitions'
import { Route } from '../types'
import { Desktop } from './Desktop/Desktop'
import { Mobile } from './Mobile/Mobile'

type Props = {
    routes: Route[]
}

export const BusinessObjectsDisplay: FC<Props> = ({ routes }) => {
    const { screenSize } = useResponder()

    return match({ screenSize, routes })
        .with({ routes: [] }, () => <NoBusinessObjectDefinitions />)
        .with({ screenSize: 'large' }, () => <Desktop routes={routes} />)
        .otherwise(() => <Mobile routes={routes} />)
}
