import { FC } from 'react'
import { match } from 'ts-pattern'
import { ProcessDef_SingleProcessQuery } from '../../../__generated__/query'
import { DetailsStep } from './DetailsStep'
import { PhasesStep } from './PhasesStep/PhasesStep'
import { ProcessEditorProvider } from './useProcessEditor'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
    onCompleted: () => void
}

const Content: FC<Props> = ({ process, onCompleted }) => (
    <ProcessEditorProvider
        process={process}
        onCompleted={onCompleted}
        render={step =>
            match(step)
                .with('details', () => <DetailsStep />)
                .with('phases', () => <PhasesStep />)
                .exhaustive()
        }
    />
)

export { Content }
