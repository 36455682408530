import { flex, styled } from 'Adapters/Freestyled'
import actions from 'Assets/images/actions-screenshot.png'
import businessObjects from 'Assets/images/business-object-screenshot.png'
import welcome from 'Assets/images/pexels-fauxels-3184465.jpg'
import processes from 'Assets/images/process-screenshot.png'
import nextSteps from 'Assets/images/shane-4993XnXQKHY-unsplash.jpg'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { FeatureName } from 'Features/FeatureName'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { SlideControls } from './SlideControls'
import { Slide } from './types'
import { useWelcomeNavigation } from './useWelcomeNavigation'

type Props = {
    slides: Slide[]
    clearWelcomeModal: () => void
}

export const WelcomeSlideshow: FC<Props> = ({ slides, clearWelcomeModal }) => {
    const {
        currentIndex,
        direction,
        incrementSlide,
        decrementSlide,
        goToSlide,
    } = useWelcomeNavigation()

    return (
        <StyledWelcomePage>
            <AnimatePresence initial={false}>
                {slides.map((slide, index) => {
                    if (index !== currentIndex) return null

                    return (
                        <StyledSlide
                            key={slide}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: {
                                    type: 'spring',
                                    stiffness: 300,
                                    damping: 30,
                                },
                            }}
                            custom={direction}
                        >
                            {match(slide)
                                .with('welcome', () => (
                                    <EmptyStateCard
                                        title={'Welcome'}
                                        paragraphs={[
                                            'Welcome to Modlify. You are in the right place!',
                                            'View the following slides for a brief introduction to some of our concepts.',
                                            'When you are finished, get started by building with AI or select one of our templates. These can all be modified to suit your business needs.',
                                        ]}
                                        imageUrl={welcome}
                                    />
                                ))
                                .with('processes', () => (
                                    <EmptyStateCard
                                        title={'Workflows'}
                                        paragraphs={[
                                            <>
                                                <FeatureName>
                                                    Workflows
                                                </FeatureName>{' '}
                                                are what your business does.
                                                They represent the way in which
                                                you want to work.
                                            </>,
                                            'They define the steps needed to fulfil business objectives, and the criteria that must be met to deliver successful outcomes.',
                                            'They provide structure to ensure relevant business data is captured at the right time.',
                                        ]}
                                        imageUrl={processes}
                                    />
                                ))
                                .with('businessObjects', () => (
                                    <EmptyStateCard
                                        title={'Datasets'}
                                        paragraphs={[
                                            <>
                                                <FeatureName>
                                                    Datasets
                                                </FeatureName>{' '}
                                                represent what your business
                                                has, the data you wish to
                                                capture.
                                            </>,
                                            'They define the entities that your business interacts with. Do you sell products and services? Do you acquire job opportunities? Do you have clients?',
                                            <>
                                                <FeatureName>
                                                    Datasets
                                                </FeatureName>{' '}
                                                are where most of your data is
                                                held, and they log a history of
                                                how that data changes over time.
                                            </>,
                                            'We allow you to determine what you want to capture and how to link your data together.',
                                        ]}
                                        imageUrl={businessObjects}
                                    />
                                ))
                                .with('actions', () => (
                                    <EmptyStateCard
                                        title={'Actions'}
                                        paragraphs={[
                                            <>
                                                <FeatureName>
                                                    Actions
                                                </FeatureName>{' '}
                                                represent atomic items of work
                                                that need to be completed either
                                                as part of a{' '}
                                                <FeatureName>
                                                    workflow
                                                </FeatureName>{' '}
                                                or independently. Think of them
                                                as tasks or todos.
                                            </>,
                                            'They can be assigned to team members, given a due date and have any number of other data fields attached to them.',
                                            <>
                                                <FeatureName>
                                                    Actions
                                                </FeatureName>{' '}
                                                are generated automatically when
                                                moving a{' '}
                                                <FeatureName>
                                                    record
                                                </FeatureName>{' '}
                                                through a{' '}
                                                <FeatureName>
                                                    workflow
                                                </FeatureName>
                                                , based on the phase criteria.
                                                They can also be created at any
                                                time from the{' '}
                                                <FeatureName>
                                                    actions
                                                </FeatureName>{' '}
                                                page.
                                            </>,
                                        ]}
                                        imageUrl={actions}
                                    />
                                ))
                                .with('nextSteps', () => (
                                    <EmptyStateCard
                                        title={'Next Steps'}
                                        paragraphs={[
                                            `Now that you are familiar with some concepts, let's get started!`,
                                            'Get started with a template or build your workflow with AI.',
                                        ]}
                                        button={{
                                            text: 'Continue',
                                            icon: 'ArrowRight',
                                            handler: () => clearWelcomeModal(),
                                        }}
                                        imageUrl={nextSteps}
                                    />
                                ))
                                .exhaustive()}
                        </StyledSlide>
                    )
                })}
            </AnimatePresence>

            <SlideControls
                currentIndex={currentIndex}
                incrementSlide={incrementSlide}
                decrementSlide={decrementSlide}
                goToSlide={goToSlide}
            />
        </StyledWelcomePage>
    )
}

const StyledWelcomePage = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    .empty-state-card {
        height: 100%;
        width: 100%;
    }
`

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? '100%' : '-100%',
        }
    },
    center: {
        zIndex: 1,
        x: 0,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? '100%' : '-100%',
        }
    },
}

const StyledSlide = styled(motion.div)`
    ${flex('column', 'center', 'center')};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
`
