import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { FC, useRef, useState } from 'react'

type Props = {
    confirmRestart: () => void
}

const Restart: FC<Props> = ({ confirmRestart }) => {
    const restartRef = useRef<HTMLButtonElement>(null)
    const [restartConfirmOpen, setRestartConfirmOpen] = useState(false)
    return (
        <>
            <IconTextButton
                icon="AngleLeft"
                text="Restart"
                variant="secondary"
                ref={restartRef}
                onClick={() => setRestartConfirmOpen(true)}
            />
            <ControlledModal
                title="Are you sure?"
                description="All unsaved changes will be lost."
                open={restartConfirmOpen}
                onOpenChange={setRestartConfirmOpen}
                renderContent={() => (
                    <StyledConfirmRestart>
                        <IconTextButton
                            icon="Close"
                            text="Cancel"
                            variant="secondary"
                            onClick={() => setRestartConfirmOpen(false)}
                        />
                        <IconTextButton
                            icon="Check"
                            text="Restart"
                            variant="primary"
                            onClick={() => {
                                setRestartConfirmOpen(false)
                                confirmRestart()
                            }}
                        />
                    </StyledConfirmRestart>
                )}
                returnFocus={() => restartRef.current?.focus()}
            />
        </>
    )
}

const StyledConfirmRestart = styled.div`
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    button {
        flex: 1;
    }
`

export { Restart }
