import {
    UseVirtualFloatingOptions,
    flip,
    offset,
} from '@udecode/plate-floating'
import {
    LinkFloatingToolbarState,
    useFloatingLinkEdit,
    useFloatingLinkEditState,
    useFloatingLinkInsert,
    useFloatingLinkInsertState,
} from '@udecode/plate-link'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { useEffect, useRef } from 'react'
import { ExternalLinkButton } from './ExternalLinkButton'
import { LinkInput } from './LinkInput'
import { Separator } from './Separator'

const floatingOptions: UseVirtualFloatingOptions = {
    placement: 'bottom-start',
    middleware: [
        offset(12),
        flip({
            padding: 12,
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
        }),
    ],
}

export interface LinkFloatingToolbarProps {
    state?: LinkFloatingToolbarState
}

export function LinkFloatingToolbar({ state }: LinkFloatingToolbarProps) {
    const insertState = useFloatingLinkInsertState({
        ...state,
        floatingOptions: {
            ...floatingOptions,
            ...state?.floatingOptions,
        },
    })

    const {
        props: insertProps,
        ref: insertRef,
        hidden,
        textInputProps,
    } = useFloatingLinkInsert(insertState)

    const editState = useFloatingLinkEditState({
        ...state,
        floatingOptions: {
            ...floatingOptions,
            ...state?.floatingOptions,
        },
    })

    const {
        props: editProps,
        ref: editRef,
        editButtonProps,
        unlinkButtonProps,
    } = useFloatingLinkEdit(editState) // The 'enter' handler is contained in here

    // Clears the display text input value on close of the floating link menu
    const inputRef = useRef<HTMLInputElement>(null)
    const isOpen = useRef(false)
    useEffect(() => {
        if (insertState.isOpen !== isOpen.current) {
            isOpen.current = insertState.isOpen

            if (insertState.isOpen === false && inputRef.current?.value) {
                inputRef.current.value = ''
            }
        }
    }, [insertState.isOpen])

    if (hidden) return null

    const input = (
        <StyledEditLink>
            <div className="input-container">
                <div className="icon-container">
                    <Icon name="Link" />
                </div>

                <LinkInput isOpen={insertState.isOpen} />
            </div>

            <div className="input-container">
                <div className="icon-container">
                    <Icon name="Menu" title="Text" />
                </div>

                <input
                    placeholder="Text to display"
                    {...textInputProps}
                    ref={inputRef}
                />
            </div>

            <p>
                Press <kbd>enter</kbd> when complete
            </p>
        </StyledEditLink>
    )

    const editContent = editState.isEditing ? (
        input
    ) : (
        <StyledInteractWithExistingLink>
            <Tooltip content="Edit link">
                <IconButton
                    iconName={'Edit'}
                    onClick={editButtonProps.onClick}
                    title="Edit"
                    type="button"
                />
            </Tooltip>

            <Tooltip content="Unlink">
                <IconButton
                    iconName={'Unlink'}
                    title={'Unlink'}
                    onClick={unlinkButtonProps.onClick}
                    type="button"
                />
            </Tooltip>

            <Separator />

            <Tooltip content="Visit link">
                <ExternalLinkButton />
            </Tooltip>
        </StyledInteractWithExistingLink>
    )

    return (
        <>
            <StyledWrapper ref={insertRef} {...insertProps}>
                {input}
            </StyledWrapper>

            <StyledWrapper ref={editRef} {...editProps}>
                {editContent}
            </StyledWrapper>
        </>
    )
}

const StyledWrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    padding: 0.25rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
`

const StyledEditLink = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 20rem;
    gap: 0.5rem;

    .input-container {
        ${flex('row', 'flex-start', 'center')};
        width: 100%;
        gap: 0.75rem;
        padding: 0.25rem;

        input {
            flex: 1;
            padding: 0.5rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};

            &:focus {
                outline: none;
            }
        }

        .icon-container {
            ${flex('row', 'flex-start', 'center')};

            svg path {
                fill: ${({ theme }) => theme.palette.icon['02'].normal};
            }
        }
    }

    > p {
        ${regular5};
        color: ${({ theme }) => theme.palette.text['03'].normal};
        text-align: center;
        width: 100%;
    }
`

const StyledInteractWithExistingLink = styled.div`
    ${flex('row', 'flex-start', 'center')};
    box-sizing: content-box;
    gap: 0.25rem;
    height: 2.25rem;
`
