import { IconButton, IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { noop } from 'lodash'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { Loaded } from './Loaded'
import { useActionTimelineCardQuery } from './__generated__/query'
import { StyledCard } from './styles'

type Props = {
    id: string
    draggable?: true
    hideMenu?: boolean
    isMostRecent?: boolean
}

export const ActionCard: FC<Props> = ({
    id,
    draggable,
    hideMenu = false,
    isMostRecent,
}) => {
    const { data } = useActionTimelineCardQuery({
        variables: { input: { id } },
    })

    return match({ data, draggable })
        .with({ data: P.nullish }, () => (
            <StyledCard data-loading>
                <header>
                    <Text as="h4" variant="bold-4">
                        loading
                    </Text>
                    {!hideMenu ? <IconButton iconName="More" disabled /> : null}
                </header>
                <div>
                    <IconTextButton
                        size="xSmall"
                        variant="ghost"
                        icon="User"
                        text="..."
                        onClick={noop}
                    />
                </div>
            </StyledCard>
        ))
        .with(
            { data: P.not(P.nullish), draggable: true },
            ({ data, draggable }) => (
                <Loaded
                    action={data.action}
                    draggable={draggable}
                    hideMenu={hideMenu}
                    isMostRecent={isMostRecent}
                />
            )
        )
        .with({ data: P.not(P.nullish), draggable: undefined }, ({ data }) => (
            <Loaded
                action={data.action}
                hideMenu={hideMenu}
                isMostRecent={isMostRecent}
            />
        ))
        .otherwise(() => null)
}
