import { ControlledModal } from 'Components/Modal'
import { delay } from 'lodash'
import { ComponentProps, FC } from 'react'
import { CreateTenantForm } from './Form'

type Props = {
    close: () => void
} & Pick<
    ComponentProps<typeof ControlledModal>,
    'open' | 'onOpenChange' | 'returnFocus'
>

const CreateTenantModal: FC<Props> = ({ close, ...rest }) => (
    <ControlledModal
        title="Create workspace"
        description="Create a new workspace with its own users and workflows."
        {...rest}
        renderContent={() => (
            <CreateTenantForm
                onSuccess={() => {
                    delay(() => rest.onOpenChange(false), 1500)
                }}
            />
        )}
    />
)

export { CreateTenantModal }
