import { dayjs } from 'Adapters/DayJS'
import { useActionsView } from 'Features/ActionView'
import { helpers as principal } from 'Features/Principal'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { Action } from '../types'

type Sort = {
    by: string
    asc: boolean
}

type SortActions = (sort: Sort) => (actions: Action[]) => Action[]

const sortActions: SortActions = sort => actions =>
    orderBy(
        actions,
        [
            a =>
                match(sort.by)
                    .with('name', () => a.name)
                    .with(
                        'assignedTo',
                        () => a.assignedTo && principal.name(a.assignedTo)
                    )
                    .with('dueDate', () =>
                        a.dueDate ? dayjs(a.dueDate).unix() : 0
                    )
                    .otherwise(() => {
                        return match(
                            a.extendedBy?.fields.find(
                                f => f.fieldDefinition.name === sort.by
                            )
                        )
                            .with(
                                { __typename: 'BusinessObjectTextField' },
                                field => field.textValue
                            )
                            .with(
                                { __typename: 'BusinessObjectNumberField' },
                                field => field.numValue
                            )
                            .with(
                                { __typename: 'BusinessObjectCurrencyField' },
                                field => field.currencyValue?.amount
                            )
                            .with(
                                { __typename: 'BusinessObjectUserField' },
                                field => field.userValue?.name
                            )
                            .with(
                                { __typename: 'BusinessObjectRelationField' },
                                field => field.relationValue?.label
                            )
                            .with(
                                { __typename: 'BusinessObjectDateField' },
                                field =>
                                    field.dateValue &&
                                    dayjs(field.dateValue).unix()
                            )
                            .otherwise(() => a.name)
                    }),
        ],
        [sort.asc ? 'asc' : 'desc']
    )

export const useSortActions = (actions: Action[]) => {
    const {
        sort: [field, order],
    } = useActionsView()

    return useMemo(
        () => sortActions({ by: field, asc: order === 'asc' })(actions),
        [actions, field, order]
    )
}
