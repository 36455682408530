import { useTriggerToast } from 'Components/Toast'
import { ChangeSubscriptionAction } from '__generated__'
import {
    useToggleBusinessObjectSubscriptionMutation,
    useUserIsSubscribedToBusinessObjectQuery,
} from './__generated__/q'

const useToggleSubscription = (id: string) => {
    const {
        open: toastOpen,
        setOpen: setToastOpen,
        triggerToast,
    } = useTriggerToast()
    const [update] = useToggleBusinessObjectSubscriptionMutation()
    const { data, loading } = useUserIsSubscribedToBusinessObjectQuery({
        variables: { input: { id } },
    })

    const subscribed = data?.businessObject.subscribed ?? false

    const toggleSubscription = () => {
        update({
            variables: {
                input: {
                    businessObjectId: id,
                    action: ChangeSubscriptionAction[
                        subscribed ? 'Remove' : 'Add'
                    ],
                },
            },
            update: (cache, { data }) => {
                if (!data) return
                cache.modify({
                    id: cache.identify({
                        __typename: 'BusinessObject',
                        id,
                    }),
                    fields: {
                        subscribed: () => data.changeBusinessObjectSubscription,
                    },
                })
            },
            optimisticResponse: {
                changeBusinessObjectSubscription: !subscribed,
            },
            onError: () => {
                triggerToast()
            },
        })
    }

    return {
        loading: loading || !data,
        subscribed,
        toastOpen,
        setToastOpen,
        toggleSubscription,
    }
}

export { useToggleSubscription }
