import { Content, Item } from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { keyframes } from 'styled-components'

const slideDown = keyframes`
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
`

const slideUp = keyframes`
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
`

const Styled = styled(Item)`
    border-top: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
    position: relative;

    &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const StyledHeader = styled.header`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    > span {
        display: block;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 1.5rem 0;
        text-transform: capitalize;
        width: 6rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
    }

    > div {
        flex: 1;
        padding: 1.5rem 1rem;
    }

    > button {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
        border-radius: 0;
        padding: 1rem;
    }

    > button:last-of-type span {
        transition: transform
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &[data-state='open'] > button:last-of-type span {
        transform: rotateZ(180deg);
    }
`

const StyledContent = styled(Content)`
    overflow: hidden;

    &[data-state='open'] {
        animation: ${slideDown} ${({ theme }) => theme.animation.mid} ease;
    }

    &[data-state='closed'] {
        animation: ${slideUp} ${({ theme }) => theme.animation.mid} ease;
    }

    > div {
        padding: 1rem 2rem 2rem 7rem;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-auto-rows: auto;
        grid-gap: 1.5rem;
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    p.none-set {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }
`

export { Styled, StyledContent, StyledHeader }
