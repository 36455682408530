import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput, Toggle } from 'Components/Input'
import { Field } from '../Field'

type RangeConstraintValue = {
    range?: { min?: number | null; max?: number | null } | null
}

type Props = {
    index: number
    onChange: (value: RangeConstraintValue | undefined) => void
    value: RangeConstraintValue | undefined
}

export const RangeConstraint: React.FC<Props> = ({
    index,
    onChange,
    value,
}) => {
    const hasMinSet = typeof value?.range?.min !== 'undefined'
    const hasMaxSet = typeof value?.range?.max !== 'undefined'

    return (
        <StyledRange>
            <Field htmlFor={`bod-field-range-min-${index}`} name="Minimum">
                <Toggle
                    name={`bod-field-range-toggle-min${index}`}
                    id={`bod-field-range-toggle-min${index}`}
                    indicatorText={['', 'No Minimum']}
                    checked={hasMinSet}
                    onCheckedChange={checked =>
                        onChange(
                            checked
                                ? {
                                      range: {
                                          min: 0,
                                          max: value?.range?.max,
                                      },
                                  }
                                : {
                                      range: {
                                          max: value?.range?.max,
                                      },
                                  }
                        )
                    }
                />
                {hasMinSet && (
                    <NumberInput
                        id={`bod-field-range-min-${index}`}
                        value={value?.range?.min ?? 0}
                        onChange={e =>
                            onChange({
                                range: {
                                    min: Number(e.target.value),
                                    max: value?.range?.max,
                                },
                            })
                        }
                    />
                )}
            </Field>
            <Field htmlFor={`bod-field-range-max-${index}`} name="Maximum">
                <Toggle
                    name={`bod-field-range-toggle-max${index}`}
                    id={`bod-field-range-toggle-max${index}`}
                    indicatorText={['', 'No Maximum']}
                    checked={hasMaxSet}
                    onCheckedChange={checked =>
                        onChange(
                            checked
                                ? {
                                      range: {
                                          min: value?.range?.min,
                                          max: 0,
                                      },
                                  }
                                : {
                                      range: {
                                          min: value?.range?.min,
                                      },
                                  }
                        )
                    }
                />
                {hasMaxSet && (
                    <NumberInput
                        id={`bod-field-range-max-${index}`}
                        value={value?.range?.max ?? 0}
                        onChange={e =>
                            onChange({
                                range: {
                                    min: value?.range?.min,
                                    max: Number(e.target.value),
                                },
                            })
                        }
                    />
                )}
            </Field>
        </StyledRange>
    )
}

const StyledRange = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100%;

    > div > div > div:last-of-type {
        margin-left: 0.5rem;
    }
`
