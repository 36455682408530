import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { useCallback } from 'react'
import { StorageService } from '__generated__'
import {
    CreateDocumentMutation,
    useCreateDocumentMutation,
} from './__generated__/mutations'

export const useCreateDocument = ({
    onComplete,
}: {
    onComplete: (data?: CreateDocumentMutation) => void
}) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const [createFileMutation] = useCreateDocumentMutation({
        onCompleted: onComplete,
        onError(e) {
            logger.error('Failed to create document', e)
            triggerToast()
        },
        refetchQueries: ['FetchDocuments'],
    })

    const createDocument = useCallback(
        (fileName: string, fileId: string) => {
            createFileMutation({
                variables: {
                    input: {
                        name: fileName,
                        storageProvider: {
                            id: fileId,
                            service: StorageService.Uploadcare,
                        },
                    },
                },
            })
        },
        [createFileMutation]
    )

    return { createDocument, errorToastOpen: open, setErrorToastOpen: setOpen }
}
