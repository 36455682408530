import { FC, FormEventHandler, useMemo, useState } from 'react'
import { styled } from '../../Adapters/Freestyled'
import { IconButton, TextButton } from '../../Components/Button'
import { ControlledModal } from '../../Components/Modal'
import { Select } from '../../Components/Select'
import { CenteredSpinner } from '../../Components/Spinner'
import { useModalControls } from '../../Hooks'
import { DeepPartial } from '../../Utils/types'
import { TriggerEvent } from '../../__generated__'
import { IntegrationProviders } from '../IntegrationProviders'
import { ParamResolver } from './ParamResolver'
import { useAvailable_Integration_ActionsQuery } from './__generated__/query'

export type CreateIntegrationState = {
    trigger: {
        event: TriggerEvent
        filter: {
            params: Record<string, unknown>
        }
    }
    action: {
        provider: string
        name: string
        params: Record<string, unknown>
    }
}

type Props = {
    initialValues: DeepPartial<CreateIntegrationState>
    onSubmit: (values: CreateIntegrationState) => void
    lockTrigger?: boolean
    additionalActions?: Array<{ onClick: () => void; text: string }>
}

export const IntegrationEditor: FC<Props> = ({
    initialValues,
    onSubmit,
    lockTrigger,
    additionalActions,
}) => {
    const { data, loading } = useAvailable_Integration_ActionsQuery({
        notifyOnNetworkStatusChange: true,
    })

    const providersModal = useModalControls()

    const integrations = useMemo(
        () => data?.availableIntegrations.filter(int => int.connection) ?? [],
        [data]
    )

    const [showErrors, setShowErrors] = useState(false)
    const [state, setState] = useState(initialValues)

    const selectedProvider = integrations.find(
        int => int.name === state.action?.provider
    )
    const selectedAction = selectedProvider?.actions.find(
        action => action.name === state.action?.name
    )

    const handleTriggerEventChanged = (event: TriggerEvent) => {
        setShowErrors(false)
        setState(state => ({
            ...state,
            trigger: {
                ...state.trigger,
                event,
            },
        }))
    }

    const handleActionProviderChanged = (provider: string) => {
        setShowErrors(false)
        setState(state => ({
            ...state,
            action: {
                provider,
                name: undefined,
            },
        }))
    }

    const handleActionNameChanged = (name: string) => {
        setShowErrors(false)
        setState(state => ({
            ...state,
            action: {
                ...state.action,
                name,
                params: undefined,
            },
        }))
    }

    const handleActionParamChanged = (param: string) => (value: unknown) => {
        setShowErrors(false)
        setState(state => ({
            ...state,
            action: {
                ...state.action,
                params: {
                    ...state.action?.params,
                    [param]: value,
                },
            },
        }))
    }

    const validStateOrErrors = useMemo(() => {
        if (!selectedProvider) {
            return {
                isValid: false as const,
                errors: ['No provider selected'],
            }
        }

        if (!selectedAction) {
            return {
                isValid: false as const,
                errors: ['No action selected'],
            }
        }

        const invalidParams = selectedAction?.params
            .filter(param => param.required)
            .filter(param => !state.action?.params?.hasOwnProperty(param.name))

        if (invalidParams.length > 0) {
            return {
                isValid: false as const,
                errors: invalidParams.map(
                    param => `${param.displayName} is required`
                ),
            }
        }

        if (!state.trigger?.event) {
            return {
                isValid: false as const,
                errors: ['No trigger event selected'],
            }
        }

        return {
            isValid: true as const,
            instance: {
                trigger: {
                    event: state.trigger.event,
                    filter: {
                        params: state.trigger.filter?.params ?? {},
                    },
                },
                action: {
                    provider: selectedProvider.name,
                    name: selectedAction.name,
                    params: state.action?.params ?? {},
                },
            },
        }
    }, [selectedAction, selectedProvider, state.action, state.trigger])

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        setShowErrors(false)

        if (validStateOrErrors.isValid) {
            return onSubmit(validStateOrErrors.instance)
        }

        setShowErrors(true)
    }

    return (
        <>
            <ControlledModal
                {...providersModal}
                onOpenChange={providersModal.setOpen}
                title="Connect to a provider"
                description=""
                hideDescription
                renderContent={() => (
                    <StyledModal>
                        <IntegrationProviders />
                    </StyledModal>
                )}
            />
            <StyledForm onSubmit={handleSubmit}>
                {loading ? (
                    <CenteredSpinner />
                ) : (
                    <>
                        {lockTrigger ? null : (
                            <section>
                                <h3>Trigger</h3>
                                <label htmlFor="select-trigger-event">
                                    Event
                                    <Select
                                        id="select-trigger-event"
                                        name="select-trigger-event"
                                        options={[
                                            {
                                                text: 'Record entered phase',
                                                value: TriggerEvent.ProcessBoEnteredPhase,
                                            },
                                        ]}
                                        value={state.trigger?.event}
                                        onValueChange={
                                            handleTriggerEventChanged
                                        }
                                    />
                                </label>
                            </section>
                        )}
                        <section>
                            <h3>Output</h3>
                            <label htmlFor="select-action-integration">
                                Provider
                            </label>
                            <StyledInputButton>
                                <Select
                                    id="select-action-integration"
                                    name="select-action-integration"
                                    options={
                                        integrations.length === 0
                                            ? [
                                                  {
                                                      text: '--- No integrations available ---',
                                                      value: '',
                                                      disabled: true,
                                                  },
                                              ]
                                            : integrations.map(integration => ({
                                                  text: integration.displayName,
                                                  value: integration.name,
                                              }))
                                    }
                                    value={state.action?.provider ?? ''}
                                    onValueChange={handleActionProviderChanged}
                                />

                                <IconButton
                                    iconName="Integrations"
                                    title="Connect to a new provider"
                                    type="button"
                                    onClick={providersModal.openModal}
                                />
                            </StyledInputButton>

                            {selectedProvider && (
                                <label htmlFor="select-action-name">
                                    Action
                                    <Select
                                        id="select-action-name"
                                        name="select-action-name"
                                        options={
                                            selectedProvider.actions
                                                .filter(
                                                    action => !action.hidden
                                                )
                                                .map(action => ({
                                                    text: action.displayName,
                                                    value: action.name,
                                                })) ?? []
                                        }
                                        value={state.action?.name}
                                        onValueChange={handleActionNameChanged}
                                    />
                                </label>
                            )}

                            {selectedProvider &&
                                selectedAction &&
                                selectedAction.params.map(param => {
                                    return (
                                        <section key={param.name}>
                                            <ParamResolver
                                                param={param}
                                                params={
                                                    state.action?.params ?? {}
                                                }
                                                actions={
                                                    selectedProvider?.actions ??
                                                    []
                                                }
                                                value={
                                                    state.action?.params?.[
                                                        param.name
                                                    ] as string | undefined
                                                }
                                                onParamChanged={handleActionParamChanged(
                                                    param.name
                                                )}
                                            />
                                        </section>
                                    )
                                })}
                        </section>
                        {additionalActions?.map(({ onClick, text }) => (
                            <TextButton
                                key={text}
                                type="button"
                                text={text}
                                onClick={onClick}
                                variant="secondary"
                            />
                        ))}
                        <TextButton type="submit" text="Save" />
                        {validStateOrErrors.isValid || !showErrors ? null : (
                            <ul>
                                {validStateOrErrors.errors.map(error => (
                                    <li key={error}>{error}</li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
            </StyledForm>
        </>
    )
}

const StyledForm = styled.form`
    width: 100%;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

const StyledModal = styled.div`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
`

const StyledInputButton = styled.div`
    display: flex;
    flex-direction: row;
`
