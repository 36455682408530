import { useMemo } from 'react'
import slugify from 'slugify'
import { Route } from '../types'
import { useBusinessObjectDefinitionsQuery } from './__generated__/queries'

export const useFetchBusinessObjectRoutes = () => {
    const { data } = useBusinessObjectDefinitionsQuery({
        fetchPolicy: 'cache-and-network',
    })

    const routes = useMemo(() => {
        if (!data) return []

        return data.businessObjectDefinitions.map<Route>(definition => ({
            id: definition.id,
            description: definition.description ?? '',
            name: definition.name,
            slug: slugify(definition.name, { lower: true }),
        }))
    }, [data])

    return { routes, hasInitiallyLoaded: !!data }
}
