import { flex, styled } from 'Adapters/Freestyled'
import { Drawer } from 'Components/Drawer/Drawer'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { OverflowMenu } from './OverflowMenu'
import { StyledCard, StyledImageThumbnail } from './styles'
import { Document } from './types'

type Props = {
    document: Document
}

export const ImageCard: FC<Props> = ({ document }) => {
    const { open, setOpen, returnFocus, openModal, buttonRef } =
        useModalControls()

    return (
        <>
            <StyledCard>
                {/* Clicking the card displays the image in a fullscreen drawer */}
                <StyledImageThumbnail
                    onClick={openModal}
                    as="button"
                    ref={buttonRef}
                >
                    <img src={document.url} alt={document.name} />
                </StyledImageThumbnail>

                <p>{document.name}</p>

                <OverflowMenu document={document} />
            </StyledCard>

            <Drawer
                content={
                    <StyledContainer>
                        <header>
                            <Text as="h1" variant="bold-1">
                                {document.name}
                            </Text>
                        </header>

                        <img src={document.url} alt={document.name} />
                    </StyledContainer>
                }
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledContainer = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    padding: 2rem 3rem 0;
    flex: 1;

    img {
        width: 100%;
        object-fit: contain;
        flex: 1;
    }

    header {
        ${flex('row', 'flex-start', 'flex-start')};
        padding: 2rem 0;
    }
`
