import { logger } from 'Adapters/Logger'
import {
    checkBusinessObjectDefinitionUpdated,
    decodeGroupState,
} from 'Features/BusinessObjectView/Grouping'
import { convertSearchParamsToObject } from 'Features/FilterBusinessObject'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BODFields } from './types'

export const useLatestView = (
    businessObjectDefinitionId: string,
    fields: BODFields
) => {
    const key = `businessObjectListLatestView:${businessObjectDefinitionId}`

    const [params, setUrlSearchParams] = useSearchParams()

    useEffect(() => {
        if (!fields.length) return // Fields required to check if the bodef has been updated which will affect grouping

        if (params.size > 0) {
            try {
                globalThis.localStorage.setItem(
                    key,
                    JSON.stringify(convertSearchParamsToObject(params))
                )
            } catch (error) {
                logger.error(
                    'failed to set latest view state for business object list in local storage',
                    error as Error,
                    { businessObjectDefinitionId }
                )
            }
        } else {
            try {
                const value = globalThis.localStorage.getItem(key)
                const decodedValue = JSON.parse(value || '{}')

                const bodefHasChangedSinceLastEncoded = !decodedValue.grouping
                    ? false
                    : checkBusinessObjectDefinitionUpdated(
                          decodeGroupState(decodedValue.grouping, fields),
                          fields
                      )

                setUrlSearchParams(
                    {
                        ...(decodedValue.filterState && {
                            filterState: decodedValue.filterState,
                        }),
                        ...(decodedValue.display && {
                            display: decodedValue.display,
                        }),
                        ...(decodedValue.selectedFields && {
                            selectedFields: decodedValue.selectedFields,
                        }),
                        ...(decodedValue.sort && {
                            sort: decodedValue.sort,
                        }),
                        ...(decodedValue.grouping &&
                            !bodefHasChangedSinceLastEncoded && {
                                grouping: decodedValue.grouping,
                            }),
                    },
                    { replace: true }
                )
            } catch (error) {
                logger.error(
                    'failed to get latest view state for business object list in local storage',
                    error as Error,
                    { businessObjectDefinitionId }
                )
            }
        }
    }, [businessObjectDefinitionId, params, setUrlSearchParams, key, fields])
}
