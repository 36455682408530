import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Possible_Delegate_ProcessesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Possible_Delegate_ProcessesQuery = { __typename?: 'Query', processes: Array<{ __typename?: 'Process', id: string, name: string, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string }> }> };

export type Possible_Delegate_FieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Possible_Delegate_FieldsQuery = { __typename?: 'Query', businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, fields: Array<{ __typename?: 'BooleanFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'CurrencyFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'DateFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'DocumentFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'EmailFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'ListFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'NumberFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'RelationFieldDefinition', id: string, name: string, type: Types.FieldType, constraints: Array<{ __typename?: 'RelationTypeConstraint', type: Types.FieldConstraintType, types: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }> } | { __typename?: 'RequiredConstraint' }> } | { __typename?: 'SelectFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'TelephoneFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'TextFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'URLFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'UpdatesFieldDefinition', id: string, name: string, type: Types.FieldType } | { __typename?: 'UserFieldDefinition', id: string, name: string, type: Types.FieldType }> }> };


export const Possible_Delegate_ProcessesDocument = gql`
    query Possible_Delegate_Processes {
  processes {
    id
    name
    operatesUpon {
      id
    }
  }
}
    `;

/**
 * __usePossible_Delegate_ProcessesQuery__
 *
 * To run a query within a React component, call `usePossible_Delegate_ProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossible_Delegate_ProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossible_Delegate_ProcessesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePossible_Delegate_ProcessesQuery(baseOptions?: Apollo.QueryHookOptions<Possible_Delegate_ProcessesQuery, Possible_Delegate_ProcessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Possible_Delegate_ProcessesQuery, Possible_Delegate_ProcessesQueryVariables>(Possible_Delegate_ProcessesDocument, options);
      }
export function usePossible_Delegate_ProcessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Possible_Delegate_ProcessesQuery, Possible_Delegate_ProcessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Possible_Delegate_ProcessesQuery, Possible_Delegate_ProcessesQueryVariables>(Possible_Delegate_ProcessesDocument, options);
        }
export type Possible_Delegate_ProcessesQueryHookResult = ReturnType<typeof usePossible_Delegate_ProcessesQuery>;
export type Possible_Delegate_ProcessesLazyQueryHookResult = ReturnType<typeof usePossible_Delegate_ProcessesLazyQuery>;
export type Possible_Delegate_ProcessesQueryResult = Apollo.QueryResult<Possible_Delegate_ProcessesQuery, Possible_Delegate_ProcessesQueryVariables>;
export const Possible_Delegate_FieldsDocument = gql`
    query Possible_Delegate_Fields {
  businessObjectDefinitions {
    id
    fields {
      id
      name
      type
      ... on RelationFieldDefinition {
        constraints {
          ... on RelationTypeConstraint {
            type
            types {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePossible_Delegate_FieldsQuery__
 *
 * To run a query within a React component, call `usePossible_Delegate_FieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossible_Delegate_FieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossible_Delegate_FieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePossible_Delegate_FieldsQuery(baseOptions?: Apollo.QueryHookOptions<Possible_Delegate_FieldsQuery, Possible_Delegate_FieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Possible_Delegate_FieldsQuery, Possible_Delegate_FieldsQueryVariables>(Possible_Delegate_FieldsDocument, options);
      }
export function usePossible_Delegate_FieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Possible_Delegate_FieldsQuery, Possible_Delegate_FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Possible_Delegate_FieldsQuery, Possible_Delegate_FieldsQueryVariables>(Possible_Delegate_FieldsDocument, options);
        }
export type Possible_Delegate_FieldsQueryHookResult = ReturnType<typeof usePossible_Delegate_FieldsQuery>;
export type Possible_Delegate_FieldsLazyQueryHookResult = ReturnType<typeof usePossible_Delegate_FieldsLazyQuery>;
export type Possible_Delegate_FieldsQueryResult = Apollo.QueryResult<Possible_Delegate_FieldsQuery, Possible_Delegate_FieldsQueryVariables>;