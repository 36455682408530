import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Dropdown, DropdownRadioGroup } from 'Components/Dropdown'
import { SimpleToast } from 'Components/Toast'
import { useSetActiveTenant } from 'Hooks/useSetActiveTenant/useSetActiveTenant'
import { FC, useRef } from 'react'

export const TenantSwitch: FC<{
    navigateToOnComplete: string
    active: {
        id: string
        name: string
    }
    tenantOptions: { value: string; label: string }[]
}> = ({ navigateToOnComplete, active, tenantOptions }) => {
    const { setActiveTenant, errorToast } =
        useSetActiveTenant(navigateToOnComplete)

    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <Dropdown
                variant="dark"
                align="start"
                renderOpenDropdownButton={() => (
                    <StyledTrigger
                        ref={buttonRef}
                        icon="DropDown"
                        text={'Set active tenant'}
                        variant="primary"
                        iconRight
                    />
                )}
                renderContent={() => (
                    <DropdownRadioGroup
                        options={tenantOptions}
                        value={active.id}
                        onValueChange={setActiveTenant}
                        groupLabel="Tenants"
                    />
                )}
            />

            <SimpleToast
                {...errorToast}
                description={'Could not switch tenant'}
            />
        </>
    )
}

const StyledTrigger = styled(IconTextButton)`
    .icon > svg {
        --icon-size: 1.5rem;
        width: var(--icon-size);
        height: var(--icon-size);
        min-height: var(--icon-size);
        min-width: var(--icon-size);
        max-height: var(--icon-size);
        max-width: var(--icon-size);
    }
`
