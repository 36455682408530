import { dayjs } from 'Adapters/DayJS'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'

import { FieldType } from '__generated__'
import { orderBy } from 'lodash'
import { match } from 'ts-pattern'

type SortBOs = (sort: {
    by: string
    asc: boolean
}) => (
    bos: BusinessObjectTableItemFragment[]
) => BusinessObjectTableItemFragment[]

export const sortableDynamicColumns: BusinessObjectTableItemFragment['fields'][number]['__typename'][] =
    [
        'BusinessObjectNumberField',
        'BusinessObjectTextField',
        'BusinessObjectCurrencyField',
        'BusinessObjectUserField',
        'BusinessObjectRelationField',
        'BusinessObjectDateField',
    ]

export const sortableFieldTypes: FieldType[] = [
    FieldType.Number,
    FieldType.Text,
    FieldType.Currency,
    FieldType.User,
    FieldType.Relation,
    FieldType.Date,
]

export const sortBos: SortBOs = sort => bos =>
    orderBy(
        bos,
        [
            a =>
                match(sort)
                    .with({ by: 'label' }, () => a.label)
                    .otherwise(({ by }) =>
                        match(a.fields.find(f => f.fieldDefinition.name === by))
                            .with(
                                { __typename: 'BusinessObjectTextField' },
                                field => field.textValue
                            )
                            .with(
                                { __typename: 'BusinessObjectNumberField' },
                                field => field.numValue
                            )
                            .with(
                                { __typename: 'BusinessObjectCurrencyField' },
                                field => field.currencyValue?.amount
                            )
                            .with(
                                { __typename: 'BusinessObjectUserField' },
                                field => field.userValue?.name
                            )
                            .with(
                                { __typename: 'BusinessObjectRelationField' },
                                field => field.relationValue?.label
                            )
                            .with(
                                { __typename: 'BusinessObjectDateField' },
                                field =>
                                    field.dateValue &&
                                    dayjs(field.dateValue).unix()
                            )
                            .otherwise(() => a.label)
                    ),
        ],
        [sort.asc ? 'asc' : 'desc']
    )
