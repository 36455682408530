import { TextAreaInput, TextInput } from 'Components/Input'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { fromEvent } from '../../../../Utils'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useTextConstraints } from './useTextConstraints'

export const TextField: FC<FieldInputProps<'TextFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useTextConstraints({
        textConstraints: field.textConstraints,
    })

    return (
        <Field
            id={field.id}
            name={field.name}
            description={field.description ?? ''}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            {constraints.max && constraints.max <= 70 ? (
                <TextInput
                    name={field.id}
                    id={field.id}
                    required={constraints.required}
                    onChange={pipe(fromEvent, onChange)}
                    value={value ?? undefined}
                />
            ) : (
                <TextAreaInput
                    name={field.id}
                    id={field.id}
                    required={constraints.required}
                    onChange={pipe(fromEvent, onChange)}
                    value={value ?? undefined}
                />
            )}
        </Field>
    )
}
