import { dayjs } from 'Adapters/DayJS'
import {
    has as _has,
    isNull,
    isUndefined,
    upperFirst as _upperFirst,
} from 'lodash'
import { match, P } from 'ts-pattern'
import { logger } from '../Adapters/Logger'
import { FieldType } from '../__generated__'
import { Setter } from './types'

export * from './countryCodeOptions'
export { customModelledFields, fieldLabels } from './fieldLabels'
export * from './oneOfPair'

export const waitForDelay = (delay: number): Promise<void> =>
    new Promise(resolve => setTimeout(() => resolve(), delay))

export const blankArray = (length: number): undefined[] =>
    Array(length).fill(undefined)

export const validateEmail = (input: string): boolean =>
    new RegExp(/\S+@\S+\.\S+/).test(input)

export const parseURL = (input: string) => {
    try {
        return { url: new URL(input), isURL: true as const }
    } catch (err) {
        return { isURL: false as const }
    }
}

export const getPortalsContainer = () =>
    globalThis.document.getElementById('portals')

export const setAt = <T extends object, K extends keyof T>(
    arr: T[],
    index: number,
    prop: K | string,
    setter: Setter<T[K] | unknown>
): T[] => {
    const prev = arr[index][prop as K]
    const newVal = typeof setter === 'function' ? setter(prev) : setter
    const r = [
        ...arr.slice(0, index),
        {
            ...arr[index],
            [prop]: newVal,
        },
        ...arr.slice(index + 1),
    ]

    return r
}

export const removeAt = <T extends object>(arr: T[], index: number) => {
    if (index === 0) {
        return arr.slice(1)
    }
    const r = [...arr.slice(0, index), ...arr.slice(index + 1)]
    return r
}

export const fromEvent = <T>(e: { target: { value: T } }) => e.target.value
export const stopPropagation = <T extends { stopPropagation: () => void }>(e: T ) => e.stopPropagation()
export const preventDefault = <T extends { preventDefault: () => void }>(
    e: T
) => {
    e.preventDefault()
    return e
}

export const replaceEmptyString = (
    str: string | null | undefined,
    replacement = undefined
): string | undefined => {
    if (isUndefined(str) || isNull(str) || str === '') {
        return replacement
    }

    return str
}

export const matchesProp =
    <Prop extends string>(prop: Prop) =>
    <ExactValue>(value: ExactValue) =>
    <T extends Record<Prop, any>, U extends Record<Prop, ExactValue>>(
        a: T
    ): a is T & U =>
        a[prop] === value

export const guardAnd =
    <T, U extends T, V extends T & U>(
        pred1: (a: T) => a is U,
        pred2: (a: T & U) => a is V
    ) =>
    (a: T): a is U & V =>
        pred1(a) && pred2(a)

export const guardOr =
    <T, U extends T, V extends T>(
        pred1: (a: T) => a is U,
        pred2: (a: T) => a is V
    ) =>
    (a: T): a is U | V =>
        pred1(a) || pred2(a)

export const has = <T extends object, K extends string>(
    obj: T,
    key: K
): obj is Extract<T, Record<K, unknown>> & T => _has(obj, key)

/**
 * Curried greater than
 *
 * DO NOT USE lodash/fp gt - it is curried the wrong way round!!!
 * See https://github.com/lodash/lodash/issues/3151
 */
export const gt = (than: number) => (num: number) => num > than

/**
 * Type representation of a string with uppercased first letter.
 * UpperFirst<"hello"> => "Hello"
 */
export type UpperFirst<T extends string> = T extends `${infer U}${infer V}`
    ? `${Uppercase<U>}${V}`
    : Uppercase<T>

export const upperFirst = <T extends string>(s: T): UpperFirst<T> =>
    _upperFirst(s) as UpperFirst<T>

export const formatDate = (date: string) => dayjs(date).format('DD MMM YYYY')

// Inspired by pretty-bytes
export const formatBytes = (number: number) => {
    const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const exponent = Math.min(
        Math.floor(Math.log10(number) / 3),
        UNITS.length - 1
    )

    const convertedNumber = (number /= 1000 ** exponent)

    const numberString = convertedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 1,
    })

    const unit = UNITS[exponent]

    return `${numberString} ${unit}`
}

/**
 * Create a type guard that pulls matching members of a union
 */
export const byDiscriminant =
    <const K extends string, U>(prop: K, val: U) =>
    <T extends { [KK in K]?: string }>(
        a: T
    ): a is Extract<T, { [KK in K]?: U }> =>
        a[prop] === val

export const byTypename =
    <const U>(val: U) =>
    <const T extends { __typename?: string }>(
        a: T
    ): a is Extract<T, { __typename?: U }> =>
        byDiscriminant('__typename', val)(a)

export const _try = <T>(a: () => T, b: T): T => {
    try {
        return a()
    } catch {
        return b
    }
}

type Field = {
    fieldDefinition: {
        __typename?: string
        listOf?: { type: FieldType; name: string }
    }
}

export const isUpdateListField = <T extends Field>(field: T): boolean =>
    field.fieldDefinition.__typename === 'ListFieldDefinition' &&
    field.fieldDefinition.listOf?.type === FieldType.Updates

export const documentToggleFieldListOfName = 'Document (automatic)' // used to distinguish between field created by toggle/manually

export const isDocumentToggleField = <T extends Field>(field: T): boolean =>
    field.fieldDefinition.__typename === 'ListFieldDefinition' &&
    field.fieldDefinition.listOf?.type === FieldType.Document &&
    field.fieldDefinition.listOf?.name === documentToggleFieldListOfName

export const isValidJSONString = (string: string) => {
    try {
        JSON.parse(string)
        return true
    } catch {
        return false
    }
}

export const decodeURIComponentObj = <T extends Record<string, unknown>>(
    input: string
) => {
    try {
        return JSON.parse(decodeURIComponent(input)) as T
    } catch (err) {
        logger.error('failed to parse JSON from URI', err as Error)
        return undefined
    }
}

export const encodeURIComponentObj = <T extends Record<string, unknown>>(
    config: T
) => {
    try {
        return encodeURIComponent(JSON.stringify(config))
    } catch (err) {
        return undefined
    }
}

// Inspired by https://stackoverflow.com/a/63908274
// Rounds a number to the nearest provided step i.e round(0.5)(5.4) => 5.5
export const round =
    (step: number) =>
    (value: number): number =>
        Math.round(value / step) * step

export const opt = <T, U, V>(
    x: T | undefined | null,
    some: (x: T) => U,
    none: () => V
): U | V => match(x).with(P.nullish, none).otherwise(some)
