import { GroupingControl } from 'Components/GroupingControl'
import { MinimalModal } from 'Components/Modal/Minimal'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { DispatchAction, ViewConfig } from '../../types'
import { MenuButton } from './MenuButton'

type Props = {
    config: ViewConfig
    dispatch: DispatchAction
}

const Grouping: FC<Props> = ({ config, dispatch }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <MenuButton
                icon="DotCircle"
                title="Group"
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <Styled
                    grouping={config.grouping}
                    onFieldSelect={selection => {
                        dispatch({
                            type: 'SetGroupingSelection',
                            payload: { selection },
                        })
                    }}
                    onMoveGroupUp={fieldId => groupId => {
                        dispatch({
                            type: 'MoveGroupUp',
                            payload: {
                                fieldId,
                                groupId,
                            },
                        })
                    }}
                    onMoveGroupDown={fieldId => groupId => {
                        dispatch({
                            type: 'MoveGroupDown',
                            payload: {
                                fieldId,
                                groupId,
                            },
                        })
                    }}
                    onSelectGroupColor={fieldId => groupId => color => {
                        dispatch({
                            type: 'SetGroupColor',
                            payload: {
                                fieldId,
                                groupId,
                                color,
                            },
                        })
                    }}
                    onClose={() => setOpen(false)}
                />
            </MinimalModal>
        </>
    )
}

const Styled = styled(GroupingControl)`
    padding: 0.25rem 4vw 1rem;
`

export { Grouping }
