import { useMemo } from 'react'
import { SelectFieldConstraints } from '__generated__'
import { determineSelectUIType } from './helpers'
import { defaultConstraints, SelectConstraintsMap, SelectUI } from './types'

type Params = {
    selectConstraints: SelectFieldConstraints[]
}

export const useSelectConstraints = ({ selectConstraints }: Params) => {
    const constraints = useMemo(() => {
        return selectConstraints.reduce<SelectConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'NumberOfSelectionsConstraint') {
                    return {
                        ...out,
                        min: constraint.min ?? 0,
                        max: constraint.max ?? undefined,
                    }
                }

                return out
            },
            defaultConstraints
        )
    }, [selectConstraints])

    const constraintsTooltip = useMemo(() => {
        const { min, max } = constraints

        if (min === max) {
            if (min === 1 && max === 1) {
                return `Select one`
            }

            return `Select ${min} options`
        }

        if (min && !max) return `Select at least ${min} options`

        if (max && !min) return `Select a maximum of ${max} options`

        if (min && max) {
            return `Select between ${min} and ${max} options`
        }

        return ''
    }, [constraints])

    const UIToRender = useMemo<SelectUI>(
        () => determineSelectUIType(constraints.min, constraints.max),
        [constraints]
    )

    return { constraintsTooltip, UIToRender }
}
