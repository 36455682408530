import {
    AuthParams,
    useAuthenticate,
} from 'Adapters/Authentication/useAuthenticate'
import { FC, useEffect } from 'react'

export const Login: FC<AuthParams> = authParams => {
    const { login } = useAuthenticate()

    useEffect(() => {
        login(authParams)
    }, [login, authParams])

    return null
}
