import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateActionMutationVariables = Types.Exact<{
  input: Types.CreateActionInput;
}>;


export type CreateActionMutation = { __typename?: 'Mutation', createAction: { __typename?: 'CompletedAction', id: string } | { __typename?: 'IncompleteAction', id: string } };

export type ScheduleActionMutationVariables = Types.Exact<{
  input: Types.CreateActionInput;
  schedule: Types.ScheduleInput;
}>;


export type ScheduleActionMutation = { __typename?: 'Mutation', scheduleAction: { __typename?: 'ScheduledEvent', id: string } };


export const CreateActionDocument = gql`
    mutation CreateAction($input: CreateActionInput!) {
  createAction(input: $input) {
    id
  }
}
    `;
export type CreateActionMutationFn = Apollo.MutationFunction<CreateActionMutation, CreateActionMutationVariables>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateActionMutation, CreateActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActionMutation, CreateActionMutationVariables>(CreateActionDocument, options);
      }
export type CreateActionMutationHookResult = ReturnType<typeof useCreateActionMutation>;
export type CreateActionMutationResult = Apollo.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = Apollo.BaseMutationOptions<CreateActionMutation, CreateActionMutationVariables>;
export const ScheduleActionDocument = gql`
    mutation ScheduleAction($input: CreateActionInput!, $schedule: ScheduleInput!) {
  scheduleAction(input: $input, schedule: $schedule) {
    id
  }
}
    `;
export type ScheduleActionMutationFn = Apollo.MutationFunction<ScheduleActionMutation, ScheduleActionMutationVariables>;

/**
 * __useScheduleActionMutation__
 *
 * To run a mutation, you first call `useScheduleActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleActionMutation, { data, loading, error }] = useScheduleActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useScheduleActionMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleActionMutation, ScheduleActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleActionMutation, ScheduleActionMutationVariables>(ScheduleActionDocument, options);
      }
export type ScheduleActionMutationHookResult = ReturnType<typeof useScheduleActionMutation>;
export type ScheduleActionMutationResult = Apollo.MutationResult<ScheduleActionMutation>;
export type ScheduleActionMutationOptions = Apollo.BaseMutationOptions<ScheduleActionMutation, ScheduleActionMutationVariables>;