import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import {
    BusinessObjectInProcessOverviewQuery,
    BusinessObjectPhaseProgressQuery,
} from 'Features/SingleBusinessObjectTabs/Overview/__generated__/query'
import { FC } from 'react'
import { ProgressBar } from './ProgressBar'

type Props = {
    process: BusinessObjectInProcessOverviewQuery['process']
    businessObjectPhaseIndex: number
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    onProgressChange: () => void
    percentageComplete: number
    percentageCompleteLoading: boolean
    actions: BusinessObjectPhaseProgressQuery['actions']
}

export const ProgressSummary: FC<Props> = ({
    process,
    businessObjectPhaseIndex,
    businessObject,
    onProgressChange,
    percentageComplete,
    percentageCompleteLoading,
    actions,
}) => {
    return (
        <StyledProgressSummary>
            <header>
                <Text as="h2" variant="bold-4">
                    Progress Summary
                </Text>
            </header>
            <ProgressBar
                process={process}
                businessObjectPhaseIndex={businessObjectPhaseIndex}
                businessObject={businessObject}
                onProgressChange={onProgressChange}
                percentageComplete={percentageComplete}
                percentageCompleteLoading={percentageCompleteLoading}
                actions={actions}
            />
        </StyledProgressSummary>
    )
}

const StyledProgressSummary = styled.section`
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 1.5rem;

    h2 {
        padding-bottom: 1rem;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        border: 0;
        border-radius: 0;
        padding: 1rem 0 0.5rem;

        header {
            padding: 0 4vw;
        }
    }
`
