import { Operator } from '../../__generated__'
import { Select } from '../Select'

const operatorOptions = {
    equals: {
        label: 'Equals',
        operator: Operator.Equals,
        negate: false,
    },
    doesNotEqual: {
        label: 'Does not equal',
        operator: Operator.Equals,
        negate: true,
    },
    greaterThan: {
        label: 'Greater than',
        operator: Operator.GreaterThan,
        negate: false,
    },
    lessThan: {
        label: 'Less than',
        operator: Operator.LessThan,
        negate: false,
    },
    includes: {
        label: 'Includes',
        operator: Operator.Includes,
        negate: false,
    },
    doesNotInclude: {
        label: 'Does not include',
        operator: Operator.Includes,
        negate: true,
    },
    isDefined: {
        label: 'Is defined',
        operator: Operator.IsDefined,
        negate: false,
    },
    isNotDefined: {
        label: 'Is not defined',
        operator: Operator.IsDefined,
        negate: true,
    },
}

type OperatorOption = keyof typeof operatorOptions

const allowedOperators: Record<Operator, OperatorOption[]> = {
    [Operator.Equals]: ['equals', 'doesNotEqual'],
    [Operator.GreaterThan]: ['greaterThan'],
    [Operator.LessThan]: ['lessThan'],
    [Operator.Includes]: ['includes', 'doesNotInclude'],
    [Operator.IsDefined]: ['isDefined', 'isNotDefined'],
}

type Props = {
    id: string
    onOperatorChanged: (operator: Operator, negate?: boolean) => void
    operator: Operator | undefined
    negate: boolean | undefined
    operators: Operator[]
    disabled?: boolean
}

export const OperatorSelector: React.FC<Props> = ({
    id,
    onOperatorChanged,
    operator,
    operators,
    disabled,
    negate,
}) => {
    return (
        <Select
            id={id}
            value={
                Object.entries(operatorOptions).find(
                    ([_, val]) =>
                        val.operator === operator && val.negate === negate
                )?.[0] as OperatorOption
            }
            name="operator"
            options={operators
                .flatMap(op => allowedOperators[op])
                .map(op => ({ text: operatorOptions[op].label, value: op }))}
            onValueChange={(value: OperatorOption) => {
                onOperatorChanged(
                    operatorOptions[value].operator,
                    operatorOptions[value].negate
                )
            }}
            disabled={disabled}
        />
    )
}
