import { styled } from 'Adapters/Freestyled'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownRadioGroup,
} from 'Components/Dropdown'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import { CreateTenantModal } from 'Features/CreateTenantModal'
import { useModalControls } from 'Hooks'
import { useSandbox } from 'Hooks/useSandbox'
import { useSetActiveTenant } from 'Hooks/useSetActiveTenant/useSetActiveTenant'
import { FC, useRef } from 'react'
import { useWorkspaceControlQuery } from './__generated__/q'
import { strings } from './strings'

type Props = {
    open: boolean
    setOpen: (open: boolean) => void
}

const WorkspaceControl: FC<Props> = ({ open, setOpen }) => {
    const { data } = useWorkspaceControlQuery()
    const { setActiveTenant, errorToast } = useSetActiveTenant()
    const {
        open: createTenantOpen,
        setOpen: setCreateTenantOpen,
        closeModal,
    } = useModalControls()
    const createTenantBtnRef = useRef<HTMLDivElement>(null)
    const { isSandbox } = useSandbox()

    return (
        <>
            <SimpleToast {...errorToast} description={strings.errorMessage} />
            <Dropdown
                variant="dark"
                align="center"
                open={open}
                onOpenChange={setOpen}
                renderOpenDropdownButton={() => (
                    <StyledOpenButton
                        ref={createTenantBtnRef}
                        tabIndex={1}
                        onClick={() => {
                            setOpen(true)
                        }}
                        data-open={open}
                    >
                        <Text as="p" variant="bold-4">
                            {data?.currentUser.activeTenant?.tenant.name ??
                                '...'}
                        </Text>
                        <Text as="p" variant="bold-6">
                            {strings.tenant}
                        </Text>
                        <Icon name="DropDown" />
                    </StyledOpenButton>
                )}
                renderContent={() => (
                    <>
                        {data && (
                            <DropdownRadioGroup
                                options={data.currentUser.tenancies.map(
                                    ({ tenant: { id, name } }) => ({
                                        value: id,
                                        label: name,
                                    })
                                )}
                                value={
                                    data.currentUser.activeTenant?.tenant.id ??
                                    ''
                                }
                                onValueChange={setActiveTenant}
                            />
                        )}
                        <DropdownDivider />
                        <DropdownButton
                            icon="Plus"
                            text={'Create new workspace'}
                            title={
                                isSandbox ? 'Disabled in sandbox' : undefined
                            }
                            disabled={isSandbox}
                            onSelect={() => setCreateTenantOpen(true)}
                            closeOnSelect
                        />
                    </>
                )}
            />
            <CreateTenantModal
                open={createTenantOpen}
                close={closeModal}
                onOpenChange={setCreateTenantOpen}
                returnFocus={() => createTenantBtnRef.current?.focus()}
            />
        </>
    )
}

const StyledOpenButton = styled.div`
    text-decoration: none;
    display: grid;
    grid-template: auto auto / 1fr auto;
    align-items: center;
    text-align: left;
    padding: 1rem 1.25rem;
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['03'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['03'].active};
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    p:first-child {
        color: ${({ theme }) => theme.palette.text['01'].normal};
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    p:not(:first-child) {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    .icon {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        transition: transform
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    &[data-open='true'] .icon {
        transform: rotateX(180deg);
    }
`

export { WorkspaceControl }
