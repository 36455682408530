import { useQueryNav } from 'Hooks/useQueryNav'
import { tabs } from './config'

type UseBusinessObjectDisplay = (_: { key: string; processId?: string }) => {
    isOpen: boolean
    open: (_: { id: string; tab: keyof typeof tabs }) => void
    close: () => void
    id: string | undefined
    tab: keyof typeof tabs
    onSelect: (tab: keyof typeof tabs) => void
    processId?: string
}

const useBusinessObjectDisplay: UseBusinessObjectDisplay = ({
    key,
    processId,
}) => {
    const { values, nav, remove } = useQueryNav<
        ['id', 'tab'],
        {
            id: string
            tab: keyof typeof tabs
        }
    >({ key, path: ['id', 'tab'] })

    return {
        open: ({ id, tab }) => nav({ id, tab }),
        close: remove,
        isOpen: !!values,
        id: values?.id,
        tab: values?.tab || 'details',
        onSelect: (tab: keyof typeof tabs) => nav({ tab }),
        processId,
    }
}

export { useBusinessObjectDisplay }
