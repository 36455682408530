import { useEffect, useMemo } from 'react'

import { useBusinessObjectById_SetBusinessObjectLazyQuery } from './__generated__/query'

export const useBusinessObjectDetails = (
    businessObjectId: string | undefined
) => {
    const [query, { data }] = useBusinessObjectById_SetBusinessObjectLazyQuery()

    useEffect(() => {
        if (!businessObjectId) return

        query({
            variables: { input: { id: businessObjectId } },
        })
    }, [businessObjectId, query])

    const businessObjectLabel = useMemo(() => {
        if (!businessObjectId || !data) return undefined
        return data.businessObject.label
    }, [businessObjectId, data])

    const businessObjectDefinitionId = useMemo(() => {
        if (!businessObjectId || !data) return undefined
        return data.businessObject.definition.id
    }, [businessObjectId, data])

    return { businessObjectLabel, businessObjectDefinitionId }
}
