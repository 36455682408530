import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Basics } from './Basics'
import { Fields } from './Fields'
import { DispatchAction, State } from './types'

type Props = {
    state: State
    dispatch: DispatchAction
}

const BusinessObjectDefinitionEditor: FC<Props> = ({
    state: {
        definition,
        enableUpdates,
        updatesLocked,
        enableDocuments,
        documentsLocked,
        customLabel,
        fieldEditorOpen,
        addFieldOpen,
        relationOptions,
        userOptions,
        currencyOptions,
        validation,
        mode,
    },
    dispatch,
}) => (
    <Styled>
        <Basics
            definition={definition}
            customLabel={customLabel}
            enableUpdates={enableUpdates}
            updatesLocked={updatesLocked}
            enableDocuments={enableDocuments}
            documentsLocked={documentsLocked}
            validation={validation}
            dispatch={dispatch}
        />
        <Fields
            definition={definition}
            fieldEditorOpen={fieldEditorOpen}
            addFieldOpen={addFieldOpen}
            dispatch={dispatch}
            relationOptions={relationOptions}
            userOptions={userOptions}
            currencyOptions={currencyOptions}
            validation={validation}
            mode={mode}
        />
    </Styled>
)

const Styled = styled.div`
    display: grid;
    grid-template: 1fr / 1fr 2fr;
    overflow: hidden;
`

export { BusinessObjectDefinitionEditor }
