import { FieldCommonWrapper } from './Common'
import { RelationTypeConstraint } from './Constraints/RelationType'
import { RequiredConstraint } from './Constraints/Required'
import { FieldProps } from './types'

export const RelationFieldDefinitionInput: React.FC<FieldProps<'relation'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => (
    <FieldCommonWrapper
        field={field}
        index={index}
        onChange={onChange}
        onRemoved={onRemoved}
        label={name}
    >
        {disableRequiredConstraint ? null : (
            <RequiredConstraint
                index={index}
                value={field.constraints.find(
                    constraint => constraint.required
                )}
                onChange={constraint =>
                    onChange('constraints')(prev =>
                        constraint?.required
                            ? [...prev, constraint]
                            : prev.filter(c => !c.required)
                    )
                }
            />
        )}
        <RelationTypeConstraint
            index={index}
            value={field.constraints.find(
                constraint => constraint.relationType
            )}
            onChange={constraint =>
                onChange('constraints')(prev =>
                    constraint?.relationType
                        ? [...prev.filter(c => !c.relationType), constraint]
                        : prev.filter(c => !c.relationType)
                )
            }
        />
    </FieldCommonWrapper>
)
