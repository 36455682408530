import { CenteredSpinner } from 'Components/Spinner'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { FC } from 'react'
import { useFetchBusinessObjectDefinitionFieldsById } from '../../../Hooks'
import { useLatestView } from '../../../Hooks/useLatestView'
import { ListView } from './ListView'

type Props = {
    businessObjectDefinitionId: string
    name: string
    description: string
}

export const BusinessObject: FC<Props> = ({
    businessObjectDefinitionId,
    description,
    name,
}) => {
    const boDisplayProps = useBusinessObjectDisplay({ key: 'business-object' })

    const { fields, hasInitiallyLoaded } =
        useFetchBusinessObjectDefinitionFieldsById({
            id: businessObjectDefinitionId,
        })

    useLatestView(businessObjectDefinitionId, fields)

    return hasInitiallyLoaded ? (
        <>
            <ListView
                name={name}
                description={description}
                id={businessObjectDefinitionId}
                fields={fields}
            />
            <BusinessObjectDisplay
                {...boDisplayProps}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        </>
    ) : (
        <CenteredSpinner />
    )
}
