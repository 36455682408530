import { FC } from 'react'
import { match, P } from 'ts-pattern'
import { useProcessCreator } from '../../useProcessCreator'
import { ActionCriterion } from './ActionCriterion'
import { FieldConditionCriterion } from './FieldConditionCriterion'
import { SubProcessCriterion } from './SubProcessCriterion'

type Props = {
    phaseIndex: number
    criterionIndex: number
}

const Criterion: FC<Props> = ({ phaseIndex, criterionIndex }) => {
    const {
        state: { input },
    } = useProcessCreator()

    return match(input.phases[phaseIndex].criteria[criterionIndex])
        .with({ action: P.not(P.nullish) }, criterion => (
            <ActionCriterion
                criterion={criterion}
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />
        ))
        .with({ fieldCondition: P.not(P.nullish) }, criterion => (
            <FieldConditionCriterion
                criterion={criterion}
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />
        ))
        .with({ processFanout: P.not(P.nullish) }, criterion => (
            <SubProcessCriterion
                criterion={criterion}
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />
        ))
        .with({ processDelegate: P.not(P.nullish) }, criterion => (
            <SubProcessCriterion
                criterion={criterion}
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />
        ))
        .otherwise(() => null)
}

export { Criterion }
