import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { useProcessEditor } from '../../useProcessEditor'
import { AddActionCriterionModal } from './AddActionCriterionModal'
import { AddFieldConditionCriterionModal } from './AddFieldConditionCriterionModal'
import { AddProcessFanoutCriterionModal } from './SubProcessCriterionModal'

type Props = {
    phaseIndex: number
    onCriterionAdded: () => void
}

export const AddCriterion: FC<Props> = ({ phaseIndex, onCriterionAdded }) => {
    const {
        dispatch,
        state: { process },
    } = useProcessEditor()

    const actionModalControls = useModalControls()
    const fieldConditionModalControls = useModalControls()
    const subProcessModalControls = useModalControls()

    const businessObjectDefinitionId = process.operatesUpon[0].id

    return (
        <footer>
            <StyledButton
                icon="Plus"
                text="Action"
                size="xSmall"
                onClick={actionModalControls.openModal}
                ref={actionModalControls.buttonRef}
            />
            <StyledButton
                icon="Plus"
                text="Required field"
                size="xSmall"
                onClick={fieldConditionModalControls.openModal}
                ref={fieldConditionModalControls.buttonRef}
            />
            <StyledButton
                icon="Plus"
                text="Sub-workflow"
                size="xSmall"
                onClick={subProcessModalControls.openModal}
                ref={subProcessModalControls.buttonRef}
            />

            <AddActionCriterionModal
                onSave={(description, defaultDueSeconds, conditions) => {
                    dispatch({
                        type: 'addActionCriterion',
                        payload: {
                            phaseIndex,
                            description: description as string,
                            defaultDueSeconds,
                            conditions,
                        },
                    })
                    onCriterionAdded()
                }}
                open={actionModalControls.open}
                onOpenChange={actionModalControls.setOpen}
                returnFocus={actionModalControls.returnFocus}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />

            <AddFieldConditionCriterionModal
                onSave={(fieldId, conditions) => {
                    dispatch({
                        type: 'addFieldConditionCriterion',
                        payload: {
                            phaseIndex,
                            fieldId,
                            conditions,
                        },
                    })
                    onCriterionAdded()
                }}
                open={fieldConditionModalControls.open}
                onOpenChange={fieldConditionModalControls.setOpen}
                returnFocus={fieldConditionModalControls.returnFocus}
                businessObjectDefinitionId={businessObjectDefinitionId}
                phaseIndex={phaseIndex}
            />

            <AddProcessFanoutCriterionModal
                onSave={(process, conditions, type: 'Fanout' | 'Delegate') => {
                    dispatch({
                        type: `addProcess${type}Criterion`,
                        payload: {
                            phaseIndex,
                            process,
                            conditions,
                        },
                    })
                    onCriterionAdded()
                }}
                open={subProcessModalControls.open}
                onOpenChange={subProcessModalControls.setOpen}
                returnFocus={subProcessModalControls.returnFocus}
                phaseIndex={phaseIndex}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        </footer>
    )
}

const StyledButton = styled(IconTextButton)`
    width: 100%;
    justify-content: flex-start;
`
