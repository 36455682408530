import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { iconMap, isKnownFileType } from './iconMap'
import GenericFileIcon from './icons/file-generic.svg?react'

type Props = {
    name: string
}

export const FileIcon: FC<Props> = ({ name }) => {
    const Icon = isKnownFileType(name) ? iconMap[name].icon : GenericFileIcon

    return <StyledIcon>{<Icon title={name} />}</StyledIcon>
}

export const StyledIcon = styled.span`
    display: block;

    svg {
        min-width: 2rem;
        min-height: 2rem;
        display: block;
    }
`
