import { useMemo } from 'react'
import { RelationTypeConstraint } from '__generated__/'
import { FieldInputProps } from '../../types'

export const useRelationConstraints = (
    relationContraints: FieldInputProps<'RelationFieldDefinition'>['field']['relationConstraints']
) => {
    const constraints = useMemo(() => {
        const relation = relationContraints.find(
            contraint => contraint.__typename === 'RelationTypeConstraint'
        ) as RelationTypeConstraint | undefined

        return {
            required: !!relationContraints.find(
                contraint => contraint.__typename === 'RequiredConstraint'
            ),
            relationId: relation?.types[0]?.id as string, // unsafe at type-level, but is okay...,
        }
    }, [relationContraints])

    const constraintsTooltip = useMemo(() => {
        if (!constraints.required) return ''
        return 'Required.'
    }, [constraints])

    return {
        constraints,
        constraintsTooltip,
    }
}
