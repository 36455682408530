import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { TelephoneFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'

type Props = {
    field: TelephoneFieldUnique
}

const TelephoneFieldContent: FC<Props> = ({ field: { defaultValue } }) => (
    <Styled>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Default country code:
                </Text>
                <Text as="p" variant="regular-5">
                    {defaultValue.countryCode || '-'}
                </Text>
            </div>
        </StyledConstraint>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Default number:
                </Text>
                <Text as="p" variant="regular-5">
                    {defaultValue.number || '-'}
                </Text>
            </div>
        </StyledConstraint>
    </Styled>
)

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledConstraint = styled.div`
    > p {
        padding-bottom: 0.5rem;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    div p:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { TelephoneFieldContent }
