import {
    useLinkToolbarButton,
    useLinkToolbarButtonState,
} from '@udecode/plate-link'
import { Tooltip } from 'Components/Tooltip'
import { StyledPressableIconButton } from '../styles'

const modKey = navigator.userAgent.includes('Mac') ? 'CMD' : 'CTRL'

export const Link = () => {
    const state = useLinkToolbarButtonState()
    const { props } = useLinkToolbarButton(state)

    return (
        <Tooltip
            content={
                <span>
                    Link : <kbd>{modKey} + K</kbd>
                </span>
            }
        >
            <StyledPressableIconButton
                iconName={'Link'}
                $pressed={props.pressed}
                variant="secondary"
                type="button"
                onClick={props.onClick}
            />
        </Tooltip>
    )
}
