import { Toggle } from 'Components/Input'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'

export const BooleanField: FC<BODField<'BooleanFieldDefinition'>> = ({
    id,
    name,
    description,
    type,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    return (
        <Field id={id} name={name} description={description ?? ''}>
            <Toggle
                name={type + '||' + id}
                id={id}
                checked={state[id].value as boolean}
                onCheckedChange={bool => {
                    dispatch({
                        type: 'updateBooleanField',
                        payload: { id, value: bool },
                    })
                }}
            />
        </Field>
    )
}
