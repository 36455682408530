import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
    processName: string
    phaseName: string
}
export const EnteredPhaseHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    processName,
    phaseName,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> moved this record to the{' '}
                <strong>{phaseName}</strong> phase in the{' '}
                <strong>{processName}</strong> workflow.
            </Text>
        </BaseHistoryEntry>
    )
}
