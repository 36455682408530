import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextAreaInput, TextInput } from 'Components/Input'
import { regular5 } from 'Components/Text'
import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { compact, isEqual, uniq } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../../../EditFieldButton'
import { useUpdateBusinessObjectField } from '../../../useUpdateBusinessObjectField'
import { ListValue } from '../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useTextConstraints } from './useTextConstraints'

type Props = BODField<'TextFieldDefinition'> & {
    businessObjectId: string
    listName: string
    listId: string
    listValue: ListValue
    onComplete: () => void
}

const generateInitialValues = (listValue: ListValue): string[] =>
    compact(
        listValue.map(field =>
            match(field)
                .with(
                    { __typename: 'BusinessObjectTextField' },
                    ({ textValue }) => textValue ?? ''
                )
                .otherwise(() => null)
        )
    )

export const TextListField: FC<Props> = ({
    businessObjectId,
    textConstraints,
    type,
    listName,
    listId,
    listValue,
    onComplete,
}) => {
    const { constraints, constraintsTooltip } = useTextConstraints({
        textConstraints,
    })

    const [currentValue, setCurrentValue] = useState('')

    const [initialValues, setInitialValues] = useState(
        generateInitialValues(listValue)
    )
    const [values, setValues] = useState(initialValues)

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const hasChanged = useMemo(() => {
        if (!isEqual(initialValues, values)) return true
        return false
    }, [values, initialValues])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
            list={values.map((url, i) => (
                <StyledListItem key={url + i}>
                    <span>{url}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev => prev.filter(link => link !== url))
                        }}
                    />
                </StyledListItem>
            ))}
        >
            {constraints.max && constraints.max <= 70 ? (
                <TextInput
                    name={type + '||' + listId}
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    id={listId}
                    placeholder="Type some text and press enter"
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            setValues(prev => uniq([...prev, currentValue]))
                            setCurrentValue('')
                        }
                    }}
                />
            ) : (
                <TextAreaInput
                    name={type + '||' + listId}
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    id={listId}
                    placeholder="Type some text and press enter"
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            setValues(prev => uniq([...prev, currentValue]))
                            setCurrentValue('')
                        }
                    }}
                />
            )}

            <EditFieldButton
                disabled={!hasChanged || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            list: {
                                fieldDefinitionId: listId,
                                previousValue: initialValues.map(value => ({
                                    text: value,
                                })),
                                value: values.map(value => ({
                                    text: value,
                                })),
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            setInitialValues(values)
                            onComplete()
                        },
                    })
                }}
            />
        </ListFieldWrapper>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > span {
        overflow-wrap: break-word;
        word-break: break-all;
    }

    > button {
        margin-left: auto;
    }
`
