import { SelectOption } from 'Components/Select/types'

// From https://gist.github.com/anubhavshrimal/75f6183458db8c453306f93521e93d37
export const countryCodeOptions: SelectOption[] = [
    {
        text: '-',
        value: '',
        disabled: false,
    },
    {
        text: '+1',
        value: '+1',
        disabled: false,
    },
    {
        text: '+7',
        value: '+7',
        disabled: false,
    },
    {
        text: '+20',
        value: '+20',
        disabled: false,
    },
    {
        text: '+27',
        value: '+27',
        disabled: false,
    },
    {
        text: '+30',
        value: '+30',
        disabled: false,
    },
    {
        text: '+31',
        value: '+31',
        disabled: false,
    },
    {
        text: '+32',
        value: '+32',
        disabled: false,
    },
    {
        text: '+33',
        value: '+33',
        disabled: false,
    },
    {
        text: '+34',
        value: '+34',
        disabled: false,
    },
    {
        text: '+36',
        value: '+36',
        disabled: false,
    },
    {
        text: '+39',
        value: '+39',
        disabled: false,
    },
    {
        text: '+40',
        value: '+40',
        disabled: false,
    },
    {
        text: '+41',
        value: '+41',
        disabled: false,
    },
    {
        text: '+43',
        value: '+43',
        disabled: false,
    },
    {
        text: '+44',
        value: '+44',
        disabled: false,
    },
    {
        text: '+45',
        value: '+45',
        disabled: false,
    },
    {
        text: '+46',
        value: '+46',
        disabled: false,
    },
    {
        text: '+47',
        value: '+47',
        disabled: false,
    },
    {
        text: '+48',
        value: '+48',
        disabled: false,
    },
    {
        text: '+49',
        value: '+49',
        disabled: false,
    },
    {
        text: '+51',
        value: '+51',
        disabled: false,
    },
    {
        text: '+52',
        value: '+52',
        disabled: false,
    },
    {
        text: '+53',
        value: '+53',
        disabled: false,
    },
    {
        text: '+54',
        value: '+54',
        disabled: false,
    },
    {
        text: '+55',
        value: '+55',
        disabled: false,
    },
    {
        text: '+56',
        value: '+56',
        disabled: false,
    },
    {
        text: '+57',
        value: '+57',
        disabled: false,
    },
    {
        text: '+58',
        value: '+58',
        disabled: false,
    },
    {
        text: '+60',
        value: '+60',
        disabled: false,
    },
    {
        text: '+61',
        value: '+61',
        disabled: false,
    },
    {
        text: '+62',
        value: '+62',
        disabled: false,
    },
    {
        text: '+63',
        value: '+63',
        disabled: false,
    },
    {
        text: '+64',
        value: '+64',
        disabled: false,
    },
    {
        text: '+65',
        value: '+65',
        disabled: false,
    },
    {
        text: '+66',
        value: '+66',
        disabled: false,
    },
    {
        text: '+77',
        value: '+77',
        disabled: false,
    },
    {
        text: '+81',
        value: '+81',
        disabled: false,
    },
    {
        text: '+82',
        value: '+82',
        disabled: false,
    },
    {
        text: '+84',
        value: '+84',
        disabled: false,
    },
    {
        text: '+86',
        value: '+86',
        disabled: false,
    },
    {
        text: '+90',
        value: '+90',
        disabled: false,
    },
    {
        text: '+91',
        value: '+91',
        disabled: false,
    },
    {
        text: '+92',
        value: '+92',
        disabled: false,
    },
    {
        text: '+93',
        value: '+93',
        disabled: false,
    },
    {
        text: '+94',
        value: '+94',
        disabled: false,
    },
    {
        text: '+95',
        value: '+95',
        disabled: false,
    },
    {
        text: '+98',
        value: '+98',
        disabled: false,
    },
    {
        text: '+211',
        value: '+211',
        disabled: false,
    },
    {
        text: '+212',
        value: '+212',
        disabled: false,
    },
    {
        text: '+213',
        value: '+213',
        disabled: false,
    },
    {
        text: '+216',
        value: '+216',
        disabled: false,
    },
    {
        text: '+218',
        value: '+218',
        disabled: false,
    },
    {
        text: '+220',
        value: '+220',
        disabled: false,
    },
    {
        text: '+221',
        value: '+221',
        disabled: false,
    },
    {
        text: '+222',
        value: '+222',
        disabled: false,
    },
    {
        text: '+223',
        value: '+223',
        disabled: false,
    },
    {
        text: '+224',
        value: '+224',
        disabled: false,
    },
    {
        text: '+225',
        value: '+225',
        disabled: false,
    },
    {
        text: '+226',
        value: '+226',
        disabled: false,
    },
    {
        text: '+227',
        value: '+227',
        disabled: false,
    },
    {
        text: '+228',
        value: '+228',
        disabled: false,
    },
    {
        text: '+229',
        value: '+229',
        disabled: false,
    },
    {
        text: '+230',
        value: '+230',
        disabled: false,
    },
    {
        text: '+231',
        value: '+231',
        disabled: false,
    },
    {
        text: '+232',
        value: '+232',
        disabled: false,
    },
    {
        text: '+233',
        value: '+233',
        disabled: false,
    },
    {
        text: '+234',
        value: '+234',
        disabled: false,
    },
    {
        text: '+235',
        value: '+235',
        disabled: false,
    },
    {
        text: '+236',
        value: '+236',
        disabled: false,
    },
    {
        text: '+237',
        value: '+237',
        disabled: false,
    },
    {
        text: '+238',
        value: '+238',
        disabled: false,
    },
    {
        text: '+239',
        value: '+239',
        disabled: false,
    },
    {
        text: '+240',
        value: '+240',
        disabled: false,
    },
    {
        text: '+241',
        value: '+241',
        disabled: false,
    },
    {
        text: '+242',
        value: '+242',
        disabled: false,
    },
    {
        text: '+243',
        value: '+243',
        disabled: false,
    },
    {
        text: '+244',
        value: '+244',
        disabled: false,
    },
    {
        text: '+245',
        value: '+245',
        disabled: false,
    },
    {
        text: '+246',
        value: '+246',
        disabled: false,
    },
    {
        text: '+248',
        value: '+248',
        disabled: false,
    },
    {
        text: '+249',
        value: '+249',
        disabled: false,
    },
    {
        text: '+250',
        value: '+250',
        disabled: false,
    },
    {
        text: '+251',
        value: '+251',
        disabled: false,
    },
    {
        text: '+252',
        value: '+252',
        disabled: false,
    },
    {
        text: '+253',
        value: '+253',
        disabled: false,
    },
    {
        text: '+254',
        value: '+254',
        disabled: false,
    },
    {
        text: '+255',
        value: '+255',
        disabled: false,
    },
    {
        text: '+256',
        value: '+256',
        disabled: false,
    },
    {
        text: '+257',
        value: '+257',
        disabled: false,
    },
    {
        text: '+258',
        value: '+258',
        disabled: false,
    },
    {
        text: '+260',
        value: '+260',
        disabled: false,
    },
    {
        text: '+261',
        value: '+261',
        disabled: false,
    },
    {
        text: '+262',
        value: '+262',
        disabled: false,
    },
    {
        text: '+263',
        value: '+263',
        disabled: false,
    },
    {
        text: '+264',
        value: '+264',
        disabled: false,
    },
    {
        text: '+265',
        value: '+265',
        disabled: false,
    },
    {
        text: '+266',
        value: '+266',
        disabled: false,
    },
    {
        text: '+267',
        value: '+267',
        disabled: false,
    },
    {
        text: '+268',
        value: '+268',
        disabled: false,
    },
    {
        text: '+269',
        value: '+269',
        disabled: false,
    },
    {
        text: '+290',
        value: '+290',
        disabled: false,
    },
    {
        text: '+291',
        value: '+291',
        disabled: false,
    },
    {
        text: '+297',
        value: '+297',
        disabled: false,
    },
    {
        text: '+298',
        value: '+298',
        disabled: false,
    },
    {
        text: '+299',
        value: '+299',
        disabled: false,
    },
    {
        text: '+ 345',
        value: '+ 345',
        disabled: false,
    },
    {
        text: '+350',
        value: '+350',
        disabled: false,
    },
    {
        text: '+351',
        value: '+351',
        disabled: false,
    },
    {
        text: '+352',
        value: '+352',
        disabled: false,
    },
    {
        text: '+353',
        value: '+353',
        disabled: false,
    },
    {
        text: '+354',
        value: '+354',
        disabled: false,
    },
    {
        text: '+355',
        value: '+355',
        disabled: false,
    },
    {
        text: '+356',
        value: '+356',
        disabled: false,
    },
    {
        text: '+357',
        value: '+357',
        disabled: false,
    },
    {
        text: '+358',
        value: '+358',
        disabled: false,
    },
    {
        text: '+359',
        value: '+359',
        disabled: false,
    },
    {
        text: '+370',
        value: '+370',
        disabled: false,
    },
    {
        text: '+371',
        value: '+371',
        disabled: false,
    },
    {
        text: '+372',
        value: '+372',
        disabled: false,
    },
    {
        text: '+373',
        value: '+373',
        disabled: false,
    },
    {
        text: '+374',
        value: '+374',
        disabled: false,
    },
    {
        text: '+375',
        value: '+375',
        disabled: false,
    },
    {
        text: '+376',
        value: '+376',
        disabled: false,
    },
    {
        text: '+377',
        value: '+377',
        disabled: false,
    },
    {
        text: '+378',
        value: '+378',
        disabled: false,
    },
    {
        text: '+379',
        value: '+379',
        disabled: false,
    },
    {
        text: '+380',
        value: '+380',
        disabled: false,
    },
    {
        text: '+381',
        value: '+381',
        disabled: false,
    },
    {
        text: '+382',
        value: '+382',
        disabled: false,
    },
    {
        text: '+385',
        value: '+385',
        disabled: false,
    },
    {
        text: '+386',
        value: '+386',
        disabled: false,
    },
    {
        text: '+387',
        value: '+387',
        disabled: false,
    },
    {
        text: '+389',
        value: '+389',
        disabled: false,
    },
    {
        text: '+420',
        value: '+420',
        disabled: false,
    },
    {
        text: '+421',
        value: '+421',
        disabled: false,
    },
    {
        text: '+423',
        value: '+423',
        disabled: false,
    },
    {
        text: '+500',
        value: '+500',
        disabled: false,
    },
    {
        text: '+501',
        value: '+501',
        disabled: false,
    },
    {
        text: '+502',
        value: '+502',
        disabled: false,
    },
    {
        text: '+503',
        value: '+503',
        disabled: false,
    },
    {
        text: '+504',
        value: '+504',
        disabled: false,
    },
    {
        text: '+505',
        value: '+505',
        disabled: false,
    },
    {
        text: '+506',
        value: '+506',
        disabled: false,
    },
    {
        text: '+507',
        value: '+507',
        disabled: false,
    },
    {
        text: '+508',
        value: '+508',
        disabled: false,
    },
    {
        text: '+509',
        value: '+509',
        disabled: false,
    },
    {
        text: '+590',
        value: '+590',
        disabled: false,
    },
    {
        text: '+591',
        value: '+591',
        disabled: false,
    },
    {
        text: '+593',
        value: '+593',
        disabled: false,
    },
    {
        text: '+594',
        value: '+594',
        disabled: false,
    },
    {
        text: '+595',
        value: '+595',
        disabled: false,
    },
    {
        text: '+596',
        value: '+596',
        disabled: false,
    },
    {
        text: '+597',
        value: '+597',
        disabled: false,
    },
    {
        text: '+598',
        value: '+598',
        disabled: false,
    },
    {
        text: '+599',
        value: '+599',
        disabled: false,
    },
    {
        text: '+670',
        value: '+670',
        disabled: false,
    },
    {
        text: '+672',
        value: '+672',
        disabled: false,
    },
    {
        text: '+673',
        value: '+673',
        disabled: false,
    },
    {
        text: '+674',
        value: '+674',
        disabled: false,
    },
    {
        text: '+675',
        value: '+675',
        disabled: false,
    },
    {
        text: '+676',
        value: '+676',
        disabled: false,
    },
    {
        text: '+677',
        value: '+677',
        disabled: false,
    },
    {
        text: '+678',
        value: '+678',
        disabled: false,
    },
    {
        text: '+679',
        value: '+679',
        disabled: false,
    },
    {
        text: '+680',
        value: '+680',
        disabled: false,
    },
    {
        text: '+681',
        value: '+681',
        disabled: false,
    },
    {
        text: '+682',
        value: '+682',
        disabled: false,
    },
    {
        text: '+683',
        value: '+683',
        disabled: false,
    },
    {
        text: '+685',
        value: '+685',
        disabled: false,
    },
    {
        text: '+686',
        value: '+686',
        disabled: false,
    },
    {
        text: '+687',
        value: '+687',
        disabled: false,
    },
    {
        text: '+688',
        value: '+688',
        disabled: false,
    },
    {
        text: '+689',
        value: '+689',
        disabled: false,
    },
    {
        text: '+690',
        value: '+690',
        disabled: false,
    },
    {
        text: '+691',
        value: '+691',
        disabled: false,
    },
    {
        text: '+692',
        value: '+692',
        disabled: false,
    },
    {
        text: '+850',
        value: '+850',
        disabled: false,
    },
    {
        text: '+852',
        value: '+852',
        disabled: false,
    },
    {
        text: '+853',
        value: '+853',
        disabled: false,
    },
    {
        text: '+855',
        value: '+855',
        disabled: false,
    },
    {
        text: '+856',
        value: '+856',
        disabled: false,
    },
    {
        text: '+872',
        value: '+872',
        disabled: false,
    },
    {
        text: '+880',
        value: '+880',
        disabled: false,
    },
    {
        text: '+886',
        value: '+886',
        disabled: false,
    },
    {
        text: '+960',
        value: '+960',
        disabled: false,
    },
    {
        text: '+961',
        value: '+961',
        disabled: false,
    },
    {
        text: '+962',
        value: '+962',
        disabled: false,
    },
    {
        text: '+963',
        value: '+963',
        disabled: false,
    },
    {
        text: '+964',
        value: '+964',
        disabled: false,
    },
    {
        text: '+965',
        value: '+965',
        disabled: false,
    },
    {
        text: '+966',
        value: '+966',
        disabled: false,
    },
    {
        text: '+967',
        value: '+967',
        disabled: false,
    },
    {
        text: '+968',
        value: '+968',
        disabled: false,
    },
    {
        text: '+970',
        value: '+970',
        disabled: false,
    },
    {
        text: '+971',
        value: '+971',
        disabled: false,
    },
    {
        text: '+972',
        value: '+972',
        disabled: false,
    },
    {
        text: '+973',
        value: '+973',
        disabled: false,
    },
    {
        text: '+974',
        value: '+974',
        disabled: false,
    },
    {
        text: '+975',
        value: '+975',
        disabled: false,
    },
    {
        text: '+976',
        value: '+976',
        disabled: false,
    },
    {
        text: '+977',
        value: '+977',
        disabled: false,
    },
    {
        text: '+992',
        value: '+992',
        disabled: false,
    },
    {
        text: '+993',
        value: '+993',
        disabled: false,
    },
    {
        text: '+994',
        value: '+994',
        disabled: false,
    },
    {
        text: '+995',
        value: '+995',
        disabled: false,
    },
    {
        text: '+996',
        value: '+996',
        disabled: false,
    },
    {
        text: '+998',
        value: '+998',
        disabled: false,
    },
    {
        text: '+1242',
        value: '+1242',
        disabled: false,
    },
    {
        text: '+1246',
        value: '+1246',
        disabled: false,
    },
    {
        text: '+1264',
        value: '+1264',
        disabled: false,
    },
    {
        text: '+1268',
        value: '+1268',
        disabled: false,
    },
    {
        text: '+1284',
        value: '+1284',
        disabled: false,
    },
    {
        text: '+1340',
        value: '+1340',
        disabled: false,
    },
    {
        text: '+1441',
        value: '+1441',
        disabled: false,
    },
    {
        text: '+1473',
        value: '+1473',
        disabled: false,
    },
    {
        text: '+1649',
        value: '+1649',
        disabled: false,
    },
    {
        text: '+1664',
        value: '+1664',
        disabled: false,
    },
    {
        text: '+1670',
        value: '+1670',
        disabled: false,
    },
    {
        text: '+1671',
        value: '+1671',
        disabled: false,
    },
    {
        text: '+1684',
        value: '+1684',
        disabled: false,
    },
    {
        text: '+1758',
        value: '+1758',
        disabled: false,
    },
    {
        text: '+1767',
        value: '+1767',
        disabled: false,
    },
    {
        text: '+1784',
        value: '+1784',
        disabled: false,
    },
    {
        text: '+1849',
        value: '+1849',
        disabled: false,
    },
    {
        text: '+1868',
        value: '+1868',
        disabled: false,
    },
    {
        text: '+1869',
        value: '+1869',
        disabled: false,
    },
    {
        text: '+1876',
        value: '+1876',
        disabled: false,
    },
    {
        text: '+1939',
        value: '+1939',
        disabled: false,
    },
]
