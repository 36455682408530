import { styled } from 'Adapters/Freestyled'
import { Spinner } from 'Components/Spinner'
import { FC } from 'react'

const Loading: FC = () => (
    <Styled>
        <Spinner />
    </Styled>
)

const Styled = styled.section`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

export { Loading }
