import { flex, styled } from 'Adapters/Freestyled'
import { Avatar, AvatarColorVariant, NoOfInitials } from 'Components/Avatar'
import { Text } from 'Components/Text'
import { FC } from 'react'

/*
 * A cell that renders an avatar component
 * with the name to the right
 */

type Props = {
    name: string
    variant?: AvatarColorVariant
    noOfInitials?: NoOfInitials
}

export const AvatarNameCell: FC<Props> = ({
    name,
    variant = 'grey',
    noOfInitials = 1,
}) => {
    return (
        <StyledAvatarCell>
            <div>
                <Avatar
                    name={name}
                    variant={variant}
                    noOfInitials={noOfInitials}
                />
                <Text as="p" variant="bold-5">
                    {name}
                </Text>
            </div>
        </StyledAvatarCell>
    )
}

const StyledAvatarCell = styled.td`
    vertical-align: middle;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${({ theme }) => theme.spacing['04']};
        width: 100%;
        height: 100%;
    }
`
