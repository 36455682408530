import { useAuthenticate } from 'Adapters/Authentication/useAuthenticate'
import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { useSandbox } from 'Hooks/useSandbox'
import { useLocation } from 'react-router-dom'

const SandboxWarning = () => {
    const { signup } = useAuthenticate()
    const { pathname } = useLocation()
    const sandbox = useSandbox()
    const { isLarge } = useResponder()
    const message = isLarge
        ? 'You are currently working in a sandbox workspace'
        : 'Sandbox'
    const buttonText = isLarge
        ? 'Register to save your work'
        : 'Register to save'

    return sandbox.state === 'active' ? (
        <Styled>
            <div>
                <Icon name="ExclamationTriangle" />
                <Text variant="bold-4" as="p">
                    {message}
                </Text>
                <StyledRegisterButton
                    variant="ghost"
                    size="xSmall"
                    icon="Save"
                    iconRight
                    text={buttonText}
                    onClick={() =>
                        signup({
                            returnTo: `/sandbox-signup?id=${sandbox.user.id}&token=${sandbox.user.token}&pathname=${pathname}`,
                        })
                    }
                />
            </div>
        </Styled>
    ) : null
}

const Styled = styled.div`
    padding: 0.25rem;
    background-color: ${({ theme }) => theme.palette.brand['01'].normal};

    > div {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        padding: 0.25rem 0.25rem 0.25rem 0.75rem;
        background-color: ${({ theme }) => theme.palette.support.bg05.normal};
        color: ${({ theme }) => theme.palette.text.support05.normal};
        border: 1px solid ${({ theme }) => theme.palette.support['05'].normal}20;
        box-shadow: 0 0 0.5rem -0.25rem ${({ theme }) => theme.palette.ui['07'].normal};
        border-radius: 0.25rem;

        > .icon svg {
            --icon-width: 1rem;
            width: var(--icon-width);
            height: var(--icon-width);
            min-width: var(--icon-width);
            min-height: var(--icon-width);
        }
        > .icon svg path {
            fill: ${({ theme }) => theme.palette.text.support05.normal};
        }

        > p {
            flex: 1;
        }
    }
`

const StyledRegisterButton = styled(IconTextButton)`
    background-color: ${({ theme }) => theme.palette.support['05'].normal}30;
    color: ${({ theme }) => theme.palette.text.support05.normal};
    border: 1px solid ${({ theme }) => theme.palette.text.support05.normal}60;

    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support05.normal};
    }
`

export { SandboxWarning }
