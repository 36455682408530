import { FC, useEffect } from 'react'
import { useVisitorDetailsQuery } from './__generated__/query'
import * as sdk from './sdk'

export const SupportProvider: FC = () => {
    const { data } = useVisitorDetailsQuery()

    useEffect(() => {
        if (!data?.currentUser) return

        if (data.currentUser.email === 'sandbox@modlify.io') {
            sdk.setVisitorData({
                distinct_id: data.currentUser.id,
            })
            return
        }

        sdk.setVisitorData({
            distinct_id: data.currentUser.id,
            email: data.currentUser.email,
            name: data.currentUser.name,
        })
    }, [data])

    return null
}
