import { styled } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { Text } from 'Components/Text'
import { ContactUsToast } from 'Components/Toast'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC, useCallback } from 'react'
import { useDropToUpdate } from '../../../dnd'
import { Group } from './Group'
import { useGroupBusinessObjects } from './useGroupBusinessObjects'

type Props = {
    businessObjectDefinitionId: string
    businessObjects: BusinessObjectTableItemFragment[]
    grouping: Grouping
}

export const CardGroupDisplay: FC<Props> = ({
    businessObjects,
    grouping,
    businessObjectDefinitionId,
}) => {
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'bo-card-display',
    })

    const openDrawer = useCallback(
        (id: string) => {
            boDisplayProps.open({
                id,
                tab: 'details',
            })
        },
        [boDisplayProps]
    )

    const groups = useGroupBusinessObjects(businessObjects, grouping)

    const { errorToastOpen, setErrorToastOpen } = useDropToUpdate(grouping)

    return (
        <>
            <StyledCardDisplay>
                {Object.keys(groups).length ? (
                    <>
                        <Text variant="bold-3" as="h2">
                            {
                                grouping.fields.find(
                                    field => field.id === grouping.selection
                                )?.label
                            }
                        </Text>

                        <div className="groups">
                            {Object.entries(groups).map(([id, group]) => (
                                <Group
                                    fieldType={group.fieldType}
                                    key={id}
                                    id={id}
                                    businessObjectGroup={group}
                                    openDrawer={openDrawer}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <Text variant="regular-4" as="p">
                        <em>
                            No <strong>records</strong> to show for this{' '}
                            <strong>dataset</strong>
                        </em>
                    </Text>
                )}

                <BusinessObjectDisplay
                    {...boDisplayProps}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />

                <ContactUsToast
                    open={errorToastOpen}
                    onOpenChange={setErrorToastOpen}
                    description="We were unable to update the field value."
                />
            </StyledCardDisplay>
        </>
    )
}

const StyledCardDisplay = styled.section`
    padding: 2rem 3rem;

    > h2 {
        padding-bottom: 1.5rem;
    }

    > p {
        width: 100%;
        text-align: center;
    }

    .groups {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 2rem;
    }
`
