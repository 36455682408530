import { css, flex, styled } from 'Adapters/Freestyled'
import { Toggle } from 'Components/Input'
import { regular5, regular6 } from 'Components/Text'
import { motion } from 'framer-motion'

export const StyledConstraintsTooltip = styled.p`
    ${regular6};
    color: ${({ theme }) => theme.palette.text['02'].normal};
`

export const StyledErrorMessage = styled.p`
    color: red;
`

export const StyledInputGroup = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    width: 100%;

    label {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.25rem;
        padding-bottom: 0.25rem;

        > button {
            margin-top: 0;
        }
    }

    > div {
        ${flex('row', 'flex-start', 'center')};
        width: 100%;
    }

    > ul {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.25rem;
        width: 100%;
    }

    > button {
        margin-left: auto;
        padding: 0;
    }
`

const formSectionStyles = css`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem 2rem;
    border: 0.1rem solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: 100%;
    margin-bottom: 1rem;
`

export const StyledFormSection = styled.fieldset`
    ${formSectionStyles};
`

export const StyledMotionFormSection = styled(motion.fieldset)`
    ${formSectionStyles};
`

export const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;
    margin-top: 0;

    > button {
        margin-left: auto;
    }
`

export const StyledToggle = styled(Toggle)`
    button > span {
        border: 4px solid #000;
    }
`

export const StyledSectionHeader = styled.header`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button:last-of-type {
        margin-left: auto;
    }
`
