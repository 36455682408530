import { TextInput } from 'Components/Input'
import { Text, Props as TextProps } from 'Components/Text'
import { omit } from 'lodash'
import { FC } from 'react'

type MaybeEditEditing = {
    isEditing: true
    label?: React.ReactNode
    children: string | null | undefined
} & React.InputHTMLAttributes<HTMLInputElement>

type MaybeEditNotEditing = {
    isEditing: false
    label?: React.ReactNode
    children: string | null | undefined
} & TextProps & { placeholder?: string }

export const MaybeEdit: FC<MaybeEditEditing | MaybeEditNotEditing> = ({
    children,
    label,
    placeholder,
    ...props
}) => {
    if (props.isEditing) {
        return (
            <>
                {label}
                <TextInput
                    {...omit(props, 'isEditing')}
                    onClick={e => {
                        e.stopPropagation()
                        props.onClick?.(e)
                    }}
                    value={children ?? ''}
                    placeholder={placeholder}
                />
            </>
        )
    }
    return (
        <>
            {label}
            <Text {...omit(props, 'isEditing')}>
                {!children && placeholder ? placeholder : children}
            </Text>
        </>
    )
}
