import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { useProcessCreator } from '../useProcessCreator'
import AI from './ai.svg?react'
import Dataset from './dataset.svg?react'
import Process from './process.svg?react'

export const StartOptionsStep = () => {
    const { dispatch } = useProcessCreator()

    return (
        <StyledWelcomePage>
            <div className="buttons">
                <button
                    onClick={() =>
                        dispatch({
                            type: 'stepChanged',
                            payload: { step: 'ai-1' },
                        })
                    }
                >
                    <AI />
                    <Text as="span" variant="regular-3">
                        Build your workflow with AI
                    </Text>
                    <Text as="span" variant="regular-6">
                        Best if you want to explore different workflow options
                    </Text>
                </button>

                <button
                    onClick={() =>
                        dispatch({
                            type: 'showPhasesStep',
                        })
                    }
                >
                    <Process />
                    <Text as="span" variant="regular-3">
                        Build your workflow step-by-step
                    </Text>
                    <Text as="span" variant="regular-6">
                        Best if you have a pre-defined workflow
                    </Text>
                </button>

                <button
                    onClick={() =>
                        dispatch({
                            type: 'stepChanged',
                            payload: { step: 'details' },
                        })
                    }
                >
                    <Dataset />
                    <Text as="span" variant="regular-3">
                        Build a workflow around a dataset
                    </Text>
                    <Text as="span" variant="regular-6">
                        Best if you already have a dataset to build around
                    </Text>
                </button>
            </div>
        </StyledWelcomePage>
    )
}

const StyledWelcomePage = styled.section`
    ${flex('column', 'center', 'center')};
    gap: 2rem;
    height: 100%;
    width: 100%;
    padding: 2rem 0;

    .buttons {
        ${flex('column', 'flex-start', 'center')};
        gap: 2rem;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 2rem;

        button {
            ${flex('column', 'space-around', 'center')};
            width: 100%;
            padding: 2rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};
            color: ${({ theme }) => theme.palette.text['02'].normal};
            transition: ${({ theme }) =>
                    theme.animation.buttonTransitionDuration}
                ease;
            background-color: ${({ theme }) => theme.palette.ui['02'].normal};
            border: 1px solid transparent;

            @media (hover: hover) {
                &:hover {
                    color: ${({ theme }) => theme.palette.text['02'].hover};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].hover};
                }
            }

            &:active {
                color: ${({ theme }) => theme.palette.text['02'].active};
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].active};
            }

            &:focus-visible {
                box-shadow: 0 0 0 0.125rem
                    ${({ theme }) => theme.palette.brand['01'].normal};
            }

            > svg {
                height: 5rem;
                width: 5rem;
                padding-bottom: 1rem;

                line,
                circle {
                    stroke: ${({ theme }) => theme.palette.text['02'].normal};
                }

                path {
                    fill: ${({ theme }) => theme.palette.text['02'].normal};
                }
            }

            .text {
                ${flex('column', 'flex-start', 'center')};
            }
        }
    }

    @media screen and (min-width: ${breakpoints.medium}px) {
        .buttons {
            ${flex('row', 'center', 'center')};

            button {
                width: 25rem;
                height: 18.75rem;

                > svg {
                    height: 10rem;
                    width: 10rem;
                }
            }
        }
    }
`
