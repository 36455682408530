import { dayjs } from 'Adapters/DayJS'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { DateRangeConstraint } from '__generated__'

export const DateRange: FC<DateRangeConstraint> = ({ start, end }) => (
    <>
        <Text as="p" variant="bold-4">
            Date range start
        </Text>
        {start ? (
            <Text as="p" variant="regular-4">
                {dayjs(start).format('DD MMM YYYY')}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No start set
            </Text>
        )}
        <Text as="p" variant="bold-4">
            Date range end
        </Text>
        {end ? (
            <Text as="p" variant="regular-4">
                {dayjs(end).format('DD MMM YYYY')}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No end set
            </Text>
        )}
    </>
)
