import { z } from 'zod'

const AI_ANSWERS_KEY = 'aiAnswers'

const aiAnswersSchema = z.object({
    industry: z.string(),
    size: z.string(),
    team: z.string(),
})

type AIAnswers = z.infer<typeof aiAnswersSchema>

export const getAIAnswersOrDefault = (): Partial<AIAnswers> => {
    const defaultAnswers = {
        ai: undefined,
        size: undefined,
        team: undefined,
    }

    const aiAnswers = localStorage.getItem(AI_ANSWERS_KEY)

    if (!aiAnswers) return defaultAnswers

    const validation = aiAnswersSchema.safeParse(JSON.parse(aiAnswers))

    if (!validation.success) return defaultAnswers

    return validation.data
}

export const saveAIAnswers = (answers: Partial<AIAnswers>) => {
    localStorage.setItem(AI_ANSWERS_KEY, JSON.stringify(answers))
}
