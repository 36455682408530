import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { TextAreaInput, TextInput, Toggle } from 'Components/Input'
import { Text, bold4, regular6 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { ValidationStatus } from 'Utils/BusinessObjectDefinition'
import { FC, useEffect, useRef } from 'react'
import { DetailsSection } from './Section'
import { DispatchAction, State } from './types'

type Props = Pick<
    State,
    | 'definition'
    | 'customLabel'
    | 'enableUpdates'
    | 'updatesLocked'
    | 'enableDocuments'
    | 'documentsLocked'
    | 'validation'
> & { dispatch: DispatchAction }

const Basics: FC<Props> = ({
    definition,
    customLabel,
    enableUpdates,
    updatesLocked,
    enableDocuments,
    documentsLocked,
    validation,
    dispatch,
}) => {
    const labelInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (customLabel) labelInputRef.current?.focus()
    }, [customLabel])
    return (
        <Styled
            heading="Basics"
            description="Configure some basic information about the dataset and it's records that will make it easier to understand and use."
        >
            <fieldset>
                <div>
                    <Text
                        as="label"
                        variant="bold-5"
                        htmlFor="bo-definition-creator-name"
                    >
                        Name
                    </Text>
                    <p>
                        This will be the name for the collection as a whole.
                        Giving the dataset a unique and meaningful name will
                        help others to find it later, for example when trying to
                        link it to a workflow.
                    </p>
                    <StyledTextInput
                        value={definition.name}
                        placeholder="Dataset name"
                        onChange={({ currentTarget: { value } }) =>
                            dispatch({ type: 'setName', payload: { value } })
                        }
                        onBlur={({ currentTarget: { value } }) =>
                            dispatch({ type: 'setName', payload: { value } })
                        }
                        hasError={
                            validation.name.status === ValidationStatus.Invalid
                        }
                    />
                    {validation.name.message && (
                        <p className="error">{validation.name.message}</p>
                    )}
                </div>
                <div>
                    <Text
                        as="label"
                        variant="bold-5"
                        htmlFor="bo-definition-creator-description"
                    >
                        Description (optional)
                    </Text>
                    <p>
                        By giving your dataset a meaningful description you can
                        help others to understand it's purpose, for example what
                        business entity records in this dataset should
                        represent.
                    </p>
                    <StyledTextAreaInput
                        value={definition.description ?? ''}
                        placeholder="Dataset description"
                        onChange={({ currentTarget: { value } }) =>
                            dispatch({
                                type: 'setDescription',
                                payload: { value },
                            })
                        }
                    />
                </div>
                <div>
                    <Text
                        as="label"
                        variant="bold-5"
                        htmlFor="bo-definition-creator-name"
                    >
                        Label
                    </Text>
                    <p>
                        This will be used to give each individual record in this
                        dataset a unique identifying label. We will
                        automatically generate a label for you based on the
                        fields you define, but you can customise it if you want.
                    </p>
                    <StyledLabelInputContainer>
                        <StyledTextInput
                            value={definition.label}
                            placeholder={
                                customLabel
                                    ? `{{{ it.field_name }}}`
                                    : 'Add named fields to generate a label'
                            }
                            disabled={!customLabel}
                            onChange={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'setLabel',
                                    payload: { value },
                                })
                            }
                            onBlur={({ currentTarget: { value } }) =>
                                dispatch({
                                    type: 'setLabel',
                                    payload: { value },
                                })
                            }
                            hasError={
                                validation.label.status ===
                                ValidationStatus.Invalid
                            }
                            ref={labelInputRef}
                        />
                        <Tooltip
                            content={
                                customLabel
                                    ? 'Generate label'
                                    : 'Customise label'
                            }
                        >
                            <IconButton
                                iconName={customLabel ? 'Sync' : 'Edit'}
                                variant="primary"
                                onClick={() => {
                                    dispatch({
                                        type: 'toggleCustomLabel',
                                        payload: undefined,
                                    })
                                }}
                            />
                        </Tooltip>
                    </StyledLabelInputContainer>
                    {validation.label.message && (
                        <p className="error">{validation.label.message}</p>
                    )}
                </div>
                <StyledToggleFieldContainer data-display-only={updatesLocked}>
                    <header>
                        <Text
                            as="label"
                            variant="bold-5"
                            htmlFor="bo-definition-creator-enable-updates"
                        >
                            Enable updates
                        </Text>
                        {updatesLocked && (
                            <Tooltip content="Updates cannot be disabled once they have been enabled on a dataset">
                                <StyledLockIndicator>
                                    <Icon name="Lock" />
                                </StyledLockIndicator>
                            </Tooltip>
                        )}
                    </header>
                    <p>
                        If enabled, records in this dataset will have an updates
                        screen where you and your team can leave comments and
                        discuss the record's progress.
                    </p>
                    <StyledToggleContainer>
                        {updatesLocked ? (
                            <span className="disabled-value">Enabled</span>
                        ) : (
                            <Toggle
                                id="bo-definition-creator-enable-updates"
                                checked={enableUpdates}
                                onCheckedChange={() =>
                                    dispatch({
                                        type: 'toggleEnableUpdates',
                                        payload: undefined,
                                    })
                                }
                                indicatorText={['Enabled', 'Disabled']}
                                disabled={updatesLocked}
                            />
                        )}
                    </StyledToggleContainer>
                </StyledToggleFieldContainer>
                <StyledToggleFieldContainer data-display-only={documentsLocked}>
                    <header>
                        <Text
                            as="label"
                            variant="bold-5"
                            htmlFor="bo-definition-creator-enable-updates"
                        >
                            Enable documents
                        </Text>
                        {documentsLocked && (
                            <Tooltip content="Documents cannot be disabled once they have been enabled on a dataset">
                                <StyledLockIndicator>
                                    <Icon name="Lock" />
                                </StyledLockIndicator>
                            </Tooltip>
                        )}
                    </header>
                    <p>Automatically include a document list field.</p>
                    <StyledToggleContainer>
                        {documentsLocked ? (
                            <span className="disabled-value">Enabled</span>
                        ) : (
                            <Toggle
                                id="bo-definition-creator-enable-documents"
                                checked={enableDocuments}
                                onCheckedChange={() =>
                                    dispatch({
                                        type: 'toggleEnableDocuments',
                                        payload: undefined,
                                    })
                                }
                                indicatorText={['Enabled', 'Disabled']}
                                disabled={documentsLocked}
                            />
                        )}
                    </StyledToggleContainer>
                </StyledToggleFieldContainer>
            </fieldset>
        </Styled>
    )
}

const Styled = styled(DetailsSection)`
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    background-image: linear-gradient(
        175deg,
        ${({ theme }) => theme.palette.ui['02'].normal},
        ${({ theme }) => theme.palette.ui['03'].normal}
    );
    border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-left: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: 0.5rem 0 0 0.5rem;
    overflow: auto;

    fieldset {
        border: none;
        padding: 0rem 2rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    fieldset > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    fieldset label {
        display: block;
    }

    fieldset p {
        ${regular6}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        line-height: 150%;
    }

    fieldset p.error {
        color: ${({ theme }) => theme.palette.text.support01.normal};
    }

    fieldset p a {
        color: ${({ theme }) => theme.palette.support['03'].normal};
    }
`

const StyledTextInput = styled(TextInput)`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    &[data-disabled='true'] {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    }
    &[data-disabled='true'] definition {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const StyledTextAreaInput = styled(TextAreaInput)`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
`

const StyledLabelInputContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
`

const StyledToggleFieldContainer = styled.div`
    &[data-display-only='true'] {
        background-color: ${({ theme }) => theme.palette.support.bg03.normal};
        padding: 1rem;
        border-top: 1px dotted
            ${({ theme }) => theme.palette.support['03'].normal}80;
    }

    > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .disabled-value {
        ${bold4}
        color: ${({ theme }) => theme.palette.text.support03.normal};
    }
`

const StyledLockIndicator = styled.div`
    padding: 0.25rem;
    border-radius: 50%;
    border: 1px dotted ${({ theme }) => theme.palette.support['03'].normal};

    .icon svg {
        --width: 1rem;
        width: var(--width);
        height: var(--width);
        min-width: var(--width);
        min-height: var(--width);
    }

    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }
`

const StyledToggleContainer = styled.div`
    padding-top: 0.25rem;
`

export { Basics }
