import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import dayjs from 'dayjs'
import { FC } from 'react'
import { match, P } from 'ts-pattern'
import { opt } from 'Utils'
import { useActionsView } from '../useActionsView'
import { ActionTimelineCardQuery } from './__generated__/query'

type Props = {
    obj: NonNullable<ActionTimelineCardQuery['action']['extendedBy']>
}

export const ExtensionDisplayFields: FC<Props> = ({ obj }) => {
    const { selectedFields } = useActionsView()

    if (!selectedFields.length) return null

    return (
        <>
            <StyledDivider />

            <StyledFields>
                {obj.fields
                    // Ignore Updates
                    .filter(
                        field =>
                            !(
                                field.fieldDefinition.__typename ===
                                    'ListFieldDefinition' &&
                                field.fieldDefinition.listOf.__typename ===
                                    'UpdatesFieldDefinition'
                            )
                    )
                    .filter(field =>
                        selectedFields.includes(field.fieldDefinition.id)
                    )
                    .map(field => (
                        <li key={field.fieldDefinition.id}>
                            <FieldLabel label={field.fieldDefinition.name} />
                            <StyledValue>
                                {match(field)
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectBooleanField',
                                        },
                                        ({ booleanValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {booleanValue
                                                    ? 'True'
                                                    : 'False'}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectCurrencyField',
                                        },
                                        ({ currencyValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    currencyValue,
                                                    ({
                                                        amount,
                                                        currencyDetails,
                                                    }) =>
                                                        `${currencyDetails.symbol}${amount}`,
                                                    () => (
                                                        <span className="">
                                                            -
                                                        </span>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectDateField',
                                        },
                                        ({ dateValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    dateValue,
                                                    d =>
                                                        dayjs(d).format(
                                                            'DD MM YYYY'
                                                        ),
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectDocumentField',
                                        },
                                        ({ docValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    docValue,
                                                    ({ name, url }) => (
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={url}
                                                        >
                                                            {name}
                                                        </a>
                                                    ),
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectEmailField',
                                        },
                                        ({ emailValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    emailValue,
                                                    val => (
                                                        <ExternalLink
                                                            val={val}
                                                            type="email"
                                                        />
                                                    ),
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectListField',
                                        },
                                        ({ listValue }) => {
                                            return (
                                                <Text
                                                    as="p"
                                                    variant="regular-5"
                                                >
                                                    {`${listValue.length} selected`}
                                                </Text>
                                            )
                                        }
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectNumberField',
                                        },
                                        ({ numValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    numValue,
                                                    val => val,
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectRelationField',
                                        },
                                        ({ relationValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    relationValue,
                                                    rel => rel.label,
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectSelectField',
                                        },
                                        ({ selectValue, fieldDefinition }) => (
                                            <Text as="p" variant="regular-5">
                                                {match(selectValue)
                                                    .with(
                                                        [P._],
                                                        v =>
                                                            match(
                                                                fieldDefinition
                                                            )
                                                                .with(
                                                                    {
                                                                        __typename:
                                                                            'SelectFieldDefinition',
                                                                    },
                                                                    ({
                                                                        selectOptions,
                                                                    }) =>
                                                                        selectOptions
                                                                )
                                                                .otherwise(
                                                                    () => []
                                                                )
                                                                .find(
                                                                    ({ id }) =>
                                                                        id ===
                                                                        v[0]
                                                                )?.value ||
                                                            '1 selected'
                                                    )
                                                    .otherwise(
                                                        ({ length }) =>
                                                            `${length} selected`
                                                    )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectTelephoneField',
                                        },
                                        ({ telephoneValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    telephoneValue,
                                                    ({
                                                        countryCode,
                                                        number,
                                                    }) => (
                                                        <ExternalLink
                                                            val={`${
                                                                countryCode ||
                                                                ''
                                                            }${number || ''}`}
                                                            type="tel"
                                                        />
                                                    ),
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectTextField',
                                        },
                                        ({ textValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    textValue,
                                                    val => val,
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUpdatesField',
                                        },
                                        ({ updatesValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    updatesValue,
                                                    () => 'enabled',
                                                    () => 'disabeld'
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUrlField',
                                        },
                                        ({ urlValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    urlValue,
                                                    val => (
                                                        <ExternalLink
                                                            val={val}
                                                            type="link"
                                                        />
                                                    ),
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'BusinessObjectUserField',
                                        },
                                        ({ userValue }) => (
                                            <Text as="p" variant="regular-5">
                                                {opt(
                                                    userValue,
                                                    ({ name }) => name,
                                                    () => (
                                                        <StyledUnset>
                                                            -
                                                        </StyledUnset>
                                                    )
                                                )}
                                            </Text>
                                        )
                                    )
                                    .exhaustive()}
                            </StyledValue>
                        </li>
                    ))}
            </StyledFields>
        </>
    )
}

const StyledDivider = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.palette.ui['02'].normal};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`

const StyledFields = styled.ul`
    padding: 0 0.25rem;
    display: flex;
    flex-direction: column;
`

const StyledValue = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    overflow-wrap: break-word;
    word-break: break-all;
`

const StyledUnset = styled.span`
    opacity: 0.4;
`

type FieldLabelProps = {
    label: string
}

const FieldLabel: FC<FieldLabelProps> = ({ label }) => (
    <StyledFieldLabel>
        <Text as="span" variant="bold-6">
            {label}
        </Text>
    </StyledFieldLabel>
)

const StyledFieldLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: ${({ theme }) => theme.palette.text['03'].normal};
`

type ExternalLinkProps = {
    val: string
    type: 'link' | 'email' | 'tel'
}

const ExternalLink: FC<ExternalLinkProps> = ({ val, type }) => (
    <StyledExternalLink
        target="_blank"
        rel="noreferrer"
        href={`${{ link: '', email: 'mailto:', tel: 'tel:' }[type]}${val}`}
        onClick={e => e.stopPropagation()}
    >
        {val}
    </StyledExternalLink>
)

const StyledExternalLink = styled.a`
    transition: color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease,
        background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    background-color: ${({ theme }) => theme.palette.ui['02'].hover}00;
    border-radius: 0.125rem;

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
        background-color: ${({ theme }) => theme.palette.ui['02'].hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support03.active};
        background-color: ${({ theme }) => theme.palette.ui['02'].active};
    }
`
