import { logger } from 'Adapters/Logger'
import { useMemo } from 'react'
import { useIsFreshTenantQuery } from './__generated__/query'

export const useIsFreshTenant = () => {
    const { data } = useIsFreshTenantQuery({
        onError(error) {
            logger.error('failed to fetch fresh tenant details', error)
        },
    })

    const isFreshTenant = useMemo(() => {
        if (!data) return undefined

        if (
            data.actionCount === 0 &&
            data.businessObjectDefinitions.length === 0 &&
            data.processes.length === 0
        )
            return true

        return false
    }, [data])

    return isFreshTenant
}
