import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { SavedConfig } from '../../../types'
import { DeleteView } from './DeleteView'

type Props = {
    view: SavedConfig
}

export const ManageSingleView: FC<Props> = ({ view }) => (
    <Styled>
        <Icon name="View" />
        <Text as="span" variant="bold-5" className="title">
            {view.label}
        </Text>
        <DeleteView id={view.id} />
    </Styled>
)

const Styled = styled.li`
    display: grid;
    grid-template: auto / auto 1fr auto;
    align-items: center;
    justify-content: center;
    text-align: left;
    grid-column-gap: 1rem;
    grid-row-gap: 0.125rem;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};

    .icon:first-child {
        grid-column: 1 / 2;
    }

    .title {
        grid-column: 2 / 3;
    }

    .icon:last-child {
        grid-column: 3 / 4;
    }

    .icon svg {
        --icon-width: 1rem;
        width: var(--icon-width);
        height: var(--icon-width);
        min-width: var(--icon-width);
        min-height: var(--icon-width);
    }

    .icon svg path,
    .icon svg circle {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }
`
