import * as Select from '@radix-ui/react-select'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { StyledContent } from 'Components/Select'
import { regular5 } from 'Components/Text'
import { FC, Fragment } from 'react'
import { getPortalsContainer } from 'Utils'

type Field = {
    id: string
    name: string
}

type DeepField = {
    parentField: Field
    fields: Field[]
}

type Props = {
    id: string
    onFieldChanged: (fieldId: string) => void
    fieldId: string | undefined
    fields: Field[]
    deepFields: DeepField[]
}

export const DeepFieldSelector: React.FC<Props> = ({
    id,
    onFieldChanged,
    fieldId,
    fields,
    deepFields,
}) => {
    return (
        <Select.Root value={fieldId} onValueChange={onFieldChanged}>
            <SelectTrigger id={id} hasError={false} />
            <Select.Portal container={getPortalsContainer()}>
                <StyledContent position="popper">
                    <Select.ScrollUpButton>
                        <Icon name="ArrowUp" />
                    </Select.ScrollUpButton>
                    <Select.Viewport>
                        {fields.length + deepFields.length > 0 ? (
                            <>
                                <Select.Group>
                                    {deepFields.length > 0 ? (
                                        <StyledLabel>
                                            Top-level fields
                                        </StyledLabel>
                                    ) : null}
                                    {fields.map(field => (
                                        <SelectItem
                                            key={field.id}
                                            value={field.id}
                                        >
                                            {field.name}
                                        </SelectItem>
                                    ))}
                                </Select.Group>
                                {deepFields.map(field => (
                                    <Fragment
                                        key={`${field.parentField.id}-group`}
                                    >
                                        <StyledSeparator />
                                        <Select.Group>
                                            <StyledLabel>
                                                {field.parentField.name}
                                            </StyledLabel>
                                            {field.fields.map(deepField => (
                                                <SelectItem
                                                    value={deepField.id}
                                                    key={`${field.parentField.id}-${deepField.id}`}
                                                >
                                                    {deepField.name}
                                                </SelectItem>
                                            ))}
                                        </Select.Group>
                                    </Fragment>
                                ))}
                            </>
                        ) : (
                            <SelectItem value="">
                                No fields available
                            </SelectItem>
                        )}
                    </Select.Viewport>
                    <Select.ScrollDownButton>
                        <Icon name="ArrowDown" />
                    </Select.ScrollDownButton>
                </StyledContent>
            </Select.Portal>
        </Select.Root>
    )
}

const SelectItem: FC<Select.SelectItemProps> = ({
    value,
    children,
    disabled,
}) => {
    return (
        <StyledItem value={value} key={value} disabled={disabled}>
            <StyledItemIndicator>
                <Icon name="Check" />
            </StyledItemIndicator>
            <Select.ItemText>{children}</Select.ItemText>
        </StyledItem>
    )
}

const SelectTrigger: FC<{
    id: string
    hasError: boolean
}> = ({ id, hasError }) => {
    return (
        <StyledTrigger id={id} $hasError={hasError} className="select-trigger">
            <Select.Value />
            <StyledSelectIcon>
                <Icon name={'AngleDown'} />
            </StyledSelectIcon>
        </StyledTrigger>
    )
}

const StyledTrigger = styled(Select.Trigger)<{ $hasError: boolean }>`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    color: ${({ theme }) => theme.palette.text['01'].normal};
    width: 100%;
    padding: 0.625rem 0.875rem;
    margin-top: 0;
    border: 0.125rem solid
        ${({ $hasError, theme }) =>
            $hasError ? theme.palette.support['01'].normal : 'transparent'};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};
    max-height: 2.813rem;
`

const StyledSelectIcon = styled(Select.SelectIcon)`
    margin-left: auto;
`

const StyledItem = styled(Select.Item)`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};
    font-size: ${({ theme }) => theme.typography.scale['01']};
    padding: 0.5rem 1.2rem;
    height: fit-content;
    width: 100%;
    position: relative;
    user-select: none;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    max-height: 2rem;

    svg {
        path {
            fill: ${({ theme }) => theme.palette.icon['01'].normal};
        }
    }

    &[data-disabled] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        pointer-events: none;

        svg {
            path {
                fill: ${({ theme }) => theme.palette.icon['03'].normal};
            }
        }
    }

    &[data-highlighted] {
        outline: none;
        box-shadow: none;
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &[data-state='checked'] {
        margin-left: -0.19rem;
    }
`

const StyledItemIndicator = styled(Select.ItemIndicator)`
    svg {
        min-width: initial;
        min-height: initial;
    }

    width: 1rem;
    height: 1.4rem;

    margin-left: -0.8rem;
`

const StyledSeparator = styled(Select.Separator)`
    height: 1px;
    background-color: ${({ theme }) => theme.palette.field['01'].active};
    margin: 5px;
`

const StyledLabel = styled(Select.Label)`
    padding-left: 0.5rem;
`
