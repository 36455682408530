import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantCheck_ActiveTenantQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantCheck_ActiveTenantQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', name: string, id: string } } | null, tenancies: Array<{ __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } }> } };


export const TenantCheck_ActiveTenantDocument = gql`
    query TenantCheck_ActiveTenant {
  currentUser {
    activeTenant {
      tenant {
        name
        id
      }
    }
    tenancies {
      tenant {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTenantCheck_ActiveTenantQuery__
 *
 * To run a query within a React component, call `useTenantCheck_ActiveTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantCheck_ActiveTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantCheck_ActiveTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantCheck_ActiveTenantQuery(baseOptions?: Apollo.QueryHookOptions<TenantCheck_ActiveTenantQuery, TenantCheck_ActiveTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantCheck_ActiveTenantQuery, TenantCheck_ActiveTenantQueryVariables>(TenantCheck_ActiveTenantDocument, options);
      }
export function useTenantCheck_ActiveTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantCheck_ActiveTenantQuery, TenantCheck_ActiveTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantCheck_ActiveTenantQuery, TenantCheck_ActiveTenantQueryVariables>(TenantCheck_ActiveTenantDocument, options);
        }
export type TenantCheck_ActiveTenantQueryHookResult = ReturnType<typeof useTenantCheck_ActiveTenantQuery>;
export type TenantCheck_ActiveTenantLazyQueryHookResult = ReturnType<typeof useTenantCheck_ActiveTenantLazyQuery>;
export type TenantCheck_ActiveTenantQueryResult = Apollo.QueryResult<TenantCheck_ActiveTenantQuery, TenantCheck_ActiveTenantQueryVariables>;