import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { SelectorName, useSelectorFieldMap } from 'Components/SelectorName'
import { Text } from 'Components/Text'
import { ActionCompletionModal } from 'Features/ActionCompletionModal'
import { FieldCompletionModal } from 'Features/FieldCompletionModal'
import {
    BusinessObjectInProcessOverviewQuery,
    BusinessObjectPhaseProgressQuery,
} from 'Features/SingleBusinessObjectTabs/Overview/__generated__/query'
import { ComparatorFragment } from 'Fragments/__generated__/Comparator'
import { always } from 'lodash/fp'
import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { match } from 'ts-pattern'
import { CenteredSpinner } from '../../../../../Components/Spinner'
import { MoveToNextPhase } from '../../MoveToNextPhase'
import { StyledCriteria, StyledPhaseBox } from './styles'

type Props = {
    phaseIndex: number
    phaseName: string
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    process: BusinessObjectInProcessOverviewQuery['process']
    onProgressChange: () => void
    percentageComplete: number
    percentageCompleteLoading: boolean
    actions: BusinessObjectPhaseProgressQuery['actions']
}

export const CurrentPhaseBox: FC<Props> = ({
    phaseIndex,
    businessObject,
    process,
    phaseName,
    onProgressChange,
    percentageComplete,
    percentageCompleteLoading,
    actions,
}) => {
    const fieldMap = useSelectorFieldMap(businessObject.definition)

    const [showCompletionModal, setShowCompletionModal] = useState(false)
    const [incompleteActions, setIncompleteActions] = useState<string[]>()
    const [includeFields, setIncludeFields] = useState<string[]>([])

    const navigate = useNavigate()

    const handleFieldCompletionClick = useCallback(
        (selector: ComparatorFragment) => {
            if (selector.valueSelector.__typename === 'FieldValueSelector') {
                setIncludeFields([selector.valueSelector.fieldId])
            }
            if (
                selector.valueSelector.__typename === 'DeepSelector' &&
                selector.valueSelector.selectors[0]?.__typename ===
                    'FieldValueSelector'
            ) {
                setIncludeFields([selector.valueSelector.selectors[0].fieldId])
            }

            setShowCompletionModal(true)
        },
        []
    )

    const handleFieldUpdated = useCallback(
        (fieldId: string) => {
            setIncludeFields(fields =>
                fields.filter(field => field !== fieldId)
            )
            onProgressChange()
        },
        [onProgressChange, setIncludeFields]
    )

    const phase = process.phases[phaseIndex]
    const isComplete = percentageComplete === 100

    return (
        <>
            <StyledPhaseBox $complete={isComplete} $current={true}>
                <div>
                    <header>
                        <Text as="h3" variant="regular-3">
                            {phaseName}
                        </Text>

                        <div className="complete">
                            <Icon
                                name={
                                    isComplete ? 'CheckboxTicked' : 'ArrowDown'
                                }
                                className={isComplete ? '' : 'down-icon'}
                            />

                            <span>
                                {isComplete ? 'complete' : 'in progress'}
                            </span>
                        </div>
                    </header>

                    <StyledCriteria>
                        {percentageCompleteLoading ? (
                            <CenteredSpinner />
                        ) : (
                            <StyledPhaseCriterionList>
                                {actions.map((action, i) => (
                                    <li key={action.id}>
                                        <button
                                            onClick={() => {
                                                setIncompleteActions(
                                                    actions
                                                        .filter(action =>
                                                            match(action)
                                                                .with(
                                                                    {
                                                                        __typename:
                                                                            'IncompleteAction',
                                                                    },
                                                                    () => true
                                                                )
                                                                .otherwise(
                                                                    () => false
                                                                )
                                                        )
                                                        .map(
                                                            action => action.id
                                                        )
                                                )
                                            }}
                                        >
                                            <div>
                                                <Icon name="CircleCheck" />
                                                <Text
                                                    as="span"
                                                    variant="regular-5"
                                                    key={i}
                                                >
                                                    {action.name}
                                                </Text>
                                            </div>

                                            {action.__typename ===
                                                'CompletedAction' ||
                                            isComplete ? (
                                                <Icon name="CheckboxTicked" />
                                            ) : (
                                                <Icon name="CheckboxUnticked" />
                                            )}
                                        </button>
                                    </li>
                                ))}

                                {phase?.criteria.map((criterion, i) =>
                                    match(criterion)
                                        .with(
                                            {
                                                __typename:
                                                    'FieldConditionPhaseCriterion',
                                            },
                                            criterion => (
                                                <li key={i}>
                                                    <button
                                                        onClick={() =>
                                                            handleFieldCompletionClick(
                                                                criterion.comparator
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            <Icon name="Edit" />
                                                            <Text
                                                                as="span"
                                                                variant="regular-5"
                                                                key={i}
                                                            >
                                                                <SelectorName
                                                                    selector={
                                                                        criterion
                                                                            .comparator
                                                                            .valueSelector
                                                                    }
                                                                    fieldMap={
                                                                        fieldMap
                                                                    }
                                                                />
                                                            </Text>
                                                        </div>

                                                        {criterion
                                                            .businessObjectOnCriterion
                                                            ?.isCompleted ||
                                                        isComplete ? (
                                                            <Icon name="CheckboxTicked" />
                                                        ) : (
                                                            <Icon name="CheckboxUnticked" />
                                                        )}
                                                    </button>
                                                </li>
                                            )
                                        )
                                        .with(
                                            {
                                                __typename:
                                                    'ProcessDelegatePhaseCriterion',
                                            },
                                            criterion => (
                                                <li key={i}>
                                                    <button
                                                        onClick={() =>
                                                            navigate(
                                                                `/processes/${criterion.process.id}/${businessObject.id}`
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            <Icon name="Process" />
                                                            <Text
                                                                as="span"
                                                                variant="regular-5"
                                                                key={i}
                                                            >
                                                                {
                                                                    criterion
                                                                        .process
                                                                        .name
                                                                }
                                                            </Text>
                                                        </div>

                                                        {criterion
                                                            .businessObjectOnCriterion
                                                            ?.isCompleted ||
                                                        isComplete ? (
                                                            <Icon name="CheckboxTicked" />
                                                        ) : (
                                                            <Icon name="CheckboxUnticked" />
                                                        )}
                                                    </button>
                                                </li>
                                            )
                                        )
                                        .otherwise(always(null))
                                )}
                            </StyledPhaseCriterionList>
                        )}
                    </StyledCriteria>
                </div>
                {percentageComplete === 100 && !percentageCompleteLoading ? (
                    <MoveToNextPhase
                        businessObjectId={businessObject.id}
                        processId={process.id}
                        isTerminalPhase={
                            phase.phaseIndex === process.phases.length - 1
                        }
                        onPhaseUpdated={onProgressChange}
                    />
                ) : null}
            </StyledPhaseBox>

            <ActionCompletionModal
                actionIds={incompleteActions}
                onClose={() => {
                    setIncompleteActions(undefined)
                    onProgressChange()
                }}
            />

            {showCompletionModal ? (
                <FieldCompletionModal
                    processId={process.id}
                    boId={businessObject.id}
                    onClose={() => setShowCompletionModal(false)}
                    includeFields={includeFields}
                    onFieldUpdated={handleFieldUpdated}
                />
            ) : null}
        </>
    )
}

const StyledPhaseCriterionList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 18rem;
    gap: 0.25rem;
    padding: 1rem;

    > li {
        > button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            transition: background-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

            &:hover {
                background-color: ${({ theme }) =>
                    theme.palette.ui['01'].hover};
            }

            &:active {
                background-color: ${({ theme }) =>
                    theme.palette.ui['01'].active};
            }

            padding: 0.1rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};

            > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 0.4rem;

                > span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 12rem;
                }
            }
        }
    }
`
