import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import { PrincipalFragmentDoc } from '../../Principal/__generated__/Principal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleDocumentQueryVariables = Types.Exact<{
  input: Types.GetFileInput;
}>;


export type SingleDocumentQuery = { __typename?: 'Query', file: { __typename?: 'File', id: string, name: string, sizeInBytes: number, uploadedAt: string, url: string, fileType: string, uploadedBy: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } } } };


export const SingleDocumentDocument = gql`
    query SingleDocument($input: GetFileInput!) {
  file(input: $input) {
    id
    name
    sizeInBytes
    uploadedAt
    uploadedBy {
      ...Principal
    }
    url
    fileType
  }
}
    ${PrincipalFragmentDoc}`;

/**
 * __useSingleDocumentQuery__
 *
 * To run a query within a React component, call `useSingleDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSingleDocumentQuery(baseOptions: Apollo.QueryHookOptions<SingleDocumentQuery, SingleDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleDocumentQuery, SingleDocumentQueryVariables>(SingleDocumentDocument, options);
      }
export function useSingleDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleDocumentQuery, SingleDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleDocumentQuery, SingleDocumentQueryVariables>(SingleDocumentDocument, options);
        }
export type SingleDocumentQueryHookResult = ReturnType<typeof useSingleDocumentQuery>;
export type SingleDocumentLazyQueryHookResult = ReturnType<typeof useSingleDocumentLazyQuery>;
export type SingleDocumentQueryResult = Apollo.QueryResult<SingleDocumentQuery, SingleDocumentQueryVariables>;