import { pipe } from 'lodash/fp'
import { useState } from 'react'
import { styled } from '../../../../../Adapters/Freestyled'
import { IconTextButton } from '../../../../../Components/Button'
import { TextInput } from '../../../../../Components/Input'
import { fromEvent, preventDefault } from '../../../../../Utils'
import { UserGroup } from './UserGroup'
import {
    Member_UserGroupFragmentDoc,
    useCreateUserGroupMutation,
    useManageWorkspaceUserGroupsQuery,
} from './__generated__/q'

export const UserGroups = () => {
    const { data } = useManageWorkspaceUserGroupsQuery()

    const [name, setName] = useState('')
    const [createUserGroup, { loading }] = useCreateUserGroupMutation({
        variables: {
            input: {
                name,
            },
        },
        onCompleted: () => {
            setName('')
        },
        update(cache, { data }) {
            cache.modify({
                fields: {
                    userGroups(existing = []) {
                        const groupRef = cache.writeFragment({
                            data: data?.createUserGroup,
                            fragment: Member_UserGroupFragmentDoc,
                            fragmentName: 'Member_UserGroup',
                        })
                        return [...existing, groupRef]
                    },
                },
            })
        },
    })

    return (
        <div>
            <h2>Teams</h2>
            <StyledForm onSubmit={pipe(preventDefault, createUserGroup)}>
                <TextInput
                    placeholder="Enter A New Team Name"
                    onChange={pipe(fromEvent, setName)}
                    value={name}
                />

                <IconTextButton
                    icon="Team"
                    variant="primary"
                    text="Create New Team"
                    disabled={loading || name === ''}
                    type="submit"
                    size="xSmall"
                />
            </StyledForm>
            <ul>
                {data?.userGroups.map(userGroup => (
                    <UserGroup userGroup={userGroup} key={userGroup.id} />
                ))}
            </ul>
        </div>
    )
}

const StyledForm = styled.form`
    display: flex;
    gap: 1rem;
    flex-direction: row;
    width: 100%;
`
