import { isUserPrincipal } from 'Features/Principal/helpers'
import {
    BusinessObjectValueSelectorInput,
    CreateCriterionInput,
    CreateFieldConditionCriterionInput,
    CreateProcessDelegateCriterionInput,
    CreateProcessFanoutCriterionInput,
    CreateProcessInput,
} from '__generated__'
import { match } from 'ts-pattern'
import { ProcessDef_SingleProcessQuery } from '../../__generated__/query'

type Process = ProcessDef_SingleProcessQuery['process']
type Criteria = Process['phases'][number]['criteria']
type Criterion = Criteria[number]
type Conditions = Criterion['conditions']
type Transform = Extract<
    Criterion,
    { __typename: 'ProcessFanoutPhaseCriterion' }
>['transform']
type FieldConditionPhaseCriterion = Extract<
    Criterion,
    { __typename: 'FieldConditionPhaseCriterion' }
>
type Comparator = FieldConditionPhaseCriterion['comparator']
type ValueSelector = Comparator['valueSelector']

export const processToProcessInput = (process: Process): CreateProcessInput => {
    return {
        name: process.name,
        operatesUpon: [process.operatesUpon[0].id],
        description: process.description,
        phases: process.phases.map(phase => ({
            name: phase.name,
            description: phase.description,
            criteria: criteriaToCriteriaInput(phase.criteria),
        })),
    }
}

const criteriaToCriteriaInput = (criteria: Criteria) => {
    return criteria.map(criterion =>
        match(criterion)
            .with(
                { __typename: 'ActionPhaseCriterion' },
                ({
                    description,
                    defaultDueSeconds,
                    defaultAssignee,
                    conditions,
                    purpose,
                }) => ({
                    action: {
                        description,
                        defaultDueSeconds,
                        ...(defaultAssignee && isUserPrincipal(defaultAssignee)
                            ? {
                                  defaultAssignee: {
                                      user: { userId: defaultAssignee.user.id },
                                  },
                              }
                            : {}),
                        conditions: conditionsToConditionsInput(conditions),
                        purpose,
                    } satisfies CreateCriterionInput['action'],
                })
            )
            .with(
                { __typename: 'FieldConditionPhaseCriterion' },
                ({ comparator, conditions }) => ({
                    fieldCondition: {
                        comparator: comparatorToComparatorInput(comparator),
                        conditions: conditionsToConditionsInput(conditions),
                    } satisfies CreateFieldConditionCriterionInput,
                })
            )
            .with({ __typename: 'ProcessDelegatePhaseCriterion' }, c => ({
                processDelegate: {
                    processId: c.process.id,
                    conditions: conditionsToConditionsInput(c.conditions),
                    transform: transformToTransformInput(c.transform),
                } satisfies CreateProcessDelegateCriterionInput,
            }))
            .with({ __typename: 'ProcessFanoutPhaseCriterion' }, c => ({
                processFanout: {
                    processId: c.process.id,
                    conditions: conditionsToConditionsInput(c.conditions),
                    transform: transformToTransformInput(c.transform),
                } satisfies CreateProcessFanoutCriterionInput,
            }))
            .exhaustive()
    ) satisfies CreateCriterionInput[]
}

const conditionsToConditionsInput = (conditions: Conditions) => {
    return conditions?.map(comparatorToComparatorInput)
}

const comparatorToComparatorInput = (comparator: Comparator) => {
    return {
        negate: comparator.negate,
        operator: comparator.operator,
        valueSelector: valueSelectorToValueSelectorInput(
            comparator.valueSelector
        ),
        with: comparator.with,
    }
}

const valueSelectorToValueSelectorInput = (
    valueSelector: ValueSelector
): BusinessObjectValueSelectorInput => {
    return match(valueSelector)
        .with({ __typename: 'ConstantSelector' }, ({ value }) => ({
            constant: {
                value,
            },
        }))
        .with(
            {
                __typename: 'FieldValueSelector',
            },
            value => ({
                fieldValue: {
                    fieldId: value.fieldId,
                    default: value.default,
                },
            })
        )
        .with({ __typename: 'DeepSelector' }, ({ selectors }) => ({
            deep: {
                selectors: selectors.map(valueSelectorToValueSelectorInput),
            },
        }))
        .exhaustive()
}

const transformToTransformInput = (transform: Transform) => {
    return match(transform)
        .with(
            {
                __typename: 'ListFieldDelegationTransformer',
            },
            transform => ({
                listField: {
                    fieldId: transform.field.id,
                },
            })
        )
        .with(
            {
                __typename: 'RelationFieldDelegationTransformer',
            },
            transform => ({
                relationField: {
                    fieldId: transform.field.id,
                },
            })
        )
        .otherwise(() => undefined)
}
