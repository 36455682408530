import { CenteredSpinner } from 'Components/Spinner'
import { ContactUsToast } from 'Components/Toast'
import { CreateBusinessObjectModal } from 'Features/CreateBusinessObjectModal'
import { ProcessDisplayFragment } from 'Features/ProcessDisplay/__generated__/q'
import { useModalControls } from 'Hooks'
import { uniq } from 'lodash'
import { FC, useCallback, useState } from 'react'
import { match } from 'ts-pattern'
import { SearchSelect } from './SearchSelect'
import { StyledFooter, StyledForm, StyledIconTextButton } from './styles'
import { useAddBusinessObjectsToProcess } from './useAddBusinessObjectsToProcess'
import { useAddableBusinessObjects } from './useAddableBusinessObjects'

type Props = {
    processId: string
    operatesUpon: ProcessDisplayFragment['operatesUpon'][number]
    closeModal: () => void
    addBusinessObject: ReturnType<typeof useAddBusinessObjectsToProcess>
    triggerToast: () => void
    onBusinessObjectAdded: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

export const Form: FC<Props> = ({
    operatesUpon,
    processId,
    closeModal,
    addBusinessObject,
    triggerToast,
    onBusinessObjectAdded,
}) => {
    const [includeRemovedRecords, setIncludeRemovedRecords] = useState(false)

    const addableBusinessObjects = useAddableBusinessObjects({
        processId,
        businessObjectDefinitionId: operatesUpon.id,
        includeRemoved: includeRemovedRecords,
    })

    const {
        addBusinessObjectsToProcess,
        loading,
        errorToastOpen,
        setErrorToastOpen,
    } = addBusinessObject

    const [selectedValues, setSelectedValues] = useState<string[]>([])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addBusinessObjectsToProcess(
            selectedValues,
            mode === 'single' && selectedValues.length === 1
                ? () =>
                      onBusinessObjectAdded({
                          id: selectedValues[0],
                          tab: 'overview',
                      })
                : closeModal
        )
    }

    const {
        buttonRef,
        closeModal: closeCreateModal,
        open,
        openModal,
        returnFocus,
        setOpen,
    } = useModalControls()

    const [mode, setMode] = useState<'single' | 'multiple'>('single')

    const handleBusinessObjectCreated = useCallback(
        ({ id }: { id: string }) => {
            if (mode === 'single') {
                triggerToast()
                closeModal()
                addBusinessObjectsToProcess([id], () =>
                    onBusinessObjectAdded({ id, tab: 'overview' })
                )
            } else {
                addableBusinessObjects.refetch()
                setSelectedValues(prev => uniq([...prev, id]))
            }
        },
        [
            mode,
            triggerToast,
            closeModal,
            addBusinessObjectsToProcess,
            addableBusinessObjects,
            onBusinessObjectAdded,
        ]
    )

    return (
        <StyledForm onSubmit={handleSubmit}>
            {match(addableBusinessObjects)
                .with({ hasInitiallyLoaded: false }, () => <CenteredSpinner />)
                .otherwise(({ businessObjectsToDisplay }) => (
                    <SearchSelect
                        options={businessObjectsToDisplay.map(bo => ({
                            id: bo.id,
                            label: bo.label,
                        }))}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        mode={mode}
                        setMode={setMode}
                        includeRemovedRecords={includeRemovedRecords}
                        setIncludeRemovedRecords={setIncludeRemovedRecords}
                        businessObjectDefinitionName={operatesUpon.name}
                    />
                ))}

            <StyledFooter>
                <StyledIconTextButton
                    icon="Plus"
                    text={`Create new`}
                    variant="secondary"
                    ref={buttonRef}
                    onClick={e => {
                        e.preventDefault()
                        openModal()
                    }}
                    size="small"
                />
                <StyledIconTextButton
                    icon="Share"
                    text={
                        mode === 'single'
                            ? `Add single`
                            : `Add selected (${selectedValues.length})`
                    }
                    disabled={loading || !selectedValues.length}
                    size="small"
                    variant="primary"
                />
            </StyledFooter>

            <CreateBusinessObjectModal
                businessObjectDefinitionName={operatesUpon.name}
                businessObjectDefinitionId={operatesUpon.id}
                fields={operatesUpon.fields}
                open={open}
                closeModal={closeCreateModal}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
                onBusinessObjectCreated={handleBusinessObjectCreated}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description="There was an error adding the selected records. Contact us if the problem persists"
            />
        </StyledForm>
    )
}
