import { dayjs } from 'Adapters/DayJS'
import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { DateFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { P, match } from 'ts-pattern'

type Props = {
    field: DateFieldUnique
}

const DateFieldContent: FC<Props> = ({
    field: { constraints, precision, defaultValue },
}) => (
    <Styled>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Precision:
                </Text>
                <Text as="p" variant="regular-5">
                    {precision}
                </Text>
            </div>
        </StyledConstraint>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Not before:
                </Text>
                <Text as="p" variant="regular-5">
                    {constraints.dateRange.start
                        ? dayjs(constraints.dateRange.start).format(
                              'DD MMM YYYY'
                          )
                        : '-'}
                </Text>
            </div>
        </StyledConstraint>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Not after:
                </Text>
                <Text as="p" variant="regular-5">
                    {constraints.dateRange.end
                        ? dayjs(constraints.dateRange.end).format('DD MMM YYYY')
                        : '-'}
                </Text>
            </div>
        </StyledConstraint>
        <StyledConstraint>
            <div>
                <Text as="p" variant="bold-5">
                    Default:
                </Text>
                {match(defaultValue)
                    .with({ absolute: P.string }, ({ absolute }) => (
                        <Text as="p" variant="regular-5">
                            {dayjs(absolute).format('DD MMM YYYY')}
                        </Text>
                    ))
                    .with({ relative: P.string }, ({ relative }) => (
                        <Text as="p" variant="regular-5">
                            {dayjs.duration(relative).humanize(true)}
                        </Text>
                    ))
                    .with(P.nullish, () => (
                        <Text as="p" variant="regular-5">
                            -
                        </Text>
                    ))
                    .exhaustive()}
            </div>
        </StyledConstraint>
    </Styled>
)

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledConstraint = styled.div`
    > p {
        padding-bottom: 0.5rem;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    div p:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { DateFieldContent }
