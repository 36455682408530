import { flex, styled } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { countryCodeOptions } from 'Utils'
import { FieldCommonWrapper } from './Common'
import { RequiredConstraint } from './Constraints/Required'
import { Field } from './Field'
import { FieldProps } from './types'

export const TelephoneFieldDefinitionInput: React.FC<
    FieldProps<'telephone'>
> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-${index}-default`} name="Default value">
                <StyledTelInput>
                    <Select
                        id={`bod-field-${index}-default-country-code`}
                        value={field.defaultValue?.countryCode ?? ''}
                        onValueChange={newCountryCode => {
                            onChange('defaultValue')(() => ({
                                number: field.defaultValue?.number || null,
                                countryCode: newCountryCode || null,
                            }))
                        }}
                        name={'Country Code'}
                        options={countryCodeOptions}
                    />

                    <TextInput
                        value={field.defaultValue?.number ?? ''}
                        id={`bod-field-${index}-default`}
                        onChange={e => {
                            onChange('defaultValue')(() => ({
                                number: e.target.value || null,
                                countryCode:
                                    field.defaultValue?.countryCode || null,
                            }))
                        }}
                        className="tel-number-input"
                    />
                </StyledTelInput>
            </Field>

            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
        </FieldCommonWrapper>
    )
}

const StyledTelInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .tel-number-input {
        flex: 1;
        height: 3rem;
    }
`
