import { TextInput } from 'Components/Input'
import { fromEvent } from 'Utils'
import { FieldCommonWrapper } from './Common'
import { RequiredConstraint } from './Constraints/Required'
import { Field } from './Field'
import { FieldProps } from './types'

export const URLFieldDefinitionInput: React.FC<FieldProps<'url'>> = ({
    field,
    index,
    onChange,
    onRemoved,
    name,
    disableRequiredConstraint,
}) => {
    return (
        <FieldCommonWrapper
            field={field}
            index={index}
            onChange={onChange}
            onRemoved={onRemoved}
            label={name}
        >
            <Field htmlFor={`bod-field-${index}-default`} name="Default value">
                <TextInput
                    id={`bod-field-${index}-default`}
                    value={field.defaultValue ?? ''}
                    onChange={e => onChange('defaultValue')(() => fromEvent(e))}
                />
            </Field>
            {disableRequiredConstraint ? null : (
                <RequiredConstraint
                    index={index}
                    value={field.constraints.find(
                        constraint => constraint.required
                    )}
                    onChange={constraint =>
                        onChange('constraints')(prev =>
                            constraint?.required
                                ? [...prev, constraint]
                                : prev.filter(c => !c.required)
                        )
                    }
                />
            )}
        </FieldCommonWrapper>
    )
}
