import { useCallback } from 'react'
import { Template } from './Components/Templates'
import { hasConstraints, noNullKeys } from './helpers'
import { State } from './types'

type Params = {
    setHasStarted: React.Dispatch<React.SetStateAction<boolean>>
    setState: React.Dispatch<React.SetStateAction<State>>
    resetState: () => void
}

export const useSuggestion = ({
    setHasStarted,
    setState,
    resetState,
}: Params) => {
    const handleSuggestionSelected = useCallback(
        (suggestion: Template | undefined) => {
            setHasStarted(true)

            if (!suggestion) {
                return resetState()
            }

            setState({
                ...suggestion,
                fields: suggestion.fields.map(field => ({
                    ...field,
                    type: field.type as any,
                    ...(hasConstraints(field) && {
                        constraints: (field as any).constraints.map(
                            // excuse the anys - GQL codegen makes this very difficult to do accurately
                            ({ type, ...constraint }: any) => ({
                                [type]: noNullKeys(constraint),
                            })
                        ),
                    }),
                })),
            })
        },
        [resetState, setHasStarted, setState]
    )

    return {
        handleSuggestionSelected,
    }
}
