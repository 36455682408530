import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton, DropdownDivider } from 'Components/Dropdown'
import { Toggle } from 'Components/Input'
import { Select } from 'Components/Select'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { usePossibleDelegateProcesses } from 'Hooks/usePossibleDelegateProcesses/usePossibleDelegateProcesses'
import { has } from 'Utils'
import {
    CreateProcessDelegateCriterionInput,
    CreateProcessFanoutCriterionInput,
    DelegationTransformerType,
} from '__generated__'
import { compact } from 'lodash'
import { FC, useRef } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'
import { useProcessCreator } from '../../../useProcessCreator'
import { Conditions } from '../Conditions'

type Props =
    | {
          phaseIndex: number
          criterionIndex: number
          criterion: {
              processDelegate: CreateProcessDelegateCriterionInput
          }
      }
    | {
          phaseIndex: number
          criterionIndex: number
          criterion: {
              processFanout: CreateProcessFanoutCriterionInput
          }
      }

export const SubProcessCriterion: FC<Props> = ({
    phaseIndex,
    criterionIndex,
    criterion: delegateOrFanoutCriterion,
}) => {
    const {
        state: { input },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const criterion = has(delegateOrFanoutCriterion, 'processDelegate')
        ? delegateOrFanoutCriterion.processDelegate
        : delegateOrFanoutCriterion.processFanout

    const { current: inputId } = useRef(v4())

    const { loading, processes } = usePossibleDelegateProcesses(
        input.operatesUpon
    )

    const inUseDelegateProcessesForThisPhase = new Set(
        compact(
            input.phases[phaseIndex].criteria
                .filter(c => !!c.processDelegate?.processId)
                .map(c => c.processDelegate?.processId)
        )
    )

    const availableProcesses = processes?.filter(
        process =>
            process.process.id === criterion.processId ||
            !inUseDelegateProcessesForThisPhase.has(process.process.id)
    )

    return (
        <Styled>
            <header>
                <IconTextButton
                    icon="Process"
                    text="Sub-workflow"
                    disabled
                    size="xSmall"
                    variant="ghost"
                />
                <Dropdown
                    renderOpenDropdownButton={() => (
                        <IconButton iconName="More" />
                    )}
                    renderContent={() => (
                        <>
                            <DropdownButton
                                icon="ArrowUp"
                                text="Move criterion up"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionUp',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={criterionIndex === 0}
                            />
                            <DropdownButton
                                icon="ArrowDown"
                                text="Move criterion down"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionDown',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={
                                    criterionIndex ===
                                    input.phases[phaseIndex].criteria.length - 1
                                }
                            />
                            <DropdownDivider />
                            <DropdownButton
                                icon="Delete"
                                text="Remove criterion"
                                onSelect={() => {
                                    dispatch({
                                        type: 'removeCriterion',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                            />
                        </>
                    )}
                />
            </header>

            <div>
                <VisuallyHidden>
                    <label htmlFor={inputId}>Workflow</label>
                </VisuallyHidden>
                {match({ loading, availableProcesses })
                    .with(
                        { availableProcesses: P.not(P.nullish) },
                        ({ availableProcesses }) => {
                            const selectedProcess = availableProcesses.find(
                                pr => pr.process.id === criterion.processId
                            )
                            return (
                                <>
                                    <Select
                                        id={inputId}
                                        name="processId"
                                        onValueChange={processId => {
                                            const selectedProcess =
                                                availableProcesses.find(
                                                    p =>
                                                        p.process.id ===
                                                        processId
                                                )
                                            dispatch({
                                                type: 'setProcessDelegateCriterionProcessId',
                                                payload: {
                                                    phaseIndex,
                                                    criterionIndex,
                                                    processId,
                                                },
                                            })

                                            if (
                                                !selectedProcess?.untransformed
                                            ) {
                                                dispatch({
                                                    type: 'setProcessDelegateCriterionTransform',
                                                    payload: {
                                                        phaseIndex,
                                                        criterionIndex,
                                                        transform: {
                                                            type: DelegationTransformerType.RelationField,
                                                            fieldId: '',
                                                        },
                                                    },
                                                })
                                            }
                                        }}
                                        options={
                                            availableProcesses.length > 0
                                                ? availableProcesses.map(p => ({
                                                      value: p.process.id,
                                                      text: p.process.name,
                                                  }))
                                                : [
                                                      {
                                                          value: '',
                                                          text: 'No workflows available',
                                                      },
                                                  ]
                                        }
                                        value={criterion.processId}
                                    />
                                    {selectedProcess &&
                                        !selectedProcess.untransformed && (
                                            <Select
                                                id={inputId}
                                                name="fieldId"
                                                onValueChange={fieldId =>
                                                    dispatch({
                                                        type: 'setProcessDelegateCriterionTransform',
                                                        payload: {
                                                            phaseIndex,
                                                            criterionIndex,
                                                            transform: {
                                                                type: DelegationTransformerType.RelationField,
                                                                fieldId,
                                                            },
                                                        },
                                                    })
                                                }
                                                options={
                                                    selectedProcess.fields
                                                        .length > 0
                                                        ? selectedProcess.fields.map(
                                                              p => ({
                                                                  value: p.fieldId,
                                                                  text: p.fieldName,
                                                              })
                                                          )
                                                        : [
                                                              {
                                                                  value: '',
                                                                  text: 'No fields available',
                                                              },
                                                          ]
                                                }
                                                value={
                                                    criterion.transform
                                                        ?.relationField?.fieldId
                                                }
                                            />
                                        )}
                                </>
                            )
                        }
                    )
                    .with({ loading: true }, () => <CenteredSpinner />)
                    .otherwise(() => (
                        <Text as="p" variant="regular-4">
                            An error occurred fetching workflows
                        </Text>
                    ))}
            </div>

            <Text as="label" htmlFor="fanout-or-delegate" variant="regular-6">
                Completion required?
                <Toggle
                    name="fanout-or-delegate"
                    id="fanout-or-delegate"
                    indicatorText={['Yes', 'No']}
                    checked={has(delegateOrFanoutCriterion, 'processDelegate')}
                    onCheckedChange={checked =>
                        dispatch({
                            type: checked
                                ? 'convertFanoutToDelegate'
                                : 'convertDelegateToFanout',
                            payload: {
                                phaseIndex,
                                criterionIndex,
                            },
                        })
                    }
                />
            </Text>

            {isProcessFirst ? null : (
                <Conditions
                    phaseIndex={phaseIndex}
                    criterionIndex={criterionIndex}
                />
            )}
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.5rem 0.125rem 0.125rem;
    }

    > div {
        padding: 0 0.5rem 0.5rem;
    }

    > label {
        ${flex('row', 'space-between', 'center')};
        width: 100%;
        padding: 0 0.5rem 0.5rem;
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }
`
