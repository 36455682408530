import { logger } from 'Adapters/Logger'
import { TextInput } from 'Components/Input'
import { useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { waitForDelay } from 'Utils'
import {
    ComponentProps,
    HTMLProps,
    useCallback,
    useMemo,
    useState,
} from 'react'
import { useUpdateUserMutation } from './__generated__/mutation'

type UseForm = (params: { name: string; id: string }) => {
    handleNameChange: NonNullable<ComponentProps<typeof TextInput>['onChange']>
    handleSubmit: NonNullable<HTMLProps<HTMLFormElement>['onSubmit']>
    saveEnabled: boolean
    status: 'error' | 'loading' | 'ready' | 'success'
    details: {
        name: string
    }
    showToast: boolean
    setShowToast: (value: boolean) => void
}

export const useForm: UseForm = ({ name, id }) => {
    const [update, result] = useUpdateUserMutation()

    const { status, resetMutation } = useMutationStatus(result)

    const { open, triggerToast, setOpen } = useTriggerToast()

    const [details, setDetails] = useState<ReturnType<UseForm>['details']>({
        name,
    })

    const saveEnabled = useMemo(
        () => !!(details.name && details.name !== name),
        [name, details]
    )

    const handleNameChange = useCallback<
        ReturnType<UseForm>['handleNameChange']
    >(({ currentTarget: { value: name } }) => {
        setDetails(p => ({ ...p, name }))
    }, [])

    const handleSubmit = useCallback<ReturnType<UseForm>['handleSubmit']>(
        async e => {
            e.preventDefault()
            update({
                variables: { input: { name: details.name, id } },
                onCompleted: async () => {
                    await waitForDelay(2000)
                    resetMutation()
                },
                onError: async e => {
                    logger.error('Failed to update user details', e)
                    triggerToast()
                    await waitForDelay(6000)
                    resetMutation()
                    setOpen(false)
                },
                refetchQueries: ['UserDetails'],
            })
        },
        [details, update, triggerToast, setOpen, resetMutation, id]
    )

    return {
        handleNameChange,
        handleSubmit,
        saveEnabled,
        details,
        status,
        showToast: open,
        setShowToast: setOpen,
    }
}
