import { match, P } from 'ts-pattern'
import { ProcessDef_SingleProcessQuery } from '../../../__generated__/query'

export const validateDetails = (
    process: Pick<
        ProcessDef_SingleProcessQuery['process'],
        'name' | 'description' | 'operatesUpon'
    >
): boolean =>
    match(process)
        .with({ name: P.not(''), operatesUpon: P.not([]) }, () => true)
        .otherwise(() => false)

export const validatePhases = (
    process: Pick<ProcessDef_SingleProcessQuery['process'], 'phases'>
): boolean =>
    process.phases.length > 0 &&
    process.phases.every(
        phase => phase.name && phase.criteria.every(criterionIsValid)
    )

const criterionIsValid = (
    criterion: ProcessDef_SingleProcessQuery['process']['phases'][number]['criteria'][number]
) => {
    return match(criterion)
        .with(
            { __typename: 'FieldConditionPhaseCriterion' },
            criterion =>
                (criterion.comparator.valueSelector.__typename ===
                    'FieldValueSelector' &&
                    criterion.comparator.valueSelector.fieldId) ||
                (criterion.comparator.valueSelector.__typename ===
                    'DeepSelector' &&
                    criterion.comparator.valueSelector.selectors.every(
                        selector =>
                            selector.__typename === 'FieldValueSelector' &&
                            selector.fieldId
                    ))
        )
        .with(
            { __typename: 'ActionPhaseCriterion' },
            criterion => criterion.description
        )
        .with(
            { __typename: 'ProcessFanoutPhaseCriterion' },
            criterion => criterion.process.id
        )
        .with(
            { __typename: 'ProcessDelegatePhaseCriterion' },
            criterion => criterion.process.id
        )
        .exhaustive()
}

export const validateProcess = (
    process: ProcessDef_SingleProcessQuery['process']
): boolean => validateDetails(process) && validatePhases(process)
