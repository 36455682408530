import { breakpoints } from 'Adapters/Freestyled'
import { ScreenSize } from './types'

type Response = {
    screenSize: ScreenSize
    isSmall: boolean
    isMedium: boolean
    isLarge: boolean
}

export const calculateResponse = () => {
    return {
        screenSize:
            window.innerWidth > breakpoints.medium
                ? 'large'
                : window.innerWidth > breakpoints.small
                ? 'medium'
                : 'small',
        isSmall: window.innerWidth <= breakpoints.small,
        isMedium:
            window.innerWidth > breakpoints.small &&
            window.innerWidth <= breakpoints.medium,
        isLarge: window.innerWidth > breakpoints.medium,
    } satisfies Response
}
