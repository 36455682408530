import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge'
import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { FC, useMemo } from 'react'
import ReactFlow, { Background, BackgroundVariant, Controls } from 'reactflow'
import 'reactflow/dist/style.css'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { PhaseNode } from './CustomNodes/PhaseNode'
import { ProcessNode } from './CustomNodes/ProcessNode'
import { useBusinessDomain_Visualise_ProcessesQuery } from './__generated__/query'
import { useNodesAndEdges } from './useNodesAndEdges'

export const VisualiseProcesses: FC<{}> = () => {
    const { data, loading } = useBusinessDomain_Visualise_ProcessesQuery()

    const { nodes, edges, onNodesChange } = useNodesAndEdges(
        data?.processes ?? []
    )

    const nodeTypes = useMemo(
        () => ({
            process: ProcessNode,
            phase: PhaseNode,
        }),
        []
    )
    const edgeTypes = useMemo(() => ({ smart: SmartBezierEdge }), [])

    return (
        <Styled>
            <header>
                <menu>
                    <Breadcrumb area="processes" />
                </menu>
                <Text as="h2" variant="bold-4">
                    Visualise Workflows
                </Text>
                <div />
            </header>
            {!data || loading ? (
                <CenteredSpinner />
            ) : (
                <ReactFlow
                    fitView
                    nodesDraggable={true}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    proOptions={{
                        hideAttribution: true,
                    }}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                >
                    <Background
                        variant={BackgroundVariant.Dots}
                        gap={12}
                        size={1}
                    />
                    <Controls />
                </ReactFlow>
            )}
        </Styled>
    )
}

const Styled = styled.section`
    height: 100%;
    display: grid;
    grid-template: auto 1fr / minmax(0, 1fr);

    > header {
        ${flex('row', 'space-between', 'center')};
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding: 0.5rem 1rem 0.5rem 0;
        overflow-x: auto;

        @media (min-width: ${breakpoints.large}px) {
            padding: 0.75rem 2rem 0.75rem 2rem;
        }
    }

    > header > menu {
        ${flex('row-reverse', 'flex-start', 'center')};
        gap: 0.5rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
        }
    }
`
