import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { FC } from 'react'

type Props = {
    disabled: boolean
    onClick: () => void
    className?: string
}

export const EditFieldButton: FC<Props> = ({
    disabled,
    onClick,
    className = '',
}) => {
    return (
        <StyledEditFieldButton
            icon="Save"
            text="Save"
            variant="primary"
            disabled={disabled}
            onClick={onClick}
            className={className}
        />
    )
}

const StyledEditFieldButton = styled(IconTextButton)`
    height: 100%;

    &:disabled {
        opacity: 0;
        width: 0;
        margin-left: 0;
        padding: 0;

        span {
            display: none;
        }
    }
`
