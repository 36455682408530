import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { Text, bold5 } from 'Components/Text'
import { UpdateAction } from 'Features/UpdateAction'
import { Updates } from 'Features/Updates'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { ActionTimelineCardQuery } from '../__generated__/query'

type Props = {
    action: ActionTimelineCardQuery['action']
    onUpdated: () => void
    onDeleted: () => void
}

const ActionDisplay: FC<Props> = ({ action, onUpdated, onDeleted }) => {
    const [tab, setTab] = useState<'details' | 'updates'>('details')
    const navigate = useNavigate()

    return (
        <Styled>
            <header>
                <Text as="h2" variant="bold-2">
                    {action.name}
                </Text>
                <menu>
                    <button
                        onClick={() => setTab('details')}
                        data-current={tab === 'details'}
                    >
                        <Icon name="List" />
                        <Text as="span" variant="regular-5">
                            Details
                        </Text>
                    </button>
                    <button
                        onClick={() => setTab('updates')}
                        data-current={tab === 'updates'}
                    >
                        <Icon name="Send" />
                        <Text as="span" variant="regular-5">
                            Updates
                        </Text>
                    </button>
                </menu>
            </header>
            {match(tab)
                .with('details', () => (
                    <StyledDetailsSection key={tab}>
                        <UpdateAction
                            actionId={action.id}
                            onCompleted={onUpdated}
                            onDeleted={onDeleted}
                        />
                    </StyledDetailsSection>
                ))
                .with('updates', () => (
                    <StyledUpdatesSection key={tab}>
                        {match(action)
                            .with(
                                { extendedBy: P.not(P.nullish) },
                                ({ extendedBy }) => (
                                    <StyledUpdates
                                        businessObjectId={extendedBy.id}
                                    />
                                )
                            )
                            .otherwise(() => (
                                <StyledActionsNotEnabled>
                                    <Text as="h3" variant="bold-4">
                                        Updates disabled
                                    </Text>
                                    <Text as="p" variant="regular-5">
                                        Action updates are not enabled for this
                                        workspace. They can be enabled in the
                                        workspace manager.
                                    </Text>
                                    <footer>
                                        <IconTextButton
                                            icon="AngleRight"
                                            iconRight
                                            text="Configure actions"
                                            variant="primary"
                                            size="xSmall"
                                            onClick={() =>
                                                navigate(
                                                    '/manage-workspace/model/actions'
                                                )
                                            }
                                        />
                                    </footer>
                                </StyledActionsNotEnabled>
                            ))}
                    </StyledUpdatesSection>
                ))
                .exhaustive()}
        </Styled>
    )
}

const Styled = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    > header {
        padding: 0.5rem 4vw 0;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > header h2 {
        padding-bottom: 1rem;
    }

    > header menu {
        display: flex;
        justify-content: flex-start;
        gap: 4vw;

        button {
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem 0.75rem 0.5rem 0.5rem;
            justify-content: center;
            align-items: center;
            border-bottom: 0.25rem solid
                ${({ theme }) => theme.palette.ui['07'].normal}00;
            transition: border-color
                    ${({ theme }) => theme.animation.buttonTransitionDuration}
                    ease,
                opacity
                    ${({ theme }) => theme.animation.buttonTransitionDuration}
                    ease;

            .icon svg {
                --icon-width: 1rem;
                width: var(--icon-width);
                height: var(--icon-width);
                min-width: var(--icon-width);
                min-height: var(--icon-width);
            }

            span:last-child {
                ${bold5};
            }
        }

        button[data-current='true'] {
            border-color: ${({ theme }) => theme.palette.ui['07'].normal};
        }

        button[data-current='false'] {
            opacity: 0.56;
        }
    }

    > section {
    }
`

const StyledDetailsSection = styled.section`
    padding: 1rem 4vw;
    flex: 1;
    overflow: hidden;
`

const StyledUpdatesSection = styled.section`
    padding: 1rem 4vw 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledUpdates = styled(Updates)`
    height: 100%;
    display: flex;
    align-items: center;
`

const StyledActionsNotEnabled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > footer {
        padding-top: 0.5rem;
    }

    > footer button {
        width: 100%;
    }
`

export { ActionDisplay }
