import { css, styled } from 'Adapters/Freestyled'
import { IconTextButton, StatusButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { bold5 } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import { noop } from 'lodash'
import { FC } from 'react'
import { useForm } from './useForm'

type Direction = 'column' | 'row'

type Props = {
    tenant: {
        name: string
    }
    onSuccess?: () => void
    onCancel?: () => void
    direction?: Direction
}

const WorkspaceNameForm: FC<Props> = ({
    tenant,
    onSuccess,
    onCancel,
    direction = 'column',
}) => {
    const {
        handleNameChange,
        handleSubmit,
        handleNameBlur,
        saveEnabled,
        details,
        status,
        showToast,
    } = useForm({ tenant, onSuccess })

    return (
        <Styled onSubmit={handleSubmit} $direction={direction}>
            <div>
                <div>
                    <label htmlFor="tenant-name-input">Workspace name</label>
                    <TextInput
                        id="tenant-name-input"
                        name="tenant-name-input"
                        value={details.name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        placeholder="Enter the name of your workspace"
                    />
                </div>
            </div>
            <footer>
                {onCancel && (
                    <IconTextButton
                        icon="Close"
                        text="Cancel"
                        type="button"
                        onClick={onCancel}
                        variant="secondary"
                    />
                )}
                <StatusButton
                    status={status}
                    text={{
                        error: 'Save',
                        loading: 'Saving',
                        ready: 'Save',
                        success: 'Saved',
                    }}
                    disabled={!saveEnabled}
                />
            </footer>
            <SimpleToast
                open={showToast}
                onOpenChange={noop}
                description="Unable to save tenant details"
            />
        </Styled>
    )
}

const columnStyles = css`
    flex-direction: column;
`

const rowStyles = css`
    flex-direction: row;
    align-items: flex-end;

    > footer {
        > button {
            max-height: none;
            height: 2.813rem;
        }
    }
`

const Styled = styled.form<{ $direction: Direction }>`
    display: flex;
    gap: 1rem;

    [data-screen-size='large'] & {
        ${({ $direction }) =>
            $direction === 'column' ? columnStyles : rowStyles}
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        flex-direction: column;
        gap: 0.75rem;
    }

    > div {
        flex: 1;
    }

    label {
        ${bold5};
        display: block;
        padding-bottom: 0.5rem;
    }

    > footer {
        display: flex;
        gap: 1rem;
    }

    > footer button {
        flex: 1;
    }
`

export { WorkspaceNameForm }
