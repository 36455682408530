import { styled } from 'Adapters/Freestyled'
import { bold5 } from 'Components/Text'
import { FC } from 'react'
import { Day } from './types'

const MonthHeader: FC<{ dayIndex: number; day: Day }> = ({ day, dayIndex }) => {
    return dayIndex === 0 || day.d.format('DD') === '01' ? (
        <Styled
            $colStart={dayIndex + 1}
            $colEnd={dayIndex + day.d.endOf('month').diff(day.d, 'day') + 2}
        >
            <div>
                <h2>{day.d.format('MMM YYYY')}</h2>
            </div>
        </Styled>
    ) : null
}

const Styled = styled.header<{ $colStart: number; $colEnd: number }>`
    position: sticky;
    top: 0;
    z-index: 2;
    grid-row: 1 / 2;
    grid-column: ${({ $colStart, $colEnd }) => `${$colStart} / ${$colEnd}`};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    padding-top: 2rem;

    > div {
        padding: 0 1rem;
        border-left: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > div h2 {
        ${bold5}
        line-height: 2rem;
    }
`

export { MonthHeader }
