import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ContactUsToast } from 'Components/Toast'
import { FC } from 'react'
import { State } from '../useCreator'
import { GettingStartedCard } from './Card'
import { GettingStartedSection } from './Section'

type Props = {
    templates: State['templates']
    onTemplateSelected: (template: State['templates'][number]) => void
    restart: () => void
    suggestionToastProps: {
        open: boolean
        setOpen: (open: boolean) => void
    }
}

const Templates: FC<Props> = ({
    templates,
    onTemplateSelected,
    restart,
    suggestionToastProps: { open, setOpen },
}) => (
    <Styled
        heading="Start with a template"
        description="Use one of our templates to get you started. You will be able to edit the template however you see
        fit."
    >
        <div>
            <ul>
                {templates.map(template => (
                    <li key={template.definition.name}>
                        <GettingStartedCard
                            name={template.definition.name}
                            description={template.definition.description}
                            onClick={() => onTemplateSelected(template)}
                            fieldCount={template.definition.fields.length}
                            updates={template.enableUpdates}
                            documents={template.enableDocuments}
                        />
                    </li>
                ))}
            </ul>
        </div>

        <footer>
            <IconTextButton
                icon="ArrowLeft"
                text="Go back"
                onClick={restart}
                variant="secondary"
            />
        </footer>

        <ContactUsToast
            open={open}
            onOpenChange={setOpen}
            description={'We were unable to generate a suggestion'}
        />
    </Styled>
)

const Styled = styled(GettingStartedSection)`
    display: grid;
    grid-template: auto 1fr / 1fr;
    height: 80%;

    > div {
        height: 100%;
        overflow: auto;
        padding: 1rem;
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        box-shadow: inset 0 0 1rem -0.5rem ${({ theme }) => theme.palette.ui['04'].normal};
    }

    > div > ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    > footer {
        ${flex('row', 'flex-end', 'center')};
        padding: 1rem 2rem;
    }
`

export { Templates }
