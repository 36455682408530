import { encodeURIComponentObj } from 'Utils'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { ProcessDisplayFragment } from '../__generated__/q'
import type {
    Action,
    DispatchAction,
    ProcessDisplay,
    ViewConfig,
} from '../types'
import { useBuildConfig } from '../utils'
import { applyViewConfig } from './applyViewConfig'
import {
    handleClearAllFilters,
    handleMoveGroup,
    handleSetFilters,
    handleSetGroupColor,
    handleSetGroupingSelection,
    handleToggleFieldDisplay,
    handleToggleShowCompleted,
} from './handle'
import { useLatestView } from './latestView'

const reducer = (config: ViewConfig, action: Action): ViewConfig =>
    match(action)
        .with(
            { type: 'SetGroupingSelection' },
            handleSetGroupingSelection(config)
        )
        .with({ type: 'SetGroupColor' }, handleSetGroupColor(config))
        .with({ type: 'MoveGroupUp' }, handleMoveGroup(config))
        .with({ type: 'MoveGroupDown' }, handleMoveGroup(config))
        .with({ type: 'SetFilters' }, handleSetFilters(config))
        .with({ type: 'ClearAllFilters' }, handleClearAllFilters(config))
        .with({ type: 'ToggleFieldDisplay' }, handleToggleFieldDisplay(config))
        .with(
            { type: 'ToggleShowCompleted' },
            handleToggleShowCompleted(config)
        )
        .exhaustive()

type UseView = (_: {
    process: ProcessDisplayFragment
    latestViewKey: string
}) => {
    config: ViewConfig
    dispatch: DispatchAction
    display: ProcessDisplay
}
export const useView: UseView = ({ process, latestViewKey }) => {
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    useLatestView({
        urlSearchParams,
        setUrlSearchParams,
        process,
        uniqueKey: latestViewKey,
    })

    const buildConfig = useBuildConfig(process)

    const config = useMemo(
        () => buildConfig(urlSearchParams),
        [buildConfig, urlSearchParams]
    )

    const display = useMemo(
        () => applyViewConfig(config)(process),
        [config, process]
    )

    const dispatch = useCallback<DispatchAction>(
        action => {
            setUrlSearchParams(
                prev => {
                    const next = new URLSearchParams(prev)
                    const config = buildConfig(next)
                    const nextConfig = config && reducer(config, action)
                    const configParam =
                        nextConfig && encodeURIComponentObj(nextConfig)
                    next.set('config', configParam || '')
                    return next
                },
                { replace: true }
            )
        },
        [setUrlSearchParams, buildConfig]
    )

    return {
        config,
        dispatch,
        display,
    }
}
