import { Text } from 'Components/Text'
import { FC } from 'react'
import { Card } from './Card'
import { Available_TemplatesQuery } from './__generated__/query'

type Props = {
    template: Available_TemplatesQuery['workspaceTemplates'][number]['templates'][number]
    isSelected: boolean
    onClick: () => void
    imageURL?: string
}

export const Template: FC<Props> = ({
    template,
    isSelected,
    onClick,
    imageURL,
}) => {
    return (
        <Card
            isSelected={isSelected}
            onClick={onClick}
            title={template.name}
            imageURL={imageURL}
        >
            <Text variant="regular-5" as="p">
                {template.description}
            </Text>
        </Card>
    )
}
