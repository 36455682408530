import { Content } from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { Field, FieldType } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import { match } from 'ts-pattern'
import { BooleanFieldContent } from './BooleanFieldContent'
import { CurrencyFieldContent } from './CurrencyFieldContent'
import { DateFieldContent } from './DateFieldContent'
import { EmailFieldContent } from './EmailFieldContent'
import { ListFieldContent } from './ListFieldContent'
import { NumberFieldContent } from './NumberFieldContent'
import { RelationFieldContent } from './RelationFieldContent'
import { SelectFieldContent } from './SelectFieldContent'
import { TelephoneFieldContent } from './TelephoneFieldContent'
import { TextFieldContent } from './TextFieldContent'
import { UrlFieldContent } from './UrlFieldContent'
import { UserFieldContent } from './UserFieldContent/UserFieldContent'

type Props = {
    field: Field
}

const FieldContent: FC<Props> = ({ field }) => (
    <Styled>
        <div>
            <StyledDescription>
                <Text as="p" variant="regular-5">
                    {field.description || 'No description provided.'}
                </Text>
            </StyledDescription>
            {match(field)
                .with({ type: FieldType.Boolean }, f => (
                    <BooleanFieldContent field={f} />
                ))
                .with({ type: FieldType.Currency }, f => (
                    <CurrencyFieldContent field={f} />
                ))
                .with({ type: FieldType.Date }, f => (
                    <DateFieldContent field={f} />
                ))
                .with({ type: FieldType.Document }, () => null)
                .with({ type: FieldType.Email }, f => (
                    <EmailFieldContent field={f} />
                ))
                .with({ type: FieldType.List }, f => (
                    <ListFieldContent field={f} />
                ))
                .with({ type: FieldType.Number }, f => (
                    <NumberFieldContent field={f} />
                ))
                .with({ type: FieldType.Relation }, f => (
                    <RelationFieldContent field={f} />
                ))
                .with({ type: FieldType.Select }, f => (
                    <SelectFieldContent field={f} />
                ))
                .with({ type: FieldType.Telephone }, f => (
                    <TelephoneFieldContent field={f} />
                ))
                .with({ type: FieldType.Text }, f => (
                    <TextFieldContent field={f} />
                ))
                .with({ type: FieldType.Url }, f => (
                    <UrlFieldContent field={f} />
                ))
                .with({ type: FieldType.User }, f => (
                    <UserFieldContent field={f} />
                ))
                .exhaustive()}
        </div>
    </Styled>
)

const slideDown = keyframes`
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
        opacity: 1;
    }
    `

const slideUp = keyframes`
    from {
        height: var(--radix-accordion-content-height);
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
`

const Styled = styled(Content)`
    overflow: hidden;
    animation-duration: ${({ theme }) => theme.animation.mid};
    animation-timing-function: ease;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    &[data-state='open'] {
        animation-name: ${slideDown};
    }

    &[data-state='closed'] {
        animation-name: ${slideUp};
    }

    > div {
        padding: 0.5rem 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

const StyledDescription = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    max-width: 70ch;
`

export { FieldContent }
