import { useFlow } from '@frigade/react'
import { Variant } from 'Components/Button/IconButton/types'
import { ControlledModal } from 'Components/Modal'
import { FrigadeHint } from 'Features/Frigade/FrigadeHint'
import { useModalControls } from 'Hooks'
import { FC, ReactNode } from 'react'
import { IDENTIFIER, STEP_IDENTIFIER } from './constants'
import { StyledTutorialTrigger } from './styles'

type Props = {
    title: string
    description?: string
    content: ReactNode
    buttonVariant: Variant
}

export const TutorialModalDesktop: FC<Props> = ({
    title,
    description = '',
    content,
    buttonVariant,
}) => {
    const { open, setOpen, openModal, buttonRef, returnFocus } =
        useModalControls()

    const { flow } = useFlow(IDENTIFIER)
    const hint = flow?.steps.get(STEP_IDENTIFIER)

    if (!hint || !flow) return null

    return (
        <>
            <FrigadeHint flowId={IDENTIFIER} stepId={STEP_IDENTIFIER}>
                <StyledTutorialTrigger
                    iconName={'Question'}
                    onClick={() => {
                        openModal?.()

                        if (!hint.$state.completed) {
                            hint.complete()
                            flow.complete()
                        }
                    }}
                    ref={buttonRef}
                    variant={buttonVariant}
                />
            </FrigadeHint>

            <ControlledModal
                renderContent={() => content}
                title={title}
                description={description}
                hideDescription={!description}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}
