import { CenteredSpinner } from 'Components/Spinner'
import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { VisualiseBusinessObjectDefinitions } from '../VisualiseBusinessObjectDefinitions'
import { Directory } from './Directory/Directory'
import { SingleDefinition } from './Single/Single'
import { useModelManagerBusinessObjectDefinitionsQuery } from './__generated__/q'

const BusinessObjectDefinitions: FC = () => {
    const { data, loading } = useModelManagerBusinessObjectDefinitionsQuery()

    return match((!loading || undefined) && data)
        .with(undefined, () => (
            <section>
                <CenteredSpinner />
            </section>
        ))
        .otherwise(({ businessObjectDefinitions }) => (
            <Routes>
                <Route
                    index
                    element={
                        <Directory definitions={businessObjectDefinitions} />
                    }
                />
                <Route
                    path="visualise"
                    element={
                        <SmallScreenWarning
                            element={<VisualiseBusinessObjectDefinitions />}
                        />
                    }
                />
                {businessObjectDefinitions.map(({ id, name }, i) => (
                    <Route
                        key={id}
                        path={slugify(name, { lower: true })}
                        element={
                            <SingleDefinition
                                id={id}
                                navOptions={{
                                    selected: i,
                                    options: businessObjectDefinitions.map(
                                        def => ({
                                            path: slugify(def.name, {
                                                lower: true,
                                            }),
                                            text: def.name,
                                        })
                                    ),
                                }}
                            />
                        }
                    />
                ))}
            </Routes>
        ))
}

export { BusinessObjectDefinitions }
