import { flex, styled } from 'Adapters/Freestyled'
import { ViewMode } from 'Components/DocumentPicker/types'
import { DocumentMetadata } from 'Components/Documents'
import { SearchBar } from 'Components/SearchBar'
import { regular6 } from 'Components/Text'
import { Upload } from 'Components/Upload'
import { FC } from 'react'
import { Field, SortOptions } from '../types'
import { Filter } from './Filter'
import { Sort } from './Sort'
import { ViewToggle } from './ViewToggle'

type Props = {
    viewMode: ViewMode
    changeViewMode: (viewMode: ViewMode) => void

    searchTerms: string
    updateSearchTerms: (event: React.ChangeEvent<HTMLInputElement>) => void
    clearSearchTerms: () => void

    sort: SortOptions
    updateSort: (field: Field) => void

    toggleSelection: (document: DocumentMetadata) => void

    availableFileTypes: string[]
    fileTypeFilter: Set<string>
    toggleFileTypeFilter: (fileType: string) => void
}

export const MenuBar: FC<Props> = ({
    viewMode,
    changeViewMode,
    searchTerms,
    updateSearchTerms,
    clearSearchTerms,
    sort,
    updateSort,
    toggleSelection,
    toggleFileTypeFilter,
    availableFileTypes,
    fileTypeFilter,
}) => {
    return (
        <StyledMenuBar>
            <li className="search">
                <StyledSearchBar
                    searchTerms={searchTerms}
                    placeholderText={'Search documents'}
                    updateSearchTerms={updateSearchTerms}
                    clearSearchTerms={clearSearchTerms}
                />
            </li>

            <li>
                <Upload
                    onUploadCompleted={toggleSelection}
                    existingSelections={{ allowExistingSelection: false }}
                />
            </li>

            <li className="divider" />

            <li>
                <Filter
                    toggleFileTypeFilter={toggleFileTypeFilter}
                    availableFileTypes={availableFileTypes}
                    fileTypeFilter={fileTypeFilter}
                />
            </li>

            <li className="divider" />

            <li>
                <Sort sort={sort} updateSort={updateSort} />
            </li>

            <li className="divider" />

            <ViewToggle viewMode={viewMode} changeViewMode={changeViewMode} />
        </StyledMenuBar>
    )
}

const StyledMenuBar = styled.menu`
    ${flex('row', 'flex-end', 'center')};
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    margin-bottom: 1rem;

    li {
        padding: 0.25rem 0.5rem;
        list-style: none;
    }

    li.search {
        margin-right: auto;
    }

    li.divider {
        height: 100%;
        width: 1px;
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
        padding: 0;
    }
`

const StyledSearchBar = styled(SearchBar)`
    width: 20rem;
    height: 2rem;
    border: none;

    input {
        ${regular6};
    }

    .icon svg {
        min-height: 1em;
        min-width: 1em;
        max-height: 1em;
        max-width: 1em;
    }
`
