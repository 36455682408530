import { withProps } from '@udecode/cn'
import {
    createBoldPlugin,
    createItalicPlugin,
    createStrikethroughPlugin,
    createUnderlinePlugin,
    MARK_BOLD,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_UNDERLINE,
} from '@udecode/plate-basic-marks'
import { createComboboxPlugin } from '@udecode/plate-combobox'
import {
    createPlugins,
    PlateLeaf,
    RenderAfterEditable,
} from '@udecode/plate-common'
import { createLinkPlugin, ELEMENT_LINK } from '@udecode/plate-link'
import {
    createMentionPlugin,
    ELEMENT_MENTION,
    ELEMENT_MENTION_INPUT,
} from '@udecode/plate-mention'
import {
    createParagraphPlugin,
    ELEMENT_PARAGRAPH,
} from '@udecode/plate-paragraph'
import { DeprecatedLink } from './Components/DeprecatedLink'
import { Link } from './Components/Link'
import { LinkFloatingToolbar } from './Components/LinkFloatingToolbar'
import { Mention } from './Components/Mention'
import { MentionInput } from './Components/MentionInput'
import { Paragraph } from './Components/Paragraph'
import {
    createDeprecatedLinkPlugin,
    ELEMENT_DEPRECATED_LINK,
} from './CustomPlugins/deprecatedLink'

export const plugins = createPlugins(
    [
        createParagraphPlugin(),
        createLinkPlugin({
            renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
        }),
        createComboboxPlugin(),
        createMentionPlugin({
            options: {
                createMentionNode: (item: any) => ({
                    value: item.text,
                    id: item.userId,
                }),
            },
        }),
        createBoldPlugin(),
        createItalicPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        createDeprecatedLinkPlugin(),
    ],
    {
        components: {
            [ELEMENT_PARAGRAPH]: Paragraph,
            paragraph: Paragraph, // backwards compatability with previous version
            [ELEMENT_LINK]: Link,
            [ELEMENT_DEPRECATED_LINK]: DeprecatedLink, // backwards compatability with previous version
            [ELEMENT_MENTION]: Mention,
            [ELEMENT_MENTION_INPUT]: MentionInput,
            [MARK_BOLD]: withProps(PlateLeaf, { as: 'strong' }),
            [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
            [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' }),
            [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' }),
        },
    }
)
