import { Content, Overlay } from '@radix-ui/react-dialog'
import { boxShadow } from 'Adapters/Freestyled'
import styled, { css, keyframes } from 'styled-components'

const DURATION = '300ms'

const enter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const exit = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

const slideUp = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`

const slideDown = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
`

const centerStyles = css<{ $fullScreen?: boolean }>`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${({ theme }) => theme.borderRadius.large};
    width: ${({ $fullScreen }) =>
        $fullScreen ? 'calc(100vw - 2rem)' : 'max-content'};
    height: ${({ $fullScreen }) =>
        $fullScreen ? 'calc(100dvh - 2rem)' : 'max-content'};
`

const bottomStyles = css<{ $fullScreen?: boolean }>`
    bottom: 0;
    left: 0;
    border-top-left-radius: ${({ theme }) => theme.borderRadius.large};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.large};
    border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: ${({ $fullScreen }) =>
        $fullScreen ? 'calc(100vw - 2rem)' : 'var(--safe-width)'};
    height: ${({ $fullScreen }) =>
        $fullScreen ? 'calc(100dvh - 1rem)' : 'max-content'};
`

const fullScreenStyles = css`
    display: grid;
    grid-template: auto auto 1fr / 1fr;

    > h2 {
        grid-row: 1 / 2;
    }
    > p {
        grid-row: 2 / 3;
    }
    > div {
        grid-row: 3 / 4;
        padding-top: 0.5rem;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
`

const mobileContentEnterAnimation = css`
    animation-name: ${enter}, ${slideUp};
`

const desktopContentEnterAnimation = css`
    animation-name: ${enter};
`

const mobileContentExitAnimation = css`
    animation-name: ${exit}, ${slideDown};
`

const desktopContentExitAnimation = css`
    animation-name: ${exit};
`

export const StyledContent = styled(Content)<{
    $location: 'center' | 'bottom'
    $fullScreen?: boolean
}>`
    ${({ $location }) =>
        $location === 'center' ? centerStyles : bottomStyles};
    ${({ $fullScreen }) => $fullScreen && fullScreenStyles};

    ${boxShadow()};
    background-color: white;
    position: fixed;
    padding: 1.25rem 1.5rem;

    &[data-state='open'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-out;
        ${({ $location }) =>
            $location === 'bottom'
                ? mobileContentEnterAnimation
                : desktopContentEnterAnimation}
    }

    &[data-state='closed'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-in;
        ${({ $location }) =>
            $location === 'bottom'
                ? mobileContentExitAnimation
                : desktopContentExitAnimation}
    }
`

export const StyledOverlay = styled(Overlay)`
    position: fixed;
    inset: 0;
    background-color: ${({ theme }) => theme.palette.overlay['01'].normal};
    cursor: pointer;

    &[data-state='open'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-out;
        animation-name: ${enter};
    }

    &[data-state='closed'] {
        animation-duration: ${DURATION};
        animation-timing-function: ease-in;
        animation-name: ${exit};
    }
`
