import { compact } from 'lodash'
import { match } from 'ts-pattern'
import { useProcessCreator } from '../../useProcessCreator'
import { useSuggestPhasesLazyQuery } from './__generated__/query'

export const useSuggestedPhases = (description: string) => {
    const {
        state: { input },
        dispatch,
    } = useProcessCreator()

    const [query, { loading, error }] = useSuggestPhasesLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                name: input.name,
                operatesUpon: input.operatesUpon[0],
                description,
            },
        },
        onCompleted: data => {
            dispatch({
                type: 'suggestionApplied',
                payload: {
                    name: input.name,
                    phases: data.suggestedPhases.phases.map(phase => ({
                        name: phase.name,
                        description: phase.description,
                        criteria: compact(
                            phase.criteria.map(criterion =>
                                match(criterion)
                                    .with(
                                        { __typename: 'ActionPhaseCriterion' },
                                        criterion => ({
                                            action: {
                                                description:
                                                    criterion.description,
                                                ...(criterion.defaultDueSeconds && {
                                                    defaultDueSeconds:
                                                        criterion.defaultDueSeconds,
                                                }),
                                                ...(criterion.purpose && {
                                                    purpose: criterion.purpose,
                                                }),
                                            },
                                        })
                                    )
                                    .with(
                                        {
                                            __typename:
                                                'FieldConditionPhaseCriterion',
                                            comparator: {
                                                valueSelector: {
                                                    __typename:
                                                        'FieldValueSelector',
                                                },
                                            },
                                        },
                                        criterion => ({
                                            fieldCondition: {
                                                comparator: {
                                                    operator:
                                                        criterion.comparator
                                                            .operator,
                                                    negate: criterion.comparator
                                                        .negate,
                                                    with: criterion.comparator
                                                        .with,
                                                    valueSelector: {
                                                        fieldValue: {
                                                            fieldId:
                                                                criterion
                                                                    .comparator
                                                                    .valueSelector
                                                                    .fieldId,
                                                        },
                                                    },
                                                },
                                            },
                                        })
                                    )
                                    .otherwise(() => null)
                            )
                        ),
                    })),
                },
            })
        },
    })

    const getSuggestion = () => {
        input.phases.forEach((_, index) => {
            dispatch({
                type: 'removePhase',
                payload: { index: input.phases.length - 1 - index }, // reverse otherwise indices change
            })
        })
        return query()
    }

    return {
        getSuggestion,
        loading,
        error,
    }
}
