import { CreateProcessInput } from '__generated__'
import { createContext, FC, ReactNode, useContext, useReducer } from 'react'
import { match } from 'ts-pattern'
import { BusinessObjectDefinitionsCreatorQuery } from '../../BusinessObjectDefinitionCreator/__generated__/q'
import { CreateIntegrationState } from '../../IntegrationEditor'
import {
    handleActionCriterionPurposeChanged,
    handleAddActionCriterion,
    handleAddFieldConditionCriterion,
    handleAddProcessDelegateCriterion,
    handleAddProcessFanoutCriterion,
    handleAIInputChanged,
    handleApplyCriterionConditions,
    handleConvertDelegateToFanout,
    handleConvertFanoutToDelegate,
    handleCustomLabelToggled,
    handleFieldAdded,
    handleFieldEdited,
    handleFieldMoved,
    handleFieldRemoved,
    handleInsertPhase,
    handleIntegrationAdded,
    handleIntegrationRemoved,
    handleIntegrationUpdated,
    handleLabelEdited,
    handleMoveCriterionDown,
    handleMoveCriterionUp,
    handleMovePhaseLeft,
    handleMovePhaseRight,
    handleRemoveCriterion,
    handleRemovePhase,
    handleSetActionCriterionDefaultAssignee,
    handleSetActionCriterionDescription,
    handleSetCriterionDueTime,
    handleSetDescription,
    handleSetFieldConditionCriterionFieldId,
    handleSetName,
    handleSetOperatesUpon,
    handleSetPhaseDescription,
    handleSetPhaseName,
    handleSetProcessDelegateCriterionProcessId,
    handleSetProcessDelegateCriterionTransform,
    handleSetProcessFanoutCriterionProcessId,
    handleSetProcessFanoutCriterionTransform,
    handleShowDetailsStep,
    handleShowPhasesStep,
    handleSuggestionApplied,
    stepChanged,
} from './handle'
import { getAIAnswersOrDefault } from './persistAIAnswers'
import { Action, State } from './types'

const Ctx = createContext<
    | {
          state: State
          dispatch: React.Dispatch<Action>
          onCompleted: (process: { id: string; name: string }) => void
      }
    | undefined
>(undefined)

const reducer = (state: State, action: Action): State => {
    const next = match(action)
        .with({ type: 'showDetailsStep' }, handleShowDetailsStep(state))
        .with({ type: 'showPhasesStep' }, handleShowPhasesStep(state))
        .with({ type: 'stepChanged' }, stepChanged(state))
        .with({ type: 'setName' }, handleSetName(state))
        .with({ type: 'setDescription' }, handleSetDescription(state))
        .with({ type: 'setOperatesUpon' }, handleSetOperatesUpon(state))
        .with({ type: 'insertPhase' }, handleInsertPhase(state))
        .with({ type: 'setPhaseName' }, handleSetPhaseName(state))
        .with({ type: 'setPhaseDescription' }, handleSetPhaseDescription(state))
        .with({ type: 'removePhase' }, handleRemovePhase(state))
        .with({ type: 'movePhaseLeft' }, handleMovePhaseLeft(state))
        .with({ type: 'movePhaseRight' }, handleMovePhaseRight(state))
        .with({ type: 'addActionCriterion' }, handleAddActionCriterion(state))
        .with(
            { type: 'addFieldConditionCriterion' },
            handleAddFieldConditionCriterion(state)
        )
        .with(
            { type: 'setFieldConditionCriterionFieldId' },
            handleSetFieldConditionCriterionFieldId(state)
        )
        .with(
            { type: 'setActionCriterionDescription' },
            handleSetActionCriterionDescription(state)
        )
        .with({ type: 'removeCriterion' }, handleRemoveCriterion(state))
        .with({ type: 'moveCriterionUp' }, handleMoveCriterionUp(state))
        .with({ type: 'moveCriterionDown' }, handleMoveCriterionDown(state))
        .with({ type: 'setCriterionDueTime' }, handleSetCriterionDueTime(state))
        .with({ type: 'suggestionApplied' }, handleSuggestionApplied(state))
        .with(
            { type: 'applyCriterionConditions' },
            handleApplyCriterionConditions(state)
        )
        .with(
            { type: 'addProcessFanoutCriterion' },
            handleAddProcessFanoutCriterion(state)
        )
        .with(
            { type: 'setProcessFanoutCriterionProcessId' },
            handleSetProcessFanoutCriterionProcessId(state)
        )
        .with(
            { type: 'addProcessDelegateCriterion' },
            handleAddProcessDelegateCriterion(state)
        )
        .with(
            { type: 'setProcessDelegateCriterionProcessId' },
            handleSetProcessDelegateCriterionProcessId(state)
        )
        .with(
            { type: 'setProcessFanoutCriterionTransform' },
            handleSetProcessFanoutCriterionTransform(state)
        )
        .with(
            { type: 'setProcessDelegateCriterionTransform' },
            handleSetProcessDelegateCriterionTransform(state)
        )
        .with({ type: 'integrationAdded' }, handleIntegrationAdded(state))
        .with({ type: 'integrationUpdated' }, handleIntegrationUpdated(state))
        .with({ type: 'integrationRemoved' }, handleIntegrationRemoved(state))
        .with(
            { type: 'setActionCriterionDefaultAssignee' },
            handleSetActionCriterionDefaultAssignee(state)
        )
        .with(
            { type: 'convertDelegateToFanout' },
            handleConvertDelegateToFanout(state)
        )
        .with(
            { type: 'convertFanoutToDelegate' },
            handleConvertFanoutToDelegate(state)
        )
        .with({ type: 'fieldAdded' }, handleFieldAdded(state))
        .with({ type: 'fieldRemoved' }, handleFieldRemoved(state))
        .with({ type: 'fieldMoved' }, handleFieldMoved(state))
        .with({ type: 'fieldEdited' }, handleFieldEdited(state))
        .with({ type: 'customLabelToggled' }, handleCustomLabelToggled(state))
        .with({ type: 'labelEdited' }, handleLabelEdited(state))
        .with({ type: 'aiInputChanged' }, handleAIInputChanged(state))
        .with(
            { type: 'actionCriterionPurposeChanged' },
            handleActionCriterionPurposeChanged(state)
        )
        .exhaustive()

    return next
}

const ProcessCreatorProvider: FC<{
    preselectedBO?: {
        id: string
        name: string
    }
    render: (step: State['step']) => ReactNode
    onCompleted: (process: { id: string; name: string }) => void
    initialProcess?: CreateProcessInput
    initialIntegrations?: Array<CreateIntegrationState & { id: string }>
    processId?: string
    definitionData: BusinessObjectDefinitionsCreatorQuery
    initialStep?: State['step']
}> = ({
    preselectedBO,
    render,
    onCompleted,
    initialProcess,
    processId,
    initialIntegrations,
    definitionData,
    initialStep = 'start',
}) => {
    const aiANswers = getAIAnswersOrDefault()

    const [state, dispatch] = useReducer(reducer, {
        step: initialStep,
        id: processId,
        input: initialProcess ?? {
            name: '',
            description: undefined,
            operatesUpon: preselectedBO ? [preselectedBO.id] : [],
            phases: [
                {
                    name: '',
                    criteria: [],
                },
            ],
        },
        integrations: initialIntegrations ?? [],
        initialIntegrations: initialIntegrations ?? [],
        fields: [],
        label: {
            value: '',
            isCustom: false,
        },
        relationOptions: definitionData.businessObjectDefinitions.map(def => ({
            text: def.name,
            value: def.id,
        })),
        userOptions: definitionData.users.map(user => ({
            text: user.name,
            value: user.id,
        })),
        currencyOptions: definitionData.currencies.map(option => ({
            text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
            value: option.code,
        })),
        ai: {
            industry: aiANswers.industry,
            size: aiANswers.size,
            team: aiANswers.team,
        },
    })

    return (
        <Ctx.Provider value={{ state, dispatch, onCompleted }}>
            {render(state.step)}
        </Ctx.Provider>
    )
}

const useProcessCreator = () => {
    const c = useContext(Ctx)
    if (!c)
        throw new Error(
            'useProcessCreator was called outside of ProcessCreatorProvider'
        )

    return {
        ...c,
        isProcessFirst: c.state.input.operatesUpon.length === 0,
    }
}

export { ProcessCreatorProvider, useProcessCreator }
