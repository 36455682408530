import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveBusinessObjectViewMutationVariables = Types.Exact<{
  input: Types.SaveViewInput;
}>;


export type SaveBusinessObjectViewMutation = { __typename?: 'Mutation', saveView: { __typename?: 'View', id: string, name: string, configuration: string, viewOf: { __typename?: 'ViewOfActions' } | { __typename?: 'ViewOfBusinessObjects', businessObjectDefinition: { __typename?: 'BusinessObjectDefinition', id: string, name: string } } | { __typename?: 'ViewOfProcess' } } };

export type DeleteBusinessObjectsViewMutationVariables = Types.Exact<{
  input: Types.DeleteViewInput;
}>;


export type DeleteBusinessObjectsViewMutation = { __typename?: 'Mutation', deleteView: string };


export const SaveBusinessObjectViewDocument = gql`
    mutation SaveBusinessObjectView($input: SaveViewInput!) {
  saveView(input: $input) {
    id
    name
    viewOf {
      ... on ViewOfBusinessObjects {
        businessObjectDefinition {
          id
          name
        }
      }
    }
    configuration
  }
}
    `;
export type SaveBusinessObjectViewMutationFn = Apollo.MutationFunction<SaveBusinessObjectViewMutation, SaveBusinessObjectViewMutationVariables>;

/**
 * __useSaveBusinessObjectViewMutation__
 *
 * To run a mutation, you first call `useSaveBusinessObjectViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBusinessObjectViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBusinessObjectViewMutation, { data, loading, error }] = useSaveBusinessObjectViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBusinessObjectViewMutation(baseOptions?: Apollo.MutationHookOptions<SaveBusinessObjectViewMutation, SaveBusinessObjectViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBusinessObjectViewMutation, SaveBusinessObjectViewMutationVariables>(SaveBusinessObjectViewDocument, options);
      }
export type SaveBusinessObjectViewMutationHookResult = ReturnType<typeof useSaveBusinessObjectViewMutation>;
export type SaveBusinessObjectViewMutationResult = Apollo.MutationResult<SaveBusinessObjectViewMutation>;
export type SaveBusinessObjectViewMutationOptions = Apollo.BaseMutationOptions<SaveBusinessObjectViewMutation, SaveBusinessObjectViewMutationVariables>;
export const DeleteBusinessObjectsViewDocument = gql`
    mutation DeleteBusinessObjectsView($input: DeleteViewInput!) {
  deleteView(input: $input)
}
    `;
export type DeleteBusinessObjectsViewMutationFn = Apollo.MutationFunction<DeleteBusinessObjectsViewMutation, DeleteBusinessObjectsViewMutationVariables>;

/**
 * __useDeleteBusinessObjectsViewMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessObjectsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessObjectsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessObjectsViewMutation, { data, loading, error }] = useDeleteBusinessObjectsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBusinessObjectsViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessObjectsViewMutation, DeleteBusinessObjectsViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessObjectsViewMutation, DeleteBusinessObjectsViewMutationVariables>(DeleteBusinessObjectsViewDocument, options);
      }
export type DeleteBusinessObjectsViewMutationHookResult = ReturnType<typeof useDeleteBusinessObjectsViewMutation>;
export type DeleteBusinessObjectsViewMutationResult = Apollo.MutationResult<DeleteBusinessObjectsViewMutation>;
export type DeleteBusinessObjectsViewMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessObjectsViewMutation, DeleteBusinessObjectsViewMutationVariables>;