import { flex, styled } from 'Adapters/Freestyled'
import { NumberInput, Toggle } from 'Components/Input'
import { motion } from 'framer-motion'
import { Field } from '../Field'

type NumberOfSelectionsConstraintValue = {
    numberOfSelections?: { min?: number | null; max?: number | null } | null
}

type Props = {
    index: number
    onChange: (value: NumberOfSelectionsConstraintValue | undefined) => void
    value: NumberOfSelectionsConstraintValue | undefined
    maxCeiling: number
}

export const NumberOfSelectionsConstraint: React.FC<Props> = ({
    index,
    onChange,
    value,
    maxCeiling,
}) => {
    const hasMinSet = typeof value?.numberOfSelections?.min !== 'undefined'
    const hasMaxSet = typeof value?.numberOfSelections?.max !== 'undefined'

    return (
        <StyledNumberOfSelections
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <Field
                htmlFor={`bod-field-NumberOfSelections-min-${index}`}
                name="Minimum Selections"
            >
                <Toggle
                    name={`bod-field-NumberOfSelections-toggle-min${index}`}
                    id={`bod-field-NumberOfSelections-toggle-min${index}`}
                    indicatorText={['', 'No Minimum']}
                    checked={hasMinSet}
                    onCheckedChange={checked =>
                        onChange(
                            checked
                                ? {
                                      numberOfSelections: {
                                          min: 0,
                                          max: value?.numberOfSelections?.max,
                                      },
                                  }
                                : {
                                      numberOfSelections: {
                                          max: value?.numberOfSelections?.max,
                                      },
                                  }
                        )
                    }
                />
                {hasMinSet && (
                    <NumberInput
                        id={`bod-field-numberOfSelections-min-${index}`}
                        value={value?.numberOfSelections?.min ?? 0}
                        onChange={e =>
                            onChange({
                                numberOfSelections: {
                                    min: Math.min(
                                        Math.max(Number(e.target.value), 0),
                                        maxCeiling
                                    ),
                                    max: value?.numberOfSelections?.max,
                                },
                            })
                        }
                    />
                )}
            </Field>
            <Field
                htmlFor={`bod-field-NumberOfSelections-max-${index}`}
                name="Maximum Selections"
            >
                <Toggle
                    name={`bod-field-NumberOfSelections-toggle-max${index}`}
                    id={`bod-field-NumberOfSelections-toggle-max${index}`}
                    indicatorText={['', 'No Maximum']}
                    checked={hasMaxSet}
                    onCheckedChange={checked =>
                        onChange(
                            checked
                                ? {
                                      numberOfSelections: {
                                          min: value?.numberOfSelections?.min,
                                          max: 0,
                                      },
                                  }
                                : {
                                      numberOfSelections: {
                                          min: value?.numberOfSelections?.min,
                                      },
                                  }
                        )
                    }
                />
                {hasMaxSet && (
                    <NumberInput
                        id={`bod-field-numberOfSelections-max-${index}`}
                        value={value?.numberOfSelections?.max ?? 0}
                        onChange={e =>
                            onChange({
                                numberOfSelections: {
                                    min: value?.numberOfSelections?.min,
                                    max: Math.max(
                                        Math.min(
                                            Number(e.target.value),
                                            maxCeiling
                                        ),
                                        value?.numberOfSelections?.min ?? 0
                                    ),
                                },
                            })
                        }
                    />
                )}
            </Field>
        </StyledNumberOfSelections>
    )
}

const StyledNumberOfSelections = styled(motion.div)`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100%;

    > div > div > div:last-of-type {
        margin-left: 0.5rem;
    }
`
