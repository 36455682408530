import { Text } from 'Components/Text'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC } from 'react'
import { useDragBusinessObjectCard } from '../dnd/useDragBusinessObjectCard'
import { DisplayFields } from './DisplayFields'
import { StyledCard, StyledContent } from './styles'

type Props = {
    businessObject: BusinessObjectTableItemFragment
    openDrawer: (id: string) => void
    groupId: string
}

export const DraggableCard: FC<Props> = ({
    businessObject,
    openDrawer,
    groupId,
}) => {
    const { ref, dragging } = useDragBusinessObjectCard(
        businessObject.id,
        groupId
    )

    return (
        <StyledCard ref={ref} $dragging={dragging}>
            <StyledContent onClick={() => openDrawer(businessObject.id)}>
                <header>
                    <Text as="h4" variant="bold-4">
                        {businessObject.label}
                    </Text>
                </header>
                <DisplayFields obj={businessObject} />
            </StyledContent>
        </StyledCard>
    )
}
