import { groupBy, intersection } from 'lodash'
import { byTypename } from 'Utils'
import {
    usePossible_Delegate_FieldsQuery,
    usePossible_Delegate_ProcessesQuery,
} from './__generated__/query'

export const usePossibleDelegateProcesses = (operatesUpon: string[]) => {
    const delegateProcesses = usePossible_Delegate_ProcessesQuery()
    const delegateBODs = usePossible_Delegate_FieldsQuery()

    const fields =
        delegateBODs.data?.businessObjectDefinitions
            .filter(bod => operatesUpon.includes(bod.id))
            .flatMap(bod => bod.fields) ?? []

    const relationFields = fields
        .filter(byTypename('RelationFieldDefinition' as const))
        .flatMap(relationField =>
            relationField.constraints
                .filter(byTypename('RelationTypeConstraint' as const))
                .flatMap(constraint =>
                    constraint.types.map(t => ({
                        fieldName: relationField.name,
                        fieldId: relationField.id,
                        onBOD: t.id,
                    }))
                )
        )

    const fieldsByBusinessObjectId = groupBy(relationFields, f => f.onBOD)

    const processes = delegateProcesses.data?.processes.map(process => ({
        process,
        fields: process.operatesUpon.flatMap(
            operatesUpon => fieldsByBusinessObjectId[operatesUpon.id] ?? []
        ),
        // if the target process works on the same definition as the source process, it can be sent untransformed
        untransformed:
            intersection(
                operatesUpon,
                process.operatesUpon.map(o => o.id)
            ).length > 0,
    }))

    return {
        processes,
        loading: delegateProcesses.loading || delegateBODs.loading,
    }
}
