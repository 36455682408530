import { useMemo } from 'react'
import { EmailFieldConstraints } from '__generated__'
import { defaultConstraints, EmailConstraintsMap } from './types'

type Params = {
    emailConstraints: EmailFieldConstraints[]
}

export const useEmailConstraints = ({ emailConstraints }: Params) => {
    const constraints = useMemo(() => {
        return emailConstraints.reduce<EmailConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'RequiredConstraint') {
                    return { ...out, required: true }
                }

                return out
            },
            defaultConstraints
        )
    }, [emailConstraints])

    const constraintsTooltip = useMemo(() => {
        if (constraints.required) return 'Required'
        return ''
    }, [constraints])

    return { constraints, constraintsTooltip }
}
