import { FieldType } from '__generated__'

type TextFieldValue = string | undefined
type URLFieldValue = string | undefined
type NumberFieldValue = number | undefined
type BooleanFieldValue = boolean
type EmailFieldValue = string | undefined
type TelephoneFieldValue = {
    countryCode: string | undefined
    number: string | undefined
}
type UserFieldValue = string | undefined
type DocumentFieldValue = string | undefined
type DateFieldValue = string | undefined
type SelectFieldValue = string[]
type RelationFieldValue = string
type CurrencyFieldValue =
    | {
          currencyCode: string | undefined
          amount: number | undefined
      }
    | undefined

export type FieldValue =
    | TextFieldValue
    | URLFieldValue
    | NumberFieldValue
    | BooleanFieldValue
    | EmailFieldValue
    | TelephoneFieldValue
    | UserFieldValue
    | SelectFieldValue
    | RelationFieldValue
    | CurrencyFieldValue

export type ListFieldValue =
    | { document: string }[]
    | { user: string }[]
    | { url: string }[]
    | { relation: string }[]
    | { email: string }[]
    | { date: string }[]

export const fieldValueIsTelephoneFieldValue = (
    fieldValue: FieldValue | ListFieldValue
): fieldValue is TelephoneFieldValue => {
    return typeof fieldValue === 'object' && fieldValue.hasOwnProperty('number')
}

export const fieldValueIsCurrencyFieldValue = (
    fieldValue: FieldValue | ListFieldValue
): fieldValue is CurrencyFieldValue => {
    return (
        typeof fieldValue === 'object' &&
        fieldValue.hasOwnProperty('currencyCode')
    )
}

export type CreateFormState = Record<
    string,
    {
        type: FieldType
        value: FieldValue | ListFieldValue
        errors: string[] | undefined
    }
>

export type Action =
    | {
          type: 'updateTextField'
          payload: { id: string; value: TextFieldValue }
      }
    | {
          type: 'updateURLField'
          payload: { id: string; value: URLFieldValue }
      }
    | {
          type: 'updateNumberField'
          payload: {
              id: string
              value: NumberFieldValue
          }
      }
    | {
          type: 'updateBooleanField'
          payload: {
              id: string
              value: BooleanFieldValue
          }
      }
    | {
          type: 'updateEmailField'
          payload: {
              id: string
              value: EmailFieldValue
          }
      }
    | {
          type: 'updateTelephoneField'
          payload: {
              id: string
              value: TelephoneFieldValue
          }
      }
    | {
          type: 'updateUserField'
          payload: {
              id: string
              value: UserFieldValue
          }
      }
    | {
          type: 'updateDocumentField'
          payload: {
              id: string
              value: DocumentFieldValue
          }
      }
    | {
          type: 'updateDateField'
          payload: {
              id: string
              value: DateFieldValue
          }
      }
    | {
          type: 'updateSelectField'
          payload: {
              id: string
              value: SelectFieldValue
          }
      }
    | {
          type: 'updateRelationField'
          payload: {
              id: string
              value: RelationFieldValue
          }
      }
    | {
          type: 'updateListField'
          payload: {
              id: string
              value: ListFieldValue
          }
      }
    | {
          type: 'updateCurrencyField'
          payload: {
              id: string
              value: CurrencyFieldValue
          }
      }
    | { type: 'setErrors'; payload: { id: string; errors: string[] } }

export type Dispatch = React.Dispatch<Action>
