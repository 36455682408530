import { compact } from 'lodash'
import { useEffect } from 'react'
import { useEdgesState, useNodesState } from 'reactflow'
import { match } from 'ts-pattern'
import { getLayoutedElements } from './layout'

type Criterion =
    | {
          __typename: 'ProcessDelegatePhaseCriterion'
          process: {
              id: string
              name: string
          }
      }
    | {
          __typename: 'ProcessFanoutPhaseCriterion'
          process: {
              id: string
              name: string
          }
      }
    | { __typename: 'ActionPhaseCriterion' }
    | { __typename: 'FieldConditionPhaseCriterion' }
    | { __typename: 'IntegrationPhaseCriterion' }

type Phase = {
    phaseIndex: number
    name: string
    criteria: Criterion[]
}

type Process = {
    id: string
    name: string
    backstop?: { id: string; name: string } | null
    phases: Phase[]
}

export const useNodesAndEdges = (processes: Process[]) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges] = useEdgesState([])

    useEffect(() => {
        getLayoutedElements(
            processes
                .slice()
                .sort((a, b) => {
                    const aLinksToB = a.phases.some(p =>
                        p.criteria.some(c =>
                            c.__typename === 'ProcessDelegatePhaseCriterion'
                                ? c.process.id === b.id
                                : false
                        )
                    )
                    const bLinksToA = b.phases.some(p =>
                        p.criteria.some(c =>
                            c.__typename === 'ProcessDelegatePhaseCriterion'
                                ? c.process.id === a.id
                                : false
                        )
                    )

                    if (aLinksToB) return -1
                    if (bLinksToA) return 1
                    return 0
                })

                .map(process => ({
                    id: process.id,
                    data: { label: process.name },
                    type: 'process',
                    zIndex: 100,
                    children: process.phases.map(phase => ({
                        id: `${process.id}-${phase.phaseIndex}`,
                        data: {
                            label: phase.name,
                            hasSubProcess: phase.criteria.some(c =>
                                [
                                    'ProcessDelegatePhaseCriterion',
                                    'ProcessFanoutPhaseCriterion',
                                ].includes(c.__typename)
                            ),
                            isFirstPhase: phase.phaseIndex === 0,
                            isFinalPhase:
                                phase.phaseIndex === process.phases.length - 1,
                            hasBackstop: !!process.backstop,
                        },
                        type: 'phase',
                        parentNode: process.id,
                        extent: 'parent',
                        draggable: false,
                        zIndex: 900,
                    })),
                })) ?? [],
            compact(
                processes.flatMap((process, i) => [
                    ...(process.backstop
                        ? process.phases.map(phase => ({
                              id: `${process.id}-${phase.phaseIndex}-${
                                  process.backstop!.id
                              }`,
                              source: `${process.id}-${phase.phaseIndex}`,
                              sourceHandle: 'backstop',
                              target: process.backstop!.id,
                              zIndex: 500,
                          }))
                        : []),
                    ...process.phases.flatMap(phase => {
                        const nextPhase = process.phases[phase.phaseIndex + 1]

                        return compact([
                            nextPhase && {
                                id: `${process.id}-${phase.phaseIndex}-${nextPhase.phaseIndex}`,
                                source: `${process.id}-${phase.phaseIndex}`,
                                sourceHandle: 'next',
                                targetHandle: 'previous',
                                target: `${process.id}-${nextPhase.phaseIndex}`,
                                zIndex: 500,
                            },
                            ...phase.criteria.flatMap(
                                (criterion, criterionIndex) => {
                                    return match(criterion)
                                        .with(
                                            {
                                                __typename:
                                                    'ProcessDelegatePhaseCriterion',
                                            },
                                            criterion => [
                                                {
                                                    id: `${process.id}-${phase.phaseIndex}-${criterionIndex}-out`,
                                                    source: `${process.id}-${phase.phaseIndex}`,
                                                    sourceHandle:
                                                        'subprocess-out',
                                                    target: criterion.process
                                                        .id,
                                                    type: 'smart',
                                                    zIndex: 500,
                                                },
                                                {
                                                    id: `${process.id}-${phase.phaseIndex}-${criterionIndex}-in`,
                                                    source: criterion.process
                                                        .id,
                                                    targetHandle:
                                                        'subprocess-in',
                                                    target: `${process.id}-${phase.phaseIndex}`,
                                                    type: 'smart',
                                                    zIndex: 500,
                                                },
                                            ]
                                        )
                                        .with(
                                            {
                                                __typename:
                                                    'ProcessFanoutPhaseCriterion',
                                            },
                                            criterion => ({
                                                id: `${process.id}-${phase.phaseIndex}-${criterionIndex}-out`,
                                                source: `${process.id}-${phase.phaseIndex}`,
                                                sourceHandle: 'subprocess-out',
                                                target: criterion.process.id,
                                                type: 'smart',
                                                zIndex: 500,
                                            })
                                        )
                                        .otherwise(() => undefined)
                                }
                            ),
                        ])
                    }),
                ]) ?? []
            )
        ).then(({ nodes, edges }) => {
            setNodes(nodes)
            setEdges(edges)
        })
    }, [setNodes, setEdges, processes])

    return {
        nodes,
        edges,
        onNodesChange,
    }
}
