import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { NameCriteriaTooltip } from 'Components/NameCriteriaTooltip'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { SaveProcess } from '../SaveProcess'
import { useProcessEditor_BusinessObjectsQuery } from '../__generated__/query'
import { useProcessEditor } from '../useProcessEditor'
import { Phase } from './Phase'

const PhasesStep: FC = () => {
    const { data } = useProcessEditor_BusinessObjectsQuery()
    const {
        state: { process },
        dispatch,
    } = useProcessEditor()

    return (
        <Styled>
            <div>
                <header>
                    <div>
                        <div className="process-name">
                            <Text as="h3" variant="bold-2">
                                {process.name}
                            </Text>

                            <NameCriteriaTooltip />
                        </div>

                        <div>
                            <Text as="p" variant="regular-4">
                                Operates upon{' '}
                                {(data &&
                                    data.businessObjectDefinitions.find(
                                        def =>
                                            def.id ===
                                            process.operatesUpon[0].id
                                    )?.name) ||
                                    '...'}
                            </Text>
                            {process.description && (
                                <Text as="p" variant="regular-5">
                                    {process.description}
                                </Text>
                            )}
                        </div>
                    </div>
                </header>

                <div>
                    <ul>
                        {process.phases.map((_, i) => (
                            <Phase key={i} index={i} />
                        ))}
                    </ul>
                </div>
            </div>
            <footer>
                <IconTextButton
                    icon="AngleLeft"
                    text="Edit details"
                    variant="secondary"
                    onClick={() => {
                        dispatch({ type: 'showDetailsStep' })
                    }}
                />
                <SaveProcess />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    width: calc(100vw - 6rem);
    height: calc(100vh - 8rem);
    padding-top: 1rem;
    gap: 1.5rem;
    overflow: hidden;
    display: grid;
    grid-template: minmax(0, 1fr) auto / minmax(0, 1fr);

    > div {
        grid-row: 1 / 2;
        border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    > div > header {
        padding: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    > div > header > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    > div > header > div {
        p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }
    }

    .process-name {
        ${flex('row', 'flex-start', 'center')};
        gap: 1rem;
    }

    > div > div {
        overflow: auto;
        flex: 1;
        padding-bottom: 1rem;
    }

    > div > div > ul {
        display: flex;
        gap: 1rem;
        padding-left: 2rem;
        height: 100%;
    }

    > div > div > ul::after {
        content: '';
        flex: 0 0 1rem;
    }

    > footer {
        grid-row: 2 / 3;
        display: flex;
        justify-content: space-between;
    }
`

export { PhasesStep }
