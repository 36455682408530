import { CenteredSpinner } from 'Components/Spinner'
import { DetailsForm, useUserDetailsQuery } from 'Features/UserDetails'
import { FC } from 'react'
import { P, match } from 'ts-pattern'

import { ChecklistItem } from './ChecklistItem'

export const SetName: FC = () => {
    const { data, loading } = useUserDetailsQuery()

    return (
        <ChecklistItem stepId="set-name">
            {match({ data, loading })
                .with({ loading: true, data: P.nullish }, () => (
                    <CenteredSpinner />
                ))
                .with({ loading: false, data: P.nullish }, () => null)
                .with(
                    { data: P.not(P.nullish) },
                    ({
                        data: {
                            currentUser: { name, id },
                        },
                    }) => <DetailsForm name={name} id={id} direction="row" />
                )
                .exhaustive()}
        </ChecklistItem>
    )
}
