/*
 * Derives a status from Apollo useMutation loading + error
 */

import { MutationResult } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

type Status = 'ready' | 'loading' | 'error' | 'success'

export const useMutationStatus = ({
    loading,
    error,
    reset,
}: MutationResult<unknown>) => {
    const [status, setStatus] = useState<Status>('ready')

    useEffect(() => {
        setStatus(prev => {
            if (error) return 'error'
            if (loading) return 'loading'
            if (prev === 'loading') return 'success'
            return 'ready'
        })
    }, [error, loading])

    const resetMutation = useCallback(() => {
        reset()
        setStatus('ready')
    }, [reset])

    return {
        status,
        resetMutation,
    }
}
