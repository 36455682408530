import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { regular5 } from 'Components/Text'
import { AnchorProps, Upload } from 'Components/Upload'
import { BODField } from 'Root/App/Main/BusinessObjects/Hooks/types'
import { compact, isEqual, uniqBy } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { EditFieldButton } from '../../../EditFieldButton'
import { useUpdateBusinessObjectField } from '../../../useUpdateBusinessObjectField'
import { ListValue } from '../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { DocumentDisplay } from './DocumentDisplay'
import { useDocConstraints } from './useDocConstraints'

type Props = BODField<'DocumentFieldDefinition'> & {
    businessObjectId: string
    listName: string
    listId: string
    listValue: ListValue
    onComplete: () => void
}

const generateInitialValues = (
    listValue: ListValue
): { id: string; name: string }[] =>
    compact(
        listValue.map(field =>
            match(field)
                .with(
                    { __typename: 'BusinessObjectDocumentField' },
                    ({ docValue }) => ({
                        id: docValue?.id ?? '',
                        name: docValue?.name ?? '',
                    })
                )
                .otherwise(() => null)
        )
    )

export const DocumentListField: FC<Props> = ({
    businessObjectId,
    docConstraints,
    listName,
    listId,
    listValue,
    onComplete,
}) => {
    const { constraints, constraintsTooltip } = useDocConstraints({
        docConstraints,
    })

    const [initialValues, setInitialValues] = useState(
        generateInitialValues(listValue)
    )
    const [documentsList, setDocumentsList] = useState(
        generateInitialValues(listValue)
    )

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const hasChanged = useMemo(() => {
        if (!isEqual(initialValues, documentsList)) return true
        return false
    }, [documentsList, initialValues])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
            list={documentsList.map(({ name, id }) => (
                <StyledListItem key={id}>
                    <DocumentDisplay id={id} name={name} />
                    <IconButton
                        className="remove-button"
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setDocumentsList(prev =>
                                prev.filter(doc => doc.id !== id)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <Upload
                anchor={AddDocumentButton}
                anchorProps={{
                    text: 'Add documents',
                }}
                onUploadCompleted={document => {
                    setDocumentsList(prev => [...prev, document])
                }}
                existingSelections={{
                    selectMode: 'multiple',
                    allowExistingSelection: true,
                    existingSelections: documentsList.map(doc => doc.id),
                    onExistingDocumentSelected: documents => {
                        setDocumentsList(prev =>
                            uniqBy(
                                [
                                    ...prev,
                                    ...documents.map(({ id, name }) => ({
                                        id,
                                        name,
                                    })),
                                ],
                                document => document.id
                            )
                        )
                    },
                }}
            />

            <EditFieldButton
                disabled={!hasChanged || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            list: {
                                fieldDefinitionId: listId,
                                previousValue: initialValues.map(val => ({
                                    document: val.id,
                                })),
                                value: documentsList.map(val => ({
                                    document: val.id,
                                })),
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            setInitialValues(documentsList)
                            onComplete()
                        },
                    })
                }}
            />
        </ListFieldWrapper>
    )
}

const AddDocumentButton: FC<AnchorProps<{ text: string }>> = ({
    buttonRef,
    onClick,
    text,
}) => (
    <StyledAddDocumentButton
        icon="Document"
        text={text}
        ref={buttonRef}
        onClick={onClick}
    />
)

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    a {
        color: inherit;
        text-decoration: none;
    }

    .remove-button {
        margin-left: auto;
    }
`
