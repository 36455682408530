import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModelManagerBusinessObjectDefinitionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModelManagerBusinessObjectDefinitionsQuery = { __typename?: 'Query', businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string, description?: string | null, fields: Array<{ __typename?: 'BooleanFieldDefinition', id: string } | { __typename?: 'CurrencyFieldDefinition', id: string } | { __typename?: 'DateFieldDefinition', id: string } | { __typename?: 'DocumentFieldDefinition', id: string } | { __typename?: 'EmailFieldDefinition', id: string } | { __typename?: 'ListFieldDefinition', id: string } | { __typename?: 'NumberFieldDefinition', id: string } | { __typename?: 'RelationFieldDefinition', id: string } | { __typename?: 'SelectFieldDefinition', id: string } | { __typename?: 'TelephoneFieldDefinition', id: string } | { __typename?: 'TextFieldDefinition', id: string } | { __typename?: 'URLFieldDefinition', id: string } | { __typename?: 'UpdatesFieldDefinition', id: string } | { __typename?: 'UserFieldDefinition', id: string }>, usage: { __typename?: 'BusinessObjectDefinitionUsage', instanceCount: number, processes: Array<{ __typename?: 'Process', id: string }> } }> };


export const ModelManagerBusinessObjectDefinitionsDocument = gql`
    query ModelManagerBusinessObjectDefinitions {
  businessObjectDefinitions {
    id
    name
    description
    fields {
      id
    }
    usage {
      processes {
        id
      }
      instanceCount
    }
  }
}
    `;

/**
 * __useModelManagerBusinessObjectDefinitionsQuery__
 *
 * To run a query within a React component, call `useModelManagerBusinessObjectDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelManagerBusinessObjectDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelManagerBusinessObjectDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelManagerBusinessObjectDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<ModelManagerBusinessObjectDefinitionsQuery, ModelManagerBusinessObjectDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelManagerBusinessObjectDefinitionsQuery, ModelManagerBusinessObjectDefinitionsQueryVariables>(ModelManagerBusinessObjectDefinitionsDocument, options);
      }
export function useModelManagerBusinessObjectDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelManagerBusinessObjectDefinitionsQuery, ModelManagerBusinessObjectDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelManagerBusinessObjectDefinitionsQuery, ModelManagerBusinessObjectDefinitionsQueryVariables>(ModelManagerBusinessObjectDefinitionsDocument, options);
        }
export type ModelManagerBusinessObjectDefinitionsQueryHookResult = ReturnType<typeof useModelManagerBusinessObjectDefinitionsQuery>;
export type ModelManagerBusinessObjectDefinitionsLazyQueryHookResult = ReturnType<typeof useModelManagerBusinessObjectDefinitionsLazyQuery>;
export type ModelManagerBusinessObjectDefinitionsQueryResult = Apollo.QueryResult<ModelManagerBusinessObjectDefinitionsQuery, ModelManagerBusinessObjectDefinitionsQueryVariables>;