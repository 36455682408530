import * as Collapsible from '@radix-ui/react-collapsible'
import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { TextAreaInput, TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC, useState } from 'react'
import { Field } from '../Field'
import { ProcessCreator_BusinessObjectsQuery } from '../__generated__/query'
import { useProcessCreator } from '../useProcessCreator'

type Props = {
    data: ProcessCreator_BusinessObjectsQuery | undefined
}

export const BasicInformation: FC<Props> = ({ data }) => {
    const {
        state: { input },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const [open, setOpen] = useState(true)

    return (
        <StyledBasicInformation open={open} onOpenChange={setOpen}>
            <StyledContent>
                <section>
                    <Field
                        label="Workflow Name"
                        htmlFor="name-input"
                        input={
                            <TextInput
                                id="name-input"
                                placeholder="Workflow Name"
                                value={input.name}
                                onChange={e =>
                                    dispatch({
                                        type: 'setName',
                                        payload: { name: e.target.value },
                                    })
                                }
                                hasError={input.name.length > 30}
                            />
                        }
                        errorMessage={
                            input.name.length > 30
                                ? 'Name must be 30 characters or less'
                                : undefined
                        }
                    />
                    <Field
                        label="Workflow Description"
                        htmlFor="desc-input"
                        input={
                            <TextAreaInput
                                id="desc-input"
                                placeholder="Workflow Description"
                                value={input.description ?? ''}
                                rows={1}
                                maxRows={1}
                                onChange={e =>
                                    dispatch({
                                        type: 'setDescription',
                                        payload: {
                                            description: e.target.value,
                                        },
                                    })
                                }
                            />
                        }
                    />
                </section>

                <div className="operates-upon">
                    {isProcessFirst ? null : (
                        <>
                            <Icon name="Cube" />
                            <Text as="span" variant="regular-4">
                                {(data &&
                                    data.businessObjectDefinitions.find(
                                        def => def.id === input.operatesUpon[0]
                                    )?.name) ||
                                    '...'}
                            </Text>
                        </>
                    )}
                </div>
            </StyledContent>

            <Collapsible.Trigger asChild>
                <StyledCollapseButton
                    icon={open ? 'AngleUp' : 'AngleDown'}
                    text={
                        open
                            ? 'Hide Basic Information'
                            : 'Show Basic Information'
                    }
                    variant="secondary"
                    size="xSmall"
                />
            </Collapsible.Trigger>
        </StyledBasicInformation>
    )
}

const StyledBasicInformation = styled(Collapsible.Root)`
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    margin-bottom: 1rem;

    @media (min-width: ${breakpoints.large}px) {
        margin-left: 5.24rem;
    }

    &[data-state='open'] {
        padding: 1rem;
    }

    &[data-state='closed'] {
        padding: 0.5rem;
    }

    > button {
        left: 50%;
        transform: translateX(-50%);
    }
`

const StyledContent = styled(Collapsible.Content)`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > section:first-child > div {
        flex-grow: 1;
    }

    .operates-upon {
        ${flex('row', 'flex-start', 'center')};
        gap: 1rem;
        margin-top: 1rem;
    }

    > section {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 1rem;

        @media (min-width: ${breakpoints.large}px) {
            flex-direction: row;
            align-items: flex-start;
            gap: 1rem;
        }

        > div {
            width: 100%;
        }
    }
`

const StyledCollapseButton = styled(IconTextButton)`
    width: 15rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
`
