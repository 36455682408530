import { styled } from 'Adapters/Freestyled'
import { Spinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { ComponentProps, FC } from 'react'
import { P, match } from 'ts-pattern'
import { ProcessDisplay, ViewConfig } from '../types'
import { PhaseGroupSection } from './Group'

type Props = {
    display: ProcessDisplay
    processId: string
    cardSettings: ViewConfig['cardSettings']
} & Pick<ComponentProps<typeof PhaseGroupSection>, 'cardInteractions'>

const Phases: FC<Props> = ({
    processId,
    display,
    cardSettings,
    cardInteractions,
}) =>
    match(display)
        .with(P.nullish, () => (
            <StyledLoading>
                <Spinner />
            </StyledLoading>
        ))
        .otherwise(({ phases }) => (
            <Styled
                $cols={phases.length}
                $rows={Object.values(phases[0].groups).length || 1}
            >
                <ul>
                    {phases.map((phase, i) => (
                        <li key={phase.phaseIndex}>
                            <header>
                                <Text as="h2" variant="bold-4">
                                    {phase.name}
                                </Text>
                                <Text as="span" variant="regular-4">
                                    {phase.count}
                                </Text>
                            </header>
                            {phase.groups.map((group, j) => (
                                <PhaseGroupSection
                                    key={group.label || 'all'}
                                    row={j + 2}
                                    col={i + 1}
                                    group={group}
                                    processId={processId}
                                    cardSettings={cardSettings}
                                    cardInteractions={cardInteractions}
                                />
                            ))}
                        </li>
                    ))}
                </ul>
            </Styled>
        ))

const Styled = styled.section<{ $cols: number; $rows: number }>`
    overflow-x: scroll;

    > ul {
        display: grid;
        padding: 2rem 0 2rem 3rem;
        grid-template: ${({ $rows, $cols }) =>
            `repeat(${$rows}, auto) / repeat(${$cols}, minmax(20rem, 1fr)) 3rem`};
    }

    [data-screen-size='small'] & > ul {
        padding: 1.5rem 0 1.5rem 4vw;
    }

    > ul > li {
        display: contents;
    }

    > ul > li > header {
        grid-row: 1 / 2;
        padding: 0.5rem 1rem;
        border-right: 1px dotted ${({ theme }) => theme.palette.ui['04'].normal};
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: ${({ theme }) => theme.palette.ui['01'].normal};
        z-index: 2;
    }

    > ul > li > section {
        position: relative;
        z-index: 1;
    }

    > ul::after {
        content: '';
        display: block;
        width: 3rem;
        height: 100%;
    }
    [data-screen-size='small'] & > ul::after {
        width: 4vw;
    }
`

const StyledLoading = styled.section`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

export { Phases }
