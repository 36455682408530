import {
    Field,
    ValidationStatus,
    isBusinessObjectDefinitionFieldValid,
    validateField,
    validateLabel,
} from 'Utils/BusinessObjectDefinition'
import { CreateProcessInput } from '__generated__'
import { P, match } from 'ts-pattern'

const validateDetails = (
    input: Pick<CreateProcessInput, 'name' | 'description' | 'operatesUpon'>
): boolean => input.name !== ''

const validatePhases = (input: Pick<CreateProcessInput, 'phases'>): boolean => {
    return (
        input.phases.length > 0 &&
        input.phases.every(
            phase =>
                phase.name &&
                phase.criteria.every(criterion =>
                    match(criterion)
                        .with(
                            { action: P.not(P.nullish) },
                            criterion => !!criterion.action.description
                        )
                        .with(
                            { fieldCondition: P.not(P.nullish) },
                            criterion =>
                                !!criterion.fieldCondition.comparator
                                    .valueSelector.fieldValue?.fieldId ||
                                !!criterion.fieldCondition.comparator.valueSelector.deep?.selectors.every(
                                    selector => selector.fieldValue?.fieldId
                                )
                        )
                        .with(
                            { processFanout: P.not(P.nullish) },
                            criterion =>
                                criterion.processFanout.processId &&
                                // if transform is set, fieldId must be set
                                (criterion.processFanout.transform
                                    ? criterion.processFanout.transform
                                          .listField?.fieldId ||
                                      criterion.processFanout.transform
                                          .relationField?.fieldId
                                    : true)
                        )
                        .with(
                            { processDelegate: P.not(P.nullish) },
                            criterion =>
                                criterion.processDelegate.processId &&
                                // if transform is set, fieldId must be set
                                (criterion.processDelegate.transform
                                    ? criterion.processDelegate.transform
                                          .listField?.fieldId ||
                                      criterion.processDelegate.transform
                                          .relationField?.fieldId
                                    : true)
                        )
                        .otherwise(() => false)
                )
        )
    )
}

const validateInput = (input: CreateProcessInput): boolean =>
    validateDetails(input) && validatePhases(input)

const validateDefinition = ({
    label,
    fields,
}: {
    label: string
    fields: Field[]
}) => {
    return (
        validateLabel(label).status === ValidationStatus.Valid &&
        fields.every(field =>
            isBusinessObjectDefinitionFieldValid(validateField(field))
        )
    )
}

export { validateDefinition, validateDetails, validateInput, validatePhases }
