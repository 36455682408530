import dayjs, { Dayjs } from 'dayjs'
import { partition } from 'lodash'
import { useMemo } from 'react'
import { match, P } from 'ts-pattern'
import { Action, Day } from './types'

const minDays = 7
const allColor = '#FFFFFF'

const makeEmptyDays = (start: Dayjs) => (count: number) =>
    (Array(count).fill(undefined) as undefined[]).reduce(
        (acc: { [index: string]: Day }, _, i) => {
            const day = start.add(i, 'day')
            return {
                ...acc,
                [day.format('YYYY/MM/DD')]: {
                    d: day,
                    isToday: day.isSame(dayjs(), 'day'),
                    count: 0,
                    groups: [{ label: 'all', actions: [], color: allColor }],
                },
            }
        },
        {}
    )

const addActionToDays = (
    days: { [index: string]: Day },
    a: Action
): { [index: string]: Day } =>
    match(a)
        .with({ dueDate: P.nullish }, () => days)
        .otherwise(action => {
            const key = dayjs(action.dueDate).format('YYYY/MM/DD')
            const day = days[key]

            return {
                ...days,
                [key]: {
                    ...day,
                    count: day.count + 1,
                    groups: [
                        {
                            label: 'all',
                            actions: [...day.groups[0].actions, action],
                            color: allColor,
                        },
                    ],
                },
            }
        })

const makeMyDay = (actions: Action[]) => {
    const latestStart = dayjs().startOf('day').unix()
    const soonestEnd = dayjs().add(minDays, 'days').startOf('day').unix()
    const dueDates = actions.map(a => dayjs(a.dueDate).startOf('day'))
    const start = Math.min(latestStart, ...dueDates.map(d => d.unix()))
    const end = Math.max(
        soonestEnd,
        ...dueDates.map(d => d.add(1, 'day').unix())
    )
    const diff = dayjs.unix(end).diff(dayjs.unix(start), 'days')

    return Object.values(
        actions.reduce(addActionToDays, makeEmptyDays(dayjs.unix(start))(diff))
    )
}

type UseActionsTimeline = (actions: Action[]) => {
    days: Day[]
    actionsWithNoDueDate: Action[]
}

export const useActionsTimeline: UseActionsTimeline = actions => {
    const [actionsWithDueDate, actionsWithNoDueDate] = useMemo(
        () => partition(actions, a => !!a.dueDate),
        [actions]
    )

    const days = useMemo(
        () => makeMyDay(actionsWithDueDate),
        [actionsWithDueDate]
    )

    return { days, actionsWithNoDueDate }
}
