import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { FieldTypeTag } from 'Components/FieldTypeTag'
import { Text } from 'Components/Text'
import { BusinessObjectDefinitionFieldEditor } from 'Features/BusinessObjectDefinitionFieldEditor'
import { useModalControls } from 'Hooks'
import { Field, validateField } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { useProcessCreator } from '../../useProcessCreator'

type Props = {
    field: Field
    phaseIndex: number
    criterionIndex: number
}

export const DataCollectionCriterion: FC<Props> = ({
    field,
    phaseIndex,
    criterionIndex,
}) => {
    const {
        dispatch,
        state: { relationOptions, userOptions, currencyOptions },
    } = useProcessCreator()
    const editModalControls = useModalControls()

    return (
        <Styled>
            <header>
                <IconTextButton
                    icon="CheckboxTicked"
                    text="Collect Data"
                    disabled
                    size="xSmall"
                    variant="ghost"
                />
                <Dropdown
                    renderOpenDropdownButton={() => (
                        <IconButton iconName="More" />
                    )}
                    renderContent={() => (
                        <>
                            <DropdownButton
                                icon="Edit"
                                text="Edit criterion"
                                onSelect={editModalControls.openModal}
                            />
                            <DropdownButton
                                icon="Delete"
                                text="Remove criterion"
                                onSelect={() => {
                                    dispatch({
                                        type: 'fieldRemoved',
                                        payload: {
                                            field,
                                            requiredBy: { phase: phaseIndex },
                                            index: criterionIndex,
                                        },
                                    })
                                }}
                            />
                        </>
                    )}
                />
            </header>

            <StyledFieldDisplay>
                <Text as="p" variant="bold-4">
                    {field.name || '[field name]'}
                </Text>
                <FieldTypeTag field={field} />
            </StyledFieldDisplay>

            <BusinessObjectDefinitionFieldEditor
                {...editModalControls}
                onOpenChange={editModalControls.setOpen}
                onSubmit={({ field }) => {
                    dispatch({
                        type: 'fieldEdited',
                        payload: {
                            requiredBy: { phase: phaseIndex },
                            field,
                            index: criterionIndex,
                        },
                    })
                    editModalControls.closeModal()
                }}
                hideRequired
                relationOptions={relationOptions}
                userOptions={userOptions}
                currencyOptions={currencyOptions}
                {...(editModalControls.open
                    ? {
                          field: field,
                          validation: validateField(field),
                      }
                    : {
                          field: undefined,
                          validation: undefined,
                      })}
            />
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.5rem 0.125rem 0.125rem;
    }

    > div {
        padding: 0 0.5rem 0.5rem;
    }
`

const StyledFieldDisplay = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`
