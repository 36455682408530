import { match } from 'ts-pattern'

export const HOUR_SECS = 3600

export const DAY_SECS = 86400

export const WEEK_SECS = 604800

export type TimeUnit = 'hour' | 'day' | 'week'

export const convertToSeconds = (number: number, timeUnit: TimeUnit): number =>
    match(timeUnit)
        .with('day', () => DAY_SECS * number)
        .with('hour', () => HOUR_SECS * number)
        .with('week', () => WEEK_SECS * number)
        .exhaustive()

export const convertToTimeUnit = (seconds: number, to: TimeUnit): number => {
    return match(to)
        .with('day', () => seconds / DAY_SECS)
        .with('hour', () => seconds / HOUR_SECS)
        .with('week', () => seconds / WEEK_SECS)
        .exhaustive()
}
