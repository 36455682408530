import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { Process_CatalogueFragmentDoc } from '../../../../../../Fragments/__generated__/Process';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcessCatalogueQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProcessCatalogueQuery = { __typename?: 'Query', processes: Array<{ __typename?: 'Process', id: string, name: string, description?: string | null, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', activeBusinessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }>, usage: { __typename?: 'ProcessUsage', businessObjects: number } }> };


export const ProcessCatalogueDocument = gql`
    query ProcessCatalogue {
  processes {
    ...Process_Catalogue
  }
}
    ${Process_CatalogueFragmentDoc}`;

/**
 * __useProcessCatalogueQuery__
 *
 * To run a query within a React component, call `useProcessCatalogueQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessCatalogueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessCatalogueQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessCatalogueQuery(baseOptions?: Apollo.QueryHookOptions<ProcessCatalogueQuery, ProcessCatalogueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessCatalogueQuery, ProcessCatalogueQueryVariables>(ProcessCatalogueDocument, options);
      }
export function useProcessCatalogueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessCatalogueQuery, ProcessCatalogueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessCatalogueQuery, ProcessCatalogueQueryVariables>(ProcessCatalogueDocument, options);
        }
export type ProcessCatalogueQueryHookResult = ReturnType<typeof useProcessCatalogueQuery>;
export type ProcessCatalogueLazyQueryHookResult = ReturnType<typeof useProcessCatalogueLazyQuery>;
export type ProcessCatalogueQueryResult = Apollo.QueryResult<ProcessCatalogueQuery, ProcessCatalogueQueryVariables>;