import { styled } from 'Adapters/Freestyled'

export const UpdatesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 1rem;
`

export const UpdateDisplay = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.3rem;
`

export const AuthorAvatar = styled.div`
    padding-top: 0.3rem;
`

export const UpdateText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    padding: 0.3rem;
    padding-top: 0;
`

export const AuthorText = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    padding-left: 0.3rem;

    > span {
        font-weight: bold;
    }
`

export const UpdateInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 0.3rem;
`

export const UpdateContent = styled.div`
    flex-grow: 1;
    padding-left: 0.3rem;
`
