import ELK, { ElkNode } from 'elkjs'
import { Edge, Node as FlowNode } from 'reactflow'

const nodeWidth = 169
const nodeHeight = 45

type Node<T> = {
    id: string
    data: T
    children?: Node<T>[]
    parentNode?: string
}

const mapChild = <T>(node: Node<T>): ElkNode => ({
    id: node.id,
    height: nodeHeight,
    width: nodeWidth,
    children: node.children?.map(mapChild),
})

const mapNode =
    (layoutNodes: ElkNode[]) =>
    <T>(node: Node<T>): FlowNode<T>[] => {
        const layoutNode = layoutNodes.find(v => v.id === node.id)

        const children = node.children?.flatMap(
            mapNode(layoutNode?.children ?? [])
        )

        return [
            {
                ...node,
                position: {
                    x: layoutNode?.x!,
                    y: layoutNode?.y!,
                },
                style: {
                    height: layoutNode?.height,
                    width: layoutNode?.width,
                },
            },
            ...(children ?? []),
        ]
    }

export const getLayoutedElements = async <T>(
    nodes: Node<T>[],
    edges: Edge[]
) => {
    const elk = new ELK()

    const graph = await elk.layout(
        {
            id: 'root',
            children: nodes.map(node => ({
                id: node.id,
                height: nodeHeight,
                children: node.children?.map(mapChild),
            })),
            edges: edges.map(edge => ({
                id: edge.id,
                sources: [edge.source],
                targets: [edge.target],
            })),
        },
        {
            layoutOptions: {
                'elk.algorithm': 'org.eclipse.elk.layered',
            },
        }
    )

    const posNodes = nodes.flatMap(mapNode(graph.children ?? []))

    return { nodes: posNodes, edges }
}
