import { flex, styled } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { FC } from 'react'

/*
 * A cell that renders an avatar component
 */

type Props = {
    name: string
}

export const AvatarCell: FC<Props> = ({ name }) => {
    return (
        <StyledAvatarCell>
            <Avatar name={name} noOfInitials={2} />
        </StyledAvatarCell>
    )
}

const StyledAvatarCell = styled.td`
    vertical-align: middle;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${({ theme }) => theme.spacing['04']};
    }
`
