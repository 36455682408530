import { Value } from '@udecode/plate-common'
import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, StatusButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { TextInput } from 'Components/Input'
import { RichText } from 'Components/RichText'
import { Select } from 'Components/Select'
import { bold5, regular6 } from 'Components/Text'
import {
    SetBusinessObject,
    useBusinessObjectDetails,
} from 'Features/SetBusinessObject'
import { getFieldValue } from 'Utils/getFieldValue'
import { noop } from 'lodash'
import { pipe } from 'lodash/fp'
import { FC, useRef, useState } from 'react'
import { preventDefault } from '../../Utils'
import { BusinessObjectFieldset } from '../BusinessObjectFieldset'
import { DeleteAction } from './DeleteAction'
import { ToggleActionSubscription } from './ToggleActionSubscription'
import { Action_And_ExtensionQuery } from './__generated__/query'
import { setInitialDescription } from './helpers'
import { useForm } from './useForm'
import { validateForm } from './useFormValidation'
import { useUpdateAction } from './useUpdateAction'

type Props = {
    onCompleted: () => void
    onDeleted: () => void
    users: {
        id: string
        name: string
        isCurrentUser: boolean
        principal: { id: string }
    }[]
    userGroups: Array<{ id: string; name: string; principal: { id: string } }>
    action: Action_And_ExtensionQuery['action']
    bodExtension:
        | Action_And_ExtensionQuery['businessObjectDefinitionExtensions'][number]
        | undefined
}

export const UpdateActionContent: FC<Props> = ({
    onCompleted,
    action,
    bodExtension,
    users,
    onDeleted,
    userGroups,
}) => {
    const {
        handleTextInputUpdate,
        handleDescriptionUpdate,
        handleSelectChange,
        handleDateChange,
        handleExtensionFieldChanged,
        clearDateInput,
        handleAttachmentsChanged,
        form,
    } = useForm({
        initialValues: {
            name: action.name,
            description: setInitialDescription(action.description),
            descriptionAttachments:
                action.descriptionAttachments?.map(attachment => ({
                    type: 'file',
                    file: attachment,
                })) ?? [],
            dueDate: action.dueDate ?? undefined,
            assignedToId: action.assignedTo?.id ?? undefined,
            extendedBy:
                bodExtension?.fields.map(field => {
                    const existingFieldExtension =
                        action.extendedBy?.fields.find(
                            existing => existing.fieldDefinition.id === field.id
                        )
                    const initialValue = existingFieldExtension
                        ? getFieldValue(existingFieldExtension)
                        : undefined

                    return {
                        field: {
                            id: field.id,
                            type: field.type,
                        },
                        value: initialValue,
                        initialValue,
                    }
                }) ?? [],
        },
    })

    const errors = validateForm(form)

    const { status, mutationErrors, updateAction } = useUpdateAction()

    const handleSubmit = pipe(preventDefault, () => {
        updateAction({
            input: form,
            onCompleted,
            id: action.id,
            extendedByDefinitionId: bodExtension?.id,
        })
    })

    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const dateButtonRef = useRef<HTMLButtonElement>(null)

    const { businessObjectLabel, businessObjectDefinitionId } =
        useBusinessObjectDetails(action.relatesTo?.businessObject.id)

    return (
        <>
            <StyledForm onSubmit={handleSubmit}>
                <div>
                    <StyledField>
                        <label htmlFor="name">
                            Name <strong>*</strong>
                        </label>
                        <TextInput
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleTextInputUpdate('name')}
                            hasError={errors.has('name')}
                        />
                    </StyledField>

                    <StyledField>
                        <label htmlFor="assignedTo">Assignee</label>
                        <Select
                            id="assignedTo"
                            value={form.assignedToId}
                            onValueChange={handleSelectChange}
                            name="assignedTo"
                            options={[
                                {
                                    groupName: 'A user',
                                    options: users.map(user => ({
                                        text: user.isCurrentUser
                                            ? `${user.name} (Me)`
                                            : user.name,
                                        value: user.principal.id,
                                        disabled: false,
                                    })),
                                },
                                {
                                    groupName: 'A team',
                                    options: userGroups.map(group => ({
                                        text: group.name,
                                        value: group.principal.id,
                                        disabled: false,
                                    })),
                                },
                            ]}
                        />
                    </StyledField>

                    {/* TODO : check this is working in all instances */}
                    <StyledField>
                        <label htmlFor="description">Description</label>
                        <StyledRichText
                            id="update-action-description"
                            value={JSON.parse(form.description) as Value}
                            onChange={handleDescriptionUpdate}
                            attachments={form.descriptionAttachments ?? []}
                            onAttachmentAdded={handleAttachmentsChanged}
                        />
                    </StyledField>

                    <StyledField>
                        <label htmlFor="due-date">Due Date</label>
                        <StyledDateInputGroup>
                            <StyledDateButton
                                icon={'Calendar'}
                                text={
                                    form.dueDate?.length
                                        ? dayjs(form.dueDate).format(
                                              'DD MMM YYYY'
                                          )
                                        : 'Add A Due Date'
                                }
                                ref={dateButtonRef}
                                onClick={e => {
                                    e.preventDefault() // Prevent from submitting form
                                    setDatePickerOpen(true)
                                }}
                                variant="secondary"
                            />
                            <StyledDateButton
                                icon={'Close'}
                                text="Clear"
                                onClick={e => {
                                    e.preventDefault() // Prevent from submitting form
                                    clearDateInput()
                                }}
                                variant="secondary"
                            />
                            <DatePicker
                                hideDescription
                                open={datePickerOpen}
                                onOpenChange={setDatePickerOpen}
                                title={'Set Due Date'}
                                description={
                                    'Add an optional due date for the action'
                                }
                                onConfirm={({ date }) => {
                                    handleDateChange(date)
                                    setDatePickerOpen(false)
                                }}
                                returnFocus={() => {
                                    dateButtonRef.current?.focus()
                                }}
                                selectedDate={
                                    form.dueDate
                                        ? new Date(form.dueDate)
                                        : undefined
                                }
                            />
                        </StyledDateInputGroup>
                        <p className="error-message">
                            {errors.has('dueDate')
                                ? 'The due date is in the past'
                                : ''}
                        </p>
                    </StyledField>

                    <StyledField>
                        <label htmlFor="businessObject">Record</label>

                        <SetBusinessObject
                            onBusinessObjectSelect={noop}
                            selection={{
                                businessObjectId:
                                    action.relatesTo?.businessObject.id,
                                businessObjectLabel,
                                businessObjectDefinitionId,
                            }}
                            disabled={true}
                        />
                    </StyledField>

                    {!bodExtension ? null : (
                        <BusinessObjectFieldset
                            mutationErrors={mutationErrors}
                            onFieldChanged={handleExtensionFieldChanged}
                            fieldDefinitions={bodExtension.fields}
                            fieldValues={form.extendedBy}
                        />
                    )}
                </div>
                <footer>
                    <ToggleActionSubscription action={action} />
                    <div>
                        <DeleteAction action={action} onDeleted={onDeleted} />
                        <StatusButton
                            status={status}
                            text={{
                                ready: 'Update action',
                                loading: 'Updating action',
                                error: 'Error updating action',
                                success: 'Success!',
                            }}
                        />
                    </div>
                </footer>
            </StyledForm>
        </>
    )
}

const StyledRichText = styled(RichText)`
    border-radius: 4px;
`

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > div {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .error-message {
            min-height: 1em;
            color: ${({ theme }) => theme.palette.text.support05.normal};
        }
    }

    .set-business-object-button {
        width: 100%;
        padding: 1rem 1.5rem;
    }

    > footer {
        border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
        padding-top: 0.5rem;
    }

    > footer > div:last-child {
        padding-top: 0.5rem;
        display: flex;
        gap: 1rem;
    }

    > footer > div:last-child button {
        flex: 1;
    }
`

const StyledField = styled.div`
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        ${bold5}
        display: block;
        padding: 0;
    }

    label + p {
        ${regular6}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        line-height: 150%;
        padding-bottom: 0.25rem;
    }

    label + p a {
        color: ${({ theme }) => theme.palette.support['03'].normal};
    }

    p.error {
        ${regular6}
        line-height: 150%;
        padding-bottom: 0.25rem;
        color: ${({ theme }) => theme.palette.text.support01.normal};
    }
`

const StyledDateButton = styled(IconTextButton)`
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
`

const StyledDateInputGroup = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button:first-of-type {
        flex: 1;
    }
`
