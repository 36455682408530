import { IconTextButton } from 'Components/Button'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownLabel,
} from 'Components/Dropdown'
import { FC, useState } from 'react'
import { useSavedViews } from '../useSavedViews'
import { ManageViews } from './ManageViews'

type Props = {
    processId: string
}

const SavedViews: FC<Props> = ({ processId }) => {
    const [open, setOpen] = useState(false)
    const { savedViews, selectSavedView } = useSavedViews(processId)

    return (
        <Dropdown
            align="end"
            open={!!savedViews.length && open}
            onOpenChange={setOpen}
            renderOpenDropdownButton={() => (
                <IconTextButton
                    icon="View"
                    text="Saved views"
                    size="xSmall"
                    disabled={!savedViews.length}
                />
            )}
            renderContent={() => (
                <>
                    <DropdownLabel text="Saved Views" />
                    {savedViews.map(view => (
                        <DropdownButton
                            key={view.id}
                            text={view.label}
                            icon="View"
                            onSelect={() => {
                                selectSavedView(view)
                            }}
                        />
                    ))}
                    <DropdownDivider />
                    <ManageViews views={savedViews} />
                </>
            )}
        />
    )
}

export { SavedViews }
