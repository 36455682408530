import Add from './icons/Add.svg?react'
import Aged from './icons/Aged.svg?react'
import Analytics from './icons/Analytics.svg?react'
import AngleDown from './icons/AngleDown.svg?react'
import AngleLeft from './icons/AngleLeft.svg?react'
import AngleRight from './icons/AngleRight.svg?react'
import AngleUp from './icons/AngleUp.svg?react'
import ArrowDown from './icons/ArrowDown.svg?react'
import ArrowLeft from './icons/ArrowLeft.svg?react'
import ArrowRight from './icons/ArrowRight.svg?react'
import ArrowUp from './icons/ArrowUp.svg?react'
import Article from './icons/Article.svg?react'
import Date from './icons/Calendar.svg?react'
import Calendar from './icons/Calendar1.svg?react'
import Cards from './icons/Cards.svg?react'
import Check from './icons/Check.svg?react'
import CheckboxTicked from './icons/CheckboxTicked.svg?react'
import CheckboxTickedInverted from './icons/CheckboxTickedInverted.svg?react'
import CheckboxUnticked from './icons/CheckboxUnticked.svg?react'
import Checklist from './icons/Checklist.svg?react'
import Circle from './icons/Circle.svg?react'
import CircleCheck from './icons/CircleCheck.svg?react'
import Close from './icons/Close.svg?react'
import CloseArrow from './icons/CloseArrow.svg?react'
import CloseMenu from './icons/CloseMenu.svg?react'
import Coin from './icons/Coin.svg?react'
import Columns from './icons/Columns.svg?react'
import Company from './icons/Company.svg?react'
import Converted from './icons/Converted.svg?react'
import Cube from './icons/Cube.svg?react'
import Deal from './icons/Deal.svg?react'
import DealDetail from './icons/DealDetail.svg?react'
import DealGreeting from './icons/DealGreeting.svg?react'
import DealValue from './icons/DealValue.svg?react'
import Delete from './icons/Delete.svg?react'
import Domain from './icons/Domain.svg?react'
import Dormant from './icons/Dormant.svg?react'
import DotCircle from './icons/DotCircle.svg?react'
import DropDown from './icons/DropDown.svg?react'
import Ellipsis from './icons/Ellipsis.svg?react'
import ExclamationTriangle from './icons/ExclamationTriangle.svg?react'
import Filter from './icons/Filter.svg?react'
import Forecast from './icons/Forecast.svg?react'
import Idea from './icons/Idea.svg?react'
import Inactive from './icons/Inactive.svg?react'
import Info from './icons/Info.svg?react'
import Integrations from './icons/Integrations.svg?react'
import Invalid from './icons/Invalid.svg?react'
import Invalid1 from './icons/Invalid1.svg?react'
import Invite from './icons/Invite.svg?react'
import List from './icons/List.svg?react'
import Lock from './icons/Lock.svg?react'
import Logout from './icons/Logout.svg?react'
import MiniDice from './icons/MiniDice.svg?react'
import Minus from './icons/Minus.svg?react'
import More from './icons/More.svg?react'
import NeutralDot from './icons/NeutralDot.svg?react'
import NoItem from './icons/NoItem.svg?react'
import Notifications from './icons/Notifications.svg?react'
import Option from './icons/Option.svg?react'
import Pipeline from './icons/Pipeline.svg?react'
import Plus from './icons/Plus.svg?react'
import Probability from './icons/Probability.svg?react'
import ProductLines from './icons/ProductLines.svg?react'
import Progress from './icons/Progress.svg?react'
import Remove from './icons/Remove.svg?react'
import SalesProcessAvatar from './icons/SalesProcessAvatar.svg?react'
import Search from './icons/Search.svg?react'
import Settings from './icons/Settings.svg?react'
import Share from './icons/Share.svg?react'
import Sort from './icons/Sort.svg?react'
import Spinner from './icons/Spinner.svg?react'
import Star from './icons/Star.svg?react'
import Starter from './icons/Starter.svg?react'
import SubCompany from './icons/SubCompany.svg?react'
import Subscription from './icons/Subscription.svg?react'
import SubscriptionPlan from './icons/SubscriptionPlan.svg?react'
import Sync from './icons/Sync.svg?react'
import Target from './icons/Target.svg?react'
import Tasks from './icons/Tasks.svg?react'
import TimeTravel from './icons/TimeTravel.svg?react'
import ToggleChecked from './icons/ToggleChecked.svg?react'
import ToggleUnchecked from './icons/ToggleUnchecked.svg?react'
import Tour from './icons/Tour.svg?react'
import Upload from './icons/Upload.svg?react'
import User from './icons/User.svg?react'
import Value from './icons/Value.svg?react'
import Accounts from './icons/accounts.svg?react'
import AddSmall from './icons/add-small.svg?react'
import At from './icons/at.svg?react'
import BadgeTick from './icons/badge-tick.svg?react'
import Bold from './icons/bold.svg?react'
import Bullet from './icons/bullet.svg?react'
import CarrotLeft from './icons/carrot-left.svg?react'
import CarrotRight from './icons/carrot-right.svg?react'
import CircleAdd from './icons/circle-add.svg?react'
import CircleFilledClose from './icons/circle-filled-close.svg?react'
import CircleFilledMinus from './icons/circle-filled-minus.svg?react'
import CircleMinus from './icons/circle-minus.svg?react'
import Comment from './icons/comment.svg?react'
import Contact from './icons/contact.svg?react'
import Copy from './icons/copy.svg?react'
import Criteria from './icons/criteria.svg?react'
import CurrencyWorld from './icons/currency-world.svg?react'
import Dashboard from './icons/dashboard.svg?react'
import DealNav from './icons/deal-nav.svg?react'
import Detail from './icons/detail.svg?react'
import Dice from './icons/dice-12.svg?react'
import Document from './icons/document.svg?react'
import Edit from './icons/edit.svg?react'
import Emoji from './icons/emoji.svg?react'
import ErrorCircle from './icons/exclamation-circle.svg?react'
import ExitProcess from './icons/exit-process.svg?react'
import External from './icons/external.svg?react'
import Save from './icons/floppy.svg?react'
import Funnel from './icons/funnel.svg?react'
import HidePanel from './icons/hidepanel.svg?react'
import Hub from './icons/hub.svg?react'
import Incomplete from './icons/incomplete.svg?react'
import Input from './icons/input.svg?react'
import Italic from './icons/italic.svg?react'
import Link from './icons/link.svg?react'
import LinkedIn from './icons/linkedin.svg?react'
import LogoLight from './icons/logo-light.svg?react'
import Mail from './icons/mail.svg?react'
import Menu from './icons/menu.svg?react'
import Message from './icons/message.svg?react'
import MobilePhone from './icons/mobile-phone.svg?react'
import NavListOpen from './icons/nav-list-open.svg?react'
import Notes from './icons/notes.svg?react'
import Process from './icons/process.svg?react'
import Products from './icons/products.svg?react'
import Question from './icons/question.svg?react'
import Reopen from './icons/reopen.svg?react'
import Rocket from './icons/rocket.svg?react'
import Send from './icons/send.svg?react'
import Strikethrough from './icons/strikethrough.svg?react'
import Team from './icons/team.svg?react'
import Twitter from './icons/twitter.svg?react'
import Unlink from './icons/unlink.svg?react'
import View from './icons/view.svg?react'
import Weighted from './icons/weighted.svg?react'

export const iconMap = {
    Add,
    AddSmall,
    Aged,
    Analytics,
    AngleDown,
    AngleLeft,
    AngleRight,
    AngleUp,
    ArrowDown,
    ArrowUp,
    Date,
    Calendar,
    Check,
    CheckboxTicked,
    CheckboxUnticked,
    Checklist,
    Converted,
    CircleCheck,
    Close,
    Company,
    Cube,
    Deal,
    DealDetail,
    DealGreeting,
    DealValue,
    Delete,
    Dormant,
    Filter,
    Cards,
    Inactive,
    Info,
    Invalid,
    Invalid1,
    Incomplete,
    Invite,
    Logout,
    MiniDice,
    Minus,
    NoItem,
    Option,
    Pipeline,
    Plus,
    Probability,
    ProductLines,
    Remove,
    Search,
    Settings,
    Starter,
    Sort,
    SubCompany,
    Subscription,
    Target,
    Tasks,
    ToggleChecked,
    ToggleUnchecked,
    Tour,
    User,
    TimeTravel,
    Value,
    NeutralDot,
    Coin,
    Notifications,
    Spinner,
    Ellipsis,
    ExclamationTriangle,
    Sync,
    Circle,
    DotCircle,
    Star,
    Dice,
    DropDown,
    ArrowRight,
    More,
    CloseMenu,
    LogoLight,
    Accounts,
    Upload,
    Products,
    DealNav,
    Menu,
    Send,
    Message,
    CarrotLeft,
    CarrotRight,
    CircleAdd,
    Mail,
    Weighted,
    CurrencyWorld,
    BadgeTick,
    List,
    Columns,
    ErrorCircle,
    CloseArrow,
    CircleMinus,
    Funnel,
    CheckboxTickedInverted,
    ArrowLeft,
    Progress,
    HidePanel,
    CircleFilledMinus,
    CircleFilledClose,
    Reopen,
    SalesProcessAvatar,
    Integrations,
    Forecast,
    SubscriptionPlan,
    Detail,
    MobilePhone,
    LinkedIn,
    Twitter,
    Notes,
    Contact,
    Comment,
    Copy,
    At,
    Link,
    Bold,
    Emoji,
    Edit,
    Dashboard,
    Domain,
    Document,
    Process,
    NavListOpen,
    Input,
    Criteria,
    Save,
    View,
    Italic,
    Idea,
    Article,
    ExitProcess,
    External,
    Unlink,
    Strikethrough,
    Bullet,
    Hub,
    Question,
    Rocket,
    Share,
    Lock,
    Team,
} as const
