import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { Toggle } from 'Components/Input'
import { useRef, useState } from 'react'
import { Field } from '../Field'

type DateRangeConstraintValue = {
    dateRange?: { start?: string | null; end?: string | null } | null
}

type Props = {
    index: number
    onChange: (value: DateRangeConstraintValue | undefined) => void
    value: DateRangeConstraintValue | undefined
}

export const DateRangeConstraint: React.FC<Props> = ({
    index,
    onChange,
    value,
}) => {
    const hasStartSet =
        typeof value?.dateRange?.start !== 'undefined' &&
        value?.dateRange?.start !== null
    const hasEndSet =
        typeof value?.dateRange?.end !== 'undefined' &&
        value?.dateRange?.end !== null

    const [startModalOpen, setStartModalOpen] = useState(false)
    const [endModalOpen, setEndModalOpen] = useState(false)

    const startDateButtonRef = useRef<HTMLButtonElement>(null)
    const endDateButtonRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <Field
                htmlFor={`bod-field-dateRange-start-${index}`}
                name="Start date"
            >
                <DateToggleWrapper>
                    <Toggle
                        name={`bod-field-dateRange-toggle-start${index}`}
                        id={`bod-field-dateRange-toggle-start${index}`}
                        indicatorText={['', 'No start date']}
                        checked={hasStartSet}
                        onCheckedChange={checked =>
                            onChange(
                                checked
                                    ? {
                                          dateRange: {
                                              start: dayjs().toISOString(),
                                              end: value?.dateRange?.end,
                                          },
                                      }
                                    : {
                                          dateRange: {
                                              end: value?.dateRange?.end,
                                          },
                                      }
                            )
                        }
                    />
                    {hasStartSet && (
                        <>
                            <StyledDateButton
                                icon="Calendar"
                                text={dayjs(value?.dateRange?.start).format(
                                    'DD MMM YYYY'
                                )}
                                ref={startDateButtonRef}
                                onClick={e => {
                                    e.preventDefault() // Prevent from submitting form
                                    setStartModalOpen(true)
                                }}
                                size="xSmall"
                            />

                            <DatePicker
                                title="Start date"
                                description="Allow dates from this date (inclusive)"
                                returnFocus={() =>
                                    startDateButtonRef.current?.focus()
                                }
                                open={startModalOpen}
                                onOpenChange={setStartModalOpen}
                                onConfirm={({ date, e }) => {
                                    e.preventDefault()
                                    onChange({
                                        dateRange: {
                                            start: dayjs(date).toISOString(),
                                            end: value?.dateRange?.end,
                                        },
                                    })
                                    setStartModalOpen(false)
                                }}
                                selectedDate={
                                    value?.dateRange?.start
                                        ? dayjs(
                                              value?.dateRange?.start
                                          ).toDate()
                                        : new Date()
                                }
                            />
                        </>
                    )}
                </DateToggleWrapper>
            </Field>
            <Field htmlFor={`bod-field-dateRange-end-${index}`} name="End date">
                <DateToggleWrapper>
                    <Toggle
                        name={`bod-field-dateRange-toggle-end${index}`}
                        id={`bod-field-dateRange-toggle-end${index}`}
                        indicatorText={['', 'No end date']}
                        checked={hasEndSet}
                        onCheckedChange={checked =>
                            onChange(
                                checked
                                    ? {
                                          dateRange: {
                                              end: dayjs().toISOString(),
                                              start: value?.dateRange?.start,
                                          },
                                      }
                                    : {
                                          dateRange: {
                                              start: value?.dateRange?.start,
                                          },
                                      }
                            )
                        }
                    />
                    {hasEndSet && (
                        <>
                            <StyledDateButton
                                icon="Calendar"
                                text={dayjs(value?.dateRange?.end).format(
                                    'DD MMM YYYY'
                                )}
                                ref={endDateButtonRef}
                                onClick={e => {
                                    e.preventDefault() // Prevent from submitting form
                                    setEndModalOpen(true)
                                }}
                                size="xSmall"
                            />

                            <DatePicker
                                title="End date"
                                description="Allow dates until this date (inclusive)"
                                returnFocus={() =>
                                    endDateButtonRef.current?.focus()
                                }
                                open={endModalOpen}
                                onOpenChange={setStartModalOpen}
                                onConfirm={({ date, e }) => {
                                    e.preventDefault()
                                    onChange({
                                        dateRange: {
                                            end: dayjs(date).toISOString(),
                                            start: value?.dateRange?.start,
                                        },
                                    })
                                    setEndModalOpen(false)
                                }}
                                selectedDate={
                                    value?.dateRange?.end
                                        ? dayjs(value?.dateRange?.end).toDate()
                                        : new Date()
                                }
                            />
                        </>
                    )}
                </DateToggleWrapper>
            </Field>
        </>
    )
}

const DateToggleWrapper = styled.div`
    ${flex('row', 'center', 'center')};
    gap: 0.5rem;
`
const StyledDateButton = styled(IconTextButton)`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
`
