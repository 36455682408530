import { logger } from 'Adapters/Logger'
import * as principal from 'Features/Principal'
import { useMemo } from 'react'
import { DocumentMetadata } from '../types'
import { useFetchDocumentsQuery } from './__generated__/queries'

export const useFetchAllDocuments = () => {
    const { data } = useFetchDocumentsQuery({
        variables: {
            input: null,
        },
        onError(error) {
            logger.error('Failed to fetch documents', error)
        },
        fetchPolicy: 'cache-and-network',
    })

    // The download url must be queried separately based on the id
    const documents = useMemo<DocumentMetadata[]>(() => {
        if (!data) return []
        return data.files.map(file => ({
            ...file,
            addedBy: principal.helpers.name(file.uploadedBy),
            addedOn: file.uploadedAt,
            canDelete: file.permissions.delete && file.canDelete,
        }))
    }, [data])

    return { documents, hasInitiallyLoaded: data !== undefined }
}
