import {
    PlateElement,
    PlateElementProps,
    TDescendant,
} from '@udecode/plate-common'
import { styled } from 'Adapters/Freestyled'

type DeprecatedLinkNode = {
    type: 'link'
    url: string
    content: string
    children: TDescendant[]
}

export function DeprecatedLink({ children, ...props }: PlateElementProps) {
    const element = props.element as DeprecatedLinkNode

    return (
        <PlateElement asChild {...props}>
            <StyledLink
                href={element.url}
                target="_blank"
                rel="noopener noreferrer external"
            >
                {element.content}
                {children}
            </StyledLink>
        </PlateElement>
    )
}

const StyledLink = styled.a`
    color: ${({ theme }) => theme.palette.text.support03.normal};

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
        text-decoration: underline;
    }

    &:active,
    &:focus-visible {
        color: ${({ theme }) => theme.palette.text.support03.active};
        text-decoration: underline;
    }
`
