import { FC } from 'react'
import { iconMap } from './iconMap'
import { Styled } from './styles'
import { IconName } from './types'

export type Props = {
    name: IconName
    title?: string
    hideTitle?: boolean
    className?: string
    onPointerDown?: React.PointerEventHandler<HTMLSpanElement> | undefined
} & React.HTMLAttributes<HTMLSpanElement>

export const Icon: FC<Props> = ({
    name,
    title,
    hideTitle = false,
    className,
    onPointerDown,
    ...props
}) => {
    const I = iconMap[name]

    return (
        <Styled
            className={className ? `icon ${className}` : 'icon'}
            onPointerDown={onPointerDown}
            {...props}
        >
            {hideTitle ? <I/> : <I title={title || name} />}
        </Styled>
    )
}
