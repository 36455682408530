import Fuse from 'fuse.js'
import { useMemo, useState } from 'react'
import { OpenInvite, User } from './types'

type UseMemberSearch = (input: {
    users: User[]
    openInvites: OpenInvite[]
}) => {
    searchTerm: string
    setSearchTerm: (term: string) => void
    filteredUsers: User[]
    filteredOpenInvites: OpenInvite[]
}

export const useMemberSearch: UseMemberSearch = ({ users, openInvites }) => {
    const [searchTerm, setSearchTerm] = useState('')

    const filteredUsers = useMemo(
        () =>
            searchTerm
                ? new Fuse(users, {
                      keys: ['name', 'email'],
                      threshold: 0.2,
                  })
                      .search(searchTerm)
                      .map(result => result.item)
                : users,
        [users, searchTerm]
    )

    const filteredOpenInvites = useMemo(
        () =>
            searchTerm
                ? new Fuse(openInvites, {
                      keys: ['inviteeEmail'],
                      threshold: 0.2,
                  })
                      .search(searchTerm)
                      .map(result => result.item)
                : openInvites,
        [openInvites, searchTerm]
    )

    return {
        searchTerm,
        setSearchTerm,
        filteredUsers,
        filteredOpenInvites,
    }
}
