import { css, flex, styled } from 'Adapters/Freestyled'
import { bold4, bold5, bold6 } from 'Components/Text'
import { match } from 'ts-pattern'
import { Size, Status } from './types'

export const StyledStatusButton = styled.button<{
    $status: Status
    $size: Size
}>`
    ${flex('row', 'center', 'center')};
    ${({ $size }) => applyButtonSizeStyles($size)}
    text-align: center;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: 100%;
    border: 1px solid
        ${({ $status, theme }) =>
            $status === 'error'
                ? theme.palette.support['01'].normal
                : theme.palette.ui.primary.normal};
    background-color: ${({ theme }) => theme.palette.ui.primary.normal};
    color: ${({ theme }) => theme.palette.text['04'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    span:first-child {
        display: inline-block;
        padding-right: 0.75rem;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.2;
    }

    .spinner {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.25rem;
    }

    svg {
        display: block;
    }

    path {
        fill: ${({ theme }) => theme.palette.icon['04'].normal};
    }
`

const applyButtonSizeStyles = (size: Size) => {
    return match(size)
        .with(
            'xSmall',
            () => css`
                ${bold6};
                padding: 0.5rem;
            `
        )
        .with(
            'small',
            () => css`
                ${bold5};
                padding: 0.75rem 1rem;
                max-height: 2.594rem;
            `
        )
        .with(
            'medium',
            () => css`
                ${bold4}
                padding: 1rem 1.5rem;
            `
        )
        .exhaustive()
}
