import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { CenteredSpinner } from 'Components/Spinner'
import { fieldLabels } from 'Utils'
import {
    Field,
    FieldType,
    FieldValidation,
} from 'Utils/BusinessObjectDefinition'
import { noop } from 'lodash'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { BooleanField } from './Field/Boolean'
import { CurrencyField } from './Field/Currency'
import { DateField } from './Field/Date'
import { DocField } from './Field/Doc'
import { EmailField } from './Field/Email'
import { ListField } from './Field/List/ListField'
import { NumberField } from './Field/Number'
import { RelationField } from './Field/Relation'
import { SelectField } from './Field/Select/SelectField'
import { TelephoneField } from './Field/Telephone'
import { TextField } from './Field/Text'
import { UrlField } from './Field/Url'
import { UserField } from './Field/User'
import { OnSubmit } from './types'

type Props = {
    onOpenChange: (open: boolean) => void
    onSubmit: OnSubmit<Field, FieldValidation>
    relationOptions: {
        value: string
        text: string
    }[]
    userOptions: {
        value: string
        text: string
    }[]
    currencyOptions: {
        value: string
        text: string
    }[]
    hideRequired?: boolean
} & (
    | {
          field: Field
          validation: FieldValidation
      }
    | {
          field: undefined
          validation: undefined
      }
)

const BusinessObjectDefinitionFieldEditor: FC<Props> = ({
    onOpenChange,
    field,
    validation,
    onSubmit,
    relationOptions,
    userOptions,
    currencyOptions,
    hideRequired = false,
}) => (
    <ControlledModal
        title={`${field && fieldLabels[field.type]} field`}
        description="Edit field"
        hideDescription
        open={!!field}
        onOpenChange={onOpenChange}
        renderContent={() => {
            if (field?.type !== validation?.type)
                throw new Error(
                    'Field editor must have matching field and validation types.'
                )
            return match({ f: field, v: validation })
                .with(
                    {
                        f: { type: FieldType.Text },
                        v: { type: FieldType.Text },
                    },
                    ({ f, v }) => (
                        <TextField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Number },
                        v: { type: FieldType.Number },
                    },
                    ({ f, v }) => (
                        <NumberField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Telephone },
                        v: { type: FieldType.Telephone },
                    },
                    ({ f, v }) => (
                        <TelephoneField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Email },
                        v: { type: FieldType.Email },
                    },
                    ({ f, v }) => (
                        <EmailField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Url },
                        v: { type: FieldType.Url },
                    },
                    ({ f, v }) => (
                        <UrlField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Select },
                        v: { type: FieldType.Select },
                    },
                    ({ f, v }) => (
                        <SelectField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Currency },
                        v: { type: FieldType.Currency },
                    },
                    ({ f, v }) => (
                        <CurrencyField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            currencyOptions={currencyOptions}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Date },
                        v: { type: FieldType.Date },
                    },
                    ({ f, v }) => (
                        <DateField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Document },
                        v: { type: FieldType.Document },
                    },
                    ({ f, v }) => (
                        <DocField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Relation },
                        v: { type: FieldType.Relation },
                    },
                    ({ f, v }) => (
                        <RelationField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            relationOptions={relationOptions}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.User },
                        v: { type: FieldType.User },
                    },
                    ({ f, v }) => (
                        <UserField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            userOptions={userOptions}
                            hideRequired={hideRequired}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.Boolean },
                        v: { type: FieldType.Boolean },
                    },
                    ({ f, v }) => (
                        <BooleanField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                        />
                    )
                )
                .with(
                    {
                        f: { type: FieldType.List },
                        v: { type: FieldType.List },
                    },
                    ({ f, v }) => (
                        <ListField
                            field={f}
                            validation={v}
                            onClose={() => onOpenChange(false)}
                            onSubmit={onSubmit}
                            relationOptions={relationOptions}
                            userOptions={userOptions}
                            currencyOptions={currencyOptions}
                        />
                    )
                )
                .otherwise(() => (
                    <StyledEmpty>
                        <CenteredSpinner />
                    </StyledEmpty>
                ))
        }}
        returnFocus={noop}
    />
)

const StyledEmpty = styled.div`
    width: 32rem;
    max-width: 80vw;
    padding: 3rem;
`

export { BusinessObjectDefinitionFieldEditor }
