import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { forwardRef } from 'react'

type Props = (
    | {
          icon: IconName
          text?: string
      }
    | {
          icon?: IconName
          text: string
      }
) & {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    className?: string
}

const TagButton = forwardRef<HTMLButtonElement, Props>(
    ({ icon, text, onClick, className = '' }, ref) => (
        <Styled ref={ref} onClick={onClick} className={className}>
            {icon && <Icon name={icon} />}
            {text && (
                <Text as="span" variant="bold-6">
                    {text}
                </Text>
            )}
        </Styled>
    )
)

const Styled = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: ${({ theme }) => theme.palette.text['01'].normal};
    border: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    padding: 0.125rem 0.5rem 0.125rem 0.25rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    svg {
        height: 0.75rem;
        width: 0.75rem;
        min-height: 0;
        min-width: 0;
    }

    svg path {
        color: ${({ theme }) => theme.palette.icon['01'].normal};
    }
`

export { TagButton }
