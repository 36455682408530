import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
    fieldName: string
    previousValueString?: string
    newValueString?: string
}

export const FieldUpdatedHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    fieldName,
    newValueString,
    previousValueString,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> updated the value of the{' '}
                <strong>{fieldName}</strong> field from{' '}
                <strong>
                    {previousValueString ? previousValueString : <em>unset</em>}
                </strong>{' '}
                to{' '}
                <strong>
                    {newValueString ? newValueString : <em>unset</em>}
                </strong>
                .
            </Text>
        </BaseHistoryEntry>
    )
}
