import { IDeveloperField } from 'dromo-uploader-react'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { BODFields } from '../../../../Hooks/types'

type Validators = NonNullable<IDeveloperField['validators']>

export const useGenerateCSVFields = (
    businessObjectDefinitionFields: BODFields
) => {
    const csvFields = useMemo(() => {
        return compact(
            businessObjectDefinitionFields.map<IDeveloperField | null>(
                field => {
                    const base = {
                        key: field.name,
                        label: field.name,
                        description: field.description ?? undefined,
                        requireMapping: true,
                    } satisfies IDeveloperField

                    return (
                        match(field)
                            .with(
                                { __typename: 'BooleanFieldDefinition' },
                                () => {
                                    return {
                                        ...base,
                                        type: 'checkbox', // Outputs JSON boolean
                                    } satisfies IDeveloperField
                                }
                            )
                            .with(
                                { __typename: 'DateFieldDefinition' },
                                ({ dateConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired = !!dateConstraints.find(
                                        constraint =>
                                            constraint.__typename ===
                                            'RequiredConstraint'
                                    )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )

                            .with(
                                { __typename: 'EmailFieldDefinition' },
                                ({ emailConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired = !!emailConstraints.find(
                                        constraint =>
                                            constraint.__typename ===
                                            'RequiredConstraint'
                                    )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        type: 'email',
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )

                            .with(
                                { __typename: 'NumberFieldDefinition' },
                                ({ numConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired = !!numConstraints.find(
                                        constraint =>
                                            constraint.__typename ===
                                            'RequiredConstraint'
                                    )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        type: 'number',
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )

                            .with(
                                { __typename: 'SelectFieldDefinition' },
                                ({ selectOptions, selectConstraints }) => {
                                    const validators: Validators = []

                                    const noOfSelections =
                                        selectConstraints.find(
                                            constraint =>
                                                constraint.__typename ===
                                                'NumberOfSelectionsConstraint'
                                        )

                                    if (noOfSelections?.min === 1)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        type: 'select',
                                        selectOptions: selectOptions.map(
                                            option => ({
                                                label: option.value,
                                                value: option.id,
                                            })
                                        ),
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )
                            .with(
                                { __typename: 'TelephoneFieldDefinition' },
                                ({ telephoneConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired =
                                        !!telephoneConstraints.find(
                                            constraint =>
                                                constraint.__typename ===
                                                'RequiredConstraint'
                                        )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )
                            .with(
                                { __typename: 'TextFieldDefinition' },
                                ({ textConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired = !!textConstraints.find(
                                        constraint =>
                                            constraint.__typename ===
                                            'RequiredConstraint'
                                    )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )
                            .with(
                                { __typename: 'URLFieldDefinition' },
                                ({ urlConstraints }) => {
                                    const validators: Validators = []

                                    const isRequired = !!urlConstraints.find(
                                        constraint =>
                                            constraint.__typename ===
                                            'RequiredConstraint'
                                    )

                                    if (isRequired)
                                        validators.push({
                                            validate: 'required',
                                        })

                                    return {
                                        ...base,
                                        validators,
                                    } satisfies IDeveloperField
                                }
                            )
                            /*
                             * Ignoring the following fields in the importer.
                             * The business object will be created with these as empty instead
                             */
                            .with(
                                { __typename: 'ListFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'RelationFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'DocumentFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'UserFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'UpdatesFieldDefinition' },
                                () => null
                            )
                            .with(
                                { __typename: 'CurrencyFieldDefinition' },
                                () => null
                            )
                            .exhaustive()
                    )
                }
            )
        )
    }, [businessObjectDefinitionFields])

    return { csvFields }
}
