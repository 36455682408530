import {
    isDefinitionRestricted,
    sanitiseExistingDefinitionSourceData,
    validateDefinition,
} from 'Utils/BusinessObjectDefinition'
import { Data, State, Status } from './types'

export const makeInit = (data: Data): State => {
    const restricted = isDefinitionRestricted(data.businessObjectDefinition)
    const {
        definition,
        enableUpdates,
        updatesLocked,
        enableDocuments,
        documentsLocked,
    } = sanitiseExistingDefinitionSourceData({
        fieldConstraints: true,
        selectOptions: true,
        enabledUpdates: true,
        enabledDocuments: true,
    })(data.businessObjectDefinition)

    const validation = validateDefinition(definition)

    return {
        open: false,
        status: Status.Idle,
        mode: restricted ? 'restrictedEdit' : 'edit',
        definition: {
            ...definition,
            id: data.businessObjectDefinition.id,
        },
        enableUpdates,
        updatesLocked,
        enableDocuments,
        documentsLocked,
        relationOptions: data.businessObjectDefinitions.map(def => ({
            text: def.name,
            value: def.id,
        })),
        userOptions: data.users.map(user => ({
            text: user.name,
            value: user.id,
        })),
        currencyOptions: data.currencies.map(option => ({
            text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
            value: option.code,
        })),
        customLabel: false,
        fieldEditorOpen: false,
        addFieldOpen: false,
        validation,
    }
}
