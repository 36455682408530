import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";

// This provides a way to navigate locations that are decoupled from the main tree structured path of the url.
// This is useful for controlling multiple drawers using url state from a single path location.
// 
// For example, on the Actions table screen we need to be able to either open a drawer for a related business object,
// or process. If we were to derive the id to be passed to the drawer from the path structure it would not be possible
// to distinguish between `/:processId` and `/:businessObjectId` and determine which drawer to open.
//
// Here we instead attach the drawer's path state as a query parameter, allowing us to distinguish between otherwise identical
// value struictures by the key of the query parameter. The key is determined by the caller so that it can be unique to the
// parent component. However, we can not guarantee that the key will be unique across the entire application, for example in
// the case of a recursive relationship between drawers.
//
// To solve this we append a number to the key, which is incremented each time the hook is called with the same key.
// 
// The problem with this approach is that the applications structure is not necessarily identical for all users,
// due to authorisations for example. This means there is nothing in principle to prevent a location key from bein invalid or losing it's "meaning".
// In most cases this would entail the same result as a normal path that a user would not have access to, but it may be possible for
// complex circumstances to arrise. For example, UserA might copy a link which for them produces a certain application strucutre,
// and send that to another user for whome the link is semantically meaningful, but produces a different application structure.
//
// It is pretty hard to imagine a circumstance where this could cause an actual problem, but it is worth noting as it is not
// prohibited in principle.

const keyReg: Map<string, number> = new Map()

export const useQueryNav = <
    const T extends string[], 
    const U extends Record<`${T[number]}`, string | undefined>
>({ key }: { key: string; path: T }): {
    values: Partial<U> | undefined
    nav: (location: Partial<U>) => void
    remove: () => void
} => {
    const [params, setParams] = useSearchParams()
    const indexedKey = useRef(`${key}${keyReg.get(key) ?? 0}`)

    useEffect(() => {
        keyReg.set(key, (keyReg.get(key) ?? 0) + 1)
        return () => {
            match(keyReg.get(key))
                .when(v => (v ?? 0) > 1, v => keyReg.set(key, v ?? 0 - 1))
                .otherwise(() => keyReg.delete(key))
        }
    }, [key])
    
    const parseParam = useCallback((p: URLSearchParams) =>  (
        p.get(indexedKey.current)?.split('/').reduce((acc: Partial<U>, p) => {
        const [ k, V ] = p.split(':')
        return {
            ...acc,
            [k]: V
        }
    }, {} as Partial<U>)
    ), [indexedKey])

    const nav = useCallback<
        (location: Partial<U>) => void
    >(
        location => {
            setParams(prev => {
                const parsed = parseParam(prev) || {}
                const next = new URLSearchParams(prev)
                next.set(indexedKey.current, Object.entries({
                    ...parsed,
                    ...location
                }).map(([k, v]) => v ? `${k}:${v}` : '').join('/'))
                return next
            })
        },
        [parseParam, setParams, indexedKey]
    )

    const remove = useCallback(() => {
        setParams(prev => {
            if (!indexedKey.current) return prev
            const next = new URLSearchParams(prev)
            next.delete(indexedKey.current)
            return next
        })
    }, [setParams, indexedKey])

    const values = useMemo(() => parseParam(params), [params, parseParam])

    return {
        values,
        nav,
        remove
    }
}