import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { CenteredSpinner } from 'Components/Spinner'
import { ContactUsToast } from 'Components/Toast'
import { BusinessObjectDefinitionEditor } from 'Features/BusinessObjectDefinitionEditor'
import { CreatorModal } from 'Features/CreatorModal'
import { isBusinessObjectDefinitionValid } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { Data, Status } from './types'
import { useEditor } from './useEditor'

type Props = {
    data: Data
}

const Edit: FC<Props> = ({ data }) => {
    const { state, dispatch, updateDefinition, errorOpen, setErrorOpen } =
        useEditor(data)

    return (
        <CreatorModal
            title="Edit dataset"
            description=""
            trigger={{
                icon: 'Edit',
                text: 'Edit dataset',
                size: 'xSmall',
                variant: 'secondary',
            }}
            renderContent={triggerConfirmation => (
                <Styled>
                    <BusinessObjectDefinitionEditor
                        state={state}
                        dispatch={dispatch}
                    />
                    <footer>
                        {state.status === Status.Saving ? (
                            <>
                                <div />
                                <CenteredSpinner />
                                <div />
                            </>
                        ) : (
                            <>
                                <IconTextButton
                                    icon="Close"
                                    text="Cancel"
                                    variant="secondary"
                                    onClick={triggerConfirmation}
                                />
                                {state.mode === 'restrictedEdit' && (
                                    <StyledRestrictedNotice>
                                        <Icon name="Info" />
                                        Editing this dataset is limited due to
                                        its usage within your tenant.
                                    </StyledRestrictedNotice>
                                )}
                                <IconTextButton
                                    icon="Save"
                                    text="Save"
                                    variant="primary"
                                    onClick={updateDefinition}
                                    disabled={
                                        !isBusinessObjectDefinitionValid(
                                            state.validation
                                        )
                                    }
                                />
                            </>
                        )}
                    </footer>
                    <ContactUsToast
                        open={errorOpen}
                        onOpenChange={setErrorOpen}
                        description={'We were unable to save your changes.'}
                    />
                </Styled>
            )}
            open={state.open}
            setOpen={open =>
                dispatch({
                    type: open ? 'open' : 'close',
                    payload: undefined,
                })
            }
            confirmationDescription="If you close the editor now, you will lose all your changes."
            fullScreen
        />
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: 1fr / minmax(0, 1fr);
    height: 100%;
    overflow: hidden;

    > footer {
        grid-column: 1 / 3;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding-top: 1rem;
    }
`

const StyledRestrictedNotice = styled.div`
    background-color: ${({ theme }) => theme.palette.support.bg03.normal};
    border: 1px solid ${({ theme }) => theme.palette.support['03'].normal};
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }
`

export { Edit }
