import { Close } from '@radix-ui/react-dialog'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button/IconButton'

export const CloseButton = () => {
    return (
        <StyledCloseButton asChild>
            <IconButton
                iconName="Close"
                variant="default"
                onClick={e => e.stopPropagation()}
            />
        </StyledCloseButton>
    )
}

export const StyledCloseButton = styled(Close)`
    position: absolute;
    border: none;
    top: 0.75rem;
    right: 0.75rem;
`
