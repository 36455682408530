import { styled } from 'Adapters/Freestyled'
import { Dropdown } from 'Components/Dropdown'
import { DropdownNavButton } from 'Components/Dropdown/Components/DropdownNavButton'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { areas } from './config'

type Props = {
    area: keyof typeof areas
}

const AreaSelect: FC<Props> = ({ area }) => {
    const navigate = useNavigate()

    return (
        <Dropdown
            renderOpenDropdownButton={() => (
                <StyledButton>
                    <Icon name={areas[area].icon} />
                    <span>{areas[area].text}</span>
                    <Icon name="DropDown" />
                </StyledButton>
            )}
            renderContent={() => (
                <ul>
                    {Object.entries(areas).map(
                        ([key, { text, path, icon }]) => (
                            <li key={key}>
                                <DropdownNavButton
                                    text={text}
                                    selected={key === area}
                                    icon={icon}
                                    onSelect={() =>
                                        navigate(`/manage-workspace/${path}`)
                                    }
                                />
                            </li>
                        )
                    )}
                </ul>
            )}
            align="start"
        />
    )
}

const StyledButton = styled.button`
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    > span:nth-child(2) {
        ${regular5}
    }
`

export { AreaSelect }
