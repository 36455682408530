import * as amplitude from '@amplitude/analytics-browser'
import { AMPLITUDE_API_KEY } from './config'

export const amplitudeEnabled =
    typeof AMPLITUDE_API_KEY !== 'undefined' && AMPLITUDE_API_KEY !== ''

if (amplitudeEnabled) {
    amplitude.init(AMPLITUDE_API_KEY!, {
        defaultTracking: true,
    })
}
