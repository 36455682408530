import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Extension } from '../../types'
import { ApplyView } from './ApplyView/ApplyView'
import { EditViewMenu } from './EditViewMenu'
import { SaveView } from './SaveView'

type Props = {
    extension: Extension | undefined
    currentUserId: string
    allAssignablePrincipals: { id: string; name: string }[]
}

export const ViewBar: FC<Props> = ({
    extension,
    currentUserId,
    allAssignablePrincipals,
}) => {
    return (
        <Styled>
            <EditViewMenu
                extension={extension}
                allAssignablePrincipals={allAssignablePrincipals}
            />
            <SaveView />
            <ApplyView currentUserId={currentUserId} extension={extension} />
        </Styled>
    )
}

const Styled = styled.menu`
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 4vw;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
`
