import { styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Text, bold4 } from 'Components/Text'
import { NumberField } from 'Utils/BusinessObjectDefinition'
import { identity } from 'lodash'
import { FC, useRef } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'

type SetMinMax = (
    value: string
) => (
    prev: NumberField['constraints']['range']
) => NumberField['constraints']['range']
const setMin: SetMinMax = (value: string) => prev => {
    const min = match(value)
        .with(P.nullish, () => undefined)
        .with('', () => undefined)
        .otherwise(Number)
    const max = match(prev?.max)
        .with(P.nullish, identity)
        .otherwise(x => (min !== undefined ? Math.max(x, min) : x))
    return { min, max }
}
const setMax: SetMinMax = (value: string) => prev => {
    const max = match(value)
        .with(P.nullish, () => undefined)
        .with('', () => undefined)
        .otherwise(Number)
    const min = match(prev?.min)
        .with(P.nullish, identity)
        .otherwise(x => (max !== undefined ? Math.min(x, max) : x))
    return { min, max }
}

type Props = {
    range: NumberField['constraints']['range']
    update: (
        fn: (
            prev: NumberField['constraints']['range']
        ) => NumberField['constraints']['range']
    ) => void
    displayOnly: boolean
}
const RangeConstraint: FC<Props> = ({ range, update, displayOnly }) => {
    const { current: minFieldId } = useRef(v4())
    const { current: maxFieldId } = useRef(v4())

    return (
        <Styled>
            <Text as="label" variant="bold-5" htmlFor={minFieldId}>
                Min
            </Text>
            {displayOnly ? (
                <StyledDisplayOnly>{range?.min || 'none'}</StyledDisplayOnly>
            ) : (
                <NumberInput
                    id={minFieldId}
                    placeholder="Set min"
                    value={range?.min ?? ''}
                    onChange={({ currentTarget: { value } }) => {
                        update(setMin(value))
                    }}
                />
            )}
            <Text as="label" variant="bold-5" htmlFor={maxFieldId}>
                Max
            </Text>
            {displayOnly ? (
                <StyledDisplayOnly>{range?.max || 'none'}</StyledDisplayOnly>
            ) : (
                <NumberInput
                    id={maxFieldId}
                    placeholder="Set max"
                    value={range?.max ?? ''}
                    onChange={({ currentTarget: { value } }) => {
                        update(setMax(value))
                    }}
                />
            )}
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto auto / auto 1fr;
    align-items: center;
    gap: 1rem;
`

const StyledDisplayOnly = styled.p`
    ${bold4}
    color: ${({ theme }) => theme.palette.text.support03.normal};
`

export { RangeConstraint }
