import { styled } from 'Adapters/Freestyled'
import { GridTable } from 'Components/GridTable'
import { Icon } from 'Components/Icon'
import { Tooltip } from 'Components/Tooltip'
import { compact } from 'lodash'
import { ComponentProps } from 'react'
import { match, P } from 'ts-pattern'
import { ActionsTableItemFragment } from './__generated__/q'
import { UpdatesCell } from './UpdatesCell/UpdatesCell'

type GetExtensionCell = (
    field: NonNullable<
        ActionsTableItemFragment['extendedBy']
    >['fields'][number],
    action: ActionsTableItemFragment
) => ComponentProps<
    typeof GridTable
>['groups'][number]['rows'][number]['cells'][string]

const getExtensionCell: GetExtensionCell = (field, action) => {
    const cell = match(field)
        .with(
            {
                __typename: 'BusinessObjectSelectField',
                fieldDefinition: { __typename: 'SelectFieldDefinition' },
            },
            ({ fieldDefinition, selectValue }) => ({
                element: match(
                    fieldDefinition.selectOptions.filter(o =>
                        selectValue.includes(o.id)
                    )
                )
                    .with([], () => (
                        <StyledUnselected>
                            <Icon name="CheckboxTickedInverted" />
                            <span>0 selected</span>
                        </StyledUnselected>
                    ))
                    .with([P.not(P.nullish)], ([{ value }]) => (
                        <StyledSelectCell>
                            <Icon name="CheckboxTickedInverted" />
                            <span>{value}</span>
                        </StyledSelectCell>
                    ))
                    .otherwise(options => (
                        <Tooltip
                            content={
                                <StyledTooltipList>
                                    {options.map(o => (
                                        <li key={o.id}>{o.value}</li>
                                    ))}
                                </StyledTooltipList>
                            }
                        >
                            <StyledSelectCell>
                                <Icon name="CheckboxTickedInverted" />
                                <span>{`${options.length} selected`}</span>
                            </StyledSelectCell>
                        </Tooltip>
                    )),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectBooleanField',
                fieldDefinition: { __typename: 'BooleanFieldDefinition' },
            },
            ({ booleanValue }) => ({
                element: `${booleanValue}`,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectDocumentField',
                fieldDefinition: { __typename: 'DocumentFieldDefinition' },
            },
            ({ docValue }) => ({
                element: docValue?.name,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectEmailField',
                fieldDefinition: { __typename: 'EmailFieldDefinition' },
            },
            ({ emailValue }) => ({
                element: emailValue,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectNumberField',
                fieldDefinition: { __typename: 'NumberFieldDefinition' },
            },
            ({ numValue }) => ({
                element: match(numValue)
                    .with(P.number, val => `${val}`)
                    .otherwise(() => undefined),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectTextField',
                fieldDefinition: { __typename: 'TextFieldDefinition' },
            },
            ({ textValue }) => ({ element: textValue })
        )
        .with(
            {
                __typename: 'BusinessObjectTelephoneField',
                fieldDefinition: { __typename: 'TelephoneFieldDefinition' },
            },
            ({ telephoneValue }) => ({
                element: match(telephoneValue)
                    .with(P.nullish, () => undefined)
                    .otherwise(({ countryCode, number }) =>
                        compact([countryCode, number]).join(' ')
                    ),
            })
        )
        .with(
            {
                __typename: 'BusinessObjectUrlField',
                fieldDefinition: { __typename: 'URLFieldDefinition' },
            },
            ({ urlValue }) => ({
                element: urlValue && (
                    <StyledAnchor
                        target="_blank"
                        rel="noreferrer"
                        href={urlValue}
                    >
                        {urlValue}
                    </StyledAnchor>
                ),
                stopPropagation: !!urlValue,
            })
        )
        .with(
            {
                __typename: 'BusinessObjectListField',
                fieldDefinition: {
                    listOf: { __typename: 'UpdatesFieldDefinition' },
                },
            },
            ({ listValue }) => ({
                element: (
                    <UpdatesCell
                        updateCount={listValue.length}
                        businessObjectId={action.extendedBy?.id}
                    />
                ),
                stopPropagation: true,
            })
        )
        .otherwise(() => ({
            // Not yet implemented
            element: <p>Not implemented</p>,
        }))

    return cell
}

const StyledSelectCell = styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    svg {
        --icon-size: 1rem;
        height: var(--icon-size);
        width: var(--icon-size);
        min-height: var(--icon-size);
        min-width: var(--icon-size);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    > span:last-child {
        white-space: nowrap;
    }
`

const StyledUnselected = styled(StyledSelectCell)`
    color: ${({ theme }) => theme.palette.text['03'].normal};
    opacity: 0.5;
`

const StyledTooltipList = styled.ul`
    text-align: left;
    list-style: disc;
    padding-left: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const StyledAnchor = styled.a`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    transition: color ${({ theme }) => theme.animation.buttonTransitionDuration}
        ease;

    &:hover {
        color: ${({ theme }) => theme.palette.text.support03.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support03.active};
    }
`

export { getExtensionCell }
