import { logger } from 'Adapters/Logger'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { Document } from './types'
import { useBusinessObjectById_DocumentsQuery } from './__generated__/query'

type Params = {
    businessObjectId: string
}

export const useGetDocumentsFromBusinessObject = ({
    businessObjectId,
}: Params) => {
    const { data } = useBusinessObjectById_DocumentsQuery({
        variables: { input: { id: businessObjectId } },
        fetchPolicy: 'cache-and-network',
        onError(err) {
            logger.error(
                'Failed to fetch business object from documents tab',
                err,
                { businessObjectId }
            )
        },
    })

    const fields = useMemo(() => {
        if (!data) return []
        return data.businessObject.fields
    }, [data])

    const documents: Document[] = useMemo(() => {
        const docs = fields.flatMap(field => {
            return match(field)
                .with(
                    { __typename: 'BusinessObjectDocumentField' },
                    ({ docValue }) => docValue ?? null
                )
                .with(
                    { __typename: 'BusinessObjectListField' },
                    ({ listValue }) =>
                        listValue.map(field =>
                            match(field)
                                .with(
                                    {
                                        __typename:
                                            'BusinessObjectDocumentField',
                                    },
                                    ({ docValue }) => docValue ?? null
                                )
                                .otherwise(() => null)
                        )
                )
                .otherwise(() => null)
        })

        return compact(docs)
    }, [fields])

    return {
        documents,
        fields,
        hasInitiallyLoaded: data !== undefined,
    }
}
