import { logger } from 'Adapters/Logger'
import { useTriggerToast } from 'Components/Toast'
import { useCallback } from 'react'
import { useSingleProcess_AddBusinessObjectsMutation } from './__generated__/q'

export const useAddBusinessObjectsToProcess = (processId: string) => {
    const [add, { loading }] = useSingleProcess_AddBusinessObjectsMutation()

    const { open, setOpen, triggerToast } = useTriggerToast()

    const addBusinessObjectsToProcess = useCallback(
        (businessObjectIds: string[], onSuccess: () => void) => {
            add({
                variables: { input: { businessObjectIds, processId } },
                onCompleted: onSuccess,
                onError(error) {
                    triggerToast()
                    logger.error(
                        'Failed to add business object to process',
                        error
                    )
                },
                onQueryUpdated: q => q.refetch(),
                refetchQueries: ['OnboardingProgress'],
            })
        },
        [add, processId, triggerToast]
    )

    return {
        addBusinessObjectsToProcess,
        loading,
        errorToastOpen: open,
        setErrorToastOpen: setOpen,
    }
}
