import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { useDeleteActionMutation } from './__generated__/mutation'
import { Action_And_ExtensionQuery } from './__generated__/query'

type Props = {
    action: Action_And_ExtensionQuery['action']
    onDeleted: () => void
}

export const DeleteAction: FC<Props> = ({ action, onDeleted }) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const [deleteAction, { loading }] = useDeleteActionMutation({
        variables: { input: { actionId: action.id } },
        onError: error => {
            triggerToast()
            logger.error('Failed to delete action', error, {
                actionId: action.id,
            })
        },
        onCompleted: () => {
            onDeleted()
        },
        update: cache => {
            cache.evict({
                id: `${action.__typename}:${action.id}`,
            })
        },
        optimisticResponse: {
            deleteAction: true,
        },
    })

    return (
        <>
            {match(action.canDelete)
                .with({ __typename: 'CanDeleteActionYes' }, () => (
                    <IconTextButton
                        disabled={loading}
                        variant="secondary"
                        icon={'Delete'}
                        text={'Delete action'}
                        onClick={() => {
                            deleteAction()
                        }}
                    />
                ))
                .with({ __typename: 'CanDeleteActionNo' }, ({ reason }) => (
                    <Tooltip content={reason}>
                        <IconTextButton
                            disabled
                            variant="secondary"
                            icon={'Delete'}
                            text={'Delete action'}
                            onClick={() => {
                                deleteAction()
                            }}
                        />
                    </Tooltip>
                ))
                .exhaustive()}

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description={
                    'We were unable to delete your action. Contact us if the problem persists.'
                }
            />
        </>
    )
}
