import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { CreateRelationModal } from 'Features/CreateRelationModal'
import { useFetchBusinessObjectsById, useModalControls } from 'Hooks'
import { FC, useEffect, useMemo, useState } from 'react'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useRelationConstraints } from './useRelationConstraints'

export const RelationField: FC<BODField<'RelationFieldDefinition'>> = ({
    relationConstraints,
    prefilledBusinessObject,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } =
        useRelationConstraints(relationConstraints)

    const data = useFetchBusinessObjectsById(constraints.relationId)

    const modalControls = useModalControls()

    const options = useMemo(() => {
        return (
            data?.businessObjects.map(bo => {
                return {
                    id: bo.id,
                    label: bo.label,
                }
            }) ?? []
        )
    }, [data])

    const [selectedOption, setSelectedOption] = useState<string>(() => {
        return state[common.id].value as string
    })

    /**
     * In an effect here because we need this to happen after options have been loaded
     */
    useEffect(() => {
        const prefilledOption = options.find(
            ({ id }) => id === prefilledBusinessObject?.id
        )
        if (prefilledOption) {
            setSelectedOption(prefilledOption.id)
        }
    }, [setSelectedOption, options, prefilledBusinessObject])

    useEffect(() => {
        dispatch({
            type: 'updateRelationField',
            payload: { id: common.id, value: selectedOption },
        })
    }, [selectedOption, dispatch, common.id])

    const label = useMemo(() => {
        const label = !selectedOption
            ? `Select: ${common.name}`
            : options.find(option => option.id === selectedOption)?.label ?? ''

        const shouldTruncate = label.length > 50

        return {
            text: shouldTruncate ? label.substring(0, 50) + '...' : label,
            original: label,
            isTruncated: shouldTruncate,
        }
    }, [common.name, options, selectedOption])

    return (
        <>
            <Field
                id={common.id}
                name={common.name}
                required={!!constraints.required}
                description={common.description ?? ''}
                constraintsTooltip={constraintsTooltip}
                errors={state[common.id].errors}
            >
                <Dropdown
                    renderContent={() => (
                        <>
                            <DropdownButton
                                text={'-- No Selection --'}
                                onSelect={() => setSelectedOption('')}
                            />
                            {options.map(option => (
                                <DropdownButton
                                    key={option.id}
                                    text={option.label}
                                    onSelect={() =>
                                        setSelectedOption(option.id)
                                    }
                                />
                            ))}
                        </>
                    )}
                    renderInSpan
                    renderOpenDropdownButton={() =>
                        label.isTruncated ? (
                            <Tooltip content={label.original}>
                                <StyledDropdownButton
                                    icon="Dashboard"
                                    text={label.text}
                                />
                            </Tooltip>
                        ) : (
                            <StyledDropdownButton
                                icon="Dashboard"
                                text={label.text}
                            />
                        )
                    }
                    variant="light"
                />

                <IconButton
                    iconName="AddSmall"
                    title={`Create new ${
                        data?.businessObjects[0]?.definition.name ?? 'Relation'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        modalControls.openModal()
                    }}
                />
            </Field>
            <CreateRelationModal
                businessObjectDefinitionId={constraints.relationId}
                onBusinessObjectCreated={businessObject => {
                    setSelectedOption(businessObject.id)
                }}
                {...modalControls}
            />
        </>
    )
}

const StyledDropdownButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
