import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Available_IntegrationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Available_IntegrationsQuery = { __typename?: 'Query', availableIntegrations: Array<{ __typename?: 'Integration', name: string, displayName: string, actions: Array<{ __typename?: 'IntegrationAction', name: string }>, connection?: { __typename?: 'Connection', id: string } | null, instances: Array<{ __typename?: 'IntegrationInstance', id: string }> }>, currentUser: { __typename?: 'User', activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string } } | null } };


export const Available_IntegrationsDocument = gql`
    query Available_Integrations {
  availableIntegrations {
    name
    displayName
    actions {
      name
    }
    connection {
      id
    }
    instances {
      id
    }
  }
  currentUser {
    activeTenant {
      tenant {
        id
      }
    }
  }
}
    `;

/**
 * __useAvailable_IntegrationsQuery__
 *
 * To run a query within a React component, call `useAvailable_IntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailable_IntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailable_IntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailable_IntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<Available_IntegrationsQuery, Available_IntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Available_IntegrationsQuery, Available_IntegrationsQueryVariables>(Available_IntegrationsDocument, options);
      }
export function useAvailable_IntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Available_IntegrationsQuery, Available_IntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Available_IntegrationsQuery, Available_IntegrationsQueryVariables>(Available_IntegrationsDocument, options);
        }
export type Available_IntegrationsQueryHookResult = ReturnType<typeof useAvailable_IntegrationsQuery>;
export type Available_IntegrationsLazyQueryHookResult = ReturnType<typeof useAvailable_IntegrationsLazyQuery>;
export type Available_IntegrationsQueryResult = Apollo.QueryResult<Available_IntegrationsQuery, Available_IntegrationsQueryVariables>;