import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import { SavedConfig } from '../../../types'
import { DeleteView } from './DeleteView'

type Props = {
    view: SavedConfig
}

export const ManageSingleView: FC<Props> = ({ view }) => (
    <StyledManageSingleView>
        <div className="name-wrapper">
            <Icon name={'View'} />
            <span>{view.label}</span>
        </div>

        <div>
            <DeleteView id={view.id} />
        </div>
    </StyledManageSingleView>
)

const StyledManageSingleView = styled.li`
    ${flex('row', 'space-between', 'center')};
    width: 100%;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.5rem;
    }

    .name-wrapper {
        flex: 1;
        padding-right: 0.5rem;

        input {
            flex: 1;
        }
    }
`
