import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { Process_CatalogueFragmentDoc } from '../../../../../Fragments/__generated__/Process';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeployTemplateMutationVariables = Types.Exact<{
  input: Types.DeployTemplateInput;
}>;


export type DeployTemplateMutation = { __typename?: 'Mutation', deployTemplate: { __typename?: 'DeployedTemplate', processes: Array<{ __typename?: 'Process', id: string, name: string, description?: string | null, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', activeBusinessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }>, usage: { __typename?: 'ProcessUsage', businessObjects: number } }>, businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string, description?: string | null }> } };


export const DeployTemplateDocument = gql`
    mutation DeployTemplate($input: DeployTemplateInput!) {
  deployTemplate(input: $input) {
    processes {
      ...Process_Catalogue
    }
    businessObjectDefinitions {
      id
      name
      description
    }
  }
}
    ${Process_CatalogueFragmentDoc}`;
export type DeployTemplateMutationFn = Apollo.MutationFunction<DeployTemplateMutation, DeployTemplateMutationVariables>;

/**
 * __useDeployTemplateMutation__
 *
 * To run a mutation, you first call `useDeployTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployTemplateMutation, { data, loading, error }] = useDeployTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeployTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeployTemplateMutation, DeployTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployTemplateMutation, DeployTemplateMutationVariables>(DeployTemplateDocument, options);
      }
export type DeployTemplateMutationHookResult = ReturnType<typeof useDeployTemplateMutation>;
export type DeployTemplateMutationResult = Apollo.MutationResult<DeployTemplateMutation>;
export type DeployTemplateMutationOptions = Apollo.BaseMutationOptions<DeployTemplateMutation, DeployTemplateMutationVariables>;