import { useMemo } from 'react'
import { oneOfPair } from 'Utils'

type DateDefaultValue =
    | {
          absolute?: string | null | undefined
          relative?: string | null | undefined
      }
    | null
    | undefined

type DefaultTuple =
    | [undefined, undefined]
    | ['absolute', string]
    | ['relative', string]

export const useDateDefault = (dateDefaultValue: DateDefaultValue) => {
    return useMemo(() => {
        if (!dateDefaultValue) return [undefined, undefined]
        return oneOfPair(dateDefaultValue)
    }, [dateDefaultValue]) satisfies DefaultTuple
}
