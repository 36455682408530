import * as Popover from '@radix-ui/react-popover'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { getPortalsContainer } from 'Utils'
import { keyframes } from 'styled-components'

export const MentionHelp = () => {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <IconButton iconName="At" variant="secondary" />
            </Popover.Trigger>

            <Popover.Portal container={getPortalsContainer()}>
                <StyledContent side="bottom" align="center" sideOffset={10}>
                    <StyledTooltipContent>
                        <Text variant="regular-6" as={'h4'}>
                            Mention Controls
                        </Text>

                        <div className="controls">
                            <Text as="kbd" variant="regular-5">
                                @
                            </Text>
                            <Text variant="regular-5" as={'p'}>
                                open
                            </Text>
                        </div>

                        <div className="controls">
                            <Text as="kbd" variant="regular-5">
                                ↓
                            </Text>
                            <Text as="kbd" variant="regular-5">
                                ↑
                            </Text>

                            <Text variant="regular-5" as={'p'}>
                                navigate
                            </Text>
                        </div>

                        <div className="controls">
                            <Text as="kbd" variant="regular-5">
                                enter
                            </Text>
                            <Text as="kbd" variant="regular-5">
                                tab
                            </Text>

                            <Text variant="regular-5" as={'p'}>
                                confirm
                            </Text>
                        </div>
                    </StyledTooltipContent>
                </StyledContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const DURATION = 400
const TIMING = 'cubic-bezier(0.16, 1, 0.3, 1)'

const slideOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideBack = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-1rem);
  }
`

const StyledContent = styled(Popover.Content)`
    max-height: 75vh;
    max-width: 14rem;
    width: 100vw;
    padding: 1rem;
    background-color: white;
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    animation-duration: ${DURATION}ms;
    animation-timing-function: ${TIMING};
    will-change: opacity, transform;

    h4 {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .controls {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.5rem;
        width: 100%;

        kbd {
            background-color: ${({ theme }) => theme.palette.ui['02'].normal};
            padding: 0.25rem 0.5rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};
        }

        p {
            margin-left: auto;
        }
    }

    &[data-state='open'][data-side='bottom'] {
        animation-name: ${slideOut};
    }

    &[data-state='closed'][data-side='bottom'] {
        animation-name: ${slideBack};
    }
`

const StyledTooltipContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;

    span a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }
`
