import { css, styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Icon } from '../../../../Components/Icon'
import { ViewImageModal } from './ViewImageModal'

type Props = {
    title: string
    children: React.ReactNode
    onClick: () => void
    isSelected?: boolean
    interactive?: boolean
    imageURL?: string
}

export const Card: FC<Props> = ({
    title,
    children,
    onClick,
    isSelected,
    interactive = true,
    imageURL,
}) => {
    return (
        <Styled onClick={onClick} tabIndex={0} $interactive={interactive}>
            <header>
                <Text as="h4" variant="bold-3">
                    {title}
                </Text>
                {imageURL ? (
                    <ViewImageModal imageURL={imageURL} title={title} />
                ) : null}
                {typeof isSelected === 'undefined' ? null : isSelected ? (
                    <Icon name="CheckboxTicked" />
                ) : (
                    <Icon name="CheckboxUnticked" />
                )}
            </header>
            <div>{children}</div>
        </Styled>
    )
}

const interactiveStyles = css`
    cursor: pointer;

    &:hover {
        filter: drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}
        );
        border-color: ${({ theme }) => theme.palette.ui['03'].hover};
    }
`

const Styled = styled.li<{ $interactive: boolean }>`
    ${({ $interactive }) => $interactive && interactiveStyles};
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
    border-radius: 0.25rem;
    height: 100%;
    transition: filter
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
        border-color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease;

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        padding: 1rem;
        background: ${({ theme }) => theme.palette.ui['03'].normal};

        > h4 {
            margin-right: auto;
        }
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 1rem 0.5rem 1rem;
        gap: 0.5rem;
    }

    > div p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > footer {
        padding: 0.75rem 1rem;
        display: flex;
        gap: 1rem;
    }
`
