import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Drawer } from 'Components/Drawer/Drawer'
import { SingleDocument } from 'Features/SingleDocument'
import { FC, useRef, useState } from 'react'

type Props = {
    id: string
}

const DocumentDisplay: FC<Props> = ({ id }) => {
    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)

    return (
        <>
            <StyledIconButton
                ref={ref}
                iconName={'Search'}
                title={'View Document'}
                onClick={() => setOpen(true)}
                variant="secondary"
            />
            <Drawer
                open={open}
                onOpenChange={setOpen}
                content={<SingleDocument id={id} />}
                returnFocus={() => ref.current?.focus()}
            />
        </>
    )
}

const StyledIconButton = styled(IconButton)`
    min-height: 2.75rem;
    min-width: 2.75rem;
`

export { DocumentDisplay }
