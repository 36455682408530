import { Select } from 'Components/Select'
import {
    UserField as Field,
    UserFieldValidation,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: UserFieldValidation
    onSubmit: OnSubmit<Field, UserFieldValidation>
    onClose: () => void
    userOptions: {
        value: string
        text: string
    }[]

    hideRequired: boolean
}

const UserField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,
    userOptions,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, UserFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <UserSubFields
                defaultValue={{
                    value: state.field.defaultValue ?? '',
                    onChange: value => {
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                defaultValue: value,
                            },
                        }))
                    },
                }}
                userOptions={userOptions}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type SubFieldsProps = {
    defaultValue: {
        value: string
        onChange: (value: string) => void
    }
    userOptions: {
        value: string
        text: string
    }[]
}

const UserSubFields: FC<SubFieldsProps> = ({ defaultValue, userOptions }) => {
    const { current: defaultFieldId } = useRef(v4())
    return (
        <InputWrapper
            label="Default value"
            fieldId={defaultFieldId}
            description="This will set a default user when a new record is created."
        >
            <Select
                id={defaultFieldId}
                name={defaultFieldId}
                value={defaultValue.value}
                onValueChange={defaultValue.onChange}
                options={[
                    {
                        text: '- No default user -',
                        value: '',
                    },
                    ...userOptions,
                ]}
                disabled={userOptions.length < 1}
            />
        </InputWrapper>
    )
}

export { UserField, UserSubFields }
