import { useCallback, useState } from 'react'
import { Template } from './Components/Templates'
import { stataliseField } from './helpers'
import { State } from './types'

type Params = {
    setHasStarted: React.Dispatch<React.SetStateAction<boolean>>
    setState: React.Dispatch<React.SetStateAction<State>>
    resetState: () => void
}

export const useTemplate = ({
    setHasStarted,
    setState,
    resetState,
}: Params) => {
    const [template, setTemplate] = useState<Template>()

    const handleTemplateSelected = useCallback(
        (template: Template | undefined) => {
            setTemplate(template)
            setHasStarted(true)

            if (!template) {
                return resetState()
            }

            setState({
                ...template,
                fields: template.fields.map(stataliseField),
            })
        },
        [resetState, setHasStarted, setState, setTemplate]
    )

    return { template, setTemplate, handleTemplateSelected }
}
