import { Field, FieldType } from 'Utils/BusinessObjectDefinition'
import {
    FieldType as GeneratedFieldType,
    PatchBusinessObjectDefinitionInput,
    PatchBusinessObjectUpdateLabelInput,
    PatchBusinessObjectUpdateNameInput,
} from '__generated__'
import { compact } from 'lodash'
import { v4 } from 'uuid'
import { Data, State } from '../types'
import { createFieldOperations } from './fieldOperations'
import { FieldsFromState, UpdatesField } from './types'

// This UX does not currently facilitat the "removeFieldConstraint" operation.
// Constraints are not stored here as collections but as well structured objects.
// Field constraints are defined in forms with no simple way of giving the user
// a 'remove constraint' action.
// This functionality will need to be dewlivered seperately, but should arguably
// be superceded by a general approach to constraint modification, which should
// form part of a broader change management strategy.

const updatesField = (
    definition: Data['businessObjectDefinition']
): UpdatesField => {
    const extant = definition.fields.find(
        f =>
            f.type === GeneratedFieldType.List &&
            'listOf' in f &&
            f.listOf.type === GeneratedFieldType.Updates
    )

    return {
        type: GeneratedFieldType.Updates,
        id: extant?.id || v4(),
        name: extant?.name || 'Updates',
        description: extant?.description || 'Updates about this object',
    }
}

const documentsField = (
    definition: Data['businessObjectDefinition']
): Field => {
    const extant = definition.fields.find(
        f =>
            f.type === GeneratedFieldType.List &&
            'listOf' in f &&
            f.listOf.type === GeneratedFieldType.Document &&
            f.name === 'Documents'
    )
    return {
        type: FieldType.List,
        id: extant?.id || v4(),
        name: extant?.name || 'Documents',
        description: extant?.description || 'Documents relating to this object',
        listOf: { type: FieldType.Document, constraints: { required: false } },
        constraintsLocked: true,
    }
}

type RecombineToggleFields = (_: {
    definition: Data['businessObjectDefinition']
    state: State
}) => FieldsFromState
const recombineToggleFields: RecombineToggleFields = ({ definition, state }) =>
    compact([
        ...state.definition.fields,
        state.enableUpdates && updatesField(definition),
        state.enableDocuments && documentsField(definition),
    ])

type CreateUpdateNameOperation = (_: {
    definition: Data['businessObjectDefinition']
    state: State
}) => { updateName: PatchBusinessObjectUpdateNameInput } | undefined
const createUpdateNameOperation: CreateUpdateNameOperation = ({
    definition,
    state,
}) =>
    definition.name !== state.definition.name
        ? {
              updateName: {
                  previousValue: definition.name,
                  nextValue: state.definition.name,
              },
          }
        : undefined

type CreateUpdateDescriptionOperation = (_: {
    definition: Data['businessObjectDefinition']
    state: State
}) => { updateDescription: PatchBusinessObjectUpdateNameInput } | undefined
const createUpdateDescriptionOperation: CreateUpdateDescriptionOperation = ({
    definition,
    state,
}) =>
    (definition.description || undefined) !==
    (state.definition.description || undefined)
        ? {
              updateDescription: {
                  previousValue: definition.description ?? '',
                  nextValue: state.definition.description ?? '',
              },
          }
        : undefined

type CreateUpdateLabelOperation = (_: {
    definition: Data['businessObjectDefinition']
    state: State
}) => { updateLabel: PatchBusinessObjectUpdateLabelInput } | undefined
const createUpdateLabelOperation: CreateUpdateLabelOperation = ({
    definition,
    state,
}) =>
    definition.label !== state.definition.label
        ? {
              updateLabel: {
                  previousValue: definition.label,
                  nextValue: state.definition.label,
              },
          }
        : undefined

type MakeMutationVariables = (_: {
    definition: Data['businessObjectDefinition']
    state: State
}) => {
    input: PatchBusinessObjectDefinitionInput
}
const makeMutationVariables: MakeMutationVariables = ({
    definition,
    state,
}) => ({
    input: {
        id: state.definition.id,
        operations: compact([
            createUpdateNameOperation({ definition, state }),
            createUpdateDescriptionOperation({ definition, state }),
            createUpdateLabelOperation({ definition, state }),
            ...createFieldOperations({
                definition,
                fields: recombineToggleFields({ definition, state }),
            }),
        ]),
    },
})

export { makeMutationVariables }
