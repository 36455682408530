import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Spinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { SimpleToast } from 'Components/Toast'
import { useModalControls, useResponder } from 'Hooks'
import { has } from 'Utils'
import { CreateProcessInput } from '__generated__'
import { FC, useCallback } from 'react'
import { Mode } from '../types'
import { useProcessCreator } from '../useProcessCreator'
import { FieldCreation } from '../useProcessCreator/types'
import { validateDefinition, validateInput } from '../validation'
import { useSave } from './useSave'

type Props = {
    mode: Mode
}

export const SaveProcess: FC<Props> = ({ mode }) => {
    const { state, isProcessFirst } = useProcessCreator()

    const { input, fields } = state

    const { openModal, closeModal, open, setOpen, buttonRef, returnFocus } =
        useModalControls()

    const { handleSave, loading, setShowErrorToast, showErrorToast } =
        useSave(mode)

    const handleSaveButtonClicked = useCallback(() => {
        if (finalPhaseHasCriteria(input, isProcessFirst, fields)) {
            handleSave()
        } else {
            openModal()
        }
    }, [input, isProcessFirst, fields, handleSave, openModal])

    const { isLarge } = useResponder()

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <IconTextButton
                    icon={loading ? 'Spinner' : 'Save'}
                    iconRight
                    text="Save"
                    variant="primary"
                    disabled={
                        !validateInput(input) &&
                        (isProcessFirst
                            ? validateDefinition({
                                  label: state.label.value,
                                  fields: state.fields.map(
                                      ({ field }) => field
                                  ),
                              })
                            : true)
                    }
                    onClick={handleSaveButtonClicked}
                    ref={buttonRef}
                    size={isLarge ? 'small' : 'xSmall'}
                />
            )}

            <SimpleToast
                open={showErrorToast}
                onOpenChange={setShowErrorToast}
                description="We were unable to save your workflow. Please try again."
            />

            <ControlledModal
                renderContent={() => (
                    <StyledConfirmCriteria>
                        <Text as={'p'} variant={'regular-4'}>
                            The final phase of a workflow should ideally contain
                            some criteria.
                        </Text>

                        <Text as={'p'} variant={'regular-4'}>
                            A phase represents a collection of work that needs
                            to get done. Once all the work is complete, it will
                            automatically be marked as such.
                        </Text>

                        <Text as={'p'} variant={'regular-4'}>
                            There is no need for an empty final phase to
                            represent this.
                        </Text>

                        <div>
                            <TextButton
                                text={'Go back'}
                                onClick={closeModal}
                                size="small"
                            />

                            <TextButton
                                text={'Proceed anyway'}
                                onClick={handleSave}
                                size="small"
                                variant="secondary"
                            />
                        </div>
                    </StyledConfirmCriteria>
                )}
                title={'Missing final phase criteria'}
                description={''}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const datasetFirstFinalCriterion = (input: CreateProcessInput) => {
    const finalPhase = input.phases[input.phases.length - 1]
    const noOfCriteria = finalPhase.criteria.length
    if (noOfCriteria > 0) return true
    return false
}

const finalPhaseHasCriteria = (
    input: CreateProcessInput,
    isProcessFirst: boolean,
    fields: FieldCreation[]
) => {
    if (!isProcessFirst) {
        return datasetFirstFinalCriterion(input)
    }

    const finalPhaseDataCriteria = fields.find(
        ({ requiredBy }) =>
            has(requiredBy, 'phase') &&
            requiredBy.phase === input.phases.length - 1
    )

    return finalPhaseDataCriteria || datasetFirstFinalCriterion(input)
}

const StyledConfirmCriteria = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 30rem;

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        ${flex('row', 'center', 'center')};
        gap: 1rem;
        width: 100%;

        > button {
            flex: 1;
        }
    }
`
