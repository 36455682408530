import { Icon as SelectIcon, Trigger, Value } from '@radix-ui/react-select'
import { css, flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'

export type SelectTriggerProps = {
    id: string
    hasError: boolean
    hasValue: boolean
    disabled?: boolean
    placeholder?: string
}

export const SelectTrigger: FC<SelectTriggerProps> = ({
    id,
    hasError,
    hasValue,
    disabled = false,
    placeholder,
}) => (
    <StyledTrigger
        id={id}
        $hasError={hasError}
        $disabled={disabled}
        className="select-trigger"
        data-hasvalue={hasValue}
    >
        <Value placeholder={placeholder} />
        <StyledSelectIcon>
            <Icon name={'AngleDown'} />
        </StyledSelectIcon>
    </StyledTrigger>
)

const disabledStyles = css`
    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon.primary.disabled};
    }
`

const StyledTrigger = styled(Trigger)<{
    $hasError: boolean
    $disabled: boolean
}>`
    ${flex('row', 'flex-start', 'center')};
    ${regular5};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    color: ${({ theme }) => theme.palette.text['01'].normal};
    width: 100%;
    padding: 0.625rem 0.875rem;
    max-height: 2.813rem;
    margin-top: 0;
    border: 0.125rem solid
        ${({ $hasError, theme }) =>
            $hasError ? theme.palette.support['01'].normal : 'transparent'};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    &[data-hasvalue='false'] {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    ${({ $disabled }) => $disabled && disabledStyles}
`

const StyledSelectIcon = styled(SelectIcon)`
    margin-left: auto;
`
