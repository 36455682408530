import * as amplitude from '@amplitude/analytics-browser'

export enum WelcomePageEvent {
    AI = 'START_WITH_AI_CLICKED',
    Template = 'START_WITH_TEMPLATE_CLICKED',
    Learn = 'LEARN_ABOUT_MODLIFY_CLICKED',
    Scratch = 'START_FROM_SCRATCH_CLICKED',
}

export const trackWelcomePageInteraction = (event: WelcomePageEvent) => {
    amplitude.track(event)
}
