import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import { PrincipalFragmentDoc } from '../../../../Features/Principal/__generated__/Principal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDocumentsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.GetFilesInput>;
}>;


export type FetchDocumentsQuery = { __typename?: 'Query', files: Array<{ __typename?: 'File', id: string, name: string, uploadedAt: string, sizeInBytes: number, fileType: string, canDelete: boolean, url: string, uploadedBy: { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } } | { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } } | { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } }, permissions: { __typename?: 'Permissions', delete: boolean } }> };

export type UploadSignatureQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UploadSignatureQuery = { __typename?: 'Query', uploadSignature: { __typename?: 'UploadSignature', expire: string, signature: string } };


export const FetchDocumentsDocument = gql`
    query FetchDocuments($input: GetFilesInput) {
  files(input: $input) {
    id
    name
    uploadedAt
    uploadedBy {
      ...Principal
    }
    sizeInBytes
    fileType
    permissions {
      delete
    }
    canDelete
    url
  }
}
    ${PrincipalFragmentDoc}`;

/**
 * __useFetchDocumentsQuery__
 *
 * To run a query within a React component, call `useFetchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<FetchDocumentsQuery, FetchDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDocumentsQuery, FetchDocumentsQueryVariables>(FetchDocumentsDocument, options);
      }
export function useFetchDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDocumentsQuery, FetchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDocumentsQuery, FetchDocumentsQueryVariables>(FetchDocumentsDocument, options);
        }
export type FetchDocumentsQueryHookResult = ReturnType<typeof useFetchDocumentsQuery>;
export type FetchDocumentsLazyQueryHookResult = ReturnType<typeof useFetchDocumentsLazyQuery>;
export type FetchDocumentsQueryResult = Apollo.QueryResult<FetchDocumentsQuery, FetchDocumentsQueryVariables>;
export const UploadSignatureDocument = gql`
    query UploadSignature {
  uploadSignature {
    expire
    signature
  }
}
    `;

/**
 * __useUploadSignatureQuery__
 *
 * To run a query within a React component, call `useUploadSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadSignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useUploadSignatureQuery(baseOptions?: Apollo.QueryHookOptions<UploadSignatureQuery, UploadSignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadSignatureQuery, UploadSignatureQueryVariables>(UploadSignatureDocument, options);
      }
export function useUploadSignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadSignatureQuery, UploadSignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadSignatureQuery, UploadSignatureQueryVariables>(UploadSignatureDocument, options);
        }
export type UploadSignatureQueryHookResult = ReturnType<typeof useUploadSignatureQuery>;
export type UploadSignatureLazyQueryHookResult = ReturnType<typeof useUploadSignatureLazyQuery>;
export type UploadSignatureQueryResult = Apollo.QueryResult<UploadSignatureQuery, UploadSignatureQueryVariables>;