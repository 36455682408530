import { Toggle } from 'Components/Input'
import { has } from 'lodash'

type RequiredConstraintValue = {
    required?: { ignoreMe?: string | null } | null
}

type Props = {
    index: number
    onChange: (constraint: RequiredConstraintValue | undefined) => void
    value: RequiredConstraintValue | undefined
}

export const RequiredConstraint: React.FC<Props> = ({
    index,
    onChange,
    value,
}) => (
    <Toggle
        name={`bod-field-required-${index}`}
        id={`bod-field-required-${index}`}
        indicatorText={['Required', 'Not required']}
        checked={has(value, 'required')}
        onCheckedChange={checked =>
            onChange(checked ? { required: {} } : undefined)
        }
    />
)
