import { Value } from '@udecode/plate-common'
import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { NumberInput, TextInput, Toggle } from 'Components/Input'
import { RichText } from 'Components/RichText'
import { Select } from 'Components/Select'
import { regular5 } from 'Components/Text'
import { useOrganisationUsers } from 'Hooks'
import { omit } from 'lodash'
import { pipe } from 'lodash/fp'
import { FC, useRef, useState } from 'react'
import { SelectButton } from '../../Components/Button/SelectButton'
import { fromEvent, preventDefault } from '../../Utils'
import { RecurringScheduleFrequency } from '../../__generated__'
import { BusinessObjectFieldset } from '../BusinessObjectFieldset'
import { ActionExtensionObjects_CompleteQuery } from './__generated__/query'
import { RelatesTo } from './types'
import { useCreateAction } from './useCreateAction'
import { useForm } from './useForm'
import { validateForm } from './useFormValidation'
import { useScheduleAction } from './useScheduleAction'

type Props = {
    closeModal: () => void
    relatesTo?: RelatesTo
    bodExtension:
        | ActionExtensionObjects_CompleteQuery['businessObjectDefinitionExtensions'][number]
        | undefined
    currentUserId: string
    userGroups: Array<{ id: string; name: string; principal: { id: string } }>
}

export const CreateActionModalContent: FC<Props> = ({
    closeModal,
    relatesTo,
    bodExtension,
    currentUserId,
    userGroups,
}) => {
    const {
        handleTextInputUpdate,
        handleSelectChange,
        handleDateChange,
        handleExtensionFieldChanged,
        handleScheduledAtChanged,
        handleScheduleTypeChanged,
        handleRecurringFrequencyChanged,
        handleRecurringIntervalChanged,
        handleDescriptionUpdate,
        form,
    } = useForm(currentUserId)

    const errors = validateForm(form)

    const { users } = useOrganisationUsers()

    const {
        status: createStatus,
        mutationErrors: createMutationErrors,
        createAction,
        hasError: createHasError,
        attachments,
        setAttachments,
    } = useCreateAction({
        relatesTo,
        extendedBy: bodExtension?.id,
    })

    const {
        status: scheduleStatus,
        mutationErrors: scheduleMutationErrors,
        scheduleAction,
        hasError: scheduleHasError,
    } = useScheduleAction({
        relatesTo,
        extendedBy: bodExtension?.id,
    })

    const submitAction = form.schedule?.type ? 'schedule' : 'create'

    const handleSubmit = pipe(preventDefault, () => {
        if (submitAction === 'create') {
            createAction({
                input: omit(form, 'scheduledAt'),
                onCompleted: closeModal,
            })
        } else if (submitAction === 'schedule') {
            scheduleAction({
                input: form,
                onCompleted: closeModal,
            })
        }
    })

    const status = { create: createStatus, schedule: scheduleStatus }[
        submitAction
    ]
    const mutationErrors = {
        create: createMutationErrors,
        schedule: scheduleMutationErrors,
    }[submitAction]

    const hasError = { create: createHasError, schedule: scheduleHasError }[
        submitAction
    ]

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const dateButtonRef = useRef<HTMLButtonElement>(null)

    const [scheduledAtDatePickerOpen, setScheduledAtDatePickerOpen] =
        useState(false)
    const scheduledAtDateButtonRef = useRef<HTMLButtonElement>(null)

    return (
        <StyledCreateActionForm onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name">
                    Name <strong>*</strong>
                </label>
                <TextInput
                    id="name"
                    name="name"
                    onChange={handleTextInputUpdate('name')}
                    hasError={errors.has('name') && hasError}
                />
            </div>

            <div>
                <label htmlFor="assignedTo">Assignee</label>
                <Select
                    id="assignedTo"
                    value={form.assignedToId}
                    onValueChange={handleSelectChange}
                    name="assignedTo"
                    options={[
                        {
                            disabled: true,
                            text: '-- select an assignee --',
                            value: '',
                        },
                        {
                            groupName: 'Users',
                            options: users.map(user => ({
                                text: user.isCurrentUser
                                    ? `${user.name} (Me)`
                                    : user.name,
                                value: user.principalId,
                                disabled: false,
                            })),
                        },
                        {
                            groupName: 'Teams',
                            options: userGroups.map(group => ({
                                text: group.name,
                                value: group.principal.id,
                                disabled: false,
                            })),
                        },
                    ]}
                />
            </div>

            <div>
                <label htmlFor="description">Description</label>
                <StyledRichText
                    id="update-action-description"
                    value={JSON.parse(form.description) as Value}
                    onChange={handleDescriptionUpdate}
                    attachments={attachments}
                    onAttachmentAdded={setAttachments}
                />
            </div>

            <div>
                <label htmlFor="due-date">Due Date</label>
                <StyledDateButton
                    icon={'Calendar'}
                    text={
                        form.dueDate?.length
                            ? dayjs(form.dueDate).format('DD MMM YYYY')
                            : 'Add A Due Date'
                    }
                    ref={dateButtonRef}
                    onClick={e => {
                        e.preventDefault() // Prevent from submitting form
                        setDatePickerOpen(true)
                    }}
                    size="small"
                />
                <DatePicker
                    open={datePickerOpen}
                    onOpenChange={setDatePickerOpen}
                    title={'Set Due Date'}
                    description={'Add an optional due date for the action'}
                    hideDescription
                    onConfirm={({ date }) => {
                        handleDateChange(date)
                        setDatePickerOpen(false)
                    }}
                    returnFocus={() => {
                        dateButtonRef.current?.focus()
                    }}
                />

                <p className="error-message">
                    {errors.has('dueDate') ? 'The due date is in the past' : ''}
                </p>
            </div>

            {!bodExtension ? null : (
                <BusinessObjectFieldset
                    mutationErrors={mutationErrors}
                    onFieldChanged={handleExtensionFieldChanged}
                    fieldDefinitions={bodExtension.fields}
                    fieldValues={form.extendedBy}
                />
            )}

            <SelectButton
                buttonProps={{
                    type: 'submit',
                    size: 'small',
                }}
                onSelectOptionChanged={value =>
                    handleScheduleTypeChanged(
                        value === 'schedule' ? 'single' : undefined
                    )
                }
                options={[
                    {
                        buttonText: {
                            ready: 'Create action',
                            loading: 'Creating action',
                            error: 'Error Creating action',
                            success: 'Success!',
                        }[status],
                        selectText: 'Create action',
                        key: 'create',
                    },
                    {
                        buttonText: {
                            ready: 'Schedule action',
                            loading: 'Scheduling action',
                            error: 'Error Scheduling action',
                            success: 'Success!',
                        }[status],
                        selectText: 'Schedule action',
                        key: 'schedule',
                    },
                ]}
            />

            {form.schedule && (
                <div>
                    <StyledDateButton
                        icon={'Calendar'}
                        text={
                            form.schedule.type === 'single' &&
                            form.schedule.scheduledAt?.length
                                ? dayjs(form.schedule.scheduledAt).format(
                                      'DD MMM YYYY'
                                  )
                                : form.schedule.type === 'recurring' &&
                                  form.schedule.from?.length
                                ? dayjs(form.schedule.from).format(
                                      'DD MMM YYYY'
                                  )
                                : 'Set date for this action to be created'
                        }
                        ref={scheduledAtDateButtonRef}
                        onClick={e => {
                            setScheduledAtDatePickerOpen(true)
                        }}
                        type="button"
                        size="small"
                    />

                    <DatePicker
                        open={scheduledAtDatePickerOpen}
                        onOpenChange={setScheduledAtDatePickerOpen}
                        title={'Set Scheduled Date'}
                        description={
                            'Add a scheduled date for creating the action'
                        }
                        hideDescription
                        onConfirm={({ date }) => {
                            handleScheduledAtChanged(date)
                            setScheduledAtDatePickerOpen(false)
                        }}
                        returnFocus={() => {
                            scheduledAtDateButtonRef.current?.focus()
                        }}
                    />

                    <p className="error-message">
                        {errors.has('schedule.single.scheduledAt')
                            ? 'The scheduled date must be in the future'
                            : ''}
                        {errors.has('schedule.recurring.from')
                            ? 'The first date of the recurring schedule must be in the future'
                            : ''}
                    </p>

                    <Toggle
                        name="Recurring"
                        indicatorText={['Recurring', 'One Off']}
                        checked={form.schedule.type === 'recurring'}
                        onCheckedChange={checked =>
                            handleScheduleTypeChanged(
                                checked ? 'recurring' : 'single'
                            )
                        }
                    />

                    {form.schedule.type === 'recurring' && (
                        <StyledRecurringFieldset>
                            Every
                            <NumberInput
                                value={form.schedule.interval}
                                onChange={pipe(
                                    fromEvent,
                                    Number,
                                    handleRecurringIntervalChanged
                                )}
                            />
                            <Select
                                id="recurring-frequency"
                                name="recurring-frequency"
                                options={[
                                    {
                                        value: RecurringScheduleFrequency.Daily,
                                        text: 'Days',
                                    },
                                    {
                                        value: RecurringScheduleFrequency.Weekly,
                                        text: 'Weeks',
                                    },
                                    {
                                        value: RecurringScheduleFrequency.Monthly,
                                        text: 'Months',
                                    },
                                    {
                                        value: RecurringScheduleFrequency.Annually,
                                        text: 'Years',
                                    },
                                ]}
                                value={form.schedule.frequency}
                                onValueChange={handleRecurringFrequencyChanged}
                            />
                        </StyledRecurringFieldset>
                    )}
                </div>
            )}
        </StyledCreateActionForm>
    )
}

const StyledRichText = styled(RichText)`
    border-radius: 4px;
`

const StyledCreateActionForm = styled.form`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0;

    > div {
        width: 100%;

        .error-message {
            min-height: 1em;
            color: ${({ theme }) => theme.palette.text.support05.normal};
        }
    }

    label {
        ${regular5};
        display: block;
        padding-bottom: 0.5rem;
    }

    [data-screen-size='large'] & {
        width: 37rem;
    }
`

const StyledDateButton = styled(IconTextButton)`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
`

const StyledRecurringFieldset = styled.fieldset`
    display: flex;
    flex-direction: row;
    border: 0;
    align-items: center;
    gap: 0.3rem;

    > button {
        padding: 0.5rem;
        margin: 0;
    }
`
