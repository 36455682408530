import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
}

export const CreatedHistoryEntry: FC<Props> = ({ userName, timestamp }) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> created this record.
            </Text>
        </BaseHistoryEntry>
    )
}
