import { Group } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Dropdown, DropdownCheckbox } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { includes } from 'lodash'
import { FC } from 'react'
import { FieldInputProps } from '../../types'

export const Checkboxes: FC<FieldInputProps<'SelectFieldDefinition'>> = ({
    field,
    value,
    onChange,
}) => {
    return (
        <Dropdown
            renderContent={() => (
                <Group>
                    {field.selectOptions.map(option => (
                        <DropdownCheckbox
                            key={option.id}
                            text={option.value}
                            checked={includes(value, option.id)}
                            onCheckedChange={bool => {
                                if (bool === true) {
                                    onChange([...(value ?? []), option.id])
                                } else {
                                    onChange(
                                        value
                                            ? value.filter(
                                                  id => id !== option.id
                                              )
                                            : []
                                    )
                                }
                            }}
                            closeOnSelect={false}
                        />
                    ))}
                </Group>
            )}
            renderOpenDropdownButton={() => (
                <StyledOpenDropdownButton
                    icon={value?.length ? 'CheckboxTicked' : 'CheckboxUnticked'}
                    text={
                        value?.length
                            ? `${value.length} selected`
                            : 'Select Items'
                    }
                />
            )}
            variant="light"
        />
    )
}

const StyledOpenDropdownButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
