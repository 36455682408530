import { styled } from 'Adapters/Freestyled'
import { bold1 } from 'Components/Text'
import { FC } from 'react'
import { BusinessObjectHeaderQuery } from '../__generated__/q'
import { DeleteBusinessObject } from './DeleteBusinessObject'
import { ToggleSubscription } from './ToggleSubscription'
import { ViewDefinition } from './ViewDefinition'

type Props = {
    businessObject: BusinessObjectHeaderQuery['businessObject'] | undefined
    onDelete: () => void
    showDelete: boolean
}

const Large: FC<Props> = ({ businessObject, onDelete, showDelete }) => (
    <Styled>
        <h1>{businessObject?.label || '...'}</h1>
        {businessObject ? (
            <menu>
                <ViewDefinition definitionId={businessObject.definition.id} />

                {showDelete ? (
                    <DeleteBusinessObject
                        id={businessObject.id}
                        canDelete={businessObject.canDelete}
                        onDelete={onDelete}
                    />
                ) : null}

                <ToggleSubscription id={businessObject.id} />
            </menu>
        ) : (
            <menu />
        )}
    </Styled>
)

const Styled = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 2rem 1rem;
    background: ${({ theme }) => theme.palette.ui['02'].normal};

    h1 {
        ${bold1}
    }

    > menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
    }
`

export { Large }
