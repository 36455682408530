import { useState } from 'react'
import { styled } from '../../../Adapters/Freestyled'
import { TextButton } from '../../../Components/Button'
import { ControlledModal } from '../../../Components/Modal'
import { Setter } from '../../../Utils/types'
import { CreateBusinessObjectDefFields } from '../../CreateBusinessObjectDef/Components/CreateBusinessObjectDefFields'
import { blankField } from '../../CreateBusinessObjectDef/helpers'
import { FieldType } from '../../CreateBusinessObjectDef/types'

type Props = {
    type: FieldType
    onFieldCreated: (field: ReturnType<typeof blankField>) => void
    onModalClosed: () => void
    returnFocus: () => void
}

export const CreateFieldModal: React.FC<Props> = ({
    type,
    onFieldCreated,
    onModalClosed,
    returnFocus,
}) => {
    const [field, setField] = useState(blankField(type))
    const handleFieldChange = () => (prop: string) => (value: Setter<any>) => {
        return setField(prev => {
            return {
                ...prev,
                [prop]:
                    typeof value === 'function'
                        ? value((prev as any)[prop])
                        : value,
            }
        })
    }

    return (
        <ControlledModal
            open={true}
            onOpenChange={onModalClosed}
            returnFocus={returnFocus}
            title="Create new field"
            description="Create new field"
            renderContent={() => (
                <Styled>
                    <CreateBusinessObjectDefFields
                        index={0}
                        field={field}
                        onFieldChange={handleFieldChange}
                        disableRequiredConstraint={true}
                    />
                    <TextButton
                        text="Create field"
                        type="button"
                        onClick={() => onFieldCreated(field)}
                        size="small"
                    />
                </Styled>
            )}
        />
    )
}

const Styled = styled.div`
    min-width: 40rem;
    max-height: 90vh;
    overflow: auto;

    * + * {
        margin-top: 0.4rem;
    }

    > button {
        width: 100%;
    }
`
