import { ApolloClient, ApolloProvider as Provider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { FC, ReactNode, useMemo } from 'react'
import { cache } from './client'
import { getLinks } from './links'

type Props = {
    children: ReactNode
    getSandboxToken: undefined | (() => Promise<string>)
}

export const ApolloProvider: FC<Props> = ({ children, getSandboxToken }) => {
    const { getAccessTokenSilently } = useAuth0()

    const client = useMemo(
        () =>
            new ApolloClient({
                cache,
                link: getLinks(getSandboxToken || getAccessTokenSilently),
                queryDeduplication: false,
            }),
        [getAccessTokenSilently, getSandboxToken]
    )

    return <Provider client={client}>{children}</Provider>
}
