import { Grouping } from 'Components/GroupingControl/types'
import { BizObjectDefExtension_CompleteFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    GROUPING_KEY,
    decodeGroupState,
    encodeGroupState,
    setDefaultState,
} from './helpers'

export const useGroupingMenuState = (
    fields: BizObjectDefExtension_CompleteFragment['fields'],
    allAssignablePrincipals: { id: string; name: string }[]
) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [groupState, setState] = useState<Grouping>(() => {
        const exisitingGrouping = searchParams.get(GROUPING_KEY)

        if (exisitingGrouping) {
            return decodeGroupState(
                exisitingGrouping,
                fields,
                allAssignablePrincipals
            )
        } else {
            return setDefaultState(fields, allAssignablePrincipals)
        }
    })

    const setSelection = useCallback((selection: string) => {
        setState(prev => ({ ...prev, selection }))
    }, [])

    const moveGroup = useCallback(
        (direction: 'up' | 'down', fieldId: string, groupId: string) => {
            setState(prev => {
                const field = prev.fields.find(({ id }) => id === fieldId)

                if (!field) return prev

                const groupIndex = field.groups.findIndex(
                    ({ id }) => id === groupId
                )

                const filteredGroups = field.groups.filter(
                    ({ id }) => id !== groupId
                )

                const shiftedIndex =
                    direction === 'up' ? groupIndex - 1 : groupIndex + 1

                return {
                    ...prev,
                    fields: prev.fields.map(field =>
                        field.id === fieldId
                            ? {
                                  ...field,
                                  groups: [
                                      ...filteredGroups.slice(0, shiftedIndex),
                                      field.groups[groupIndex],
                                      ...filteredGroups.slice(shiftedIndex),
                                  ],
                              }
                            : field
                    ),
                }
            })
        },
        []
    )

    const moveGroupUp = useCallback(
        (fieldId: string) => (groupId: string) => {
            moveGroup('up', fieldId, groupId)
        },
        [moveGroup]
    )

    const moveGroupDown = useCallback(
        (fieldId: string) => (groupId: string) => {
            moveGroup('down', fieldId, groupId)
        },
        [moveGroup]
    )

    const setGroupColor = useCallback(
        (fieldId: string) => (groupId: string) => (color: string) => {
            setState(prev => ({
                ...prev,
                fields: prev.fields.map(field =>
                    field.id === fieldId
                        ? {
                              ...field,
                              groups: field.groups.map(group =>
                                  group.id === groupId
                                      ? {
                                            ...group,
                                            color,
                                        }
                                      : group
                              ),
                          }
                        : field
                ),
            }))
        },
        []
    )

    const setToSearchParams = () => {
        setSearchParams(prev => {
            const next = new URLSearchParams(prev)

            const encodedGroupState = encodeGroupState(groupState)

            if (groupState.selection === 'all') {
                next.delete(GROUPING_KEY)
            } else {
                next.set(GROUPING_KEY, encodedGroupState)
            }

            return next
        })
    }

    const groupingBy = useMemo<string | undefined>(() => {
        const grouping = searchParams.get(GROUPING_KEY)

        if (!grouping) return undefined

        const decoded = decodeGroupState(
            grouping,
            fields,
            allAssignablePrincipals
        )

        return decoded.fields.find(field => field.id === decoded.selection)
            ?.label
    }, [searchParams, fields, allAssignablePrincipals])

    return {
        setSelection,
        moveGroupUp,
        moveGroupDown,
        setGroupColor,
        groupState,
        setToSearchParams,
        groupingBy,
    }
}
