import { NumberInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { fromEvent } from 'Utils'
import { PatchBusinessObjectDefinitionFieldDefaultInput } from '__generated__'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const NumberField: FC<
    Field<'NumberFieldDefinition'> &
        AbstractFieldChangeProps & {
            handleDefaultChanged: (
                input: NonNullable<
                    PatchBusinessObjectDefinitionFieldDefaultInput['number']
                >
            ) => void
        }
> = ({ numConstraints, numDefaultValue, handleDefaultChanged, ...common }) => (
    <AbstractField
        {...common}
        defaultDisplay={
            common.isEditing ? (
                <NumberInput
                    name={common.type + '||' + common.id}
                    value={numDefaultValue ?? ''}
                    id={common.id}
                    onChange={e => {
                        const value = fromEvent(e)

                        handleDefaultChanged({
                            fieldId: common.id,
                            previousValue: numDefaultValue,
                            nextValue: value ? Number(value) : null,
                        })
                    }}
                    type="number"
                />
            ) : (
                <Text
                    as="p"
                    variant="regular-4"
                    className={
                        typeof numDefaultValue !== 'number' ? 'none-set' : ''
                    }
                >
                    {numDefaultValue ?? 'No default value set'}
                </Text>
            )
        }
        constraints={numConstraints}
    />
)
