import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DocumentPicker } from 'Components/DocumentPicker'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { useModalControls } from 'Hooks'
import { FC, useEffect, useState } from 'react'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useDocConstraints } from './useDocConstraints'

type Props = BODField<'DocumentFieldDefinition'> & {
    listName: string
    listId: string
}

export const DocumentListField: FC<Props> = ({
    docConstraints,
    listName,
    listId,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useDocConstraints({
        docConstraints,
    })

    const [documentsList, setDocumentsList] = useState<
        { id: string; name: string }[]
    >([])

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: documentsList.map(doc => ({ document: doc.id })),
            },
        })
    }, [documentsList, dispatch, listId])

    const { open, setOpen, buttonRef, openModal, closeModal, returnFocus } =
        useModalControls()

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[listId].errors ?? []}
            list={documentsList.map(({ name, id }) => (
                <StyledListItem key={id}>
                    <Icon name="Document" />
                    <span>{name}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setDocumentsList(prev =>
                                prev.filter(doc => doc.id !== id)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <StyledAddDocumentButton
                icon="Document"
                text={'Add documents'}
                onClick={e => {
                    e.preventDefault()
                    openModal()
                }}
                ref={buttonRef}
            />

            <DocumentPicker
                onConfirm={selections => {
                    setDocumentsList(selections)
                    closeModal()
                }}
                selectMode={'multiple'}
                isOpen={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
                initialSelections={documentsList.map(doc => doc.id)}
            />
        </ListFieldWrapper>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button {
        margin-left: auto;
    }
`
