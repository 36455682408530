import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { ProcessCardFragment } from './__generated__/q'

type Props = {
    process: ProcessCardFragment
    onClick: () => void
    className?: string
}

const ProcessCard: FC<Props> = ({ process, onClick, className = '' }) => {
    const activeCount = process.phases.reduce(
        (total, { activeBusinessObjects }) =>
            activeBusinessObjects.meta.totalCount + total,
        0
    )
    const completedCount = process.usage.businessObjects - activeCount

    return (
        <Styled onClick={onClick} tabIndex={0} className={className}>
            <header>
                <Text as="h2" variant="bold-4">
                    {process.name}
                </Text>
                <Text as="h3" variant="bold-6">
                    Operates upon {process.operatesUpon[0].name}
                </Text>
            </header>
            <div>
                <Text as="p" variant="regular-5">
                    {process.description}
                </Text>
            </div>
            <footer>
                <Text as="p" variant="regular-6">
                    Active: {activeCount}
                </Text>
                <Text as="p" variant="regular-6">
                    Completed: {completedCount}
                </Text>
            </footer>
        </Styled>
    )
}

const Styled = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
    height: 100%;
    border-radius: 0.25rem;
    transition: filter
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
        border-color ${({ theme }) => theme.animation.buttonTransitionDuration}
            ease;

    &:hover {
        filter: drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}
        );
        border-color: ${({ theme }) => theme.palette.ui['03'].hover};
    }

    > header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
        padding: 0.75rem 1rem 0.25rem;
    }

    > header svg {
        width: 1rem;
        height: 1rem;
    }

    > header path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    h3 {
        padding-bottom: 0.5rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0.25rem 1rem 1rem;
        gap: 0.25rem;
    }

    > div p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > footer {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: 0 0 0.1875rem 0.1875rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 0.75rem 1rem;
        display: flex;
        gap: 1rem;
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    @media (hover: hover) {
        &:hover > footer {
            background-color: ${({ theme }) => theme.palette.ui['02'].hover};
        }
    }

    > footer > div {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }
`

export { ProcessCard }
