import { CenteredSpinner } from 'Components/Spinner'
import { Content } from 'Features/FieldCompletionModal/Content'
import { FC, useCallback } from 'react'
import { useSubFieldCompletionModalQuery } from './__generated__/q'
type Props = {
    id: string
    onClose: () => void
    includeFields?: string[]
    onFieldUpdated?: (fieldId: string) => void
}

export const Container: FC<Props> = ({
    id,
    onClose,
    includeFields,
    onFieldUpdated,
}) => {
    const { data, loading, refetch } = useSubFieldCompletionModalQuery({
        variables: {
            businessObjectId: id,
        },
    })

    const handleUpdate = useCallback(
        (fieldId: string) => {
            refetch()
            onFieldUpdated?.(fieldId)
        },
        [onFieldUpdated, refetch]
    )

    return data && !loading ? (
        <Content
            businessObject={data.businessObject}
            onClose={onClose}
            onFieldUpdated={handleUpdate}
            includeFields={includeFields}
        />
    ) : (
        <CenteredSpinner />
    )
}
