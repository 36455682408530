import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { Attachment } from 'Components/Attachments'
import { useMutationStatus } from 'Hooks'
import { AttachmentType } from '__generated__'
import { compact, uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { P, match } from 'ts-pattern'
import { useCreateActionMutation } from './__generated__/mutation'
import { RelatesTo } from './types'
import { CreateFormState } from './useForm'

export const useCreateAction = ({
    extendedBy,
    relatesTo,
}: {
    extendedBy?: string
    relatesTo?: RelatesTo
}) => {
    const [create, result] = useCreateActionMutation()

    const [mutationErrors, setMutationErrors] = useState<
        Record<string, string[]>
    >({})

    const [attachments, setAttachments] = useState<Attachment[]>([])

    const createAction = useCallback(
        ({
            input,
            onCompleted,
        }: {
            input: CreateFormState
            onCompleted?: () => void
        }) => {
            setMutationErrors({})

            create({
                variables: {
                    input: {
                        name: input.name,
                        ...(input.assignedToId && {
                            assignedTo: input.assignedToId,
                        }),
                        ...(input.description && {
                            description: input.description,
                        }),
                        ...(attachments.length && {
                            descriptionAttachments: attachments.map(
                                attachment => ({
                                    attachment: attachment.file.id,
                                    type: AttachmentType.File,
                                })
                            ),
                        }),
                        ...(input.dueDate && {
                            dueDate: input.dueDate,
                        }),
                        ...(relatesTo && {
                            relatesTo: {
                                ...(relatesTo.businessObjectId && {
                                    businessObject: {
                                        id: relatesTo.businessObjectId,
                                    },
                                }),
                            },
                        }),
                        ...(extendedBy && {
                            extension: {
                                businessObjectDefinitionId: extendedBy,
                                fields: compact(
                                    input.extendedBy.map(
                                        ({ field: { id, type }, value }) => {
                                            return match(value)
                                                .with(
                                                    P.nullish, // Value might be false in the case of a boolean
                                                    () => undefined
                                                )
                                                .otherwise(() => ({
                                                    [type]: {
                                                        fieldDefinitionId: id,
                                                        value,
                                                    },
                                                }))
                                        }
                                    )
                                ),
                            },
                        }),
                    },
                },
                refetchQueries: [
                    'Actions',
                    'ActionCount',
                    'SingleBusinessObjectActions',
                ],
                onCompleted,
                onError(error) {
                    logger.error('Failed to create action', error)

                    error.graphQLErrors.forEach(error => {
                        if (!hasCustomErrorExtenstions(error.extensions)) return

                        const errors: Record<string, string[]> = {}

                        Object.entries(error.extensions.errors.errors).forEach(
                            ([key, value]) => {
                                errors[key] = uniq(value)
                            }
                        )

                        setMutationErrors(errors)
                    })
                },
            })
        },
        [attachments, create, extendedBy, relatesTo]
    )

    const { status } = useMutationStatus(result)

    return {
        createAction,
        mutationErrors,
        status,
        hasError: !!result.error,
        attachments,
        setAttachments,
    }
}
