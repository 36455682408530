import { logger } from 'Adapters/Logger'
import { Comparator } from 'Components/ComparatorBuilderV2'
import { showByQueryKey } from 'Root/App/Main/Actions/Hooks/constants'
import { applyComparator, applySelector } from 'Utils/comparator'
import { Base64 } from 'js-base64'
import { pipe } from 'lodash/fp'
import { FilterableBusinessObject } from './types'

export const FILTER_STATE_KEY = 'filterState'

export const flattenAndFilterBusinessObjects = <
    T extends FilterableBusinessObject
>(
    comparators: (Comparator | Comparator[])[],
    businessObjects: T[]
): T[] =>
    filterBusinessObjects(
        comparators.flatMap(comparator => comparator),
        businessObjects
    )

export const filterBusinessObjects = <T extends FilterableBusinessObject>(
    comparators: Comparator[],
    businessObjects: T[]
): T[] =>
    comparators.reduce(
        (out, comparator) =>
            out.filter(
                pipe(
                    applySelector(comparator.valueSelector),
                    applyComparator(comparator)
                )
            ),
        businessObjects
    )

export const encodeFilterState = (
    filterState: Record<string, Comparator | Comparator[]>
): string => Base64.encodeURL(JSON.stringify(filterState))

export const decodeFilterState = (
    encodedFilterState: string | null
): Record<string, Comparator | Comparator[]> => {
    try {
        return encodedFilterState
            ? (JSON.parse(Base64.decode(encodedFilterState)) as Record<
                  string,
                  Comparator | Comparator[]
              >)
            : {}
    } catch (e) {
        logger.error('Failed to decode filter state', e as Error, {
            encodedFilterState,
        })
        return {}
    }
}

export const convertSearchParamsToObject = (searchParams: URLSearchParams) => {
    const asObject: Record<string, string | string[]> = {}

    for (const [key, val] of searchParams.entries()) {
        // Actions query string can contain a queryBy property which is an array
        if (key !== showByQueryKey) {
            asObject[key] = val
        } else {
            if (key in asObject === false) {
                asObject[key] = [val]
            } else {
                asObject[key] = [...asObject[key], val]
            }
        }
    }

    return asObject
}
