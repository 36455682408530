import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Spinner } from 'Components/Spinner'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

import { useBusinessObjects_SetBusinessObjectQuery } from './__generated__/query'

type Props = {
    businessObjectDefinitionId: string
    selectedBusinessObjectId?: string
    onBusinessObjectSelect: (params: {
        businessObjectDefinitionId: string
        businessObjectId: string
        businessObjectLabel: string
    }) => void
}

export const BusinessObjectSelector: FC<Props> = ({
    businessObjectDefinitionId,
    selectedBusinessObjectId,
    onBusinessObjectSelect,
}) => {
    const { data } = useBusinessObjects_SetBusinessObjectQuery({
        variables: { input: { businessObjectDefinitionId } },
    })

    if (!data) return <Spinner />

    return (
        <StyledBusinessObjectSelector>
            {data.businessObjects.map(businessObject => {
                return (
                    <Tooltip
                        content={businessObject.label}
                        key={businessObject.id}
                    >
                        <BusinessObjectButton
                            $selected={
                                selectedBusinessObjectId === businessObject.id
                            }
                            onClick={() => {
                                onBusinessObjectSelect({
                                    businessObjectId: businessObject.id,
                                    businessObjectDefinitionId,
                                    businessObjectLabel: businessObject.label,
                                })
                            }}
                        >
                            <Icon name="Cube" />
                            <span className="label">
                                {businessObject.label}
                            </span>
                        </BusinessObjectButton>
                    </Tooltip>
                )
            })}
        </StyledBusinessObjectSelector>
    )
}

const StyledBusinessObjectSelector = styled.div`
    ${flex('row', 'flex-start', 'flex-end')};
    gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 1rem;
`

const BusinessObjectButton = styled.button<{ $selected: boolean }>`
    ${flex('column', 'space-between', 'center')};
    gap: 0.5rem;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: 10rem;
    background-color: ${({ theme, $selected }) =>
        $selected
            ? theme.palette.ui['01'].hover
            : theme.palette.ui['01'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};
    will-change: background-color;

    .icon svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    .label {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }
`
