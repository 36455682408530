import { useFlow } from '@frigade/react'
import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text, regular5 } from 'Components/Text'
import { FC, ReactNode } from 'react'
import { FLOW_IDENTIFIER } from '../constants'

type Props = {
    children: ReactNode
    stepId: string
}

export const ChecklistItem: FC<Props> = ({ children, stepId }) => {
    const { flow } = useFlow(FLOW_IDENTIFIER)
    const item = flow?.steps.get(stepId)

    if (!item || !item.title || !item.subtitle) return null

    const isDone = !!item.$state.completed

    return (
        <StyledChecklistItem $isDone={isDone}>
            <Icon name={isDone ? 'CircleCheck' : 'Circle'} />

            <div className="content">
                <Text as="h4" variant="bold-4">
                    {item.title}
                </Text>
                <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                {!isDone && children}
            </div>
        </StyledChecklistItem>
    )
}

const StyledChecklistItem = styled.div<{ $isDone: boolean }>`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    width: 100%;

    > .icon {
        padding-top: 0.25rem;

        svg {
            path {
                fill: ${({ theme, $isDone }) =>
                    theme.palette.text[$isDone ? '03' : '01'].normal};
            }
        }
    }

    .content {
        color: ${({ theme, $isDone }) =>
            theme.palette.text[$isDone ? '03' : '01'].normal};

        > h4 {
            text-decoration: ${({ $isDone }) =>
                $isDone ? 'line-through' : 'none'};
            padding-bottom: 0.25rem;
        }

        > p {
            ${regular5};
            padding-bottom: 0.5rem;
        }
    }
`
