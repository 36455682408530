import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import AI from 'Assets/ai.svg?react'
import Learn from 'Assets/learn.svg?react'
import Template from 'Assets/template.svg?react'
import { TextButton } from 'Components/Button'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { FC, useMemo } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { WorkspaceSetup } from '../WorkspaceSetup'
import { WelcomeModal } from '../WorkspaceSetup/WelcomeModal'
import {
    WelcomePageEvent,
    trackWelcomePageInteraction,
} from './amplitudeEvents'

const camelCaseToWords = (input: string) => {
    const result = input.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const Welcome: FC = () => {
    return (
        <Routes>
            <Route index element={<WelcomePage />} />
            <Route path="templates/*" element={<WorkspaceSetup />} />
        </Routes>
    )
}

export const WelcomePage: FC = () => {
    const { isLarge } = useResponder()

    return (
        <StyledWelcomePage>
            {match(isLarge)
                .with(true, () => <Content />)
                .with(false, () => (
                    <VerticalScrollArea height="100%" width="100%">
                        <Content />
                    </VerticalScrollArea>
                ))
                .exhaustive()}

            <WelcomeModal />
        </StyledWelcomePage>
    )
}

const Content = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    const { isLarge } = useResponder()

    const templates = useMemo(() => {
        const templates = searchParams.get('templates')
        const category = searchParams.get('category')

        return match({ templates, category })
            .with(
                { category: P.string, templates: P.string },
                ({ category, templates }) => {
                    return {
                        path: `templates/${category}?templates=${templates}`,
                        text: `Start with your selected template:`,
                        selection: `${camelCaseToWords(
                            templates.split(',')[0]
                        )}`,
                    }
                }
            )
            .otherwise(() => ({
                path: 'templates',
                text: 'Start with a template',
                selection: null,
            }))
    }, [searchParams])

    return (
        <div className="wrapper">
            <div className="buttons">
                <button
                    onClick={() => {
                        trackWelcomePageInteraction(WelcomePageEvent.AI)
                        navigate(
                            isLarge
                                ? '/manage-workspace/processes?creator=open&step=ai-1'
                                : '/manage-workspace/model/processes?creator=open&step=ai-1'
                        )
                    }}
                >
                    <AI />
                    <Text as="span" variant="regular-3">
                        Build your workflow with AI
                    </Text>
                </button>

                <button
                    onClick={() => {
                        trackWelcomePageInteraction(WelcomePageEvent.Template)
                        navigate(templates.path)
                    }}
                >
                    <Template />

                    <div className="text">
                        <Text as="span" variant="regular-3">
                            {templates.text}
                        </Text>
                        {templates.selection ? (
                            <Text as="span" variant="regular-3">
                                <em>{templates.selection}</em>
                            </Text>
                        ) : null}
                    </div>
                </button>

                <button
                    onClick={() => {
                        trackWelcomePageInteraction(WelcomePageEvent.Learn)
                        setSearchParams(prev => {
                            const next = new URLSearchParams(prev)
                            next.set('showWelcome', 'true')
                            return next
                        })
                    }}
                >
                    <Learn />
                    <Text as="span" variant="regular-3">
                        Learn about Modlify
                    </Text>
                </button>
            </div>

            <TextButton
                onClick={() => {
                    trackWelcomePageInteraction(WelcomePageEvent.Scratch)
                    navigate('/manage-workspace')
                }}
                text="Or start with a blank slate"
                size="small"
                variant="ghost"
            />
        </div>
    )
}

const StyledWelcomePage = styled.section`
    height: 100%;
    width: 100%;

    .wrapper {
        ${flex('column', 'center', 'center')};
        gap: 2rem;
        height: 100%;
        width: 100%;
        padding: 2rem 0;
    }

    .buttons {
        ${flex('column', 'flex-start', 'center')};
        gap: 2rem;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 2rem;

        button {
            ${flex('column', 'space-around', 'center')};
            width: 100%;
            padding: 2rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};
            color: ${({ theme }) => theme.palette.text['02'].normal};
            transition: ${({ theme }) =>
                    theme.animation.buttonTransitionDuration}
                ease;
            background-color: ${({ theme }) => theme.palette.ui['02'].normal};
            border: 1px solid transparent;

            @media (hover: hover) {
                &:hover {
                    color: ${({ theme }) => theme.palette.text['02'].hover};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].hover};
                }
            }

            &:active {
                color: ${({ theme }) => theme.palette.text['02'].active};
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].active};
            }

            &:focus-visible {
                box-shadow: 0 0 0 0.125rem
                    ${({ theme }) => theme.palette.brand['01'].normal};
            }

            > svg {
                height: 5rem;
                width: 5rem;
                padding-bottom: 1rem;

                line,
                circle {
                    stroke: ${({ theme }) => theme.palette.text['02'].normal};
                }

                path {
                    fill: ${({ theme }) => theme.palette.text['02'].normal};
                }
            }

            .text {
                ${flex('column', 'flex-start', 'center')};
            }
        }
    }

    @media screen and (min-width: ${breakpoints.medium}px) {
        .buttons {
            ${flex('row', 'center', 'center')};

            button {
                width: 25rem;
                height: 18.75rem;

                > svg {
                    height: 10rem;
                    width: 10rem;
                }
            }
        }
    }
`
