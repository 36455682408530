import {
    SelectField as Field,
    SelectFieldValidation,
} from 'Utils/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { OnSubmit, State } from '../../types'
import { Content } from '../Content'
import { SelectSubFields } from './SelectSubFields'

type Props = {
    field: Field
    validation: SelectFieldValidation
    onSubmit: OnSubmit<Field, SelectFieldValidation>
    onClose: () => void
}

const SelectField: FC<Props> = ({ field, validation, onSubmit, onClose }) => {
    const [state, setState] = useState<State<Field, SelectFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <SelectSubFields<Field>
                constraints={state.field.constraints}
                options={state.field.options}
                update={fn =>
                    setState(prev => ({
                        ...prev,
                        field: fn(prev.field),
                    }))
                }
                constraintsLocked={field.constraintsLocked}
            />
        </Content>
    )
}

export { SelectField }
