import 'Adapters/Amplitude/init'
import { AuthProvider } from 'Adapters/Authentication'
import 'Adapters/DDRum/init'
import { StyleProvider } from 'Adapters/Freestyled'
import { initDatadog } from 'Adapters/Logger'
import * as support from 'Features/Support'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Root } from './Root'
import reportWebVitals from './reportWebVitals'

support.init()
initDatadog()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <StyleProvider>
                    <Root />
                </StyleProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
