import { FieldType } from '../types'

export enum ValidationStatus {
    Valid = 'Valid',
    Invalid = 'Invalid',
    Pending = 'Pending',
}

export type Validity = {
    status: ValidationStatus
    message: string
}

export type CurrencyFieldValidation = {
    type: FieldType.Currency
    name: Validity
}

export type DateFieldValidation = {
    type: FieldType.Date
    name: Validity
}

export type DocumentFieldValidation = {
    type: FieldType.Document
    name: Validity
}

export type EmailFieldValidation = {
    type: FieldType.Email
    name: Validity
}

export type NumberFieldValidation = {
    type: FieldType.Number
    name: Validity
}

export type SelectFieldValidation = {
    type: FieldType.Select
    name: Validity
}

export type TelephoneFieldValidation = {
    type: FieldType.Telephone
    name: Validity
}

export type TextFieldValidation = {
    type: FieldType.Text
    name: Validity
}

export type UserFieldValidation = {
    type: FieldType.User
    name: Validity
}

export type UrlFieldValidation = {
    type: FieldType.Url
    name: Validity
}

export type BooleanFieldValidation = {
    type: FieldType.Boolean
    name: Validity
    defaultValue: Validity
}

export type RelationFieldValidation = {
    type: FieldType.Relation
    name: Validity
    relationType: Validity
}

export type ListOfCurrencyFieldValidation = {
    type: FieldType.Currency
}

export type ListOfDateFieldValidation = {
    type: FieldType.Date
}

export type ListOfDocumentFieldValidation = {
    type: FieldType.Document
}

export type ListOfEmailFieldValidation = {
    type: FieldType.Email
}

export type ListOfNumberFieldValidation = {
    type: FieldType.Number
}

export type ListOfSelectFieldValidation = {
    type: FieldType.Select
}

export type ListOfTelephoneFieldValidation = {
    type: FieldType.Telephone
}

export type ListOfTextFieldValidation = {
    type: FieldType.Text
}

export type ListOfUserFieldValidation = {
    type: FieldType.User
}

export type ListOfUrlFieldValidation = {
    type: FieldType.Url
}

export type ListOfBooleanFieldValidation = {
    type: FieldType.Boolean
    defaultValue: Validity
}

export type ListOfRelationFieldValidation = {
    type: FieldType.Relation
    relationType: Validity
}

export type ListOfValidation =
    | ListOfRelationFieldValidation
    | ListOfBooleanFieldValidation
    | ListOfCurrencyFieldValidation
    | ListOfDateFieldValidation
    | ListOfDocumentFieldValidation
    | ListOfEmailFieldValidation
    | ListOfNumberFieldValidation
    | ListOfSelectFieldValidation
    | ListOfTelephoneFieldValidation
    | ListOfTextFieldValidation
    | ListOfUserFieldValidation
    | ListOfUrlFieldValidation
    | Validity

export type ListFieldValidation = {
    type: FieldType.List
    name: Validity
    listOf: ListOfValidation
}

export type FieldValidation =
    | ListFieldValidation
    | RelationFieldValidation
    | BooleanFieldValidation
    | CurrencyFieldValidation
    | DateFieldValidation
    | DocumentFieldValidation
    | EmailFieldValidation
    | NumberFieldValidation
    | SelectFieldValidation
    | TelephoneFieldValidation
    | TextFieldValidation
    | UserFieldValidation
    | UrlFieldValidation

export type BusinessObjectDefinitionValidation = {
    name: Validity
    label: Validity
    fields: FieldValidation[]
}
