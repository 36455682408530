import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { UncontrolledModal } from 'Components/Modal'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { WorkspaceNameForm } from 'Features/WorkspaceName'
import { useResponder } from 'Hooks'
import { FC, useState } from 'react'
import { match } from 'ts-pattern'

type Props = {
    tenant: {
        name: string
    }
}

const ManageWorkspaceHeader: FC<Props> = props => {
    const { screenSize } = useResponder()
    return match(screenSize)
        .with('large', () => <Large {...props} />)
        .otherwise(() => <Small {...props} />)
}

const Large: FC<Props> = ({ tenant }) => (
    <StyledLarge>
        <Text as="h1" variant="bold-2">
            {tenant.name}
        </Text>
        <UncontrolledModal
            title="Workspace details"
            description="Configure your workspace details."
            hideDescription
            renderOpenModalButton={() => (
                <IconButton iconName="Edit" variant="secondary" />
            )}
            renderContent={() => (
                <StyledLargeContent>
                    <WorkspaceNameForm tenant={tenant} />
                </StyledLargeContent>
            )}
        />
    </StyledLarge>
)

const StyledLarge = styled.header`
    padding: 0 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`

const StyledLargeContent = styled.div`
    padding-top: 1rem;

    form {
        width: 30rem;
    }
`

const Small: FC<Props> = ({ tenant }) => {
    const [open, setOpen] = useState(false)
    return (
        <StyledSmall>
            <Text as="h1" variant="bold-2">
                {tenant.name}
            </Text>
            <IconButton
                iconName="Edit"
                variant="secondary"
                onClick={() => setOpen(true)}
            />
            <MinimalModal open={open} onOpenChange={setOpen}>
                <StyledSmallContent>
                    <WorkspaceNameForm
                        tenant={tenant}
                        onSuccess={() => setOpen(false)}
                        onCancel={() => setOpen(false)}
                    />
                </StyledSmallContent>
            </MinimalModal>
        </StyledSmall>
    )
}

const StyledSmall = styled.header`
    padding: 0.5rem 4vw;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    h1 {
        flex: 1;
    }
`

const StyledSmallContent = styled.div`
    padding: 0.5rem 4vw 1rem;

    > footer button {
        width: 100%;
    }
`

export { ManageWorkspaceHeader }
