import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { slides } from './types'

export const useWelcomeNavigation = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const currentIndex = useMemo(() => {
        const pageParam = searchParams.get('page')
        return pageParam ? Number(pageParam) : 0
    }, [searchParams])

    const [direction, setDirection] = useState<1 | -1>(1)

    const incrementSlide = useCallback(() => {
        if (currentIndex === slides.length - 1) return
        setDirection(1)
        setTimeout(() => {
            setSearchParams(prev => {
                const next = new URLSearchParams(prev)
                next.set('page', (currentIndex + 1).toString())
                return next
            })
        }, 0) // Prevent batching to ensure the direction is changed before the animation has time to play
    }, [setDirection, currentIndex, setSearchParams])

    const decrementSlide = useCallback(() => {
        if (currentIndex === 0) return
        setDirection(-1)
        setTimeout(() => {
            setSearchParams(prev => {
                const next = new URLSearchParams(prev)
                next.set('page', (currentIndex - 1).toString())
                return next
            })
        }, 0)
    }, [setDirection, currentIndex, setSearchParams])

    const goToSlide = useCallback(
        (index: number) => {
            setDirection(index > currentIndex ? 1 : -1)
            setTimeout(() => {
                setSearchParams(prev => {
                    const next = new URLSearchParams(prev)
                    next.set('page', index.toString())
                    return next
                })
            }, 0)
        },
        [setDirection, currentIndex, setSearchParams]
    )

    const arrowHandler = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'ArrowRight') {
                incrementSlide()
            } else if (e.key === 'ArrowLeft') {
                decrementSlide()
            }
        },
        [incrementSlide, decrementSlide]
    )

    useEffect(() => {
        window.addEventListener('keydown', arrowHandler)

        return () => {
            window.removeEventListener('keydown', arrowHandler)
        }
    }, [arrowHandler])

    return {
        currentIndex,
        direction,
        incrementSlide,
        decrementSlide,
        goToSlide,
    }
}
