import { flex, styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { useMemo } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { TenantSwitch } from './TenantSwitch'
import { useTenantCheck_ActiveTenantQuery } from './__generated__/query'

export const TenantCheck = () => {
    const { data } = useTenantCheck_ActiveTenantQuery()

    const activeTenant = useMemo(
        () => data?.currentUser.activeTenant?.tenant,
        [data]
    )

    let { tenantId } = useParams()

    const { pathname } = useLocation()

    const forwardURL = useMemo(
        // The incoming url is in the form of /tenant-check/:tenantId/*
        // where * is the intended destination
        // Here we extract the * and return it as the forwardURL
        // i.e. /tenant-check/:tenantId/datasets/:businessObjectId -> /datasets/:businessObjectId
        () => '/' + pathname.split('/').slice(3).join('/'),
        [pathname]
    )

    return match({ activeTenantId: activeTenant?.id, tenantId })
        .with({ activeTenantId: undefined }, () => <CenteredSpinner />)
        .with({ tenantId: undefined }, () => <CenteredSpinner />)
        .with(
            { activeTenantId: P.string, tenantId: P.string },
            ({ activeTenantId, tenantId }) => {
                if (activeTenantId === tenantId) {
                    return <Navigate to={forwardURL} replace={true} />
                } else {
                    return (
                        <StyledTenantCheck>
                            <div className="content">
                                <Text as="h1" variant="bold-3">
                                    Incorrect tenant found.
                                </Text>

                                <Text as="p" variant="regular-4">
                                    You are currently signed in to a different
                                    tenant than the one found in the link you
                                    followed.
                                </Text>

                                <Text as="p" variant="regular-4">
                                    Use the button below to change tenants.
                                </Text>

                                <TenantSwitch
                                    navigateToOnComplete={forwardURL}
                                    active={{
                                        id: activeTenantId,
                                        name: activeTenant!.name,
                                    }}
                                    tenantOptions={data!.currentUser.tenancies
                                        .filter(
                                            ({ tenant }) =>
                                                tenant.id === tenantId ||
                                                tenant.id === activeTenantId
                                        )
                                        .map(({ tenant }) => ({
                                            label: tenant.name,
                                            value: tenant.id,
                                        }))}
                                />
                            </div>
                        </StyledTenantCheck>
                    )
                }
            }
        )
        .exhaustive()
}

const StyledTenantCheck = styled.div`
    ${flex('column', 'center', 'center')};
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    padding: 2rem;

    .content {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 1rem;
        padding: 2rem 0;
        max-width: 30rem;

        button {
            margin-top: 1rem;
        }
    }
`
