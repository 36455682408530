import { css, flex, styled } from 'Adapters/Freestyled'
import { bold4, bold5, bold6 } from 'Components/Text'
import { Size, StyledProps, Variant } from './types'

export const StyledTextButton = styled.button<StyledProps>`
    ${flex('row', 'center', 'center')};
    ${({ $size }) => applyButtonSizeStyles($size)}
    ${({ $variant }) => applyButtonVariantStyles($variant)}
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    display: block;
    text-align: center;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`

const applyButtonSizeStyles = (size: Size) => {
    switch (size) {
        case 'xSmall': {
            return css`
                ${bold6};
                border-radius: 0.25rem;
                padding: 0.5rem;
            `
        }
        case 'small': {
            return css`
                ${bold5};
                border-radius: 0.25rem;
                padding: 0.75rem 1rem;
                max-height: 2.594rem;
            `
        }
        // Medium
        default: {
            return css`
                ${bold4}
                border-radius: ${({ theme }) => theme.borderRadius.small};
                padding: 1rem 1.5rem;
            `
        }
    }
}

export const applyButtonVariantStyles = (variant: Variant) => {
    switch (variant) {
        case 'primary': {
            return css`
                color: ${({ theme }) => theme.palette.text['04'].normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui.primary.normal};
                border: 1px solid transparent;

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) => theme.palette.text['04'].hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui.primary.hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text['04'].active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui.primary.active};
                }

                &:focus-visible {
                    box-shadow: 0 0 0 0.125rem
                        ${({ theme }) => theme.palette.brand['01'].normal};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};
                    border: 1px solid transparent;
                }
            `
        }
        case 'secondary': {
            return css`
                color: ${({ theme }) => theme.palette.text['02'].normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].normal};
                border: 1px solid transparent;

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) => theme.palette.text['02'].hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui['02'].hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text['02'].active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].active};
                }

                &:focus-visible {
                    box-shadow: 0 0 0 0.125rem
                        ${({ theme }) => theme.palette.brand['01'].normal};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};
                    border: 1px solid transparent;
                }
            `
        }
        case 'ghost': {
            return css`
                color: ${({ theme }) => theme.palette.text.primary.normal};
                background-color: ${({ theme }) =>
                    theme.palette.ui['01'].normal};
                border: 1px solid transparent;

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) =>
                            theme.palette.text.primary.hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui['01'].hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text.primary.active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['01'].active};
                }

                &:focus-visible {
                    box-shadow: 0 0 0 0.125rem
                        ${({ theme }) => theme.palette.brand['01'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['01'].hover};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['01'].normal};
                    border: 1px solid transparent;
                }
            `
        }
        /* To be extended if and when necessary */
        default: {
            throw Error('Button variant not supported')
        }
    }
}
