import { styled } from 'Adapters/Freestyled'
import {
    bold0,
    bold1,
    bold2,
    bold3,
    bold4,
    bold5,
    bold6,
    bold7,
    regular0,
    regular00,
    regular1,
    regular2,
    regular3,
    regular4,
    regular5,
    regular6,
    regular7,
    strikethroughStyles,
    underlineStyles,
} from './mixins'

export const StyledText = styled.p<{
    strikethrough: boolean
    underline: boolean
}>`
    /* Text Decoration */

    ${({ strikethrough }) => strikethrough && strikethroughStyles}
    ${({ underline }) => underline && underlineStyles}
    
    /* Variants */

    &.bold-0 {
        ${bold0}
    }

    &.bold-1 {
        ${bold1}
    }

    &.bold-2 {
        ${bold2}
    }

    &.bold-3 {
        ${bold3}
    }

    &.bold-4 {
        ${bold4}
    }

    &.bold-5 {
        ${bold5}
    }

    &.bold-6 {
        ${bold6}
    }

    &.bold-7 {
        ${bold7}
    }

    &.regular-00 {
        ${regular00}
    }

    &.regular-0 {
        ${regular0}
    }

    &.regular-1 {
        ${regular1}
    }

    &.regular-2 {
        ${regular2}
    }

    &.regular-2-spaced {
        ${regular2}
        line-height: 140%;
    }

    &.regular-3 {
        ${regular3}
    }

    &.regular-4 {
        ${regular4}
    }

    &.regular-5 {
        ${regular5}
    }

    &.regular-6 {
        ${regular6}
    }

    &.regular-7 {
        ${regular7}
    }
`
