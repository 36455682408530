import { useResponder } from 'Hooks'
import { FC } from 'react'
import { Large } from './Large/Large'
import { Small } from './Small/Small'
import { useBusinessObjectHeaderQuery } from './__generated__/q'

type Props = {
    id: string
    onDelete: () => void
    showDelete: boolean
}

const BOHeader: FC<Props> = ({ id, onDelete, showDelete }) => {
    const { screenSize } = useResponder()
    const { data } = useBusinessObjectHeaderQuery({
        variables: { input: { id } },
        fetchPolicy: 'cache-and-network',
    })

    return screenSize === 'large' ? (
        <Large
            businessObject={data?.businessObject}
            onDelete={onDelete}
            showDelete={showDelete}
        />
    ) : (
        <Small
            businessObject={data?.businessObject}
            onDelete={onDelete}
            showDelete={showDelete}
        />
    )
}

export { BOHeader }
