import { Close, Description } from '@radix-ui/react-toast'
import { IconButton } from 'Components/Button/IconButton'
import { FC } from 'react'
import { StyledToast } from './styles'

type Props = {
    open: boolean
    onOpenChange: (open: boolean) => void
    description: string
}

export const SimpleToast: FC<Props> = ({ open, onOpenChange, description }) => {
    return (
        <StyledToast open={open} onOpenChange={onOpenChange}>
            <Description>{description}</Description>

            <Close asChild>
                <IconButton
                    iconName="Close"
                    aria-label="Dismiss"
                    variant="primary"
                />
            </Close>
        </StyledToast>
    )
}
