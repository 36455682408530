import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_Integration_InstanceMutationVariables = Types.Exact<{
  input: Types.CreateIntegrationInstanceInput;
}>;


export type Create_Integration_InstanceMutation = { __typename?: 'Mutation', createIntegrationInstance: { __typename?: 'IntegrationInstance', id: string } };

export type Delete_Integration_InstanceMutationVariables = Types.Exact<{
  input: Types.DeleteIntegrationInstanceInput;
}>;


export type Delete_Integration_InstanceMutation = { __typename?: 'Mutation', deleteIntegrationInstance: boolean };


export const Create_Integration_InstanceDocument = gql`
    mutation Create_Integration_Instance($input: CreateIntegrationInstanceInput!) {
  createIntegrationInstance(input: $input) {
    id
  }
}
    `;
export type Create_Integration_InstanceMutationFn = Apollo.MutationFunction<Create_Integration_InstanceMutation, Create_Integration_InstanceMutationVariables>;

/**
 * __useCreate_Integration_InstanceMutation__
 *
 * To run a mutation, you first call `useCreate_Integration_InstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Integration_InstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationInstanceMutation, { data, loading, error }] = useCreate_Integration_InstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_Integration_InstanceMutation(baseOptions?: Apollo.MutationHookOptions<Create_Integration_InstanceMutation, Create_Integration_InstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_Integration_InstanceMutation, Create_Integration_InstanceMutationVariables>(Create_Integration_InstanceDocument, options);
      }
export type Create_Integration_InstanceMutationHookResult = ReturnType<typeof useCreate_Integration_InstanceMutation>;
export type Create_Integration_InstanceMutationResult = Apollo.MutationResult<Create_Integration_InstanceMutation>;
export type Create_Integration_InstanceMutationOptions = Apollo.BaseMutationOptions<Create_Integration_InstanceMutation, Create_Integration_InstanceMutationVariables>;
export const Delete_Integration_InstanceDocument = gql`
    mutation Delete_Integration_Instance($input: DeleteIntegrationInstanceInput!) {
  deleteIntegrationInstance(input: $input)
}
    `;
export type Delete_Integration_InstanceMutationFn = Apollo.MutationFunction<Delete_Integration_InstanceMutation, Delete_Integration_InstanceMutationVariables>;

/**
 * __useDelete_Integration_InstanceMutation__
 *
 * To run a mutation, you first call `useDelete_Integration_InstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Integration_InstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationInstanceMutation, { data, loading, error }] = useDelete_Integration_InstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_Integration_InstanceMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Integration_InstanceMutation, Delete_Integration_InstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Integration_InstanceMutation, Delete_Integration_InstanceMutationVariables>(Delete_Integration_InstanceDocument, options);
      }
export type Delete_Integration_InstanceMutationHookResult = ReturnType<typeof useDelete_Integration_InstanceMutation>;
export type Delete_Integration_InstanceMutationResult = Apollo.MutationResult<Delete_Integration_InstanceMutation>;
export type Delete_Integration_InstanceMutationOptions = Apollo.BaseMutationOptions<Delete_Integration_InstanceMutation, Delete_Integration_InstanceMutationVariables>;