import { css, flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { AlreadyDeployed } from './AlreadyDeployed'
import { CanEditSnackbar } from './CanEditSnackbar'
import { Completed } from './Completed'
import { Template } from './Template'
import { WhatWeWillBuild } from './WhatWeWillBuild/WhatWeWillBuild'
import { Available_TemplatesQuery } from './__generated__/query'
import { useDeployTemplate } from './hooks/deploy'
import { useTemplateState } from './hooks/state'

type Props = {
    templateCategory: Available_TemplatesQuery['workspaceTemplates'][number]
    onDeploy: () => void
    isPreviouslyDeployed: boolean
}

export const Category: FC<Props> = ({
    templateCategory,
    onDeploy,
    isPreviouslyDeployed,
}) => {
    const { open, setOpen, triggerToast } = useTriggerToast()

    const { selectedTemplates, toggleSelection, datasets, processes } =
        useTemplateState({
            templateCategory,
        })

    const {
        isDeployed,
        deployTemplate,
        loading: mutating,
    } = useDeployTemplate({
        onDeploy,
        onError: triggerToast,
    })

    const handleDeploy = () =>
        deployTemplate({
            templateCategory: templateCategory.id,
            templates: [...selectedTemplates],
        })

    const { isLarge } = useResponder()

    if (isDeployed) {
        return <Completed isLarge={isLarge} />
    }

    if (isPreviouslyDeployed) {
        return (
            <AlreadyDeployed
                category={templateCategory.name}
                isLarge={isLarge}
            />
        )
    }

    return (
        <>
            <StyledTemplateCategory $isLarge={isLarge}>
                <VerticalScrollArea height="100%" width="100%">
                    <StyledDescription>
                        <Text variant="bold-0" as="h2">
                            Build your workspace
                        </Text>

                        <CanEditSnackbar />

                        <Text variant="regular-4" as="p">
                            Select one or more of the templates below and we
                            will build your workspace.
                        </Text>

                        <StyledCards $isLarge={isLarge}>
                            <ul>
                                {templateCategory.templates.map(template => (
                                    <Template
                                        key={template.id}
                                        template={template}
                                        isSelected={selectedTemplates.has(
                                            template.id
                                        )}
                                        onClick={toggleSelection(template.id)}
                                        imageURL={
                                            template.imageURL ?? undefined
                                        }
                                    />
                                ))}
                            </ul>
                        </StyledCards>
                    </StyledDescription>

                    <WhatWeWillBuild
                        processes={processes}
                        datasets={datasets}
                        isLarge={isLarge}
                    />
                </VerticalScrollArea>

                <StyledBuildWorkspaceButton
                    type="button"
                    icon="ArrowRight"
                    text="Build your workspace"
                    onClick={handleDeploy}
                    disabled={mutating || selectedTemplates.size === 0}
                    variant="primary"
                    $isLarge={isLarge}
                />
            </StyledTemplateCategory>

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description={
                    "We couldn't deploy your template. Contact us if the problem persists."
                }
            />
        </>
    )
}

const cardsLargeStyles = css`
    ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
        gap: 2rem;
    }
`

const cardsSmallStyles = css`
    padding-bottom: 1rem;

    ul {
        ${flex('column', 'flex-start', 'center')};
        gap: 1rem;
        padding: 0;

        li {
            max-width: none;
            width: 100%;
        }
    }
`

const StyledCards = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) => ($isLarge ? cardsLargeStyles : cardsSmallStyles)};
    width: 100%;
`

const templateCategoryLargeStyles = css`
    padding: 2rem;
`

const templateCategorySmallStyles = css`
    padding: 1rem 1rem 3.5rem 1rem;
`

const StyledTemplateCategory = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) =>
        $isLarge ? templateCategoryLargeStyles : templateCategorySmallStyles};
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`

const StyledDescription = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;

    > p {
        max-width: 42rem;
    }
`

const smallBuildWorkspaceButtonStyles = css`
    width: 100%;
`

const StyledBuildWorkspaceButton = styled(IconTextButton)<{
    $isLarge: boolean
}>`
    ${({ $isLarge }) => !$isLarge && smallBuildWorkspaceButtonStyles};
    margin: 0 auto;
`
