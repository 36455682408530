import { styled } from 'Adapters/Freestyled'
import { ControlledModal } from 'Components/Modal'
import { CenteredSpinner } from 'Components/Spinner'
import { noop } from 'lodash'
import { FC } from 'react'
import { Container } from './Container'

type Props = {
    businessObjectId: string | undefined
    includeFields?: string[]
    onClose: () => void
    onFieldUpdated?: (fieldId: string) => void
}

export const SubFieldCompletionModal: FC<Props> = ({
    businessObjectId,
    includeFields,
    onClose,
    onFieldUpdated,
}) => (
    <ControlledModal
        open={!!businessObjectId}
        onOpenChange={open => !open && onClose()}
        title={'Incomplete fields'}
        description="The following fields are required in order to progress"
        renderContent={() => (
            <Styled>
                {businessObjectId ? (
                    <Container
                        id={businessObjectId}
                        onClose={onClose}
                        includeFields={includeFields}
                        onFieldUpdated={onFieldUpdated}
                    />
                ) : (
                    <CenteredSpinner />
                )}
            </Styled>
        )}
        returnFocus={noop}
    />
)

const Styled = styled.div`
    padding-top: 1rem;
    width: 30rem;
    max-width: 90vw;
`
