import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

type Props = {
    errors: string[]
}

export const EditorErrors: FC<Props> = ({ errors }) => {
    if (!errors.length) return null

    return (
        <StyledErrorMessage>
            {errors.map((errorMessage, i) => (
                <span key={i}>
                    {errorMessage}
                    {i !== errors.length - 1 || errors.length < 2 ? '' : ', '}
                </span>
            ))}
        </StyledErrorMessage>
    )
}

export const StyledErrorMessage = styled.p`
    color: ${({ theme }) => theme.palette.support['01'].normal};
`
