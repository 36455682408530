import { logger } from 'Adapters/Logger'
import { useEditDocumentMutation } from './__generated__/mutation'

export const useEditDocument = () => {
    const [edit, { loading }] = useEditDocumentMutation({
        onError(error) {
            logger.error('Failed to edit document', error)
        },
    })

    return {
        editDocument: edit,
        editLoading: loading,
    }
}
