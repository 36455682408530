import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { Tutorial } from 'Features/Tutorials'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { slides } from '../Tutorial/slides'
import { ImportBusinessObjectsModal } from './ImportBusinessObjectsModal'
import { ProcessSingleQuery } from './__generated__/query'

type Props = Pick<
    ProcessSingleQuery['process'],
    'id' | 'name' | 'description' | 'operatesUpon'
> & {
    onBusinessObjectAdded: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

const HeaderLarge: FC<Props> = ({
    id,
    name,
    description,
    operatesUpon,
    onBusinessObjectAdded,
}) => {
    const navigate = useNavigate()
    const { isLarge } = useResponder()
    return (
        <StyledLarge>
            <div>
                <IconButton
                    iconName="CarrotLeft"
                    onClick={() => navigate('..')}
                    variant="secondary"
                />
                <div>
                    <div className="name">
                        <Tutorial markdown={slides} />
                        <Text as="h3" variant="bold-2">
                            {name}
                        </Text>
                    </div>

                    {description && (
                        <Text as="p" variant="regular-5">
                            {description}
                        </Text>
                    )}
                </div>
            </div>
            <div>
                <IconTextButton
                    icon="Domain"
                    text={`View definition`}
                    variant="secondary"
                    size="small"
                    onClick={() => {
                        navigate(
                            `/manage-workspace${
                                isLarge ? '' : '/model'
                            }/processes/${slugify(name, {
                                lower: true,
                            })}`
                        )
                    }}
                />

                <IconTextButton
                    icon="Cube"
                    text={`View all ${operatesUpon[0].name} records`}
                    variant="secondary"
                    size="small"
                    onClick={() => {
                        navigate(
                            `/datasets/${slugify(operatesUpon[0].name, {
                                lower: true,
                            })}`
                        )
                    }}
                />

                <ImportBusinessObjectsModal
                    processId={id}
                    operatesUpon={operatesUpon[0]}
                    onBusinessObjectAdded={onBusinessObjectAdded}
                />
            </div>
        </StyledLarge>
    )
}

const StyledLarge = styled.header`
    padding: 2rem 3rem 1.25rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    > div:first-child {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    > div:first-child > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        align-items: flex-start;
    }

    > div:first-child > div > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;
        align-items: flex-start;
    }

    .name {
        ${flex('row', 'flex-start', 'flex-start')};
        gap: 0.25rem;
    }
`

export { HeaderLarge }
