import { TextInput } from 'Components/Input'
import {
    EmailFieldValidation,
    EmailField as Field,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: EmailFieldValidation
    onSubmit: OnSubmit<Field, EmailFieldValidation>
    onClose: () => void

    hideRequired: boolean
}

const EmailField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, EmailFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <EmailSubFields
                defaultValue={{
                    value: state.field.defaultValue ?? '',
                    onChange: value => {
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                defaultValue: value,
                            },
                        }))
                    },
                }}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type SubFieldsProps = {
    defaultValue: {
        value: string
        onChange: (value: string) => void
    }
}

const EmailSubFields: FC<SubFieldsProps> = ({ defaultValue }) => {
    const { current: defaultFieldId } = useRef(v4())
    return (
        <InputWrapper
            label="Default value"
            fieldId={defaultFieldId}
            description="If set this will be the default email address when a new record is created."
        >
            <TextInput
                type="email"
                id={defaultFieldId}
                value={defaultValue.value}
                placeholder="Enter a default value"
                onChange={({ currentTarget: { value } }) => {
                    defaultValue.onChange(value)
                }}
            />
        </InputWrapper>
    )
}

export { EmailField, EmailSubFields }
