import { styled } from 'Adapters/Freestyled'
import { VerticalScrollArea } from 'Components/ScrollableArea'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC } from 'react'
import { Card } from '../../Card'

type Props = {
    businessObjects: BusinessObjectTableItemFragment[]
    openDrawer: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

export const CardList: FC<Props> = ({ businessObjects, openDrawer }) => {
    return (
        <StyledSection>
            <VerticalScrollArea height={'100%'} width={'100%'}>
                <div className="cards-list">
                    {businessObjects.map(businessObject => (
                        <Card
                            key={businessObject.id}
                            businessObject={businessObject}
                            openDrawer={id => {
                                openDrawer({
                                    id,
                                    tab: 'details',
                                })
                            }}
                        />
                    ))}
                </div>
            </VerticalScrollArea>
        </StyledSection>
    )
}

const StyledSection = styled.section`
    padding: 1rem 0.5rem;

    .cards-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 1rem;
    }
`
