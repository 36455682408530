import { removeAt } from 'Utils'
import { CreateFieldConditionCriterionInput, Operator } from '__generated__'
import { has, isEqual, omit, partition } from 'lodash'
import {
    FieldType,
    createDefaultLabel,
    createNewField,
} from '../../../Utils/BusinessObjectDefinition'
import {
    AIInputChanged,
    ActionCriterionPurposeChanged,
    AddActionCriterion,
    AddFieldConditionCriterion,
    AddProcessDelegateCriterion,
    AddProcessFanoutCriterion,
    ApplyCriterionConditions,
    ConvertDelegateToFanout,
    ConvertFanoutToDelegate,
    FieldAdded,
    FieldEdited,
    FieldMoved,
    FieldRemoved,
    InsertPhase,
    IntegrationAdded,
    IntegrationRemoved,
    IntegrationUpdated,
    LabelEdited,
    MoveCriterionDown,
    MoveCriterionUp,
    MovePhaseLeft,
    MovePhaseRight,
    RemoveCriterion,
    RemoveCriterionDueTime,
    RemovePhase,
    SetActionCriterionDefaultAssignee,
    SetActionCriterionDescription,
    SetCriterionDueTime,
    SetDescription,
    SetFieldConditionCriterionFieldId,
    SetName,
    SetOperatesUpon,
    SetPhaseDescription,
    SetPhaseName,
    SetProcessDelegateCriterionProcessId,
    SetProcessDelegateCriterionTransform,
    SetProcessFanoutCriterionProcessId,
    SetProcessFanoutCriterionTransform,
    State,
    StepChanged,
    SuggestionApplied,
} from './types'

export const handleShowDetailsStep = (state: State) => (): State => ({
    ...state,
    step: 'details',
})

export const stepChanged =
    (state: State) =>
    ({ payload: { step } }: StepChanged): State => ({
        ...state,
        step,
    })

export const handleShowPhasesStep = (state: State) => (): State => ({
    ...state,
    step: 'phases',
    fields:
        state.input.operatesUpon.length > 0
            ? []
            : [
                  {
                      field: {
                          ...createNewField(FieldType.Text, true),
                          name: 'Name',
                      },
                      requiredBy: { init: true },
                  },
              ],
    label:
        state.input.operatesUpon.length > 0
            ? state.label
            : { value: '{{{ it.name }}}', isCustom: false },
})

export const handleSetName =
    ({ input, ...state }: State) =>
    ({ payload: { name } }: SetName): State => ({
        ...state,
        input: {
            ...input,
            name,
        },
    })

export const handleSetDescription =
    ({ input, ...state }: State) =>
    ({ payload: { description } }: SetDescription): State => ({
        ...state,
        input: {
            ...input,
            description,
        },
    })

export const handleSetOperatesUpon =
    ({ input, ...state }: State) =>
    ({ payload: { id } }: SetOperatesUpon): State => ({
        ...state,
        input: {
            ...input,
            operatesUpon: [id],
        },
    })

export const handleInsertPhase =
    ({ input, ...state }: State) =>
    ({ payload: { index } }: InsertPhase): State => ({
        ...state,
        input: {
            ...input,
            phases: [
                ...input.phases.slice(0, index),
                { name: '', criteria: [] },
                ...input.phases.slice(index),
            ],
        },
    })

export const handleSetPhaseName =
    ({ input, ...state }: State) =>
    ({ payload: { index, name } }: SetPhaseName): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === index ? { ...p, name } : p
            ),
        },
    })

export const handleSetPhaseDescription =
    ({ input, ...state }: State) =>
    ({ payload: { index, description } }: SetPhaseDescription): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === index ? { ...p, description } : p
            ),
        },
    })

export const handleRemovePhase =
    ({ input, ...state }: State) =>
    ({ payload: { index } }: RemovePhase): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.filter((_, i) => i !== index),
        },
    })

export const handleMovePhaseLeft =
    ({ input, ...state }: State) =>
    ({ payload: { index } }: MovePhaseLeft): State => {
        const filteredPhases = input.phases.filter((_, i) => i !== index)
        return index > 0
            ? {
                  ...state,
                  input: {
                      ...input,
                      phases: [
                          ...filteredPhases.slice(0, index - 1),
                          input.phases[index],
                          ...filteredPhases.slice(index - 1),
                      ],
                  },
              }
            : {
                  ...state,
                  input,
              }
    }

export const handleMovePhaseRight =
    ({ input, ...state }: State) =>
    ({ payload: { index } }: MovePhaseRight): State => {
        const filteredPhases = input.phases.filter((_, i) => i !== index)
        return index < input.phases.length
            ? {
                  ...state,
                  input: {
                      ...input,
                      phases: [
                          ...filteredPhases.slice(0, index + 1),
                          input.phases[index],
                          ...filteredPhases.slice(index + 1),
                      ],
                  },
              }
            : {
                  ...state,
                  input,
              }
    }

export const handleAddActionCriterion =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex } }: AddActionCriterion): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              { action: { description: '' } },
                          ],
                      }
                    : p
            ),
        },
    })

export const handleAddProcessFanoutCriterion =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex } }: AddProcessFanoutCriterion): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              { processFanout: { processId: '' } },
                          ],
                      }
                    : p
            ),
        },
    })

export const handleSetProcessFanoutCriterionTransform =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, transform },
    }: SetProcessFanoutCriterionTransform): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processFanout: {
                                            ...criterion.processFanout!,
                                            transform: {
                                                [transform.type]: {
                                                    fieldId: transform.fieldId,
                                                },
                                            },
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleSetProcessDelegateCriterionTransform =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, transform },
    }: SetProcessDelegateCriterionTransform): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processDelegate: {
                                            ...criterion.processDelegate!,
                                            transform: {
                                                [transform.type]: {
                                                    fieldId: transform.fieldId,
                                                },
                                            },
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleAddProcessDelegateCriterion =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex } }: AddProcessDelegateCriterion): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              { processDelegate: { processId: '' } },
                          ],
                      }
                    : p
            ),
        },
    })

export const handleAddFieldConditionCriterion =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex } }: AddFieldConditionCriterion): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: [
                              ...p.criteria,
                              {
                                  fieldCondition: {
                                      comparator: {
                                          valueSelector: {
                                              fieldValue: { fieldId: '' },
                                          },
                                          operator: Operator.IsDefined,
                                          with: `{}`,
                                          negate: false,
                                      },
                                  },
                              },
                          ],
                      }
                    : p
            ),
        },
    })

export const handleSetActionCriterionDescription =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, description },
    }: SetActionCriterionDescription): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        action: {
                                            ...criterion.action,
                                            description,
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleActionCriterionPurposeChanged =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, purpose },
    }: ActionCriterionPurposeChanged): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        action: {
                                            ...criterion.action,
                                            description:
                                                criterion.action?.description ??
                                                '',
                                            purpose,
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleSetFieldConditionCriterionFieldId =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, fieldId },
    }: SetFieldConditionCriterionFieldId): State => {
        const selectorIds = fieldId.split('___')

        const valueSelector =
            selectorIds.length === 1
                ? {
                      fieldValue: {
                          fieldId,
                      },
                  }
                : {
                      deep: {
                          selectors: selectorIds.map(fieldId => ({
                              fieldValue: {
                                  fieldId,
                              },
                          })),
                      },
                  }

        return {
            ...state,
            input: {
                ...input,
                phases: input.phases.map((phase, i) =>
                    i === phaseIndex
                        ? {
                              ...phase,
                              criteria: phase.criteria.map((criterion, j) =>
                                  j === criterionIndex
                                      ? {
                                            fieldCondition: {
                                                ...criterion.fieldCondition,
                                                comparator: {
                                                    ...(
                                                        criterion.fieldCondition as CreateFieldConditionCriterionInput
                                                    ).comparator,
                                                    valueSelector,
                                                },
                                            },
                                        }
                                      : criterion
                              ),
                          }
                        : phase
                ),
            },
        }
    }

export const handleSetProcessFanoutCriterionProcessId =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, processId },
    }: SetProcessFanoutCriterionProcessId): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processFanout: {
                                            processId,
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleSetProcessDelegateCriterionProcessId =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, processId },
    }: SetProcessDelegateCriterionProcessId): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((phase, i) =>
                i === phaseIndex
                    ? {
                          ...phase,
                          criteria: phase.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processDelegate: {
                                            processId,
                                        },
                                    }
                                  : criterion
                          ),
                      }
                    : phase
            ),
        },
    })

export const handleRemoveCriterion =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex, criterionIndex } }: RemoveCriterion): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: p.criteria.filter(
                              (_, j) => j !== criterionIndex
                          ),
                      }
                    : p
            ),
        },
    })

export const handleMoveCriterionUp =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex, criterionIndex } }: MoveCriterionUp): State => {
        const filteredCriteria = input.phases[phaseIndex].criteria.filter(
            (_, j) => j !== criterionIndex
        )
        return criterionIndex > 0
            ? {
                  ...state,
                  input: {
                      ...input,
                      phases: input.phases.map((p, i) =>
                          i === phaseIndex
                              ? {
                                    ...p,
                                    criteria: [
                                        ...filteredCriteria.slice(
                                            0,
                                            criterionIndex - 1
                                        ),
                                        input.phases[phaseIndex].criteria[
                                            criterionIndex
                                        ],
                                        ...filteredCriteria.slice(
                                            criterionIndex - 1
                                        ),
                                    ],
                                }
                              : p
                      ),
                  },
              }
            : { ...state, input }
    }

export const handleMoveCriterionDown =
    ({ input, ...state }: State) =>
    ({ payload: { phaseIndex, criterionIndex } }: MoveCriterionDown): State => {
        const filteredCriteria = input.phases[phaseIndex].criteria.filter(
            (_, j) => j !== criterionIndex
        )
        return criterionIndex < input.phases[phaseIndex].criteria.length
            ? {
                  ...state,
                  input: {
                      ...input,
                      phases: input.phases.map((p, i) =>
                          i === phaseIndex
                              ? {
                                    ...p,
                                    criteria: [
                                        ...filteredCriteria.slice(
                                            0,
                                            criterionIndex + 1
                                        ),
                                        input.phases[phaseIndex].criteria[
                                            criterionIndex
                                        ],
                                        ...filteredCriteria.slice(
                                            criterionIndex + 1
                                        ),
                                    ],
                                }
                              : p
                      ),
                  },
              }
            : {
                  ...state,
                  input,
              }
    }

export const handleSetCriterionDueTime =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, defaultDueSeconds },
    }: SetCriterionDueTime): State => {
        if (!defaultDueSeconds)
            return handleRemoveCriterionDueTime({
                ...state,
                input,
            })({
                type: 'removeCriterionDueTime',
                payload: { phaseIndex, criterionIndex },
            })

        return {
            ...state,
            input: {
                ...input,
                phases: input.phases.map((p, i) =>
                    i === phaseIndex
                        ? {
                              ...p,
                              criteria: p.criteria.map((criterion, cIndex) =>
                                  cIndex === criterionIndex
                                      ? {
                                            ...(criterion.action && {
                                                action: {
                                                    ...criterion.action,
                                                    defaultDueSeconds,
                                                },
                                            }),
                                        }
                                      : criterion
                              ),
                          }
                        : p
                ),
            },
        }
    }

export const handleRemoveCriterionDueTime =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex },
    }: RemoveCriterionDueTime): State => {
        return {
            ...state,
            input: {
                ...input,
                phases: input.phases.map((p, i) =>
                    i === phaseIndex
                        ? {
                              ...p,
                              criteria: p.criteria.map((criterion, cIndex) =>
                                  cIndex === criterionIndex
                                      ? {
                                            ...(criterion.action && {
                                                action: omit(
                                                    criterion.action,
                                                    'defaultDueSeconds'
                                                ),
                                            }),
                                        }
                                      : criterion
                              ),
                          }
                        : p
                ),
            },
        }
    }

export const handleSuggestionApplied =
    ({ input, ...state }: State) =>
    ({
        payload: { label, name, phases, fields },
    }: SuggestionApplied): State => {
        return {
            ...state,
            label: label
                ? {
                      value: label,
                      isCustom: false,
                  }
                : state.label,
            fields: fields || state.fields,
            input: {
                ...input,
                name,
                phases,
            },
            step: 'phases',
        }
    }

export const handleApplyCriterionConditions =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex, conditions },
    }: ApplyCriterionConditions): State => {
        return {
            ...state,
            input: {
                ...input,
                phases: input.phases.map((phase, pIndex) =>
                    pIndex === phaseIndex
                        ? {
                              ...phase,
                              criteria: phase.criteria.map(
                                  (criterion, cIndex) =>
                                      cIndex === criterionIndex
                                          ? {
                                                ...(criterion.action && {
                                                    action: {
                                                        ...criterion.action,
                                                        conditions,
                                                    },
                                                }),
                                                ...(criterion.fieldCondition && {
                                                    fieldCondition: {
                                                        ...criterion.fieldCondition,
                                                        conditions,
                                                    },
                                                }),
                                                ...(criterion.processDelegate && {
                                                    processDelegate: {
                                                        ...criterion.processDelegate,
                                                        conditions,
                                                    },
                                                }),
                                                ...(criterion.processFanout && {
                                                    processFanout: {
                                                        ...criterion.processFanout,
                                                        conditions,
                                                    },
                                                }),
                                            }
                                          : criterion
                              ),
                          }
                        : phase
                ),
            },
        }
    }

export const handleIntegrationAdded =
    ({ integrations, ...state }: State) =>
    ({ payload: { integration } }: IntegrationAdded): State => {
        return {
            ...state,
            integrations: [...integrations, integration],
        }
    }

export const handleIntegrationUpdated =
    ({ integrations, ...state }: State) =>
    ({
        payload: { integration, integrationIndex },
    }: IntegrationUpdated): State => {
        const nextIntegrations = integrations.slice()
        nextIntegrations[integrationIndex] = integration
        return {
            ...state,
            integrations: nextIntegrations,
        }
    }

export const handleIntegrationRemoved =
    ({ integrations, ...state }: State) =>
    ({ payload: { integrationIndex } }: IntegrationRemoved): State => {
        return {
            ...state,
            integrations: removeAt(integrations, integrationIndex),
        }
    }

export const handleSetActionCriterionDefaultAssignee =
    ({ input, ...state }: State) =>
    ({
        payload: { userId, phaseIndex, criterionIndex },
    }: SetActionCriterionDefaultAssignee): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: p.criteria.map((criterion, cIndex) =>
                              cIndex === criterionIndex
                                  ? {
                                        ...(criterion.action && {
                                            action: {
                                                ...criterion.action,
                                                defaultAssignee: {
                                                    user: {
                                                        userId,
                                                    },
                                                },
                                            },
                                        }),
                                    }
                                  : criterion
                          ),
                      }
                    : p
            ),
        },
    })

export const handleConvertDelegateToFanout =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex },
    }: ConvertDelegateToFanout): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: p.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processFanout:
                                            criterion.processDelegate,
                                    }
                                  : criterion
                          ),
                      }
                    : p
            ),
        },
    })

export const handleConvertFanoutToDelegate =
    ({ input, ...state }: State) =>
    ({
        payload: { phaseIndex, criterionIndex },
    }: ConvertFanoutToDelegate): State => ({
        ...state,
        input: {
            ...input,
            phases: input.phases.map((p, i) =>
                i === phaseIndex
                    ? {
                          ...p,
                          criteria: p.criteria.map((criterion, j) =>
                              j === criterionIndex
                                  ? {
                                        processDelegate:
                                            criterion.processFanout,
                                    }
                                  : criterion
                          ),
                      }
                    : p
            ),
        },
    })

export const handleFieldAdded =
    ({ fields, ...state }: State) =>
    ({ payload: { requiredBy, field } }: FieldAdded): State => ({
        ...state,
        fields: [...fields, { field, requiredBy }],
    })

export const handleFieldEdited =
    ({ fields, ...state }: State) =>
    ({ payload: { requiredBy, field, index } }: FieldEdited): State => {
        const [initFields, phaseFields] = partition(fields, f =>
            has(f.requiredBy, 'init')
        )

        if (has(requiredBy, 'init')) {
            initFields.splice(index, 1, {
                field,
                requiredBy,
            })

            return {
                ...state,
                fields: [...initFields, ...phaseFields],
            }
        }

        phaseFields.splice(index, 1, {
            field,
            requiredBy,
        })

        return {
            ...state,
            fields: [...initFields, ...phaseFields],
        }
    }

export const handleFieldRemoved =
    ({ fields, ...state }: State) =>
    ({ payload: { requiredBy, field, index } }: FieldRemoved): State => {
        const [initFields, phaseFields] = partition(fields, f =>
            has(f.requiredBy, 'init')
        )

        if (has(requiredBy, 'init')) {
            initFields.splice(index, 1)
            return {
                ...state,
                fields: [...initFields, ...phaseFields],
            }
        }

        const newPhaseFields = phaseFields.filter(
            f =>
                !(
                    isEqual(f.requiredBy, requiredBy) &&
                    f.field.name === field.name
                )
        )

        return {
            ...state,
            fields: [...initFields, ...newPhaseFields],
        }
    }

export const handleFieldMoved =
    ({ fields, ...state }: State) =>
    ({ payload: { index, direction } }: FieldMoved): State => {
        if (
            (index === 0 && direction === 'up') ||
            (index === fields.length - 1 && direction === 'down')
        ) {
            return {
                ...state,
                fields,
            }
        }

        const [initFields, phaseFields] = partition(fields, f =>
            has(f.requiredBy, 'init')
        )

        const targetIndex = direction === 'up' ? index - 1 : index + 1

        const newInitFields = initFields.filter((_, i) => i !== index)
        newInitFields.splice(targetIndex, 0, initFields[index])

        return {
            ...state,
            fields: [...newInitFields, ...phaseFields],
        }
    }

export const handleLabelEdited =
    ({ label, ...state }: State) =>
    ({ payload: { value } }: LabelEdited): State => ({
        ...state,
        label: {
            value,
            isCustom: label.isCustom,
        },
    })

export const handleCustomLabelToggled =
    ({ label, ...state }: State) =>
    (): State => ({
        ...state,
        label: {
            value: label.isCustom
                ? createDefaultLabel({
                      fields: state.fields.map(({ field }) => field),
                  })
                : label.value,
            isCustom: !label.isCustom,
        },
    })

export const handleAIInputChanged =
    ({ ai, ...state }: State) =>
    ({ payload: { key, value } }: AIInputChanged): State => ({
        ...state,
        ai: {
            ...ai,
            [key]: value,
        },
    })
