import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { ComparatorFragmentDoc } from '../../../../../Fragments/__generated__/Comparator';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuggestPhasesQueryVariables = Types.Exact<{
  input: Types.SuggestedPhasesInput;
}>;


export type SuggestPhasesQuery = { __typename?: 'Query', suggestedPhases: { __typename?: 'SuggestedProcess', phases: Array<{ __typename?: 'SuggestedPhase', name: string, description?: string | null, criteria: Array<{ __typename: 'ActionPhaseCriterion', description: string, defaultDueSeconds?: number | null, purpose?: string | null } | { __typename: 'FieldConditionPhaseCriterion', comparator: { __typename: 'BusinessObjectComparator', negate: boolean, operator: Types.Operator, with: string, valueSelector: { __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'DeepSelector', type: Types.ValueSelectorType, selectors: Array<{ __typename: 'ConstantSelector', type: Types.ValueSelectorType, value: string } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType }> } | { __typename: 'FieldValueSelector', default?: string | null, fieldId: string, type: Types.ValueSelectorType } } } | { __typename: 'ProcessDelegatePhaseCriterion' } | { __typename: 'ProcessFanoutPhaseCriterion' }> }> } };


export const SuggestPhasesDocument = gql`
    query SuggestPhases($input: SuggestedPhasesInput!) {
  suggestedPhases(input: $input) {
    phases {
      name
      description
      criteria {
        __typename
        ... on ActionPhaseCriterion {
          description
          defaultDueSeconds
          purpose
        }
        ... on FieldConditionPhaseCriterion {
          comparator {
            ...Comparator
          }
        }
      }
    }
  }
}
    ${ComparatorFragmentDoc}`;

/**
 * __useSuggestPhasesQuery__
 *
 * To run a query within a React component, call `useSuggestPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestPhasesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestPhasesQuery(baseOptions: Apollo.QueryHookOptions<SuggestPhasesQuery, SuggestPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestPhasesQuery, SuggestPhasesQueryVariables>(SuggestPhasesDocument, options);
      }
export function useSuggestPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestPhasesQuery, SuggestPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestPhasesQuery, SuggestPhasesQueryVariables>(SuggestPhasesDocument, options);
        }
export type SuggestPhasesQueryHookResult = ReturnType<typeof useSuggestPhasesQuery>;
export type SuggestPhasesLazyQueryHookResult = ReturnType<typeof useSuggestPhasesLazyQuery>;
export type SuggestPhasesQueryResult = Apollo.QueryResult<SuggestPhasesQuery, SuggestPhasesQueryVariables>;