import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { gqlUri } from 'Adapters/Apollo/config'
import { CenteredSpinner } from 'Components/Spinner'
import { useSandbox } from 'Hooks/useSandbox'
import { App } from 'Root/App'
import { FC, useCallback, useState } from 'react'
import {
    CreateSandboxUserMutation,
    useCreateSandboxUserMutation,
} from './__generated__/mutation'

export const Sandbox: FC = () => {
    const sandbox = useSandbox()

    const [createSandboxUser, { loading: creatingSandboxUser }] =
        useCreateSandboxUserMutation({
            client: new ApolloClient({
                link: new HttpLink({ uri: gqlUri }),
                cache: new InMemoryCache(),
            }),
        })

    const [sandboxUser, setSandboxUser] =
        useState<Promise<CreateSandboxUserMutation>>()

    const getSandboxToken = useCallback(async () => {
        if (sandbox.state === 'active') {
            return sandbox.user.token
        }

        if (sandboxUser) {
            return (await sandboxUser).createSandboxUser.token
        }

        const userPromise = createSandboxUser().then(r => {
            if (r.data) {
                sandbox.state === 'pending' &&
                    sandbox.setUser({
                        id: r.data.createSandboxUser.user.id,
                        token: r.data.createSandboxUser.token,
                    })
                return r.data
            }
            throw new Error('Failed to create sandbox user')
        })

        setSandboxUser(userPromise)

        return (await userPromise).createSandboxUser.token
    }, [createSandboxUser, sandbox, sandboxUser])

    if (creatingSandboxUser) {
        return <CenteredSpinner />
    }

    return <App isFirstVisit getSandboxToken={getSandboxToken} />
}
