import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { ControlledModal } from 'Components/Modal'
import { ContactUsToast, SimpleToast, useTriggerToast } from 'Components/Toast'
import { useBusinessObjectsView } from 'Features/BusinessObjectView'
import { useBusinessObjectFilterState } from 'Features/FilterBusinessObject/useBusinessObjectFilterState'
import { useModalControls } from 'Hooks'
import { fromEvent } from 'Utils'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { FC, useState } from 'react'
import { useSaveBusinessObjectViewMutation } from '../../__generated__/mutation'

type Props = {
    businessObjectDefinitionId: string
}

export const SaveView: FC<Props> = ({ businessObjectDefinitionId }) => {
    const { filterState } = useBusinessObjectFilterState()

    const { display, selectedFields, sort, grouping } = useBusinessObjectsView()

    const [viewName, setViewName] = useState('')

    const {
        buttonRef,
        open: modalOpen,
        openModal,
        closeModal,
        returnFocus,
        setOpen: setModalOpen,
    } = useModalControls()

    const {
        open: toastOpen,
        setOpen: setToastOpen,
        triggerToast,
    } = useTriggerToast()

    const [persist] = useSaveBusinessObjectViewMutation({
        variables: {
            input: {
                type: ViewOfType.BusinessObjects,
                businessObjectDefinitionId,
                configuration: Buffer.from(
                    JSON.stringify({
                        filterState,
                        display,
                        selectedFields,
                        sort,
                        ...(grouping && { grouping }),
                    })
                ).toString('base64'),
                name: viewName,
            },
        },
        onCompleted: () => {
            closeModal()
            triggerToast()
            setViewName('')
        },
        onError: () => {
            triggerErrorToast()
        },
        refetchQueries: ['GetBusinessObjectViews'],
    })

    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    return (
        <>
            <IconTextButton
                icon="Save"
                text="Save view"
                onClick={openModal}
                ref={buttonRef}
                size="xSmall"
            />

            <ControlledModal
                renderContent={() => (
                    <StyledModalContent>
                        <TextInput
                            value={viewName}
                            onChange={e => setViewName(fromEvent(e))}
                        />
                        <TextButton
                            text="Save view"
                            onClick={() => {
                                persist()
                            }}
                            size="small"
                            disabled={!viewName}
                        />
                    </StyledModalContent>
                )}
                title={'Save view'}
                description={'Give the view a name'}
                open={modalOpen}
                onOpenChange={setModalOpen}
                returnFocus={returnFocus}
            />

            <SimpleToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={'Successfully saved view'}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={'There was an error saving the view.'}
            />
        </>
    )
}

const StyledModalContent = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;

    button {
        margin-top: 0.5rem;
        width: 100%;
    }
`
