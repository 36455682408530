import { useMemo } from 'react'
import { UserFieldConstraints } from '__generated__'
import { defaultConstraints, UserConstraintsMap } from './types'

type Params = {
    userConstraints: UserFieldConstraints[]
}

export const useUserConstraints = ({ userConstraints }: Params) => {
    const constraints = useMemo(() => {
        return userConstraints.reduce<UserConstraintsMap>((out, constraint) => {
            if (constraint.__typename === 'RequiredConstraint') {
                return { ...out, required: true }
            }

            return out
        }, defaultConstraints)
    }, [userConstraints])

    const constraintsTooltip = useMemo(() => {
        if (!constraints.required) return ''
        return 'Required.'
    }, [constraints])

    return { constraints, constraintsTooltip }
}
