import * as Types from '../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManageWorkspaceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ManageWorkspaceQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } } | null } };


export const ManageWorkspaceDocument = gql`
    query ManageWorkspace {
  currentUser {
    id
    activeTenant {
      tenant {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useManageWorkspaceQuery__
 *
 * To run a query within a React component, call `useManageWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<ManageWorkspaceQuery, ManageWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageWorkspaceQuery, ManageWorkspaceQueryVariables>(ManageWorkspaceDocument, options);
      }
export function useManageWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageWorkspaceQuery, ManageWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageWorkspaceQuery, ManageWorkspaceQueryVariables>(ManageWorkspaceDocument, options);
        }
export type ManageWorkspaceQueryHookResult = ReturnType<typeof useManageWorkspaceQuery>;
export type ManageWorkspaceLazyQueryHookResult = ReturnType<typeof useManageWorkspaceLazyQuery>;
export type ManageWorkspaceQueryResult = Apollo.QueryResult<ManageWorkspaceQuery, ManageWorkspaceQueryVariables>;