import { useFlow } from '@frigade/react'
import { Group } from '@radix-ui/react-dropdown-menu'
import { IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import {
    Display,
    displays,
    useActionsView,
} from 'Features/ActionView/useActionsView'
import { FrigadeHint } from 'Features/Frigade'
import { FC } from 'react'
import { match } from 'ts-pattern'

const IDENTIFIER = 'flow_GSUkRvmS'
const STEP_IDENTIFIER = 'action_view_callout'

export const DisplayControl: FC = () => {
    const { display, setDisplay } = useActionsView()

    const { flow } = useFlow(IDENTIFIER)

    const hint = flow?.steps.get(STEP_IDENTIFIER)

    if (!hint || !flow) return null

    return (
        <Dropdown
            align="start"
            renderInSpan
            disabled={!hint.$state.completed}
            renderOpenDropdownButton={() => (
                <FrigadeHint flowId={IDENTIFIER} stepId={STEP_IDENTIFIER}>
                    <IconTextButton
                        icon="View"
                        text={optionToLabel(display)}
                        size="xSmall"
                        onClick={() => {
                            if (!hint.$state.completed) {
                                hint.complete()
                                flow.complete()
                            }
                        }}
                    />
                </FrigadeHint>
            )}
            renderContent={() => (
                <Group>
                    {displays.map(option => (
                        <DropdownButton
                            text={optionToLabel(option)}
                            onSelect={() => {
                                setDisplay(option)
                            }}
                            icon="View"
                            key={option}
                        />
                    ))}
                </Group>
            )}
        />
    )
}

const optionToLabel = (option: Display) => {
    return match(option)
        .with('table', () => 'Table')
        .with('timeline', () => 'Timeline: Due date')
        .with('card', () => 'Card')
        .exhaustive()
}
