import { getDataColors, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { ColorPicker } from 'Components/ColorPicker'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Grouping } from './types'

type Props = {
    groups: Grouping['fields'][number]['groups']
    onMoveUp: (groupdId: string) => void
    onMoveDown: (groupdId: string) => void
    onColorSelect: (groupdId: string) => (color: string) => void
}

const GroupConfig: FC<Props> = ({
    groups,
    onMoveUp,
    onMoveDown,
    onColorSelect,
}) => (
    <Styled>
        {groups.map((group, i) => (
            <StyledGroup key={group.id} $color={group.color}>
                <Text as="p" variant="regular-5">
                    {group.value}
                </Text>
                <IconButton
                    iconName="ArrowUp"
                    disabled={i === 0}
                    onClick={() => onMoveUp(group.id)}
                />
                <IconButton
                    iconName="ArrowDown"
                    disabled={i >= groups.length - 1}
                    onClick={() => onMoveDown(group.id)}
                />
                <ColorPicker
                    colors={getDataColors()}
                    onSelect={onColorSelect(group.id)}
                    selected={group.color}
                />
            </StyledGroup>
        ))}
    </Styled>
)

const Styled = styled.ul`
    > li {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.25rem 0 0.25rem 0.5rem;
        gap: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};

        > p {
            flex: 1;
        }
    }
`

const StyledGroup = styled.li<{ $color: string }>`
    p {
        color: ${({ $color }) => $color};
        filter: brightness(50%);
    }
`

export { GroupConfig }
