import { Trigger } from '@radix-ui/react-accordion'
import { IconButton } from 'Components/Button'
import { MaybeEdit } from 'Components/MaybeEdit'
import { Text } from 'Components/Text'
import { pipe } from 'lodash/fp'
import { ComponentProps, FC, Fragment, ReactNode } from 'react'
import { preventDefault } from 'Utils'
import { FieldConstraintType } from '__generated__'
import { Common } from '../../types'
import { Constraints } from './Contraints'
import { Styled, StyledContent, StyledHeader } from './styles'

export type AbstractFieldChangeProps = {
    isEditing: boolean
    onPropertyChanged: (
        property: 'name' | 'description'
    ) => React.ChangeEventHandler
    onConstraintRemoved?: (constraintType: FieldConstraintType) => () => void
    isRestrictedMode: boolean
    onFieldRemoved: () => void
}

type Props = Common &
    AbstractFieldChangeProps & {
        children?: ReactNode
        defaultDisplay?: ReactNode
        constraints?: ComponentProps<typeof Constraints>['constraints']
        customRows?: {
            label: string
            element: ReactNode
        }[]
    }

const AbstractField: FC<Props> = ({
    id,
    name,
    description,
    type,
    defaultDisplay,
    constraints,
    customRows,
    isEditing,
    onPropertyChanged,
    onConstraintRemoved,
    isRestrictedMode,
    onFieldRemoved,
}) => (
    <Styled value={id}>
        <Trigger asChild>
            <StyledHeader>
                <Text as="span" variant="bold-5">
                    {`${type}`}
                </Text>
                <div>
                    <MaybeEdit
                        isEditing={isEditing}
                        onChange={onPropertyChanged('name')}
                        as="h3"
                        variant="bold-3"
                    >
                        {name}
                    </MaybeEdit>
                </div>
                {isEditing && !isRestrictedMode ? (
                    <IconButton
                        iconName="Delete"
                        onClick={pipe(preventDefault, onFieldRemoved)}
                    />
                ) : null}
                <IconButton iconName="AngleDown" />
            </StyledHeader>
        </Trigger>
        <StyledContent>
            <div>
                <MaybeEdit
                    isEditing={isEditing}
                    onChange={onPropertyChanged('description')}
                    as="h4"
                    variant="bold-4"
                    label={
                        <Text as="h4" variant="bold-4">
                            Description:
                        </Text>
                    }
                >
                    {description ?? ''}
                </MaybeEdit>
                {!!defaultDisplay && (
                    <>
                        <Text as="h4" variant="bold-4">
                            Default value:
                        </Text>
                        {defaultDisplay}
                    </>
                )}
                {constraints && (
                    <>
                        <Text as="h4" variant="bold-4">
                            Constraints:
                        </Text>
                        <Constraints
                            isEditing={isEditing}
                            constraints={constraints || []}
                            onConstraintRemoved={onConstraintRemoved}
                        />
                    </>
                )}
                {customRows?.map(({ label, element }) => (
                    <Fragment key={label}>
                        <Text as="h4" variant="bold-4">
                            {label}:
                        </Text>
                        {element}
                    </Fragment>
                ))}
            </div>
        </StyledContent>
    </Styled>
)

export { AbstractField }
