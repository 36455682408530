import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { noop } from 'lodash'
import { FC, forwardRef } from 'react'
import { ViewConfig } from '../../../types'
import { ProcessSingle_CardQuery } from '../__generated__/q'
import { DisplayFields } from './DisplayFields/DisplayFields'
import { CardFooter } from './Footer'

type CardInteraction = (
    obj: ProcessSingle_CardQuery['businessObject']
) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void

type Props = {
    obj: ProcessSingle_CardQuery['businessObject']
    processId: string
    cardSettings: ViewConfig['cardSettings']
    cardInteractions: {
        contentClick: CardInteraction
        requiredFieldsClick: CardInteraction
        actionsClick: (actionIds: string[]) => void
        subprocessesClick: CardInteraction
    }
}

const Loaded: FC<Props> = props => {
    return <LoadedContents {...props} />
}

const LoadedContents = forwardRef<
    HTMLDivElement,
    Props & { preventAction?: boolean }
>(
    (
        {
            obj,
            processId,
            cardSettings,
            cardInteractions,
            preventAction = false,
        },
        ref
    ) => {
        return (
            <Styled data-completed={obj.onProcess?.isCompleted} ref={ref}>
                <StyledContent
                    id={obj.id}
                    onClick={
                        preventAction
                            ? noop
                            : cardInteractions.contentClick(obj)
                    }
                >
                    <header>
                        <Text as="h4" variant="bold-4">
                            {obj.label}
                        </Text>
                    </header>
                    <DisplayFields obj={obj} cardSettings={cardSettings} />
                </StyledContent>
                <CardFooter
                    obj={obj}
                    processId={processId}
                    onRequiredFieldsClick={cardInteractions.requiredFieldsClick(
                        obj
                    )}
                    onActionsClick={cardInteractions.actionsClick}
                    onSubprocessesClick={cardInteractions.subprocessesClick(
                        obj
                    )}
                />
            </Styled>
        )
    }
)

const Styled = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    display: grid;
    grid-template: auto auto / 1fr auto;

    > footer {
        position: relative;
        z-index: 3;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
    }

    > div > header {
        display: flex;
        align-items: flex-start;
    }

    &[data-completed='true'] {
        border-color: ${({ theme }) => theme.palette.support['02'].normal};
    }
`

const StyledContent = styled.button`
    position: relative;
    z-index: 1;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    padding: 0;
    text-align: left;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: 0.25rem 0.25rem 0 0;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    h4 {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    }
`

export { Loaded }
