import { Item } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { Icon, IconName } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'

type Props = {
    text: string
    onSelect: (e: Event) => void
    disabled?: boolean
    closeOnSelect?: boolean
    icon?: IconName
    title?: string
    className?: string
    selected?: boolean
}

const DropdownNavButton: FC<Props> = ({
    text,
    onSelect,
    closeOnSelect = true,
    disabled = false,
    icon,
    title,
    className = '',
    selected = false,
}) => {
    const { variant } = useColorVariant()

    return (
        <StyledItem
            className={className}
            $variant={variant}
            onSelect={e => {
                if (!closeOnSelect) e.preventDefault()
                onSelect(e)
            }}
            disabled={disabled}
            title={title}
            data-selected={selected}
        >
            {icon ? <Icon name={icon} /> : null}
            <span className="text">{text}</span>
            <Icon name="CarrotRight" />
        </StyledItem>
    )
}

const StyledItem = styled(Item)<{ $variant: ColorVariant }>`
    ${({ $variant }) => applyColorStyles($variant)};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.25rem;
    cursor: pointer;

    > .text {
        ${regular5}
        flex: 1;
    }
`

const applyColorStyles = (variant: ColorVariant) =>
    variant === 'light'
        ? css`
              background-color: ${({ theme }) => theme.palette.ui['01'].normal};
              color: ${({ theme }) => theme.palette.text['02'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['01'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['01'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['02'].normal};
                  }
              }

              .icon:last-child {
                  opacity: 0;
              }

              &[data-selected='true'] {
                  color: ${({ theme }) => theme.palette.text['01'].normal};
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['01'].normal};
                      }
                  }
                  .icon:last-child {
                      opacity: 1;
                  }
              }
          `
        : css`
              background-color: ${({ theme }) => theme.palette.ui['07'].normal};
              color: ${({ theme }) => theme.palette.text['03'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['07'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['07'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['02'].normal};
                  }
              }

              .icon:last-child {
                  opacity: 0;
              }

              &[data-selected='true'] {
                  color: ${({ theme }) => theme.palette.text['04'].normal};
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['04'].normal};
                      }
                  }
                  .icon:last-child {
                      opacity: 1;
                  }
              }
          `

export { DropdownNavButton }
