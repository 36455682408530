import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

type Props = {
    text: string
}

const ShortText: FC<Props> = ({ text }) => (
    <Tooltip
        content={
            <StyledTooltip>
                <Text as="p" variant="regular-4">
                    {text}
                </Text>
            </StyledTooltip>
        }
    >
        <Styled>{text}</Styled>
    </Tooltip>
)

const Styled = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: nowrap;
    padding: 0.5rem 0;
`

const StyledTooltip = styled.div`
    width: fit-content;
    max-width: min(80vw, 27rem);
    text-align: left;
`

export { ShortText }
