import { styled } from 'Adapters/Freestyled'
import { ProgressSummary } from 'Features/SingleBusinessObjectTabs/Widgets'
import { RecentUpdates } from 'Features/SingleBusinessObjectTabs/Widgets/RecentUpdates/RecentUpdates'
import { FC } from 'react'
import Masonry from 'react-masonry-css'
import { BusinessObjectInProcessOverviewQuery } from '../__generated__/query'
import { useCurrentPhaseProgress } from '../useCurrentPhaseProgress'
import { OverviewHeader } from './Header'

type Props = {
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    process: BusinessObjectInProcessOverviewQuery['process']
    phaseIndex: number
    refetch: () => void
    onRemoveFromProcess: () => void
}
const Large: FC<Props> = ({
    businessObject,
    onRemoveFromProcess,
    phaseIndex,
    process,
    refetch,
}) => {
    const { percentageComplete, actions, loading } = useCurrentPhaseProgress({
        businessObjectId: businessObject.id,
        process,
        phaseIndex,
    })

    return (
        <StyledOverview>
            <OverviewHeader
                businessObjectId={businessObject.id}
                processId={process.id}
                onRemoveFromProcess={onRemoveFromProcess}
                isTerminalPhase={phaseIndex === process.phases.length - 1}
                percentageComplete={percentageComplete}
            />

            <ProgressSummary
                process={process}
                businessObjectPhaseIndex={phaseIndex}
                businessObject={businessObject}
                onProgressChange={refetch}
                percentageComplete={percentageComplete}
                percentageCompleteLoading={loading}
                actions={actions}
            />

            <StyledMasonry
                breakpointCols={breakpointColumns}
                columnClassName="masonry-column"
                className="" //unused but a required prop
            >
                <RecentUpdates businessObjectId={businessObject.id} />
            </StyledMasonry>
        </StyledOverview>
    )
}

const StyledOverview = styled.div`
    padding: 2rem;
    flex: 1;
    overflow: auto;
`

const breakpointColumns = {
    default: 2,
    1250: 1,
}

const StyledMasonry = styled(Masonry)`
    display: flex;
    gap: 2rem;
    width: auto;
    padding-top: 2rem;

    .masonry-column {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &::after {
            content: '';
            display: block;
            border-bottom: 1px solid transparent;
        }
    }
`

export { Large }
