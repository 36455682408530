import { StyledText } from './styles'
import { Props } from './types'

export const Text: React.FC<Props> = ({
    as,
    variant,
    children,
    strikethrough = false,
    underline = false,
    className = '',
    id = '',
    htmlFor,
}) => (
    <StyledText
        className={`${variant} ${className}`}
        as={as}
        strikethrough={strikethrough}
        underline={underline}
        id={id}
        htmlFor={htmlFor}
    >
        {children}
    </StyledText>
)
