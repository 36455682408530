import {
    NovuProvider,
    PopoverNotificationCenter,
} from '@novu/notification-center'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { Bell } from './Bell'
import { useNotificationCentre_CurrentUserIdQuery } from './__generated__/query'

const { VITE_NOVU_APPLICATION_IDENTIFIER } = import.meta.env

type Props = {
    onOtherWorkspaceNotificationClick: () => void
}

export const NotificationCentre: FC<Props> = ({
    onOtherWorkspaceNotificationClick,
}) => {
    const { data } = useNotificationCentre_CurrentUserIdQuery()

    const navigate = useNavigate()

    const navigateToUrl = useCallback(
        (url: string | undefined) => {
            if (!url) return
            navigate(url)
        },
        [navigate]
    )

    if (!data?.currentUser) return null

    return (
        <NovuProvider
            subscriberId={data.currentUser.id}
            applicationIdentifier={VITE_NOVU_APPLICATION_IDENTIFIER ?? ''}
            initialFetchingStrategy={{
                fetchNotifications: true,
                fetchUserPreferences: true,
            }}
        >
            <PopoverNotificationCenter
                colorScheme="light"
                onNotificationClick={({
                    payload,
                    cta: {
                        data: { url },
                    },
                }) => {
                    match(payload)
                        .with(
                            {
                                tenantId: P.not(
                                    data.currentUser.activeTenant?.tenant.id
                                ),
                            },
                            () => {
                                onOtherWorkspaceNotificationClick()
                            }
                        )
                        .with(
                            {
                                tenantId: P.nullish,
                            },
                            () => {
                                navigateToUrl(url)
                            }
                        )
                        .otherwise(() => {
                            navigateToUrl(url)
                        })
                }}
            >
                {({ unseenCount }) => <Bell unseenCount={unseenCount} />}
            </PopoverNotificationCenter>
        </NovuProvider>
    )
}
