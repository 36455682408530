import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { useRelationIdToLabelMap } from '../Phase/useRelationIdToLabelMap'
import { useUsersToNamesMap } from '../Phase/useUsersToNamesMap'
import { Conditions, FieldDefDictionary } from '../types'
import { generateConditionStrings } from './helpers'

type Props = {
    conditions: Conditions
    fieldDefById: FieldDefDictionary
}

export const CriterionConditions: FC<Props> = ({
    conditions,
    fieldDefById,
}) => {
    const { usersToNamesMap } = useUsersToNamesMap()

    const { relationIdToLabelMap } = useRelationIdToLabelMap({
        conditions,
        fieldDefById,
    })

    return (
        <StyledCriterionConditions>
            {generateConditionStrings(conditions, fieldDefById, {
                ...usersToNamesMap,
                ...relationIdToLabelMap,
            }).map(node => node)}
        </StyledCriterionConditions>
    )
}

const StyledCriterionConditions = styled.ul`
    padding-top: 0.25rem;

    li {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-left: 1rem;
    }
`
