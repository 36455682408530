import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { Avatar } from 'Components/Avatar'
import { IconTextButton } from 'Components/Button'
import { isImageFileType } from 'Components/Documents'
import { FileIcon } from 'Components/FileIcon'
import { Text } from 'Components/Text'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import * as principal from 'Features/Principal'
import { formatBytes, formatDate } from 'Utils'
import { FC, useCallback } from 'react'
import { SingleDocumentOverflow } from './Overflow'
import { SingleDocumentQuery } from './__generated__/SingleDocument'

type Props = {
    file: SingleDocumentQuery['file']
}

const DocDisplay: FC<Props> = ({ file }) => {
    const { open, setOpen, triggerToast } = useTriggerToast()
    const handleDownload = useCallback(() => {
        try {
            window.open(file.url, '_blank')
        } catch (err) {
            const msg = 'Failed to download file'
            logger.error(msg, new Error(msg), { file })
            triggerToast()
        }
    }, [file, triggerToast])

    return (
        <Styled>
            <header>
                <div>
                    <FileIcon name={file.fileType} />
                    <Text as="h2" variant="bold-1">
                        {file.name}
                    </Text>
                </div>
                <SingleDocumentOverflow file={file} />
            </header>
            <div>
                <Cell>
                    <Text as="label" variant="bold-4">
                        File type:
                    </Text>
                </Cell>

                <Cell>
                    <FileIcon name={file.fileType} />
                    <Text as="p" variant="regular-4">
                        {file.fileType}
                    </Text>
                </Cell>
                <Cell>
                    <Text as="label" variant="bold-4">
                        Size:
                    </Text>
                </Cell>
                <Cell>
                    <Text as="p" variant="regular-4">
                        {formatBytes(file.sizeInBytes)}
                    </Text>
                </Cell>
                <Cell>
                    <Text as="label" variant="bold-4">
                        Added by:
                    </Text>
                </Cell>
                <Cell>
                    <Avatar name={principal.helpers.name(file.uploadedBy)} />
                    <Text as="p" variant="regular-4">
                        {principal.helpers.name(file.uploadedBy)}
                    </Text>
                </Cell>
                <Cell data-last>
                    <Text as="label" variant="bold-4">
                        Uploaded on:
                    </Text>
                </Cell>
                <Cell data-last>
                    <Text as="p" variant="regular-4">
                        {formatDate(file.uploadedAt)}
                    </Text>
                </Cell>
            </div>
            {isImageFileType(file.fileType) && (
                <ImgPreview>
                    <img
                        src={file.url}
                        alt={file.name}
                        width="800"
                        height="600"
                    />
                </ImgPreview>
            )}
            <footer>
                <IconTextButton
                    variant="primary"
                    size="medium"
                    icon="ArrowDown"
                    text="Download"
                    onClick={handleDownload}
                />
            </footer>
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to download file. Please try again."
            />
        </Styled>
    )
}

const Styled = styled.div`
    padding: 0 3rem;
    max-width: 50rem;
    margin: 0 auto;
    overflow: auto;

    > header {
        padding: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    > header > div:first-child {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    > div {
        display: grid;
        grid-template: auto auto / auto 1fr;
    }

    img {
        width: 100%;
        height: auto;
    }

    > footer {
        width: 100%;
        padding: 2rem 0;
        display: flex;
        justify-content: center;
    }
`

const Cell = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 1rem 1rem 1rem 0;
    gap: 0.5rem;

    &:not([data-last='true']) {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const ImgPreview = styled.div`
    display: block;
    padding-top: 2rem;
`

export { DocDisplay }
