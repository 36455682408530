import { useAuth0 } from '@auth0/auth0-react'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CreateUserAndTenantInput } from '__generated__'

/*
 * Handles form state + initially setting from invitation info on token
 */

export const useUserDetails = () => {
    const { getIdTokenClaims } = useAuth0()

    const [userDetails, setUserDetails] = useState<CreateUserAndTenantInput>({
        name: '',
        companyName: '',
        identityUserId: '',
        email: '',
        termsAccepted: dayjs().toISOString(),
    })

    const setDetailsFromToken = useCallback(async () => {
        const token = await getIdTokenClaims()
        const identityUserId = token?.sub
        const email = token?.email
        if (identityUserId && email)
            setUserDetails({
                name: '',
                companyName: '',
                identityUserId,
                email,
                termsAccepted: dayjs().toISOString(),
            })
    }, [getIdTokenClaims])

    useEffect(() => {
        setDetailsFromToken()
    }, [setDetailsFromToken])

    const formIncomplete = useMemo(
        () => Object.values(userDetails).some(field => !field),
        [userDetails]
    )

    const setName = useCallback(
        (name: string) => setUserDetails(p => ({ ...p, name })),
        []
    )
    const setCompanyName = useCallback(
        (companyName: string) => setUserDetails(p => ({ ...p, companyName })),
        []
    )

    return { formIncomplete, userDetails, setName, setCompanyName }
}
