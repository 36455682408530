import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FieldType } from '__generated__'
import { FC } from 'react'
import { FieldSelection } from './FieldSelection'

type Props = {
    fields: {
        id: string
        label: string
        type: FieldType
    }[]
    selected: string[]
    onSelect: (id: string) => void
    onDone: () => void
    className?: string
}

const CardSettingsControl: FC<Props> = ({
    fields,
    selected,
    onSelect,
    onDone,
    className = '',
}) => (
    <Styled className={className}>
        <section>
            <header>
                <Text as="h5" variant="bold-6">
                    Display fields
                </Text>
            </header>
            <FieldSelection
                fields={fields}
                selected={selected}
                onSelect={onSelect}
            />
        </section>
        <footer>
            <IconTextButton
                icon="Check"
                text="Done"
                onClick={onDone}
                size="xSmall"
                variant="primary"
            />
        </footer>
    </Styled>
)

const Styled = styled.div`
    section header {
        padding: 0.25rem 0.5rem;
    }

    section header h5 {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    section:not(:first-child) {
        padding-top: 1rem;
    }

    footer {
        padding-top: 0.75rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    footer button {
        width: 100%;
    }
`

export { CardSettingsControl }
