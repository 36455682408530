import * as Types from '../../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteProcessMutationVariables = Types.Exact<{
  input: Types.DeleteProcessInput;
}>;


export type DeleteProcessMutation = { __typename?: 'Mutation', deleteProcess: boolean };


export const DeleteProcessDocument = gql`
    mutation DeleteProcess($input: DeleteProcessInput!) {
  deleteProcess(input: $input)
}
    `;
export type DeleteProcessMutationFn = Apollo.MutationFunction<DeleteProcessMutation, DeleteProcessMutationVariables>;

/**
 * __useDeleteProcessMutation__
 *
 * To run a mutation, you first call `useDeleteProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProcessMutation, { data, loading, error }] = useDeleteProcessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProcessMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProcessMutation, DeleteProcessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProcessMutation, DeleteProcessMutationVariables>(DeleteProcessDocument, options);
      }
export type DeleteProcessMutationHookResult = ReturnType<typeof useDeleteProcessMutation>;
export type DeleteProcessMutationResult = Apollo.MutationResult<DeleteProcessMutation>;
export type DeleteProcessMutationOptions = Apollo.BaseMutationOptions<DeleteProcessMutation, DeleteProcessMutationVariables>;