import * as principal from 'Features/Principal'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { FieldUpdatedEntries } from './FieldUpdatedEntries'
import {
    AddedToProcessHistoryEntry,
    CreatedHistoryEntry,
    CriterionToggledHistoryEntry,
    EnteredPhaseHistoryEntry,
    RemovedFromProcessHistoryEntry,
} from './HistoryEntry'
import { BusinessObjectHistoryQuery } from './__generated__/BusinessObjectHistory'

type Props = {
    businessObjectHistory: BusinessObjectHistoryQuery['businessObjectHistory']
}

export const HistoryEntryTimeline: FC<Props> = ({ businessObjectHistory }) => {
    return (
        <>
            {businessObjectHistory.map(entry =>
                match(entry)
                    .with(
                        {
                            __typename: 'BusinessObjectCreatedHistoryEntry',
                        },
                        ({ createdBy, createdAt, id }) => (
                            <CreatedHistoryEntry
                                key={id}
                                userName={principal.helpers.name(createdBy)}
                                timestamp={createdAt}
                            />
                        )
                    )
                    .with(
                        {
                            __typename:
                                'BusinessObjectAddedToProcessHistoryEntry',
                        },
                        ({
                            id,
                            createdBy,
                            createdAt,
                            payload: { process },
                        }) => (
                            <AddedToProcessHistoryEntry
                                key={id}
                                userName={principal.helpers.name(createdBy)}
                                timestamp={createdAt}
                                processName={process.name}
                            />
                        )
                    )
                    .with(
                        {
                            __typename:
                                'BusinessObjectEnteredPhaseHistoryEntry',
                        },
                        ({
                            id,
                            createdBy,
                            createdAt,
                            payload: { process, phaseIndex },
                        }) => (
                            <EnteredPhaseHistoryEntry
                                key={id}
                                processName={process.name}
                                phaseName={process.phases[phaseIndex].name}
                                userName={principal.helpers.name(createdBy)}
                                timestamp={createdAt}
                            />
                        )
                    )
                    .with(
                        {
                            __typename:
                                'BusinessObjectRemovedFromProcessHistoryEntry',
                        },
                        ({
                            id,
                            createdBy,
                            createdAt,
                            payload: { process },
                        }) => (
                            <RemovedFromProcessHistoryEntry
                                key={id}
                                processName={process.name}
                                userName={principal.helpers.name(createdBy)}
                                timestamp={createdAt}
                            />
                        )
                    )
                    .with(
                        {
                            __typename: 'PhaseCriterionToggledHistoryEntry',
                        },
                        ({
                            id,
                            createdBy,
                            createdAt,
                            payload: { process, action, toPhase },
                        }) => (
                            <CriterionToggledHistoryEntry
                                key={id}
                                processName={process.name}
                                userName={principal.helpers.name(createdBy)}
                                timestamp={createdAt}
                                actionName={action.name}
                                toPhase={toPhase}
                            />
                        )
                    )
                    .with(
                        {
                            __typename:
                                'BusinessObjectFieldUpdatedHistoryEntry',
                        },
                        ({ createdAt, createdBy, payload, id }) => {
                            return payload.__typename ===
                                'BusinessObjectUpdatesFieldUpdate' ? null : (
                                <FieldUpdatedEntries
                                    createdAt={createdAt}
                                    createdByName={principal.helpers.name(
                                        createdBy
                                    )}
                                    fieldDefinitionName={
                                        payload.fieldDefinition.name
                                    }
                                    id={id}
                                    payload={payload}
                                    key={id}
                                />
                            )
                        }
                    )
                    .exhaustive()
            )}
        </>
    )
}
