import processes from 'Assets/images/process-screenshot.png'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { FeatureName } from 'Features/FeatureName'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    isLarge: boolean
}

export const Completed: FC<Props> = ({ isLarge }) => {
    const navigate = useNavigate()

    const handler = useCallback(() => {
        if (isLarge) {
            navigate(`/manage-workspace${isLarge ? '' : '/model'}/processes`)
        } else {
            navigate('/processes')
        }
    }, [isLarge, navigate])

    return (
        <EmptyStateCard
            title="Your workspace is ready!"
            paragraphs={[
                'Amazing work, your new workspace has been set up for you!',
                isLarge ? (
                    <>
                        You can use the main menu on the left to start
                        exploring, but we'd suggest starting by taking a look at
                        your new <FeatureName>workflows</FeatureName> and making
                        sure they fit your needs perfectly.
                    </>
                ) : (
                    <>
                        You can use the main menu at the bottom of the screen to
                        start exploring, or start running the{' '}
                        <FeatureName>workflows</FeatureName> you just created
                        from a template.
                    </>
                ),
            ]}
            imageUrl={processes}
            button={[
                {
                    text: isLarge ? 'View my workflows' : 'Run my workflows',
                    handler,
                    icon: 'ArrowRight',
                },
            ]}
        />
    )
}
