export const areas = {
    processes: {
        text: 'Workflows',
        icon: 'Process' as const,
        path: 'processes',
    },
    datasets: {
        text: 'Datasets',
        icon: 'Cube' as const,
        path: 'datasets',
    },
    actions: {
        text: 'Actions',
        icon: 'CircleCheck' as const,
        path: 'actions',
    },
}
