import { Label } from '@radix-ui/react-dropdown-menu'
import { styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { bold7 } from 'Components/Text'
import { FC } from 'react'

type Props = {
    text: string
}

export const DropdownLabel: FC<Props> = ({ text }) => {
    const { variant } = useColorVariant()

    return <StyledLabel $variant={variant}>{text}</StyledLabel>
}

const StyledLabel = styled(Label)<{ $variant: ColorVariant }>`
    ${bold7}
    padding: 0.75rem 0 0.25rem 0.75rem;
    color: ${({ theme, $variant }) =>
        $variant === 'light'
            ? theme.palette.text['02'].normal
            : theme.palette.text['03'].normal};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing['01']};
`
