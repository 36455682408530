import { flex, styled } from 'Adapters/Freestyled'
import { Toggle } from 'Components/Input'
import { Text } from 'Components/Text'
import { State } from '../types'
import { Field } from './Field'
import { StyledFormSection } from './Field/styles'
import { TextField } from './TextField'

const basicFields = [
    {
        name: 'name',
        label: 'Name',
        required: true,
    },
    {
        name: 'label',
        label: 'Label',
        required: true,
    },
    { name: 'description', label: 'Description', required: false },
] satisfies Array<{
    name: BasicsFields
    label: string
    required: boolean
}>

type BasicsFields = 'name' | 'description' | 'label'

export type Props = {
    state: Pick<State, BasicsFields>
    setState: <T extends BasicsFields>(prop: T) => (value: State[T]) => void
    updatesEnabled: boolean
    setUpdatesEnabled: (enabled: boolean) => void
    documentsEnabled: boolean
    setDocumentsEnabled: (enabled: boolean) => void
    extensionOf?: 'action'
}

export const CreateBusinessObjectDefBasics: React.FC<Props> = ({
    state,
    setState,
    updatesEnabled,
    setUpdatesEnabled,
    documentsEnabled,
    setDocumentsEnabled,
    extensionOf,
}) => {
    const setStateFromEvent =
        (prop: BasicsFields): React.ChangeEventHandler<HTMLInputElement> =>
        e => {
            setState(prop)(e.target.value)
        }

    return (
        <>
            <StyledHeader>
                <Text as="h2" variant="bold-1">
                    The basics
                </Text>

                {!extensionOf ? (
                    <Text as="p" variant="regular-4">
                        All datasets have a <strong>name</strong>,{' '}
                        <strong>label</strong> and optional{' '}
                        <strong>description</strong>.
                    </Text>
                ) : null}
            </StyledHeader>

            <StyledFormSection>
                {extensionOf
                    ? null
                    : basicFields.map(({ name, label, required }) => (
                          <TextField
                              name={name}
                              label={label}
                              key={`basics-field-${name}`}
                              onChange={setStateFromEvent(name)}
                              value={state[name] ?? ''}
                              required={required}
                              maxLength={name === 'name' ? 25 : undefined}
                          />
                      ))}

                <Field htmlFor="bod-updates-enabled" name="Enable updates">
                    <Toggle
                        name="bod-updates-enabled"
                        id="bod-updates-enabled"
                        indicatorText={['Enabled', 'Not enabled']}
                        checked={updatesEnabled}
                        onCheckedChange={setUpdatesEnabled}
                    />
                </Field>

                <Field htmlFor="bod-documents-enabled" name="Enable documents">
                    <Toggle
                        name="bod-documents-enabled"
                        id="bod-documents-enabled"
                        indicatorText={['Enabled', 'Not enabled']}
                        checked={documentsEnabled}
                        onCheckedChange={setDocumentsEnabled}
                    />
                </Field>
            </StyledFormSection>
        </>
    )
}

const StyledHeader = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.25rem;
    padding-bottom: 0.5rem;

    p {
        a {
            color: ${({ theme }) => theme.palette.text.support03.normal};
            text-decoration: underline;
        }
    }
`
