import { useAuthenticate } from 'Adapters/Authentication/useAuthenticate'
import { flex, styled } from 'Adapters/Freestyled'
import { LoadingDisplay } from 'Components/LoadingDisplay'
import { Text } from 'Components/Text'
import { useSandbox } from 'Hooks/useSandbox'
import { always } from 'lodash/fp'
import { FC, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { match } from 'ts-pattern'

export const NotInSandbox: FC<{ children: ReactElement }> = ({ children }) => {
    const sandbox = useSandbox()
    const { signup } = useAuthenticate()
    const { pathname } = useLocation()

    return match(sandbox)
        .with({ state: 'active' }, sandbox => (
            <Styled>
                <Text as="h2" variant="bold-2">
                    This feature is disabled in sandbox workspaces.
                    <LinkButton
                        onClick={() =>
                            signup({
                                returnTo: `/sandbox-signup?id=${sandbox.user.id}&token=${sandbox.user.token}&pathname=${pathname}`,
                            })
                        }
                    >
                        Register
                    </LinkButton>
                    to save your work
                </Text>
            </Styled>
        ))
        .with({ state: 'pending' }, () => <LoadingDisplay />)
        .with({ state: 'disabled' }, always(children))
        .exhaustive()
}

export const Styled = styled.div`
    ${flex('column', 'center', 'center')};
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    padding: ${({ theme }) => theme.spacing['07']};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: 100%;

    h2 {
        text-align: center;
        padding-bottom: 0.5rem;
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const LinkButton = styled.button`
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.text.support05.normal};

    &:hover {
        color: ${({ theme }) => theme.palette.text.support05.hover};
    }

    &:active {
        color: ${({ theme }) => theme.palette.text.support05.active};
    }
`
