import {
    findNode,
    getPluginType,
    useEditorRef,
    useEditorSelection,
} from '@udecode/plate-common'
import {
    ELEMENT_LINK,
    TLinkElement,
    getLinkAttributes,
} from '@udecode/plate-link'
import { IconButton } from 'Components/Button'
import { forwardRef, useMemo } from 'react'

export const ExternalLinkButton = forwardRef<HTMLButtonElement>((_, ref) => {
    const editor = useEditorRef()
    const selection = useEditorSelection()

    const entry = useMemo(
        () =>
            findNode<TLinkElement>(editor, {
                match: { type: getPluginType(editor, ELEMENT_LINK) },
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor, selection]
    )

    if (!entry) return null

    const [element] = entry

    const linkAttributes = getLinkAttributes(editor, element)

    return (
        <IconButton
            iconName={'External'}
            title="Visit"
            onClick={() => {
                window.open(linkAttributes.href, '_blank')
            }}
            type="button"
            ref={ref}
        />
    )
})
