import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {}

const Loading: FC<Props> = () => {
    return (
        <Styled>
            <StyledMore iconName="More" disabled />
            <StyledContent>
                <header>
                    <Text as="h4" variant="bold-4">
                        Loading
                    </Text>
                </header>
                <ul></ul>
            </StyledContent>
            <footer>
                <IconButton iconName="AngleLeft" variant="secondary" disabled />
                <IconTextButton
                    icon="List"
                    variant="secondary"
                    text={`_/_`}
                    size="xSmall"
                    disabled
                />
                <IconTextButton
                    icon="CircleCheck"
                    variant="secondary"
                    text={`_/_`}
                    size="xSmall"
                    disabled
                />
                <IconButton
                    iconName="AngleRight"
                    variant="secondary"
                    disabled
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.ui['04'].normal};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    display: grid;
    grid-template: auto auto / 1fr auto;
    opacity: 0.5;
    pointer-events: none;

    > footer {
        position: relative;
        z-index: 3;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        border-radius: 0 0 0.25rem 0.25rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    }

    > div > header {
        display: flex;
        align-items: flex-start;
    }
`

const StyledMore = styled(IconButton)`
    position: relative;
    z-index: 2;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
`

const StyledContent = styled.div`
    position: relative;
    z-index: 1;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    padding: 0;
    text-align: left;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: 0.25rem 0.25rem 0 0;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    h4 {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > ul {
        padding: 0.25rem;
    }
`

export { Loading }
