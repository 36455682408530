import { TextAreaInput, TextInput } from 'Components/Input'
import { BOField, BizObjDef_Text } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useTextConstraints } from './useTextConstraints'

type Props = BOField<'BusinessObjectTextField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const TextField: FC<Props> = ({
    businessObjectId,
    textValue,
    fieldDefinition,
    onComplete,
}) => {
    const { textConstraints, ...common } = fieldDefinition as BizObjDef_Text

    const { constraints, constraintsTooltip } = useTextConstraints({
        textConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialValue = useRef(textValue ?? '')

    const [value, setValue] = useState(textValue ?? '')

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            {constraints.max && constraints.max <= 70 ? (
                <TextInput
                    value={value}
                    name={common.type + '||' + common.id}
                    id={common.id}
                    hasError={!!errors.length}
                    onChange={e => {
                        setValue(e.target.value)
                    }}
                    required={constraints.required}
                />
            ) : (
                <TextAreaInput
                    value={value}
                    name={common.type + '||' + common.id}
                    id={common.id}
                    hasError={!!errors.length}
                    onChange={e => {
                        setValue(e.target.value)
                    }}
                    required={constraints.required}
                />
            )}

            <EditFieldButton
                disabled={initialValue.current === value || loading}
                onClick={() => {
                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue: initialValue.current
                                    ? initialValue.current
                                    : undefined,
                                value: value.length ? value : null,
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialValue.current = value
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}
