import { dayjs } from 'Adapters/DayJS'
import { compact } from 'lodash'
import { UpdateActionForm } from './useForm'

type UpdateActionValidator = (data: UpdateActionForm) => string | null

const validations = [
    ({ name }) => (name && name.length > 0 ? null : 'name'),
    ({ dueDate }) => {
        if (!dueDate) return null
        const startOfToday = dayjs().startOf('day').toISOString()
        return dueDate < startOfToday ? 'dueDate' : null
    },
] satisfies Array<UpdateActionValidator>

export const validateForm = (data: UpdateActionForm) => {
    const errors = compact(validations.map(v => v(data)))

    return new Set(errors)
}
