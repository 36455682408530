import * as RadixPopover from '@radix-ui/react-popover'
import { FC } from 'react'
import { flex, styled } from '../../Adapters/Freestyled'
import { getPortalsContainer } from '../../Utils'

type Props = {
    trigger: React.ReactNode
    children: React.ReactNode
}

export const Popover: FC<Props> = ({ trigger, children }) => {
    return (
        <StyledRoot>
            <StyledTrigger>{trigger}</StyledTrigger>
            <RadixPopover.Portal container={getPortalsContainer()}>
                <StyledContent>
                    {children}
                    <StyledArrow />
                </StyledContent>
            </RadixPopover.Portal>
        </StyledRoot>
    )
}

const StyledTrigger = styled(RadixPopover.Trigger)`
    ${flex('row', 'center', 'center')};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};

    .icon path {
        fill: ${({ theme }) => theme.palette.icon['01'].normal};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    &:active,
    &:focus-visible {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    }

    border-radius: ${({ theme }) => theme.borderRadius.small};
    cursor: pointer;
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    padding: 0.5rem;
    border: none;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        box-shadow: inset 0 0 0 0.125rem
            ${({ theme }) => theme.palette.brand['01'].normal};
    }

    &:disabled path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    svg {
        min-height: 1.25rem;
        min-width: 1.25rem;
        max-height: 1.25rem;
        max-width: 1.25rem;
    }
`

const StyledContent = styled(RadixPopover.Content)`
    border-radius: 0.1rem;
    padding: 1rem;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &:focus {
        box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
            hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px var(--violet-7);
    }

    &[data-state='open'][data-side='top'] {
        animation-name: slideDownAndFade;
    }

    &[data-state='open'][data-side='right'] {
        animation-name: slideLeftAndFade;
    }

    &[data-state='open'][data-side='bottom'] {
        animation-name: slideUpAndFade;
    }

    &[data-state='open'][data-side='left'] {
        animation-name: slideRightAndFade;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @keyframes slideUpAndFade {
        from {
            opacity: 0;
            transform: translateY(2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideRightAndFade {
        from {
            opacity: 0;
            transform: translateX(-2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes slideDownAndFade {
        from {
            opacity: 0;
            transform: translateY(-2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideLeftAndFade {
        from {
            opacity: 0;
            transform: translateX(2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`

const StyledArrow = styled(RadixPopover.Arrow)`
    fill: white;
`

const StyledRoot = styled(RadixPopover.Root)`
    .PopoverContent {
    }

    .PopoverContent:focus {
    }

    .PopoverClose {
        font-family: inherit;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: var(--violet-11);
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .PopoverClose:hover {
        background-color: var(--violet-4);
    }

    .PopoverClose:focus {
        box-shadow: 0 0 0 2px var(--violet-7);
    }
`
