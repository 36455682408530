import { Text } from 'Components/Text'
import { FC } from 'react'
import { BaseHistoryEntry } from './BaseHistoryEntry'

type Props = {
    userName: string
    timestamp: string
    processName: string
}

export const RemovedFromProcessHistoryEntry: FC<Props> = ({
    userName,
    timestamp,
    processName,
}) => {
    return (
        <BaseHistoryEntry userName={userName} timestamp={timestamp}>
            <Text as="p" variant="regular-5">
                <strong>{userName}</strong> removed this record from workflow{' '}
                <strong>{processName}</strong>
            </Text>
        </BaseHistoryEntry>
    )
}
