import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { ProcessSingle_CardQuery } from '../__generated__/q'
import { CompletionButton } from './CompletionButton'
import { NextPhase } from './NextPhase'
import { PrevPhase } from './PrevPhase'
import { useCriteriaCounts } from './useCriteriaCounts'

type Props = {
    obj: ProcessSingle_CardQuery['businessObject']
    processId: string
    onRequiredFieldsClick: (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => void
    onActionsClick: (actionIds: string[]) => void
    onSubprocessesClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const CardFooter: FC<Props> = ({
    obj,
    processId,
    onRequiredFieldsClick,
    onActionsClick,
    onSubprocessesClick,
}) => {
    const { fieldCriteria, actions, delegateProcesses, canMoveForward } =
        useCriteriaCounts(obj)

    return (
        <Styled data-completed={obj.onProcess?.isCompleted}>
            <PrevPhase
                businessObjectId={obj.id}
                processId={processId}
                disabled={obj.onProcess?.onPhase.phase.phaseIndex === 0}
                tootip={'Move to the previous phase'}
            />

            <CompletionButton
                tooltipLabel="required fields completed"
                icon="List"
                total={fieldCriteria.total}
                incomplete={fieldCriteria.incomplete}
                onClick={onRequiredFieldsClick}
            />

            <CompletionButton
                tooltipLabel="actions completed"
                icon="CircleCheck"
                total={actions.total}
                incomplete={actions.incomplete}
                overdue={actions.overdue}
                onClick={() => {
                    onActionsClick(actions.incompleteActionIds)
                }}
            />

            <CompletionButton
                tooltipLabel="sub-workflows completed"
                icon="Process"
                total={delegateProcesses.total}
                incomplete={delegateProcesses.incomplete}
                onClick={onSubprocessesClick}
            />

            <NextPhase
                businessObjectId={obj.id}
                processId={processId}
                disabled={!canMoveForward}
                tooltip={
                    canMoveForward
                        ? 'Move to the next phase'
                        : 'Complete all required fields and actions to move to the next phase'
                }
            />
        </Styled>
    )
}

const Styled = styled.footer`
    border-radius: 0 0 0.25rem 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};

    > button:disabled {
        opacity: 0.4;
    }

    &[data-completed='true'] {
        background-color: ${({ theme }) => theme.palette.support.bg02.normal};
        color: ${({ theme }) => theme.palette.text.support02.normal};

        > button:disabled,
        > button {
            background-color: ${({ theme }) =>
                theme.palette.support.bg02.normal};
        }

        > button:hover {
            background-color: ${({ theme }) =>
                theme.palette.support.bg02.hover};
        }

        > button:active {
            background-color: ${({ theme }) =>
                theme.palette.support.bg02.active};
        }

        > button .icon svg path {
            fill: ${({ theme }) => theme.palette.text.support02.normal};
        }

        > div {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
        }

        > div svg {
            height: 1rem;
            width: 1rem;
        }

        > div svg path {
            fill: ${({ theme }) => theme.palette.text.support02.normal};
        }
    }
`

export { CardFooter }
