import { ControlledModal } from 'Components/Modal'
import { FC } from 'react'
import { CreateActionModalData } from './CreateActionModalData'
import { RelatesTo } from './types'

type Props = {
    open: boolean
    closeModal: () => void
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    relatesTo?: RelatesTo
}

export const CreateActionModal: FC<Props> = ({
    onOpenChange,
    open,
    returnFocus,
    closeModal,
    relatesTo,
}) => {
    return (
        <ControlledModal
            title="Create action"
            description={
                'Create an item of work to be done and assign it to someone to complete'
            }
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
            renderContent={() => (
                <CreateActionModalData
                    closeModal={closeModal}
                    relatesTo={relatesTo}
                />
            )}
        />
    )
}
