import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { FieldRequiredTag } from 'Components/FieldRequiredTag'
import { FieldTypeTag } from 'Components/FieldTypeTag/FieldTypeTag'
import { Text } from 'Components/Text'
import { stopPropagation } from 'Utils'
import { Field, isFieldRequired } from 'Utils/BusinessObjectDefinition'
import { pipe } from 'lodash/fp'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import { Mode } from './types'

type Props = {
    field: Field
    index: number
    last: boolean
    isValid: boolean
    mode: Mode
    onEdit: () => void
    onMoveUp: () => void
    onMoveDown: () => void
    onRemove: () => void
}
const FieldCard: FC<Props> = ({
    field,
    index,
    last,
    isValid,
    mode,
    onEdit,
    onMoveDown,
    onMoveUp,
    onRemove,
}) => (
    <Styled
        key={`${field.name}-${index}`}
        data-valid={isValid}
        onClick={onEdit}
        className="field-card"
    >
        <div data-fieldname-unset={!field.name}>
            <Text as="p" variant="bold-4">
                {field.name || '[field name]'}
            </Text>

            <FieldTypeTag field={field} />
            <FieldRequiredTag isRequired={isFieldRequired(field)} />
        </div>
        <menu>
            {mode === 'create' && (
                <>
                    <IconButton
                        iconName="ArrowUp"
                        variant="secondary"
                        onClick={pipe(stopPropagation, onMoveUp)}
                        disabled={index === 0}
                    />
                    <IconButton
                        iconName="ArrowDown"
                        variant="secondary"
                        onClick={pipe(stopPropagation, onMoveDown)}
                        disabled={last}
                    />
                </>
            )}

            <IconButton
                iconName="Delete"
                variant="secondary"
                onClick={pipe(stopPropagation, onRemove)}
                disabled={mode === 'restrictedEdit'}
            />
        </menu>
    </Styled>
)

const enter = keyframes`
    0% {
        opacity: 0;
        transform: rotateX(-65deg);
    }
    100% {
        opacity: 100%;
        transform: rotateX(0);
    }
`

const Styled = styled.div`
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['04'].normal}20
    );
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    animation: ${enter} 0.4s ease;

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }

        &:hover button {
            filter: brightness(95%);
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    > menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    [data-fieldname-unset='true'] > p {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        opacity: 0.5;
    }

    &[data-valid='false'] {
        border-color: ${({ theme }) => theme.palette.support['01'].normal};
    }
`

export { FieldCard }
