import { Root, Thumb } from '@radix-ui/react-switch'
import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

type BaseProps = {
    disabled?: boolean
    required?: boolean
    id?: string
    name?: string
    onCheckedChange: (bool: boolean) => void
    indicatorText?: [string, string] // What gets shown when [true / false]
}

type ControlledProps = BaseProps & {
    defaultChecked?: never
    checked: boolean
}

type UncontrolledProps = BaseProps & {
    defaultChecked: boolean
    checked?: never
}

type Props = ControlledProps | UncontrolledProps

export const Toggle: FC<Props> = ({
    disabled,
    required,
    name,
    id,
    checked,
    defaultChecked,
    onCheckedChange,
    indicatorText = ['true', 'false'],
}) => (
    <StyledRoot
        disabled={disabled}
        id={id}
        name={name}
        required={required}
        onCheckedChange={onCheckedChange}
        checked={checked}
        defaultChecked={defaultChecked}
        className="toggle"
    >
        <span className="thumb-container">
            <StyledThumb />
        </span>

        <StyledIndicator className="on" aria-hidden="true">
            {indicatorText[0]}
        </StyledIndicator>

        <StyledIndicator className="off" aria-hidden="true">
            {indicatorText[1]}
        </StyledIndicator>
    </StyledRoot>
)

const StyledRoot = styled(Root)`
    all: unset;
    cursor: pointer;
    display: grid;
    grid-template: auto / auto auto;
    align-items: center;
    gap: 0.5rem;

    .thumb-container {
        padding-right: 1rem;
        background-color: ${({ theme }) => theme.palette.ui['05'].normal};
        display: inline-block;
        border-radius: 2rem;
        border: 1px solid ${({ theme }) => theme.palette.ui['05'].normal};
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        transition: background-color ease 0.2s, border-color ease 0.2s;
    }

    @media (hover: hover) {
        &:hover .thumb-container {
            background-color: ${({ theme }) => theme.palette.ui['05'].hover};
        }
    }

    &:active .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['05'].active};
    }

    &:focus-visible .thumb-container {
        outline: 2px solid ${({ theme }) => theme.palette.brand['01'].normal};
        background-color: ${({ theme }) => theme.palette.ui['05'].active};
    }

    &[data-state='checked'] .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['08'].normal};
        border-color: ${({ theme }) => theme.palette.ui['08'].normal};
    }

    @media (hover: hover) {
        &[data-state='checked']:hover .thumb-container {
            background-color: ${({ theme }) => theme.palette.ui['08'].hover};
        }
    }

    &[data-state='checked']:active .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['08'].active};
    }

    &[data-state='checked']:focus-visible .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['08'].active};
    }

    &[data-state='checked'] .off {
        opacity: 0;
    }

    &[data-state='unchecked'] .on {
        opacity: 0;
    }

    &[data-disabled] {
        pointer-events: none;
    }

    &[data-disabled] .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        border-color: ${({ theme }) => theme.palette.ui['03'].normal};
    }

    &[data-disabled][data-state='checked'] .thumb-container {
        background-color: ${({ theme }) => theme.palette.ui['04'].normal};
        border-color: ${({ theme }) => theme.palette.ui['04'].normal};
    }
`

const StyledThumb = styled(Thumb)`
    height: 1.75rem;
    width: 1.75rem;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    display: block;
    border-radius: 50%;
    transition: transform ease 0.2s;

    &[data-state='checked'] {
        transform: translateX(1rem);
    }
`

const StyledIndicator = styled.span`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    transition: opacity ease 0.2s;

    &.off {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`
