import { useTriggerToast } from 'Components/Toast'
import { useCallback } from 'react'
import { useDeleteBusinessObjectMutation } from './__generated__/q'

type UseDelete = (_: { id: string; onDelete: () => void }) => {
    deleteBusinessObject: () => void
    toastOpen: boolean
    setToastOpen: (_: boolean) => void
}
const useDelete: UseDelete = ({ id, onDelete }) => {
    const {
        open: toastOpen,
        setOpen: setToastOpen,
        triggerToast,
    } = useTriggerToast()
    const [update] = useDeleteBusinessObjectMutation()
    const deleteBusinessObject = useCallback(() => {
        update({
            variables: { input: { id } },
            update: cache => {
                cache.evict({
                    id: cache.identify({
                        __typename: 'BusinessObject',
                        id,
                    }),
                })
            },
            onError: () => triggerToast(),
            onCompleted: () => onDelete(),
        })
    }, [id, update, onDelete, triggerToast])
    return {
        deleteBusinessObject,
        toastOpen,
        setToastOpen,
    }
}

export { useDelete }
