import { IconButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { RenameDocumentModal } from 'Features/RenameDocumentModal'
import { FC, useState } from 'react'
import { SingleDocumentQuery } from '../__generated__/SingleDocument'

type Props = {
    file: SingleDocumentQuery['file']
}

const SingleDocumentOverflow: FC<Props> = ({ file }) => {
    const [openRename, setRenameOpen] = useState(false)
    const [open, setOpen] = useState(false)

    return (
        <>
            <Dropdown
                variant="dark"
                open={open}
                onOpenChange={setOpen}
                renderOpenDropdownButton={() => (
                    <IconButton iconName="More"></IconButton>
                )}
                renderContent={() => (
                    <DropdownButton
                        text={'Rename document'}
                        onSelect={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            setOpen(false)
                            setRenameOpen(true)
                        }}
                        icon="Edit"
                    />
                )}
            />
            <RenameDocumentModal
                documentId={file.id}
                initialDocumentName={file.name}
                open={openRename}
                onOpenChange={setRenameOpen}
            />
        </>
    )
}

export { SingleDocumentOverflow }
