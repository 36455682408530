import { styled } from 'Adapters/Freestyled'
import { canFilterFieldType } from 'Components/ComparatorBuilderV2/useFilterableFields'
import { SearchBar } from 'Components/SearchBar'
import { regular4, regular6 } from 'Components/Text'
import { useSearchTerms } from 'Features/FilterBusinessObject'
import { fromEvent } from 'Utils'
import { FC, useMemo } from 'react'
import { FieldDefinitions } from '../types'
import { ApplyView } from './ApplyView'
import { EditViewMenu } from './EditViewMenu'
import { SaveView } from './SaveView'

type Props = {
    businessObjectDefinitionId: string
    fieldDefinitions: FieldDefinitions
}

export const FilterBarSmall: FC<Props> = ({
    businessObjectDefinitionId,
    fieldDefinitions,
}) => {
    const canFilter = useMemo(
        () =>
            fieldDefinitions.filter(field => canFilterFieldType(field.type))
                .length
                ? true
                : false,
        [fieldDefinitions]
    )

    const { searchTerms, setSearchTerms } = useSearchTerms()

    return (
        <StyledFilterBar>
            <div>
                <StyledSearchBar
                    searchTerms={searchTerms}
                    placeholderText={'Search records'}
                    updateSearchTerms={e => setSearchTerms(fromEvent(e))}
                    clearSearchTerms={() => setSearchTerms('')}
                />
            </div>
            <menu>
                <EditViewMenu
                    businessObjectDefinitionId={businessObjectDefinitionId}
                    canFilter={canFilter}
                    fieldDefinitions={fieldDefinitions}
                />

                <SaveView
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />

                <ApplyView
                    businessObjectDefinitionId={businessObjectDefinitionId}
                    fieldDefinitions={fieldDefinitions}
                />
            </menu>
        </StyledFilterBar>
    )
}

const StyledFilterBar = styled.div`
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};

    > div {
        padding: 0.5rem 4vw 0.25rem;
    }

    > menu {
        padding: 0.25rem 4vw 0.5rem;
        border-radius: 0.25rem;
        display: flex;
        justify-content: space-between;
    }
`

const StyledSearchBar = styled(SearchBar)`
    border: none;

    input {
        ${regular6};
    }

    .icon svg {
        min-height: 1em;
        min-width: 1em;
        max-height: 1em;
        max-width: 1em;
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        input {
            ${regular4}
        }
    }
`
