import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useMutationStatus } from 'Hooks'
import { compact, omit, uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { useScheduleActionMutation } from './__generated__/mutation'
import { RelatesTo } from './types'
import { CreateFormState } from './useForm'

export const useScheduleAction = ({
    extendedBy,
    relatesTo,
}: {
    extendedBy?: string
    relatesTo?: RelatesTo
}) => {
    const [schedule, result] = useScheduleActionMutation()

    const [mutationErrors, setMutationErrors] = useState<
        Record<string, string[]>
    >({})

    const scheduleAction = useCallback(
        ({
            input,
            onCompleted,
        }: {
            input: CreateFormState
            onCompleted?: () => void
        }) => {
            setMutationErrors({})

            const { schedule: scheduleInput } = input

            if (!scheduleInput) {
                // this is actually guarded by form validation
                throw new Error(`Cannot schedule action without schedule`)
            }

            schedule({
                variables: {
                    schedule: {
                        [scheduleInput.type]: omit(scheduleInput, 'type'),
                    },
                    input: {
                        name: input.name,
                        ...(input.assignedToId && {
                            assignedTo: input.assignedToId,
                        }),
                        ...(input.description && {
                            description: input.description,
                        }),
                        ...(input.dueDate && {
                            dueDate: input.dueDate,
                        }),
                        ...(relatesTo && {
                            relatesTo: {
                                ...(relatesTo.businessObjectId && {
                                    businessObject: {
                                        id: relatesTo.businessObjectId,
                                    },
                                }),
                            },
                        }),
                        ...(extendedBy && {
                            extension: {
                                businessObjectDefinitionId: extendedBy,
                                fields: compact(
                                    input.extendedBy.map(
                                        ({ field: { id, type }, value }) => {
                                            return value
                                                ? {
                                                      [type]: {
                                                          fieldDefinitionId: id,
                                                          value,
                                                      },
                                                  }
                                                : undefined
                                        }
                                    )
                                ),
                            },
                        }),
                    },
                },
                refetchQueries: ['Actions'],
                onCompleted,
                onError(error) {
                    logger.error('Failed to schedule action', error)

                    error.graphQLErrors.forEach(error => {
                        if (!hasCustomErrorExtenstions(error.extensions)) return

                        const errors: Record<string, string[]> = {}

                        Object.entries(error.extensions.errors.errors).forEach(
                            ([key, value]) => {
                                errors[key] = uniq(value)
                            }
                        )

                        setMutationErrors(errors)
                    })
                },
            })
        },
        [schedule, extendedBy, relatesTo]
    )

    const { status } = useMutationStatus(result)

    return {
        scheduleAction,
        mutationErrors,
        status,
        hasError: !!result.error,
    }
}
