import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { Criteria } from './Criteria'
import { PhaseHeader } from './Header'
import { PhaseDetails } from './PhaseDetails'

type Props = { index: number }

const Phase: FC<Props> = ({ index }) => (
    <Styled>
        <PhaseHeader index={index} />
        <div>
            <PhaseDetails index={index} />
            <Criteria phaseIndex={index} />
        </div>
    </Styled>
)

const Styled = styled.li`
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    display: flex;
    flex-direction: column;
    width: 20rem;
    flex-shrink: 0;

    > div {
        flex: 1;
        overflow: auto;
        padding-bottom: 0.5rem;
    }
`

export { Phase }
