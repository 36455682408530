import { IconTextButton } from 'Components/Button'
import {
    DocumentMetadata,
    PropsWithAllowExistingSelection,
    PropsWithDisallowExistingSelection,
    UploadModal,
} from 'Components/Documents'
import { ContactUsToast } from 'Components/Toast'
import { useUploadDocument } from 'Hooks'
import { FC, Ref } from 'react'
import { has } from '../../Utils'

const DefaultAnchor: FC<AnchorProps<{}>> = ({ buttonRef, onClick }) => (
    <IconTextButton
        icon={'Plus'}
        text={'Upload a new document'}
        ref={buttonRef}
        size="xSmall"
        onClick={onClick}
    />
)

export type AnchorProps<T = {}> = T & {
    buttonRef: Ref<HTMLButtonElement>
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

type Props = {
    onUploadCompleted: (document: DocumentMetadata) => void
    existingSelections:
        | PropsWithAllowExistingSelection
        | PropsWithDisallowExistingSelection
}

type PropsWithAnchor<T> = Props & {
    anchorProps?: T
    anchor: FC<AnchorProps<T>>
}

export const Upload = <T,>({
    onUploadCompleted,
    existingSelections,
    ...props
}: Props | PropsWithAnchor<T>) => {
    const {
        buttonRef,
        returnFocus,
        setUploadModalOpen,
        signature,
        createDocument,
        expire,
        uploadModalOpen,
        errorToastOpen,
        setErrorToastOpen,
    } = useUploadDocument({
        onComplete: data => {
            onUploadCompleted({
                ...data.createFile,
                addedOn: data.createFile.uploadedAt,
                addedBy: data.createFile.uploadedBy.id,
            })
        },
    })

    const uploadReady = !!signature && !!expire

    const Anchor = has(props, 'anchor') ? props.anchor : DefaultAnchor

    return (
        <>
            {uploadReady ? (
                <UploadModal
                    open={uploadModalOpen}
                    onOpenChange={setUploadModalOpen}
                    secureSignature={signature}
                    secureExpire={+expire}
                    onUploadsComplete={files => {
                        files.forEach(fileMetadata =>
                            createDocument(fileMetadata.name, fileMetadata.id)
                        )
                    }}
                    {...existingSelections}
                    returnFocus={returnFocus}
                />
            ) : null}

            <Anchor
                buttonRef={buttonRef}
                onClick={e => {
                    e.preventDefault()
                    setUploadModalOpen(true)
                }}
                {...((props as PropsWithAnchor<T>).anchorProps ?? ({} as T))}
            />

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'We were unable to upload your document. Contact us if the problem persists.'
                }
            />
        </>
    )
}
