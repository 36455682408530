import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessDomain_Visualise_ProcessesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BusinessDomain_Visualise_ProcessesQuery = { __typename?: 'Query', processes: Array<{ __typename?: 'Process', id: string, name: string, backstop?: { __typename?: 'Process', id: string, name: string } | null, phases: Array<{ __typename?: 'Phase', phaseIndex: number, name: string, criteria: Array<{ __typename: 'ActionPhaseCriterion', description: string } | { __typename: 'FieldConditionPhaseCriterion' } | { __typename: 'ProcessDelegatePhaseCriterion', process: { __typename?: 'Process', id: string, name: string } } | { __typename: 'ProcessFanoutPhaseCriterion', process: { __typename?: 'Process', id: string, name: string } }> }> }> };


export const BusinessDomain_Visualise_ProcessesDocument = gql`
    query BusinessDomain_Visualise_Processes {
  processes {
    id
    name
    backstop {
      id
      name
    }
    phases {
      phaseIndex
      name
      criteria {
        __typename
        ... on ActionPhaseCriterion {
          description
        }
        ... on ProcessDelegatePhaseCriterion {
          process {
            id
            name
          }
        }
        ... on ProcessFanoutPhaseCriterion {
          process {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBusinessDomain_Visualise_ProcessesQuery__
 *
 * To run a query within a React component, call `useBusinessDomain_Visualise_ProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDomain_Visualise_ProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDomain_Visualise_ProcessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessDomain_Visualise_ProcessesQuery(baseOptions?: Apollo.QueryHookOptions<BusinessDomain_Visualise_ProcessesQuery, BusinessDomain_Visualise_ProcessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessDomain_Visualise_ProcessesQuery, BusinessDomain_Visualise_ProcessesQueryVariables>(BusinessDomain_Visualise_ProcessesDocument, options);
      }
export function useBusinessDomain_Visualise_ProcessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessDomain_Visualise_ProcessesQuery, BusinessDomain_Visualise_ProcessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessDomain_Visualise_ProcessesQuery, BusinessDomain_Visualise_ProcessesQueryVariables>(BusinessDomain_Visualise_ProcessesDocument, options);
        }
export type BusinessDomain_Visualise_ProcessesQueryHookResult = ReturnType<typeof useBusinessDomain_Visualise_ProcessesQuery>;
export type BusinessDomain_Visualise_ProcessesLazyQueryHookResult = ReturnType<typeof useBusinessDomain_Visualise_ProcessesLazyQuery>;
export type BusinessDomain_Visualise_ProcessesQueryResult = Apollo.QueryResult<BusinessDomain_Visualise_ProcessesQuery, BusinessDomain_Visualise_ProcessesQueryVariables>;