import { useEffect, useRef, useState } from 'react'

/*
 * Returns an up to date DOMRect for the target element
 * Helps to ensures that the spotlight is positioned correctly when the element is rendered
 *
 * showSpotlight is passed to the effect dependency array to ensure that the targetRect is recalculated
 */
export const useSpotlight = (showSpotlight?: boolean) => {
    const targetRef = useRef<HTMLButtonElement>(null)

    const [targetRect, setTargetRect] = useState<DOMRect | null | undefined>()

    useEffect(() => {
        if (targetRect) return

        if (!targetRef.current) return

        setTargetRect(targetRef.current.getBoundingClientRect())
    }, [setTargetRect, targetRect, showSpotlight])

    return { targetRef, targetRect }
}
