import { useSandbox } from 'Hooks/useSandbox'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { AuthParams } from '../../Adapters/Authentication/useAuthenticate'
import { useResponder } from '../../Hooks'
import { DesktopLanding } from './Desktop'
import { MobileLanding } from './Mobile'
import { Sandbox } from './Sandbox'

export const Landing: FC<{ auth: AuthParams }> = ({ auth }) => {
    const sandbox = useSandbox()
    const { screenSize } = useResponder()
    const [params] = useSearchParams()

    const isInvitation = params.get('invitation') === 'true'
    const autoLogin = params.get('autologin') === 'true'

    return match({ screenSize, sandbox })
        .with({ sandbox: { state: 'active' } }, () => <Sandbox />)
        .with({ sandbox: { state: 'pending' } }, () => <Sandbox />)
        .with(
            {
                screenSize: 'small',
                sandbox: { state: 'disabled' },
            },
            ({ sandbox }) => (
                <MobileLanding
                    enterSandbox={sandbox.activate}
                    auth={auth}
                    isInvitation={isInvitation}
                    autoLogin={autoLogin}
                />
            )
        )
        .with(
            {
                sandbox: { state: 'disabled' },
            },
            ({ sandbox }) => (
                <DesktopLanding
                    enterSandbox={sandbox.activate}
                    auth={auth}
                    isInvitation={isInvitation}
                    autoLogin={autoLogin}
                />
            )
        )
        .exhaustive()
}
