import { Arrow, Content } from '@radix-ui/react-tooltip'
import { styled } from 'Adapters/Freestyled'
import { regular5 } from 'Components/Text'
import {
    ANIMATION_DURATION,
    fade,
    slideDown,
    slideLeft,
    slideRight,
    slideUp,
} from './animation'

export const StyledTooltipContent = styled(Content)`
    ${regular5};
    user-select: none;
    background-color: ${({ theme }) => theme.palette.ui['07'].normal};
    border: 1px solid ${({ theme }) => theme.palette.ui['07'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: 0.625rem 1rem;
    filter: drop-shadow(0px 4px 4px rgba(16, 37, 97, 0.04))
        drop-shadow(0px 8px 8px rgba(16, 37, 97, 0.08));
    color: ${({ theme }) => theme.palette.text['04'].normal};
    text-align: center;
    animation-duration: ${ANIMATION_DURATION}ms;
    animation-timing-function: ease;
    will-change: transform, opacity;
    z-index: 3;

    &[data-state='delayed-open'][data-side='top'] {
        animation-name: ${fade}, ${slideUp};
    }

    &[data-state='delayed-open'][data-side='right'] {
        animation-name: ${fade}, ${slideRight};
    }

    &[data-state='delayed-open'][data-side='bottom'] {
        animation-name: ${fade}, ${slideDown};
    }

    &[data-state='delayed-open'][data-side='left'] {
        animation-name: ${fade}, ${slideLeft};
    }
`

export const StyledArrow = styled(Arrow)`
    fill: ${({ theme }) => theme.palette.ui['07'].normal};
`
