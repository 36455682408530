import { useCallback, useState } from 'react'
import { Mode } from '../types'
import { useCreateProcess } from './useCreateProcess'
import { useUpdateProcess } from './useUpdateProcess'

export const useSave = (mode: Mode) => {
    const [showErrorToast, setShowErrorToast] = useState(false)

    const { handleUpdate, updateLoading } = useUpdateProcess(setShowErrorToast)
    const { handleCreate, createLoading } = useCreateProcess(setShowErrorToast)

    const handleSave = useCallback(() => {
        if (mode === 'edit') {
            handleUpdate()
        } else {
            handleCreate()
        }
    }, [mode, handleCreate, handleUpdate])

    return {
        loading: updateLoading || createLoading,
        handleSave,
        showErrorToast,
        setShowErrorToast,
    }
}
