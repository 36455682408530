import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { ProcessNodeData } from '../types'

export const ProcessNode: FC<NodeProps<ProcessNodeData>> = ({ data }) => {
    return (
        <StyledGroup>
            <Handle type="target" position={Position.Left} />
            <Text variant="regular-6" as="legend">
                {data.label}
            </Text>
            <Handle type="source" position={Position.Right} />
        </StyledGroup>
    )
}
const StyledGroup = styled.fieldset`
    height: 100%;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}
    );
    overflow: hidden;
`
