import { flex, styled } from 'Adapters/Freestyled'
import { bold1, regular5 } from 'Components/Text'

export const Styled = styled.form`
    width: 26rem;
    display: block;
    padding: 2rem;
    overflow: auto;
    max-height: 100%;

    > header {
        padding-bottom: 1.5rem;
    }

    > header p {
        ${regular5}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding-bottom: 0.5rem;
    }

    > header h2 {
        ${bold1}
    }

    > footer {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 2rem;
        padding-top: 0.5rem;

        > button {
            width: 100%;
        }
    }

    .inputs {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.5rem;
    }
`
