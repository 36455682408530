import * as Types from '../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsUserSetupQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsUserSetupQuery = { __typename?: 'Query', isUserSetup: boolean, invites: Array<{ __typename?: 'Invite', id: string, tenant: { __typename?: 'InvitingTenant', id: string, name: string } }> };


export const IsUserSetupDocument = gql`
    query IsUserSetup {
  isUserSetup
  invites {
    id
    tenant {
      id
      name
    }
  }
}
    `;

/**
 * __useIsUserSetupQuery__
 *
 * To run a query within a React component, call `useIsUserSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserSetupQuery(baseOptions?: Apollo.QueryHookOptions<IsUserSetupQuery, IsUserSetupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserSetupQuery, IsUserSetupQueryVariables>(IsUserSetupDocument, options);
      }
export function useIsUserSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserSetupQuery, IsUserSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserSetupQuery, IsUserSetupQueryVariables>(IsUserSetupDocument, options);
        }
export type IsUserSetupQueryHookResult = ReturnType<typeof useIsUserSetupQuery>;
export type IsUserSetupLazyQueryHookResult = ReturnType<typeof useIsUserSetupLazyQuery>;
export type IsUserSetupQueryResult = Apollo.QueryResult<IsUserSetupQuery, IsUserSetupQueryVariables>;