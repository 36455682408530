import { Item } from '@radix-ui/react-dropdown-menu'
import { css, flex, styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import { baseButtonStyles } from '../styles'

type Props = {
    text: string
    onSelect: (e: Event) => void
    disabled?: boolean
    closeOnSelect?: boolean
    order?: 'asc' | 'desc'
    className?: string
}

export const DropdownSortButton: FC<Props> = ({
    text,
    onSelect,
    closeOnSelect = true,
    disabled = false,
    order,
    className,
}) => {
    const { variant } = useColorVariant()

    return (
        <StyledItem
            $variant={variant}
            onSelect={e => {
                if (!closeOnSelect) e.preventDefault()
                onSelect(e)
            }}
            disabled={disabled}
            className={className}
        >
            <span>{text}</span>
            {order ? (
                <Icon name={order === 'asc' ? 'ArrowUp' : 'ArrowDown'} />
            ) : null}
        </StyledItem>
    )
}

const StyledItem = styled(Item)<{ $variant: ColorVariant }>`
    ${({ $variant }) => applyColorStyles($variant)};
    ${baseButtonStyles};
    ${flex('row', 'space-between', 'center')};
    width: 8rem;

    svg {
        min-width: 0.5rem;
        min-height: 0.5rem;
    }
`

const applyColorStyles = (variant: ColorVariant) =>
    variant === 'light'
        ? css`
              background-color: ${({ theme }) => theme.palette.ui['01'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['01'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['01'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['03'].normal};
                  }
              }
          `
        : css`
              background-color: ${({ theme }) => theme.palette.ui['07'].normal};
              color: white;

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['07'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['07'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['02'].normal};
                  }
              }
          `
