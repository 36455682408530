import { match } from 'ts-pattern'
import { PrincipalFragment } from './__generated__/Principal'

export const isUserPrincipal = (
    principal: PrincipalFragment
): principal is Extract<PrincipalFragment, { __typename: 'UserPrincipal' }> => {
    return principal.__typename === 'UserPrincipal'
}

export const name = (principal: PrincipalFragment) =>
    match(principal)
        .with({ __typename: 'UserPrincipal' }, ({ user }) => user.name)
        .with(
            { __typename: 'WebhookPrincipal' },
            ({ webhook }) => webhook.name ?? 'A webhook'
        )
        .with(
            { __typename: 'UserGroupPrincipal' },
            ({ userGroup }) => userGroup.name
        )
        .exhaustive()
