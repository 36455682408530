import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GROUPING_KEY, decodeGroupState } from './Grouping'

const displays = ['table', 'card'] as const // Default at [0]

type Display = (typeof displays)[number]

const DISPLAY_KEY = 'display'

const SELECTED_FIELDS_KEY = 'selectedFields'

const SORT_KEY = 'sort'

type Sort = [string, 'asc' | 'desc']

const extractSelectedFields = (urlSearchParams: URLSearchParams) => {
    const val = urlSearchParams.get(SELECTED_FIELDS_KEY)

    if (!val) return []

    try {
        return JSON.parse(val) as string[]
    } catch {
        return []
    }
}

const extractSort = (urlSearchParams: URLSearchParams): Sort => {
    const val = urlSearchParams.get(SORT_KEY)

    if (!val) return ['label', 'asc']

    try {
        return JSON.parse(val) as Sort
    } catch {
        return ['label', 'asc']
    }
}

export const useBusinessObjectsView = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const display = useMemo(() => {
        const displayParam = searchParams.get(DISPLAY_KEY)
        return displays.find(d => d === displayParam) || displays[0]
    }, [searchParams])

    const setDisplay = (display: Display) => {
        setSearchParams(prev => {
            const next = new URLSearchParams(prev)

            next.set(DISPLAY_KEY, display)

            return next
        })
    }

    const selectedFields = useMemo(
        () => extractSelectedFields(searchParams),
        [searchParams]
    )

    const toggleSelectedField = useCallback(
        (fieldId: string) => {
            setSearchParams(
                prev => {
                    const selectedFields = extractSelectedFields(prev)

                    const updatedFields = selectedFields.includes(fieldId)
                        ? selectedFields.filter(id => id !== fieldId)
                        : [...selectedFields, fieldId]

                    const next = new URLSearchParams(prev)

                    next.set(SELECTED_FIELDS_KEY, JSON.stringify(updatedFields))

                    return next
                },
                { replace: true }
            )
        },
        [setSearchParams]
    )

    const sort = useMemo(() => extractSort(searchParams), [searchParams])

    const toggleSort = useCallback(
        (fieldName: string) => {
            setSearchParams(
                prev => {
                    const [sortField, sortDirection] = extractSort(prev)

                    const next = new URLSearchParams(prev)

                    if (sortField === fieldName) {
                        next.set(
                            SORT_KEY,
                            JSON.stringify([
                                fieldName,
                                sortDirection === 'asc' ? 'desc' : 'asc',
                            ])
                        )
                    } else {
                        next.set(SORT_KEY, JSON.stringify([fieldName, 'asc']))
                    }

                    return next
                },
                { replace: true }
            )
        },
        [setSearchParams]
    )

    const clearSort = useCallback(() => {
        setSearchParams(prev => {
            const next = new URLSearchParams(prev)

            next.delete(SORT_KEY)

            return next
        })
    }, [setSearchParams])

    const grouping = useMemo(() => {
        const grouping = searchParams.get(GROUPING_KEY)

        if (!grouping) return null

        return decodeGroupState(grouping, [])
    }, [searchParams])

    // Clear card-specific view data when switching to table view
    useEffect(() => {
        if (display === 'table') {
            setSearchParams(prev => {
                const next = new URLSearchParams(prev)

                next.delete(SELECTED_FIELDS_KEY)
                next.delete(SORT_KEY)
                next.delete(GROUPING_KEY)

                return next
            })
        }
    }, [display, setSearchParams])

    const hasSort = useMemo(() => {
        return searchParams.has(SORT_KEY)
    }, [searchParams])

    return {
        display,
        setDisplay,
        selectedFields,
        toggleSelectedField,
        sort,
        hasSort,
        toggleSort,
        clearSort,
        grouping,
    }
}
