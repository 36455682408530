import { FC, useMemo, useState } from 'react'
import { CreateUserTenant } from './CreateUserTenant/CreateUserTenant'
import { Layout } from './Layout'
import { RespondToInvites } from './RespondToInvites'

type Step = 'invites' | 'createTenant'

type Props = {
    onSuccess: () => void
    invites: {
        id: string
        tenant: {
            name: string
        }
    }[]
}

export const SetupUser: FC<Props> = ({ invites, onSuccess }) => {
    const [skipInvites, setSkipInvites] = useState(false)

    const step = useMemo<Step>(() => {
        if (skipInvites) return 'createTenant'
        return invites.length ? 'invites' : 'createTenant'
    }, [invites, skipInvites])

    return (
        <Layout>
            {step === 'invites' ? (
                <RespondToInvites
                    onSuccess={onSuccess}
                    invites={invites}
                    skip={() => setSkipInvites(true)}
                />
            ) : null}

            {step === 'createTenant' ? (
                <CreateUserTenant onSuccess={onSuccess} />
            ) : null}
        </Layout>
    )
}
