import { RadioGroup } from '@radix-ui/react-dropdown-menu'
import { FC } from 'react'
import { DropdownLabel } from '../DropdownLabel'
import { DropdownRadio } from '../DropdownRadio'

type Props = {
    options: {
        value: string
        label: string
    }[]
    value: string
    onValueChange: (string: string) => void
    groupLabel?: string
}

export const DropdownRadioGroup: FC<Props> = ({
    value,
    options,
    onValueChange,
    groupLabel,
}) => {
    return (
        <RadioGroup value={value} onValueChange={onValueChange}>
            {groupLabel ? <DropdownLabel text={groupLabel} /> : null}

            {options.map(option => {
                return (
                    <DropdownRadio
                        key={option.value}
                        text={option.label}
                        value={option.value}
                        isSelected={option.value === value}
                        closeOnSelect={false}
                    />
                )
            })}
        </RadioGroup>
    )
}
