import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text, bold7, regular5, regular6 } from 'Components/Text'
import { Tooltip } from 'Components/Tooltip'
import { useResponder } from 'Hooks'
import { FC, useState } from 'react'

type Props = {
    label: string
    description: string
    children: React.ReactNode
    errorMessage?: string
    displayOnly?: boolean
} & (
    | {
          fieldset?: undefined
          fieldId: string
      }
    | {
          fieldset: true
          fieldId?: undefined
      }
)

const InputWrapper: FC<Props> = ({
    fieldset,
    label,
    description,
    children,
    fieldId,
    errorMessage,
    displayOnly = false,
}) => (
    <Styled as={fieldset ? 'fieldset' : 'div'} data-display-only={displayOnly}>
        <header>
            <Text as="label" variant="bold-5" htmlFor={fieldId}>
                {label}
            </Text>
            {displayOnly && <DisplayOnly />}
        </header>
        <p className="description">{description}</p>
        {children}
        {errorMessage && <p className="error">{errorMessage}</p>}
    </Styled>
)

const DisplayOnly: FC = () => {
    const [open, setOpen] = useState(false)
    const { isLarge } = useResponder()
    return isLarge ? (
        <>
            <Tooltip content="This setting cannot be modified once a dataset has been created.">
                <StyledDisplayOnlyLarge>
                    <Icon name="Lock" />
                </StyledDisplayOnlyLarge>
            </Tooltip>
        </>
    ) : (
        <>
            <StyledDisplayOnlySmall onClick={() => setOpen(true)}>
                <Icon name="Lock" />
            </StyledDisplayOnlySmall>
            <MinimalModal open={open} onOpenChange={setOpen}>
                <StyledDisplayOnlySmallContent>
                    <h3>Display only</h3>
                    <p>
                        This setting cannot be modified once a dataset has been
                        created.
                    </p>
                </StyledDisplayOnlySmallContent>
            </MinimalModal>
        </>
    )
}

const Styled = styled.div`
    all: unset;
    border: none;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    label {
        display: block;
        padding: 0;
    }

    > p.description {
        ${regular6}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        line-height: 150%;
        padding-bottom: 0.25rem;
    }

    > p.description a {
        color: ${({ theme }) => theme.palette.support['03'].normal};
    }

    > p.error {
        ${regular6}
        line-height: 150%;
        padding-bottom: 0.25rem;
        color: ${({ theme }) => theme.palette.text.support01.normal};
    }

    &[data-display-only='true'] {
        border-top: 1px dotted
            ${({ theme }) => theme.palette.support['03'].normal}80;
        background-color: ${({ theme }) => theme.palette.support.bg03.normal};
        padding: 1rem;
    }
`

const StyledDisplayOnlyLarge = styled.div`
    ${bold7}
    padding: 0.25rem;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    filter: brightness(0.9);
    border-radius: 50%;
    border: 1px dotted ${({ theme }) => theme.palette.support['03'].normal};
    cursor: default;

    .icon svg {
        --icon-width: 1rem;
        width: var(--icon-width);
        height: var(--icon-width);
        min-width: var(--icon-width);
        min-height: var(--icon-width);
    }
    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }
`
const StyledDisplayOnlySmall = styled.button`
    ${bold7}
    padding: 0.25rem;
    color: ${({ theme }) => theme.palette.text.support03.normal};
    filter: brightness(0.9);
    border-radius: 50%;
    border: 1px dotted ${({ theme }) => theme.palette.support['03'].normal};

    .icon svg {
        --icon-width: 1rem;
        width: var(--icon-width);
        height: var(--icon-width);
        min-width: var(--icon-width);
        min-height: var(--icon-width);
    }
    .icon svg path {
        fill: ${({ theme }) => theme.palette.text.support03.normal};
    }
`
const StyledDisplayOnlySmallContent = styled.div`
    padding: 0.25rem 4vw 1rem;

    h3 {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        padding-bottom: 0.25rem;
    }

    p {
        ${regular5}
        color: ${({ theme }) => theme.palette.text['02'].normal};
        line-height: 150%;
    }
`

export { InputWrapper }
