import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu'
import { boxShadow, styled } from 'Adapters/Freestyled'
import { getPortalsContainer } from 'Utils'
import { FC, ReactNode } from 'react'
import { applyAnimation } from './animation'
import { Provider as ColorVariantProvider } from './colorVariantProvider'
import { ColorVariant } from './types'

type BaseProps = {
    renderContent: () => ReactNode
    renderOpenDropdownButton: () => ReactNode
    variant?: ColorVariant
    align?: 'center' | 'end' | 'start'
    disabled?: boolean
    side?: 'bottom' | 'top' | 'right' | 'left' | undefined
    renderInSpan?: boolean // Span needed if we want to pass a tooltip-wrapped button as a trigger
}

type UncontrolledProps = BaseProps & {
    open?: never
    onOpenChange?: never
}

type ControlledProps = BaseProps & {
    open: boolean
    onOpenChange: (open: boolean) => void
}

type Props = ControlledProps | UncontrolledProps

export const Dropdown: FC<Props> = ({
    renderOpenDropdownButton,
    renderContent,
    variant = 'light',
    open,
    onOpenChange,
    align = 'end',
    disabled,
    side,
    renderInSpan = false,
}) => {
    return (
        <Root open={open} onOpenChange={onOpenChange}>
            <Trigger asChild disabled={disabled}>
                {renderInSpan ? (
                    <span>{renderOpenDropdownButton()}</span>
                ) : (
                    renderOpenDropdownButton()
                )}
            </Trigger>

            <Portal container={getPortalsContainer()}>
                <StyledContent
                    $variant={variant}
                    loop
                    sideOffset={5}
                    align={align}
                    side={side}
                >
                    <ColorVariantProvider value={variant}>
                        {renderContent()}
                    </ColorVariantProvider>
                </StyledContent>
            </Portal>
        </Root>
    )
}

const StyledContent = styled(Content)<{ $variant: ColorVariant }>`
    ${boxShadow()};
    ${applyAnimation()}
    background-color: ${({ theme, $variant }) =>
        $variant === 'light'
            ? theme.palette.ui['01'].normal
            : theme.palette.ui['07'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: 0.5rem;
    overflow-y: auto;

    // This is a brute-force workaround to the dropdown height issue
    // Ideally we use the --radix-dropdown-menu-content-available-height custom property
    // but I cannot get this to work correctly.
    max-height: 20rem;

    &:focus,
    &:focus-visible {
        ${boxShadow()};
    }
`
