import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { NavLinkWithSearch } from 'Components/NavLinkWithSearch/NavLinkWithSearch'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import {
    NavLinkProps,
    Navigate,
    Route,
    RouteProps,
    Routes,
} from 'react-router-dom'
import { match } from 'ts-pattern'

const NavTabs: FC<{
    tabs: {
        label: string
        description: string
        icon: IconName
        route: RouteProps
        link: NavLinkProps
    }[]
    redirections?: string[]
}> = ({ tabs, redirections }) => {
    const { screenSize } = useResponder()
    return (
        <>
            {match(screenSize)
                .with('large', () => (
                    <StyledLarge>
                        {tabs.map(({ label, link, description, icon }) => (
                            <NavLinkWithSearch key={label} {...link}>
                                <Icon name={icon} />
                                <Text as="p" variant="bold-4">
                                    {label}
                                </Text>
                                <Text as="p" variant="regular-6">
                                    {description}
                                </Text>
                            </NavLinkWithSearch>
                        ))}
                    </StyledLarge>
                ))
                .otherwise(() => (
                    <StyledSmall>
                        {tabs.map(({ label, link, icon }) => (
                            <NavLinkWithSearch key={label} {...link}>
                                <Icon name={icon} />
                            </NavLinkWithSearch>
                        ))}
                    </StyledSmall>
                ))}
            <Routes>
                {tabs.map(({ label, route }) => (
                    <Route key={label} {...route} />
                ))}
                {redirections?.map(redirection => (
                    <Route
                        path={redirection}
                        element={<Navigate to="." />}
                        key={redirection}
                    />
                ))}
            </Routes>
        </>
    )
}

const StyledLarge = styled.nav`
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    display: flex;
    align-items: stretch;
    gap: 1rem;

    a {
        text-decoration: none;
        display: grid;
        grid-template: auto auto / auto auto;
        grid-column-gap: 1rem;
        align-items: center;
        padding: 0.5rem 0.75rem 0.5rem;
        border-bottom: 0.25rem solid transparent;
        border-radius: 0.25rem 0.25rem 0 0;
        transition: background-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
            border-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    a:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    a:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    a[aria-current='page'] {
        border-bottom-color: ${({ theme }) => theme.palette.ui['07'].normal};
    }

    .icon {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
    }

    svg {
        --icon-width: 1.25rem;
        min-width: var(--icon-width);
        min-height: var(--icon-width);
        width: var(--icon-width);
        height: var(--icon-width);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
        transition: fill
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    a[aria-current='page'] svg path {
        fill: ${({ theme }) => theme.palette.icon['01'].normal};
    }

    p {
        transition: color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    P:first-of-type {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    a[aria-current='page'] P:first-of-type {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    P:last-child {
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }

    a[aria-current='page'] P:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

const StyledSmall = styled.nav`
    display: flex;
    align-items: stretch;
    padding: 0 4vw;
    background-color: ${({ theme }) => theme.palette.ui['07'].normal};

    a {
        flex: 1;
        text-decoration: none;
        grid-column-gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        border-radius: 0.25rem 0.25rem 0 0;
        transition: background-color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    a:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    .icon {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
    }

    svg {
        --icon-width: 1.25rem;
        min-width: var(--icon-width);
        min-height: var(--icon-width);
        width: var(--icon-width);
        height: var(--icon-width);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
        transition: fill
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    a[aria-current='page'] svg path {
        fill: ${({ theme }) => theme.palette.icon['04'].normal};
    }
`

export { NavTabs }
