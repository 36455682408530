import { styled } from 'Adapters/Freestyled'
import { NumberInput } from 'Components/Input'
import { Text, bold4 } from 'Components/Text'
import { SelectField } from 'Utils/BusinessObjectDefinition'
import { identity } from 'lodash'
import { FC, useRef } from 'react'
import { P, match } from 'ts-pattern'
import { v4 } from 'uuid'

type SetLength = (
    value: string
) => (
    prev: SelectField['constraints']['numberOfSelections'] | undefined
) => SelectField['constraints']['numberOfSelections']
const setMinLength: SetLength =
    (value: string) =>
    (
        prev = {
            min: 0,
            max: undefined,
        }
    ) => {
        const min = (value && parseInt(value)) || 0
        const max = match(prev.max)
            .with(P.nullish, identity)
            .otherwise(x => Math.max(x, min))
        return {
            min,
            max,
        }
    }
const setMaxLength: SetLength =
    (value: string) =>
    (
        prev = {
            min: 0,
            max: undefined,
        }
    ) => {
        const max = value ? parseInt(value) : undefined
        const min = Math.min(prev.min ?? 0, max ?? 0)

        return {
            min,
            max,
        }
    }

type Props = {
    numberOfSelections: SelectField['constraints']['numberOfSelections']
    update: (
        fn: (
            prev: SelectField['constraints']['numberOfSelections']
        ) => SelectField['constraints']['numberOfSelections']
    ) => void
    displayOnly: boolean
}
const NumberOfSelectionsConstraint: FC<Props> = ({
    numberOfSelections,
    update,
    displayOnly,
}) => {
    const { current: minFieldId } = useRef(v4())
    const { current: maxFieldId } = useRef(v4())

    return (
        <Styled>
            <Text as="label" variant="bold-5" htmlFor={minFieldId}>
                Min
            </Text>
            {displayOnly ? (
                <StyledDisplayOnly>
                    {numberOfSelections?.min || 0}
                </StyledDisplayOnly>
            ) : (
                <NumberInput
                    min={0}
                    step={1}
                    id={minFieldId}
                    value={numberOfSelections?.min || 0}
                    onChange={({ currentTarget: { value } }) => {
                        update(setMinLength(value))
                    }}
                />
            )}
            <Text as="label" variant="bold-5" htmlFor={maxFieldId}>
                Max
            </Text>
            {displayOnly ? (
                <StyledDisplayOnly>
                    {numberOfSelections.max || 'none'}
                </StyledDisplayOnly>
            ) : (
                <NumberInput
                    min={1}
                    step={1}
                    id={maxFieldId}
                    placeholder="No max"
                    value={numberOfSelections.max || undefined}
                    onChange={({ currentTarget: { value } }) => {
                        update(setMaxLength(value))
                    }}
                />
            )}
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto auto / auto 1fr;
    align-items: center;
    gap: 1rem;
`

const StyledDisplayOnly = styled.p`
    ${bold4}
    color: ${({ theme }) => theme.palette.text.support03.normal};
`

export { NumberOfSelectionsConstraint }
