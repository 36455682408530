import { FC, useState } from 'react'
import { match } from 'ts-pattern'
import { FieldSelection } from './FieldSelection'
import { GroupingFooter } from './Footer'
import { GroupConfig } from './GroupConfig'
import { Grouping } from './types'

type Props = {
    grouping: Grouping
    onFieldSelect: (fieldId: string) => void
    onMoveGroupUp: (fieldId: string) => (groupdId: string) => void
    onMoveGroupDown: (fieldId: string) => (groupdId: string) => void
    onSelectGroupColor: (
        fieldId: string
    ) => (groupdId: string) => (color: string) => void
    onClose: () => void
    className?: string
}

const GroupingControl: FC<Props> = ({
    grouping,
    onFieldSelect,
    onMoveGroupUp,
    onMoveGroupDown,
    onSelectGroupColor,
    onClose,
    className = '',
}) => {
    const [step, setStep] = useState<'fieldSelection' | 'groupConfig'>(
        'fieldSelection'
    )
    return (
        <div className={className}>
            {match(step)
                .with('fieldSelection', () => (
                    <FieldSelection
                        fields={grouping.fields}
                        selection={grouping.selection}
                        onSelect={onFieldSelect}
                    />
                ))
                .with('groupConfig', () => {
                    const field =
                        grouping.fields.find(
                            field => field.id === grouping.selection
                        ) ?? null
                    return (
                        field && (
                            <GroupConfig
                                groups={field.groups}
                                onMoveUp={onMoveGroupUp(field.id)}
                                onMoveDown={onMoveGroupDown(field.id)}
                                onColorSelect={onSelectGroupColor(field.id)}
                            />
                        )
                    )
                })
                .exhaustive()}
            <GroupingFooter
                close={onClose}
                step={step}
                setStep={setStep}
                selection={grouping.selection}
            />
        </div>
    )
}

export { GroupingControl }
