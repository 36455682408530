import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    children: React.ReactNode
    step: number
    title: string
    onDelete: () => void
    moveUp?: () => void
    moveDown?: () => void
}

export const StepOutline: FC<Props> = ({
    children,
    title,
    step,
    onDelete,
    moveUp,
    moveDown,
}) => (
    <Step>
        <StepTitle>
            <Text variant="bold-4" as="h3">
                Step {step} - {title}
            </Text>
            <IconButton iconName="Delete" type="button" onClick={onDelete} />
            <IconButton
                iconName="AngleUp"
                type="button"
                disabled={!moveUp}
                onClick={moveUp}
            />
            <IconButton
                iconName="AngleDown"
                type="button"
                disabled={!moveDown}
                onClick={moveDown}
            />
        </StepTitle>
        {children}
    </Step>
)

const StepTitle = styled.legend`
    display: flex;
    align-items: center;

    button {
        margin-top: 0;
    }
`

const Step = styled.fieldset`
    width: 100%;
    padding: 0.6rem;
    padding-bottom: 0;
    margin-bottom: 1rem;
`
