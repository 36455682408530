import { styled } from 'Adapters/Freestyled'
import { togglePopup } from 'Features/Support'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuButton } from './Button'
import { NotificationCentre } from './NotificationCentre'

type Props = {
    setWorkspaceControlOpen: (open: boolean) => void
}

const AppHeaderMenu: FC<Props> = ({ setWorkspaceControlOpen }) => {
    const navigate = useNavigate()
    return (
        <Styled>
            <NotificationCentre
                onOtherWorkspaceNotificationClick={() =>
                    setWorkspaceControlOpen(true)
                }
            />
            <MenuButton
                icon={'User'}
                tooltip={'Account'}
                onClick={() => navigate('/account')}
            />
            <MenuButton
                icon={'Comment'}
                tooltip={'Talk to us'}
                onClick={togglePopup}
            />
        </Styled>
    )
}

const Styled = styled.menu`
    display: flex;
    flex-direction: row;
    align-items: center;

    [data-screen-size='large'] & {
        padding: 0 1rem;
    }
`

export { AppHeaderMenu }
