import {
    DateFieldValidation,
    DateField as Field,
} from 'Utils/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { OnSubmit, State } from '../../types'
import { RequiredField } from '../Constraint/RequiredField'
import { Content } from '../Content'
import { DateSubFields } from './DateSubFields'
import {
    clearDateRange,
    updateDateRangeEnd,
    updateDateRangeStart,
    updateDefaultRelativeDate,
    updateDefaultSpecificDate,
} from './utils'

type Props = {
    field: Field
    validation: DateFieldValidation
    onSubmit: OnSubmit<Field, DateFieldValidation>
    onClose: () => void

    hideRequired: boolean
}

const DateField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,
    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, DateFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <DateSubFields
                precision={{
                    value: state.field.precision ?? undefined,
                    onChange: precision => {
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                precision,
                            },
                        }))
                    },
                }}
                dateRange={{
                    constraint: state.field.constraints.dateRange,
                    onSetStart: date => {
                        setState(prev => ({
                            ...prev,
                            field: updateDateRangeStart(date, prev.field),
                        }))
                    },
                    onSetEnd: date => {
                        setState(prev => ({
                            ...prev,
                            field: updateDateRangeEnd(date, prev.field),
                        }))
                    },
                    onClear: () => {
                        setState(prev => ({
                            ...prev,
                            field: clearDateRange(prev.field),
                        }))
                    },
                }}
                defaultValue={{
                    value: state.field.defaultValue ?? undefined,
                    onClear: () => {
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                defaultValue: undefined,
                            },
                        }))
                    },
                    onSetSpecifc: date => {
                        setState(prev => ({
                            ...prev,
                            field: updateDefaultSpecificDate(date, prev.field),
                        }))
                    },
                    onSetRelative: (value, unit) => {
                        if (!value || !unit) return
                        setState(prev => ({
                            ...prev,
                            field: updateDefaultRelativeDate(
                                value,
                                unit,
                                prev.field
                            ),
                        }))
                    },
                }}
                constraintsLocked={field.constraintsLocked}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

export { DateField }
