import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'

type Props = {
    onClick: () => void
    disabled?: boolean
    title: string
    description: ReactNode
    icon: IconName
    secondIcon?: IconName
    current?: boolean
}

const MenuNavButton: FC<Props> = ({
    onClick,
    disabled = false,
    icon,
    title,
    description,
    secondIcon = 'CarrotRight',
    current = false,
}) => (
    <Styled onClick={onClick} disabled={disabled} data-current={current}>
        <Icon name={icon} />
        <Text as="span" variant="bold-4" className="title">
            {title}
        </Text>
        <Text as="span" variant="regular-6" className="description">
            {description}
        </Text>
        <Icon name={secondIcon} />
    </Styled>
)

const Styled = styled.button`
    display: grid;
    grid-template: auto auto / auto 1fr auto;
    align-items: center;
    justify-content: center;
    text-align: left;
    grid-column-gap: 1rem;
    grid-row-gap: 0.125rem;
    width: 100%;
    padding: 1rem;
    border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};

    .icon:first-child {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
    }

    .icon:first-child svg {
        --icon-size: 1.25rem;
        width: var(--icon-size);
        height: var(--icon-size);
        min-width: var(--icon-size);
        min-height: var(--icon-size);
    }

    .icon:first-child svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    .title {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    .description {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .icon:last-child {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
    }

    .icon:last-child svg {
        --icon-size: 1.5rem;
        width: var(--icon-size);
        height: var(--icon-size);
        min-width: var(--icon-size);
        min-height: var(--icon-size);
    }

    .icon:last-child svg path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    .spacer {
        display: inline-block;
        padding: 0 0.5rem;
        color: ${({ theme }) => theme.palette.ui['04'].normal};
    }

    &:disabled {
        opacity: 0.7;

        .title {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }

        .icon svg path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    &[data-current='true'] {
        background-color: ${({ theme }) => theme.palette.support.bg03.normal};
        color: ${({ theme }) => theme.palette.text.support03.normal};

        .icon:last-child svg path {
            fill: ${({ theme }) => theme.palette.support.bg03.normal};
        }
    }
`

export { MenuNavButton }
