import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'

const Unset: FC = () => <Styled>—</Styled>

const Styled = styled.p`
    color: ${({ theme }) => theme.palette.text['03'].normal};
    opacity: 0.5;
    text-align: center;
`

export { Unset }
