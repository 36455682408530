import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { ViewMode } from 'Components/DocumentPicker/types'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'

type Props = {
    viewMode: ViewMode
    changeViewMode: (viewMode: ViewMode) => void
}

export const ViewToggle: FC<Props> = ({ viewMode, changeViewMode }) => {
    return (
        <StyledViewToggle>
            <Tooltip content="List view">
                <StyledIconButton
                    iconName={'List'}
                    onClick={() => {
                        changeViewMode('list')
                    }}
                    variant="secondary"
                    $selected={viewMode === 'list'}
                />
            </Tooltip>

            <Tooltip content="Grid view">
                <StyledIconButton
                    iconName={'Dashboard'}
                    title="Grid"
                    onClick={() => {
                        changeViewMode('grid')
                    }}
                    variant="secondary"
                    $selected={viewMode === 'grid'}
                />
            </Tooltip>
        </StyledViewToggle>
    )
}

const StyledViewToggle = styled.li`
    ${flex('row', 'center', 'center')}
    gap: 0.25rem;
`

const StyledIconButton = styled(IconButton)<{ $selected: boolean }>`
    background-color: ${({ theme, $selected }) =>
        $selected ? theme.palette.ui['02'].hover : 'inherit'};
`
