import { css } from 'Adapters/Freestyled'

export const bold0 = css`
    font-size: ${({ theme }) => theme.typography.scale['08']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold1 = css`
    font-size: ${({ theme }) => theme.typography.scale['06']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold2 = css`
    font-size: ${({ theme }) => theme.typography.scale['05']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold3 = css`
    font-size: ${({ theme }) => theme.typography.scale['04']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold4 = css`
    font-size: ${({ theme }) => theme.typography.scale['03']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 140%;
`

export const bold5 = css`
    font-size: ${({ theme }) => theme.typography.scale['02']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold6 = css`
    font-size: ${({ theme }) => theme.typography.scale['01']};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`

export const bold7 = css`
    font-size: 0.625rem; // 10px
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    line-height: 125%;
`
export const regular00 = css`
    font-size: ${({ theme }) => theme.typography.scale['10']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular0 = css`
    font-size: ${({ theme }) => theme.typography.scale['08']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular1 = css`
    font-size: ${({ theme }) => theme.typography.scale['06']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular2 = css`
    font-size: ${({ theme }) => theme.typography.scale['05']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular3 = css`
    font-size: ${({ theme }) => theme.typography.scale['04']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular4 = css`
    font-size: ${({ theme }) => theme.typography.scale['03']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 140%;
`

export const regular5 = css`
    font-size: ${({ theme }) => theme.typography.scale['02']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 150%;
`

export const regular6 = css`
    font-size: ${({ theme }) => theme.typography.scale['01']};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const regular7 = css`
    font-size: 0.625rem; // 10px
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    line-height: 125%;
`

export const strikethroughStyles = css`
    text-decoration: line-through;
`

export const underlineStyles = css`
    text-decoration: underline;
`
