import { flex } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { Text } from 'Components/Text'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {
    name: string
}

export const AddedByCell: FC<Props> = ({ name }) => {
    return (
        <StyledAvatarCell data-bbc="avatar-cell">
            <div>
                <Avatar name={name} size="small" />
                <Text as="span" variant="regular-5">
                    {name}
                </Text>
            </div>
        </StyledAvatarCell>
    )
}

const StyledAvatarCell = styled.td`
    vertical-align: middle;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: ${({ theme }) => theme.spacing['04']};
        width: 100%;
        height: 100%;
    }
`
