import { lightTheme } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FieldConstraintType } from '__generated__'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { GroupedBusinessObjects } from './types'

export const useGroupBusinessObjects = (
    businessObjects: BusinessObjectTableItemFragment[],
    grouping: Grouping
) => {
    return useMemo(() => {
        if (!businessObjects.length) return {}

        const fieldId = grouping.selection

        const field = grouping.fields.find(field => field.id === fieldId)

        const groups = field?.groups

        const fieldType = field?.type

        if (!groups || !fieldType) return {}

        const fieldDefinition = businessObjects[0].fields.find(
            field => field.fieldDefinition.id === fieldId
        )?.fieldDefinition

        const showUnassigned = match(fieldDefinition)
            .with(
                { __typename: 'SelectFieldDefinition' },
                ({ selectConstraints }) =>
                    selectConstraints.some(
                        c =>
                            c.type === FieldConstraintType.NumberOfSelections &&
                            !c.min
                    )
            )
            .otherwise(() => false)

        const groupMap = groups.reduce<GroupedBusinessObjects>(
            (out, group) => ({
                ...out,
                [group.id]: {
                    fieldType,
                    value: group.value,
                    color: group.color,
                    items: [],
                },
            }),
            {
                ...(showUnassigned && {
                    unassigned: {
                        fieldType,
                        value: 'Unset',
                        color: lightTheme.palette.ui['05'].normal,
                        items: [],
                    },
                }),
            }
        )

        businessObjects.forEach(bo => {
            const field = bo.fields.find(
                ({ fieldDefinition: { id } }) => id === fieldId
            )

            if (!field) return

            match(field)
                .with({ __typename: 'BusinessObjectSelectField' }, field => {
                    const value = field.selectValue

                    // Unset can be [] or ['']
                    if (value.filter(v => v.length).length) {
                        groupMap[value[0]]?.items.push(bo)
                    } else {
                        groupMap.unassigned.items.push(bo)
                    }
                })
                .with({ __typename: 'BusinessObjectBooleanField' }, field => {
                    const value = field.booleanValue

                    groupMap[value ? 'true' : 'false']?.items.push(bo)
                })
                .otherwise(noop)
        })

        return groupMap
    }, [grouping, businessObjects])
}
