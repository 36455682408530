import * as Accordion from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import { match } from 'ts-pattern'
import { BusinessDomain_BusinessObjectDefinitionQuery } from '../__generated__/query'

type Props = {
    usage: Exclude<
        BusinessDomain_BusinessObjectDefinitionQuery['businessObjectDefinition'],
        undefined | null
    >['usage']
}

export const RestrictedMode: FC<Props> = ({ usage }) => {
    return (
        <Accordion.Root type="single" collapsible>
            <StyledItem value="1">
                <StyledSection>
                    <Text as="p" variant="regular-1">
                        Restricted mode
                    </Text>
                    <Accordion.Trigger asChild>
                        <StyledTriggerButton iconName="AngleDown" />
                    </Accordion.Trigger>
                </StyledSection>
                <StyledContent>
                    <Text as="p" variant="regular-4">
                        Editing and deleting this dataset is restricted due to
                        its usage within your tenant.
                    </Text>
                    {usage.processes.length === 0 ? null : (
                        <>
                            <Text as="h6" variant="regular-2">
                                Workflows
                            </Text>
                            <ul>
                                {usage.processes.map(({ id, name }) => (
                                    <li key={id}>
                                        <Text as="span" variant="regular-4">
                                            {name}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {usage.relations.length === 0 ? null : (
                        <>
                            <Text as="h6" variant="regular-2">
                                Datasets
                            </Text>
                            <ul>
                                {usage.relations.map(relation => (
                                    <li key={relation.id}>
                                        <Text as="span" variant="regular-4">
                                            {match(relation)
                                                .with(
                                                    {
                                                        __typename:
                                                            'BusinessObjectDefinition',
                                                    },
                                                    r => r.name
                                                )
                                                .with(
                                                    {
                                                        __typename:
                                                            'BusinessObjectDefinitionExtension',
                                                    },
                                                    r => r.label
                                                )
                                                .exhaustive()}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {usage.boards.length === 0 ? null : (
                        <>
                            <Text as="h6" variant="regular-2">
                                Boards
                            </Text>
                            <ul>
                                {usage.boards.map(({ id, name }) => (
                                    <li key={id}>
                                        <Text as="span" variant="regular-4">
                                            {name}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {usage.instanceCount === 0 ? null : (
                        <>
                            <Text as="h6" variant="regular-2">
                                Records
                            </Text>
                            <Text as="span" variant="regular-4">
                                {usage.instanceCount} instances
                            </Text>
                        </>
                    )}
                </StyledContent>
            </StyledItem>
        </Accordion.Root>
    )
}

const StyledItem = styled(Accordion.Item)`
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    background-color: ${({ theme }) => theme.palette.ui['04'].normal};

    > section > p {
        padding-bottom: 0;
    }
`

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const slideDown = keyframes`
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
`

const slideUp = keyframes`
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
`

const StyledContent = styled(Accordion.Content)`
    > h6 + * {
        padding-bottom: 1rem;
    }

    overflow: hidden;

    &[data-state='open'] {
        animation: ${slideDown} ${({ theme }) => theme.animation.mid} ease;
    }

    &[data-state='closed'] {
        animation: ${slideUp} ${({ theme }) => theme.animation.mid} ease;
    }
`

const StyledTriggerButton = styled(IconButton)`
    span {
        transition: transform
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &[data-state='open'] span {
        transform: rotateZ(180deg);
    }
`
