import { breakpoints, flex, styled } from 'Adapters/Freestyled'

export const StyledChecklistItemActions = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 0.5rem;

    @media screen and (min-width: ${breakpoints.large}px) {
        ${flex('row', 'flex-start', 'center')};
    }

    p button {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
        background: none;
        border: none;
        padding: 0;
    }
`
