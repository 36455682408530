import { PrincipalFragment } from 'Features/Principal/__generated__/Principal'
import { CreateActionPayloadData } from '__generated__'
import { FC } from 'react'
import { Text } from '../../../../../../Components/Text'
import { PrincipalName } from '../../../../../../Features/Principal/Name'

type Props = {
    payload: Pick<
        CreateActionPayloadData,
        'name' | 'dueDate' | 'description'
    > & { assignedTo?: PrincipalFragment | null }
}

export const CreateActionPayloadDetail: FC<Props> = ({ payload }) => (
    <div>
        <Text as="h2" variant="regular-2-spaced">
            Create an action
        </Text>
        <div>Name: {payload.name}</div>
        <div>Due date: {payload.dueDate}</div>
        <div>Description: {payload.description}</div>
        <div>
            Assigned to:{' '}
            {payload.assignedTo ? (
                <PrincipalName principal={payload.assignedTo} />
            ) : (
                'Unassigned'
            )}
        </div>
    </div>
)
