import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { ProcessDisplayFragment } from '../../__generated__/q'
import { DispatchAction, ViewConfig } from '../../types'
import { SaveView, SavedViews } from '../ViewPersistor/Large'
import { CardSettings } from './CardSettings'
import { CompletedToggle } from './CompletedToggle'
import { Filters } from './Filters'
import { Grouping } from './Grouping'

type Props = {
    process: ProcessDisplayFragment
    config: ViewConfig
    dispatch: DispatchAction
}

const ViewBarLarge: FC<Props> = ({ config, dispatch, process }) => (
    <StyledLarge>
        <menu>
            <div>
                <Grouping config={config} dispatch={dispatch} />

                <Filters
                    process={process}
                    config={config}
                    dispatch={dispatch}
                />

                <CardSettings config={config} dispatch={dispatch} />

                <CompletedToggle
                    process={process}
                    config={config}
                    dispatch={dispatch}
                />
            </div>

            <StyledViewPersistor>
                <SaveView process={process} />
                <SavedViews processId={process.id} />
            </StyledViewPersistor>
        </menu>
    </StyledLarge>
)

const StyledLarge = styled.div`
    padding: 0 3rem 0 3rem;

    > menu {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: 0.25rem;
        display: flex;
        justify-content: flex-start;

        > div:first-of-type {
            background-color: ${({ theme }) => theme.palette.ui['03'].normal};
            padding-right: 3rem;
            display: flex;
            align-items: center;
            padding: 0.25rem 0.5rem;
            gap: 1rem;
            border-radius: 0.25rem 0 0 0.25rem;

            > form {
                width: 20rem;
            }
        }
    }
`

const StyledViewPersistor = styled.div`
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    display: flex;
    align-items: center;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.25rem 0.5rem;
    gap: 1rem;
    margin-left: auto;
`

export { ViewBarLarge }
