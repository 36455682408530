import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { FC } from 'react'

type Props = {
    handleRestart: () => void
}

export const RestartButton: FC<Props> = ({ handleRestart }) => {
    const { buttonRef, open, setOpen, closeModal, openModal, returnFocus } =
        useModalControls()

    return (
        <>
            <TextButton
                text="Restart (clear all fields)"
                type="button"
                variant="secondary"
                onClick={openModal}
                size="small"
                ref={buttonRef}
            />

            <ControlledModal
                renderContent={() => (
                    <StyledConfirmRestart>
                        <Text as={'p'} variant={'regular-4'}>
                            Restarting will clear all fields and start from
                            scratch.
                        </Text>

                        <Text as={'p'} variant={'regular-4'}>
                            Any progress that you have made will be lost.
                        </Text>

                        <div>
                            <TextButton
                                onClick={e => {
                                    e.preventDefault()
                                    closeModal()
                                }}
                                text="Cancel"
                                variant="secondary"
                                size="small"
                            />

                            <TextButton
                                onClick={e => {
                                    e.preventDefault()
                                    handleRestart()
                                    closeModal()
                                }}
                                text="Confirm"
                                variant="primary"
                                size="small"
                            />
                        </div>
                    </StyledConfirmRestart>
                )}
                title={'Restart'}
                description={''}
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />
        </>
    )
}

const StyledConfirmRestart = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 30rem;

    > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        ${flex('row', 'center', 'center')};
        gap: 1rem;
        width: 100%;

        > button {
            flex: 1;
        }
    }
`
