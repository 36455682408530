import { useLogout } from 'Adapters/Authentication/useLogout'
import { flex, styled } from 'Adapters/Freestyled'
import illustration from 'Assets/IllustrationError.svg'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { Layout } from './Layout'

export const ErrorScreen = () => {
    const { logout } = useLogout()

    return (
        <Layout>
            <StyledErrorScreen>
                <img src={illustration} alt="Error illustration" />

                <Text as="h2" variant="bold-0">
                    Oops! Looks like we encountered an error
                </Text>

                <Text as="h2" variant="regular-4">
                    Please logout and try again. If the problem persist, please
                    contact us directly and we will do our best to fix the
                    issue.
                </Text>

                <TextButton
                    text="Logout"
                    onClick={logout}
                    variant="primary"
                    size="small"
                />
            </StyledErrorScreen>
        </Layout>
    )
}

export const StyledErrorScreen = styled.section`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    width: 26rem;
    padding: 2rem;
    overflow: auto;
    max-height: 100%;

    > img {
        width: 100%;
    }

    > button {
        width: 100%;
    }
`
