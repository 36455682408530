import { useResponder } from 'Hooks'
import { QueryActionsInput } from '__generated__'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { Large } from './Large'
import { LoadingDisplay } from './LoadingDisplay'
import { Small } from './Small'
import { useSingleBusinessObjectActionsQuery } from './__generated__/SingleBusinessObjectActions'

type Props = {
    actionsQueryInput: QueryActionsInput
    businessObjectId: string
}

const Actions: FC<Props> = ({ actionsQueryInput, businessObjectId }) => {
    const { screenSize } = useResponder()
    const { data } = useSingleBusinessObjectActionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                ...actionsQueryInput,
                relatesTo: {
                    ...actionsQueryInput?.relatesTo,
                    businessObjectId,
                },
            },
        },
    })

    return match(data)
        .with(undefined, () => <LoadingDisplay />)
        .otherwise(({ actions }) =>
            match(screenSize)
                .with('large', () => (
                    <Large
                        actions={actions}
                        actionsQueryInput={actionsQueryInput}
                    />
                ))
                .otherwise(() => (
                    <Small
                        actions={actions}
                        actionsQueryInput={actionsQueryInput}
                    />
                ))
        )
}

export { Actions }
