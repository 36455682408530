import { Value } from '@udecode/plate-common'
import { RichText } from 'Components/RichText'
import { FC } from 'react'

type Props = {
    actionId: string
    richText: string | undefined
}

export const RichTextCell: FC<Props> = ({ richText, actionId }) => {
    if (!richText) return null
    return (
        <RichText
            readonly
            initialValue={JSON.parse(richText) as Value}
            id={`rich-text-cell-${actionId}`}
        />
    )
}
