import { compact } from 'lodash'
import { showByQueryKey } from './constants'
import { isShowBy } from './types'

export const getShowBy = (searchParams: URLSearchParams) => {
    const showByQuery = searchParams.getAll(showByQueryKey)

    return compact(
        showByQuery.map(showBy => (isShowBy(showBy) ? showBy : null))
    )
}
