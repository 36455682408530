import { getDataColors } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { Grouping } from 'Components/GroupingControl/types'
import { BizObjectDefFieldsFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { FieldConstraintType, FieldType } from '__generated__'
import { Base64 } from 'js-base64'
import { isEqual, sortBy } from 'lodash'
import { match } from 'ts-pattern'

export const GROUPING_KEY = 'grouping'

const allGroup = {
    id: 'all',
    label: 'No grouping',
    type: FieldType.Boolean, // Unused but required for the type
    groups: [],
}

const colors = getDataColors()

export const setDefaultState = (
    fields: BizObjectDefFieldsFragment['fields']
): Grouping => {
    return {
        fields: fields.reduce(
            (acc: Grouping['fields'], field) =>
                match(field)
                    .with(
                        { __typename: 'SelectFieldDefinition' },
                        ({ selectOptions, selectConstraints }) =>
                            selectConstraints.some(c =>
                                match(c)
                                    .with(
                                        {
                                            type: FieldConstraintType.NumberOfSelections,
                                            max: 1,
                                        },
                                        () => true
                                    )
                                    .otherwise(() => false)
                            )
                                ? [
                                      ...acc,
                                      {
                                          id: field.id,
                                          label: `Group by ${field.name}`,
                                          type: field.type,
                                          groups: selectOptions.map((o, i) => ({
                                              id: o.id,
                                              value: o.value,
                                              color: colors[i % colors.length],
                                          })),
                                      },
                                  ]
                                : acc
                    )
                    .with({ __typename: 'BooleanFieldDefinition' }, () => {
                        return [
                            ...acc,
                            {
                                id: field.id,
                                label: `Group by ${field.name}`,
                                type: field.type,
                                groups: [
                                    {
                                        id: 'true',
                                        value: 'True',
                                        color: colors[0],
                                    },
                                    {
                                        id: 'false',
                                        value: 'False',
                                        color: colors[1],
                                    },
                                ],
                            },
                        ]
                    })
                    .otherwise(() => acc),
            [allGroup]
        ),
        selection: 'all',
    }
}

export const encodeGroupState = (groupState: Grouping) => {
    return Base64.encodeURL(JSON.stringify(groupState))
}

export const decodeGroupState = (
    encodedGroupState: string,
    fields: BizObjectDefFieldsFragment['fields']
) => {
    try {
        return JSON.parse(Base64.decode(encodedGroupState)) as Grouping
    } catch (e) {
        logger.error('Failed to decode group state', e as Error, {
            encodedGroupState,
        })
        return setDefaultState(fields)
    }
}

// Checks if the business object definition has been changed since the state was encoded by
// a) that the fields have changed in length or content
// b) that the select options for single option select fields have changed in length or content
export const checkBusinessObjectDefinitionUpdated = (
    decodedGroupState: Grouping,
    fields: BizObjectDefFieldsFragment['fields']
) => {
    const decodedFields = decodedGroupState.fields.filter(
        field => field.id !== 'all'
    )

    const groupableFields = fields.filter(
        field => isBooleanField(field) || isSingleSelectField(field)
    )

    if (decodedFields.length !== groupableFields.length) {
        return true
    }

    const prevFieldIds = sortBy(decodedFields.map(f => f.id))
    const nextFieldIds = sortBy(groupableFields.map(f => f.id))

    const fieldsChanged = !isEqual(prevFieldIds, nextFieldIds)

    if (fieldsChanged) {
        return true
    }

    const singleOptionSelectFields = groupableFields.filter(f =>
        isSingleSelectField(f)
    )

    const selectOptionsHaveChanged = singleOptionSelectFields.some(f => {
        if (f.__typename !== 'SelectFieldDefinition') return true

        const prevField = decodedFields.find(field => field.id === f.id)

        if (!prevField) {
            return true
        }

        const prevOptions = sortBy(prevField.groups.map(g => g.id))
        const nextOptions = sortBy(f.selectOptions.map(o => o.id))

        if (prevOptions.length !== nextOptions.length) return true

        return !isEqual(prevOptions, nextOptions)
    })

    return selectOptionsHaveChanged
}

export const isSingleSelectField = (
    field: BizObjectDefFieldsFragment['fields'][number]
) => {
    return match(field)
        .with(
            {
                __typename: 'SelectFieldDefinition',
            },
            ({ selectConstraints }) =>
                selectConstraints.some(
                    c =>
                        c.type === FieldConstraintType.NumberOfSelections &&
                        c.max === 1
                )
        )
        .otherwise(() => false)
}

export const isBooleanField = (
    field: BizObjectDefFieldsFragment['fields'][number]
) => {
    return field.__typename === 'BooleanFieldDefinition'
}
