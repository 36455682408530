const primary = {
    normal: '#1E2738',
    hover: '#2E384B',
    active: '#2E384B',
}

export const palette = {
    ai: {
        primary: '#BA6CE7',
    },
    ui: {
        primary: {
            ...primary,
            background: {
                normal: '#F3F4F7',
                hover: '#E7EBF1',
                active: '#D6DCE6',
            },
        },
        '01': {
            normal: '#FFFFFF',
            hover: '#F3F4F7',
            active: '#E7EBF1',
        },
        '02': {
            normal: '#F3F4F7',
            hover: '#E7EBF1',
            active: '#D6DCE6',
        },
        '03': {
            normal: '#E7EBF1',
            hover: '#D6DCE6',
            active: '#C6CEDD',
        },
        '04': {
            normal: '#C6CEDD',
            hover: '#AFBBCF',
            active: '#9DABC4',
        },
        '05': {
            normal: '#9DABC4',
            hover: '#8C9CBA',
            active: '#6C81A7',
        },
        '06': {
            normal: '#32415D',
            hover: '#273349',
            active: '#202A3C',
        },
        '07': {
            normal: '#1E2738',
            hover: '#2E384B',
            active: '#364259',
        },
        '08': {
            normal: '#151C28',
            hover: '#0E131B',
            active: '#07090D',
        },
        background: {
            normal: `#FFFFFF`,
            contrast: `#126BC4`,
        },
        grad01: {
            start: '#F4F5F6',
            end: '#E7EAF1',
        },
        grad06: {
            start: '#273048',
            end: '#455173',
        },
        shadow: '#10256114',
        tint: {
            '01': {
                hover: '#001E560A',
                active: '#001E5614',
            },
            '03': {
                normal: '#001E560A',
                hover: '#001E5614',
                active: '#001E5629',
            },
            '04': {
                normal: '#001E561F',
                hover: '#001E563D',
                active: '#001E5652',
            },
        },
    },
    text: {
        primary: {
            ...primary,
        },
        '01': {
            normal: '#1E2738',
            hover: '#2E384B',
            active: '#2E384B',
        },
        '02': {
            normal: '#596D93',
            hover: '#4E6183',
            active: '#455573',
        },
        '03': {
            normal: '#9DABC4',
            hover: '#8C9CBA',
            active: '#6C81A7',
        },
        '04': {
            normal: '#FFFFFF',
            hover: '#F2F4F8',
            active: '#E7EBF1',
        },
        support01: {
            normal: '#C81423',
            hover: '#AD111E',
            active: '#940F1A',
        },
        support02: {
            normal: '#1D7C6B',
            hover: '#176356',
            active: '#114A40',
        },
        support03: {
            normal: '#126BC4',
            hover: '#105DAB',
            active: '#0D4F91',
        },
        support04: {
            normal: '#8A6E00',
            hover: '#705900',
            active: '#574500',
        },
        support05: {
            normal: '#A85210',
            hover: '#8F460E',
            active: '#75390B',
        },
    },
    icon: {
        primary: {
            ...primary,
            disabled: '#C6CEDD',
        },
        '01': {
            normal: '#1E2738',
            hover: '#2E384B',
            active: '#2E384B',
        },
        '02': {
            normal: '#596D93',
            hover: '#4E6183',
            active: '#455573',
        },
        '03': {
            normal: '#9DABC4',
            hover: '#8C9CBA',
            active: '#6C81A7',
        },
        '04': {
            normal: '#FFFFFF',
            hover: '#E7EBF1',
            active: '#E7EBF1',
        },
    },
    field: {
        '01': {
            normal: '#F3F4F7',
            hover: '#E7EBF1',
            active: '#D6DCE6',
        },
        '02': {
            normal: '#FFFFFF',
            hover: '#F3F4F7',
            active: '#E7EBF1',
        },
    },
    support: {
        '01': {
            normal: '#E81728',
            hover: '#CF1424',
            active: '#B5121F',
        },
        '02': {
            normal: '#49D4BB',
            hover: '#40BAA4',
            active: '#37A18E',
        },
        '03': {
            normal: '#3B94ED',
            hover: '#3584D4',
            active: '#2E74BA',
        },
        '04': {
            normal: '#EFC100',
            hover: '#D6AD00',
            active: '#BD9800',
        },
        '05': {
            normal: '#DB6B14',
            hover: '#C25F12',
            active: '#A8520F',
            background: {
                normal: '#FFEDE0',
                hover: '#F7E6D9',
                active: '#F0DFD2',
            },
        },
        bg01: {
            normal: '#FCDCDF',
            hover: '#F5D6D9',
            active: '#EDCFD2',
        },
        bg02: {
            normal: '#DEF7F3',
            hover: '#D7F0EC',
            active: '#D1E8E4',
        },
        bg03: {
            normal: '#E2EFFC',
            hover: '#DCE8F5',
            active: '#D5E1ED',
        },
        bg04: {
            normal: '#FFF8DB',
            hover: '#F7F1D4',
            active: '#F0E9CE',
        },
        bg05: {
            normal: '#FFEDE0',
            hover: '#F7E6D9',
            active: '#F0DFD2',
        },
    },
    meta: {
        '01': {
            normal: '#49D4BB',
            hover: '#35CFB3',
            active: '#2DBEA4',
        },
        '02': {
            normal: '#FF6B75',
            hover: '#FF525D',
            active: '#FF3846',
        },
        '03': {
            normal: '#219C85',
            hover: '#1C8773',
            active: '#187261',
            tint: {
                '01': {
                    normal: '#219C8514',
                    hover: '#219C8529',
                    active: '#219C853D',
                },
                '02': {
                    normal: '#219C853D',
                    hover: '#219C85A3',
                    active: '#219C85B8',
                },
            },
        },
        '04': {
            normal: '#DB4B55',
            hover: '#C82833',
            active: '#B72832',
            tint: {
                '01': {
                    normal: '#DB4B5514',
                    hover: '#DB4B5529',
                    active: '#DB4B553D',
                },
                '02': {
                    normal: '#DB4B553d',
                    hover: '#DB4B55A3',
                    active: '#DB4B55B8',
                },
            },
        },
        '05': {
            normal: '#32415D',
            hover: '#273349',
            active: '#202A3C',
            tint: {
                '01': {
                    normal: '#F4F5F6',
                    hover: '#E6E8EB',
                    active: '#CED2D8',
                },
                '02': {
                    normal: '#E2E3E6',
                    hover: '#9CA4B1',
                    active: '#8B94A4',
                },
            },
        },
    },
    overlay: {
        '01': {
            normal: '#2C3242CC',
            fade: '#2C324244',
        },
    },
    data: {
        '01': {
            normal: '#6C21DD',
        },
        '02': {
            normal: '#9637DB',
        },
        '03': {
            normal: '#C04DD8',
        },
        '04': {
            normal: '#E24FB4',
        },
        '05': {
            normal: '#EF487C',
        },
        '06': {
            normal: '#F15C47',
        },
        '07': {
            normal: '#E76F16',
        },
        '08': {
            normal: '#EB980B',
        },
        '09': {
            normal: '#EFC100',
        },
        '10': {
            normal: '#A3DB5F',
        },
        '11': {
            normal: '#49D4BB',
        },
        '12': {
            normal: '#42A1D6',
        },
        '13': {
            normal: '#3B6DF1',
        },
        '14': {
            normal: '#2F57C1',
        },
        '15': {
            normal: '#234191',
        },
    },
    brand: {
        '01': {
            normal: '#C4DBFA',
            hover: '#AACBF8',
            active: '#93BDF6',
        },
        '02': {
            normal: '#FCB6A6',
        },
        '03': {
            normal: '#F4F4F5',
        },
        '04': {
            normal: '#566281',
        },
        '05': {
            normal: '#1E2738',
        },
        '06': {
            normal: '#5D72AE',
        },
        '07': {
            normal: '#2E374B',
            hover: '#424F6B',
        },
        grad01: {
            start: '#C8D9F9',
            end: '#C8D9F9',
        },
    },
} as const
