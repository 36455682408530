import { Action, Close, Description } from '@radix-ui/react-toast'
import { IconTextButton } from 'Components/Button'
import { IconButton } from 'Components/Button/IconButton'
import * as support from 'Features/Support'
import { FC } from 'react'
import { StyledToast } from './styles'

type Props = {
    open: boolean
    onOpenChange: (open: boolean) => void
    description: string
}

export const ContactUsToast: FC<Props> = ({
    open,
    onOpenChange,
    description,
}) => {
    return (
        <StyledToast
            open={open}
            onOpenChange={onOpenChange}
            type="foreground"
            duration={10000}
        >
            <Description>{description}</Description>

            <Action altText="Contact us" asChild>
                <IconTextButton
                    icon="Comment"
                    text="Contact"
                    variant="secondary"
                    onClick={() => {
                        onOpenChange(false)
                        support.togglePopup()
                    }}
                    size="xSmall"
                />
            </Action>

            <Close asChild>
                <IconButton
                    iconName="Close"
                    aria-label="Dismiss"
                    variant="primary"
                />
            </Close>
        </StyledToast>
    )
}
