import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBusinessObjectDefinitionMutationVariables = Types.Exact<{
  input: Types.DeleteBusinessObjectDefinitionInput;
}>;


export type DeleteBusinessObjectDefinitionMutation = { __typename?: 'Mutation', deleteBusinessObjectDefinition?: boolean | null };


export const DeleteBusinessObjectDefinitionDocument = gql`
    mutation DeleteBusinessObjectDefinition($input: DeleteBusinessObjectDefinitionInput!) {
  deleteBusinessObjectDefinition(input: $input)
}
    `;
export type DeleteBusinessObjectDefinitionMutationFn = Apollo.MutationFunction<DeleteBusinessObjectDefinitionMutation, DeleteBusinessObjectDefinitionMutationVariables>;

/**
 * __useDeleteBusinessObjectDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessObjectDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessObjectDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessObjectDefinitionMutation, { data, loading, error }] = useDeleteBusinessObjectDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBusinessObjectDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessObjectDefinitionMutation, DeleteBusinessObjectDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessObjectDefinitionMutation, DeleteBusinessObjectDefinitionMutationVariables>(DeleteBusinessObjectDefinitionDocument, options);
      }
export type DeleteBusinessObjectDefinitionMutationHookResult = ReturnType<typeof useDeleteBusinessObjectDefinitionMutation>;
export type DeleteBusinessObjectDefinitionMutationResult = Apollo.MutationResult<DeleteBusinessObjectDefinitionMutation>;
export type DeleteBusinessObjectDefinitionMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessObjectDefinitionMutation, DeleteBusinessObjectDefinitionMutationVariables>;