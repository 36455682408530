import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { useLocalStorage } from 'Hooks/useLocalStorageState'
import { FC, useMemo } from 'react'
import { has } from '../../Utils'

type UncontrolledProps = {
    title: string
    feature: string
    content: React.ReactNode
    buttonSlot?: React.ReactNode
}

type ControlledProps = UncontrolledProps & {
    collapsed: boolean // providing this prop makes the component controlled
    onCollapsedChanged: (collapsed: boolean) => void
}

type Props = UncontrolledProps | ControlledProps

export const CollapsableLeftNav: FC<Props> = ({
    title,
    feature,
    content,
    buttonSlot,
    ...rest
}) => {
    const [uncontrolledCollapsed, setCollapsed] = useLocalStorage<
        'true' | 'false'
    >(`${feature}NavCollapsed`, 'false')

    const { changeCallback, collapsed } = useMemo(() => {
        if (has(rest, 'collapsed')) {
            return {
                changeCallback: rest.onCollapsedChanged,
                collapsed: rest.collapsed,
            }
        }

        const boolUncontrolledCollapsed = (() => {
            try {
                return JSON.parse(uncontrolledCollapsed) as boolean
            } catch (err) {
                setCollapsed('false')
                return false
            }
        })()

        return {
            changeCallback: (isCollapsed: boolean) =>
                setCollapsed(isCollapsed ? 'true' : 'false'),
            collapsed: boolUncontrolledCollapsed,
        }
    }, [rest, setCollapsed, uncontrolledCollapsed])

    return (
        <StyledContainer $collapsed={collapsed}>
            <section>
                <IconButton
                    onClick={() => changeCallback(!collapsed)}
                    iconName="NavListOpen"
                    className="collapse-icon"
                />

                {buttonSlot ? buttonSlot : null}
            </section>

            <Styled $collapsed={collapsed}>
                <header>
                    <Text as="h1" variant="bold-1">
                        {title}
                    </Text>
                </header>

                {content}
            </Styled>
        </StyledContainer>
    )
}

const StyledContainer = styled.div<{ $collapsed: boolean }>`
    border-right: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    width: ${({ theme, $collapsed }) =>
        $collapsed ? '3.5rem' : theme.structure.navColumnWidth};
    overflow: hidden;
    will-change: width;
    transition: width ${({ theme }) => theme.animation.mid} ease;
    position: relative;

    > section {
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        ${({ $collapsed }) =>
            $collapsed
                ? flex('column', 'center', 'center')
                : flex('row', 'center', 'center')};

        .collapse-icon {
            transition: transform ${({ theme }) => theme.animation.mid} ease;
            transform: ${({ $collapsed }) =>
                $collapsed ? 'rotateY(180deg)' : 'none'};
        }
    }
`

const Styled = styled.div<{ $collapsed: boolean }>`
    position: relative;
    z-index: 1;
    width: ${({ theme }) => theme.structure.navColumnWidth};
    padding: 2rem 1rem;
    transition: opacity ${({ theme }) => theme.animation.mid} ease;
    opacity: ${({ $collapsed }) => ($collapsed ? 0 : 1)};
    pointer-event: ${({ $collapsed }) => ($collapsed ? 'none' : 'all')};

    > header {
        padding: 0 1rem 1rem;
    }
`
