import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { TextAreaInput } from 'Components/Input'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { fromEvent } from 'Utils'
import { pipe } from 'lodash/fp'
import React, { useCallback, useState } from 'react'
import { useSuggestedPhases } from './useSuggestedPhases'

type Props = { description: string; onSuggested?: () => void }

export const SuggestionModal: React.FC<Props> = ({
    description,
    onSuggested,
}) => {
    const [suggestionInput, setSuggestionInput] = useState(description)
    const { getSuggestion, loading } = useSuggestedPhases(suggestionInput)

    const handleSuggestion = useCallback(() => {
        async function suggest() {
            await getSuggestion()
            onSuggested?.()
        }

        suggest()
    }, [getSuggestion, onSuggested])

    const errors =
        suggestionInput.length < 25
            ? 'Use at least 25 characters to describe your workflow'
            : ''

    return (
        <StyledForm>
            <label>What are you trying to build?</label>
            <TextAreaInput
                rows={5}
                value={suggestionInput}
                onChange={pipe(fromEvent, setSuggestionInput)}
            />
            {errors && (
                <Text as="p" variant="regular-6">
                    {errors}
                </Text>
            )}
            <TextButton
                onClick={handleSuggestion}
                disabled={loading || !!errors}
                text="Suggest phases"
            />
            {loading && <CenteredSpinner />}
        </StyledForm>
    )
}

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 40rem;
`
