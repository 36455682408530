import { DropDownTextButton } from 'Components/Button/DropDownTextButton'
import { MenuNavButton } from 'Components/Button/MenuNavButton'
import { MinimalModal } from 'Components/Modal/Minimal'
import { last } from 'lodash'
import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
    categories: { id: string; name: string; description: string }[]
    navOpen: boolean
    setNavOpen: (navOpen: boolean) => void
}

export const MobileNav: FC<Props> = ({ categories, navOpen, setNavOpen }) => {
    const navigate = useNavigate()

    const { pathname } = useLocation()

    const selectedRoute = useMemo(() => {
        const id = last(pathname.split('/'))
        const name = categories.find(category => category.id === id)?.name

        return { id, name }
    }, [categories, pathname])

    return (
        <>
            <DropDownTextButton
                text={selectedRoute.name ?? 'Start with a template'}
                onClick={() => setNavOpen(true)}
            />
            <MinimalModal open={navOpen} onOpenChange={setNavOpen}>
                <nav>
                    {categories.map(({ id, name, description }) => (
                        <MenuNavButton
                            key={id}
                            title={name}
                            description={description}
                            onClick={() => {
                                navigate(id)
                                setNavOpen(false)
                            }}
                            current={selectedRoute.id === id}
                            icon={'Cube'}
                        />
                    ))}
                </nav>
            </MinimalModal>
        </>
    )
}
