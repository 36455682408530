import { RadioGroup } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Dropdown, DropdownRadio } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import { find, first } from 'lodash'
import { FC, useMemo } from 'react'
import { FieldInputProps } from '../../types'

export const Radios: FC<FieldInputProps<'SelectFieldDefinition'>> = ({
    field,
    value,
    onChange,
}) => {
    const selectedOption = first(value)

    const selectedOptionText = useMemo(
        () =>
            find(field.selectOptions, option => option.id === selectedOption)
                ?.value ?? '',
        [field, selectedOption]
    )

    return (
        <Dropdown
            renderContent={() => (
                <RadioGroup>
                    {field.selectOptions.map(option => (
                        <DropdownRadio
                            key={option.id}
                            text={option.value}
                            value={selectedOption!}
                            isSelected={value?.includes(option.id) ?? false}
                            onSelect={() => {
                                onChange([option.id])
                            }}
                            closeOnSelect={true}
                        />
                    ))}
                </RadioGroup>
            )}
            renderOpenDropdownButton={() =>
                selectedOptionText ? (
                    <StyledOpenDropdownButtonIcon
                        icon={'CircleCheck'}
                        text={selectedOptionText}
                    />
                ) : (
                    <StyledOpenDropdownButton
                        text={'Select an option (required)'}
                    />
                )
            }
            variant="light"
        />
    )
}

const buttonStyles = css`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledOpenDropdownButton = styled(TextButton)`
    ${buttonStyles}
`

const StyledOpenDropdownButtonIcon = styled(IconTextButton)`
    ${buttonStyles}
`
