import { every, includes, isEqual, isNull, isUndefined } from 'lodash'
import { always, pipe } from 'lodash/fp'
import { P, match } from 'ts-pattern'
import { PrincipalFragment } from '../Features/Principal/__generated__/Principal'
import {
    BusinessObjectComparator,
    ConstantSelector,
    DeepSelector,
    FieldValueSelector,
} from '../__generated__'
import { getFieldValue } from './getFieldValue'

type FilterableBusinessObjectBooleanField = {
    __typename: 'BusinessObjectBooleanField'
    booleanValue?: boolean | null
}
type FilterableBusinessObjectTextField = {
    __typename: 'BusinessObjectTextField'
    textValue?: string | null
}
type FilterableBusinessObjectSelectField = {
    __typename: 'BusinessObjectSelectField'
    selectValue: string[]
    fieldDefinition: {
        selectOptions?: Array<{ id: string; value: string }>
    }
}
type FilterableBusinessObjectTelephoneField = {
    __typename: 'BusinessObjectTelephoneField'
    telephoneValue?: {
        countryCode?: string | null
        number?: string | null
    } | null
}
type FilterableBusinessObjectUrlField = {
    __typename: 'BusinessObjectUrlField'
    urlValue?: string | null
}
type FilterableBusinessObjectEmailField = {
    __typename: 'BusinessObjectEmailField'
    emailValue?: string | null
}
type FilterableBusinessObjectUserField = {
    __typename: 'BusinessObjectUserField'
    userValue?: { id: string; name: string } | null
}
type FilterableBusinessObjectDateField = {
    __typename: 'BusinessObjectDateField'
    dateValue?: string | null
}
type FilterableBusinessObjectDocumentField = {
    __typename: 'BusinessObjectDocumentField'
    docValue?: { id: string; name: string } | null
}
type FilterableBusinessObjectNumberField = {
    __typename: 'BusinessObjectNumberField'
    numValue?: number | null
}
type FilterableBusinessObjectRelationField = {
    __typename: 'BusinessObjectRelationField'
    relationValue?: { id: string; label: string } | null
}
type FilterableBusinessObjectListField = {
    __typename: 'BusinessObjectListField'
    listValue: Array<Omit<FilterableBusinessObjectField, 'fieldDefinition'>>
}
type FilterableBusinessObjectUpdatesField = {
    __typename: 'BusinessObjectUpdatesField'
    updatesValue?: {
        createdBy: PrincipalFragment
        createdAt: string
        richText: string
    } | null
}
type FilterableBusinessObjectCurrencyField = {
    __typename: 'BusinessObjectCurrencyField'
    currencyValue?: {
        currencyDetails: {
            symbol: string
            name: string
            nativeSymbol: string
            decimalDigits: number
            rounding: number
            code: string
            namePlural: string
        }
        amount: number
    } | null
}

type FieldDefinition = {
    fieldDefinition: {
        id: string
    }
}

export type FilterableBusinessObjectField = FieldDefinition &
    (
        | FilterableBusinessObjectBooleanField
        | FilterableBusinessObjectTextField
        | FilterableBusinessObjectSelectField
        | FilterableBusinessObjectTelephoneField
        | FilterableBusinessObjectUrlField
        | FilterableBusinessObjectEmailField
        | FilterableBusinessObjectUserField
        | FilterableBusinessObjectDateField
        | FilterableBusinessObjectDocumentField
        | FilterableBusinessObjectNumberField
        | FilterableBusinessObjectRelationField
        | FilterableBusinessObjectListField
        | FilterableBusinessObjectUpdatesField
        | FilterableBusinessObjectCurrencyField
    )

export type FilterableBusinessObject = {
    id: string
    definition: {
        id: string
        label: string
    }
    fields: Array<FilterableBusinessObjectField>
}

export const applySelector = (
    selector: ConstantSelector | FieldValueSelector | DeepSelector
) => {
    return match(selector)
        .with({ value: P.string }, s => () => always(JSON.parse(s.value))())
        .with({ fieldId: P.string }, s => (bo: FilterableBusinessObject) => {
            const field = bo.fields.find(
                f => f.fieldDefinition.id === s.fieldId
            )

            return field ? getFieldValue(field) : s.default
        })
        .with({ selectors: P.any }, () => {
            throw new Error('TODO')
        })
        .exhaustive()
}

export const applyComparator =
    (comparator: Exclude<BusinessObjectComparator, '__typename'>) =>
    (selectedValue: any) => {
        const nonNegatedCompare = operatorFns[comparator.operator]
        const compare = comparator.negate
            ? pipe(nonNegatedCompare, v => !v)
            : nonNegatedCompare

        const withVal = JSON.parse(comparator.with)

        return compare(withVal, selectedValue)
    }

const idEqual = (
    a: { id: string } | undefined,
    b: { id: string } | undefined
) => {
    if (isUndefined(a) || isUndefined(b) || isNull(a) || isNull(b)) {
        return false
    }
    return a.id === b.id
}

export const operatorFns = {
    equals: (a: any, b: any) => isEqual(a, b) ?? idEqual(a, b),
    includes: <T>(a: T, b: T[]) =>
        Array.isArray(a)
            ? every(a, element => includes(b, element))
            : b.includes(a),
    isDefined: (_a: unknown, b: unknown) =>
        Array.isArray(b) ? b.length > 0 : !isUndefined(b) && !isNull(b),
    greaterThan: (a: number | string | null, b: number | string | null) => {
        if (a === null || b === null) return false
        return a < b
    },
    lessThan: (a: number | string | null, b: number | string | null) => {
        if (a === null || b === null) return false
        return a > b
    },
}
