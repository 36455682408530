import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { ComponentProps, FC } from 'react'
import { match } from 'ts-pattern'
import {
    DateRangeConstraint,
    FieldConstraintType,
    LengthConstraint,
    NumberOfSelectionsConstraint,
    RangeConstraint,
    RequiredConstraint,
} from '__generated__'
import { DateRange } from './DateRange'
import { Length } from './Length'
import { NumberOfSelections } from './NumberOfSelections'
import { Range } from './Range'
import { RelationType } from './RelationType'
import { Required } from './Required'

const Constraints: FC<{
    constraints: Array<
        | RequiredConstraint
        | LengthConstraint
        | RangeConstraint
        | NumberOfSelectionsConstraint
        | ComponentProps<typeof RelationType>
        | DateRangeConstraint
    >
    isEditing: boolean
    onConstraintRemoved?: (constraintType: FieldConstraintType) => () => void
}> = ({ constraints, isEditing, onConstraintRemoved }) =>
    constraints.length ? (
        <Styled>
            {constraints.map((c, i) => (
                <li key={i}>
                    {match(c)
                        .with(
                            {
                                __typename: 'RequiredConstraint',
                            },
                            () => <Required />
                        )
                        .with(
                            { __typename: 'LengthConstraint' },
                            constraint => <Length {...constraint} />
                        )
                        .with({ __typename: 'RangeConstraint' }, constraint => (
                            <Range {...constraint} />
                        ))
                        .with(
                            { __typename: 'NumberOfSelectionsConstraint' },
                            constraint => <NumberOfSelections {...constraint} />
                        )
                        .with(
                            { __typename: 'RelationTypeConstraint' },
                            constraint => <RelationType {...constraint} />
                        )
                        .with(
                            { __typename: 'DateRangeConstraint' },
                            constraint => <DateRange {...constraint} />
                        )
                        .with({ __typename: undefined }, () => null)
                        .exhaustive()}
                    <span>
                        {isEditing ? (
                            <TextButton
                                variant="secondary"
                                text="Remove constraint"
                                onClick={onConstraintRemoved?.(c.type)}
                            />
                        ) : null}
                    </span>
                </li>
            ))}
        </Styled>
    ) : (
        <Text as="p" variant="regular-4" className="none-set">
            No constraints set
        </Text>
    )

const Styled = styled.ul`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    padding: 0.75rem 1.25rem;

    li {
        display: contents;

        > span {
            grid-column-start: span 2;
        }
    }

    button {
        min-height: 0;
    }
`

export { Constraints }
