import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    handleStartFromScratch: () => void
    disabled: boolean
}

export const FromScratch: FC<Props> = ({
    handleStartFromScratch,
    disabled,
}) => {
    return (
        <StyledFromScratch $disabled={disabled}>
            <Text as="h2" variant="bold-4">
                Build from scratch:
            </Text>

            <Text as="p" variant="regular-4">
                You'll have total freedom to define your dataset however you'd
                like.
            </Text>

            <TextButton
                text="I'll start from scratch"
                type="button"
                variant="primary"
                onClick={handleStartFromScratch}
                size="small"
                disabled={disabled}
            />
        </StyledFromScratch>
    )
}

export const StyledFromScratch = styled.div<{ $disabled: boolean }>`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1rem;
    padding: 1.5rem 2rem;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    width: 100%;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

    a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }

    button {
        margin-top: 0;
        width: 100%;
    }
`
