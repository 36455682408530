import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { BusinessObjectTableItemFragment } from 'Fragments/__generated__/BusinessObjectTableItemFragment'
import { FC, useCallback } from 'react'
import { Card } from '../../../Card/Card'

type Props = {
    businessObjects: BusinessObjectTableItemFragment[]
    businessObjectDefinitionId: string
}

export const CardDisplay: FC<Props> = ({
    businessObjects,
    businessObjectDefinitionId,
}) => {
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'bo-card-display',
    })

    const openDrawer = useCallback(
        (id: string) => {
            boDisplayProps.open({
                id,
                tab: 'details',
            })
        },
        [boDisplayProps]
    )

    return (
        <StyledCardDisplay>
            {businessObjects.length ? (
                businessObjects.map(bo => (
                    <Card
                        businessObject={bo}
                        key={bo.id}
                        openDrawer={openDrawer}
                    />
                ))
            ) : (
                <Text variant="regular-4" as="p">
                    <em>
                        No <strong>records</strong> to show for this{' '}
                        <strong>dataset</strong>
                    </em>
                </Text>
            )}

            <BusinessObjectDisplay
                {...boDisplayProps}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        </StyledCardDisplay>
    )
}

const StyledCardDisplay = styled.section`
    padding: 2rem 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: auto;
    gap: 1rem;

    > p {
        grid-column: 1 / -1;
        text-align: center;
    }
`
