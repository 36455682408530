import { FC, ReactNode } from 'react'
import { StyledErrorMessage, StyledInputGroup } from './styles'

// A wrapper around a field that provides a label, error message etc.

type Props = {
    children: ReactNode
    htmlFor: string
    name: string
    required?: boolean
    errors?: string[]
    labelExtras?: ReactNode
}

export const Field: FC<Props> = ({
    children,
    htmlFor,
    errors,
    name,
    required,
    labelExtras,
}) => {
    return (
        <StyledInputGroup>
            <label htmlFor={htmlFor}>
                {name} {required ? <strong>*</strong> : null} {labelExtras}
            </label>

            <div>{children}</div>

            <StyledErrorMessage>
                {errors?.map(message => message)}
            </StyledErrorMessage>
        </StyledInputGroup>
    )
}
