import { IconButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { ReactEventHandler, ReactNode } from 'react'
import { fromEvent } from 'Utils'
import { FieldShared } from '../../types'
import { Field } from './Field'
import { StyledMotionFormSection, StyledSectionHeader } from './styles'

type Props = {
    index: number
    label: string
    field: FieldShared
    onChange: <K extends keyof FieldShared>(
        prop: K
    ) => (get: (prev: string | undefined | null) => string) => void
    onRemoved?: ReactEventHandler
    children?: React.ReactNode | React.ReactNode[]
    tooltip?: ReactNode
}

export const FieldCommonWrapper: React.FC<Props> = ({
    index,
    label,
    field,
    onChange,
    onRemoved,
    children,
    tooltip,
}) => (
    <StyledMotionFormSection
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            duration: 0.3,
        }}
    >
        <StyledSectionHeader>
            <Text as="h5" variant="bold-3">
                {label} Field
            </Text>
            {tooltip ? tooltip : null}
            {onRemoved && (
                <IconButton
                    iconName="Close"
                    type="button"
                    onClick={onRemoved}
                />
            )}
        </StyledSectionHeader>
        <Field htmlFor={`bod-field-name-${index}`} name="Name" required>
            <TextInput
                id={`bod-field-name-${index}`}
                required
                value={field.name}
                onChange={e => onChange('name')(() => fromEvent(e))}
            />
        </Field>
        <Field htmlFor={`bod-field-description-${index}`} name="Description">
            <TextInput
                id={`bod-field-description-${index}`}
                value={field.description ?? ''}
                onChange={e => onChange('description')(() => fromEvent(e))}
            />
        </Field>
        {children}
    </StyledMotionFormSection>
)
