import { IconTextButton } from 'Components/Button'
import { useBusinessObjectsView } from 'Features/BusinessObjectView/useBusinessObjectsView'
import { FC } from 'react'
import { match } from 'ts-pattern'

export const DisplayControl: FC = () => {
    const { display, setDisplay } = useBusinessObjectsView()

    return (
        <IconTextButton
            icon="View"
            text={match(display)
                .with('card', () => 'Card')
                .with('table', () => 'Table')
                .exhaustive()}
            onClick={() => setDisplay(display === 'table' ? 'card' : 'table')}
            size="xSmall"
        />
    )
}
