import { opt } from 'Utils'
import { compact } from 'lodash'
import { ProcessDisplay, ViewConfig } from '../../../../types'

type UseDisplayFields = (_: {
    obj: ProcessDisplay['phases'][number]['groups'][number]['businessObjects'][number]
    cardSettings: ViewConfig['cardSettings']
}) => {
    fields: {
        id: string
        label: string
        field: ProcessDisplay['phases'][number]['groups'][number]['businessObjects'][number]['fields'][number]
    }[]
}

export const useDisplayFields: UseDisplayFields = ({
    obj,
    cardSettings: { fields, selected },
}) => ({
    fields: compact(
        fields.map(
            ({ id, label }) =>
                selected.includes(id) &&
                opt(
                    obj.fields.find(
                        ({ fieldDefinition }) => fieldDefinition.id === id
                    ),
                    field => ({ id, label, field }),
                    () => false
                )
        )
    ),
})
