import { css } from 'Adapters/Freestyled'

export const pipStyles = css`
    position: relative;

    &:after {
        content: '';
        height: 5px;
        width: 5px;
        background-color: ${({ theme }) => theme.palette.support['01'].normal};
        border-radius: 50%;
        top: 0.2rem;
        right: 0.2rem;
        position: absolute;
    }
`
