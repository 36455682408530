import { dayjs } from 'Adapters/DayJS'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { regular5 } from 'Components/Text'
import { useModalControls } from 'Hooks'
import { FC, useEffect, useState } from 'react'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from '../../../../../Hooks/useCreateFormState'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useDateConstraints } from './useDateConstraints'

type Props = BODField<'DateFieldDefinition'> & {
    listName: string
    listId: string
}

export const DateListField: FC<Props> = ({
    dateConstraints,
    listName,
    listId,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useDateConstraints({
        dateConstraints,
    })

    const [values, setValues] = useState<string[]>([])

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: values.map(date => ({ date })),
            },
        })
    }, [values, dispatch, listId])

    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[listId].errors ?? []}
            list={values.map((date, i) => (
                <StyledListItem key={i}>
                    <span>{dayjs(date).format('DD MMM YYYY')}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev =>
                                prev.filter(dateValue => dateValue !== date)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <StyledOpenDatePickerButton
                icon="Calendar"
                text={'Add a date'}
                onClick={e => {
                    e.preventDefault() // prevent from firing onSubmit
                    openModal()
                }}
                ref={buttonRef}
            />

            <DatePicker
                open={open}
                onOpenChange={setOpen}
                title={`Choose a date`}
                description={`Choose a date to add to the list`}
                onConfirm={({ date }) => {
                    setValues(prev => [...prev, date.toISOString()])
                    closeModal()
                }}
                returnFocus={returnFocus}
                limit={{
                    from: constraints.startAfter
                        ? new Date(constraints.startAfter)
                        : undefined,
                    to: constraints.endBefore
                        ? new Date(constraints.endBefore)
                        : undefined,
                }}
            />
        </ListFieldWrapper>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > span {
        overflow-wrap: break-word;
        word-break: break-all;
    }

    > button {
        margin-left: auto;
    }
`

const StyledOpenDatePickerButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
