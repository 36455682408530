import { Icon } from 'Components/Icon'
import { Spinner } from 'Components/Spinner'
import { noop } from 'lodash'
import { forwardRef } from 'react'
import { match } from 'ts-pattern'
import { StyledStatusButton } from './styles'
import { Size, Status, StatusText } from './types'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    status?: Status
    text: StatusText
    size?: Size
}

export const StatusButton = forwardRef<HTMLButtonElement, Props>(
    ({ status = 'ready', text, size = 'small', ...props }, ref) => (
        <StyledStatusButton
            onClick={props['aria-disabled'] ? noop : props.onClick} // Disabling behavior only, so button doesn't lose focus
            $status={status}
            ref={ref}
            $size={size}
            {...props}
        >
            <span>{text[status]}</span>
            {match(status)
                .with('loading', () => <Spinner variant="light" />)
                .with('success', () => <Icon name="Check" />)
                .with('error', () => <Icon name="ErrorCircle" />)
                .with('ready', () => <Icon name="Save" />)
                .exhaustive()}
        </StyledStatusButton>
    )
)
