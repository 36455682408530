import { styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { useActionsView } from 'Features/ActionView'
import { ActionsList } from 'Features/ActionsList'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { useFilterActions, useSortActions } from '../Hooks'
import { ActionsHeader } from './ActionsHeader'
import { GroupedActionsList } from './GroupedActionsList'
import { NoFilteredResults } from './NoFilteredResults'
import { ViewBar } from './ViewBar'

type Props = {
    currentUserId: string
}

const Small: FC<Props> = ({ currentUserId }) => {
    const actionsResult = useFilterActions(currentUserId)

    const sortedActions = useSortActions(actionsResult.actions)

    const { grouping } = useActionsView()

    return (
        <Styled>
            <ActionsHeader />
            <StyledMain>
                {match({ ...actionsResult })
                    .with({ hasInitiallyLoaded: false }, () => (
                        <CenteredSpinner />
                    ))
                    .with({ actions: [] }, () => <NoFilteredResults />)
                    .otherwise(() =>
                        grouping ? (
                            <GroupedActionsList
                                actions={sortedActions}
                                grouping={grouping}
                            />
                        ) : (
                            <ActionsList actions={sortedActions} />
                        )
                    )}
            </StyledMain>
            <ViewBar
                extension={actionsResult.extension}
                currentUserId={currentUserId}
                allAssignablePrincipals={actionsResult.allAssignablePrincipals}
            />
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto minmax(0, 1fr) auto / minmax(0, 1fr);
    background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );
`

const StyledMain = styled.div`
    padding: 0 4vw;
    overflow: auto;
`

export { Small }
