import { FC } from 'react'
import { FieldType } from '../../../../__generated__'
import { FieldInputProps } from '../../types'

export const ListField: FC<FieldInputProps<'ListFieldDefinition'>> = ({
    field,
    value,
    onChange,
}) => {
    if (field.listOf.type === FieldType.Updates) {
        return null
    }
    return <>To be implemented</>
}
