import { Size, Variant } from 'Components/Button/TextButton/types'
import { CreatorModal } from 'Features/CreatorModal'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { Details } from './Details'
import { GettingStarted } from './GettingStarted'
import { Definitions } from './GettingStarted/Definitions'
import { Generate } from './GettingStarted/Generate'
import { Templates } from './GettingStarted/Templates'
import {
    BusinessObjectDefinitionsCreatorQuery,
    useBusinessObjectDefinitionsCreatorQuery,
} from './__generated__/q'
import { Step, useCreator } from './useCreator'

type Props = {
    buttonSize?: Size
    buttonVariant?: Variant
    navStyleButton?: true
    onComplete?: (id: string) => void
}

const Container: FC<Props> = props =>
    match(useBusinessObjectDefinitionsCreatorQuery())
        .with({ data: P.not(P.nullish) }, ({ data }) => (
            <Content data={data} {...props} />
        ))
        .otherwise(() => null)

const Content: FC<{ data: BusinessObjectDefinitionsCreatorQuery } & Props> = ({
    data,
    buttonSize,
    buttonVariant,
    navStyleButton,
    onComplete,
}) => {
    const {
        state,
        dispatch,
        getSuggestion,
        suggestionToastProps,
        createToastProps,
        saveDefinition,
        errorMessages,
    } = useCreator({ data, onComplete })

    return (
        <CreatorModal
            title="Dataset Creator"
            description=""
            navStyleButton={navStyleButton}
            trigger={{
                text: 'Create new dataset',
                size: buttonSize,
                variant: buttonVariant,
            }}
            renderContent={() =>
                match(state.step)
                    .with(Step.GetStarted, () => (
                        <GettingStarted dispatch={dispatch} />
                    ))
                    .with(Step.AI, () => (
                        <Generate
                            prompt={state.suggestionPrompt}
                            onPromptChange={suggestionPrompt => {
                                dispatch({
                                    type: 'changePrompt',
                                    payload: { suggestionPrompt },
                                })
                            }}
                            onPromptSubmit={getSuggestion}
                            loadingSuggestion={state.loadingSuggestion}
                            restart={() => {
                                dispatch({
                                    type: 'restart',
                                    payload: undefined,
                                })
                            }}
                        />
                    ))
                    .with(Step.Template, () => (
                        <Templates
                            templates={state.templates}
                            onTemplateSelected={template => {
                                dispatch({
                                    type: 'selectTemplate',
                                    payload: { template },
                                })
                            }}
                            restart={() => {
                                dispatch({
                                    type: 'restart',
                                    payload: undefined,
                                })
                            }}
                            suggestionToastProps={suggestionToastProps}
                        />
                    ))
                    .with(Step.Existing, () => (
                        <Definitions
                            definitions={state.definitions}
                            onDefinitionSelected={definition => {
                                dispatch({
                                    type: 'selectExisting',
                                    payload: { definition },
                                })
                            }}
                            restart={() => {
                                dispatch({
                                    type: 'restart',
                                    payload: undefined,
                                })
                            }}
                        />
                    ))
                    .with(Step.Details, () => (
                        <Details
                            state={state}
                            dispatch={dispatch}
                            createToastProps={createToastProps}
                            onSave={saveDefinition}
                            errorMessages={errorMessages}
                        />
                    ))
                    .exhaustive()
            }
            open={state.open}
            setOpen={open =>
                dispatch({
                    type: open ? 'openCreator' : 'closeCreator',
                    payload: undefined,
                })
            }
            confirmationDescription="If you close the creator now, you will lose all your changes."
            fullScreen
        />
    )
}

export { Container as BusinessObjectDefinitionCreator }
