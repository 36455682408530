import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import Dark from './assets/Dark.png'
import Light from './assets/Light.png'

type Props = {
    variant?: 'light' | 'dark'
}

export const Spinner: FC<Props> = ({ variant = 'dark' }) => (
    <StyledSpinner className="spinner">
        <img
            alt="spinner"
            src={variant === 'dark' ? Dark : Light}
            width={40}
            height={40}
        />
    </StyledSpinner>
)

const rotation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
`

const StyledSpinner = styled.span`
    display: inline-block;
    overflow: hidden;
    width: 2.5rem;
    height: 2.5rem;

    img {
        animation: ${rotation} 1s infinite linear;
        display: block;
        width: 100%;
        height: 100%;
    }
`
