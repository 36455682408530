import { flex, styled } from 'Adapters/Freestyled'
import { Grouping, useActionsView } from 'Features/ActionView'
import { FC } from 'react'
import { Extension } from '../../types'
import { ApplyView } from './ApplyView/ApplyView'
import { CardSettings } from './CardSettings'
import { DisplayControl } from './DisplayControl'
import { Filters } from './Filters'
import { SaveView } from './SaveView'
import { Sort } from './Sort'

type Props = {
    extension: Extension | undefined
    currentUserId: string
    allAssignablePrincipals: { id: string; name: string }[]
}

export const ViewBar: FC<Props> = ({
    extension,
    currentUserId,
    allAssignablePrincipals,
}) => {
    const { display } = useActionsView()

    return (
        <Styled>
            <menu>
                <div>
                    <DisplayControl />

                    {display !== 'table' && !!extension ? (
                        <>
                            <CardSettings
                                fields={extension.fields
                                    // Ignore updates
                                    .filter(
                                        field =>
                                            !(
                                                field.__typename ===
                                                    'ListFieldDefinition' &&
                                                field.listOf.__typename ===
                                                    'UpdatesFieldDefinition'
                                            )
                                    )
                                    .map(field => ({
                                        id: field.id,
                                        label: field.name,
                                        type: field.type,
                                    }))}
                            />

                            <Sort
                                extensionFields={extension.fields
                                    .filter(
                                        field =>
                                            !(
                                                field.__typename ===
                                                    'ListFieldDefinition' &&
                                                field.listOf.__typename ===
                                                    'UpdatesFieldDefinition'
                                            )
                                    )
                                    .map(field => ({
                                        id: field.id,
                                        label: field.name,
                                        type: field.type,
                                    }))}
                            />
                        </>
                    ) : null}

                    {display === 'card' && !!extension ? (
                        <Grouping
                            fields={extension.fields}
                            allAssignablePrincipals={allAssignablePrincipals}
                        />
                    ) : null}

                    <Filters extension={extension} />
                </div>

                <div>
                    <SaveView />
                    <ApplyView
                        currentUserId={currentUserId}
                        extension={extension}
                    />
                </div>
            </menu>
        </Styled>
    )
}

const Styled = styled.div`
    padding: 0 3rem;

    menu {
        ${flex('row', 'space-between', 'stretch')};
        width: 100%;
        gap: 5rem;
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
        border-radius: ${({ theme }) => theme.borderRadius.small};

        > div {
            ${flex('row', 'flex-start', 'center')};
            gap: 1rem;
            padding: 0.25rem 0.5rem;
            background-color: ${({ theme }) => theme.palette.ui['03'].normal};

            &:first-of-type {
                border-radius: 0.25rem 0 0 0.25rem;
            }

            &:last-of-type {
                border-radius: 0.25rem 0 0 0.25rem;
            }
        }
    }
`
