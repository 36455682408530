import { Icon } from 'Components/Icon'
import { forwardRef } from 'react'
import { StyledIconButton } from './styles'
import { IconButtonProps } from './types'

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ iconName, hideTitle = false, variant = 'default', ...props }, ref) => {
        return (
            <StyledIconButton {...props} ref={ref} $variant={variant}>
                <Icon name={iconName} hideTitle={hideTitle} />
            </StyledIconButton>
        )
    }
)
