import { breakpoints, styled } from 'Adapters/Freestyled'
import { Fragment } from 'react'
import { useProcessCreator } from '../useProcessCreator'
import { InsertPhase } from './InsertPhase'
import { Phase } from './Phase'

export const Phases = () => {
    const {
        state: { input },
    } = useProcessCreator()

    return (
        <StyledPhases>
            <ul>
                {input.phases.map((_, i) => (
                    <Fragment key={i}>
                        <InsertPhase index={i} />
                        <Phase index={i} />
                    </Fragment>
                ))}
                <InsertPhase index={input.phases.length + 1} />
            </ul>
        </StyledPhases>
    )
}

const StyledPhases = styled.div`
    overflow: auto;
    padding-bottom: 1rem;

    &:last-child {
        flex-grow: 1;
    }

    > ul {
        display: flex;
        gap: 1rem;

        height: 100%;

        &::after {
            content: '';
            flex: 0 0 1rem;
        }

        @media (min-width: ${breakpoints.large}px) {
            padding-left: 2rem;
        }
    }
`
