import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcessCreator_BusinessObjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProcessCreator_BusinessObjectsQuery = { __typename?: 'Query', businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }> };


export const ProcessCreator_BusinessObjectsDocument = gql`
    query ProcessCreator_BusinessObjects {
  businessObjectDefinitions {
    id
    name
  }
}
    `;

/**
 * __useProcessCreator_BusinessObjectsQuery__
 *
 * To run a query within a React component, call `useProcessCreator_BusinessObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessCreator_BusinessObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessCreator_BusinessObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessCreator_BusinessObjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessCreator_BusinessObjectsQuery, ProcessCreator_BusinessObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessCreator_BusinessObjectsQuery, ProcessCreator_BusinessObjectsQueryVariables>(ProcessCreator_BusinessObjectsDocument, options);
      }
export function useProcessCreator_BusinessObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessCreator_BusinessObjectsQuery, ProcessCreator_BusinessObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessCreator_BusinessObjectsQuery, ProcessCreator_BusinessObjectsQueryVariables>(ProcessCreator_BusinessObjectsDocument, options);
        }
export type ProcessCreator_BusinessObjectsQueryHookResult = ReturnType<typeof useProcessCreator_BusinessObjectsQuery>;
export type ProcessCreator_BusinessObjectsLazyQueryHookResult = ReturnType<typeof useProcessCreator_BusinessObjectsLazyQuery>;
export type ProcessCreator_BusinessObjectsQueryResult = Apollo.QueryResult<ProcessCreator_BusinessObjectsQuery, ProcessCreator_BusinessObjectsQueryVariables>;