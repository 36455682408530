import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Tutorial } from 'Features/Tutorials'
import { useModalControls, useResponder } from 'Hooks'
import { useState } from 'react'
import { Card } from './Card'
import { StyledCards } from './Cards'
import { CreateWebhookModal } from './CreateWebhookModal'
import { PostCreationModal } from './PostCreationModal'
import { WebhookFragment, useWebhooksQuery } from './__generated__/q'
import { slides } from './tutorial/slides'

export const Webhooks = () => {
    const { data, refetch } = useWebhooksQuery()
    const createModal = useModalControls()
    const postCreateModal = useModalControls()
    const [createdWebhook, setCreatedWebhook] = useState<{
        id: string
        signingSecret: string | undefined
    }>()

    const [editingWebhook, setEditingWebhook] = useState<WebhookFragment>()

    const { isLarge } = useResponder()

    const webhooks = data?.webhooks ?? []

    const editWebhook = (webhook: WebhookFragment) => () => {
        setEditingWebhook(webhook)
        createModal.openModal()
    }

    return (
        <Styled>
            <StyledMenu>
                <Tutorial markdown={slides} />
                <TextButton
                    ref={createModal.buttonRef}
                    onClick={createModal.openModal}
                    text="Create Webhook"
                />
            </StyledMenu>

            <StyledCards $isLarge={isLarge}>
                <ul>
                    {webhooks.map(webhook => (
                        <Card
                            key={webhook.id}
                            webhook={webhook}
                            editWebhook={editWebhook(webhook)}
                        />
                    ))}
                </ul>
            </StyledCards>

            <ControlledModal
                title={
                    <StyledModalTitle>
                        <Tutorial markdown={slides} />
                        {editingWebhook ? 'Edit webhook' : 'Create webhook'}
                    </StyledModalTitle>
                }
                description=""
                hideDescription
                open={createModal.open}
                onOpenChange={open => {
                    createModal.setOpen(open)
                    if (!open) {
                        setEditingWebhook(undefined)
                    }
                }}
                returnFocus={createModal.returnFocus}
                renderContent={() => (
                    <CreateWebhookModal
                        initialWebhook={editingWebhook}
                        onCompleted={({ webhook, signingSecret }) => {
                            refetch()
                            setCreatedWebhook({
                                id: webhook.id,
                                signingSecret,
                            })
                            setEditingWebhook(undefined)
                            createModal.closeModal()
                            postCreateModal.openModal()
                        }}
                    />
                )}
            />

            <ControlledModal
                title="Webhook created"
                description=""
                hideDescription
                open={postCreateModal.open}
                onOpenChange={open => {
                    postCreateModal.setOpen(open)
                    if (!open) {
                        setCreatedWebhook(undefined)
                    }
                }}
                returnFocus={postCreateModal.returnFocus}
                renderContent={() =>
                    createdWebhook ? (
                        <PostCreationModal
                            webhookId={createdWebhook.id}
                            signingSecret={createdWebhook.signingSecret}
                        />
                    ) : null
                }
            />
        </Styled>
    )
}

const Styled = styled.section`
    padding: 2rem 1rem;
    height: 100%;
`

const StyledMenu = styled.div`
    ${flex('row')}
    gap: 0.6rem;
    padding-left: 2rem;
`

const StyledModalTitle = styled.div`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
`
