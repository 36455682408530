import { styled } from 'Adapters/Freestyled'
import { IconTextButton, TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { partition } from 'lodash'
import { FC, useMemo, useState } from 'react'
import {
    Available_IntegrationsQuery,
    useAvailable_IntegrationsQuery,
} from './__generated__/query'

import Nango from '@nangohq/frontend'
import { IntegrationImage } from 'Components/Integrations'
import { ControlledModal } from 'Components/Modal'
import { useModalControls } from 'Hooks'
import { useDisconnect_ProviderMutation } from './__generated__/mutation'

const nango = new Nango({ publicKey: import.meta.env.VITE_NANGO_PUBLIC_KEY })

type Integration = Available_IntegrationsQuery['availableIntegrations'][number]

export const IntegrationProviders: FC = () => {
    const { buttonRef, returnFocus } = useModalControls()
    const [disconnectingIntegration, setDisconnectingIntegration] =
        useState<Integration>()

    const { data, refetch } = useAvailable_IntegrationsQuery()
    const [disconnectMutation, { loading: isDisconnecting }] =
        useDisconnect_ProviderMutation()

    const [connectedIntegrations, disconnectedIntegrations] = useMemo(
        () =>
            !data
                ? [[], []]
                : partition(
                      data.availableIntegrations,
                      integration => integration.connection
                  ),
        [data]
    )

    const tenantId = data?.currentUser.activeTenant?.tenant.id

    const connect = async (integration: Integration) => {
        if (!tenantId) return
        await nango.auth(integration.name, tenantId)
        refetch()
    }

    const disconnect = async (integration: Integration) => {
        await disconnectMutation({
            variables: {
                input: { name: integration.name },
            },
        })
        setDisconnectingIntegration(undefined)
        refetch()
    }

    const handleDisconnect = async (integration: Integration) => {
        if (integration.instances.length > 0) {
            setDisconnectingIntegration(integration)
        } else {
            disconnect(integration)
        }
    }

    return (
        <>
            <ControlledModal
                open={!!disconnectingIntegration}
                onOpenChange={() => setDisconnectingIntegration(undefined)}
                returnFocus={returnFocus}
                title="Disconnect Integration"
                description=""
                hideDescription
                renderContent={() => (
                    <DisconnectModalContents>
                        <Text as="p" variant="regular-5">
                            This provider is used in{' '}
                            {disconnectingIntegration?.instances.length}{' '}
                            integration(s) - disconnecting it will break them.
                        </Text>
                        <IconTextButton
                            icon="ExclamationTriangle"
                            text="Disconnect anyway"
                            disabled={isDisconnecting}
                            onClick={() =>
                                disconnect(disconnectingIntegration!)
                            }
                        />
                    </DisconnectModalContents>
                )}
            />
            {connectedIntegrations.length === 0 ? null : (
                <>
                    <Text as="h2" variant="bold-4">
                        Connected
                    </Text>
                    <IntegrationGrid>
                        {connectedIntegrations.map(integration => (
                            <IntegrationItem key={integration.name}>
                                <IntegrationImage
                                    name={integration.name}
                                    alt={integration.displayName}
                                />
                                <IntegrationDetails>
                                    <Text as="p" variant="regular-5">
                                        {integration.displayName}
                                    </Text>
                                    <TextButton
                                        onClick={() =>
                                            handleDisconnect(integration)
                                        }
                                        variant="secondary"
                                        text="Disconnect"
                                        ref={buttonRef}
                                    />
                                </IntegrationDetails>
                            </IntegrationItem>
                        ))}
                    </IntegrationGrid>
                </>
            )}
            <Text as="h2" variant="bold-4">
                Available
            </Text>
            <IntegrationGrid>
                {disconnectedIntegrations.map(integration => (
                    <IntegrationItem key={integration.name}>
                        <IntegrationImage
                            name={integration.name}
                            alt={integration.displayName}
                        />
                        <IntegrationDetails>
                            <Text as="p" variant="regular-5">
                                {integration.displayName}
                            </Text>
                            <TextButton
                                onClick={() => connect(integration)}
                                variant="secondary"
                                text="Connect"
                            />
                        </IntegrationDetails>
                    </IntegrationItem>
                ))}
            </IntegrationGrid>
        </>
    )
}

const IntegrationGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 0rem;
`

const IntegrationItem = styled.div`
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
    border-radius: 0.25rem;

    > img {
        padding: 1rem 2.6rem;
    }
`

const IntegrationDetails = styled.div`
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;

    button {
        width: 100%;
    }
`

const DisconnectModalContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
`
