import { useAuth0 } from '@auth0/auth0-react'
import { useResponder } from 'Hooks'
import { useSandbox } from 'Hooks/useSandbox'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'

export const EnterSandbox = () => {
    const sandbox = useSandbox()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const { isLoading, isAuthenticated } = useAuth0()

    const category = params.get('category')
    const templates = params.get('templates')
    const startWithAI = params.get('startWithAI')

    const { isLarge } = useResponder()

    const url = match({ category, templates, startWithAI })
        .with(
            {
                category: P.string,
                templates: P.string,
            },
            ({ category, templates }) =>
                `/welcome?category=${category}&templates=${templates}`
        )
        .with(
            { startWithAI: 'true' },
            () =>
                `/manage-workspace${
                    isLarge ? '' : '/model'
                }/processes?creator=open`
        )
        .otherwise(() => '/welcome')

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (!isAuthenticated && sandbox.state === 'disabled') {
            sandbox.activate()
        }
        navigate(url)
    }, [sandbox, navigate, url, isLoading, isAuthenticated])

    return null
}
