import {
    ValidationStatus,
    sanitiseExistingDefinitionSourceData,
    sanitiseTemplateSourceData,
} from 'Utils/BusinessObjectDefinition'
import { v4 } from 'uuid'
import { BusinessObjectDefinitionsCreatorQuery } from '../__generated__/q'
import { State, Status, Step } from './types'

const makeInit = (data: BusinessObjectDefinitionsCreatorQuery): State => ({
    open: false,
    step: Step.GetStarted,
    definition: {
        id: v4(),
        name: '',
        label: '',
        description: undefined,
        fields: [],
    },
    relationOptions: data.businessObjectDefinitions.map(def => ({
        text: def.name,
        value: def.id,
    })),
    userOptions: data.users.map(user => ({
        text: user.name,
        value: user.id,
    })),
    currencyOptions: data.currencies.map(option => ({
        text: `${option.nativeSymbol} (${option.code.toUpperCase()})`,
        value: option.code,
    })),
    enableUpdates: false,
    updatesLocked: false,
    enableDocuments: false,
    documentsLocked: false,
    templates: data.businessObjectDefinitionTemplates.map(
        sanitiseTemplateSourceData
    ),
    definitions: data.businessObjectDefinitions.map(
        sanitiseExistingDefinitionSourceData({
            fieldConstraints: false,
            selectOptions: false,
            enabledUpdates: false,
            enabledDocuments: false,
        })
    ),
    suggestionPrompt: '',
    loadingSuggestion: false,
    customLabel: false,
    fieldEditorOpen: false,
    addFieldOpen: false,
    status: Status.Idle,
    validation: {
        name: {
            status: ValidationStatus.Pending,
            message: '',
        },
        label: {
            status: ValidationStatus.Pending,
            message: '',
        },
        fields: [],
    },
    mode: 'create',
})

export { makeInit }
