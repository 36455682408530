const gqlUriOrUndefined = import.meta.env.VITE_GQL_URI

if (!gqlUriOrUndefined)
    throw new Error(
        `VITE_GQL_URI not set. Make sure you are using 'netlify dev' to run the development environment'`
    )

const gqlUri = gqlUriOrUndefined

export { gqlUri }
