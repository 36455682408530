import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC, ReactNode } from 'react'

export const GettingStartedSection: FC<{
    heading: string
    description: string
    children: ReactNode
    className?: string
}> = ({ heading, description, children, className = '' }) => (
    <Styled className={className}>
        <header>
            <Text as="h3" variant="bold-2">
                {heading}
            </Text>
            <Text as="p" variant="regular-4">
                {description}
            </Text>
        </header>

        {children}
    </Styled>
)

const Styled = styled.section`
    overflow: auto;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-image: linear-gradient(
        175deg,
        ${({ theme }) => theme.palette.ui['02'].normal},
        ${({ theme }) => theme.palette.ui['03'].normal}
    );
    background-size: cover;
    background-position: center;
    transition: opacity ${({ theme }) => theme.animation.mid} ease;
    width: 100%;
    height: fit-content;
    max-width: 62rem;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > header {
        padding: 1.75rem 2rem;
        position: sticky;
        top: 0;
    }
    > header h3 {
        padding-bottom: 0.75rem;
    }

    > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`
