import { sortableFieldTypes } from 'Features/SortBusinessObject'
import { BizObjectDefFieldsFragment } from 'Fragments/__generated__/BusinessObjectDefinition'
import { FC } from 'react'
import { CardSettings } from './CardSettings'
import { DisplayControl } from './DisplayControl'
import { Grouping } from './Grouping'
import { Sort } from './Sort'
import { useBusinessObjectsView } from './useBusinessObjectsView'

type Props = {
    fields: BizObjectDefFieldsFragment['fields']
}

export const BusinessObjectView: FC<Props> = ({ fields }) => {
    const { display } = useBusinessObjectsView()

    return (
        <>
            <DisplayControl />

            {display === 'card' ? (
                <CardSettings
                    fields={fields.map(field => ({
                        id: field.id,
                        label: field.name,
                        type: field.type,
                    }))}
                />
            ) : null}

            {display === 'card' ? (
                <Sort
                    fields={fields
                        .map(field => ({
                            id: field.id,
                            label: field.name,
                            type: field.type,
                        }))
                        .filter(field =>
                            sortableFieldTypes.includes(field.type)
                        )}
                />
            ) : null}

            {display === 'card' ? <Grouping fields={fields} /> : null}
        </>
    )
}
