import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetActiveTenantMutationVariables = Types.Exact<{
  input: Types.SetActiveTenantInput;
}>;


export type SetActiveTenantMutation = { __typename?: 'Mutation', setActiveTenant: { __typename?: 'User', id: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, name: string } } | null } };


export const SetActiveTenantDocument = gql`
    mutation SetActiveTenant($input: SetActiveTenantInput!) {
  setActiveTenant(input: $input) {
    id
    activeTenant {
      tenant {
        id
        name
      }
    }
  }
}
    `;
export type SetActiveTenantMutationFn = Apollo.MutationFunction<SetActiveTenantMutation, SetActiveTenantMutationVariables>;

/**
 * __useSetActiveTenantMutation__
 *
 * To run a mutation, you first call `useSetActiveTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveTenantMutation, { data, loading, error }] = useSetActiveTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActiveTenantMutation(baseOptions?: Apollo.MutationHookOptions<SetActiveTenantMutation, SetActiveTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActiveTenantMutation, SetActiveTenantMutationVariables>(SetActiveTenantDocument, options);
      }
export type SetActiveTenantMutationHookResult = ReturnType<typeof useSetActiveTenantMutation>;
export type SetActiveTenantMutationResult = Apollo.MutationResult<SetActiveTenantMutation>;
export type SetActiveTenantMutationOptions = Apollo.BaseMutationOptions<SetActiveTenantMutation, SetActiveTenantMutationVariables>;