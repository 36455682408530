import { dayjs } from 'Adapters/DayJS'
import { compact } from 'lodash'
import { match } from 'ts-pattern'
import { CreateFormState } from './useForm'

type UpdateActionValidator = (data: CreateFormState) => string | null

const validations = [
    ({ name }) => (name && name.length > 0 ? null : 'name'),
    ({ dueDate }) => {
        if (!dueDate) return null
        const startOfToday = dayjs().startOf('day').toISOString()
        return dueDate < startOfToday ? 'dueDate' : null
    },
    ({ schedule }) => {
        if (!schedule) return null

        return match(schedule)
            .with({ type: 'single' }, ({ scheduledAt }) => {
                const startOfTomorrow = dayjs()
                    .add(1, 'day')
                    .startOf('day')
                    .toISOString()
                return scheduledAt < startOfTomorrow
                    ? 'schedule.single.scheduledAt'
                    : null
            })
            .with({ type: 'recurring' }, ({ from, frequency }) => {
                const startOfTomorrow = dayjs()
                    .add(1, 'day')
                    .startOf('day')
                    .toISOString()
                return from < startOfTomorrow ? 'schedule.recurring.from' : null
            })
            .exhaustive()
    },
] satisfies Array<UpdateActionValidator>

export const validateForm = (data: CreateFormState) => {
    const errors = compact(validations.map(v => v(data)))

    return new Set(errors)
}
