import { SmallScreenWarning } from 'Features/SmallScreenWarning/SmallScreenWarning'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ConnectNav } from './ConnectNav'
import { ConnectSection } from './Header'
import { Integrations } from './Integrations'
import { OutgoingWebhooks } from './OutgoingWebhooks'
import { Webhooks } from './Webhooks'

export const Connect: FC = () => (
    <Routes>
        <Route index element={<ConnectNav />} />
        <Route
            path="integrations/*"
            element={
                <SmallScreenWarning
                    element={
                        <ConnectSection
                            icon="Integrations"
                            pageTitle="Integrations"
                        >
                            <Integrations />
                        </ConnectSection>
                    }
                />
            }
        />
        <Route
            path="webhooks/*"
            element={
                <SmallScreenWarning
                    element={
                        <ConnectSection icon="Pipeline" pageTitle="Webhooks">
                            <Webhooks />
                        </ConnectSection>
                    }
                />
            }
        />
        <Route
            path="outgoing-webhooks/*"
            element={
                <SmallScreenWarning
                    element={
                        <ConnectSection
                            icon="External"
                            pageTitle="Outgoing Webhooks"
                        >
                            <OutgoingWebhooks />
                        </ConnectSection>
                    }
                />
            }
        />
    </Routes>
)
