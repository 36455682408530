import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { Spinner } from 'Components/Spinner'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { useProcessEditor } from '../useProcessEditor'
import { validateProcess } from '../validation'
import { useEditor_SaveProcessMutation } from './__generated__/query'

const SaveProcess: FC = () => {
    const navigate = useNavigate()
    const {
        state: { process, operations },
        onCompleted,
    } = useProcessEditor()
    const [save, { loading }] = useEditor_SaveProcessMutation()
    const { open, setOpen, triggerToast } = useTriggerToast()

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <IconTextButton
                    icon="Save"
                    iconRight
                    text="Save"
                    variant="primary"
                    disabled={!validateProcess(process)}
                    onClick={() => {
                        save({
                            variables: {
                                input: {
                                    id: process.id,
                                    operations,
                                },
                            },
                            onCompleted: data => {
                                navigate(
                                    `/manage-workspace/processes/${slugify(
                                        data.patchProcess?.name ?? '',
                                        {
                                            lower: true,
                                        }
                                    )}`
                                )
                                onCompleted()
                            },
                            onError: e => {
                                logger.error(
                                    'Failed to save process changes',
                                    e,
                                    { processId: process.id, operations }
                                )
                                triggerToast()
                            },
                            onQueryUpdated: q => q.refetch(),
                        })
                    }}
                />
            )}
            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="We were unable to save your workflow. Contact us if the problem persists."
            />
        </>
    )
}

export { SaveProcess }
