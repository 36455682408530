import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Available_Integration_ActionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Available_Integration_ActionsQuery = { __typename?: 'Query', availableIntegrations: Array<{ __typename?: 'Integration', name: string, displayName: string, actions: Array<{ __typename?: 'IntegrationAction', name: string, displayName: string, hidden?: boolean | null, params: Array<{ __typename?: 'IntegrationActionParam', name: string, displayName: string, type: Types.IntegrationActionParamType, required: boolean, resolver: { __typename: 'IntegrationActionParamResolverDataLookup', type: Types.IntegrationActionParamResolverType, provider: string, action: string, itemsKey: string, idKey: string, displayKey: string } | { __typename: 'IntegrationActionParamResolverInput', type: Types.IntegrationActionParamResolverType } }> }>, connection?: { __typename?: 'Connection', id: string } | null }> };

export type Action_Execution_ResultQueryVariables = Types.Exact<{
  input: Types.ActionExecutionInput;
}>;


export type Action_Execution_ResultQuery = { __typename?: 'Query', actionExecution: { __typename?: 'ActionExecution', result: string } };


export const Available_Integration_ActionsDocument = gql`
    query Available_Integration_Actions {
  availableIntegrations {
    name
    displayName
    actions {
      name
      displayName
      hidden
      params {
        name
        displayName
        type
        required
        resolver {
          __typename
          ... on IntegrationActionParamResolverInput {
            type
          }
          ... on IntegrationActionParamResolverDataLookup {
            type
            provider
            action
            itemsKey
            idKey
            displayKey
          }
        }
      }
    }
    connection {
      id
    }
  }
}
    `;

/**
 * __useAvailable_Integration_ActionsQuery__
 *
 * To run a query within a React component, call `useAvailable_Integration_ActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailable_Integration_ActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailable_Integration_ActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailable_Integration_ActionsQuery(baseOptions?: Apollo.QueryHookOptions<Available_Integration_ActionsQuery, Available_Integration_ActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Available_Integration_ActionsQuery, Available_Integration_ActionsQueryVariables>(Available_Integration_ActionsDocument, options);
      }
export function useAvailable_Integration_ActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Available_Integration_ActionsQuery, Available_Integration_ActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Available_Integration_ActionsQuery, Available_Integration_ActionsQueryVariables>(Available_Integration_ActionsDocument, options);
        }
export type Available_Integration_ActionsQueryHookResult = ReturnType<typeof useAvailable_Integration_ActionsQuery>;
export type Available_Integration_ActionsLazyQueryHookResult = ReturnType<typeof useAvailable_Integration_ActionsLazyQuery>;
export type Available_Integration_ActionsQueryResult = Apollo.QueryResult<Available_Integration_ActionsQuery, Available_Integration_ActionsQueryVariables>;
export const Action_Execution_ResultDocument = gql`
    query Action_Execution_Result($input: ActionExecutionInput!) {
  actionExecution(input: $input) {
    result
  }
}
    `;

/**
 * __useAction_Execution_ResultQuery__
 *
 * To run a query within a React component, call `useAction_Execution_ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useAction_Execution_ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAction_Execution_ResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAction_Execution_ResultQuery(baseOptions: Apollo.QueryHookOptions<Action_Execution_ResultQuery, Action_Execution_ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Action_Execution_ResultQuery, Action_Execution_ResultQueryVariables>(Action_Execution_ResultDocument, options);
      }
export function useAction_Execution_ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Action_Execution_ResultQuery, Action_Execution_ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Action_Execution_ResultQuery, Action_Execution_ResultQueryVariables>(Action_Execution_ResultDocument, options);
        }
export type Action_Execution_ResultQueryHookResult = ReturnType<typeof useAction_Execution_ResultQuery>;
export type Action_Execution_ResultLazyQueryHookResult = ReturnType<typeof useAction_Execution_ResultLazyQuery>;
export type Action_Execution_ResultQueryResult = Apollo.QueryResult<Action_Execution_ResultQuery, Action_Execution_ResultQueryVariables>;