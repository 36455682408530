import { IconTextButton } from 'Components/Button'
import { ContactUsToast } from 'Components/Toast'
import { FC } from 'react'
import { useToggleSubscription } from '../useToggleSubscription'

type Props = {
    id: string
}

export const ToggleSubscription: FC<Props> = ({ id }) => {
    const { subscribed, loading, toggleSubscription, toastOpen, setToastOpen } =
        useToggleSubscription(id)

    return (
        <>
            <IconTextButton
                text="Subscribe"
                variant="secondary"
                icon={subscribed ? 'ToggleChecked' : 'ToggleUnchecked'}
                disabled={loading}
                onClick={toggleSubscription}
            />
            <ContactUsToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'We were unable to toggle the subscription. Contact us if the problem persists.'
                }
            />
        </>
    )
}
