import { styled } from 'Adapters/Freestyled'
import { Dropdown } from 'Components/Dropdown'
import { DropdownNavButton } from 'Components/Dropdown/Components/DropdownNavButton'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { areas } from './config'

type Props = {
    area: keyof typeof areas
    single?: {
        selected: number
        options: {
            text: string
            path: string
        }[]
    }
}

const SingleSelect: FC<Props> = ({ area, single }) => {
    const navigate = useNavigate()
    const selected = single?.options[single.selected]

    return selected ? (
        <Dropdown
            renderOpenDropdownButton={() => (
                <StyledButton>
                    {/* <Icon name="Bullet" /> */}
                    <span className="text">{selected.text}</span>
                    <Icon name="DropDown" />
                </StyledButton>
            )}
            renderContent={() => (
                <ul>
                    {single.options.map(({ text, path }) => (
                        <li key={path}>
                            <DropdownNavButton
                                text={text}
                                selected={path === selected.path}
                                onSelect={() =>
                                    navigate(
                                        `/manage-workspace/${areas[area].path}/${path}`
                                    )
                                }
                            />
                        </li>
                    ))}
                </ul>
            )}
            align="start"
        />
    ) : null
}

const StyledButton = styled.button`
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }

    > .text {
        ${regular5}
    }
`

export { SingleSelect }
