import { FC } from 'react'
import { Field } from '../types'
import { AbstractField } from './AbstractField'
import { AbstractFieldChangeProps } from './AbstractField/AbstractField'

export const DocumentField: FC<
    Field<'DocumentFieldDefinition'> & AbstractFieldChangeProps
> = ({ docConstraints, ...common }) => (
    <AbstractField {...common} constraints={docConstraints} />
)
