import { Provider } from '@radix-ui/react-tooltip'
import { FC, ReactNode } from 'react'

const TOOLTIP_DELAY = 500

type Props = {
    children: ReactNode
}

export const TooltipProvider: FC<Props> = ({ children }) => {
    return <Provider delayDuration={TOOLTIP_DELAY}>{children}</Provider>
}
