import { round } from 'Utils'
import { DAY_SECS, HOUR_SECS, TimeUnit, WEEK_SECS } from 'Utils/timeConversions'

export const secondsToUsefulUnit = (
    secs: number
): { number: number; unit: TimeUnit } => {
    const numberOfHours = secs / HOUR_SECS
    if (numberOfHours < 24)
        return {
            number: numberOfHours,
            unit: 'hour',
        }

    const numberOfDays = secs / DAY_SECS
    if (numberOfDays < 7)
        return {
            number: numberOfDays,
            unit: 'day',
        }

    const numberOfWeeks = secs / WEEK_SECS
    return {
        number: numberOfWeeks,
        unit: 'week',
    }
}

const roundToHalfDecimal = round(0.5)

export const generateDueTimeString = (ms: number): string => {
    const { number, unit } = secondsToUsefulUnit(ms)
    const rounded = roundToHalfDecimal(number)
    return `Action default due time : ${rounded} ${unit}(s)`
}
