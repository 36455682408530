import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'

export const StyledForm = styled.form`
    ${flex('column', 'flex-start', 'flex-start')};
    width: calc(100vw - 3rem);
    max-width: 35rem;
`

export const StyledMissingBOMessage = styled.p`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    text-align: center;
    width: 100%;
`

export const StyledIconTextButton = styled(IconTextButton)`
    flex: 1;
    white-space: nowrap;
`

export const StyledFooter = styled.footer`
    ${flex('row', 'center', 'center')};
    gap: 0.5rem;
    width: 100%;
    margin-top: 1rem;
`
