import { CenteredSpinner } from 'Components/Spinner'
import { WorkspaceNameForm } from 'Features/WorkspaceName'
import { FC } from 'react'
import { P, match } from 'ts-pattern'
import { ChecklistItem } from './ChecklistItem'
import { useSetOrgNameQuery } from './__generated__/q'

export const SetOrgName: FC = () => {
    const { data, loading } = useSetOrgNameQuery()

    return (
        <ChecklistItem stepId="name-workspace">
            {match({ data, loading })
                .with({ loading: true, data: P.nullish }, () => (
                    <CenteredSpinner />
                ))
                .with({ loading: false, data: P.nullish }, () => null)
                .with(
                    { data: P.not(P.nullish) },
                    ({
                        data: {
                            currentUser: { activeTenant },
                        },
                    }) => (
                        <WorkspaceNameForm
                            direction="row"
                            tenant={{
                                name: activeTenant?.tenant.name ?? '',
                            }}
                        />
                    )
                )
                .exhaustive()}
        </ChecklistItem>
    )
}
