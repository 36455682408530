import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { StyledButtonContainer } from '../styled'
import { strings } from './strings'

export type Props = {
    dayDisplay: number
    selected: boolean
    isToday: boolean
    dateString: string
    onClick: () => void
    onDownArrow: () => void
    onUpArrow: () => void
    onLeftArrow: () => void
    onRightArrow: () => void
    disabled?: boolean
    isTabbable: boolean
}

export const Day: FC<Props> = ({
    dayDisplay,
    selected,
    isToday,
    dateString,
    onClick,
    onUpArrow,
    onDownArrow,
    onLeftArrow,
    onRightArrow,
    disabled = false,
    isTabbable,
}) => {
    return (
        <StyledDay>
            <button
                aria-label={`${strings.ariaLabel} ${dateString}`}
                data-date={dateString}
                data-selected={selected}
                data-is-today={isToday}
                disabled={disabled}
                onClick={onClick}
                onKeyDown={e => {
                    const { key } = e
                    if (key === 'ArrowUp') onUpArrow()
                    if (key === 'ArrowDown') onDownArrow()
                    if (key === 'ArrowLeft') onLeftArrow()
                    if (key === 'ArrowRight') onRightArrow()
                }}
                tabIndex={isTabbable ? 0 : -1}
                autoFocus={isTabbable}
            >
                {dayDisplay}
            </button>
        </StyledDay>
    )
}

const StyledDay = styled(StyledButtonContainer)`
    padding: 0 0.25rem;

    button {
        height: 2.25rem;
        width: 2.25rem;
        border-radius: 50%;
    }
`
