import { styled } from 'Adapters/Freestyled'
import { EditBusinessObject } from 'Features/EditBusinessObject'
import { FC } from 'react'

type Props = {
    businessObjectId: string
}

const Details: FC<Props> = ({ businessObjectId }) => (
    <Styled>
        <StyledEditForm businessObjectId={businessObjectId} />
    </Styled>
)

const Styled = styled.div`
    [data-screen-size='large'] & {
        padding: 2rem 3rem;
        flex: 1;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    [data-screen-size='medium'] &,
    [data-screen-size='small'] & {
        padding: 1rem 4vw;
    }
`

const StyledEditForm = styled(EditBusinessObject)`
    align-self: flex-start;
`

export { Details }
