import { flex, styled } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { FC, useEffect, useState } from 'react'
import { countryCodeOptions } from 'Utils'
import { CreateBusinessObjectTelephoneFieldValue } from '__generated__'
import { BODField } from '../../../types'
import { Field } from '../Field'
import { useTelephoneConstraints } from './useTelephoneConstraints'

export const TelephoneField: FC<BODField<'TelephoneFieldDefinition'>> = ({
    telephoneConstraints,
    ...common
}) => {
    const dispatch = useCreateFormStateDispatch()
    const state = useCreateFormState()

    const { constraints, constraintsTooltip } = useTelephoneConstraints({
        telephoneConstraints,
    })

    const [countryCode, setCountryCode] = useState<string>(() => {
        const value = state[common.id]
            .value as CreateBusinessObjectTelephoneFieldValue

        return value.countryCode ?? ''
    })
    const [number, setNumber] = useState<string>(() => {
        const value = state[common.id]
            .value as CreateBusinessObjectTelephoneFieldValue

        return value.number ?? ''
    })

    useEffect(() => {
        dispatch({
            type: 'updateTelephoneField',
            payload: {
                id: common.id,
                value: {
                    countryCode,
                    number: number ?? undefined,
                },
            },
        })
    }, [number, countryCode, dispatch, common.id])

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            description={common.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={state[common.id].errors}
        >
            <StyledTelInput>
                <Select
                    id={common.id + 'countrycode'}
                    value={countryCode}
                    onValueChange={setCountryCode}
                    name={'Country Code'}
                    options={countryCodeOptions}
                />
                <TextInput
                    value={number}
                    id={common.id}
                    hasError={!!state[common.id].errors}
                    onChange={e => setNumber(e.target.value)}
                    required={constraints.required}
                    className="tel-number-input"
                />
            </StyledTelInput>
        </Field>
    )
}

const StyledTelInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .tel-number-input {
        flex: 1;
        height: 2.75rem;
    }
`
