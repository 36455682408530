import { Text } from 'Components/Text'
import { FC } from 'react'
import { RangeConstraint } from '__generated__'

const isDefined = (val: number | undefined | null): val is number =>
    typeof val !== 'undefined' && val !== null

export const Range: FC<RangeConstraint> = ({ min, max }) => (
    <>
        <Text as="p" variant="bold-4">
            Minimum
        </Text>
        {isDefined(min) ? (
            <Text as="p" variant="regular-4">
                {min}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No minimum set
            </Text>
        )}
        <Text as="p" variant="bold-4">
            Maximum
        </Text>
        {isDefined(max) ? (
            <Text as="p" variant="regular-4">
                {max}
            </Text>
        ) : (
            <Text as="p" variant="regular-4" className="none-set">
                No maximum set
            </Text>
        )}
    </>
)
