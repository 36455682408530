import { flex, styled } from 'Adapters/Freestyled'
import { Comparator } from 'Components/ComparatorBuilderV2'
import { Icon } from 'Components/Icon'
import { SelectorName } from 'Components/SelectorName'
import { Text } from 'Components/Text'
import { byTypename } from 'Utils'
import { keyBy } from 'lodash'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { CriterionConditions } from '../../../../../CriterionConditions'
import { useBusinessObjectDefinitionFieldNamesQuery } from '../../../../../__generated__/query'
import { generateDueTimeString } from '../../../../../helpers'
import { FieldDefDictionary } from '../../../../../types'
import { mapConditionsToComparators } from '../../useProcessEditor/helpers'
import { State } from '../../useProcessEditor/types'
import { EditCriterion } from './EditCriterion'

type Props = {
    updateCriterion: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    criterion: State['process']['phases'][number]['criteria'][number]
    operatesUpon: State['process']['operatesUpon'][number]
}

export const Criterion: FC<Props> = ({
    criterion,
    operatesUpon,
    updateCriterion,
}) => {
    const fieldDefById = useMemo(() => {
        const relationFields = operatesUpon.fields
            .filter(byTypename('RelationFieldDefinition' as const))
            .map(field =>
                field.constraints
                    .filter(byTypename('RelationTypeConstraint' as const))
                    .flatMap(constraint =>
                        constraint.types.flatMap(type => type.fields)
                    )
            )
            .flat()

        return keyBy([...operatesUpon.fields, ...relationFields], f => f.id)
    }, [operatesUpon])

    const businessObjectDefinitionId = operatesUpon.id

    return match(criterion)
        .with({ __typename: 'ActionPhaseCriterion' }, criterion => (
            <ActionCriterion
                criterion={criterion}
                updateCriterion={updateCriterion}
                fieldDefById={fieldDefById}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        ))
        .with({ __typename: 'FieldConditionPhaseCriterion' }, criterion => (
            <FieldCriterion
                criterion={criterion}
                operatesUpon={operatesUpon}
                fieldDefById={fieldDefById}
                updateCriterion={updateCriterion}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        ))
        .with({ __typename: 'ProcessFanoutPhaseCriterion' }, criterion => (
            <ProcessFanoutPhaseCriterion
                criterion={criterion}
                fieldDefById={fieldDefById}
                updateCriterion={updateCriterion}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        ))
        .with({ __typename: 'ProcessDelegatePhaseCriterion' }, criterion => (
            <ProcessDelegatePhaseCriterion
                criterion={criterion}
                fieldDefById={fieldDefById}
                updateCriterion={updateCriterion}
                businessObjectDefinitionId={businessObjectDefinitionId}
            />
        ))
        .exhaustive()
}

const ActionCriterion: FC<{
    criterion: Extract<
        Props['criterion'],
        { __typename: 'ActionPhaseCriterion' }
    >
    updateCriterion: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    fieldDefById: FieldDefDictionary
    businessObjectDefinitionId: string
}> = ({
    criterion,
    updateCriterion,
    fieldDefById,
    businessObjectDefinitionId,
}) => {
    const { description, defaultDueSeconds, conditions } = criterion

    return (
        <Styled>
            <div className="action">
                <Icon name="CheckboxTicked" />
                <Text as="p" variant="bold-6">
                    {match(criterion)
                        .with(
                            { __typename: 'ActionPhaseCriterion' },
                            () => 'Action'
                        )
                        .exhaustive()}
                </Text>
            </div>

            <div className="description">
                <Text as="p" variant="regular-4">
                    {description}
                </Text>

                <EditCriterion
                    criteriaType="action"
                    initialDescription={description}
                    initialDefaultDueSeconds={defaultDueSeconds}
                    initialConditions={mapConditionsToComparators(conditions)}
                    onSave={updateCriterion}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />
            </div>

            {defaultDueSeconds ? (
                <Text as="p" variant="regular-6" className="default-due-time">
                    {generateDueTimeString(defaultDueSeconds)}
                </Text>
            ) : null}

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </Styled>
    )
}

const FieldCriterion: FC<{
    criterion: Extract<
        Props['criterion'],
        { __typename: 'FieldConditionPhaseCriterion' }
    >
    operatesUpon: State['process']['operatesUpon'][number]
    fieldDefById: FieldDefDictionary
    updateCriterion: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    businessObjectDefinitionId: string
}> = ({
    criterion,
    operatesUpon,
    fieldDefById,
    updateCriterion,
    businessObjectDefinitionId,
}) => {
    const { data, loading } = useBusinessObjectDefinitionFieldNamesQuery({
        variables: { input: { id: operatesUpon.id } },
    })

    const fieldMap = useMemo(
        () => ({
            ...keyBy(data?.businessObjectDefinition?.fields, field => field.id),
            ...keyBy(
                data?.businessObjectDefinition?.fields
                    .filter(byTypename('RelationFieldDefinition' as const))
                    .flatMap(field =>
                        field.constraints
                            .filter(
                                byTypename('RelationTypeConstraint' as const)
                            )
                            .flatMap(constraint =>
                                constraint.types.flatMap(type => type.fields)
                            )
                    ),
                field => field.id
            ),
        }),
        [data]
    )

    return (
        <Styled>
            <div className="action">
                <Icon name="CheckboxTicked" />
                <Text as="p" variant="bold-6">
                    Required field
                </Text>
            </div>

            <div className="description">
                <Text as="p" variant="regular-4">
                    {loading ? (
                        'Loading...'
                    ) : (
                        <SelectorName
                            selector={criterion.comparator.valueSelector}
                            fieldMap={fieldMap}
                        />
                    )}
                </Text>

                <EditCriterion
                    criteriaType="fieldCondition"
                    initialDescription={undefined}
                    initialDefaultDueSeconds={undefined}
                    initialConditions={mapConditionsToComparators(
                        criterion.conditions
                    )}
                    onSave={updateCriterion}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />
            </div>

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </Styled>
    )
}

const ProcessFanoutPhaseCriterion: FC<{
    criterion: Extract<
        Props['criterion'],
        { __typename: 'ProcessFanoutPhaseCriterion' }
    >
    fieldDefById: FieldDefDictionary
    updateCriterion: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    businessObjectDefinitionId: string
}> = ({
    criterion,
    fieldDefById,
    updateCriterion,
    businessObjectDefinitionId,
}) => {
    return (
        <Styled>
            <div className="action">
                <Icon name="Process" />
                <Text as="p" variant="bold-6">
                    Sub-workflow (optional)
                </Text>
            </div>

            <div className="description">
                <Text as="p" variant="regular-4">
                    {criterion.process.name}
                </Text>

                {criterion.transform && (
                    <Text as="p" variant="regular-6">
                        {criterion.transform.field.name}
                    </Text>
                )}

                <EditCriterion
                    criteriaType="processFanout"
                    initialDescription={undefined}
                    initialDefaultDueSeconds={undefined}
                    initialConditions={mapConditionsToComparators(
                        criterion.conditions
                    )}
                    onSave={updateCriterion}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />
            </div>

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </Styled>
    )
}

const ProcessDelegatePhaseCriterion: FC<{
    criterion: Extract<
        Props['criterion'],
        { __typename: 'ProcessDelegatePhaseCriterion' }
    >
    fieldDefById: FieldDefDictionary
    updateCriterion: (
        description: string | undefined,
        defaultDueSeconds: number | undefined,
        conditions: Comparator[] | undefined
    ) => void
    businessObjectDefinitionId: string
}> = ({
    criterion,
    fieldDefById,
    updateCriterion,
    businessObjectDefinitionId,
}) => {
    return (
        <Styled>
            <div className="action">
                <Icon name="Process" />
                <Text as="p" variant="bold-6">
                    Sub-process (required)
                </Text>
            </div>

            <div className="description">
                <Text as="p" variant="regular-4">
                    {criterion.process.name}
                </Text>

                {criterion.transform && (
                    <Text as="p" variant="regular-6">
                        {criterion.transform.field.name}
                    </Text>
                )}

                <EditCriterion
                    criteriaType="processDelegate"
                    initialDescription={undefined}
                    initialDefaultDueSeconds={undefined}
                    initialConditions={mapConditionsToComparators(
                        criterion.conditions
                    )}
                    onSave={updateCriterion}
                    businessObjectDefinitionId={businessObjectDefinitionId}
                />
            </div>

            <CriterionConditions
                conditions={criterion.conditions}
                fieldDefById={fieldDefById}
            />
        </Styled>
    )
}

const Styled = styled.li`
    ${flex('column', 'flex-start', 'flex-start')};
    padding: 1rem 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};
    }

    .action {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.25rem;
        padding-bottom: 0.5rem;

        > p {
            color: ${({ theme }) => theme.palette.text['02'].normal};
        }

        .icon svg {
            height: 0.75rem;
            width: 0.75rem;
        }

        .icon path {
            fill: ${({ theme }) => theme.palette.icon['03'].normal};
        }
    }

    .description {
        ${flex('row', 'space-between', 'center')};
        width: 100%;
    }

    .default-due-time {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`
