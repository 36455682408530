import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { CreatorModal } from 'Features/CreatorModal'
import { ProcessCreator } from 'Features/ProcessCreator'
import { FC, useCallback, useMemo, useState } from 'react'
import { ProcessDef_SingleProcessQuery } from '../../__generated__/query'
import { SelectBackstop } from './SelectBackstop'
import {
    useSingleProcess_BackstopProcessesQuery,
    useSingleProcess_UpdateBackstopMutation,
} from './__generated__/q'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
}

const AssignBackstop: FC<Props> = ({ process }) => {
    const [update] = useSingleProcess_UpdateBackstopMutation()
    const [updating, setUpdating] = useState(false)
    const { open, setOpen, triggerToast } = useTriggerToast()
    const [creatorOpen, setCreatorOpen] = useState(false)
    const { data } = useSingleProcess_BackstopProcessesQuery()

    const options = useMemo(
        () =>
            (data?.processes || []).filter(
                ({ id, operatesUpon }) =>
                    id !== process.id &&
                    operatesUpon[0].id === process.operatesUpon[0].id
            ),
        [process, data]
    )

    const handleUpdate = useCallback(
        (id: string) => {
            setUpdating(true)
            update({
                variables: {
                    input: {
                        id: process.id,
                        operations: [
                            {
                                updateBackstop: {
                                    nextValue: id,
                                },
                            },
                        ],
                    },
                },
                onError: () => {
                    setUpdating(false)
                    triggerToast()
                },
                onQueryUpdated: q => q.refetch(),
            })
        },
        [process, update, triggerToast]
    )

    return (
        <Styled>
            <StyledCreateSection>
                {options.length === 0 ? null : (
                    <>
                        <SelectBackstop
                            updating={updating}
                            options={options}
                            onSelect={handleUpdate}
                        />
                        <Text as="p" variant="bold-5">
                            — or —
                        </Text>
                    </>
                )}
                <CreatorModal
                    open={creatorOpen}
                    setOpen={setCreatorOpen}
                    title=""
                    description=""
                    trigger={{
                        text: 'Create a new workflow',
                        disabled: updating,
                    }}
                    renderContent={() => (
                        <ProcessCreator
                            preselectedBO={process.operatesUpon[0]}
                            onCompleted={({ id }) => {
                                setCreatorOpen(false)
                                handleUpdate(id)
                            }}
                            initialStep="phases"
                        />
                    )}
                    confirmationDescription="If you close the creator now, you will lose all your changes."
                />
            </StyledCreateSection>

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="We were unable to assign this backstop workflow. Please contact us if the problem persists."
            />
        </Styled>
    )
}

const Styled = styled.div`
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StyledCreateSection = styled.section`
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.palette.text['02'].normal};
`

export { AssignBackstop }
