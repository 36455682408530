import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { TextInput } from 'Components/Input'
import { SelectFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'

type Props<T extends SelectFieldUnique> = {
    value: string
    index: number
    isFirst: boolean
    isLast: boolean
    update: (fn: (prev: T) => T) => void
}
const Option = <T extends SelectFieldUnique>({
    value,
    index,
    isFirst,
    isLast,
    update,
}: Props<T>): ReturnType<FC<Props<T>>> => {
    const { current: optionFieldId } = useRef(v4())

    return (
        <StyledOption>
            <Icon name="Bullet" />
            <label htmlFor={optionFieldId}>{`Option ${index + 1}`}</label>
            <TextInput
                id={optionFieldId}
                value={value}
                placeholder={`Option ${index + 1}`}
                onChange={({ currentTarget: { value } }) => {
                    update(prev => ({
                        ...prev,
                        options: prev.options.map((o, i) =>
                            index === i ? { value } : o
                        ),
                    }))
                }}
            />
            <IconButton
                iconName="ArrowUp"
                disabled={isFirst}
                onClick={() => {
                    update(prev => {
                        const options = prev.options.filter(
                            (_, i) => i !== index
                        )
                        options.splice(index - 1, 0, prev.options[index])
                        return {
                            ...prev,
                            options,
                        }
                    })
                }}
            />
            <IconButton
                iconName="ArrowDown"
                disabled={isLast}
                onClick={() => {
                    update(prev => {
                        const options = prev.options.filter(
                            (_, i) => i !== index
                        )
                        options.splice(index + 1, 0, prev.options[index])
                        return {
                            ...prev,
                            options,
                        }
                    })
                }}
            />
            <IconButton
                iconName="Delete"
                onClick={() => {
                    update(prev => ({
                        ...prev,
                        options: prev.options.filter((_, i) => index !== i),
                    }))
                }}
            />
        </StyledOption>
    )
}

const StyledOption = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label {
        height: 0;
        width: 0;
        overflow: hidden;
        opacity: 0;
    }

    > .icon svg {
        --width: 1rem;
        width: var(--width);
        height: var(--width);
        min-width: var(--width);
        min-height: var(--width);
    }

    svg path {
        fill: ${({ theme }) => theme.palette.ui['05'].normal};
    }
`

export { Option }
