import { TextButton } from 'Components/Button'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { styled } from '../../../Adapters/Freestyled'
import { FieldConstraintType } from '../../../__generated__'
import { BODFields, PrefilledBusinessObject } from '../types'
import {
    BooleanField,
    CurrencyField,
    DateField,
    DocumentField,
    EmailField,
    ListField,
    NumberField,
    RelationField,
    SelectField,
    TelephoneField,
    TextField,
    URLField,
    UserField,
} from './Fields'

export type Props = {
    prefilledBusinessObject?: PrefilledBusinessObject
    requiredFields: BODFields
    optionalFields: BODFields
    showOptionalFields: boolean
    setShowOptionalFields: (f: (a: boolean) => boolean) => void
    showOptionalFieldsToggle: boolean
    children?: React.ReactElement
}

export const CreateBusinessObjectFormContents: FC<Props> = ({
    prefilledBusinessObject,
    requiredFields,
    optionalFields,
    showOptionalFields,
    setShowOptionalFields,
    showOptionalFieldsToggle,
    children,
}) => {
    return (
        <StyledFieldset>
            {requiredFields.map(renderField(prefilledBusinessObject))}

            {showOptionalFieldsToggle ? (
                <TextButton
                    variant="ghost"
                    text={
                        showOptionalFields
                            ? 'Hide optional fields'
                            : 'Show optional fields'
                    }
                    onClick={() => setShowOptionalFields(v => !v)}
                    type="button"
                />
            ) : null}

            {showOptionalFields
                ? optionalFields.map(renderField(prefilledBusinessObject))
                : null}

            {children}
        </StyledFieldset>
    )
}

const StyledFieldset = styled.fieldset`
    border: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const renderField =
    (prefilledBusinessObject?: PrefilledBusinessObject) =>
    (field: BODFields[number]) =>
        match(field)
            .with({ __typename: 'TextFieldDefinition' }, props => (
                <TextField key={props.id} {...props} />
            ))
            .with({ __typename: 'URLFieldDefinition' }, props => (
                <URLField key={props.id} {...props} />
            ))
            .with({ __typename: 'NumberFieldDefinition' }, props => (
                <NumberField key={props.id} {...props} />
            ))
            .with({ __typename: 'EmailFieldDefinition' }, props => (
                <EmailField key={props.id} {...props} />
            ))
            .with({ __typename: 'BooleanFieldDefinition' }, props => (
                <BooleanField key={props.id} {...props} />
            ))
            .with({ __typename: 'SelectFieldDefinition' }, props => (
                <SelectField key={props.id} {...props} />
            ))
            .with({ __typename: 'DateFieldDefinition' }, props => (
                <DateField key={props.id} {...props} />
            ))
            .with({ __typename: 'TelephoneFieldDefinition' }, props => (
                <TelephoneField key={props.id} {...props} />
            ))
            .with({ __typename: 'CurrencyFieldDefinition' }, props => (
                <CurrencyField key={props.id} {...props} />
            ))
            .with({ __typename: 'DocumentFieldDefinition' }, props => (
                <DocumentField key={props.id} {...props} />
            ))
            .with({ __typename: 'UserFieldDefinition' }, props => (
                <UserField key={props.id} {...props} />
            ))
            .with({ __typename: 'RelationFieldDefinition' }, props => (
                <RelationField
                    key={props.id}
                    prefilledBusinessObject={
                        props.relationConstraints.find(
                            c => c.type === FieldConstraintType.Required
                        )
                            ? prefilledBusinessObject
                            : undefined
                    }
                    {...props}
                />
            ))
            .with({ __typename: 'ListFieldDefinition' }, props => (
                <ListField
                    key={props.id}
                    prefilledBusinessObject={prefilledBusinessObject}
                    {...props}
                />
            ))
            .with({ __typename: 'UpdatesFieldDefinition' }, () => null) // Maybe TODO : Would we ever want to initialize a BO with an update?
            .exhaustive()
