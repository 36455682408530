// It's worth noting that some care needs to be taken here due to the
// limitations of the typing generated for the fields. It is always possible to return
// a standard field for any field type, but certain field types must differ from the standard

import {
    CreateBusinessObjectDefinitionBooleanField,
    CreateBusinessObjectDefinitionCurrencyField,
    CreateBusinessObjectDefinitionDateField,
    CreateBusinessObjectDefinitionDateFieldConstraint,
    CreateBusinessObjectDefinitionDateFieldPrecision,
    CreateBusinessObjectDefinitionDocumentField,
    CreateBusinessObjectDefinitionEmailField,
    CreateBusinessObjectDefinitionField,
    CreateBusinessObjectDefinitionListField,
    CreateBusinessObjectDefinitionNumberField,
    CreateBusinessObjectDefinitionNumberFieldConstraint,
    CreateBusinessObjectDefinitionRelationField,
    CreateBusinessObjectDefinitionRelationFieldConstraint,
    CreateBusinessObjectDefinitionSelectField,
    CreateBusinessObjectDefinitionSelectFieldConstraint,
    CreateBusinessObjectDefinitionTelephoneField,
    CreateBusinessObjectDefinitionTextField,
    CreateBusinessObjectDefinitionTextFieldConstraint,
    CreateBusinessObjectDefinitionUrlField,
    CreateBusinessObjectDefinitionUrlFieldConstraint,
    CreateBusinessObjectDefinitionUserField,
    CreateBusinessObjectDefinitionUserFieldConstraint,
} from '__generated__'
import { compact } from 'lodash'
import slugify from 'slugify'
import { match } from 'ts-pattern'
import { v4 } from 'uuid'
import {
    DateFieldPrecision,
    Definition,
    Field,
    FieldType,
    ListOf,
} from './types'

export const createNewField = (fieldType: FieldType, required = false): Field =>
    match(fieldType)
        .with(FieldType.Text, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
                length: {
                    max: undefined,
                    min: undefined,
                },
            },
            defaultValue: undefined,
            constraintsLocked: false,
        }))
        .with(FieldType.Number, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
                range: {
                    max: undefined,
                    min: undefined,
                },
            },
            defaultValue: undefined,
            constraintsLocked: false,
        }))
        .with(FieldType.Telephone, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: {
                countryCode: undefined,
                number: undefined,
            },
            constraintsLocked: false,
        }))
        .with(FieldType.Email, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: '',
            constraintsLocked: false,
        }))
        .with(FieldType.Document, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: '',
            constraintsLocked: false,
        }))
        .with(FieldType.Currency, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: {
                amount: undefined,
                currencyCode: undefined,
            },
            constraintsLocked: false,
        }))
        .with(FieldType.Relation, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
                relationType: {
                    id: '',
                    name: '',
                },
            },
            constraintsLocked: false,
        }))
        .with(FieldType.User, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: undefined,
            constraintsLocked: false,
        }))
        .with(FieldType.Url, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
            },
            defaultValue: undefined,
            constraintsLocked: false,
        }))
        .with(FieldType.Date, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            constraints: {
                required,
                dateRange: {
                    start: undefined,
                    end: undefined,
                },
            },
            precision: DateFieldPrecision.Day,
            defaultValue: undefined,
            constraintsLocked: false,
        }))
        .with(FieldType.Boolean, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            defaultValue: false,
            constraintsLocked: false,
        }))
        .with(FieldType.Select, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            options: [],
            constraints: { numberOfSelections: { min: 1, max: 1 } },
            defaultValue: [],
            constraintsLocked: false,
        }))
        .with(FieldType.List, type => ({
            type,
            id: v4(),
            name: '',
            description: '',
            listOf: {
                type: FieldType.Boolean,
                defaultValue: false,
            } as ListOf,
            constraintsLocked: false,
        }))
        .exhaustive()

// It's possible this already exists in the codebase, but I couldn't find it.
// It should probably be generalised outside of this feature at some point.
// Rather use P.string predicates and ".with" rather than "".when", but for seom reason
// typescript won't let me use P.string.minLength(1)
//
// We filter out list and updates fields as they are not suitable for labels.
// There may be others that are also not suitable;
// this needs to be verified before PR.
export const createDefaultLabel = ({ fields }: Pick<Definition, 'fields'>) => {
    const firstText = fields.find(f => f.type === FieldType.Text)?.name
    const firstNonList = fields.find(f => f.type !== FieldType.List)?.name
    const name = firstText || firstNonList
    return name
        ? `{{{ it.${slugify(name, {
              lower: true,
              replacement: '_',
              strict: true,
          })} }}}`
        : ''
}

export const createDefinitionFieldInput = (
    field: Field
): CreateBusinessObjectDefinitionField =>
    match(field)
        .with(
            { type: FieldType.Boolean },
            ({ type, name, description, defaultValue }) => {
                const field: CreateBusinessObjectDefinitionBooleanField = {
                    defaultValue,
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Currency },
            ({ type, name, description, defaultValue, constraints }) => {
                const field: CreateBusinessObjectDefinitionCurrencyField = {
                    defaultValue,
                    constraints: constraints.required ? [{ required: {} }] : [],
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Date },
            ({
                type,
                name,
                description,
                defaultValue,
                constraints: { required, dateRange },
                precision,
            }) => {
                const constraints: CreateBusinessObjectDefinitionDateFieldConstraint[] =
                    [{ dateRange }]
                if (required) constraints.push({ required: {} })
                const field: CreateBusinessObjectDefinitionDateField = {
                    defaultValue,
                    constraints,
                    precision: mapPrecision(precision),
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Document },
            ({ type, name, description, constraints }) => {
                const field: CreateBusinessObjectDefinitionDocumentField = {
                    constraints: constraints.required ? [{ required: {} }] : [],
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Email },
            ({ type, name, description, constraints, defaultValue }) => {
                const field: CreateBusinessObjectDefinitionEmailField = {
                    defaultValue: defaultValue || undefined,
                    constraints: constraints.required ? [{ required: {} }] : [],
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Number },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { required, range },
            }) => {
                const constraints: CreateBusinessObjectDefinitionNumberFieldConstraint[] =
                    [{ range }]
                if (required) constraints.push({ required: {} })
                const field: CreateBusinessObjectDefinitionNumberField = {
                    defaultValue,
                    constraints,
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Select },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { numberOfSelections },
                options,
            }) => {
                const constraints: CreateBusinessObjectDefinitionSelectFieldConstraint[] =
                    [{ numberOfSelections }]

                const field: CreateBusinessObjectDefinitionSelectField = {
                    defaultValue: defaultValue.length
                        ? {
                              selectedIds: defaultValue,
                          }
                        : undefined,
                    constraints,
                    options: options.map(({ value }) => ({ value })),
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Telephone },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { required },
            }) => {
                const field: CreateBusinessObjectDefinitionTelephoneField = {
                    defaultValue: defaultValue || undefined,
                    constraints: required ? [{ required: {} }] : [],
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Text },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { required, length },
            }) => {
                const constraints: CreateBusinessObjectDefinitionTextFieldConstraint[] =
                    [{ length }]
                if (required) constraints.push({ required: {} })
                const field: CreateBusinessObjectDefinitionTextField = {
                    defaultValue: defaultValue || undefined,
                    constraints,
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.Url },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { required },
            }) => {
                const constraints: CreateBusinessObjectDefinitionUrlFieldConstraint[] =
                    []
                if (required) constraints.push({ required: {} })
                const field: CreateBusinessObjectDefinitionUrlField = {
                    defaultValue: defaultValue || undefined,
                    constraints,
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.User },
            ({
                type,
                name,
                defaultValue,
                description,
                constraints: { required },
            }) => {
                const field: CreateBusinessObjectDefinitionUserField = {
                    defaultValue: defaultValue || undefined,
                    constraints: compact([required && { required: {} }]),
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )

        .with(
            { type: FieldType.Relation },
            ({
                type,
                name,
                description,
                constraints: { required, relationType },
            }) => {
                const constraints: CreateBusinessObjectDefinitionRelationFieldConstraint[] =
                    [{ relationType: { types: [relationType.id] } }]
                if (required) constraints.push({ required: {} })
                const field: CreateBusinessObjectDefinitionRelationField = {
                    constraints,
                }
                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )
        .with(
            { type: FieldType.List },
            ({ type, name, description, listOf }) => {
                const field: CreateBusinessObjectDefinitionListField = {
                    listOf: match(listOf)
                        .with(
                            { type: FieldType.Boolean },
                            ({ type, defaultValue }) => {
                                const field: CreateBusinessObjectDefinitionBooleanField =
                                    {
                                        defaultValue,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Currency },
                            ({
                                type,

                                defaultValue,
                                constraints,
                            }) => {
                                const field: CreateBusinessObjectDefinitionCurrencyField =
                                    {
                                        defaultValue,
                                        constraints: constraints.required
                                            ? [{ required: {} }]
                                            : [],
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Date },
                            ({
                                defaultValue,
                                constraints: { required, dateRange },
                                precision,
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionDateFieldConstraint[] =
                                    [{ dateRange }]
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionDateField =
                                    {
                                        defaultValue,
                                        constraints,
                                        precision: mapPrecision(precision),
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Document },
                            ({ type, constraints }) => {
                                const field: CreateBusinessObjectDefinitionDocumentField =
                                    {
                                        constraints: constraints.required
                                            ? [{ required: {} }]
                                            : [],
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Email },
                            ({ type, constraints, defaultValue }) => {
                                const field: CreateBusinessObjectDefinitionEmailField =
                                    {
                                        defaultValue,
                                        constraints: constraints.required
                                            ? [{ required: {} }]
                                            : [],
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Number },
                            ({
                                type,
                                defaultValue,
                                constraints: { required, range },
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionNumberFieldConstraint[] =
                                    [{ range }]
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionNumberField =
                                    {
                                        defaultValue,
                                        constraints,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Select },
                            ({
                                type,
                                defaultValue,
                                constraints: { numberOfSelections },
                                options,
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionSelectFieldConstraint[] =
                                    [{ numberOfSelections }]

                                const field: CreateBusinessObjectDefinitionSelectField =
                                    {
                                        defaultValue: {
                                            selectedIds: defaultValue,
                                        },
                                        constraints,
                                        options,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Telephone },
                            ({
                                type,
                                defaultValue,
                                constraints: { required },
                            }) => {
                                const field: CreateBusinessObjectDefinitionTelephoneField =
                                    {
                                        defaultValue,
                                        constraints: required
                                            ? [{ required: {} }]
                                            : [],
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Text },
                            ({
                                type,
                                defaultValue,
                                constraints: { required, length },
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionTextFieldConstraint[] =
                                    [{ length }]
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionTextField =
                                    {
                                        defaultValue,
                                        constraints,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.Url },
                            ({
                                type,
                                defaultValue,
                                constraints: { required },
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionUrlFieldConstraint[] =
                                    []
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionUrlField =
                                    {
                                        defaultValue,
                                        constraints,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .with(
                            { type: FieldType.User },
                            ({
                                type,
                                defaultValue,
                                constraints: { required },
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionUserFieldConstraint[] =
                                    []
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionUserField =
                                    {
                                        defaultValue,
                                        constraints,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )

                        .with(
                            { type: FieldType.Relation },
                            ({
                                type,
                                constraints: { required, relationType },
                            }) => {
                                const constraints: CreateBusinessObjectDefinitionRelationFieldConstraint[] =
                                    [
                                        {
                                            relationType: {
                                                types: [relationType.id],
                                            },
                                        },
                                    ]
                                if (required) constraints.push({ required: {} })
                                const field: CreateBusinessObjectDefinitionRelationField =
                                    {
                                        constraints,
                                    }
                                return {
                                    name,
                                    [type]: field,
                                }
                            }
                        )
                        .exhaustive(),
                }

                return {
                    name,
                    description,
                    [type]: field,
                }
            }
        )

        .exhaustive()

export const mapPrecision = (
    precision: DateFieldPrecision | undefined
): CreateBusinessObjectDefinitionDateFieldPrecision | undefined =>
    match(precision)
        .with(
            DateFieldPrecision.Day,
            () => CreateBusinessObjectDefinitionDateFieldPrecision.Day
        )
        .with(
            DateFieldPrecision.Month,
            () => CreateBusinessObjectDefinitionDateFieldPrecision.Month
        )
        .with(
            DateFieldPrecision.Year,
            () => CreateBusinessObjectDefinitionDateFieldPrecision.Year
        )
        .with(undefined, () => undefined)
        .exhaustive()

type IsDefinitionRestricted = (definition: {
    usage: {
        processes: { id: string }[]
        instanceCount: number
        relations: { id: string }[]
        boards: { id: string }[]
    }
}) => boolean
export const isDefinitionRestricted: IsDefinitionRestricted = ({
    usage: { processes, instanceCount, relations },
}) => processes.length + instanceCount + relations.length > 0

export const isFieldRequired = (field: Field): boolean =>
    'constraints' in field &&
    'required' in field.constraints &&
    field.constraints.required
