import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { MinimalModal } from 'Components/Modal/Minimal'
import { Text } from 'Components/Text'
import { Tutorial } from 'Features/Tutorials'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { slides } from '../Tutorial/slides'
import { ImportBusinessObjectsModal } from './ImportBusinessObjectsModal'
import { ProcessSingleQuery } from './__generated__/query'

type Props = Pick<
    ProcessSingleQuery['process'],
    'id' | 'name' | 'description' | 'operatesUpon'
> & {
    onBusinessObjectAdded: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

const HeaderSmall: FC<Props> = ({
    id,
    name,
    description,
    operatesUpon,
    onBusinessObjectAdded,
}) => {
    const navigate = useNavigate()
    const [infoOpen, setInfoOpen] = useState(false)
    return (
        <Styled>
            <div>
                <Text as="h3" variant="bold-2">
                    {name}
                </Text>
            </div>
            <div>
                <Tutorial markdown={slides} />
                <IconButton
                    iconName="Info"
                    onClick={() => setInfoOpen(true)}
                    variant="secondary"
                />
                <ImportBusinessObjectsModal
                    processId={id}
                    operatesUpon={operatesUpon[0]}
                    onBusinessObjectAdded={onBusinessObjectAdded}
                />
            </div>
            <MinimalModal open={infoOpen} onOpenChange={setInfoOpen}>
                <StyledInfoModal>
                    <div>
                        <Text as="h4" variant="bold-5">
                            Workflow name
                        </Text>
                        <Text as="p" variant="regular-5">
                            {name}
                        </Text>
                    </div>
                    <div>
                        <Text as="h4" variant="bold-5">
                            Description
                        </Text>
                        <Text as="p" variant="regular-5">
                            {description}
                        </Text>
                    </div>
                    <footer>
                        <IconTextButton
                            icon="Domain"
                            text={`View workflow definition`}
                            variant="secondary"
                            size="small"
                            onClick={() => {
                                navigate(
                                    `/manage-workspace/processes/${slugify(
                                        name,
                                        {
                                            lower: true,
                                        }
                                    )}`
                                )
                            }}
                        />
                    </footer>
                </StyledInfoModal>
            </MinimalModal>
        </Styled>
    )
}

const Styled = styled.header`
    padding: 1rem 4vw;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    > div:first-child {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
    > div:first-child > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        align-items: flex-start;
    }

    > div:first-child > div > p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;
        align-items: flex-start;
    }
`

const StyledInfoModal = styled.div`
    padding: 0.75rem 4vw 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h4 {
        padding-bottom: 0.25rem;
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    footer {
        padding-top: 0.25rem;
    }

    footer > button {
        width: 100%;
    }
`

export { HeaderSmall }
