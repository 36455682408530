import { css, flex, styled } from 'Adapters/Freestyled'
import { Variant } from './types'

export const StyledIconButton = styled.button<{ $variant: Variant }>`
    ${flex('row', 'center', 'center')};
    ${({ $variant }) => renderButtonVariantStyles($variant)}
    border-radius: ${({ theme }) => theme.borderRadius.small};
    cursor: pointer;
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    padding: 0.5rem;
    border: none;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        box-shadow: inset 0 0 0 0.125rem
            ${({ theme }) => theme.palette.brand['01'].normal};
    }

    &:disabled path {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }

    svg {
        min-height: 1.25rem;
        min-width: 1.25rem;
        max-height: 1.25rem;
        max-width: 1.25rem;
    }
`

function renderButtonVariantStyles(variant: Variant) {
    switch (variant) {
        case 'primary': {
            return css`
                background-color: ${({ theme }) =>
                    theme.palette.ui['07'].normal};

                .icon path {
                    fill: ${({ theme }) => theme.palette.icon['04'].normal};
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.palette.ui['07'].hover};
                    }
                }

                &:active,
                &:focus-visible {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['07'].active};
                }

                &:disabled {
                    background-color: ${({ theme }) =>
                        theme.palette.ui.tint['03'].normal};
                }
            `
        }
        case 'secondary': {
            return css`
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].normal};

                .icon path {
                    fill: ${({ theme }) => theme.palette.icon['01'].normal};
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.palette.ui['02'].hover};
                    }
                }

                &:active,
                &:focus-visible {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].active};
                }

                &:disabled {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].normal};
                }
            `
        }
        case 'ghost': {
            return css`
                background-color: transparent;
                color: ${({ theme }) => theme.palette.text.primary.normal};

                @media (hover: hover) {
                    &:hover {
                        color: ${({ theme }) =>
                            theme.palette.text.primary.hover};
                        background-color: ${({ theme }) =>
                            theme.palette.ui['03'].hover};
                    }
                }

                &:active {
                    color: ${({ theme }) => theme.palette.text.primary.active};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].active};
                }

                &:focus-visible {
                    box-shadow: 0 0 0 0.125rem
                        ${({ theme }) => theme.palette.brand['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].hover};
                }

                &:disabled {
                    color: ${({ theme }) => theme.palette.text['03'].normal};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['03'].normal};
                    border: 1px solid transparent;
                }

                .icon svg {
                    path {
                        fill: ${({ theme }) => theme.palette.icon['02'].normal};
                    }
                }
            `
        }
        default: {
            return css`
                background-color: ${({ theme }) =>
                    theme.palette.ui['01'].normal};

                .icon path {
                    fill: ${({ theme }) => theme.palette.icon['01'].normal};
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.palette.ui['01'].hover};
                    }
                }

                &:active,
                &:focus-visible {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['01'].active};
                }

                &:disabled {
                    background-color: ${({ theme }) =>
                        theme.palette.ui['01'].normal};
                }
            `
        }
    }
}
