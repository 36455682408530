import { match, P } from 'ts-pattern'
import { ProcessDisplayFragment } from '../__generated__/q'
import {
    AllGroup,
    OthersGroup,
    PhaseGroup,
    ProcessDisplay,
    ViewConfig,
} from '../types'

type Phase = ProcessDisplayFragment['phases'][number]
type BuildGroups = (config: ViewConfig) => {
    buildPhaseGroups: (
        phase: Phase
    ) => ProcessDisplay['phases'][number]['groups']
}
type Acc = Record<string, PhaseGroup | OthersGroup>

const allGroup = (
    businessObjects: Phase['businessObjects']['nodes']
): AllGroup => ({
    kind: 'AllGroup' as const,
    label: undefined,
    color: undefined,
    businessObjects,
})

const makeInit = (selectedField: ViewConfig['grouping']['fields'][number]) => ({
    ...selectedField?.groups.reduce(
        (acc: Acc, group) => ({
            ...acc,
            [group.id]: {
                kind: 'PhaseGroup' as const,
                label: group.value,
                color: group.color,
                businessObjects: [],
            },
        }),
        {}
    ),
    other: {
        kind: 'OthersGroup' as const,
        label: undefined,
        color: undefined,
        businessObjects: [],
    },
})

export const buildGroups: BuildGroups = config =>
    match(config.grouping.selection)
        .with('all', () => ({
            buildPhaseGroups: (phase: Phase) => [
                allGroup(phase.businessObjects.nodes),
            ],
        }))
        .otherwise(selection =>
            match(config.grouping.fields.find(({ id }) => id === selection))
                .with(P.nullish, () => ({
                    buildPhaseGroups: (phase: Phase) => [
                        allGroup(phase.businessObjects.nodes),
                    ],
                }))
                .otherwise(selectedField => ({
                    buildPhaseGroups: (phase: Phase) =>
                        Object.values(
                            phase.businessObjects.nodes.reduce(
                                (acc: Acc, bo) => {
                                    const key = match(
                                        bo.fields.find(
                                            ({ fieldDefinition }) =>
                                                fieldDefinition.id ===
                                                config.grouping.selection
                                        )
                                    )
                                        .with(
                                            {
                                                __typename:
                                                    'BusinessObjectSelectField',
                                            },
                                            ({ selectValue }) => selectValue[0]
                                        )
                                        .with(
                                            {
                                                __typename:
                                                    'BusinessObjectRelationField',
                                                relationValue: P.not(P.nullish),
                                            },
                                            ({ relationValue }) =>
                                                relationValue.id
                                        )
                                        .otherwise(() => undefined)

                                    ;(
                                        acc[key || 'other']?.businessObjects ??
                                        acc['other'].businessObjects
                                    ).push(bo)

                                    return acc
                                },
                                makeInit(selectedField)
                            )
                        ),
                }))
        )
