import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { UserFieldUnique } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { keyframes } from 'styled-components'
import { P, match } from 'ts-pattern'
import { useBusinessObjectDefinitionDisplay_UserFieldContentQuery } from './__generated__/q'

type Props = {
    field: UserFieldUnique
}

const UserFieldContent: FC<Props> = ({ field: { defaultValue } }) => {
    const { data } = useBusinessObjectDefinitionDisplay_UserFieldContentQuery({
        variables: { input: { id: defaultValue || '' } },
        skip: !defaultValue,
    })
    return match(defaultValue)
        .with(P.nullish, () => (
            <Styled>
                <Text as="p" variant="bold-5">
                    Default user:
                </Text>
                <Text as="p" variant="regular-5">
                    {'-'}
                </Text>
            </Styled>
        ))
        .otherwise(() => (
            <Styled data-loading={!data}>
                <Text as="p" variant="bold-5">
                    Default user:
                </Text>
                <Text as="p" variant="regular-5">
                    {data?.user.name || 'loading...'}
                </Text>
            </Styled>
        ))
}

const loading = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
`

const Styled = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    p:last-child {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    &[data-loading='true'] p:last-child {
        color: ${({ theme }) => theme.palette.text['03'].normal};
        animation: ${loading} 600ms infinite alternate;
    }
`

export { UserFieldContent }
