import { flex, styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import { useOrganisationUsers } from 'Hooks'
import { FC, useCallback, useMemo } from 'react'
import { useProcessCreator } from '../../useProcessCreator'

type Props = {
    phaseIndex: number
    criterionIndex: number
}

export const DefaultAssignee: FC<Props> = ({ phaseIndex, criterionIndex }) => {
    const {
        dispatch,
        state: {
            input: { phases },
        },
    } = useProcessCreator()

    const { users } = useOrganisationUsers()

    const selectedUser = useMemo(
        () =>
            phases[phaseIndex]?.criteria[criterionIndex].action?.defaultAssignee
                ?.user?.userId,
        [phases, phaseIndex, criterionIndex]
    )

    const onChange = useCallback(
        (userId: string) => {
            dispatch({
                type: 'setActionCriterionDefaultAssignee',
                payload: {
                    phaseIndex,
                    criterionIndex,
                    userId,
                },
            })
        },
        [dispatch, phaseIndex, criterionIndex]
    )

    return (
        <StyledDefaultAssignee>
            <Text as="legend" variant="regular-6">
                Default assignee:
            </Text>

            <Select
                id="user"
                value={selectedUser}
                onValueChange={onChange}
                name="user"
                options={[
                    {
                        disabled: true,
                        text: '-- select a user --',
                        value: '',
                    },
                    ...users.map(user => ({
                        text: user.isCurrentUser ? 'Me' : user.name,
                        value: user.id,
                        disabled: false,
                    })),
                ]}
                disabled={!users.length}
            />
        </StyledDefaultAssignee>
    )
}

const StyledDefaultAssignee = styled.fieldset`
    ${flex('row', 'flex-start', 'center')}
    gap: 0.5rem;
    border: none;
    padding: 0 0.5rem 0.5rem;

    legend {
        padding-bottom: 0.25rem;
        color: ${({ theme }) => theme.palette.text['03'].normal};
    }
`
