import { isNull, isUndefined } from 'lodash'

/**
 * Generates paired [K,V] entries from an object type, e.g.
 *
 * type T = { a: string; b: boolean }
 *
 * EntryOf\<T\> => ['a', string] | ['b', boolean]
 *
 * Used to keep association between key and value pairs in heterogenous-valued objects, rather than the simpler T[keyof T] approach,
 * which would produce
 *
 * T[keyof T] => ['a' | 'b', string | boolean]
 *
 * Excludes null/undefined values because it's intended to be used on @oneOf values (once GQL supports that...), and this is verified at runtime
 */
type EntryOf<T> = Exclude<
    {
        [K in keyof T]: [K, Exclude<T[K], null | undefined>]
    }[keyof T],
    null | undefined
>

export const oneOfPair = <T extends object>(obj: T): EntryOf<T> => {
    const pair = Object.entries(obj).find(
        ([_, value]) => !isNull(value) && !isUndefined(value)
    )

    if (!pair) {
        throw new Error('At least one property must be set on oneOf input')
    }

    return pair as EntryOf<T>
}

export const oneOf = <T extends object>(obj: T): EntryOf<T>[1] =>
    oneOfPair(obj)[1]
