import { Content, Item, Root, Trigger } from '@radix-ui/react-accordion'
import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Spinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { FC, useState } from 'react'
import { BusinessObjectSelector } from './BusinessObjectSelector'
import { useBusinessObjectDefinitions_SetBusinessObjectQuery } from './__generated__/query'

type Props = {
    onBusinessObjectSelect: (params: {
        businessObjectDefinitionId: string
        businessObjectId: string
        businessObjectLabel: string
    }) => void
    selection: {
        businessObjectDefinitionId?: string
        businessObjectId?: string
    }
}

export const SetBusinessObjectContent: FC<Props> = ({
    onBusinessObjectSelect,
    selection,
}) => {
    const { data } = useBusinessObjectDefinitions_SetBusinessObjectQuery()

    const [openAccordionItem, setOpenAccordionItem] = useState<
        string | undefined
    >(selection.businessObjectDefinitionId)

    if (!data) return <Spinner />

    return (
        <StyledSetBusinessObjectContent>
            <Root
                type="single"
                collapsible
                value={openAccordionItem}
                onValueChange={setOpenAccordionItem}
            >
                {data.businessObjectDefinitions.map(definition => {
                    return (
                        <StyledItem value={definition.id} key={definition.id}>
                            <StyledTrigger>
                                <Text as="span" variant="bold-5">
                                    {`${definition.name}`}
                                </Text>

                                <Icon name="AngleDown" />
                            </StyledTrigger>

                            <Content>
                                <BusinessObjectSelector
                                    businessObjectDefinitionId={definition.id}
                                    selectedBusinessObjectId={
                                        selection?.businessObjectId
                                    }
                                    onBusinessObjectSelect={
                                        onBusinessObjectSelect
                                    }
                                />
                            </Content>
                        </StyledItem>
                    )
                })}
            </Root>
        </StyledSetBusinessObjectContent>
    )
}

const StyledSetBusinessObjectContent = styled.div`
    width: 100vw;
    max-width: 45rem;
    max-height: 45rem;
    overflow: auto;
    border: 0;
`

const StyledItem = styled(Item)`
    border-top: 1px solid ${({ theme }) => theme.palette.ui['02'].normal};
    position: relative;

    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const StyledTrigger = styled(Trigger)`
    ${flex('row', 'space-between', 'center')};
    width: 100%;
    cursor: pointer;

    > span {
        ${flex('row', 'flex-start', 'center')};
        display: block;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        padding: 1.5rem 0;
        text-transform: capitalize;
    }

    > .icon {
        padding-right: 1rem;

        svg {
            transition: transform
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
        }
    }

    &[data-state='open'] > .icon svg {
        transform: rotateZ(180deg);
    }
`
