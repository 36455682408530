import { css, flex, styled } from 'Adapters/Freestyled'
import { CollapsableLeftNav } from 'Components/CollapsableLeftNav'
import { NavLinkList } from 'Components/NavLinkList'
import { useResponder } from 'Hooks'
import { last } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Category } from './Category'
import { MobileNav } from './MobileNav'
import { useAvailable_TemplatesQuery } from './__generated__/query'

export const WorkspaceSetup = () => {
    const location = useLocation()

    const navigate = useNavigate()

    const { data } = useAvailable_TemplatesQuery()

    const { categories, deployedCategories } = useMemo(() => {
        const deployedDefinitions = new Set(
            data?.businessObjectDefinitions.map(
                definition => definition.name
            ) ?? []
        )

        const deployedProcesses = new Set(
            data?.processes.map(process => process.name) ?? []
        )

        return {
            categories: data?.workspaceTemplates ?? [],
            deployedCategories: new Set(
                (data?.workspaceTemplates ?? [])
                    .filter(
                        category =>
                            category.templates.some(template =>
                                template.definitions.some(definition =>
                                    deployedDefinitions.has(definition.name)
                                )
                            ) ||
                            category.templates.some(template =>
                                template.processes.some(process =>
                                    deployedProcesses.has(process.name)
                                )
                            )
                    )
                    .map(category => category.id)
            ),
        }
    }, [data])

    const [isCollapsed, setCollapsed] = useState(false)

    const { isLarge } = useResponder()

    const [navOpen, setNavOpen] = useState(false)

    // Navigate to first category when available, instead of showing blank page
    useEffect(() => {
        const endOfPath = last(location.pathname.split('/'))
        if (endOfPath === 'templates' && categories.length) {
            const firstCategory = categories[0]
            navigate(firstCategory.id)
        }
    }, [categories, location.pathname, navigate])

    return (
        <Styled $isLarge={isLarge}>
            {isLarge ? (
                <CollapsableLeftNav
                    collapsed={isCollapsed}
                    onCollapsedChanged={setCollapsed}
                    title="Templates"
                    feature={'templates'}
                    content={
                        <NavLinkList
                            items={categories.map(
                                ({ name, description, id }) => ({
                                    icon: 'Cube',
                                    heading: name,
                                    description,
                                    to: id,
                                })
                            )}
                        />
                    }
                />
            ) : (
                <MobileNav
                    categories={categories}
                    navOpen={navOpen}
                    setNavOpen={setNavOpen}
                />
            )}
            <Routes>
                {categories.map(templateCategory => (
                    <Route
                        key={templateCategory.id}
                        path={templateCategory.id}
                        element={
                            <Category
                                templateCategory={templateCategory}
                                isPreviouslyDeployed={deployedCategories.has(
                                    templateCategory.id
                                )}
                                onDeploy={() => setCollapsed(true)}
                            />
                        }
                    />
                ))}
            </Routes>
        </Styled>
    )
}

const largeScreenStyles = css`
    display: grid;
    grid-template: minmax(0, 1fr) / auto minmax(0, 1fr);
`

const smallScreenStyles = css`
    ${flex('column', 'flex-start', 'flex-start')};
`

const Styled = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) => ($isLarge ? largeScreenStyles : smallScreenStyles)};
`
