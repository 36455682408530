import { css } from 'Adapters/Freestyled'
import { keyframes } from 'styled-components'

export const DURATION = 200
const BEZIER = 'cubic-bezier(0.41, 0.73, 0.51, 1.02)'

const enter = keyframes`
    0% {
      transform: scale(0.9);
      transform: translateY(100%);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      transform: translateY(0);
      opacity: 1;
    }
`

const leave = keyframes`
    0% { 
      transform: scale(1); 
      opacity: 1;
    }
    
    100% { 
      transform: scale(0.9); 
      opacity: 0;
    }
`

const slideIn = keyframes`
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
`

const slideOut = keyframes`
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(100%);
    }
`

export const enterAnimation = css`
    animation-name: ${enter}, ${slideIn};
    animation-duration: ${DURATION}ms;
    animation-fill-mode: none;
    animation-timing-function: ease-out, ${BEZIER};
`

export const exitAnimation = css`
    animation-name: ${leave}, ${slideOut};
    animation-duration: ${DURATION}ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in, ${BEZIER};
`
