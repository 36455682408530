import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'

type Props = {
    isRequired: boolean
}

const FieldRequiredTag: FC<Props> = ({ isRequired }) =>
    isRequired ? (
        <Styled>
            <Text as="p" variant="bold-6">
                Required
            </Text>
        </Styled>
    ) : null

const Styled = styled.div`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.palette.text['02'].normal};
    background-color: ${({ theme }) =>
        theme.palette.meta['04'].tint['01'].active};
`

export { FieldRequiredTag }
