import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton, DropdownDivider } from 'Components/Dropdown'
import { TextAreaInput } from 'Components/Input'
import { Text } from 'Components/Text'
import { FC, useRef } from 'react'
import { v4 } from 'uuid'
import { CreateActionCriterionInput } from '../../../../__generated__'
import { useProcessCreator } from '../../useProcessCreator'
import { Conditions } from './Conditions'
import { DefaultAssignee } from './DefaultAssignee'
import { DefaultDueTime } from './DefaultDueTime'

type Props = {
    phaseIndex: number
    criterionIndex: number
    criterion: {
        action: CreateActionCriterionInput
    }
}

export const ActionCriterion: FC<Props> = ({
    phaseIndex,
    criterionIndex,
    criterion,
}) => {
    const {
        state: { input },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const { current: inputId } = useRef(v4())

    const { current: purposeInputId } = useRef(v4())

    return (
        <Styled>
            <header>
                <IconTextButton
                    icon="CheckboxTicked"
                    text="Action"
                    disabled
                    size="xSmall"
                    variant="ghost"
                />
                <Dropdown
                    renderOpenDropdownButton={() => (
                        <IconButton iconName="More" />
                    )}
                    renderContent={() => (
                        <>
                            <DropdownButton
                                icon="ArrowUp"
                                text="Move criterion up"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionUp',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={criterionIndex === 0}
                            />
                            <DropdownButton
                                icon="ArrowDown"
                                text="Move criterion down"
                                onSelect={() => {
                                    dispatch({
                                        type: 'moveCriterionDown',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                                disabled={
                                    criterionIndex ===
                                    input.phases[phaseIndex].criteria.length - 1
                                }
                            />
                            <DropdownDivider />
                            <DropdownButton
                                icon="Delete"
                                text="Remove criterion"
                                onSelect={() => {
                                    dispatch({
                                        type: 'removeCriterion',
                                        payload: {
                                            phaseIndex,
                                            criterionIndex,
                                        },
                                    })
                                }}
                            />
                        </>
                    )}
                />
            </header>

            <div>
                <VisuallyHidden>
                    <label htmlFor={inputId}>Criteria description</label>
                </VisuallyHidden>

                <TextAreaInput
                    id={inputId}
                    value={criterion.action.description}
                    placeholder="Description"
                    onChange={({ currentTarget: { value: description } }) => {
                        dispatch({
                            type: 'setActionCriterionDescription',
                            payload: {
                                phaseIndex,
                                criterionIndex,
                                description,
                            },
                        })
                    }}
                />
            </div>

            <div className="purpose">
                <Text as="label" variant="regular-6" htmlFor={purposeInputId}>
                    Purpose:
                </Text>

                <TextAreaInput
                    id={purposeInputId}
                    value={criterion.action?.purpose ?? ''}
                    placeholder="Purpose"
                    onChange={({ currentTarget: { value: purpose } }) => {
                        dispatch({
                            type: 'actionCriterionPurposeChanged',
                            payload: {
                                phaseIndex,
                                criterionIndex,
                                purpose,
                            },
                        })
                    }}
                />
            </div>

            <DefaultDueTime
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />

            <DefaultAssignee
                phaseIndex={phaseIndex}
                criterionIndex={criterionIndex}
            />

            {isProcessFirst ? null : (
                <Conditions
                    phaseIndex={phaseIndex}
                    criterionIndex={criterionIndex}
                />
            )}
        </Styled>
    )
}

const Styled = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.5rem 0.125rem 0.125rem;
    }

    > div {
        padding: 0 0.5rem 0.5rem;
    }

    .purpose {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.25rem;

        label {
            color: ${({ theme }) => theme.palette.text['03'].normal};
        }
    }
`
