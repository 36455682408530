import { flex, styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { HomeLink } from './HomeLink'
import { AppHeaderMenu } from './Menu'
import { SandboxWarning } from './SandboxWarning'

type Props = {
    setWorkspaceControlOpen: (open: boolean) => void
}

export const AppHeader: FC<Props> = ({ setWorkspaceControlOpen }) => (
    <header>
        <SandboxWarning />
        <Styled>
            <HomeLink />
            <AppHeaderMenu setWorkspaceControlOpen={setWorkspaceControlOpen} />
        </Styled>
    </header>
)

const Styled = styled.div`
    ${flex('row', 'space-between', 'center')};
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.brand['01'].normal};
    border-bottom: 1px solid
        ${({ theme }) => theme.palette.brand['06'].normal}20;

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        padding: 0 0.5rem;
    }
`
