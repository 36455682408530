import {
    Content,
    Portal,
    Root,
    SelectGroup,
    Viewport,
} from '@radix-ui/react-select'
import { boxShadow, styled } from 'Adapters/Freestyled'
import { getPortalsContainer, has } from 'Utils'
import { FC } from 'react'
import { SelectItem, SelectTrigger, SelectTriggerProps } from './Components'
import { SelectLabel } from './Components/SelectLabel'
import { SelectOption } from './types'

// NB. After moving to version 2.0, the Select component now no longer accepts empty strings
// as values. Therefore we need to map empty strings back and forth to a special value to
// avoid runtime errors.

const UNASSIGNED_VALUE = 'UNASSIGNED_VALUE'

const withUnassigned = (value: string) =>
    value === '' ? UNASSIGNED_VALUE : value

type Options<TValues extends string = string> = Array<
    | SelectOption<TValues>
    | { groupName: string; options: Array<SelectOption<TValues>> }
>

type Props<TValues extends string = string> = {
    id: string
    value?: string
    onValueChange: (value: TValues) => void
    name: string
    disabled?: boolean
    options: Options<TValues>
    hasError?: boolean
    Trigger?: FC<SelectTriggerProps>
    contentPosition?: 'item-aligned' | 'popper'
    placeholder?: string
}

export const Select = <TValues extends string = string>({
    id,
    value,
    onValueChange,
    name,
    disabled = false,
    options,
    hasError = false,
    Trigger = SelectTrigger,
    contentPosition = 'popper',
    placeholder,
}: Props<TValues>) => {
    return (
        <Root
            value={value === '' ? UNASSIGNED_VALUE : value}
            onValueChange={value =>
                onValueChange(
                    value.startsWith(UNASSIGNED_VALUE)
                        ? ('' as TValues)
                        : (value as TValues)
                )
            }
            name={name}
            disabled={disabled}
        >
            <Trigger
                id={id}
                hasError={hasError}
                hasValue={!!value}
                disabled={disabled}
                placeholder={placeholder}
            />

            <Portal container={getPortalsContainer()}>
                <StyledContent
                    position={contentPosition}
                    // Workaround to a 2 year old bug in @radix-ui/react-select
                    // https://github.com/radix-ui/primitives/issues/1658
                    ref={ref =>
                        ref?.addEventListener('touchend', e =>
                            e.preventDefault()
                        )
                    }
                >
                    <StyledViewport>
                        {options.map(option =>
                            has(option, 'groupName') ? (
                                <StyledSelectGroup
                                    title={option.groupName}
                                    key={option.groupName}
                                >
                                    <SelectLabel>
                                        {option.groupName}
                                    </SelectLabel>
                                    {option.options.map(op => (
                                        <SelectItem
                                            option={{
                                                ...op,
                                                value: withUnassigned(op.value),
                                            }}
                                            key={withUnassigned(op.value)}
                                        />
                                    ))}
                                </StyledSelectGroup>
                            ) : (
                                <SelectItem
                                    option={{
                                        ...option,
                                        value: withUnassigned(option.value),
                                    }}
                                    key={withUnassigned(option.value)}
                                />
                            )
                        )}
                    </StyledViewport>
                </StyledContent>
            </Portal>
        </Root>
    )
}

export const StyledContent = styled(Content)`
    z-index: 4;
    ${boxShadow()};
    overflow: hidden;
    background-color: white;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    max-height: var(--radix-select-content-available-height);
    width: var(--radix-select-trigger-width);
`

export const StyledViewport = styled(Viewport)`
    padding: 0.5rem;
`

export const StyledSelectGroup = styled(SelectGroup)`
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    margin-bottom: 0.25rem;
`
