import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { useDeleteActionViewMutation } from 'Root/App/Main/Actions/__generated__/mutation'
import { GetActionViewsQuery } from 'Root/App/Main/Actions/__generated__/query'
import { FC } from 'react'

type Props = {
    view: GetActionViewsQuery['views'][number]
}

export const ManageSingleView: FC<Props> = ({ view }) => {
    const {
        open: errorToastOpen,
        setOpen: setErrorToastOpen,
        triggerToast: triggerErrorToast,
    } = useTriggerToast()

    const [deleteView] = useDeleteActionViewMutation({
        variables: {
            input: {
                id: view.id,
            },
        },
        optimisticResponse: {
            deleteView: view.id,
        },
        update(cache) {
            cache.evict({
                id: cache.identify({ id: view.id, __typename: 'View' }),
            })
            cache.gc()
        },
        onError: () => {
            triggerErrorToast()
        },
    })

    return (
        <StyledManageSingleView>
            <div className="name-wrapper">
                <Icon name={'View'} />
                <span>{view.name}</span>
            </div>

            <div>
                <IconButton
                    iconName={'Delete'}
                    className="delete-button"
                    onClick={() => {
                        deleteView()
                    }}
                />
            </div>

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={
                    'Unable to delete the view. Contact us if the problem persists.'
                }
            />
        </StyledManageSingleView>
    )
}

const StyledManageSingleView = styled.li`
    ${flex('row', 'space-between', 'center')};
    width: 100%;

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.5rem;
    }

    .name-wrapper {
        flex: 1;
        padding-right: 0.5rem;

        input {
            flex: 1;
        }
    }
`
