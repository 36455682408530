import { Icon, IconName } from 'Components/Icon'
import { Tooltip } from 'Components/Tooltip'
import { ReactNode, forwardRef } from 'react'
import { StyledIconTextButton } from './styles'
import { Size, Variant } from './types'

const truncate = (str: string, max: number): string =>
    str.length > max ? str.slice(0, max) + '...' : str

type SharedProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: IconName
    text: string | ReactNode
    selected?: boolean
    variant?: Variant
    size?: Size
    iconRight?: boolean
    hideTitle?: boolean
    maxLength?: number
}

type WithMaxLength = SharedProps & {
    text: string
    maxLength: number
}

type WithoutMaxLength = SharedProps & {
    text: ReactNode | string
    maxLength?: never
}

export type Props = WithMaxLength | WithoutMaxLength

export const IconTextButton = forwardRef<HTMLButtonElement, Props>(
    (
        {
            text,
            icon,
            selected = false,
            variant = 'ghost',
            size = 'small',
            iconRight = false,
            hideTitle = false,
            maxLength,
            ...props
        },
        ref
    ) => {
        return (
            <Tooltip
                content={text}
                disabled={
                    maxLength === undefined ||
                    (typeof text === 'string' && text.length < maxLength)
                }
            >
                <StyledIconTextButton
                    $selected={selected}
                    $variant={variant}
                    $size={size}
                    $iconRight={iconRight}
                    {...props}
                    ref={ref}
                >
                    <Icon name={icon} hideTitle={hideTitle} />
                    <span>
                        {typeof maxLength === 'number' &&
                        typeof text === 'string'
                            ? truncate(text, maxLength)
                            : text}
                    </span>
                </StyledIconTextButton>
            </Tooltip>
        )
    }
)
