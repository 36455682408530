import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { BizObjFragmentDoc } from 'Fragments/__generated__/BusinessObject'
import { useModalControls } from 'Hooks'
import { CreateBusinessObjectInput } from '__generated__'
import { GraphQLError } from 'graphql'
import pMap from 'p-map'
import { useCallback, useState } from 'react'
import { useCreateBusinessObjectMutation } from '../../../../Hooks/__generated__/queries'
import { ErroringInput } from './types'

export const useBatchCreateBusinessObject = () => {
    const { open, setOpen, openModal } = useModalControls()

    const [erroringInputs, setErroringInputs] = useState<ErroringInput[]>([])

    const openWizardWithErroringInputs = useCallback(
        (errors: ErroringInput[]) => {
            setErroringInputs(errors)
            openModal()
        },
        [openModal]
    )

    const [create] = useCreateBusinessObjectMutation({
        onError(e) {
            logger.error('Error creating business object', e)
        },
        update(cache, { data }) {
            try {
                cache.modify({
                    fields: {
                        businessObjects(existing = []) {
                            const newBusinessObject = cache.writeFragment({
                                data: data?.createBusinessObject,
                                fragment: BizObjFragmentDoc,
                                fragmentName: 'BizObj',
                            })
                            return [...existing, newBusinessObject]
                        },
                    },
                })
            } catch (err) {
                logger.error(
                    'Could not update cache after creating business object',
                    err as Error
                )
                throw err
            }
        },
        refetchQueries: ['OnboardingProgress'],
    })

    const handleBatchCreateBusinessObjects = useCallback(
        async (input: CreateBusinessObjectInput[]) => {
            const results = await pMap(
                input,
                async value => {
                    return await create({ variables: { input: value } })
                },
                { concurrency: 5, stopOnError: false }
            )

            const errors = results.reduce<ErroringInput[]>((out, result, i) => {
                const errors = result.errors as any // This is being typed incorrectly. Typed as an array but in fact an object. The array lives on a property of that object
                const hasError = !!errors
                if (!hasError) return out
                const graphqlErrors =
                    errors.graphQLErrors as unknown as GraphQLError[]

                const erroringFieldIds: string[] = []

                graphqlErrors.forEach(e => {
                    if (!hasCustomErrorExtenstions(e.extensions)) return
                    Object.keys(e.extensions.errors.errors).forEach(fieldId => {
                        erroringFieldIds.push(fieldId)
                    })
                })

                return [
                    ...out,
                    {
                        erroringInput: input[i],
                        erroringFields: erroringFieldIds,
                    },
                ]
            }, [])

            if (errors.length) openWizardWithErroringInputs(errors)
        },
        [create, openWizardWithErroringInputs]
    )

    return {
        handleBatchCreateBusinessObjects,
        wizardIsOpen: open,
        setWizardOpen: setOpen,
        erroringInputs,
    }
}
