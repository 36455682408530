import { css, flex, styled } from 'Adapters/Freestyled'

export const StyledCriteria = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
`
type PhaseBoxStatus = { $complete: boolean; $current: boolean }

const statusStyles = ({ $complete, $current }: PhaseBoxStatus) => {
    if ($complete)
        return css`
            background-color: ${({ theme }) =>
                theme.palette.support['02'].normal + 'A6'}; //65% opacity
        `

    if ($current)
        return css`
            background-color: ${({ theme }) =>
                theme.palette.data['01'].normal + '29'}; //16% opacity
        `

    return css`
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    `
}

export const StyledPhaseBox = styled.div<PhaseBoxStatus>`
    ${flex('column', 'space-between', 'center')};
    ${({ $complete, $current }) => statusStyles({ $complete, $current })};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    flex: 1;
    position: relative;
    padding: 0.6rem 0;

    > div {
        ${flex('column', 'flex-start', 'center')};
        width: 100%;

        header {
            ${flex('column', 'flex-start', 'center')};
            width: 100%;
            gap: 0.25rem;

            .complete {
                ${flex('row', 'flex-start', 'center')};
                gap: 0.25rem;

                h3 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                // The down icon is weirdly large in comparison to the check icon
                .down-icon svg {
                    height: 1rem;
                    width: 1rem;
                }
            }
        }

        .icon svg {
            path {
                fill: ${({ theme }) => theme.palette.icon['01'].normal};
            }
        }
    }
`
