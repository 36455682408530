import { useSortItems } from './hooks'
import { SortButton } from './SortButton/SortButton'
import { StyledMenuCell, StyledTable, StyledTableWrapper } from './styles'
import { TableProps } from './types'

export const Table = <TItem,>({
    columnSetup,
    items,
    renderItem,
    includeMenuCell = false,
}: TableProps<TItem>) => {
    const { sortState, setSortState, sortedItems } = useSortItems({ items })

    return (
        <StyledTableWrapper>
            <StyledTable $includeMenuCell={includeMenuCell}>
                <thead>
                    <tr>
                        {includeMenuCell ? <StyledMenuCell as="th" /> : null}

                        {columnSetup.map(({ width, heading, sorting }, i) => {
                            return (
                                <th
                                    style={width ? { width: width } : undefined}
                                    key={i}
                                >
                                    {/* Render a button or plain text depending on if the column is sortable */}
                                    {sorting ? (
                                        <SortButton<TItem>
                                            heading={heading}
                                            primarySort={sorting.primary}
                                            secondarySort={sorting.secondary}
                                            sortState={sortState}
                                            setSortState={setSortState}
                                        />
                                    ) : (
                                        heading
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>{sortedItems.map(item => renderItem(item))}</tbody>
            </StyledTable>
        </StyledTableWrapper>
    )
}

export type { ColumnSetup } from './types'
