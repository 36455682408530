import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { css, flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { slides } from './types'

type Props = {
    currentIndex: number
    incrementSlide: () => void
    decrementSlide: () => void
    goToSlide: (index: number) => void
}

export const SlideControls: FC<Props> = ({
    currentIndex,
    decrementSlide,
    incrementSlide,
    goToSlide,
}) => {
    const { isLarge } = useResponder()

    return (
        <StyledSlideControls $isLarge={isLarge}>
            <StyledChangeSlideButton
                iconName={'CarrotLeft'}
                title="back"
                onClick={decrementSlide}
                disabled={currentIndex === 0}
            />

            <div className="pips">
                {slides.map((slide, index) => (
                    <StyledPipButton
                        $selected={index === currentIndex}
                        key={slide}
                        onClick={() => {
                            goToSlide(index)
                        }}
                        $isLarge={isLarge}
                    >
                        <VisuallyHidden>
                            <span>{slide}</span>
                        </VisuallyHidden>
                    </StyledPipButton>
                ))}
            </div>

            <StyledChangeSlideButton
                iconName={'CarrotRight'}
                title="forward"
                onClick={incrementSlide}
                disabled={currentIndex === slides.length - 1}
            />
        </StyledSlideControls>
    )
}

const largeStyles = css`
    bottom: 4rem;
`

const smallStyles = css`
    bottom: 2rem;
`

const StyledSlideControls = styled.div<{ $isLarge: boolean }>`
    ${({ $isLarge }) => ($isLarge ? largeStyles : smallStyles)};
    ${flex('row', 'center', 'center')};
    gap: 1rem;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);

    .pips {
        ${flex('row', 'center', 'center')};
        gap: 0.5rem;
    }
`

const largePipStyles = css`
    height: 1.25rem;
    width: 1.25rem;
`

const smallPipStyles = css`
    height: 1rem;
    width: 1rem;
`

const StyledPipButton = styled.button<{
    $selected: boolean
    $isLarge: boolean
}>`
    ${({ $isLarge }) => ($isLarge ? largePipStyles : smallPipStyles)};
    border-radius: 50%;
    background-color: ${({ theme, $selected }) =>
        $selected ? theme.palette.brand['01'].normal : 'transparent'};
    border: 2px solid ${({ theme }) => theme.palette.brand['01'].normal};

    &:focus-visible {
        box-shadow: inset 0 0 0 0.125rem
            ${({ theme, $selected }) =>
                $selected
                    ? theme.palette.brand['03'].normal
                    : theme.palette.brand['01'].normal};
    }
`

const StyledChangeSlideButton = styled(IconButton)`
    &,
    &:hover,
    &:focus-visible,
    &:disabled {
        background-color: transparent;
    }

    .icon svg {
        min-height: 2rem;
        min-width: 2rem;
        max-height: 2rem;
        max-width: 2rem;

        path {
            fill: ${({ theme }) => theme.palette.brand['01'].normal};
        }
    }

    &:disabled {
        .icon svg path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }
    }
`
