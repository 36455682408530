import { flex, styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { regular4 } from 'Components/Text'
import { BODFields } from 'Features/CreateBusinessObjectModal/types'
import { noop } from 'lodash'
import { FC, useCallback, useState } from 'react'
import { ErroringInput } from '../types'
import { Slide } from './Slide'

type WizardProps = {
    open: boolean
    setOpen: (open: boolean) => void
    erroringInputs: ErroringInput[]
    businessObjectDefinitionFields: BODFields
}

export const FixValidationErrorsWizard: FC<WizardProps> = ({
    setOpen,
    open,
    erroringInputs,
    businessObjectDefinitionFields,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0)

    const incrementSlide = useCallback(() => {
        setCurrentSlide(prev => prev + 1)
    }, [])

    const onOpenChange = useCallback(
        (bool: boolean) => {
            setOpen(bool)
            setCurrentSlide(0)
        },
        [setOpen]
    )

    const closeModal = useCallback(() => {
        onOpenChange(false)
    }, [onOpenChange])

    return (
        <ControlledModal
            renderContent={() => {
                return (
                    <>
                        {currentSlide === 0 ? (
                            <StyledFirstSlide>
                                <p>
                                    Some validation errors were found in{' '}
                                    {erroringInputs.length} row(s) of your data.
                                    Follow this process to make the necessary
                                    ammendments.
                                </p>

                                <div className="button-wrapper">
                                    <TextButton
                                        onClick={closeModal}
                                        text={'Ignore'}
                                        size="small"
                                        variant="secondary"
                                    />
                                    <TextButton
                                        onClick={incrementSlide}
                                        text={'Next'}
                                        size="small"
                                        variant="primary"
                                    />
                                </div>
                            </StyledFirstSlide>
                        ) : null}

                        {erroringInputs.map((input, i) => {
                            return (
                                <Slide
                                    key={i}
                                    isOpen={currentSlide === i + 1}
                                    erroringInput={input}
                                    incrementSlide={incrementSlide}
                                    isFinalSlide={
                                        currentSlide === erroringInputs.length
                                    }
                                    currentSlide={currentSlide}
                                    finalSlide={erroringInputs.length}
                                    businessObjectDefinitionFields={
                                        businessObjectDefinitionFields
                                    }
                                    businessObjectDefinitionId={
                                        input.erroringInput
                                            .businessObjectDefinitionId
                                    }
                                    closeModal={closeModal}
                                />
                            )
                        })}
                    </>
                )
            }}
            title={'Validation Errors'}
            description={''}
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={noop}
        />
    )
}

const StyledFirstSlide = styled.div`
    width: 100vw;
    max-width: 30rem;
    max-height: 45rem;
    overflow: auto;

    > p {
        ${regular4};
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .button-wrapper {
        ${flex('row', 'center', 'center')};
        gap: 1rem;
        width: 100%;
        margin-top: 1.5rem;

        > button {
            flex: 1;
        }
    }
`
