import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { useBusinessObjectOverview_RemoveFromProccessMutation } from './__generated__/q'

type Props = {
    businessObjectId: string
    processId: string
    onRemoveFromProcess: () => void
    isTerminalPhase: boolean
    percentageComplete: number
}

const OverviewFooter: FC<Props> = ({
    processId,
    businessObjectId,
    onRemoveFromProcess,
    isTerminalPhase,
    percentageComplete,
}) => {
    const isComplete = isTerminalPhase && percentageComplete === 100

    const [remove, { loading }] =
        useBusinessObjectOverview_RemoveFromProccessMutation()

    const { open, setOpen, triggerToast } = useTriggerToast()

    return (
        <Styled>
            <Tooltip
                content={
                    'This record cannot be removed as it is already complete'
                }
                disabled={!isComplete}
                side="left"
                offset={10}
            >
                <IconTextButton
                    icon="CircleMinus"
                    text="Remove from workflow"
                    size="xSmall"
                    variant="primary"
                    onClick={() => {
                        remove({
                            variables: {
                                input: {
                                    processId,
                                    businessObjectId,
                                },
                            },
                            onError: () => {
                                triggerToast()
                            },
                            onCompleted: () => {
                                onRemoveFromProcess()
                            },
                        })
                    }}
                    disabled={loading || isComplete}
                />
            </Tooltip>

            <ContactUsToast
                open={open}
                onOpenChange={setOpen}
                description="Unable to remove the record"
            />
        </Styled>
    )
}

const Styled = styled.footer`
    flex: 1;
    display: flex;
    padding: 1rem 4vw;
    background-color: ${({ theme }) => theme.palette.ui['03'].normal};
    align-items: center;

    button {
        width: 100%;
    }
`

export { OverviewFooter }
