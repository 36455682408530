import { TagButton } from 'Components/Button/Tag'
import { Drawer } from 'Components/Drawer'
import { BusinessObjectDefinitionDisplay } from 'Features/BusinessObjectDefinitionDisplay'
import { FC, useRef, useState } from 'react'

type Props = {
    obj: {
        id: string
        name: string
    }
}

export const ObjectDefTag: FC<Props> = ({ obj }) => {
    const ref = useRef<HTMLButtonElement>(null)

    const [open, setOpen] = useState(false)

    return (
        <>
            <TagButton
                ref={ref}
                icon="Cube"
                text={obj.name}
                onClick={() => setOpen(true)}
            />

            <Drawer
                open={open}
                onOpenChange={setOpen}
                content={<BusinessObjectDefinitionDisplay id={obj.id} />}
                returnFocus={() => ref.current?.focus()}
            />
        </>
    )
}
