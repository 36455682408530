import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
export type Principal_UserGroupPrincipal_Fragment = { __typename: 'UserGroupPrincipal', id: string, type: Types.PrincipalType, userGroup: { __typename?: 'UserGroup', id: string, name: string } };

export type Principal_UserPrincipal_Fragment = { __typename: 'UserPrincipal', id: string, type: Types.PrincipalType, user: { __typename?: 'User', id: string, name: string } };

export type Principal_WebhookPrincipal_Fragment = { __typename: 'WebhookPrincipal', id: string, type: Types.PrincipalType, webhook: { __typename?: 'Webhook', id: string, name?: string | null } };

export type PrincipalFragment = Principal_UserGroupPrincipal_Fragment | Principal_UserPrincipal_Fragment | Principal_WebhookPrincipal_Fragment;

export const PrincipalFragmentDoc = gql`
    fragment Principal on Principal {
  __typename
  id
  type
  ... on UserPrincipal {
    user {
      id
      name
    }
  }
  ... on WebhookPrincipal {
    webhook {
      id
      name
    }
  }
  ... on UserGroupPrincipal {
    userGroup {
      id
      name
    }
  }
}
    `;