import { ErrorBoundary } from 'Features/ErrorBoundary'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Account } from './Account'
import { Actions } from './Actions'
import { BusinessObjects } from './BusinessObjects'
import { Init } from './Init'
import { ManageWorkspace } from './ManageWorkspace'
import { Processes } from './Processes'
import { TenantCheck } from './TenantCheck'
import { Welcome } from './Welcome'

const MainRoutes: FC<{ isFreshTenant: boolean }> = ({ isFreshTenant }) => (
    <Routes>
        <Route index element={<Init isFreshTenant={isFreshTenant} />} />

        <Route
            path="welcome/*"
            element={
                <ErrorBoundary page="welcome">
                    <Welcome />
                </ErrorBoundary>
            }
        />

        <Route
            path="tenant-check/:tenantId/*"
            element={
                <ErrorBoundary page="tenant-check">
                    <TenantCheck />
                </ErrorBoundary>
            }
        />

        <Route
            path="actions/*"
            element={
                <ErrorBoundary page="actions">
                    <Actions />
                </ErrorBoundary>
            }
        />

        <Route
            path="processes/*"
            element={
                <ErrorBoundary page="processes">
                    <Processes />
                </ErrorBoundary>
            }
        />

        <Route
            path="datasets/*"
            element={
                <ErrorBoundary page="records">
                    <BusinessObjects />
                </ErrorBoundary>
            }
        />

        <Route
            path="account/*"
            element={
                <ErrorBoundary page="account">
                    <Account />
                </ErrorBoundary>
            }
        />

        <Route
            path="manage-workspace/*"
            element={
                <ErrorBoundary page="manage-workspace">
                    <ManageWorkspace />
                </ErrorBoundary>
            }
        />

        <Route path="*" element={<Navigate to="." />} />
    </Routes>
)

export { MainRoutes }
