import businessObjects from 'Assets/images/business-object-screenshot.png'
import { EmptyStateCard } from 'Components/EmptyStateCard'
import { FeatureName } from 'Features/FeatureName'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

export const NoBusinessObjectDefinitions: FC = () => {
    const navigate = useNavigate()

    return (
        <EmptyStateCard
            title={'Datasets'}
            paragraphs={[
                <>
                    <FeatureName>Datasets</FeatureName> represent what your
                    business has, the data you wish to capture.
                </>,
                'They define the entities that your business interacts with. Do you sell products and services? Do you acquire job opportunities? Do you have clients?',
                <>
                    <FeatureName>Datasets</FeatureName> are where most of your
                    data is held, and they log a history of how that data
                    changes over time.
                </>,
                'We allow you to determine what you want to capture and how to link your data together.',
            ]}
            button={{
                handler: () => {
                    navigate('/manage-workspace/datasets')
                },
                text: 'Set up a dataset',
            }}
            imageUrl={businessObjects}
        />
    )
}
