import { IconButton, IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { ProcessDisplayFragment } from 'Features/ProcessDisplay/__generated__/q'
import { useModalControls, useResponder } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { Form } from './Form'
import { useAddBusinessObjectsToProcess } from './useAddBusinessObjectsToProcess'

type Props = {
    processId: string
    operatesUpon: ProcessDisplayFragment['operatesUpon'][number]
    onBusinessObjectAdded: (_: {
        id: string
        tab:
            | 'details'
            | 'overview'
            | 'actions'
            | 'documents'
            | 'updates'
            | 'history'
    }) => void
}

export const ImportBusinessObjectsModal: FC<Props> = ({
    processId,
    operatesUpon,
    onBusinessObjectAdded,
}) => {
    const { open, setOpen, openModal, closeModal, buttonRef, returnFocus } =
        useModalControls()

    const { screenSize } = useResponder()

    const addBusinessObject = useAddBusinessObjectsToProcess(processId)

    const {
        open: toastOpen,
        setOpen: setToastOpen,
        triggerToast,
    } = useTriggerToast()

    return (
        <>
            {match(screenSize)
                .with('large', () => (
                    <IconTextButton
                        icon="Share"
                        text={`Add ${operatesUpon.name}`}
                        variant="primary"
                        size="small"
                        ref={buttonRef}
                        onClick={openModal}
                    />
                ))
                .otherwise(() => (
                    <IconButton
                        iconName="Share"
                        variant="primary"
                        ref={buttonRef}
                        onClick={openModal}
                    />
                ))}

            <ControlledModal
                renderContent={() => (
                    <Form
                        closeModal={closeModal}
                        processId={processId}
                        operatesUpon={operatesUpon}
                        addBusinessObject={addBusinessObject}
                        triggerToast={triggerToast}
                        onBusinessObjectAdded={onBusinessObjectAdded}
                    />
                )}
                title={`Add ${operatesUpon.name}`}
                description="Add records to this workflow."
                hideDescription
                open={open}
                onOpenChange={setOpen}
                returnFocus={returnFocus}
            />

            <SimpleToast
                open={toastOpen}
                onOpenChange={setToastOpen}
                description={
                    'Loading your new record. It will open when ready.'
                }
            />
        </>
    )
}
