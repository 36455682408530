import { hasCustomErrorExtenstions } from 'Adapters/Apollo'
import { BizObjFragmentDoc } from 'Fragments/__generated__/BusinessObject'
import { CreateBusinessObjectField } from '__generated__/'
import { compact, uniq } from 'lodash'
import { useCallback } from 'react'
import {
    CreateBusinessObjectMutation,
    useCreateBusinessObjectMutation,
} from './__generated__/mutation'
import {
    CreateFormState,
    fieldValueIsTelephoneFieldValue,
    useCreateFormState,
    useCreateFormStateDispatch,
} from './useCreateFormState'
import { fieldValueIsCurrencyFieldValue } from './useCreateFormState/types'

export type CreatedBusinessObject =
    CreateBusinessObjectMutation['createBusinessObject']

type Params = {
    businessObjectDefinitionId: string
    onCompleted?: (businessObject: CreatedBusinessObject) => void
}

export const useCreateBusinessObject = ({
    businessObjectDefinitionId,
    onCompleted,
}: Params) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const [create, { loading }] = useCreateBusinessObjectMutation({
        onCompleted(mutation) {
            onCompleted?.(mutation.createBusinessObject)
        },
        onError(e) {
            e.graphQLErrors.forEach(error => {
                if (!hasCustomErrorExtenstions(error.extensions)) return

                Object.entries(error.extensions.errors.errors).forEach(
                    ([key, value]) => {
                        dispatch({
                            type: 'setErrors',
                            payload: { id: key, errors: uniq(value) },
                        })
                    }
                )
            })
        },
        onQueryUpdated: q => q.refetch(),
        update: (cache, result) => {
            cache.modify({
                fields: {
                    businessObjects(existing = []) {
                        const newBO = cache.writeFragment({
                            data: result.data?.createBusinessObject,
                            fragment: BizObjFragmentDoc,
                            fragmentName: 'BizObj',
                        })
                        return [...existing, newBO]
                    },
                },
            })
        },
        refetchQueries: ['OnboardingProgress'],
    })

    const handleSubmit = useCallback(() => {
        const fieldsToSubmit = mapStateToFieldsToSubmit(state)

        create({
            variables: {
                input: {
                    businessObjectDefinitionId,
                    fields: fieldsToSubmit,
                },
            },
        })
    }, [create, businessObjectDefinitionId, state])

    return { handleSubmit, loading }
}

const mapStateToFieldsToSubmit = (
    state: CreateFormState
): CreateBusinessObjectField[] => {
    return compact(
        Object.entries(state).map(([key, value]) => {
            if (fieldValueIsTelephoneFieldValue(value.value)) {
                if (!value.value.number && !value.value.countryCode)
                    return undefined
            }

            if (fieldValueIsCurrencyFieldValue(value.value)) {
                if (
                    !value.value?.currencyCode ||
                    value.value.amount === undefined
                )
                    return undefined
            }

            if (value.value === '' || value.value === undefined)
                return undefined

            return {
                [value.type]: {
                    fieldDefinitionId: key,
                    value: value.value,
                },
            }
        })
    )
}
