import { URLSearchParamsInit } from 'react-router-dom'

export const showByQueryKey = 'showBy[]'

export const defaultShowByParamsFactory = (
    currentUserId: string
): URLSearchParamsInit => ({
    [showByQueryKey]: ['IncompleteAction'],
    assignedTo: currentUserId,
})
