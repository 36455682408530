import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { TextInput } from 'Components/Input'
import { fromEvent } from 'Utils'
import { always, pipe } from 'lodash/fp'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { Tooltip } from '../../../../../../../Components/Tooltip'

type Props<T = string> = {
    native: (props: {
        value: T
        onChange: (value: T) => void
    }) => React.ReactNode
    value: T
    onChange: (value: T) => void
}

export const TemplateableField = <T,>({
    native,
    value,
    onChange,
}: Props<T>) => {
    const [mode, setMode] = useState<'native' | 'template'>('native')

    const toggleMode = () =>
        setMode(mode =>
            match(mode)
                .with('native', always('template' as const))
                .with('template', always('native' as const))
                .exhaustive()
        )

    return (
        <SwitchableField>
            {match(mode)
                .with('native', always(native({ value, onChange })))
                .with('template', () => (
                    <TextInput
                        type="text"
                        value={String(value) ?? ''}
                        onChange={pipe(fromEvent, onChange)}
                    />
                ))
                .exhaustive()}
            <Tooltip content="Toggle between simple and templated inputs">
                <IconButton
                    type="button"
                    iconName="Converted"
                    onClick={toggleMode}
                />
            </Tooltip>
        </SwitchableField>
    )
}

const SwitchableField = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
`
