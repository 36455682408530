import { encodeURIComponentObj } from 'Utils'
import { ViewOfType } from '__generated__'
import { Buffer } from 'buffer'
import { compact } from 'lodash'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SavedConfig } from '../../types'
import {
    ProcessSingle_ViewsQuery,
    useProcessSingle_ViewsQuery,
} from './__generated__/q'

const parseViewsFromData = (data: ProcessSingle_ViewsQuery | undefined) =>
    compact(
        (data?.views || []).map(({ id, name, configuration }) => {
            const encodedConfig = encodeURIComponentObj(
                JSON.parse(Buffer.from(configuration, 'base64').toString())
            )
            return (
                encodedConfig && {
                    id,
                    label: name,
                    encodedConfig,
                }
            )
        })
    )

type UseSavedViews = (processId: string) => {
    savedViews: SavedConfig[]
    selectSavedView: (savedConfig: SavedConfig) => void
}

export const useSavedViews: UseSavedViews = processId => {
    const [, setUrlSearchParams] = useSearchParams()

    const { data } = useProcessSingle_ViewsQuery({
        variables: {
            input: {
                type: ViewOfType.Process,
                processId,
            },
        },
    })

    const selectSavedView = useCallback(
        (savedConfig: SavedConfig) => {
            setUrlSearchParams(
                () => ({
                    config: savedConfig.encodedConfig,
                }),
                { replace: true }
            )
        },
        [setUrlSearchParams]
    )

    return {
        selectSavedView,
        savedViews: parseViewsFromData(data),
    }
}
