import { styled } from 'Adapters/Freestyled'
import { CenteredSpinner } from 'Components/Spinner'
import { ActionsTable } from 'Features/ActionsTable'
import { FC } from 'react'
import { match } from 'ts-pattern'
import { useActionsView } from '../../../../../Features/ActionView/useActionsView'
import { useFilterActions } from '../Hooks'
import { ActionsHeader } from './ActionsHeader'
import { CardView } from './CardView'
import { NoFilteredResults } from './NoFilteredResults'
import { ActionsTimeline } from './Timeline'
import { ViewBar } from './ViewBar'

type Props = {
    currentUserId: string
}

export const Large: FC<Props> = ({ currentUserId }) => {
    const actionsResult = useFilterActions(currentUserId)
    const { display } = useActionsView()

    return (
        <Styled>
            <ActionsHeader />

            <ViewBar
                extension={actionsResult.extension}
                currentUserId={currentUserId}
                allAssignablePrincipals={actionsResult.allAssignablePrincipals}
            />

            {match({ ...actionsResult, display })
                .with({ hasInitiallyLoaded: false }, () => <CenteredSpinner />)
                .with({ actions: [] }, () => <NoFilteredResults />)
                .with({ display: 'table' }, ({ actions }) => (
                    <ActionsTable
                        actions={actions}
                        className="table-container"
                        tableClassName="table"
                    />
                ))
                .with({ display: 'timeline' }, ({ actions }) => (
                    <ActionsTimeline
                        actions={actions}
                        mostRecentAction={actionsResult.mostRecentAction}
                    />
                ))
                .with({ display: 'card' }, ({ actions }) => (
                    <CardView
                        actions={actions}
                        extension={actionsResult.extension}
                        mostRecentAction={actionsResult.mostRecentAction}
                    />
                ))
                .exhaustive()}
        </Styled>
    )
}

const Styled = styled.div`
    display: grid;
    grid-template: auto auto minmax(0, 1fr) / minmax(0, 1fr);
    background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.ui['01'].normal},
        ${({ theme }) => theme.palette.ui['02'].normal}
    );

    .table-container {
        margin-top: 2rem;
        height: calc(100% - 2rem);
    }

    .table {
        padding: 0 3rem;
    }
`
