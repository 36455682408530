import { dayjs } from 'Adapters/DayJS'
import { match, P } from 'ts-pattern'
import { oneOfPair } from 'Utils'
import { CreateBusinessObjectDefinitionDateFieldPrecision } from '__generated__'
import { FieldProps } from '../types'

type PrecisionOption = {
    value: CreateBusinessObjectDefinitionDateFieldPrecision
    text: string
}

export const precisionOptions = [
    /* Minute / Hour precision are not supported yet by the date picker */

    {
        value: CreateBusinessObjectDefinitionDateFieldPrecision.Day,
        text: 'Day',
    },

    {
        value: CreateBusinessObjectDefinitionDateFieldPrecision.Month,
        text: 'Month',
    },
    {
        value: CreateBusinessObjectDefinitionDateFieldPrecision.Year,
        text: 'Year',
    },
] satisfies PrecisionOption[]

type DateField = FieldProps<'date'>['field']

export const validateDateDefault = (field: DateField): string[] => {
    if (!field.defaultValue) return []

    return field.constraints.reduce<string[]>((errors, constraint) => {
        return match(constraint)
            .with({ required: P.not(P.nullish) }, () => errors)
            .with({ dateRange: P.not(P.nullish) }, () => {
                const { defaultValue } = field

                const toCheck = !defaultValue ? '' : oneOfPair(defaultValue)[1]

                return [
                    ...errors,
                    ...validateAgainstDateRangeConstraint(
                        toCheck,
                        constraint.dateRange,
                        field.precision ??
                            CreateBusinessObjectDefinitionDateFieldPrecision.Day
                    ),
                ]
            })
            .otherwise(() => [])
    }, [])
}

export const validateAgainstDateRangeConstraint = (
    defaultValue: string,
    dateRangeConstraint: DateField['constraints'][number]['dateRange'],
    precision: CreateBusinessObjectDefinitionDateFieldPrecision
): string[] => {
    if (!dateRangeConstraint) return []

    const start = dateRangeConstraint.start
    const end = dateRangeConstraint.end

    if (start && dayjs(defaultValue).isBefore(start, precision))
        return [`The default value is before the start ${precision}`]

    if (end && dayjs(defaultValue).isAfter(end, precision))
        return [`The default value is after the end ${precision}`]

    return []
}
