import { Header, Item, Root, Trigger } from '@radix-ui/react-accordion'
import { styled } from 'Adapters/Freestyled'
import { FieldRequiredTag } from 'Components/FieldRequiredTag'
import { FieldTypeTag } from 'Components/FieldTypeTag'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { Definition, isFieldRequired } from 'Utils/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { FieldContent } from './FieldContent'

type Props = {
    definition: Definition
}

const DefinitionFields: FC<Props> = ({ definition }) => {
    const [collapsed, setCollapsed] = useState(true)
    return (
        <Styled
            type="single"
            collapsible
            onValueChange={v => setCollapsed(!v)}
            data-collapsed={collapsed}
        >
            {definition.fields.map(field => (
                <Item key={field.id} value={field.id} asChild>
                    <StyledItem>
                        <Header asChild>
                            <header>
                                <StyledTrigger>
                                    <div>
                                        <Text as="h3" variant="bold-4">
                                            {field.name}
                                        </Text>

                                        <FieldTypeTag field={field} />
                                        <FieldRequiredTag
                                            isRequired={isFieldRequired(field)}
                                        />
                                    </div>
                                    <Icon name="AngleDown" />
                                </StyledTrigger>
                            </header>
                        </Header>
                        <FieldContent field={field} />
                    </StyledItem>
                </Item>
            ))}
        </Styled>
    )
}

const Styled = styled(Root)`
    position: relative;
    padding-bottom: 2rem;
    filter: drop-shadow(
        0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
    );
`

const StyledItem = styled.section`
    border-top: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    position: relative;
    transition: opacity ${({ theme }) => theme.animation.mid} ease;

    &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    [data-collapsed='false'] &[data-state='closed'] {
        opacity: 0.6;
    }
`

const StyledTrigger = styled(Trigger)`
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['01'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    > div {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .icon {
        transition: transform ${({ theme }) => theme.animation.mid} ease;
        transform: rotateZ(0deg);
    }

    &[data-state='open'] .icon {
        transform: rotateZ(180deg);
    }
`

export { DefinitionFields }
