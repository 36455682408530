import { useOrganisationUsers } from 'Hooks'
import { FC, useMemo } from 'react'
import { Select } from '../Select'

type Props = {
    index: number
    userId: string | undefined
    onUserSelected: (userId: string) => void
}

export const UserSelector: FC<Props> = ({ index, userId, onUserSelected }) => {
    const { users } = useOrganisationUsers()

    const options = useMemo(() => {
        return users.map(user => ({
            text: user.isCurrentUser ? 'Me' : user.name,
            value: user.id,
            disabled: false,
        }))
    }, [users])

    return (
        <Select
            id={`select-value-${index}`}
            value={userId}
            onValueChange={onUserSelected}
            name="operator"
            options={options}
        />
    )
}
