import { styled } from 'Adapters/Freestyled'
import { TextButton } from 'Components/Button'
import { Dayjs } from 'dayjs'
import { FC } from 'react'
import { strings } from './strings'

type Props = {
    onConfirm: (props: {
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        date: Date
    }) => void
    selection: Dayjs | undefined
}

export const DatePickerFooter: FC<Props> = ({ onConfirm, selection }) => (
    <StyledDatePickerFooter>
        <TextButton
            text={
                selection
                    ? `${strings.confirmButtonText}: ${selection.format(
                          'DD MMM YYYY'
                      )}`
                    : strings.confirmButtonText
            }
            variant="primary"
            disabled={!selection}
            onClick={e => {
                if (selection)
                    onConfirm({
                        e,
                        date: selection.utcOffset(0, true).toDate(),
                    })
            }}
            size="small"
        />
    </StyledDatePickerFooter>
)

const StyledDatePickerFooter = styled.footer`
    padding-top: 1rem;

    button {
        width: 100%;
    }
`
