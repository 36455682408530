import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { Text } from 'Components/Text'
import { ContactUsToast, useTriggerToast } from 'Components/Toast'
import { isDefinitionRestricted } from 'Utils/BusinessObjectDefinition'
import { FC, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModelManager_SingleBusinessObjectDefinitionQuery } from '../__generated__/q'
import { useModelManager_DeleteBusinessObjectDefinitionMutation } from './__generated__/q'

type Props = {
    definition: NonNullable<
        ModelManager_SingleBusinessObjectDefinitionQuery['businessObjectDefinition']
    >
}

const Delete: FC<Props> = ({ definition }) => {
    const trigger = useRef<HTMLButtonElement>(null)
    const navigate = useNavigate()
    const [run, { loading }] =
        useModelManager_DeleteBusinessObjectDefinitionMutation()
    const [open, setOpen] = useState(false)
    const {
        open: errorOpen,
        setOpen: setErrorOpen,
        triggerToast,
    } = useTriggerToast()

    const handleDelete = useCallback(() => {
        run({
            variables: {
                input: { id: definition.id },
            },
            onCompleted: () => {
                navigate('/manage-workspace/datasets')
            },
            update: cache => {
                cache.evict({
                    id: cache.identify({
                        __typename: 'BusinessObjectDefinition',
                        id: definition.id,
                    }),
                })
            },
            onError: () => {
                triggerToast()
            },
        })
    }, [run, definition, navigate, triggerToast])

    return (
        <>
            <IconTextButton
                icon="Delete"
                size="xSmall"
                text="Delete dataset"
                variant="secondary"
                disabled={loading || isDefinitionRestricted(definition)}
                onClick={() => {
                    setOpen(true)
                }}
                ref={trigger}
            />
            <ControlledModal
                title="Delete dataset"
                description="Delete dataset."
                hideDescription
                open={open}
                onOpenChange={setOpen}
                returnFocus={() => trigger.current?.focus()}
                renderContent={() => (
                    <Styled>
                        <Text as="p" variant="regular-5">
                            Your dataset will be permanently removed from your
                            workspace. This action cannot be undone.
                        </Text>
                        <footer>
                            <IconTextButton
                                icon="Close"
                                size="xSmall"
                                text="Cancel"
                                variant="secondary"
                                onClick={() => {}}
                            />
                            <IconTextButton
                                icon="Delete"
                                size="xSmall"
                                text="Confirm"
                                variant="primary"
                                disabled={loading}
                                onClick={handleDelete}
                            />
                        </footer>
                    </Styled>
                )}
            />
            <ContactUsToast
                open={errorOpen}
                onOpenChange={setErrorOpen}
                description="We were unable to delete your dataset."
            />
        </>
    )
}

const Styled = styled.div`
    width: 27rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    footer {
        display: flex;
        gap: 1rem;
        button {
            flex: 1;
        }
    }
`

export { Delete }
