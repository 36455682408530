import { IconTextButton } from 'Components/Button'
import { FC, useMemo } from 'react'
import { ProcessDisplayFragment } from '../../__generated__/q'
import { DispatchAction, ViewConfig } from '../../types'

type Props = {
    process: ProcessDisplayFragment
    config: ViewConfig
    dispatch: DispatchAction
}

const CompletedToggle: FC<Props> = ({ config, dispatch, process }) => {
    const noOfCompleted = useMemo(() => {
        return process.phases
            .flatMap(phase => phase.businessObjects.nodes)
            .filter(bo => bo.onProcess?.isCompleted).length
    }, [process])

    return (
        <IconTextButton
            icon={config.showCompleted ? 'CheckboxTicked' : 'CheckboxUnticked'}
            text={`Show completed (${noOfCompleted})`}
            onClick={() =>
                dispatch({
                    type: 'ToggleShowCompleted',
                    payload: undefined,
                })
            }
            size="xSmall"
        />
    )
}

export { CompletedToggle }
