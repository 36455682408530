import { breakpoints, flex, styled } from 'Adapters/Freestyled'
import AI from 'Assets/ai.svg?react'
import Dataset from 'Assets/cube.svg?react'
import Template from 'Assets/template.svg?react'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { DispatchAction, Step } from '../useCreator'

type Props = {
    dispatch: DispatchAction
}

export const GettingStarted: FC<Props> = ({ dispatch }) => (
    <Styled>
        <div className="wrapper">
            <div className="buttons">
                <button
                    onClick={() => {
                        dispatch({
                            type: 'choosePath',
                            payload: { step: Step.Template },
                        })
                    }}
                >
                    <Template />
                    <Text as="span" variant="regular-3">
                        Start with a template
                    </Text>
                </button>

                <button
                    onClick={() => {
                        dispatch({
                            type: 'choosePath',
                            payload: { step: Step.Existing },
                        })
                    }}
                >
                    <Dataset />
                    <Text as="span" variant="regular-3">
                        Copy from an existing dataset
                    </Text>
                </button>

                <button
                    onClick={() => {
                        dispatch({
                            type: 'choosePath',
                            payload: { step: Step.AI },
                        })
                    }}
                >
                    <AI />
                    <Text as="span" variant="regular-3">
                        Generate with AI
                    </Text>
                </button>
            </div>

            <TextButton
                onClick={() => {
                    dispatch({
                        type: 'startFromScratch',
                        payload: undefined,
                    })
                }}
                text="Start from scratch"
                size="small"
                variant="ghost"
            />
        </div>
    </Styled>
)

const Styled = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;

    .wrapper {
        ${flex('column', 'center', 'center')};
        gap: 2rem;
        height: 100%;
        width: 100%;
        padding: 2rem 0;
    }

    .buttons {
        ${flex('column', 'flex-start', 'center')};
        gap: 2rem;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 2rem;

        button {
            ${flex('column', 'space-around', 'center')};
            width: 100%;
            padding: 2rem;
            border-radius: ${({ theme }) => theme.borderRadius.small};
            color: ${({ theme }) => theme.palette.text['02'].normal};
            transition: ${({ theme }) =>
                    theme.animation.buttonTransitionDuration}
                ease;
            background-color: ${({ theme }) => theme.palette.ui['02'].normal};
            border: 1px solid transparent;

            @media (hover: hover) {
                &:hover {
                    color: ${({ theme }) => theme.palette.text['02'].hover};
                    background-color: ${({ theme }) =>
                        theme.palette.ui['02'].hover};
                }
            }

            &:active {
                color: ${({ theme }) => theme.palette.text['02'].active};
                background-color: ${({ theme }) =>
                    theme.palette.ui['02'].active};
            }

            &:focus-visible {
                box-shadow: 0 0 0 0.125rem
                    ${({ theme }) => theme.palette.brand['01'].normal};
            }

            > svg {
                height: 5rem;
                width: 5rem;
                padding-bottom: 1rem;

                line,
                circle {
                    stroke: ${({ theme }) => theme.palette.text['02'].normal};
                }

                path {
                    fill: ${({ theme }) => theme.palette.text['02'].normal};
                }
            }

            .text {
                ${flex('column', 'flex-start', 'center')};
            }
        }
    }

    @media screen and (min-width: ${breakpoints.medium}px) {
        .buttons {
            ${flex('row', 'center', 'center')};

            button {
                width: 25rem;
                height: 18.75rem;

                > svg {
                    height: 10rem;
                    width: 10rem;
                }
            }
        }
    }
`
