import { flex, styled } from 'Adapters/Freestyled'

export const StyledButtonContainer = styled.div`
    position: relative;
    display: block;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    button {
        ${flex('row', 'center', 'center')};
        position: relative;
        transition: background-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
            border-color
                ${({ theme }) => theme.animation.buttonTransitionDuration} ease,
            color ${({ theme }) => theme.animation.buttonTransitionDuration}
                ease;
        border: 1px solid transparent;
        font-size: ${({ theme }) => theme.typography.scale['02']};

        @media (hover: hover) {
            &:hover {
                border-color: ${({ theme }) => theme.palette.ui['07'].normal};
            }
        }

        &:active:enabled {
            background-color: ${({ theme }) => theme.palette.ui['07'].normal};
            color: ${({ theme }) => theme.palette.text['04'].normal};
        }

        &:focus-visible {
            border-color: ${({ theme }) => theme.palette.ui['07'].normal};
            box-shadow: none; //remove default focus styles
        }

        &:disabled {
            opacity: 0.3;
        }

        &[data-selected='true'] {
            background-color: ${({ theme }) => theme.palette.ui['07'].normal};
            color: ${({ theme }) => theme.palette.text['04'].normal};

            &:focus-visible {
                border-width: 2px;
                border-color: ${({ theme }) =>
                    theme.palette.brand['01'].normal};
            }
        }

        &[data-is-today='true'] {
            background-color: ${({ theme }) => theme.palette.ui['03'].normal};

            &[data-selected='true'] {
                background-color: ${({ theme }) =>
                    theme.palette.ui['07'].hover};
            }
        }
    }
`
