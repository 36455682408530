import { css, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'

export const colorStyles = css`
    .icon svg {
        path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }
    }
`

export const StyledTutorialTrigger = styled(IconButton)`
    ${colorStyles};
    padding: 0.25rem;

    .icon svg {
        min-height: 1rem;
        min-width: 1rem;
        max-height: 1rem;
        max-width: 1rem;
    }
`
