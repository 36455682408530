import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC, useState } from 'react'
import { match } from 'ts-pattern'
import { NameCriteriaTooltip } from '../../../Components/NameCriteriaTooltip'
import { has } from '../../../Utils'
import { SaveProcess } from '../SaveProcess'
import { useProcessCreator_BusinessObjectsQuery } from '../__generated__/query'
import { useProcessCreator } from '../useProcessCreator'
import { BasicInformation } from './BasicInformation'
import { InitFields } from './InitFields'
import { Phases } from './Phases'

type Props = {
    mode: 'create' | 'edit'
}

export const PhasesStepMobile: FC<Props> = ({ mode }) => {
    const {
        state: { input, ...state },
        dispatch,
        isProcessFirst,
    } = useProcessCreator()

    const { data } = useProcessCreator_BusinessObjectsQuery({
        skip: isProcessFirst,
    })

    const [subStep, setSubStep] = useState<'basicInfo' | 'phases'>('basicInfo')

    return (
        <Styled>
            <header>
                <Text as="h3" variant="bold-2">
                    Workflow phases
                </Text>

                <NameCriteriaTooltip />
            </header>

            <div>
                {subStep === 'basicInfo' ? (
                    <>
                        <BasicInformation data={data} />

                        <InitFields
                            fields={state.fields.filter(
                                f =>
                                    has(f.requiredBy, 'init') &&
                                    f.requiredBy.init === true
                            )}
                        />
                    </>
                ) : (
                    <Phases />
                )}
            </div>

            <footer>
                <IconTextButton
                    icon="AngleLeft"
                    text={match(subStep)
                        .with('basicInfo', () => 'Start again')
                        .with('phases', () => 'Basic info')
                        .exhaustive()}
                    variant="secondary"
                    onClick={() => {
                        match(subStep)
                            .with('basicInfo', () => {
                                dispatch({
                                    type: 'stepChanged',
                                    payload: { step: 'start' },
                                })
                            })
                            .with('phases', () => {
                                setSubStep('basicInfo')
                            })
                            .exhaustive()
                    }}
                    size="xSmall"
                />

                {subStep === 'basicInfo' ? (
                    <IconTextButton
                        icon={'AngleRight'}
                        iconRight
                        text={'Phases'}
                        variant="primary"
                        onClick={() => {
                            setSubStep('phases')
                        }}
                        size={'xSmall'}
                    />
                ) : (
                    <SaveProcess mode={mode} />
                )}
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    width: 100%;
    height: calc(100vh - 5rem);
    gap: 1.5rem;
    overflow: hidden;
    display: grid;
    grid-template: auto minmax(0, 1fr) auto / minmax(0, 1fr);

    > header {
        grid-row: 1 / 2;
        ${flex('row', 'flex-start', 'center')};
        gap: 1rem;

        > div {
            ${flex('row', 'flex-start', 'center')};
            gap: 1rem;
        }
    }

    > div {
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    > footer {
        grid-row: 3 / 4;
        display: flex;
        justify-content: space-between;
    }
`
