import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Grouping } from './types'

type Props = {
    fields: Grouping['fields']
    selection: string
    onSelect: (fieldId: string) => void
}

const FieldSelection: FC<Props> = ({ fields, selection, onSelect }) => (
    <Styled>
        {fields.map(({ id, label }) => (
            <li key={id}>
                <StyledSelection
                    onClick={() => selection !== id && onSelect(id)}
                    disabled={selection === id}
                >
                    <Icon
                        name={
                            selection === id
                                ? 'CheckboxTicked'
                                : 'CheckboxUnticked'
                        }
                    />
                    <Text as="span" variant="regular-5">
                        {label}
                    </Text>
                </StyledSelection>
            </li>
        ))}
    </Styled>
)

const Styled = styled.ul`
    > li {
        display: flex;
        padding: 0.25rem 0;
        gap: 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const StyledSelection = styled.button`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.375rem;
    gap: 0.5rem;
    justify-content: flex-start;

    > p {
        flex: 1;
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    &:disabled {
        pointer-events: none;
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }
`

export { FieldSelection }
