import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { UncontrolledModal } from 'Components/Modal'
import { Text } from 'Components/Text'

export const NameCriteriaTooltip = () => {
    return (
        <UncontrolledModal
            renderContent={() => (
                <StyledContent>
                    <Text as="p" variant="regular-4">
                        Here are some tips on how to name your criteria. You
                        should name your criteria as commands, not questions.
                        They should be actions that can be completed.
                    </Text>

                    <Text as="h4" variant="bold-4">
                        Bad examples :
                    </Text>
                    <ul>
                        <Text as="li" variant="regular-4">
                            ❌ Did you send a follow up email?
                        </Text>
                        <Text as="li" variant="regular-4">
                            ❌ Deck created?
                        </Text>
                        <Text as="li" variant="regular-4">
                            ❌ Client spoken to?
                        </Text>
                    </ul>

                    <Text as="h4" variant="bold-4">
                        Good examples :
                    </Text>
                    <ul>
                        <Text as="li" variant="regular-4">
                            ✅ Send a follow up email.
                        </Text>
                        <Text as="li" variant="regular-4">
                            ✅ Create a pitch deck.
                        </Text>
                        <Text as="li" variant="regular-4">
                            ✅ Contact the client.
                        </Text>
                    </ul>
                </StyledContent>
            )}
            title={'Criteria naming'}
            hideDescription={true}
            description=""
            renderOpenModalButton={() => (
                <IconTextButton
                    icon={'Info'}
                    text={'Criteria naming'}
                    size="xSmall"
                />
            )}
        />
    )
}

const StyledContent = styled.div`
    max-width: 30rem;

    > p {
        padding-bottom: 1rem;
    }

    > h4 {
        padding-bottom: 0.25rem;
    }

    > ul:first-of-type {
        padding-bottom: 1rem;
    }

    ul li {
        padding-bottom: 0.25rem;
    }
`
