import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspaceMembersFragment = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, email: string }>, currentUser: { __typename?: 'User', id: string, name: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, openInvites: Array<{ __typename?: 'Invite', id: string, inviteeEmail: string }> } } | null } };

export type SendInviteMutationVariables = Types.Exact<{
  input: Types.SendInviteInput;
}>;


export type SendInviteMutation = { __typename?: 'Mutation', sendInvite: { __typename?: 'Invite', id: string } };

export const WorkspaceMembersFragmentDoc = gql`
    fragment WorkspaceMembers on Query {
  users {
    id
    name
    email
  }
  currentUser {
    id
    name
    activeTenant {
      tenant {
        id
        openInvites {
          id
          inviteeEmail
        }
      }
    }
  }
}
    `;
export const SendInviteDocument = gql`
    mutation SendInvite($input: SendInviteInput!) {
  sendInvite(input: $input) {
    id
  }
}
    `;
export type SendInviteMutationFn = Apollo.MutationFunction<SendInviteMutation, SendInviteMutationVariables>;

/**
 * __useSendInviteMutation__
 *
 * To run a mutation, you first call `useSendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteMutation, { data, loading, error }] = useSendInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteMutation, SendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteMutation, SendInviteMutationVariables>(SendInviteDocument, options);
      }
export type SendInviteMutationHookResult = ReturnType<typeof useSendInviteMutation>;
export type SendInviteMutationResult = Apollo.MutationResult<SendInviteMutation>;
export type SendInviteMutationOptions = Apollo.BaseMutationOptions<SendInviteMutation, SendInviteMutationVariables>;