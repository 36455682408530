import { ControlledModal } from 'Components/Modal'
import { FC, useCallback } from 'react'
import { CreateBusinessObjectForm } from './CreateBusinessObjectForm'
import { CreatedBusinessObject } from './Hooks/useCreateBusinessObject'
import { CreateFormStateProvider } from './Hooks/useCreateFormState'
import { BODFields, PrefilledBusinessObject } from './types'

type Props = {
    businessObjectDefinitionName: string
    businessObjectDefinitionId: string
    fields: BODFields
    open: boolean
    closeModal: () => void
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
    onBusinessObjectCreated?: (businessObject: CreatedBusinessObject) => void
    prefilledBusinessObject?: PrefilledBusinessObject
}

export const CreateBusinessObjectModal: FC<Props> = ({
    businessObjectDefinitionName,
    businessObjectDefinitionId,
    fields,
    onOpenChange,
    open,
    returnFocus,
    closeModal,
    onBusinessObjectCreated,
    prefilledBusinessObject,
}) => {
    const handleBusinessObjectCreated = useCallback(
        (businessObject: CreatedBusinessObject) => {
            closeModal()
            onBusinessObjectCreated?.(businessObject)
        },
        [closeModal, onBusinessObjectCreated]
    )
    return (
        <ControlledModal
            title={`Create ${businessObjectDefinitionName}`}
            description={`Use the form to create a record in the ${businessObjectDefinitionName} dataset`}
            hideDescription
            open={open}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
            renderContent={() => {
                return (
                    <CreateFormStateProvider fields={fields}>
                        <CreateBusinessObjectForm
                            fields={fields}
                            businessObjectDefinitionId={
                                businessObjectDefinitionId
                            }
                            onBusinessObjectCreated={
                                handleBusinessObjectCreated
                            }
                            prefilledBusinessObject={prefilledBusinessObject}
                        />
                    </CreateFormStateProvider>
                )
            }}
        />
    )
}
