import { useMemo } from 'react'
import { CurrencyFieldConstraints } from '__generated__'
import { CurrencyConstraintsMap, defaultConstraints } from './types'

type Params = {
    currencyConstraints: CurrencyFieldConstraints[]
}

export const useCurrencyConstraints = ({ currencyConstraints }: Params) => {
    const constraints = useMemo(() => {
        return currencyConstraints.reduce<CurrencyConstraintsMap>(
            (out, constraint) => {
                if (constraint.__typename === 'RequiredConstraint') {
                    return { ...out, required: true }
                }

                return out
            },
            defaultConstraints
        )
    }, [currencyConstraints])

    const constraintsTooltip = useMemo(() => {
        if (constraints.required) return 'Required'
        return ''
    }, [constraints])

    return { constraints, constraintsTooltip }
}
