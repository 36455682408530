import { css } from 'Adapters/Freestyled'
import { keyframes } from 'styled-components'

const DURATION = 300
const TIMING = 'cubic-bezier(0.16, 1, 0.3, 1)'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const slideUp = keyframes`
    0% {
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
    }
`

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

const slideDown = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-1rem);
    }
`

export const applyAnimation = () => css`
    animation-duration: ${DURATION}ms;
    animation-timing-function: ${TIMING};
    will-change: transform, opacity;

    &[data-state='open'] {
        animation-name: ${fadeIn}, ${slideUp};
    }

    &[data-state='closed'] {
        animation-name: ${fadeOut}, ${slideDown};
    }
`
