import {
    AddField,
    CloseAddField,
    CloseFieldEditor,
    Mode,
    MoveField,
    OpenAddField,
    OpenFieldEditor,
    RemoveField,
    SetDescription,
    SetField,
    SetLabel,
    SetName,
    ToggleCustomLabel,
    ToggleEnableDocuments,
    ToggleEnableUpdates,
} from 'Features/BusinessObjectDefinitionEditor'
import {
    ValidationStatus,
    createDefaultLabel,
    createNewField,
    createPendingFieldValidation,
    validateField,
    validateLabel,
    validateName,
} from 'Utils/BusinessObjectDefinition'
import { Close, Data, Handle, Open, SetStatus, State } from './types'
import { makeInit } from './utils'

// Prevent a handler from modifying state if the state is restricted
const guardMode = (guarded: Mode[]) => (prev: State) => (next: State) =>
    guarded.includes(prev.mode) ? prev : next

export const handleOpen =
    (data: Data): Handle<Open> =>
    () =>
    () => ({
        ...makeInit(data),
        open: true,
    })

export const handleClose =
    (data: Data): Handle<Close> =>
    () =>
    () =>
        makeInit(data)

export const handleToggleCustomLabel: Handle<ToggleCustomLabel> =
    prev => () => {
        const defaultLabel = createDefaultLabel(prev.definition)
        return {
            ...prev,
            definition: {
                ...prev.definition,
                label: prev.customLabel ? defaultLabel : '',
            },
            customLabel: !prev.customLabel,
            fieldEditorOpen: false,
            addFieldOpen: false,
            validation: {
                ...prev.validation,
                label: prev.customLabel
                    ? {
                          status: ValidationStatus.Pending,
                          message: '',
                      }
                    : validateLabel(defaultLabel),
            },
        }
    }

export const handleSetName: Handle<SetName> =
    prev =>
    ({ payload: { value } }) => ({
        ...prev,
        definition: {
            ...prev.definition,
            name: value,
        },
        fieldEditorOpen: false,
        addFieldOpen: false,
        validation: {
            ...prev.validation,
            name: validateName(value),
        },
    })

export const handleSetDescription: Handle<SetDescription> =
    prev =>
    ({ payload: { value } }) => ({
        ...prev,
        definition: {
            ...prev.definition,
            description: value || undefined,
        },
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleSetLabel: Handle<SetLabel> =
    prev =>
    ({ payload: { value } }) => ({
        ...prev,
        definition: {
            ...prev.definition,
            label: value,
        },
        customLabel: true,
        fieldEditorOpen: false,
        addFieldOpen: false,
        validation: {
            ...prev.validation,
            label: validateLabel(value),
        },
    })

export const handleOpenFieldEditor: Handle<OpenFieldEditor> =
    prev =>
    ({ payload: { index } }) => ({
        ...prev,
        fieldEditorOpen: index,
    })

export const handleCloseFieldEditor: Handle<CloseFieldEditor> = prev => () => ({
    ...prev,
    fieldEditorOpen: false,
    addFieldOpen: false,
})

export const handleOpenAddField: Handle<OpenAddField> = prev => () => ({
    ...prev,
    fieldEditorOpen: false,
    addFieldOpen: true,
})

export const handleCloseAddField: Handle<CloseAddField> = prev => () => ({
    ...prev,
    fieldEditorOpen: false,
    addFieldOpen: false,
})

export const handleAddField: Handle<AddField> =
    prev =>
    ({ payload: { fieldType } }) => ({
        ...prev,
        fieldEditorOpen: prev.definition.fields.length,
        addFieldOpen: false,
        definition: {
            ...prev.definition,
            fields: [...prev.definition.fields, createNewField(fieldType)],
        },
        validation: {
            ...prev.validation,
            fields: [
                ...prev.validation.fields,
                createPendingFieldValidation(fieldType),
            ],
        },
    })

export const handleRemoveField: Handle<RemoveField> =
    prev =>
    ({ payload: { index } }) => {
        const nextInput = {
            ...prev.definition,
            fields: prev.definition.fields.filter((_, i) => i !== index),
        }
        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        const nextFieldsValidation = prev.validation.fields.filter(
            (_, i) => i !== index
        )
        return guardMode(['restrictedEdit'])(prev)({
            ...prev,
            fieldEditorOpen: false,
            addFieldOpen: false,
            definition: {
                ...nextInput,
                label,
            },
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: nextFieldsValidation,
            },
        })
    }

export const handleMoveField: Handle<MoveField> =
    prev =>
    ({ payload: { index, targetIndex } }) => {
        const fields = prev.definition.fields.filter((_, i) => i !== index)
        fields.splice(targetIndex, 0, prev.definition.fields[index])
        const validationFields = prev.validation.fields.filter(
            (_, i) => i !== index
        )
        validationFields.splice(targetIndex, 0, prev.validation.fields[index])
        const nextInput = {
            ...prev.definition,
            fields,
        }
        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        return guardMode(['edit', 'restrictedEdit'])(prev)({
            ...prev,
            fieldEditorOpen: false,
            addFieldOpen: false,
            definition: {
                ...nextInput,
                label,
            },
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: validationFields,
            },
        })
    }

export const handleSetField: Handle<SetField> =
    prev =>
    ({ payload: { index, value } }) => {
        const nextInput = {
            ...prev.definition,
            fields: prev.definition.fields.map((f, i) =>
                i === index ? value : f
            ),
        }
        const validationFields = prev.validation.fields.map((v, i) =>
            i === index ? validateField(value) : v
        )

        const label = prev.customLabel
            ? prev.definition.label
            : createDefaultLabel(nextInput)
        return {
            ...prev,
            definition: {
                ...nextInput,
                label,
            },
            fieldEditorOpen: false,
            validation: {
                ...prev.validation,
                label: validateLabel(label),
                fields: validationFields,
            },
        }
    }

export const handleToggleEnableUpdates: Handle<ToggleEnableUpdates> =
    prev => () => ({
        ...prev,
        enableUpdates: prev.updatesLocked
            ? prev.enableUpdates
            : !prev.enableUpdates,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleToggleEnableDocuments: Handle<ToggleEnableDocuments> =
    prev => () => ({
        ...prev,
        enableDocuments: prev.documentsLocked
            ? prev.enableDocuments
            : !prev.enableDocuments,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })

export const handleSetStatus: Handle<SetStatus> =
    prev =>
    ({ payload: { status } }) => ({
        ...prev,
        status,
        fieldEditorOpen: false,
        addFieldOpen: false,
    })
