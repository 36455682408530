import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { CenteredSpinner } from 'Components/Spinner'
import { ContactUsToast } from 'Components/Toast'
import { BusinessObjectDefinitionEditor } from 'Features/BusinessObjectDefinitionEditor'
import { isBusinessObjectDefinitionValid } from 'Utils/BusinessObjectDefinition'
import { FC } from 'react'
import { Restart } from './Restart'
import { DispatchAction, State } from './useCreator'
import { Status } from './useCreator/types'

type Props = {
    state: State
    dispatch: DispatchAction
    onSave: () => void
    createToastProps: {
        open: boolean
        setOpen: (open: boolean) => void
    }
    errorMessages: string[]
}
const Details: FC<Props> = ({
    state,
    dispatch,
    onSave,
    createToastProps,
    errorMessages,
}) => (
    <Styled>
        <BusinessObjectDefinitionEditor state={state} dispatch={dispatch} />
        <footer>
            {state.status === Status.Saving ? (
                <>
                    <div />
                    <CenteredSpinner />
                    <div />
                </>
            ) : (
                <>
                    <Restart
                        confirmRestart={() => {
                            dispatch({
                                type: 'restart',
                                payload: undefined,
                            })
                        }}
                    />
                    <IconTextButton
                        icon="Save"
                        text="Save"
                        variant="primary"
                        onClick={onSave}
                        disabled={
                            !isBusinessObjectDefinitionValid(state.validation)
                        }
                    />
                </>
            )}
        </footer>
        <ContactUsToast
            open={createToastProps.open}
            onOpenChange={createToastProps.setOpen}
            description={
                errorMessages.length
                    ? errorMessages.join('. ')
                    : 'We were unable to create your dataset'
            }
        />
    </Styled>
)

const Styled = styled.div`
    display: grid;
    grid-template: 1fr / minmax(0, 1fr);
    height: 100%;
    overflow: hidden;

    > footer {
        grid-column: 1 / 3;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding-top: 1rem;
    }
`

export { Details }
