import { FC } from 'react'
import { styled } from '../../../../../Adapters/Freestyled'
import { Avatar } from '../../../../../Components/Avatar'
import { Text } from '../../../../../Components/Text'
import { Tooltip } from '../../../../../Components/Tooltip'
import { UserGroupAddMember } from './UserGroupAddMember'
import { Member_UserGroupFragment } from './__generated__/q'

type Props = {
    userGroup: Member_UserGroupFragment
}

export const UserGroup: FC<Props> = ({ userGroup }) => {
    return (
        <>
            <Styled>
                <div>
                    <div>
                        <Text as="h5" variant="bold-4">
                            {userGroup.name}
                        </Text>
                        <Text as="p" variant="regular-6">
                            {userGroup.users.length} members
                        </Text>
                    </div>
                    {userGroup.users.map(u => (
                        <Tooltip key={u.id} content={u.name}>
                            <Avatar noOfInitials={2} name={u.name} />
                        </Tooltip>
                    ))}
                </div>
                <UserGroupAddMember
                    userGroupId={userGroup.id}
                    currentMembers={userGroup.users.map(u => u.principal.id)}
                />
            </Styled>
        </>
    )
}

const Styled = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1rem;

    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};

    > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.5rem;
    }
`
