import { IconTextButton } from 'Components/Button'
import { ControlledModal } from 'Components/Modal'
import { InviteMember } from 'Features/InviteMember'
import { WorkspaceMembersFragment } from 'Features/InviteMember/__generated__/q'
import { FC, useRef, useState } from 'react'

type Props = WorkspaceMembersFragment & {
    refetch: () => void
}
const Invite: FC<Props> = ({ refetch, users, currentUser }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    return (
        <>
            <IconTextButton
                text="Invite member"
                icon="Invite"
                variant="primary"
                onClick={() => setOpen(true)}
                size="xSmall"
                ref={ref}
            />
            <ControlledModal
                title="Invite member"
                description="Enter an email and we'll send an invite to their inbox."
                renderContent={() => (
                    <InviteMember
                        onCompleted={() => {
                            refetch()
                            setOpen(false)
                        }}
                        users={users}
                        currentUser={currentUser}
                    />
                )}
                open={open}
                onOpenChange={setOpen}
                returnFocus={() => ref.current?.focus()}
            />
        </>
    )
}

export { Invite }
