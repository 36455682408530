import { styled } from 'Adapters/Freestyled'
import { FC } from 'react'
import { StyledButtonContainer } from '../styled'
import { strings } from './strings'

type Props = {
    monthDisplay: string
    selected: boolean
    isToday: boolean
    dateString: string
    onClick: () => void
    onDownArrow: () => void
    onUpArrow: () => void
    onLeftArrow: () => void
    onRightArrow: () => void
    disabled?: boolean
    isTabbable: boolean
}

export const Month: FC<Props> = ({
    monthDisplay,
    selected,
    isToday,
    dateString,
    onClick,
    onUpArrow,
    onDownArrow,
    onLeftArrow,
    onRightArrow,
    disabled = false,
    isTabbable,
}) => (
    <StyledMonth>
        <button
            disabled={disabled}
            aria-label={`${strings.ariaLabel} ${dateString}`}
            data-date={dateString}
            data-selected={selected}
            data-is-today={isToday}
            onClick={onClick}
            onKeyDown={e => {
                const { key } = e
                if (key === 'ArrowUp') onUpArrow()
                if (key === 'ArrowDown') onDownArrow()
                if (key === 'ArrowLeft') onLeftArrow()
                if (key === 'ArrowRight') onRightArrow()
            }}
            tabIndex={isTabbable ? 0 : -1}
            autoFocus={isTabbable}
        >
            {monthDisplay}
        </button>
    </StyledMonth>
)

const StyledMonth = styled(StyledButtonContainer)`
    button {
        height: 4rem;
        width: 6.25rem;
        border-radius: 0.25rem;
    }
`
