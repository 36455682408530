import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { FieldType } from '__generated__'

type Props = {
    fields: {
        id: string
        label: string
        type: FieldType
    }[]
    selected: string[]
    onSelect: (id: string) => void
}

const FieldSelection: FC<Props> = ({ fields, selected, onSelect }) => (
    <Styled>
        {fields.map(({ id, label, type }) => (
            <li key={id}>
                <StyledSelection onClick={() => onSelect(id)}>
                    <Icon
                        name={
                            selected.includes(id)
                                ? 'CheckboxTicked'
                                : 'CheckboxUnticked'
                        }
                    />
                    <div>
                        <StyledTypeLabel>
                            <Icon name="List" />
                            <Text as="p" variant="bold-7">
                                {type}
                            </Text>
                        </StyledTypeLabel>
                        <Text as="span" variant="regular-5">
                            {label}
                        </Text>
                    </div>
                </StyledSelection>
            </li>
        ))}
    </Styled>
)

const Styled = styled.ul`
    > li {
        display: flex;
        padding: 0.25rem 0;
        gap: 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }
`

const StyledSelection = styled.button`
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.375rem;
    gap: 0.75rem;
    justify-content: flex-start;

    > p {
        flex: 1;
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].active};
    }

    > div {
        flex: 1;
        text-align: left;
    }
`

const StyledTypeLabel = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    color: ${({ theme }) => theme.palette.text['03'].normal};
    text-transform: uppercase;

    svg {
        height: 0.5rem;
        width: 0.5rem;
    }

    svg * {
        fill: ${({ theme }) => theme.palette.icon['03'].normal};
    }
`

export { FieldSelection }
