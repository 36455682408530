import { dayjs } from 'Adapters/DayJS'
import { styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { IconTextButton } from 'Components/Button'
import { Icon } from 'Components/Icon'
import { CenteredSpinner } from 'Components/Spinner'
import { Text, regular5 } from 'Components/Text'
import { SimpleToast, useTriggerToast } from 'Components/Toast'
import { useMutationStatus } from 'Hooks'
import { P, match } from 'ts-pattern'
import { SendInvite } from './SendInvite'
import {
    useCreateInvitedUserMutation,
    useDeclineInviteMutation,
} from './__generated__/mutation'
import { useCurrentUserInvitesQuery } from './__generated__/query'
import { strings } from './strings'

const Invites = () => {
    const { triggerToast, open, setOpen } = useTriggerToast()
    const { data, loading: queryLoading } = useCurrentUserInvitesQuery({
        onError: triggerToast,
    })
    const [acceptInvite, acceptMutationData] = useCreateInvitedUserMutation({
        refetchQueries: ['CurrentUserInvites', 'TenantName'],
        onError(e) {
            logger.error('Failed to accept invite', e)
            triggerToast()
        },
    })

    const [declineInvite, declineMutationData] = useDeclineInviteMutation({
        refetchQueries: ['CurrentUserInvites'],
        onError(e) {
            logger.error('Failed to decline invite', e)
            triggerToast()
        },
    })

    const { status: acceptStatus } = useMutationStatus(acceptMutationData)
    const { status: declineStatus } = useMutationStatus(declineMutationData)

    const isLoading =
        queryLoading ||
        acceptStatus === 'loading' ||
        declineStatus === 'loading'

    const handleInviteAccepted = (inviteId: string) => async () => {
        if (isLoading || !data) return
        await acceptInvite({
            variables: {
                input: {
                    name: data.currentUser.name,
                    inviteId,
                    termsAccepted: dayjs().toISOString(),
                },
            },
        })
    }

    const handleInviteDeclined = (inviteId: string) => async () => {
        if (isLoading || !data) return
        await declineInvite({
            variables: {
                input: {
                    inviteId,
                },
            },
        })
    }

    return (
        <Styled>
            <SimpleToast
                open={open}
                onOpenChange={setOpen}
                description={strings.errorMessage}
            />
            <header>
                <Text as="h2" variant="bold-3">
                    Workspace invitations
                </Text>
                <Text as="p" variant="regular-4">
                    Here you will find invitations to join additional workspaces
                    which you can accept or decline.
                </Text>
            </header>
            <div>
                {match(data)
                    .with(P.nullish, () => <CenteredSpinner />)
                    .with({ invites: [] }, () => (
                        <StyledNoInvitations>
                            <Icon name="Check" />
                            <div>
                                <Text as="h5" variant="bold-4">
                                    No pending invitations
                                </Text>
                                <p>
                                    We'll let you know if you receive any
                                    invitations to additional workspaces
                                </p>
                            </div>
                        </StyledNoInvitations>
                    ))
                    .otherwise(({ invites }) => (
                        <StyledInviteList>
                            {invites.map(invite => (
                                <StyledInviteListItem key={invite.id}>
                                    <header>
                                        <Icon name="Invite" />
                                        <div>
                                            <Text as="h5" variant="bold-4">
                                                {invite.tenant.name}
                                            </Text>
                                            <Text as="p" variant="regular-6">
                                                Invited by {invite.inviter.name}
                                            </Text>
                                        </div>
                                    </header>
                                    <menu>
                                        <IconTextButton
                                            icon="CircleCheck"
                                            text="Accept"
                                            disabled={isLoading}
                                            onClick={handleInviteAccepted(
                                                invite.id
                                            )}
                                            title="Accept Invite"
                                            variant="primary"
                                            size="xSmall"
                                        />
                                        <IconTextButton
                                            icon="Close"
                                            text="Decline"
                                            disabled={isLoading}
                                            onClick={handleInviteDeclined(
                                                invite.id
                                            )}
                                            title="Decline Invite"
                                            variant="secondary"
                                            size="xSmall"
                                        />
                                    </menu>
                                </StyledInviteListItem>
                            ))}
                        </StyledInviteList>
                    ))}
            </div>
            <footer>
                <SendInvite />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > header h2 {
        padding-bottom: 1rem;
    }
    > header p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    > div {
        flex: 1;
    }

    > footer {
        padding: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const StyledInviteList = styled.ul`
    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

const StyledInviteListItem = styled.li`
    padding: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ui['04'].normal};

    > header {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }

    > menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
    }

    h5 {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
        padding: 1.25rem 4vw 1rem;
        background-color: ${({ theme }) => theme.palette.ui['01'].normal};
        border: none;
        border-radius: 0.5rem;
        filter: drop-shadow(
            0 0.25rem 0.25rem ${({ theme }) => theme.palette.ui['03'].normal}80
        );

        menu {
            justify-content: flex-start;
            flex-direction: row-reverse;
            gap: 1rem;
        }

        menu button {
            flex: 1;
        }
    }
`

const StyledNoInvitations = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.support.bg02.normal};
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.palette.support['02'].normal};
    border-radius: 0.5rem;
    color: ${({ theme }) => theme.palette.text.support02.normal};

    p {
        ${regular5}
        line-height: 150%;
        padding-top: 0.5rem;
    }

    svg path {
        fill: ${({ theme }) => theme.palette.text.support02.normal};
    }
`

export { Invites }
