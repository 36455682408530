import { Toggle } from 'Components/Input'
import {
    BooleanFieldValidation,
    BooleanField as Field,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: BooleanFieldValidation
    onSubmit: OnSubmit<Field, BooleanFieldValidation>
    onClose: () => void
}

const BooleanField: FC<Props> = ({ field, validation, onSubmit, onClose }) => {
    const [state, setState] = useState<State<Field, BooleanFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <BooleanSubFields
                defaultValue={{
                    checked: !!state.field.defaultValue,
                    onCheckedChange: checked => {
                        setState(prev => ({
                            ...prev,
                            field: {
                                ...prev.field,
                                defaultValue: checked,
                            },
                        }))
                    },
                }}
            />
        </Content>
    )
}

type SubFieldsProps = {
    defaultValue: {
        checked: boolean
        onCheckedChange: (checked: boolean) => void
    }
}

const BooleanSubFields: FC<SubFieldsProps> = ({ defaultValue }) => {
    const { current: defaultFieldId } = useRef(v4())
    return (
        <InputWrapper
            label="Default value"
            fieldId={defaultFieldId}
            description="This will determine the default state for the switch when a new record is created."
        >
            <div>
                <Toggle
                    id={defaultFieldId}
                    checked={defaultValue.checked}
                    onCheckedChange={defaultValue.onCheckedChange}
                />
            </div>
        </InputWrapper>
    )
}

export { BooleanField, BooleanSubFields }
