import { logger } from 'Adapters/Logger'
import { FC, useMemo } from 'react'
import Masonry from 'react-masonry-css'
import styled from 'styled-components'
import { LoadingDisplay } from '../Actions/LoadingDisplay'
import {
    BusinessObjectInProcessOverviewQuery,
    useBusinessObjectInProcessOverviewQuery,
} from '../Overview/__generated__/query'
import { useCurrentPhaseProgress } from '../Overview/useCurrentPhaseProgress'
import { AddBusinessObjectToProcess, ProgressSummary } from '../Widgets'

type Props = {
    processId: string
    businessObjectId: string
}

export const ProcessOverview: FC<Props> = ({ processId, businessObjectId }) => {
    const { data, refetch } = useBusinessObjectInProcessOverviewQuery({
        variables: {
            processId,
            businessObjectId,
        },
        fetchPolicy: 'cache-and-network',
        onError(error) {
            logger.error('Failed to fetch process data', error, {
                processId,
            })
        },
    })

    const businessProcessPhaseIndex = useMemo(() => {
        if (!data) return undefined

        return data.businessObject.onProcess?.onPhase.phase.phaseIndex
    }, [data])

    if (!data) return <LoadingDisplay />

    if (businessProcessPhaseIndex === undefined)
        return (
            <StyledMasonry
                breakpointCols={breakpointColumns}
                columnClassName="masonry-column"
                className="without-padding"
            >
                <AddBusinessObjectToProcess
                    businessObjectId={businessObjectId}
                    processId={processId}
                />
            </StyledMasonry>
        )

    return (
        <WithPercentageComplete
            businessObject={data.businessObject}
            process={data.process}
            phaseIndex={businessProcessPhaseIndex}
            refetch={refetch}
        />
    )
}

type WithPercentageCompleteProps = {
    businessObject: BusinessObjectInProcessOverviewQuery['businessObject']
    process: BusinessObjectInProcessOverviewQuery['process']
    phaseIndex: number
    refetch: () => void
}

const WithPercentageComplete: FC<WithPercentageCompleteProps> = ({
    businessObject,
    phaseIndex,
    process,
    refetch,
}) => {
    const { percentageComplete, actions, loading } = useCurrentPhaseProgress({
        businessObjectId: businessObject.id,
        process,
        phaseIndex,
    })

    return (
        <div>
            <ProgressSummary
                process={process}
                businessObjectPhaseIndex={phaseIndex}
                businessObject={businessObject}
                onProgressChange={refetch}
                percentageComplete={percentageComplete}
                percentageCompleteLoading={loading}
                actions={actions}
            />
        </div>
    )
}

const StyledMasonry = styled(Masonry)`
    display: flex;
    gap: 2rem;
    width: auto;
    height: 100%;
    padding-top: 2rem;

    &.without-padding {
        padding-top: 0;
    }

    .masonry-column {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &::after {
            content: '';
            display: block;
            border-bottom: 1px solid transparent;
        }
    }
`

const breakpointColumns = {
    default: 2,
    1250: 1,
}
