import { css, styled } from 'Adapters/Freestyled'

// Will apply to the underlying card when dragging
const draggingStyles = css`
    opacity: 0.5;
`

const draggablePointer = css`
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
`

export const StyledCard = styled.div<{ $dragging?: boolean }>`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.ui['03'].normal};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    display: grid;
    grid-template: auto auto / 1fr auto;

    ${draggablePointer};

    ${({ $dragging }) => $dragging && draggingStyles}
`

export const StyledContent = styled.button`
    position: relative;
    z-index: 1;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    padding: 0;
    text-align: left;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: 0.25rem 0.25rem 0 0;
    transition: background-color
        ${({ theme }) => theme.animation.buttonTransitionDuration} ease;

    &:hover {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['01'].hover};
    }

    h4 {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    }
`
