import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitorDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VisitorDetailsQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', name: string, email: string, id: string } };


export const VisitorDetailsDocument = gql`
    query VisitorDetails {
  currentUser {
    name
    email
    id
  }
}
    `;

/**
 * __useVisitorDetailsQuery__
 *
 * To run a query within a React component, call `useVisitorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisitorDetailsQuery(baseOptions?: Apollo.QueryHookOptions<VisitorDetailsQuery, VisitorDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitorDetailsQuery, VisitorDetailsQueryVariables>(VisitorDetailsDocument, options);
      }
export function useVisitorDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitorDetailsQuery, VisitorDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitorDetailsQuery, VisitorDetailsQueryVariables>(VisitorDetailsDocument, options);
        }
export type VisitorDetailsQueryHookResult = ReturnType<typeof useVisitorDetailsQuery>;
export type VisitorDetailsLazyQueryHookResult = ReturnType<typeof useVisitorDetailsLazyQuery>;
export type VisitorDetailsQueryResult = Apollo.QueryResult<VisitorDetailsQuery, VisitorDetailsQueryVariables>;