import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { DurationSelector } from 'Features/DurationSelector'
import { DefaultDateInput } from '__generated__'
import dayjs from 'dayjs'
import { FC, useRef, useState } from 'react'
import { P, match } from 'ts-pattern'
import { InputWrapper } from '../InputWrapper'

const DefaultValue: FC<{
    defaultValue: DefaultDateInput | undefined
    onClear: () => void
    onSetSpecifc: (date: Date) => void
    onSetRelative: (
        value: number,
        unit: 'hours' | 'days' | 'months' | 'years'
    ) => void
    range: {
        start: Date | undefined
        end: Date | undefined
    }
}> = ({ defaultValue, onClear, onSetSpecifc, onSetRelative, range }) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const dpTriggerRef = useRef<HTMLButtonElement>(null)
    return (
        <InputWrapper
            label="Default value"
            fieldset
            description="This will determine the default date when a new record is created. If a range has been set, specific dates will be bounded by that range."
        >
            {match(defaultValue)
                .with({ absolute: P.not(P.nullish) }, ({ absolute }) => (
                    <StyledDefaultWrapper>
                        <StyledDateButton
                            icon="Calendar"
                            text={dayjs(absolute).format('DD MMM YYYY')}
                            onClick={() => {
                                setDatePickerOpen(true)
                            }}
                            variant="secondary"
                            ref={dpTriggerRef}
                        />
                        <IconTextButton
                            icon="Close"
                            text="Clear"
                            onClick={onClear}
                            variant="ghost"
                        />
                    </StyledDefaultWrapper>
                ))
                .with({ relative: P.not(P.nullish) }, ({ relative }) => (
                    <StyledDefaultWrapper>
                        <DurationSelector
                            trigger={
                                <StyledDateButton
                                    icon="Calendar"
                                    text={dayjs
                                        .duration(relative)
                                        .humanize(true)}
                                    variant="secondary"
                                />
                            }
                            handleConfirm={onSetRelative}
                            align="start"
                        />
                        <IconTextButton
                            icon="Close"
                            text="Clear"
                            onClick={onClear}
                            variant="ghost"
                        />
                    </StyledDefaultWrapper>
                ))
                .otherwise(() => (
                    <StyledDefaultWrapper>
                        <StyledDateButton
                            icon="Calendar"
                            text="Set a specifc date"
                            onClick={() => {
                                setDatePickerOpen(true)
                            }}
                            variant="secondary"
                            ref={dpTriggerRef}
                        />
                        <DurationSelector
                            trigger={
                                <StyledDateButton
                                    icon="Calendar"
                                    text="Set a relative date"
                                    variant="secondary"
                                />
                            }
                            handleConfirm={onSetRelative}
                            align="end"
                        />
                    </StyledDefaultWrapper>
                ))}
            <DatePicker
                title="Set specific default date"
                description=""
                hideDescription
                open={datePickerOpen}
                onOpenChange={setDatePickerOpen}
                onConfirm={({ date }) => {
                    onSetSpecifc(date)
                    setDatePickerOpen(false)
                }}
                returnFocus={() => dpTriggerRef.current?.focus()}
                limit={{
                    from: range.start,
                    to: range.end,
                }}
            />
        </InputWrapper>
    )
}

const StyledDefaultWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const StyledDateButton = styled(IconTextButton)`
    flex: 1;
`

export { DefaultValue }
