import { useMemo } from 'react'
import { useOrganisationUsersQuery } from './__generated__/query'

export const useOrganisationUsers = () => {
    const { data } = useOrganisationUsersQuery({
        fetchPolicy: 'cache-and-network',
    })

    const users = useMemo(() => {
        if (!data) return []

        const currentUserId = data.currentUser.id

        return data.users.map(user => {
            return {
                id: user.id,
                name: user.name,
                isCurrentUser: user.id === currentUserId,
                principalId: user.principal.id,
            }
        })
    }, [data])

    return { users, hasInitiallyFetched: !!data }
}
