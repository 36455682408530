import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'

export const NoDueDateNotice: FC = () => {
    return (
        <Styled>
            <div>
                <Text as="p" variant="regular-5">
                    The following actions have no due date set.
                </Text>
            </div>
        </Styled>
    )
}

const Styled = styled.div`
    padding: 1rem 3rem 0;
    position: sticky;
    left: 0;

    > div {
        padding: 0.75rem 1rem;
        background-color: ${({ theme }) => theme.palette.support.bg05.normal};
        color: ${({ theme }) => theme.palette.text.support05.normal};
    }
`
