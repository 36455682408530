import { flex, styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { CenteredSpinner } from 'Components/Spinner'
import { Text } from 'Components/Text'
import { noop } from 'lodash'
import { FC, useMemo } from 'react'
import { NoTypename } from 'Utils/stripTypenames'
import { BusinessObjectDefinitionTemplate } from '__generated__'
import { BizObjectDef_CompleteFragment } from '../../../../Fragments/__generated__/BusinessObjectDefinition'
import { useExisting_BodsQuery } from './__generated__/query'

type Props = {
    onExistingSelected: (
        existing: BizObjectDef_CompleteFragment | undefined
    ) => void
    selectedExisting?: { id: string }
    disabled: boolean
}

export type Template = NoTypename<BusinessObjectDefinitionTemplate>

export const CopyExisting: FC<Props> = ({
    onExistingSelected,
    selectedExisting,
    disabled,
}) => {
    const { data, loading } = useExisting_BodsQuery()

    const existingBODs = useMemo(
        () => data?.businessObjectDefinitions ?? [],
        [data]
    )

    const options = useMemo(
        () => [
            { text: 'Select a definition', value: '' },
            ...existingBODs.map(bod => ({
                text: bod.name,
                value: bod.id,
            })),
        ],
        [existingBODs]
    )

    return (
        <StyledTemplate $isLoading={loading} $disabled={disabled}>
            {loading ? (
                <CenteredSpinner />
            ) : (
                <>
                    <Text as="h2" variant="bold-4">
                        Copy from an existing dataset:
                    </Text>

                    <Select
                        id={`bod-template`}
                        name={`bod-template`}
                        options={options}
                        value={selectedExisting?.id ?? ''}
                        onValueChange={value =>
                            value
                                ? onExistingSelected(
                                      existingBODs.find(
                                          ({ id }) => id === value
                                      )
                                  )
                                : noop()
                        }
                        disabled={disabled}
                    />
                </>
            )}
        </StyledTemplate>
    )
}

const StyledTemplate = styled.div<{ $isLoading: boolean; $disabled: boolean }>`
    ${({ $isLoading }) =>
        flex('column', 'flex-start', $isLoading ? 'center' : 'flex-start')};
    gap: 1rem;
    padding: 1.5rem 2rem;
    border: 1px dashed ${({ theme }) => theme.palette.ui['04'].normal};
    width: 100%;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

    a {
        color: ${({ theme }) => theme.palette.text.support03.normal};
        text-decoration: underline;
    }
`
