import { styled } from 'Adapters/Freestyled'
import { Grouping } from 'Components/GroupingControl/types'
import { Text } from 'Components/Text'
import { ContactUsToast } from 'Components/Toast'
import { useDropToUpdate } from 'Features/ActionView/dnd/useDropToUpdate'
import { FC } from 'react'
import { useGroupActions } from '../../Hooks/useGroupActions'
import { Action, Extension } from '../../types'
import { Group } from './Group'

type Props = {
    actions: Action[]
    extension?: Extension
    grouping: Grouping
}

export const GroupDisplay: FC<Props> = ({ actions, extension, grouping }) => {
    const groups = useGroupActions(actions, grouping, extension)

    const { errorToastOpen, setErrorToastOpen } = useDropToUpdate(grouping)

    return (
        <StyledGroupDisplay>
            {Object.keys(groups).length ? (
                <>
                    <Text variant="bold-3" as="h2">
                        {
                            grouping.fields.find(
                                field => field.id === grouping.selection
                            )?.label
                        }
                    </Text>

                    <div className="groups">
                        {Object.entries(groups).map(([entityId, group]) => (
                            <Group
                                fieldId={grouping.selection}
                                group={group}
                                entityId={entityId}
                                key={entityId}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Text variant="regular-4" as="p">
                    <em>
                        No <strong>actions</strong> to show.
                    </em>
                </Text>
            )}

            <ContactUsToast
                open={errorToastOpen}
                onOpenChange={setErrorToastOpen}
                description={`There was a problem updating this action. Contact us if the problem persists`}
            />
        </StyledGroupDisplay>
    )
}

const StyledGroupDisplay = styled.section`
    padding: 2rem 3rem;

    > h2 {
        padding-bottom: 1.5rem;
    }

    > p {
        width: 100%;
        text-align: center;
    }

    .groups {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 2rem;
    }
`
