import { styled } from 'Adapters/Freestyled'
import { FC, useMemo } from 'react'
import { match } from 'ts-pattern'
import { FieldType } from '../../__generated__'
import {
    BooleanField,
    CurrencyField,
    DateField,
    DocumentField,
    EmailField,
    ListField,
    NumberField,
    RelationField,
    SelectField,
    TelephoneField,
    TextField,
    URLField,
    UserField,
} from './Fields'
import { FieldChangeHandler, FieldExtensionDefinition } from './types'

export type Field = {
    field: {
        id: string
        type: `${FieldType}` // get enum values as string union
    }
    value: any
}

export type Props = {
    mutationErrors: Record<string, string[]>
    fieldDefinitions: FieldExtensionDefinition[]
    fieldValues: Field[]
    onFieldChanged: FieldChangeHandler
}

export const BusinessObjectFieldset: FC<Props> = ({
    mutationErrors,
    fieldDefinitions,
    fieldValues,
    onFieldChanged,
}) => {
    const renderer = useMemo(
        () => renderField(onFieldChanged),
        [onFieldChanged]
    )
    return (
        <StyledFieldset>
            {fieldDefinitions.map(definition =>
                renderer(
                    definition,
                    fieldValues.find(
                        ({ field: { id } }) => id === definition.id
                    ),
                    mutationErrors[definition.id]
                )
            )}
        </StyledFieldset>
    )
}

const renderField =
    (onFieldChanged: FieldChangeHandler) =>
    (
        fieldDefinition: FieldExtensionDefinition,
        fieldValue?: Field,
        errors?: string[]
    ) =>
        match(fieldDefinition)
            // Primitive
            .with({ __typename: 'TextFieldDefinition' }, props => (
                <TextField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'TextFieldDefinition'>
                    )({ ...props, type: FieldType.Text })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'URLFieldDefinition' }, props => (
                <URLField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'URLFieldDefinition'>
                    )({ ...props, type: FieldType.Url })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'NumberFieldDefinition' }, props => (
                <NumberField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'NumberFieldDefinition'>
                    )({ ...props, type: FieldType.Number })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'EmailFieldDefinition' }, props => (
                <EmailField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'EmailFieldDefinition'>
                    )({ ...props, type: FieldType.Email })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'BooleanFieldDefinition' }, props => (
                <BooleanField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'BooleanFieldDefinition'>
                    )({ ...props, type: FieldType.Boolean })}
                    errors={errors}
                />
            ))

            // Complex
            .with({ __typename: 'SelectFieldDefinition' }, props => (
                <SelectField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'SelectFieldDefinition'>
                    )({ ...props, type: FieldType.Select })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'DateFieldDefinition' }, props => (
                <DateField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'DateFieldDefinition'>
                    )({ ...props, type: FieldType.Date })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'TelephoneFieldDefinition' }, props => (
                <TelephoneField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'TelephoneFieldDefinition'>
                    )({ ...props, type: FieldType.Telephone })}
                    errors={errors}
                />
            ))

            // Built In Objects
            .with({ __typename: 'DocumentFieldDefinition' }, props => (
                <DocumentField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'DocumentFieldDefinition'>
                    )({ ...props, type: FieldType.Document })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'UserFieldDefinition' }, props => (
                <UserField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'UserFieldDefinition'>
                    )({ ...props, type: FieldType.User })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'RelationFieldDefinition' }, props => (
                <RelationField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'RelationFieldDefinition'>
                    )({ ...props, type: FieldType.Relation })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'ListFieldDefinition' }, props => (
                <ListField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'ListFieldDefinition'>
                    )({ ...props, type: FieldType.List })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'CurrencyFieldDefinition' }, props => (
                <CurrencyField
                    key={props.id}
                    field={props}
                    value={fieldValue?.value}
                    onChange={(
                        onFieldChanged as FieldChangeHandler<'CurrencyFieldDefinition'>
                    )({ ...props, type: FieldType.Currency })}
                    errors={errors}
                />
            ))
            .with({ __typename: 'UpdatesFieldDefinition' }, () => null)
            .exhaustive()

const StyledFieldset = styled.fieldset`
    border: 0;
    padding: 0;
    width: 100%;
`
