import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { PhaseNodeData } from '../types'

export const PhaseNode: FC<NodeProps<PhaseNodeData>> = ({ data }) => {
    return (
        <StyledNode>
            <StyledNodeInterior>
                {!data.isFirstPhase && (
                    <Handle
                        type="target"
                        position={Position.Left}
                        id="previous"
                    />
                )}
                {data.hasBackstop && (
                    <Handle
                        type="source"
                        id="backstop"
                        position={Position.Top}
                    />
                )}
                <Text variant="regular-6" as="p">
                    {data.label}
                </Text>
                {data.hasSubProcess && (
                    <>
                        <Handle
                            type="source"
                            position={Position.Bottom}
                            id="subprocess-out"
                        />
                        <Handle
                            type="target"
                            position={Position.Bottom}
                            id="subprocess-in"
                        />
                    </>
                )}
                {!data.isFinalPhase && (
                    <Handle type="source" position={Position.Right} id="next" />
                )}
            </StyledNodeInterior>
        </StyledNode>
    )
}

const StyledNode = styled.div`
    height: 100%;
    width: 100%;
`

const StyledNodeInterior = styled.div`
    height: calc(100% - 5px);
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    overflow: hidden;
`
