import { IconButton } from 'Components/Button'
import { Tooltip } from 'Components/Tooltip'
import { FC } from 'react'
import { AnchorProps, Upload } from '../../../../Upload'
import { Attachment } from '../../../types'

type Props = {
    attachments: Attachment[]
    onAttachment: (attachments: Attachment[]) => void
}

export const Attach: FC<Props> = ({ onAttachment, attachments }) => {
    return (
        <Upload
            anchor={AttachButton}
            onUploadCompleted={document =>
                onAttachment([
                    {
                        type: 'file',
                        file: document,
                    },
                ])
            }
            existingSelections={{
                selectMode: 'multiple',
                allowExistingSelection: true,
                existingSelections: attachments.map(
                    attachment => attachment.file.id
                ),
                onExistingDocumentSelected: documents =>
                    onAttachment(
                        documents.map(document => ({
                            type: 'file',
                            file: document,
                        }))
                    ),
            }}
        />
    )
}

const AttachButton: FC<AnchorProps> = ({ onClick, buttonRef }) => (
    <Tooltip content="Attach document">
        <IconButton
            iconName="Document"
            onClick={onClick}
            variant="secondary"
            ref={buttonRef}
        />
    </Tooltip>
)
