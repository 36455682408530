import {
    AuthParams,
    useAuthenticate,
} from 'Adapters/Authentication/useAuthenticate'
import { styled } from 'Adapters/Freestyled'
import modlifyLogo from 'Assets/modlify-full-logo.svg'
import { TextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC, useEffect } from 'react'
import { keyframes } from 'styled-components'

const DesktopLanding: FC<{
    auth: AuthParams
    enterSandbox: () => void
    isInvitation?: boolean
    autoLogin: boolean
}> = ({ auth, enterSandbox, isInvitation, autoLogin }) => {
    const { login, signup } = useAuthenticate()

    const getStartedAction = isInvitation ? () => signup(auth) : enterSandbox
    const getStartedText = isInvitation ? 'Accept Invitation' : 'Get Started'

    useEffect(() => {
        if (autoLogin) {
            login(auth)
        }
    }, [auth, autoLogin, login])

    return (
        <Styled>
            <Slides>
                <div />
                <div />
                <div />
            </Slides>
            <SyledHeader>
                <img src={modlifyLogo} alt="modlify logo" />
                <div>
                    <Text as="p" variant="regular-4">
                        Already a member?
                    </Text>
                    <TextButton
                        onClick={() => login(auth)}
                        text="Log in"
                        size="xSmall"
                    />
                </div>
            </SyledHeader>
            <StyledContent>
                <StyledCopy>
                    <h1>Welcome to Modlify</h1>
                    <p>Where teams optimise their ways of working.</p>
                    <p>
                        Collaborative team workspaces centred on outcomes,
                        <br />
                        delivered through processes.
                    </p>
                </StyledCopy>
                <div>
                    <TextButton
                        onClick={getStartedAction}
                        text={getStartedText}
                        size="medium"
                        variant="primary"
                    />
                </div>
            </StyledContent>
        </Styled>
    )
}

const slide1 = keyframes`
    0% {
        opacity: 1;
    }
    30% {
        transform: scale(1.4);
        opacity: 1;
    }
    33% {
        opacity: 0;
        transform: scale(1.4);
    }
    96% {
        transform: scale(1);
        opacity: 0;
    }
    98% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`

const slide2 = keyframes`
    0% {
        transform: scale(1);
        opacity: 0;
    }
    30% {
        transform: scale(1);
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    63% {
        transform: scale(1.4);
        opacity: 1;
    }
    66% {
        transform: scale(1.4);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
`

const slide3 = keyframes`
    0% {
        transform: scale(1);
        opacity: 0;
    }
    63% {
        transform: scale(1);
        opacity: 0;
    }
    66% {
        opacity: 1;
    }
    96% {
        transform: scale(1.4);
        opacity: 1;
    }
    98% {
        transform: scale(1.4);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
`

const Slides = styled.div`
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 80% 50%;

    div {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 45s;
        transform: scale(1);
    }

    div:nth-child(1) {
        background-image: url('/images/landing-slide-1.jpg');
        animation-name: ${slide1};
    }
    div:nth-child(2) {
        background-image: url('/images/landing-slide-2.jpg');
        animation-name: ${slide2};
    }
    div:nth-child(3) {
        background-image: url('/images/landing-slide-3.jpg');
        animation-name: ${slide3};
    }
`

const Styled = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.brand['01'].normal};

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(
            circle at 100% 100%,
            transparent 20%,
            ${({ theme }) => theme.palette.brand['01'].normal} 80%
        );
        background-size: cover;
        background-position: center;
    }
`

const SyledHeader = styled.header`
    z-index: 3;
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 2rem 4rem;
    background-image: linear-gradient(
        to right,
        transparent,
        ${({ theme }) => theme.palette.brand['01'].normal} 90%
    );

    > div {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    img {
        height: 2rem;
        width: auto;
    }
`

const StyledContent = styled.div`
    z-index: 3;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem 4rem;
    gap: 1.5rem;
`

const StyledCopy = styled.div`
    max-width: 47rem;
    text-align: left;
    color: ${({ theme }) => theme.palette.ui['07'].normal};

    h1 {
        font-size: 3rem;
        font-weight: 400;
        padding-bottom: 1rem;
    }

    p:first-of-type {
        font-weight: 700;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.5em;
        padding-bottom: 1em;
    }

    p:not(:first-of-type) {
        color: ${({ theme }) => theme.palette.ui['06'].normal};
    }
`

export { DesktopLanding }
