import { styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Tooltip } from 'Components/Tooltip'
import { ComponentProps, FC } from 'react'
import { keyframes } from 'styled-components'

type Props = {
    onSubmit: () => void
    disabled: boolean
} & Pick<ComponentProps<typeof IconTextButton>, 'variant'>

export const Send: FC<Props> = ({ onSubmit, disabled, variant }) => {
    return (
        <Tooltip content="Submit">
            <StyledSendButton
                icon="Send"
                text={disabled ? '' : 'Send'}
                onClick={onSubmit}
                disabled={disabled}
                variant={variant}
                size="xSmall"
            />
        </Tooltip>
    )
}

const wiggle = keyframes`
    0% { 
        transform: rotate(0deg); 
    }

    5% { 
        transform: rotate(10deg);
    }

    10% { 
        transform: rotate(-10deg);
    }

    15% { 
        transform: rotate(10deg); 
    }

    20% {
        transform: rotate(0deg);
    }
`

const StyledSendButton = styled(IconTextButton)`
    margin-left: auto;
    transition: 0.4s;

    svg {
        animation: ${({ disabled }) => (!disabled ? wiggle : 'none')};
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`
