import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import { WorkspaceMembersFragmentDoc } from '../../../../../../Features/InviteMember/__generated__/q';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserInvitesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserInvitesQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, name: string }, invites: Array<{ __typename?: 'Invite', id: string, tenant: { __typename?: 'InvitingTenant', id: string, name: string }, inviter: { __typename?: 'InvitingUser', id: string, name: string } }> };

export type AccountSendInviteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountSendInviteQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, email: string }>, currentUser: { __typename?: 'User', id: string, name: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, openInvites: Array<{ __typename?: 'Invite', id: string, inviteeEmail: string }> } } | null } };


export const CurrentUserInvitesDocument = gql`
    query CurrentUserInvites {
  currentUser {
    id
    name
  }
  invites {
    id
    tenant {
      id
      name
    }
    inviter {
      id
      name
    }
  }
}
    `;

/**
 * __useCurrentUserInvitesQuery__
 *
 * To run a query within a React component, call `useCurrentUserInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserInvitesQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserInvitesQuery, CurrentUserInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserInvitesQuery, CurrentUserInvitesQueryVariables>(CurrentUserInvitesDocument, options);
      }
export function useCurrentUserInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserInvitesQuery, CurrentUserInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserInvitesQuery, CurrentUserInvitesQueryVariables>(CurrentUserInvitesDocument, options);
        }
export type CurrentUserInvitesQueryHookResult = ReturnType<typeof useCurrentUserInvitesQuery>;
export type CurrentUserInvitesLazyQueryHookResult = ReturnType<typeof useCurrentUserInvitesLazyQuery>;
export type CurrentUserInvitesQueryResult = Apollo.QueryResult<CurrentUserInvitesQuery, CurrentUserInvitesQueryVariables>;
export const AccountSendInviteDocument = gql`
    query AccountSendInvite {
  ...WorkspaceMembers
}
    ${WorkspaceMembersFragmentDoc}`;

/**
 * __useAccountSendInviteQuery__
 *
 * To run a query within a React component, call `useAccountSendInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSendInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSendInviteQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSendInviteQuery(baseOptions?: Apollo.QueryHookOptions<AccountSendInviteQuery, AccountSendInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSendInviteQuery, AccountSendInviteQueryVariables>(AccountSendInviteDocument, options);
      }
export function useAccountSendInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSendInviteQuery, AccountSendInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSendInviteQuery, AccountSendInviteQueryVariables>(AccountSendInviteDocument, options);
        }
export type AccountSendInviteQueryHookResult = ReturnType<typeof useAccountSendInviteQuery>;
export type AccountSendInviteLazyQueryHookResult = ReturnType<typeof useAccountSendInviteLazyQuery>;
export type AccountSendInviteQueryResult = Apollo.QueryResult<AccountSendInviteQuery, AccountSendInviteQueryVariables>;