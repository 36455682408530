import { debounce } from 'lodash'
import { calculateResponse } from './utils'

const tagBodyWithScreenSize = () =>
    window.document.body.setAttribute(
        'data-screen-size',
        calculateResponse().screenSize
    )
tagBodyWithScreenSize()

let widthTracker = window.innerWidth
window.addEventListener(
    'resize',
    debounce(() => {
        if (window.innerWidth !== widthTracker) {
            widthTracker = window.innerWidth
            tagBodyWithScreenSize()
            window.dispatchEvent(new Event('resize-x-end'))
        }
    }, 250)
)

export * from './useResponder'
