import { flex, styled } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { useOrganisationUsers } from 'Hooks'
import { uniqBy } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useUserConstraints } from './useUserConstraints'

type Props = BODField<'UserFieldDefinition'> & {
    listName: string
    listId: string
}

export const UserListField: FC<Props> = ({
    userConstraints,
    userDefaultValue,
    listName,
    listId,
}) => {
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } = useUserConstraints({
        userConstraints,
    })

    const { users } = useOrganisationUsers()

    const [values, setValues] = useState(
        userDefaultValue ? [userDefaultValue] : []
    )

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: values.map(user => ({ user: user.id })),
            },
        })
    }, [values, dispatch, listId])

    return (
        <ListFieldWrapper
            id={listId}
            name={listName}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={state[listId].errors ?? []}
            list={values.map(({ name, id }) => (
                <StyledListItem key={id}>
                    <Avatar name={name} variant="blue" size="small" />
                    <span>{name}</span>
                    <IconButton
                        iconName={'Close'}
                        title="Remove"
                        onClick={e => {
                            e.preventDefault() // Stop form from being submitted
                            setValues(prev =>
                                prev.filter(user => user.id !== id)
                            )
                        }}
                    />
                </StyledListItem>
            ))}
        >
            <Dropdown
                renderContent={() => (
                    <>
                        {users.map(user => (
                            <DropdownButton
                                key={user.id}
                                text={user.isCurrentUser ? 'Me' : user.name}
                                onSelect={() => {
                                    setValues(prev =>
                                        uniqBy(
                                            [
                                                ...prev,
                                                {
                                                    id: user.id,
                                                    name: user.name,
                                                },
                                            ],
                                            'id'
                                        )
                                    )
                                }}
                            />
                        ))}
                    </>
                )}
                renderOpenDropdownButton={() => (
                    <StyledAddDocumentButton icon="User" text={'Add a user'} />
                )}
                variant="light"
            />
        </ListFieldWrapper>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button {
        margin-left: auto;
    }
`
