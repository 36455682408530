import { styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { Text } from 'Components/Text'
import {
    ListField as Field,
    FieldType,
    ListFieldValidation,
    validateListOf,
    validateRelationType,
} from 'Utils/BusinessObjectDefinition'
import {} from '__generated__'
import { FC, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../../types'
import { BooleanSubFields } from '../Boolean'
import { Content } from '../Content'
import { CurrencySubFields } from '../Currency'
import {
    DateSubFields,
    clearDateRange,
    updateDateRangeEnd,
    updateDateRangeStart,
    updateDefaultRelativeDate,
    updateDefaultSpecificDate,
} from '../Date'
import { EmailSubFields } from '../Email'
import { InputWrapper } from '../InputWrapper'
import { NumberSubFields } from '../Number'
import { RelationSubFields } from '../Relation'
import { SelectSubFields } from '../Select'
import { TelephoneSubFields } from '../Telephone'
import { TextSubFields } from '../Text'
import { UrlSubFields } from '../Url'
import { UserSubFields } from '../User'
import { createSubField, listOfOptions } from './utils'

type Props = {
    field: Field
    validation: ListFieldValidation
    onSubmit: OnSubmit<Field, ListFieldValidation>
    onClose: () => void
    relationOptions: {
        value: string
        text: string
    }[]
    userOptions: {
        value: string
        text: string
    }[]
    currencyOptions: {
        value: string
        text: string
    }[]
}

const ListField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,
    relationOptions,
    userOptions,
    currencyOptions,
}) => {
    const [state, setState] = useState<State<Field, ListFieldValidation>>({
        field,
        validation,
    })
    const { current: listOfFieldId } = useRef(v4())
    const displayOnly = field.constraintsLocked
    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <InputWrapper
                label="List of"
                fieldId={listOfFieldId}
                description="Select what type of field this list will contain."
                errorMessage={
                    'message' in state.validation.listOf
                        ? state.validation.listOf.message
                        : undefined
                }
                displayOnly={displayOnly}
            >
                {displayOnly ? (
                    <StyledDisplayOnly>
                        <Text as="p" variant="bold-4">
                            {
                                listOfOptions.find(
                                    o => o.value === state.field.listOf.type
                                )?.text
                            }
                        </Text>
                    </StyledDisplayOnly>
                ) : (
                    <Select
                        id={listOfFieldId}
                        name={listOfFieldId}
                        value={state.field.listOf.type}
                        onValueChange={value => {
                            setState(prev => {
                                const nextField = {
                                    ...prev.field,
                                    listOf: createSubField({
                                        fieldType: value,
                                    }),
                                }
                                return {
                                    ...prev,
                                    field: nextField,
                                    validation: {
                                        ...prev.validation,
                                        listOf: validateListOf({
                                            list: nextField,
                                            pendingSubFields: true,
                                        }),
                                    },
                                }
                            })
                        }}
                        options={listOfOptions}
                    />
                )}
            </InputWrapper>
            {match(state.field.listOf)
                .with({ type: FieldType.Text }, text => (
                    <TextSubFields
                        defaultValue={{
                            value: text.defaultValue ?? '',
                            onChange: defaultValue =>
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        listOf: {
                                            ...text,
                                            defaultValue,
                                        },
                                    },
                                })),
                        }}
                        minMax={{
                            length: text.constraints.length ?? undefined,
                            update: fn =>
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        listOf: fn(text),
                                    },
                                })),
                        }}
                        constraintsLocked={field.constraintsLocked}
                    />
                ))
                .with({ type: FieldType.Number }, number => (
                    <NumberSubFields
                        defaultValue={{
                            value: number.defaultValue ?? '',
                            onChange: value => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        listOf: {
                                            ...number,
                                            defaultValue:
                                                Number(value) ?? undefined,
                                        },
                                    },
                                }))
                            },
                        }}
                        range={{
                            value: number.constraints.range ?? undefined,
                            onChange: fn => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        listOf: fn(number),
                                    },
                                }))
                            },
                        }}
                        constraintsLocked={field.constraintsLocked}
                    />
                ))
                .with({ type: FieldType.Telephone }, telephone => (
                    <TelephoneSubFields
                        defaultValue={{
                            code: {
                                value:
                                    telephone.defaultValue.countryCode ??
                                    undefined,
                                onChange: code => {
                                    setState(prev => ({
                                        ...prev,
                                        field: {
                                            ...prev.field,
                                            list: {
                                                listOf: {
                                                    ...telephone,
                                                    defaultValue: {
                                                        ...telephone.defaultValue,
                                                        countryCode: code,
                                                    },
                                                },
                                            },
                                        },
                                    }))
                                },
                            },
                            number: {
                                value: telephone.defaultValue.number ?? '',
                                onChange: value => {
                                    setState(prev => ({
                                        ...prev,
                                        field: {
                                            ...prev.field,
                                            list: {
                                                listOf: {
                                                    ...telephone,
                                                    defaultValue: {
                                                        ...telephone.defaultValue,
                                                        number: value,
                                                    },
                                                },
                                            },
                                        },
                                    }))
                                },
                            },
                        }}
                    />
                ))
                .with({ type: FieldType.Email }, email => (
                    <EmailSubFields
                        defaultValue={{
                            value: email.defaultValue ?? '',
                            onChange: value => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...email,
                                                defaultValue: value,
                                            },
                                        },
                                    },
                                }))
                            },
                        }}
                    />
                ))
                .with({ type: FieldType.Url }, url => (
                    <UrlSubFields
                        defaultValue={{
                            value: url.defaultValue ?? '',
                            onChange: value => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...url,
                                                defaultValue: value,
                                            },
                                        },
                                    },
                                }))
                            },
                        }}
                    />
                ))
                .with({ type: FieldType.Boolean }, boolean => (
                    <BooleanSubFields
                        defaultValue={{
                            checked: !!boolean.defaultValue,
                            onCheckedChange: checked => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...boolean,
                                                defaultValue: checked,
                                            },
                                        },
                                    },
                                }))
                            },
                        }}
                    />
                ))
                .with({ type: FieldType.Select }, select => (
                    <SelectSubFields
                        constraints={select.constraints}
                        options={select.options}
                        update={fn => {
                            setState(prev => ({
                                ...prev,
                                field: {
                                    ...prev.field,
                                    list: {
                                        listOf: fn(select),
                                    },
                                },
                            }))
                        }}
                        constraintsLocked={field.constraintsLocked}
                    />
                ))
                .with({ type: FieldType.Currency }, currency => (
                    <CurrencySubFields
                        defaultValue={{
                            code: {
                                value: currency.defaultValue.currencyCode || '',
                                onChange: value => {
                                    setState(prev => ({
                                        ...prev,
                                        field: {
                                            ...prev.field,
                                            list: {
                                                listOf: {
                                                    ...currency,
                                                    defaultValue: {
                                                        ...currency.defaultValue,
                                                        currencyCode: value,
                                                    },
                                                },
                                            },
                                        },
                                    }))
                                },
                            },
                            amount: {
                                value: currency.defaultValue?.amount || '',
                                onChange: value => {
                                    setState(prev => ({
                                        ...prev,
                                        field: {
                                            ...prev.field,
                                            list: {
                                                listOf: {
                                                    ...currency,
                                                    defaultValue: {
                                                        ...currency.defaultValue,
                                                        amount: value,
                                                    },
                                                },
                                            },
                                        },
                                    }))
                                },
                            },
                        }}
                        currencyOptions={currencyOptions}
                    />
                ))
                .with({ type: FieldType.Date }, dateField => (
                    <DateSubFields
                        precision={{
                            value: dateField.precision ?? undefined,
                            onChange: precision => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...dateField,
                                                precision,
                                            },
                                        },
                                    },
                                }))
                            },
                        }}
                        dateRange={{
                            constraint:
                                dateField.constraints.dateRange ?? undefined,
                            onSetStart: date => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: updateDateRangeStart(
                                                date,
                                                dateField
                                            ),
                                        },
                                    },
                                }))
                            },
                            onSetEnd: date => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: updateDateRangeEnd(
                                                date,
                                                dateField
                                            ),
                                        },
                                    },
                                }))
                            },
                            onClear: () => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: clearDateRange(dateField),
                                        },
                                    },
                                }))
                            },
                        }}
                        defaultValue={{
                            value: dateField.defaultValue ?? undefined,
                            onClear: () => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...dateField,
                                                defaultValue: undefined,
                                            },
                                        },
                                    },
                                }))
                            },
                            onSetSpecifc: date => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: updateDefaultSpecificDate(
                                                date,
                                                dateField
                                            ),
                                        },
                                    },
                                }))
                            },
                            onSetRelative: (value, unit) => {
                                if (!value || !unit) return
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: updateDefaultRelativeDate(
                                                value,
                                                unit,
                                                dateField
                                            ),
                                        },
                                    },
                                }))
                            },
                        }}
                        constraintsLocked={field.constraintsLocked}
                    />
                ))
                .with({ type: FieldType.Relation }, relation => {
                    const relationFieldValdiation =
                        'type' in state.validation.listOf &&
                        state.validation.listOf.type === FieldType.Relation
                            ? state.validation.listOf
                            : undefined

                    return relationFieldValdiation ? (
                        <RelationSubFields
                            dataset={{
                                value: relation.constraints.relationType,
                                onChange: value => {
                                    const selected = relationOptions.find(
                                        ({ value: id }) => id === value
                                    )
                                    setState(prev => {
                                        const next = selected && {
                                            ...relation,
                                            constraints: {
                                                ...relation.constraints,
                                                relationType: {
                                                    id: selected.value,
                                                    name: selected.text,
                                                },
                                            },
                                        }
                                        return next
                                            ? {
                                                  ...prev,
                                                  field: {
                                                      ...prev.field,
                                                      list: {
                                                          listOf: next,
                                                      },
                                                  },
                                                  validation: {
                                                      ...prev.validation,
                                                      list: {
                                                          listOf: {
                                                              relationType:
                                                                  validateRelationType(
                                                                      next
                                                                  ),
                                                          },
                                                      },
                                                  },
                                              }
                                            : prev
                                    })
                                },
                            }}
                            relationOptions={relationOptions}
                            validation={relationFieldValdiation}
                            constraintsLocked={field.constraintsLocked}
                        />
                    ) : null
                })
                .with({ type: FieldType.User }, user => (
                    <UserSubFields
                        defaultValue={{
                            value: user.defaultValue ?? '',
                            onChange: value => {
                                setState(prev => ({
                                    ...prev,
                                    field: {
                                        ...prev.field,
                                        list: {
                                            listOf: {
                                                ...user,
                                                defaultValue: value,
                                            },
                                        },
                                    },
                                }))
                            },
                        }}
                        userOptions={userOptions}
                    />
                ))
                .otherwise(() => null)}
        </Content>
    )
}

const StyledDisplayOnly = styled.div`
    color: ${({ theme }) => theme.palette.text['support03'].normal};
`

export { ListField }
