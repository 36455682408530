import { createGlobalStyle } from 'styled-components'
import { Theme } from './themes'
import { breakpoints } from './utils'

// Reset taken from https://www.joshwcomeau.com/css/custom-css-reset/

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
    :root {
        --safe-width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
        --safe-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
    
    // Use a more-intuitive box-sizing model.
    *, *::before, *::after {
        box-sizing: border-box;
    }

    // Remove default margin
    // Add global font
    * {
        margin: 0;
        font-family: ${({ theme }) => theme.typography.fontFamily};
    }

    // Allow percentage-based heights in the application
    html, body {
        height: 100%;
        overscroll-behavior: none;
    }

    // Scale down app at smaller desktop screen sizes
    @media screen and (min-width: ${breakpoints.medium}px) {
        html {
            font-size: clamp(0.75rem, 0.4643rem + 0.4762vw, 1rem);
        }
    }

    /*
    Add accessible line-height
    Improve text rendering
    */
    body {
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
    }

    // Improve media defaults
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }


    // Remove built-in form typography styles
    input, button, textarea, select {
        font: inherit;
    }

    a {
        text-decoration: none;
        color: inherit;
    }


    // Avoid text overflows
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    // Remove deault list styles
    ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    menu {
        padding: 0;
        margin: 0;
    }

    // Create a root stacking context
    #root, #drawers, #portals, #toasts {
        position: relative;
        isolation: isolate;
    }

    #root {
        z-index: 1;
    }
    
    #drawers {
        z-index: 2;
    }
  
    #portals {
        z-index: 3;
    }
    
    #toasts {
        z-index: 4;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
    }

    // Override default focus styles
    *:focus {
        outline: none;
    }

    *:focus-visible {
        box-shadow: inset 0 0 0 0.125rem ${({ theme }) =>
            theme.palette.brand['01'].normal};
    }

    // Override default input styles
    input {
        background: none;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        border: none;
        padding: 0;
    }

    kbd {
        font-family: monospace, monospace;
    }
`
