import * as Types from '../../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PossibleRelationTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PossibleRelationTypesQuery = { __typename?: 'Query', businessObjectDefinitions: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }> };


export const PossibleRelationTypesDocument = gql`
    query PossibleRelationTypes {
  businessObjectDefinitions {
    id
    name
  }
}
    `;

/**
 * __usePossibleRelationTypesQuery__
 *
 * To run a query within a React component, call `usePossibleRelationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibleRelationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibleRelationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePossibleRelationTypesQuery(baseOptions?: Apollo.QueryHookOptions<PossibleRelationTypesQuery, PossibleRelationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PossibleRelationTypesQuery, PossibleRelationTypesQueryVariables>(PossibleRelationTypesDocument, options);
      }
export function usePossibleRelationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibleRelationTypesQuery, PossibleRelationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PossibleRelationTypesQuery, PossibleRelationTypesQueryVariables>(PossibleRelationTypesDocument, options);
        }
export type PossibleRelationTypesQueryHookResult = ReturnType<typeof usePossibleRelationTypesQuery>;
export type PossibleRelationTypesLazyQueryHookResult = ReturnType<typeof usePossibleRelationTypesLazyQuery>;
export type PossibleRelationTypesQueryResult = Apollo.QueryResult<PossibleRelationTypesQuery, PossibleRelationTypesQueryVariables>;