import { flex, styled } from 'Adapters/Freestyled'
import { logger } from 'Adapters/Logger'
import { CenteredSpinner } from 'Components/Spinner'
import { FC } from 'react'
import { HistoryEntryTimeline } from './HistoryEntryTimeline'
import { useBusinessObjectHistoryQuery } from './__generated__/BusinessObjectHistory'

type Props = {
    businessObjectId: string
    className?: string
}

export const History: FC<Props> = ({ businessObjectId, className = '' }) => {
    const { data } = useBusinessObjectHistoryQuery({
        variables: { input: { businessObjectId } },
        fetchPolicy: 'cache-and-network',
        onError(error) {
            logger.error('Failed to fetch business object history', error, {
                businessObjectId,
            })
        },
    })

    if (!data) return <CenteredSpinner />

    return (
        <div className={className}>
            <StyledHistory>
                <StyledConnectingLine />
                <HistoryEntryTimeline
                    businessObjectHistory={data.businessObjectHistory}
                />
            </StyledHistory>
        </div>
    )
}

const StyledHistory = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};
    gap: 1.5rem;
    overflow: auto;
    position: relative;
    padding-bottom: 2rem;
`

const StyledConnectingLine = styled.div`
    position: absolute;
    height: calc(100% - 4rem);
    width: 2px;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    z-index: 1;
    margin-left: 1.2rem;
`
