import { isNull, isUndefined, omit } from 'lodash'

export type NoTypename<T> = T extends object
    ? { [K in keyof T]: K extends '__typename' ? never : T[K] }
    : T extends Array<any>
    ? Array<Omit<T, '__typename'>>
    : Omit<T, '__typename'>

export const stripTypenameFields = <T extends { __typename?: string }>(
    obj: T | T[] | undefined
): NoTypename<T> => {
    if (isUndefined(obj) || isNull(obj)) {
        return obj as unknown as NoTypename<T>
    }
    if (['string', 'boolean', 'number'].includes(typeof obj)) {
        return obj as unknown as NoTypename<T>
    }

    if (Array.isArray(obj)) {
        return obj.map(stripTypenameFields) as unknown as NoTypename<T>
    }

    return Object.fromEntries(
        Object.entries(omit(obj, '__typename')).map(([key, val]) => [
            key,
            stripTypenameFields(val as any),
        ])
    ) as unknown as NoTypename<T>
}
