import { identity } from 'lodash'
import { always } from 'lodash/fp'
import { match } from 'ts-pattern'
import { Operator, ValueSelectorType } from '../../__generated__'
import { Comparator, ComparatorState } from './types'

export type ValidatedComparator =
    | {
          valid: false
          reason: string
      }
    | {
          valid: true
          comparator: Comparator
      }

export const stateComparatorToValidated = ({
    operator,
    with: withValue,
    valueSelector,
    negate,
}: Partial<ComparatorState>): ValidatedComparator => {
    if (!valueSelector?.fieldId) {
        return {
            valid: false,
            reason: 'No field selected',
        }
    }

    if (!operator) {
        return {
            valid: false,
            reason: 'No operator selected',
        }
    }

    if (withValue === undefined && operator !== Operator.IsDefined) {
        return {
            valid: false,
            reason: 'No value selected',
        }
    }

    const splitFieldId = valueSelector.fieldId.split('___')

    if (splitFieldId.length === 1) {
        return {
            valid: true,
            comparator: {
                operator,
                with: JSON.stringify(withValue) || '{}',
                negate: negate ?? false,
                valueSelector: {
                    type: ValueSelectorType.FieldValue,
                    fieldId: splitFieldId[0],
                },
            },
        }
    } else {
        return {
            valid: true,
            comparator: {
                operator,
                with: JSON.stringify(withValue) || '{}',
                negate: negate ?? false,
                valueSelector: {
                    type: ValueSelectorType.Deep,
                    selectors: splitFieldId.map(fieldId => ({
                        type: ValueSelectorType.FieldValue,
                        fieldId,
                    })),
                },
            },
        }
    }
}

export const initialComparatorToState = (initComparator: Comparator) =>
    match(initComparator)
        .with(
            {
                valueSelector: {
                    type: ValueSelectorType.FieldValue,
                },
            },
            identity
        )
        .with(
            {
                valueSelector: {
                    type: ValueSelectorType.Deep,
                },
            },
            comparator => {
                return {
                    ...comparator,
                    valueSelector: {
                        fieldId: comparator.valueSelector.selectors
                            .map(s =>
                                s.type === ValueSelectorType.FieldValue
                                    ? s.fieldId
                                    : ''
                            )
                            .join('___'),
                    },
                }
            }
        )
        .with(
            {
                valueSelector: {
                    type: ValueSelectorType.Constant,
                },
            },
            always(undefined)
        )
        .exhaustive()
