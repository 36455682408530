import { dayjs } from 'Adapters/DayJS'
import { styled, useCurrentTheme } from 'Adapters/Freestyled'
import { Avatar } from 'Components/Avatar'
import { IconTextButton } from 'Components/Button'
import { GridTable } from 'Components/GridTable'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import * as principal from 'Features/Principal'
import { UpdateActionModal } from 'Features/UpdateActionModal'
import { QueryActionsInput } from '__generated__'
import { noop } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { P, match } from 'ts-pattern'
import { CompletionCell } from './CompletionCell'
import { CreateActionMenu } from './CreateActionMenu'
import { ActionsProcessDisplay, useProcessDisplay } from './ProcessDisplay'
import { RichTextCell } from './RichTextCell/RichTextCell'
import { ActionsTableItemFragment } from './__generated__/q'
import { getExtensionCell } from './getExtensionCell'
import { useCols } from './useCols'

type Props = {
    actions: ActionsTableItemFragment[]
    className?: string
    tableClassName?: string
    relatesTo?: QueryActionsInput['relatesTo']
    includeMenu?: boolean
}

const ActionsTable: FC<Props> = ({
    actions,
    className = '',
    tableClassName = '',
    relatesTo,
    includeMenu = false,
}) => {
    const { theme } = useCurrentTheme()
    const [updateActionId, setUpdateActionId] = useState<string>()
    const { columns, sortedActions } = useCols(actions)
    const boDisplayProps = useBusinessObjectDisplay({
        key: 'actions-table-object-display',
    })
    const processDisplayProps = useProcessDisplay()

    const groups = useMemo(
        () => [
            {
                label: '',
                colorConfig: {
                    headerBackground: 'transparent',
                    label: 'transparent',
                    background: theme.palette.ui['01'].normal,
                    backgroundHover: theme.palette.ui['01'].hover,
                    backgroundActive: theme.palette.ui['01'].active,
                    borderTop: theme.palette.ui['04'].normal,
                    borderRight: theme.palette.ui['03'].normal,
                },
                rows: sortedActions.map(action => ({
                    cells: {
                        completed: {
                            element: <CompletionCell action={action} />,
                            stopPropagation: true,
                        },
                        assignedTo: {
                            element: action.assignedTo && (
                                <StyledAvatarCell>
                                    <Avatar
                                        size="xSmall"
                                        name={principal.helpers.name(
                                            action.assignedTo
                                        )}
                                    />
                                    <p>
                                        <principal.PrincipalName
                                            principal={action.assignedTo}
                                        />
                                    </p>
                                </StyledAvatarCell>
                            ),
                        },
                        dueDate: {
                            element:
                                action.dueDate &&
                                dayjs(action.dueDate).format('DD MMM YYYY'),
                        },
                        name: {
                            element: action.name,
                        },
                        description: {
                            element: (
                                <RichTextCell
                                    actionId={action.id}
                                    richText={action.description ?? undefined}
                                />
                            ),
                        },
                        createdBy: {
                            element: (
                                <StyledAvatarCell>
                                    <Avatar
                                        size="xSmall"
                                        name={principal.helpers.name(
                                            action.createdBy
                                        )}
                                    />
                                    <p>
                                        <principal.PrincipalName
                                            principal={action.createdBy}
                                        />
                                    </p>
                                </StyledAvatarCell>
                            ),
                        },
                        businessObject: {
                            element: match(action.relatesTo?.businessObject)
                                .with(
                                    { id: P.string, label: P.string },
                                    ({ id, label }) => (
                                        <IconTextButton
                                            icon="Cube"
                                            text={label}
                                            onClick={() =>
                                                boDisplayProps.open({
                                                    id,
                                                    tab: 'details',
                                                })
                                            }
                                        />
                                    )
                                )
                                .otherwise(() => undefined),
                            stopPropagation: !!action.relatesTo?.businessObject,
                        },
                        process: match(action.relatesTo)
                            .with(
                                {
                                    __typename: 'PhaseCriterionActionRelatesTo',
                                },
                                ({ process }) => ({
                                    element: (
                                        <IconTextButton
                                            icon="Cube"
                                            text={process.name}
                                            onClick={() =>
                                                processDisplayProps.open(
                                                    process.id
                                                )
                                            }
                                        />
                                    ),
                                    stopPropagation: true,
                                })
                            )
                            .otherwise(() => ({ element: undefined })),
                        ...action.extendedBy?.fields?.reduce(
                            (acc, field) => ({
                                ...acc,
                                [field.fieldDefinition.name]: getExtensionCell(
                                    field,
                                    action
                                ),
                            }),
                            {}
                        ),
                    },
                    onClick: () => setUpdateActionId(action.id),
                })),
            },
        ],
        [boDisplayProps, processDisplayProps, sortedActions, theme.palette.ui]
    )

    return (
        <Styled className={className}>
            {includeMenu ? (
                <CreateActionMenu relatesTo={relatesTo ?? undefined} />
            ) : null}

            <GridTable
                className={tableClassName}
                columns={columns}
                groups={groups}
            />
            <UpdateActionModal
                open={!!updateActionId}
                closeModal={() => setUpdateActionId(undefined)}
                onOpenChange={open => !open && setUpdateActionId(undefined)}
                returnFocus={noop}
                actionId={updateActionId || ''}
            />
            <BusinessObjectDisplay {...boDisplayProps} />
            <ActionsProcessDisplay {...processDisplayProps} />
        </Styled>
    )
}

const Styled = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    overflow: auto;
`

const StyledAvatarCell = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
`

export { ActionsTable }
