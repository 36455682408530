import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { State } from '../useCreator'
import { GettingStartedCard } from './Card'
import { GettingStartedSection } from './Section'

type Props = {
    definitions: State['definitions']
    onDefinitionSelected: (definition: State['definitions'][number]) => void
    restart: () => void
}

const Definitions: FC<Props> = ({
    definitions,
    onDefinitionSelected,
    restart,
}) => (
    <Styled
        heading="Copy from an existing dataset"
        description="We will clone the selected dataset, allowing you to make changes to the copy without affecting the original."
    >
        <div>
            {definitions.length ? (
                <ul>
                    {definitions.map(definition => (
                        <li key={definition.definition.name}>
                            <GettingStartedCard
                                name={definition.definition.name}
                                description={definition.definition.description}
                                onClick={() => onDefinitionSelected(definition)}
                                fieldCount={definition.definition.fields.length}
                                updates={definition.enableUpdates}
                                documents={definition.enableDocuments}
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <StyledEmpty>
                    <Text as="p" variant="regular-5">
                        Once you have created a dataset it will appear here,
                        allowing you to clone it as the basis for a new dataset.
                    </Text>
                </StyledEmpty>
            )}
        </div>
        <footer>
            <IconTextButton
                icon="ArrowLeft"
                text="Go back"
                onClick={restart}
                variant="secondary"
            />
        </footer>
    </Styled>
)

const Styled = styled(GettingStartedSection)`
    display: grid;
    grid-template: auto 1fr / 1fr;
    height: 80%;

    > div {
        height: 100%;
        overflow: auto;
        padding: 1rem;
        background-color: ${({ theme }) => theme.palette.ui['03'].normal};
        box-shadow: inset 0 0 1rem -0.5rem ${({ theme }) => theme.palette.ui['04'].normal};
    }

    > div > ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    > footer {
        ${flex('row', 'flex-end', 'center')};
        padding: 1rem 2rem;
    }
`

const StyledEmpty = styled.div`
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }
`

export { Definitions }
