import { ApolloProvider } from 'Adapters/Apollo'
import { ToastProvider } from 'Components/Toast'
import { TooltipProvider } from 'Components/Tooltip'
import { SupportProvider } from 'Features/Support'
import React, { FC } from 'react'
import { AppContent } from './AppContent'
import { UserSetup } from './UserSetup'

type Props = {
    getSandboxToken?: () => Promise<string>
    isFirstVisit?: boolean
}

export const App: FC<Props> = React.memo(
    ({ getSandboxToken, isFirstVisit }) => (
        <ApolloProvider getSandboxToken={getSandboxToken}>
            <TooltipProvider>
                <ToastProvider>
                    <UserSetup isFirstVisit={isFirstVisit}>
                        <SupportProvider />
                        <AppContent />
                    </UserSetup>
                </ToastProvider>
            </TooltipProvider>
        </ApolloProvider>
    )
)
