import _styled, {
    css as _css,
    ThemedBaseStyledInterface,
    ThemedCssFunction,
} from 'styled-components'
import { StyleProvider } from './StyleProvider'
import { lightTheme, Theme } from './themes'

const styled = _styled as ThemedBaseStyledInterface<Theme>
const css = _css as ThemedCssFunction<Theme>

export { useCurrentTheme } from './useCurrentTheme'
export * from './utils'
export { css, lightTheme, styled, StyleProvider }
