import { FieldType } from '__generated__'

export const fieldLabels = {
    currency: 'Currency',
    date: 'Date',
    document: 'Document',
    email: 'Email',
    list: 'List',
    number: 'Numeric',
    relation: 'Relation',
    select: 'Select',
    boolean: 'Switch',
    telephone: 'Telephone',
    text: 'Text',
    url: 'URL',
    user: 'User',
    updates: 'Updates',
} satisfies Record<FieldType, string>

// Custom-modelled fields - these fields are set using custom logic
export const customModelledFields: Set<FieldType> = new Set([FieldType.Updates])
