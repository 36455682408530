import { MutationHookOptions } from '@apollo/client'
import { resetCache } from 'Adapters/Apollo'
import { logger } from 'Adapters/Logger'
import { useMutationStatus } from 'Hooks/useMutationStatus'
import { useCallback } from 'react'
import { SetIdentityInput } from '../../__generated__'
import { useSetIdentityMutation } from './__generated__/SetIdentity'

export const useSetIdentity = ({
    client,
}: {
    client?: MutationHookOptions['client']
}) => {
    const [updateIdentity, mutationData] = useSetIdentityMutation({
        onCompleted: async () => {
            await resetCache()
        },
        onError: e => {
            logger.error('Failed to update identity', e)
        },
        client,
    })

    const { status } = useMutationStatus(mutationData)

    const setIdentity = useCallback(
        (input: SetIdentityInput) => {
            return updateIdentity({
                variables: {
                    input,
                },
            })
        },
        [updateIdentity]
    )

    return {
        setIdentity,
        status,
    }
}
