import { flex, styled } from 'Adapters/Freestyled'
import { IconTextButton } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import dayjs from 'dayjs'
import { useModalControls } from 'Hooks'
import { FC } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useDateConstraints } from './useDateConstraints'

export const DateField: FC<FieldInputProps<'DateFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useDateConstraints({
        dateConstraints: field.dateConstraints,
    })

    const { buttonRef, open, setOpen, returnFocus, openModal, closeModal } =
        useModalControls()

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledButtons>
                <StyledButton
                    icon="Calendar"
                    text={
                        value
                            ? dayjs(value).format('DD MMM YYYY')
                            : 'Add a date'
                    }
                    type="button"
                    onClick={openModal}
                    ref={buttonRef}
                    variant="secondary"
                />

                <StyledButton
                    icon={'Close'}
                    text={'Clear'}
                    onClick={e => {
                        e.preventDefault()
                        onChange(undefined)
                    }}
                    variant="secondary"
                />
            </StyledButtons>

            <DatePicker
                open={open}
                onOpenChange={setOpen}
                title={`Choose a date`}
                description={`Choose a date for ${field.name}`}
                onConfirm={({ date }) => {
                    const dateString = date.toISOString()
                    onChange(dateString)
                    closeModal()
                }}
                returnFocus={returnFocus}
                limit={{
                    from: constraints.startAfter
                        ? new Date(constraints.startAfter)
                        : undefined,
                    to: constraints.endBefore
                        ? new Date(constraints.endBefore)
                        : undefined,
                }}
            />
        </Field>
    )
}

const StyledButtons = styled.div`
    ${flex('row', 'flex-start', 'center')};
    width: 100%;
    gap: 1rem;

    button:first-of-type {
        flex: 1;
    }
`

const StyledButton = styled(IconTextButton)`
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
`
