import { css, flex, styled } from 'Adapters/Freestyled'
import { DocumentMetadata, useFetchAllDocuments } from 'Components/Documents'
import { CenteredSpinner } from 'Components/Spinner'
import { FC } from 'react'
import { SelectMode } from '../types'
import { Documents } from './Documents'
import { Footer } from './Footer'
import { MenuBar } from './MenuBar'
import { useFilter } from './useFilter'
import { useFuzzySearch } from './useFuzzySearch'
import { useSelections } from './useSelections'
import { useSort } from './useSort'
import { useViewMode } from './useViewMode'

type Props = {
    initialSelections: string[]
    onConfirm: (selections: DocumentMetadata[]) => void
    selectMode: SelectMode
}

export const DocumentPickerContent: FC<Props> = ({
    onConfirm,
    selectMode,
    initialSelections,
}) => {
    const { viewMode, changeViewMode } = useViewMode()

    const { documents, hasInitiallyLoaded } = useFetchAllDocuments()

    const { selections, toggleSelection, clearSelections } = useSelections({
        selectMode,
        documents,
        initialSelections,
    })

    const {
        filteredDocuments,
        toggleFileTypeFilter,
        availableFileTypes,
        fileTypeFilter,
    } = useFilter(documents)

    const {
        documentSearchResults,
        searchTerms,
        updateSearchTerms,
        clearSearchTerms,
    } = useFuzzySearch(filteredDocuments)

    const { sort, updateSort, sortedDocuments } = useSort(documentSearchResults)

    return hasInitiallyLoaded ? (
        <StyledDocumentPickerContent>
            <MenuBar
                viewMode={viewMode}
                changeViewMode={changeViewMode}
                searchTerms={searchTerms}
                updateSearchTerms={updateSearchTerms}
                clearSearchTerms={clearSearchTerms}
                sort={sort}
                updateSort={updateSort}
                toggleSelection={toggleSelection}
                toggleFileTypeFilter={toggleFileTypeFilter}
                availableFileTypes={availableFileTypes}
                fileTypeFilter={fileTypeFilter}
            />

            <Documents
                viewMode={viewMode}
                documents={sortedDocuments}
                selections={selections}
                toggleSelection={toggleSelection}
            />

            <Footer
                handleConfirm={() => {
                    onConfirm(Array.from(selections.values()))
                }}
                clearSelections={clearSelections}
                noOfSelections={selections.size}
            />
        </StyledDocumentPickerContent>
    ) : (
        <StyledSpinnerContainer>
            <CenteredSpinner />
        </StyledSpinnerContainer>
    )
}

const modalSize = css`
    width: calc(100vw - 6rem);
    height: calc(100dvh - 8rem);
`

const StyledDocumentPickerContent = styled.section`
    ${modalSize};
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
`

const StyledSpinnerContainer = styled.section`
    ${modalSize};
    ${flex('column', 'center', 'center')};
`
