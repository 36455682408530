import { flex, styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'

export type File = {
    id: string
    name: string
    url: string
    fileType: string
}

export type Attachment = {
    type: 'file'
    file: File
}

export type Props = {
    attachments: Attachment[]
}

export const Attachments: React.FC<Props> = ({ attachments }) => {
    if (!attachments.length) return null

    return (
        <StyledAttachments>
            <Text as="h4" variant="regular-5">
                Attachments:
            </Text>

            <ul>
                {attachments.map(attachment => (
                    <AttachmentDisplay
                        key={attachment.file.id}
                        file={attachment.file}
                    />
                ))}
            </ul>
        </StyledAttachments>
    )
}

const AttachmentDisplay: React.FC<{ file: File }> = ({ file }) => {
    return (
        <StyledAttachmentListItem>
            <button
                type="button"
                onClick={() => window.open(file.url, '_blank')}
            >
                <Icon name="Document" />
                <Text as="span" variant="regular-6">
                    {file.name}
                </Text>
            </button>
        </StyledAttachmentListItem>
    )
}

const StyledAttachments = styled.footer`
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    padding: 0.5rem 1rem;

    > h4 {
        color: ${({ theme }) => theme.palette.text['02'].normal};
        width: fit-content;
    }

    ul {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.75rem;
        flex-wrap: wrap;
    }
`

const StyledAttachmentListItem = styled.li`
    > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        svg {
            width: 0.3rem;
        }
    }
`
