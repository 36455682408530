import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog'
import { styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { FC } from 'react'
import { keyframes } from 'styled-components'

type Props = {
    content: JSX.Element
    open: boolean
    onOpenChange: (open: boolean) => void
    // Because this modal is controlled, we need to provide
    // a function that returns focus to the button that opened the modal
    returnFocus: () => void
}

const Drawer: FC<Props> = ({ content, open, onOpenChange, returnFocus }) => (
    <Root modal open={open} onOpenChange={onOpenChange}>
        <Portal container={globalThis.document.getElementById('drawers')}>
            <StyledOverlay
                className="drawer-overlay"
                onClick={e => e.stopPropagation()}
            >
                <StyledContent
                    className="drawer-content"
                    onCloseAutoFocus={e => {
                        e.preventDefault()
                        returnFocus()
                    }}
                >
                    <IconButton
                        iconName="AngleLeft"
                        onClick={() => onOpenChange(false)}
                    />
                    {content}
                </StyledContent>
            </StyledOverlay>
        </Portal>
    </Root>
)

const overlayEnter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const overlayLeave = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const StyledOverlay = styled(Overlay)`
    position: fixed;
    width: ${({ theme }) => theme.structure.safeWidth};
    height: ${({ theme }) => theme.structure.safeHeight};
    top: env(safe-area-inset-top);
    left: env(safe-area-inset-left);
    background: ${({ theme }) => theme.palette.overlay['01'].normal};
    cursor: pointer;
    animation-duration: ${({ theme }) => theme.animation.mid};
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    &[data-state='open'] {
        animation-name: ${overlayEnter};
    }

    &[data-state='closed'] {
        animation-name: ${overlayLeave};
    }

    &:nth-child(2n) > div {
        width: calc(${({ theme }) => theme.structure.safeWidth} - 9rem);
    }
    &:nth-child(3n) > div {
        width: calc(${({ theme }) => theme.structure.safeWidth} - 12rem);
    }
    &:nth-child(4n) > div {
        width: calc(${({ theme }) => theme.structure.safeWidth} - 15rem);
    }
`

const contentLargeEnter = keyframes`
    0% {
        opacity: 0;
        transform: translateX(30vw);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    `

const contentLargeLeave = keyframes`
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(30vw);
    }
`

const contentSmallEnter = keyframes`
    0% {
        opacity: 0;
        transform: translateY(30vw);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    `

const contentSmallLeave = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(30vw);
    }
`

export const StyledContent = styled(Content)`
    cursor: default;
    position: fixed;
    background-color: ${({ theme }) => theme.palette.ui['01'].normal};
    box-shadow: 0 0 0.5rem 0
        ${({ theme }) => theme.palette.overlay['01'].normal};
    animation-duration: ${({ theme }) => theme.animation.mid};
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    display: flex;

    [data-screen-size='large'] & {
        width: calc(${({ theme }) => theme.structure.safeWidth} - 6rem);
        height: ${({ theme }) => theme.structure.safeHeight};
        top: env(safe-area-inset-top);
        right: env(safe-area-inset-right);
        border-radius: 0.5rem 0 0 0.5rem;
        flex-direction: row;

        &[data-state='open'] {
            animation-name: ${contentLargeEnter};
        }

        &[data-state='closed'] {
            animation-name: ${contentLargeLeave};
        }

        > :first-child {
            height: 100%;
            width: 3rem;
            border-radius: 0.5rem 0 0 5rem;
            border-right: 1px solid
                ${({ theme }) => theme.palette.ui['03'].normal};
        }

        > :last-child {
            flex: 1;
            overflow: hidden;
        }
    }

    [data-screen-size='small'] &,
    [data-screen-size='medium'] & {
        width: ${({ theme }) => theme.structure.safeWidth};
        height: calc(${({ theme }) => theme.structure.safeHeight} - 1rem);
        bottom: env(safe-area-inset-bottom);
        left: env(safe-area-inset-left);
        border-radius: 0.5rem 0.5rem 0 0;
        flex-direction: column;

        &[data-state='open'] {
            animation-name: ${contentSmallEnter};
        }

        &[data-state='closed'] {
            animation-name: ${contentSmallLeave};
        }

        > :first-child {
            height: 2rem;
            width: 100%;
            border-radius: 1.25rem 1.25rem 0 0;
            border-bottom: 1px solid
                ${({ theme }) => theme.palette.ui['03'].normal};
        }

        > :first-child svg {
            transform: rotateZ(270deg);
        }

        > :first-child svg path {
            fill: ${({ theme }) => theme.palette.icon['02'].normal};
        }

        > :last-child {
            flex: 1;
            overflow: hidden;
        }
    }
`

export { Drawer }
