import { styled } from 'Adapters/Freestyled'
import { Select } from 'Components/Select'
import { bold4 } from 'Components/Text'
import {
    RelationField as Field,
    ListOfRelationFieldValidation,
    RelationFieldValidation,
    validateRelationType,
} from 'Utils/BusinessObjectDefinition'
import { FC, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'
import { InputWrapper } from './InputWrapper'

type Props = {
    field: Field
    validation: RelationFieldValidation
    onSubmit: OnSubmit<Field, RelationFieldValidation>
    onClose: () => void
    relationOptions: {
        value: string
        text: string
    }[]

    hideRequired: boolean
}

const RelationField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,
    relationOptions,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, RelationFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <RelationSubFields
                dataset={{
                    value: state.field.constraints.relationType,
                    onChange: value => {
                        const selected = relationOptions.find(
                            ({ value: id }) => id === value
                        )
                        setState(prev => {
                            const field = selected && {
                                ...prev.field,
                                constraints: {
                                    ...prev.field.constraints,
                                    relationType: {
                                        id: selected.value,
                                        name: selected.text,
                                    },
                                },
                            }
                            return field
                                ? {
                                      ...prev,
                                      field,
                                      validation: {
                                          ...prev.validation,
                                          relation: {
                                              relationType:
                                                  validateRelationType(field),
                                          },
                                      },
                                  }
                                : prev
                        })
                    },
                }}
                relationOptions={relationOptions}
                validation={state.validation}
                constraintsLocked={field.constraintsLocked}
            />
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

type SubFieldsProps = {
    dataset: {
        value: {
            id: string
            name: string
        }
        onChange: (value: string) => void
    }
    relationOptions: {
        value: string
        text: string
    }[]
    validation: RelationFieldValidation | ListOfRelationFieldValidation
    constraintsLocked: boolean
}

const RelationSubFields: FC<SubFieldsProps> = ({
    dataset,
    relationOptions,
    validation,
    constraintsLocked,
}) => {
    const { current: datasetFieldId } = useRef(v4())
    const errorMessage =
        'relation' in validation ? validation.relationType.message : undefined
    return (
        <InputWrapper
            label="Dataset"
            fieldId={datasetFieldId}
            description="This will determine which datsaset the field can be related to."
            errorMessage={errorMessage}
            displayOnly={constraintsLocked}
        >
            {constraintsLocked ? (
                <StyledDisplayOnlyDataset>
                    {dataset.value.name}
                </StyledDisplayOnlyDataset>
            ) : (
                <Select
                    id={datasetFieldId}
                    name={datasetFieldId}
                    value={dataset.value.id}
                    onValueChange={dataset.onChange}
                    options={[
                        {
                            text:
                                relationOptions.length < 1
                                    ? '- No datasets available -'
                                    : '- No dataset selected -',
                            value: '',
                        },
                        ...relationOptions,
                    ]}
                    disabled={relationOptions.length < 1}
                    hasError={!!errorMessage}
                />
            )}
        </InputWrapper>
    )
}

const StyledDisplayOnlyDataset = styled.p`
    ${bold4}
    color: ${({ theme }) => theme.palette.text.support03.normal};
`

export { RelationField, RelationSubFields }
