import { Group } from '@radix-ui/react-dropdown-menu'
import { flex, styled } from 'Adapters/Freestyled'
import { IconButton } from 'Components/Button'
import { Checkbox } from 'Components/Checkbox'
import { Dropdown, DropdownCheckbox } from 'Components/Dropdown'
import { RadioGroup } from 'Components/RadioGroup'
import { SearchBar } from 'Components/SearchBar'
import { Text } from 'Components/Text'
import Fuse from 'fuse.js'
import { uniq } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { P, match } from 'ts-pattern'

type Props = {
    options: { id: string; label: string }[]
    selectedValues: string[]
    setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
    mode: 'single' | 'multiple'
    setMode: (mode: 'single' | 'multiple') => void
    includeRemovedRecords: boolean
    setIncludeRemovedRecords: React.Dispatch<React.SetStateAction<boolean>>
    businessObjectDefinitionName: string
}

export const SearchSelect: FC<Props> = ({
    options,
    selectedValues,
    setSelectedValues,
    mode,
    setMode,
    includeRemovedRecords,
    setIncludeRemovedRecords,
    businessObjectDefinitionName,
}) => {
    const [searchTerms, setSearchTerms] = useState('')

    const results = useMemo(
        () =>
            (searchTerms
                ? new Fuse(options, {
                      keys: ['label'],
                      threshold: 0.2,
                  })
                      .search(searchTerms)
                      .map(result => result.item)
                : options
            ).sort((a, b) =>
                a.label > b.label ? 1 : a.label < b.label ? -1 : 0
            ),
        [searchTerms, options]
    )

    useEffect(() => {
        setSearchTerms('')
    }, [options])

    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

    const handleCheckedChanged = useCallback(
        (businessObjectId: string, replace?: true | undefined) =>
            (isChecked: boolean) => {
                setSelectedValues(prev =>
                    match({ isChecked, replace })
                        .with({ isChecked: true, replace: undefined }, () =>
                            uniq([...prev, businessObjectId])
                        )
                        .with({ isChecked: false, replace: undefined }, () =>
                            prev.filter(id => id !== businessObjectId)
                        )
                        .with({ isChecked: P.any, replace: true }, () =>
                            isChecked ? [businessObjectId] : []
                        )
                        .exhaustive()
                )
            },
        [setSelectedValues]
    )

    return (
        <Styled>
            <header>
                <SearchBar
                    searchTerms={searchTerms}
                    placeholderText="Search by label"
                    updateSearchTerms={({ currentTarget: { value } }) =>
                        setSearchTerms(value)
                    }
                    clearSearchTerms={() => setSearchTerms('')}
                />

                <Dropdown
                    variant="light"
                    side="bottom"
                    align="end"
                    open={dropdownMenuOpen}
                    onOpenChange={setDropdownMenuOpen}
                    renderOpenDropdownButton={() => (
                        <StyledIconButton
                            iconName={'More'}
                            variant="secondary"
                        />
                    )}
                    renderContent={() => (
                        <Group>
                            <DropdownCheckbox
                                text={'Add multiple records?'}
                                onCheckedChange={checked => {
                                    setMode(checked ? 'multiple' : 'single')
                                    setSelectedValues(prev =>
                                        !checked && prev.length
                                            ? [prev[0]]
                                            : prev
                                    )
                                }}
                                checked={mode === 'multiple'}
                                closeOnSelect={false}
                            />
                            <DropdownCheckbox
                                text={'Include previously removed records?'}
                                onCheckedChange={checked =>
                                    setIncludeRemovedRecords(!!checked)
                                }
                                checked={includeRemovedRecords}
                                closeOnSelect={false}
                            />
                        </Group>
                    )}
                />
            </header>

            {match({ length: !!results.length, mode })
                .with({ length: false }, () => (
                    <Text as="p" variant="regular-5">
                        {!options.length
                            ? `There are no available ${businessObjectDefinitionName} records, create one using the button below.`
                            : 'There are no results matching your search terms'}
                    </Text>
                ))
                .with({ mode: 'single', length: true }, () => (
                    <RadioGroup
                        value={selectedValues[0]}
                        options={results}
                        onValueChange={id => {
                            handleCheckedChanged(id, true)(true)
                        }}
                        name={'record-to-add'}
                    />
                ))
                .with({ mode: 'multiple', length: true }, () => (
                    <div>
                        {results.map(({ id, label }) => (
                            <Checkbox
                                key={id}
                                htmlFor={id}
                                label={label}
                                name={id}
                                checked={selectedValues.includes(id)}
                                onCheckedChange={handleCheckedChanged(id)}
                                className="checkbox"
                            />
                        ))}
                    </div>
                ))
                .exhaustive()}
        </Styled>
    )
}

const Styled = styled.div`
    width: 100%;

    > header {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.75rem;
        margin-bottom: 0.5rem;
    }

    > div {
        ${flex('column', 'flex-start', 'flex-start')};
        max-height: 60vh;
        overflow: auto;

        .checkbox {
            width: 100%;
        }
    }
`

const StyledIconButton = styled(IconButton)`
    padding: 0.813rem;
`
