import { P, match } from 'ts-pattern'
import {
    BooleanField,
    Definition,
    Field,
    FieldType,
    ListField,
    RelationField,
} from '../types'
import {
    BusinessObjectDefinitionValidation,
    FieldValidation,
    ListOfValidation,
    ValidationStatus,
    Validity,
} from './types'

type ValidateName = (name: string) => {
    status: ValidationStatus
    message: string
}
const validateName: ValidateName = name =>
    match(name.trim())
        .when(
            ({ length }) => length < 1,
            () => ({
                status: ValidationStatus.Invalid,
                message: 'A name is required',
            })
        )
        .when(
            ({ length }) => length > 40,
            () => ({
                status: ValidationStatus.Invalid,
                message: 'A dataset name must be less than 26 characters',
            })
        )
        .otherwise(() => ({
            status: ValidationStatus.Valid,
            message: '',
        }))

type ValidateLabel = (label: string) => {
    status: ValidationStatus
    message: string
}
const validateLabel: ValidateLabel = label =>
    match(label.trim())
        .when(
            ({ length }) => length < 1,
            () => ({
                status: ValidationStatus.Invalid,
                message: 'A label is required',
            })
        )
        .otherwise(() => ({
            status: ValidationStatus.Valid,
            message: '',
        }))

type ValidateRelationType = (
    field: Pick<RelationField, 'type' | 'constraints'>
) => Validity
const validateRelationType: ValidateRelationType = field =>
    match(field)
        .with({ constraints: { relationType: { id: '' } } }, () => ({
            status: ValidationStatus.Invalid,
            message: 'A relation dataset must be selected',
        }))
        .with({ constraints: { relationType: { name: '' } } }, () => ({
            status: ValidationStatus.Invalid,
            message: 'A relation dataset must be selected',
        }))
        .otherwise(() => ({
            status: ValidationStatus.Valid,
            message: '',
        }))

type ValidateBoolean = (boolean: BooleanField) => Validity
const validateBoolean: ValidateBoolean = boolean =>
    match(boolean)
        .with({ defaultValue: P.boolean }, () => ({
            status: ValidationStatus.Valid,
            message: '',
        }))
        .otherwise(() => ({
            status: ValidationStatus.Invalid,
            message:
                'A switch field must have a default value of either true or false',
        }))

// Here we can run and validation for subfields of a selected list type.
// For the most part there is no subfield validation to be done,
// but as we find cases where it could be added they can be added here.
// The ListOfValidation type must be updated to accomodate additional validation information
// in the same way the it accomodates validation information for Relation subfields
type ValidateListOf = (_: {
    list: ListField
    // If pendingSubFields is set to true
    // then subfields will not be validated.
    // This allows for the listOf to be selected without
    // prematurely validating subfields,
    // but allows us to validate subfields when we need to validate
    // the entire field holistically, for example when submitting a field form,
    // or validating an entire business object definition
    pendingSubFields?: boolean
}) => ListOfValidation
const makeGuardPending =
    (pendingSubFields: boolean) =>
    (v: Validity): Validity =>
        pendingSubFields ? { status: ValidationStatus.Pending, message: '' } : v
const validateListOf: ValidateListOf = ({ list, pendingSubFields = false }) => {
    const guardPending = makeGuardPending(pendingSubFields)
    return match(list.listOf)
        .with({ type: FieldType.Relation }, listOf => ({
            type: listOf.type,
            relationType: guardPending(validateRelationType(listOf)),
        }))
        .with({ type: FieldType.Boolean }, bool => ({
            type: bool.type,
            defaultValue: guardPending(
                match(bool.defaultValue)
                    .with(P.boolean, () => ({
                        status: ValidationStatus.Valid,
                        message: '',
                    }))
                    .otherwise(() => ({
                        status: ValidationStatus.Invalid,
                        message:
                            'A switch field must have a default value of either true or false',
                    }))
            ),
        }))
        .with({ type: FieldType.Text }, ({ type }) => ({ type }))
        .with({ type: FieldType.Number }, ({ type }) => ({ type }))
        .with({ type: FieldType.Telephone }, ({ type }) => ({ type }))
        .with({ type: FieldType.Email }, ({ type }) => ({ type }))
        .with({ type: FieldType.Document }, ({ type }) => ({ type }))
        .with({ type: FieldType.Currency }, ({ type }) => ({ type }))
        .with({ type: FieldType.User }, ({ type }) => ({ type }))
        .with({ type: FieldType.Url }, ({ type }) => ({ type }))
        .with({ type: FieldType.Date }, ({ type }) => ({ type }))
        .with({ type: FieldType.Select }, ({ type }) => ({ type }))
        .otherwise(() => ({
            status: ValidationStatus.Invalid,
            message: 'List of must have a selection',
        }))
}

type ValidateField = (field: Field) => FieldValidation
const validateField: ValidateField = field => {
    const name = validateName(field.name)
    return match(field)
        .with({ type: FieldType.Relation }, relation => ({
            type: relation.type,
            name,
            relationType: validateRelationType(relation),
        }))
        .with({ type: FieldType.List }, list => ({
            type: list.type,
            name,
            listOf: validateListOf({ list }),
        }))
        .with({ type: FieldType.Boolean }, boolean => ({
            type: boolean.type,
            name,
            defaultValue: validateBoolean(boolean),
        }))
        .with({ type: FieldType.Text }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Currency }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Date }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Document }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Email }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Select }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Telephone }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.User }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Url }, ({ type }) => ({ type, name }))
        .with({ type: FieldType.Number }, ({ type }) => ({ type, name }))
        .exhaustive()
}

type ValidateDefinition = (
    defintion: Definition
) => BusinessObjectDefinitionValidation
const validateDefinition: ValidateDefinition = definition => {
    const name = validateName(definition.name)
    const label = validateLabel(definition.label)
    return {
        name,
        label,
        fields: definition.fields.map(validateField),
    }
}

const isBusinessObjectDefinitionValid = (
    validation: BusinessObjectDefinitionValidation
): boolean =>
    match(validation)
        .with({ name: { status: ValidationStatus.Pending } }, () => false)
        .with({ name: { status: ValidationStatus.Invalid } }, () => false)
        .with({ label: { status: ValidationStatus.Pending } }, () => false)
        .with({ label: { status: ValidationStatus.Invalid } }, () => false)
        .otherwise(({ fields }) =>
            fields.every(isBusinessObjectDefinitionFieldValid)
        )

// I think there is provbabluy a more elegant way to calculate this
// rather than having to articulate each possible invalid state
// but as a first pass I am hoping this is acceptable
const isBusinessObjectDefinitionFieldValid = (
    validation: FieldValidation
): boolean =>
    match(validation)
        .with({ name: { status: ValidationStatus.Pending } }, () => false)
        .with({ name: { status: ValidationStatus.Invalid } }, () => false)
        .with(
            {
                type: FieldType.Relation,
                relationType: { status: ValidationStatus.Pending },
            },
            () => false
        )
        .with(
            {
                type: FieldType.Relation,
                relationType: { status: ValidationStatus.Invalid },
            },
            () => false
        )
        .with(
            {
                type: FieldType.List,
                listOf: { status: ValidationStatus.Pending },
            },
            () => false
        )
        .with(
            {
                type: FieldType.List,
                listOf: { status: ValidationStatus.Invalid },
            },
            () => false
        )
        .with(
            {
                type: FieldType.List,
                listOf: {
                    type: FieldType.Relation,
                    relationType: { status: ValidationStatus.Pending },
                },
            },
            () => false
        )
        .with(
            {
                type: FieldType.List,
                listOf: {
                    type: FieldType.Relation,
                    relationType: { status: ValidationStatus.Invalid },
                },
            },
            () => false
        )
        .otherwise(() => true)

const createPendingFieldValidation = (
    fieldType: FieldType
): FieldValidation => {
    const name = {
        status: ValidationStatus.Pending,
        message: '',
    }
    const std = <T extends FieldType>(
        type: T
    ): { type: T; name: Validity } => ({ type, name })
    return match(fieldType)
        .with(FieldType.Relation, type => ({
            type,
            name,
            relationType: {
                status: ValidationStatus.Pending,
                message: '',
            },
        }))
        .with(FieldType.List, type => ({
            type,
            name,
            listOf: {
                status: ValidationStatus.Pending,
                message: '',
            },
        }))
        .with(FieldType.Boolean, type => ({
            type,
            name,
            defaultValue: {
                status: ValidationStatus.Pending,
                message: '',
            },
        }))
        .with(FieldType.Text, std)
        .with(FieldType.Currency, std)
        .with(FieldType.Date, std)
        .with(FieldType.Document, std)
        .with(FieldType.Email, std)
        .with(FieldType.Select, std)
        .with(FieldType.Telephone, std)
        .with(FieldType.User, std)
        .with(FieldType.Url, std)
        .with(FieldType.Number, std)
        .exhaustive()
}

export {
    createPendingFieldValidation,
    isBusinessObjectDefinitionFieldValid,
    isBusinessObjectDefinitionValid,
    validateDefinition,
    validateField,
    validateLabel,
    validateListOf,
    validateName,
    validateRelationType,
}
