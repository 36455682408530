import { styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { regular5 } from 'Components/Text'
import {
    BusinessObjectDisplay,
    useBusinessObjectDisplay,
} from 'Features/BusinessObjectDisplay'
import { CreateRelationModal } from 'Features/CreateRelationModal'
import { SubFieldCompletionModal } from 'Features/SubFieldCompletionModal'
import {
    BOField,
    BizObjDef_Relation,
    useFetchBusinessObjectsById,
    useModalControls,
} from 'Hooks'
import { first } from 'lodash'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useRelationConstraints } from './useRelationConstraints'

type Props = BOField<'BusinessObjectRelationField'> & {
    businessObjectId: string
    onComplete: () => void
    incompleteRelations?: {
        relationId: string | undefined
        relationFieldId: string
    }[]
}

export const RelationField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    relationValue,
    onComplete,
    incompleteRelations = [],
}) => {
    const { relationConstraints, ...common } =
        fieldDefinition as BizObjDef_Relation

    const { constraints, constraintsTooltip } = useRelationConstraints({
        relationConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const data = useFetchBusinessObjectsById(constraints.relationId)

    const options = useMemo(() => {
        return (
            data?.businessObjects.map(businessObject => {
                return {
                    id: businessObject.id,
                    label: businessObject.label,
                }
            }) ?? []
        )
    }, [data])

    const initialValue = useRef(relationValue?.id ?? null)

    const [selectedOption, setSelectedOption] = useState(initialValue.current)

    const modalControls = useModalControls()

    const boDisplayProps = useBusinessObjectDisplay({
        key: 'relation-display-object-display',
    })

    const openBusinessObjectInDrawer = useCallback(() => {
        if (selectedOption) {
            boDisplayProps.open({ id: selectedOption, tab: 'overview' })
        }
    }, [boDisplayProps, selectedOption])

    const [editingRelation, setEditingRelation] = useState(false)
    const relationId = useMemo(
        () => first(incompleteRelations)?.relationId,
        [incompleteRelations]
    )

    return (
        <>
            <Field
                id={common.id}
                name={common.name}
                required={!!constraints.required}
                constraintsTooltip={constraintsTooltip}
                errors={errors}
            >
                <Dropdown
                    renderContent={() => (
                        <>
                            <DropdownButton
                                text={'-- No Selection --'}
                                onSelect={() => setSelectedOption('')}
                            />
                            {options.map(option => (
                                <DropdownButton
                                    key={option.id}
                                    text={option.label}
                                    onSelect={() =>
                                        setSelectedOption(option.id)
                                    }
                                />
                            ))}
                        </>
                    )}
                    renderOpenDropdownButton={() => (
                        <StyledDropdownButton
                            icon="Cube"
                            text={
                                options.find(
                                    option => option.id === selectedOption
                                )?.label ?? '-- No Selection --'
                            }
                        />
                    )}
                    variant="light"
                />

                <IconButton
                    iconName="AddSmall"
                    title={`Create new ${
                        data?.businessObjects[0]?.definition.name ?? 'Relation'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        modalControls.openModal()
                    }}
                />

                {incompleteRelations.length && relationId ? (
                    <IconTextButton
                        icon={'Cube'}
                        text={`${incompleteRelations.length} missing fields`}
                        onClick={() => setEditingRelation(true)}
                    />
                ) : selectedOption &&
                  selectedOption === initialValue.current ? (
                    <IconTextButton
                        icon={'Cube'}
                        text={'View'}
                        onClick={openBusinessObjectInDrawer}
                    />
                ) : null}

                <EditFieldButton
                    disabled={
                        initialValue.current === selectedOption || loading
                    }
                    onClick={() => {
                        updateBusinessObjectField({
                            field: {
                                [common.type]: {
                                    fieldDefinitionId: common.id,
                                    previousValue: initialValue.current,
                                    value: selectedOption
                                        ? selectedOption
                                        : undefined,
                                },
                            },
                            id: businessObjectId,
                            onSuccess: () => {
                                initialValue.current = selectedOption
                                onComplete()
                            },
                        })
                    }}
                />
            </Field>

            <CreateRelationModal
                businessObjectDefinitionId={constraints.relationId}
                prefilledBusinessObject={{ id: businessObjectId }}
                onBusinessObjectCreated={businessObject => {
                    setSelectedOption(businessObject.id)
                }}
                {...modalControls}
            />

            {relationId && editingRelation ? (
                <SubFieldCompletionModal
                    businessObjectId={relationId}
                    onClose={() => setEditingRelation(false)}
                    includeFields={incompleteRelations.map(
                        f => f.relationFieldId
                    )}
                    onFieldUpdated={onComplete}
                />
            ) : null}

            <BusinessObjectDisplay
                {...boDisplayProps}
                businessObjectDefinitionId={relationId}
            />
        </>
    )
}

const StyledDropdownButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`
