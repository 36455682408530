export const remove =
    <T>(set: Set<T>) =>
    (value: T) => {
        return new Set([...set].filter(i => i !== value))
    }

export const add =
    <T>(set: Set<T>) =>
    (value: T) => {
        return new Set([...set, value])
    }
