import * as Types from '../../../__generated__/index';

import { gql } from '@apollo/client';
export type ProcessCardFragment = { __typename?: 'Process', id: string, name: string, description?: string | null, operatesUpon: Array<{ __typename?: 'BusinessObjectDefinition', id: string, name: string }>, phases: Array<{ __typename?: 'Phase', activeBusinessObjects: { __typename?: 'PhaseBusinessObjects', meta: { __typename?: 'Meta', totalCount: number } } }>, usage: { __typename?: 'ProcessUsage', businessObjects: number } };

export const ProcessCardFragmentDoc = gql`
    fragment ProcessCard on Process {
  id
  name
  description
  operatesUpon {
    id
    name
  }
  phases {
    activeBusinessObjects: businessObjects(input: {excludeCompleted: true}) {
      meta {
        totalCount
      }
    }
  }
  usage {
    businessObjects
  }
}
    `;