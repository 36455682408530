import { TagButton } from 'Components/Button/Tag'
import { Drawer } from 'Components/Drawer'
import { ControlledModal } from 'Components/Modal'
import { FC, useRef, useState } from 'react'
import { SingleProcess } from '../../SingleProcess'
import { ProcessDef_SingleProcessQuery } from '../../__generated__/query'
import { AssignBackstop } from './AssignBackstop'
import { SelectedBackstop } from './SelectedBackstop'

type Props = {
    process: ProcessDef_SingleProcessQuery['process']
}

const Backstop: FC<Props> = ({ process }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const [defOpen, setDefOpen] = useState(false)
    return (
        <>
            <TagButton
                icon="ExitProcess"
                text={
                    process.backstop?.name
                        ? `Backstop: ${process.backstop.name}`
                        : 'No backstop workflow'
                }
                ref={ref}
                onClick={() => setOpen(true)}
            />
            <ControlledModal
                title="Backstop process"
                description={
                    process.backstop
                        ? `When objects are removed from the ${process.name} workflow, they will be automatically sent to the ${process.backstop.name} workflow, which will handle any subsequent actions that need to be taken.`
                        : `When objects are removed from the ${process.name} workflow, they can be automatically sent to a backstop workflow, which can be modelled to handle any subsequent actions that need to be taken.`
                }
                open={open}
                onOpenChange={setOpen}
                renderContent={() =>
                    process.backstop ? (
                        <SelectedBackstop
                            backstop={process.backstop}
                            processId={process.id}
                            onOpenDef={() => {
                                setOpen(false)
                                setDefOpen(true)
                            }}
                            onDone={() => setOpen(false)}
                        />
                    ) : (
                        <AssignBackstop process={process} />
                    )
                }
                returnFocus={() => ref.current?.focus()}
            />
            {process.backstop && (
                <Drawer
                    open={defOpen}
                    onOpenChange={setDefOpen}
                    content={<SingleProcess id={process.backstop.id} />}
                    returnFocus={() => ref.current?.focus()}
                />
            )}
        </>
    )
}

export { Backstop }
