import { match } from 'ts-pattern'
import { FilterableBusinessObjectField } from './comparator'

export const getFieldValue = (field: FilterableBusinessObjectField) => {
    return match(field)
        .with(
            {
                __typename: 'BusinessObjectTextField',
            },
            f => f.textValue
        )
        .with(
            {
                __typename: 'BusinessObjectBooleanField',
            },
            f => f.booleanValue
        )
        .with(
            {
                __typename: 'BusinessObjectDateField',
            },
            f => f.dateValue
        )
        .with(
            {
                __typename: 'BusinessObjectDocumentField',
            },
            f => f.docValue?.id
        )
        .with(
            {
                __typename: 'BusinessObjectEmailField',
            },
            f => f.emailValue
        )
        .with(
            {
                __typename: 'BusinessObjectNumberField',
            },
            f => f.numValue
        )
        .with(
            {
                __typename: 'BusinessObjectRelationField',
            },
            f => f.relationValue?.id
        )
        .with(
            {
                __typename: 'BusinessObjectSelectField',
            },
            f => f.selectValue
        )
        .with(
            {
                __typename: 'BusinessObjectTelephoneField',
            },
            f =>
                f.telephoneValue
                    ? {
                          countryCode: f.telephoneValue.countryCode,
                          number: f.telephoneValue.number,
                      }
                    : undefined
        )
        .with(
            {
                __typename: 'BusinessObjectUrlField',
            },
            f => f.urlValue
        )
        .with(
            {
                __typename: 'BusinessObjectUserField',
            },
            f => f.userValue?.id
        )
        .with(
            {
                __typename: 'BusinessObjectListField',
            },
            f => f.listValue
        )
        .with(
            {
                __typename: 'BusinessObjectUpdatesField',
            },
            f => f.updatesValue
        )
        .with(
            { __typename: 'BusinessObjectCurrencyField' },
            f => f.currencyValue?.amount
        )
        .exhaustive()
}
