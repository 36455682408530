import { flex, styled } from 'Adapters/Freestyled'
import { regular6 } from 'Components/Text'

export const StyledConstraintsTooltip = styled.p`
    ${regular6};
    color: ${({ theme }) => theme.palette.text['02'].normal};
    padding-bottom: 0.25rem;
`

export const StyledErrorMessage = styled.p`
    min-height: 1em;
    color: red;
`

export const StyledInputGroup = styled.div`
    ${flex('column', 'flex-start', 'flex-start')};

    label {
        display: block;
    }

    .toggle {
        margin-top: 0.5rem;
    }

    > div {
        ${flex('row', 'flex-start', 'center')};
        gap: 0.5rem;
        width: 100%;
    }

    > ul {
        ${flex('column', 'flex-start', 'flex-start')};
        gap: 0.5rem;
        width: 100%;
    }
`
