import { styled } from 'Adapters/Freestyled'
import { Icon } from 'Components/Icon'
import { Text } from 'Components/Text'
import { BusinessObjectDefinitionCreator } from 'Features/BusinessObjectDefinitionCreator'
import { FC, useCallback } from 'react'
import { useProcessCreator } from '../useProcessCreator'
import { BODefSelect } from './BODefSelect'

type Props = {
    preselectedBO?: {
        id: string
        name: string
    }
    mode: 'create' | 'edit'
}

const DetailsStep: FC<Props> = ({ preselectedBO, mode }) => {
    const {
        state: { input },
        dispatch,
    } = useProcessCreator()

    const handleSelection = useCallback(
        (id: string) => {
            dispatch({
                type: 'setOperatesUpon',
                payload: { id },
            })
            dispatch({ type: 'showPhasesStep' })
        },
        [dispatch]
    )

    return (
        <Styled>
            <header>
                {mode === 'create' ? (
                    <Text as="h3" variant="bold-2">
                        Workflow details
                    </Text>
                ) : null}
                {preselectedBO && (
                    <StyledPreselectInfo>
                        <Icon name="Cube" />
                        <p>
                            <Text
                                as="span"
                                variant="regular-4"
                            >{`This workflow will operate upon the dataset: `}</Text>
                            <Text as="span" variant="bold-4">
                                {preselectedBO.name}
                            </Text>
                        </p>
                    </StyledPreselectInfo>
                )}
            </header>
            <fieldset>
                {!preselectedBO && mode === 'create' && (
                    <BODefSelect
                        value={input.operatesUpon[0]}
                        onSelect={handleSelection}
                    />
                )}
            </fieldset>
            <footer>
                <BusinessObjectDefinitionCreator
                    buttonVariant="secondary"
                    buttonSize="small"
                    onComplete={handleSelection}
                />
            </footer>
        </Styled>
    )
}

const Styled = styled.section`
    width: 30rem;

    fieldset {
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
    }

    footer button {
        width: 100%;
    }
`

const StyledPreselectInfo = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background-color: ${({ theme }) => theme.palette.support.bg03.normal};
    padding: 0.75rem 0.5rem 0.75rem 1rem;
    border-radius: 0.5rem;

    p {
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
    }
`

export { DetailsStep }
