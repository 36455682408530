import { IconTextButton } from 'Components/Button'
import { Text } from 'Components/Text'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { match } from 'ts-pattern'
import { Invites } from './Invites'
import { YourDetails } from './YourDetails'

const Account: FC = () => {
    const navigate = useNavigate()
    const { screenSize } = useResponder()
    return match(screenSize)
        .with('large', () => (
            <StyledLarge>
                <header>
                    <Text as="h1" variant="bold-2">
                        Account
                    </Text>
                    <IconTextButton
                        icon="Logout"
                        text="Log out"
                        variant="secondary"
                        onClick={() => navigate('/logout')}
                    />
                </header>
                <YourDetails />
                <Invites />
            </StyledLarge>
        ))
        .otherwise(() => (
            <div>
                <StyledSmall>
                    <header>
                        <Text as="h1" variant="bold-2">
                            Account
                        </Text>
                    </header>
                    <YourDetails />
                    <Invites />
                    <footer>
                        <IconTextButton
                            icon="Logout"
                            text="Log out"
                            variant="primary"
                            onClick={() => navigate('/logout')}
                        />
                    </footer>
                </StyledSmall>
            </div>
        ))
}

const StyledLarge = styled.div`
    display: grid;
    grid-template: auto 1fr / 1fr 1fr;

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1/3;
        padding: 1rem 1rem 1rem 3rem;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > section {
        padding: 2rem 3rem 0;
    }

    > section:nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    }
`

const StyledSmall = styled.div`
    overflow: auto;
    height: 100%;

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1/3;
        padding: 1rem 4vw;
        border-bottom: 1px solid ${({ theme }) => theme.palette.ui['03'].normal};
    }

    > section {
        padding: 2rem 4vw 0;
    }

    > section:nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    }

    > footer {
        background-color: ${({ theme }) => theme.palette.ui['04'].normal};
        padding: 1.5rem 4vw;
    }
    > footer button {
        width: 100%;
    }
`

export { Account }
