import { useOrganisationUsers } from 'Hooks'
import { useMemo } from 'react'

export const useUsersToNamesMap = () => {
    const { users } = useOrganisationUsers()

    const usersToNamesMap: Record<string, string> = useMemo(
        () =>
            users.reduce(
                (acc, user) => ({ ...acc, [user.id]: user.name }),
                {} as Record<string, string>
            ),
        [users]
    )

    return { usersToNamesMap }
}
