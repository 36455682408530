import { logger } from 'Adapters/Logger'
import { useUploadSignatureQuery } from './__generated__/queries'

export type UploadSignature = {
    uploadSignature: {
        expire: string
        signature: string
    }
}

const TEN_MINUTES = 1000 * 60 * 10

export const useUploadSignature = () => {
    const { data } = useUploadSignatureQuery({
        fetchPolicy: 'network-only',
        pollInterval: TEN_MINUTES,
        onError(e) {
            logger.error('Failed to fetch document upload signature', e)
        },
    }) // Signature has a 10m expiration
    return { data }
}
