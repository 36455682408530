import { styled } from 'Adapters/Freestyled'
import { ActionCard } from 'Features/ActionView/ActionCard'
import { FC } from 'react'
import { ActionsList_ActionFragment } from './__generated__/q'

type Props = {
    actions: ActionsList_ActionFragment[]
}

export const ActionsList: FC<Props> = ({ actions }) => {
    return (
        <StyledActionsList>
            {actions.map(action => (
                <ActionCard id={action.id} key={action.id} />
            ))}
        </StyledActionsList>
    )
}

const StyledActionsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
