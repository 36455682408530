import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSearchTerms = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTerms = searchParams.get('searchTerms')

    const setSearchTerms = useCallback(
        (terms: string) => {
            if (!terms) {
                searchParams.delete('searchTerms')
            } else {
                searchParams.set('searchTerms', terms)
            }

            setSearchParams(searchParams)
        },
        [searchParams, setSearchParams]
    )

    return { searchTerms: searchTerms ?? '', setSearchTerms }
}
