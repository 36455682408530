import { CheckboxItem } from '@radix-ui/react-dropdown-menu'
import { css, styled } from 'Adapters/Freestyled'
import { useColorVariant } from 'Components/Dropdown/colorVariantProvider'
import { ColorVariant } from 'Components/Dropdown/types'
import { Icon } from 'Components/Icon'
import { FC } from 'react'
import { baseButtonStyles } from '../styles'

type Props = {
    text: string
    checked: boolean | 'indeterminate'
    onCheckedChange: (checked: boolean | 'indeterminate') => void
    disabled?: boolean
    closeOnSelect?: boolean
}

export const DropdownCheckbox: FC<Props> = ({
    text,
    checked,
    onCheckedChange,
    disabled = false,
    closeOnSelect = true,
}) => {
    const { variant } = useColorVariant()

    return (
        <StyledDropdownCheckbox
            checked={checked}
            onCheckedChange={onCheckedChange}
            disabled={disabled}
            onSelect={e => {
                if (!closeOnSelect) e.preventDefault()
            }}
            $variant={variant}
        >
            <Icon
                name={checked ? 'CheckboxTicked' : 'CheckboxUnticked'}
                data-state={checked ? 'checked' : 'unchecked'}
            />

            <span>{text}</span>
        </StyledDropdownCheckbox>
    )
}

export const StyledDropdownCheckbox = styled(CheckboxItem)<{
    $variant: ColorVariant
}>`
    ${baseButtonStyles};
    ${({ $variant }) => applyColorStyles($variant)};
`

const applyColorStyles = (variant: ColorVariant) =>
    variant === 'light'
        ? css`
              background-color: ${({ theme }) => theme.palette.ui['01'].normal};

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['01'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['01'].active};
              }

              &[data-state='unchecked'] {
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['03'].normal};
                      }
                  }
              }

              &[data-state='checked'] {
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['01'].normal};
                      }
                  }
              }
          `
        : css`
              background-color: ${({ theme }) => theme.palette.ui['07'].normal};
              color: white;

              @media (hover: hover) {
                  &:hover {
                      background-color: ${({ theme }) =>
                          theme.palette.ui['07'].hover};
                  }
              }

              &:active {
                  background-color: ${({ theme }) =>
                      theme.palette.ui['07'].active};
              }

              svg {
                  path {
                      fill: ${({ theme }) => theme.palette.icon['02'].normal};
                  }
              }

              &[data-state='unchecked'] {
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['02'].normal};
                      }
                  }
              }

              &[data-state='checked'] {
                  svg {
                      path {
                          fill: ${({ theme }) =>
                              theme.palette.icon['02'].normal};
                      }
                  }
              }
          `
