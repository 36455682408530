import * as Types from '../../../../../../__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveUserFromTenantMutationVariables = Types.Exact<{
  input: Types.RemoveUserFromTenantInput;
}>;


export type RemoveUserFromTenantMutation = { __typename?: 'Mutation', removeUserFromTenant: boolean };

export type ManageWorkspaceMembersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ManageWorkspaceMembersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, email: string }>, currentUser: { __typename?: 'User', id: string, name: string, activeTenant?: { __typename?: 'UserTenancy', tenant: { __typename?: 'Tenant', id: string, openInvites: Array<{ __typename?: 'Invite', id: string, inviteeEmail: string }> } } | null } };

export type Member_UserGroupFragment = { __typename?: 'UserGroup', id: string, name: string, users: Array<{ __typename?: 'UserGroupUser', id: string, name: string, principal: { __typename?: 'UserGroupUserPrincipal', id: string } }> };

export type CreateUserGroupMutationVariables = Types.Exact<{
  input: Types.CreateUserGroupInput;
}>;


export type CreateUserGroupMutation = { __typename?: 'Mutation', createUserGroup: { __typename?: 'UserGroup', id: string, name: string, users: Array<{ __typename?: 'UserGroupUser', id: string, name: string, principal: { __typename?: 'UserGroupUserPrincipal', id: string } }> } };

export type ManageWorkspaceUserGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ManageWorkspaceUserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'UserGroup', id: string, name: string, users: Array<{ __typename?: 'UserGroupUser', id: string, name: string, principal: { __typename?: 'UserGroupUserPrincipal', id: string } }> }> };

export type UpdateUserGroupMembersMutationVariables = Types.Exact<{
  input: Types.UpdateUserGroupMembersInput;
}>;


export type UpdateUserGroupMembersMutation = { __typename?: 'Mutation', updateUserGroupMembers: { __typename?: 'UserGroup', id: string, name: string, users: Array<{ __typename?: 'UserGroupUser', id: string, name: string, principal: { __typename?: 'UserGroupUserPrincipal', id: string } }> } };

export const Member_UserGroupFragmentDoc = gql`
    fragment Member_UserGroup on UserGroup {
  id
  name
  users {
    id
    name
    principal {
      id
    }
  }
}
    `;
export const RemoveUserFromTenantDocument = gql`
    mutation RemoveUserFromTenant($input: RemoveUserFromTenantInput!) {
  removeUserFromTenant(input: $input)
}
    `;
export type RemoveUserFromTenantMutationFn = Apollo.MutationFunction<RemoveUserFromTenantMutation, RemoveUserFromTenantMutationVariables>;

/**
 * __useRemoveUserFromTenantMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromTenantMutation, { data, loading, error }] = useRemoveUserFromTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserFromTenantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromTenantMutation, RemoveUserFromTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromTenantMutation, RemoveUserFromTenantMutationVariables>(RemoveUserFromTenantDocument, options);
      }
export type RemoveUserFromTenantMutationHookResult = ReturnType<typeof useRemoveUserFromTenantMutation>;
export type RemoveUserFromTenantMutationResult = Apollo.MutationResult<RemoveUserFromTenantMutation>;
export type RemoveUserFromTenantMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromTenantMutation, RemoveUserFromTenantMutationVariables>;
export const ManageWorkspaceMembersDocument = gql`
    query ManageWorkspaceMembers {
  users {
    id
    name
    email
  }
  currentUser {
    id
    name
    activeTenant {
      tenant {
        id
        openInvites {
          id
          inviteeEmail
        }
      }
    }
  }
}
    `;

/**
 * __useManageWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useManageWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageWorkspaceMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageWorkspaceMembersQuery(baseOptions?: Apollo.QueryHookOptions<ManageWorkspaceMembersQuery, ManageWorkspaceMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageWorkspaceMembersQuery, ManageWorkspaceMembersQueryVariables>(ManageWorkspaceMembersDocument, options);
      }
export function useManageWorkspaceMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageWorkspaceMembersQuery, ManageWorkspaceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageWorkspaceMembersQuery, ManageWorkspaceMembersQueryVariables>(ManageWorkspaceMembersDocument, options);
        }
export type ManageWorkspaceMembersQueryHookResult = ReturnType<typeof useManageWorkspaceMembersQuery>;
export type ManageWorkspaceMembersLazyQueryHookResult = ReturnType<typeof useManageWorkspaceMembersLazyQuery>;
export type ManageWorkspaceMembersQueryResult = Apollo.QueryResult<ManageWorkspaceMembersQuery, ManageWorkspaceMembersQueryVariables>;
export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($input: CreateUserGroupInput!) {
  createUserGroup(input: $input) {
    ...Member_UserGroup
  }
}
    ${Member_UserGroupFragmentDoc}`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<CreateUserGroupMutation, CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>;
export const ManageWorkspaceUserGroupsDocument = gql`
    query ManageWorkspaceUserGroups {
  userGroups {
    ...Member_UserGroup
  }
}
    ${Member_UserGroupFragmentDoc}`;

/**
 * __useManageWorkspaceUserGroupsQuery__
 *
 * To run a query within a React component, call `useManageWorkspaceUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageWorkspaceUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageWorkspaceUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageWorkspaceUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ManageWorkspaceUserGroupsQuery, ManageWorkspaceUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageWorkspaceUserGroupsQuery, ManageWorkspaceUserGroupsQueryVariables>(ManageWorkspaceUserGroupsDocument, options);
      }
export function useManageWorkspaceUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageWorkspaceUserGroupsQuery, ManageWorkspaceUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageWorkspaceUserGroupsQuery, ManageWorkspaceUserGroupsQueryVariables>(ManageWorkspaceUserGroupsDocument, options);
        }
export type ManageWorkspaceUserGroupsQueryHookResult = ReturnType<typeof useManageWorkspaceUserGroupsQuery>;
export type ManageWorkspaceUserGroupsLazyQueryHookResult = ReturnType<typeof useManageWorkspaceUserGroupsLazyQuery>;
export type ManageWorkspaceUserGroupsQueryResult = Apollo.QueryResult<ManageWorkspaceUserGroupsQuery, ManageWorkspaceUserGroupsQueryVariables>;
export const UpdateUserGroupMembersDocument = gql`
    mutation UpdateUserGroupMembers($input: UpdateUserGroupMembersInput!) {
  updateUserGroupMembers(input: $input) {
    ...Member_UserGroup
  }
}
    ${Member_UserGroupFragmentDoc}`;
export type UpdateUserGroupMembersMutationFn = Apollo.MutationFunction<UpdateUserGroupMembersMutation, UpdateUserGroupMembersMutationVariables>;

/**
 * __useUpdateUserGroupMembersMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMembersMutation, { data, loading, error }] = useUpdateUserGroupMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserGroupMembersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupMembersMutation, UpdateUserGroupMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupMembersMutation, UpdateUserGroupMembersMutationVariables>(UpdateUserGroupMembersDocument, options);
      }
export type UpdateUserGroupMembersMutationHookResult = ReturnType<typeof useUpdateUserGroupMembersMutation>;
export type UpdateUserGroupMembersMutationResult = Apollo.MutationResult<UpdateUserGroupMembersMutation>;
export type UpdateUserGroupMembersMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupMembersMutation, UpdateUserGroupMembersMutationVariables>;