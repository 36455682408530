import { NumberInput } from 'Components/Input'
import { FC } from 'react'
import { FieldInputProps } from '../../types'
import { Field } from '../Field'
import { useNumberConstraints } from './useNumberConstraints'

// NB. I am assuming the user knows that numbers should be incrementable
// and number fields are not to be used to denote credit card numbers, phone numbers etc
// that are 'numeric' rather than numbers

export const NumberField: FC<FieldInputProps<'NumberFieldDefinition'>> = ({
    field,
    value,
    onChange,
    errors,
}) => {
    const { constraints, constraintsTooltip } = useNumberConstraints({
        numConstraints: field.numConstraints,
    })

    return (
        <Field
            id={field.id}
            name={field.name}
            required={!!constraints.required}
            description={field.description ?? ''}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <NumberInput
                name={field.type + '||' + field.id}
                value={value ?? ''}
                id={field.id}
                onChange={e => {
                    const value = e.target.value
                    if (value === '') return onChange(undefined)
                    onChange(Number(value))
                }}
                type="number"
                min={constraints.min}
                max={constraints.max}
                required={constraints.required}
            />
        </Field>
    )
}
