import { Size, Variant } from 'Components/Button/TextButton/types'
import { CreatorModal } from 'Features/CreatorModal'
import { ProcessCreator } from 'Features/ProcessCreator'
import { State } from 'Features/ProcessCreator/useProcessCreator/types'
import { useResponder } from 'Hooks'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { usePreselected_BoQuery } from './__generated__/q'

type Props = {
    buttonSize?: Size
    buttonVariant?: Variant
    initiallyOpen?: boolean
    initialStep?: State['step']
    preselectedBOId?: string
}

const Creator: FC<Props> = ({
    buttonSize = 'xSmall',
    buttonVariant = 'primary',
    initiallyOpen = false,
    initialStep = 'start',
    preselectedBOId,
}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(initiallyOpen)

    const { data } = usePreselected_BoQuery({
        skip: !preselectedBOId,
        variables: {
            id: preselectedBOId!, // assertion ok as query skipped if not set
        },
    })

    const { isLarge } = useResponder()

    return (
        <CreatorModal
            title=""
            description=""
            trigger={{
                text: 'Create new workflow',
                size: buttonSize,
                variant: buttonVariant,
            }}
            renderContent={() =>
                preselectedBOId && !data?.businessObjectDefinition ? (
                    <></>
                ) : (
                    <ProcessCreator
                        onCompleted={({ name }) => {
                            setOpen(false)
                            navigate(
                                `/manage-workspace${
                                    isLarge ? '' : '/model'
                                }/processes/${slugify(name ?? '', {
                                    lower: true,
                                })}`
                            )
                        }}
                        initialStep={initialStep}
                        preselectedBO={
                            data?.businessObjectDefinition ?? undefined
                        }
                    />
                )
            }
            open={open}
            setOpen={setOpen}
            confirmationDescription="If you close the creator now, you will lose all your changes."
        />
    )
}

export { Creator }
