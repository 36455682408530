import { styled } from 'Adapters/Freestyled'
import { Text } from 'Components/Text'
import dayjs from 'dayjs'
import { FC } from 'react'
import { match, P } from 'ts-pattern'
import { opt } from 'Utils'
import { ViewConfig } from '../../../../types'
import { ProcessSingle_CardQuery } from '../../__generated__/q'
import { ExternalLink } from './ExternalLink'
import { FieldLabel } from './FieldLabel'
import { useDisplayFields } from './useDisplayFields'

type Props = {
    obj: ProcessSingle_CardQuery['businessObject']
    cardSettings: ViewConfig['cardSettings']
}

const DisplayFields: FC<Props> = props => {
    const { fields } = useDisplayFields(props)
    return (
        <Styled>
            {fields.map(({ id, label, field }) => (
                <li key={id}>
                    <FieldLabel label={label} />
                    <StyledValue>
                        {match(field)
                            .with(
                                { __typename: 'BusinessObjectBooleanField' },
                                ({ booleanValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {booleanValue ? 'True' : 'False'}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectCurrencyField' },
                                ({ currencyValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            currencyValue,
                                            ({ amount, currencyDetails }) =>
                                                `${currencyDetails.symbol}${amount}`,
                                            () => (
                                                <span className="">-</span>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectDateField' },
                                ({ dateValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            dateValue,
                                            d => dayjs(d).format('DD MM YYYY'),
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectDocumentField' },
                                ({ docValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            docValue,
                                            ({ name, url }) => (
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={url}
                                                >
                                                    {name}
                                                </a>
                                            ),
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectEmailField' },
                                ({ emailValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            emailValue,
                                            val => (
                                                <ExternalLink
                                                    val={val}
                                                    type="email"
                                                />
                                            ),
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectListField' },
                                ({ listValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {`${listValue.length} selected`}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectNumberField' },
                                ({ numValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            numValue,
                                            val => val,
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectRelationField' },
                                ({ relationValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            relationValue,
                                            rel => rel.label,
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectSelectField' },
                                ({ selectValue, fieldDefinition }) => (
                                    <Text as="p" variant="regular-4">
                                        {match(selectValue)
                                            .with(
                                                [P._],
                                                v =>
                                                    match(fieldDefinition)
                                                        .with(
                                                            {
                                                                __typename:
                                                                    'SelectFieldDefinition',
                                                            },
                                                            ({
                                                                selectOptions,
                                                            }) => selectOptions
                                                        )
                                                        .otherwise(() => [])
                                                        .find(
                                                            ({ id }) =>
                                                                id === v[0]
                                                        )?.value || '1 selected'
                                            )
                                            .otherwise(
                                                ({ length }) =>
                                                    `${length} selected`
                                            )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectTelephoneField' },
                                ({ telephoneValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            telephoneValue,
                                            ({ countryCode, number }) => (
                                                <ExternalLink
                                                    val={`${countryCode || ''}${
                                                        number || ''
                                                    }`}
                                                    type="tel"
                                                />
                                            ),
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectTextField' },
                                ({ textValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            textValue,
                                            val => val,
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectUpdatesField' },
                                ({ updatesValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            updatesValue,
                                            () => 'enabled',
                                            () => 'disabeld'
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectUrlField' },
                                ({ urlValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            urlValue,
                                            val => (
                                                <ExternalLink
                                                    val={val}
                                                    type="link"
                                                />
                                            ),
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .with(
                                { __typename: 'BusinessObjectUserField' },
                                ({ userValue }) => (
                                    <Text as="p" variant="regular-4">
                                        {opt(
                                            userValue,
                                            ({ name }) => name,
                                            () => (
                                                <StyledUnset>-</StyledUnset>
                                            )
                                        )}
                                    </Text>
                                )
                            )
                            .exhaustive()}
                    </StyledValue>
                </li>
            ))}
        </Styled>
    )
}

const Styled = styled.ul`
    padding: 0.25rem 0 0;
    display: flex;
    flex-direction: column;

    li {
        padding: 0rem 0.75rem 0.5rem;
    }
`

const StyledValue = styled.div`
    color: ${({ theme }) => theme.palette.text['02'].normal};
    overflow-wrap: break-word;
    word-break: break-all;
`

const StyledUnset = styled.span`
    opacity: 0.4;
`

export { DisplayFields }
