import { flex, styled } from 'Adapters/Freestyled'
import { TextInput } from 'Components/Input'
import { Select } from 'Components/Select'
import { BizObjDef_Tel, BOField } from 'Hooks'
import { FC, useRef, useState } from 'react'
import { countryCodeOptions } from 'Utils'
import { EditFieldButton } from '../EditFieldButton'
import { Field } from '../Field'
import { useUpdateBusinessObjectField } from '../useUpdateBusinessObjectField'
import { useTelephoneConstraints } from './useTelephoneConstraints'

type Props = BOField<'BusinessObjectTelephoneField'> & {
    businessObjectId: string
    onComplete: () => void
}

export const TelephoneField: FC<Props> = ({
    businessObjectId,
    fieldDefinition,
    telephoneValue,
    onComplete,
}) => {
    const { telephoneConstraints, ...common } = fieldDefinition as BizObjDef_Tel

    const { constraints, constraintsTooltip } = useTelephoneConstraints({
        telephoneConstraints,
    })

    const { updateBusinessObjectField, errors, loading } =
        useUpdateBusinessObjectField()

    const initialCode = useRef(telephoneValue?.countryCode ?? '')
    const initialNumber = useRef(telephoneValue?.number ?? '')

    const [countryCode, setCountryCode] = useState(initialCode.current)
    const [number, setNumber] = useState<string>(initialNumber.current)

    return (
        <Field
            id={common.id}
            name={common.name}
            required={!!constraints.required}
            constraintsTooltip={constraintsTooltip}
            errors={errors}
        >
            <StyledTelInput>
                <Select
                    id={common.id + 'countrycode'}
                    value={countryCode}
                    onValueChange={setCountryCode}
                    name={'Country Code'}
                    options={countryCodeOptions}
                />
                <TextInput
                    value={number}
                    id={common.id}
                    hasError={!!errors.length}
                    onChange={e => setNumber(e.target.value)}
                    required={constraints.required}
                    className="tel-number-input"
                />
            </StyledTelInput>

            <EditFieldButton
                disabled={
                    (initialNumber.current === number &&
                        initialCode.current === countryCode) ||
                    loading
                }
                onClick={() => {
                    // If both previous values are empty, send undefined rather than empty object
                    const previousValue =
                        initialCode.current || initialNumber.current
                            ? {
                                  countryCode: initialCode.current
                                      ? initialCode.current
                                      : undefined,
                                  number: initialNumber.current
                                      ? initialNumber.current
                                      : undefined,
                              }
                            : undefined

                    updateBusinessObjectField({
                        field: {
                            [common.type]: {
                                fieldDefinitionId: common.id,
                                previousValue,
                                value: {
                                    countryCode: countryCode
                                        ? countryCode
                                        : undefined,
                                    number: number ? number : undefined,
                                },
                            },
                        },
                        id: businessObjectId,
                        onSuccess: () => {
                            initialCode.current = countryCode
                            initialNumber.current = number
                            onComplete()
                        },
                    })
                }}
            />
        </Field>
    )
}

const StyledTelInput = styled.div`
    ${flex('row', 'flex-start', 'center')};
    gap: 1rem;
    width: 100%;

    button {
        width: 8rem;
    }

    .tel-number-input {
        flex: 1;
        height: 2.75rem;
    }
`
