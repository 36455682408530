import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { regular5 } from 'Components/Text'
import { BusinessObjectsByIdQuery } from 'Hooks/useFetchBusinessObjectsById/__generated__/query'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

type Props = {
    relatedBusinessObject: BusinessObjectsByIdQuery['businessObjects'][number]
    setValues: Dispatch<
        SetStateAction<
            {
                id: string
                label: string
            }[]
        >
    >
}

export const RelationListFieldItem: FC<Props> = ({
    relatedBusinessObject,
    setValues,
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { id } = useParams<{ id: string }>()

    const openBusinessObjectInDrawer = useCallback(() => {
        if (!id) return

        const newPath = pathname.replace(id, relatedBusinessObject.id)

        navigate(newPath, {
            state: { businessObjectLabel: relatedBusinessObject.label },
        })
    }, [navigate, relatedBusinessObject, id, pathname])

    return (
        <StyledListItem>
            <StyledOpenRelationButton
                icon={'Cube'}
                text={relatedBusinessObject.label}
                onClick={openBusinessObjectInDrawer}
            />
            <IconButton
                iconName={'Close'}
                title="Remove"
                onClick={e => {
                    e.preventDefault() // Stop form from being submitted
                    setValues(prev =>
                        prev.filter(
                            value => value.id !== relatedBusinessObject.id
                        )
                    )
                }}
            />
        </StyledListItem>
    )
}

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button:last-of-type {
        margin-left: auto;
    }
`

const StyledOpenRelationButton = styled(IconTextButton)`
    color: ${({ theme }) => theme.palette.text['02'].normal};
`
