import { Label } from '@radix-ui/react-label'
import { css, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { DeepFieldSelector } from 'Components/DeepFieldSelector'
import { useResponder } from 'Hooks'
import { FC } from 'react'
import { Operator } from '../../__generated__'
import { Text, regular5 } from '../Text'
import { OperatorSelector } from './OperatorSelector'
import { ValueSelector } from './ValueSelector'
import { DisplayComparator } from './useComparatorDisplay'
import { DeepFilterableField, FilterableField } from './useFilterableFields'

type Props = {
    index: number
    comparator: DisplayComparator
    handleComparatorFieldIdChanged: (index: number) => (fieldId: string) => void
    handleOperatorChanged: (
        index: number
    ) => (operator: Operator, negate?: boolean) => void
    handleComparatorWithChanged: (index: number) => (value: unknown) => void
    handleComparatorRemoved: (index: number) => () => void
    availableFields: FilterableField[]
    availableDeepFields: DeepFilterableField[]
    showErrors: boolean
    isSmall?: boolean
}

export const BuildComparator: FC<Props> = ({
    index,
    availableDeepFields,
    availableFields,
    comparator: { comparator, validOperators, validation, valueSelectorField },
    handleComparatorFieldIdChanged,
    handleComparatorRemoved,
    handleComparatorWithChanged,
    handleOperatorChanged,
    showErrors,
    isSmall = false,
}) => {
    const { isLarge } = useResponder()

    return (
        <li>
            <StyledComparatorSelections $isSmall={isSmall}>
                <div className="select-wrapper">
                    <Label htmlFor={`select-condition-${index}`}>Field</Label>
                    <DeepFieldSelector
                        id={`select-condition-${index}`}
                        onFieldChanged={handleComparatorFieldIdChanged(index)}
                        fieldId={comparator.valueSelector?.fieldId}
                        fields={availableFields}
                        deepFields={availableDeepFields}
                    />
                </div>

                <div className="select-wrapper">
                    <Label htmlFor={`select-condition-${index}`}>
                        Operator
                    </Label>
                    <OperatorSelector
                        id={`select-condition-${index}`}
                        onOperatorChanged={handleOperatorChanged(index)}
                        operator={comparator.operator}
                        negate={comparator.negate}
                        operators={validOperators}
                        disabled={!comparator.valueSelector?.fieldId}
                    />
                </div>

                <div className="select-wrapper">
                    <Label htmlFor={`select-value-${index}`}>Value</Label>
                    <ValueSelector
                        id={`select-value-${index}`}
                        index={index}
                        field={valueSelectorField}
                        operator={comparator.operator}
                        value={comparator.with}
                        onValueChanged={handleComparatorWithChanged(index)}
                    />
                </div>

                <div className="button-wrapper">
                    {isLarge ? (
                        <IconButton
                            iconName={'Delete'}
                            onClick={handleComparatorRemoved(index)}
                            variant="secondary"
                        />
                    ) : (
                        <IconTextButton
                            icon="Delete"
                            onClick={handleComparatorRemoved(index)}
                            variant="secondary"
                            text="Remove"
                        />
                    )}
                </div>
            </StyledComparatorSelections>

            {!validation.valid && showErrors ? (
                <Text as="p" variant="regular-5">
                    Invalid: {validation.reason}
                </Text>
            ) : null}
        </li>
    )
}

const smallStyles = css`
    display: grid;
    grid-template-columns: 1fr 1fr;

    .button-wrapper {
        width: 100%;
        height: 100%;

        button {
            width: 100%;
            height: 100%;
        }
    }
`

const largeStyles = css`
    display: flex;
    flex-direction: row;

    > .select-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

const StyledComparatorSelections = styled.div<{ $isSmall: boolean }>`
    ${({ $isSmall }) => ($isSmall ? smallStyles : largeStyles)}
    gap: 0.5rem;

    label {
        ${regular5};
        color: ${({ theme }) => theme.palette.text['02'].normal};
    }

    .button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        button {
            height: 2.813rem;
        }
    }
`
