import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { useEffect, useRef, useState } from 'react'
import { Source } from './types'

export const useDragBusinessObjectCard = (
    businessObjectId: string,
    groupId: string
) => {
    const [dragging, setDragging] = useState<boolean>(false)

    const ref = useRef(null)

    useEffect(() => {
        const el = ref.current

        if (!el) return

        return draggable({
            element: el,
            onDragStart: () => setDragging(true),
            onDrop: () => setDragging(false),
            getInitialData: () =>
                ({
                    businessObjectId,
                    groupId,
                } satisfies Source),
        })
    }, [businessObjectId, groupId])

    return { ref, dragging }
}
