import {
    DocumentFieldValidation,
    DocumentField as Field,
} from 'Utils/BusinessObjectDefinition'
import { FC, useState } from 'react'
import { OnSubmit, State } from '../types'
import { RequiredField } from './Constraint/RequiredField'
import { Content } from './Content'

type Props = {
    field: Field
    validation: DocumentFieldValidation
    onSubmit: OnSubmit<Field, DocumentFieldValidation>
    onClose: () => void

    hideRequired: boolean
}

const DocField: FC<Props> = ({
    field,
    validation,
    onSubmit,
    onClose,

    hideRequired,
}) => {
    const [state, setState] = useState<State<Field, DocumentFieldValidation>>({
        field,
        validation,
    })

    return (
        <Content
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <RequiredField
                checked={state.field.constraints.required}
                onCheckedChange={checked => {
                    setState(prev => ({
                        ...prev,
                        field: {
                            ...prev.field,
                            constraints: {
                                ...prev.field.constraints,
                                required: checked,
                            },
                        },
                    }))
                }}
                displayOnly={field.constraintsLocked}
                hidden={hideRequired}
            />
        </Content>
    )
}

export { DocField }
