import { BizObjDef_List, BOField } from 'Hooks'
import { FC } from 'react'
import { match } from 'ts-pattern'
import {
    DateListField,
    DocumentListField,
    EmailListField,
    RelationListField,
    TextListField,
    UrlListField,
    UserListField,
} from './ListFields'

type Props = BOField<'BusinessObjectListField'> & {
    businessObjectId: string
    onComplete: () => void
}

const ListField: FC<Props> = ({
    listValue,
    businessObjectId,
    fieldDefinition,
    onComplete,
}) => {
    const { listOf, ...common } = fieldDefinition as BizObjDef_List

    return (
        <>
            {match(listOf)
                .with({ __typename: 'DocumentFieldDefinition' }, props => (
                    <DocumentListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'UserFieldDefinition' }, props => (
                    <UserListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'URLFieldDefinition' }, props => (
                    <UrlListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'TextFieldDefinition' }, props => (
                    <TextListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'RelationFieldDefinition' }, props => (
                    <RelationListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'EmailFieldDefinition' }, props => (
                    <EmailListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))
                .with({ __typename: 'DateFieldDefinition' }, props => (
                    <DateListField
                        key={props.id}
                        businessObjectId={businessObjectId}
                        listName={common.name}
                        listId={common.id}
                        listValue={listValue}
                        onComplete={onComplete}
                        {...props}
                    />
                ))

                // TODO : Implement lists of the following field types
                .with({ __typename: 'TelephoneFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'CurrencyFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'NumberFieldDefinition' }, () => (
                    <Unimplemented />
                ))

                // Maybe TODO : Are lists of these even useful?
                .with({ __typename: 'BooleanFieldDefinition' }, () => (
                    <Unimplemented />
                ))
                .with({ __typename: 'SelectFieldDefinition' }, () => (
                    <Unimplemented />
                ))

                .with({ __typename: 'UpdatesFieldDefinition' }, () => null) // Probably don't want to edit updates in this fashion
                .with({ __typename: 'ListFieldDefinition' }, () => null) // No list of lists
                .exhaustive()}
        </>
    )
}

const Unimplemented = () => (
    <p>
        <em>Unimplemented : Contact us if this is an issue</em>
    </p>
)

export { ListField }
