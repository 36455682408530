import { flex, styled } from 'Adapters/Freestyled'
import { IconButton, IconTextButton } from 'Components/Button'
import { Dropdown, DropdownButton } from 'Components/Dropdown'
import { Icon } from 'Components/Icon'
import { regular5 } from 'Components/Text'
import {
    useCreateFormState,
    useCreateFormStateDispatch,
} from 'Features/CreateBusinessObjectModal/Hooks/useCreateFormState'
import { CreateRelationModal } from 'Features/CreateRelationModal'
import { useFetchBusinessObjectsById, useModalControls } from 'Hooks'
import { uniqBy } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { BODField } from '../../../../../types'
import { ListFieldWrapper } from '../ListFieldWrapper'
import { useRelationConstraints } from './useRelationConstraints'

type Props = BODField<'RelationFieldDefinition'> & {
    listName: string
    listId: string
}

type SelectedItems = { id: string; label: string }

export const RelationListField: FC<Props> = ({
    relationConstraints,
    listName,
    listId,
    prefilledBusinessObject,
}) => {
    const modalControls = useModalControls()
    const state = useCreateFormState()
    const dispatch = useCreateFormStateDispatch()

    const { constraints, constraintsTooltip } =
        useRelationConstraints(relationConstraints)

    const data = useFetchBusinessObjectsById(constraints.relationId)

    const [values, setValues] = useState<SelectedItems[]>([])

    useEffect(() => {
        dispatch({
            type: 'updateListField',
            payload: {
                id: listId,
                value: values.map(relation => ({ relation: relation.id })),
            },
        })
    }, [values, dispatch, listId])

    const options = useMemo(() => {
        return (
            data?.businessObjects.map(businessObject => ({
                id: businessObject.id,
                label: businessObject.label,
            })) ?? []
        )
    }, [data])

    const handleSelection = useCallback(
        (item: SelectedItems) => {
            setValues(prev => uniqBy([...prev, item], 'id'))
        },
        [setValues]
    )

    useEffect(() => {
        const prefilledOption = options.find(
            option => option.id === prefilledBusinessObject?.id
        )

        if (prefilledOption) {
            handleSelection(prefilledOption)
        }
    }, [prefilledBusinessObject, options, handleSelection])

    return (
        <>
            <ListFieldWrapper
                id={listId}
                name={listName}
                required={!!constraints.required}
                constraintsTooltip={constraintsTooltip}
                errors={state[listId].errors ?? []}
                list={values.map(({ label, id }) => (
                    <StyledListItem key={id}>
                        <Icon name="Cube" />
                        <span>{label}</span>
                        <IconButton
                            iconName="Close"
                            title="Remove"
                            onClick={e => {
                                e.preventDefault() // Stop form from being submitted
                                setValues(prev =>
                                    prev.filter(relation => relation.id !== id)
                                )
                            }}
                        />
                    </StyledListItem>
                ))}
            >
                <Dropdown
                    renderContent={() =>
                        options.map(option => (
                            <DropdownButton
                                key={option.id}
                                text={option.label}
                                onSelect={() => {
                                    handleSelection(option)
                                }}
                            />
                        ))
                    }
                    renderOpenDropdownButton={() => (
                        <StyledAddDocumentButton
                            icon="Cube"
                            text={'Add a relation'}
                        />
                    )}
                    variant="light"
                />

                <IconButton
                    iconName="AddSmall"
                    title={`Create new ${
                        data?.businessObjects[0]?.definition.name ?? 'Relation'
                    }`}
                    onClick={e => {
                        e.preventDefault()
                        modalControls.openModal()
                    }}
                />
            </ListFieldWrapper>
            <CreateRelationModal
                businessObjectDefinitionId={constraints.relationId}
                onBusinessObjectCreated={businessObject => {
                    handleSelection({
                        id: businessObject.id,
                        label: businessObject.label,
                    })
                }}
                {...modalControls}
            />
        </>
    )
}

const StyledAddDocumentButton = styled(IconTextButton)`
    ${regular5};
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    width: 100%;
`

const StyledListItem = styled.li`
    ${regular5};
    ${flex('row', 'flex-start', 'center')};
    gap: 0.5rem;
    width: 100%;

    > button {
        margin-left: auto;
    }
`
