import dayjs from 'dayjs'
import { FC, useCallback, useRef, useState } from 'react'
import { styled } from '../../Adapters/Freestyled'
import { IconTextButton } from '../Button'
import { DatePicker } from '../DatePicker'

type Props = {
    date: string | undefined
    onDateChanged: (date: string) => void
}

export const DateSelector: FC<Props> = ({ date, onDateChanged }) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const dayte = date ? dayjs(date) : undefined

    const handleConfirm = useCallback(
        ({ date }: { date: Date }) => {
            onDateChanged(date.toISOString())
            setDatePickerOpen(false)
        },
        [onDateChanged]
    )

    return (
        <>
            <StyledButton
                icon="Calendar"
                text={dayte ? dayte.format('DD MMM YYYY') : 'Add a date'}
                onClick={() => setDatePickerOpen(true)}
                ref={buttonRef}
            />
            <DatePicker
                open={datePickerOpen}
                onOpenChange={setDatePickerOpen}
                title={'Add date'}
                description={'Add a date for the filter'}
                selectedDate={dayte?.toDate() ?? undefined}
                onConfirm={handleConfirm}
                returnFocus={() => {
                    buttonRef.current?.focus()
                }}
            />
        </>
    )
}

const StyledButton = styled(IconTextButton)`
    min-width: 208px;
    min-height: 48px;
`
