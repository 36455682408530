import { styled } from 'Adapters/Freestyled'
import { Icon, IconName } from 'Components/Icon'
import { Text } from 'Components/Text'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const NavItem: FC<{
    name: string
    to: string
    end?: boolean
    icon: IconName
    expanded: boolean
}> = ({ name, to, end, icon }) => (
    <Styled end={end} to={to}>
        <Icon name={icon} title={name} />
        <Text as="p" variant="regular-5">
            {name}
        </Text>
        <Icon name="CarrotRight" title={name} className="carrot" />
    </Styled>
)

const Styled = styled(NavLink)`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    gap: 0.5rem;
    background-color: ${({ theme }) => theme.palette.ui['02'].normal};
    transition: ${({ theme }) => theme.animation.buttonTransitionDuration};

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => theme.palette.ui['02'].hover};
        }
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.ui['02'].active};
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    > p {
        flex: 1;
        color: ${({ theme }) => theme.palette.text['02'].normal};
        transition: color
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &.active > p {
        color: ${({ theme }) => theme.palette.text['01'].normal};
    }

    svg path {
        fill: ${({ theme }) => theme.palette.icon['02'].normal};
        transition: fill
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &.active svg path {
        fill: ${({ theme }) => theme.palette.icon['01'].normal};
    }

    .carrot {
        opacity: 0;
        transition: opacity
            ${({ theme }) => theme.animation.buttonTransitionDuration} ease;
    }

    &.active .carrot {
        opacity: 1;
    }
`

export { NavItem }
