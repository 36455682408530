import { FC } from 'react'
import SlackLogo from './slack.webp'
import TeamsLogo from './teams.png'

export const IntegrationImage: FC<{
    name: string
    imgProps?: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >
    alt: string
}> = ({ name, imgProps, alt }) => {
    switch (name) {
        case 'slack':
            return <img src={SlackLogo} alt={alt} {...imgProps} />
        case 'microsoft-teams':
            return <img src={TeamsLogo} alt={alt} {...imgProps} />
        default:
            return <></>
    }
}
