import { DocumentMetadata } from 'Components/Documents'
import { ControlledModal } from 'Components/Modal'
import { FC } from 'react'
import { DocumentPickerContent } from './Components/DocumentPickerContent'

type Props = {
    initialSelections?: string[]
    onConfirm: (selections: DocumentMetadata[]) => void
    selectMode: 'single' | 'multiple'
    isOpen: boolean
    onOpenChange: (open: boolean) => void
    returnFocus: () => void
}

export const DocumentPicker: FC<Props> = ({
    onConfirm,
    selectMode,
    isOpen,
    onOpenChange,
    returnFocus,
    initialSelections = [],
}) => {
    return (
        <ControlledModal
            renderContent={() => (
                <DocumentPickerContent
                    onConfirm={onConfirm}
                    selectMode={selectMode}
                    initialSelections={initialSelections}
                />
            )}
            title={'Select document'}
            description={
                'Select a document that has been uploaded or upload a new one'
            }
            hideDescription
            open={isOpen}
            onOpenChange={onOpenChange}
            returnFocus={returnFocus}
        />
    )
}
